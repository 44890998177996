import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import dateUtil from '@premier/utils/date';
import { DatePicker, RadioGroup, FormGroup, ErrorText } from '@premier/ui';
import withField from './withField';
import './DateRangePresetField.scss';

export const DateRangeEnum = {
    ALL: 'all',
    NONE: 'none',
    TODAY: 'today',
    YESTERDAY: 'yesterday',
    LAST_7DAYS: 'last7days',
    LAST_30DAYS: 'last30days',
};

/**
 * A DateRangePicker + RadioButtons (the presets) to select a date range.
 * @return {Object} An object { dates: [fromDateTime,toDateTime], presetValue: 'today' } where presetValue is null if custom range.
 */
const DateRangePresetField = ({ 
    name, formProps, groupProps,
    presetOptions, onAutoUpdate, onPresetSelected,
    placeholder, minDate, maxDate, enableTime, onOpen, onClose, // to be passed to DatePicker
    colClass,
}) => {

    const { value, error, setValue } = formProps;
    const datePickerError = _.get(error, 'dates');

    const [id] = useState(_.uniqueId(`${name}_`));
    const [radioGroupName] = useState(_.uniqueId('dateRangeRadio_'));

    useEffect(() => {
        if (value) {
            // Set dates if only the presetValue is supplied
            if (value.presetValue && ![DateRangeEnum.ALL, DateRangeEnum.NONE].includes(value.presetValue) && !_.get(value, 'dates.length')) {
                let dates = getDatesForPresetValue(value.presetValue);
                autoUpdateValueTo({...value, dates});
            }
            // Otherwise set presetValue based on dates
            else if (_.get(value, 'dates.length') === 2) {
                let expectedValue = getPresetValueByDates(value.dates);
                if(value.presetValue !== expectedValue) {
                    autoUpdateValueTo({...value, presetValue: expectedValue});
                }
            }
        }
    }, [formProps, value]);

    useEffect(() => {
        if (onPresetSelected)
            onPresetSelected(_.get(value, 'presetValue'));
    }, [value])
    
    function autoUpdateValueTo(newValue) {
        setValue(newValue);
        if(onAutoUpdate)
            onAutoUpdate(newValue);
    }

    function handleDateChange(dates) {
        let presetValue = getPresetValueByDates(dates);
        setValue({dates: dates, presetValue: presetValue});
    }

    function handleRadioChange(option) {
        let dates = getDatesForPresetValue(option.value);
        setValue({dates: dates, presetValue: option.value});
    }

    function getDatesForPresetValue(val) {
        function merchantNow() { return dateUtil.getMerchantNowAsLocal(); }

        switch(val) {
            case DateRangeEnum.NONE:
            case DateRangeEnum.ALL:
                return [];
            case DateRangeEnum.TODAY:
                return [merchantNow().startOf('day').toDate(), merchantNow().endOf('day').toDate()];
            case DateRangeEnum.YESTERDAY:
                return [merchantNow().add(-1,'day').startOf('day').toDate(), merchantNow().add(-1,'day').endOf('day').toDate()];
            case DateRangeEnum.LAST_7DAYS:
                return [merchantNow().add(-7,'day').startOf('day').toDate(), merchantNow().endOf('day').toDate()];
            case DateRangeEnum.LAST_30DAYS:
                return [merchantNow().add(-30,'day').startOf('day').toDate(), merchantNow().endOf('day').toDate()];
            default:
                throw new Error('Unhandled date range preset: '+val);
        }
    }

    function getPresetValueByDates(dates) {
        var val = null;

        presetOptions.map(o => o.value).forEach(presetValue => {
            if(!val) {
                var expectedDates = getDatesForPresetValue(presetValue);
                if(expectedDates.map(d => moment(d).format('YYYY-MM-DD HH:mm Z')).join() === 
                           dates.map(d => moment(d).format('YYYY-MM-DD HH:mm Z')).join()) // If array have same elements
                    val = presetValue;
            }
        });

        return val;
    }

    return (
        <FormGroup fieldId={id}
            {...groupProps}
            className={classNames(groupProps.className, 'date-range-preset-field')}
        >
            <div className='row'>
                <div className={colClass || 'col-lg-6'}>
                    <DatePicker mode='range'
                        enableTime={enableTime}
                        minDate={minDate}
                        maxDate={maxDate}
                        placeholder={placeholder}
                        onChange={handleDateChange}
                        value={_.get(value, 'dates')}
                        error={datePickerError}
                        onOpen={onOpen}
                        onClose={onClose}
                        aria-labelledby={groupProps.label && `${id}_label`}
                        aria-required={groupProps.mandatory}
                        aria-invalid={!!datePickerError}
                        aria-describedby={datePickerError && `${id}_datePickerError`}
                    />

                    {datePickerError && <ErrorText id={`${id}_datePickerError`}>{datePickerError}</ErrorText>}
                </div>

                <div className={classNames(colClass || 'col-lg-6', 'date-radios')}>
                    <RadioGroup inlineUpSm compact
                        name={radioGroupName}
                        options={presetOptions}
                        onChange={handleRadioChange}
                        selectedValue={_.get(value, 'presetValue')}
                        ariaLabelledBy={`${id}_label`}
                    />
                </div>
            </div>
        </FormGroup>
    );
};

DateRangePresetField.propTypes = {
    name: PropTypes.string.isRequired,

    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    /** Set to true for automatic placeholder (from merchant settings), otherwise specify the placeholder manually as a string */
    placeholder: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    
    enableTime: PropTypes.bool,
    /** The earliest selectable date. Note that dynamic time might cause infinite refreshes */
    minDate: PropTypes.object,
    /** The latest selectable date. Note that dynamic time might cause infinite refreshes */
    maxDate: PropTypes.object,

    /** The radio buttons */
    presetOptions: PropTypes.arrayOf(PropTypes.shape({
        /** This must be a DateRangeEnum (eg. DateRangeEnum.TODAY) */
        value: PropTypes.string,
        label: PropTypes.string
    })),
    onChange: PropTypes.func,
    /** Fired when the dates/presetValue is updating each other. eg. (newValue) => { ... } */
    onAutoUpdate: PropTypes.func,

    onPresetSelected: PropTypes.func,

    /** Default = 'col-lg-6' */
    colClass: PropTypes.string,
};

DateRangePresetField.defaultProps = {
    presetOptions: [
        { value: DateRangeEnum.LAST_30DAYS, label: 'Last 30 days' },
        { value: DateRangeEnum.TODAY,       label: 'Today' },
        { value: DateRangeEnum.YESTERDAY,   label: 'Yesterday' },
    ],  // currently applies to Transactions filter
}

export default withField(DateRangePresetField);