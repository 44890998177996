export default {
    None: "None",
    PaymentOnly: "PaymentOnly",
    PreAuthOnly: "PreAuthOnly",
    PaymentAndTokenise: "PaymentAndTokenise",
    PreAuthAndTokenise: "PreAuthAndTokenise",
    TokeniseOnly: "TokeniseOnly",
    UpdateToken: "UpdateToken",
    PaymentAndUpdateToken: "PaymentAndUpdateToken",
    PreAuthAndUpdateToken: "PreAuthAndUpdateToken",
    UpdateAndRetryMissedPayment: "UpdateAndRetryMissedPayment",
};

export function actionTypeValueToEnumName(value: number | string) {
    switch (Number(value)) {
        case 0:
            return "None";
        case 1:
            return "PaymentOnly";
        case 2:
            return "PreAuthOnly";
        case 3:
            return "PaymentAndTokenise";
        case 4:
            return "PreAuthAndTokenise";
        case 5:
            return "TokeniseOnly";
        case 6:
            return "UpdateToken";
        case 7:
            return "PaymentAndUpdateToken";
        case 8:
            return "PreAuthAndUpdateToken";
        case 9:
            return "UpdateAndRetryMissedPayment";
        default:
            return null;
    }
}
