import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as invoiceActions from '../_actions/invoiceActions';
import SecurityUtil from '@premier/utils/security';
import { userRoles } from 'components/Routing';

import { InvoiceModel } from '@premier/webapi-client';


const useInvoices = () => {
    const dispatch = useDispatch();
    const { authenticatedUser, invoiceBillers } = useSelector((state: any) => ({
        invoiceBillers: state.dataVault?.invoice?.invoiceBillers?.data || null,
        authenticatedUser: state.accounts.users.authenticatedUser
    }));

    const [ invoicesPermitted ] = useState(SecurityUtil.hasAccess([[userRoles.dataVault, userRoles.invoices]], authenticatedUser));

    const [submitting, setSubmitting] = useState(false);

    const submitInvoice = (model: InvoiceModel, callback?: () => void, errorsCallback?: () => void) => {
        if (!invoicesPermitted) {
            return;
        }

        setSubmitting(true);
        dispatch(invoiceActions.createInvoice(model)).then((r) => {
            setSubmitting(false);
            if (r.ok && callback) {
                callback();
            } else if (!r.ok && errorsCallback) {
                errorsCallback();
            }
        });
    };

    // This must only run once and an empty dependency array is required for that. Ignore the warning.
    // See https://stackoverflow.com/questions/58579426/in-useeffect-whats-the-difference-between-providing-no-dependency-array-and-an
    useEffect(() => {
        if (!invoiceBillers && invoicesPermitted) {
            dispatch(invoiceActions.getInvoiceBillers());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        submitting,
        submitInvoice,
        invoiceBillers,
    };
};

export default useInvoices;
