import React from 'react';
import moment from 'moment-timezone';
import { DatePickerField, DropdownField, Form, InputField, validate } from '@premier/form';
import { FormGroup, Input } from '@premier/ui';
import dateUtil from '@premier/utils/date';
import currencyUtil from '@premier/utils/currency';
import { useInvoiceSettings } from 'components/Settings/_hooks/useInvoicesSettings';

import { maxLengthErrorMessage } from './InvoiceForm';
import IsFormDirty from './IsFormDirty';

export const labels = {
    invoiceNumber: 'Invoice number',
    invoiceDate: 'Invoice date',
    orderNumber: 'Order number',
    termsOfPayments: 'Terms of payments',
    dueDate: 'Due date',
    currency: 'Currency',
};

export const fieldNames = {
    invoiceNumber: 'invoiceNumber',
    invoiceDate: 'invoiceDate',
    orderNumber: 'orderNumber',
    termsOfPayments: 'termsOfPayments',
    dueDate: 'dueDate',
    currency: 'currency',
};

interface Props {
    name?: string;
    setIsDirty?: (value: boolean) => void;
}

const InvoiceDetailForm: React.FC<Props> = ({ name, setIsDirty }) => {
    const invoiceSettings = useInvoiceSettings();

    const initialValues = {
        [fieldNames.currency]: currencyUtil.currencies.find((c) => c.alphaCode === 'AUD')?.code,
    };

    const validation = {
        [fieldNames.invoiceNumber]: validate().required().maxLength(100, maxLengthErrorMessage(100)),
        [fieldNames.invoiceDate]: validate().required(),
        [fieldNames.orderNumber]: validate().required().maxLength(100, maxLengthErrorMessage(100)),
        [fieldNames.termsOfPayments]: validate().test(
            (value: any) => typeof value === 'number',
            '{label} is required.',
            'required'
        ),
        [fieldNames.currency]: validate().required(),
    };
    const render = (context: any) => {
        // Calculate due date
        const invoiceDate = context.getValue(fieldNames.invoiceDate);
        const termsOfPayments = context.getValue(fieldNames.termsOfPayments);
        const dueDate =
            invoiceDate && typeof termsOfPayments === 'number'
                ? moment(new Date(invoiceDate)).add(termsOfPayments, 'days').format(dateUtil.dateFormat)
                : '';
        return (
            <>
                {setIsDirty && <IsFormDirty initialValues={initialValues} setDirty={setIsDirty} />}
                <InputField
                    name={fieldNames.invoiceNumber}
                    label={labels.invoiceNumber}
                    validateOnChange
                    {...{ digitsOnly: true }}
                />
                <DatePickerField name={fieldNames.invoiceDate} label={labels.invoiceDate} />
                <InputField name={fieldNames.orderNumber} label={labels.orderNumber} validateOnChange />
                <DropdownField
                    name={fieldNames.termsOfPayments}
                    label={labels.termsOfPayments}
                    {...{
                        options:
                            invoiceSettings.settings?.termOfPayments?.map((top) => ({
                                value: top.daysToPay,
                                label: top.description,
                            })) || [],
                    }}
                />
                <FormGroup fieldId={fieldNames.dueDate} label={labels.dueDate}>
                    <Input name={fieldNames.dueDate} value={dueDate} disabled />
                </FormGroup>
            </>
        );
    };
    return <Form name={name} initialValues={initialValues} initialValidation={validation} {...{ render }} />;
};

export default InvoiceDetailForm;
