import { connect } from "react-redux";
import { RootState } from "store/store";
import { PlatformRoutesConfiguration, userRoles } from 'components/Routing';
import SecurityUtil, { User } from "@premier/utils/security";
import { PageHeader } from "components/Common";
import { Link, PaddedContainer, PageSection } from "packages/ui";

type Props = {
    authenticatedUser: User;
}

const DeclineManagerSettingsPage = ({ authenticatedUser }: Props) => {
    return (
        <PageSection>
            <PageHeader title="Batch decline settings" subtitle="Configure how declined batch payments are handled and managed." />

            <PaddedContainer title="General settings" button={<Link to={PlatformRoutesConfiguration.batchDeclineSettingsRoute!.generalSettings.generatePath()} button>Edit</Link>} lessMargin noDivider>
                Configure global declines feature settings.
            </PaddedContainer>

            <PaddedContainer title="Manage trays" button={<Link button to={PlatformRoutesConfiguration.batchDeclineSettingsRoute!.manageTrays.generatePath()}>Edit</Link>} lessMargin noDivider >
                Define tray aliases, instructions, and notification options.
            </PaddedContainer>

            <PaddedContainer title="Card actions" button={<Link button to={PlatformRoutesConfiguration.batchDeclineSettingsRoute?.cardActions.generatePath()}>Edit</Link>} lessMargin noDivider>
                Define the actions for card decline codes, and destination trays.
            </PaddedContainer>

            {SecurityUtil.hasAccess([userRoles.directDebit], authenticatedUser) &&
                <PaddedContainer title="Bank account actions" button={<Link button to={PlatformRoutesConfiguration.batchDeclineSettingsRoute?.bankAccountActions.generatePath()}>Edit</Link>} lessMargin noDivider>
                    Define the actions for bank acount decline codes, and destination trays.
                </PaddedContainer>}
        </PageSection>
    );
};

function mapStateToProps(state: RootState) {
    return {
        authenticatedUser: state.accounts.users.authenticatedUser,
    };
}

export default connect(mapStateToProps)(DeclineManagerSettingsPage);
