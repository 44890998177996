import React from "react";
import { FileUploadState } from "./IServicesForm";
import { Dialog, Icon, Button } from "@premier/ui";

export const IServicesUploadDialogCopy = {
    overwritingWarning: {
        title: "Overwrite the existing rules?",
        body:
            "If there are existing rules, new rules from the uploaded file will overwrite them.",
    },
    invalidFileType: {
        title: "Error with file upload",
        body:
            "The file type you have submitted is invalid. Only dat, txt and csv files can be submitted with or without zipping.",
    },
    backendError: {
        title: "Error with file upload",
        body:
            "File you have submitted is either empty or invalid.",
    },
    genericError: {
        title: "Error with file upload",
        body:
            "The selected file can not be uploaded right now. Please try again.",
    },
    inProgress: {
        title: "i-Services file upload in-progress!",
        body:
            "",
    },
    success: {
        title: "i-Services file uploaded successfully!",
        body:
            "The file is uploaded successfully. An email will be sent to the supplied emails notifying further progress about the file.",
    },
    uploadButton: "Upload file to overwrite",
    uploadAgainButton: "Upload again",
    cancelButton: "Cancel",
};

interface Props {
    fileUploadState: FileUploadState;
    onClickFileUpload: () => void;
    onInvalidFileClose: () => void;
    onClose: () => void;
    onSubmit: () => void;
    fileName: string;
    errorMessages?: string[];
}

const IServicesUploadDialog: React.FC<Props> = ({
    fileUploadState,
    onClickFileUpload,
    onInvalidFileClose,
    onClose,
    onSubmit,
    fileName,
    errorMessages,
}) => {
    return (
        <>
            <Dialog
                show={fileUploadState === "INVALID_FILE_TYPE"}
                closeButton
                onClose={onInvalidFileClose}
                title={IServicesUploadDialogCopy.invalidFileType.title}
                icon={<Icon alert />}
                footerButtons={
                    <>
                        <Button primary onClick={onClickFileUpload}>
                            {IServicesUploadDialogCopy.uploadAgainButton}
                        </Button>
                        <Button onClick={onInvalidFileClose}>
                            {IServicesUploadDialogCopy.cancelButton}
                        </Button>
                    </>
                }
            >
                <p className="iservices-upload-error-filename">{fileName}</p>
                <p>{IServicesUploadDialogCopy.invalidFileType.body}</p>
            </Dialog>
            <Dialog
                show={fileUploadState === "BACKEND_ERROR"}
                closeButton
                onClose={onClose}
                title={IServicesUploadDialogCopy.backendError.title}
                icon={<Icon alert />}
                footerButtons={
                    <>
                        <Button primary onClick={onClickFileUpload}>
                            {IServicesUploadDialogCopy.uploadAgainButton}
                        </Button>
                        <Button onClick={onClose}>
                            {IServicesUploadDialogCopy.cancelButton}
                        </Button>
                    </>
                }
            >
                <p className="iservices-upload-error-filename">{fileName}</p>
                {errorMessages?.map((err, idx) => (
                    <p key={idx}>{err}</p>
                ))}
            </Dialog>
            <Dialog
                show={fileUploadState === "GENERIC_ERROR"}
                closeButton
                onClose={onClose}
                title={IServicesUploadDialogCopy.backendError.title}
                icon={<Icon alert />}
                footerButtons={
                    <>
                        <Button primary onClick={onClickFileUpload}>
                            {IServicesUploadDialogCopy.uploadAgainButton}
                        </Button>
                        <Button onClick={onClose}>
                            {IServicesUploadDialogCopy.cancelButton}
                        </Button>
                    </>
                }
            >
                <p className="iservices-upload-error-filename">{fileName}</p>
                <p>{IServicesUploadDialogCopy.genericError.body}</p>
            </Dialog>

            <Dialog
                show={fileUploadState === "OVERWRITE_WARNING"}
                closeButton
                onClose={onClose}
                title={IServicesUploadDialogCopy.overwritingWarning.title}
                icon={<Icon alert />}
                footerButtons={
                    <>
                        <Button onClick={onSubmit}>
                            {IServicesUploadDialogCopy.uploadButton}
                        </Button>
                        <Button onClick={onClose}>
                            {IServicesUploadDialogCopy.cancelButton}
                        </Button>
                    </>
                }
            >
                <p>{IServicesUploadDialogCopy.overwritingWarning.body}</p>
            </Dialog>
            <Dialog
                show={fileUploadState === "IN_PROGRESS"}
                onClose={onClose}
                title={IServicesUploadDialogCopy.inProgress.title}
                icon={<Icon hourglass />}
            >
                <p>{IServicesUploadDialogCopy.inProgress.body}</p>
            </Dialog>
            <Dialog
                show={fileUploadState === "SUCCESS"}
                closeButton
                onClose={onClose}
                title={IServicesUploadDialogCopy.success.title}
                icon={<Icon tick />}
                autoClose={3}
            >
                <p>{IServicesUploadDialogCopy.success.body}</p>
            </Dialog>
        </>
    );
};

export default IServicesUploadDialog;
