import base from 'platforms/base/constants/text';

export default {...base,
    platformName: 'EFTPOS Stationery shop',
    loginHeader: 'EFTPOS Stationery log on',
    loginText: `This service is only available to existing CommBank customers.

Please login using your 16 digit CommBank Merchant ID, which can be located on your last statement. Your Merchant ID will begin with the numbers 535310.`,
    footerText: 'Brought to you by Linkly and Commonwealth Bank of Australia, ABN 48 123 123 124 AFSL and Australian credit licence 234945.',
    supportPhoneNumber: '1800 230 177',
    supportPhoneLink: 'tel:+611800230177',
};
