import React from 'react';
import classNames from 'classnames';
import './PaginationDots.scss';

type Props = {
    /** Number of items per page */
    itemsPerPage: number,
    /** The total number of items available to be paginated */
    itemCount: number,
    /** Starts from 1 */
    currentPage: number,
    /** Fired when page is changing. eg. (newPage) => setPageNumber(newPage) **/
    onPageChange: (newPageNum: number) => void,
    className?: string,
};

/** Similar to PaginationControl but currently only shown on small screens and does not support more than 3/4 pages */
const PaginationDots = ({
    itemsPerPage, itemCount=0, currentPage=1, onPageChange, className
}: Props) => {

    if (!itemCount)
        return null;

    const numPages = Math.ceil(itemCount / itemsPerPage);

    function canGoNext() {
        return currentPage < numPages;
    }

    function canGoPrevious() {
        return currentPage > 1;
    }

    function handlePreviousClick() {
        if(canGoPrevious())
            onPageChange(currentPage - 1);
    }

    function handleNextClick() {
        if(canGoNext())
            onPageChange(currentPage + 1);
    }

    return (
        <div className={classNames('pagination-dots', className)}>
            <div className={classNames('prev-arrow', {'disabled': !canGoPrevious()})}
                title='Previous'
                onClick={handlePreviousClick}
                role='button' tabIndex={0}
            >
                <div></div>
            </div>

            <ul className='dots'>
                {[...Array(numPages)].map((x, i) => (
                    <li key={i} className={classNames({'active': i + 1 === currentPage})}
                        onClick={() => onPageChange(i+1)}
                    >
                        <div></div>
                    </li>
                ))}
            </ul>

            <div className={classNames('next-arrow', {'disabled': !canGoNext()})}
                title='Next'
                onClick={handleNextClick}
                role='button' tabIndex={0}
            >
                <div></div>
            </div>
        </div>
    );
}

export default PaginationDots;
