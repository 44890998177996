import { DropdownField } from "@premier/form";
import { OrderTypeModel } from "@premier/webapi-client";

type Props = {
    name: string;
    orderTypes: OrderTypeModel[];
    excludeOrderTypes: string[];
    onChange: (e: any) => void;
    noLabels: boolean;
    compact: boolean;
};

const OrderTypesField = ({
    name,
    orderTypes, excludeOrderTypes, //data
    onChange,
    noLabels,
    compact,
    ...otherProps
} : Props) => {
    const availableOrderTypes = excludeOrderTypes
        ? orderTypes.filter((el) => el?.key && !excludeOrderTypes.includes(el.key))
        : orderTypes;

    return (
        <DropdownField
            name={name}
            options={availableOrderTypes.map(x => ({ value: x.key, label: x.description }))}
            onChange={onChange}
            noLabels={noLabels}
            compact={compact}
            {...otherProps}
        />
    );
};

export default OrderTypesField;