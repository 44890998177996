import React from 'react';
import { Switch } from '@premier/ui';
import text from 'platforms/current/constants/text';
import './FraudRuleToggle.scss';
import { FraudBasicSettingsModel } from 'packages/webapi-client';

interface Props {
    title: string;
    subtitle?: string;
    on?: boolean;
    disabled?: boolean;
    onChange: (key: keyof FraudBasicSettingsModel) => void;
    value: keyof FraudBasicSettingsModel;
    additionalText?: boolean;
    additionalTextContent?:string;
}

export const FraudRuleToggle: React.FC<Props> = ({ title, subtitle, disabled, on, onChange, value, additionalText, additionalTextContent }) => {
    return (
        <div className='fraud-rule-toggle'>
            <div className='fraud-rule-toggle-label'>
                <div className='fraud-rule-toggle-title'>{title}</div>
                <div className='fraud-rule-toggle-button-wrapper-small'>
                    <Switch disabled={disabled} on={on} onChange={() => onChange(value)} />
                </div>
                {subtitle ? <div className='fraud-rule-toggle-subtitle'>{subtitle}</div> : null}
                {additionalText &&
                 <span><br />
                 <div className='fraud-rule-toggle-subtitle'>
                 {additionalTextContent}please contact {text.platformName} support team on <a href={text.supportPhoneLink}>{text.supportPhoneNumber}</a> or <a href={`mailto:${text.supportEmail}`}>{text.supportEmail}</a>.
                </div></span>} 
            </div>
            <div className='fraud-rule-toggle-button-wrapper-large'>
                <Switch disabled={disabled} on={on} onChange={() => onChange(value)} />
            </div>
        </div>
    );
};
