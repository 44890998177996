import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import countryUtil from '@premier/utils/country'
import {Form, InputField, EmailAddressField, AddressForm, PhoneNumberField, CheckboxField, FormContext} from '@premier/form';
import { PageSection, SingleColumnFormContainer, Accordion } from '@premier/ui';
import labels from 'constants/labels';

import './ChangeContactDetailsForm.scss';

/** Sub-form used for a user to change contact details. Used for the contact us page */
const ChangeContactDetailsForm = ({
    merchantCountryCode //state
}) => {
    const { getValue } = React.useContext(FormContext);

    return (
        <PageSection header='Provide new details below'>
            <Accordion title='Business address' initiallyExpanded>
                <SingleColumnFormContainer>
                    <AddressForm name='businessAddress' disableCountry />
                </SingleColumnFormContainer>

                <CheckboxField name='useSameAddress'>
                    Use the same details for business postal address
                </CheckboxField>

                {!getValue('useSameAddress') && (
                    <>
                        <p style={{marginTop: '3rem'}}>Business postal address</p>
                        <SingleColumnFormContainer>
                            <AddressForm name='businessPostalAddress' disableCountry />
                        </SingleColumnFormContainer>
                    </>
                )}
            </Accordion>

            <Accordion title='Business contact details' initiallyExpanded className='business-contact-details'>
                <SingleColumnFormContainer >
                    <Form
                        name='businessContactDetails'
                        initialValues={{
                            phoneNumber1: {iddCode: countryUtil.getIddCode(merchantCountryCode)},
                            phoneNumber2: {iddCode: countryUtil.getIddCode(merchantCountryCode)},
                            phoneNumberMobile: {iddCode: countryUtil.getIddCode(merchantCountryCode)},
                            emailAddress: '',
                            url: ''
                        }}
                        >
                        <PhoneNumberField name='phoneNumber1' label='Phone number 1' />
                        <PhoneNumberField name='phoneNumber2' label='Phone number 2' />
                        <PhoneNumberField name='phoneNumberMobile' label={labels.mobile} />
                        <EmailAddressField name='emailAddress' label={labels.emailAddress} />
                        <InputField name='url' label={labels.website} />
                    </Form>
                </SingleColumnFormContainer>
            </Accordion>
        </PageSection>
    );
};

ChangeContactDetailsForm.propTypes = {
    merchantCountryCode: PropTypes.number
};

function mapStateToProps(state, ownProps) {
    return {
        merchantCountryCode: state.accounts.users.merchant.countryCode,
    };
}

export default connect(mapStateToProps)(ChangeContactDetailsForm);
