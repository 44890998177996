import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';

import currencyUtil from '@premier/utils/currency';
import dateUtil from '@premier/utils/date';

import { ResponsiveTable, Link, PageSection, LoadingIndicator, CardContainer, PaginationControl } from '@premier/ui';
import { withError,FormError, PageHeader } from 'components/Common';

import { PlatformRoutesConfiguration } from 'components/Routing';
import * as stationeryShopActions from 'components/StationeryShop/_actions/stationeryShopActions';

const Orders = ({
    savedState, orders, errors, //state values
    actions, //API actions
    isLoading //logic renders
}) => {

    const [sort, setSort] = useState(
        _.get(savedState, 'sort', { field: 'dateCreated', descending: true })
    );
    const [reload, setReload] = useState(true);
    const [currentPage, setCurrentPage] = useState(
        _.get(savedState, 'pageIndex', 0) + 1
    );
    const resultsPerPage = 10;

    useEffect(() => {
        if (reload) {
            loadOrders();
            setReload(false);
        }
    }, [reload]);

    function pageChanged(newPage) {
        setCurrentPage(newPage);
        setReload(true);
    }

    function savePageState() {
        let pageIndex = currentPage - 1;
        let pageState = { sort, pageIndex };
        actions.saveOrdersPageState(pageState);
    }

    function loadOrders() {
        let pageIndex = currentPage - 1;

        actions.loadOrders(pageIndex, resultsPerPage, sort)
            .then(() => {
                savePageState();
            })
            .catch(err => {});
    }

    function handleSort(columnName, isDescending) {
        setSort({
            field: columnName,
            descending: isDescending,
        });
        setReload(true);
    }

    return (
        <PageSection>
            {isLoading && (
                <CardContainer header="Loading orders">
                    <LoadingIndicator />
                </CardContainer>
            )}

            {!isLoading && (
                <>
                    <PageHeader title="My orders" />

                    <FormError errors={errors} />

                    <ResponsiveTable
                        breakpoints={['sm', 'md']}
                        data={_.get(orders, 'list', [])}
                        sort={sort}
                        onSort={handleSort}
                        columns={[
                            {
                                label: 'Status',
                                sortKey: 'orderStatus',
                                getter: item =>
                                    item.orderStatus.description,
                            },
                            {
                                label: 'Order no.',
                                sortKey: 'orderReference',
                                getter: item => (
                                    <Link
                                        to={PlatformRoutesConfiguration.stationeryRoute.orderDetails.generatePath(
                                            item.orderId
                                        )}
                                    >
                                        {item.orderReference}{' '}
                                    </Link>
                                ),
                            },
                            {
                                label: 'Submitted on',
                                sortKey: 'dateCreated',
                                getter: item =>
                                    dateUtil.formatToDateTimeString(
                                        item.dateCreated
                                    ),
                            },
                            {
                                label: 'Total amount',
                                sortKey: 'totalAmount',
                                getter: item =>
                                    currencyUtil.convertToDisplayString(
                                        item.totalAmount
                                    ),
                                textAlign: 'right',
                            },
                        ]}
                        noDataText="No orders to display."
                    />

                    <PaginationControl
                        currentPage={currentPage}
                        onPageChanged={pageChanged}
                        itemCount={_.get(orders, 'totalCount', 0)}
                        itemsPerPage={10}
                    />
                </>
            )}
        </PageSection>
    );
};

function mapStateToProps(state) {
    return {
        savedState: state.stationeryShop.shop.ordersPageState,
        orders: state.stationeryShop.shop.orders,
        isLoading: state.stationeryShop.shop.isLoading,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(stationeryShopActions, dispatch),
    };
}

function mapStoreToErrors(state) {
    return state.stationeryShop.shop.errors;
}

export default withError(
    connect(mapStateToProps, mapDispatchToProps)(Orders),
    mapStoreToErrors,
    null
);
