import { DeclineTrayModel } from "packages/webapi-client";
import DeclineManagerTrayDetails from "./DeclineManagerTrayDetails";

type Props = {
    declineTrays?: DeclineTrayModel[] | null;
    merchantNumber: string
}

const DeclineManagerTrays = ({ declineTrays, merchantNumber }: Props) => {

    return (
        <div>
        {declineTrays?.map((t) => {
            return(
                <div key={t.trayID}>
                        <DeclineManagerTrayDetails declineTray={t} merchantNumber={merchantNumber} />
                </div>)
            })
        }
        </div>
    );
}

export default DeclineManagerTrays;