import { MouseEventHandler, useState } from "react";
import { Dispatch, bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { Input, FormGroup, FormikSwitch } from "@linkly/formik-ui";
import { Button, Dialog, ErrorText, Row, SingleColumnFormContainer, Icon } from "@premier/ui";
import labels from "platforms/current/constants/labels";
import billerApi from "api/billerApi";
import * as billerActions from "components/Settings/_actions/billerActions";

import "./EditBillerCodeNameDialog.scss";

type Props = {
    show: boolean;
    onClose: () => void;
    billerCode: string;
    currentBillerCodeName: string;
    currentEnableBillerCode: boolean;
    billerActions: any;
};

type FormFields = {
    billerCodeName: string;
    enableBillerCode: boolean;
};

const EditBillerCodeNameDialog = ({
    show, //logic renders
    onClose, //functions
    billerCode,
    currentBillerCodeName, //data
    currentEnableBillerCode,
    billerActions, // API actions
}: Props) => {
    const [submitErrors, setSubmitErrors] = useState<string[]>([]);
    const [showEnableBillerCodeWarningDialog, setShowEnableBillerCodeWarningDialog] = useState(false);
    const [currentFormValues, setCurrentFormValues] = useState<any>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    function doClose() {
        setSubmitErrors([]);
        onClose();
    }

    const handleClose: MouseEventHandler = () => {
        doClose();
    };

    const ValidationSchema = Yup.object().shape({
        billerCodeName: Yup.string().label("Biller code name").min(2).max(100).required(),
    });

    const handleSubmit = async (formValues: FormFields) => {
        try {
            const response = await billerApi.updateDetails({
                billerCode: billerCode,
                description: formValues.billerCodeName,
                billerCodeEnabled: formValues.enableBillerCode,
            });

            if (response?.status !== 200 || response?.data?.errors?.length) {
                throw response;
            }

            await billerActions.loadBillers();
        } catch (response: any) {
            if (response?.data?.errors?.length) {
                setSubmitErrors(response.data.errors.map((r: any) => r.message));
            } else {
                setSubmitErrors([labels.unknownErrorMessage]);
            }

            return false;
        }

        return true;
    };

    const handleConfirmSubmit = async () => {
        setIsSubmitting(true);
        const success = await handleSubmit(currentFormValues);
        setIsSubmitting(false);
        if (success) {
            doClose();
        }
    };

    return (
        <>

            <Dialog title="Edit biller code" show={show} onClose={onClose} closeButton>
                <Formik
                    initialValues={{
                        billerCodeName: currentBillerCodeName,
                        enableBillerCode: currentEnableBillerCode
                    }}
                    validationSchema={ValidationSchema}
                    onSubmit={async (formValues, { setSubmitting }) => {
                        setCurrentFormValues(formValues);
                        if (formValues.enableBillerCode !== currentEnableBillerCode) {
                            setShowEnableBillerCodeWarningDialog(true);
                        } else {
                            const success = await handleSubmit(formValues);
                            setSubmitting(false);

                            if (success) {
                                doClose();
                            }
                        }
                    }}
                >
                    {(props) => (
                        <Form>
                            <SingleColumnFormContainer>
                                <Row>
                                    <FormGroup label="Enable biller code" className="col-sm-12" name="enableBillerCode">
                                        <Field as={FormikSwitch} name="enableBillerCode" />
                                    </FormGroup>
                                </Row>
                                <Row>
                                    <FormGroup label="Biller code name" className="col-sm-12" name="billerCodeName">
                                        <Field as={Input} name="billerCodeName" />
                                    </FormGroup>
                                </Row>
                                <div className="dialog-actions">
                                    <Button type="submit" disabled={props.isSubmitting || isSubmitting} primary>
                                        Save
                                    </Button>
                                    <Button onClick={handleClose}>Cancel</Button>
                                </div>
                                {submitErrors.length
                                    ? submitErrors.map((errorMessage, i) => <ErrorText key={i}>{errorMessage}</ErrorText>)
                                    : null}
                            </SingleColumnFormContainer>
                        </Form>
                    )}
                </Formik>
            </Dialog>

            <Dialog
                show={showEnableBillerCodeWarningDialog}
                icon={<Icon alert />}
                title={currentEnableBillerCode ? "Are you sure you want to disable this biller code?" : "Are you sure you want to enable this biller code?"}
                footerButtons={<>
                    <Button onClick={() => { setShowEnableBillerCodeWarningDialog(false); handleConfirmSubmit(); }} >Confirm</Button>
                    <Button onClick={() => { setShowEnableBillerCodeWarningDialog(false); doClose(); }} >Cancel</Button>
                </>}
            >
                {currentEnableBillerCode ? "Doing so will mean no future payments can be processed for this biller code (through the back office, API or hosted payment page), but existing schedules will continue to be processed."
                    : "Doing so will mean payments can now be processed for this biller code (through the back office, API or hosted payment page)."}
            </Dialog>
        </>

    );
};

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        billerActions: bindActionCreators(billerActions, dispatch),
    };
}

export default connect(null, mapDispatchToProps)(EditBillerCodeNameDialog);
