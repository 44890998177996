import * as actions from './actionTypes';
import { reportsApi } from 'api';


//#region ========== Reports ==========

export function getDailyReports(dateRange, pageNo, resultsPerPage, merchantNumber) {
    return function(dispatch) {
        dispatch(getDailyReportsRequested());
        return reportsApi.getDailyReports(dateRange, pageNo, resultsPerPage, merchantNumber).then((result) => {
            dispatch(getDailyReportsSuccess(result.reportsList));
            return result;
        }).catch(err => {
            dispatch(getDailyReportsFailed(err));
            throw err;
        });
    }
}

export function getDailyReport(fileName, childMerchantNumber) {
    return function(dispatch) {
        dispatch(getDailyReportRequested());
        return reportsApi.getDailyReport(fileName, childMerchantNumber).then((result) => {
            dispatch(getDailyReportSuccess(result));
            return result;
        }).catch(err => {
            dispatch(getDailyReportFailed(err));
            throw err;
        });
    }
}

export function getDailyReportsRequested() {
    return { type: actions.GET_DAILY_REPORTS_REQUESTED }
}
export function getDailyReportsSuccess(reportsList) {
    return {type: actions.GET_DAILY_REPORTS_SUCCESS, reportsList};
}
export function getDailyReportsFailed(err) {
    return {type: actions.GET_DAILY_REPORTS_FAILED, err};
}


export function getDailyReportRequested() {
    return { type: actions.GET_DAILY_REPORT_REQUESTED }
}
export function getDailyReportSuccess(report) {
    return {type: actions.GET_DAILY_REPORT_SUCCESS, report};
}
export function getDailyReportFailed(err) {
    return {type: actions.GET_DAILY_REPORT_FAILED, err};
}

//#endregion