import React, { ReactNode } from 'react';
import { IconText, IconTextProps } from '..';
import './ErrorText.scss';

type Props = Omit<IconTextProps, 'children'> & {
    children: ReactNode,
}

/** Shows an alert Icon followed by red text */
const ErrorText = ({
    children, ...otherProps
}: Props) => {
    return (
        <IconText alert srOnly='Error: ' {...otherProps}>
            <span className='error-text'>{children}</span>
        </IconText>
    );
}

export default ErrorText;