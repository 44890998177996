import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';

import labels from 'constants/labels';
import { searchableTransactionDateRange } from '@premier/utils/date';

import { PageSection, LoadingIndicator, Link } from '@premier/ui';
import { Form, DateRangePresetField, DateRangeEnum } from '@premier/form';
import { FormError, PageHeader, MerchantDropdownLegacy, ProductTooltip } from 'components/Common';
import { SettlementSummaryReport, SettlementTransactionsBreakdown } from 'components/Reports';

import * as settlementActions from 'components/Reports/_actions/settlementActions';
import { PlatformRoutesConfiguration } from 'components/Routing';

import './SettlementReports.scss';

const SettlementReports = ({
    report, //state values
    actions, //API actions
    isLoading, //logic render
    errors //form
}) => {

    const initialFilter = {
        merchantNumber: '',
        dateRange: { presetValue: DateRangeEnum.TODAY }
    };

    const [filter, setFilter] = useState({});

    useEffect(() => {

        if (_.get(filter, 'dateRange.dates.length', 0) === 2) {
            actions.getSettlementReport(filter.merchantNumber, filter.dateRange.dates)
        }
    }, [filter]);


    function handleFilterChange(values) {

        setFilter(values);
    }


    // ========== Render ==========

    return (
        <div className='settlement-reports-page'>
            <PageSection className='header-section'>
                <PageHeader
                    title={<>{'Settlements'}<ProductTooltip productTooltipModule={"SETTLEMENTS_REPORTS"} /></>}
                >
                    <Link primary to={PlatformRoutesConfiguration.reportRoute.exportSettlements.generatePath()}>Export settlements</Link>
                </PageHeader>
                <p>A settlement date is when the payment transfer is due to be completed. Payments processed after 5:30pm will be settled on the next business day.</p>
                <p>Did you know your customised reporting can be automated? Contact your relationship manager to find out how.</p>
            </PageSection>

            <PageSection noPaddingBottom>
                <h2>Search by</h2>

                <Form
                    initialValues={initialFilter}
                    render={(context) => (<>
                        <div className='row'>
                            <MerchantDropdownLegacy
                                className='col-lg-6'
                                onChange={(val, context) => handleFilterChange(context.values)}
                                allOption
                            />

                            <DateRangePresetField className='col-lg-6' colClass='col-xl-12'
                                name='dateRange'
                                label={labels.date}
                                presetOptions={[
                                    { value: DateRangeEnum.TODAY, label: 'Today' },
                                    { value: DateRangeEnum.YESTERDAY, label: 'Yesterday' },
                                    { value: DateRangeEnum.LAST_7DAYS, label: 'Last 7 days' },
                                    { value: DateRangeEnum.LAST_30DAYS, label: 'Last 30 days' },
                                ]}
                                minDate={searchableTransactionDateRange[0]}
                                maxDate={searchableTransactionDateRange[1]}
                                onChange={val => handleFilterChange(context.values)}
                            />

                        </div>
                    </>)}
                />

                {isLoading && <LoadingIndicator />}

                <FormError apiErrors={errors} />

            </PageSection>

            <PageSection>
                <h2>Summary report</h2>

                {report && (
                    <SettlementSummaryReport report={report.summaryReport} />
                )}

                <h2>Authorised transactions breakdown</h2>
                <p>Total amount of authorised transactions by account type. This includes refunded transactions.</p>

                {report && (
                    <SettlementTransactionsBreakdown report={report.transactionsBreakdown} />
                )}
            </PageSection>
        </div>
    );
};

function mapStateToProps(state, ownProps) {
    return {
        report: state.reports.settlement.report,
        isLoading: state.reports.settlement.isLoading,
        errors: state.reports.settlement.errors,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(settlementActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SettlementReports);