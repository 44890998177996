import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";

import { Modal, Dialog, Icon, Button, SuccessModal } from "@premier/ui";
import { FormError } from "components/Common";
import { EditTokenModalContent } from "components/DataVault";

import * as commonActions from "components/Common/_actions/actions";
import * as tokenActions from "components/DataVault/_actions/tokenActions";
import * as customerActions from "components/DataVault/_actions/customerActions";
import { RootState } from "store/store";
import { Token } from "models/Token";
import { ApiError } from "packages/webapi-client";
import { SurchargeRule } from "packages/utils/models";

export const EditTokenModalType = {
    EDIT: 1,
    EDIT_SAVE: 2,
    UNLINK_DIALOG: 3,
    UNLINKED: 4,
    DELETE_DIALOG: 5,
    DELETED: 6,
};

type Props = {
    show: boolean;
    isLoading: boolean;
    /** Show the unlink button to allow a token to be unlinked from the resource */
    allowUnlink: boolean;
    /** One of the ModalContent enum, default = EDIT */
    initialModal: number;
    /** Required if initialModal is EDIT */
    token: Token;
    surchargeRules?: SurchargeRule[];
    /** A function with a bool param, eg. (refresh) => { closeModal(refresh); } */
    onClose: (refresh: boolean) => void;
    commonActions: any;
    customerActions: any;
    tokenActions: any;
    errors: ApiError[];
}

/** Called from customer details to edit the customer's payment method. */
const EditTokenModals = ({
    show, isLoading, allowUnlink, //logic renders
    initialModal = EditTokenModalType.EDIT, token, surchargeRules, //data
    onClose, //function
    commonActions, customerActions, tokenActions, //API actions
    errors, //forms
}: Props) => {

    const [modalContent, setModalContent] = useState(initialModal);
    const [autoClose, setAutoClose] = useState(false);

    const disableDismiss = modalContent === EditTokenModalType.UNLINK_DIALOG || modalContent === EditTokenModalType.DELETE_DIALOG;

    useEffect(() => {
        commonActions.clearErrors();
    }, []);

    function handleSaved() {
        setModalContent(EditTokenModalType.EDIT_SAVE);
        setAutoClose(true);
    }

    function handleUnlink() {
        customerActions.unlinkToken(token.customerV2Id, [token.dataVaultId]).then(() => {
            setModalContent(EditTokenModalType.UNLINKED);
            setAutoClose(true);
        });
    }

    function handleDelete() {
        tokenActions.deleteToken(token.dataVaultId).then(() => {
            setModalContent(EditTokenModalType.DELETED);
            setAutoClose(true);
        });
    }

    function handleDialogCancel() {
        if (initialModal === EditTokenModalType.EDIT)
            setModalContent(initialModal);  // back to previous modal content
        else
            handleClose();
    }

    function handleClose(anyUpdate?: boolean) {
        commonActions.clearErrors();

        if (onClose)
            onClose(anyUpdate || autoClose); // autoClose is only set after unlink/delete, which means there was an update

        setModalContent(initialModal);
        setAutoClose(false);
    }

    return (
        <Modal show={show} onHide={handleClose} autoClose={autoClose} dismissable={!disableDismiss}>

            {modalContent === EditTokenModalType.EDIT && (
                <EditTokenModalContent
                    onCancel={handleClose}
                    onSaved={() => handleSaved()}
                    onUnlink={allowUnlink ? () => setModalContent(EditTokenModalType.UNLINK_DIALOG): undefined}
                    onDelete={() => setModalContent(EditTokenModalType.DELETE_DIALOG)}
                    surchargeRules={surchargeRules}
                />
            )}

            {modalContent === EditTokenModalType.EDIT_SAVE && (
                <SuccessModal noWrapper>Saved</SuccessModal>
            )}

            {modalContent === EditTokenModalType.UNLINK_DIALOG && (
                <Dialog noWrapper
                    icon={<Icon info />}
                    title="Unlink payment method"
                    footerButtons={<>
                        <Button onClick={handleUnlink} disabled={isLoading}>Unlink</Button>
                        <Button onClick={handleDialogCancel} disabled={isLoading}>Cancel</Button>
                    </>}
                >
                    This payment method will no longer be connected to a customer.
                    <FormError apiErrors={errors} />
                </Dialog>
            )}

            {modalContent === EditTokenModalType.UNLINKED && (
                <SuccessModal noWrapper>Payment method unlinked successfully</SuccessModal>
            )}


            {modalContent === EditTokenModalType.DELETE_DIALOG && (
                <Dialog noWrapper
                    icon={<Icon alert/>}
                    title="Delete this payment method?"
                    footerButtons={<>
                        <Button onClick={handleDelete} disabled={isLoading}>Delete</Button>
                        <Button onClick={handleDialogCancel} disabled={isLoading}>Cancel</Button>
                    </>}
                >
                    <p>
                        The payment method will be permanently deleted.
                    </p>
                    <p>
                        Please note: This will impact any future scheduled payments linked to this payment method.
                    </p>
                    <p>
                        Transactions associated with this payment method will not be affected and can still be searched in Manage Transactions.
                    </p>
                    <FormError apiErrors={errors} />
                </Dialog>
            )}

            {modalContent === EditTokenModalType.DELETED && (
                <SuccessModal noWrapper>Payment method deleted successfully</SuccessModal>
            )}

        </Modal>
    );
};

function mapStateToProps(state: RootState) {
    return {
        token: state.dataVault.token.details,
        isLoading: state.dataVault.token.isLoading,
        errors: state.dataVault.token.errors,
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        tokenActions: bindActionCreators(tokenActions, dispatch),
        customerActions: bindActionCreators(customerActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditTokenModals);
