import React from "react";
import { FileUploadState } from "./FraudAdvancedForm";
import { Dialog, Icon, Button, LoadingIndicator } from "@premier/ui";
import { FilenameSubheading } from "components/Batch";

export const fraudUploadDialogCopy = {
    overwritingWarning: {
        title: "Overwrite the existing rules?",
        body:
            "New rules from the uploaded file will overwrite the existing rules. Only dat, txt and csv files can be submitted with or without zipping.",
    },
    invalidFileType: {
        title: "Error with file upload",
        body:
            "The file type you have submitted is invalid. Only dat, txt and csv files can be submitted with or without zipping.",
    },
    backendError: {
        title: "Error with file upload",
        body:
            "The file you have submitted contains the following errors. Please check and resolve them before uploading again:",
    },
    success: {
        title: "File uploaded successfully",
        body: "Your file may still be processing and may take several minutes to complete. You may need to refresh this page at a later time."
    },
    uploadButton: "Select file to upload",
    uploadAgainButton: "Upload again",
    cancelButton: "Cancel",
};

interface Props {
    fileUploadState: FileUploadState;
    setFileUploadState: (state: FileUploadState) => void;
    onClickFileUpload: () => void;
    onCloseSuccess: () => void;
    fileName: string;
    errorMessages: string[];
}

const FraudUploadDialog: React.FC<Props> = ({
    fileUploadState,
    setFileUploadState,
    onClickFileUpload,
    onCloseSuccess,
    fileName,
    errorMessages,
}) => {
    return (
        <>
            <Dialog
                show={fileUploadState === "OVERWRITE_WARNING"}
                closeButton
                onClose={() => setFileUploadState("")}
                title={fraudUploadDialogCopy.overwritingWarning.title}
                icon={<Icon alert />}
                footerButtons={
                    <>
                        <Button onClick={onClickFileUpload}>{fraudUploadDialogCopy.uploadButton}</Button>
                        <Button onClick={() => setFileUploadState("")}>{fraudUploadDialogCopy.cancelButton}</Button>
                    </>
                }
            >
                <p>{fraudUploadDialogCopy.overwritingWarning.body}</p>
            </Dialog>
            <Dialog
                show={fileUploadState === "INVALID_FILE_TYPE"}
                closeButton
                onClose={() => setFileUploadState("")}
                title={fraudUploadDialogCopy.invalidFileType.title}
                icon={<Icon alert />}
                footerButtons={
                    <>
                        <Button primary onClick={onClickFileUpload}>
                            {fraudUploadDialogCopy.uploadAgainButton}
                        </Button>
                        <Button onClick={() => setFileUploadState("")}>{fraudUploadDialogCopy.cancelButton}</Button>
                    </>
                }
            >
                <p className="fraud-advanced-upload-error-filename">{fileName}</p>
                <p>{fraudUploadDialogCopy.invalidFileType.body}</p>
            </Dialog>
            <Dialog
                show={fileUploadState === "BACKEND_FILE_ERROR"}
                closeButton
                onClose={() => setFileUploadState("")}
                title={fraudUploadDialogCopy.backendError.title}
                icon={<Icon alert />}
                footerButtons={
                    <>
                        <Button primary onClick={onClickFileUpload}>
                            {fraudUploadDialogCopy.uploadAgainButton}
                        </Button>
                        <Button onClick={() => setFileUploadState("")}>{fraudUploadDialogCopy.cancelButton}</Button>
                    </>
                }
            >
                <p className="fraud-advanced-upload-error-filename">{fileName}</p>
                <p>{fraudUploadDialogCopy.backendError.body}</p>
                <ul>
                    {errorMessages?.map((err, idx) => (
                        <li key={idx}>{err}</li>
                    ))}
                </ul>
            </Dialog>
            <Dialog
                show={fileUploadState === "BACKEND_GENERIC_ERROR"}
                closeButton
                onClose={() => setFileUploadState("")}
                title={fraudUploadDialogCopy.backendError.title}
                icon={<Icon alert />}
                footerButtons={
                    <>
                        <Button primary onClick={onClickFileUpload}>
                            {fraudUploadDialogCopy.uploadAgainButton}
                        </Button>
                        <Button onClick={() => setFileUploadState("")}>{fraudUploadDialogCopy.cancelButton}</Button>
                    </>
                }
            >
                <p className="fraud-advanced-upload-error-filename">{fileName}</p>
                {errorMessages?.map((err, idx) => (
                    <p key={idx}>{err}</p>
                ))}
            </Dialog>
            <Dialog
                show={fileUploadState === "SUCCESS"}
                closeButton
                onClose={onCloseSuccess}
                title={fraudUploadDialogCopy.success.title}
                icon={<Icon tick />}
            >
                <p>{fraudUploadDialogCopy.success.body}</p>
            </Dialog>
            {fileUploadState === "LOADING" && (
                <Dialog show title="Uploading">
                    <FilenameSubheading filenames={[fileName]} />
                    <LoadingIndicator />
                </Dialog>
            )}
        </>
    );
};

export default FraudUploadDialog;
