import React, { ChangeEventHandler, MouseEventHandler } from 'react';
import classNames from 'classnames';
import { uniqueId } from '@premier/utils/helpers';
import FormCheck, {FormCheckProps} from './FormCheck';

export type SelectorProps<TValue extends string | number> = Omit<FormCheckProps, 'fieldId'> & {  // Similar PropTypes in Selector, Checkbox and RadioInput
    id?: string,
    type: 'checkbox' | 'radio',
    name?: string,
    value?: TValue,
    disabled?: boolean,
    checked?: boolean,
    defaultChecked?: boolean,
    onChange: ChangeEventHandler<HTMLInputElement>,
    onClick?: MouseEventHandler<HTMLInputElement>,
};

/** Not to be used directly. A common component for Checkbox and RadioButton. */
const Selector = <TValue extends string | number>({
    label, mandatory, error, className, children,  // FormCheck props
    id, type, checked, ...otherProps
}: SelectorProps<TValue>) => {

    const [elementId] = React.useState(id || uniqueId('selector_'));

    return (
        <FormCheck
            fieldId={elementId}
            label={children || label}
            mandatory={mandatory}
            error={error}
            className={className}
        >
            <input
                type={type}
                className={classNames('form-check-input', {
                    'has-error': !!error
                })}
                id={elementId}
                checked={(checked == null && otherProps.defaultChecked == null && otherProps.onChange) ? false : checked}
                {...otherProps}
            />
        </FormCheck>
    );
};

export default Selector;
