import React, { ReactNode } from 'react';
import classNames from 'classnames';
import './ButtonContainer.scss';

type Props = {
    /** Removes the top margin of the button container */
    noTopMargin?: boolean,
    /** Add a divider on top */
    divider?: boolean,
    /** Distribute button widths to occupy 100% width in smaller screens (<= md) */
    justify?: boolean,
    justifyOverride?: boolean,
    className?: string,
    children?: ReactNode,
};

/** A div with top margin, to contain buttons
 * @param {boolean} justify Distribute button widths to occupy 100% width in smaller screens
*/
const ButtonContainer = ({
    noTopMargin, divider, justify, justifyOverride, className,  // styles
    children  //data
}: Props) => {
    return (
        <div
            className={classNames('button-container', {
                'no-top-margin': noTopMargin,
                'has-divider': divider,
                'justify': justify,
                'justify-override': justifyOverride,
            }, className)}
        >
            {children}
        </div>
    );
};

export default ButtonContainer;