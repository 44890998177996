export type Failure<T> = {
    ok: false,
    val: T
}

export type Ok<T> = {
    ok: true,
    val: T
}

export type Result<T, T2> = Ok<T> | Failure<T2>;

export function isObject(input: unknown): input is object {
    return typeof input === 'object' && input !== null;
}

export function hasProperty<X, Y extends PropertyKey>(obj: X, prop: Y): obj is X & Record<Y, unknown> {
    return isObject(obj) && obj.hasOwnProperty(prop)
}

/** Generates an incrementing number at every call. (Lodash _.uniqueId replacement) */
export const uniqueId = (function () {
    let num = 0;
    return function (prefix: string = '') {
        num += 1;
        return prefix + num;
    };
}());

export const isNonEmptyArray = (o : unknown) => (Array.isArray(o) && o.length);