import * as actions from './actionTypes';
import { noticeApi } from 'api';

export function getNotices() {
    return function(dispatch) {
        dispatch(loadNoticesRequested());

        return noticeApi.getNotices().then(notices => {
            dispatch(loadNoticesSuccess(notices));
        }).catch(err => {
            dispatch(loadNoticesFailed(err));
            throw err;
        });
    }
}


export function getNotice(id) {
    return function(dispatch) {
        dispatch(loadNoticeRequested());

        return noticeApi.getNotice(id).then(notice => {
            dispatch(loadNoticeSuccess(notice));
        }).catch(err => {
            dispatch(loadNoticeFailed(err));
            throw err;
        });
    }
}

export function loadNoticesRequested() {
    return {type: actions.LOAD_NOTICES_REQUESTED};
}
export function loadNoticesSuccess(notices, lastUpdated) {
    return {type: actions.LOAD_NOTICES_SUCCESS, notices, lastUpdated};
}
export function loadNoticesFailed(err) {
    return {type: actions.LOAD_NOTICES_FAILED, err};
}

export function loadNoticeRequested() {
    return {type: actions.LOAD_NOTICE_REQUESTED};
}
export function loadNoticeSuccess(notice) {
    return {type: actions.LOAD_NOTICE_SUCCESS, notice};
}
export function loadNoticeFailed(err) {
    return {type: actions.LOAD_NOTICE_FAILED, err};
}
