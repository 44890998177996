import React from 'react';
import PropTypes from 'prop-types';

import { Accordion, Divider } from '@premier/ui';
import { InputField, PhoneNumberField, RadioField, NumberRangeField, CurrencyRangeField, DropdownField } from '@premier/form';
import { withAdvancedFilter } from 'components/Common';
import labels from 'constants/labels';

const UserFilter = ({ 
    roleOptions, statusOptions,
}) => {

    return (
        <>
            <h1>Filter payment method</h1>

            <Accordion title='By permissions' enableDownMd>
                <div className='row'>
                    <div className='col-lg-8'>
                        <NumberRangeField name='dailyRefundCount' label={labels.dailyRefundCount} />
                    </div>
                    <div className='col-lg-8'>
                        <CurrencyRangeField name='dailyRefundAmount' label={labels.dailyRefundAmount} />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg-4'>
                        <DropdownField name='roleName' label={labels.userRole} options={roleOptions} />
                    </div>
                    <div className='col-lg-8'>
                        <RadioField name='status' label={labels.userStatus} inline options={statusOptions} />
                    </div>
                </div>
            </Accordion>
            <Divider />

            <Accordion title='By user details' enableDownMd>
                <div className='row'>
                    <div className='col-lg-4'>
                        <InputField name='fullName' label={labels.name} />
                    </div>
                    <div className='col-lg-4'>
                        <InputField name='userName' label={labels.loginUsername} />
                    </div>
                    <div className='col-lg-4'>
                        <InputField name='emailAddress' label={labels.email} />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg-8'>
                        <PhoneNumberField name='mobileNumber' label={labels.mobile} lessGapDownMd />
                    </div>
                    <div className='col-lg-8'>
                        <PhoneNumberField name='phoneNumber' label={labels.phone} lessGapDownMd />
                    </div>
                </div>
            </Accordion>
        </>
    );
};

UserFilter.propTypes = {
    roleOptions: PropTypes.array.isRequired,
    statusOptions: PropTypes.array.isRequired,

    // The rest of the props are in advancedFilterComponent.propTypes in withAdvancedFilter.js
};

export default withAdvancedFilter(UserFilter);