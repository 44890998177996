import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { RadioField, FormContext } from '@premier/form';
import { CustomerAddress, NewAddressInput } from 'components/StationeryShop';
import './DeliveryAddressSelector.scss';

const DeliveryAddressSelector = ({
    addresses //data
}) => {
    const { getValue } = useContext(FormContext);

    function getAddressOptions() {
        var options = addresses.map((addr, index) => ({
            value: 'del_'+index,
            label: <CustomerAddress address={addr} />
        }));

        return [{ value: 'same', label: 'Same as billing address' }]
                .concat(options)
                .concat([{ value: 'new', label: 'Add new address' }]);
    }

    return (
        <div className='delivery-address-selector'>
            <RadioField name='delRadio' label='Select a delivery address' options={getAddressOptions()} />
            {getValue('delRadio') === 'new' ? <NewAddressInput useWhite={addresses.length % 2 === 0} /> : null}
        </div>
    );
};

DeliveryAddressSelector.propTypes = {
    /** Delivery addresses (excluding billing address) */
    addresses: PropTypes.arrayOf(PropTypes.shape({
        customerName: PropTypes.string,
        street1: PropTypes.string,
        street2: PropTypes.string,
        suburb: PropTypes.string,
        state: PropTypes.string,
        postcode: PropTypes.string,
        phoneNumber: PropTypes.string,
    }))
};

export default DeliveryAddressSelector;