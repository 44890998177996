import { PageSection } from '@premier/ui';
import { PageHeader, ProductTooltip } from 'components/Common';

import IServicesForm from '../components/IServicesSettings/IServicesForm';
interface Props {}

export const iServicesSettingsPageCopy = {
    title: 'i-Services',
    explanation:
        'Set up rules for payments initiated by certain customer reference numbers (CRN)',
};

const IServicesSettingsPage: React.FC<Props> = () => {
    return (
        <PageSection>
            <PageHeader title={<>{iServicesSettingsPageCopy.title}<ProductTooltip productTooltipModule={"ISERVICES_SETTINGS"} /></>} />
            <p>{iServicesSettingsPageCopy.explanation}</p>

            <IServicesForm />
        </PageSection>
    );
};

export default IServicesSettingsPage;
