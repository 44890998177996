/* eslint eqeqeq: "off" */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';

import dateUtil from '@premier/utils/date';
import { PageSection, LoadingIndicator } from '@premier/ui';
import { PageHeader } from 'components/Common';

import * as noticeActions from 'components/Account/_actions/noticeActions';

import './NoticePage.scss';

const NoticePage = ({
    isLoading, //logic render
    notice, noticeId, //state values
    actions //API actions
}) => {

    var sanitizeHtml = require('sanitize-html');

    useEffect(() => {
        actions.getNotice(noticeId);
    }, [noticeId]);


    if (isLoading || !notice || !notice.message) {
        return <LoadingIndicator />;
    }

    var cleanNoticeMessage =
        sanitizeHtml(notice.message, {
            allowedTags: [  'h1', 'h2', 'br', 'a', 'b', 'i', 'u',
                            'table', 'thead', 'tbody', 'tr', 'th', 'td'],
            allowedAttributes: {
                a: [ 'href', 'target' ]
            }
    });

    var messageDd = <dd dangerouslySetInnerHTML={{ __html: cleanNoticeMessage }} />

    return (
        <div className='message-details-page'>
            <PageSection>
                <PageHeader backButton title='Message details' />

                <dl className='message-details'>
                    <dt>Subject:</dt>
                    <dd>{notice.subject}</dd>

                    <dt>Date received:</dt>
                    <dd>{dateUtil.convertToDateString(notice.updated)}</dd>

                    <dt>Message:</dt>
                    {messageDd}

                </dl>
            </PageSection>
        </div>
    );
};

function mapStateToProps(state, ownProps) {
    var notice =_.find(state.accounts.eNotices.notices, (notice) => (notice.noticeId == ownProps.match.params.id) );
    return {
        isLoading: state.accounts.eNotices.isLoading === undefined ? true : state.accounts.eNotices.isLoading,
        notice: notice,
        noticeId: ownProps.match.params.id
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(noticeActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(NoticePage);