import React from 'react';
import PropTypes from 'prop-types';

import BaseTransactionDetailCategory from './BaseTransactionDetailCategory';

const BankDetailsCategory = ({ transaction }) => {

    var bankAcctDetails = [
        { name: 'BSB number', value: transaction.paymentProcessedTxnModel.bankDetails.bsb },
        { name: 'Account number', value: transaction.paymentProcessedTxnModel.bankDetails.accountNumber }];

    return (
        <BaseTransactionDetailCategory title='Bank account details' items={bankAcctDetails} />
    );
}

BankDetailsCategory.propTypes = {
    transaction: PropTypes.object.isRequired
};

export default BankDetailsCategory;
