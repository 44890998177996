import BaseTransactionDetailCategory from './BaseTransactionDetailCategory';
import PropTypes from 'prop-types';

const LeastCostRoutingCategory = ({ transaction }) => {
    let LCRResult = [
        { name: 'Processed through eftpos', value: transaction.paymentSource.eftposRouted ? 'Yes' : 'No' }
    ];

    return <BaseTransactionDetailCategory title='Least Cost Routing' items={LCRResult} />;
}

LeastCostRoutingCategory.propTypes = {
    transaction: PropTypes.object.isRequired
};

export default LeastCostRoutingCategory;
