import { Icon, PageSection, ResponsiveTable } from '@premier/ui';
import { ApiError, InvoicePaymentModel } from '@premier/webapi-client';
import dateUtil from '@premier/utils/date';
import labels from 'platforms/base/constants/labels';
import currencyUtil from '@premier/utils/currency';
import { PlatformRoutesConfiguration } from 'components/Routing';

interface Props {
    apiData?: InvoicePaymentModel[];
    apiErrors?: ApiError;
    currencyCode: number;
}
export const invoiceTransactionHistoryCopy = {
    info: 'To refund the payment, please access the specific transaction via the receipt number.',
};

const InvoiceTransactionHistoryList: React.FC<Props> = ({ apiData, apiErrors, currencyCode }) => {
    const columns = [
        {
            label: labels.transactionType,
            getter: 'typeLabel',
        },
        {
            label: labels.transactionReceiptNumber,
            getter: 'receiptNumber',
        },
        {
            label: labels.transactionDate,
            getter: (k: InvoicePaymentModel) => dateUtil.formatToDateTimeString(k.processedDateMerchantTime!),
        },
        {
            label: labels.transactionAmount,
            getter: (k: InvoicePaymentModel) => currencyUtil.convertToDisplayString(k.amount!, currencyCode),
        },
    ];

    return (
        <PageSection header='Transaction history'>
            <p>
                <Icon info inline />
                {invoiceTransactionHistoryCopy.info}
            </p>
            <div className='invoice-transaction-history-list'>
                <ResponsiveTable
                    // @ts-ignore
                    data={apiData ?? []}
                    //@ts-ignore
                    getRowLink={e => PlatformRoutesConfiguration.transactionRoute.transactionDetails.generatePath(e.txnHistoryId)}
                    // @ts-ignore
                    columns={columns}
                    noDataText={'No transactions found'}
                />
            </div>
        </PageSection>
    );
};

export default InvoiceTransactionHistoryList;
