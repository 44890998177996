import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import Link from './Link';
import './MenuLink.scss';

type Props = {
    href: string,
    newWindow?: boolean,
    onClick?: () => void,
    className: string,
    children: ReactNode,
};

const MenuLink = ({
    href, newWindow, onClick, className, children,
}: Props) => {

    const location = useLocation();
    const isActive = location.pathname.toLowerCase().startsWith(href.toLowerCase());

    return (
        <Link to={href} newWindow={newWindow} className="menu-link" onClick={onClick}>
            <div className={classNames(className, {'active': isActive})}>
                {children}
            </div>
        </Link>
    );
}

export default MenuLink;