import httpRequest from './httpRequest';
import dateUtil from '@premier/utils/date';
import _ from 'lodash';

class SettlementApi {
    static getSettlementReport(childMerchantNumber, dateRange) {
        return new Promise((resolve, reject) => {
            var dto = {
                childMerchantNumber,
                fromDate: dateUtil.convertDateToApiValue(dateRange[0]),
                toDate: dateUtil.convertDateToApiValue(dateRange[1])
            };

            httpRequest.post(`/Settlement/GetSettlementReportData`, dto).then(response => {
                resolve(response);
            }).catch(err => {
                reject(err);
            })
        });
    };

    static getSettlementExportTypes() {
        return new Promise((resolve, reject) => {
            httpRequest.post(`/Settlement/GetSettlementExportTypes`).then(response => {
                resolve(response);
            }).catch(err => {
                reject(err);
            })
        });
    }

    static getSettlementExportColumns(reportCode) {
        return new Promise((resolve, reject) => {
            httpRequest.post(`/Settlement/GetSettlementExportColumns`, {reportCode}).then(response => {
                resolve(response);
            }).catch(err => {
                reject(err);
            })
        });
    }

    static getSettlementExportOptions(reportCode) {
        return new Promise((resolve, reject) => {
            httpRequest.post(`/Settlement/GetSettlementExportOptions`, {reportCode}).then(response => {
                resolve(response);
            }).catch(err => {
                reject(err);
            })
        });
    }

    static downloadSettlementExport(options) {
        var dto = {
            ...options,
            fromDate: dateUtil.convertDateToApiValue(_.get(options, 'dateRange.dates[0]')),
            toDate: dateUtil.convertDateToApiValue(_.get(options, 'dateRange.dates[1]')),
        };

        return new Promise((resolve, reject) => {
            httpRequest.post(`/Settlement/DownloadSettlementExport`, dto).then(response => {
                resolve(response);
            }).catch(err => {
                reject(err);
            })
        });
    }

    static downloadSettlementSummary(options) {
        var dto = {
            childMerchantNumber: options.childMerchantNumber,
            fromDate: dateUtil.convertDateToApiValue(_.get(options, 'dateRange.dates[0]')),
            toDate: dateUtil.convertDateToApiValue(_.get(options, 'dateRange.dates[1]')),
        };

        return new Promise((resolve, reject) => {
            httpRequest.post(`/Settlement/DownloadSettlementSummary`, dto).then(response => {
                resolve(response);
            }).catch(err => {
                reject(err);
            })
        });
    }

}

export default SettlementApi;