import { useState, useEffect } from "react";
import { PageSection, LoadingIndicator, IconText } from "@premier/ui";
import { useApiCall, APICallRequestState, PageHeader, ProductTooltip, PromotionCard } from "components/Common";
import PaymentPlanListPage from "components/Transactions/components/PaymentPlanListPage";
import { BillerTitleDropdown } from "components/Settings";
import { PlatformRoutesConfiguration } from "components/Routing";
import { Navigate, useParams } from "react-router-dom";
import PaymentPageUrlSection from "../../Settings/components/PaymentPageUrlSection";
import { PaymentPlanTermsAndConditionsRequiredNotification } from "components/Settings";

// @ts-ignore
import { defaultPageSize, defaultPageSizeOptions } from "constants/billpay";
import { Form, DropdownField } from "@premier/form";
import { paymentPlanApi, billerApi } from "api";
import { VerifyBiller } from "../../../util/verificationUtil";
import { getFirstActiveBiller } from "@premier/utils/billpay";
import { Biller } from "packages/webapi-client";

const ManagePaymentPlanPage = () => {
    const { billerCode } = useParams();
    const [resultsPerPage, setResultsPerPage] = useState(defaultPageSize);
    const [selectedBiller, setSelectedBiller] = useState<Biller>();
    const [shouldRedirect, setShouldRedirect] = useState(false);

    const [billers, billerRequestStatus] = useApiCall<Biller[]>(() => {
        return billerApi.getBillersV2(false);
    }, []);

    const [termsAndConditionsExist] = useApiCall(() => {
        if (billerCode)
            return paymentPlanApi.checkIfPaymentPlanTermsAndConditionsExists(billerCode);
    }, [billerCode]);

    useEffect(() => {
        if (billers?.length) {
            setSelectedBiller(billers.find(b => b.billerCode === billerCode));
        }
    }, [billers]);

    useEffect(() => {
        if (!billerCode && billers?.length) {
            setShouldRedirect(true);
        }
    }, [billerCode, billers]);

    useEffect(() => {
        if (billerCode) {
            VerifyBiller(billerCode, true);
        }
    }, [billerCode]);

    if (shouldRedirect) {
        const redirectParam = billers ? getFirstActiveBiller(billers)?.billerCode : "";
        return <Navigate to={PlatformRoutesConfiguration.transactionRoute?.landingPaymentPlanPageBillerCodeSelected.generatePath(redirectParam)!} />;
    }

    return (
        <PageSection noDivider>
            <PageHeader title={<>{"Payment plan"}<ProductTooltip productTooltipModule={"PAYMENT_PLAN_TRANSACTIONS"} /></>} />
            { billerRequestStatus === APICallRequestState.SUCCESSFUL && selectedBiller ? <>
                <BillerTitleDropdown
                    billerCode={billerCode}
                    billers={billers}
                    getUrl={PlatformRoutesConfiguration.transactionRoute?.landingPaymentPlanPageBillerCodeSelected.generatePath}
                    hideInactiveBillers
                />
                <PaymentPlanTermsAndConditionsRequiredNotification termsAndConditionsExist={termsAndConditionsExist} />
                <PromotionCard>
                    <IconText info>
                        Payments Plans where the first payment was processed over 18 months ago will not be displayed.
                    </IconText>
                </PromotionCard>
                {termsAndConditionsExist ?
                    <PaymentPageUrlSection
                        billerCode={billerCode}
                        billerFullName={selectedBiller.billerCodeWithName}
                        paymentFlowType="PaymentPlan"
                    /> : null}
                <Form>
                    <div className="col-md-4 filter-container results-filter-container inline-label">
                        <DropdownField id="pageSize" name="pageSize" label="Items per page" aria-labelledby="pageSize_label"
                            onChange={setResultsPerPage}
                            options={defaultPageSizeOptions.map((result: string) => ({ value: result, label: result }))}
                            defaultValue={defaultPageSize}
                        />
                    </div>
                    <PageSection>
                        <PaymentPlanListPage crnLabel={selectedBiller.acceptedCrn1?.label} billerCode={billerCode} resultsPerPage={resultsPerPage} />
                    </PageSection>
                </Form></> : <LoadingIndicator /> }
        </PageSection>
    );
};

export default ManagePaymentPlanPage;
