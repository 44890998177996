import React, {useState} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import currencyUtil from '@premier/utils/currency';
import dateUtil from '@premier/utils/date';
import textUtil from '@premier/utils/text';
import labels from 'constants/labels';

import { Modal, Icon, LoadingIndicator, DescriptionList, Button, SuccessModal } from '@premier/ui';
import { SendReceiptForm } from 'components/Transactions';

import './CaptureResultModal.scss';

const CaptureResultModal = ({
    showModal, //logic render
    closeModal,  //function
    receipt, biller //data
}) => {

    const [showReceiptSentModal, setShowReceiptSentModal] = useState(false);

    const currencyAlphaCode = receipt ? currencyUtil.getCurrency(receipt.currencyCode).alphaCode : '';
    const success = receipt && receipt.responseCode === '0';

    function closeThisModal(formProps) {
        if(formProps)
            formProps.resetForm();
        closeModal();
    }

    function closeReceiptSentModal() {
        setShowReceiptSentModal(false);
    }

    function handleReceiptSent() {
        closeModal();
        setShowReceiptSentModal(true);
    }


    return (<>
        <Modal show={showModal} onHide={closeThisModal} className='transaction-capture-modal'>
            <Modal.Header closeButton>
                <Modal.Title>
                    {success ? <Icon tick /> : <Icon alert />}
                    Capture {success ? 'successful' : 'unsuccessful'}
                </Modal.Title>
            </Modal.Header>

            {(!receipt)
                ? <LoadingIndicator />
                : (<>
                    <Modal.Body>
                        {!success && receipt.authorizationResult ? <p className='bank-error'>{receipt.authorizationResult}</p> : ''}

                        <DescriptionList spaceBetween hideBlankValue items={[
                            {name: 'Receipt number', value: textUtil.insertSpaces(receipt.receiptNumber)},
                            {name: 'Capture date', value: dateUtil.convertToDateTimeString(receipt.processedDate)},
                        ]} divider />

                        <DescriptionList spaceBetween hideBlankValue items={[
                            {name: labels.billerName, value: _.get(biller, 'billName')},
                            {name: labels.billerCode, value: receipt.billerCode},
                            {name: _.get(biller, 'acceptedCrn1.label') || labels.crn1, value: receipt.crn1},
                            {name: _.get(biller, 'acceptedCrn2.label') || labels.crn2, value: receipt.crn2},
                            {name: _.get(biller, 'acceptedCrn3.label') || labels.crn3, value: receipt.crn3},
                        ]} divider />

                        <DescriptionList spaceBetween hideBlankValue items={[
                            {name: `Capture amount (${currencyAlphaCode})`, value: currencyUtil.formatApiValue(receipt.totalAmount, receipt.currencyCode)},
                        ]} divider />

                        <DescriptionList spaceBetween hideBlankValue items={[
                            {name: 'Card number', value: receipt.cardDetails.cardNumber},
                        ]} divider />

                        <SendReceiptForm receipt={receipt} biller={biller} onSent={handleReceiptSent} divider />
                    </Modal.Body>

                    <Modal.Footer buttons>
                        <Button subtle onClick={() => { closeThisModal(); }}>Thanks, I'm done</Button>
                    </Modal.Footer>
                </>)
            }
        </Modal>

        <SuccessModal show={showReceiptSentModal} onClose={closeReceiptSentModal}>
            Sent successfully
        </SuccessModal>
    </>);
};

CaptureResultModal.propTypes = {
    // Required
    showModal: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,

    // Optional
    receipt: PropTypes.object,
    biller: PropTypes.object
};


export default CaptureResultModal;
