import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { cardNetworks } from '@premier/cards';
import { Form } from '@premier/form';
import { CardLocalisationEnum, CardSubTypeEnum } from 'constants/billpay';
import BillerSurchargeFields, {ApplyEnum} from './BillerSurchargeFields';


//#region ====== Functions (exported only for unit tests) ======

/** Return the number of unique rules/values */
function numUniqRules(rules){
    return _.uniqWith(rules.map(r => ({
        a: r.fixedSurchargeAmount,
        b: r.fixedSurchargePercent,
        c: r.surchargeLimits,
    })), _.isEqual).length;
}

/** True if all the rules have identical values */
export function rulesAreAllSame(rules, defaultValue = false) {
    if(!rules) return defaultValue;

    return numUniqRules(rules) === 1;
}

function appliedBy(rules, mapper) {
    if(!rules) return false;

    var combinations = _.uniqWith(rules.map(mapper), _.isEqual);

    for(const c of combinations) {
        var subset = _.filter(rules, c);
        if(!rulesAreAllSame(subset))
            return true;
    }

    return false;
}

/** True if a network (eg. Visa) has different rules to the others (eg. Amex) */
export function appliedByNetwork(rules) {
    return appliedBy(rules, (r) => ({cardLocalisation: r.cardLocalisation, cardSubType: r.cardSubType}));
}

/** True if a localisation (eg. Visa Domestic) has different rules to the other (eg. Visa International) */
export function appliedByLocalisation(rules) {
    return appliedBy(rules, (r) => ({cardTypeCode: r.cardTypeCode, cardSubType: r.cardSubType}));
}

/** True if a subType (eg. Visa Debit) has different rules to the other (eg. Visa Credit) */
export function appliedBySubType(rules) {
    return appliedBy(rules, (r) => ({cardTypeCode: r.cardTypeCode, cardLocalisation: r.cardLocalisation}));
}

/** True if any of the rules has fixed-amount or minimum surcharge */
export function hasAdvancedOptions(rules) {
    return _.some(rules, r => r.fixedSurchargeAmount || r.surchargeLimits.minimum);
}

//#endregion


/** The declaration of BillerSurchargeForm. This handles calculating the initialValues based on initialRules. */
const BillerSurchargeForm = ({paymentMethods, initialRules, cards, lcrEnabled}) => {

    function getInitialApplyCheckboxes() {
        var values = [];

        if(appliedByNetwork(initialRules))
            values.push(ApplyEnum.BY_NETWORK);
        if(appliedByLocalisation(initialRules))
            values.push(ApplyEnum.BY_LOCALISATION);
        if(appliedBySubType(initialRules))
            values.push(ApplyEnum.BY_SUBTYPE);
        
        return values;
    }

    return (
        <Form
            name='surchargeSettings'
            initialValues={{
                applyBy: getInitialApplyCheckboxes(),
                rules: initialRules,
                advanced: hasAdvancedOptions(initialRules),
            }}
        >
            <BillerSurchargeFields paymentMethods={paymentMethods} cards={cards} lcrEnabled={lcrEnabled} />
        </Form>
    );
};


BillerSurchargeForm.propTypes = {
    paymentMethods: PropTypes.arrayOf(PropTypes.shape({
        cardTypeCode: PropTypes.string,
        differentiateSurchargeByCardSubType: PropTypes.bool,
        differentiateSurchargeByCardRegion: PropTypes.bool,
    })),
    initialRules: PropTypes.arrayOf(PropTypes.shape({
        cardTypeCode: PropTypes.oneOf(cardNetworks.map(c => c.serverCode)),  // eg. 'MC' or 'VC'
        cardSubType: PropTypes.oneOf(Object.values(CardSubTypeEnum)),
        cardLocalisation: PropTypes.oneOf(Object.values(CardLocalisationEnum)),
        fixedSurchargeAmount: PropTypes.number,
        fixedSurchargePercent: PropTypes.number,
        surchargeLimits: PropTypes.shape({
            minimum: PropTypes.number,
            maximum: PropTypes.number,
        }),
    })),
    cards: PropTypes.arrayOf(
        PropTypes.oneOf(cardNetworks.map(c => c.serverCode))  // eg. 'MC' or 'VC'
    ),
    lcrEnabled: PropTypes.bool,
};

export default BillerSurchargeForm;