import { defaultPageSize } from 'constants/billpay';
import * as actions from './actionTypes';
import { schedulesApi } from 'api';
import { promiseRequestActions } from 'components/Common/requestActions';

//#region ----- Schedules -----

export let getSchedules = promiseRequestActions(actions.GET_SCHEDULES, (resultsPerPage = defaultPageSize, pageIndex = 0, filter = {}, sort) => schedulesApi.getSchedules(resultsPerPage, pageIndex, filter, sort)).send;
export let getSchedule = promiseRequestActions(actions.GET_SCHEDULE, (scheduleId) => schedulesApi.getSchedule(scheduleId)).send;
export let addSchedule = promiseRequestActions(actions.ADD_SCHEDULE, (scheduleDetails) => schedulesApi.addSchedule(scheduleDetails)).send;
export let updateSchedule = promiseRequestActions(actions.UPDATE_SCHEDULE, (scheduleDetails) => schedulesApi.updateSchedule(scheduleDetails)).send;
export let cancelSchedule = promiseRequestActions(actions.CANCEL_SCHEDULE, (scheduleId) => schedulesApi.cancelSchedule(scheduleId)).send;
export let suspendSchedule = promiseRequestActions(actions.SUSPEND_SCHEDULE, (scheduleId, suspendDetails) => schedulesApi.suspendSchedule(scheduleId, suspendDetails)).send;
export let resumeSchedule = promiseRequestActions(actions.RESUME_SCHEDULE, (scheduleId) => schedulesApi.resumeSchedule(scheduleId)).send;
export let getOverduePaymentsCount = promiseRequestActions(actions.GET_OVERDUE_PAYMENTS_COUNT, ({childMerchantNumber, customerId}) => schedulesApi.getSchedules(1, 1, { schedulePaymentStatus: "OVERDUE", childMerchantNumber, customerId }, { field: 'created', descending: true })).send;

export function saveSchedulesPageState(pageState) {
    return function(dispatch) {
        dispatch({type: actions.SAVE_SCHEDULES_PAGE_STATE, pageState});
    }
}
//#endregion

//#region ----- Schedule Filter -----

export let getScheduleStatuses = promiseRequestActions(actions.GET_SCHEDULE_STATUSES, () => schedulesApi.getScheduleStatuses()).send;
export let getScheduleFrequencies = promiseRequestActions(actions.GET_SCHEDULE_FREQUENCIES, () => schedulesApi.getScheduleFrequencies()).send;
export let getSchedulePaymentStatuses = promiseRequestActions(actions.GET_SCHEDULE_PAYMENT_STATUSES, () => schedulesApi.getSchedulePaymentStatuses()).send;

//#endregion