import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Navigate } from 'react-router-dom';
import sanitizeHtml from 'sanitize-html';

import * as accountActions from 'components/Account/_actions/accountActions';
import { PlatformRoutesConfiguration } from 'components/Routing';

import text from 'constants/text';

import { FormHeader, FloatingContainer } from 'components/Common';
import { Button, LoadingIndicator, ButtonContainer } from '@premier/ui';

import './UserAgreementPage.scss';

/**
 * UserAgreementPage will pull the set of terms of conditions onComponentDidMount and then present
 * the user with the option to Accept or Decline the terms and conditions.
 * @param {props} props
 */
const UserAgreementPage = ({ termsAndConditions, actions, isLoading, requiredUserAction }) => {
    const [redirect, setRedirect] = React.useState(null);

    useEffect(() => {
        if (!termsAndConditions) {
            actions.getTerms();
        }
    }, []);

    var cleanHtml =
        termsAndConditions &&
        sanitizeHtml(termsAndConditions.content, {
            allowedTags: sanitizeHtml.defaults.allowedTags.concat(['h1', 'h2']),
            allowedAttributes: {
                div: ['style']
            }
        });

    if (redirect) {
        return redirect;
    }

    if(requiredUserAction !== 'ACCEPT_TERMS_AND_CONDITIONS'){
        return <Navigate to={PlatformRoutesConfiguration.accountRoute?.home.generatePath()} />
    }

    return (
        <FloatingContainer>
            <FormHeader title={text.loginHeader} description='Usage policy agreement' />

            {isLoading ? (
                <LoadingIndicator></LoadingIndicator>
            ) : (
                <div className='terms-container' dangerouslySetInnerHTML={{ __html: cleanHtml }} />
            )}

            <ButtonContainer>
                <Button
                    primary
                    onClick={() => {
                        actions.acceptTerms(termsAndConditions.termsId).then(() => {
                            actions.getSessionLogin();
                        });
                    }}
                >
                    Accept
                </Button>
                <Button
                    onClick={() => {
                        setRedirect(<Navigate to={PlatformRoutesConfiguration.accountRoute.logoff.generatePath()} />);
                    }}
                >
                    Decline
                </Button>
            </ButtonContainer>
        </FloatingContainer>
    );
};

function mapStateToProps(state) {
    return {
        termsAndConditions: state.accounts.users.termsAndConditions,
        isLoading: state.accounts.users.isLoading,
        requiredUserAction: state.accounts.users.requiredUserAction
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(accountActions, dispatch)
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(UserAgreementPage);
