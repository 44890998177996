import FieldValidation from './fieldValidation';

/**
 * Determines whether or not an object is validated with a field validator or an
 * object validator.
 * @param {object} testProperty
 */
function isObject(testProperty) {
    return (
        typeof testProperty === 'object' &&
        typeof testProperty !== 'function' &&
        !(testProperty instanceof FieldValidation)
    );
}

/**
 * Determines whether or not an object is empty.
 * @param {object} obj The object to check
 */
function isEmpty(obj) {
    for (var key in obj) {
        if (obj.hasOwnProperty(key)) return false;
    }
    return true;
}

/**
 * Validates an object against an object's validation schema.  The schema shape should match the
 * object to validate's shape
 * @param {object} obj The object to validate
 * @param {object} validation The validation schema
 */
export function validate(obj, validation) {
    var results = validateObject(obj, validation);
    return results;
}

/**
 * Validates an object shallowly against a target validation schema.  Context values are provided
 * for cases where validations rely on the greater context of the object as a whole (parent values, etc)
 *
 * This method is recursive so it validates shallowly, then if it encounters nested properties that
 * are objects, it calls itself on the property, but keeps the context values the same
 * @param {object} obj The object that we want to be validating shallowly
 * @param {object} validation The matching validation object to match
 */
export function validateObject(obj, validation) {
    var results = {};

    //the logic in the next few lines simply merges the obj and validation
    //objects to a single set of keys so that the validator can validate against
    //empty object and vice versa.
    var keys = obj;
    if (isObject(obj) || !obj)
        keys = Object.assign({}, obj, validation);

    if (!keys)
        return null;

    Object.keys(keys).forEach(function(key) {
        if (validation[key]) {
            if (isObject(validation[key])) {
                var res = validateObject(obj && obj[key], validation[key]);
                if (res) {
                    results[key] = res;
                }
            } else {
                try {
                    validateField(obj && obj[key], validation[key], obj);
                } catch (e) {
                    results[key] = e.message;
                }
            }
        }
    });

    return isEmpty(results) ? null : results;
}

/**
 * Validates a single property against a validator.
 * @param {object | string} val The value to validate
 * @param {FieldValidation | function}  validator The validator to validate the value against
 * @param {object} contextValues The context of the object the value resides in.
 */
export function validateField(val, validator, contextValues) {
    if (validator) {
        if (validator instanceof FieldValidation) {
            try {
                validator.validate(val, contextValues);
            } catch (e) {
                throw e;
            }
        } else {
            var result = validator(val);

            if (!result) {
                throw new Error();
            }
        }
    }
}
