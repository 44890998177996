import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';

import branding from 'constants/branding';
import PreviewContainer from './PreviewContainer';
import './Layout.scss';

/** The common layout for most preview pages */
const Layout = ({
    style, // from API
    className, mobile, refreshTrigger, children,
}) => {

    const logoUrl = _.get(style, 'banner.logo.newFileUrl') || _.get(style, 'banner.logoUrl');
    const copyrightText = _.get(branding, 'preview.footer.copyrightText', '')
                            .replace('{CurrentYear}', new Date().getFullYear());
    function getStyle(path) {
        if (path === 'banner.logoPosition' && style.banner.logoPositionHorizontal) {
            return `${getStyle('banner.logoPositionHorizontal')} ${getStyle('banner.logoPositionVertical')}`;
        }
        return _.get(style, path) || undefined;
    }

    return (
        <PreviewContainer mobile={mobile} refreshTrigger={refreshTrigger}>
            <div className={classNames('preview-layout', className)}>
                <div className='returnBar' style={{
                    color: getStyle('returnBar.fontColour'),
                    backgroundColor: getStyle('returnBar.backColour'),
                    height: getStyle('returnBar.height'),
                    }}>Back to Site
                </div>
                <div className='header' style={{
                    color: getStyle('banner.fontColour'),
                    backgroundImage: logoUrl ? `url('${logoUrl}')` : undefined,
                    backgroundColor: getStyle('banner.backColour'),
                    backgroundPosition: getStyle('banner.logoPosition'),
                    height: getStyle('banner.height'),
                }}>
                </div>

                {children}

                <div className='footer'>
                    <div className='copyright-logo' style={{
                        backgroundImage: _.get(branding, 'preview.footer.logo.url') ? `url('${branding.preview.footer.logo.url}')` : undefined,
                        height: _.get(branding, 'preview.footer.logo.height') || undefined,
                    }}></div>
                    <div>{copyrightText}</div>
                </div>
            </div>
        </PreviewContainer>
    );
};

Layout.propTypes = {
    style: PropTypes.object.isRequired,
    className: PropTypes.string,

    // PreviewContainer props:
    /** A mobile view instead of the default desktop view */
    mobile: PropTypes.bool,
    /** Any change to this prop will redraw the preview. Useful when the preview is initially hidden and hence its size cannot be calculated. */
    refreshTrigger: PropTypes.any,
};

export default Layout;
