import httpRequest from "./httpRequest";
import { UtilitiesApiFactory } from "packages/webapi-client";
import { config } from "./util";

const utilitiesApi = UtilitiesApiFactory(config);

class UtilitiesApi {
    static getCountryList() {
        return new Promise((resolve, reject) => {
            httpRequest
                .get("/Utilities/GetCountries")
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    static getCurrencyList() {
        return new Promise((resolve, reject) => {
            httpRequest
                .get("/Utilities/GetCurrencies")
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    static getBillerActions(merchantNumber: string, billerCode: string) {
        return utilitiesApi.utilitiesGetBillerActions(merchantNumber, billerCode);
    }

    static getBillersDetails(merchantNumber: string) {
        return utilitiesApi.utilitiesGetBillers(merchantNumber);
    }

    static getProcessTransactionOrderTypes() {
        return new Promise((resolve, reject) => {
            httpRequest
                .get('/Utilities/GetProcessTransactionOrderTypes')
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    static getOrderTypes() {
        return new Promise((resolve, reject) => {
            httpRequest
                .get('/Utilities/GetOrderTypes')
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }
}

export default UtilitiesApi;
