import React from "react";
import _ from "lodash";

import { FilenameSubheading } from "components/Batch";
import { Dialog, Icon, Button } from "@premier/ui";

import errorUtil from "@premier/utils/error";
import { BatchFileValidationResult, Error } from "components/Batch/models/BatchFileResult";

type Props = {
    show: boolean;
    errors: Error[];
    filenames?: string[];
    filename: string;
    batchFileResult?: BatchFileValidationResult[] | null;
    onRetry: () => void;
    onCancel: () => void;
}

const UploadErrorDialog = ({
    show, //logic render
    errors, filenames, filename, batchFileResult, //data
    onRetry, onCancel //functions
}: Props) => {

    function genericErrorToString(error: Error) {
        if (error.code === "INVALID_BATCH_FILENAME" && error.parameter === "File.Filename")
            return "The filename you have submitted is invalid. Filename must only contain a-z, 0-9 or _ characters and cannot exceed 100 characters.";
        else if (error.code === "INVALID_BATCH_FILE_EXTENSION" && error.parameter === "File.Filename")
            return "The file type you have submitted is invalid. Only dat, txt and csv files can be submitted with or without zipping.";

        const paramLabels = {
            "File.Filename": "Filename",
        };

        return errorUtil.getMessage(error, paramLabels);
    }

    return (
        <Dialog show={show}
            icon={<Icon alert />}
            title="Error with file upload"
            footerButtons={<>
                <Button primary onClick={onRetry}>Upload again</Button>
                <Button onClick={onCancel}>Cancel</Button>
            </>}
        >

            <FilenameSubheading filenames={filenames ? filenames : [filename]} />

            {_.get(errors, "length") && (
                errors.map((err, idx) => <p key={idx}>{genericErrorToString(err)}</p>)
            )}

            {batchFileResult && batchFileResult.filter((r) => r.hasErrors).map((r, index) => (
                <React.Fragment key={index}>
                    <p>{r.filename}:</p>
                    <ul>
                        {r.errors.map((err, idx) => (<li key={idx}>{err}</li>))}
                    </ul>
                </React.Fragment>
            ))}

        </Dialog>
    );
};

export default UploadErrorDialog;
