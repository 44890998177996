import React, { useState, useEffect } from 'react';
import { Breakpoint } from 'react-socks';
import classNames from 'classnames';
import {ResponsiveTableRecordPartial} from './ResponsiveTableTypes';
import Table, { TableProps } from './Table';
import CardList, { CardListProps } from './CardList';
import './ResponsiveTable.scss';


export type ResponsiveTableProps<TRecord extends ResponsiveTableRecordPartial> =
    CardListProps<TRecord>
    & TableProps<TRecord>
    & {
        /** A string array with 2 elements: The maximum breakpoint for the card list to show, and the minimum breakpoint for the table to show (Default = ['md','lg']) */
        breakpoints?: ('xs' | 'sm' | 'md' | 'lg' | 'xl')[],
        /** The table items that are pre-selected */
        initialSelection?: TRecord[],
        className?: string,
    };


/**
 * Table with alternating row colour. The rows turn into a list of cards on smaller screens.
 * Optional features:
 * - Clickable column header for sorting
 * - Quick filters on top
 * - Row selection and batch actions
 */
const ResponsiveTable = <TRecord extends ResponsiveTableRecordPartial>({
    breakpoints, initialSelection, className,  // own props, not passed down
    renderCard,  // CardList only
    data, noDataText = 'No data to show.', ...otherProps
}: ResponsiveTableProps<TRecord>) => {

    //console.log('data = ',data);

    const [selectedItems, setSelectedItems] = useState<TRecord[]>([]);

    /** Clear checkboxes when data is changed (eg. paginated / filtered) */
    useEffect(() => {
        setSelectedItems(initialSelection || []);
    }, [data, initialSelection]);

    breakpoints = breakpoints || ['md','lg'];
    var listProps = breakpoints[0] === 'xs'
        ? { [breakpoints[0]]: true, 'only': true }
        : { [breakpoints[0]]: true, 'down': true };
    var tableProps = breakpoints[0] === 'xl'
        ? { [breakpoints[1]]: true, 'only': true }
        : { [breakpoints[1]]: true, 'up': true };


    function handleItemSelected(item: TRecord) {
        let updatedItems = [];

        if (otherProps.singleSelect) {
            updatedItems = [item];
        } else {
            updatedItems = selectedItems.includes(item)
                ? selectedItems.filter(x => x !== item)
                : selectedItems.concat([item]);
        }

        setSelectedItems(updatedItems);

        if (otherProps.onItemSelected)
            otherProps.onItemSelected(item, updatedItems);
    }


    return (
        <div className={classNames('responsive-table', className)}>
            <Breakpoint {...listProps}>
                <CardList
                    {...otherProps}
                    data={data}
                    renderCard={renderCard}
                    noDataText={noDataText}
                    selectedItems={selectedItems}
                    handleItemSelected={handleItemSelected}
                />
            </Breakpoint>

            <Breakpoint {...tableProps}>
                <Table
                    {...otherProps}
                    data={data}
                    noDataText={noDataText}
                    selectedItems={selectedItems}
                    setSelectedItems={setSelectedItems}
                    handleItemSelected={handleItemSelected}
                />
            </Breakpoint>
        </div>
    );
};

export default ResponsiveTable;