import { useState, useMemo, MouseEvent } from "react";
import { connect } from "react-redux";
import currencyUtil from "@premier/utils/currency";
import dateUtil from "@premier/utils/date";
import textUtil from "@premier/utils/text";
import windowUtil from "@premier/utils/window";
import * as billpayUtil from "@premier/utils/billpay";
//@ts-ignore
import labels from "constants/labels";
import { DescriptionList, Button, Modal, Icon } from "@premier/ui";
import { SendReceiptForm } from "components/Transactions";
import { Biller, PaymentProcessedTxnModel } from "packages/webapi-client";
import { RootState } from "store/store";
import { Phone } from "models";

import "./NewPaymentResultModal.scss";

type Props = {
    onClose: (reset?: boolean) => void;
    receipt: PaymentProcessedTxnModel;
    billers?: Biller[];
    customerEmail?: string;
    customerPhoneNumber?:Phone;
}

const NewPaymentResultModal = ({
    onClose, //function
    receipt, billers, customerEmail, customerPhoneNumber //data
}: Props) => {

    const [showCopied, setShowCopied] = useState(false);

    const biller = useMemo(() => {
        return billers?.find(b => b.billerCode === receipt?.billerCode);
    }, [receipt, billers]);

    const success = receipt.responseCode === "0";

    function handleReceiptClose(e: MouseEvent, reset?: boolean) {
        if (onClose)
            onClose(reset);
    }

    function showCopiedTextMessage() {
        if (receipt.receiptNumber) {
            windowUtil.copyTextToClipboard(receipt.receiptNumber);
            setShowCopied(true);
            setTimeout(() => {
                setShowCopied(false);
            }, 3000);
        }
    }

    function getBillerDetailsItems() {
        const detailsItems = [];
        const biller = billers?.find(b => b.billerCode === receipt?.billerCode);

        if (receipt.billerCode) {
            detailsItems.push(
                { name: labels.billerName, value: biller?.billName },
                { name: labels.billerCode, value: receipt.billerCode }
            );
        }
        if (receipt.crn1) {
            detailsItems.push({ name: biller?.acceptedCrn1?.label || biller?.acceptedCrn1?.crnName || labels.crn1, value: receipt.crn1 });
        }
        if (receipt.crn2) {
            detailsItems.push({ name: biller?.acceptedCrn2?.label || biller?.acceptedCrn2?.crnName || labels.crn2, value: receipt.crn2 });
        }
        if (receipt.crn3) {
            detailsItems.push({ name: biller?.acceptedCrn3?.label || biller?.acceptedCrn3?.crnName || labels.crn3, value: receipt.crn3 });
        }
        return detailsItems;
    }

    const receiptNumber = (
        showCopied
            ? <> <Icon tick inline /> Copied</>
            : <Button
                link
                label = {textUtil.insertSpaces(receipt.receiptNumber)}
                title ="Click to Copy"
                id = "receiptNumber"
                onClick = {() => { showCopiedTextMessage(); }} />
    );

    const getRequestActionDescription = (requestActionDescription?: string) => {
        if (!requestActionDescription) {
            return "Payment";
        }

        switch (requestActionDescription.toLowerCase()) {
            case "preauth":
                return "Pre-auth";
            default:
                return "Payment";
        }
    };


    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>
                    {success ? <Icon tick /> : <Icon alert />}
                    {getRequestActionDescription(receipt.txnFinancialType)} {success ? "successful" : "unsuccessful"}
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {receipt && (<>

                    {!success && receipt.authorizationResult && <p className="bank-error">{receipt.authorizationResult}</p>}

                    <DescriptionList spaceBetween hideBlankValue items={[
                        { name: "Receipt number", value: receiptNumber },
                        { name: "Payment date", value: receipt.processedDate && dateUtil.convertToDateTimeString(receipt.processedDate) },
                    ]} divider />

                    <DescriptionList spaceBetween hideBlankValue items={getBillerDetailsItems()} divider />

                    <DescriptionList spaceBetween hideBlankValue items={[
                        { name: "Amount", value: receipt.originalAmount && currencyUtil.convertToDisplayString(receipt.originalAmount, receipt.currencyCode) },
                        { name: "Surcharge amount", value: receipt.surchargeAmount && currencyUtil.convertToDisplayString(receipt.surchargeAmount, receipt.currencyCode) },
                        { name: "Total amount", value: receipt.totalAmount && currencyUtil.convertToDisplayString(receipt.totalAmount, receipt.currencyCode) },
                    ]} divider />

                    {receipt.cardDetails && <DescriptionList spaceBetween hideBlankValue items={[
                        { name: "Card number", value: receipt.cardDetails.cardNumber },
                        { name: "Expiry", value: receipt.cardDetails.expiryDate && billpayUtil.formatExpiry(receipt.cardDetails.expiryDate) },
                    ]} divider />}

                    {receipt.bankDetails && <DescriptionList spaceBetween hideBlankValue items={[
                        { name: "BSB number", value: receipt.bankDetails.bsb },
                        { name: "Account number", value: receipt.bankDetails.accountNumber },
                    ]} divider />}

                    <SendReceiptForm receipt={receipt} biller={biller} customerEmail={customerEmail} customerPhoneNumber={customerPhoneNumber} divider />
                </>)}
            </Modal.Body>

            <Modal.Footer buttons>
                <Button primary onClick={(e) => handleReceiptClose(e, true)}>Start a new payment</Button>
                {!success &&<Button onClick={handleReceiptClose}>Check details</Button>}
            </Modal.Footer>
        </>
    );
};

function mapStateToProps(state: RootState) {
    return {
        billers: state.accounts.users.activeBillers,
    };
}

export default connect(mapStateToProps, null)(NewPaymentResultModal);
