import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { NewTokenOptions, PaymentTypeKey } from 'constants/billpay';
import { IconText, Button, ButtonContainer } from '@premier/ui';
import { LinkedTokensList, AddCustomerTokenModal, AddPaymentMethodDialog, EditTokenModals } from 'components/DataVault';

import * as tokenActions from 'components/DataVault/_actions/tokenActions';
import './CustomerPaymentMethods.scss';

const CustomerPaymentMethods = ({
    tokens, //data
    onTokensUpdated, onRedirectToLink, //functions
    tokenActions //API actions
}) => {

    const defaultPaymentMethodsSort = { field: 'expiryDate', descending: true };

    const [showAddPaymentMethodDialog, setShowAddPaymentMethodDialog] = useState(false);
    const [showAddTokenModal, setShowAddTokenModal] = useState(false);
    const [showEditTokenModal, setShowEditTokenModal] = useState(false);
    const [paymentMethodsSort, setPaymentMethodsSort] = useState(defaultPaymentMethodsSort);
    const [sortedTokens, setSortedTokens] = useState([]);

    var customerHasTokens = tokens && tokens.length > 0;

    useEffect(() => {
        if (paymentMethodsSort)
            setSortedTokens(_.orderBy(tokens, [paymentMethodsSort.field], [paymentMethodsSort.descending ? 'desc' : 'asc']));
        else
            setSortedTokens(tokens);
    }, [tokens, paymentMethodsSort]);

    function handlePaymentMethodSelection(values) {
        setShowAddPaymentMethodDialog(false);

        values.newTokenOption === NewTokenOptions.ADD_NEW ? setShowAddTokenModal(true) : onRedirectToLink();
    }

    function handlePaymentMethodSort(field, descending) {
        setPaymentMethodsSort({ field, descending });
    }

    function handleTokenClick(data) {
        tokenActions.getToken(data.dataVaultId).then(() => {
            setShowEditTokenModal(true);
        });
    }

    function handleAddTokenClose(updated) {
        setShowAddTokenModal(false);

        if (updated)
            onTokensUpdated();
        tokenActions.clearTokenCache();
    }

    function handleEditTokenClose(updated) {
        setShowEditTokenModal(false);

        if (updated)
            onTokensUpdated();
        tokenActions.clearTokenCache();
    }

    return (
        <div className="customer-payment-methods-section">
            {customerHasTokens && (<>
                <h3>Payment methods</h3>
                <p>Store and manage payment methods in a secure environment for future and recurring payments</p>

                <LinkedTokensList
                    sortable
                    sort={paymentMethodsSort}
                    onSort={handlePaymentMethodSort}
                    tokens={sortedTokens}
                    onLinkClick={handleTokenClick}
                />

                <Button onClick={() => setShowAddPaymentMethodDialog(true)}>New payment method</Button>
            </>)}

            {!customerHasTokens && (<>
                <h3><IconText info>Add payment method</IconText></h3>
                <p>A payment method represents a card or bank account. Save a payment method in customer profile to help accelerate the payment process.</p>
                <ButtonContainer noTopMargin>
                    <Button primary onClick={() => setShowAddTokenModal(true)}>New payment method</Button>
                    <Button onClick={() => onRedirectToLink()}>Link existing method</Button>
                </ButtonContainer>
            </>)}



            <AddPaymentMethodDialog show={showAddPaymentMethodDialog}
                onSubmit={handlePaymentMethodSelection}
                onClose={() => setShowAddPaymentMethodDialog(false)}
            />
            <AddCustomerTokenModal show={showAddTokenModal} onCancel={handleAddTokenClose} />
            <EditTokenModals show={showEditTokenModal} allowUnlink onClose={handleEditTokenClose} />
        </div>
    );
}


CustomerPaymentMethods.propTypes = {
    /** Array of tokens, unsorted */
    tokens: PropTypes.arrayOf(PropTypes.shape({
        dataVaultId: PropTypes.number.isRequired,
        token: PropTypes.string.isRequired,
        expiryDate: PropTypes.shape({
            month: PropTypes.number,
            year: PropTypes.number
        }),
        accountName: PropTypes.string,
        maskedCardNumber: PropTypes.string,
        deBsbNumber: PropTypes.string,
        deAccountNumber: PropTypes.string,
        crn1: PropTypes.string.isRequired,
        crn2: PropTypes.string,
        crn3: PropTypes.string,
        customerV2Id: PropTypes.number,
        customerUniqueId: PropTypes.string.isRequired,
        type: PropTypes.oneOf([PaymentTypeKey.CARD, PaymentTypeKey.BANK_ACCOUNT]).isRequired,
        cardTypeCode: PropTypes.string.isRequired
    })),

    /** function called once adding a new token has been successful */
    onTokensUpdated: PropTypes.func.isRequired,

    /** function called to trigger redirect to Link Token to Customer page */
    onRedirectToLink: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
    return {
        tokenActions: bindActionCreators(tokenActions, dispatch)
    };
}

export default connect(null, mapDispatchToProps)(CustomerPaymentMethods);