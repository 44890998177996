import React, { useEffect, useState } from 'react';
import { CountryTax, InvoiceConfigModel } from 'packages/webapi-client/api';
import { Item } from '@premier/ui/src/ReorderableList';
import { useNavigate } from 'react-router-dom';
import { PageHeader } from 'components/Common';
import _ from 'lodash';
import {
    Button,
    ButtonContainer,
    PaddedContainer,
    PageSection,
    Switch,
} from '@premier/ui';

import InvoiceSettingsItemsForm from 'components/Settings/components/InvoiceSettings/InvoiceSettingsItemsForm';
import InvoiceSettingsHeading from 'components/Settings/components/InvoiceSettings/InvoiceSettingsHeading';
import NoBillerCodeErrorPage from 'components/Common/pages/NoBillerCodeErrorPage';
import InvoiceDialog from 'components/Settings/components/InvoiceSettings/InvoiceDialog';
import { useInvoiceSettings } from 'components/Settings/_hooks/useInvoicesSettings';
import { InvoiceSettingsState } from '../InvoiceSettingsPage';
import 'components/Settings/components/InvoiceSettings/InvoiceSettingsItemsForm.scss';

export const InvoiceSettingsItemsPageCopy = {
    title: 'Settings for invoice items',
    tax: {
        title: 'Tax rate options',
        tooltipId: 'invoice_items',
        tooltip:
            'The applicable tax rates can be set up for your organisation. These tax rates will appear in a drop down when adding items to the invoice. For example, GST 10%, VAT 20%.',
        input: {
            newRateName: 'New rate name',
            newRateNamePlaceholder: 'Enter name...',
            rate: 'Rate',
            ratePlaceholder: 'Enter rate...',
        },
        list: {
            title: 'Existing tax rate options',
            description:
                'To remove or reorder options, please select an option to edit.',
        },
    },
    otherSettings: {
        title: 'Other invoice items settings',
        tooltipId: 'other_invoice_items',
        tooltip:
            'These additional settings are used to determine the rules for the unit price entered and whether to display an item with a zero amount.',
        toggle: {
            unitPrice: 'Unit price is inclusive of tax.',
            displayZeroAmount:
                'Items with zero amount are not displayed on the invoice sent.',
        },
    },
    error: {
        emptyTaxName: 'New rate name is required.',
        emptyRate: 'Rate is required.',
        emptyList: 'At least one option is required.',
        duplicateTaxName: 'This new rate name has already been used.',
        taxNameCharacterLimit: 'New rate name exceeds 4 characters.',
        rateCharacterLimit: 'Rate exceeds 10 digits.',
        submit: 'There are errors above. Please fix them before continuing.',
    },
    addButton: 'Add rate',
    saveButton: 'Save',
    cancelButton: 'Cancel',
};

export const defaultValues = {
    tax: undefined,
    errorMsg: '',
    errorState: false,
    maxNameLength: 4,
    maxRateLength: 10,
    modalState: '' as InvoiceSettingsState,
};

interface toggleItems {
    unitPriceInclusiveOfTax: boolean;
    displayItemsWithZeroAmount: boolean;
}

interface Props {}

const InvoiceSettingsItemsPage: React.FC<Props> = () => {
    // invoice hook ---
    const { hasLoaded, settings, updateInvoiceSettings } = useInvoiceSettings();

    const convertToItem = (settings?: CountryTax[]) => {
        let itemsList: Item[] = [];

        if (settings) {
            settings.forEach((e) => {
                let percentage = (e.taxRate! * 100).toFixed(3);
                percentage = parseFloat(percentage).toPrecision();

                itemsList.push({
                    //@ts-ignore
                    key: e.taxName,
                    label: `${e.taxName} ${percentage}%`,
                });
            });
        }
        return itemsList;
    };

    const convertToCountryItem = (settings?: CountryTax[]) => {
        let itemsList: CountryTax[] = [];
        if (settings) {
            settings.forEach((e) => {
                itemsList.push(e);
            });
        }
        return itemsList;
    };

    const convertToToggleItem = (settings?: InvoiceConfigModel) => {
        const toggleSettings: toggleItems = {
            displayItemsWithZeroAmount: false,
            unitPriceInclusiveOfTax: false,
        };

        if (settings) {
            toggleSettings.displayItemsWithZeroAmount = settings.displayItemsWithZeroAmount!;
            toggleSettings.unitPriceInclusiveOfTax = settings.unitPriceInclusiveOfTax!;
        }
        return toggleSettings;
    };

    // states ---
    const [taxName, setTaxName] = useState<string>();
    const [taxRate, setTaxRate] = useState<string>();
    const [countryTaxItems, setCountryTaxItems] = useState<CountryTax[]>(
        convertToCountryItem(settings?.taxRates)
    );
    const [items, setItems] = useState<Item[]>(
        convertToItem(settings?.taxRates)
    );

    const [taxNameError, setTaxNameError] = useState<string>(
        defaultValues.errorMsg
    );
    const [taxRateError, setTaxRateError] = useState<string>(
        defaultValues.errorMsg
    );
    const [toggleSettings, setToggleSettings] = useState<toggleItems>(
        convertToToggleItem(settings)
    );

    const [listError, setListError] = useState<string>(defaultValues.errorMsg);
    const [modalState, setModalState] = useState<InvoiceSettingsState>(
        defaultValues.modalState
    );

    useEffect(() => {
        if (settings?.taxRates) {
            setCountryTaxItems(convertToCountryItem(settings.taxRates));
            setItems(convertToItem(settings.taxRates));
        }
        setToggleSettings(convertToToggleItem(settings));
    }, [settings]);

    // input ---
    const addNewTaxRate = () => {
        setTaxNameError(defaultValues.errorMsg);
        setTaxRateError(defaultValues.errorMsg);

        if (validateTaxRate()) {
            // add to item list
            const itemList = items;
            itemList.push({
                key: taxName!,
                label: `${taxName} ${taxRate}%`,
            });
            setItems(itemList);

            // add to countryTax list
            const taxItemList = countryTaxItems;
            taxItemList?.push({
                taxName: taxName,
                taxRate: parseFloat(taxRate!) / 100,
            });
            setCountryTaxItems(taxItemList);

            resetInput();
        }
    };

    const validateTaxRate = () => {
        let hasNoError = true;

        // tax name
        if (!taxName) {
            // empty input
            setTaxNameError(InvoiceSettingsItemsPageCopy.error.emptyTaxName);
            hasNoError = false;
        } else if (taxName!.length > defaultValues.maxNameLength) {
            // character limit
            setTaxNameError(
                InvoiceSettingsItemsPageCopy.error.taxNameCharacterLimit
            );
            hasNoError = false;
        } else if (
            items.find(
                (e) => e.key.toString().toUpperCase() === taxName?.toUpperCase()
            )
        ) {
            // duplicated value
            setTaxNameError(
                InvoiceSettingsItemsPageCopy.error.duplicateTaxName
            );
            hasNoError = false;
        }

        // tax rate
        if (!taxRate) {
            // empty input
            setTaxRateError(InvoiceSettingsItemsPageCopy.error.emptyRate);
            hasNoError = false;
        } else if (taxRate.length > defaultValues.maxRateLength) {
            // character limit
            setTaxRateError(
                InvoiceSettingsItemsPageCopy.error.rateCharacterLimit
            );
            hasNoError = false;
        }

        return hasNoError;
    };

    const handleOnChangeList = (newItems: Item[]) => {
        // match countryTax list with item list returned from reorderableList
        const newCountryTaxItems = newItems.map(
            (item) => countryTaxItems.find((e) => e.taxName === item.key)!
        );
        setCountryTaxItems(newCountryTaxItems);
        setItems(newItems);
    };

    const resetInput = () => {
        setTaxName(defaultValues.tax);
        setTaxRate(defaultValues.tax);

        setListError(defaultValues.errorMsg);
        setTaxNameError(defaultValues.errorMsg);
        setTaxRateError(defaultValues.errorMsg);
    };

    // toggle ---
    const handleToggleSettings = (key: keyof toggleItems) => {
        const newToggleSettings = {
            ...toggleSettings,
            [key]: !toggleSettings[key],
        };
        setToggleSettings(newToggleSettings);
    };

    // modal ---
    const navigate = useNavigate();

    const handleExit = () => {
        navigate(-1);
    };

    const handleSave = () => {
        if (items.length <= 0) {
            setListError(InvoiceSettingsItemsPageCopy.error.emptyList);
            return;
        }
        // submit
        const newSettings = {
            ...settings,
           taxRates: countryTaxItems,
           unitPriceInclusiveOfTax: toggleSettings.unitPriceInclusiveOfTax,
           displayItemsWithZeroAmount: toggleSettings.displayItemsWithZeroAmount
        }

        updateInvoiceSettings(newSettings);
        setModalState('SUCCESS');
    };

    const handleBack = () => {
        // no changes made
        if (
            _.isEqual(items, convertToItem(settings.taxRates)) &&
            checkToggleItems()
        ) {
            handleExit();
            return;
        }
        // unsaved changes
        setModalState('UNSAVED');
    };

    const checkToggleItems = () => {
        return (
            settings.unitPriceInclusiveOfTax ===
                toggleSettings.unitPriceInclusiveOfTax &&
            settings.displayItemsWithZeroAmount ===
                toggleSettings.displayItemsWithZeroAmount
        );
    };

    // no billers linked ---
    if (!settings?.hasInvoiceBiller && hasLoaded) {
        return <NoBillerCodeErrorPage />;
    }

    return (
        <PageSection>
            <PageHeader
                title={InvoiceSettingsItemsPageCopy.title}
                backButton={{...{ onClick: handleBack }}}
            />
            <PaddedContainer lessMargin>
                <PageSection noPaddingBottom>
                    <InvoiceSettingsHeading
                        title={InvoiceSettingsItemsPageCopy.tax.title}
                        tooltip={InvoiceSettingsItemsPageCopy.tax.tooltip}
                        tooltipId={InvoiceSettingsItemsPageCopy.tax.tooltipId}
                    />
                    <InvoiceSettingsItemsForm
                        items={items}
                        taxName={taxName}
                        taxRate={taxRate}
                        taxNameError={taxNameError}
                        taxRateError={taxRateError}
                        listError={listError}
                        onAdd={addNewTaxRate}
                        onTaxNameInputChange={setTaxName}
                        onTaxRateChange={setTaxRate}
                        onListChange={handleOnChangeList}
                    />
                </PageSection>

                <PageSection>
                    <InvoiceSettingsHeading
                        title={InvoiceSettingsItemsPageCopy.otherSettings.title}
                        tooltip={
                            InvoiceSettingsItemsPageCopy.otherSettings.tooltip
                        }
                        tooltipId={
                            InvoiceSettingsItemsPageCopy.otherSettings.tooltipId
                        }
                    />

                    <div className='invoice-toggle-container'>
                        <Switch
                            id={'invoice_toggle_unit_price_inclusive_of_tax'}
                            className={'invoice-toggle'}
                            disabled={!hasLoaded}
                            on={toggleSettings.unitPriceInclusiveOfTax}
                            onChange={() => handleToggleSettings('unitPriceInclusiveOfTax')
                            }
                        />

                        <div className='invoice-toggle-title'>
                            { InvoiceSettingsItemsPageCopy.otherSettings.toggle.unitPrice }
                        </div>
                    </div>

                    <div className='invoice-toggle-container'>
                        <Switch
                            className={'invoice-toggle'}
                            disabled={!hasLoaded}
                            on={toggleSettings.displayItemsWithZeroAmount}
                            onChange={() => handleToggleSettings('displayItemsWithZeroAmount')
                            }
                        />

                        <div className='invoice-toggle-title'>
                            { InvoiceSettingsItemsPageCopy.otherSettings.toggle.displayZeroAmount }
                        </div>
                    </div>
                </PageSection>
            </PaddedContainer>

            <InvoiceDialog
                modalState={modalState}
                pageName={'invoice items'}
                onClose={() => setModalState(defaultValues.modalState)}
                onExit={handleExit}
            />

            <ButtonContainer>
                <Button primary onClick={handleSave}>
                    {InvoiceSettingsItemsPageCopy.saveButton}
                </Button>
                <Button onClick={handleBack}>
                    {InvoiceSettingsItemsPageCopy.cancelButton}
                </Button>
            </ButtonContainer>

            {!!listError && (
                <p className='invoices-error-message'>
                    {InvoiceSettingsItemsPageCopy.error.submit}
                </p>
            )}
        </PageSection>
    );
};

export default InvoiceSettingsItemsPage;
