import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';

import { Button, DescriptionList, PaddedContainer, SuccessModal } from '@premier/ui';
import { KlarnaCredentialsDialog } from '..';

import * as merchantActions from 'components/Settings/_actions/merchantActions';

/**
 * A Klarna settings PaddedContainer (only used when Klarna is enabled for the merchant)
 */
const KlarnaSettingsSections = ({
    actions, klarnaConfigState,   // API / Redux
}) => {

    const klarnaConfig = klarnaConfigState.data;

    const [showKlarnaDialog, setShowKlarnaDialog] = useState(false);
    const [showKlarnaSuccessModal, setShowKlarnaSuccessModal] = useState(false);

    useEffect(() => {
        actions.getKlarnaConfig();
    }, []);

    function handleKlarnaDialogClose(wasSaved) {
        setShowKlarnaDialog(false);

        if(wasSaved) {
            setShowKlarnaSuccessModal(true);
            actions.getKlarnaConfig();   // Refresh Klarna credentials
        }
    }

    // ====== Render ======

    return (<>
        <PaddedContainer lessMargin noDivider
            title='Klarna settings'
            button={
                <Button onClick={() => setShowKlarnaDialog(true)}>Edit</Button>
            }
        >
            { _.get(klarnaConfig, 'username') && (
                <DescriptionList>
                    <dt>Klarna Username</dt>
                    <dd>{klarnaConfig.username}</dd>

                    <dt>Klarna Password</dt>
                    <dd>**********</dd>
                </DescriptionList>
            )}
        </PaddedContainer>



        <KlarnaCredentialsDialog
            show={showKlarnaDialog}
            initialUsername={_.get(klarnaConfig, 'username')}
            onClose={handleKlarnaDialogClose}
        />

        <SuccessModal
            title='Your Klarna settings were saved successfully'
            show={showKlarnaSuccessModal}
            onClose={() => setShowKlarnaSuccessModal(false)}
        />
    </>);
};

function mapStateToProps(state, ownProps) {
    return {
        // new way
        klarnaConfigState: state.settings.paymentSettings.klarnaConfig,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(merchantActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(KlarnaSettingsSections);