import httpRequest from './httpRequest';
import currencyUtil from '@premier/utils/currency';
import * as billpayUtil from '@premier/utils/billpay';
import mapErrors from './mapErrors';
import _ from 'lodash';

//#region functions
function getStatus(status) {
    switch (status) {
        case "active":
            return true;
        case "inactive":
            return false;
        default:
            return null;
    }
}

function toUserDto(userData) {
    var model = {
        childMerchantNumber: userData.merchantNumber,
        isActive: userData.status === 1 ? true : false,
        firstName: userData.firstName,
        lastName: userData.lastName,
        username: userData.username,
        emailAddress: userData.emailAddress,
        mobileNumber: userData.mobile ? billpayUtil.formatPhoneObjectToApiString(userData.mobile) : '',
        phoneNumber: userData.phone ? billpayUtil.formatPhoneObjectToApiString(userData.phone) : '',
        roleId: userData.role,
        dailyRefundLimit: userData.refundLimit,
        dailyRefundAmountLimit: currencyUtil.convertToApiValue(userData.refundAmount),
        currentPassword: userData.currentPassword
    }

    if (userData.userId) { //used for edit API call
        return {
            userId: userData.userId, //only difference in the add / edit API calls
            ...model
        }
    }

    return model;
}
//#endregion


//#region Error Mapping

//Used in addUser, editUser calls
function mapUserErrorsFromDto(parameter) {
    switch (parameter) {
        case 'phoneNumber':
            return 'phone';
        case 'mobileNumber':
            return 'mobile';
        case 'dailyRefundLimit':
            return 'refundLimit'
        case 'dailyRefundAmountLimit':
            return 'refundAmount'
        case 'roleId':
            return 'role'
        case 'isActive':
            return 'status'
        case 'childMerchantNumber':
            return 'merchantNumber'
        default:
            return parameter;
    }
}
//#endregion


//#region API calls
class UserApi {
    static getUser(userId) {
        var request = {
            userId: userId
        };
        return new Promise((resolve, reject) => {
            httpRequest
                .post('/Users/GetUser', request)
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    reject(err);
                })
        });
    }

    static getUsers(resultsPerPage, pageNumber, filter, sort) {
        function filterToDto(filter) {
            let dto = {
                ...filter,
                dailyRefundCountMin: filter.dailyRefundCount.min,
                dailyRefundCountMax: filter.dailyRefundCount.max,
                dailyRefundAmountMin: currencyUtil.convertToApiValue(filter.dailyRefundAmount.min),
                dailyRefundAmountMax: currencyUtil.convertToApiValue(filter.dailyRefundAmount.max),
                status: getStatus(filter.status),
                mobileNumber: billpayUtil.formatPhoneObjectToApiString(filter.mobileNumber),
                phoneNumber: billpayUtil.formatPhoneObjectToApiString(filter.phoneNumber),
            };

            delete dto.dailyRefundCount;
            delete dto.dailyRefundAmount;

            return _.pickBy(dto, x => x != null && x !== '');
        }

        return new Promise((resolve, reject) => {
            var dto = {
                request: filterToDto(filter),
                sortField: sort.field,
                order: sort.descending ? 'DESCENDING' : 'ASCENDING',
                pageIndex: pageNumber - 1,
                pageSize: resultsPerPage
            };

            httpRequest
                .post(`/Users/GetUsers`, dto)
                .then(response => {
                    var users = response.list;
                    var resultCount = response.totalCount;
                    resolve({ users, resultCount });
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    static getUserRoles() {
        return new Promise((resolve, reject) => {
            httpRequest
                .get(`/Users/GetUserRoles`)
                .then(response => {
                    var merchants = response.merchants;
                    resolve({ merchants });
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    static addUser(newUserData) {
        var dto = toUserDto(newUserData)

        return new Promise((resolve, reject) => {
            httpRequest
                .post(`/Users/AddUser`, dto)
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    reject(mapErrors(err, mapUserErrorsFromDto));
                });
        });
    }

    static editUser(newUserData) {
        var dto = toUserDto(newUserData)

        return new Promise((resolve, reject) => {
            httpRequest
                .post(`/Users/updateUser`, dto)
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    reject(mapErrors(err, mapUserErrorsFromDto));
                });
        });
    }

    static resetUserPassword(userId, merchantNumber) {
        var request = {
            userId: userId,
            childMerchantNumber: merchantNumber
        };

        return new Promise((resolve, reject) => {
            httpRequest
                .post('/Users/ResetPassword', request)
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    static deleteUser(userId, merchantNumber) {
        var request = {
            userId: userId,
            childMerchantNumber: merchantNumber
        };

        return new Promise((resolve, reject) => {
            httpRequest
                .post('/Users/DeleteUser', request)
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }
}
//#endregion

export default UserApi;
