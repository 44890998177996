import React from "react";
import PropTypes from "prop-types";

import { WarningText } from "@premier/ui";
import { BatchJobStatus as Status } from "api/transactionsApi";

import "./BatchJobStatus.scss";

const BatchJobStatus = ({
    file, //data
    verbose, //logic render
    openErrorModal
}) => {
    if (!file.status) return null;

    switch (file.status.key) {
        case Status.COMPLETE:
            return file.approvedRecordCount === file.uploadedRecordCount
                ? '100% approved'
                : `${file.uploadedRecordCount - file.approvedRecordCount} declined`;

        case Status.IN_PROGRESS:
            let percent = Math.floor((100.0 * file.approvedRecordCount) / file.uploadedRecordCount);
            return `${percent}% complete`;

        case Status.WARNING:
            return (
                <div className={`batch-file-status-error ${openErrorModal ? 'pointer-cursor' : 'default-cursor'}`} onClick={openErrorModal ? () => openErrorModal(file, true) : null} >
                    <WarningText>Warning</WarningText>
                    {verbose && "Duplicate file warning. This file has the same number of records and value as a file that was uploaded in the last 30 days."}
                </div>
            );

        default:
            return file.status.description;
    }
};

BatchJobStatus.propTypes = {
    file: PropTypes.shape({
        status: PropTypes.shape({
            key: PropTypes.string.isRequired,
            description: PropTypes.string
        }).isRequired
    }),
    /** Show more details. Keep this false if inside a table */
    verbose: PropTypes.bool,
    /** Show error modal for BatchJobs */
    openErrorModal: PropTypes.func,
};

export default BatchJobStatus;
