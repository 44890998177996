import React from 'react';
import PropTypes from 'prop-types';

import { StationeryItem } from 'components/StationeryShop'

const StationeryItemList = ({ 
    items //data
}) => {
    return (
        <> 
            {items ?  items.map((item, i) => {
               return <StationeryItem item={item} key={i} />
            }) : ''}
        </>
    );
}

StationeryItemList.propTypes = {
    items: PropTypes.arrayOf(PropTypes.object.isRequired),
};

export default StationeryItemList;

