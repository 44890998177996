import httpRequest from './httpRequest';
import { defaultPageSize } from 'constants/billpay';

class ScheduledPaymentsApi {
    //#region ----- Scheduled Payments -----
    static getScheduledPayments(resultsPerPage = defaultPageSize, pageIndex = 0, filter = {}, sort) {
        return new Promise((resolve, reject) => {
            const model = {
                request: filter,
                pageIndex: pageIndex,
                pageSize: resultsPerPage
            };

            if (sort) {
                model.sortField = sort.field;
                model.order = sort.descending ? 'DESCENDING' : 'ASCENDING';
            }

            httpRequest
                .post(`/scheduled-payments/search`, model)
                .then(response => {
                    var scheduledPayments = response.list;
                    var resultCount = response.totalCount;
                    resolve({ scheduledPayments, resultCount });
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    static getScheduledPayment(scheduledPaymentId) {
        return new Promise((resolve, reject) => {
            httpRequest
                .get(`/scheduled-payments/${scheduledPaymentId}`)
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    reject(err);
                });
            });
    }

    static cancelScheduledPayment(scheduledPaymentId) {
        return new Promise((resolve, reject) => {
            httpRequest
                .post(`/scheduled-payments/${scheduledPaymentId}/cancel`)
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    static retryScheduledPayment(scheduledPaymentId) {
        return new Promise((resolve, reject) => {
            httpRequest
                .post(`/scheduled-payments/${scheduledPaymentId}/retry`)
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }
    //#endregion
}

export default ScheduledPaymentsApi;
