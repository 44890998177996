import React from 'react';
import PropTypes from 'prop-types';
import { NavMenu } from '@premier/ui';

import hamburger from 'images/hamburger.svg';
import exit from 'images/exit.svg';

import './Header.scss';

const MainNavigation = props => {
    const openedButton = (
        <div className='menu-toggle-button menu-left'>
            <img className='img' src={hamburger} alt='close-menu-button' />
        </div>
    );

    const closedButton = (
        <div className='menu-toggle-button menu-left'>
            <img className='img' src={exit} alt='open-menu-button' />
        </div>
    );

    return props.items.length > 0 ? (
        <NavMenu
            className={props.className}
            items={props.items}
            toggleOpen={closedButton}
            toggleClosed={openedButton}
        />
    ) : null;
};

MainNavigation.propTypes = {
    items: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default MainNavigation;
