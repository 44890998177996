import { useState } from 'react';
import _ from 'lodash';

const DEBOUNCE_DELAY = 800;

/** Returns a debounced function of func (which is also a function)
  * @param {function} func The function to debounce
  */
export default function useDebounce(func, delay = null) {

    if (delay === null) {
      delay = DEBOUNCE_DELAY;
    }

    const [debouncedFunc] = useState(() => _.debounce(func, delay));

    return debouncedFunc;
}
