import { absolute } from '@premier/utils/url';
import { Configuration, ApiError } from '@premier/webapi-client';
import { Result, hasProperty, Failure } from '@premier/utils/helpers';
import { AxiosResponse } from 'axios';
import http from 'http';
import https from 'https';
import { ApiResult } from './mapErrors';


export function configFactory() {
    if(!process.env.REACT_APP_API_ENDPOINT)
        throw new Error('REACT_APP_API_ENDPOINT is not set');

    return new Configuration({
        basePath: absolute(process.env.REACT_APP_API_ENDPOINT).replace(/\/$/, ''),
        // See https://axios-http.com/docs/req_config
        baseOptions: {
            withCredentials: true,
            httpAgent: new http.Agent({ keepAlive: true }),
            httpsAgent: new https.Agent({ keepAlive: true }),
            // This line ensures that 400 Bad Request responses don't get outright rejected since the WebApi
            // have been changed to return 400 whenever validation (on forms) has failed. If this
            // is instead rejected, the frontend would be unable to retrieve the validation errors
            // on any 400 Bad request responses.
            // Prior to validateStatus being added, the previous developers pre Linkly thought passing a 400 Bad Request
            // as a 200 in the WebApi was a good idea (rather than just configuring Axios).
            validateStatus: (status : number) => {
                return status >= 200 && status <= 400;
            },
        }
    });
};

export const config = configFactory();


const defaultError = (status: number | undefined) => [{ message: `Error${status ? ' ' + status : ''} - An unknown error has occurred` }];

/**
 * Maps to ApiError array, or undefined if it does not meet the format
 * @param input
 */
function getApiErrors(input: unknown): ApiError[] | undefined {
    if (!Array.isArray(input))
        return undefined;

    let errors: ApiError[] = [];

    for (let i = 0; i < input.length; i++) {
        const val = input[i] as unknown;
        if (!(hasProperty(val, 'code') && typeof val.code === 'string')
            || !(hasProperty(val, 'message') && typeof val.message === 'string')
            || !(hasProperty(val, 'parameter') && typeof val.parameter === 'string')
        )
            return undefined;

        errors.push({
            code: val.code,
            message: val.message,
            parameter: val.parameter
        })
    }

    return errors;
}

/**
 * if error looks like a AxiosResponse
 * - if 401 status and redirect
 * - if errors look like ApiError[], return as ApiError[]
 * @param error
 */
const onRejected = (error: unknown): Failure<Array<ApiError>> => {
        const response = hasProperty(error, 'response') ? error.response : undefined;
    const status = hasProperty(response, 'status') && typeof response.status === 'number' ? response.status : undefined;

    checkSessionTimeOut(status);

    const data = hasProperty(response, 'data') ? response.data : undefined;
    const errors = hasProperty(data, 'errors') ? getApiErrors(data.errors) : undefined;

    return {
        ok: false,
        val: errors || defaultError(status)
    }
}

/**
 * Check if the response error status is a 401 (unauthorised). If yes, then redirect user to the logon page.
 */
export const checkSessionTimeOut = (status: number | undefined) => {
    // redirect user to logon page when API returns 401
    if (status === 401) {
        // not really sure this is the best place for this logic
        // could consider moving it later
        let currentLocation = encodeURI(window.location.pathname);
        const appPath = process.env.PUBLIC_URL;

        if (appPath !== '/' && currentLocation.indexOf(appPath) === 0) {
            currentLocation = currentLocation.substring(appPath.length);
        }

        window.location.href = appPath + '/logon?ref=sessionExpired&lastLocation=' + currentLocation;
    }
}

interface EmptyApiResponsish {
    errors?: Array<ApiError>
}

/**
 * Map API response to an empty Result
 * @param response
 */
const onFulfilledEmpty = ((response: AxiosResponse<EmptyApiResponsish>): Result<void, Array<ApiError>> => {
    if (response.data == null) {
        return { ok: false, val: defaultError(response.status) };
    }

    if (response.data.errors?.length) {
        return { ok: false, val: response.data.errors };
    }

    return { ok: true, val: undefined };
});

export const emptyResultHandler = [
    onFulfilledEmpty,
    onRejected
];


interface ApiResponsish<T> extends EmptyApiResponsish {
    data?: T;
}

/**
 * Map API response to a Result
 * @param response
 */
const onFulfilled = (<T = void>(response: AxiosResponse<ApiResponsish<T>>): Result<T, Array<ApiError>> => {
    if (response.data == null) {
        return { ok: false, val: defaultError(response.status) };
    }

    if (response.data.errors?.length) {
        return { ok: false, val: response.data.errors };
    }

    const val = response.data.data;
    if (val == null) {
        return { ok: false, val: defaultError(response.status)};
    }

    return { ok: true, val: val };
})

export const resultHandler = [
    onFulfilled,
    onRejected
];


export const mapFromResult = <T, T1>(result: ApiResult<T>, mapper: (dto: T) => T1) => {
    if(result.ok) {
        return { ok: result.ok, val: mapper(result.val) };
    } else {
        return { ok: result.ok, val: result.val};
    }
};
