import React, { ReactNode } from 'react';
import { Dialog, Icon } from '..';

type Props = {
    /** If no title, the children will be shown as the title. Otherwise children will be the body text. */
    title?: string | ReactNode,
    /** Do not print the outer Modal wrapper (for switching modal content dynamically) */
    noWrapper?: boolean,
    show?: boolean,  // required unless noWrapper
    dialogClassName?: string,
    disableAutoClose?: boolean,
    onClose?: () => void,  // required unless noWrapper
    footerButtons?: ReactNode,
    footerDivider?: boolean,
    children?: ReactNode,
};

/** A Modal with a green tick and some text. Auto-closes in 3 seconds. */
const SuccessModal = ({
    show, noWrapper, dialogClassName, disableAutoClose, //logic renders
    onClose, //functions
    title, children, //data
    footerButtons, footerDivider //footer props
}: Props) => {

    return (
        <Dialog
            closeButton
            dismissable
            autoClose={!disableAutoClose}
            noWrapper={noWrapper}
            dialogClassName={dialogClassName}

            icon={<Icon tick />}
            title={title != null ? title : children}

            show={show}
            onClose={onClose}
            footerDivider={footerDivider}
            footerButtons={footerButtons}
        >
            {title != null && (children)}
        </Dialog>
    )
};

export default SuccessModal;