import { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { RootState } from "store/store";
import { PlatformRoutesConfiguration, userRoles } from "components/Routing";
import { APICallRequestState, MerchantDropdown, PageHeader, useApiCall } from "components/Common";
import { Option } from "packages/formik-ui/src/FormikDropdown";
import { FormGroup, LoadingIndicator, PageSection, ResponsiveTable, Row, Link as ButtonLink } from "packages/ui";
import { ResponsiveTableRecordPartial } from "packages/ui/src/ResponsiveTableTypes";
import DeclineApi from "api/declineApi";
import currencyUtil from "packages/utils/currency";
import { Merchant } from "packages/utils/models";
import { getMerchantOptions } from "packages/utils/billpay";

type Props = {
    merchant: Merchant;
};

type TrayGridItem = {
    trayId?: number;
    trayName: string;
    description: string;
    count: number;
    total: number;
} & ResponsiveTableRecordPartial;

const ManageDeclinesPage = ({ merchant }: Props) => {
    const [selectedMerchant, setSelectedMerchant] = useState<Option | null>(null);

    const merchantOptions = getMerchantOptions(merchant, false, userRoles.declineManager);

    useEffect(() => {
        if (merchantOptions.length === 1) {
            setSelectedMerchant(merchantOptions[0]);
        }
    }, []);

    const [trays, traysStatus] = useApiCall(() => {
        if (selectedMerchant) {
            return DeclineApi.getDeclineTrays(selectedMerchant.value as string);
        }
    }, [selectedMerchant]);

    const trayGridItems = useMemo<TrayGridItem[]>(() => {
        return [
            {
                trayId: trays?.resolvedTrayId,
                trayName: "Resolved",
                description: trays?.resolvedDescription || "",
                count: trays?.resolvedCount || 0,
                total: trays?.resolvedAmount || 0,
            },
            {
                trayId: trays?.unresolvedTrayId,
                trayName: "Unresolved",
                description: trays?.unresolvedDescription || "",
                count: trays?.unresolvedCount || 0,
                total: trays?.unresolvedAmount || 0
            },
            {
                trayId: trays?.trayId1,
                trayName: trays?.tray1Nickname || "Tray 1",
                description: trays?.tray1Description || "",
                count: trays?.tray1Count || 0,
                total: trays?.tray1Amount || 0,
            },
            {
                trayId: trays?.trayId2,
                trayName: trays?.tray2Nickname || "Tray 2",
                description: trays?.tray2Description || "",
                count: trays?.tray2Count || 0,
                total: trays?.tray2Amount || 0,
            },
            {
                trayId: trays?.trayId3,
                trayName: trays?.tray3Nickname || "Tray 3",
                description: trays?.tray3Description || "",
                count: trays?.tray3Count || 0,
                total: trays?.tray3Amount || 0,
            },
        ];
    }, [trays]);

    const handleMerchantChange = (selectedMerchant: Option | null) => {
        setSelectedMerchant(selectedMerchant);
    };

    return (<>
        <PageSection>
            <PageHeader title="Declines" subtitle="Manage declined recurring payments." />
        </PageSection>
        <PageSection>
            <Row divided>
                <div className='col-md-12 col-lg-6'>
                    <h2>Statistics</h2>
                    <p>View tray and batch file statistics.</p>
                    <ButtonLink to={PlatformRoutesConfiguration.transactionRoute!.declinesDashboard.generatePath()} button primary>View dashboard</ButtonLink>
                </div>
                <div className='col-md-12 col-lg-6 token-reference'>
                    <h2>Declines handling</h2>
                    <p>For options related to how declines are managed, including configuration of your trays, go to the <ButtonLink to={PlatformRoutesConfiguration.batchDeclineSettingsRoute!.settings.generatePath()}>Batch declines settings</ButtonLink>.</p>
                </div>
            </Row>
        </PageSection>

        <PageSection>
            <h2>Trays</h2>
            {merchantOptions.length > 1 ?
                <FormGroup fieldId="merchantNumber" label="Merchant">
                    <MerchantDropdown onChange={handleMerchantChange} feature={userRoles.declineManager} useDefaultValue />
                </FormGroup> :
                <></>}
            {traysStatus === APICallRequestState.LOADING || traysStatus === APICallRequestState.PENDING ?
                <LoadingIndicator /> :
                <ResponsiveTable
                    data={trayGridItems}
                    columns={[
                        {
                            label: "Name",
                            getter: item => (item.trayId ? <ButtonLink to={PlatformRoutesConfiguration.transactionRoute!.manageDeclinesTray.generatePath(item.trayId)}>{item.trayName}</ButtonLink> : <>{item.trayName}</>),
                        },
                        {
                            label: "Description",
                            getter: item => item.description,
                            className: "overflow-wrap",
                        },
                        {
                            label: "Records",
                            getter: item => item.count,
                            textAlign: "right",
                        },
                        {
                            label: "Total",
                            getter: item => item.total >= 0 ? currencyUtil.convertToDisplayString(item.total) : "",
                            textAlign: "right",
                        }
                    ]}
                />
            }
        </PageSection>
    </>);
};

function mapStateToProps(state: RootState) {
    return {
        merchant: state.accounts.users.merchant
    };
}

export default connect(mapStateToProps)(ManageDeclinesPage);
