export const INIT_REQUESTED = 'INIT_REQUESTED';
export const INIT_SUCCESS = 'INIT_SUCCESS';
export const INIT_FAILED = 'INIT_FAILED';

export const LOGIN_USER_REQUESTED = 'LOGIN_USER_REQUESTED';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILED = 'LOGIN_USER_FAILED';

export const LOGOUT_USER_REQUESTED = 'LOGOUT_USER_REQUESTED';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';
export const LOGOUT_USER_FAILED = 'LOGOUT_USER_FAILED';


export const RESET_PASSWORD_REQUESTED = 'RESET_PASSWORD_REQUESTED';
export const RESET_PASSWORD_SUCCESS   = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILED    = 'RESET_PASSWORD_FAILED';

export const CHANGE_PASSWORD_REQUESTED = 'CHANGE_PASSWORD_REQUESTED';
export const CHANGE_PASSWORD_SUCCESS   = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILED    = 'CHANGE_PASSWORD_FAILED';

// export const GET_PROFILE_REQUESTED = 'GET_PROFILE_REQUESTED';
// export const GET_PROFILE_SUCCESS   = 'GET_PROFILE_SUCCESS';
// export const GET_PROFILE_FAILED    = 'GET_PROFILE_FAILED';

export const UPDATE_PROFILE_REQUESTED = 'UPDATE_PROFILE_REQUESTED';
export const UPDATE_PROFILE_SUCCESS   = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAILED    = 'UPDATE_PROFILE_FAILED';

export const LOAD_NOTICES_REQUESTED = 'LOAD_NOTICES_REQUESTED';
export const LOAD_NOTICES_SUCCESS   = 'LOAD_NOTICES_SUCCESS';
export const LOAD_NOTICES_FAILED    = 'LOAD_NOTICES_FAILED';

export const LOAD_NOTICE_REQUESTED = 'LOAD_NOTICE_REQUESTED';
export const LOAD_NOTICE_SUCCESS   = 'LOAD_NOTICE_SUCCESS';
export const LOAD_NOTICE_FAILED    = 'LOAD_NOTICE_FAILED';

export const LOAD_CONFIG_REQUESTED = 'LOAD_CONFIG_REQUESTED';
export const LOAD_CONFIG_SUCCESS = 'LOAD_CONFIG_SUCCESS';
export const LOAD_CONFIG_FAILED = 'LOAD_CONFIG_FAILED';

export const SEND_CONTACT_FORM_REQUESTED = 'SEND_CONTACT_FORM_REQUESTED';
export const SEND_CONTACT_FORM_SUCCESS = 'SEND_CONTACT_FORM_SUCCESS';
export const SEND_CONTACT_FORM_FAILED = 'SEND_CONTACT_FORM_FAILED';

export const GET_BILLER_CODES_REQUESTED = 'GET_BILLER_CODES_REQUESTED';
export const GET_BILLER_CODES_SUCCESS = 'GET_BILLER_CODES_SUCCESS';
export const GET_BILLER_CODES_FAILED = 'GET_BILLER_CODES_FAILED';

export const GET_TERMS_REQUESTED = 'GET_TERMS_REQUESTED';
export const GET_TERMS_SUCCESS = 'GET_TERMS_SUCCESS';
export const GET_TERMS_FAILED = 'GET_TERMS_FAILED';

export const ACCEPT_TERMS_REQUESTED = 'ACCEPT_TERMS_REQUESTED';
export const ACCEPT_TERMS_SUCCESS = 'ACCEPT_TERMS_SUCCESS';
export const ACCEPT_TERMS_FAILED = 'ACCEPT_TERMS_FAILED';


//#region Account settings
export const GET_BUSINESS_DETAILS_REQUESTED = 'GET_BUSINESS_DETAILS_REQUESTED';
export const GET_BUSINESS_DETAILS_SUCCESS = 'GET_BUSINESS_DETAILS_SUCCESS';
export const GET_BUSINESS_DETAILS_FAILED = 'GET_BUSINESS_DETAILS_FAILED';

export const UPDATE_BUSINESS_DETAILS_REQUESTED = 'UPDATE_BUSINESS_DETAILS_REQUESTED';
export const UPDATE_BUSINESS_DETAILS_SUCCESS = 'UPDATE_BUSINESS_DETAILS_SUCCESS';
export const UPDATE_BUSINESS_DETAILS_FAILED = 'UPDATE_BUSINESS_DETAILS_FAILED';
//#endregion


//#region --- Local changes ---
export const SET_BRANDING_BY_BILLER = 'SET_BRANDING_BY_BILLER';
//#endregion