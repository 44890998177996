import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import labels from 'constants/labels';
import { CardLocalisationEnum, CardSubTypeEnum } from 'constants/billpay';
import { cardNetworks } from '@premier/cards';
import { ProductTooltip } from 'components/Common';

import { CheckboxGroupField, FormContext } from '@premier/form';
import { Button, Icon, IconText, Tooltip } from '@premier/ui';
import BillerSurchargeTable from './BillerSurchargeTable';
import './BillerSurchargeFields.scss';


//#region ====== Enum + Functions (exported for unit tests) ======

export const ApplyEnum = {
    BY_NETWORK: 'byNetwork',
    BY_LOCALISATION: 'byLocalisation',
    BY_SUBTYPE: 'byCardSubType',
}

export const DEFAULT_RULE = {
    fixedSurchargeAmount: 0,
    fixedSurchargePercent: 0,
    surchargeLimits: {
        minimum: 0,
        maximum: null
    }
}

function isDifferentiable(cardTypeCode, by, paymentMethods, selectedCards) {
    if(cardTypeCode === 'UNSPECIFIED') {
        return _.every(selectedCards, c => isDifferentiable(c, by, paymentMethods));
    }

    let paymentMethod = _.find(paymentMethods, {cardTypeCode});
    return (
        !_.get(paymentMethod, `allowSurcharge`, true) ||  // Return true if card can't be surcharged
        _.get(paymentMethod, `differentiateSurchargeByCard${by}`)
    );
}

/**
 * Regenerate the surcharge-rules rows based on the current rules, user options and the card properties
 * @param {*} rules The current surcharge rules (to be regenerated)
 * @param {*} cards The selected accepted-cards
 * @param {*} applyBy The apply-by network/localisation/subType checkboxes
 * @param {*} paymentMethods This has the global card properties from DB (surchargeability by localisation/subType, from tbl_BP_CardTypes)
 */
export function regenerateRules(rules, cards, applyBy, paymentMethods) {
    applyBy = applyBy || [];

    let cardCodes = applyBy.includes(ApplyEnum.BY_NETWORK)
        ? cards.filter(c => paymentMethods.filter(pm => pm.allowSurcharge).map(pm => pm.cardTypeCode).includes(c))
        : ['UNSPECIFIED'];

    let newRules = [];
    cardCodes.forEach(cardTypeCode => {

        let cardSubTypes = applyBy.includes(ApplyEnum.BY_SUBTYPE) && isDifferentiable(cardTypeCode, 'SubType', paymentMethods, cards)
            ? [CardSubTypeEnum.DEBIT, CardSubTypeEnum.CREDIT]
            : [CardSubTypeEnum.UNSPECIFIED];
        let cardLocalisations = applyBy.includes(ApplyEnum.BY_LOCALISATION) && isDifferentiable(cardTypeCode, 'Region', paymentMethods, cards)
            ? [CardLocalisationEnum.LOCAL, CardLocalisationEnum.INTERNATIONAL]
            : [CardLocalisationEnum.UNSPECIFIED];

        cardSubTypes.forEach(cardSubType => {

            cardLocalisations.forEach(cardLocalisation => {

                if(applyBy.includes(ApplyEnum.BY_NETWORK)) {
                    var existingRule = _.find(rules, { cardTypeCode, cardSubType, cardLocalisation })
                } else {
                    let base = _.find(rules, { cardSubType, cardLocalisation });
                    existingRule = base && {...base, cardTypeCode};
                }

                newRules.push(existingRule || { cardTypeCode, cardSubType, cardLocalisation, ..._.cloneDeep(DEFAULT_RULE) });
            });
        });
    });

    return newRules;
}

//#endregion


/** The checkboxes + table in BillerSurchargeForm. This handles regenerating the rules on option updates. */
const BillerSurchargeFields = ({paymentMethods, cards, lcrEnabled}) => {

    const context = React.useContext(FormContext);

    useEffect(() => {
        setTimeout(generateRules, 0);  // setTimeout to prevent calling generateRules before the Form finish initialising on first render
    }, [cards]);

    function generateRules(applyBy) {
        var newRules = regenerateRules(context.values.rules, cards, applyBy || context.values.applyBy, paymentMethods);
        context.setValue('rules', newRules);
    }

    function handleApplyCheckboxesChange(selectedValues) {
        var newRules = regenerateRules(context.getValue('rules'), cards, selectedValues, paymentMethods);
        setTimeout(() => {
            context.setValue('rules', newRules);  // Delay to let applyBy finished updating to prevent restoring applyBy values from parent form (Form bug)
        }, 300);
    }

    function handleAdvancedSwitch() {
        let advanced = !context.getValue('advanced');
        if(!advanced) {
            clearAdvancedValues();
        }
        context.setValue('advanced', advanced);
    }

    /** Clear/reset fixed amount / minimum amount. To be used when switching to simple view, as they will be hidden. */
    function clearAdvancedValues() {
        let newRules = context.getValue('rules');
        newRules.forEach(r => {
            r.fixedSurchargeAmount = DEFAULT_RULE.fixedSurchargeAmount;
            r.surchargeLimits.minimum = DEFAULT_RULE.surchargeLimits.minimum;
        });
        context.setValue('rules', newRules);
    }

    function renderFilterWarning(forApplyBy) {
        let applyBy = context.getValue('applyBy');
        if(!applyBy) return null;

        if (!applyBy.includes(ApplyEnum.BY_NETWORK) && applyBy.includes(forApplyBy)) {
            if (forApplyBy === ApplyEnum.BY_SUBTYPE) {
                var dbBy = 'SubType';
                var description = labels.bySubType.toLowerCase();
            } else if (forApplyBy === ApplyEnum.BY_LOCALISATION) {
                dbBy = 'Region';
                description = labels.byLocalisation.toLowerCase();
            }

            let unsupportedCardCodes = cards.filter(cardTypeCode => !isDifferentiable(cardTypeCode, dbBy, paymentMethods, cards));
            let unsupportedCardNames = unsupportedCardCodes.map(serverCode => _.get(_.find(cardNetworks, {serverCode}), 'name'));

            if (unsupportedCardNames.length > 1) {
                let lastCardName = unsupportedCardNames.pop();
                return (
                    <IconText info>
                        {unsupportedCardNames.join(', ')} and {lastCardName} do not support <i>{description}</i>.
                    </IconText>
                );
            } else if (unsupportedCardNames.length) {
                return (
                    <IconText info>
                        {unsupportedCardNames[0]} does not support <i>{description}</i>.
                    </IconText>
                );
            }
        }

        return null;
    }

    return (
        <div className='biller-surcharge-form'>
            <CheckboxGroupField inlineLg3
                name='applyBy'
                label={lcrEnabled ? <>{'Surcharge'}<ProductTooltip productTooltipModule={"LEAST_COST_ROUTING_SURCHARGE"} /></> : <>Surcharge</>}
                labelText='Surcharge'
                options={[
                    {value: ApplyEnum.BY_NETWORK, label: labels.byNetwork},
                    {value: ApplyEnum.BY_LOCALISATION, label: labels.byLocalisation},
                    {value: ApplyEnum.BY_SUBTYPE, label: labels.bySubType},
                ]}
                onChange={handleApplyCheckboxesChange}
            />

            {renderFilterWarning(ApplyEnum.BY_LOCALISATION)}
            {renderFilterWarning(ApplyEnum.BY_SUBTYPE)}

            <div className='switch-button-wrapper'>
                <Button subtle onClick={handleAdvancedSwitch}>
                    <Icon switchArrows /> Switch to
                    {context.values.advanced ? ' basic ' : ' advanced '}
                    surcharge setup
                </Button>
            </div>

            <BillerSurchargeTable
                rules={context.getValue('rules') || []}
                applyByNetwork={_.get(context.values, 'applyBy',[]).includes(ApplyEnum.BY_NETWORK)}
                applyByLocalisation={_.get(context.values, 'applyBy',[]).includes(ApplyEnum.BY_LOCALISATION)}
                applyByCardSubType={_.get(context.values, 'applyBy',[]).includes(ApplyEnum.BY_SUBTYPE)}
                advanced={context.values.advanced}
            />



            <Tooltip id='tip-setup-surcharge'>
                Set up surcharge by card scheme, domestic or international cards and debit or credit cards
            </Tooltip>
        </div>
    );
};

BillerSurchargeFields.propTypes = {
    paymentMethods: PropTypes.arrayOf(PropTypes.shape({
        cardTypeCode: PropTypes.string,
        differentiateSurchargeByCardSubType: PropTypes.bool,
        differentiateSurchargeByCardRegion: PropTypes.bool,
    })),
    cards: PropTypes.arrayOf(
        PropTypes.oneOf(cardNetworks.map(c => c.serverCode))  // eg. 'MC' or 'VC'
    ),
    applyBy: PropTypes.arrayOf(PropTypes.string),
    rules: PropTypes.arrayOf(PropTypes.shape({
        cardTypeCode: PropTypes.oneOf(cardNetworks.map(c => c.serverCode)),  // eg. 'MC' or 'VC'
        cardSubType: PropTypes.oneOf(Object.values(CardSubTypeEnum)),
        cardLocalisation: PropTypes.oneOf(Object.values(CardLocalisationEnum)),
        fixedSurchargeAmount: PropTypes.number,
        fixedSurchargePercent: PropTypes.number,
        surchargeLimits: PropTypes.shape({
            minimum: PropTypes.number,
            maximum: PropTypes.number,
        }),
    })),
    lcrEnabled: PropTypes.bool,
};

export default BillerSurchargeFields;
