import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Navigate } from 'react-router-dom';

import { Button, ButtonContainer } from '@premier/ui'
import { FloatingContainer, FormHeader } from 'components/Common';

import { PlatformRoutesConfiguration } from 'components/Routing';
import * as accountActions from 'components/Account/_actions/accountActions';

const ChangePasswordConfirmationPage = ({
    changePasswordSuccess, //logic render
    actions //API actions
}) => {
    const [redirect, setRedirect] = useState(false);
    const [logout, setLogout] = useState(false);

    useEffect(()=> {
        if(logout) {
            actions.logoutUser().then(() => {
               setRedirect(true);
            })
       };
    }, [logout])

    if(!changePasswordSuccess || redirect)  // If !changePasswordSuccess, user may have loaded the page from history, redirect immediately
        return <Navigate to={PlatformRoutesConfiguration.accountRoute.logon.generatePath()} />;

    return (
        <FloatingContainer>
            <FormHeader title="Password changed successfully" description=" " />
            <ButtonContainer>
                <Button onClick={() => { setLogout(true) }}>Back to log on</Button>
            </ButtonContainer>
        </FloatingContainer>
    );
};

function mapStateToProps(state, ownProps) {
    return {
        changePasswordSuccess: state.accounts.users.changePasswordSuccess
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(accountActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordConfirmationPage);