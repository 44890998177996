import { IconText, Link, PaddedContainer } from "@premier/ui";

// @ts-ignore
import labels from "constants/labels";
import { PromotionCard } from "components/Common";

import { PlatformRoutesConfiguration } from "components/Routing";
import "./CustomerProfileCard.scss";

type Props = {
    customerId?: number;
    tokenId: number;
    customerCode?: string;
}

/** Customer summary to be shown in Token Details page */
const CustomerProfileCard = ({
    customerId, tokenId, customerCode //data
}: Props) => {
    return (
        <div className="customer-profile-card">
            {customerId ? (
                <PaddedContainer className="customer-summary">
                    <IconText tick >{labels.customerCode} &nbsp; <b>{customerCode}</b></IconText>
                    <Link to={PlatformRoutesConfiguration.customerRoute?.viewCustomer.generatePath(customerId)}>View customer details</Link>
                </PaddedContainer>
            ) : (
                <PromotionCard
                    badge="New"
                    title="Create customer profile"
                    subText={"Link this payment method to a customer to easily manage your customer's data."}
                    buttons={<>
                        <Link button primary to={{ pathname: PlatformRoutesConfiguration.customerRoute?.newCustomer.generatePath(), search: `?dataVaultId=${tokenId}` }}>
                            Create new customer
                        </Link>
                        <Link button to={PlatformRoutesConfiguration.tokenRoute?.linkTokenToCustomer.generatePath(tokenId)}>
                            Link existing customer
                        </Link>
                    </>}
                />
            )}
        </div>
    );
};

export default CustomerProfileCard;
