import React from 'react';
import { PaddedContainer, Divider, Row, DescriptionList, Switch, Link, LoadingIndicator } from '@premier/ui';

import './CustomerReferencesSection.scss';
import { PlatformRoutesConfiguration } from 'components/Routing';
import { billerApi } from 'api';
import { useApiCallDeprecated, APICallRequestState } from 'components/Common';

interface Props {
    billerCode?: string;
}

export const CustomerReferencesSection: React.FC<Props> = ({ billerCode }) => {

    const [biller, billerStatus] = useApiCallDeprecated(() => {
        // @ts-ignore
        return billerApi.getBillerDetails(billerCode)
    }, [billerCode])

    function getCrnName(name: string) {
        // if name in crnNameList then return name; otherwise check if name + " [T]" in list and crnFileName exist then return name + " [T]"
        return biller.crnNameList.some((item: any) => item.crnName === name) ? name : (biller.crnNameList.some((item: any) => item.crnName === name + " [T]" && item.crnFileName) ? name + " [T]" : undefined);
    }

    function getCustomerReferenceRows() {
        return [1, 2, 3].map((num) => {
            const crn = getCrn(num);
            if (crn) {
                const items = [[
                    { name: 'Name:', value: getCrnName(crn.crnName), id: 'Name' + String(num) },
                    {
                        name: 'Prefix:', value: <Switch
                            on={crn.isPrefixActive} disabled={true}
                        />, id: 'Prefix' + String(num)
                    }],
                [{ name: 'Minimum length:', value: crn.minLength, id: 'MinimumLength' + String(num) },
                {
                    name: 'Suffix:', value: <Switch
                        on={crn.isSuffixActive} disabled={true}
                    />, id: 'Suffix' + String(num)
                }],
                [{ name: 'Maximum length:', value: crn.maxLength, id: 'MaximumLength' + String(num) },
                {
                    name: 'Checksum algorithm:', value: <Switch
                        on={crn.isChecksumAlgoActive} disabled={true}
                    />, id: 'Checksum' + String(num)
                }]
                ];

                const prefixDescription = crn.isPrefixRangeEnabled ? `Range from ${crn.prefixRangeStart} to ${crn.prefixRangeEnd}` : `Specify ${crn.prefixSpecify}`;
                const suffixDescription = crn.isSuffixRangeEnabled ? `Range from ${crn.suffixRangeStart} to ${crn.suffixRangeEnd}` : `Specify ${crn.suffixSpecify}`;
                const checkSumList = crn.checkSumList ? crn.checkSumList.filter((x: { checkSumId: any; }) => x.checkSumId === crn.crnCheckSum)[0]?.checkSumName : '';
                const checksumAlgodescription = (
                    <div>{checkSumList}
                        &emsp;
                        {crn.xAdd ? `xAdd: ${crn.xAddDisplay}` : null}
                        &emsp;
                        {crn.xFirst ? `xFirst: ${crn.xFirstDisplay}` : null}
                        &emsp;
                        {crn.xLast ? `xLast: ${crn.xLastDisplay}` : null}
                    </div>);

                return (
                    <>
                    <React.Fragment key={num}>
                        {crn.number > 1 &&
                            <Divider className='reference-divider' />
                        }

                        <dt className='reference-title'>Customer reference {crn.number}</dt>

                        <Row>
                            <div className='col-lg-3 reference-items'>
                                <DescriptionList showBlankName inlineXs items={items[0]} />
                                {crn.isPrefixActive &&
                                    <div className='description'>{prefixDescription}</div>
                                }
                            </div>
                            <div className='col-lg-4 reference-items'>
                                <DescriptionList showBlankName inlineXs items={items[1]} />
                                {crn.isSuffixActive &&
                                    <div className='description'>{suffixDescription}</div>}
                            </div>
                            <div className='col-lg-5 reference-items'>
                                <DescriptionList showBlankName inlineXs items={items[2]} />
                                {crn.isChecksumAlgoActive &&
                                    checksumAlgodescription}
                            </div>
                        </Row>
                        </React.Fragment>
                    </>
                );
            }
            return null;
        });
    }

    function getCrn(num: number): any | undefined {
        if (num === 1) {
            return biller?.acceptedCrn1 ?? null;
        }
        if (num === 2) {
            return biller?.acceptedCrn2 ?? null;
        }
        if (num === 3) {
            return biller?.acceptedCrn3 ?? null;
        }
    }

    return (
        <>
            <div>
                <PaddedContainer title="Customer references" button={<Link to={PlatformRoutesConfiguration.settingsRoute?.customerReferences.generatePath(billerCode)} button>Edit</Link>} lessMargin>
                    {billerStatus === APICallRequestState.SUCCESSFUL ? getCustomerReferenceRows() : <LoadingIndicator />}
                </PaddedContainer>
            </div>
        </>);
}

export default CustomerReferencesSection;