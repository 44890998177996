import classNames from "classnames";
import "./HelpText.scss";

type Props = {
    className?: string;
    title: string;
    description: string;
}

const HelpText = ({
    className, //style
    title, description //data
}: Props) => {
    return (
        <div className={classNames("help-text-container", className)}>
            <h3>{title}</h3>
            <div>{description}</div>
        </div>
    );
};


export default HelpText;