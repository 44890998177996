import React, {Fragment} from 'react';

export class TextUtil {

    isNullOrEmpty(text?: string) {
        return text === null || text === undefined || text === '';
    }

    toPrettyString = (text?: string) => {
        return text && (text.charAt(0).toUpperCase() + text.slice(1));
    }

    toInitialCapital = (text?: string) =>{
        return text && this.toPrettyString(text.toLowerCase());
    }

    /** Turns the first letter to Uppercase and leave the rest untouched */
    capitaliseFirstLetter = (text?: string) => {
        return text && (text[0].toUpperCase() + text.slice(1));
    }

    /** Formats ABN Values (or similar string) to remove all spaces and dashes
      * as well as custom formatting for lengths of 9 & 11
      * 9 = XXX XXX XXXX
      * 11 = XX XXX XXX XXX
      */
    formatAbn = (text?: string) => {
        if(!text)
            return text;

        var returnABN = text.split(" ").join("").split("-").join("");

        if (returnABN.length === 11) {
            var firstABNValues = returnABN.slice(0,2);
            var secondABNValues = returnABN.slice(2);
            return firstABNValues + ' ' + this.insertSpaces(secondABNValues);
        }

        if (returnABN.length === 9) {
            returnABN = this.insertSpaces(returnABN) ?? '';
        }

        return returnABN;
    }
    /** Insert spaces on a long string (eg. receipt number) for readability.
      * eg. 'A123456' -> 'A123 456'
      */
    insertSpaces = (text?: string) => {
        const DESIRED_GROUP_LENGTH = 3;

        if(!text) return text;

        text = text.trim();
        if(~text.indexOf(' ')) return text; // May be do nothing if already have spaces

        var numGroups = Math.floor(text.length / DESIRED_GROUP_LENGTH);

        var start = 0;
        var result = '';
        for(var remainLen = text.length; remainLen > 0; remainLen = text.length - start){
            var groupLen = Math.ceil(remainLen / numGroups);
            result += text.substr(start, groupLen) + ' ';
            start += groupLen;
            numGroups--;
        }

        return result.trim();
    }

    /** Remove all spaces in the text (mainly for reversing insertSpaces).
      * eg. 'A123 456 ' -> 'A123456'
      */
    removeSpaces = (text?: string) => {
        return text && text.replace(/ /g, '');
    }

    convertToUrl(path: string, params?: {[key: string]: string | number}){
        var paramStr = params && Object.keys(params).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`);
        return `${path}${(paramStr && paramStr.length > 0) ? `?${paramStr.join('&')}` : ''}`;
    }

    /** checks if a val passed in is true (in some sense), and returns either 'Yes' or 'No' depending.
     * @param val the value to check.
     */
    toYesNo(val?: boolean) {
        if(val == null)
            return val;

        return val == true ? 'Yes' : 'No';  // eslint-disable-line eqeqeq
    }

    /** Renders all '/r/n' that are returned from the API / database, into new lines
     * @param text raw api / database string (with '/r/n')
     */
    newlineToBr(text?: string) {
        if (!text)
            return text;

        var splitVal = text.split(/\r?\n|(?:\\r)?\\n/);

         return splitVal.map((text, index) => (
            <Fragment key={index}>{text}<br/></Fragment>
         ))
    }
}

const textUtil = new TextUtil();
export default textUtil;