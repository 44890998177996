import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import { Navigate } from "react-router-dom";
import _ from "lodash";
import dateUtil from "@premier/utils/date";
import { SingleColumnFormContainer, Link, DescriptionList, LoadingIndicator, BackButton, IconText, SuccessModal } from "@premier/ui";
import { PaymentMethodRequestPendingModal, TokenDetailsForm } from "components/DataVault";
import { PageHeader } from "components/Common";
// @ts-ignore
import labels from "constants/labels";
// @ts-ignore
import { PaymentMethodDetailsTabKeys, PaymentRequestStatus } from "constants/billpay";
import { PlatformRoutesConfiguration } from "components/Routing";
import * as tokenActions from "components/DataVault/_actions/tokenActions";
import * as paymentRequestActions from "components/PaymentRequests/_actions/paymentRequestActions";
import PaymentRequestActionTypes from "components/PaymentRequests/_actions/paymentRequestActionTypes";
import { FieldError } from "api/mapErrors";
import { RootState } from "store/store";
import { Token } from "models/Request";
import { Merchant } from "models";

type Props = {
    tokenActions: any;
    paymentRequestActions: any;
    merchant: Merchant;
    token: Token;
    paymentRequestId: string;
    errors: FieldError[];
    isLoading: boolean;
}

/** Edit a Payment Method */
const EditTokenPage = ({
    tokenActions, paymentRequestActions, //API actions
    merchant, token, paymentRequestId, errors, //state
    isLoading //logic render
}: Props) => {
    const [tokenFound, setTokenFound] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showRequestPendingModal, setShowRequestPendingModal] = useState(false);

    useEffect(() => {
        tokenActions.getToken(token.dataVaultId)
            .then(() =>
                setTokenFound(true)
            )
            .catch((err: FieldError) => {
                console.error(err);
            });
    }, [tokenActions]);

    function updateToken(values: any) {
        if (values["paymentMethodDetailsTab"] === PaymentMethodDetailsTabKeys.CREATE) {
            const tokenDetails = _.merge(token, values);

            tokenActions.updateToken(tokenDetails)
                .then(() => setShowSuccessModal(true))
                .catch((err: FieldError) => console.error(err));
        }
        else {
            const paymentDetails = {
                ...values.paymentRequest,
                billerCodeForm: {
                    billerCode: values.tokenDetailsBillerCodeForm.billerCode,
                    childMerchantNumber: values.tokenDetailsBillerCodeForm.merchantNumber,
                    billerCrnList: values.tokenDetailsBillerCodeForm.billerCrnList,
                },
                dataVaultId: token.dataVaultId,
                currencyCode: merchant.currency?.code,
                action: PaymentRequestActionTypes.UpdateToken
            };
            paymentRequestActions.create(paymentDetails).then(() => {
                setShowRequestPendingModal(true);
            });
        }
    }

    function getPrefixDetails() {
        const customerLink = token.customerV2Id
            ? <Link to={PlatformRoutesConfiguration.customerRoute?.viewCustomer.generatePath(token.customerV2Id)} newWindow>{token.customerUniqueId}</Link>
            : "Not yet created";

        return [
            { name: labels.customerCode + ":", value: customerLink },
            { name: labels.createdTime + ":", value: dateUtil.convertToDateTimeString(token.createdTime) },
            { name: "Last update:", value: dateUtil.convertToDateTimeString(token.updatedTime) }];
    }

    function handleRequestPendingClose(requestStatus: string) {
        setShowRequestPendingModal(false);

        if (requestStatus === PaymentRequestStatus.REGISTERED) {  // token updated
            setShowSuccessModal(true);
        }
    }


    if (redirect)
        return <Navigate to={PlatformRoutesConfiguration.tokenRoute?.viewToken.generatePath(token.dataVaultId)!} />;

    return (<>
        {isLoading && <LoadingIndicator />}

        {!isLoading && !tokenFound && <>
            <BackButton to={PlatformRoutesConfiguration.tokenRoute?.manageTokens.generatePath()} />
            <IconText alert>Token not found or an error occurred.</IconText>
        </>}

        {tokenFound && (
            <SingleColumnFormContainer className="edit-token-form">
                <PageHeader
                    backButton
                    title={token.token}
                />

                <DescriptionList greyLabel items={getPrefixDetails()} />

                <TokenDetailsForm
                    errors={errors}
                    token={token}
                    onSubmit={updateToken}
                />
            </SingleColumnFormContainer>
        )}

        <SuccessModal show={showSuccessModal} onClose={() => setRedirect(true)}>
            Payment method saved successfully
        </SuccessModal>

        {showRequestPendingModal && paymentRequestId && (
            <PaymentMethodRequestPendingModal
                paymentMethodRequestId={paymentRequestId}
                onClose={handleRequestPendingClose}
            />
        )}
    </>);
};

function mapStateToProps(state: RootState, ownProps: any) {
    const tokenId = ownProps.match.params.id;
    let token: Token = {
        dataVaultId: parseInt(tokenId),
        childMerchantNumber: "",
        token: "",
        expiryDate: {
            month: 0,
            year: 0
        },
        maskedCardNumber: "",
        crn1: "",
        crn2: "",
        crn3: "",
        customerUniqueId: "",
        type: "",
        cardTypeCode: "",
        updatedTime: "",
        createdTime: "",
        customerV2Id: ""
    };

    // If (or as soon as) the redux store has the requested token then use that
    if (state.dataVault.token.details?.dataVaultId === parseInt(tokenId)) {
        token = state.dataVault.token.details;
    }

    // Map payment request errors errors
    state.dataVault.token.errors?.map((e: FieldError) => {
        if (e.field === "email") {
            e.field = "paymentRequest.email";
        } else if (e.field === "mobile") {
            e.field = "paymentRequest.mobile";
        } else if (e.field === "dueDate") {
            e.field = "paymentRequest.dueDate";
        } else if (e.field === "expiryDate") {
            e.field = "paymentRequest.expiryDate";
        }

        return e;
    });

    return {
        merchant: state.accounts.users.merchant,
        token,
        paymentRequestId: state.paymentRequest.create.data?.guid,
        isLoading: state.dataVault.token.isLoading,
        errors: state.dataVault.token.errors
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        tokenActions: bindActionCreators(tokenActions, dispatch),
        paymentRequestActions: bindActionCreators(paymentRequestActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditTokenPage);
