import { useState, useEffect, useRef } from "react";
import { Field, Formik } from "formik";
import { FormGroup, Input } from "packages/formik-ui";
import { PaymentTypeKey, TOKEN_LENGTH } from "platforms/base/constants/billpay";
import { isToken } from "@premier/cards";
import tokenApi from "api/tokenApi";
import { Token } from "models/Token";
import * as Yup from "yup";

type Props = {
    parentMerchantNumber: string | undefined;
    merchantNumber: string | undefined;
    selectedToken: Token | undefined;
    customerUniqueId?: string;
    // Marks field as required (including asterix)
    required?: boolean;
    // Indicates whether the token field should be editable (use in conjunction with selectedToken)
    locked?: boolean;
    // Flag to trigger validation of field
    triggerValidation?: boolean;
    onTokenRetrieved: (token: Token) => void;
    onTokenCleared: () => void;
};

type FormValues = {
    tokenNumber?: string;
    accountNumber?: string;
}

type FormErrors = {
} & FormValues

export const TokenNumberLookup = ({ parentMerchantNumber, merchantNumber, selectedToken, customerUniqueId, required = false, locked = false, triggerValidation = false, onTokenRetrieved, onTokenCleared }: Props) => {
    const [token, setToken] = useState<Token | undefined>();
    const formRef = useRef<any>();

    useEffect(() => {
        setToken(selectedToken);
    }, [selectedToken]);

    useEffect(() => {
        if (triggerValidation) {
            formRef.current?.setTouched({ tokenNumber: true });
            formRef.current?.validateForm();
        }
    }, [triggerValidation]);

    const clear = () => {
        setToken(undefined);
        onTokenCleared();
    };

    return (
        <Formik
            innerRef={formRef}
            initialValues={{
                tokenNumber: token?.token,
                accountNumber: token?.type === PaymentTypeKey.BANK_ACCOUNT ? `${token?.deBsbNumber} - ${token?.deAccountNumber}` : token?.maskedCardNumber,
            }}
            onSubmit={() => {}}
            enableReinitialize={true}
            validationSchema={Yup.object().shape({
                tokenNumber: Yup.string()
                    .label("Token number")
                    .matches(/^\d+$/, "Token number must only contain digits")
                    .min(TOKEN_LENGTH)
                    .max(TOKEN_LENGTH)
                    .when([], {
                        is: () => required,
                        then: Yup.string().required("Token number is required")
                    })
            })}
            validateOnBlur={false}
            validate={async (values: FormValues) => {
                const errors: FormErrors = {};
                const tokenNumber = values.tokenNumber;
                const invalidTokenError = "Invalid token";

                // Only try to fetch token details if value is indeed a token and is 16 digits
                // Currently this hide expiry date field feature only applys in /transactions/new-payment
                if (tokenNumber?.length === TOKEN_LENGTH) {
                    if (isToken(tokenNumber)) {
                        const response = await tokenApi.getTokens(1, 0, {
                            token: tokenNumber,
                            // Attach child merchant number if the selected merchant number is different from the parent merchant number
                            childMerchantNumber: parentMerchantNumber !== merchantNumber ? merchantNumber: null,
                            // Restricts search to within specific customer's tokens, if required
                            customerUniqueId: customerUniqueId
                        });

                        if (response.tokens && response.tokens.length > 0) {
                            const item: Token = response.tokens[0];
                            setToken(item);
                            onTokenRetrieved(item);
                        } else {
                            clear();
                            errors.tokenNumber = invalidTokenError;
                        }
                    } else {
                        clear();
                        errors.tokenNumber = invalidTokenError;
                    }
                } else {
                    clear();
                }

                return errors;
            }}
        >
            <>
                <FormGroup label="Token number" name="tokenNumber" mandatory={required}>
                    <Field as={Input} name="tokenNumber" plaintext={locked} />
                </FormGroup>
                {token && <FormGroup label="Account number" name="accountNumber">
                    <Field as={Input} name="accountNumber" plaintext />
                </FormGroup>}
            </>
        </Formik>
    );
};
