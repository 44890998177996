import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';

import { Form, InputField, DatePickerField } from '@premier/form';
import { PaginationControl } from '@premier/ui';
import { FormError, useDebounce } from 'components/Common';
import { BatchLibraryFileList, ProcessBatchDialogs } from 'components/Batch';
import labels from 'constants/labels';

import windowUtil from '@premier/utils/window';

import * as batchActions from 'components/Batch/_actions/batchActions';

const initialFilter = {
    filename: '',
    updatedDate: []
};

/** The BatchLibraryFileList including filter and pagination */
const BatchLibraryFiles = ({
    actions, //API actions
    savedState, libraryFilesResult, isLoading //state
}) => {

    // Batch files
    const [filter, setFilter]           = useState(_.get(savedState, 'filter', {...initialFilter}));
    const [sort, setSort]               = useState(_.get(savedState, 'sort', {field: 'filename', descending: false}));
    const [resultsPerPage]              = useState(_.get(savedState, 'resultsPerPage', 10));
    const [currentPage, setCurrentPage] = useState(_.get(savedState, 'pageIndex', 0) + 1);
    const [resetPage, setResetPage] = useState(false);
    const [listErrors, setListErrors] = useState([]);

    // Process
    const [selectedLibraryFiles, setSelectedLibraryFiles] = useState(null);
    const [showProcessDialogs, setShowProcessDialogs] = useState(false);


    // On page load, fetch things from API, get transactions and restore previous state (page number, scroll position, filters) if any
    useEffect(() => {
        handleFilterChange((savedState && savedState.filter) || filter, false)
    }, []);

    useEffect(() => {
        setListErrors([]);

        let newPage = resetPage ? 1 : currentPage;
        setCurrentPage(newPage);

        actions.getBatchLibraryFiles(resultsPerPage, getPageIndex(newPage), filter, sort).then(result => {
            if (savedState && savedState.scrollPosition)
                windowUtil.scrollTo(savedState.scrollPosition); // Scroll back to previous position
        }).catch(errors => {
            setListErrors(errors);
        });

        setResetPage(false);
    }, [resultsPerPage, currentPage, filter, sort]);


    function getPageIndex(pageNumber = currentPage) {
        return pageNumber - 1;
    }

    function handleFilterChange(values, resetPage=true) {
        setResetPage(resetPage);
        setFilter(values);
    }
    const debouncedHandleFilterChange = useDebounce(handleFilterChange);

    function handleFilterChangeDebounced(values) {
        debouncedHandleFilterChange(values);
    }

    function handleSort(field, descending) {
        setSort({field, descending});
    }

    function handlePageChange(newPage) {
        setCurrentPage(newPage);
    }

    function saveScrollPosition() {
        var pageState = Object.assign({}, savedState, {
            scrollPosition: document.body.scrollTop || document.documentElement.scrollTop
        });
        actions.saveBatchLibraryPageState(pageState);
    }

    function handleProcess(selectedFiles) {
        setSelectedLibraryFiles(selectedFiles);
        setShowProcessDialogs(true);
    }


    return (
        <div className='batch-library-files'>
            <Form initialValues={{...filter}} render={(context) => (<>
                <div className='row filter'>
                    <InputField className='col-xs-12 col-sm-6 col-lg-4'
                        name='filename'
                        label={labels.batchFilename}
                        onChange={val => handleFilterChangeDebounced({...context.values, filename: val})}
                    />
                    <DatePickerField className='col-xs-12 col-sm-6 col-lg-4'
                        name='updatedDate'
                        label='Updated after'
                        onChange={() => handleFilterChangeDebounced(context.values)}
                    />
                </div>
            </>)}/>

            {listErrors && <FormError apiErrors={listErrors} />}

            <BatchLibraryFileList data={_.get(libraryFilesResult, 'items', [])}
                isLoading={isLoading}
                sortable
                sort={sort}
                onSort={handleSort}
                onLinkClick={saveScrollPosition}
                onProcess={handleProcess}
            />

            <PaginationControl
                currentPage={currentPage}
                onPageChanged={handlePageChange}
                itemCount={_.get(libraryFilesResult, 'count', 0)}
                itemsPerPage={resultsPerPage}
            />

            <ProcessBatchDialogs show={showProcessDialogs}
                files={selectedLibraryFiles}
                onClose={() => setShowProcessDialogs(false)}
                isTemplate
            />

        </div>
    );
};

function mapStateToProps(state, ownProps) {
    return {
        savedState: state.transactions.batch.batchLibraryPageState,
        isLoading: state.transactions.batch.isLoading,
        libraryFilesResult: state.transactions.batch.batchLibraryFilesResult,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(batchActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(BatchLibraryFiles);