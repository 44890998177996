export class RegexUtil {
    /** Returns the Regular Expression for validation of an e-mail.
     * Sourced from: https://github.com/jquense/yup (including //eslint line)
     */
    getEmailRegex() {
        // eslint-disable-next-line
        return /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i;
    }

    /** Returns the Regular Expression for validation of a phone number.
     * TODO: Stricter validation pre-requisites for this.
     */
    getPhoneRegex() {
        return /^[+()\- 0-9]{8,}$/g;
    }

    /** Regex for the BSB part of a bank account details (Currently has to be 6 digits) */
    bsbRegex = /^\d{6}$/;

    /** Regex for the 'Account number' part of a bank account details (Currently has to be between 3-9 digits) */
    accountNumberRegex = /^\d{3,9}$/;

    /** For validating a CSS color (hex only), eg. #000 or #9a81ca */
    colourHexRegex = /^#(?:[0-9a-f]{3}){1,2}$/i;

    /** For validating a CSS dimension */
    cssDimensionRegex = /^\d+(px|em)$/i;
}

const regexUtil = new RegexUtil();
export default regexUtil;
