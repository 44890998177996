import errorUtil from "@premier/utils/error";
import { ServerError } from "@premier/ui";
import { ApiError } from "packages/webapi-client";

type Props = {
    /** An array of error messages (NOT the error objects from the state) */
    errors?: (string | undefined)[];
    /** An array of error objects from API */
    apiErrors?: ApiError[];
}

/** This is for error message(s) coming from the server */
const FormError = ({
    errors, apiErrors //errors
}: Props) => {
    if (!errors && apiErrors) {
        if (Array.isArray(apiErrors))
            errors = apiErrors.map((err) => errorUtil.getMessage(err));
        else
            console.error(apiErrors);  // Probably JavaScript error
    }

    return <ServerError errors={errors} />;
};

export default FormError;
