import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Modal } from '@premier/ui';
import { TokenDetailsForm, PaymentMethodRequestPendingModal } from 'components/DataVault';

import { PaymentMethodDetailsTabKeys } from 'constants/billpay';
import * as tokenActions from 'components/DataVault/_actions/tokenActions';
import * as paymentRequestActions from 'components/PaymentRequests/_actions/paymentRequestActions';
import PaymentRequestActionTypes from 'components/PaymentRequests/_actions/paymentRequestActionTypes';
import { useLocation } from "react-router-dom";
import { PlatformRoutesConfiguration } from "components/Routing";

/** Modal for adding a payment method to another resource. E.g. for the New Schedule Page */
const AddPaymentMethodModal = ({
    show, //logic renders
    onCancel, onAdd, //functions
    parentBiller, paymentMethod, merchant, errors, customerId, //data
    actions, paymentRequestActions, //API actions
}) => {

    const [sentPaymentMethodRequestId, setSentPaymentMethodRequestId] = useState();
    const isSubscriptionsFromPaymentMethodPage = useLocation().pathname.startsWith(PlatformRoutesConfiguration.tokenRoute.manageTokens.path);

    function handleSubmit(formData) {
        if (formData['paymentMethodDetailsTab'] === PaymentMethodDetailsTabKeys.CREATE) {
            actions.addToken(formData).then((token) => {
                onAdd(token);
            });
        } else {
            var paymentDetails = {
                ...formData.paymentRequest,
                billerCodeForm: {
                    billerCode: formData.tokenDetailsBillerCodeForm.billerCode,
                    childMerchantNumber: formData.tokenDetailsBillerCodeForm.merchantNumber,
                    billerCrnList: formData.tokenDetailsBillerCodeForm.billerCrnList,
                },
                currencyCode: merchant.currency.code,
                action: PaymentRequestActionTypes.TokeniseOnly,
                customerId
            };
            paymentRequestActions.create(paymentDetails).then((request) => {
                setSentPaymentMethodRequestId(request.guid);
            });
        }
    }

    function handleRequestPendingClose() {
        if (sentPaymentMethodRequestId && isSubscriptionsFromPaymentMethodPage) {
            paymentRequestActions.get(sentPaymentMethodRequestId).then(async (request) => {
                if (request.dataVaultId) {
                    const token = await actions.getToken(request.dataVaultId);
                    onAdd(token);
                }
            });
        }
        setSentPaymentMethodRequestId();
        if (onCancel) onCancel();
    }

    if (sentPaymentMethodRequestId) {
        return <PaymentMethodRequestPendingModal
            paymentMethodRequestId={sentPaymentMethodRequestId}
            onClose={() => handleRequestPendingClose(true)}
        />
    }

    return (
        <Modal
            show={show}
            onHide={onCancel}
            dialogClassName='modal-token'
        >
            <Modal.Header closeButton>
                <Modal.Title>New payment method</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <TokenDetailsForm token={paymentMethod} parentBiller={parentBiller} hideBillerSelector errors={errors} submitButtonText='Add' onSubmit={handleSubmit} onCancel={onCancel} />
            </Modal.Body>
        </Modal>
    );
}

AddPaymentMethodModal.propTypes = {
    /** property to display or hide modal */
    show: PropTypes.bool.isRequired,
    /** property to pre-set Payment Method */
    paymentMethod: PropTypes.object,
    /** property to pre-set a biller on a Payment Method */
    parentBiller: PropTypes.shape({
        billerCode: PropTypes.string,
        acceptedCrn1: PropTypes.object,
        acceptedCrn2: PropTypes.object,
        acceptedCrn3: PropTypes.object,
    }),
    /** property to pre-set a customerId on a Payment Method */
    customerId: PropTypes.number,
    /** function to close modal from calling page */
    onCancel: PropTypes.func.isRequired,
    /** function called when token is added */
    onAdd: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {
        errors: state.dataVault.token.errors,
        merchant: state.accounts.users.merchant,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(tokenActions, dispatch),
        paymentRequestActions: bindActionCreators(paymentRequestActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddPaymentMethodModal);