import { useState, useEffect, useRef } from "react";
import { Button, FileSelector } from "@premier/ui";
import { RadioField, FormDialog } from "@premier/form";
import { UploadErrorDialog } from "components/Batch";
import { Error } from "components/Batch/models/BatchFileResult";
import { FocusSelectorRef } from "packages/ui/src/FileSelector";

type Props = {
    show: boolean;
    append?: boolean;
    uploadNow: boolean;
    onCancel: () => void;
    onManual: () => void;
    onUpload: (files: FileList) => void;
}

/** "New batch file" dialog to select the method of creating a new batch file (upload / manual) */
const MethodDialog = ({
    show, append, uploadNow, //logic renders
    onCancel, onManual, onUpload //functions
}: Props) => {

    const [method, setMethod] = useState("upload");
    const [filename, setFilename] = useState("");
    const [errors, setErrors] = useState<Error[]>([]);
    const fileSelectRef = useRef<FocusSelectorRef>(null);

    useEffect(() => {
        if (uploadNow)
            handleRetry();
    }, [uploadNow]);

    let title = "";
    let text = "";
    let manualEntryButtonText = "";

    if (append) {
        title = "Add records";
        text = "Choose an option to add batch records";
        manualEntryButtonText = "Add";
    } else {
        title = "New batch file";
        text = "Choose an option to create your new batch file";
        manualEntryButtonText = "Create";
    }

    function handleRadioChange(option: string) {
        setMethod(option);
    }

    function handleOk() {
        if (!method) { // cancelled
            handleCancel();
            return;
        }

        if (method === "upload") {
            fileSelectRef.current?.open();  // Open file select dialog
        } else {  // manual create
            onManual();
        }
    }

    function handleFileSelect(files: FileList) {
        setFilename(files[0].name);  // We only support uploading 1 file at a time, but it can be a zip that contains multiple files

        if (!/^[\w-. ]{1,100}$/i.test(files[0].name)) {
            setErrors([{ code: "INVALID_BATCH_FILENAME", parameter: "File.Filename" }]);
        }
        else if (!/\.(dat|txt|csv|zip)$/i.test(files[0].name)) {
            setErrors([{ code: "INVALID_BATCH_FILE_EXTENSION", parameter: "File.Filename" }]);
        }
        else {
            onUpload(files);
        }
    }

    function handleRetry() {
        setErrors([]);
        fileSelectRef.current?.open();  // Open file select dialog
    }

    function handleCancel() {
        setMethod("upload");
        setErrors([]);
        onCancel();
    }


    return (<>
        <FormDialog show={show}
            closeButton
            onClose={handleCancel}
            className="method-dialog"
            title={title}
            initialValues={{ method: method }}
            renderButtons={context => (<>
                <Button onClick={handleOk}>
                    {method === "upload" ? "Choose file" : manualEntryButtonText}
                </Button>
            </>)}
        >
            <RadioField name="method"
                label={text}
                options={[
                    { label: "Upload existing batch file", value: "upload" },
                    { label: "Manually enter batch records", value: "manual" },
                ]}
                onChange={handleRadioChange}
            />
        </FormDialog>

        <FileSelector ref={fileSelectRef} onSelect={handleFileSelect} />

        <UploadErrorDialog show={show && errors.length > 0}
            filename={filename}
            errors={errors}
            onRetry={handleRetry}
            onCancel={handleCancel}
        />
    </>);
};

export default MethodDialog;
