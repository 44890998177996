import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import KeyCode from 'keycode-js';
import _ from 'lodash';
import { Input } from '@premier/ui';
import FormGroup from './FormGroup';
import FormContext from '../FormContext';
import withField from './withField';
import { validate } from '../validation/fieldValidation';
import './CardExpiryField.scss'

const CardExpiryField = ({formProps, groupProps, onBlur, required, disabled, defaultValue}) => {

    const { value, setValue, error } = formProps;
    const { setValidation } = useContext(FormContext);

    const [id] = useState(_.uniqueId(`${formProps.name}_`));
    const groupClassName = 'expiry-wrapper';

    useEffect(() => {
        if (required) {
            setValidation(groupProps.name,
                validate()
                    .requiredIf(() => required)
                    .cardExpiryMonth(undefined, defaultValue)
                    .cardExpiryYear(undefined, defaultValue)
            );
        } else {
            setValidation(groupProps.name,
                validate()
                    .requiredIf(() => required)

            );
        }
    }, [required]);


    function handleFieldChanged(fieldName) {
        return e => {
            setValue({ ...value, [fieldName]: e.target.value });
        };
    }

    function handleMaxLengthReached(e) {
        e.target.nextSibling.focus();
    }

    function handleBlur(e) {
        // Do not call onBlur if we're still here (eg. on the Year field)
        if(!_.get(e, 'relatedTarget.parentNode.className', '').includes(groupClassName)) {
            onBlur(e);
        }
    }

    function handleYearKeyDown(e) {
        if (e.target.value.length === 0 && e.keyCode === KeyCode.KEY_BACK_SPACE) {
            e.target.previousSibling.focus();
        }
    }

    return (
        <FormGroup
            fieldId={id}
            {...groupProps}
            className={classNames(groupClassName, groupProps.className)}
            mandatory={required}
        >
            <Input
                id={id}
                digitsOnly
                placeholder='MM'
                autoComplete='cc-exp-month'
                maxLength={2}
                value={value ? value.month : ''}
                onChange={handleFieldChanged('month')}
                onMaxLengthReached={handleMaxLengthReached}
                onBlur={handleBlur}
                error={error}
                aria-label='Expiration month'
                aria-required={groupProps.mandatory}
                aria-invalid={!!formProps.error}
                disabled={disabled}
            />
            <Input
                digitsOnly
                placeholder='YY'
                autoComplete='cc-exp-year'
                maxLength={2}
                value={value ? value.year : ''}
                onChange={handleFieldChanged('year')}
                onKeyDown={handleYearKeyDown}
                onBlur={handleBlur}
                error={error}
                aria-label='Expiration year'
                aria-required={groupProps.mandatory}
                aria-invalid={!!formProps.error}
                disabled={disabled}
            />
        </FormGroup>
    );
};

CardExpiryField.propTypes = {
    name: PropTypes.string.isRequired,
    required: PropTypes.bool
};

export default withField(CardExpiryField);
