import React from "react";
import { Textarea, FormGroup } from "@premier/ui";

import "./InvoiceSettingsCustomerNotesForm.scss";

interface Props {
    value: string;
    numChar: number;
    errorMsg: string;
    onChange: (value: string) => void;
}

const InvoiceSettingsCustomerNotesForm: React.FC<Props> = ({
    value,
    numChar,
    errorMsg,
    onChange
}) => {

    return (
        <div className="invoice-settings-input-field">
            <FormGroup fieldId={"invoice_customer_notes"} error={errorMsg}>
                <Textarea
                    id={"invoice_customer_notes_input"}
                    value={value}
                    error={!!errorMsg}
                    onChange={(e) => onChange(e.target.value)}
                />
                {!errorMsg && (
                    <p className={"invoice-notes-characters-remaining"}>
                        {numChar} characters remaining
                    </p>
                )}
            </FormGroup>
        </div>
    );
};

export default InvoiceSettingsCustomerNotesForm;
