import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Navigate } from 'react-router-dom';
import currencyUtil from '@premier/utils/currency';

import { Form, InputField, DropdownField, SubmitButton, validate } from '@premier/form';
import { PageSection, ErrorText, Link, Dialog, Icon, Button } from '@premier/ui';
import { PageHeader, FormError } from 'components/Common';
import { AddRecordsForm } from 'components/Batch';

import * as batchActions from 'components/Batch/_actions/batchActions';
import { PlatformRoutesConfiguration } from 'components/Routing';

const fileTypeOptions = [
    {
        value: 'csv',
        label: 'CSV'
    },
    {
        value: 'txt',
        label: 'TXT'
    },
    {
        value: 'dat',
        label: 'DAT'
    }
];

const BatchAddRecords = ({ actions }) => {
    const [errors, setErrors] = React.useState([]);
    const [formErrors, setFormErrors] = React.useState([]);
    const [uploadedFile, setUploadedFile] = React.useState(null);
    const [isExiting, setIsExiting] = React.useState(false);
    const [hasUnsavedChanges, setHasUnsavedChanges] = React.useState(false);

    const batchErrorFormat = /Batches\[(\d+)\].Records\[(\d+)\].(.+)/;

    function parseErrors(err) {
        return err.map(e => {
            let match = e.parameter.match(batchErrorFormat);
            return {
                batchId: match[1],
                recordId: match[2],
                property: match[3]
            };
        });
    }

    function handleSubmit(values) {
        if (!values.fileName) {
            return;
        }

        let records = [];
        for (let prop in values.records) {
            //Use no batch actions as a flag the row is empty (untouched).
            if (values.records[prop] && !values.records[prop].noBatchActions) {
                records.push(Object.assign({}, { ...values.records[prop], id: prop }));
            }
        }

        if (records.length === 0) return;

        let batchFile = {
            filename: `${values.fileName}.${values.extension}`,
            batches: [
                {
                    records: records.map(r => {
                        return { ...r, amount: currencyUtil.convertToApiValue(r.amount) };
                    })
                }
            ]
        };

        actions
            .createBatchLibraryFile(batchFile)
            .then(result => {
                setUploadedFile(result);
            })
            .catch(errors => {
                let recordErrors = errors.filter(e => e.parameter && e.parameter.match(batchErrorFormat));
                setFormErrors(errors.filter(e => !(e.parameter && e.parameter.match(batchErrorFormat))));
                setErrors(parseErrors(recordErrors));
            });
    }

    /*{usePrompt('You have unsaved changes, are you sure you want to leave?', hasUnsavedChanges && !isExiting)} */
    return  (
        <>
            {uploadedFile && <Navigate to={PlatformRoutesConfiguration.transactionRoute.batchLibraryFile.generatePath(uploadedFile)} />}
            <PageSection>
                <PageHeader
                    backButton={hasUnsavedChanges ? {
                        onClick: () => setIsExiting(true),
                        label: 'Back to batch payments'
                    } : {
                        to: PlatformRoutesConfiguration.transactionRoute.batchPayments.generatePath(),
                        label: 'Back to batch payments'
                    }}
                />
            </PageSection>

            <Form
                initialValues={{
                    fileName: '',
                    extension: 'csv',
                    records: {}
                }}
                initialValidation={{
                    fileName: validate().required()
                }}
                onSubmit={handleSubmit}
                submitOnError
            >
                <PageSection>
                    <div className='row'>
                        <div className='col-sm-6 col-lg-4'>
                            <InputField noLabels name='fileName' placeholder='Batch file name'></InputField>
                        </div>
                        <div className='col-sm-3 col-lg-2'>
                            <DropdownField
                                inline
                                noLabels
                                name='extension'
                                options={fileTypeOptions}
                            ></DropdownField>
                        </div>
                    </div>
                    <FormError apiErrors={formErrors}></FormError>
                </PageSection>

                <PageSection>
                    <h2>Batch records</h2>
                    <AddRecordsForm
                        errors={errors}
                        onUnsavedRecordsChanged={e => {
                            setHasUnsavedChanges(e);
                        }}
                    ></AddRecordsForm>
                </PageSection>

                <PageSection>
                    <SubmitButton>Save</SubmitButton>
                    {hasUnsavedChanges ? (
                        <Button onClick={() => setIsExiting(true)}>Cancel</Button>
                    ) : (
                        <Link button to={PlatformRoutesConfiguration.transactionRoute.batchPayments.generatePath()} >
                            Cancel
                        </Link>
                    )}
                    {errors && errors.length > 0 && (
                        <ErrorText>
                            One or more records have errors or are incomplete. See highlighted fields. Please correct
                            the errors and click ‘Save’ button again
                        </ErrorText>
                    )}
                </PageSection>

                <Dialog
                    show={isExiting}
                    closeButton
                    onClose={() => setIsExiting(false)}
                    icon={<Icon alert />}
                    title='Changes to batch file are not saved'
                    footerButtons={<>
                        <Button primary onClick={() => setIsExiting(false)}>Continue editing</Button>
                        <Link subtle to={PlatformRoutesConfiguration.transactionRoute.batchPayments.generatePath()}>Discard changes</Link>
                    </>}
                >
                    Leaving this page without saving will discard your unsaved changes.
                </Dialog>
            </Form>
        </>
    );
};

function mapStateToProps(state, ownProps) {
    return {};
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(batchActions, dispatch)
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BatchAddRecords);
