import PropTypes from "prop-types";

import uiUtil from "@premier/utils/ui";
import { Accordion, Divider } from "@premier/ui";
import { InputField, PhoneNumberField, RadioField, CardExpiryField, DateRangePresetField, DateRangeEnum, MaskedCardNumberField } from "@premier/form";
import { withAdvancedFilter } from "components/Common";
import labels from "constants/labels";

const TokenFilter = ({
    context, // from withAdvancedFilter
    onAutoUpdate,
}) => {

    const cardExpiryOptions = uiUtil.addAllOption([
        { value: "expired", label: "Expired cards only" }
    ]);

    return (
        <>
            <h1>Filter payment methods</h1>

            <Accordion title="By card details" enableDownMd>
                <div className="row">
                    <div className="col-lg-6 col-xl-4">
                        <MaskedCardNumberField
                            name="maskedCardNumber"
                            label={labels.maskedCardNumber} />
                    </div>
                    <div className="col-lg-6 col-xl-4">
                        <CardExpiryField name="expiryDate" label={labels.expiryDate} />
                    </div>
                </div>
            </Accordion>
            <Divider />

            <Accordion title="By bank account" enableDownMd>
                <div className="row">
                    <div className="col-lg-4 col-xl-4">
                        <InputField name="deAccountName" label={labels.bankAccountName} />
                    </div>
                    <div className="col-lg-4 col-xl-4">
                        <InputField name="deBsbNumber" label={labels.bsb} />
                    </div>
                    <div className="col-lg-4 col-xl-4">
                        <InputField name="deAccountNumber" label={labels.bankAccountNumber} />
                    </div>
                </div>
            </Accordion>
            <Divider />

            <Accordion title="By token reference" enableDownMd>
                <div className="row">
                    <div className="col-lg-4 col-xl-4">
                        <InputField name="crn1" label={labels.tokenReference1} />
                    </div>
                    <div className="col-lg-4 col-xl-4">
                        <InputField name="crn2" label={labels.tokenReference2} />
                    </div>
                    <div className="col-lg-4 col-xl-4">
                        <InputField name="crn3" label={labels.tokenReference3} />
                    </div>
                </div>
            </Accordion>
            <Divider />

            <Accordion title="By last updated date" enableDownMd>
                <DateRangePresetField
                    name="tokenUpdatedDateRange"
                    label={labels.dateRange}
                    enableTime
                    presetOptions={[
                        { value: DateRangeEnum.TODAY,       label: "Today" },
                        { value: DateRangeEnum.YESTERDAY,   label: "Yesterday" },
                        { value: DateRangeEnum.LAST_30DAYS, label: "Last 30 days" },
                    ]}
                    onAutoUpdate={val => onAutoUpdate({ ...context.values, tokenUpdatedDateRange: val })}
                />
            </Accordion>
            <Divider />

            <Accordion title="By card expiry" enableDownMd>
                <RadioField inline name="expiredOnly" noLabels options={cardExpiryOptions} ariaLabel="Card expiry" />
            </Accordion>
            <Divider />

            <Accordion title="By customer" enableDownMd>
                <div className="row">
                    <div className="col-lg-4 col-xl-4">
                        <InputField name="customerUniqueId" label={labels.customerCode} />
                    </div>
                    <div className="col-lg-4 col-xl-4">
                        <InputField name="customerFirstName" label={labels.firstName} />
                    </div>
                    <div className="col-lg-4 col-xl-4">
                        <InputField name="customerLastName" label={labels.lastName} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-xl-4">
                        <InputField name="customerEmailAddress" label={labels.email} />
                    </div>
                    <div className="col-lg-8 col-xl-8">
                        <PhoneNumberField name="customerPhoneNumber" label={labels.phone} lessGapDownMd />
                    </div>
                </div>
            </Accordion>

        </>
    );
};

TokenFilter.propTypes = {
    onAutoUpdate: PropTypes.func.isRequired,

    // The rest of the props are in advancedFilterComponent.propTypes in withAdvancedFilter.js
};

export default withAdvancedFilter(TokenFilter);