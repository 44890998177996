import React, { useState, useEffect, ChangeEvent } from 'react';
import classNames from 'classnames';
import Input from './Input';
import Button from './Button';
import './PaginationControl.scss';


type Props = {
    /** Starts from 1 */
    currentPage: number,
    /** The total number of records available */
    itemCount: number,
    /** Number of records per page */
    itemsPerPage: number,
    onPageChanged: (newPageNum: number) => void,
    className?: string,
};

/** PaginationControl (eg. [1] of 4 pages) is shown on the right on LG+, otherwise centered. */
const PaginationControl = ({
    currentPage = 1, itemCount = 0, itemsPerPage, onPageChanged, className,
}: Props) => {

    const [currentInput, setCurrentInput] = useState(currentPage);

    const numPages = Math.ceil(itemCount / itemsPerPage);

    useEffect(() => {
        // Never show non-existing page (eg. page 5 out of 4) - Don't reset to page 1 in case a row is deleted
        if(itemCount && currentPage > numPages) {
            setCurrentInput(numPages);
            onPageChanged(numPages);
        }

        // this behaviour is in the wrong place, it should be lifted up to owner of the state
        // e.g. in a custom hook: const { page, setPage } = usePagination(itemCount, itemsPerPage)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemCount]);

    useEffect(() => {
        setCurrentInput(currentPage);
    }, [currentPage])

    if (!itemCount)
        return null;

    function handlePreviousClick() {
        var newValue = currentPage - 1;
        setCurrentInput(newValue);
        onPageChanged(newValue);
    }

    function handleNextClick() {
        var newValue = currentPage + 1;
        setCurrentInput(newValue);
        onPageChanged(newValue);
    }

    function handleInputChange(e: ChangeEvent<HTMLInputElement>) {
        setCurrentInput(parseInt(e.target.value));
    }

    function handleInputSubmit() {
        if (isNaN(currentInput) || currentInput < 1 || currentInput > numPages) {
            setCurrentInput(currentPage);  // restore
            return;
        }

        onPageChanged(currentInput * 1);
    }


    return (
        <div className={classNames('pagination-container', className)}>
            { currentPage > 1 && (
                <div className="page-arrow left-page-arrow"
                    title='Previous page'
                    onClick={handlePreviousClick}
                    role='button' tabIndex={0}
                ></div>
            )}

            <div className="page-selection-input-container">
                <Input
                    type='number'
                    digitsOnly
                    min={1}
                    max={numPages}
                    name="page-input"
                    className='textbox no-number-spinner page-selection-input'
                    value={currentInput}
                    onChange={handleInputChange}
                    onEnter={handleInputSubmit}
                    disabled={numPages <= 1}
                    aria-label='Page number'
                />&nbsp;
                of {numPages} pages
            </div>

            { currentPage < numPages && (
                <div className="page-arrow right-page-arrow"
                    title='Next page'
                    onClick={handleNextClick}
                    role='button' tabIndex={0}
                ></div>
            )}

            <Button
                subtle left
                className={classNames('go-button', {'d-none': numPages <= 1})}
                onClick={handleInputSubmit}
            >
                Go
            </Button>
        </div>
    );
}

export default PaginationControl;
