import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Accordion, IconText, Divider, Link, ButtonContainer } from "@premier/ui";
import { Form, FormErrorList, DropdownField, InputField, SubmitButton, PhoneNumberField, DatePickerField, TextareaField, EmailAddressField, AddressForm, } from "@premier/form";
import countryUtil from "@premier/utils/country";
import { FieldError } from "api/mapErrors";
import { RootState } from "store/store";

// @ts-ignore
import errorMaps from "constants/errorMaps";
// @ts-ignore
import labels from "constants/labels";
import { Merchant } from "models";
import { Customer } from "packages/utils/models";

type Props = {
    customer?: Customer;
    cancelTo: string | undefined;
    errors: FieldError[];
    loading: boolean;
    merchantCountryCode: number;
    merchantCurrencyCode: string;
    merchant: Merchant;
    onSubmit: (values: any) => Promise<void>;
}

const CustomerDetailsForm = ({
    customer, cancelTo, errors, loading, //data
    merchantCountryCode, merchantCurrencyCode, merchant, //state values
    onSubmit //functions
}: Props) => {

    const defaultValues = {
        addresses: [{ countryCode: merchantCountryCode }],
        phoneNumbers: [{ phoneNumber: { iddCode: countryUtil.getIddCode(merchantCountryCode) } }],
        currencyCode: merchantCurrencyCode
    };

    const [merchantOptions, setMerchantOptions] = useState<Merchant[]>([]);

    const parentMerchant = {
        merchantNumber: merchant.merchantNumber,
        merchantName: merchant.merchantName,
        isParent: true
    };

    useEffect(() => {
        const childMercs =
            merchant.childMerchants &&
            merchant.childMerchants.map(cm => ({
                merchantNumber: cm.merchantNumber,
                merchantName: cm.merchantName,
                isParent: false
            }));
        setMerchantOptions([parentMerchant].concat(childMercs || []));
    }, []);

    function getInitialValues() {
        if (customer && customer.dob)
            customer.dob = new Date(customer.dob);

        return customer
            ? { ...defaultValues, ...customer }
            : defaultValues;
    }

    function doOnSubmit(values: Customer) {
        if (values?.addresses) {
            values.addresses.forEach(a => {
                // If a pre-defined set of country states exists then clear the "state" text and keep the "stateId" index,
                // otherwise clear out the "stateId" and keep the "state" text.
                if (countryUtil.getStateOptions(a.countryCode ?? 0)?.length) {
                    a.state = undefined;
                } else {
                    a.stateId = undefined;
                }
            });
        }

        if (Array.isArray(values.dob)) {
            values.dob = values.dob[0];
        }

        onSubmit(values);
    }

    // renders AddressForms dependant on how many addresses a customer has
    function renderAddresses() {
        if (!customer || !customer.addresses?.length) {
            //return a single address when new customer, or customer has no addresses
            return [<AddressForm name="addresses[0]" />];
        }

        //returns an array of AddressForms that is rendered out, based on how many addresses a customer has.
        const addressRenderReturn = [];
        for (let i = 0; i < customer.addresses.length; i++) {
            const componentName = `addresses[${i}]`;
            //since context stores values from customer in an address array, we need to give the name of the form that array index element
            addressRenderReturn.push(<AddressForm name={componentName} defaultCountryCode={customer.addresses[i].countryCode ?? countryUtil.country.countryCode} />);
        }
        return addressRenderReturn;
    }

    // renders phoneNumberFields dependant on how many phone numbers a customer has
    function renderPhoneNumbers() {
        if (!customer || !customer.phoneNumbers?.length) {
            //return a single phoneNumberField when new customer, or customer has no phonenumbers
            return [
                <PhoneNumberField
                    name="phoneNumbers[0].phoneNumber"
                    label={labels.phoneNumber}
                    key={0}
                />
            ];
        }

        //returns an array of phoneNumberFields that is rendered out, based on how many phone numbers a customer has.
        const phoneRenderReturn = [];
        for (let i = 0; i < customer.phoneNumbers.length; i++) {
            const componentName = `phoneNumbers[${i}].phoneNumber`;
            phoneRenderReturn.push(
                <PhoneNumberField
                    name={componentName}
                    label={labels.phoneNumber}
                    key={i}
                />
            );
        }
        return phoneRenderReturn;
    }


    return (
        <Form
            errors={errors}
            errorMaps={errorMaps}
            initialValues={getInitialValues()}
            onSubmit={doOnSubmit}
        >
            {merchantOptions && merchantOptions.length > 1 && (
                <DropdownField
                    name="childMerchantNumber"
                    label={labels.merchant}
                    options={merchantOptions.map(m => ({ value: m.merchantNumber, label: m.merchantName }))}
                    defaultValue={parentMerchant.merchantNumber}
                />
            )}

            <InputField name="title" label={labels.salutation} />
            <InputField name="firstName" label={labels.firstName} />
            <InputField name="lastName" label={labels.lastName} />
            <EmailAddressField name="emailAddress" label={labels.emailAddress}/>

            {renderPhoneNumbers()}
            {renderAddresses()}

            <Divider />

            <Accordion initiallyExpanded title="Other information">
                <DatePickerField name="dob" label={labels.dateOfBirth} />
                <InputField name="crn1" label={labels.customerId1} />
                <InputField name="crn2" label={labels.customerId2} />
                <InputField name="crn3" label={labels.customerId3} />

                <h4>Note</h4>
                <TextareaField
                    name="note"
                    label={<IconText info>Please do not provide card numbers here</IconText>}
                    labelText="Note"
                />
            </Accordion>

            <ButtonContainer>
                <SubmitButton loading={loading}>{customer ? "Save" : "Create"}</SubmitButton>
                <Link button to={cancelTo}>Cancel</Link>
            </ButtonContainer>

            <FormErrorList/>
        </Form>
    );
};

function mapStateToProps(state: RootState) {
    return {
        loading: state.accounts.users.isLoading,
        merchant: state.accounts.users.merchant,
        merchantCountryCode: state.accounts.users.merchant.countryCode,
        merchantCurrencyCode: state.accounts.users.merchant.currency.code || state.accounts.users.configuration.defaultCountryCode
    };
}

export default connect(mapStateToProps)(CustomerDetailsForm);
