import { useEffect, useState, useMemo, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import classNames from "classnames";

import windowUtil from "@premier/utils/window";
import errorUtil from "@premier/utils/error";
import labels from "constants/labels";
import { defaultPageSize, defaultPageSizeOptions } from "constants/billpay";

import { PageSection, Button, BackToTop, PaginationControl, CardContainer, LoadingIndicator, Link } from "@premier/ui";
import { Form } from "@premier/form";
import { TokenList, TokenFilter } from "components/DataVault";
import UploadTokenBatchDialogs from "../components/UploadTokenBatchDialogs";
import ExportTokensDialog from "../components/ExportTokensDialog";
import TokensGettingStarted from "../components/TokensGettingStarted";

import { withError, PageHeader, FormError, MerchantPageCountDropdowns, ProductTooltip } from "components/Common";

import * as tokenActions from "components/DataVault/_actions/tokenActions";
import * as accountActions from "components/Account/_actions/accountActions";
import { PlatformRoutesConfiguration } from "components/Routing";

import "./ManageTokensPage.scss";

const ManageTokensPage = ({
    savedState, isLoading, searchResult, tokenCount, //state values
    actions, //API actions
    errors //form
}) => {

    const [showFilter, setShowFilter] = useState(false);
    const [filter, setFilter] = useState(null);  // A live copy of the form values
    const [lastSavedFilter, setLastSavedFilter] = useState({});
    const [sort, setSort] = useState(_.get(savedState, "sort", { field: "Updated", descending: true }));
    const [reload, setReload] = useState(false);
    const [resultsPerPage, setResultsPerPage] = useState(_.get(savedState, "resultsPerPage", defaultPageSize));
    const [currentPage, setCurrentPage] = useState(_.get(savedState, "pageIndex", 0) + 1);
    const [showExportDialog, setShowExportDialog] = useState(false);

    const fileSelectRef = useRef(undefined);

    // On page load, fetch things from API, get tokens and restore previous state (page number, scroll position, filters) if any
    useEffect(() => {
        if (savedState) {
            if (savedState.scrollPosition)
                windowUtil.scrollTo(savedState.scrollPosition); // Scroll back to previous position

            handleFilterChange(savedState.filter, false);
        } else {
            handleFilterChange(defaultFilter);
        }
    }, [actions]);

    useEffect(() => {
        if (reload) {
            actions.getTokens(resultsPerPage, currentPage - 1, filter, sort);
            setReload(false);
        }
    }, [reload]);

    //#region ========== Filter stuff ==========

    const defaultFilter = {
        childMerchantNumber: "",
        token: "",
        customerUniqueId: "",
        customerFirstName: "",
        customerLastName: "",
        customerEmailAddress: "",
        maskedCardNumber: { prefix: "", suffix: "" },
        expiryDate: { month: "", year: "" },
        crn1: "",
        crn2: "",
        crn3: "",
        deBsbNumber: "",
        deAccountNumber: "",
        deAccountName: "",
        accountName: "",
        tokenUpdatedDateRange: {},
        expiredOnly: "",
    };

    /** Get the number of applied filters in the Advanced Filter */
    const filterCount = useMemo(() => {
        if (!filter) return 0;

        const { childMerchantNumber, tokenUpdatedDateRange, ...otherDefaultValues } = defaultFilter;

        let count = 0;
        if (_.get(filter.tokenUpdatedDateRange, "dates.length") || _.get(filter.tokenUpdatedDateRange, "presetValue"))
            count++;

        Object.keys(otherDefaultValues).forEach(key => {
            if (!_.isEqual(filter[key], defaultFilter[key]))
                count++;
        });

        return count;
    }, [filter]);

    /** This is called when a component auto-updates it's internal value (ie. DateRangePreset updates the dates/presetValue based on the other) */
    function handleFilterUpdate(newFilter) {
        if (!_.get(filter, "tokenUpdatedDateRange.dates"))  // Initially when we don't have dates yet, call API to populate results
            handleFilterChange(newFilter);
        else   // Otherwise just update the filter without calling API
            setFilter(newFilter);
    }

    function handleFilterChange(newFilter, resetPage = true) {  // Note: This is also called at first render (from either useEffect or DateRangePresetField completing the dates)
        if (resetPage)
            setCurrentPage(1);

        setFilter(newFilter);
        setLastSavedFilter(newFilter);
        setReload(true);
    }

    //#endregion


    function handleSort(field, descending) {
        setSort({ field, descending });
        setReload(true);
    }

    function pageChanged(newPage) {
        setCurrentPage(newPage);
        setReload(true);
    }

    function resultsPerPageChanged(newResultsPerPage) {
        setResultsPerPage(newResultsPerPage.value);
        setCurrentPage(1);
        setReload(true);
    }

    function saveScrollPosition() {
        const pageState = Object.assign({}, savedState, {
            scrollPosition: document.body.scrollTop || document.documentElement.scrollTop
        });
        actions.saveTokensPageState(pageState);
    }

    function getTokens() {
        //hides tokens if errors in the token search
        return errors.length ? null : _.get(searchResult, "items", []);
    }

    // ========== Render ==========
    return (<>
        {isLoading && (
            <CardContainer header="Loading payment methods">
                <LoadingIndicator />
            </CardContainer>
        )}

        <UploadTokenBatchDialogs fileSelectRef={fileSelectRef} />

        {!isLoading && tokenCount === 0 && (<>
            <TokensGettingStarted onClickImport={() => { fileSelectRef.current.open(); }} />
        </>)}

        {tokenCount > 0 && (
            <Form initialValues={(savedState && savedState.filter) || defaultFilter}>
                <div className={classNames("manage-tokens-page", { "hidden": showFilter })} aria-hidden={showFilter}>
                    <PageSection>
                        <PageHeader title={<>{"Payment methods"}<ProductTooltip productTooltipModule={"PAYMENT_METHODS_DATAVAULT"} /></>}>
                            <Link button primary to={PlatformRoutesConfiguration.tokenRoute.newToken.generatePath()}>New payment method</Link>
                        </PageHeader>

                        <Button subtle onClick={() => { setShowFilter(true); }}>View filters ({filterCount} applied)</Button>
                        <Button subtle onClick={() => { setShowExportDialog(true); }}>Export tokens</Button>
                        <Button subtle onClick={() => { fileSelectRef.current.open(); }}>Import tokens</Button>

                        <MerchantPageCountDropdowns
                            resultsPerPageOptions={defaultPageSizeOptions}
                            resultsPerPage={resultsPerPage}
                            onChildMerchantChanged={(val, context) => handleFilterChange(context.values)}
                            onResultsPerPageChanged={resultsPerPageChanged}
                        />

                        <FormError errors={errors} />

                        <TokenList
                            // For the quick filter
                            savedFilter={savedState && savedState.filter}
                            onFilterChange={handleFilterChange}
                            // For the table/list
                            sort={sort}
                            onSort={handleSort}
                            tokens={getTokens()}
                            onLinkClick={saveScrollPosition}
                            isLoading={isLoading}
                        />

                        <PaginationControl currentPage={currentPage} onPageChanged={pageChanged} itemCount={_.get(searchResult, "count", 0)} itemsPerPage={resultsPerPage} />
                    </PageSection>

                    <BackToTop />
                </div>



                <TokenFilter
                    show={showFilter}
                    onHide={() => setShowFilter(false)}
                    defaultFilter={defaultFilter}
                    lastSavedFilter={lastSavedFilter}
                    onAutoUpdate={handleFilterUpdate}
                    onChange={handleFilterChange}
                />

                <ExportTokensDialog
                    show={showExportDialog}
                    onClose={() => setShowExportDialog(false)}
                    filter={filter}
                />
            </Form>
        )}
    </>);
};


function mapStateToProps(state) {
    return {
        savedState: state.dataVault.token.tokensPageState,
        isLoading: state.dataVault.token.isLoading,
        searchResult: state.dataVault.token.searchResult,
        tokenCount: state.dataVault.token.tokenCount
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(tokenActions, dispatch),
        accountActions: bindActionCreators(accountActions, dispatch)
    };
}

function mapStoreToErrors(state) {
    return state.dataVault.token.errors?.filter(e => e.code !== "INVALID_BATCH_FILE_EXTENSION" && e.code !== "INVALID_BATCH_FILENAME");
}

function mapErrorToString(error) {
    const paramLabels = {
        "DeBsbNumber": labels.bsb,
        "Request.Token": "Token",

        //Filter - Customer
        "Request.CustomerUniqueId": labels.customerCode,
        "Request.CustomerFirstName": "First name",
        "Request.CustomerLastName": "Last name",
        "Request.CustomerEmailAddress": labels.email,
        "Request.CustomerPhoneNumber": labels.phoneNumber,

        //Filter - Card / Bank details
        "Request.MaskedCardNumber": labels.cardNumber,
        "Request.DeBsbNumber": "BSB",
        "Request.DeAccountNumber": "Account number",
        "Request.AccountName": "Account name",

        //Filter - CRN
        "Request.Crn1": labels.tokenReference1,
        "Request.Crn2": labels.tokenReference2,
        "Request.Crn3": labels.tokenReference3,

    };

    return errorUtil.getMessage(error, paramLabels);
}

export default withError(
    connect(mapStateToProps, mapDispatchToProps)(ManageTokensPage),
    mapStoreToErrors,
    mapErrorToString
);
