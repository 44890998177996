import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { Link } from '@premier/ui';
import dateUtil from '@premier/utils/date';
import { absolute } from '@premier/utils/url';

import { TransactionStatusIcon } from 'components/Transactions';
import BaseTransactionDetailCategory from './BaseTransactionDetailCategory';

const TransactionResultCategory = ({ transaction }) => {

    var transactionResult = [
        { name: 'Action', value: _.get(transaction, 'paymentSource.transactionTypeDetails.description') },
        { name: 'Result', value: <TransactionStatusIcon transaction={transaction} /> },
        { name: 'Result description', value: transaction.paymentSource && transaction.paymentSource.authorisationResult },
        { name: 'Response code', value: transaction.paymentProcessedTxnModel && transaction.paymentProcessedTxnModel.responseCode },
        { name: 'Bank response code', value: _.get(transaction, 'paymentSource.acqResponseCode') },
        { name: 'Transaction date', value: dateUtil.convertToDateTimeString(transaction.paymentProcessedTxnModel.processedDate) },
    ];

    if (transaction.paymentProcessedTxnModel.settlementDate && !['PREAUTH', 'VERIFY'].includes(transaction.paymentProcessedTxnModel.txnFinancialType)) //TODO rik create constants for enum values
        transactionResult.push({ name: 'Settlement date', value: dateUtil.convertToDateString(transaction.paymentProcessedTxnModel.settlementDate) });

    return (
        <BaseTransactionDetailCategory title='Transaction result' items={transactionResult}>
            <Link to={absolute(process.env.REACT_APP_SITE_DOMAIN, 'developers/v3/#!#txnresponses')} newWindow>Click here for a full list of response codes</Link>
        </BaseTransactionDetailCategory>
    );
}

TransactionResultCategory.propTypes = {
    transaction: PropTypes.object.isRequired
};

export default TransactionResultCategory;
