import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { RootState } from "store/store";
import { Field, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { FormGroup, FormikDropdownOption, FormikSwitch, Input } from "packages/formik-ui";
import { APICallRequestState, MerchantDropdown, PageHeader, ProductTooltip, useApiCall } from "components/Common";
import { Button, Dialog, Icon, LoadingIndicator, SuccessModal, PageSection } from "packages/ui";
import { declineManagerApi } from "api";
import errorUtil from "packages/utils/error";
import { MerchantModel } from "packages/webapi-client";
import { userRoles } from "components/Routing";
import { getMerchantOptions } from "packages/utils/billpay";

type Props = {
    merchant: MerchantModel;
}

type FormValues = {
    merchantNumber: string;
    useDeclineManager: boolean;
    maxAttempts?: number;
    maxAttemptDays?: number;
    applyAllMerchants: boolean;
}

const DeclineManagerGeneralSettingsPage = ({ merchant }: Props) => {
    const navigate = useNavigate();
    const [selectedMerchantNumber, setSelectedMerchantNumber] = useState(merchant.merchantNumber);
    const [showWarningModal, setShowWarningModal] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [successModalText, setSuccessModalText] = useState("");

    const [settings, settingsStatus] = useApiCall(async () => {
        if (selectedMerchantNumber) {
            return declineManagerApi.getDelinceManagerSettings(selectedMerchantNumber);
        }
    }, [selectedMerchantNumber]);

    useEffect(() => {
        if (settingsStatus === APICallRequestState.SUCCESSFUL && settings) {
            formik.setValues({
                ...formik.values,
                useDeclineManager: settings.useDeclineManager ?? false,
                maxAttempts: settings.maxAttempts,
                maxAttemptDays: settings.maxAttemptDays,
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settingsStatus, settings]);

    const formik = useFormik<FormValues>({
        initialValues: {
            merchantNumber: merchant.merchantNumber ?? "",
            useDeclineManager: false,
            maxAttempts: 0,
            maxAttemptDays: 0,
            applyAllMerchants: false,
        },
        validationSchema: Yup.object().shape({
            merchantNumber: Yup.string().required(),
            maxAttempts: Yup.number().label("Maximum attempts").min(1).max(9).required(),
            maxAttemptDays: Yup.number().label("Maximum attempt period").min(1).max(31).required(),
        }),
        onSubmit: async (formValues: FormValues) => {
            if (formValues.applyAllMerchants) {
                setShowWarningModal(true);
                setSuccessModalText("All merchants general settings updated successfully");
            } else if (formValues.maxAttempts && formValues.maxAttemptDays) {
                const response = await declineManagerApi.saveDeclineManagerSettings(formValues.merchantNumber, formValues.maxAttempts, formValues.maxAttemptDays, formValues.useDeclineManager, formValues.applyAllMerchants);

                if (response.status === 200) {
                    setSuccessModalText("Merchant's general settings updated successfully");
                    setShowSuccessModal(true);
                } else if (response.data.errors) {
                    const errors = response.data.errors ? errorUtil.convertApiErrorsToFormikErrors<FormValues>(response.data.errors) : null;

                    if (errors) {
                        formik.setErrors(errors);
                    }
                }
            }
        }
    });

    useEffect(() => {
        setSelectedMerchantNumber(formik.values.merchantNumber);
    }, [formik.values.merchantNumber]);

    const hasDeclineManagerEnabledChildMerchants = useMemo(() => {
        return getMerchantOptions(merchant, false, userRoles.declineManager).length > 1;
    }, [merchant]);

    const handleMerchantNumberChange = (selectedOption: FormikDropdownOption) => {
        setSelectedMerchantNumber(selectedOption.value as string);
    };

    const handleWarningOkClick = async () => {
        setShowWarningModal(false);

        if (formik.values.maxAttempts && formik.values.maxAttemptDays) {
            await declineManagerApi.saveDeclineManagerSettings(formik.values.merchantNumber, formik.values.maxAttempts, formik.values.maxAttemptDays, formik.values.useDeclineManager, formik.values.applyAllMerchants);
            setShowSuccessModal(true);
        }
    };

    const handleWarningCancelClick = () => {
        setShowWarningModal(false);
    };

    const handleCancelClick = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        navigate(-1);
    };

    return (
        <PageSection>
            <PageHeader title="Decline general settings" subtitle={hasDeclineManagerEnabledChildMerchants ? "" : `Merchant ${merchant.merchantNumber}`} backButton />
            <FormikProvider value={formik}>
                <form onSubmit={formik.handleSubmit}>
                    {hasDeclineManagerEnabledChildMerchants &&
                        <FormGroup name="merchantNumber" label="Merchant">
                            <Field name="merchantNumber" as={MerchantDropdown} feature={userRoles.declineManager} onChange={handleMerchantNumberChange} />
                        </FormGroup>}
                    {settingsStatus === APICallRequestState.LOADING ? <LoadingIndicator /> : <>
                        <FormGroup name="useDeclineManager" label="Automatic retries enabled for this merchant">
                            <Field name="useDeclineManager" as={FormikSwitch} />
                        </FormGroup>
                        <FormGroup name="maxAttempts" label={<>Maximum attempts<ProductTooltip productTooltipModule={"DECLINE_MANAGER_MAX_ATTEMPTS"} /></>} mandatory>
                            <Field name="maxAttempts" type="number" as={Input} />
                        </FormGroup>
                        <FormGroup name="maxAttemptDays" label={<>Maximum attempt period (days)<ProductTooltip productTooltipModule={"DECLINE_MANAGER_MAX_ATTEMPT_DAYS"} /></>} mandatory>
                            <Field name="maxAttemptDays" type="number" as={Input} />
                        </FormGroup>
                        {hasDeclineManagerEnabledChildMerchants &&
                            <FormGroup name="applyAllMerchants" label="Apply to all merchants">
                                <Field name="applyAllMerchants" as={FormikSwitch} />
                            </FormGroup>
                        }
                        <Button type="submit" disabled={formik.isSubmitting} primary>Save</Button>
                        <Button type="submit" onClick={handleCancelClick}>Cancel</Button>
                    </>}
                </form>
            </FormikProvider>
            <Dialog icon={<Icon alert />} title="Warning" show={showWarningModal} footerButtons={<><Button onClick={handleWarningOkClick} primary>OK</Button><Button onClick={handleWarningCancelClick}>Cancel</Button></>}>
                You are about to update all Merchant I.D's with these settings. Do you want to proceed?
            </Dialog>
            <SuccessModal title={successModalText} show={showSuccessModal} onClose={() => setShowSuccessModal(false)} />
        </PageSection>
    );
};

function mapStateToProps(state: RootState) {
    return {
        merchant: state.accounts.users.merchant,
    };
}

export default connect(mapStateToProps)(DeclineManagerGeneralSettingsPage);
