import React from 'react';
import { Input, FormGroup } from '@premier/ui';

import './IServicesSection.scss';

interface TextObject {
    title: string;
    explanation: string;
    emailInputLabel: string;
}

interface Props {
    text: TextObject;
    email: string;
    numChar: number;
    maxLength: number;
    errorMessage: string;
    onChange: (e: { target: { value: string } }) => void;
    onSubmit: () => void;
    handleMaxLengthReached: () => void;
}

const IServicesEmailSection: React.FC<Props> = ({
    text,
    email,
    numChar,
    maxLength,
    errorMessage,
    onChange,
    onSubmit,
    handleMaxLengthReached,
}) => {
    return (
        <>
            <div className='iservices-section-heading'>
                <h2>{text.title}</h2>
            </div>
            <p>{text.explanation}</p>
            <div className='iservices-email-input-heading'>
                <FormGroup
                    fieldId={'iServicesEmail'}
                    label={text.emailInputLabel}
                    error={errorMessage}
                >
                    <Input
                        id={'iServicesEmail_Input'}
                        error={errorMessage}
                        value={email}
                        maxLength={maxLength + 1}
                        onMaxLengthReached={handleMaxLengthReached}
                        onChange={onChange}
                        onEnter={onSubmit}
                    />
                    {!errorMessage && (
                        <p className={'iservices-characters-remaining'}>
                            {numChar} characters remaining
                        </p>
                    )}
                </FormGroup>
            </div>
        </>
    );
};

export default IServicesEmailSection;
