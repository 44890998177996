import React from "react";
import _ from "lodash";
import { Tooltip, Icon } from "@premier/ui";
import { ProductTooltipModelProductModuleEnum, ProductTooltipModel } from "@premier/webapi-client";
import { APICallRequestState, useApiCall } from "components/Common";
import { contentManagementApi } from "api";
import { absolute } from "@premier/utils/url";

import "./ProductTooltip.scss";

interface ProductTooltipProps {
    productTooltipModule? : ProductTooltipModelProductModuleEnum; // Required if text is not provided. Key to fetch tooltip info from API.
    text?: string | React.ReactElement;                           // Required if productTooltipModule is not provided. Simple tooltip text if fetching tooltip from API is not required.
}

const ProductTooltip : React.FC<ProductTooltipProps> = ({ productTooltipModule, text }) => {
    const generateTooltipContent = (productTooltip : ProductTooltipModel) => {
        const parts = productTooltip?.content?.split("{}") ?? [];
        return <>
            {parts[0] ?? ""}
            {<a href={ absolute(process.env.REACT_APP_SITE_DOMAIN || "", productTooltip?.link ?? "") } target="_blank" rel="noopener noreferrer">
                <span>{ productTooltip?.linkText }</span>
            </a>}
            {parts[1] ?? ""}
        </>;
    };

    const [productTooltip, productTooltipStatus ] = useApiCall(() => {
        if (productTooltipModule) {
            return contentManagementApi.getProductTooltip({ productTooltipModule });
        }
    }, [productTooltipModule]);

    const id = _.uniqueId("tooltip");

    return (
        <>
            {productTooltipStatus === APICallRequestState.SUCCESSFUL && productTooltip?.enabled ?
                <>
                    {/* Product tooltip fetched from API */}
                    <Icon question data-tip data-for={productTooltipModule} className="product_tooltip_question_mark" />
                    <Tooltip className="product_tooltip_content" id={productTooltipModule}>{ generateTooltipContent(productTooltip) }
                        {productTooltip?.youtubeLink ? <iframe
                            width="560"
                            height="315" src={productTooltip.youtubeLink}
                            title={productTooltip?.linkText ?? "YouTube video player"}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen
                        /> : null }
                    </Tooltip>
                </> :
                <>
                    {/* Simple text tooltip */}
                    <Icon question data-tip data-for={id} className="product_tooltip_question_mark" />
                    <Tooltip className="product_tooltip_content" id={id}>{text}</Tooltip>
                </>
            }
        </>
    );
};

export default ProductTooltip;
