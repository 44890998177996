
export const GET_COUNTRY_LIST_REQUESTED = 'GET_COUNTRY_LIST_REQUESTED';
export const GET_COUNTRY_LIST_SUCCESS = 'GET_COUNTRY_LIST_SUCCESS';
export const GET_COUNTRY_LIST_FAILED = 'GET_COUNTRY_LIST_FAILED';

export const GET_COUNTRIES_PHONE_CODE_REQUESTED = 'GET_COUNTRIES_PHONE_CODE_REQUESTED';
export const GET_COUNTRIES_PHONE_CODE_SUCCESS = 'GET_COUNTRIES_PHONE_CODE_SUCCESS';
export const GET_COUNTRIES_PHONE_CODE_FAILED = 'GET_COUNTRIES_PHONE_CODE_FAILED';

export const GET_CURRENCIES_REQUESTED = 'GET_CURRENCIES_REQUESTED';
export const GET_CURRENCIES_SUCCESS = 'GET_CURRENCIES_SUCCESS';
export const GET_CURRENCIES_FAILED = 'GET_CURRENCIES_FAILED';

export const GET_PROCESS_TRANSACTION_ORDER_TYPES_SUCCESS   = 'GET_PROCESS_TRANSACTION_ORDER_TYPES_SUCCESS';
export const GET_PROCESS_TRANSACTION_ORDER_TYPES_FAILED    = 'GET_PROCESS_TRANSACTION_ORDER_TYPES_FAILED';
export const GET_PROCESS_TRANSACTION_ORDER_TYPES_REQUESTED = 'GET_PROCESS_TRANSACTION_ORDER_TYPES_REQUESTED';

export const GET_ORDER_TYPES_SUCCESS   = 'GET_ORDER_TYPES_SUCCESS';
export const GET_ORDER_TYPES_FAILED    = 'GET_ORDER_TYPES_FAILED';
export const GET_ORDER_TYPES_REQUESTED = 'GET_ORDER_TYPES_REQUESTED';
