import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import classNames from 'classnames';

import { Link, PageSection, CardContainer, LoadingIndicator, PaginationControl, BackToTop, Icon, ButtonContainer, Button } from '@premier/ui';
import { Form } from '@premier/form';
import { PageHeader, FormError, MerchantPageCountDropdowns, PromotionBoxesPageSection, ProductTooltip } from 'components/Common';
import { CustomerList, CustomerFilter } from 'components/DataVault';

import { defaultPageSize } from 'constants/billpay';

import { PlatformRoutesConfiguration } from 'components/Routing';
import * as customerActions from 'components/DataVault/_actions/customerActions';

const defaultFilter = {
    childMerchantNumber: '',
    uniqueId: '',
    name: '',
    emailAddress: '',
    phone: '',        // quick filter
    phoneNumber: '',  // advanced filter
    crn1: '',
    crn2: '',
    crn3: '',
    dob: null,
    postcode: '',
}

const ManageCustomersPage = ({
    actions, //API actions
    searchResult, customerCount, savedState, isLoading, //state values
    errors //form data
}) => {

    const resultsPerPage = defaultPageSize;
    const [showFilter, setShowFilter] = useState(false);
    const [lastSavedFilter, setLastSavedFilter] = useState({});
    const [currentPage, setCurrentPage] = useState(_.get(savedState, 'pageNumber', 1));
    const [filter, setFilter] = useState(_.get(savedState, 'filter', defaultFilter));  // A live copy of the form values
    const [sort, setSort] = useState(_.get(savedState, 'sort', { field: 'lastUpdated', descending: true }));

    const promotions = [
        <>
            <Icon person />
            <h2>Create profile</h2>
            <p>Manage your customer data including personal and contact details.</p>
        </>,
        <>
            <Icon card />
            <h2>Add payment method</h2>
            <p>Add one or more payment methods securely to a customer profile.</p>
        </>,
        <>
            <Icon money />
            <h2>Track payments</h2>
            <p>Keep track on each customer's past and future payment activities.</p>
        </>
    ]

    useEffect(() => {
        actions.getCustomers(resultsPerPage, currentPage, filter, sort);
    }, [filter, sort, resultsPerPage, currentPage]);


    //#region ========== Filter stuff ==========

    /** Get the number of applied filters in the Advanced Filter */
    const filterCount = useMemo(() => {
        if (!filter) return 0;

        const { childMerchantNumber, phone, ...otherDefaultValues } = defaultFilter;

        var count = 0;
        Object.keys(otherDefaultValues).forEach(key => {
            if (!_.isEqual(filter[key], defaultFilter[key]))
                count++;
        });

        return count;
    }, [filter]);

    function handleFilterChange(newFilter, resetPage = true) {  // Note: This is also called at first render (from either useEffect or DateRangePresetField completing the dates)
        if (resetPage)
            setCurrentPage(1);

        setFilter(newFilter);
        setLastSavedFilter(newFilter);
    }
    //#endregion

    function saveScrollPosition() {
        var pageState = Object.assign({}, savedState, {
            scrollPosition: document.body.scrollTop || document.documentElement.scrollTop
        });

        actions.saveCustomersPageState(pageState);
    }

    return (<>
        {isLoading && customerCount === undefined && (
            <CardContainer header="Loading customers">
                <LoadingIndicator />
            </CardContainer>
        )}

        {!isLoading && customerCount === 0 &&
            <PromotionBoxesPageSection
                title={<>{'Customers'}<ProductTooltip productTooltipModule={"CUSTOMERS_DATAVAULT"} /></> }
                subText='Build customer database in a secure environment to learn and understand their payment activities.'
                promotions={promotions}
            >
                <ButtonContainer justify>
                    <Link primary to={PlatformRoutesConfiguration.customerRoute.newCustomer.generatePath()}>Add new customer</Link>
                </ButtonContainer>
            </PromotionBoxesPageSection>
        }

        {customerCount > 0 && (
            <Form initialValues={(savedState && savedState.filter) || defaultFilter}>
                <div className={classNames('manage-transactions-page', { 'hidden': showFilter })} aria-hidden={showFilter}>
                    <PageSection fullWidth>
                        <PageHeader title={<>{'Customers'}<ProductTooltip productTooltipModule={"CUSTOMERS_DATAVAULT"} /></>}>
                            <Link button primary to={PlatformRoutesConfiguration.customerRoute.newCustomer.generatePath()}>New customer</Link>
                        </PageHeader>

                        <Button subtle onClick={() => { setShowFilter(true) }}>View filters ({filterCount} applied)</Button>

                        <MerchantPageCountDropdowns
                            onChildMerchantChanged={(val, context) => handleFilterChange(context.values)}
                        />

                        <FormError errors={errors} />

                        <CustomerList
                            savedFilter={savedState && savedState.filter}
                            onFilterChange={handleFilterChange}
                            sort={sort}
                            onSort={(field, descending) => setSort({ field, descending })}
                            customers={_.get(searchResult, 'items', [])}
                            onLinkClick={saveScrollPosition}
                            isLoading={isLoading}
                        />

                        <PaginationControl
                            currentPage={currentPage}
                            itemsPerPage={resultsPerPage}
                            itemCount={_.get(searchResult, 'count', 0)}
                            onPageChanged={(page) => setCurrentPage(page)}
                        />
                    </PageSection>
                </div>
                <BackToTop />


                <CustomerFilter
                    show={showFilter}
                    onHide={() => setShowFilter(false)}
                    defaultFilter={defaultFilter}
                    lastSavedFilter={lastSavedFilter}
                    onChange={handleFilterChange}
                />
            </Form>
        )}
    </>);
}

function mapStateToProps(state, ownProps) {
    return {
        customerCount: state.dataVault.customer.customerCount,
        searchResult: state.dataVault.customer.searchResult,
        savedState: state.dataVault.customer.customersPageState,
        isLoading: state.dataVault.customer.isLoading,
        errors: state.dataVault.customer.errors,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(customerActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageCustomersPage);
