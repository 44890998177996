import React, {useEffect, useState} from 'react';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';

import { PageSection, DescriptionList, LoadingIndicator, IconText, BackButton, Button, Divider, SuccessModal, Dialog, Icon, Link, PaddedContainer } from '@premier/ui';
import { PageHeader } from 'components/Common';
import { LinkedTokensList, CustomerDetails, EditTokenModals } from 'components/DataVault';
import { SuspendConfirmDialog, ScheduledPaymentList } from 'components/Transactions';

import dateUtil from '@premier/utils/date';
import currencyUtil from '@premier/utils/currency';
import windowUtil from '@premier/utils/window';

import { PlatformRoutesConfiguration } from 'components/Routing';
import * as scheduleActions from 'components/Transactions/_actions/scheduleActions';
import * as tokenActions from 'components/DataVault/_actions/tokenActions';
import * as scheduledPaymentActions from 'components/Transactions/_actions/scheduledPaymentActions';
import * as billerActions from "components/Settings/_actions/billerActions";

import labels from 'constants/labels';
import './ViewSchedulePage.scss';

const ViewSchedulePage = ({
    actions, tokenActions, scheduledPaymentActions, billerActions, //API actions
    schedule, scheduleId, token, scheduledPayments, scheduleFrequencies, suspendErrors, surchargesState,//state values
    isLoading //logic renders
}) => {
    const dispatch = useDispatch();
    const [reload, setReload] = useState(true);
    const [showCancelConfirmModal, setShowCancelConfirmModal] = useState(false);
    const [showCancelSuccessModal, setShowCancelSuccessModal] = useState(false);
    const [showSuspendConfirmModal, setShowSuspendConfirmModal] = useState(false);
    const [showSuspendSuccessModal, setShowSuspendSuccessModal] = useState(false);
    const [showResumeSuccessModal, setShowResumeSuccessModal] = useState(false);
    const [suspendedNumberPayments, setSuspendedNumberPayments] = useState(false);
    const [suspendedUntil, setSuspendedUntil] = useState(false);
    const [showEditTokenModal, setShowEditTokenModal] = useState(false);
    const [tokenFound, setTokenFound] = useState(false);

    const scheduledPaymentsRef = React.createRef();
    const surchargeRules= surchargesState.data;

    useEffect(() => {
        if (reload) {
            actions.getScheduleFrequencies()
            actions.getSchedule(scheduleId)
                .then(() => {
                    setReload(false);
                });
        }
    }, [reload]);

    useEffect(() => {
        async function fetchData() {
        if (!schedule || !schedule.dataVaultId) return;
        try {
            await tokenActions.getToken(schedule.dataVaultId);
            setTokenFound(true);
        } catch {
            // Token attached to schedule might be stale because the token might had been deleted already.
            setTokenFound(false);
        }
            scheduledPaymentActions.getScheduledPayments(3, 0, { subscriptionId: scheduleId }, { field: 'scheduledPaymentDate', descending: true });
        }
        fetchData();
    }, [schedule]);

    useEffect(() => {
        // Upon unmount, clear the token from Redux so it doesn't affect other pages.
        return () => {
            dispatch(tokenActions.clearTokenCache());
          }
    }, []);

    useEffect(() => {
        if (schedule?.billerCode)
            billerActions.getSurchargeRules(schedule.billerCode);
    }, [schedule?.billerCode]);

    function getScheduleDetails() {
        let subscriptionPeriod;
        if (schedule.endDate) {
            subscriptionPeriod = `${dateUtil.convertToDateString(schedule.originalStartDate)} - ${dateUtil.convertToDateString(schedule.endDate)}`;
        } else {
            subscriptionPeriod = `${dateUtil.convertToDateString(schedule.originalStartDate)} - No end date`;
        }

        const scheduleFrequency = scheduleFrequencies ? _.get(_.find(scheduleFrequencies.items, { key: schedule.frequency }), 'description', 'Unknown') : '';
        const recurringAmountLabel = schedule.surchargeApplied ? labels.recurringAmountExSurcharge : labels.recurringAmount;
        const remainingAmountLabel = schedule.surchargeApplied ? labels.remainingAmountExSurcharge : labels.remainingAmount;

        const scheduleDetails = [
            {name: labels.nextPayment, value: dateUtil.convertToDateString(schedule.nextPaymentDate)},
            {name: recurringAmountLabel, value: currencyUtil.convertToDisplayString(schedule.recurringAmount)},
            {name: labels.frequency, value: scheduleFrequency},
            {name: labels.reference1, value: schedule.reference1},
            {name: labels.reference2, value: schedule.reference2},
            {name: labels.reference3, value: schedule.reference3},
            {name: labels.subscriptionPeriod, value: subscriptionPeriod},
            {name: labels.amountCollected, value: schedule.amountCollected ? currencyUtil.convertToDisplayString(schedule.amountCollected) : currencyUtil.convertToDisplayString(0)},
            {name: labels.amountOriginalCollected, value: schedule.amountOriginalCollected ? currencyUtil.convertToDisplayString(schedule.amountOriginalCollected) : currencyUtil.convertToDisplayString(0)},
            {name: labels.amountSurchargeCollected, value: schedule.amountSurchargeCollected ? currencyUtil.convertToDisplayString(schedule.amountSurchargeCollected) : currencyUtil.convertToDisplayString(0)},
            {name: labels.remainingPayments, value: schedule.remainingPayments ?? "0" },
            {name: remainingAmountLabel, value: schedule.remainingAmount ? currencyUtil.convertToDisplayString(schedule.remainingAmount) : currencyUtil.convertToDisplayString(0)},
        ];

        if (!schedule.surchargeApplied) {
            return scheduleDetails.filter(s => s.name !== labels.amountOriginalCollected && s.name !== labels.amountSurchargeCollected);
        }

        return scheduleDetails;
    }

    function getPrefixDetails() {
        return [
            {name: labels.createdTime, value: dateUtil.convertToDateTimeString(schedule.createdDate)},
            {name: labels.updatedTime, value: dateUtil.convertToDateTimeString(schedule.updatedDate)},
            {name: labels.userNameUpdated, value: schedule.userNameUpdated},
        ];
    }

    function scheduleInStatuses(statuses) {
        const subscriptionStatus = _.get(schedule, 'subscriptionStatus.key');
        return statuses.includes(subscriptionStatus);
    }

    function getScheduleStatusDescription() {
        return _.get(schedule, 'subscriptionStatus.description') || 'Unknown';
    }

    function getScheduleStatusIcon() {
        const statusKey = _.get(schedule, 'subscriptionStatus.key');

        switch (statusKey) {
            case 'SCHEDULED':
            case 'FINISHED':
                return {'tick': true};
            case 'PAUSED':
            case 'DELETED':
                return {'info': true};
            case 'ERROR':
                return {'alert': true};
            default:
                return {'question': true};
        }
    }

    function handleCancel() {
        setShowCancelConfirmModal(true);
    }

    function handleConfirmCancel() {
        actions.cancelSchedule(scheduleId)
            .then(() => {
                setShowCancelConfirmModal(false);
                setShowCancelSuccessModal(true);
                setReload(true);
            });
    }

    function handleSuspend() {
        setShowSuspendConfirmModal(true);
    }

    function handleConfirmSuspend(values) {
        setSuspendedNumberPayments(null);
        setSuspendedUntil(null);

        actions.suspendSchedule(scheduleId, values)
            .then(() => {
                setShowSuspendConfirmModal(false);
                if (values.numberOfPayments)
                    setSuspendedNumberPayments(values.numberOfPayments);
                else if (values.endDate)
                    setSuspendedUntil(values.endDate[0]);
                setShowSuspendSuccessModal(true);
                setReload(true);
            });
    }

    function handleResume() {
        actions.resumeSchedule(scheduleId)
            .then(() => {
                setShowResumeSuccessModal(true);
                setReload(true);
            });
    }

    function handleEditTokenClose(updated) {
        setShowEditTokenModal(false);

        if (updated)
            setReload(true);
    }

    function navigateToScheduledPayments() {
        windowUtil.scrollToElement(scheduledPaymentsRef.current);
    }

    return (<>
        <PageSection className='view-schedule-page'>
            {isLoading && <LoadingIndicator />}

            {!isLoading && !schedule && <>
                <BackButton to={PlatformRoutesConfiguration.transactionRoute.manageSchedules.generatePath()} />
                <IconText alert>Subscription not found or an error occurred.</IconText>
            </>}

            {schedule && <>
                <PageHeader
                    backButton
                    title={<IconText {...getScheduleStatusIcon()}>{getScheduleStatusDescription()}</IconText>}
                    subtitle={<>
                        {schedule.scheduleReference && <h2>{schedule.scheduleReference}</h2>}
                        <DescriptionList greyLabel items={getPrefixDetails()} />
                    </>}
                >
                    {scheduleInStatuses(['SCHEDULED', 'PAUSED']) &&
                        <Link button primary to={PlatformRoutesConfiguration.transactionRoute.editSchedule.generatePath(scheduleId)}>Update subscription</Link>
                    }
                    {scheduleInStatuses(['SCHEDULED']) &&
                        <Button onClick={handleSuspend}>Suspend subscription</Button>
                    }
                    {scheduleInStatuses(['PAUSED']) &&
                        <Button onClick={handleResume}>Resume subscription</Button>
                    }
                </PageHeader>


                {schedule.numberOfOverduePayments > 0 && <>
                    <Divider />
                        <IconText className='overdue-payments-banner' alert>
                            You have {schedule.numberOfOverduePayments} overdue {schedule.numberOfOverduePayments === 1 ? 'payment' : 'payments'}
                            <Button link onClick={navigateToScheduledPayments}>View</Button>
                        </IconText>
                    <Divider />
                </>}

                {schedule.customer &&
                    <CustomerDetails
                        customer={schedule.customer}
                        hideEditButton
                        showIdentifiers
                    />
                }

                <PaddedContainer
                    className="subscription-details"
                    icon={<Icon calendar />}
                    title='Subscription details'
                >
                    <DescriptionList twoColumn items={getScheduleDetails()} />
                </PaddedContainer>

                <h2>Payment method</h2>
                <LinkedTokensList
                    tokens={tokenFound && token ? [token] : []}
                    onLinkClick={() => setShowEditTokenModal(true)}
                />

                <h2 ref={scheduledPaymentsRef}>Scheduled payments</h2>
                <ScheduledPaymentList payments={_.get(scheduledPayments, 'items', [])} getRowLink={t => PlatformRoutesConfiguration.transactionRoute.scheduledPaymentTransactions.generatePath(t.id)} />
                <Link to={PlatformRoutesConfiguration.transactionRoute.subscriptionScheduledPayments.generatePath(scheduleId)}>Click here to see the scheduled payments history</Link>

                {scheduleInStatuses(['SCHEDULED', 'PAUSED']) && <>
                    <Divider />
                    <Button link onClick={handleCancel}>Cancel subscription</Button>
                </>}
            </>}
        </PageSection>

        {/* Cancel Dialogs */}
        <Dialog
            show={showCancelConfirmModal}
            closeButton
            icon={<Icon alert />}
            title='Cancel subscription?'
            onClose={() => setShowCancelConfirmModal(false)}
            footerButtons={<>
                <Button onClick={handleConfirmCancel}>Cancel subscription</Button>
                <Button onClick={() => setShowCancelConfirmModal(false)}>Close</Button>
            </>}
        >
            A cancelled subscription cannot be reinstated
        </Dialog>
        <SuccessModal show={showCancelSuccessModal} onClose={() => setShowCancelSuccessModal(false)}>
            Subscription cancelled successfully
        </SuccessModal>

        {/* Suspend/Resume Dialogs */}
        <SuspendConfirmDialog
            show={showSuspendConfirmModal}
            errors={suspendErrors}
            onConfirm={handleConfirmSuspend}
            onCancel={() => setShowSuspendConfirmModal(false)}
        />
        <SuccessModal
            show={showSuspendSuccessModal}
            title='Subscription suspended successfully'
            onClose={() => setShowSuspendSuccessModal(false)}
        >
            {suspendedNumberPayments &&
                <span>Suspend for {suspendedNumberPayments} payments</span>
            }
            {suspendedUntil &&
                <span>Suspend until {dateUtil.convertToDateString(suspendedUntil)}</span>
            }
        </SuccessModal>
        <SuccessModal
            show={showResumeSuccessModal}
            title='Subscription resumed successfully'
            onClose={() => setShowResumeSuccessModal(false)}
        />

        {/* Payment Method Dialogs */}
        <EditTokenModals show={showEditTokenModal} onClose={handleEditTokenClose} surchargeRules={surchargeRules}/>
    </>);
}

function mapStateToProps(state, ownProps) {
    return {
        scheduleId: ownProps.match.params.id,
        schedule: state.transactions.schedule.details.data,
        token: state.dataVault.token.details,
        scheduleFrequencies: state.transactions.schedule.scheduleFrequencies.data,
        scheduledPayments: state.transactions.scheduledPayment.searchResult,
        suspendErrors: state.transactions.schedule.suspend.errors,
        isLoading: state.transactions.schedule.details.isLoading,
        surchargesState: state.settings.paymentSettings.surcharges,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(scheduleActions, dispatch),
        tokenActions: bindActionCreators(tokenActions, dispatch),
        scheduledPaymentActions: bindActionCreators(scheduledPaymentActions, dispatch),
        billerActions: bindActionCreators(billerActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewSchedulePage);