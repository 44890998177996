import { useState, useContext, ReactNode } from "react";
import { connect } from "react-redux";
import { RootState } from "store/store";

import { CardInputForm, FormContext } from "@premier/form";
import { Tabs, Tab, Divider } from "@premier/ui";
import { PaymentRequestForm } from "components/PaymentRequests";

import { userRoles } from "components/Routing";
import SecurityUtil, { User } from "@premier/utils/security";

// @ts-ignore
import { PaymentDetailsTabKeys } from "constants/billpay";

type Props = {
    /**A Custom form that can be passed-in */
    customPaymentForm?: ReactNode;
    authenticatedUser: User;
    /**The function to run when the tab is changed, passing back the eventKey */
    onTabChange: (data: string | null) => void;
}

/**A component for displaying either a CardInputForm / PaymentRequestForm in a tabbed layout (if the user has permission for Payment Request)
 * Or just the CardInputForm if the user has no permission.
 */
const PaymentDetailsForms = ({
    customPaymentForm, //data
    authenticatedUser, //state
    onTabChange, //function
}: Props) => {
    const { getValue } = useContext(FormContext);  // The parent Form

    //Required for errors the CardInputForm's submission only triggering when tab is selected.
    const [currentTab, setCurrentTab] = useState(PaymentDetailsTabKeys.PAYMENT);

    function handleChange(data: string | null) {
        setCurrentTab(data);
        onTabChange && onTabChange(data);
        // Note: If any issue, see how PaymentMethodDetailsForms handles this
    }

    function renderDefaultPaymentForm() {
        return (<>
            <CardInputForm name="card" required disableCvn={getValue("orderType") === "MAIL_FAX" || getValue("orderType") === "RECURRING"} />
            <Divider />
        </>);
    }

    const allowedTabs = [];
    const paymentInputTabContent = customPaymentForm || renderDefaultPaymentForm();

    // This is always allowed (to make a normal payment on the page)
    allowedTabs.push(
        <Tab key = {PaymentDetailsTabKeys.PAYMENT} eventKey={PaymentDetailsTabKeys.PAYMENT} title="Enter payment details">
            {currentTab === PaymentDetailsTabKeys.PAYMENT &&
            paymentInputTabContent
            }
        </Tab>
    );

    if (SecurityUtil.hasAccess([userRoles.qrCode], authenticatedUser)) {
        allowedTabs.push(
            <Tab key = {PaymentDetailsTabKeys.QRREQUESTS} eventKey={PaymentDetailsTabKeys.QRREQUESTS} title="Scan and pay">
                {currentTab === PaymentDetailsTabKeys.QRREQUESTS &&
                    <PaymentRequestForm qrCodeRequest showSavePaymentMethod />
                }
            </Tab>
        );
    }

    return (
        <Tabs onSelect={handleChange}>
            {allowedTabs}
        </Tabs>
    );
};

function mapStateToProps(state: RootState) {
    return {
        authenticatedUser: state.accounts.users.authenticatedUser,
    };
}

export default connect(mapStateToProps)(PaymentDetailsForms);
