import React from 'react';
import PropTypes from 'prop-types';

import BaseTransactionDetailCategory from './BaseTransactionDetailCategory';

const AuthenticationResultCategory = ({ transaction }) => {

    var authResult = [];
    if (!transaction.isThreeDs) {
        authResult.push({ name: '3D secure', value: 'No' });
    }
    else {
        if (transaction.threeDsAuthenticationModel) {
            authResult.push({ name: '3DS ECI', value: transaction.threeDsAuthenticationModel.threeDsEci });
            authResult.push({ name: '3DS enrolled', value: transaction.threeDsAuthenticationModel.threeDsEnrolled });
            authResult.push({ name: '3DS status', value: transaction.threeDsAuthenticationModel.threeDsStatus });
            authResult.push({ name: '3DS verify security level', value: transaction.threeDsAuthenticationModel.threeDsVerifySecurityLevel });
            authResult.push({ name: '3DS verify status', value: transaction.threeDsAuthenticationModel.threeDsVerifyStatus });
            authResult.push({ name: '3DS verify token', value: transaction.threeDsAuthenticationModel.threeDsVerifyToken });
            authResult.push({ name: '3DS XID', value: transaction.threeDsAuthenticationModel.threeDsXid });
        };
    };

    return (
        <BaseTransactionDetailCategory title='Authentication result' items={authResult} />
    );
}

AuthenticationResultCategory.propTypes = {
    transaction: PropTypes.object.isRequired
};

export default AuthenticationResultCategory;
