import { requestActionTypes } from 'components/Common/requestActions';

export const LOAD_BILLERS = requestActionTypes('LOAD_BILLERS');
export const GET_BILLER = requestActionTypes('GET_BILLER');
export const ADD_BILLER = requestActionTypes('ADD_BILLER');
export const UPDATE_BILLER = requestActionTypes('UPDATE_BILLER');
export const UPDATE_BILLER_DETAILS = requestActionTypes('UPDATE_BILLER_DETAILS');
export const GET_BILLER_WALLETS = requestActionTypes('GET_BILLER_WALLETS');
export const PUT_BILLER_WALLETS = requestActionTypes('PUT_BILLER_WALLETS');
export const PUT_BILLER_PAYMENT_LIMITS = requestActionTypes('PUT_BILLER_PAYMENT_LIMITS');
export const GET_SURCHARGE_RULES = requestActionTypes('GET_SURCHARGE_RULES');
export const PUT_SURCHARGE_RULES = requestActionTypes('PUT_SURCHARGE_RULES');
