import React from 'react';
import { connect } from 'react-redux';

import { Link, Icon, ButtonContainer } from '@premier/ui';
import { FloatingContainer, PageHeader, ErrorPage } from 'components/Common';

import { PlatformRoutesConfiguration } from 'components/Routing';

const UnauthorizedPage = ({
    authenticatedUser //state value
}) => {
    const errorPage = (
        <ErrorPage>
            <PageHeader
                icon={<Icon lock />}
                title='Unauthorized access'
                subtitle="Sorry, it looks like you don’t have permission to view this page…"
            />

            <ButtonContainer>
                {!!authenticatedUser && (
                    <Link button to={PlatformRoutesConfiguration.accountRoute.home?.generatePath()}>Back to home</Link>
                )}
                {!authenticatedUser && (
                    <Link button to={PlatformRoutesConfiguration.accountRoute.logon?.generatePath()}>Back to log on</Link>
                )}
            </ButtonContainer>
        </ErrorPage>
    );

    if (authenticatedUser) {
        return errorPage;
    }

    return (
        <FloatingContainer>
            {errorPage}
        </FloatingContainer>
    );
}

function mapStateToProps(state, ownProps) {
    return {
        authenticatedUser: state.accounts.users.authenticatedUser
    }
}

export default connect(mapStateToProps)(UnauthorizedPage);