import React, { useContext } from 'react';
import { ResponsiveTable } from '@premier/ui';
import { FormContext, InputField, DropdownField, DatePickerField } from '@premier/form';
import currencyUtil from '@premier/utils/currency';
import dateUtil from '@premier/utils/date';

// @ts-ignore
import labels from 'constants/labels';
import { useDebounce } from 'components/Common';
import { InvoiceSearchResponseModel } from 'packages/webapi-client';
import { ValueLabel } from '../pages/ManageCustomerInvoicesPage';
import { PlatformRoutesConfiguration } from 'components/Routing';

import './InvoiceRecordsList.scss';

export const fieldInfo = {
    status: {
        name: 'status', // key in the form context data
        defaultValue: 'All', // default value for the field
        sortKey: 'Status', // backend key for sorting
    },
    invoiceNumber: {
        name: 'invoiceNumber',
        defaultValue: '',
        sortKey: 'InvoiceNumber',
    },
    orderNumber: {
        name: 'orderNumber',
        defaultValue: '',
        sortKey: 'OrderNumber',
    },
    invoiceDate: {
        name: 'invoiceDate',
        defaultValue: '',
        sortKey: 'InvoiceDate',
    },
    amount: {
        name: 'amount',
        defaultValue: '',
        sortKey: 'Amount',
    },
};
interface Props {
    invoiceList: InvoiceSearchResponseModel[];
    sort: { field: string; descending: boolean };
    onSort: (sortKey: string, isDescending: boolean) => void;
    statusOptions: ValueLabel[];
    noDataText: string;
    isLoading: boolean;
    onFilterChange: (values: any) => void;
}

const InvoiceRecordsList: React.FC<Props> = ({
    invoiceList,
    isLoading,
    sort,
    onSort,
    statusOptions,
    noDataText,
    onFilterChange,
}) => {
    const context = useContext(FormContext);
    const debouncedFilterChange = useDebounce(onFilterChange);

    function handleQuickFilterChange() {
        debouncedFilterChange(context.values);
    }

    // list fields and columns ---
    const fields = {
        status: (
            <DropdownField
                name={fieldInfo.status.name}
                // @ts-ignore
                defaultValue={fieldInfo.status.defaultValue}
                options={statusOptions}
                onChange={handleQuickFilterChange}
            />
        ),
        invoiceNumber: <InputField name={fieldInfo.invoiceNumber.name} onChange={handleQuickFilterChange} />,
        orderNumber: (
            <InputField
                name={fieldInfo.orderNumber.name}
                onChange={handleQuickFilterChange}
                data-testid='order-number-input'
            />
        ),
        invoiceDate: <DatePickerField name={fieldInfo.invoiceDate.name} onChange={handleQuickFilterChange} />,
        amount: (
            <InputField
                name={fieldInfo.amount.name}
                onChange={handleQuickFilterChange}
                //@ts-ignore
                decimal
            />
        ),
    };

    const columns = [
        {
            label: labels.status,
            sortKey: fieldInfo.status.sortKey,
            getter: (k: InvoiceSearchResponseModel) => k.status?.description,
            filter: fields.status,
        },
        {
            label: labels.invoiceNumber,
            sortKey: fieldInfo.invoiceNumber.sortKey,
            getter: (k: InvoiceSearchResponseModel) => k.invoiceNumber,
            filter: fields.invoiceNumber,
        },
        {
            label: labels.orderNumber,
            sortKey: fieldInfo.orderNumber.sortKey,
            getter: (k: InvoiceSearchResponseModel) => k.orderNumber,
            filter: fields.orderNumber,
        },
        {
            label: labels.invoiceDate,
            sortKey: fieldInfo.invoiceDate.sortKey,
            getter: (k: InvoiceSearchResponseModel) => dateUtil.formatToDateString(k.invoiceDate!),
            filter: fields.invoiceDate,
        },
        {
            label: labels.amount,
            sortKey: fieldInfo.amount.sortKey,
            getter: (k: InvoiceSearchResponseModel) => currencyUtil.convertToDisplayString(k.amount!, k.currencyCode!),
            filter: fields.amount,
        },
    ];

    return (
        <div className='invoice-records'>
            <ResponsiveTable
                // @ts-ignore
                data={invoiceList}
                // @ts-ignore
                columns={columns}
                isLoading={isLoading}
                sort={sort}
                onSort={onSort}
                noDataText={noDataText}
                //@ts-ignore
                getRowLink={(c) => PlatformRoutesConfiguration.invoicingRoute.viewInvoice.generatePath(c.invoiceId)}
            />
        </div>
    );
};

export default InvoiceRecordsList;
