export default {
    home: "Home",
    transactions: "Transactions",
    newPayment: "New payment",
    manageTransactions: "Manage transactions",
    manageSchedules: "Subscriptions",
    batchPayments: "Batch payments",
    declines: "Declines",
    declineManager: "Decline manager",
    sci: "SCI",
    dataVault: "Data vault",
    manageCustomers: "Customers",
    manageTokens: "Payment methods",
    requests: "Requests",
    newRequest: "New request",
    manageRequests: "Manage requests",
    batchRequests: "Batch requests",
    reports: "Reports",
    settlementReports: "Settlements",
    customReports: "Custom reports",
    stationeryShop: "Stationery shop",
    stationeryProducts: "All products",
    stationeryCart: "Shopping cart",
    stationeryOrders: "My orders",
    stationeryTerms: " Terms and conditions",
    feedback: "Feedback",
    contactUs: "Contact us",
    developerCentre: "Developers",
    developerReference: "Developer reference",
    settings: "Settings",
    batchDeclineSettings: "Batch decline settings",
    businessDetails: "Business details",
    fraudSettings: "Fraud management",
    apiIntegration: "API integration",
    invoiceSettings: "Invoice settings",
    iServicesSettings: "i-Services",
    paymentSettings: "Payment settings",
    manageUsers: "Manage users",
    messaging: "Messaging",
    account: "Account settings",
    paymentPlanSettings: "Payment plan settings",
    profile: "My profile",
    logoff: "Log off",
    designer: "Designer",
    paymentPage: "Payment page",
    paymentPlan: "Payment plan"
};
