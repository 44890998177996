import { map } from 'platforms/current/util';

import {default as cba} from 'platforms/cba/constants/text';
import {default as stationery_shop} from 'platforms/stationery_shop/constants/text';
import {default as merchantsuite} from 'platforms/merchantsuite/constants/text';

export default map({
    cba,
    stationery_shop,
    merchantsuite
});
