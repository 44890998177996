import { useSelector } from 'react-redux';
import { Link, PaddedContainer, PageSection, BackButton, LoadingIndicator, IconText } from '@premier/ui';
import { PlatformRoutesConfiguration } from 'components/Routing';
import { PageHeader, ProductTooltip } from 'components/Common';
import { useMessagingSettings } from 'components/Settings/_hooks/useMessagingSettings';
import {MessageEmailSettings} from 'components/Settings/components/MessageEmailSettings';
import { userRoles } from 'components/Routing';
import SecurityUtil from '@premier/utils/security';
import { MessagingSettingsModel } from '@premier/webapi-client';
import { RootState } from 'store/store';

const hasAnyKey = (data: MessagingSettingsModel, ...keys: string[]) : boolean => {
    for (const key of keys) {
        if (key in data)
            return true;
    }
    return false;
}

const authenticatedUserSelector = (state: RootState) => state.accounts.users.authenticatedUser;

export const MessagingLandingPage = () => {

    const { hasLoaded, hasFailed, settings } = useMessagingSettings();
    const  authenticatedUser = useSelector(authenticatedUserSelector);

    if (hasFailed)
        return <PageSection>
            <BackButton to={PlatformRoutesConfiguration.accountRoute.root.generatePath()}/>
            <IconText alert>Something went wrong.</IconText>
        </PageSection>;

    if (!hasLoaded)
        return <LoadingIndicator />

    const showReceipts = hasAnyKey(settings, 'singlePaymentReceipt', 'batchPaymentReceipt', 'internetPaymentReceipt', 'singlePaymentDecline', 'batchPaymentDecline', 'internetPaymentDecline');
    const showReminders = hasAnyKey(settings, 'scheduledPaymentReminder', 'scheduledBatchReminder');
    const showConfirmations = hasAnyKey(settings, 'eddrScheduleAcknowledgement', 'payLaterConfirmation');
    const showRequests = SecurityUtil.hasAccess([userRoles.paymentRequestAdmin], authenticatedUser);

    return <PageSection>
        <PageHeader title={<>{'Messaging'}<ProductTooltip productTooltipModule={"MESSAGING_SETTINGS"} /></>}/>

        <p>Setup messages that go to your customers upon different events</p>

        {showReceipts &&
            <PaddedContainer title="Receipts" lessMargin noDivider button={
                <Link button to={PlatformRoutesConfiguration.messagingRoute?.editReceipts.generatePath()}>Edit</Link>
            }>
                Enable or disable email and SMS receipts for successful and unsuccessful payments
            </PaddedContainer>
        }

        {showReminders &&
            <PaddedContainer title="Reminders" lessMargin noDivider button={
                <Link button to={PlatformRoutesConfiguration.messagingRoute?.editReminders.generatePath()}>Edit</Link>
            }>
                Enable or disable email and SMS reminders for your customers
            </PaddedContainer>
        }

        {showConfirmations &&
            <PaddedContainer title="Confirmations" lessMargin noDivider button={
                <Link button to={PlatformRoutesConfiguration.messagingRoute?.editConfirmations.generatePath()}>Edit</Link>
            }>
                Enable or disable email and SMS confirmations such as scheduled payment confirmations for your customers
            </PaddedContainer>
        }

        {showRequests &&
            <PaddedContainer title="Requests" lessMargin noDivider button={
                <Link button to={PlatformRoutesConfiguration.messagingRoute?.editRequests.generatePath()}>Edit</Link>
            }>
                Configure the messages that are sent out to customers when requesting payments or requesting payment details via email or SMS
            </PaddedContainer>
        }
        <MessageEmailSettings/>
    </PageSection>;
};