import { useEffect, useMemo, useState } from "react";
import { DropdownField } from "@premier/form";
import { APICallRequestState, useApiCall } from "components/Common";
import { utilitiesApi } from "api";
import { BillerActionModel } from "packages/webapi-client";
import { PaymentRequestAction } from "platforms/base/constants/billpay";
import { UiOption } from "packages/utils/models";

type Props = {
    merchantNumber: string | undefined;
    billerCode: string | undefined;
    tokenPage: boolean;
    onActionChanged?: (action: number) => void
}

const PaymentRequestActionDropdown = ({ merchantNumber, billerCode, tokenPage, onActionChanged }: Props) => {
    const [billerActionOptions, setBillerActionOptions] = useState<UiOption<string>[]>([]);

    const [billerActions, billerActionsStatus] = useApiCall<BillerActionModel[]>(() => {
        if (merchantNumber && billerCode)
            return utilitiesApi.getBillerActions(merchantNumber, billerCode);
    }, [merchantNumber, billerCode]);

    const disallowedBillerActions = useMemo(() => {
        return (tokenPage ? [
                                PaymentRequestAction.PaymentOnly,
                                PaymentRequestAction.PreAuthOnly,
                                PaymentRequestAction.PaymentAndTokenise,
                                PaymentRequestAction.PreAuthAndTokenise,
                                PaymentRequestAction.TokeniseOnly
                            ]
                          : [])
            .map(Number);
    }, [tokenPage]);

    useEffect(() => {
        if (billerActions && billerActionsStatus === APICallRequestState.SUCCESSFUL) {
            // Filter out disallowed actions and create UiOption objects
            const options = billerActions.filter(x => x.action && !disallowedBillerActions.includes(x.action))
                .map((x: BillerActionModel): UiOption<string> => ({ value: x.action ? x.action.toString(): "", label: x.actionName ? x.actionName : "" }));

            setBillerActionOptions(options);
        }
    }, [billerActions, billerActionsStatus, disallowedBillerActions]);

    const handleChange = (value: string) => {
        onActionChanged && onActionChanged(Number(value));
    };

    return (
        <DropdownField label="Action" name="action" options={billerActionOptions} onChange={handleChange} mandatory />
    );
};

export default PaymentRequestActionDropdown;
