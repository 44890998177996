import _ from "lodash";
import currencyUtil from "@premier/utils/currency";
import dateUtil from "@premier/utils/date";
import { useState } from "react";
import { DescriptionList, Button } from "@premier/ui";
import { useApiCallDeprecated, APICallRequestState } from "components/Common";
import { BillerSurchargeTableModal } from "components/Settings";
import { useSelector } from "react-redux";
import { billerApi, merchantApi } from "api";
// @ts-ignore
import labels from "constants/labels";
import { Schedule } from "models/Schedule";
import { Biller } from "models/Biller";
import { RootState } from "store/store";

type Props = {
    schedule: Schedule;
    scheduleFrequencies: any;
    biller: Biller;
}

/** A list of the Tokens values */
const ScheduleDetailsDescriptionList = ({
    schedule, scheduleFrequencies, biller //data
}:Props) => {
    const merchant = useSelector((state: RootState) => state.accounts.users.merchant);
    const [showSurchargeDialog, setShowSurchargeDialog] = useState(false);

    const [surchargeRules, surchargeRulesStatus] = useApiCallDeprecated(async () => {
        if (biller?.billerCode) {
            const dto = await billerApi.getSurchargeRules(biller.billerCode);
            return { data: { data: dto }, status: 200 };
        }
    }, [biller]);

    const [paymentMethods, paymentMethodsStatus] = useApiCallDeprecated(async () => {
        if (merchant?.merchantNumber) {
            const dto = await merchantApi.getPaymentMethods(merchant.merchantNumber);
            return { data: { data: dto }, status: 200 };
        }
    }, [merchant]);

    function getScheduleDetails() {
        const scheduleFrequency = _.get(_.find(scheduleFrequencies.items, { key: schedule.frequency }), "description", "Unknown");

        const retval = [
            { name: labels.billerCode, value: _.get(schedule, "scheduleDetailsBillerCodeForm.billerCode") },
            { name: biller?.acceptedCrn1?.label ?? biller?.acceptedCrn1?.crnName ?? labels.reference1, value: _.get(schedule, "scheduleDetailsBillerCodeForm.billerCrnList.crn1", schedule.reference1) },
            { name: biller?.acceptedCrn2?.label ?? biller?.acceptedCrn2?.crnName ?? labels.reference2, value: _.get(schedule, "scheduleDetailsBillerCodeForm.billerCrnList.crn2", schedule.reference2) },
            { name: biller?.acceptedCrn3?.label ?? biller?.acceptedCrn3?.crnName ?? labels.reference3, value: _.get(schedule, "scheduleDetailsBillerCodeForm.billerCrnList.crn3", schedule.reference3) },
            { name: labels.subscriptionReference, value: schedule.scheduleReference },
            { name: <>{labels.recurringAmount } { merchant?.applyRecurringSurcharge ? <Button link onClick={() => { setShowSurchargeDialog(true); }}>(Surcharge may apply)</Button> : null}</>, value: currencyUtil.formatWithPrefix(schedule.recurringAmount) },
        ];

        retval.push({ name: labels.frequency, value: scheduleFrequency });
        retval.push({ name: "First payment date", value: _.isEmpty(schedule.startDate) ? "" : dateUtil.convertToDateString(schedule.startDate[0]) });

        // Deduce what needs to shown for the end date, end after payments component
        switch (schedule?.scheduleEnd) {
            case "END_AFTER":
                retval.push({ name: labels.endAfter, value: `${schedule.numberOfPayments} payments` });
                break;
            case "END_DATE":
                retval.push({ name: labels.endDate, value: dateUtil.convertToDateString(schedule.endDate[0]) });
                break;
            case "NO_END":
            default:
                retval.push({ name: labels.endDate, value: "No end date" });
        }
        return retval;
    }


    return <>
        <DescriptionList items={getScheduleDetails()} />
        {paymentMethodsStatus === APICallRequestState.SUCCESSFUL && surchargeRulesStatus === APICallRequestState.SUCCESSFUL &&
            <BillerSurchargeTableModal
                paymentMethods={paymentMethods}
                surchargeRules={surchargeRules.val}
                show={showSurchargeDialog}
                onClose={() => setShowSurchargeDialog(false)}
            />
        }
    </>;
};

export default ScheduleDetailsDescriptionList;
