import React, { useEffect } from 'react';
import './BackToTop.scss';

type Props = {
    /** Default = 'Back to top' */
    title?: string,
    /** Appear when the page has been scrolled n px down (Default = 100) */
    appearAtPx?: number,
    className?: string,
};

/** A floating button that appears when you scroll down. It scrolls the window up to the top when clicked. */
const BackToTop = ({
    title, appearAtPx = 100, className,
}: Props) => {

    const elementId = 'btn-top';  // Also in .scss

    useEffect(() => {
        const onScroll = () => {
            var el = document.getElementById(elementId);
            if (el)
                el.style.display = (document.body.scrollTop || document.documentElement.scrollTop) > appearAtPx ? 'block' : 'none';
        }

        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scoll", onScroll);
    }, [appearAtPx]);

    function scrollToTop() {
        // From https://stackoverflow.com/questions/21474678/scrolltop-animation-without-jquery
        const scrollDuration = 200;

        var scrollAmount = window.pageYOffset;

        var cosParameter = scrollAmount / 2,
            scrollCount = 0,
            oldTimestamp = performance.now();

        function step(newTimestamp: number) {
            scrollCount += Math.PI / (scrollDuration / (newTimestamp - oldTimestamp));
            if (scrollCount >= Math.PI) window.scrollTo(0, 0);
            if (window.pageYOffset === 0) return;
            var scrollOffsetY = Math.round(cosParameter + cosParameter * Math.cos(scrollCount));
            window.scrollTo(0, scrollOffsetY);
            oldTimestamp = newTimestamp;
            window.requestAnimationFrame(step);
        }
        window.requestAnimationFrame(step);
    }

    return (
        <button type='button' id={elementId} className={className} onClick={scrollToTop} title={title || 'Back to top'} />
    );
};

export default BackToTop;