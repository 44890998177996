import React from 'react';
import dateUtil from '@premier/utils/date';


type Props = {
    /** API value in UTC (eg. '2018-12-05 22:00:00') */
    utcValue?: string,
    className?: string,
};

/** Display date & time in merchant's timezone and format, and avoids line breaks at weird spots */
const DateTime = ({
    utcValue, className
}: Props) => {

    if(!utcValue)
        return null;

    return (
        <span className={className}>
            <span style={{ whiteSpace: 'nowrap' }}>{dateUtil.convertToDateString(utcValue)}</span>{' '}
            <span style={{ whiteSpace: 'nowrap' }}>{dateUtil.convertToTimeString(utcValue)}</span>
        </span>
    );
};

export default DateTime;
