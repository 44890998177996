import * as actions from '../_actions/actionTypes';
import * as accountActions from 'components/Account/_actions/actionTypes';
import * as commonActions from 'components/Common/_actions/actionTypes';

export default function tokenReducer(state = {}, action) {
    switch(action.type) {
        case actions.LOAD_TOKENS_SUCCESS:
            return Object.assign({}, state, {
                searchResult: {
                    items: action.tokens,
                    count: action.resultCount
                }
            });

        case actions.GET_TOKEN_SUCCESS:
            return Object.assign({}, state, {
                details: action.result,
            });

        case actions.SAVE_TOKENS_PAGE_STATE:
            return Object.assign({}, state, {
                tokensPageState: action.pageState
            });

        case actions.SAVE_TOKEN_COUNT:
            return Object.assign({}, state, {
                tokenCount: state.tokenCount || action.resultCount
            });



        case accountActions.LOGOUT_USER_SUCCESS:
        case actions.CLEAR_TOKEN_CACHE:
        case commonActions.CLEAR_SENSITIVE_DATA:
            return {
                asyncCount: state.asyncCount,
                isLoading: state.isLoading
            };
        default:
            return state;
    }
}