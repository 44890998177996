import * as actions from './actionTypes';
import { merchantApi } from 'api';
import { promiseRequestActions } from 'components/Common/requestActions';

// ----- GET PaymentMethods for the logged-in Merchant -----
export let getPaymentMethods = promiseRequestActions(
    actions.GET_PAYMENT_METHODS,
    (merchantNumber) => merchantApi.getPaymentMethods(merchantNumber)
).send;

// ----- GET/PUT Klarna config for the logged-in Merchant -----
export let getKlarnaConfig = promiseRequestActions(
    actions.GET_KLARNA_CONFIG,
    () => merchantApi.getKlarnaConfig()
).send;

let saveKlarnaConfigActions = promiseRequestActions(
    actions.PUT_KLARNA_CONFIG,
    (values) => merchantApi.saveKlarnaConfig(values)
);
export let saveKlarnaConfig = saveKlarnaConfigActions.send;
export let clearSaveKlarnaConfig = saveKlarnaConfigActions.clear;
