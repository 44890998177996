import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { PromotionCard, PageHeader, useApiCall, APICallRequestState } from 'components/Common';
import { InstalmentsSummaryDialog } from 'components/Settings';
import { InputGroup } from 'react-bootstrap';
import { Divider, Row, SingleColumnFormContainer,Button, SuccessModal, Icon, IconText, Tooltip, ErrorText, LoadingIndicator } from '@premier/ui';
import { Input, FormGroup, FormikDatePicker, FormikDropdown, FormikRadioButton, FormikCurrencyInput } from "@linkly/formik-ui";
import { Navigate, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
// @ts-ignore
import { PlatformRoutesConfiguration } from 'components/Routing';
import paymentPlanOptionApi from 'api/paymentPlanOptionApi';
import { PaymentPlanOptionRequest, PaymentPlanOptionRequestDiscountTypeEnum, PaymentPlanOptionRequestIntervalEnum } from '@premier/webapi-client';
import { Formik, Form, Field, FormikProps } from 'formik';
import dateutil from '@premier/utils/date'
// @ts-ignore
import labels from 'constants/labels';
import './AddOrUpdatePaymentPlanOptionForm.scss';
import { VerifyBiller } from '../../../../util/verificationUtil'

const SAMPLE_INSTALMENTS_PLAN_AMOUNT = 1000000; // AUD 10000.00

/* Component constants */
export const componentLabels = {
    basicDetail: 'Basic detail',
    optionName: 'Option name',
    availablePeriod: 'Available period',
    paymentFrequency: 'Payment frequency',
    recurOnce: 'Once',
    recurEvery: 'Recur every',
    frequencyPlaceholder: 'e.g. 1',
    discountType: 'Discount method',
    noDiscountProvided: 'No discount provided',
    discountByAmount: 'Discount by amount',
    discountAmount: 'Discount amount',
    discountPercentage: 'Discount percentage',
    discountByPercentage: 'Discount by percentage',
    scheduleInterval: {
        weeks: 'Weeks',
        months: 'Months'
    },
    endDate: 'End date',
    endAfter: 'End after',
    payments: 'payments',
    endOn: 'End on',
    tooltip: 'This available period determines when this payment plan option is shown to your customers and users. You can determine just the start or end date if needed. If both leave as blank, this option will always be shown.',
    /// Used for validation error messages
    numberOfFrequency: "Number of frequency",
    unitOfFrequency: "Unit of frequency",
    numberOfPayments: "Number of payments",
    exceedThreeDigits: "exceeds 3 digits",
    exceeds100Percent: "exceeds 100%",
    shouldBeAPositiveNumber: "should be a positive number",
    shouldBeGreaterThanOne: "should be greater than 1",
    isRequired: "is required",
    submitValidationErrorMessage: "There are errors above. Please fix them before continuing.",
    startDateShouldBeEarlier: "Start date should be earlier than end date.",
    endDateShouldBeLater: "End date should be later than start date.",
    badEndDateGiven: "Please check the end date as only one payment would take place.",
    validCurrencyRequired: "Please only enter a valid currency amount."
};

enum FORM_STATUS {
    REDIRECT_TO_ALTERNATIVE_PAGE,
    SUBMITTED_SUCCESSFULLY,
    AWAITING_FORM_SUBMISSION,
    DISPLAY_INSTALMENTS_SUMMARY_MODAL,
    RECONFIRMATION_EXIT,
}

enum RECURRING_END_TYPE {
    AFTER_PAYMENTS = "after_payments",
    ON_DATE = "on_date"
};

enum PAYMENT_FREQUENCY {
    ONCE = "once",
    RECURRING = "recurring"
};

interface Props {
    billerCode: string;
    optionId?: number;
}
// See PaymentPlanOptionRequestDiscountTypeEnum
enum DISCOUNT_TYPE {
    None = 'None',
    Percentage = 'Percentage',
    Amount = 'Amount'
};

// See PaymentPlanOptionRequestIntervalEnum
enum SCHEDULE_INTERVAL {
    WEEK = 'WEEK',
    MONTH = 'MONTH',
}

interface AddOrUpdatePaymentPlanOptionFormFields extends Omit<PaymentPlanOptionRequest, 'availableFrom' | 'availableTo' | 'lastPaymentDate'> {
    // Form logic form values - used to generate the correct DTO for API and conditional form validation
    paymentFrequency?: PAYMENT_FREQUENCY
    recurringEndType?: RECURRING_END_TYPE,
    // Dates need to be stored as Javascript date objects rather than strings to make it easier
    // to work with the DatePicker/flatpickr component
    availableFrom? : Date,
    availableTo?: Date,
    lastPaymentDate?: Date
}

// You must populate the default values if you want the error messages to be shown
// if the customer did not touch the field (eg they forgot to input something for a required field)
// Formik uses initial values to determine whether a form is "dirty" by doing a deep equal with props.values
const defaultInitialValues : AddOrUpdatePaymentPlanOptionFormFields = {
    paymentPlanOptionName: "",
    paymentFrequency: PAYMENT_FREQUENCY.ONCE,
    recurringEndType: RECURRING_END_TYPE.AFTER_PAYMENTS,
    discountType: DISCOUNT_TYPE.None,
    discountAmount: undefined,
    discountPercentage: undefined,
    frequency: undefined,
    interval: SCHEDULE_INTERVAL.WEEK,
    numOfPayment: undefined,
    lastPaymentDate: undefined,
    availableFrom: undefined,
    availableTo: undefined
}

const AddOrUpdatePaymentPlanOptionSchema = Yup.object().shape({
    // DTO members, any of these values could possibly be sent to the backend
    // WARNING: This does not clean the form values object. You must maintain generateDTO
    // to ensure only the necessary values are sent over depending on its states.
    paymentPlanOptionId: Yup.number(),
    paymentPlanOptionName: Yup.string()
        .max(50, `${componentLabels.optionName} exceeds 50 characters.`)
        .required(`${componentLabels.optionName} ${componentLabels.isRequired}.`),
    availableFrom: Yup.date(),
    availableTo: Yup.date().test("availableToGreaterThanAvailableFrom", componentLabels.endDateShouldBeLater, function (availableTo? : Date) {
        const { availableFrom } = this.parent;
        return availableFrom && availableTo ? availableFrom < availableTo : true;
    }),
    discountType: Yup.mixed<PaymentPlanOptionRequestDiscountTypeEnum>().oneOf(['None', 'Percentage', 'Amount']).when('paymentFrequency',
        {
            is: PAYMENT_FREQUENCY.ONCE,
            then: Yup.mixed<PaymentPlanOptionRequestDiscountTypeEnum>().oneOf(['None', 'Percentage', 'Amount']).required()
        }
    ),
    discountAmount: Yup.number().when(['discountType', 'paymentFrequency'], {
        is: (discountType : DISCOUNT_TYPE, paymentFrequency : PAYMENT_FREQUENCY) =>
                { return paymentFrequency === PAYMENT_FREQUENCY.ONCE && discountType === DISCOUNT_TYPE.Amount },
        then: Yup.number().typeError(`${componentLabels.validCurrencyRequired}`)
        .required(`${componentLabels.discountAmount} ${componentLabels.isRequired}.`)
        .min(1, `${componentLabels.discountAmount} ${componentLabels.shouldBeAPositiveNumber}.`)
    }),
    discountPercentage: Yup.number().when(['discountType', 'paymentFrequency'], {
        is: (discountType : DISCOUNT_TYPE, paymentFrequency : PAYMENT_FREQUENCY) =>
                { return paymentFrequency === PAYMENT_FREQUENCY.ONCE && discountType === DISCOUNT_TYPE.Percentage },
        then: Yup.number()
        .required(`${componentLabels.discountPercentage} ${componentLabels.isRequired}.`)
        .min(1, `${componentLabels.discountPercentage} ${componentLabels.shouldBeAPositiveNumber}.`)
        .max(100, `${componentLabels.discountPercentage} ${componentLabels.exceeds100Percent}.`)
    }),
    frequency: Yup.number().when(
        'paymentFrequency', {
            is: PAYMENT_FREQUENCY.RECURRING,
            then: Yup.number()
            .required(`${componentLabels.numberOfFrequency} ${componentLabels.shouldBeAPositiveNumber}.`)
            .min(1, `${componentLabels.numberOfFrequency} ${componentLabels.shouldBeAPositiveNumber}.`)
            .max(999, `${componentLabels.numberOfFrequency} ${componentLabels.exceedThreeDigits}.`)
        }
    ),
    interval: Yup.mixed<PaymentPlanOptionRequestIntervalEnum>().oneOf(['WEEK', 'MONTH']).when(
        'paymentFrequency', {
            is: PAYMENT_FREQUENCY.RECURRING,
            then: Yup.mixed<PaymentPlanOptionRequestIntervalEnum>().oneOf(['WEEK', 'MONTH'])
            .required(`${componentLabels.unitOfFrequency} ${componentLabels.isRequired}`)
        }
    ),
    numOfPayment: Yup.number().when(
        ['paymentFrequency', 'recurringEndType'], {
            is: (paymentFrequency : PAYMENT_FREQUENCY, recurringEndType : RECURRING_END_TYPE) =>
                { return paymentFrequency === PAYMENT_FREQUENCY.RECURRING && recurringEndType === RECURRING_END_TYPE.AFTER_PAYMENTS },
            then: Yup.number()
                .required(`${componentLabels.numberOfPayments} ${componentLabels.isRequired}.`)
                .min(2, `${componentLabels.numberOfPayments} ${componentLabels.shouldBeGreaterThanOne}.`)
                .max(999, `${componentLabels.numberOfPayments} ${componentLabels.exceedThreeDigits}.`)
        }
    ),
    lastPaymentDate: Yup.date().when(
        ['paymentFrequency', 'recurringEndType'], {
            is: (paymentFrequency : PAYMENT_FREQUENCY, recurringEndType : RECURRING_END_TYPE) =>
                { return paymentFrequency === PAYMENT_FREQUENCY.RECURRING && recurringEndType === RECURRING_END_TYPE.ON_DATE },
            then: Yup.date()
                .required(`${componentLabels.endDate} ${componentLabels.isRequired}.`)
                .test("lastPaymentDateMustLieWithinValidInterval", componentLabels.badEndDateGiven, function (lastPaymentDate? : Date) {
                    const lastPaymentDateMustLieWithinValidInterval = (frequency? : string, interval? : PaymentPlanOptionRequestIntervalEnum, lastPaymentDate? : Date) => {
                        if(!(frequency && interval && lastPaymentDate))
                            return false

                        const currentDateAtMidnight = moment().startOf('day').toDate();
                        switch (interval) {
                            case SCHEDULE_INTERVAL.WEEK: {
                                let newDate = moment().startOf('day').toDate();
                                newDate.setDate(newDate.getDate() + (parseInt(frequency) * 7));
                                return newDate <= new Date(lastPaymentDate);
                            }
                            case SCHEDULE_INTERVAL.MONTH: {
                                let newDate = moment().startOf('day').toDate();
                                newDate.setMonth(currentDateAtMidnight.getMonth() + parseInt(frequency));
                                return newDate <= new Date(lastPaymentDate);
                            }
                            default:
                                return false;
                        }
                    };
                    const { frequency, interval } = this.parent;
                    return lastPaymentDateMustLieWithinValidInterval(frequency, interval, lastPaymentDate);
                }),
        }
    ),

    // TODO: Can use Object.values(enums) when upgrading from Typescript version 4.3.5 (not sure what is the minimum upgrade version)
    paymentFrequency: Yup.mixed<PAYMENT_FREQUENCY>().oneOf([PAYMENT_FREQUENCY.ONCE, PAYMENT_FREQUENCY.RECURRING]),
    recurringEndType: Yup.mixed<RECURRING_END_TYPE>().oneOf([RECURRING_END_TYPE.AFTER_PAYMENTS, RECURRING_END_TYPE.ON_DATE])
});

const AddOrUpdatePaymentPlanOptionForm : React.FC<Props> = ({
    billerCode, optionId
}) => {
    /* State and callback declarations */
    const [formStatus, setFormStatus] = useState<FORM_STATUS>(FORM_STATUS.AWAITING_FORM_SUBMISSION);
    const defaultRedirectPath = PlatformRoutesConfiguration.paymentPlanSettingsRoute?.landingPageBillerCodeSelected.generatePath(billerCode) ?? "";
    const [redirectPath, setRedirectPath] = useState<string>(defaultRedirectPath);
    const [isUpdatingOption] = useState<boolean>(!!optionId);
    const [submitErrors, setSubmitErrors] = useState<string[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        if (billerCode) {
            VerifyBiller(billerCode, true);
        }
    }, [billerCode])

    const [existingPaymentPlanOption, existingPaymentPlanOptionStatus] = useApiCall(() => {
        if (optionId) {
            return paymentPlanOptionApi.getPaymentPlanOption(optionId);
        }
    }, [optionId]);

    useMemo(() => {
        // If the record can't be found (status is ERROR) or if the biller code in the URL does not match the biller code on the payment plan option record then display a 404.
        if ((optionId && existingPaymentPlanOptionStatus === APICallRequestState.ERROR) || (existingPaymentPlanOption && existingPaymentPlanOption.billerCode !== billerCode)) {
            navigate(PlatformRoutesConfiguration.accountRoute.notFound.generatePath());
        }
    }, [optionId, existingPaymentPlanOptionStatus, existingPaymentPlanOption, billerCode]);

    /* TODO: react-router-dom v5 - v6 got rid of .block, need to wait for the functionality to come back or replace it
    // Used to block the user from navigating away from the page if the form has unsaved changes
    const navigation = useNavigate();
    useEffect(() => {
        // @ts-ignore
        let unblock = navigation.block((location : any, action : any) => {
            if (hasUnsavedChanges && formStatus === FORM_STATUS.AWAITING_FORM_SUBMISSION) {
                setRedirectPath(location);
                setFormStatus(FORM_STATUS.RECONFIRMATION_EXIT);
                return false;
            }
            // Let navigation continue
            return true;
        });

        return () => { unblock() }; // State doesn't automatically refresh in the callback so this would cleanup
        // the previous callback when the useEffect is retriggered due to its dependency updates.
    }, [hasUnsavedChanges, formStatus, navigation]);

    <Dialog
        show={hasUnsavedChanges && formStatus === FORM_STATUS.RECONFIRMATION_EXIT}
        closeButton
        onClose={() => setFormStatus(FORM_STATUS.AWAITING_FORM_SUBMISSION)}
        icon={<Icon alert />}
        title='Changes to payment plan option are not saved'
        footerButtons={<>
            <Button primary onClick={() => setFormStatus(FORM_STATUS.AWAITING_FORM_SUBMISSION)}>Continue editing</Button>
            <Button onClick={() => setFormStatus(FORM_STATUS.REDIRECT_TO_ALTERNATIVE_PAGE)}>Discard changes</Button>
        </>}
    >
        {labels.leavingPageUnsavedChange}
    </Dialog>
    */
    // Redirects to the landing page if the client uses the Back or Cancel button
    const exitToLandingPageCallback = () => {
        setRedirectPath(defaultRedirectPath);
        setFormStatus(FORM_STATUS.REDIRECT_TO_ALTERNATIVE_PAGE);
    }

    const generateDTO = (formValues : AddOrUpdatePaymentPlanOptionFormFields) => {
        // Since the client could had just filled in the entire form for whatever reason, the DTO
        // needs to remove any unnecessary information since the absence of specific fields can determine
        // what properties does a specific payment plan option have
        let dto : PaymentPlanOptionRequest = {
            paymentPlanOptionId: isUpdatingOption ? formValues.paymentPlanOptionId : undefined,
            paymentPlanOptionName: formValues.paymentPlanOptionName,
            availableFrom: formValues.availableFrom instanceof Date ? dateutil.convertToApiValue(formValues.availableFrom) : undefined,
            availableTo: formValues.availableTo instanceof Date ? dateutil.convertToApiValue(formValues.availableTo) : undefined,
            billerCode: billerCode
        };

        switch (formValues.paymentFrequency) {
            case PAYMENT_FREQUENCY.ONCE:
                dto.discountType = formValues.discountType;
                if (formValues.discountType === DISCOUNT_TYPE.Amount) {
                    dto.discountAmount = formValues.discountAmount;
                } else if (formValues.discountType === DISCOUNT_TYPE.Percentage) {
                    dto.discountPercentage = formValues.discountPercentage
                }
                break;
            case PAYMENT_FREQUENCY.RECURRING:
                dto.frequency = formValues.frequency;
                dto.interval = formValues.interval;
                if (formValues.recurringEndType === RECURRING_END_TYPE.AFTER_PAYMENTS) {
                    dto.numOfPayment = formValues.numOfPayment;
                } else if (formValues.recurringEndType === RECURRING_END_TYPE.ON_DATE) {
                    dto.lastPaymentDate = formValues.lastPaymentDate instanceof Date ? dateutil.convertToApiValue(formValues.lastPaymentDate) : undefined;
                }
                break;
            default:
                throw new Error("Expected value for paymentFrequency");
        }

        return dto;
    }

    const handleSubmit = async (formValues : AddOrUpdatePaymentPlanOptionFormFields) => {
        const dto = generateDTO(formValues);
        try {
            const request = isUpdatingOption ? await paymentPlanOptionApi.updatePaymentPlanOption(dto) : await paymentPlanOptionApi.addPaymentPlanOption(dto);
            if (request?.status !== 200 || request?.data?.errors?.length)  {
                throw request;
            }
            setFormStatus(FORM_STATUS.SUBMITTED_SUCCESSFULLY);
        } catch (request : any) {
            if(request?.data?.errors?.length) {
                setSubmitErrors(request.data.errors.map((r : any) => r.message));
            } else {
                setSubmitErrors([labels.unknownErrorMessage]);
            }
            setFormStatus(FORM_STATUS.AWAITING_FORM_SUBMISSION);
        }
    }

    const getMinDateForLastPaymentDate = () => {
        if (existingPaymentPlanOption?.lastPaymentDate) {
            return new Date(existingPaymentPlanOption.lastPaymentDate);
        } else {
            return moment().startOf('day').toDate();
        }
    }

    if (formStatus === FORM_STATUS.REDIRECT_TO_ALTERNATIVE_PAGE) {
        return <Navigate to={redirectPath}></Navigate>;
    }

    return <>
        <PageHeader
            subtitle={isUpdatingOption ? <p>Editing an option will not impact the customers currently on this option. The updated option is only available for new payment plan signups.</p> : null}
            backButton={{ onClick: exitToLandingPageCallback }} title={`${isUpdatingOption ? 'Edit' : 'Add'} payment plan option`}
        />

        { !isUpdatingOption || existingPaymentPlanOptionStatus === APICallRequestState.SUCCESSFUL ?
        <Formik
            initialValues = {existingPaymentPlanOption ? {
                paymentPlanOptionId: existingPaymentPlanOption.paymentPlanOptionId,
                paymentPlanOptionName: existingPaymentPlanOption.paymentPlanOptionName,
                discountType: existingPaymentPlanOption.discountType,
                discountAmount: existingPaymentPlanOption.discountAmount,
                discountPercentage: existingPaymentPlanOption.discountPercentage,
                frequency: existingPaymentPlanOption.frequency,
                interval: existingPaymentPlanOption.interval,
                numOfPayment: existingPaymentPlanOption.numOfPayment, // value for how many payments need to be made for payment plan to complete
                lastPaymentDate: existingPaymentPlanOption.lastPaymentDate ?  new Date(dateutil.convertToMerchantDate(existingPaymentPlanOption.lastPaymentDate)) : undefined,
                paymentFrequency: existingPaymentPlanOption.frequency ? PAYMENT_FREQUENCY.RECURRING : PAYMENT_FREQUENCY.ONCE,
                recurringEndType: existingPaymentPlanOption.numOfPayment ? RECURRING_END_TYPE.AFTER_PAYMENTS : RECURRING_END_TYPE.ON_DATE,
                availableFrom: existingPaymentPlanOption.availableFrom ?  new Date(dateutil.convertToMerchantDate(existingPaymentPlanOption.availableFrom)) : undefined,
                availableTo: existingPaymentPlanOption.availableTo ? new Date(dateutil.convertToMerchantDate(existingPaymentPlanOption.availableTo)) : undefined
            } : defaultInitialValues }
            onSubmit={(formValues : AddOrUpdatePaymentPlanOptionFormFields, actions : any) => {
                handleSubmit(formValues)
            }}
            validationSchema={AddOrUpdatePaymentPlanOptionSchema}
            >
            {(props : FormikProps<any>) =>  {
                return (<Form>
                <SingleColumnFormContainer>
                    <h3>{componentLabels.basicDetail}</h3>
                    <FormGroup label={componentLabels.optionName} name='paymentPlanOptionName' mandatory>
                        <Field as={Input} name='paymentPlanOptionName' />
                    </FormGroup>
                    <p><b>{componentLabels.availablePeriod}</b><Icon question data-tip data-for='tip-available-period' /></p>
                    <Row>
                        <FormGroup label={"Start"} className='col-sm-6' name='availableFrom'>
                            <Field as={FormikDatePicker} name='availableFrom' />
                        </FormGroup>
                        <FormGroup label={"End"} className='col-sm-6' name='availableTo'>
                            <Field as={FormikDatePicker} name='availableTo' />
                        </FormGroup>
                    </Row>
                    <Divider />
                    <h3>{componentLabels.paymentFrequency}</h3>
                    <Field as={FormikRadioButton} id='recurOnceOptionButton' name='paymentFrequency' label={componentLabels.recurOnce} value={PAYMENT_FREQUENCY.ONCE} />
                    <div className='frequency-section'>
                        {props.values.paymentFrequency === PAYMENT_FREQUENCY.ONCE &&
                            <FormGroup label={componentLabels.discountType} name='discountType'>
                            <Field
                                as={FormikDropdown}
                                name='discountType'
                                options={[
                                    { value: DISCOUNT_TYPE.None, label: componentLabels.noDiscountProvided },
                                    { value: DISCOUNT_TYPE.Amount, label: componentLabels.discountByAmount },
                                    { value: DISCOUNT_TYPE.Percentage, label: componentLabels.discountByPercentage }
                                ]}
                            />
                            </FormGroup>
                        }
                        {props.values.paymentFrequency === PAYMENT_FREQUENCY.ONCE && props.values.discountType === DISCOUNT_TYPE.Amount &&
                            <FormGroup label={componentLabels.discountAmount} name='discountAmount'>
                            <Field
                                as={FormikCurrencyInput}
                                name='discountAmount'
                            />
                            </FormGroup>
                        }
                        {props.values.paymentFrequency === PAYMENT_FREQUENCY.ONCE && props.values.discountType === DISCOUNT_TYPE.Percentage &&
                            <FormGroup label={componentLabels.discountPercentage} name='discountPercentage'>
                                <InputGroup>
                                    <Field
                                        as={Input}
                                        name='discountPercentage'
                                        type='number'
                                    />
                                    <InputGroup.Text>%</InputGroup.Text>
                                </InputGroup>
                            </FormGroup>
                        }
                    </div>

                    <Row>
                        <FormGroup name="recurringOptionButton" className='col-sm-4'>
                            <Field as={FormikRadioButton} id="recurringOptionButton" name='paymentFrequency' label={componentLabels.recurEvery} value={PAYMENT_FREQUENCY.RECURRING} />
                        </FormGroup>
                        <FormGroup name='frequency' className='col-sm-3'>
                            <Field as={Input} name='frequency' placeholder={componentLabels.frequencyPlaceholder} disabled={props.values.paymentFrequency !== PAYMENT_FREQUENCY.RECURRING}  />
                        </FormGroup>
                        <FormGroup name='interval' className='col-sm-5'>
                            <Field
                                as={FormikDropdown}
                                name='interval'
                                options={[
                                    { value: SCHEDULE_INTERVAL.WEEK, label: componentLabels.scheduleInterval.weeks },
                                    { value: SCHEDULE_INTERVAL.MONTH, label: componentLabels.scheduleInterval.months }
                                ]}
                                disabled={props.values.paymentFrequency !== PAYMENT_FREQUENCY.RECURRING}
                            />
                        </FormGroup>
                    </Row>
                    {props.values.paymentFrequency === PAYMENT_FREQUENCY.RECURRING &&
                        <>
                            <Row>
                                <div className='col-sm-10 offset-sm-1'>
                                    <h4 className='offset-for-inline-textbox'>{componentLabels.endDate}</h4>
                                </div>
                            </Row>
                            <Row>
                                <FormGroup name='recurringEndType' className='col-sm-4 offset-sm-1'>
                                    <Field as={FormikRadioButton} id="endAfterOptionButton" name='recurringEndType' label={componentLabels.endAfter} value={RECURRING_END_TYPE.AFTER_PAYMENTS} />
                                </FormGroup>
                                <FormGroup name='numOfPayment' className='col-sm-4'>
                                    <Field as={Input} name='numOfPayment' disabled={props.values.recurringEndType !== RECURRING_END_TYPE.AFTER_PAYMENTS} />
                                </FormGroup>
                                <div className='col-sm-3 offset-for-inline-textbox'>
                                    <span>{componentLabels.payments}</span>
                                </div>
                            </Row>
                            <Row>
                                <FormGroup name='recurringEndType' className='col-sm-4 offset-sm-1'>
                                    <Field as={FormikRadioButton} id="endOnOptionButton" name='recurringEndType' label={componentLabels.endOn} value={RECURRING_END_TYPE.ON_DATE} />
                                </FormGroup>
                                <FormGroup name='lastPaymentDate' className='col-sm-7'>
                                    <Field as={FormikDatePicker} name='lastPaymentDate' minDate={getMinDateForLastPaymentDate()} disabled={props.values.recurringEndType !== RECURRING_END_TYPE.ON_DATE} />
                                </FormGroup>
                            </Row>
                            {
                                props.values.paymentFrequency === PAYMENT_FREQUENCY.RECURRING && props.isValid ?
                                <PromotionCard>
                                    <IconText info>
                                        To see this instalments summary for this option, please check <Button className="click-here-button" link onClick={()=> { setFormStatus(FORM_STATUS.DISPLAY_INSTALMENTS_SUMMARY_MODAL) }}>here</Button>.
                                    </IconText>
                                </PromotionCard> : null
                            }
                        </>
                    }

                    <Divider />
                    <Button type="submit" primary>{existingPaymentPlanOption ? 'Update option' : 'Add option'}</Button>
                    <Button onClick={exitToLandingPageCallback}>Cancel</Button>
                    { submitErrors.length ? submitErrors.map((errorMessage, i) =><ErrorText key={i}>{errorMessage}</ErrorText>) : null }
                    { !props.isValid && props.submitCount > 0 ? <ErrorText>{componentLabels.submitValidationErrorMessage}</ErrorText> : null }
                    <Tooltip id='tip-available-period'>
                        <p>
                            {componentLabels.tooltip}
                        </p>
                    </Tooltip>
                </SingleColumnFormContainer>
                <SuccessModal
                    show={formStatus === FORM_STATUS.SUBMITTED_SUCCESSFULLY}
                    title={`Payment plan option ${isUpdatingOption ? 'updated' : 'added'} successfully!`}
                    onClose={() => { setFormStatus(FORM_STATUS.REDIRECT_TO_ALTERNATIVE_PAGE) }}
                />
                <InstalmentsSummaryDialog
                    show={props.values.paymentFrequency === PAYMENT_FREQUENCY.RECURRING && props.isValid && formStatus === FORM_STATUS.DISPLAY_INSTALMENTS_SUMMARY_MODAL}
                    closeButton
                    onClose={() => setFormStatus(FORM_STATUS.AWAITING_FORM_SUBMISSION)}
                    title='Instalments summary'
                    footerButtons={<>
                        <Button onClick={() => setFormStatus(FORM_STATUS.AWAITING_FORM_SUBMISSION)}>Close</Button>
                    </>}
                    request ={{
                        amount: SAMPLE_INSTALMENTS_PLAN_AMOUNT,
                        frequency: props.values.frequency,
                        interval: props.values.interval,
                        numOfPayment: props.values.recurringEndType === RECURRING_END_TYPE.AFTER_PAYMENTS ? props.values.numOfPayment : undefined,
                        lastPaymentDate: props.values.recurringEndType === RECURRING_END_TYPE.ON_DATE && props.values.lastPaymentDate instanceof Date ? dateutil.convertToApiValue(props.values.lastPaymentDate) : undefined
                    }}
                />
                </Form>
            )
            }
        }
        </Formik> : <LoadingIndicator />}
    </>;
};

export default AddOrUpdatePaymentPlanOptionForm;
