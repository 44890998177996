import * as billpayUtil from "@premier/utils/billpay";
import { ResponsiveTable, CardLogo } from "@premier/ui";

// @ts-ignore
import { PaymentTypeKey } from "constants/billpay";
// @ts-ignore
import labels from "constants/labels";
import "./TokenSelector.scss";
import { Token } from "models/Token";

type Props = {
    /** List of tokens that can be linked */
    tokens: Token[];
    /** The token already linked to this resource */
    selectedToken: Token | null;
    /** What happens when a token is selected from the list */
    onTokenSelected: (paymentMethod: Token) => void;
    /** Is data for the table loading */
    isLoading: boolean;
}

/** A ResponsiveTable for selecting a single Token from a list of Tokens */
const TokenSelector = ({
    tokens, selectedToken, //data
    onTokenSelected, //functions
    isLoading, //logic renders
}: Props) => {

    // The table columns (or list items)
    const columns = [{
        label: labels.accountType,
        getter: (item: Token) => item.type === PaymentTypeKey.CARD ? <CardLogo network={{ serverCode: item.cardTypeCode }}/> : "Bank account"
    }, {
        label: labels.cardOrBankAccountNumber,
        getter: (item: Token) => item.maskedCardNumber || item.deAccountNumber
    }, {
        label: labels.expiryDate,
        getter: (item: Token) => billpayUtil.formatExpiry(item.expiryDate)
    }];

    return (<>
        <ResponsiveTable
            className="token-selector"
            breakpoints={["xs", "sm"]}
            data={tokens}
            initialSelection={selectedToken ? [selectedToken] : []}
            isLoading={isLoading}
            columns={columns}
            noDataText="No tokens to display."
            singleSelect
            onItemSelected={onTokenSelected}
        />
    </>);
};

export default TokenSelector;
