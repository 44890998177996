import { ReactNode } from 'react';
import './WarningText.scss';
import { FaExclamationTriangle } from 'react-icons/fa';

type Props = {
    children: ReactNode,
}

/** Shows an warning Icon followed by yellow text */
const WarningText = ({
    children
}: Props) => {
    return (
        <div className='warning-icon-text'>
            <FaExclamationTriangle size={18} color="orange" />
            <div className='icon-text-contents'>{children}</div>
        </div>
    );
}

export default WarningText;