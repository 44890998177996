import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { PaddedContainer, PageSection, Row, Divider } from '@premier/ui';
import { PageHeader } from 'components/Common';
import './PromotionBoxesPageSection.scss';

/**A Onboarding Page Section, that displayes header, subtext 3 items in a row (in a box), and buttons.
 * Used for promoting new things in the UX site. */
const PromotionBoxesPageSection = ({
    title, subText, children, backButton, //data
    promotions, //promotion box data
    className, noDivider //styling
}) => {

    return (
        <PageSection className={classNames('onboarding-page-section', className)} noDivider={noDivider} >
            {title && <PageHeader title={title} backButton={backButton} />}
            {subText && <p>{subText}</p>}

            <PaddedContainer>
                <Row divided>
                    {promotions && promotions.map((promotion, index) => {
                        return (
                            <div className='col-lg-4' key={index} >
                                {promotion}
                                {index < promotions.length - 1 && <Divider className='d-lg-none' />}
                            </div>
                        )
                    })}
                </Row>
            </PaddedContainer>

            {children}
        </PageSection>
    )
}

PromotionBoxesPageSection.propTypes = {
    /**Title rendered in a pageHeader */
    title: PropTypes.string,

    /** Sub text rendered in <p> tags*/
    subText: PropTypes.string,

    className: PropTypes.string,

    /**Promotion Box info */
    promotions: PropTypes.arrayOf(PropTypes.node).isRequired,

    /**To render / not render the divider in the page section */
    noDivider: PropTypes.bool,

    /** To show / hide the back button, and add custom properties to it */
    backButton: PropTypes.oneOfType([PropTypes.bool, PropTypes.object])
}

export default PromotionBoxesPageSection;