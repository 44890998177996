import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { PaddedContainer, Switch, Divider, Checkbox, RadioInput } from '@premier/ui';

import * as billerActions from 'components/Settings/_actions/billerActions';

/**
 * A PaddedContainer of tokenisation settings for a Biller.
 */
const TokenisationSettingsSection = ({
    biller,
    updateBiller,
    actions
}) => {

    const [selectedTokenisationMode, setSelectedTokenisationMode] = useState('None');
    const [selectedTokeniseApprovedOnlyTxns, setSelectedTokeniseApprovedOnlyTxns] = useState(false);

    const billerTokenisationMode = useMemo(() => _.get(biller, 'txnTokenisationMode.key', ''), [biller]);
    const billerTokeniseApprovedOnlyTxns = useMemo(() => biller && biller.tokeniseApprovedOnlyTxns, [biller]);

    useEffect(() => {
        setSelectedTokenisationMode(billerTokenisationMode);
        setSelectedTokeniseApprovedOnlyTxns(billerTokeniseApprovedOnlyTxns);
    }, [biller]);

    function handleTokenisationModeChanged(mode) {
        setSelectedTokenisationMode(mode);

        actions.updateBiller({
            ...biller,
            txnTokenisationMode: mode,
            tokeniseApprovedOnlyTxns: billerTokeniseApprovedOnlyTxns
        });
    }

    function handleTokeniseApprovedOnlyTxnsChanged(tokeniseApprovedOnlyTxns) {
        setSelectedTokeniseApprovedOnlyTxns(tokeniseApprovedOnlyTxns);

        actions.updateBiller({
            ...biller,
            txnTokenisationMode: billerTokenisationMode,
            tokeniseApprovedOnlyTxns: tokeniseApprovedOnlyTxns
        });
    }

    return <PaddedContainer lessMargin noDivider
        title='Save payment method as token?'
        button={<Switch
                    on={selectedTokenisationMode !== 'None'}
                    onChange={() => handleTokenisationModeChanged(billerTokenisationMode === 'None' ? 'All' : 'None')}
                    disabled={!biller || updateBiller.isLoading}
                />}
    >
        <p>
            You can save customers' payment details securely as a token for future payments. This will apply globally including your hosted payment page.
        </p>

        {(biller && billerTokenisationMode !== 'None') && <>
            <Divider />
            <h4>How do you want to save payment methods?</h4>
            <RadioInput
                name="tokenisationMode"
                label='Save if customer opts-in'
                checked={selectedTokenisationMode === 'OptIn'}
                onChange={() => handleTokenisationModeChanged('OptIn')}
                disabled={updateBiller.isLoading}
            />
            <p>
                This option applies only to payments through Hosted Payment Page or API.
            </p>
            <RadioInput
                name="tokenisationMode"
                label='Save for all payments'
                checked={selectedTokenisationMode === 'All'}
                onChange={() => handleTokenisationModeChanged('All')}
                disabled={updateBiller.isLoading}
            />
            
            <Divider />
            <Checkbox
                label='Save for approved payments only'
                checked={selectedTokeniseApprovedOnlyTxns}
                onChange={() => handleTokeniseApprovedOnlyTxnsChanged(!selectedTokeniseApprovedOnlyTxns)}
                disabled={updateBiller.isLoading}
            />
            <p>If this option is enabled, card details will be stored only if the payment is successful.</p>
        </>}
    </PaddedContainer>
};

TokenisationSettingsSection.propTypes = {
    billerCode: PropTypes.string.isRequired,
};

function mapStateToProps(state, ownProps) {
    const biller = state.accounts.users.billers
                    && state.accounts.users.billers.find(biller => biller.billerCode === ownProps.billerCode);

    return {
        biller,
        updateBiller: state.settings.paymentSettings.updateBiller
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(billerActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(TokenisationSettingsSection);