import React, { useState, useEffect } from "react";
import { PaddedContainer, Button } from "@premier/ui";
import { LCRConfigurationDialog } from "..";
import { useApiCallDeprecated, APICallRequestState, ProductTooltip, PageHeader } from "components/Common";
import currencyUtil from "@premier/utils/currency";
import LCRConfigurationApi from "api/LCRConfigurationApi";

import "./LCRConfigurationSection.scss";

interface Props {
    allowAmountThreshold: boolean;
}

export const PrimaryNetwork = {
    MASTERCARD: "MASTERCARD",
    VISA: "VISA",
    EFTPOS: "EFTPOS",
};


export function getLCRConfigurationContent(amount: number, secondaryNetwork: string, majorNetwork: string) {
    return (<>
        {" all transactions above "}
        <span style={{ whiteSpace: "nowrap" }}>{amount ? currencyUtil.formatWithPrefix(amount) : currencyUtil.formatWithPrefix(0)}</span>
        {` will be processed through the ${secondaryNetwork} network and all others will be processed through the ${majorNetwork} network.`}
    </>);
}

export const LCRConfigurationSection: React.FC<Props> = ({ allowAmountThreshold }) => {
    const [showLCRConfigurationDialog, setShowLCRConfigurationDialog] = useState(false);
    const [lcrConfigurationModel, setLCRConfigurationModel] = useState<any>(null);

    const [LCRConfigurationSettings, loadedLCRConfigurationSettingsStatus] = useApiCallDeprecated(() => {
        //@ts-ignore
        return LCRConfigurationApi.getLCRConfiguration();
    }, [showLCRConfigurationDialog]);

    useEffect(() => {
        if (loadedLCRConfigurationSettingsStatus === APICallRequestState.SUCCESSFUL) {
            const LCRModel = {
                isMasterCardThresholdSet: LCRConfigurationSettings?.mcMaxAmountSet,
                isVisaThresholdSet: LCRConfigurationSettings?.vcMaxAmountSet,
                masterCardThresholdAmount: currencyUtil.formatApiValue(LCRConfigurationSettings?.mcMaxAmount),
                visaThresholdAmount: currencyUtil.formatApiValue(LCRConfigurationSettings?.vcMaxAmount),
                visaPrimaryNetwork: LCRConfigurationSettings?.vcPrimaryNetwork === "Eftpos" ? PrimaryNetwork.EFTPOS : PrimaryNetwork.VISA,
                masterCardPrimaryNetwork: LCRConfigurationSettings?.mcPrimaryNetwork === "Eftpos" ? PrimaryNetwork.EFTPOS : PrimaryNetwork.MASTERCARD,
            };
            setLCRConfigurationModel(LCRModel);
        }
    }, [loadedLCRConfigurationSettingsStatus, LCRConfigurationSettings]);

    function handleLCRDialogClose() {
        setShowLCRConfigurationDialog(false);
    }

    return (
        <>
            <div id="lcr-configuration-section">
                <PaddedContainer title="Least Cost Routing" titleSuffix={<ProductTooltip productTooltipModule={"LEAST_COST_ROUTING_SETTINGS"} />} button={<Button onClick={() => setShowLCRConfigurationDialog(true)}>Edit</Button>} lessMargin noDivider>
                    <p>
                        Choose which network(s) payments are sent through for dual-network debit cards, giving you the freedom to choose the option that's most cost-effective for your business.
                    </p>

                    <dt>Current setting:</dt>
                    <p>
                        For Mastercard dual network debit cards,

                        {!lcrConfigurationModel?.isMasterCardThresholdSet && lcrConfigurationModel?.masterCardPrimaryNetwork === PrimaryNetwork.MASTERCARD && " all transactions will be processed via the Mastercard network."}
                        {!lcrConfigurationModel?.isMasterCardThresholdSet && lcrConfigurationModel?.masterCardPrimaryNetwork === PrimaryNetwork.EFTPOS && " all transactions will be processed via the eftpos network."}
                        {lcrConfigurationModel?.isMasterCardThresholdSet && lcrConfigurationModel?.masterCardPrimaryNetwork === PrimaryNetwork.MASTERCARD &&
                            getLCRConfigurationContent(lcrConfigurationModel?.masterCardThresholdAmount, "eftpos", "Mastercard")
                        }
                        {lcrConfigurationModel?.isMasterCardThresholdSet && lcrConfigurationModel?.masterCardPrimaryNetwork === PrimaryNetwork.EFTPOS &&
                            getLCRConfigurationContent(lcrConfigurationModel?.masterCardThresholdAmount, "Mastercard", "eftpos")
                        }
                    </p>

                    <p>
                        For Visa dual network debit cards,
                        {!lcrConfigurationModel?.isVisaThresholdSet && lcrConfigurationModel?.visaPrimaryNetwork === PrimaryNetwork.VISA && " all transactions will be processed via the Visa network."}
                        {!lcrConfigurationModel?.isVisaThresholdSet && lcrConfigurationModel?.visaPrimaryNetwork === PrimaryNetwork.EFTPOS && " all transactions will be processed via the eftpos network."}
                        {lcrConfigurationModel?.isVisaThresholdSet && lcrConfigurationModel?.visaPrimaryNetwork === PrimaryNetwork.VISA &&
                            getLCRConfigurationContent(lcrConfigurationModel?.visaThresholdAmount, "eftpos", "Visa")
                        }
                        {lcrConfigurationModel?.isVisaThresholdSet && lcrConfigurationModel?.visaPrimaryNetwork === PrimaryNetwork.EFTPOS &&
                            getLCRConfigurationContent(lcrConfigurationModel?.visaThresholdAmount, "Visa", "eftpos")
                        }
                    </p>
                </PaddedContainer>
            </div>

            <LCRConfigurationDialog
                show={showLCRConfigurationDialog}
                onClose={handleLCRDialogClose}
                lcrConfigurationModel={lcrConfigurationModel}
                allowAmountThreshold={allowAmountThreshold}
            />

        </>);
};

export default LCRConfigurationSection;