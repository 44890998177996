import ReactSelect from "react-select";
import classNames from "classnames";
import { UiOption } from "../models";
import "./Dropdown.scss";

export type DropdownProps<TValue extends string | number> = {
    options: UiOption<TValue>[];
    /** The initially-selected option or option.value */
    defaultValue?: UiOption<TValue> | TValue;
    /** The selected option or option.value */
    value?: UiOption<TValue> | TValue;
    /** A more compact version of the dropdown, reducing the width, but keeping the height */
    compact?: boolean;
    /** The text displayed when no option is selected (Default = Select...) */
    placeholder?: string;
    isSearchable?: boolean;
    isClearable?: boolean;
    disabled?: boolean;
    error?: boolean | string;
    styles?: any;
    onChange?: (selectedOption: UiOption<TValue> | null) => void;
    className?: string;
    id?: string;
};

const Dropdown = <TValue extends string | number>({
    options, defaultValue, value, onChange,  // data
    compact, className,  // style
    disabled, error,  // UI state
    ...otherProps
}: DropdownProps<TValue>) => {

    // Allow setting defaultValue and value by option.value (in addition to the whole option object)
    const defaultValueAsOption: UiOption<TValue> | undefined =
        (typeof defaultValue === "string" || typeof defaultValue === "number")
            ? options.find(o => o.value === defaultValue)
            : defaultValue as UiOption<TValue> | undefined;

    const valueAsOption: UiOption<TValue> | undefined =
        (typeof value === "string" || typeof value === "number")
            ? options.find(o => o.value === value)
            : value as UiOption<TValue> | undefined;

    function handleChange(value: UiOption<TValue> | null) {
        onChange && onChange(value);
    }

    return (
        <ReactSelect
            className={classNames("dropdown", className, {
                "compact": compact,
                "has-error": error
            })}
            classNamePrefix="rselect"
            options={options}
            defaultValue={defaultValueAsOption}
            isDisabled={disabled}
            isOptionDisabled={option => option.disabled ?? false}
            onChange={handleChange}
            value={valueAsOption}
            {...otherProps}
        />
    );
};

export default Dropdown;
