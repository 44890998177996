import React from 'react';
import PropTypes from 'prop-types';

import currencyUtil from '@premier/utils/currency';

import { ResponsiveTable, Link, DateTime } from '@premier/ui';
import { BatchJobStatus } from 'components/Batch';

import { PlatformRoutesConfiguration } from 'components/Routing';

import './BatchJobList.scss';

const BatchJobList = ({
    isLoading, statusFilter, //logic renders
    data, sort, //data
    onSort, onLinkClick, onDownload, openErrorModal //functions
}) => {

    var columns = [{
        label: 'Filename',
        sortKey: 'filename',
        className: 'filename',
        getter: f => <Link to={PlatformRoutesConfiguration.transactionRoute.batchFile.generatePath(f.fileId)} onClick={onLinkClick}>{f.filename}</Link>
    },{
        label: 'Status',
        getter: f => <BatchJobStatus file={f} openErrorModal={openErrorModal}/>,
    },{
        label: 'Uploaded records',
        sortKey: 'uploadedRecordCount',
        getter: f => currencyUtil.formatAmount(f.uploadedRecordCount, 0),
    },{
        label: 'Approved records',
        sortKey: 'approvedRecordCount',
        getter: f => currencyUtil.formatAmount(f.approvedRecordCount, 0),
    },{
        label: 'Uploaded value',
        sortKey: 'uploadedValue',
        getter: f => currencyUtil.convertToDisplayString(f.uploadedValue),
    },{
        label: 'Approved value',
        sortKey: 'approvedValue',
        getter: f => currencyUtil.convertToDisplayString(f.approvedValue),
    },{
        label: 'Time submitted',
        sortKey: 'submittedDateTime',
        getter: f => <DateTime utcValue={f.submittedDateTime} />,
    }];

    if(statusFilter === 'completed') {
        columns = columns.filter(c => c.sortKey !== 'submittedDateTime');
        columns.push({
            label: 'Time processed',
            sortKey: 'processDateTime',
            getter: f => <DateTime utcValue={f.processDateTime} />,
        });
    } else if(statusFilter === 'scheduled') {
        columns = columns.filter(c => c.label !== 'Status' && !c.label.includes('Approved'));
        columns.push({
            label: 'Time scheduled',
            sortKey: 'scheduledDateTime',
            getter: f => <DateTime utcValue={f.scheduledDateTime} />,
        });
    }

    const batchActions = statusFilter === 'completed'
        ? [{
            label: 'Download',
            tableButtonProps: {
                primary: true,
                label: 'Download selected reports',
            },
            handleClick: onDownload,
            pleaseSelectRowText: 'Select batch file to download',
          }]
        : null;


    return (<>
        <ResponsiveTable data={data} columns={columns}
            className='batch-file-list'
            isLoading={isLoading}
            sort={sort}
            onSort={onSort}
            batchActions={batchActions}
        />
    </>);
};

BatchJobList.propTypes = {
    data: PropTypes.array,
    isLoading: PropTypes.bool,
    statusFilter: PropTypes.oneOf(['completed', 'inProgress', 'scheduled']).isRequired,

    /** The current sort order */
    sort: PropTypes.shape({
        field: PropTypes.string,
        descending: PropTypes.bool,
    }),
    /** to handle sort field/order changes */
    onSort: PropTypes.func,

    /** What happens when redirecting to view item details (eg. to save the current page state into Redux) */
    onLinkClick: PropTypes.func,
    /** Handle batch download, eg. (batchFiles) => { console.log(batchFiles.map(f => f.fileId)) } */
    onDownload: PropTypes.func,
    /** Show error modal for BatchJobs */
    openErrorModal: PropTypes.func,
};

export default BatchJobList;