import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';

import { DatePicker, RadioGroup, FormGroup } from '@premier/ui';
import FormContext from '../FormContext';
import { validate } from '../validation/fieldValidation';
import withField from './withField';
import './ScheduleField.scss';

const RadioEnum = {
    NOW: 'now',
    LATER: 'later',
};

/** Select now (asap) or pick a later date. The value is like {now: bool, date: Date} */
const ScheduleField = ({
    formProps, groupProps,
    label, nowText, laterText
}) => {

    const { setValidation, getValidation } = useContext(FormContext);
    const { value, error, setValue } = formProps;
    const { name, className } = groupProps;

    const [id] = useState(_.uniqueId(`${name}_`));

    useEffect(() => {
        if(!value)
            setValue({now: true, date: null});  // Initialise

        var validation = getValidation(name);
        if (!validation)
            setValidation(name, validate().when(
                val => val.now || val.date,
                'Please select a date'
            ));
    },[])

    function handleRadioChange(option) {
        setValue({
            now: option.value === RadioEnum.NOW,
            date: option.value === RadioEnum.NOW ? null : value.date
        });
    }

    function handleDateChange(dates) {
        var selectedDate = dates.length ? dates[0] : null;
        setValue({
            date: selectedDate,
            now: value.now === false ? value.now : !selectedDate,  // Keep 'false' as it is to allow user to select the radio before picking a date
        });
    }

    return (
        <FormGroup
            {...groupProps}
            fieldId={id}
            className={classNames('schedule-field', className)}
            error={error}
        >
            <RadioGroup
                inlineUpLg
                name={`${id}_radio`}
                label={label}
                selectedValue={_.get(value, 'now') !== false ? RadioEnum.NOW : RadioEnum.LATER}
                onChange={handleRadioChange}
                options={[
                    {value: RadioEnum.NOW, label: nowText},
                    {value: RadioEnum.LATER, label: (<>
                        {laterText}
                        <DatePicker
                            enableTime
                            minDate={new Date()}
                            value={[_.get(value, 'date')]}
                            onChange={handleDateChange}
                        />
                    </>)},
                ]}
            />
        </FormGroup>
    );
};

ScheduleField.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    nowText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    laterText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

ScheduleField.defaultProps = {
    nowText: 'As soon as possible',
    laterText: 'Schedule on',
}

export default withField(ScheduleField);