import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Navigate } from 'react-router-dom';

import errorUtil from '@premier/utils/error';

import { ButtonContainer } from '@premier/ui';
import { SubmitButton, Form } from '@premier/form';
import { FloatingContainer, FormHeader, FormError, withError } from 'components/Common';
import { NewPasswordFields } from 'components/Account';

import { PlatformRoutesConfiguration } from 'components/Routing';
import * as accountActions from 'components/Account/_actions/accountActions';

import './ChangePasswordPage.scss';
import labels from 'constants/labels';

const ChangePasswordPage = ({
    actions, //API actions
    isLoading, //state
    errors //form
}) => {
    const [redirect, setRedirect] = useState(false);

    function handleSubmit(values, {resetForm}) {
        actions.changePassword({ currentPassword: values.newPasswordForm.currentPassword, newPassword: values.newPasswordForm.newPassword }).then(() => {
            setRedirect(true);
        }).catch(err => {
            resetForm();
        });
    }

    if (redirect) return <Navigate to={PlatformRoutesConfiguration.accountRoute.changePasswordSuccess.generatePath()} />;

    return (
        <FloatingContainer className='change-password-page'>
            <FormHeader title='Change password' description='Help us identify you' />

            <Form onSubmit={handleSubmit} inlineLabelsUpMd>
                <NewPasswordFields currentPasswordEnabled changePasswordRequired />

                <FormError errors={errors} />

                <ButtonContainer>
                    <SubmitButton disabled={isLoading}>Submit</SubmitButton>
                </ButtonContainer>
            </Form>
        </FloatingContainer>
    );
};

function mapStateToProps(state) {
    return {
        isLoading: !!state.accounts.users.isLoading
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(accountActions, dispatch)
    };
}

function mapStoreToErrors(state) {
    return state.accounts.users.errors;
}

function mapErrorToString(error) {
    var paramLabels = {
        CurrentPassword: labels.currentPassword,
        NewPassword: labels.newPassword
    };

    var customMessages = {
        INCORRECT_PASSWORD_PROVIDED: `${labels.currentPassword} supplied is incorrect`
    };

    return errorUtil.getMessage(error, paramLabels, customMessages);
}

export default withError(
    connect(mapStateToProps, mapDispatchToProps)(ChangePasswordPage),
    mapStoreToErrors,
    mapErrorToString
);
