export const UPLOAD_BATCH_JOB_SUCCESS   = 'UPLOAD_BATCH_JOB_SUCCESS';
export const UPLOAD_BATCH_JOB_FAILED    = 'UPLOAD_BATCH_JOB_FAILED';
export const UPLOAD_BATCH_JOB_REQUESTED = 'UPLOAD_BATCH_JOB_REQUESTED';

export const UPLOAD_BATCH_LIBRARY_SUCCESS   = 'UPLOAD_BATCH_LIBRARY_SUCCESS';
export const UPLOAD_BATCH_LIBRARY_FAILED    = 'UPLOAD_BATCH_LIBRARY_FAILED';
export const UPLOAD_BATCH_LIBRARY_REQUESTED = 'UPLOAD_BATCH_LIBRARY_REQUESTED';

export const APPEND_BATCH_LIBRARY_FILE_SUCCESS   = 'APPEND_BATCH_LIBRARY_FILE_SUCCESS';
export const APPEND_BATCH_LIBRARY_FILE_FAILED    = 'APPEND_BATCH_LIBRARY_FILE_FAILED';
export const APPEND_BATCH_LIBRARY_FILE_REQUESTED = 'APPEND_BATCH_LIBRARY_FILE_REQUESTED';

export const GET_BATCH_JOBS_SUCCESS   = 'GET_BATCH_JOBS_SUCCESS';
export const GET_BATCH_JOBS_FAILED    = 'GET_BATCH_JOBS_FAILED';
export const GET_BATCH_JOBS_REQUESTED = 'GET_BATCH_JOBS_REQUESTED';

export const SAVE_BATCH_FILES_PAGE_STATE = 'SAVE_BATCH_FILES_PAGE_STATE';

export const DOWNLOAD_BATCH_RESULT_SUCCESS   = 'DOWNLOAD_BATCH_RESULT_SUCCESS';
export const DOWNLOAD_BATCH_RESULT_FAILED    = 'DOWNLOAD_BATCH_RESULT_FAILED';
export const DOWNLOAD_BATCH_RESULT_REQUESTED = 'DOWNLOAD_BATCH_RESULT_REQUESTED';

export const CREATE_BATCH_SUCCESS   = 'CREATE_BATCH_SUCCESS';
export const CREATE_BATCH_FAILED    = 'CREATE_BATCH_FAILED';
export const CREATE_BATCH_REQUESTED = 'CREATE_BATCH_REQUESTED';

export const GET_BATCH_LIBRARY_FILES_SUCCESS   = 'GET_BATCH_LIBRARY_FILES_SUCCESS';
export const GET_BATCH_LIBRARY_FILES_FAILED    = 'GET_BATCH_LIBRARY_FILES_FAILED';
export const GET_BATCH_LIBRARY_FILES_REQUESTED = 'GET_BATCH_LIBRARY_FILES_REQUESTED';

export const SAVE_BATCH_LIBRARY_PAGE_STATE = 'SAVE_BATCH_LIBRARY_PAGE_STATE';

export const GET_BATCH_LIBRARY_FILE_SUCCESS   = 'GET_BATCH_LIBRARY_FILE_SUCCESS';
export const GET_BATCH_LIBRARY_FILE_FAILED    = 'GET_BATCH_LIBRARY_FILE_FAILED';
export const GET_BATCH_LIBRARY_FILE_REQUESTED = 'GET_BATCH_LIBRARY_FILE_REQUESTED';

export const DELETE_BATCH_LIBRARY_FILE_SUCCESS   = 'DELETE_BATCH_LIBRARY_FILE_SUCCESS';
export const DELETE_BATCH_LIBRARY_FILE_FAILED    = 'DELETE_BATCH_LIBRARY_FILE_FAILED';
export const DELETE_BATCH_LIBRARY_FILE_REQUESTED = 'DELETE_BATCH_LIBRARY_FILE_REQUESTED';

export const DELETE_BATCH_FILE_SUCCESS   = 'DELETE_BATCH_FILE_SUCCESS';
export const DELETE_BATCH_FILE_FAILED    = 'DELETE_BATCH_FILE_FAILED';
export const DELETE_BATCH_FILE_REQUESTED = 'DELETE_BATCH_FILE_REQUESTED';

export const PROCESS_BATCH_LIBRARY_FILE_SUCCESS   = 'PROCESS_BATCH_LIBRARY_FILE_SUCCESS';
export const PROCESS_BATCH_LIBRARY_FILE_FAILED    = 'PROCESS_BATCH_LIBRARY_FILE_FAILED';
export const PROCESS_BATCH_LIBRARY_FILE_REQUESTED = 'PROCESS_BATCH_LIBRARY_FILE_REQUESTED';

export const EDIT_BATCH_JOB_SCHEDULE_SUCCESS   = 'EDIT_BATCH_JOB_SCHEDULE_SUCCESS';
export const EDIT_BATCH_JOB_SCHEDULE_FAILED    = 'EDIT_BATCH_JOB_SCHEDULE_FAILED';
export const EDIT_BATCH_JOB_SCHEDULE_REQUESTED = 'EDIT_BATCH_JOB_SCHEDULE_REQUESTED';

export const SAVE_BATCH_RECORDS_PAGE_STATE = 'SAVE_BATCH_RECORDS_PAGE_STATE';

export const EDIT_BATCH_RECORD_REQUESTED = 'EDIT_BATCH_RECORD_REQUESTED';
export const EDIT_BATCH_RECORD_SUCCESS = 'EDIT_BATCH_RECORD_SUCCESS';
export const EDIT_BATCH_RECORD_FAILED = 'EDIT_BATCH_RECORD_FAILED';

export const ADD_BATCH_RECORDS_REQUESTED = 'ADD_BATCH_RECORDS_REQUESTED';
export const ADD_BATCH_RECORDS_SUCCESS   = 'ADD_BATCH_RECORDS_SUCCESS';
export const ADD_BATCH_RECORDS_FAILED    = 'ADD_BATCH_RECORDS_FAILED';

export const DELETE_BATCH_LIBRARY_RECORD_REQUESTED = 'DELETE_BATCH_LIBRARY_RECORD_REQUESTED';
export const DELETE_BATCH_LIBRARY_RECORD_SUCCESS = 'DELETE_BATCH_LIBRARY_RECORD_SUCCESS';
export const DELETE_BATCH_LIBRARY_RECORD_FAILED = 'DELETE_BATCH_LIBRARY_RECORD_FAILED';

export const RENAME_BATCH_LIBRARY_FILE_SUCCESS   = 'RENAME_BATCH_LIBRARY_FILE_SUCCESS';
export const RENAME_BATCH_LIBRARY_FILE_FAILED    = 'RENAME_BATCH_LIBRARY_FILE_FAILED';
export const RENAME_BATCH_LIBRARY_FILE_REQUESTED = 'RENAME_BATCH_LIBRARY_FILE_REQUESTED';

export const PROCESS_DUPLICATE_BATCH_JOB_REQUESTED = 'PROCESS_DUPLICATE_BATCH_JOB_REQUESTED';
export const PROCESS_DUPLICATE_BATCH_JOB_SUCCESS = 'PROCESS_DUPLICATE_BATCH_JOB_SUCCESS';
export const PROCESS_DUPLICATE_BATCH_JOB_FAILED = 'PROCESS_DUPLICATE_BATCH_JOB_FAILED';

export const STOP_BATCH_JOB_RETRY_REQUESTED = 'STOP_BATCH_JOB_RETRY_REQUESTED';
export const STOP_BATCH_JOB_RETRY_SUCCESS   = 'STOP_BATCH_JOB_RETRY_SUCCESS';
export const STOP_BATCH_JOB_RETRY_FAILED    = 'STOP_BATCH_JOB_RETRY_FAILED';