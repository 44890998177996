import { useApiCall, APICallRequestState } from "components/Common";
import { LoadingIndicator } from "@premier/ui";
import { useRef } from "react";
import { accountApi } from "api";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { getPlatform } from "platforms/current/util";

interface HCaptchaStandaloneProps {
    onChange : ((token: string, ekey: string) => any);
}

const HCaptchaStandalone : React.FC<HCaptchaStandaloneProps> = ({ onChange }) => {
    const captchaRef = useRef<HCaptcha>(null);

    const [siteKey, siteKeyStatus]= useApiCall(()=> {
        return accountApi.getHCaptchaSiteKey(getPlatform());
    }, []);

    const onLoad = () => {
        // this reaches out to the hCaptcha JS API and runs the
        // execute function on it. you can use other functions as
        // documented here:
        // https://docs.hcaptcha.com/configuration#jsapi
        // @ts-ignore
        captchaRef.current.execute();
    };

    return (siteKeyStatus === APICallRequestState.SUCCESSFUL && siteKey && siteKey.siteKey ? <HCaptcha
        ref={captchaRef}
        onLoad={onLoad}
        onVerify={onChange}
        sitekey={siteKey.siteKey}
    /> : <LoadingIndicator />);
};

export default HCaptchaStandalone;