import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';

import { cardNetwork } from '@premier/cards';
import { CardLogo, Icon, LoadingIndicator, PaddedContainer, Tooltip, TooltipTrigger, Divider, Row } from '@premier/ui';
import { useApiCallDeprecated, APICallRequestState, WalletLogo } from 'components/Common';
import { merchantApi } from 'api';
import KlarnaSettingsSection from './KlarnaSettingsSection';
import LCRConfigurationSection from './LCRConfigurationSection';
import * as merchantActions from 'components/Settings/_actions/merchantActions';
import './AcceptedCardsSections.scss';
import SecurityUtil from '@premier/utils/security'
import { userRoles } from 'components/Routing';

/**
 * A PaddedContainer showing Accepted Cards, a Klarna settings section if Klarna is enabled, LCR configuration section is LCR is enabled.
 */
const AcceptedCardsSections = ({
    actions,
    showLCRConfiguration,
    merchantNumber, paymentMethodsState, authenticatedUser  // Redux states
}) => {

    const paymentMethods = paymentMethodsState.data;

    const supportedCardLogos = useMemo(() => (
        paymentMethods &&
        _.uniq(paymentMethods.map(pm => pm.cardTypeCode))
            .filter(pm => pm !== 'BA')  // Exclude bank account
            .map((pm, idx) => <CardLogo cardTypeCode={pm} key={idx} />)
    ), [paymentMethods]);

    const isKlarnaEnabled = _.some(paymentMethods, {cardTypeCode: cardNetwork.klarna.serverCode});

    useEffect(() => {
        if(!paymentMethodsState.hasLoaded) {
            actions.getPaymentMethods(merchantNumber);
        }
    }, []);

    const [ wallets, walletsStatus ] = useApiCallDeprecated(() => {
        if (merchantNumber) {
            return merchantApi.getEnabledWallets(merchantNumber);
        }
    }, [merchantNumber]);

    const enabledWalletsLogos = useMemo(() => {
        const enabledWallets = wallets?.filter((w) => w.enabled === true) // hide disabled wallets
        if (!enabledWallets?.length) {
            return <span>No available wallets</span>;
        }
        return enabledWallets.map((w) => <WalletLogo wallet={{key: w.walletType}} className={"biller-wallet-logo"} />);
    }, [wallets, walletsStatus]);
    // ====== Render ======

    if (!paymentMethodsState.hasLoaded)
        return <LoadingIndicator />;

    else if (!paymentMethods)
        return null;

    return (
        <div className='accepted-cards-containers'>
            <PaddedContainer lessMargin noDivider
                className='accepted-cards'
                icon={<Icon card />}
                title='Available payment methods'
                titleSuffix={<TooltipTrigger tipId='tip-cards'>What's this?</TooltipTrigger>}
            >
                <Row divided>
                    <div className='col-lg-4'>
                        <h3>Available cards</h3>
                        {supportedCardLogos}
                    </div>
                    <div className='col-lg-4'>
                        <Divider className='d-lg-none' />
                        <h3>Available wallets</h3>
                        {walletsStatus === APICallRequestState.SUCCESSFUL ? enabledWalletsLogos : <LoadingIndicator />}
                    </div>
                </Row>
            </PaddedContainer>

            {showLCRConfiguration && <LCRConfigurationSection allowAmountThreshold={SecurityUtil.hasAccess([userRoles.LCRAllowAmountThresholdConfiguration], authenticatedUser)} />}

            {isKlarnaEnabled && <KlarnaSettingsSection />}

            <Tooltip id='tip-cards'>
                Setup the card types you want to accept through the different channels.
                For each card type, you can also set a surcharge which allows you to recover the cost of your card payments.
            </Tooltip>
        </div>
    );
};

function mapStateToProps(state, ownProps) {
    return {
        // old way
        merchantNumber: state.accounts.users.merchant.merchantNumber,
        authenticatedUser: state.accounts.users.authenticatedUser,

        // new way
        paymentMethodsState: state.settings.paymentSettings.merchantPaymentMethods,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(merchantActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AcceptedCardsSections);