import React, { ReactNode } from 'react';
import './CloseButton.scss';

type Props = {
    onClick: () => void,
    children?: ReactNode,
}

const CloseButton = ({
    onClick, children
}: Props) => {
    return (
        <button
            type='button'
            className='close'
            onClick={onClick}
            aria-label='Close'
        >
            {children || <>&times;</>}
        </button>
    );
};

export default CloseButton;
