import React from "react";
import PropTypes from "prop-types";

import { Button } from "@premier/ui";
import { DropdownField, RadioField, FormDialog, SubmitButton } from "@premier/form";

const DownloadBatchDialog = ({
    show, isDownloading, //logic renders
    onConfirm, onCancel //functions
}) => {

    return (
        <FormDialog
            show={show}
            className="download-batch-dialog inline-label"
            title="Download report"
            onSubmit={onConfirm}
            initialValues={{
                downloadFormat: "",
                returnAll: 1,
                zipContents: 0
            }}
            renderButtons={() => (<>
                <SubmitButton primary disabled={isDownloading}>Download</SubmitButton>
                <Button onClick={onCancel} disabled={isDownloading}>Cancel</Button>
            </>)}
        >

            <DropdownField name="downloadFormat" label={<b>Report format</b>} labelText="Report format" options={[
                { value: "", label: "As uploaded" },
                { value: "BPOINT", label: "eVolve - BPOINT" },
                { value: "VIRTUALPOS", label: "VirtualPos" },
                { value: "VIRTUALPOSPLUS", label: "VirtualPos Secure" },
            ]} />

            <RadioField inline name="returnAll" label={<b>Results</b>} labelText="Results" options={[
                { value: 1, label: "All" },
                { value: 0, label: "Declined only" },
            ]} />

            <RadioField inline name="zipContents" label={<b>Zip report</b>} labelText="Zip report" options={[
                { value: 0, label: "No" },
                { value: 1, label: "Yes" },
            ]} />

        </FormDialog>
    );
};

DownloadBatchDialog.propTypes = {
    show: PropTypes.bool.isRequired,
    isDownloading: PropTypes.bool,

    /** When the user clicks Download */
    onConfirm: PropTypes.func.isRequired,
    /** When the user cancels */
    onCancel: PropTypes.func.isRequired,
};

export default DownloadBatchDialog;