import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Navigate } from 'react-router-dom';
import _ from 'lodash';

import dateUtil from '@premier/utils/date';
import stationeryShopUtil from '@premier/utils/stationeryShop';

import { DescriptionList, Button, LoadingIndicator, Divider, PageSection, Dialog, Icon, CardContainer } from '@premier/ui';
import { CustomerAddress, ShoppingCartTable } from 'components/StationeryShop';
import { PageHeader } from 'components/Common';

import { PlatformRoutesConfiguration } from 'components/Routing';
import * as stationeryActions from 'components/StationeryShop/_actions/stationeryShopActions';

const OrderDetails = ({
    orderDetail, orderId, cart, //State values
    isLoading, //logic render
    actions //API actions
}) => {
    const [showClearCartMessage, setShowClearCartMessage] = useState(false);
    const [redirectToCart, setRedirectToCart] = useState(false);

    useEffect(() => {
        actions.loadOrderDetails(orderId);
    }, []);

    function closeClearCartMessage() {
        setShowClearCartMessage(false)
    }

    function updateShoppingCart(clearCart) {
        if (clearCart)
            actions.clearCart().then(() => { addItemsToCart(); });
        else
            addItemsToCart();
    }

    function addItemsToCart() {
        var orderItems = orderDetail.items.map(x => ({
            productId: x.productId,
            qty: x.quantity,
        }));
        var items = stationeryShopUtil.excludeFreight(orderItems);
        actions.addItemsToCart(items);
        setRedirectToCart(true);
    }

    function handleReorderClick() {
        if (!stationeryShopUtil.cartIsEmpty(cart))
            setShowClearCartMessage(true);
        else
            addItemsToCart();
    }

    if (redirectToCart)
        return <Navigate to={PlatformRoutesConfiguration.stationeryRoute.shoppingCart.generatePath()} />;

    const freightItem = orderDetail?.items?.find(item => item.isFreightFee);
    const freightProduct = { ...freightItem, itemCost: freightItem?.amountPaid || freightItem?.amountBilled };
    const cartItems = orderDetail?.items?.map(item => ({
        imagePath: item.productImage,
        itemName: item.productName,
        productId: item.productId,
        productCode: item.productCode,
        quantity: item.quantity,
        unitCost: item.unitCost,
        amountPaid: item.amountPaid || item.amountBilled
    }));

    return (
        <div className="order-details-page">
            <PageSection>
                <PageHeader
                    backButton={{
                        to: PlatformRoutesConfiguration.stationeryRoute.orders.generatePath(),
                        label: 'Back to my orders'
                    }}
                    title={`Order number: ${_.get(orderDetail, 'orderReference', '')}`}
                />

                {isLoading && (
                    <CardContainer header="Loading order">
                        <LoadingIndicator />
                    </CardContainer>
                )}

                {!isLoading && orderDetail && (
                    <>
                        <DescriptionList items={[
                            {
                                name: 'Created',
                                value: dateUtil.formatToDateTimeString(
                                    orderDetail.created
                                ),
                            },
                            {
                                name: 'Status',
                                value: orderDetail.status.description,
                            },
                            {
                                name: 'Status updated',
                                value: dateUtil.formatToDateTimeString(
                                    orderDetail.updated
                                ),
                            },
                        ]} />
                        <Divider />

                        <h2>Ordered items</h2>
                        <ShoppingCartTable readOnly cartItems={cartItems} freightProduct={freightProduct} />

                        <h2>Address</h2>
                        <DescriptionList striped
                            items={[
                                {
                                    name: 'Delivery address',
                                    value: (<>
                                        {orderDetail.firstName} {orderDetail.lastName}<br/>
                                        {orderDetail.phoneNumber}<br/>
                                        <CustomerAddress address={orderDetail} />
                                    </>)
                                },
                            ]}
                        />
                        <Button
                            type="submit"
                            primary
                            label="Reorder items"
                            onClick={handleReorderClick}
                        />
                    </>
                )}



                <Dialog
                    title="Your shopping cart has some items!"
                    closeButton
                    onClose={closeClearCartMessage}
                    icon={<Icon info />}
                    show={showClearCartMessage}
                    footerButtons={<>
                        <Button onClick={() => { updateShoppingCart(true); setShowClearCartMessage(false); }}>
                            Clear shopping cart
                        </Button>
                        <Button onClick={() => { updateShoppingCart(false); setShowClearCartMessage(false); }}>
                            Add to existing items
                        </Button>
                    </>}
                />
            </PageSection>
        </div>
    );
};

function mapStateToProps(state, ownProps) {
    return {
        orderDetail: state.stationeryShop.shop.orderDetail,
        orderId: +ownProps.match.params.id,
        isLoading: state.stationeryShop.shop.isLoading,
        cart: state.stationeryShop.shop.cart,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(stationeryActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetails);
