import React, { ComponentType } from 'react';
import classNames from 'classnames';
import './withSelectorGroup.scss';

/** For checkbox/radio group */
const withSelectorGroup = <P extends object>(WrappedComponent: ComponentType<P>, role: string) => {
    type SelectorGroupComponentProps = {
        className?: string,

        /** In-line layout instead of having checkboxes/radios stacked on top of each other */
        inline?: boolean,
        /** In-line on SM+, stacked on XS */
        inlineUpSm?: boolean,
        /** In-line on LG+ only, stacked on MD- */
        inlineUpLg?: boolean,

        /** Reduce the horizontal distance between inline checkboxes/radios */
        compact?: boolean,

        /** 3 columns on LG, fewer on smaller screens */
        inlineLg3?: boolean,
        /** 5 columns on XL, fewer on smaller screens */
        inlineXl5?: boolean,

        /** The label ID for the radio group (alternative to ariaLabel) */
        ariaLabelledBy?: string,
        /** The group label (alternative to ariaLabelledBy) */
        ariaLabel?: string,
    };

    const selectorGroupComponent = ({
        className, inline, inlineUpSm, inlineUpLg, compact, inlineLg3, inlineXl5,
        ariaLabelledBy, ariaLabel,  // Accessibility
        ...otherProps
    }: SelectorGroupComponentProps) => {

        return (
            <div
                className={classNames('selector-group', className, {
                    'inline': inline,
                    'inline-up-sm': inlineUpSm,
                    'inline-up-lg': inlineUpLg,
                    'compact': compact,
                    'inline-lg-3': inlineLg3,
                    'inline-xl-5': inlineXl5,
                })}
                role={role}
                aria-labelledby={ariaLabelledBy}
                aria-label={ariaLabel}
            >
                <WrappedComponent {...otherProps as P} />
            </div>
        );
    }

    return selectorGroupComponent;
}

export default withSelectorGroup;