import React, { useContext, useEffect } from 'react';
import _ from 'lodash';
import { FormContext } from '@premier/form';

// Treat null, undefined and '' as default values that don't make a form dirty
const isDirty = (value: any) => value !== null && value !== undefined && value !== '';

interface Props {
    initialValues: any;
    setDirty: (value: boolean) => void;
    subFormNames?: string[];
    customIsDirty?: (value: any) => boolean; // Returns true if the value is a 'default' value
}

const IsFormDirty: React.FC<Props> = ({ initialValues, setDirty, subFormNames, customIsDirty }) => {
    const { values } = useContext(FormContext);
    useEffect(() => {
        const subFormNamesSet = new Set(subFormNames);
        for (let prop in values) {
            // Skip subforms as they need their own check
            if (subFormNamesSet.has(prop)) continue;

            if (isDirty(initialValues[prop])) {
                // If this property was in the initial values, is the correponding value in the form different?
                if (!_.isEqual(initialValues[prop], values[prop])) {
                    setDirty(true);
                    return;
                }
            } else {
                // If this property was not in the initial values, is it a 'dirty' value?
                if (isDirty(values[prop]) && (!customIsDirty || customIsDirty(values[prop]))) {
                    setDirty(true);
                    return;
                }
            }
        }
        // Form is (no longer) dirty
        setDirty(false);
    }, [customIsDirty, initialValues, setDirty, subFormNames, values]);

    return <></>;
};

export default IsFormDirty;
