import { combineReducers } from 'redux';
import reduceReducers from 'reduce-reducers';
import asyncReducer from 'src/store/asyncReducer';
import customerReducer from './customerReducer';
import tokenReducer from './tokenReducer';
import { requestActionReducer } from 'components/Common/requestActions';
import * as actions from '../_actions/actionTypes';

const dataVaultReducers = combineReducers({
    customer: reduceReducers(customerReducer, asyncReducer),
    token: reduceReducers(tokenReducer, asyncReducer),
    invoice: combineReducers({
        invoiceBillers: requestActionReducer(actions.GET_INVOICE_BILLERS),
        invoices: requestActionReducer(actions.SEARCH_INVOICES),
        invoiceStatusSource: requestActionReducer(actions.GET_INVOICE_STATUS_SOURCE)
    }),
    customerSubscriptions: requestActionReducer(actions.GET_CUSTOMER_SUBSCRIPTIONS),
    uploadTokensBatch: requestActionReducer(actions.UPLOAD_TOKENS_BATCH),
});

export default dataVaultReducers;