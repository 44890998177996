import { ReactNode, useContext } from "react";
import FormContext from "../FormContext";
import { Button } from "@premier/ui";

type Props = {
    loading?: boolean;
    disabled?: boolean;

    /** Use the default button style instead of primary. */
    defaultStyle?: boolean;

    /** When clicked and submitting, use this handler instead of Form.onSubmit, eg. (values, context) => console.log('Submit clicked') */
    onSubmit?: () => void;

    /**
    * @deprecated Not really deprecated, but just to prevent accidents
    */
    onClick?: (e: any) => void;

    primary?: boolean;

    children: ReactNode;

    tabIndex?: number;
};

const SubmitButton = (props: Props) => {
    const { loading, disabled, children, defaultStyle, onSubmit, ...otherProps } = props;
    const formContext = useContext(FormContext);

    function handleButtonClicked(e: any) {
        formContext.handleSubmit(e, onSubmit);
    }

    return (
        <Button primary={!defaultStyle} type="submit" onClick={handleButtonClicked} loading={loading} disabled={disabled} {...otherProps}>
            {children}
        </Button>
    );
};

export default SubmitButton;
