import React from 'react';
import BaseTransactionDetailCategory from './BaseTransactionDetailCategory';
import textUtil from '@premier/utils/text';
import PropTypes from 'prop-types';

const FraudScreeningResultCategory = ({ transaction }) => {

    var fraudResult = [];
    if (!transaction.isFraudScreened) {
        fraudResult.push({ name: 'Fraud screened', value: 'No' });
    }
    else {
        if (transaction.fraudDetectionResponseModel && transaction.fraudDetectionResponseModel.redResponse) {
            fraudResult.push({ name: 'Fraud screening status', value: textUtil.toInitialCapital(transaction.fraudDetectionResponseModel.redResponse.statusCode) });
            fraudResult.push({ name: 'Request ID', value: transaction.fraudDetectionResponseModel.redResponse.requestId });
            fraudResult.push({ name: 'Order ID', value: transaction.fraudDetectionResponseModel.redResponse.orderId });
            fraudResult.push({ name: 'Record ID', value: transaction.fraudDetectionResponseModel.redResponse.recordId });
            fraudResult.push({ name: 'Response code', value: transaction.fraudDetectionResponseModel.redResponse.responseCode });
            fraudResult.push({ name: 'Fraud neural', value: transaction.fraudDetectionResponseModel.redResponse.fraudNeural });
        };
    };

    return (
        <BaseTransactionDetailCategory title='Fraud screening result' items={fraudResult} />
    );
}

FraudScreeningResultCategory.propTypes = {
    transaction: PropTypes.object.isRequired
};

export default FraudScreeningResultCategory;
