import React, { useEffect, useState } from "react";
import _ from "lodash";
import {
    ButtonContainer,
    FormGroup,
    Icon,
    IconText,
    LoadingIndicator,
    PageSection,
    SingleColumnFormContainer,
    Textarea,
    Tooltip,
    TooltipTrigger,
} from "@premier/ui";
import { Form, SubmitButton, validate } from "@premier/form";
import { useInvoiceSettings } from "components/Settings/_hooks/useInvoicesSettings";
import useCustomer from "../../_hooks/useCustomer";
// @ts-ignore
import InvoiceBillToForm from "./InvoiceBillToForm";
import InvoiceDetailForm from "./InvoiceDetailForm";
import InvoiceItemsForm from "./InvoiceItemsForm";
import IsFormDirty from "./IsFormDirty";
import InvoiceDiscountFeeForm, { discountFeeOptions } from "./InvoiceDiscountFeeForm";
import { InvoiceModel } from "@premier/webapi-client";
import currencyUtil from "@premier/utils/currency";
import { formToInvoiceModel, formToTotals, maxCurrency, silentValidation } from "./invoiceUtil";
import { contextHasErrors } from "components/Utilities/form/formUtils";
import useInvoices from "../../_hooks/useInvoices";

import "./InvoiceForm.scss";

export const invoiceFormCopy = {
    notesForCustomerTooltip:
        "The following notes will be displayed on the invoice sent. This will be prefilled with the default customer notes if it has been configured.",
    totalBelowZeroMessage: "Total invoice amount must be greater than zero.",
    send: "Send",
    submitError: "There are errors above. Please fix them before continuing.",
    footerNote: "Please note that you can only cancel an invoice if it has not been paid for yet.",
};

export const labels = {
    notesForCustomer: "Notes for customer",
};

export const fieldNames = {
    billTo: "billTo",
    invoiceDetail: "invoiceDetail",
    invoiceItems: "invoiceItems",
    discountFee: "discountFee",
    notesForCustomer: "notesForCustomer",
    invoiceAmount: "invoiceAmount",
};

const fieldValidation = {
    notesForCustomerMaxLength: 400,
};

const nCharactersString = (n: number) => `${n} character${n === 1 ? "" : "s"}`;
export const maxLengthErrorMessage = (length: number) => `{label} exceeds ${length} characters.`;

interface Props {
    setIsInvoiceDirty?: (value: boolean) => void;
    showSuccess?: () => void;
    showError?: () => void;
}

const InvoiceForm: React.FC<Props> = ({ setIsInvoiceDirty, showSuccess, showError }) => {
    const invoiceSettings = useInvoiceSettings();
    const [formContext, setFormContext] = useState<any>();
    const { details: customerDetails } = useCustomer();
    const [invoiceTotals, setInvoiceTotals] = useState<InvoiceModel>({});
    const { submitting, submitInvoice, invoiceBillers } = useInvoices();
    const firstBiller = invoiceBillers?.[0];


    // A flag for each section of the form to determine if its values are different to its initial values
    const [isDirty, setIsDirty] = useState<boolean>(false);
    const [isBillToDirty, setIsBillToDirty] = useState<boolean>(false);
    const [isInvoiceDetailDirty, setIsInvoiceDetailDirty] = useState<boolean>(false);
    const [isInvoiceItemsDirty, setIsInvoiceItemsDirty] = useState<boolean>(false);
    const [isDiscountFeeDirty, setIsDiscountFeeDirty] = useState<boolean>(false);

    useEffect(() => {
        if (setIsInvoiceDirty) {
            // The invoice is dirty if any of the isDirty flags are true
            setIsInvoiceDirty(
                [isDirty, isBillToDirty, isInvoiceDetailDirty, isInvoiceItemsDirty, isDiscountFeeDirty].some((v) => !!v)
            );
        }
    }, [isDirty, isBillToDirty, isInvoiceDetailDirty, isInvoiceItemsDirty, isDiscountFeeDirty, setIsInvoiceDirty]);

    useEffect(() => {
        // We only want to set validation for the invoice amount when both the firstBiller and formContext are ready.
        if (firstBiller && formContext) {
            // Remove the validation so we can reset it.
            formContext.removeValidation(fieldNames.invoiceAmount);

            const maxInvoiceAmount = firstBiller ? firstBiller.paymentLimits.maximum : maxCurrency;
            const maxInvoiceAmountCents = currencyUtil.convertToApiValue(maxInvoiceAmount)!;

            // Set the validation here.
            formContext.setValidation(fieldNames.invoiceAmount, validate()
                .greaterThan(0, invoiceFormCopy.totalBelowZeroMessage)
                .lessThanOrEqual(
                    maxInvoiceAmountCents,
                    `Total invoice amount exceeds ${currencyUtil.formatWithPrefix(maxInvoiceAmount)}.`
                ));
        }
    }, [firstBiller, formContext]);

    // Display a loading spinner until the customer details and invoice settings are retrieved
    if (!customerDetails || !invoiceSettings.settings) {
        return <LoadingIndicator />;
    }

    const initialValues = {
        [fieldNames.notesForCustomer]: invoiceSettings.settings?.customerNotes,
    };

    const maxInvoiceAmount = firstBiller ? firstBiller.paymentLimits.maximum : maxCurrency;
    const maxInvoiceAmountCents = currencyUtil.convertToApiValue(maxInvoiceAmount)!;
    const validation = {
        [fieldNames.notesForCustomer]: validate().maxLength(fieldValidation.notesForCustomerMaxLength, ""),
        [fieldNames.invoiceAmount]: validate()
            .greaterThan(0, invoiceFormCopy.totalBelowZeroMessage)
            .lessThanOrEqual(
                maxInvoiceAmountCents,
                `Total invoice amount exceeds ${currencyUtil.formatWithPrefix(maxInvoiceAmount)}.`
            ),
    };

    const calculateTotals = (context: any) => {
        const { items, subtotals } = formToTotals(context.values, invoiceSettings.settings?.taxRates || []);

        if (items.length > 0) {
            invoiceSettings.calculateTotals(items, subtotals, (resp) => {
                setInvoiceTotals(resp);

                context.setValue(fieldNames.invoiceAmount, resp.amountTotal);
                silentValidation(context, fieldNames.invoiceAmount, resp.amountTotal);
            });
        } else {
            setInvoiceTotals({});
            context.setValue(fieldNames.invoiceAmount, 0);
            silentValidation(context, fieldNames.invoiceAmount, 0);
        }
    };

    const onSubmit = (values: any, ctx: any) => {
        ctx.validateField(fieldNames.invoiceAmount, values[fieldNames.invoiceAmount]);

        const model = formToInvoiceModel(
            customerDetails.customerId,
            values,
            invoiceSettings.settings?.taxRates || [],
            invoiceSettings.settings?.termOfPayments || []
        );

        submitInvoice(model, showSuccess, showError);
    };

    const render = (context: any) => {
        // We need to do this so that this component always has the most up to date formContext
        if (!formContext || !_.isEqual(context.values, formContext.values)) {
            setFormContext(context);
        }

        // Amounts for discount and fees
        const discountFeeAmounts = invoiceTotals.invoiceSubTotalItems?.map((st) => {
            const option = discountFeeOptions.find((df) => df.value === st.selectedOption?.optionId);
            const amount = st.amountTotal || 0;
            return option?.isCurrency ? currencyUtil.fromApiValue(amount) : amount / 100;
        });

        // Calculate Notes for Customer validation
        const notesForCustomerLength = context.getValue(fieldNames.notesForCustomer)?.length || 0;
        const notesForCustomerRemaining = fieldValidation.notesForCustomerMaxLength - notesForCustomerLength;

        const isFormValid = !contextHasErrors(context);
        return (
            <>
                <IsFormDirty
                    initialValues={initialValues}
                    setDirty={setIsDirty}
                    subFormNames={[
                        fieldNames.billTo,
                        fieldNames.invoiceDetail,
                        fieldNames.invoiceItems,
                        fieldNames.discountFee,
                    ]}
                />
                <PageSection className="invoice-form">
                    <h3>Bill to</h3>
                    <SingleColumnFormContainer>
                        <InvoiceBillToForm name={fieldNames.billTo} setIsDirty={setIsBillToDirty} />
                    </SingleColumnFormContainer>
                </PageSection>

                <PageSection>
                    <h3>Invoice detail</h3>
                    <SingleColumnFormContainer>
                        <InvoiceDetailForm name={fieldNames.invoiceDetail} setIsDirty={setIsInvoiceDetailDirty} />
                    </SingleColumnFormContainer>
                </PageSection>

                <PageSection>
                    <h3>Invoice items</h3>
                    <InvoiceItemsForm
                        name={fieldNames.invoiceItems}
                        maxItems={50}
                        subtotalExTax={currencyUtil.formatApiValue(invoiceTotals.productItemsSubTotalExTax || 0)}
                        updateTotals={() => calculateTotals(context)}
                        setIsDirty={setIsInvoiceItemsDirty}
                    />

                    <h3>Discount/Fee</h3>
                    <InvoiceDiscountFeeForm
                        name={fieldNames.discountFee}
                        maxItems={3}
                        calculatedAmounts={discountFeeAmounts || []}
                        subtotalExTax={currencyUtil.formatApiValue(invoiceTotals.subTotalExTax || 0)}
                        totalTax={currencyUtil.formatApiValue(invoiceTotals.taxTotal || 0)}
                        total={currencyUtil.formatApiValue(invoiceTotals.amountTotal || 0)}
                        updateTotals={() => calculateTotals(context)}
                        setIsDirty={setIsDiscountFeeDirty}
                    />
                </PageSection>

                <PageSection>
                    <SingleColumnFormContainer>
                        <FormGroup
                            fieldId={fieldNames.notesForCustomer}
                            label={labels.notesForCustomer}
                            error={
                                notesForCustomerRemaining < 0
                                    ? `${nCharactersString(-notesForCustomerRemaining)} exceeding limit`
                                    : undefined
                            }
                            help={
                                <TooltipTrigger tipId="tip-notesForCustomer">
                                    <Icon question className="invoice-form__tooltip-icon" />
                                </TooltipTrigger>
                            }
                        >
                            <Textarea
                                name={fieldNames.notesForCustomer}
                                value={context.getValue(fieldNames.notesForCustomer)}
                                onChange={(e) => context.setValue(fieldNames.notesForCustomer, e.target.value)}
                                error={notesForCustomerRemaining < 0}
                            />
                            {notesForCustomerRemaining >= 0 && (
                                <div className="invoice-form__notes-char-remaining">
                                    {nCharactersString(notesForCustomerRemaining)} remaining
                                </div>
                            )}
                        </FormGroup>
                        <Tooltip id="tip-notesForCustomer">{invoiceFormCopy.notesForCustomerTooltip}</Tooltip>
                    </SingleColumnFormContainer>
                </PageSection>

                <PageSection noDivider>
                    <ButtonContainer className="invoice-form__send-button">
                        <SubmitButton loading={submitting}>{invoiceFormCopy.send}</SubmitButton>
                    </ButtonContainer>
                    {!isFormValid && <div className="invoice-form__submit-error">{invoiceFormCopy.submitError}</div>}
                </PageSection>

                <SingleColumnFormContainer className="invoice-form__footer">
                    <IconText info>{invoiceFormCopy.footerNote}</IconText>
                </SingleColumnFormContainer>
            </>
        );
    };

    return <Form initialValues={initialValues} initialValidation={validation} {...{ render, onSubmit: onSubmit }} />;
};

export default InvoiceForm;
