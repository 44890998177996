import * as accountActions from 'components/Account/_actions/actionTypes';

export function requestActionTypes(name) {
    return {
        SUCCESS: `${name}_SUCCESS`,
        FAILED: `${name}_FAILED`,
        REQUESTED: `${name}_REQUESTED`,
        CLEAR: `${name}_CLEAR`,
    }
}

const requested = (actions, ext) => ({type: actions.REQUESTED, ...ext});
const success = (actions, data, ext) => ({type: actions.SUCCESS, data, ...ext});
const failed = (actions, errors, ext) => ({type: actions.FAILED, errors, ...ext});
const makeClear = (actions, ext) => ({type: actions.CLEAR, ...ext});

export function promiseRequestActions(actions, promiseCreator, subtypeMapper) {

    function send(...args) {
        var subtype = subtypeMapper ? subtypeMapper(...args) : undefined;
        return function(dispatch) {
            dispatch(requested(actions, {subtype}));
            return promiseCreator(...args).then(data => {
                dispatch(success(actions, data, {subtype}));
                return data;
            }).catch(errors => {
                dispatch(failed(actions, errors, {subtype}));
                throw errors;
            });
        }
    }

    function clear(...args) {
        var subtype = subtypeMapper ? subtypeMapper(...args) : undefined;
        return function(dispatch) {
            return dispatch(makeClear(actions, {subtype}));
        }
    }

    return {
        send,
        clear
    }
}

export function promiseResultRequestActions(actions, promiseCreator, extMapper) {

    function send(...args) {
        var ext = extMapper ? extMapper(...args) : undefined;
        return function(dispatch) {
            dispatch(requested(actions, ext));

            return promiseCreator(...args).then(data => {
                if (data.ok)
                    dispatch(success(actions, data.val, ext));
                else
                    dispatch(failed(actions, data.val, ext));
                return data;
            });
        }
    }

    function clear(...args) {
        var ext = extMapper ? extMapper(...args) : undefined;
        return function(dispatch) {
            return dispatch(makeClear(actions, ext));
        }
    }

    return {
        send,
        clear
    }
}

export class RequestActionState {
    constructor(state, data) {
        if (state != null)
            this.state = state;

        if (data != null) {
            if (this.state === 'SUCCESS' || this.state === 'LOADING') {
                this.data = data;
            } else if (this.state === 'FAILED') {
                this.errors = data;
            }
        }
    }

    get isInitial() {
        return this.state !== 'LOADING' && this.state !== 'SUCCESS' && this.state !== 'FAILED';
    }

    get hasLoaded() {
        return this.state === 'SUCCESS' || this.state === 'FAILED';
    }

    get isLoading() {
        return this.state === 'LOADING';
    }

    get hasFailed() {
        return this.state === 'FAILED';
    }

    get hasSucceeded() {
        return this.state === 'SUCCESS';
    }
}

export const emptyRequestAction = new RequestActionState();

export function mappedReducer(map, initial = {}) {
    return (state = initial, action) => {
        if (map[action.type])
            return map[action.type](state, action);
        return state;
    }
}

export function filterReducer(filter, reducer) {
    return (state, action) => {
        if (filter instanceof Function && !filter(action))
            return state;
        return reducer(state, action);
    }
}

export function userMaps(rootRoute) {
    return {
        [accountActions.LOGOUT_USER_SUCCESS]: () => emptyRequestAction,
        /* TODO: Not sure if this has any significant impact, fix this if required, used to run whenever
         the path was changed but due to react-router-dom upgrade, there appears to be no bug coming from this
        [commonActions.PATH_CHANGE]: (state, action) => {
            if (!rootRoute)
                return state;
            if(action.payload
                && !matchPath(action.payload.location.pathname, { path: rootRoute })
            )
                return emptyRequestAction;
            return state;
        }
        */
    };
}

export function requestMaps(actions, filter) {
    return {
        [actions.CLEAR]: filterReducer(filter, () => emptyRequestAction),
        [actions.REQUESTED]: filterReducer(filter, (state) => new RequestActionState('LOADING', state.data)),
        [actions.SUCCESS]: filterReducer(filter, (_, action) => new RequestActionState('SUCCESS', action.data)),
        [actions.FAILED]: filterReducer(filter, (_, action) => new RequestActionState('FAILED', action.errors)),
    };
}

export function requestActionReducer(actions, rootRoute, filter, maps) {
    return mappedReducer({
        ...requestMaps(actions, filter),
        ...userMaps(rootRoute),
        ...maps
    }, emptyRequestAction);
}
