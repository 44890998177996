import React from 'react';
import { Breakpoint } from 'react-socks';
import classNames from 'classnames';
import ScrollingTableNarrow, { ScrollingTableNarrowProps } from './ScrollingTableNarrow';
import ScrollingTableWide, { ScrollingTableWideProps } from './ScrollingTableWide';
import { ScrollingTableRecordPartial } from './ScrollingTableTypes';
import './ScrollingTable.scss';


type ScrollingTableProps<TRecord extends ScrollingTableRecordPartial> =
    Omit<ScrollingTableNarrowProps<TRecord> & ScrollingTableWideProps<TRecord>, 'data'> & {
        data?: TRecord[],
        /** A string array with 2 elements: The maximum breakpoint for the narrow view, and the minimum breakpoint for wide view (Default = ['xs','sm']) */
        breakpoints?: ('xs' | 'sm' | 'md' | 'lg' | 'xl')[],
        className?: string,
    };


/**
 * A responsive table that can hide some columns when the screen is too narrow.
 * Does NOT support quick-filter / batch-actions yet.
 */
const ScrollingTable = <TRecord extends ScrollingTableRecordPartial>({
    breakpoints, className,  // own props, not passed down
    minWidth,  // wide only
    data = [], noDataText = 'No data to show.', ...otherProps
}: ScrollingTableProps<TRecord>) => {

    breakpoints = breakpoints || ['xs','sm'];
    var narrowProps = breakpoints[0] === 'xs'
        ? { [breakpoints[0]]: true, 'only': true }
        : { [breakpoints[0]]: true, 'down': true };
    var wideProps = breakpoints[0] === 'xl'
        ? { [breakpoints[1]]: true, 'only': true }
        : { [breakpoints[1]]: true, 'up': true };


    return (
        <div className={classNames('scrolling-table', className)}>
            <Breakpoint {...narrowProps}>
                <ScrollingTableNarrow
                    data={data}
                    noDataText={noDataText}
                    {...otherProps}
                />
            </Breakpoint>

            <Breakpoint {...wideProps}>
                <ScrollingTableWide
                    data={data}
                    noDataText={noDataText}
                    minWidth={minWidth}
                    {...otherProps}
                />
            </Breakpoint>
        </div>
    );
};

export default ScrollingTable;