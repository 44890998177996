import React from 'react';
import { PaymentPlanDetailsModel, PaymentPlanOptionModelIntervalEnum } from '@premier/webapi-client'
import { PaddedContainer, DescriptionList } from '@premier/ui';
// @ts-ignore
import labels from 'constants/labels';
import currencyUtil from '@premier/utils/currency';

interface PaymentPlanSummaryTableProps {
    paymentPlanDetails : PaymentPlanDetailsModel
    crn1? : string
    crn2? : string
    crn3? : string
}

const PaymentPlanSummaryTable : React.FC<PaymentPlanSummaryTableProps> = ({ paymentPlanDetails, crn1, crn2, crn3 }) => {
    const frequencyGetter = (frequency? : number, interval? : PaymentPlanOptionModelIntervalEnum) => {
        if (!frequency) {
            return "Once";
        } else {
            return `Recur every ${frequency} ${interval?.toLowerCase() ?? "period" }${frequency > 1 ? 's' : ''}`
        }
    }

    return (
        <PaddedContainer
            className="payment-plan-summary"
            title='Payment plan summary'
        >
        { paymentPlanDetails && <DescriptionList items={[
            {name: labels.paymentPlanOption, value: paymentPlanDetails.paymentPlanCommitment?.paymentPlanOptionName},
            {name: labels.frequency, value: frequencyGetter(paymentPlanDetails.paymentPlanCommitment?.frequency, paymentPlanDetails.paymentPlanCommitment?.interval)},
            {name: labels.totalAmountToPay, value: paymentPlanDetails.totalAmountToPay ? currencyUtil.convertToDisplayString(paymentPlanDetails.totalAmountToPay) : null },
            {name: labels.reference1, value: crn1},
            {name: labels.reference2, value: crn2},
            {name: labels.reference3, value: crn3}
        ]} /> }
        </PaddedContainer>
    );
}

export default PaymentPlanSummaryTable;