import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';


import { Button } from '@premier/ui';
import { InputField, DropdownField, FormDialog, validate, SubmitButton} from '@premier/form';
import { FormError, withError } from 'components/Common';

import './BatchRenameModal.scss';

const fileTypeOptions = [
    {
        value: 'csv',
        label: 'CSV'
    },
    {
        value: 'txt',
        label: 'TXT'
    },
    {
        value: 'dat',
        label: 'DAT'
    }
];


const BatchRenameModal = ({
    show, //logic renders
    onClose, onRename, //functions
    fullFileName, //data
    errors //state
}) => {
    const [currentFileName, setCurrentFileName] = useState('');
    const [currentExtension, setCurrentExtension] = useState('');

    useEffect(() => {
        if (fullFileName) {
            var parts = fullFileName.split('.');
            setCurrentFileName(parts[0]);
            setCurrentExtension(parts[1]);
        }
    }, [show, fullFileName]);

    function handleSave (values) {
        if (concatNameAndExt(values) === fullFileName) {  // Not renamed
            onClose();
        } else {  // Renamed
            setCurrentExtension(values.fileExtension);
            onRename(concatNameAndExt(values));
        }
    }

    function concatNameAndExt (formValues) {
        return `${formValues.fileName}.${formValues.fileExtension}`;
    }

    return (<div className='batch-rename-modal'>
        <FormDialog
            title='Rename batch file'
            closeButton
            show={show}
            onClose={onClose}
            onSubmit={handleSave}
            initialValues={{
                fileName: currentFileName,
                fileExtension: currentExtension
            }}
            initialValidation={{
                fileName: validate().required()
            }}
            renderButtons={context => (<>
                <SubmitButton>Save</SubmitButton>
                <Button onClick={onClose}>Cancel</Button>
            </>)}
        >
            <div className='rename-batch-form'>
                <InputField
                    name='fileName'
                    className='file-name-field'
                    labelText='File name'
                />
                .
                <DropdownField
                    name='fileExtension'
                    className='file-type-field'
                    options={fileTypeOptions}
                />
            </div>

            <FormError errors={errors} />
        </FormDialog>
    </div>);
}

BatchRenameModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onRename: PropTypes.func.isRequired,
    fullFilename: PropTypes.func,
    errors: PropTypes.array,
};

function mapStoreToError(state) {
    return state.transactions.batch.errors;
}

export default withError(
    BatchRenameModal,
    mapStoreToError,
    null
);
