import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import PropTypes from 'prop-types';

import {Modal, LoadingIndicator} from '@premier/ui';

import * as paymentActions from 'components/Transactions/_actions/paymentActions';

const ProcessingPaymentStep = ({
    actions, //actions
    onSuccess, onError, mapErrorsFromDto, //functions
    payment, //data
}) => {
    
    useEffect(() => {
        actions.createNewPayment(payment, mapErrorsFromDto).then(receipt => {
            onSuccess(receipt);
        }).catch(error => {
            onError(error);
        });
    }, []);

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>
                    <LoadingIndicator></LoadingIndicator>
                    <h1>Processing</h1>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            </Modal.Body>
        </>
    );
}

ProcessingPaymentStep.propTypes = {
    payment: PropTypes.object.isRequired,
    onSuccess: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    mapErrorsFromDto: PropTypes.func,
};

export default connect((s,o) => {return {}}, (dispatch) =>{return {actions: bindActionCreators(paymentActions, dispatch)}})(ProcessingPaymentStep);