import PropTypes from 'prop-types';
import { CheckboxField, ColourField, FormGroup } from '@premier/form';
import { Row } from '@premier/ui';

const EditBrandingMenuSection = ({ style }) => {
    return (<>
        <FormGroup name='pages' label='Select pages to enable'>
            <CheckboxField name='menu.showTerms'>Terms</CheckboxField>
            <CheckboxField name='menu.showPrivacy'>Privacy</CheckboxField>
            <CheckboxField name='menu.showExtraInfo'>Extra Info</CheckboxField>
        </FormGroup>
        { (style?.menu?.showTerms || style?.menu?.showPrivacy || style?.menu?.showExtraInfo) &&
            <>
                <Row>
                    <div className='col-sm-6'>
                        <ColourField name='menu.fontColour' label='Font colour' />
                    </div>
                    <div className='col-sm-6'>
                        <ColourField name='menu.backColour' label='Background colour' />
                    </div>
                </Row>
                <Row>
                    <div className='col-sm-6'>
                        <ColourField name='menu.underlineColour' label='Selected item underline colour' />
                    </div>
                    <div className='col-sm-6'>
                        <ColourField name='menu.mobileColour' label='Mobile menu colour' />
                    </div>
                </Row>
                <FormGroup name='borders' label='Select borders to enable'>
                    <Row>
                        <div className='col-sm-6'>
                            <CheckboxField name='menu.showTopBorder'>Top</CheckboxField>
                        </div>
                        <div className='col-sm-6'>
                            <CheckboxField name='menu.showBottomBorder'>Bottom</CheckboxField>
                        </div>
                    </Row>
                </FormGroup>
                <Row>
                    <div className='col-sm-6'>
                        <ColourField name='menu.borderColour' label='Border colour' />
                    </div>
                </Row>
            </>
        }</>);
};

EditBrandingMenuSection.propTypes = {
    style: PropTypes.any
};

export default EditBrandingMenuSection;
