import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';

import { CheckboxGroup, PageSection, Link, Accordion, IconText, Divider } from '@premier/ui';
import { PageHeader } from 'components/Common';
import { StationeryItemList } from 'components/StationeryShop';

import * as StationeryShopActions from 'components/StationeryShop/_actions/stationeryShopActions';
import { PlatformRoutesConfiguration } from 'components/Routing';

import './HomePage.scss'

import DeliveryImpactNotice from '../components/DeliveryImpactNotice';


const HomePage = ({
    stationeryCategoryIds, //data
    stationeryCategories, stationeryShopProducts, cart, //state values
    actions //API actions
}) => {
    const [filteredProducts, setFilteredProducts] = useState(_.get(stationeryShopProducts,'products', []))
    const [selectedCategories, setSelectedCategories] = useState((stationeryCategories && stationeryCategories.map((c) => c.categoryId)) ||[])
    const [noSelectedCategories, setNoSelectedCategories] = useState(false);

    useEffect(() => {
        var apiCalls = [
            actions.getStationeryCategories(),
        ];

        Promise.all(apiCalls).then(() => {
            actions.getStationeryProducts(0);
        });
    }, [actions]);

    useEffect(() => {
        var items = _.get(stationeryShopProducts, 'products');
        var filterItems = items?.filter(item => item.isAvailable);

        if (filterItems && selectedCategories && selectedCategories.length !== 0) {
            filterItems = filterItems.filter(item => item.categories && item.categories.some(r => selectedCategories.includes(r)));
        };

        setFilteredProducts(filterItems);
    }, [stationeryShopProducts, selectedCategories]);


    useEffect(() => {
        if (stationeryCategories && !selectedCategories.length ){
            setSelectedCategories(stationeryCategories.map((c) => c.categoryId));
        }
    },[stationeryCategories]);

    function handleCategoryChange(selectedValues) {
        setNoSelectedCategories(selectedValues.length === 0);
        setSelectedCategories(selectedValues);
    }

    function getShoppingCartCount() {
        if (cart && cart.length && (cart.length !== 0)) {
            return cart.length - 1;
        }
        else {
            return 0;
        }
    }

    return (
        <div className="stationery-home-page">
            <PageSection>
                <DeliveryImpactNotice />

                <div className="stationery-header">
                    <Link primary to={PlatformRoutesConfiguration.stationeryRoute.shoppingCart.generatePath()}>Shopping cart: {getShoppingCartCount()}</Link>

                    <PageHeader title="EFTPOS stationery shop" />
                    Order your EFTPOS rolls and stationery supplies.
                </div>
                <Divider />
                <Accordion title={<b>Show me:</b>} enableDownXs>
                    <div className='categories-checkboxes'>
                        {(noSelectedCategories || selectedCategories.length) && //This is used to ensure that the checkboxes load with the default all check option.
                            <CheckboxGroup
                                name='Category Checkboxes'
                                options={stationeryCategories.map(c => ({value: c.categoryId, label: c.categoryTitle}))}
                                defaultValues={selectedCategories}
                                hasAllOption
                                inlineUpSm
                                onChange={handleCategoryChange}
                            />
                        }
                    </div>
                </Accordion>
                <Divider />
                <IconText info>All prices include GST</IconText>
                {!stationeryCategoryIds ? <StationeryItemList items={filteredProducts} /> : ''}
            </PageSection>
        </div>
    );
}

function mapStateToProps(state, ownProps) {
    return {
        stationeryCategories: state.stationeryShop.shop.stationeryCategories,
        stationeryShopProducts: state.stationeryShop.shop.stationeryProducts,
        cart: state.stationeryShop.shop.cart
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(StationeryShopActions, dispatch),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(HomePage);