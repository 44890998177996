import * as actions from './actionTypes';
import { invoiceApi } from 'api';
import { ApiResult } from 'api/mapErrors';

import { promiseResultRequestActions } from 'components/Common/requestActions';
import { InvoiceModel } from '@premier/webapi-client';

export let createInvoice = (promiseResultRequestActions(actions.CREATE_INVOICE, (model: InvoiceModel) =>
    invoiceApi.createInvoice(model)
).send as unknown) as (dispatch: any) => Promise<ApiResult<void>>;

export let getInvoiceBillers = promiseResultRequestActions(actions.GET_INVOICE_BILLERS, () =>
    invoiceApi.getInvoiceBillers()
).send;
