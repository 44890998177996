import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { Modal as BootstrapModal } from 'react-bootstrap';

import { Divider, ButtonContainer } from '..';

export type ModalFooterProps = {
    /** Wrap the footer content with ButtonContainer */
    buttons?: boolean,
    /** Add a divider on the top (between the body and the footer) */
    divider?: boolean,
    className?: string,
    children: ReactNode,
};

/** Not to be used directly. Please use Modal instead. */
const ModalFooter = ({
    className, divider,
    buttons, children
}: ModalFooterProps) => {
    return (
        <BootstrapModal.Footer className={className}>
            <Divider className={classNames({ 'none': !divider })} />

            {buttons
                ? (
                    <ButtonContainer noTopMargin >
                        {children}
                    </ButtonContainer>
                ) : (
                    children
                )
            }
        </BootstrapModal.Footer>
    );
};

export default ModalFooter;