import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';

import currencyUtil from '@premier/utils/currency';
import { Icon, Button } from '@premier/ui';
import { DropdownField, InputField, CardExpiryField, CurrencyField, FormDialog, validate, SubmitButton } from '@premier/form';
import { FormError, withError } from 'components/Common';
import labels from 'constants/labels';

import * as batchActions from 'components/Batch/_actions/batchActions';

const validOrderTypes = ['MAIL_FAX', 'TELEPHONE', 'RECURRING', 'ECOMMERCE'];
const IgnoreExpiry = "00"; // 00 allows for expiry to be ignored in batch processing

const EditBatchRecordDialog = ({
    actions, errors, //state
    show, //logic renders
    record, orderTypes, //data
    onSave, onCancel //functions
}) => {

    const [confirmingDelete, setConfirmingDelete] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    setExpiryToDefault(record);

    function getOrderTypeOptions() {
        return [].concat(
            _.filter(_.get(orderTypes, 'items'), x => validOrderTypes.includes(x.key)).map(x => ({
                value: x.description,
                label: x.description,
            }))
        );
    }

    // If expiryDate is set to 0 (a number) then we wish to set the default value to 00 for month and year as a string so that we can process
    // batch without expiry.  This is necessary as we are expecting a string of "00" instead of a number.
    function setExpiryToDefault(record) {
        if (record?.expiryDate?.month === 0) {
            record.expiryDate.month = "00";
        }
        if (record?.expiryDate?.year === 0) {
            record.expiryDate.year = "00";
        }
    }

    function handleSave(values) {
        var isDirty =
            values.crn1 !== record.crn1 ||
            values.cardNumber !== record.cardNumber ||
            values.expiryDate.month !== record.expiryDate.month ||
            values.expiryDate.year !== record.expiryDate.year ||
            values.amount !== record.amount ||
            values.orderTypeKey !== record.orderTypeKey;  // always true because card number has to be reentered

        if (isDirty) {
            const updatedRecord = {
                fileId: record.fileId,
                recordId: record.recordId,
                crn1: values.crn1,
                cardNumber: values.cardNumber,
                expiryDate: values.expiryDate,
                amount: currencyUtil.convertToApiValue(values.amount),
                orderTypeDescription: values.orderTypeKey,
            };

            setIsSaving(true);

            actions
                .editBatchLibraryFileRecord(updatedRecord)
                .then(() => {
                    setIsSaving(false);
                    onSave(true);
                })
                .catch(err => {
                    setIsSaving(false);
                });
        }
    }

    function handleDelete() {
        setIsSaving(true);

        actions.deleteBatchLibraryFileRecord(record.fileId, record.recordId)
            .then(() => {
                setIsSaving(false);
                onSave(true);
            })
            .catch(err => {
                setIsSaving(false);
            });
        setConfirmingDelete(false);
    }

    return (
        <>
            <FormDialog
                name='editRecordForm'
                show={show && !confirmingDelete}
                title='Edit record'
                initialValues={{...record, amount: currencyUtil.fromApiValue(record.amount)}}  // Ideally convert it to dollar (using fromApiValue) when getting it from API in every API call in transactionsApi?
                initialValidation={{
                    crn1: validate().required()
                        .matches(/^[^&?,\n\t\r]{1,50}$/, 'Invalid reference'),
                    cardNumber: validate().required().if(
                        (value) => (value !== record.cardNumber),
                        validate().required().matches(/^\d{13,16}$/, 'Invalid card number')
                    ),
                    amount: validate().required()
                        .when(val => currencyUtil.parseAmount(val) > 0, 'Invalid amount'),
                    orderTypeKey: validate().required()
                }}
                onSubmit={handleSave}
                renderButtons={context => (<>
                    <SubmitButton disabled={isSaving}>Save</SubmitButton>
                    <Button onClick={onCancel} disabled={isSaving}>Cancel</Button>
                    <Button subtle onClick={() => setConfirmingDelete(true)} disabled={isSaving}>
                        Delete this record
                    </Button>
                </>)}
            >
                <InputField name='crn1' label={labels.reference1} />
                <InputField name='cardNumber' label={labels.cardNumber} />
                <CardExpiryField name='expiryDate' label={labels.expiry} required defaultValue={IgnoreExpiry} />
                <CurrencyField name='amount' label={labels.amount} />
                <DropdownField name='orderTypeKey' label={labels.orderType} options={getOrderTypeOptions()} />

                <FormError errors={errors} />
            </FormDialog>

            <FormDialog name='deleteRecordConfirmationForm'
                show={show && confirmingDelete}
                icon={<Icon alert />}
                title='Delete this batch record?'
                renderButtons={context => (<>
                    <Button onClick={handleDelete} disabled={isSaving}>Delete</Button>
                    <Button onClick={() => setConfirmingDelete(false)} disabled={isSaving}>Cancel</Button>
                </>)}
            >
                The record will be permanently deleted.
            </FormDialog>
        </>
    );
};

EditBatchRecordDialog.propTypes = {
    show: PropTypes.bool.isRequired,
    record: PropTypes.object.isRequired,
    orderTypes: PropTypes.object,
    /** eg. (anyChanges) => {}. When the user clicks Save, to trigger a reload if anyChanges is true, and to close the modal */
    onSave: PropTypes.func.isRequired,
    /** When the user cancels */
    onCancel: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(batchActions, dispatch),
    };
}

function mapStateToProps(state, ownProps) {
    return {};
}

function mapStoreToErrors(state) {
    return state.transactions.batch.errors;
}

export default withError(
    connect(mapStateToProps, mapDispatchToProps)(EditBatchRecordDialog),
    mapStoreToErrors
);
