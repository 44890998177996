import * as actions from './actionTypes';
import { designerApi } from 'api';

import { promiseRequestActions, promiseResultRequestActions } from 'components/Common/requestActions';
import { BrandingStyle } from 'models/BrandingStyle';


// GET Branding
export let getBranding = promiseResultRequestActions(
    actions.GET_BRANDING,
    (billerCode: string) => designerApi.getBranding(billerCode)
).send;

// Save Branding (with/without logo)
let saveBrandingActions = promiseResultRequestActions(
    actions.SAVE_BRANDING,
    (billerCode: string, style: BrandingStyle, files: FileList) => {
        if(files?.length) {
            return designerApi.saveBrandingWithLogo(billerCode, style, files);
        } else {
            return designerApi.saveBranding(billerCode, style);
        }
    }
);
export let saveBranding = saveBrandingActions.send;
export let clearSaveBranding = saveBrandingActions.clear;

// Save and Publish Branding (without logo)
let saveAndPublishActions = promiseResultRequestActions(
    actions.SAVE_AND_PUBLISH_BRANDING,
    (billerCode: string, style: BrandingStyle, files: FileList) => {
        if(files?.length) {
            return designerApi.saveAndPublishBrandingWithLogo(billerCode, style, files);
        } else {
            return designerApi.saveAndPublishBranding(billerCode, style);
        }
    }
);
export let saveAndPublish = saveAndPublishActions.send;
export let clearSaveAndPublish = saveAndPublishActions.clear;

// Discard draft
let discardDraftActions = promiseRequestActions(
    actions.DISCARD_BRANDING_DRAFT,
    (billerCode: string) => designerApi.discardBrandingDraft(billerCode)
);
export let discardDraft = discardDraftActions.send;
export let clearDiscardDraft = discardDraftActions.clear;

// Set BrandingByBiller
export let setByBiller = promiseRequestActions(
    actions.SET_BRANDING_BY_BILLER,
    (byBiller: boolean) => designerApi.setByBiller(byBiller)
).send;