import React from "react";
import _ from "lodash";

import { FilenameSubheading } from "components/Batch";
import { Dialog, Button } from "@premier/ui";
import { FaExclamationTriangle } from "react-icons/fa";

import errorUtil from "@premier/utils/error";
import "./UploadWarningDialog.scss";
import { BatchFileValidationResult } from "components/Batch/models/BatchFileResult";

type Props = {
    show: boolean; //logic render
    warnings: Warning[] | null;
    filenames?: string[];
    filename: string;
    batchFileResult: BatchFileValidationResult[] | null; //data
    onClose: () => void; //functions
}

type Warning = {
    code: string;
    parameter: string;
}

const UploadWarningDialog = ({
    show, //logic render
    warnings, filenames, filename, batchFileResult, //data
    onClose
}: Props) => {

    function genericWarningToString(warning: Warning) {
        if (warning.code === "INVALID_BATCH_FILENAME" && warning.parameter === "File.Filename")
            return "The filename you have submitted is invalid. Filename must only contain a-z, 0-9 or _ characters and cannot exceed 100 characters.";
        else if (warning.code === "INVALID_BATCH_FILE_EXTENSION" && warning.parameter === "File.Filename")
            return "The file type you have submitted is invalid. Only dat, txt and csv files can be submitted with or without zipping.";

        const paramLabels = {
            "File.Filename": "Filename",
        };

        return errorUtil.getMessage(warning, paramLabels);
    }

    return (
        <Dialog show={show}
            icon={<div><FaExclamationTriangle size={48} color="orange" /> </div>}
            title="Error with file upload"
            footerButtons={<>
                <Button onClick={onClose}>Close</Button>
            </>}
        >

            <FilenameSubheading filenames={filenames ? filenames : [filename]} />

            {_.get(warnings, "length") && (
                warnings?.map((warning, idx) => <p key={idx}>{genericWarningToString(warning)}</p>)
            )}

            {batchFileResult && batchFileResult.filter(r => r.hasWarnings).map((r, index) => (
                <React.Fragment key={index}>
                    <p>{r.filename}:</p>
                    <ul>
                        {
                            r.warnings.map((warning, idx) => (
                                <li key={idx}>
                                    {warning}
                                </li>
                            )
                            )}
                        <li>The file has not been processed.</li>
                        <li>To process this file select the <i><FaExclamationTriangle size={24} color="orange" /></i> icon on the 'In Progress' tab.</li>
                    </ul>
                </React.Fragment>
            ))}

        </Dialog>
    );
};

export default UploadWarningDialog;
