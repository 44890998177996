import { useContext } from "react";
import FormContext from "./FormContext";
import { ServerError } from "@premier/ui";

type FormError = {
    message: string
}

const FormErrorList = () => {
    const formContext = useContext(FormContext);
    const errors: FormError[] = formContext.formErrors;

    if (!errors)
        return null;

    return <ServerError errors={errors.map((r: any) => r.message)} />;
};

export default FormErrorList;
