//Original code from the following:
//https://github.com/facebook/react/issues/15344#issuecomment-495260552
//-ChuckR

import { useRef, useReducer, useCallback, useMemo, useEffect } from 'react';

export function useReducerWithResolvedDispatch(reducer, initialState, init) {
    const r = useRef({
        lastId: 0,
        resolve: {},
        resolved: [],
        state: null
    });
    const _init = useCallback(([state, included]) => [init ? init(state) : state, included], [init]);
    const _reducer = useCallback(
        ([state, includes], [aId, resolved, action]) => {
            if (resolved.length) includes = includes.filter(i => !resolved.includes(i));
            if (aId) includes = [...includes, aId];
            return [reducer(state, action), includes];
        },
        [reducer]
    );
    const [[state, includes], dispatch] = useReducer(_reducer, [initialState, []], _init);
    const _dispatch = useCallback(
        action => {
            dispatch([0, r.current.resolved, action]);
        },
        [dispatch]
    );
    _dispatch.resolved = useCallback(
        action =>
            new Promise((resolve, reject) => {
                const aId = ++r.current.lastId;
                r.current.resolve[aId] = resolve;
                dispatch([aId, r.current.resolved, action]);
            }),
        [dispatch]
    );
    useEffect(() => {
        r.current.state = state;
    }, [state]);
    useEffect(() => {
        for (const aId of includes) {
            if (r.current.resolve[aId]) {
                r.current.resolve[aId](r.current.state);
                delete r.current.resolve[aId];
            }
        }
        r.current.resolved = includes;
    }, [includes]);
    return useMemo(() => [state, _dispatch], [state, _dispatch]);
}
