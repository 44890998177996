import { useState } from "react";
import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";

import currencyUtil from "@premier/utils/currency";
import { Button, Icon, PaddedContainer, SuccessModal } from "@premier/ui";
import AcceptedAmountModal, { FormValues } from "./AcceptedAmountModal";

import * as commonActions from "components/Common/_actions/actions";
import * as billerActions from "components/Settings/_actions/billerActions";
import { Biller } from "models/Biller";
import { RootState } from "store/store";

type Props = {
    billerCode: string;
    isLoading?: boolean;
    billerActions?: any;
    commonActions?: any;
    biller?: Biller;
    acceptedAmountSubmission?: any;
}

/**
 * A PaddedContainer showing the Accepted Amount Range of a Biller, including the edit dialog + its success modal
 */
const AcceptedAmountSection = ({
    billerCode, isLoading,
    billerActions, commonActions, biller, acceptedAmountSubmission,  // API / redux
}: Props) => {
    const [showAcceptedAmountDialog, setShowAcceptedAmountDialog] = useState(false);

    function handleAcceptedAmountSubmit(values: FormValues) {
        const acceptedAmounts = {
            minAmount: values.acceptedAmounts.min,
            maxAmount: values.acceptedAmounts.max
        };

        billerActions.updateBillerPaymentLimitRange(billerCode, acceptedAmounts)
            .then(() => {
                setShowAcceptedAmountDialog(false);
            });
    }

    function handleAcceptedAmountModalClose() {
        setShowAcceptedAmountDialog(false);
        commonActions.clearErrors();
    }

    function handleSuccessModalClose() {
        billerActions.clearBillerPaymentLimitRange();
    }

    return (<>
        <PaddedContainer lessMargin noDivider
            icon={<Icon money />}
            title="Accepted amount range"
            button={<Button onClick={() => setShowAcceptedAmountDialog(true)} disabled={!biller}>Edit</Button>}
        >
            {biller && <>
                <p>Setup minimum and maximum accepted payment amounts</p>
                <p><b>Accepted amount range </b> {currencyUtil.formatWithPrefix(biller.paymentLimits.minimum)} &ndash; {currencyUtil.formatWithPrefix(biller.paymentLimits.maximum)}</p>
            </>}
        </PaddedContainer>


        {biller && <>
            <AcceptedAmountModal
                show={showAcceptedAmountDialog}
                onClose={handleAcceptedAmountModalClose}
                onSubmit={handleAcceptedAmountSubmit}
                paymentLimits={biller.paymentLimits}
                errors={acceptedAmountSubmission.errors}
                loading={isLoading}
            />

            <SuccessModal
                show={acceptedAmountSubmission.hasSucceeded}
                onClose={handleSuccessModalClose}
            >
                Accepted amount range saved successfully
            </SuccessModal>
        </>}
    </>);
};

function mapStateToProps(state: RootState, ownProps: Props) {
    const biller = state.accounts.users.billers
                    && state.accounts.users.billers.find((biller: Biller) => biller.billerCode === ownProps.billerCode);

    return {
        biller,

        // old way
        isLoading: state.accounts.users.isLoading,

        // new way
        paymentMethodsState: state.settings.paymentSettings.merchantPaymentMethods,
        surchargesState: state.settings.paymentSettings.surcharges,
        acceptedAmountSubmission: state.settings.paymentSettings.acceptedAmountSubmission,
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        commonActions: bindActionCreators(commonActions, dispatch),
        billerActions: bindActionCreators(billerActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AcceptedAmountSection);
