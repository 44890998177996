import {useEffect, useState } from 'react';
import { useApiCallDeprecated } from 'components/Common';
import  PaymentPlanList from 'components/Transactions/components/PaymentPlanList';
import { PaginationControl } from '@premier/ui';
import { paymentPlanApi } from 'api';
import { PaymentPlanListColumns } from './PaymentPlanList';

const PaymentPlanListPage = ({
    crnLabel,
    billerCode,
    resultsPerPage
}) => {
    const [optionNameList, setOptionNameList] = useState([{value: '', label: 'All'}]);
    const [totalItemCount, setTotalItemCount] = useState(0);
    const [sort, setSort] = useState({ field: 'Lastupdated', descending: true });
    const [dto, setDto] = useState({
        pageIndex: 0,
        pageSize: resultsPerPage,
        request: {
            billerCode: billerCode
        }
    });

    const getOptionNames = (optionNames) => {
        let initList = [{value: '', label: 'All'}];
        const optionNameList = optionNames?.map(x => ({
            value: x.paymentPlanOptionName,
            label: x.paymentPlanOptionName
        }));

        if(optionNameList?.length) {
            initList = initList.concat(optionNameList);
        }

        return initList;
    }

    const onFilterChange = (field, {val}) => {
        setDto({
            ...dto,
            request: {
                ...dto.request,
                status: field === PaymentPlanListColumns.STATUS ? (val === '' ? null : val) : (dto?.request?.status ?? null),
                reference: field === PaymentPlanListColumns.REFERENCE ? val : (dto?.request?.reference ?? null),
                paymentPlanOptionName: field === PaymentPlanListColumns.OPTION_NAME ? (val === '' ? null : val) : (dto?.request?.paymentPlanOptionName ?? null),
                cardNumberFirst6: field === PaymentPlanListColumns.MASKED_CARD_NUM ? val.prefix : (dto?.request?.cardNumberFirst6 ?? null),
                cardNumberLast3: field === PaymentPlanListColumns.MASKED_CARD_NUM ? val.suffix : (dto?.request?.cardNumberLast3 ?? null),
                accountNum: field === PaymentPlanListColumns.ACCOUNT_NUM ? val : (dto?.request?.accountNum ?? null),
            }
        });
    }

    const handleSort = (field, descending) => {
        setDto({
            ...dto,
            sortField: field,
            order: descending ? 'DESCENDING' : 'ASCENDING'
        });

        setSort({ field, descending });
    }

    const onTotalRecords = (totalRecords) => {
        setTotalItemCount(totalRecords);
    }

    const onPageChanged = (newPage) => {
        setDto({
            ...dto,
            pageIndex: newPage-1
        });
    }

    const [paymentPlanCommitments] = useApiCallDeprecated(() => {
        if (billerCode) {
            return paymentPlanApi.getPaymentPlanCommitment(billerCode);
        }
    }, [billerCode]);

    const [paymentPlanListData, , pplErrors] = useApiCallDeprecated(() => {
        if(dto)
            return paymentPlanApi.getPaymentPlanList(dto);
    }, [dto]);

    useEffect(() => {
        if (paymentPlanCommitments) {
            setOptionNameList(getOptionNames(paymentPlanCommitments));
        }
    }, [paymentPlanCommitments])

    useEffect(() => {
        if (billerCode !== dto.request.billerCode) {
            setDto({
                ...dto,
                request: {
                    billerCode: billerCode
                }
            });
        }
    }, [billerCode, dto])


    useEffect(() => {
        if (resultsPerPage !== dto.pageSize) {
            setDto({
                ...dto,
                pageSize: resultsPerPage,
                pageIndex: 0
            });
        }
    }, [resultsPerPage, dto])

    return (
        <>
            <PaymentPlanList
                apiData={paymentPlanListData}
                apiErrors={pplErrors}
                crnLabel={crnLabel}
                paymentPlanFilter={dto}
                optionNameList={optionNameList}
                onTotalRecords={onTotalRecords}
                onFilterChange={onFilterChange}
                onReload={() => onPageChanged(dto.pageIndex)}
                sort={sort}
                onSort={handleSort}
            />
            <PaginationControl currentPage={dto.pageIndex+1} onPageChanged={onPageChanged} itemCount={totalItemCount} itemsPerPage={resultsPerPage} />
        </>
    );
};

export default PaymentPlanListPage;
