import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Navigate } from 'react-router-dom';
import _ from 'lodash';

import {formatPhoneObjectToApiString} from '@premier/utils/billpay';
import errorUtil from '@premier/utils/error';
import { PageSection, Button, DescriptionList, LoadingIndicator, CardContainer, Divider, ServerError } from '@premier/ui';
import { PageHeader } from 'components/Common';
import { CheckoutSteps, ShoppingCartTable, CustomerAddress } from 'components/StationeryShop';
import withHydrateProductList from 'components/StationeryShop/components/WithHydrateProductList';

import { PlatformRoutesConfiguration } from 'components/Routing';
import * as stationeryActions from 'components/StationeryShop/_actions/stationeryShopActions';

const CheckoutReview = ({
    merchantInfo, stationeryShop, products, //State values
    actions //API actions
}) => {
    const [redirect, setRedirect] = useState(false);
    const [redirectBack, setRedirectBack] = useState(false);
    const [serverErrors, setServerErrors] = useState(false);

    const isLoading = stationeryShop.isLoading;

    var orderItems = stationeryShop.cart;
    var billingAddress = stationeryShop.recentAddresses && stationeryShop.recentAddresses.find(x => x.addressType === 'BILLING');
    var deliveryAddress = _.get(stationeryShop, 'deliveryAddress.address');
    var freightProduct = stationeryShop?.freightProduct;

    if((!stationeryShop.recentAddresses || !stationeryShop.deliveryAddress) && !redirectBack)
        setRedirectBack(true);


    function getDeliveryAddressSummary() {
        let details = stationeryShop.deliveryAddress;

        return <>
            {details.firstName} {details.lastName}<br/>
            {formatPhoneObjectToApiString(details.phoneNumber)}<br/>
            {stationeryShop.deliveryAddress.delRadio === 'same'
                ? 'Same as billing address'
                : <CustomerAddress address={getSelectedAddress()} />
            }
        </>;
    }

    function getSelectedAddress() {
        return stationeryShop.deliveryAddress.delRadio === 'same' ? billingAddress : deliveryAddress;
    }

    function getTotal(data) {
        return data.reduce((a,b) => a + b.amountPaid, 0);
    }

    function submitOrder() {
        let selectedAddress = getSelectedAddress();
        let order = {
            customerName: selectedAddress.customerName,
            street1: selectedAddress.street1,
            street2: selectedAddress.street2,
            suburb: selectedAddress.suburb,
            state: selectedAddress.state,
            postcode: selectedAddress.postcode,
            firstName: stationeryShop.deliveryAddress.firstName,
            lastName: stationeryShop.deliveryAddress.lastName,
            phoneNumber: formatPhoneObjectToApiString(stationeryShop.deliveryAddress.phoneNumber),
            customerNumber: merchantInfo.merchantNumber,
            isFreeOrder: false,
            freeOrderReason: '',
            items: [],
        };

        orderItems.forEach(i => {
            var item = products.find(x => x.productId === i.productId);
            order.items.push({
                productId: i.productId,
                quantity: i.qty,
                unitCost: item.unitCost,
                amountPaid: i.qty * item.unitCost,
            });
        });
        order.amountPaid = getTotal(order.items);

        actions.createOrder(order)
            .then(_r => actions.clearCart())
            .then(_r => setRedirect(true))
            .catch(e => {
                if (!Array.isArray(e))
                    throw e;
                setServerErrors(e.map((err) => errorUtil.getMessage(err, {
                    CustomerName: 'Business name',
                    Street1: 'Address line 1',
                    Suburb: 'City',
                    State: 'State',
                    Postcode: 'Postcode',
                    PhoneNumber: 'Phone number',
                    FirstName: 'First name',
                    LastName: 'Last name'
                })));
            });
    }


    if (redirect)
        return <Navigate to={PlatformRoutesConfiguration.stationeryRoute.orderReceived.generatePath()} />;

    if (redirectBack)
        return <Navigate to={PlatformRoutesConfiguration.stationeryRoute.checkoutAddress.generatePath()} />;

    return (
        <div className="checkout-review-page">
            <PageSection>
                <PageHeader
                    backButton={!isLoading && {
                        to: PlatformRoutesConfiguration.stationeryRoute.homePage.generatePath(),
                        label: 'Continue shopping'
                    }}
                />

                {isLoading && (
                    <CardContainer header="Submitting order">
                        <LoadingIndicator />
                    </CardContainer>
                )}

                {!isLoading && (<>
                    <CheckoutSteps currentStep={3} />

                    <h2>Your order</h2>
                    <ShoppingCartTable readOnly cartItems={products} freightProduct={freightProduct} />
                    <Divider />

                    <h2>Address</h2>
                    {billingAddress && getSelectedAddress() && (
                        <DescriptionList striped items={[{
                            name: 'Billing address',
                            value: <CustomerAddress address={billingAddress} />
                        }, {
                            name: 'Delivery address',
                            value: getDeliveryAddressSummary()
                        }]} />
                    )}

                    {serverErrors && <ServerError errors={serverErrors} />}

                    <Button
                        type="submit"
                        primary
                        disabled={isLoading}
                        label="Submit"
                        onClick={() => submitOrder()}
                    />
                </>)}
            </PageSection>
        </div>
    );
};

function mapStateToProps(state, ownProps) {
    return {
        merchantInfo: state.accounts.users.merchant,
        stationeryShop: state.stationeryShop.shop,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(stationeryActions, dispatch),
    };
}

function getSelectedProductLocation(state){
    return state.stationeryShop.shop.cart;
}


export default withHydrateProductList(
    connect(mapStateToProps, mapDispatchToProps)(CheckoutReview),
    getSelectedProductLocation
);
