import React from 'react';
import PropTypes from 'prop-types';
import './FormHeader.scss';

const FormHeader = ({
    title, description //data
}) => {
    return (
        <div className="form-header">
            <h1 className="form-title-header">{title}</h1>
            <h2 className="form-title-description">{description}</h2>
        </div>
    );
}

FormHeader.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string
};

export default FormHeader;