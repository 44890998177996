import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { invoiceApi } from 'api';
import { withApiCall } from 'components/Common';
import { PageSection } from '@premier/ui';
import * as paramUtil from '@premier/utils/param';

import ViewInvoiceBody from '../components/ViewInvoice/ViewInvoiceBody';

interface Props {}

const ViewInvoicePage: React.FC<Props> = () => {
    const { invoiceId } = useParams<{ invoiceId: string }>();
    const [forceUpdate, setForceUpdate] = useState<boolean>(false);


    const Body = withApiCall(ViewInvoiceBody, (props: any) => invoiceApi.getInvoice({ invoiceId: props.invoiceId }));
    return (
        <PageSection>
            <Body onSubmitSuccess={() => setForceUpdate(!forceUpdate)} invoiceId={paramUtil.asInteger(invoiceId)} />
        </PageSection>
    );
};

export default ViewInvoicePage;
