import { useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';

import * as billpayUtil from '@premier/utils/billpay';
import * as paramUtil from '@premier/utils/param';
import { LoadingIndicator, Link, PageSection, IconText, PaginationControl } from '@premier/ui';
import { PageHeader } from 'components/Common';
import { CustomerSummarySubtitle, CustomerSubscriptionsList } from 'components/DataVault';
import { OverdueSchedulesBanner } from 'components/Transactions';

import { defaultPageSize } from 'constants/billpay';

import * as customerActions from 'components/DataVault/_actions/customerActions';
import * as scheduleActions from 'components/Transactions/_actions/scheduleActions';
import { PlatformRoutesConfiguration } from 'components/Routing';

const ViewCustomerSubscriptionsPage = ({
    actions, scheduleActions, //apiActions
    customer, customerSubscriptions, overduePaymentsCount, savedState, //state
    customerId, //urlParam
}) => {

    const resultsPerPage = defaultPageSize;
    const [currentPage, setCurrentPage] = useState(_.get(savedState, 'pageNumber', 1));
    const [sort, setSort] = useState({ field: 'Created', descending: true });

    useEffect(() => {
        if (!customer)
            actions.getCustomer(customerId);

        scheduleActions.getOverduePaymentsCount({customerId});
    }, [customerId]);

    useEffect(() => {
        actions.getCustomerSubscriptions(20, currentPage-1, {
            customerId: customerId
        }, sort);
    }, [customerId, sort, currentPage]);


    function handleSort(field, descending) {
        setSort({field, descending});
    }

    if (!customer)
        return <LoadingIndicator/>

    //Loaded and customer data present
    return (
        <PageSection>
            <PageHeader
                backButton
                title={billpayUtil.getCustomerFullName(customer)}
                subtitle={<CustomerSummarySubtitle customer={customer} />}
            >
                <Link button primary to={PlatformRoutesConfiguration.customerRoute.newCustomerSchedule.generatePath(customer.customerId)}>New subscription</Link>
            </PageHeader>

            <OverdueSchedulesBanner
                overduePaymentsCount={overduePaymentsCount}
            />

            <h2>Subscriptions</h2>
            {customerSubscriptions.hasFailed && <IconText alert>Failed to load subscriptions. An unexpected error has occurred.</IconText>}
            <CustomerSubscriptionsList
                subscriptions={_.get(customerSubscriptions, "data.schedules", [])}
                isLoading={!customerSubscriptions.hasLoaded}
                sort={sort}
                onSort={handleSort}
            />

            <PaginationControl
                currentPage={currentPage}
                itemsPerPage={resultsPerPage}
                itemCount={_.get(customerSubscriptions, 'data.resultCount', 0)}
                onPageChanged={(page) => setCurrentPage(page)}
            />
        </PageSection>
    );
}

function mapStateToProps(state, ownProps){
    var customerId = paramUtil.asInteger(ownProps.match.params.id);

    return {
        customer: _.get(state, 'dataVault.customer.details.customerId') === customerId ? state.dataVault.customer.details: undefined,
        customerId: customerId,
        isLoading: state.dataVault.customer.isLoading,
        customerSubscriptions: state.dataVault.customerSubscriptions,
        overduePaymentsCount: _.get(state, 'transactions.schedule.overduePaymentsCount.data.resultCount'),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(customerActions, dispatch),
        scheduleActions: bindActionCreators(scheduleActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewCustomerSubscriptionsPage);