import React from 'react';
import labels from 'constants/labels';

import { CurrencyField, CheckboxGroupField, Form, InputField, DatePickerField } from '@premier/form';
import { IconText, PageSection, SingleColumnFormContainer } from '@premier/ui';

/** Sub-form used for a user to submit a fund settlement. Used for the contact us page */
const FundsSettlementForm = () => {
    return (
        <PageSection>
            <Form name='fundsSettlementForm' initialValues={{
                fundsAmount: '',
                numberOfTransactions: ''
            }}>
                <SingleColumnFormContainer>
                    <h2>Provide details of the funds settlement below</h2>
                    <CurrencyField name='fundsAmount' label={labels.amount} />
                    <InputField name='numberOfTransactions' label='Number of transactions' placeholder='Value' />
                    <CheckboxGroupField
                        name='cardType'
                        label='Choose the card type'
                        inline
                        options={[
                            { value: 'masterCard', label: 'MasterCard' },
                            { value: 'visa', label: 'Visa' }
                        ]}
                    />
                </SingleColumnFormContainer>
                <IconText info>For Amex please call 1300 363 614. For Diners please call 1300 360 500</IconText>
                <SingleColumnFormContainer>
                    <DatePickerField
                        name='dateRange'
                        label={labels.dateRange}
                        mode='range'
                    />
                </SingleColumnFormContainer>
            </Form>
        </PageSection>
    );
};

export default FundsSettlementForm;
