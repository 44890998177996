import _ from 'lodash';

import dateUtil from '@premier/utils/date';
import currencyUtil from '@premier/utils/currency';
import httpRequest from './httpRequest';
import * as billpayUtil from '@premier/utils/billpay';

import { defaultPageSize } from 'constants/billpay';

import mapErrors from './mapErrors';

class SchedulesApi {
    //#region ----- Schedules -----
    static getSchedules(resultsPerPage = defaultPageSize, pageIndex = 0, filter = {}, sort) {
        function mapExpiryDate(expiryDate) {
            const dateFilter = _.omitBy(expiryDate, _.isEmpty);
            return _.isEmpty(dateFilter) ? null : dateFilter;
        }

        function mapSortField(field) {
            switch (field) {
                case 'subscriptionStatus.description':
                    return 'ScheduleStatus';
                default:
                    return field;
            }
        }

        /** Translates display-field name to sort-field name (Backend is using different field names for displaying & sorting for some reason) */
        return new Promise((resolve, reject) => {

            let createdDate = (_.get(filter, 'createdDateRange.length')) ? dateUtil.convertDateRangeToApiDateTimeRange(filter.createdDateRange) : null;
            let endDate = (_.get(filter, 'endDateRange.dates.length')) ? dateUtil.convertDateRangeToApiDateTimeRange(filter.endDateRange.dates) : null;

            const request = {
                ...filter,
                maskedCardNumber: billpayUtil.maskedCardNumberToApiString(filter.maskedCardNumber),
                recurringAmount: currencyUtil.convertToApiValue(filter.recurringAmount),
                expiryDate: mapExpiryDate(filter.expiryDate),
                created: createdDate,
                end: endDate,
                nextPayment: _.isEmpty(filter.nextPayment) ? null : dateUtil.convertDateRangeToApiDateTimeRange(filter.nextPayment),
            };

            // Remove the date ranges which have been converted to dates
            delete request.createdDateRange;
            delete request.endDateRange;

            const model = {
                request: _.pickBy(request, (value) => value),
                sortField: mapSortField(sort.field),
                order: sort.descending ? 'DESCENDING' : 'ASCENDING',
                pageIndex: pageIndex,
                pageSize: resultsPerPage
            };

            httpRequest
                .post(`/subscriptions/search`, model)
                .then(response => {
                    var schedules = response.list;
                    var resultCount = response.totalCount;
                    resolve({ schedules, resultCount });
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    static getSchedule(scheduleId) {
        return new Promise((resolve, reject) => {
            httpRequest
                .get(`/subscriptions/${scheduleId}`)
                .then(schedule => {
                    //map customer's phone numbers from string to object
                    var response = {
                        ...schedule,
                        customer: schedule.customer ? {
                            ...schedule.customer,
                            phoneNumbers: schedule.customer.phoneNumbers.map(p => ({
                                ...p,
                                phoneNumber: billpayUtil.formatPhoneApiStringToObject(p.phoneNumber),
                            })),
                        } : null
                    };
                    resolve(response);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    static addSchedule(scheduleData) {
        return new Promise((resolve, reject) => {
            httpRequest
                .post('/subscriptions', mapScheduleToDto(scheduleData))
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    reject(mapErrors(err, mapScheduleErrorsFromDto(scheduleData)));
                });
        });
    }

    static updateSchedule(scheduleData) {
        return new Promise((resolve, reject) => {
            httpRequest
                .patch(`/subscriptions/${scheduleData.subscriptionId}`, mapScheduleToDto(scheduleData))
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    reject(mapErrors(err, mapScheduleErrorsFromDto(scheduleData)));
                });
        });
    }

    static cancelSchedule(scheduleId) {
        return new Promise((resolve, reject) => {
            httpRequest
                .post(`/subscriptions/${scheduleId}/cancel`)
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    static suspendSchedule(scheduleId, suspendData) {
        return new Promise((resolve, reject) => {
            httpRequest
                .post(`/subscriptions/${scheduleId}/suspend`, mapSuspendToDto(suspendData))
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    reject(mapErrors(err));
                });
        });
    }

    static resumeSchedule(scheduleId) {
        return new Promise((resolve, reject) => {
            httpRequest
                .post(`/subscriptions/${scheduleId}/resume`)
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }
    //#endregion

    //#region ----- Schedule Filter -----

    static getScheduleStatuses() {
        return new Promise((resolve, reject) => {
            httpRequest
                .get('/subscriptions/statuses')
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    static getScheduleFrequencies() {
        return new Promise((resolve, reject) => {
            httpRequest
                .get('/subscriptions/frequencies')
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    static getSchedulePaymentStatuses() {
        return new Promise((resolve, reject) => {
            httpRequest
                .get('/subscriptions/payment-statuses')
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    //#endregion
}

/** For add/update schedule */
function mapScheduleToDto(scheduleData) {
    const crnList = _.get(scheduleData, 'scheduleDetailsBillerCodeForm.billerCrnList');
    const startDate = Array.isArray(scheduleData.startDate) ? scheduleData.startDate[0] : scheduleData.startDate;
    const endDate = Array.isArray(scheduleData.endDate) ? scheduleData.endDate[0] : scheduleData.endDate;
    const nextPaymentDate = Array.isArray(scheduleData.nextPaymentDate) ? scheduleData.nextPaymentDate[0] : scheduleData.nextPaymentDate;

    const dto = {
        childMerchantNumber: _.get(scheduleData, 'scheduleDetailsBillerCodeForm.merchantNumber'),
        billerCode: _.get(scheduleData, 'scheduleDetailsBillerCodeForm.billerCode'),
        reference1: _.get(crnList, 'crn1', scheduleData.reference1),
        reference2: _.get(crnList, 'crn2', scheduleData.reference2),
        reference3: _.get(crnList, 'crn3', scheduleData.reference3),
        scheduleReference: scheduleData.scheduleReference,
        recurringAmount: currencyUtil.convertToApiValue(scheduleData.recurringAmount),
        surchargeAmount: currencyUtil.convertToApiValue(scheduleData.surchargeAmount),
        frequency: scheduleData.frequency,
        startDate: nextPaymentDate ? dateUtil.convertToApiValue(nextPaymentDate) : dateUtil.convertToApiValue(startDate),
        numberOfPayments: scheduleData.numberOfPayments,
        endDate: dateUtil.convertToApiValue(endDate),
        scheduleEnd: scheduleData.scheduleEnd,
        dataVaultId: scheduleData.dataVaultId,
        customerId: scheduleData.customerId,
    };

    return _.pickBy(dto, (value) => value);
}

function mapSuspendToDto(suspendData) {
    var dto = {
        numberOfPayments: suspendData.numberOfPayments,
        endDate: _.isEmpty(suspendData.endDate) ? null : dateUtil.convertToApiValue(suspendData.endDate[0]),
    }

    return _.pickBy(dto, (value) => value);
}

function mapScheduleErrorsFromDto(scheduleData) {
    var billerCrnList = _.get(scheduleData, 'scheduleDetailsBillerCodeForm.billerCrnList');

    return (parameter) => {
        if (parameter === "childMerchantNumber")
            return "scheduleDetailsBillerCodeForm.merchantNumber";
        if (parameter === "billerCode")
            return "scheduleDetailsBillerCodeForm.billerCode";
        if (billerCrnList) {
            if (parameter === "crn1" || parameter === 'reference1')
                return "scheduleDetailsBillerCodeForm.billerCrnList.crn1";
            if (parameter === "crn2" || parameter === 'reference2')
                return "scheduleDetailsBillerCodeForm.billerCrnList.crn2";
            if (parameter === "crn3" || parameter === 'reference3')
                return "scheduleDetailsBillerCodeForm.billerCrnList.crn3";
        }
    }
}

export default SchedulesApi;
