import { useContext, useEffect } from "react";
import FormContext from "./FormContext";
import PropTypes from "prop-types";
import classNames from "classnames";
import _ from "lodash";
import $ from "jquery";
import windowUtil from "@premier/utils/window";
import cssClasses from "@premier/ui/cssClasses";
import FieldValidation from "./validation/fieldValidation";
import * as objectValidator from "./validation/objectValidator";
import { formActions, formReducer } from "./formReducer";
import { useReducerWithResolvedDispatch } from "./useReducerWithResolvedDispatch";

function getLabelledErrors(errors, fields, errorMaps) {
    const output = {
        list: [],
        map: {},
    };

    if (!errors) {
        return output;
    }

    let fieldErrors = 0;
    _.forEach(errors, (val) => {
        if (val.parameter && !val.field)
            console.error(`Error has a parameter (${val.parameter}) but no field. Are you calling mapErrors?`);

        const field = _.get(fields, val.field);
        const error = {
            ...val,
            label: field ? _.get(field, "label", val.field) : val.label,
        };
        if (errorMaps && errorMaps[val.code])
            error.message = errorMaps[val.code](error);
        else
            error.message = error.message.replace("{label}", error.label);
        //there is unnoknow bug for NewPaymentModal.js which causing fields value lost, and thus can't function well here. So make such error "Card.Number is invalid" to be "Card Number is invalid"
        //once the bug fixed then ".replace(".", " ")" can be removed.
        _.set(output.map, val.field, error.message.replace(".", " "));

        if (field || error.label)
            //continue with the bug result, if the error has "label", then it's controled exception from API.
            fieldErrors++;
        else
            output.list.push(error);
    });

    if (fieldErrors) {
        output.list.push({
            message: "There are errors in the form. Please scroll up and fix them.",
        });
    }

    return output;
}

const Form = (props) => {
    let { initialValues, initialValidation, submitOnError, errors, errorMaps, render, forceParent, validateOnMount, onValidate } = props;

    let parentForm = useContext(FormContext); // Note: Parent form does not have reference to its child forms
    if (forceParent)
        parentForm = null;

    if (parentForm && !props.name)
        throw new Error("The 'name' prop is required in a child form.");

    useEffect(() => {
        if (parentForm) {
            //sync the current values on to the parent form and vice versa.
            //If the parent form has initial values set, then those override what is
            //set at the child level.  Otherwise use the child initial values, and update
            //the parent values to the childs.
            const parentValues = parentForm.getValue(props.name, true);

            if (parentValues) {
                initialValues = Object.assign({}, initialValues, parentValues);
            } else {
                parentForm.setValue(props.name, initialValues);
            }

            //sync the current validation on to the parent form and vice versa.
            //If the parent form has initial validation set, then those override what is set at the child level.
            //(If you override one field, the other fields won't be affected. To disable a child validation, set it to null)
            //Otherwise use the child initial validation, and update the parent validation to the child's.
            const parentValidation = parentForm.getValidation(props.name);

            if (parentValidation) {
                initialValidation = _.merge({}, initialValidation, parentValidation);
            }
            parentForm.setValidation(props.name, initialValidation);
        }

        if (validateOnMount) {
            context.validate();
        }

        return function cleanup() {
            if (parentForm) {
                parentForm.setValidation(props.name, {});
                parentForm.setError(props.name, {});
            }
        };
    }, []);

    const [state, dispatch] = useReducerWithResolvedDispatch(formReducer, {
        values: Object.assign({}, initialValues),
        validations: initialValidation || {},
        errors: {},
        formErrors: [],
        fields: {},
    });

    useEffect(() => {
        const labelledErrors = getLabelledErrors(errors, state.fields, errorMaps);
        dispatch.resolved({
            type: formActions.addErrors,
            errors: labelledErrors.map,
            formErrors: labelledErrors.list,
        });
    }, [errors, state.fields]);

    useEffect(() => {
        if (props.resetForm) {
            resetForm();
        }
    }, [props.resetForm]);

    function blur(name) {
        validateFieldAsync(name, getValue(name)).catch((err) => {});
    }

    function getError(name) {
        const errors = parentForm ? parentForm.getError(props.name) : state.errors;
        return _.get(errors, name);
    }

    /** Gets a form value, parent's one takes priority
     * @param {bool} fromInit (For when called from initialisation) Take child value when parent one is null
     */
    function getValue(name, fromInit) {
        if (parentForm) {
            let value = parentForm.getValue(`${props.name}.${name}`, fromInit); // To fix child-form-not-reset bug
            if (fromInit && value == null) {
                value = _.get(state.values, name);
            }
            return value;
        }

        return _.get(state.values, name);
    }

    function getValidation(name) {
        const thisRule = _.get(state.validations, name);

        if (parentForm) {
            const parentValidation = parentForm.getValidation(props.name);
            const parentRule = parentValidation && _.get(parentValidation, name);
            if (parentRule !== undefined) {
                // Parent's validation rule overrides child's
                return parentRule === null ? null : Object.assign(new FieldValidation(), thisRule, parentRule);
            }
        }
        return thisRule;
    }

    function getMandatory(name) {
        const validation = getValidation(name);
        if (validation && validation instanceof FieldValidation) {
            return (
                validation.hasTest("required") ||
                validation.hasTest("requiredPhone") ||
                validation.hasEnabledTest("requiredIf")
            );
        }

        return false;
    }

    function getFields() {
        return parentForm ? _.get(parentForm.getFields(), props.name) : state.fields;
    }

    /**
     * Replace {label} with the field label/labelText
     * @param {string|Object} error The error message for a field (or an object if it is for a subform)
     * @param {*} fieldName The name of the field the error is for
     */
    function replaceFieldLabel(error, fieldName) {
        if (_.isString(error)) {
            const fieldLabel = _.get(getFields(), `${fieldName}.label`, "Field");
            return error.replace("{label}", fieldLabel);
        }

        if (_.isObject(error)) {
            Object.keys(error).forEach((childFieldName) => {
                error[childFieldName] = replaceFieldLabel(error[childFieldName], `${fieldName}.${childFieldName}`);
            });
        }

        return error;
    }

    /** Resolves when all fields are valid, rejects if there is an error. */
    function validate() {
        // Let any clients of Form.js know that validation is about to happen
        onValidate && onValidate();

        return new Promise((resolve, reject) => {
            const errors = objectValidator.validate(state.values, state.validations);
            if (errors) {
                Object.keys(errors).forEach((fieldName) => {
                    errors[fieldName] = replaceFieldLabel(errors[fieldName], fieldName);
                });

                dispatch
                    .resolved({
                        type: formActions.setErrors,
                        payload: errors,
                    })
                    .then((nextState) => {
                        if (parentForm) {
                            parentForm.setError(props.name, nextState.errors);
                        }
                    });
                reject(errors);
            } else {
                resolve();
            }
        });
    }

    function validateField(name, val) {
        const validation = getValidation(name);

        try {
                        objectValidator.validateField(val, validation, state.values);
            setError(name, null);
        } catch (e) {
            setError(name, e.message);
            throw e;
        }
    }

    function validateFieldAsync(name, val) {
        return new Promise((resolve, reject) => {
            try {
                validateField(name, val);
                resolve();
            } catch (e) {
                reject(e);
            }
        });
    }

    /**
     * Sets a fields value in the form context.  When the validation flag is set to true,
     * just the field will be validated.  When it is set to false no fields will be validated.
     * @param {string} name The name of the field to set
     * @param {object} newValue The new value to set the field to.
     * @param {bool} validate Whether or not to validate after setting the new value.
     */
    async function setValue(name, newValue, validate = true) {
        dispatch
            .resolved({
                type: formActions.setFieldValue,
                path: name,
                value: newValue,
                valuesFromParent: parentForm && parentForm.getValue(props.name), // To fix child-form-not-reset bug, but might have side-effect on dynamically setting multiple field values?
            })
            .then((nextState) => {
                if (getError(name) && validate) {
                    validateFieldAsync(name, _.get(nextState.values, name)).catch((err) => {});
                }
                //check for errors on the given field and revalidate this
                if (parentForm) {
                    parentForm.setValue(props.name, nextState.values, false);
                }
            });
    }

    function setValidation(name, validation) {
        dispatch
            .resolved({
                type: formActions.setFieldValidation,
                path: name,
                value: validation,
            })
            .then((nextState) => {
                if (parentForm) {
                    parentForm.setValidation(props.name, nextState.validations);
                }
            });
    }

    function removeValidation(name) {
        dispatch
            .resolved({
                type: formActions.removeFieldValidation,
                path: name,
            })
            .then((nextState) => {
                if (parentForm) {
                    parentForm.removeValidation(props.name);
                }
            });
    }

    /**
     * Set the error message for a field, or error object for a subform
     * @param {*} name The field name the error is for
     * @param {string|Object} val The error message for a field (or an object if it is for a subform)
     */
    function setError(name, val) {
        val = replaceFieldLabel(val, name);

        dispatch
            .resolved({
                type: formActions.setFieldError,
                path: name,
                value: val,
            })
            .then((nextState) => {
                if (parentForm) {
                    parentForm.setError(props.name, nextState.errors);
                }
            });
    }

    function clearErrors() {
        dispatch
            .resolved({
                type: formActions.clearErrors,
            })
            .then((nextState) => {
                if (parentForm) {
                    parentForm.setError(props.name, nextState.errors);
                }
            });
    }

    function removeField(name) {
        setValue(name, undefined);
    }

    function addField(name, initialValues) {
        setValue(name, initialValues);
    }

    function setFormValues(values) {
        dispatch({
            type: formActions.setValues,
            payload: values,
        });
    }

    function resetForm(newValues) {
        clearErrors();

        const resetTo = newValues || props.initialValues || {};
        setFormValues(resetTo);
        // TODO BUG STRY0076230: Parent's initialValues does not include sub-form's initialValues

        // TODO Reset touched values
    }

    function registerField(name, field) {
        if (parentForm) {
            return parentForm.registerField(props.name + "." + name, field);
        }

        dispatch.resolved({
            type: formActions.registerField,
            path: name,
            value: field,
        });
    }

    function unRegisterField(name) {
        if (parentForm) {
            return parentForm.unRegisterField(props.name + "." + name);
        }

        dispatch.resolved({
            type: formActions.unRegisterField,
            path: name,
        });
    }

    const submissionContext = {
        ...state,
        getValue,
        getValidation,
        getError,
        getMandatory,
        getFields,
        setValue,
        setValidation,
        removeValidation,
        setError,
        setFormValues,
        clearErrors,
        removeField,
        addField,
        blur,
        resetForm,
        validate,
        validateField,
        registerField,
        unRegisterField,
    };

    function handleSubmit(e, onSubmitOverride) {
        function doSubmit() {
            if (onSubmitOverride)
                onSubmitOverride(state.values, submissionContext);
            else if (props.onSubmit)
                props.onSubmit(state.values, submissionContext);
        }

        //Stop event propagation to the form elements (stops redirect)
        if (e && e.preventDefault && typeof e.preventDefault === "function") {
            e.preventDefault();
        }

        if (e && e.stopPropagation && typeof e.stopPropagation === "function") {
            e.stopPropagation();
        }

        clearErrors();

        validate()
            .then(() => {
                doSubmit();
            })
            .catch((e) => {
                if (submitOnError && (onSubmitOverride || props.onSubmit)) {
                    doSubmit();
                } else {
                    console.error(e);

                    // The React version
                    //focusOnError(context.errors, context.references);

                    // The simple jQuery version
                    if ($(`form .${cssClasses.fieldHasError}`).length)
                        windowUtil.scrollAndFocus($(`form .${cssClasses.fieldHasError}`)[0]);
                }
            });
    }

    var context = {
        ...submissionContext,
        handleSubmit,
    };

    function getWrapper(children) {
        const classnames = classNames({
            "inline-label": props.inlineLabels,
            "inline-label-up-md": props.inlineLabelsUpMd,
        });

        return parentForm ? (
            <div className={classnames}>{children}</div>
        ) : (
            <form className={classnames}>{children}</form>
        );
    }

    return (
        <FormContext.Provider value={context}>
            {getWrapper(render ? render(context) : props.children)}
        </FormContext.Provider>
    );
};

Form.propTypes = {
    /** Required for child forms */
    name: PropTypes.string,
    initialValues: PropTypes.object,
    initialValidation: PropTypes.object,
    submitOnError: PropTypes.bool,
    inlineLabels: PropTypes.bool,
    inlineLabelsUpMd: PropTypes.bool,
    errors: PropTypes.array,
    errorMaps: PropTypes.object,
    /** Opts out of this form acting as a child form */
    forceParent: PropTypes.bool,
    /** validates fields on first load */
    validateOnMount: PropTypes.bool,
    resetForm: PropTypes.bool,
    onValidate: PropTypes.func,
    onSubmit: PropTypes.func,
    render: PropTypes.func,
    children: PropTypes.node
};

export default Form;
