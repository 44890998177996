import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown, RadioGroup } from '@premier/ui';
import FormGroup from './FormGroup';
import withField from './withField';
import './ContactTimeField.scss';

const ContactTimeField = ({ formProps, groupProps }) => {

    const { value, setValue } = formProps;

    function handleDayChanged(newValue) {
        setValue(Object.assign({}, value, { day: newValue.value }));
    }

    function handleTimeChanged(newValue) {
        setValue(Object.assign({}, value, { time: newValue.id }));
    }

    function getSelectedTime() {
        if (value && value.time) {
            return times.filter(t => t.value === value.time)[0];
        }
        return times[0];
    }

    return (
        <FormGroup
            {...groupProps}
            className={`${groupProps.className} contact-time-field`}
        >
            <div className='row'>
                <div className='col-md-4'>
                    <Dropdown
                        options={days}
                        name='contactDay'
                        onChange={handleDayChanged}
                    />
                </div>
                <div className='col-md-8 contact-time-selector'>
                    <RadioGroup
                        options={times}
                        name='contactTime'
                        onChange={handleTimeChanged}
                        selectedOption={getSelectedTime()}
                        inlineUpSm
                    />
                </div>
            </div>
        </FormGroup>
    );
};

ContactTimeField.propTypes = {
    /** The form-connected name of the field */
    name: PropTypes.string.isRequired,

    /** Whether or not this field is required (defined by having a .required() validation) */
    mandatory: PropTypes.bool,

    /** Help contents (if any) */
    help: PropTypes.any,

    /** Additional class names for the inner component */
    className: PropTypes.string,

    /** Whether to display the field without labels or error messages, for use in compact areas or tables */
    noLabels: PropTypes.bool,

    /** The label for the field */
    label: PropTypes.any
};

export default withField(ContactTimeField);

const days = [
    {
        label: 'Monday',
        value: 'Monday'
    },
    {
        label: 'Tuesday',
        value: 'Tuesday'
    },
    {
        label: 'Wednesday',
        value: 'Wednesday'
    },
    {
        label: 'Thursday',
        value: 'Thursday'
    },
    {
        label: 'Friday',
        value: 'Friday'
    }
];

const times = [
    {
        label: 'Morning',
        value: 'Morning'
    },
    {
        label: 'Afternoon',
        value: 'Afternoon'
    }
];
