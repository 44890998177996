import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';

import { Link, ButtonContainer } from '@premier/ui';
import { Form, SubmitButton, InputField, FormErrorList } from '@premier/form';
import { FormHeader, HelpText, HCaptchaStandalone, FloatingContainer } from 'components/Common';
import labels from 'constants/labels';
import errorMaps from 'constants/errorMaps';

import { PlatformRoutesConfiguration } from 'components/Routing';
import * as accountActions from 'components/Account/_actions/accountActions';

import './ForgotPasswordPage.scss';

const ForgotPasswordPage = ({
    actions, //API actions
    userDetails, isLoading, //state
}) => {
    const [redirect, setRedirect] = useState(false);
    const [captchaResponse, setCaptchaResponse] = useState('');
    const [errors, setErrors] = useState(null);

    var handleSubmit = (values) => {
        setErrors(null);
        actions.resetPassword({
                ...values,
                captchaResponse: captchaResponse
            })
            .then(data => {
                setRedirect(true);
            })
            .catch((errors) => {
                setErrors(errors?.map(err => {
                    if (err.field === "captchaResponse")
                        return { ...err, field: "reCAPTCHA" };
                    return err;
                }));
            });
    };

    function handleCaptchaOnChange(token, ekey) {
        setCaptchaResponse(token);
    }

    if (redirect) return <Navigate to={PlatformRoutesConfiguration.accountRoute.forgotPasswordConfirmation.generatePath()} />;

    return (
        <FloatingContainer>
            <FormHeader title='Forgot password?' description='Help us identify you' />
            <div>
                Your 16 digit CommBank Merchant ID can be located on your last statement. Your Merchant ID will begin with the numbers 535310.
                <br /><br />
            </div>
            <Form inlineLabelsUpMd initialValues={userDetails} onSubmit={handleSubmit} errors={errors} errorMaps={errorMaps}>
                <InputField type='text' name='username' label={labels.loginUsername} />
                <InputField type='text' name='merchantNumber' label={labels.loginMerchantNumber} maxLength={16} />
                <div className='back-to-login d-md-block'>
                    <Link to={PlatformRoutesConfiguration.accountRoute.logon.generatePath()}>Back to log on</Link>
                </div>
                <div className='recaptcha-control d-md-block'>
                    <HCaptchaStandalone onChange={handleCaptchaOnChange} />
                </div>

                <FormErrorList />

                <ButtonContainer>
                    <SubmitButton disabled={isLoading}>Next</SubmitButton>
                </ButtonContainer>
            </Form>

            <HelpText
                title='Not yet registered for BPOINT?'
                description='Contact our eCommerce specialists on 1800 230 177'
            />
        </FloatingContainer>
    );
};

function mapStateToProps(state, ownProps) {
    var initialValues = {
        username: '',
        merchantNumber: Cookies.get('Merchant') || '',
        captchaResponse: ''
    };

    return {
        userDetails: initialValues,
        isLoading: !!state.accounts.users.isLoading
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(accountActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordPage);
