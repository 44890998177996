import { useEffect, useState, useMemo } from "react";
import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import _ from "lodash";
import classNames from "classnames";
import windowUtil from "@premier/utils/window";
import uiUtil from "@premier/utils/ui";
import * as billpayUtil from "@premier/utils/billpay";
import { PageSection, BackToTop, PaginationControl, CardContainer, LoadingIndicator, Button } from "@premier/ui";
import { Form, DropdownField } from "@premier/form";
import { FormError, PageHeader, ProductTooltip } from "components/Common";
import { PaymentRequestList, PaymentRequestFilter } from "components/PaymentRequests";
//@ts-ignore
import labels from "constants/labels";
//@ts-ignore
import { defaultPageSize, defaultPageSizeOptions } from "constants/billpay";
import * as requestActions from "components/PaymentRequests/_actions/paymentRequestActions";
import { userRoles } from "components/Routing";
import { Merchant } from "packages/utils/models";
import { Biller } from "models/Biller";
import { RootState } from "store/store";

import "./ManagePaymentRequestsPage.scss";

type Props = {
    requestStatuses: any;
    requestActions: any;
    searchResult: any;
    requestCount: number;
    savedState: any;
    pageIsLoading: boolean;
    searchIsLoading: boolean;
    merchant: Merchant;
    billers: Biller[];
    actions: any;
    errors: string[];
}

// Note: QR payment requests are not displayed on this page
const ManagePaymentRequestsPage = ({
    requestStatuses, requestActions,
    searchResult, requestCount, savedState, pageIsLoading, searchIsLoading, merchant, billers, //state values
    actions, //API actions
    errors, //forms
}: Props) => {
    const [showFilter, setShowFilter] = useState(false);
    const [filter, setFilter] = useState<Record<string, any>>({});  // A live copy of the form values
    const [lastSavedFilter, setLastSavedFilter] = useState({});
    const [sort, setSort] = useState(_.get(savedState, "sort", { field: "Created", descending: true }));
    const [reload, setReload] = useState(false);
    const [resultsPerPage, setResultsPerPage] = useState(_.get(savedState, "resultsPerPage", defaultPageSize));
    const [currentPage, setCurrentPage] = useState(_.get(savedState, "pageIndex", 0) + 1);

    const filteredBillers = useMemo(() => (
        filter.childMerchantNumber
            ? _.filter(billers, { merchantNumber: filter.childMerchantNumber })
            : billers
    ), [billers, filter.childMerchantNumber]);

    // On page load, fetch things from API, get requests and restore previous state (page number, scroll position, filters) if any
    useEffect(() => {
        const refDataRequests = [
            actions.getRequestStatuses(),
            actions.getRequestActions(),
        ];

        Promise.all(refDataRequests).then(() => {
            if (savedState && savedState.scrollPosition)
                windowUtil.scrollTo(savedState.scrollPosition); // Scroll back to previous position
        });

        if (savedState && !_.isEmpty(savedState))
            handleFilterChange(savedState.filter, false);
        else
            handleFilterChange(defaultFilter);
    }, [actions]);

    useEffect(() => {
        if (reload) {
            const pageIndex = currentPage -1;
            actions.saveRequestPageState({ resultsPerPage, pageIndex, filter, sort });
            actions.search(resultsPerPage, pageIndex, filter, sort);
            setReload(false);
        }
    }, [reload]);

    //#region ========== Filter stuff ==========

    const defaultFilter: Record<string, any> = {
        action: "",
        amount: "",
        amountRange: { min: "", max: "" },
        billerCode: "",
        childMerchantNumber: "",
        customerEmailAddress: "",
        customerName: "",
        customerIdentifier1: "",
        customerIdentifier2: "",
        customerIdentifier3: "",
        customerPhoneNumber: "",
        customerUniqueCode: "",
        createdDateRange: [],
        deliveryReference: "",
        dueDateRange: [],
        reference1: "",
        reference2: "",
        reference3: "",
        status: "",
    };

    const filterCount = useMemo(() => {
        let count = 0;

        Object.keys(defaultFilter).forEach(key => {
            if (!_.isEqual(filter[key], defaultFilter[key])) {
                count++;
            }
        });

        return count;
    }, [filter]);

    function handleFilterChange(newFilter: any, resetPage = true) {  // Note: This is also called at first render (from either useEffect or DateRangePresetField completing the dates)
        if (resetPage)
            setCurrentPage(1);

        setFilter(newFilter);
        setLastSavedFilter(newFilter);
        setReload(true);
    }
    //#endregion

    function handleSort(field: string, descending: boolean) {
        setSort({ field, descending });
        setReload(true);
    }

    function pageChanged(newPage: number) {
        setCurrentPage(newPage);
        setReload(true);
    }

    function resultsPerPageChanged(newResultsPerPage: number) {
        setResultsPerPage(newResultsPerPage);
        setCurrentPage(1);
        setReload(true);
    }

    if (pageIsLoading)
        return (
            <CardContainer header="Loading requests">
                <LoadingIndicator />
            </CardContainer>
        );

    // ========== Render ==========
    return (<>
        <Form initialValues={(savedState && savedState.filter) || defaultFilter}>
            <PageSection className={classNames("payment-requests-page", "full-width", { "hidden": showFilter })} aria-hidden={showFilter}>
                <PageHeader title={<>{"Requests"}<ProductTooltip productTooltipModule={"MANAGE_REQUESTS"} /></>} />

                <Button subtle onClick={() => { setShowFilter(true); }}>View filters ({filterCount} applied)</Button>

                <div className="row dropdowns">
                    {(merchant?.childMerchants?.length ?? 0) > 0 && (
                        <div className="col-lg-6 col-xl-5 filter-container merchant-filter-container inline-label">
                            <DropdownField name="childMerchantNumber" label={labels.merchant}
                                options={billpayUtil.getMerchantOptions(merchant, true, userRoles.paymentRequest)}
                                onChange={(val, context) => handleFilterChange(context.values)}
                            />
                        </div>
                    )}

                    <div className="col-lg-6 filter-container results-filter-container inline-label">
                        <DropdownField id="pageSize" name="pageSize" label="Items per page" aria-labelledby="pageSize_label"
                            onChange={resultsPerPageChanged}
                            options={defaultPageSizeOptions.map((result: number) => ({ value: result, label: result }))}
                            defaultValue={resultsPerPage}
                        />
                    </div>
                </div>

                <FormError errors={errors} />

                <PaymentRequestList
                    // For the quick filter
                    savedFilter={savedState && savedState.filter}
                    onFilterChange={handleFilterChange}
                    // For the table/list
                    sort={sort}
                    onSort={handleSort}
                    requests={searchResult}
                    requestStatusOptions={uiUtil.generateOptions(requestStatuses)}
                    requestActionOptions={uiUtil.generateOptions(requestActions)}
                    isLoading={searchIsLoading}
                />

                <PaginationControl currentPage={currentPage} onPageChanged={pageChanged} itemCount={requestCount} itemsPerPage={resultsPerPage} />

                <BackToTop />
            </PageSection>

            <PaymentRequestFilter
                show={showFilter}
                onHide={() => setShowFilter(false)}
                defaultFilter={defaultFilter}
                lastSavedFilter={lastSavedFilter}
                onChange={handleFilterChange}
                billers={filteredBillers}
                requestStatuses={requestStatuses}
                requestActions={requestActions}
            />
        </Form>
    </>);
};

function mapStateToProps(state: RootState) {
    const searchResult = state.paymentRequest.searchResult;
    const requestStatuses = state.paymentRequest.requestStatuses;
    const requestActions = state.paymentRequest.requestActions;
    const pageIsLoading = searchResult.isInitial;

    return {
        pageIsLoading,
        savedState: state.paymentRequest.requestsPageState,
        searchResult: searchResult?.data?.requests ?? [],
        searchIsLoading: searchResult.isLoading,
        requestCount: searchResult?.data?.resultCount ?? 0,
        requestStatuses: requestStatuses.data,
        requestActions: requestActions.data,
        merchant: state.accounts.users.merchant,
        childMerchants: state.accounts.users.merchant.childMerchants,
        billers: state.accounts.users.activeBillers,
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        actions: bindActionCreators(requestActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManagePaymentRequestsPage);
