import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Dropdown } from '@premier/ui';
import FormGroup from './FormGroup';
import withField from './withField';

const DropdownField = ({
    name, id,
    options, // DropdownField specific props
    formProps, groupProps,
    ...otherProps
}) => {

    const [elementId] = useState(id || _.uniqueId(`${name}_`));

    function handleChange(option) {
        formProps.setValue(option.value);
    }

    return (
        <FormGroup fieldId={elementId} {...groupProps}>
            <Dropdown
                id={elementId}
                options={options}
                onChange={handleChange}
                value={formProps.value}
                error={formProps.error}
                aria-labelledby={groupProps.label && `${elementId}_label`}
                aria-required={groupProps.mandatory}
                aria-invalid={!!formProps.error}
                aria-describedby={formProps.error && `${elementId}_error`}
                {...otherProps}
            />
        </FormGroup>
    );
};

DropdownField.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            disabled: PropTypes.bool
        })
    ).isRequired,

    name: PropTypes.string,

    /** A more compact version of the dropdown, reducing the width, but keeping the height */
    compact: PropTypes.bool,
    /** The text displayed when no option is selected (Default = Select...) */
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    isSearchable: PropTypes.bool,
    /** Default = false */
    isClearable: PropTypes.bool,

    // Events
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
};

export default withField(DropdownField);
