import { useState, useEffect } from 'react';
import { PageSection, SuccessModal } from '@premier/ui';
import { PageHeader, useApiCallDeprecated, APICallRequestState, Divider } from 'components/Common';
import { useLocation } from 'react-router-dom'
import { PaymentPlanDetailsRequest } from '@premier/webapi-client'
import { LoadingIndicator, DescriptionList, Button } from '@premier/ui';
import { paymentPlanApi, tokenApi, billerApi, scheduledPaymentsApi } from 'api';
import { PaymentPlanStatusIcon, PaymentPlanSummaryTable, InstalmentCollectionSummaryTable, PaymentPlanCancelDialog, ScheduledPaymentList } from 'components/Transactions'
import { LinkedTokensList } from 'components/DataVault';
// @ts-ignore
import labels from 'constants/labels';
import dateUtil from '@premier/utils/date';

enum ComponentState {
    READY,
    SHOW_CANCEL_MODAL,
    SHOW_CANCEL_SUCCESS_MODAL,
    CONFIRM_CANCEL
}

const ViewPaymentPlanDetailsPage : React.FC = () => {
    const location = useLocation();
    const [ paymentPlanDetailsRequest, setPaymentPlanDetailsRequest ] = useState<PaymentPlanDetailsRequest | null>();
    const [ componentState, setComponentState ] = useState<ComponentState>(ComponentState.READY);

    // Set up request from search params to initiate retrieval of payment plan
    useEffect(() => {
        if (!paymentPlanDetailsRequest) {
            const searchParams = new URLSearchParams(location.search);
            setPaymentPlanDetailsRequest({
                paymentPlanId: parseInt(searchParams.get("paymentPlanId") ?? ""),
                crn1: searchParams.get("crn1") ?? "",
                crn2: searchParams.get("crn2") ?? "",
                crn3: searchParams.get("crn3") ?? "",
                billerCode: searchParams.get("billerCode") ?? "",
            });
        }
    }, [paymentPlanDetailsRequest, location.search]);

    // Get payment plan details with the above request
    const [ paymentPlanDetails, paymentPlanDetailsStatus ] = useApiCallDeprecated(() => {
        if (paymentPlanDetailsRequest) {
            // @ts-ignore
            return paymentPlanApi.getPaymentPlanDetails(paymentPlanDetailsRequest);
        }
    }, [paymentPlanDetailsRequest]);

    const [ biller, billerStatus ] = useApiCallDeprecated(async () => {
        if (paymentPlanDetailsRequest?.billerCode) {
            // This API call was originally designed for Redux, enforce the return type for Axios so useApiCallDeprecated is compatible
            // TODO Move legacy Redux API call resolution into useApi
            const dto = await billerApi.getBiller(paymentPlanDetailsRequest.billerCode);
            return  { data: { data: dto?.val }, status: dto?.ok ? 200 : 400 };
        }
    }, [ paymentPlanDetailsRequest ]);

    // paymentPlanDetails can contain the token id if it is a recurring payment
    const [ token, tokenStatus ] = useApiCallDeprecated(async () => {
        if (paymentPlanDetails?.subscription?.dataVaultId) {
            // This API call was originally designed for Redux, enforce the return type for Axios so useApiCallDeprecated is compatible
            // TODO Move legacy Redux API call resolution into useApi
            const dto = await tokenApi.getToken(paymentPlanDetails.subscription.dataVaultId);
            return { data: { data: dto }, status: 200 }
        }
    }, [paymentPlanDetails]);

    useApiCallDeprecated(async () => {
        if (componentState === ComponentState.CONFIRM_CANCEL) {
            // @ts-ignore
            const response = await paymentPlanApi.cancelPaymentPlan({...paymentPlanDetailsRequest});
            setComponentState(ComponentState.SHOW_CANCEL_SUCCESS_MODAL);
            return response;
        }
    }, [componentState]);

    // Set up the instalment payments data to be viewed
    const [instalments, instalmentsStatus] = useApiCallDeprecated(async () => {
        if (paymentPlanDetailsStatus === APICallRequestState.SUCCESSFUL) {
            if (paymentPlanDetails?.subscription?.recurringScheduleId) {
                const dto = await scheduledPaymentsApi.getScheduledPayments(3, 0,
                    { subscriptionId: paymentPlanDetails.subscription.recurringScheduleId },
                    { field: 'scheduledPaymentDate', descending: true }
                );

                // This means there have only been less than 3 recurring instalments collected so display the initial payment.
                if (dto?.scheduledPayments?.length < 3) {
                    dto.scheduledPayments.push(paymentPlanDetails.initialPayment);
                }

                return { data: { data: dto }, status: 200 };
            } else {
                // This is a one off
                return { data: { data: { scheduledPayments: paymentPlanDetails.initialPayment ? [paymentPlanDetails.initialPayment] : [] } }, status: 200 };
            }
        }
    }, [paymentPlanDetails, paymentPlanDetailsStatus]);

    return (
        <PageSection noDivider>
                { paymentPlanDetailsStatus === APICallRequestState.SUCCESSFUL ?
                <>
                    <PageHeader
                        title={paymentPlanDetails?.status ? paymentPlanDetails.status[0] + paymentPlanDetails.status.substring(1).toLowerCase() : "None"}
                        icon={<PaymentPlanStatusIcon status={paymentPlanDetails?.status ?? "NONE"} />}
                        subtitle={<>
                            <DescriptionList greyLabel items={[
                                {name: labels.createdTime + ':', value: paymentPlanDetails?.createdOn ? dateUtil.convertToDateTimeString(paymentPlanDetails.createdOn) : ""},
                                {name: labels.updatedTime + ':', value: paymentPlanDetails?.lastUpdatedOn ? dateUtil.convertToDateTimeString(paymentPlanDetails.lastUpdatedOn) : ""},
                                {name: labels.userNameUpdated + ':', value: "Customer"}, // TODO: Awaiting Payment Plan Update flow
                            ]} />
                        </>}
                        backButton
                    />

                    <PaymentPlanSummaryTable
                        paymentPlanDetails={paymentPlanDetails}
                        crn1={paymentPlanDetailsRequest?.crn1}
                        crn2={paymentPlanDetailsRequest?.crn2}
                        crn3={paymentPlanDetailsRequest?.crn3}
                    />

                    <InstalmentCollectionSummaryTable
                        subscription={paymentPlanDetails?.subscription}
                        status={paymentPlanDetails?.status}
                        firstPaymentAmount={paymentPlanDetails?.initialPayment?.amountPaid}
                    />

                    <h2>Payment method</h2>
                    <LinkedTokensList
                        tokens={tokenStatus === APICallRequestState.SUCCESSFUL ? [token] : []}
                    />
                    <h2>Recent instalment payments</h2>
                    <p>The following is showing up to three recent instalment payments.</p>
                    <ScheduledPaymentList payments={instalmentsStatus === APICallRequestState.SUCCESSFUL ? instalments?.scheduledPayments : []} hideAttemptsColumn />
                    <Divider className="divider"/>
                    {
                        !(paymentPlanDetails.status === 'COMPLETED' || paymentPlanDetails.status === 'CANCELLED') ?
                            <Button link onClick= {()=>{ setComponentState(ComponentState.SHOW_CANCEL_MODAL) }}>Cancel plan</Button> : null
                    }

                    <PaymentPlanCancelDialog
                        show={componentState === ComponentState.SHOW_CANCEL_MODAL}
                        closeButton
                        onClose={() => { setComponentState(ComponentState.READY);}}
                        footerButtons={<>
                            <Button onClick={() => setComponentState(ComponentState.CONFIRM_CANCEL)}>Cancel plan</Button>
                            <Button onClick={() => setComponentState(ComponentState.READY)}>Close</Button>
                        </>}

                        crnLabel={billerStatus === APICallRequestState.SUCCESSFUL ? biller?.acceptedCrn1?.label : "Reference"}
                        crn1={paymentPlanDetailsRequest?.crn1 ?? ""}
                        originalTotalAmount={paymentPlanDetails?.totalAmountToPay ?? 0}
                        paymentPlanOptionName={paymentPlanDetails?.paymentPlanCommitment?.paymentPlanOptionName ?? ""}
                        totalAmountPaid={(paymentPlanDetails?.subscription?.amountCollected ?? 0) + (paymentPlanDetails?.initialPayment?.amountPaid ?? 0)}
                    />
                    <SuccessModal
                        show={componentState === ComponentState.SHOW_CANCEL_SUCCESS_MODAL}
                        title={`Payment plan cancelled successfully!`}
                        onClose={() => { setComponentState(ComponentState.READY); setPaymentPlanDetailsRequest(null); }} // Force reload
                    />
                </> : <LoadingIndicator />}
        </PageSection>
    )
}

export default ViewPaymentPlanDetailsPage;