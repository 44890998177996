import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useCurrentBreakpointName } from 'react-socks';

import { useDebounce } from 'components/Common';
import { ResponsiveTable } from '@premier/ui';
import { FormContext, InputField, MaskedCardNumberField, DropdownField } from '@premier/form';
import labels from 'constants/labels';

import { PlatformRoutesConfiguration } from 'components/Routing';

const ScheduleList = ({
    savedFilter, onFilterChange,
    sort, onSort,
    schedules,
    scheduleStatusOptions,
    isLoading
}) => {

    const context = React.useContext(FormContext);

    // Update quick filter values from Redux
    useEffect(() => {
        if(savedFilter)
            context.setFormValues(savedFilter);
    }, [savedFilter]);

    /** Triggers onFilterChange (ie. API call) on the parent */
    function handleQuickFilterChange(values) {
        onFilterChange(values || context.values);  // context.values may not be updated yet, pass values if needed
    }
    const debouncedFilterChange = useDebounce(handleQuickFilterChange);

    var isTableView = ['md','lg','xl'].includes(useCurrentBreakpointName());

    // The quick filter fields
    const fields = {
        scheduleStatus: (
            <DropdownField
                name='scheduleStatus'
                options={scheduleStatusOptions}
                onChange={() => handleQuickFilterChange()}
                compact={isTableView}
                aria-label={labels.status}
            />
        ),
        customerUniqueCode: (
            <InputField
                name='customerUniqueCode'
                onChange={val => debouncedFilterChange({ ...context.values, customerUniqueCode: val })}
                aria-label={labels.customerCode}
            />
        ),
        reference1: (
            <InputField
                name='reference1'
                onChange={val => debouncedFilterChange({ ...context.values, reference1: val })}
                aria-label={labels.reference1}
            />
        ),
        tokenReference1: (
            <InputField
                name='tokenReference1'
                onChange={val => debouncedFilterChange({ ...context.values, tokenReference1: val })}
                aria-label={labels.tokenReference1}
            />
        ),
        maskedCardNumber: (
            <MaskedCardNumberField
                name='maskedCardNumber'
                onChange={val => debouncedFilterChange({ ...context.values, maskedCardNumber: val })}
                aria-label={labels.maskedCardNumber}
            />
        ),
        deAccountNumber: (
            <InputField
                name='deAccountNumber'
                onChange={val => debouncedFilterChange({ ...context.values, deAccountNumber: val })}
                aria-label={labels.bankAccountNumber}
            />
        )
    };

    // The table columns (or list items)
    const columns = [{
        label: labels.status,
        sortKey: 'subscriptionStatus.description',
        getter: s => s.subscriptionStatus.description,
        filter: fields.scheduleStatus
    }, {
        label: labels.customerCode,
        getter: 'customerUniqueCode',
        sortKey: 'customerUniqueCode',
        filter: fields.customerUniqueCode
    }, {
        label: labels.reference1,
        showAsLink: true,
        getter: 'reference1',
        sortKey: 'reference1',
        filter: fields.reference1
    }, {
        label: labels.tokenReference1,
        getter: 'tokenReference1',
        sortKey: 'tokenReference1',
        filter: fields.tokenReference1
    }, {
        label: labels.maskedCardNumber,
        getter: 'maskedCardNumber',
        sortKey: 'maskedCardNumber',
        filter: fields.maskedCardNumber
    }, {
        label: labels.bankAccountNumber,
        getter: 'deAccountNumber',
        sortKey: 'deAccountNumber',
        filter: fields.deAccountNumber
    }];

    return (<>
        <ResponsiveTable
            data={schedules}
            className='schedule-list'
            isLoading={isLoading}
            columns={columns}
            sort={sort}
            onSort={onSort}
            getRowLink={c => PlatformRoutesConfiguration.transactionRoute.scheduleDetails.generatePath(c.recurringScheduleId)}
            noDataText='No subscriptions to display.'
        />
    </>);
};


ScheduleList.propTypes = {
    /** The saved filter from Redux (used only to update Form values when it's updated in Redux) */
    savedFilter: PropTypes.object,
    onFilterChange: PropTypes.func,
    /** The current sort order */
    sort: PropTypes.shape({
        field: PropTypes.string,
        descending: PropTypes.bool,
    }),
    /** to handle sort field/order changes */
    onSort: PropTypes.func,

    schedules: PropTypes.array,
    scheduleStatusOptions: PropTypes.array,
    isLoading: PropTypes.bool,
};

export default ScheduleList;