import { useEffect, useState } from 'react';
import { Dropdown, UiOption } from '@premier/ui';
import { FormGroup } from '@premier/ui';
import { FieldAccess, combine, notNull, replaceLabel } from '@premier/ts-form';
import { uniqueId } from '@premier/utils/helpers';

export const DropdownField = <T extends string | number>({
    field,
    options, // DropdownField specific props
    defaultValue,
    label,
    mandatory
}: {
    field: FieldAccess<T | undefined>
    options: UiOption<T>[]
    defaultValue?: UiOption<T> | T
    label: string
    mandatory?: boolean
}) => {

    const { value, fieldError, setValue, register, validate } = field;
    useEffect(() => register({
        label: label,
        validation: { self: combine(
            mandatory && notNull(),
        )}
    }), [register, label, mandatory]);

    const [elementId] = useState(uniqueId('dropdown_'));

    function handleChange(option: UiOption<T> | null) {
        setValue(() => option?.value);
        validate();
    }

    return (
        <FormGroup fieldId={elementId} label={label} mandatory={mandatory} error={replaceLabel(fieldError?.message, label)}>
            <Dropdown
                id={elementId}
                options={options}
                onChange={handleChange}
                value={value}
                defaultValue={defaultValue}
                error={!!fieldError?.message}
                aria-labelledby={`${elementId}_label`}
                aria-required={mandatory}
                aria-invalid={!!fieldError?.message}
                aria-describedby={!!fieldError?.message && `${elementId}_error`}
            />
        </FormGroup>
    );
};
