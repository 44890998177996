import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';

import store from '../../../store/store';
import * as actions from 'components/StationeryShop/_actions/stationeryShopActions';

const withHydrateProductList = (
    WrappedComponent,
    getSelectedProductLocation
) => {
    const HydrateComponent = props => {
        var [selectedProducts, setSelectedProducts] = useState([]);

        useEffect(() => {
            handleStoreChanged();
            //subscription to redux store.
            return store.subscribe(handleStoreChanged);
        }, []);

        function handleStoreChanged() {
            var val = getSelectedProductLocation(store.getState());
            if (val !== selectedProducts) {
                setSelectedProducts(val);
            }
        }

        function doMapping(selectedProducts, allProducts, freightProduct) {
            let results = [];
            if (!selectedProducts || !allProducts || !freightProduct)
                return results;

            //Add freight item so only one array to search for everything
            allProducts.push(freightProduct);

            selectedProducts.forEach(i => {
                var item = allProducts.find(x => x.productId === i.productId);
                if (item) {
                    results.push({
                        imagePath: item.productImage,
                        itemName: item.productName,
                        productId: i.productId,
                        productCode: item.productCode,
                        quantity: i.qty,
                        unitCost: i.unitCost || item.itemCost,
                        amountPaid: i.amountPaid || (i.qty * item.itemCost),
                    });
                }
            });
            return results;
        }

        useEffect(() => {
            if (!props.freightProduct)
                props.actions.getStationeryFreightFee();

            if (!props.allProducts)
                props.actions.getStationeryProducts(0);
        }, []);

        return (
            <WrappedComponent
                products={doMapping(
                    selectedProducts,
                    props.allProducts,
                    props.freightProduct
                )}
                {...props}
            ></WrappedComponent>
        );
    };

    function mapStateToProps(state, ownProps) {
        return {
            freightProduct: state.stationeryShop.shop.freightProduct,
            allProducts: _.get(
                state,
                'stationeryShop.shop.stationeryProducts.products'),
            state: state,
        };
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(actions, dispatch),
        };
    }

    return connect(
        mapStateToProps,
        mapDispatchToProps
    )(HydrateComponent);
};

export default withHydrateProductList;
