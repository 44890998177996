import { useState } from "react";
import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import { Navigate } from "react-router-dom";
import _ from "lodash";

import { MethodDialog, ProcessNowDialog, ScheduleDialog, FileWillUploadModal, UploadErrorDialog, UploadWarningDialog, UploadSuccessModal } from "components/Batch";
import dateUtil from "@premier/utils/date";

import { PlatformRoutesConfiguration } from "components/Routing";
import * as batchActions from "components/Batch/_actions/batchActions";
import { RootState } from "store/store";
import { BatchFileResult, BatchFileValidationResult } from "components/Batch/models/BatchFileResult";

const Step = {  // One of these dialogs will be visible at a time
    METHOD: "method",
    PROCESS_NOW: "processNow",
    SCHEDULE: "schedule",
    UPLOADING: "uploading",
    ERROR: "error",
    WARNING: "warning",
    SUCCESS: "success",
};

type Props = {
    show: boolean;
    actions: any;
    onClose: () => void;
}

/** The New Batch File flow that consists of several Dialogs + file picker */
const NewBatchFileDialogs = ({
    show, //logic render
    actions, //actions
    onClose //function
}: Props) => {

    const [step, setStep] = useState(Step.METHOD);
    const [redirectToManualEntry, setRedirectToManualEntry] = useState(false);
    const [files, setFiles] = useState<FileList>();
    const [reupload, setReupload] = useState(false);
    const [addToLibrary, setAddToLibrary] = useState(false);
    const [showUploadingModal, setShowUploadingModal] = useState(false);
    const [uploadResult, setUploadResult] = useState<[any, BatchFileValidationResult[] | null]>([null, null]);  // [genericErrors, batchFileResult]


    function reset() {
        setStep(Step.METHOD);
        setFiles(undefined);
        setAddToLibrary(false);
    }
    function handleCancel() {
        reset();
        onClose();
    }

    function handleFileUpload(files: FileList) {
        setFiles(files);
        setStep(Step.PROCESS_NOW);
    }

    function getFilename() {
        return files && files.length > 0 ? files[0].name : "";
    }

    function handleComplete(date: Date | null, addToLibrary: boolean) {
        if (files) {
            setAddToLibrary(addToLibrary);
            setStep(Step.UPLOADING);
            setShowUploadingModal(true);
            startFileUpload(files, date, addToLibrary);
        }
    }

    function startFileUpload(files: FileList, processDate: Date | null, addToLibrary: boolean) {
        setUploadResult([null, null]);
        // scheduled batch files should be processed at 1am
        const processDateApiValue = processDate ? dateUtil.toUtcApiDateTime(dateUtil.localToMerchantTimeZone(processDate).startOf("day").hour(1)) : null;

        if (addToLibrary)
            uploadToBatchLibrary(files, processDateApiValue);
        else
            uploadAsBatchJob(files, processDateApiValue);
    }

    function uploadAsBatchJob(files: FileList, processDate: string | null) {
        actions.uploadAsBatchJob(files, { scheduledDateTime: processDate }).then((response: BatchFileResult) => {
            if (response.hasErrors) {
                setUploadResult([null, response.batchFileValidationResult]);
                setStep(Step.ERROR);
            }
            else if (response.hasWarnings) {
                setUploadResult([null, response.batchFileValidationResult]);
                setStep(Step.WARNING);
            }
            else {
                setStep(Step.SUCCESS);
            }
        }).catch((errors: any) => {
            setUploadResult([errors, null]);
            setStep(Step.ERROR);
        });
    }

    function uploadToBatchLibrary(files: FileList, processDate: string | null) {
        actions.uploadToBatchLibrary(files, true, processDate).then((response: BatchFileResult) => {
            if (response.hasErrors) {
                setUploadResult([null, response.batchFileValidationResult]);
                setStep(Step.ERROR);
            }
            else if (response.hasWarnings) {
                setUploadResult([null, response.batchFileValidationResult]);
                setStep(Step.WARNING);
            }
            else {
                setStep(Step.SUCCESS);
            }
        }).catch((errors: any) => {
            setUploadResult([errors, null]);
            setStep(Step.ERROR);
        });
    }

    function handleRetry() {
        reset();
        setReupload(true);  // Open file select dialog
        setTimeout(() => setReupload(false), 0);
    }

    function handleOnNow(addToLibrary: boolean) {
        handleComplete(null, addToLibrary);
    }

    function handleOnLater(addToLibrary: boolean) {
        setAddToLibrary(addToLibrary);
        setStep(Step.SCHEDULE);
    }

    function handleOnSchedule(date: Date) {
        handleComplete(date, addToLibrary);
    }

    if (redirectToManualEntry)
        return <Navigate to={PlatformRoutesConfiguration.transactionRoute!.batchAddRecords.generatePath()} />;

    // TODO: Either prompt user if they leave page while uploading OR save upload result in Redux

    return (<>
        <MethodDialog show={show && step === Step.METHOD}
            onCancel={onClose}
            onManual={() => setRedirectToManualEntry(true)}
            onUpload={handleFileUpload}
            uploadNow={reupload}
        />

        <ProcessNowDialog show={show && step === Step.PROCESS_NOW}
            filenames={[getFilename()]}
            onClose={handleCancel}
            onNow={handleOnNow}
            onLater={handleOnLater}
        />

        <ScheduleDialog show={show && step === Step.SCHEDULE}
            filenames={[getFilename()]}
            onCancel={handleCancel}
            onSchedule={handleOnSchedule}
        />

        <FileWillUploadModal show={show && step === Step.UPLOADING && showUploadingModal}
            filename={getFilename()}
            onHide={() => setShowUploadingModal(false)}
            addToLibrary={addToLibrary}
        />

        <UploadErrorDialog show={show && step === Step.ERROR}
            filename={getFilename()}
            errors={uploadResult[0]}
            batchFileResult={uploadResult[1]}
            onRetry={handleRetry}
            onCancel={handleCancel}
        />

        <UploadWarningDialog show={show && step === Step.WARNING}
            filename={getFilename()}
            warnings={uploadResult[0]}
            batchFileResult={uploadResult[1]}
            onClose={handleCancel}
        />

        <UploadSuccessModal show={show && step === Step.SUCCESS}
            filename={getFilename()}
            onHide={handleCancel}
            addToLibrary={addToLibrary}
        />

    </>);
};

function mapStateToProps(state: RootState) {
    return {
        allowLibrary: _.get(state, "accounts.users.authenticatedUser.features", []).includes("BATCH_MANAGER"),
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        actions: bindActionCreators(batchActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(NewBatchFileDialogs);
