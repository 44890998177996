import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';

import { Dialog, Button, ButtonContainer } from '@premier/ui';
import { Form, FormErrorList, SubmitButton, InputField, DatePickerField, RadioField, validate } from '@premier/form';

import labels from 'constants/labels';
import errorMaps from 'constants/errorMaps';

import './SuspendConfirmDialog.scss';

const SuspendConfirmDialog = ({
    name, errors, //data
    show, //logic renders
    onConfirm, onCancel, //functions
}) => {

    const FOR_PAYMENTS = 'FOR_PAYMENTS';
    const END_DATE = 'END_DATE';

    const suspendOptions = [{
        value: FOR_PAYMENTS,
        label: (<>
            <span>For</span>
            <InputField
                className='number-of-payments'
                name='numberOfPayments'
                labelText={labels.numberOfPayments}
                placeholder='e.g. 5' onChange={handleNumberOfPaymentsChanged}
            />
            <span>payments</span>
        </>)
    }, {
        value: END_DATE,
        label: (<>
            <span>Until</span>
            <DatePickerField
                className='end-date'
                name='endDate'
                labelText={labels.endDate}
                minDate={moment().add(2,'days').startOf('day').toDate()}
                onChange={handleEndDateChanged}
            />
        </>)
    }];

    const suspendLengthFieldName = `${name || 'ScheduleSuspendForm'}_suspendLength`;

    function handleSuspendChanged(selectedOption, context) {
        switch (selectedOption) {
            case FOR_PAYMENTS:
                context.setValue('endDate', null);
                break;
            case END_DATE:
                context.setValue('numberOfPayments', null);
                break;
            default:
                context.setValue('endDate', null);
                context.setValue('numberOfPayments', null);
                break;
        }
    }

    function handleNumberOfPaymentsChanged(newValue, context) {
        if (newValue && !_.isEmpty(newValue)) {
            context.setValue(suspendLengthFieldName, FOR_PAYMENTS);
            context.setValue('endDate', null);
        }
    }

    function handleEndDateChanged(newValue, context) {
        if (newValue && !_.isEmpty(newValue)) {
            context.setValue(suspendLengthFieldName, END_DATE);
            context.setValue('numberOfPayments', null);
        }
    }

    return (
        <Dialog
            show={show}
            closeButton
            title='Suspend subscription'
            dialogClassName='subscription-suspend-dialog'
            onClose={onCancel}
        >
            Please select the duration below
            <Form
                name={name || 'ScheduleSuspendForm'}
                initialValues={{[suspendLengthFieldName]: FOR_PAYMENTS}}
                initialValidation={{
                    endDate: validate().requiredIf((values) => values[suspendLengthFieldName] === END_DATE),
                    numberOfPayments: validate().requiredIf((values) => values[suspendLengthFieldName] === FOR_PAYMENTS),
                }}
                errors={errors}
                errorMaps={errorMaps}
                onSubmit={onConfirm}
            >
                <RadioField
                        name={suspendLengthFieldName}
                        label={labels.suspendSubscription}
                        options={suspendOptions}
                        onChange={handleSuspendChanged}
                />

                <FormErrorList />

                <ButtonContainer>
                    <SubmitButton>Suspend</SubmitButton>
                    <Button onClick={onCancel}>Cancel</Button>
                </ButtonContainer>
            </Form>
        </Dialog>
    );
}

SuspendConfirmDialog.propTypes = {
    /** property for the name of the form */
    name: PropTypes.string,
    /** property to show or hide the dialog */
    show: PropTypes.bool,
    /** property for errors returned from the API */
    errors: PropTypes.arrayOf(PropTypes.shape({
        message: PropTypes.string,
        field: PropTypes.string,
        code: PropTypes.string
    })),

    /** function called when user clicks the Confirm/Submit button */
    onConfirm: PropTypes.func,
    /** function called when user clicks the Cancel/Close button */
    onCancel: PropTypes.func,
};

export default SuspendConfirmDialog;