import React from 'react';
import PropTypes from 'prop-types';

import { Dialog, Button } from '@premier/ui';
import { FilenameSubheading } from 'components/Batch';

const AppendFileConfirmationDialog = ({
    show, //render 
    batchLibraryFilename, filename, //data
    onYes, onCancel //functions
}) => {
    return (
        <Dialog show={show}
            title='Upload this batch file?'
            footerButtons={<>
                <Button primary onClick={onYes}>Yes</Button>
                <Button onClick={onCancel}>Cancel</Button>
            </>}
        >
            <FilenameSubheading filenames={[filename]} />

        <p>This file will be appended to <b>{batchLibraryFilename}</b> after it is uploaded.</p>
            
        </Dialog>
    );
};

AppendFileConfirmationDialog.propTypes = {
    show: PropTypes.bool.isRequired,

    /** The existing batch library file we're appending the uploaded file into */
    batchLibraryFilename: PropTypes.string.isRequired,
    /** The file being uploaded */
    filename: PropTypes.string.isRequired,
    onYes: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default AppendFileConfirmationDialog;