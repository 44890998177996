import React from 'react';
import { Dialog, SuccessModal, Button, Icon } from '@premier/ui';
import { FormContext } from '@premier/form';
import { BusinessDetailsModalState } from '../../pages/BusinessDetailsSettings/BusinessDetailsSettingsBody';

export const businessDetailsDialogCopy = {
    savedSuccessTitle: 'Business details saved successfully!',
    unsavedChangesTitle: 'Changes to business details are not saved',
    unsavedChangesDescription:
        'Leaving this page without saving will discard your unsaved changes.',
    errorTitle: 'Error with saving business details',
    errorDescription:
        'We are unable to save the business details now. Please try again.',
    button: {
        continue: 'Continue editing',
        discard: 'Discard changes',
        saveAgain: 'Save again',
        cancel: 'Cancel',
    },
};

type Props = {
    formName: string,
    modalState: BusinessDetailsModalState;
    setModalState: (state: BusinessDetailsModalState) => void;
    handleBack: () => void;
    handleOnSaveClose: () => void;
    handleSubmit: (values: any, ctx: any) => void;
};

const BusinessDetailsDialog: React.FC<Props> = ({
    formName,
    modalState,
    setModalState,
    handleBack,
    handleOnSaveClose,
    handleSubmit
}) => {

    const context = React.useContext(FormContext);
    const value = context.values[formName];

    return (
        <>
            <SuccessModal
                onClose={handleOnSaveClose}
                show={modalState === 'SAVED_SUCCESS'}
                title={businessDetailsDialogCopy.savedSuccessTitle}
            />

            <Dialog
                closeButton
                icon={<Icon alert />}
                onClose={() => setModalState('')}
                show={modalState === 'UNSAVED_CHANGES'}
                title={businessDetailsDialogCopy.unsavedChangesTitle}
                footerButtons={
                    <>
                        <Button primary onClick={() => setModalState('')}>
                            {businessDetailsDialogCopy.button.continue}
                        </Button>
                        <Button onClick={handleBack}>
                            {businessDetailsDialogCopy.button.discard}
                        </Button>
                    </>
                }
            >
                <p>{businessDetailsDialogCopy.unsavedChangesDescription}</p>
            </Dialog>

            <Dialog
                closeButton
                icon={<Icon alert />}
                onClose={() => setModalState('')}
                show={modalState === 'SAVED_ERROR'}
                title={businessDetailsDialogCopy.errorTitle}
                footerButtons={
                    <>
                        <Button primary onClick={() => handleSubmit(value, context)}>
                            {businessDetailsDialogCopy.button.saveAgain}
                        </Button>
                        <Button onClick={() => setModalState('')}>
                            {businessDetailsDialogCopy.button.cancel}
                        </Button>
                    </>
                }
            >
                <p>{businessDetailsDialogCopy.errorDescription}</p>
            </Dialog>
        </>
    );
};

export default BusinessDetailsDialog;
