import { Currency } from "./models";

/* This array below comes from this query:
 *
 * SELECT intCurrencyCode AS Code,
 *        vchAlphaCode AS AlphaCode,
 *        intDigitsAfterDecimal AS DecimalPlaces,
 *        vchName AS Name,
 *        vchSymbolMajor AS Symbol
 * FROM billpay.tbl_BP_Currencies WITH (NOLOCK)
 */

const currencies: Currency[] = [
    {
        "code": 8,
        "alphaCode": "ALL",
        "decimalPlaces": 2,
        "name": "Albanian Lek"
    },
    {
        "code": 12,
        "alphaCode": "DZD",
        "decimalPlaces": 2,
        "name": "Algerian Dinar"
    },
    {
        "code": 32,
        "alphaCode": "ARS",
        "decimalPlaces": 2,
        "name": "Argentine Peso"
    },
    {
        "code": 36,
        "alphaCode": "AUD",
        "decimalPlaces": 2,
        "name": "Australian Dollar",
        "symbol":"$"
    },
    {
        "code": 44,
        "alphaCode": "BSD",
        "decimalPlaces": 2,
        "name": "Bahamian Dollar"
    },
    {
        "code": 48,
        "alphaCode": "BHD",
        "decimalPlaces": 3,
        "name": "Bahraini Dinar"
    },
    {
        "code": 50,
        "alphaCode": "BDT",
        "decimalPlaces": 2,
        "name": "Bangladeshi Taka"
    },
    {
        "code": 51,
        "alphaCode": "AMD",
        "decimalPlaces": 2,
        "name": "Armenian Dram"
    },
    {
        "code": 52,
        "alphaCode": "BBD",
        "decimalPlaces": 2,
        "name": "Barbados Dollar"
    },
    {
        "code": 60,
        "alphaCode": "BMD",
        "decimalPlaces": 2,
        "name": "Bermudian Dollar"
    },
    {
        "code": 64,
        "alphaCode": "BTN",
        "decimalPlaces": 2,
        "name": "Bhutanese Ngultrum"
    },
    {
        "code": 68,
        "alphaCode": "BOB",
        "decimalPlaces": 2,
        "name": "Boliviano"
    },
    {
        "code": 72,
        "alphaCode": "BWP",
        "decimalPlaces": 2,
        "name": "Botswana Pula"
    },
    {
        "code": 84,
        "alphaCode": "BZD",
        "decimalPlaces": 2,
        "name": "Belize Dollar"
    },
    {
        "code": 90,
        "alphaCode": "SBD",
        "decimalPlaces": 2,
        "name": "Solomon Islands Dollar"
    },
    {
        "code": 96,
        "alphaCode": "BND",
        "decimalPlaces": 2,
        "name": "Brunei Dollar"
    },
    {
        "code": 104,
        "alphaCode": "MMK",
        "decimalPlaces": 2,
        "name": "Myanmar Kyat"
    },
    {
        "code": 108,
        "alphaCode": "BIF",
        "decimalPlaces": 0,
        "name": "Burundi Franc"
    },
    {
        "code": 116,
        "alphaCode": "KHR",
        "decimalPlaces": 2,
        "name": "Cambodian Riel"
    },
    {
        "code": 124,
        "alphaCode": "CAD",
        "decimalPlaces": 2,
        "name": "Canadian Dollar"
    },
    {
        "code": 132,
        "alphaCode": "CVE",
        "decimalPlaces": 0,
        "name": "Cape Verde Escudo"
    },
    {
        "code": 136,
        "alphaCode": "KYD",
        "decimalPlaces": 2,
        "name": "Cayman Islands Dollar"
    },
    {
        "code": 144,
        "alphaCode": "LKR",
        "decimalPlaces": 2,
        "name": "Sri Lanka Rupee"
    },
    {
        "code": 152,
        "alphaCode": "CLP",
        "decimalPlaces": 0,
        "name": "Chilean Peso"
    },
    {
        "code": 156,
        "alphaCode": "CNY",
        "decimalPlaces": 2,
        "name": "Yuan Renminbi"
    },
    {
        "code": 174,
        "alphaCode": "KMF",
        "decimalPlaces": 0,
        "name": "Comoros Franc"
    },
    {
        "code": 188,
        "alphaCode": "CRC",
        "decimalPlaces": 2,
        "name": "Costa Rican Colon"
    },
    {
        "code": 191,
        "alphaCode": "HRK",
        "decimalPlaces": 2,
        "name": "Croatian Kuna"
    },
    {
        "code": 192,
        "alphaCode": "CUP",
        "decimalPlaces": 2,
        "name": "Cuban Peso"
    },
    {
        "code": 203,
        "alphaCode": "CZK",
        "decimalPlaces": 2,
        "name": "Czech Koruna"
    },
    {
        "code": 208,
        "alphaCode": "DKK",
        "decimalPlaces": 2,
        "name": "Danish Krone"
    },
    {
        "code": 214,
        "alphaCode": "DOP",
        "decimalPlaces": 2,
        "name": "Dominican Peso"
    },
    {
        "code": 230,
        "alphaCode": "ETB",
        "decimalPlaces": 2,
        "name": "Ethiopian Birr"
    },
    {
        "code": 232,
        "alphaCode": "ERN",
        "decimalPlaces": 2,
        "name": "Eritrean Nakfa"
    },
    {
        "code": 238,
        "alphaCode": "FKP",
        "decimalPlaces": 2,
        "name": "Falkland Islands Pound"
    },
    {
        "code": 242,
        "alphaCode": "FJD",
        "decimalPlaces": 2,
        "name": "Fiji Dollar"
    },
    {
        "code": 262,
        "alphaCode": "DJF",
        "decimalPlaces": 0,
        "name": "Djibouti Franc"
    },
    {
        "code": 270,
        "alphaCode": "GMD",
        "decimalPlaces": 2,
        "name": "Gambian Dalasi"
    },
    {
        "code": 292,
        "alphaCode": "GIP",
        "decimalPlaces": 2,
        "name": "Gibraltar Pound"
    },
    {
        "code": 320,
        "alphaCode": "GTQ",
        "decimalPlaces": 2,
        "name": "Guatemalan Quetzal"
    },
    {
        "code": 324,
        "alphaCode": "GNF",
        "decimalPlaces": 0,
        "name": "Guinea Franc"
    },
    {
        "code": 328,
        "alphaCode": "GYD",
        "decimalPlaces": 2,
        "name": "Guyana Dollar"
    },
    {
        "code": 332,
        "alphaCode": "HTG",
        "decimalPlaces": 2,
        "name": "Haitian Gourde"
    },
    {
        "code": 340,
        "alphaCode": "HNL",
        "decimalPlaces": 2,
        "name": "Honduran Lempira"
    },
    {
        "code": 344,
        "alphaCode": "HKD",
        "decimalPlaces": 2,
        "name": "Hong Kong Dollar"
    },
    {
        "code": 348,
        "alphaCode": "HUF",
        "decimalPlaces": 2,
        "name": "Hungarian Forint"
    },
    {
        "code": 352,
        "alphaCode": "ISK",
        "decimalPlaces": 0,
        "name": "Iceland Krona"
    },
    {
        "code": 356,
        "alphaCode": "INR",
        "decimalPlaces": 2,
        "name": "Indian Rupee"
    },
    {
        "code": 360,
        "alphaCode": "IDR",
        "decimalPlaces": 2,
        "name": "Indonesian Rupiah"
    },
    {
        "code": 364,
        "alphaCode": "IRR",
        "decimalPlaces": 2,
        "name": "Iranian Rial"
    },
    {
        "code": 368,
        "alphaCode": "IQD",
        "decimalPlaces": 3,
        "name": "Iraqi Dinar"
    },
    {
        "code": 376,
        "alphaCode": "ILS",
        "decimalPlaces": 2,
        "name": "Israeli New Shekel"
    },
    {
        "code": 388,
        "alphaCode": "JMD",
        "decimalPlaces": 2,
        "name": "Jamaican Dollar"
    },
    {
        "code": 392,
        "alphaCode": "JPY",
        "decimalPlaces": 0,
        "name": "Japanese Yen"
    },
    {
        "code": 398,
        "alphaCode": "KZT",
        "decimalPlaces": 2,
        "name": "Kazakhstan Tenge"
    },
    {
        "code": 400,
        "alphaCode": "JOD",
        "decimalPlaces": 3,
        "name": "Jordanian Dinar"
    },
    {
        "code": 404,
        "alphaCode": "KES",
        "decimalPlaces": 2,
        "name": "Kenyan Shilling"
    },
    {
        "code": 408,
        "alphaCode": "KPW",
        "decimalPlaces": 2,
        "name": "North Korean Won"
    },
    {
        "code": 410,
        "alphaCode": "KRW",
        "decimalPlaces": 0,
        "name": "Korean Won"
    },
    {
        "code": 414,
        "alphaCode": "KWD",
        "decimalPlaces": 3,
        "name": "Kuwaiti Dinar"
    },
    {
        "code": 417,
        "alphaCode": "KGS",
        "decimalPlaces": 2,
        "name": "Kyrgyzstani Som"
    },
    {
        "code": 418,
        "alphaCode": "LAK",
        "decimalPlaces": 2,
        "name": "Lao Kip"
    },
    {
        "code": 422,
        "alphaCode": "LBP",
        "decimalPlaces": 2,
        "name": "Lebanese Pound"
    },
    {
        "code": 426,
        "alphaCode": "LSL",
        "decimalPlaces": 2,
        "name": "Lesotho Loti"
    },
    {
        "code": 430,
        "alphaCode": "LRD",
        "decimalPlaces": 2,
        "name": "Liberian Dollar"
    },
    {
        "code": 434,
        "alphaCode": "LYD",
        "decimalPlaces": 3,
        "name": "Libyan Dinar"
    },
    {
        "code": 446,
        "alphaCode": "MOP",
        "decimalPlaces": 2,
        "name": "Macau Pataca"
    },
    {
        "code": 454,
        "alphaCode": "MWK",
        "decimalPlaces": 2,
        "name": "Malawi Kwacha"
    },
    {
        "code": 458,
        "alphaCode": "MYR",
        "decimalPlaces": 2,
        "name": "Malaysian Ringgit"
    },
    {
        "code": 462,
        "alphaCode": "MVR",
        "decimalPlaces": 2,
        "name": "Maldive Rufiyaa"
    },
    {
        "code": 478,
        "alphaCode": "MRO",
        "decimalPlaces": 2,
        "name": "Mauritanian Ouguiya"
    },
    {
        "code": 480,
        "alphaCode": "MUR",
        "decimalPlaces": 2,
        "name": "Mauritius Rupee"
    },
    {
        "code": 484,
        "alphaCode": "MXN",
        "decimalPlaces": 2,
        "name": "Mexican Nuevo Peso"
    },
    {
        "code": 496,
        "alphaCode": "MNT",
        "decimalPlaces": 2,
        "name": "Mongolian Tugrik"
    },
    {
        "code": 498,
        "alphaCode": "MDL",
        "decimalPlaces": 2,
        "name": "Moldovan Leu"
    },
    {
        "code": 504,
        "alphaCode": "MAD",
        "decimalPlaces": 2,
        "name": "Moroccan Dirham"
    },
    {
        "code": 512,
        "alphaCode": "OMR",
        "decimalPlaces": 3,
        "name": "Omani Rial"
    },
    {
        "code": 516,
        "alphaCode": "NAD",
        "decimalPlaces": 2,
        "name": "Namibian Dollar"
    },
    {
        "code": 524,
        "alphaCode": "NPR",
        "decimalPlaces": 2,
        "name": "Nepalese Rupee"
    },
    {
        "code": 532,
        "alphaCode": "ANG",
        "decimalPlaces": 2,
        "name": "Netherlands Antillean guilder"
    },
    {
        "code": 533,
        "alphaCode": "AWG",
        "decimalPlaces": 2,
        "name": "Aruban Guilder"
    },
    {
        "code": 548,
        "alphaCode": "VUV",
        "decimalPlaces": 0,
        "name": "Vanuatu Vatu"
    },
    {
        "code": 554,
        "alphaCode": "NZD",
        "decimalPlaces": 2,
        "name": "New Zealand Dollar"
    },
    {
        "code": 558,
        "alphaCode": "NIO",
        "decimalPlaces": 2,
        "name": "Nicaraguan Cordoba Oro"
    },
    {
        "code": 566,
        "alphaCode": "NGN",
        "decimalPlaces": 2,
        "name": "Nigerian Naira"
    },
    {
        "code": 578,
        "alphaCode": "NOK",
        "decimalPlaces": 2,
        "name": "Norwegian Krone"
    },
    {
        "code": 586,
        "alphaCode": "PKR",
        "decimalPlaces": 2,
        "name": "Pakistan Rupee"
    },
    {
        "code": 590,
        "alphaCode": "PAB",
        "decimalPlaces": 2,
        "name": "Panamanian Balboa"
    },
    {
        "code": 598,
        "alphaCode": "PGK",
        "decimalPlaces": 2,
        "name": "Papua New Guinea Kina"
    },
    {
        "code": 600,
        "alphaCode": "PYG",
        "decimalPlaces": 0,
        "name": "Paraguay Guarani"
    },
    {
        "code": 604,
        "alphaCode": "PEN",
        "decimalPlaces": 2,
        "name": "Peruvian Nuevo Sol"
    },
    {
        "code": 608,
        "alphaCode": "PHP",
        "decimalPlaces": 2,
        "name": "Philippine Peso"
    },
    {
        "code": 634,
        "alphaCode": "QAR",
        "decimalPlaces": 2,
        "name": "Qatari Rial"
    },
    {
        "code": 643,
        "alphaCode": "RUB",
        "decimalPlaces": 2,
        "name": "Russian Ruble"
    },
    {
        "code": 646,
        "alphaCode": "RWF",
        "decimalPlaces": 0,
        "name": "Rwanda Franc"
    },
    {
        "code": 654,
        "alphaCode": "SHP",
        "decimalPlaces": 2,
        "name": "St. Helena Pound"
    },
    {
        "code": 678,
        "alphaCode": "STD",
        "decimalPlaces": 2,
        "name": "Dobra"
    },
    {
        "code": 682,
        "alphaCode": "SAR",
        "decimalPlaces": 2,
        "name": "Saudi Riyal"
    },
    {
        "code": 690,
        "alphaCode": "SCR",
        "decimalPlaces": 2,
        "name": "Seychelles Rupee"
    },
    {
        "code": 694,
        "alphaCode": "SLL",
        "decimalPlaces": 2,
        "name": "Sierra Leone Leone"
    },
    {
        "code": 702,
        "alphaCode": "SGD",
        "decimalPlaces": 2,
        "name": "Singapore Dollar"
    },
    {
        "code": 704,
        "alphaCode": "VND",
        "decimalPlaces": 0,
        "name": "Vietnamese Dong"
    },
    {
        "code": 706,
        "alphaCode": "SOS",
        "decimalPlaces": 2,
        "name": "Somali Shilling"
    },
    {
        "code": 710,
        "alphaCode": "ZAR",
        "decimalPlaces": 2,
        "name": "South African Rand"
    },
    {
        "code": 728,
        "alphaCode": "SSP",
        "decimalPlaces": 2,
        "name": "South Sudan Pound"
    },
    {
        "code": 748,
        "alphaCode": "SZL",
        "decimalPlaces": 2,
        "name": "Swaziland Lilangeni"
    },
    {
        "code": 752,
        "alphaCode": "SEK",
        "decimalPlaces": 2,
        "name": "Swedish Krona"
    },
    {
        "code": 756,
        "alphaCode": "CHF",
        "decimalPlaces": 2,
        "name": "Swiss Franc"
    },
    {
        "code": 760,
        "alphaCode": "SYP",
        "decimalPlaces": 2,
        "name": "Syrian Lira"
    },
    {
        "code": 764,
        "alphaCode": "THB",
        "decimalPlaces": 2,
        "name": "Thai Baht"
    },
    {
        "code": 776,
        "alphaCode": "TOP",
        "decimalPlaces": 2,
        "name": "Tongan Pa'anga"
    },
    {
        "code": 780,
        "alphaCode": "TTD",
        "decimalPlaces": 2,
        "name": "Trinidad and Tobago Dollar"
    },
    {
        "code": 784,
        "alphaCode": "AED",
        "decimalPlaces": 2,
        "name": "Arab Emirates Dirham"
    },
    {
        "code": 788,
        "alphaCode": "TND",
        "decimalPlaces": 3,
        "name": "Tunisian Dinar"
    },
    {
        "code": 800,
        "alphaCode": "UGX",
        "decimalPlaces": 0,
        "name": "Uganda Shilling"
    },
    {
        "code": 807,
        "alphaCode": "MKD",
        "decimalPlaces": 2,
        "name": "Denar"
    },
    {
        "code": 818,
        "alphaCode": "EGP",
        "decimalPlaces": 2,
        "name": "Egyptian Pound"
    },
    {
        "code": 826,
        "alphaCode": "GBP",
        "decimalPlaces": 2,
        "name": "Pound sterling"
    },
    {
        "code": 834,
        "alphaCode": "TZS",
        "decimalPlaces": 2,
        "name": "Tanzanian Shilling"
    },
    {
        "code": 840,
        "alphaCode": "USD",
        "decimalPlaces": 2,
        "name": "US Dollar",
        "symbol":"$"
    },
    {
        "code": 858,
        "alphaCode": "UYU",
        "decimalPlaces": 2,
        "name": "Uruguayan Peso"
    },
    {
        "code": 860,
        "alphaCode": "UZS",
        "decimalPlaces": 2,
        "name": "Uzbekistan Sum"
    },
    {
        "code": 868,
        "alphaCode": "YER",
        "decimalPlaces": 2,
        "name": "Yemeni Rial"
    },
    {
        "code": 882,
        "alphaCode": "WST",
        "decimalPlaces": 2,
        "name": "Samoan Tala"
    },
    {
        "code": 901,
        "alphaCode": "TWD",
        "decimalPlaces": 2,
        "name": "New Taiwan dollar"
    },
    {
        "code": 931,
        "alphaCode": "CUC",
        "decimalPlaces": 2,
        "name": "Cuban convertible peso"
    },
    {
        "code": 932,
        "alphaCode": "ZWL",
        "decimalPlaces": 2,
        "name": "Zimbabwean Dollar"
    },
    {
        "code": 934,
        "alphaCode": "TMT",
        "decimalPlaces": 2,
        "name": "Turkmenistani manat"
    },
    {
        "code": 936,
        "alphaCode": "GHS",
        "decimalPlaces": 2,
        "name": "Ghanaian cedi"
    },
    {
        "code": 937,
        "alphaCode": "VEF",
        "decimalPlaces": 2,
        "name": "Venezuelan bolívar"
    },
    {
        "code": 938,
        "alphaCode": "SDG",
        "decimalPlaces": 2,
        "name": "Sudanese pound"
    },
    {
        "code": 941,
        "alphaCode": "RSD",
        "decimalPlaces": 2,
        "name": "Serbian dinar"
    },
    {
        "code": 943,
        "alphaCode": "MZN",
        "decimalPlaces": 2,
        "name": "Mozambican metical"
    },
    {
        "code": 944,
        "alphaCode": "AZN",
        "decimalPlaces": 2,
        "name": "Azerbaijani manat"
    },
    {
        "code": 946,
        "alphaCode": "RON",
        "decimalPlaces": 2,
        "name": "Romanian new leu"
    },
    {
        "code": 949,
        "alphaCode": "TRY",
        "decimalPlaces": 2,
        "name": "Turkish lira"
    },
    {
        "code": 950,
        "alphaCode": "XAF",
        "decimalPlaces": 0,
        "name": "CFA franc BEAC"
    },
    {
        "code": 951,
        "alphaCode": "XCD",
        "decimalPlaces": 2,
        "name": "East Caribbean dollar"
    },
    {
        "code": 952,
        "alphaCode": "XOF",
        "decimalPlaces": 0,
        "name": "Communauté Financière Africaine (BCEAO) Franc"
    },
    {
        "code": 953,
        "alphaCode": "XPF",
        "decimalPlaces": 0,
        "name": "Comptoirs Français du Pacifique (CFP) Franc"
    },
    {
        "code": 960,
        "alphaCode": "XDR",
        "decimalPlaces": 2,
        "name": "International Monetary Fund (IMF) Special Drawing Rights"
    },
    {
        "code": 967,
        "alphaCode": "ZMW",
        "decimalPlaces": 2,
        "name": "Zambia Kwacha"
    },
    {
        "code": 968,
        "alphaCode": "SRD",
        "decimalPlaces": 2,
        "name": "Suriname Dollar"
    },
    {
        "code": 969,
        "alphaCode": "MGA",
        "decimalPlaces": 2,
        "name": "Malagasy Ariary"
    },
    {
        "code": 971,
        "alphaCode": "AFN",
        "decimalPlaces": 2,
        "name": "Afghan Afghani"
    },
    {
        "code": 972,
        "alphaCode": "TJS",
        "decimalPlaces": 2,
        "name": "Tajikistani Somoni"
    },
    {
        "code": 973,
        "alphaCode": "AOA",
        "decimalPlaces": 2,
        "name": "Angolan Kwanza"
    },
    {
        "code": 974,
        "alphaCode": "BYR",
        "decimalPlaces": 0,
        "name": "Belarusian Ruble"
    },
    {
        "code": 975,
        "alphaCode": "BGN",
        "decimalPlaces": 2,
        "name": "Bulgarian lev"
    },
    {
        "code": 976,
        "alphaCode": "CDF",
        "decimalPlaces": 2,
        "name": "Congolese Franc"
    },
    {
        "code": 977,
        "alphaCode": "BAM",
        "decimalPlaces": 2,
        "name": "Bosnia and Herzegovina Convertible Marka"
    },
    {
        "code": 978,
        "alphaCode": "EUR",
        "decimalPlaces": 2,
        "name": "Euro"
    },
    {
        "code": 980,
        "alphaCode": "UAH",
        "decimalPlaces": 2,
        "name": "Ukrainian Hryvnia"
    },
    {
        "code": 981,
        "alphaCode": "GEL",
        "decimalPlaces": 2,
        "name": "Georgian Lari"
    },
    {
        "code": 985,
        "alphaCode": "PLN",
        "decimalPlaces": 2,
        "name": "Poland Zloty"
    },
    {
        "code": 986,
        "alphaCode": "BRL",
        "decimalPlaces": 2,
        "name": "Brazil Real"
    }
];

export default currencies;