import React, { useEffect, useState } from "react";
import currencyUtil from "@premier/utils/currency";
import { useField } from "formik";
import { InputGroup } from "react-bootstrap";
import CurrencyInput from "react-currency-input-field";
import classNames from "classnames";

import "./FormikCurrencyInput.scss";

// See node_modules\react-currency-input-field\dist\components\CurrencyInputProps.d.ts
type CurrencyInputProps = React.ComponentProps<typeof CurrencyInput>
interface FormikCurrencyInputProps extends CurrencyInputProps {
    // name refers to the form field name (this is used as a reference ID by Formik and forms in general)
    name : string;
    value : any;
    error?: string;
    showCurrencySymbol?: boolean;
}

const FormikCurrencyInput : React.FC<FormikCurrencyInputProps> = ({
    name, value, placeholder, className, showCurrencySymbol = true,
    ...otherProps
}) => {
    const [defaultPlaceholder] = useState<string>(currencyUtil.formatAmount(0));
    const [field, meta, helpers] = useField(name);
    // Underlying Formik field value, we assume that all forms will utilize the most pure type for its value
    // i.e. all string values representing a currency amount must be converted using currencyUtil.convertToApiValue(rawValue)
    const { setValue } = helpers;
    // Assumes that any value from the API will be a number in its smallest demonination (eg. $1234.56 == 123456)
    const [defaultValue] = useState<number | undefined>(field.value ? currencyUtil.fromApiValue(field.value) : undefined);
    // Raw value is the display value on the field, eg. $12,345,678.90
    const [rawValue, setRawValue] = useState<string | undefined>("");

    const onValueChange = (value : string | undefined) => {
        setRawValue(value);
    };

    useEffect(() => {
        if (rawValue) {
            setValue(currencyUtil.convertToApiValue(rawValue));
        }
    // eslint-disable-next-line
    }, [rawValue])

    return (
        <InputGroup className="input-group-currency">
            <InputGroup.Text className={classNames({ "has-error": meta.touched && !!meta.error?.length })}>
                {currencyUtil.getCurrency().alphaCode}</InputGroup.Text>
            <CurrencyInput
                className={classNames("textbox", { "has-error": meta.touched && !!meta.error?.length })}
                name={name}
                placeholder={placeholder ?? defaultPlaceholder}
                onValueChange={onValueChange}
                intlConfig={{ locale: "en-AU", currency: currencyUtil.getCurrency().alphaCode }}
                defaultValue={defaultValue}
                allowNegativeValue={false}
                decimalScale={currencyUtil.getCurrency().decimalPlaces}
                prefix={showCurrencySymbol ? "" : " "}
                {...otherProps}
            />
        </InputGroup>
    );
};

export default FormikCurrencyInput;
