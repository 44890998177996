import * as actions from './billerActionTypes';
import * as actionTypes from './actionTypes';
import { billerApi } from 'api';
import { CreateBillerParam, UpdateBillerParam, SurchargeRuleParam, AmountParam, UpdateBillerDetailsParam } from 'api/billerApi';
import { promiseResultRequestActions } from 'components/Common/requestActions';
import { RootState, AppDispatch } from 'store/store';
import { Wallet } from 'models';
import { UploadBillerCRNLookupRulesFileResponse } from 'packages/webapi-client';
import { Dispatch } from 'redux';

// ----- GET Biller -----
export let getBiller = promiseResultRequestActions(
    actions.GET_BILLER,
    (billerCode: string) => billerApi.getBiller(billerCode)
).send;

// ----- PUT new Biller -----
export let createNewBiller = promiseResultRequestActions(
    actions.ADD_BILLER,
    (billerDetails: CreateBillerParam) => billerApi.createBiller(billerDetails)
).send;

// ----- GET all Billers -----
export let loadBillers = promiseResultRequestActions(
    actions.LOAD_BILLERS,
    () => billerApi.getBillers()
).send;

// ----- PUT updated Biller -----
let updateBillerActions = promiseResultRequestActions(
    actions.UPDATE_BILLER,
    (billerDetails: UpdateBillerParam) => billerApi.updateBiller(billerDetails)
);

export function updateBiller(billerDetails: UpdateBillerParam) {
    return async function(dispatch: AppDispatch) {
        await dispatch(updateBillerActions.send(billerDetails));
        await dispatch(loadBillers());
    }
}

// ----- PUT update biller description -----
let updateBillerDetailsActions = promiseResultRequestActions(
    actions.UPDATE_BILLER_DETAILS,
    (params: UpdateBillerDetailsParam) => billerApi.updateDetails(params)
);

export function updateBillerDetails(params: UpdateBillerDetailsParam) {
    return async function(dispatch: AppDispatch, getState: () => RootState) {
        await dispatch(updateBillerDetailsActions.send(params));
        const updatedState = getState();
        if (updatedState.settings.paymentSettings.updateBillerDetails.hasSucceeded) {
            await dispatch(loadBillers());
        }
    }
}

// ----- GET/PUT Wallets for a Biller -----
export let getBillerWallets = promiseResultRequestActions(
    actions.GET_BILLER_WALLETS,
    (billerCode: string) => billerApi.getWalletsRedux(billerCode)
).send;

let updateBillerWalletsActions = promiseResultRequestActions(
    actions.PUT_BILLER_WALLETS,
    (billerCode: string, wallets: Wallet[]) => billerApi.updateWalletsRedux(billerCode, wallets)
);
export let updateBillerWallets = updateBillerWalletsActions.send;
export let clearUpdateBillerWallets = updateBillerWalletsActions.clear;

// ----- GET/PUT SurchargeRules for a Biller -----
export let getSurchargeRules = promiseResultRequestActions(
    actions.GET_SURCHARGE_RULES,
    (billerCode: string) => billerApi.getSurchargeRules(billerCode)
).send;
// ----- GET/PUT SurchargeRules for a Biller -----
let updateSurchargeRulesActions = promiseResultRequestActions(
    actions.PUT_SURCHARGE_RULES,
    (billerCode: string, rules: SurchargeRuleParam[]) => billerApi.updateSurchargeRules(billerCode, rules)
);
export let updateSurchargeRules = updateSurchargeRulesActions.send;
export let clearUpdateSurchargeRules = updateSurchargeRulesActions.clear;

// ----- PUT Biller payment limits -----
let updateBillerPaymentLimitActions = promiseResultRequestActions(
    actions.PUT_BILLER_PAYMENT_LIMITS,
    (billerCode: string, acceptedAmounts: AmountParam) => billerApi.updatePaymentLimits(billerCode, acceptedAmounts)
);
export let updateBillerPaymentLimitRange = (billerCode: string, acceptedAmounts: AmountParam) => {
    return async function(dispatch: AppDispatch, getState: () => RootState) {
        await dispatch(updateBillerPaymentLimitActions.send(billerCode, acceptedAmounts));
        const updatedState = getState();
        if (updatedState.settings.paymentSettings.acceptedAmountSubmission.hasSucceeded) {
            await dispatch(loadBillers());
        }
    }
}
export let clearBillerPaymentLimitRange = updateBillerPaymentLimitActions.clear;

// ----- POST Biller CRN lookup rules file -----
export let uploadBillerCRNLookupRulesFile = promiseResultRequestActions(
    actionTypes.UPLOAD_ISERVICE_CRN_RULES,
    (files: FileList, emails: string) => billerApi.billerUploadBillerCRNLookupRulesFile(files, emails)
).send as unknown as (dispatch: Dispatch) => Promise<UploadBillerCRNLookupRulesFileResponse>;