import React from 'react';
import classNames from 'classnames';
import './Accordion.scss';

export type AccordionChildProps = {
    title: React.ReactNode,

    enableUpXs?: boolean,
    enableUpSm?: boolean,
    enableUpMd?: boolean,
    enableUpLg?: boolean,
    enableUpXl?: boolean,
    enableDownXs?: boolean,
    enableDownSm?: boolean,
    enableDownMd?: boolean,
    enableDownLg?: boolean,
    enableDownXl?: boolean,

    expanded?: boolean;
    className?: string,
    onToggle?: () => void;
    children?: React.ReactNode;
};

export const AccordionChild = ({
    title,
    enableUpXs,
    enableUpSm,
    enableUpMd,
    enableUpLg,
    enableUpXl,
    enableDownXs,
    enableDownSm,
    enableDownMd,
    enableDownLg,
    enableDownXl,
    className,
    expanded,
    onToggle,
    children
}: AccordionChildProps) => {

    function generateClassnames() {
        return classNames('accordion', {
            expanded,
            'enable-up-xs': enableUpXs,
            'enable-up-sm': enableUpSm,
            'enable-up-md': enableUpMd,
            'enable-up-lg': enableUpLg,
            'enable-up-xl': enableUpXl,
            'enable-down-xs': enableDownXs,
            'enable-down-sm': enableDownSm,
            'enable-down-md': enableDownMd,
            'enable-down-lg': enableDownLg,
            'enable-down-xl': enableDownXl,

        }, className)
    }

    const handleFocus = () => {
        if (!expanded) {
            onToggle?.();
        }
    };

    return (
        <div className={generateClassnames()}>
            <div className='accordion-title' onClick={() => { onToggle?.() }}>{title}</div>
            <div className='accordion-content' onFocus={handleFocus}>
                {children}
            </div>
        </div>
    );
};
