import { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getFraudAdvancedSettings, uploadFraudRules, getFraudActionSources, getFraudTypeSources } from 'components/Settings/_actions/fraudActions';
import {
    PaginateRequestSearchFraudRulesInputFraudRuleSortFields as SearchRequest,
    PaginateRequestSearchFraudRulesInputFraudRuleSortFieldsOrderEnum as OrderEnum,
    PaginateRequestSearchFraudRulesInputFraudRuleSortFieldsSortFieldEnum as SortFieldEnum,
} from 'packages/webapi-client'
// @ts-ignore
import { defaultPageSize } from 'constants/billpay'
import SecurityUtil from '@premier/utils/security';
import { userRoles } from 'components/Routing';

const defaultSearchRequest: SearchRequest = {
    pageIndex: 0,
    pageSize: defaultPageSize,
    sortField: 'Type',
    order: 'ASCENDING',
    request: {
        action: undefined,
        type: undefined,
        lowField: '',
        highField: '',
    }
}

const advancedSettingsSelector = (state: any) => state.settings.fraud.advancedSettings;
const actionFilterSelector = (state: any) => state.settings.fraud.advancedFilterSources.actionSources;
const typeFilterSelector = (state: any) => state.settings.fraud.advancedFilterSources.typeSources;

export const useFraudAdvancedSettings = () => {
    const { isInitial, hasLoaded, hasFailed, data: settings, errors } = useSelector(advancedSettingsSelector);
    const { data: actionFilter } = useSelector(actionFilterSelector);
    const { data: typeFilter } = useSelector(typeFilterSelector);

    const dispatch = useDispatch();
    const { authenticatedUser } = useSelector((state: any) => ({
        authenticatedUser: state.accounts.users.authenticatedUser,
    }));

    useEffect(() => {
        // only retrieve data for fraudAdvanced if user has the fraud control role
        if (isInitial && SecurityUtil.hasAccess([userRoles.fraudControl], authenticatedUser)) {
            dispatch(getFraudAdvancedSettings(defaultSearchRequest));
            dispatch(getFraudActionSources());
            dispatch(getFraudTypeSources());
        }
    }, [dispatch, isInitial, authenticatedUser]);

    const changeFilters = useCallback((
        pageIndex: number,
        pageSize: number,
        sortField: SortFieldEnum,
        order: OrderEnum,
        request: any
    ) => {
        const r = {
            ...defaultSearchRequest,
            pageIndex,
            pageSize,
            sortField,
            order,
            request: { ...defaultSearchRequest.request, ...request }
        }

        // only call api if user has the right authentication
        if (SecurityUtil.hasAccess([userRoles.fraudControl], authenticatedUser)) {
            dispatch(getFraudAdvancedSettings(r))
        }
    }, [dispatch, authenticatedUser]);

    return {
        hasLoaded,
        hasFailed,
        settings,
        typeFilter,
        actionFilter,
        errors,
        changeFilters,
        uploadFraudRules: (files: FileList) => dispatch(uploadFraudRules(files))
    }
}