import React, { ReactNode } from 'react';
import classNames from 'classnames';
import './Row.scss';

type Props = {
    /** This puts vertical dividers between the children (ie. the columns).
      * You'll need to add horizontal dividers yourself if you need (eg. <Divider className='d-lg-none' />) */
    divided?: boolean,

    /** 8px gap between columns instead of the default 30px */
    lessGap?: boolean,
    lessGapDownXs?: boolean,
    lessGapDownMd?: boolean,

    className?: string,
    children?: ReactNode,
};

/** A simple Bootstrap row */
const Row = ({
    divided, lessGap, lessGapDownXs, lessGapDownMd, className, children
}: Props) => {

    return (
        <div className={classNames('row', className, {
            'divided': divided,
            'less-gap': lessGap,
            'less-gap-down-xs': lessGapDownXs,
            'less-gap-down-md': lessGapDownMd,
        })}>
            {children}
        </div>
    );
};

export default Row;