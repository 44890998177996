import React from 'react';

import labels from 'platforms/current/constants/labels';
import { Merchant } from 'models';
import { BrandingStyle } from 'components/Settings';
import Layout from './Layout';
import { Biller } from 'packages/webapi-client';
import './HppPreview.scss';

type Props = {
    style: BrandingStyle,
    merchant: Merchant,
    biller: Biller,

    // PreviewContainer props:
    /** A mobile view instead of the default desktop view */
    mobile: boolean,
    /** Any change to this prop will redraw the preview. Useful when the preview is initially hidden and hence its size cannot be calculated. */
    refreshTrigger: unknown,
};

/** A preview of a (styled) HPP page for the current merchant/biller */
const HppPreview = ({
    style, merchant, biller,
    mobile, refreshTrigger
}: Props) => {

    const topNodes = calculateTopNodes(style);

    return (
        <Layout className='hpp-preview' style={style} mobile={mobile} refreshTrigger={refreshTrigger}>

            { topNodes.length > 0 &&
                <div className='top' style={{
                    fontFamily: style.page.fontFamily,
                    color: style.menu.fontColour,
                    backgroundColor: style.menu.backColour,
                    borderColor: style.menu.borderColour,
                    borderWidth: `${style.menu.showTopBorder ? '1' : '0'}px 0px ` +
                                `${style.menu.showBottomBorder ? '1' : '0'}px 0px`}}>

                    <div className='navbar-gap'>
                        <div>
                            { topNodes }
                        </div>
                    </div>
                </div>
            }

            <div className='page' style={{backgroundColor: style.page.backColour}}>
                <div className='content' style={{
                    fontFamily: style.page.fontFamily,
                    color: style.page.fontColour,
                    backgroundColor: style.page.sectionColour,
                }}>
                    <dl>
                        <dt>You are paying</dt>
                        <dd>{merchant.merchantName}</dd>
                    </dl>
                    <dl>
                        <dt>Biller Code</dt>
                        <dd>{biller?.billerCodeWithName}</dd>
                    </dl>
                    <dl>
                        <dt>{biller?.acceptedCrn1?.label ?? labels.reference1}</dt>
                        <dd className='no-pad'><input type='text' tabIndex={-1} /></dd>
                    </dl>

                    <div className='row'>
                        <div className='col-6'>
                            <div className='fake-button' style={{
                                color: style.page.secondaryButtonFontColour,
                                backgroundColor: style.page.secondaryButtonColour,
                            }}>Back</div>
                        </div>
                        <div className='col-6'>
                            <div className='fake-button' style={{
                                color: style.page.primaryButtonFontColour,
                                backgroundColor: style.page.primaryButtonColour,
                            }}>Next</div>
                        </div>
                    </div>
                </div>
            </div>

        </Layout>
    );
};

function calculateTopNodes(style: BrandingStyle) {

    let topNodes = [];

    if (style.menu.showTerms) {
        topNodes.push(<span key='Terms'>Terms</span>);
    }

    if (style.menu.showPrivacy) {
        topNodes.push(<span key='Privacy'>Privacy</span>);
    }

    if (style.menu.showExtraInfo) {
        topNodes.push(<span key='Extra Info'>Extra Info</span>);
    }

    if (topNodes.length > 0) {
        topNodes.unshift(
            <span key='Make a Payment' style={{
                borderBottom: '2px solid',
                fontWeight: 'bold',
                borderColor: style.menu?.underlineColour
            }}>
                Make a Payment
            </span>
        );
    }
    return topNodes;
}

export default HppPreview;
