import React from 'react';
import classNames from 'classnames';
import { NavItem } from 'models';
import MenuItem from './MenuItem';
import 'images/arrowdown-white.svg';
import './Menu.scss';

type Props = {
    items: NavItem[],
    right?: boolean,
    isSubMenu?: boolean,
    isOpen?: boolean,
    nav?: boolean,
    onLinkClick?: () => void,
};

const Menu = ({
    items, right, isSubMenu, isOpen, nav, onLinkClick,
}: Props) => {

    var containerClasses = classNames({
        "menu-container": true,
        "menu-right": right,
        "menu-left": !right,
        "menu-right-show": isOpen && right,
        "menu-left-show": isOpen && !right,
        "sub-menu": isSubMenu,
        "nav-menu": nav
    });

    var componentClasses = classNames({
        "menu-component": true,
        "sub-menu": isSubMenu,
        "nav-menu-component": nav
    });

    return (
        <nav className={containerClasses}
            aria-label={isSubMenu ? undefined : (right ? 'Right menu' : 'Left menu')}
        >
            <ul className={componentClasses}>
                {items
                    .filter(item => item.type !== 'public' || !nav) //remove public items from nav menu
                    .map((item, key) => {
                    return <MenuItem key={key} item={item} nav={nav} isSubMenu={isSubMenu} onLinkClick={onLinkClick}/>;
                })}
            </ul>
        </nav>
    );
}

export default Menu;