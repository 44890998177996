import { FormError, FieldsType, ValidateSettings, ValidationGroup, ErrorMap, ExternalError, RegisterOptions } from './FormTypes';

function closest(value: string, ...chars: string[]) {
    let closestIndex = -1;
    for (const char of chars) {
        let cindex = value.indexOf(char);
        if (cindex < 0)
            continue;
        if (closestIndex >= 0 && cindex > closestIndex)
            continue;
        closestIndex = cindex;
    }
    return closestIndex;
}

export function toPath(value: string): string[] {
    if (!value)
        return [];

    let rest = value;
    let path: string[] = [];

    while (true) {
        if (!rest)
            break;
        if (rest[0] === '"') {
            let closeIndex = rest.indexOf('"', 1);
            if (closeIndex < 0) {
                path.push(rest.substring(1));
                break;
            }
            path.push(rest.substring(1, closeIndex));
            rest = rest.substring(closeIndex + 1);
            continue;
        }

        const index = closest(rest, '.', '[', ']');
        if (index < 0) {
            path.push(rest);
            break;
        }
        const seg = rest.substring(0, index);
        if (seg)
            path.push(seg)
        rest = rest.substring(index + 1);
    }
    return path;
}

export function addErrorFromPath<T>(error: FormError<T>, path: string[], message: string): FormError<T> {
    if (path.length === 0) {
        return {...error, message: message};
    }
    let key = path[0] as keyof T;
    let subpath = path.slice(1);
    return {
        ...error,
        details: {
            ...error?.details,
            [key]: addErrorFromPath(error?.details?.[key], subpath, message)
        }
    };
}

export function addExternalErrors<T>(oldError: FormError<T>, errors: ExternalError[], fields?: FieldsType<unknown>): [FormError<T>, string[]] {
    let externals: string[] = [];
    let error: FormError<T> = oldError;

    for (const extErr of errors) {
        let message = extErr.message ?? 'Unknown error'
        if (!extErr.field)
        {
            externals.push(message);
            continue;
        }
        let label = extErr.field ?? 'Unknown field';
        const path = toPath(extErr.field);
        let field: RegisterOptions<unknown> | undefined = undefined;
        let nestedFields: { [key: string ]: RegisterOptions<unknown> } | undefined = fields;
        for (const seg of path) {
            if (!nestedFields)
                break;
            field = nestedFields?.[seg];
            nestedFields = field?.fields;
        }
        if (field)
            label = field.label ?? label;
        if (field) {
            error = addErrorFromPath(error, path, message);
        } else {
            message = message.replaceAll("{label}", label);
            externals.push(message);
        }
    }

    if (error !== oldError) {
        externals.push("There are errors in the form. Please scroll up and fix them.");
    }

    return [error, externals];
}

export function validateValues<T>(values: T, oldError: FormError<T>, validate: ValidateSettings<T> | undefined, fields?: FieldsType<T>, ...validations: (ValidationGroup<T> | undefined)[]): FormError<T> {
    if (!validate)
        return oldError;

    const details: ErrorMap<T> = validate === "all" ? {} : {...oldError?.details};
    let message = validate === "all" ? undefined : oldError?.message;

    let keys: (keyof T)[];
    if (validate !== 'all') {
        keys = [];
        for (const key in validate)
            keys.push(key);
    } else {
        const keySet: Set<keyof T> = new Set();

        if (fields)
            for (const key in fields)
                keySet.add(key);

        for (const val of validations) {
            if (val?.fields)
                for (const key in val.fields)
                    keySet.add(key);
            if (val?.all)
                for (const key in values)
                    keySet.add(key);
        }

        keys = Array.from(keySet);
    }

    for (const key of keys) {
        const innerValidate = validate === "all" ? "all" : validate[key];
        if (!innerValidate)
            continue;

        const field = fields?.[key];
        const innerValue = values[key];
        const innerValidations: ValidationGroup<T[typeof key]>[] = [];

        if (field?.validation)
            innerValidations.push(field.validation);

        for (const val of validations) {
            const innerV = val?.fields?.[key];
            if (innerV)
                innerValidations.push(innerV);
            const all = val?.all;
            if (all)
                innerValidations.push(all);
        }

        details[key] = validateValues(innerValue, details[key], innerValidate, field?.fields, ...innerValidations);
    }

    if (validate === "all") {
        for (const validation of validations) {
            const validator = validation?.self;
            if (!validator)
                continue;
            message = validator(values);
            if (message)
                break;
        }
    }

    removeFalsyKeys(details);
    return asError(message, details);
}

export function removeFalsyKeys<T>(details: ErrorMap<T>) {
    for (const key in details) {
        if (!details[key])
            delete details[key];
    }
}

/** Produce a clean error object, or undefined */
export function asError<T>(message?: string, details?: ErrorMap<T>): FormError<T> {
    if (details && !Object.keys(details).length)
        details = undefined;

    const error: FormError<T> = {}
    if (!message && !details)
        return undefined
    if (message)
        error.message = message;
    if (details)
        error.details = details;
    return error;
}

export function replaceLabel(message: string | undefined, label: string): string | undefined {
    return message?.replaceAll("{label}", label);
}