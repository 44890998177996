import React, { ChangeEvent, useState } from "react";
import _ from "lodash";
import classNames from "classnames";

import { Input, Dropdown, Row } from "@premier/ui";

import FormContext from "../FormContext";
import FormGroup from "./FormGroup";
import withField from "./withField";
import { validate } from "../validation/fieldValidation";
import "./PhoneNumberField.scss";
import countryUtil from "@premier/utils/country";

type Props = {
    name: string;
    formProps: any;
    groupProps: any;
    /** Put normal (wider/30px) gap between country dropdown and phone number textbox */
    normalGap: boolean;
    /** Put normal (wider/30px) gap between country dropdown and phone number textbox in LG+ */
    lessGapDownMd: boolean;
}

export type PhoneNumberOnChangeEvent = {
    phoneNumber: string;
    iddCode: string;
}

/** A country dropdown + an input field
 * @returns {object} eg. {iddCode: '+61', phoneNumber: '399990000'}
*/
const PhoneNumberField = ({
    name, formProps, groupProps,  // from withField
    normalGap, lessGapDownMd, ...otherProps  // own props
}: Props) => {
    const { setValidation, getValidation } = React.useContext(FormContext);

    const [id] = useState(_.uniqueId(`${name}_`));

    React.useEffect(() => {
        if (!getValidation(name)) {
            setValidation(name, validate().phone());
        }
    }, []);

    function setVal(propName: string, val: string) {
        formProps.setValue({
            ...formProps.value,
            [propName]: val
        });
    }

    function handleIddCodeChange(option: any) {
        setVal("iddCode", option.value);
    }

    function handlePhoneNumberChange(e: ChangeEvent<HTMLInputElement>) {
        setVal("phoneNumber", e.target.value);
    }

    return (
        <FormGroup
            fieldId={id+"_phoneNumber"}
            {...groupProps}
        >
            <Row
                lessGapDownMd={lessGapDownMd}
                lessGap={!(normalGap || lessGapDownMd)}
                className={classNames("phone-number-wrapper")}
            >
                <div className="col-sm-6">
                    <Dropdown
                        id={id+"_iddCode"}
                        aria-label="Phone number country code"
                        options={countryUtil.getPhoneCountryOptions()}
                        value={_.get(formProps.value, "iddCode")}
                        onChange={handleIddCodeChange}
                    />
                </div>
                <div className={"col-sm-6"}>
                    <Input digitsOnly
                        id={id+"_phoneNumber"}
                        type="tel"
                        value={_.get(formProps.value, "phoneNumber")}
                        onChange={handlePhoneNumberChange}
                        error={formProps.error}
                        {...otherProps}
                    />
                </div>
            </Row>
        </FormGroup>
    );
};

export default withField(PhoneNumberField);
