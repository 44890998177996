import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useCurrentBreakpointName } from 'react-socks';

import currencyUtil from '@premier/utils/currency';
import dateUtil from '@premier/utils/date';
import textUtil from '@premier/utils/text';

import { ResponsiveTable } from '@premier/ui';
import { FormContext, InputField, DropdownField, DatePickerField, CurrencyField, MaskedCardNumberField } from '@premier/form';
import { useDebounce } from 'components/Common';
import { TransactionStatusIcon } from 'components/Transactions';

import { PlatformRoutesConfiguration } from 'components/Routing';

import './TransactionList.scss';

const TransactionList = ({
    savedFilter, sort, transactions, //data
    onFilterChange, onSort, onLinkClick,//functions
    validDateRange, responseTypeOptions, transactionTypeOptions, //option data
    isLoading
  }) => {

    const context = React.useContext(FormContext);

    // Update quick filter values from Redux
    useEffect(() => {
        if(savedFilter)
            context.setFormValues(savedFilter);
    }, [savedFilter]);

    /** Triggers onFilterChange (ie. API call) on the parent */
    function handleQuickFilterChange(values) {
        onFilterChange(values || context.values);  // context.values may not be updated yet, pass values if needed
    }
    const debouncedFilterChange = useDebounce(handleQuickFilterChange, 1250);


    var isTableView = ['md','lg','xl'].includes(useCurrentBreakpointName());

    // The quick filter fields
    const fields = {
        responseType: (
            <DropdownField
                name='searchTransactionsResponseType'
                options={responseTypeOptions}
                onChange={() => handleQuickFilterChange()}
                compact={isTableView}
                aria-label='Status filter'
            />
        ),
        transactionType: (
            <DropdownField
                name='transactionType'
                options={transactionTypeOptions}
                onChange={(val) => {
                    handleQuickFilterChange({
                        ...context.values,
                        transactionType: val,
                        ...val && {transactionTypes: []} // clear the transaction types filter, as we should only have 1 set at a time
                    });
                }}
                compact={isTableView}
                aria-label='Type filter'
            />
        ),
        crn1: (
            <InputField
                name='crn1'
                onChange={val => debouncedFilterChange({ ...context.values, crn1: val })}
                aria-label='Reference 1 filter'
            />
        ),
        receiptNo: (
            <InputField
                name='receiptNumber'
                onChange={val => debouncedFilterChange({ ...context.values, receiptNumber: val })}
                aria-label='Receipt number filter'
            />
        ),
        maskedCardNo: (
            <MaskedCardNumberField
                name='maskedCardNumber'
                onChange={val => debouncedFilterChange({ ...context.values, maskedCardNumber: val })}
                aria-label='Card number filter'
            />
        ),
        transactionDate: (
            <DatePickerField
                mode='range'
                enableTime
                name='transactionDateRange.dates'
                minDate={validDateRange[0]}
                maxDate={validDateRange[1]}
                onClose={() => handleQuickFilterChange()}
                aria-label='Transaction date range filter'
            />
        ),
        amount: (
            <CurrencyField compact
                name='amount'
                onChange={val => debouncedFilterChange({
                    ...context.values,
                    amount: val,
                    ...val && {amountRange: {min: '', max: ''}} // clear the amount range filter, as we should only have 1 set at a time
                })}
                aria-label='Transaction amount filter'
            />
        )
    };

    // The table columns (or list items)
    const columns = [{
        label: 'Status',
        sortKey: 'paymentProcessedTxnModel.responseCode',
        getter: t => <TransactionStatusIcon transaction={t} />,
        textAlign: 'center',
        filter: fields.responseType
    }, {
        label: 'Type',
        sortKey: 'transactionTypeDetails.description',
        getter: t => t.transactionTypeDetails.description,
        filter: fields.transactionType
    }, {
        label: 'Reference 1',
        sortKey: 'crn1',
        getter: t => <div className='col-break'>{t.crn1}</div>,
        filter: fields.crn1
    }, {
        label: 'Receipt number',
        sortKey: 'receiptNumber',
        getter: t => <span className='receipt-no'>{textUtil.insertSpaces(t.receiptNumber)}</span>,
        filter: fields.receiptNo
    }, {
        label: 'Masked card number',
        sortKey: 'maskedCardNumber',
        getter: t => <div className='col-break'>{t.maskedCardNumber}</div>,
        filter: fields.maskedCardNo
    }, {
        label: 'Transaction date',
        sortKey: 'processedDate',
        getter: t => <><nobr>{dateUtil.convertToDateString(t.processedDate)}</nobr> <nobr>{dateUtil.convertToTimeString(t.processedDate)}</nobr></>,
        filter: fields.transactionDate
    }, {
        label: 'Amount',
        sortKey: 'totalAmount',
        getter: t => currencyUtil.convertToDisplayString(t.totalAmount, t.currencyCode),
        filter: fields.amount
    }];

    return (<>
        <ResponsiveTable
            data={transactions}
            className='transaction-list'
            isLoading={isLoading}
            columns={columns}
            sort={sort}
            onSort={onSort}
            hideSortDropdown
            hideFilterDropdowns
            getRowLink={t => PlatformRoutesConfiguration.transactionRoute.transactionDetails.generatePath(t.txnHistoryId + (t.isExternal ? "-external" : ""))}
            onRowLinkClick={onLinkClick}
            noDataText='No transactions to display.'
        />
    </>);
};


TransactionList.propTypes = {
    /** The saved filter from Redux (used only to update Form values when it's updated in Redux) */
    savedFilter: PropTypes.object,
    onFilterChange: PropTypes.func,
    /** The current sort order */
    sort: PropTypes.shape({
        field: PropTypes.string,
        descending: PropTypes.bool,
    }),
    /** to handle sort field/order changes */
    onSort: PropTypes.func,

    /** What happens when redirecting to view item details (eg. to save the current page state into Redux) */
    onLinkClick: PropTypes.func,

    validDateRange: PropTypes.array,
    responseTypeOptions: PropTypes.array,
    transactionTypeOptions: PropTypes.array,

    transactions: PropTypes.array,
    isLoading: PropTypes.bool,
};

export default TransactionList;