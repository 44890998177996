import { Button, Dialog } from '@premier/ui';
import { FaExclamationTriangle } from 'react-icons/fa';

type Props = {
    show: boolean,
    onClose: () => {},
    handleContinueConfirmed: () => {},
}

const BatchJobWarningProcessDialog = ({
    show,
    onClose,
    handleContinueConfirmed
}: Props) => {

    return (
        <Dialog show={show}
            title='Process this batch file?'
            icon={<div><FaExclamationTriangle size={48} color="orange" /> </div>}
            footerButtons={<>
                <Button primary onClick={handleContinueConfirmed}>Process</Button>
                <Button onClick={onClose}>Cancel</Button>
            </>}
        >
        </Dialog>

    );

}

export default BatchJobWarningProcessDialog;
