import { ReactNode } from "react";
import { Button } from "@premier/ui";

import "./PrintContainer.scss";

type Props = {
    title: string;
    buttonText?: string;
    children: ReactNode;
}

const PrintContainer = (props: Props) => {
    const { title, buttonText } = props;

    return (
        <div className="print-container">
            <div className="header">
                <h1>{title}</h1>
                <Button subtle className="print-button right" onClick={() => { window.print(); }}>{buttonText || "Print receipt"}</Button>
            </div>

            {props.children}

            <div className="footer">
                &copy; Commonwealth Bank of Australia 2009-{new Date().getFullYear()} ABN 48 123 123 124
            </div>
        </div>
    );
};

export default PrintContainer;
