import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Field, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";

import { FormError, PageHeader } from "components/Common";
import { Button, PageSection, SuccessModal, Dialog, Icon } from "packages/ui";
import { DeclineTrayModel } from "packages/webapi-client";
import { FormGroup, Input, TextArea } from "packages/formik-ui";
import { declineManagerApi } from "api";
import errorUtil from "packages/utils/error";
// @ts-ignore
import labels from "constants/labels";

type FormValues = {
    name: string;
    description: string;
    instruction: string;
    emailAddress: string;
}

const EditTrayPage = () => {

    const navigate = useNavigate();
    const { state } = useLocation();

    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showWarningModal, setShowWarningModal] = useState(false);
    const [doExit, setDoExit] = useState(false);
    const [submitErrors, setSubmitErrors] = useState<string[]>([]);

    useEffect(() => {
        if (doExit) {
            navigate(-1);
        }

    }, [doExit, navigate])

    const declineTray: DeclineTrayModel = {
        ...state.declineTray
    };

    const { merchantNumber } = state;

    const formik = useFormik<FormValues>({
        initialValues: {
            name: declineTray.nickname ?? "",
            description: declineTray.description ?? "",
            instruction: declineTray.comments ?? "",
            emailAddress: declineTray.emailAddress ?? ""
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().label("Name").max(50).required(),
            description: Yup.string().label("Description").max(200),
            instruction: Yup.string().label("Instruction").max(1000),
            emailAddress: Yup.string().label("Email address").max(250).email(),
        }),
        onSubmit: async (formValues: FormValues) => {

            setSubmitErrors([]);

            const model: DeclineTrayModel = {
                childMerchantNumber: merchantNumber,
                nickname: formValues.name,
                description: formValues.description,
                comments: formValues.instruction,
                emailAddress: formValues.emailAddress,
                trayID: declineTray.trayID,
                trayType: declineTray.trayType
            };

            try {
                const response = await declineManagerApi.updateMerchantTray(model);

                if (response.status === 200) {
                    setShowSuccessModal(true);
                } else {
                    const errors = response.data.errors ? errorUtil.convertApiErrorsToFormikErrors<FormValues>(response.data.errors) : null;

                    if (errors) {
                        formik.setErrors(errors);
                    }
                }
            } catch (error: any) {
                if (error.response?.data?.errors?.length) {
                    setSubmitErrors(error.response.data.errors.map((r: any) => r.message));
                } else {
                    setSubmitErrors([labels.unknownErrorMessage]);
                }
            }
        }
    });

    const handleCancelClick = (e?: React.MouseEvent<HTMLElement>) => {
        if (e) {
            e.preventDefault();
        }

        if (formik.dirty) {
            setShowWarningModal(true);
            return;
        }
        navigate(-1);
    };

    const handleExit = (doExit: boolean) => {
        setDoExit(doExit);
        setShowWarningModal(false);
        setShowSuccessModal(false);
    }

    return (
        <PageSection>
            <PageHeader backButton={{ onClick: handleCancelClick }} title="Edit tray" subtitle={`Merchant ${merchantNumber}`} />
            <FormikProvider value={formik}>
                <form onSubmit={formik.handleSubmit}>

                    <FormGroup name="name" label="Name" mandatory >
                        <Field name="name" as={Input} readOnly={declineTray.trayType !== "Other" || !declineTray.nickname} />
                    </FormGroup>

                    <FormGroup name="description" label="Description">
                        <Field name="description" as={Input} />
                    </FormGroup>

                    <FormGroup name="instruction" label="Instruction">
                        <Field name="instruction" as={TextArea} rows={4} resize={false} />
                    </FormGroup>

                    <FormGroup name="emailAddress" label="Notification email address">
                        <Field name="emailAddress" as={Input} />
                    </FormGroup>

                    <Button type="submit" disabled={formik.isSubmitting} primary>Save</Button>
                    <Button type="submit" onClick={handleCancelClick}>Cancel</Button>
                </form>
            </FormikProvider>

            <FormError errors={submitErrors ?? []} />
            <SuccessModal title={"Tray properties updated successfully!"} show={showSuccessModal} onClose={() => handleExit(true)} />
            <Dialog show={showWarningModal}
                title="Your changes have not been saved"
                icon={<Icon alert />}
                footerButtons={<>
                    <Button onClick={() => handleExit(false)} primary>Continue editing</Button>
                    <Button onClick={() => handleExit(true)}>Discard changes</Button>
                </>}
            >
                All your changes will be discarded if you leave without saving first.
            </Dialog>

        </PageSection>
    );
};

export default EditTrayPage;