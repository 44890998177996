import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useDebounce } from 'components/Common';
import { ResponsiveTable } from '@premier/ui';
import { FormContext, InputField, MaskedCardNumberField } from '@premier/form';

import labels from 'constants/labels';
import { PlatformRoutesConfiguration } from 'components/Routing';

const TokenList = ({
    savedFilter, sort, tokens, //table data
    onFilterChange, onSort, //table functions
    isLoading //logic renders
  }) => {

    const context = React.useContext(FormContext);

    // Update quick filter values from Redux
    useEffect(() => {
        if(savedFilter)
            context.setFormValues(savedFilter);
    }, [savedFilter]);

    /** Triggers onFilterChange (ie. API call) on the parent */
    function handleQuickFilterChange(values) {
        onFilterChange(values || context.values);  // context.values may not be updated yet, pass values if needed
    }
    const debouncedFilterChange = useDebounce(handleQuickFilterChange);

    // The quick filter fields
    const fields = {
        token: (
            <InputField
                name='token'
                onChange={val => debouncedFilterChange({ ...context.values, token: val })}
                aria-label='Token filter'
            />
        ),
        customerUniqueId: (
            <InputField
                name='customerUniqueId'
                onChange={val => debouncedFilterChange({ ...context.values, customerUniqueId: val })}
                aria-label={labels.customerCode + ' filter'}
            />
        ),
        maskedCardNumber: (
            <MaskedCardNumberField
                name='maskedCardNumber'
                onChange={val => debouncedFilterChange({ ...context.values, maskedCardNumber: val })}
                aria-label='Card number filter'
            />
        ),
        deAccountNumber: (
            <InputField
                name='deAccountNumber'
                onChange={val => debouncedFilterChange({ ...context.values, deAccountNumber: val })}
                aria-label='Account number filter'
            />
        ),
        crn1: (
            <InputField
                name='crn1'
                onChange={val => debouncedFilterChange({ ...context.values, crn1: val })}
                aria-label='Token reference 1 filter'
            />
        )
    };

    // The table columns (or list items)
    const columns = [{
        label: labels.token,
        showAsLink: true,
        getter: 'token',
        sortKey: 'token',
        filter: fields.token
    }, {
        label: labels.customerCode,
        getter: 'customerUniqueId',
        sortKey: 'customerUniqueId',
        filter: fields.customerUniqueId
    }, {
        label: labels.maskedCardNumber,
        getter: 'maskedCardNumber',
        sortKey: 'maskedCardNumber',
        filter: fields.maskedCardNumber
    }, {
        label: labels.bankAccountNumber,
        getter: 'deAccountNumber',
        sortKey: 'deAccountNumber',
        filter: fields.deAccountNumber
    }, {
        label: labels.tokenReference1,
        getter: 'crn1',
        sortKey: 'crn1',
        filter: fields.crn1
    }];

    return (<>
        <ResponsiveTable
            data={tokens || []}
            className='token-list'
            isLoading={isLoading}
            columns={columns}
            sort={sort}
            onSort={onSort}
            getRowLink={c => PlatformRoutesConfiguration.tokenRoute.viewToken.generatePath(c.dataVaultId)}
            noDataText='No tokens to display.'
        />
    </>);
};


TokenList.propTypes = {
    /** The saved filter from Redux (used only to update Form values when it's updated in Redux) */
    savedFilter: PropTypes.object,
    onFilterChange: PropTypes.func,
    /** The current sort order */
    sort: PropTypes.shape({
        field: PropTypes.string,
        descending: PropTypes.bool,
    }),
    /** to handle sort field/order changes */
    onSort: PropTypes.func,

    /** What happens when redirecting to view item details (eg. to save the current page state into Redux) */
    onLinkClick: PropTypes.func,

    tokens: PropTypes.array,
    isLoading: PropTypes.bool,
};

export default TokenList;