import { useEffect, ReactElement } from 'react';
import text from 'platforms/current/constants/text';

type Props = {
    title?: string,
    children : ReactElement
};

// Modifies the document title (eg. the name of the tab in the browser)
const RouteTitleModifier = ({title, children}: Props) => {
    useEffect(() => {
        document.title = title ? `${title} – ${text.platformName}` : process.env.REACT_APP_PAGE_TITLE ?? '';
    }, [title]);

    return children;
};

export default RouteTitleModifier;