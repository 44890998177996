import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ReorderableList } from '@premier/ui';
import FormGroup from './FormGroup';
import withField from './withField';

const ReorderableListField = ({ name, id, formProps, groupProps, ...otherProps }) => {
    const [elementId] = useState(id || _.uniqueId(`${name}_`));

    const handleChange = (items) => {
        formProps.setValue(items);
    };

    return (
        <FormGroup fieldId={elementId} {...groupProps}>
            <ReorderableList
                id={elementId}
                items={formProps.value}
                onChange={handleChange}
                error={formProps.error}
                aria-labelledby={groupProps.label && `${elementId}_label`}
                aria-required={groupProps.mandatory}
                aria-invalid={!!formProps.error}
                aria-describedby={formProps.error && `${elementId}_error`}
                {...otherProps}
            />
        </FormGroup>
    );
};

ReorderableListField.propTypes = {
    name: PropTypes.string,
    id: PropTypes.string,

    // Passed down to ReorderableList
    description: PropTypes.string,
    noDataMessage: PropTypes.string,
};

export default withField(ReorderableListField);
