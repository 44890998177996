import React from 'react';
import { IconText } from '@premier/ui';

import './InvoiceItemsErrors.scss';

interface Props {
    errors: string[];
}

const InvoiceItemsErrors: React.FC<Props> = ({ errors }) => {
    return (
        <>
            {errors.map((error) => (
                <IconText alert className='invoice-items-error__message' key={error}>
                    {error}
                </IconText>
            ))}
        </>
    );
};

export default InvoiceItemsErrors;
