import { userRoles } from 'components/Routing';
import { PaymentSettingsPage, AcceptedCardsPage, BillerDetailsPage, ManageBillersPage, BusinessDetailsSettingsPage,
    MessagingLandingPage, MessagingRemindersPage, MessagingConfirmationsPage, MessagingReceiptsPage, MessagingRequestsPage,
    FraudSettingsPage, IServicesSettingsPage, EditCustomerReferencesPage, EditAdditionalPaymentsPage, ApiIntegrationPage, PrivateKeySettingsPage } from 'components/Settings';
import { RouteConfigurationMap } from 'components/Routing';
export const settingsRoute : RouteConfigurationMap = {
    /** Biller */
    root: {
        path:               '/settings/payment-settings',
        generatePath: () => '/msettings/payment-settings',
        roles:              [userRoles.billerConfig]
    },
    paymentSettings: {
        path:               '/settings/payment-settings',
        generatePath: () => '/settings/payment-settings',
        element: <PaymentSettingsPage />,
        title: 'Payment settings',
        roles:              [userRoles.billerConfig]
    },
    manageBillers: {
        path:               '/settings/payment-settings/billers',
        generatePath: () => '/settings/payment-settings/billers',
        element: <ManageBillersPage />,
        roles:              [userRoles.billerConfig]
    },
    billerDetails: {
        path:               '/settings/payment-settings/billers/:billerCode',
        element: <BillerDetailsPage />,
        generatePath: (billerCode: string) => `/settings/payment-settings/billers/${billerCode}`,
        title: 'Biller Details settings',
        roles:              [userRoles.billerConfig]
    },
    acceptedCards: {
        path:               '/settings/payment-settings/billers/:billerCode/accepted-cards',
        generatePath: (billerCode: string) => `/settings/payment-settings/billers/${billerCode}/accepted-cards`,
        element: <AcceptedCardsPage showAcceptedCards={true} />,
        title: 'Accepted cards and surcharge',
        roles:              [userRoles.billerConfig]
    },
    surcharges: {
        path:               '/settings/payment-settings/billers/:billerCode/surcharges',
        generatePath: (billerCode: string) => `/settings/payment-settings/billers/${billerCode}/surcharges`,
        element: <AcceptedCardsPage showAcceptedCards={false} />,
        title: 'Surcharges',
        roles:              [userRoles.billerConfig]
    },
    customerReferences: {
        path:               '/settings/payment-settings/billers/:billerCode/edit',
        generatePath: (billerCode: string) => `/settings/payment-settings/billers/${billerCode}/edit`,
        element: <EditCustomerReferencesPage />,
        title: 'Customer references',
        roles:              [userRoles.billerConfig]
    },
    additionalPayments: {
        path:               '/settings/payment-settings/billers/:billerCode/additional-payments',
        generatePath: (billerCode: string) => `/settings/payment-settings/billers/${billerCode}/additional-payments`,
        element: <EditAdditionalPaymentsPage />,
        title: 'Additional payments',
        roles:              [userRoles.additionalPayments]
    },
}

export const businessDetailsSettingsRoute : RouteConfigurationMap = {
    root: {
        path:               '/settings/business-details',
        generatePath: () => '/settings/business-details',
        element: <BusinessDetailsSettingsPage />,
        title: 'Business details',
        roles:              [userRoles.adminMenu]
    },
};

export const messagingRoute : RouteConfigurationMap = {
    root: {
        path:               '/settings/messaging',
        generatePath: () => '/settings/messaging',
        element: <MessagingLandingPage />,
        title: 'Messaging',
        roles:              [userRoles.messaging]
    },
    editReceipts: {
        path:               '/settings/messaging/receipts',
        generatePath: () => '/settings/messaging/receipts',
        element: <MessagingReceiptsPage />,
        title: 'Receipts',
        roles:              [userRoles.messaging]
    },
    editReminders: {
        path:               '/settings/messaging/reminders',
        generatePath: () => '/settings/messaging/reminders',
        element: <MessagingRemindersPage />,
        title: 'Reminders',
        roles:              [userRoles.messaging]
    },
    editConfirmations: {
        path:               '/settings/messaging/confirmations',
        generatePath: () => '/settings/messaging/confirmations',
        element: <MessagingConfirmationsPage />,
        title: 'Confirmations',
        roles:              [userRoles.messaging]
    }    ,
    editRequests: {
        path:               '/settings/messaging/requests',
        generatePath: () => '/settings/messaging/requests',
        element: <MessagingRequestsPage />,
        title: 'Requests',
        roles:              [userRoles.paymentRequestAdmin]
    }
};

export const fraudSettingsRoute : RouteConfigurationMap = {
    root: {
        path:               '/settings/fraud',
        generatePath: () => '/settings/fraud',
        element: <FraudSettingsPage />,
        title: 'Fraud management',
        roles:              [userRoles.fraudControl, userRoles.fraudBasic]
    },
};

export const apiIntegrationRouteRoute : RouteConfigurationMap = {
    root: {
        path:               '/settings/api-integration',
        generatePath: () => '/settings/api-integration',
        element: <ApiIntegrationPage />,
        title: 'API integration',
        roles:              [userRoles.apiConfiguration]
    },
    editPrivateKey: {
        path:               '/settings/api-integration/private-key',
        generatePath: () => '/settings/api-integration/private-key',
        element: <PrivateKeySettingsPage />,
        title: 'Apple Pay',
        roles:              [userRoles.apiConfiguration]
    },
};

export const iServicesSettingsRoute : RouteConfigurationMap = {
    root: {
        path:               '/settings/iServices',
        generatePath: () => '/settings/iServices',
        element: <IServicesSettingsPage />,
        title: 'i-Services',
        roles:              [userRoles.idbUpload]
    },
};