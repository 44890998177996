import * as actions from '../_actions/actionTypes';
import * as accountActions from 'components/Account/_actions/actionTypes';
import * as commonActions from 'components/Common/_actions/actionTypes';

export default function userReducer(state = {}, action) {

    switch (action.type) {
        case actions.GET_USERS_SUCCESS:
            return Object.assign({}, state, {
                searchResult: {
                    items: action.result.users,
                    count: action.result.resultCount
                }
            });

        case actions.GET_USER_ROLES_SUCCESS:
            return Object.assign({}, state, {
                userRoles: action.merchants
            });

        case actions.SAVE_MANAGE_USERS_PAGE_STATE:
            return Object.assign({}, state, {
                manageUsersPageState: action.pageState
            });

        case actions.GET_USER_SUCCESS:
            return Object.assign({}, state, {
                details: action.user
            });

        case accountActions.LOGOUT_USER_SUCCESS:
        case commonActions.CLEAR_SENSITIVE_DATA:
            return {
                asyncCount: state.asyncCount,
                isLoading: state.isLoading
            };
        default:
            return state;
    }
}
