import React, { forwardRef, useImperativeHandle, useRef } from 'react';

export interface FocusSelectorRef {
    open: () => void
}

const FileSelector = forwardRef(({
    onSelect
}: {
    onSelect: (files: FileList) => void
}, ref: React.ForwardedRef<FocusSelectorRef>) => {

    const inputRef = useRef<HTMLInputElement>(null);

    useImperativeHandle(ref, () => ({
        open: () => {
            if (!inputRef.current)
                return;
            // chrome won't trigger onChange a second time, if you select the same file
            // clear value to fix this
            inputRef.current.value = '';
            inputRef.current.click();
        }
    }));

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        var files = e.target.files;

        if (files?.length) {
            onSelect(files);
        }
    }

    return <>
        <input type='file' ref={inputRef} onChange={handleChange} style={{display:'none'}} />
    </>;
});

export default FileSelector;