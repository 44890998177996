import React, { useState, useEffect, useRef, useContext } from "react";
import {
    PaddedContainer,
    PageSection,
    PaginationControl,
    Button,
    FileSelector,
    Icon,
} from "@premier/ui";
import FraudRulesList, { fieldInfo } from "./FraudRulesList";
import {
    FraudRuleModel,
    PaginateRequestSearchFraudRulesInputFraudRuleSortFieldsSortFieldEnum as SortFieldEnum,
    PaginateRequestSearchFraudRulesInputFraudRuleSortFieldsOrderEnum as OrderEnum,
    UploadAntiFraudRulesFileResponse,
    KeyDescriptionBaseAntiFraudAction,
} from "packages/webapi-client";
import { FormContext, DropdownField } from "@premier/form";
// @ts-ignore
import { defaultPageSize, defaultPageSizeOptions } from "constants/billpay";
// @ts-ignore
import mediaPaths from "constants/mediaPaths";
import { FocusSelectorRef } from "packages/ui/src/FileSelector";
import FraudUploadDialog from "./FraudUploadDialog";
import { FieldError } from "api/mapErrors";
import "./FraudAdvancedForm.scss";

export type FileUploadState =
    | ""
    | "OVERWRITE_WARNING"
    | "INVALID_FILE_TYPE"
    | "SUCCESS"
    | "BACKEND_FILE_ERROR"
    | "BACKEND_GENERIC_ERROR"
    | "LOADING";
const allowedFileExtensions = [".TXT", ".DAT", ".CSV", ".ZIP"];

export const fraudAdvancedFormCopy = {
    uploadButton: "Select & upload file",
    uploadTitle: "Update fraud control rules",
    uploadSubtitle:
        <>
            <p>Once a new file is uploaded, all previous fraud control rules will be removed. Please note, Fraud Advanced rules will overwrite Fraud Basic rules.</p>
            You can get a guide for the format of the file here:&nbsp;
        </>,
    tableTitle: "Existing fraud control rules",
    tableSubtitle:
        "Allow rules are processed first and will override any block rules.",
    loadingState: "We are loading the new file...",
    fraudControlFormatLink: "Fraud Control Format",
};

export interface ValueLabel {
    label: string;
    value: string;
    disabled?: boolean;
}

interface Props {
    fraudRules: FraudRuleModel[];
    hasLoaded: boolean;
    hasFailed: boolean;
    pageIndex: number;
    pageSize: number;
    totalRules: number;
    noDataText: string;
    errorMessage: string;
    fraudTypeSources: KeyDescriptionBaseAntiFraudAction[];
    fraudActionSources: KeyDescriptionBaseAntiFraudAction[];
    onFilterChange: (
        pageIndex: number,
        pageSize: number,
        sortField: SortFieldEnum,
        order: OrderEnum,
        req: any
    ) => void;
    uploadFraudRules: (
        files: FileList
    ) => Promise<UploadAntiFraudRulesFileResponse>;
    pageInfoMessage?: string;
}

const defaultValues = {
    fraudRules: [],
    pageIndex: 0,
    totalRules: 1,
    fraudTypeSources: [],
    fraudActionSources: [],
    file: "" as FileUploadState,
    fileRef: null,
    filters: {},
    sort: { field: "Type" as SortFieldEnum, descending: false },
    backendErrorMessage: [],
};

const FraudAdvancedForm: React.FC<Props> = ({
    fraudRules = defaultValues.fraudRules,
    pageIndex = defaultValues.pageIndex,
    totalRules = defaultValues.totalRules,
    fraudTypeSources = defaultValues.fraudTypeSources,
    fraudActionSources = defaultValues.fraudActionSources,
    pageSize = defaultPageSize,
    noDataText,
    hasLoaded,
    hasFailed,
    errorMessage,
    onFilterChange,
    uploadFraudRules,
    pageInfoMessage,
}) => {
    const [currentPageIndex, setCurrentPageIndex] = useState<number>(pageIndex);
    const [currentPageSize, setCurrentPageSize] = useState<number>(pageSize);

    const [filters, setFilters] = useState<any>(defaultValues.filters);
    const [sort, setSort] = useState<{
        field: SortFieldEnum;
        descending: boolean;
    }>(defaultValues.sort);

    const [fileUploadState, setFileUploadState] = useState<FileUploadState>(
        defaultValues.file
    );
    const [fileName, setFileName] = useState<string>(defaultValues.file);
    const fileSelectorRef = useRef<FocusSelectorRef>(defaultValues.fileRef);
    const [isReloadingFraudRules, setIsReloadingFraudRules] = useState<boolean>(
        false
    );

    const [backendErrorMessages, setBackendErrorMessages] = useState<string[]>(
        defaultValues.backendErrorMessage
    );

    const formContext = useContext(FormContext);

    useEffect(
        () =>
            onFilterChange(
                currentPageIndex,
                currentPageSize,
                sort.field,
                sort.descending ? "DESCENDING" : "ASCENDING",
                filters
            ),
        [onFilterChange, currentPageIndex, currentPageSize, filters, sort]
    );

    const onPageSizeChange = (pageSize: number) => {
        setCurrentPageSize(pageSize);
        setCurrentPageIndex(0);
    };

    const refreshFraudRules = () => {
        // reset state and fire off get request
        setFileUploadState("");
        setCurrentPageSize(defaultPageSize);
        setCurrentPageIndex(defaultValues.pageIndex);
        setFilters(defaultValues.filters);
        setSort(defaultValues.sort);

        formContext.resetForm({
            [fieldInfo.action.name]: fieldInfo.action.defaultValue,
            [fieldInfo.type.name]: fieldInfo.type.defaultValue,
            [fieldInfo.lowField.name]: fieldInfo.lowField.defaultValue,
            [fieldInfo.highField.name]: fieldInfo.highField.defaultValue,
            pageSize: defaultPageSize,
        });

        onFilterChange(
            defaultValues.pageIndex,
            defaultPageSize,
            defaultValues.sort.field,
            defaultValues.sort.descending ? "DESCENDING" : "ASCENDING",
            defaultValues.filters
        );
        setIsReloadingFraudRules(false);
    };

    const onFileSelect = (files: FileList): void => {
        if (!files || files.length !== 1) {
            // invalid number of files
            return;
        }

        setFileName(files[0].name);
        const filename = files[0].name.toUpperCase();
        if (!allowedFileExtensions.some((ext) => filename.endsWith(ext))) {
            setFileUploadState("INVALID_FILE_TYPE");
            return;
        }
        setFileUploadState("LOADING");

        uploadFraudRules(files)
            .then((r) => {
                if (r.hasErrors) {
                    setFileUploadState("BACKEND_FILE_ERROR");

                    if (
                        r.uploadAntiFraudRulesValidationResult &&
                        r.uploadAntiFraudRulesValidationResult.length > 0 &&
                        r.uploadAntiFraudRulesValidationResult[0].errors
                    ) {
                        setBackendErrorMessages(
                            r.uploadAntiFraudRulesValidationResult[0].errors
                        );
                    } else {
                        setBackendErrorMessages(
                            defaultValues.backendErrorMessage
                        );
                    }
                } else {
                    // success state
                    setFileUploadState("SUCCESS");

                    // reset any error messages
                    setFileName(defaultValues.file);
                    setBackendErrorMessages(defaultValues.backendErrorMessage);

                    // set/reset state for the table
                    setIsReloadingFraudRules(true);
                }
            })
            .catch((errors) => {
                setFileUploadState("BACKEND_GENERIC_ERROR");

                if (errors) {
                    setBackendErrorMessages(
                        (errors as FieldError[])
                            .filter((e) => !!e.message)
                            .map((e) => e.message ?? "")
                    );
                }
            });
    };

    const convertFilterFormat = (
        list: KeyDescriptionBaseAntiFraudAction[]
    ): ValueLabel[] => {
        let result: ValueLabel[] = [{ value: "", label: "All" }];

        if (list) {
            const returnedFilters: ValueLabel[] = list.map(
                (element: KeyDescriptionBaseAntiFraudAction) => {
                    return Object.assign({
                        label: element.description,
                        value: element.key,
                    });
                }
            );
            result = result.concat(returnedFilters);
        }
        return result;
    };

    return (<>
        {pageInfoMessage && (
            <p>
                <Icon info inline />
                {pageInfoMessage}
            </p>
        )}
        <PaddedContainer>
            <PageSection>
                <div>
                    <div className="fraud-advanced-section-heading">
                        <h2>{fraudAdvancedFormCopy.uploadTitle}</h2>
                        <Button
                            onClick={() =>
                                setFileUploadState("OVERWRITE_WARNING")
                            }
                        >
                            {fraudAdvancedFormCopy.uploadButton}
                        </Button>
                    </div>
                    <p>
                        {fraudAdvancedFormCopy.uploadSubtitle}
                        <a
                            href={mediaPaths.fraudControlFileFormat}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {fraudAdvancedFormCopy.fraudControlFormatLink}
                        </a>
                    </p>
                </div>
                <FraudUploadDialog
                    fileUploadState={fileUploadState}
                    setFileUploadState={setFileUploadState}
                    onCloseSuccess={refreshFraudRules}
                    onClickFileUpload={() => fileSelectorRef.current?.open()}
                    fileName={fileName}
                    errorMessages={backendErrorMessages}
                ></FraudUploadDialog>
            </PageSection>
            <PageSection>
                <div>
                    <h2>{fraudAdvancedFormCopy.tableTitle}</h2>
                    <p>{fraudAdvancedFormCopy.tableSubtitle}</p>
                </div>
                <div className="row">
                    <div className="col-lg-5">
                        <div className="inline-label fraud-advanced-form-page-size-drop-down">
                            <DropdownField
                                //@ts-ignore
                                id="pageSize"
                                name="pageSize"
                                groupProps={{
                                    label: "Items per page",
                                    name: "pageSize",
                                }}
                                onChange={(e) => onPageSizeChange(e)}
                                options={defaultPageSizeOptions.map(
                                    (ps: number) => ({ value: ps, label: ps })
                                )}
                                defaultValue={defaultPageSize}
                            />
                        </div>
                    </div>
                </div>

                {hasFailed && (
                    <p className="fraud-advanced-error-message">
                        {errorMessage}
                    </p>
                )}

                <FraudRulesList
                    actionOptions={convertFilterFormat(fraudActionSources)}
                    typeOptions={convertFilterFormat(fraudTypeSources)}
                    onFilterChange={(values: any) => setFilters(values)}
                    fraudRules={
                        isReloadingFraudRules
                            ? defaultValues.fraudRules
                            : fraudRules
                    }
                    hasLoaded={hasLoaded}
                    noDataText={
                        isReloadingFraudRules
                            ? fraudAdvancedFormCopy.loadingState
                            : noDataText
                    }
                    sort={sort}
                    onSort={(field, descending) =>
                        setSort({ field: field as SortFieldEnum, descending })
                    }
                />

                <PaginationControl
                    currentPage={currentPageIndex + 1}
                    itemsPerPage={currentPageSize}
                    itemCount={totalRules}
                    onPageChanged={(page: number) =>
                        setCurrentPageIndex(page - 1)
                    }
                />
            </PageSection>

            <FileSelector ref={fileSelectorRef} onSelect={onFileSelect} />
        </PaddedContainer>
    </>);
};

export default FraudAdvancedForm;
