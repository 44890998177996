import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Button, Icon, PaddedContainer, TooltipTrigger, Tooltip, Link, LoadingIndicator, Row } from "@premier/ui";
import windowUtil from "@premier/utils/window";
import text from "platforms/current/constants/text";
import QrCodeForPaymentUrlModal from "./QrCodeForPaymentUrlModal";
import SecurityUtil from "@premier/utils/security";
// @ts-ignore
import { userRoles, PlatformRoutesConfiguration } from "components/Routing";
// @ts-ignore
import { Navigate } from "react-router-dom";
import { useApiCall, APICallRequestState } from "components/Common";
import { paymentRequestApi } from "api";
import { PaymentUrlRequestModelPaymentFlowTypeEnum } from "@premier/webapi-client";
import "./PaymentPageUrlSection.scss";

interface PaymentPageUrlSectionProps {
    billerCode? : string;
    paymentFlowType? : PaymentUrlRequestModelPaymentFlowTypeEnum;
    billerFullName? : string;
    hideEditBrandingRedirect? : boolean;
}

enum ComponentStateValues {
    IDLE,
    DISPLAY_QR_MODAL,
    REDIRECT_TO_BRANDING_PAGE
}

const PaymentPageUrlSection : React.FC<PaymentPageUrlSectionProps>  = ({ billerCode, paymentFlowType, billerFullName, hideEditBrandingRedirect }) => {
    const authenticatedUser = useSelector((state: any) => state.accounts.users.authenticatedUser);
    const [componentState, setComponentState] = useState<ComponentStateValues>(ComponentStateValues.IDLE);
    const [allowQrCodeCapture] = useState<boolean>(SecurityUtil.hasAccess([userRoles.qrCode], authenticatedUser));
    const [allowEditBrandingRedirect] = useState<boolean>(SecurityUtil.hasAccess([userRoles.internet], authenticatedUser) && !hideEditBrandingRedirect);

    const [paymentPageUrl, paymentPageUrlStatus] = useApiCall(
        () => {
            if (billerCode)
            {
                // @ts-ignore
                return paymentRequestApi.getPaymentPageUrl(paymentFlowType, null, [{ HppQueryType: "BillerCode", QueryValue: billerCode }]);
            } else {
                // @ts-ignore
                return paymentRequestApi.getPaymentPageUrl(paymentFlowType, null, []);
            }
        }, [billerCode, paymentFlowType]
    );

    const getPageType = (paymentFlowType? : PaymentUrlRequestModelPaymentFlowTypeEnum) => {
        switch (paymentFlowType) {
            case "PaymentPlan":
                return "payment plan page";
            default:
                return "payment page";
        }
    };

    const [pageType] = useState(getPageType(paymentFlowType));

    const getQrModalTitle = (paymentFlowType?: PaymentUrlRequestModelPaymentFlowTypeEnum, billerFullName? : string) => {
        switch (paymentFlowType) {
            case "PaymentPlan":
                return "QR code for payment plan";
            default:
                return `QR code for ${billerFullName ? "biller code" : "business"}`;
        }
    };

    const getQrModalSubText = (pageType : string, paymentFlowType?: PaymentUrlRequestModelPaymentFlowTypeEnum, billerFullName? : string) => {
        return `The following is the QR code of the ${pageType} for ${billerFullName ? billerFullName : "your business"}.
        You can print this out and show your customer to facilitate the ${paymentFlowType === "PaymentPlan" ? "payment plan sign-up" :
        "payment"}.`;
    };

    // Push allows redirects to be recorded in history, so if you press the Back button
    // on the Branding page, it will return to the correct page
    if (componentState === ComponentStateValues.REDIRECT_TO_BRANDING_PAGE) {
        // @ts-ignore
        return <Navigate to={PlatformRoutesConfiguration.designerRoute.editBillerBranding.generatePath(billerCode)} />;
    }

    return (
        <>
            <PaddedContainer
                title={`${pageType[0].toUpperCase() + pageType.slice(1)} URL for ${
                    billerFullName ? billerFullName : "business"
                }`}
                noDivider
                button={
                    <TooltipTrigger tipId="url-copied-tip" data-place="bottom" data-event="click">
                        <Button>Copy URL</Button>
                    </TooltipTrigger>
                }
            >
                <div className="payment-url">
                    The {text.platformName} {pageType} URL for {billerFullName ? billerFullName : "your business"} is:{" "}
                    <br />
                    {paymentPageUrlStatus === APICallRequestState.SUCCESSFUL ? (
                        <Link to={paymentPageUrl ? paymentPageUrl : ""} newWindow>
                            {paymentPageUrl}
                        </Link>
                    ) : (
                        <LoadingIndicator />
                    )}
                </div>

                <Row divided className="instruction-box">
                    {allowQrCodeCapture ? (
                        <div className={allowEditBrandingRedirect ? "col-sm-6" : "col-sm-12"}>
                            <b>Obtain QR code</b>
                            <p>
                                To view and download the QR code for this {pageType}, please click{" "}
                                <Button
                                    link
                                    onClick={() => {
                                        setComponentState(ComponentStateValues.DISPLAY_QR_MODAL);
                                    }}
                                >
                                    {" "}
                                    here
                                </Button>
                            </p>
                        </div>
                    ) : null}
                    {allowEditBrandingRedirect ? (
                        <div className="col-sm-6">
                            <b>Update Logo</b>
                            <p>
                                To change logo for your {pageType}, please go to{" "}
                                <Button
                                    link
                                    onClick={() => {
                                        setComponentState(ComponentStateValues.REDIRECT_TO_BRANDING_PAGE);
                                    }}
                                >
                                    {" "}
                                    Payment page within Design
                                </Button>
                            </p>
                        </div>
                    ) : null}
                </Row>

                <Tooltip
                    id="url-copied-tip"
                    delayHide={3000}
                    afterShow={() => paymentPageUrl && windowUtil.copyTextToClipboard(paymentPageUrl)}
                >
                    <Icon tick /> URL copied
                </Tooltip>
            </PaddedContainer>

            {componentState === ComponentStateValues.DISPLAY_QR_MODAL && (
                <QrCodeForPaymentUrlModal
                    title={getQrModalTitle(paymentFlowType, billerFullName)}
                    downloadFilename={`qr_code_${billerCode ?? "_payment_page"}.png`}
                    show={componentState === ComponentStateValues.DISPLAY_QR_MODAL}
                    onClose={() => setComponentState(ComponentStateValues.IDLE)}
                    subText={getQrModalSubText(pageType, paymentFlowType, billerFullName)}
                    billerCode={billerCode}
                    paymentFlowType={paymentFlowType}
                    altText={"QR Code for " + pageType}
                />
            )}
        </>
    );
};


export default PaymentPageUrlSection;
