import httpRequest from './httpRequest';
class WebPlatformConfigurationApi {

    static getPlatformConfiguration(){
        return new Promise((resolve, reject) => {
            httpRequest.get('/Utilities/GetPlatformConfiguration').then(response => {
                resolve(response);
            }).catch(err => {
                reject(err);
            })
        })
    }
};


export default WebPlatformConfigurationApi;