import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';

import { MethodDialog, AppendFileConfirmationDialog, UploadErrorDialog, FileWillUploadModal, UploadSuccessModal } from 'components/Batch';

import {PlatformRoutesConfiguration} from 'components/Routing';
import * as batchActions from 'components/Batch/_actions/batchActions';


const Step = {  // One of these dialogs will be visible at a time
    METHOD: 'method',
    CONFIRM_UPLOAD: 'confirmUpload',
    UPLOADING: 'uploading',
    ERROR: 'error',
    SUCCESS: 'success',
}

const AppendRecordsDialogs = ({
    show, onClose, //functions
    batchFile, //data
    actions  //actions
}) => {

    const [step, setStep] = useState(Step.METHOD);
    const [redirectToManualEntry, setRedirectToManualEntry] = useState(false);
    const [files, setFiles] = useState([]);
    const [reupload, setReupload] = useState(false);
    const [uploadResult, setUploadResult] = useState([null,null]);  // [genericErrors, batchFileResult]


    function reset() {
        setStep(Step.METHOD);
        setFiles([]);
    }
    function handleCancel() {
        reset();
        onClose();
    }

    function handleAddRecordsByFileUpload(files) {
        setFiles(files);
        setStep(Step.CONFIRM_UPLOAD);
    }

    function getFilename() {
        return files && files.length > 0 ? files[0].name : '';
    }

    function startFileUpload() {
        setStep(Step.UPLOADING);
        setUploadResult([null, null]);

        actions.appendBatchLibraryFile(files, batchFile.fileId).then((response) => {
            if(response.hasErrors) {
                setUploadResult([null, response.batchFileValidationResult]);
                setStep(Step.ERROR);
            } else {
                if(show)
                    setStep(Step.SUCCESS);
                else
                    handleDone();
            }
        }).catch(errors => {
            setUploadResult([errors, null]);
            setStep(Step.ERROR);
        });
    }

    function handleRetry() {
        reset();
        setReupload(true);  // Open file select dialog
        setTimeout(() => setReupload(false), 0);
    }

    function handleDone() {
        handleCancel();
    }


    if(redirectToManualEntry)
        return <Navigate to={PlatformRoutesConfiguration.transactionRoute.batchAppendRecords.generatePath(batchFile.fileId)} />;

    if(!show)
        return null;

    return (<>
        <MethodDialog append
            show={step === Step.METHOD}
            onCancel={handleCancel}
            onManual={() => setRedirectToManualEntry(true)}
            onUpload={handleAddRecordsByFileUpload}
            uploadNow={reupload}
        />

        <AppendFileConfirmationDialog
            show={step === Step.CONFIRM_UPLOAD}
            batchLibraryFilename={batchFile.filename}
            filename={getFilename()}
            onYes={startFileUpload}
            onCancel={handleCancel}
        />

        <FileWillUploadModal append
            show={step === Step.UPLOADING}
            onHide={onClose}
            filename={getFilename()}
            batchLibraryFileName={batchFile.filename}
        />

        <UploadErrorDialog
            show={step === Step.ERROR}
            filename={getFilename()}
            errors={uploadResult[0]}
            batchFileResult={uploadResult[1]}
            onRetry={handleRetry}
            onCancel={handleCancel}
        />

        <UploadSuccessModal append
            show={step === Step.SUCCESS}
            onHide={handleDone}
            filename={getFilename()}
            batchLibraryFileName={batchFile.filename}
        />

    </>);
};

AppendRecordsDialogs.propTypes = {
    batchFile: PropTypes.object.isRequired,
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(batchActions, dispatch)
    };
}

export default connect(null, mapDispatchToProps)(AppendRecordsDialogs);
