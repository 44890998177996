import React from 'react';
import { ResponsiveTable } from '@premier/ui';
import { ResponsiveTableRecordPartial, ResponsiveTableColumn } from 'packages/ui/src/ResponsiveTableTypes';

interface Resource extends ResponsiveTableRecordPartial {
    resourceName : string;
    pathToResource: string;
}

interface DevelopersResourceTableProps {
    data: Resource[]
}

const DevelopersResourceTable : React.FC<DevelopersResourceTableProps> = ({ data }) => {
    const pathTypeResolver = (path : string) => {
        const fileExt = path.split('.').pop();
        // Add new file extensions as required
        switch (fileExt) {
            case "pdf":
                return "PDF document"
            default:
                return "Link";
        }
    }

    const columns : ResponsiveTableColumn<Resource>[] = [
        {
            label: "Resource name",
            getter: (r : Resource) =>
                <a href={ r.pathToResource } target="_blank" rel="noopener noreferrer">
                    <span className='resource-name'>{r.resourceName}</span>
                </a>,
        },
        {
            label: "Resource type",
            getter:  (r : Resource) => pathTypeResolver(r.pathToResource)
        }
    ];

    return (
        <ResponsiveTable
            data={data}
            columns={columns}
        />
    )
}

export default DevelopersResourceTable;