import httpRequest from './httpRequest';

class ReportsApi {
    static getDailyReports(dateRange, pageNo, resultsPerPage, childMerchantNumber) {
        return new Promise((resolve, reject) => {
            var model = {
                fromDate: dateRange[0],
                toDate: dateRange[1],
                childMerchantNumber
            };

            httpRequest.post(`/Reports/GetDailyReports?pageNo=${pageNo}&resultsPerPage=${resultsPerPage}`, model).then(response => {
                var reports = response;
                resolve( reports );
            }).catch(err => {
                reject(err);
            })
        });
    };

    static getDailyReport(filename, childMerchantNumber) {
        return new Promise((resolve, reject) => {
            var model={
                filename,
                childMerchantNumber
            }
            httpRequest.post('/Reports/GetDailyReport', model).then(response => {
                var report = response;
                resolve(report);
            }).catch(err => {
                reject(err);
            })
        });
    };
}

export default ReportsApi;