import { userRoles } from 'components/Routing';
import { ViewUserPage, EditUserPage, ManageUsersPage, NewUserPage } from 'components/Settings';
import { RouteConfigurationMap } from 'components/Routing';

export const usersRoute : RouteConfigurationMap = {
    root: {
        path:               '/settings/users',
        generatePath: () => '/settings/users',
        roles:              [userRoles.userManagement]
    },
    manageUsers: {
        path:               '/settings/users',
        generatePath: () => '/settings/users',
        element: <ManageUsersPage />,
        title: 'Manage users',
        roles:              [userRoles.userManagement]
    },
    newUser: {
        path:               '/settings/users/new-user',
        generatePath: () => '/settings/users/new-user',
        element: <NewUserPage />,
        title: 'New user',
        roles:              [userRoles.userManagement]
    },
    viewUser: {
        path:                 '/settings/users/:id',
        generatePath: (id: number) => `/settings/users/${id}`,
        element: <ViewUserPage />,
        title: 'User details',
        roles:                [userRoles.userManagement]
    },
    editUser: {
        path:                 '/settings/users/:id/edit',
        generatePath: (id: number) => `/settings/users/${id}/edit`,
        element: <EditUserPage />,
        title: 'User details',
        roles:                [userRoles.userManagement]
    },
};