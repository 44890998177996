import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { RootState } from "store/store";
import { Dispatch } from "redux";

import { Form, SubmitButton, DropdownField, validate, InputField, EmailAddressField, PhoneNumberField, RadioField, CurrencyField, FormErrorList } from "@premier/form";
import { ButtonContainer, Button, LoadingIndicator, IconText } from "@premier/ui";
import { MerchantDropdownLegacy } from "components/Common";

// @ts-ignore
import labels from "constants/labels";
// @ts-ignore
import errorMaps from "constants/errorMaps";

import * as billpayUtil from "@premier/utils/billpay";
import { MerchantRolesParam } from "@premier/utils/billpay";
import countryUtil from "@premier/utils/country";
import currencyUtil from "@premier/utils/currency";

import * as userActions from "components/Settings/_actions/userActions";
import classNames from "classnames";
import { User } from "models/User";
import { FieldError } from "api/mapErrors";
import { MerchantModel } from "packages/webapi-client";

type Props = {
    /**Name fo the form */
    name?: string;

    /**User details object to display in the form */
    userDetails?: User;

    /**Passed-in to hide form if loading */
    isLoading?: boolean;

    /**Function to run when the form is submitted*/
    onSubmit: (values: User) => void;

    /**Function to run when the form cancel button is hit. */
    onCancel: () => void;

    userActions: any;
    userRoles: MerchantRolesParam[];
    merchant: MerchantModel;
    errors: FieldError[];
}

/** Form consisting of fields to input / edit a user's detials. */
const UserDetailsForm = ({
    name, //form props
    userDetails, //data
    userRoles, merchant, isLoading, errors, //state values
    onSubmit, onCancel, //functions
    userActions //API calls
} : Props) => {
    // if userDetails populated, currentMerchantNumber will be the user's details merchant
    const [currentMerchantNumber, setCurrentMerchantNumber] = useState(userDetails ? userDetails.merchantNumber : merchant.merchantNumber);
    const [statusOption, setStatusOption] = useState(userDetails ? userDetails.isActive ? true : false :true);
    const [roleOption, setRoleOption] = useState(userDetails ? userDetails.roleId : 0);

    useEffect(() => {
        userActions.getUserRoles();
    }, [userActions]);


    function handleSubmit(values: User, context: any) {
        onSubmit(values);
    }

    function handleCancel() {
        onCancel && onCancel();
    }

    function handleMerchantChanged(merchantNumber: string, context: any) {
        setCurrentMerchantNumber(merchantNumber);

        const roles = getMerchantUserRoles(merchantNumber);
        const roleIndex = roles.findIndex(x => x.value === context.getValue("role"));

        if (roleIndex >= 0)  //findIndex returns -1 if index is not found
            context.setValue("role", roles[roleIndex].value);
        else
            context.setValue("role", roles[0].value);
    }

    function getMerchantUserRoles(merchantNumber: string) {
        return billpayUtil.getUserRoleOptions(userRoles, merchantNumber);
    }

    function handleRoleChanged(role: number) {
        setRoleOption(role);
    }

    function getInitialValues() {
        const roles = getMerchantUserRoles(currentMerchantNumber  ?? "");

        if (userDetails) {
            return {
                merchantNumber: currentMerchantNumber,
                firstName: userDetails.firstName,
                lastName: userDetails.lastName,
                username: userDetails.userName,
                emailAddress: userDetails.emailAddress,
                mobile: userDetails.mobileNumber ? billpayUtil.formatPhoneApiStringToObject(userDetails.mobileNumber) : { iddCode: countryUtil.getIddCode(merchant.countryCode) },
                phone: userDetails.phoneNumber ? billpayUtil.formatPhoneApiStringToObject(userDetails.phoneNumber) : { iddCode: countryUtil.getIddCode(merchant.countryCode) },
                status: userDetails.isActive ? 1 : 0,
                role: userDetails.roleId,
                refundAmount: userDetails.dailyRefundAmount || userDetails.dailyRefundAmount === 0 ? currencyUtil.fromApiValue(userDetails.dailyRefundAmount).toString() : "",
                refundLimit: userDetails.dailyRefundCount || userDetails.dailyRefundCount === 0 ? userDetails.dailyRefundCount.toString() : "",
            };
        }

        return {
            merchantNumber: currentMerchantNumber,
            phone: { iddCode: countryUtil.getIddCode(merchant.countryCode) },
            mobile: { iddCode: countryUtil.getIddCode(merchant.countryCode) },
            status: 1,
            role: roles[0].value,
        };

    }

    const showMessage=(
        statusOption===false && roleOption===6 ? (
            <div className={classNames("form-group", "background-white")}>
                <p className="paragraph-margin">
                    <IconText alert><b>Are you sure you want to make this user Inactive?</b></IconText>
                </p>
                <p className="paragraph-margin">Making this user inactive may stop all API transactions for this merchant account from being processed. Please contact support for more information.</p>

            </div>) : null
    );

    function handleStatusOptionsChanged(value: boolean) {
        setStatusOption(value);
    }

    const statusOptions = [
        { label: "Active", value: 1 },
        { label: "Inactive", value: 0 }
    ];



    if (isLoading || !userRoles)
        return <LoadingIndicator />;


    return (
        <Form
            name={name || "UserDetailsForm"}
            initialValues={getInitialValues()}
            initialValidation={{
                firstName: validate().required(),
                lastName: validate().required(),
                username: validate().required(),
                emailAddress: validate().required().email(),
                refundLimit: validate().if((value) => value !== "" && value != null,
                    validate().when(value => (value >= 0), "Daily allowed refunds cannot be a negative number")
                ),
                refundAmount: validate().if((value) => value !== "" && value != null,
                    validate().when(value => (value >= 0), "Daily refund amount cannot be a negative number")
                )
            }}
            onSubmit={handleSubmit}
            errors={errors}
            errorMaps={errorMaps}
        >
            {!userDetails &&
                <MerchantDropdownLegacy
                    onChange={handleMerchantChanged}
                />
            }

            <InputField name="firstName" label={labels.firstName} maxLength={50} />
            <InputField name="lastName" label={labels.lastName} maxLength={50} />
            <InputField name="username" label={labels.loginUsername} maxLength={50} />
            <EmailAddressField name="emailAddress" label={labels.email} />
            <PhoneNumberField name="mobile" label={labels.mobile} />
            <PhoneNumberField name="phone" label={labels.phone} />

            <RadioField inline name="status" label="Status" options={statusOptions} onChange={handleStatusOptionsChanged} />
            {showMessage}

            <DropdownField
                name="role"
                label="User role"
                options={getMerchantUserRoles(currentMerchantNumber ?? "")} onChange={handleRoleChanged}
            />

            <InputField name="refundLimit" type="number" label="Daily allowed refunds" placeholder="Unlimited" />
            <CurrencyField name="refundAmount" label="Daily refund amount" placeholder="Unlimited" />

            <ButtonContainer>
                <SubmitButton>Save</SubmitButton>
                <Button onClick={handleCancel}>Cancel</Button>
            </ButtonContainer>

            <FormErrorList />
        </Form>
    );
};

function mapStateToProps(state: RootState) {
    return {
        userRoles: state.settings.user.userRoles,
        merchant: state.accounts.users.merchant,
        errors: state.settings.user.errors
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        userActions: bindActionCreators(userActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserDetailsForm);
