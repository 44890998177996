import classNames from "classnames";
import "./Divider.scss";

type Props = {
    className?: string;
}

const Divider = ({
    className
}: Props) => {
    return (
        <div className={classNames("divider", className)}></div>
    );
};

export default Divider;