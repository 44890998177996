import React from 'react';
import { Currency } from '@premier/ui';
import withRangeField from './withRangeField';

const CurrencyRangeField = (props) => {
    return (
        <Currency {...props} />
    );
};

// For the PropTypes, see the rangeComponent.propTypes in withRangeField.js

export default withRangeField(CurrencyRangeField, ['Min amount', 'Max amount']);
