import { BankAccountForm, Form } from "@premier/form";
import { PageSection, SingleColumnFormContainer, IconText } from "@premier/ui";

/** Sub-form used for a user to change bank account detials. Used for the contact us page */
const ChangeBankAccountForm = () => {
    return (
        <PageSection>
            <h2>New bank account details below</h2>
            <IconText info>Enter CommBank account details below. If it's a non CommBank account, we'll send you a form by email. Your Business Owners/Directors will need to sign the form.</IconText>
            <SingleColumnFormContainer>
                <Form
                    name="changeBankAccountForm"
                    initialValues={{
                        bankAccountInfo: {
                            bsbNumber: "",
                            accountNumber: "",
                            bankAccountName: "" },

                    }}>
                    <BankAccountForm name="bankAccountInfo" required={false} />
                </Form>
            </SingleColumnFormContainer>
        </PageSection>
    );
};

export default ChangeBankAccountForm;
