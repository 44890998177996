import { Button, Dialog } from '@premier/ui';
import { FaExclamationTriangle } from 'react-icons/fa';

type Props = {
    show: boolean,
    onClose: () => {},
    handleDeleteConfirmed: () => {},
}

const BatchJobWarningDeleteDialog = ({
    show,
    onClose,
    handleDeleteConfirmed
}: Props) => {

    return (
        <Dialog show={show}
            title='Delete this batch file?'
            icon={<div><FaExclamationTriangle size={48} color="orange" /> </div>}
            footerButtons={<>
                <Button primary onClick={handleDeleteConfirmed}>Delete</Button>
                <Button onClick={onClose}>Cancel</Button>
            </>}
        >
        </Dialog>

    );

}

export default BatchJobWarningDeleteDialog;
