import { ReactNode } from "react";
import { CardContainer, DescriptionList, Divider } from "@premier/ui";
import { NameValue } from "models";

type Props = {
    title: string;
    items: any[];
    printView?: boolean;
    children?: ReactNode;
    additionalPaymentAmounts?: NameValue<string>[];
}

const BaseTransactionDetailCategory = ({
    title, items, children, additionalPaymentAmounts, //data
    printView, //logic render
}: Props) => {

    return (
        <CardContainer header={title}>
            <DescriptionList items={items} hideBlankValue inlineXs={printView} />
            {children}
            {additionalPaymentAmounts && additionalPaymentAmounts.length > 0
                && <>
                    <Divider />
                    <DescriptionList items={additionalPaymentAmounts} hideBlankValue inlineXs={printView} />
                </>
            }
        </CardContainer>
    );
};

export default BaseTransactionDetailCategory;
