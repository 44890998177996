import * as actions from './actionTypes';
import { settlementApi } from 'api';


//#region ========== Actions ===========
export function getSettlementReportRequested() {
    return { type: actions.GET_SETTLEMENT_REPORT_REQUESTED }
}
export function getSettlementReportSuccess(report) {
    return {type: actions.GET_SETTLEMENT_REPORT_SUCCESS, report};
}
export function getSettlementReportFailed(err) {
    return {type: actions.GET_SETTLEMENT_REPORT_FAILED, err};
}

export function getSettlementExportTypesRequested() {
    return { type: actions.GET_SETTLEMENT_EXPORT_TYPES_REQUESTED }
}
export function getSettlementExportTypesSuccess(exportTypes) {
    return {type: actions.GET_SETTLEMENT_EXPORT_TYPES_SUCCESS, exportTypes};
}
export function getSettlementExportTypesFailed(err) {
    return {type: actions.GET_SETTLEMENT_EXPORT_TYPES_FAILED, err};
}

export function getSettlementExportColumnsRequested() {
    return { type: actions.GET_SETTLEMENT_EXPORT_COLUMNS_REQUESTED }
}
export function getSettlementExportColumnsSuccess(exportColumns) {
    return {type: actions.GET_SETTLEMENT_EXPORT_COLUMNS_SUCCESS, exportColumns};
}
export function getSettlementExportColumnsFailed(err) {
    return {type: actions.GET_SETTLEMENT_EXPORT_COLUMNS_FAILED, err};
}

export function getSettlementExportOptionsRequested() {
    return { type: actions.GET_SETTLEMENT_EXPORT_OPTIONS_REQUESTED }
}
export function getSettlementExportOptionsSuccess(exportOptions) {
    return {type: actions.GET_SETTLEMENT_EXPORT_OPTIONS_SUCCESS, exportOptions};
}
export function getSettlementExportOptionsFailed(err) {
    return {type: actions.GET_SETTLEMENT_EXPORT_OPTIONS_FAILED, err};
}

export function downloadSettlementExportRequested() {
    return { type: actions.DOWNLOAD_SETTLEMENT_EXPORT_REQUESTED }
}
export function downloadSettlementExportSuccess() {
    return {type: actions.DOWNLOAD_SETTLEMENT_EXPORT_SUCCESS};
}
export function downloadSettlementExportFailed(err) {
    return {type: actions.DOWNLOAD_SETTLEMENT_EXPORT_FAILED, err};
}

export function downloadSettlementSummaryRequested() {
    return { type: actions.DOWNLOAD_SETTLEMENT_SUMMARY_REQUESTED }
}
export function downloadSettlementSummarySuccess() {
    return {type: actions.DOWNLOAD_SETTLEMENT_SUMMARY_SUCCESS};
}
export function downloadSettlementSummaryFailed(err) {
    return {type: actions.DOWNLOAD_SETTLEMENT_SUMMARY_FAILED, err};
}

//#endregion

//#region ========== Thunks ==========

export function getSettlementReport(childMerchantNumber, dateRange) {
    return function(dispatch) {
        dispatch(getSettlementReportRequested());
        return settlementApi.getSettlementReport(childMerchantNumber, dateRange).then((result) => {
            dispatch(getSettlementReportSuccess(result));
            return result;
        }).catch(err => {
            dispatch(getSettlementReportFailed(err));
            throw err;
        });
    }
}

export function getSettlementExportTypes() {
    return function(dispatch) {
        dispatch(getSettlementExportTypesRequested());
        return settlementApi.getSettlementExportTypes().then((result) => {
            dispatch(getSettlementExportTypesSuccess(result));
            return result;
        }).catch(err => {
            dispatch(getSettlementExportTypesFailed(err));
            throw err;
        });
    }
}

export function getSettlementExportColumns(reportCode) {
    return function(dispatch) {
        dispatch(getSettlementExportColumnsRequested());
        return settlementApi.getSettlementExportColumns(reportCode).then((result) => {
            dispatch(getSettlementExportColumnsSuccess(result));
            return result;
        }).catch(err => {
            dispatch(getSettlementExportColumnsFailed(err));
            throw err;
        });
    }
}

export function getSettlementExportOptions(reportCode) {
    return function(dispatch) {
        dispatch(getSettlementExportOptionsRequested());
        return settlementApi.getSettlementExportOptions(reportCode).then((result) => {
            dispatch(getSettlementExportOptionsSuccess(result));
            return result;
        }).catch(err => {
            dispatch(getSettlementExportOptionsFailed(err));
            throw err;
        });
    }
}

export function downloadSettlementExport(options) {
    return function(dispatch) {
        dispatch(downloadSettlementExportRequested());
        return settlementApi.downloadSettlementExport(options).then((result) => {
            dispatch(downloadSettlementExportSuccess(result));
            return result;
        }).catch(err => {
            dispatch(downloadSettlementExportFailed(err));
            throw err;
        });
    }
}

export function downloadSettlementSummary(options) {
    return function(dispatch) {
        dispatch(downloadSettlementSummaryRequested());
        return settlementApi.downloadSettlementSummary(options).then((result) => {
            dispatch(downloadSettlementSummarySuccess(result));
            return result;
        }).catch(err => {
            dispatch(downloadSettlementSummaryFailed(err));
            throw err;
        });
    }
}


//#endregion