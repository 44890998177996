import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import { Modal, LoadingIndicator } from '@premier/ui';

import * as paymentActions from 'components/Transactions/_actions/paymentActions';

const GetSurchargeInfoStep = ({
    paymentActions, // actions
    payment, // data
    onSurchargeProcessed, onErrorProcessing // functions
}) => {

    // biller isn't being passed into this, through the props
    useEffect(() => {
        let cancel = false;

        paymentActions.getNewPaymentSurcharge({
            childMerchantNumber: payment.billerCodeForm.childMerchantNumber,
            billerCode: payment.billerCodeForm.billerCode,
            amount: payment.amount,
            cardBin: payment.card.cardNumber
        }).then(result => {
            if (cancel)
                return;

            if (onSurchargeProcessed) {
                onSurchargeProcessed(result);
            }
        }).catch(err => {
            if (cancel)
                return;

            if (onErrorProcessing) {
                onErrorProcessing();
            }
        });
        return () => { cancel = true; }
    }, []);

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>
                    <LoadingIndicator></LoadingIndicator>
                    <h1>Processing</h1>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            </Modal.Body>
        </>
    );
}

GetSurchargeInfoStep.propTypes = {
    onErrorProcessing: PropTypes.func,
    onSurchargeProcessed: PropTypes.func,
    payment: PropTypes.object
};

function mapDispatchToProps(dispatch){
    return {
        paymentActions: bindActionCreators(paymentActions, dispatch)
    }
}

export default connect(null, mapDispatchToProps)(GetSurchargeInfoStep);