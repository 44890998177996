import { ApiResult, mapResultErrors } from "./mapErrors";
import { config, resultHandler, emptyResultHandler, mapFromResult } from "./util";
import {
    PaginatedListInvoiceSearchResponseModel,
    InvoiceConfigModel,
    InvoicesApiFactory,
    InvoiceModel,
    PaginateRequestInvoiceSearchInputModelInvoiceSearchSortFields,
    KeyDescriptionListInvoiceStatusFilter,
    GetInvoiceRequest,
    ApiResponseInvoiceModel,
    GetInvoicePaymentsRequest,
    ApiResponseListInvoicePaymentModel,
    CancelInvoiceRequest,
    ResendInvoiceRequest,
    ApiResponse,
    Biller,
} from "@premier/webapi-client";
import { AxiosPromise } from "axios";
import { mapBillerFromDto } from "./billerApi";

const invoicesApi = InvoicesApiFactory(config);

class InvoicesApi {
    static getConfig(): Promise<ApiResult<InvoiceConfigModel>> {
        return invoicesApi
            .invoicesGetInvoiceConfig()
            .then(...resultHandler)
            .then((r) => mapResultErrors(r));
    }

    static getInvoiceBillers(): Promise<ApiResult<Biller[]>> {
        return invoicesApi
            .invoicesGetInvoiceBillers()
            .then(...resultHandler)
            .then((r) => mapResultErrors(r))
            .then((r) =>
                mapFromResult(r, (billers) => billers.map(mapBillerFromDto))
            );
    }

    static updateConfig(settings: InvoiceConfigModel): Promise<ApiResult<void>> {
        return invoicesApi
            .invoicesUpdateInvoiceConfig(settings)
            .then(...emptyResultHandler)
            .then((r) => mapResultErrors(r));
    }

    static getInvoiceStatusSources(options?: any): Promise<ApiResult<KeyDescriptionListInvoiceStatusFilter>> {
        return invoicesApi
            .invoicesGetInvoiceStatusSources(options)
            .then(...resultHandler)
            .then((r) => mapResultErrors(r));
    }

    static searchInvoices(
        pagedRequest: PaginateRequestInvoiceSearchInputModelInvoiceSearchSortFields,
        options?: any
    ): Promise<ApiResult<PaginatedListInvoiceSearchResponseModel>> {
        return invoicesApi
            .invoicesSearchInvoices(pagedRequest, options)
            .then(...resultHandler)
            .then((r) => mapResultErrors(r));
    }

    static calculateInvoiceTotals(model: InvoiceModel): Promise<ApiResult<InvoiceModel>> {
        return invoicesApi
            .invoicesCalculateInvoiceTotals(model)
            .then(...resultHandler)
            .then((r) => mapResultErrors(r));
    }

    static createInvoice(model: InvoiceModel): Promise<ApiResult<void>> {
        return invoicesApi
            .invoicesCreateInvoice(model)
            .then(...emptyResultHandler)
            .then((r) => mapResultErrors(r));
    }

    static getInvoice(request: GetInvoiceRequest): AxiosPromise<ApiResponseInvoiceModel> {
        return invoicesApi.invoicesGetInvoice(request);
    }

    static getInvoicePayments(request: GetInvoicePaymentsRequest): AxiosPromise<ApiResponseListInvoicePaymentModel> {
        return invoicesApi.invoicesGetInvoicePayments(request);
    }

    static resendInvoice(request: ResendInvoiceRequest): AxiosPromise<ApiResponse> {
        return invoicesApi.invoicesResendEmail(request);
    }

    static cancelInvoice(request: CancelInvoiceRequest): AxiosPromise<ApiResponse> {
        return invoicesApi.invoicesCancelInvoice(request);
    }

}

export default InvoicesApi;
