import { useState, useEffect } from "react";
import _ from "lodash";

import { CardContainer, IconText, Button } from "@premier/ui";
import { TokenSelector } from "components/DataVault";

import "./AddPaymentMethodCard.scss";
import { Token } from "models/Token";

type Props = {
    /** property of the Payment Method already linked to this resource */
    selectedPaymentMethod: Token | null;
    /** a list of existing payment methods that the user can select */
    paymentMethods: Token[];
    /** function called when the user clicks the 'Add payment method' button */
    onAddClick: () => void;
    /** function called when the user clicks the 'Change payment method' button */
    onChangeClick: () => void;
    /** function called  */
    onPaymentMethodSelected: (paymentMethod: Token) => void;
}

/** A CardContainer with a CTA for creating a PaymentMethod. E.g. for the New Schedule Page */
const AddPaymentMethodCard = ({
    selectedPaymentMethod, paymentMethods, //data
    onAddClick, onChangeClick, onPaymentMethodSelected //functions
}: Props) => {

    const [selectedTokenFromMethods, setSelectedTokenFromMethods] = useState<Token | null>(null);

    // If the selected method already exists in the list, then use that object
    useEffect(() => {
        if (!selectedPaymentMethod)
            return;
        const method = _.find(paymentMethods, (pm) => pm.dataVaultId === selectedPaymentMethod.dataVaultId);

        if (method)
            setSelectedTokenFromMethods(method);
        else
            setSelectedTokenFromMethods(null);
    }, [selectedPaymentMethod, paymentMethods]);

    function getPaymentMethodList() {
        if (selectedTokenFromMethods)
            return paymentMethods;
        if (!selectedPaymentMethod)
            return paymentMethods;

        return [selectedPaymentMethod, ...paymentMethods];
    }

    return <>
        {!selectedPaymentMethod && _.isEmpty(paymentMethods) &&
            <CardContainer className="add-payment-method-card">
                <IconText card className="add-header"><h2>Add payment method</h2></IconText>
                <p>Add a payment method for the subscription</p>
                <IconText info>Once added, this payment method will be stored for future use and can be found under payment methods</IconText>
                <Button primary onClick={onAddClick}>Add payment method</Button>
            </CardContainer>
        }
        {(selectedPaymentMethod || !_.isEmpty(paymentMethods)) &&
            <div className="add-payment-method-card">
                <h2>Select payment method</h2>
                <TokenSelector
                    // For the table/list
                    tokens={getPaymentMethodList()}
                    selectedToken={selectedTokenFromMethods || selectedPaymentMethod}
                    isLoading={false}
                    onTokenSelected={onPaymentMethodSelected}
                />
                <p>Want to add a new payment method? <Button link onClick={onChangeClick}>Click here to save a new payment method</Button></p>
            </div>
        }
    </>;
};

export default AddPaymentMethodCard;
