import { map } from "platforms/current/util";

import { default as cba } from "platforms/cba/constants/labels";
import { default as stationery_shop } from "platforms/stationery_shop/constants/labels";
import { default as merchantsuite } from "platforms/merchantsuite/constants/labels";

export default map({
    cba,
    stationery_shop,
    merchantsuite,
});
