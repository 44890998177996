import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { PaddedContainer, Badge, ButtonContainer } from '@premier/ui';

import './PromotionCard.scss';

/** A PaddedContainer to emphasise an item to promote */
const PromotionCard = ({
    title, subText, children, //data
    badge, className, //styling
    buttons
}) => {
    return (
        <div className={classNames('new-promotion-item', className)} >
            <PaddedContainer blueBorder>
                {badge && <Badge>{badge}</Badge>}
                {title && <h2>{title}</h2>}
                {subText && <p>{subText}</p>}
                {children}
                {buttons &&
                    <ButtonContainer noTopMargin>
                        {buttons}
                    </ButtonContainer>
                }
            </PaddedContainer>
        </div>
    )
}

PromotionCard.propTypes = {
    /** Title text renderd as a h2 (can pass in IconText to this) */
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),

    /** Subtext to render below */
    subText: PropTypes.string,

    /** Custom UI widgets you wish to display in the box */
    children: PropTypes.node,

    /** text to show in the blue badge*/
    badge: PropTypes.string,

    /** Buttons to render in a buttonContainer */
    buttons: PropTypes.node,

    /** Classname to use on the PageSection */
    className: PropTypes.string
}

export default PromotionCard;