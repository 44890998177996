import { useState, useEffect } from "react";
import { PageSection, Link, LoadingIndicator } from "@premier/ui";
import { PageHeader, useApiCall, APICallRequestState, ProductTooltip } from "components/Common";
import { PaymentPlanOptionList, PaymentPlanTermsAndConditionsRequiredNotification } from "components/Settings";
import { BillerTitleDropdown } from "..";
import { PlatformRoutesConfiguration } from "components/Routing";
import { useParams } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { paymentPlanOptionApi, paymentPlanApi, billerApi } from "api";
import { VerifyBiller } from "../../../util/verificationUtil";
import { Biller } from "packages/webapi-client/api";

const PaymentPlanSettingsPage = () => {
    const { billerCode } = useParams();
    const [shouldRedirect, setShouldRedirect] = useState(false);

    const [billers, billersStatus] = useApiCall<Biller[]>(() => {
        return billerApi.getBillersV2(false);
    }, []);

    const [paymentPlanOptions, paymentPlanOptionsStatus] = useApiCall(() => {
        if (billerCode)
            return paymentPlanOptionApi.getPaymentPlanOptions(billerCode);
    }, [billerCode]);

    const [termsAndConditionsExist] = useApiCall(() => {
        if (billerCode)
            return paymentPlanApi.checkIfPaymentPlanTermsAndConditionsExists(billerCode);
    }, [billerCode]);

    useEffect(() => {
        if (!billerCode && billers?.length) {
            setShouldRedirect(true);
        }
    }, [billerCode, billers]);

    useEffect(() => {
        if (billerCode) {
            VerifyBiller(billerCode);
        }
    }, [billerCode]);

    // Navigate page to the first biller in the billers array (even if it is disabled) if a biller code isn't defined in the URL params
    if (shouldRedirect && billers) {
        return <Navigate to={PlatformRoutesConfiguration.paymentPlanSettingsRoute?.landingPageBillerCodeSelected.generatePath(billers[0].billerCode)!} />;
    }

    return (
        <div className="payment-page-settings-page">
            <PageSection noDivider>
                <PageHeader title={<>{"Payment plan settings"}<ProductTooltip productTooltipModule={"PAYMENT_PLAN_SETTINGS"} /></>} />
                { billersStatus === APICallRequestState.SUCCESSFUL ?
                    <BillerTitleDropdown
                        billerCode={billerCode}
                        billers={billers}
                        getUrl={PlatformRoutesConfiguration.paymentPlanSettingsRoute?.landingPageBillerCodeSelected.generatePath}
                    /> : <LoadingIndicator/> }
                <PaymentPlanTermsAndConditionsRequiredNotification termsAndConditionsExist={termsAndConditionsExist} />
            </PageSection>

            <PageSection>
                <PageHeader useH2 hideTopDiv title="Existing payment plan options">
                    <Link disabled={!billerCode} button to={PlatformRoutesConfiguration.paymentPlanSettingsRoute?.addPaymentPlanOption.generatePath(billerCode)}>Add payment plan option</Link>
                </PageHeader>
                <p>Select the payment plan option below to edit</p>
                { paymentPlanOptionsStatus === APICallRequestState.SUCCESSFUL ?
                    <PaymentPlanOptionList billerCode={billerCode} apiData={paymentPlanOptions} /> : <LoadingIndicator/>
                }
            </PageSection>
        </div>
    );
};

export default PaymentPlanSettingsPage;
