import React, { useState } from 'react';
import { PageSection } from '@premier/ui';
import { withApiCall } from 'components/Common';

import { businessDetailsApi } from 'api';
import BusinessDetailsSettingsBody from './BusinessDetailsSettingsBody';

interface Props {}

const BusinessDetailsSettingsPage: React.FC<Props> = () => {
    const [forceUpdate, setForceUpdate] = useState<boolean>(false);

    const Body = withApiCall(BusinessDetailsSettingsBody, (props: any) => businessDetailsApi.getReceiptDetails(props));
    return (
        <PageSection>
            <Body onSubmitSuccess={() => setForceUpdate(!forceUpdate)}/>
        </PageSection>
    );

}

export default BusinessDetailsSettingsPage