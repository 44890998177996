import { config } from './util';
import {
    ProductTooltipRequestModel,
    ContentManagementApiFactory
} from '@premier/webapi-client';

const contentManagementApi = ContentManagementApiFactory(config);

class ContentManagementApi {
    static async getProductTooltip(productTooltipRequestModel : ProductTooltipRequestModel) {
        return await contentManagementApi.contentManagementGetProductTooltip(productTooltipRequestModel);
    }
}

export default ContentManagementApi;