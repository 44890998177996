import {combineReducers} from 'redux';
import reduceReducers from 'reduce-reducers';
import asyncReducer from 'src/store/asyncReducer';

import accountReducer from './accountReducer';
import noticeReducer from './noticeReducer';

const accountsReducer = combineReducers({
    users: reduceReducers(accountReducer, asyncReducer),
    eNotices: reduceReducers(noticeReducer, asyncReducer)
});

export default accountsReducer;