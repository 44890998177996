import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@premier/ui'
import backButton from 'images/BackArrow.svg';

import './BackButton.scss';

export type BackButtonProps = {
    /** Overwrites the destination URL. The default if not specified is to do a history back. */
    to?: string,
    /** This should only be set if you're overwriting the default behaviour. The label can be passed as children as well. Default is 'Back' */
    label?: ReactNode,
    /** This overrides the 'to' prop so that the back button can be used to interrupt user flow. */
    onClick?: (() => void) | boolean,
    children?: ReactNode,
};

/**
 * A subtle button with a left arrow that we usually put on top of the page, that does a history back on click.
 * On BPoint, use PageHeader instead of this directly.
 * */
const BackButton = ({
    to, label, children, onClick
}: BackButtonProps) => {
    const navigate = useNavigate();
    function handleClick() {
        if (onClick && typeof onClick === 'function') {
            onClick();
        } else if (to) {
            navigate(to);
        } else {
            navigate(-1);
        }
    }

    return (
        <Button subtle left onClick={handleClick} className="back-button">
            <img src={backButton} alt="back-arrow" />
            {label || children || 'Back'}
        </Button>
    );
}

export default BackButton;
