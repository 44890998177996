import { defaultPageSize } from 'constants/billpay';
import * as actions from './actionTypes';
import { tokenApi } from 'api';
import { promiseRequestActions } from 'components/Common/requestActions';

//#region ----- GET Tokens (list) -----
export function loadTokensRequested() {
    return {type: actions.LOAD_TOKENS_REQUESTED};
}
export function loadTokensSuccess(tokens, resultCount) {
    return {type: actions.LOAD_TOKENS_SUCCESS, tokens, resultCount};
}
export function loadTokensFailed(err) {
    return {type: actions.LOAD_TOKENS_FAILED, err};
}

export function saveTokenCount(resultCount) {
    return {type: actions.SAVE_TOKEN_COUNT, resultCount}
}
export function saveTokensPageState(pageState) {
    return {type: actions.SAVE_TOKENS_PAGE_STATE, pageState};
}

export function clearTokenCache() {
    return {type: actions.CLEAR_TOKEN_CACHE};
}

export function getTokens(resultsPerPage = defaultPageSize, pageIndex = 0, filter = {}, sort) {
    return function(dispatch) {
        dispatch(loadTokensRequested());

        return tokenApi.getTokens(resultsPerPage, pageIndex, filter, sort).then(({tokens, resultCount}) => {
            dispatch(loadTokensSuccess(tokens, resultCount));
            dispatch(saveTokenCount(resultCount));
            dispatch(saveTokensPageState({resultsPerPage, pageIndex, filter, sort}));
            return {tokens, resultCount};
        }).catch(err => {
            dispatch(loadTokensFailed(err));
            throw err;
        });
    }
}
//#endregion


//#region ----- ADD Token -----
export function addTokenRequested() {
    return {type: actions.ADD_TOKEN_REQUESTED};
}
export function addTokenSuccess(result) {
    return {type: actions.ADD_TOKEN_SUCCESS, result};
}
export function addTokenFailed(err) {
    return {type: actions.ADD_TOKEN_FAILED, err};
}


export function addToken(tokenDetails) {
    return function (dispatch) {
        dispatch(addTokenRequested());

        return tokenApi.addToken(tokenDetails)
            .then(token => {
                dispatch(addTokenSuccess(token));
                return token;
            })
            .catch(err => {
                dispatch(addTokenFailed(err));
                throw(err);
            })
    }
}
//#endregion

//#region ----- UPDATE Token -----
export function updateTokenRequested() {
    return {type: actions.UPDATE_TOKEN_REQUESTED};
}
export function updateTokenSuccess(result) {
    return {type: actions.UPDATE_TOKEN_SUCCESS, result};
}
export function updateTokenFailed(err) {
    return {type: actions.UPDATE_TOKEN_FAILED, err};
}

export function updateToken(tokenDetails) {
    return function (dispatch) {
        dispatch(updateTokenRequested());

        return tokenApi.updateToken(tokenDetails)
            .then(token => {
                dispatch(updateTokenSuccess(token));
                return token;
            })
            .catch(err => {
                dispatch(updateTokenFailed(err));
                throw(err);
            })
    }
}
//#endregion

//#region ----- DELETE Token -----
export function deleteTokenRequested() {
    return {type: actions.DELETE_TOKEN_REQUESTED};
}
export function deleteTokenSuccess() {
    return {type: actions.DELETE_TOKEN_SUCCESS};
}
export function deleteTokenFailed(err) {
    return {type: actions.DELETE_TOKEN_FAILED, err};
}

export function deleteToken(tokenId) {
    return function (dispatch) {
        dispatch(deleteTokenRequested());

        return tokenApi.deleteToken(tokenId)
            .then(() => {
                dispatch(deleteTokenSuccess());
            })
            .catch(err => {
                dispatch(deleteTokenFailed(err));
                throw(err);
            })
    }
}
//#endregion


//#region ----- GET Token details -----
export function getTokenRequested() {
    return {type: actions.GET_TOKEN_REQUESTED};
}
export function getTokenSuccess(result) {
    return {type: actions.GET_TOKEN_SUCCESS, result};
}
export function getTokenFailed(err) {
    return {type: actions.GET_TOKEN_FAILED, err};
}

export function getToken(tokenId) {
    return function (dispatch) {
        dispatch(getTokenRequested());

        return tokenApi.getToken(tokenId)
            .then(token => {
                dispatch(getTokenSuccess(token));
                return token;
            })
            .catch(err => {
                dispatch(getTokenFailed(err));
                throw(err);
            })
    }
}
//#endregion

const uploadBatchActions = promiseRequestActions(actions.UPLOAD_TOKENS_BATCH, (files) => tokenApi.uploadBatch(files));
export let uploadBatch = uploadBatchActions.send;
export let clearUploadBatch = uploadBatchActions.clear;
