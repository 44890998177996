import httpRequest from './httpRequest';
import mapErrors, { ApiResult, mapResultErrors } from './mapErrors';
import { config, resultHandler, emptyResultHandler } from './util';
import { DesignerApiFactory, Branding } from '@premier/webapi-client';

const designerApi = DesignerApiFactory(config);

class DesignerApi {
    static getBranding(billerCode: string): Promise<ApiResult<Branding>> {
        if (billerCode) {
            return designerApi.designerGetBranding(billerCode).then(...resultHandler).then((r) => mapResultErrors(r));
        } else {
            return designerApi.designerGetBrandingForMerchant().then(...resultHandler).then((r) => mapResultErrors(r));
        }
    }

    static saveBranding(billerCode: string, style: Branding): Promise<ApiResult<void>> {
        if (billerCode) {
            return designerApi.designerSaveBranding(billerCode, style).then(...emptyResultHandler).then((r) => mapResultErrors(r));
        } else {
            return designerApi.designerSaveBrandingForMerchant(style).then(...emptyResultHandler).then((r) => mapResultErrors(r));
        }
    }

    static saveBrandingWithLogo(billerCode: string, style: Branding, files: FileList): Promise<ApiResult<void>> {
        // Note: API doesn't currently support correct generation of OpenAPI for file uploads
        var url = getApiUrl('/branding/save-with-logo', billerCode);

        return new Promise((resolve, reject) => {
            httpRequest
                .postFiles(url, files, {branding: style})
                .then(() => {
                    resolve({
                        ok: true,
                        val: undefined
                    });
                })
                .catch((err) => {
                    reject(mapErrors(err));
                });
        });
    }

    static saveAndPublishBranding(billerCode: string, style: Branding): Promise<ApiResult<void>> {
        var designerApi = DesignerApiFactory(config);

        if (billerCode) {
            return designerApi.designerSaveAndPublishBranding(billerCode, style).then(...emptyResultHandler).then((r) => mapResultErrors(r));
        } else {
            return designerApi.designerSaveAndPublishBrandingForMerchant(style).then(...emptyResultHandler).then((r) => mapResultErrors(r));
        }
    }

    static saveAndPublishBrandingWithLogo(billerCode: string, style: Branding, files: FileList): Promise<ApiResult<void>> {
        // Note: API doesn't currently support correct generation of OpenAPI for file uploads
        var url = getApiUrl('/branding/save-and-publish-with-logo', billerCode);

        return new Promise((resolve, reject) => {
            httpRequest.postFiles(url, files, {branding: style})
                .then(() => {
                    resolve({
                        ok: true,
                        val: undefined
                    });
                })
                .catch((err) => {
                    reject(mapErrors(err));
                });
        });
    }

    static discardBrandingDraft(billerCode: string): Promise<ApiResult<void>> {
        if (billerCode) {
            return designerApi.designerDiscardBrandingDraft(billerCode).then(...emptyResultHandler).then((r) => mapResultErrors(r));
        } else {
            return designerApi.designerDiscardBrandingDraftForMerchant().then(...emptyResultHandler).then((r) => mapResultErrors(r));
        }
    }

    static setByBiller(byBiller: boolean): Promise<ApiResult<void>> {
        return designerApi.designerSetByBiller(byBiller).then(...emptyResultHandler).then((r) => mapResultErrors(r));
    }
}

/**
 * Returns either the merchant or biller API relative URL based on the billerCode
 * @param {string} url The base/merchant URL, must start with '/'
 * @param {string} billerCode If null/falsy, then we're dealing with the merchant style
 */
function getApiUrl(url: string, billerCode: string) {
    return billerCode
        ? `/billers/${billerCode}${url}`
        : url;
}

export default DesignerApi;