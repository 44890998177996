import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';

import { searchableTransactionDateRange } from '@premier/utils/date';
import windowUtil from '@premier/utils/window';
import * as billpayUtil from '@premier/utils/billpay';
import uiUtil from '@premier/utils/ui';
import errorUtil from '@premier/utils/error';
import securityUtil from '@premier/utils/security';

import { PageSection, PaginationControl, Icon, CardContainer, LoadingIndicator, BackButton, IconText, Button } from '@premier/ui';
import { Form } from '@premier/form';
import { withError, FormError, PageHeader } from 'components/Common';
import { TransactionList } from 'components/Transactions';
import { CustomerPaymentModal, CustomerSummarySubtitle } from 'components/DataVault';
import labels from 'constants/labels';
import { defaultPageSize } from 'constants/billpay';

import { PlatformRoutesConfiguration } from 'components/Routing';

import * as customerActions from 'components/DataVault/_actions/customerActions';
import * as paymentActions from 'components/Transactions/_actions/paymentActions';

// Copied straight from ManageTransactionPage.js
const responseTypeOptions = [
    { value: '',         label: 'All' },
    { value: 'approved', label: 'Approved' },
    { value: 'declined', label: 'Declined' },
];

const defaultFilter = {
    childMerchantNumber: '',
    transactionDateRange: {dates: searchableTransactionDateRange},
    settlementDate: [],
    amount: '',
    billerCode: '',
    crn1: '',
    merchantReference: '',
    receiptNumber: '',
    transactionId: '',
    rrn: '',
    maskedCardNumber: {prefix: '', suffix: ''},
    token: '',
    chequeNo: '',
    transactionType: '',
    transactionSource: '',
    orderType: '',
    cardType: '',
    searchTransactionsResponseType: '',
};

const CustomerTransactionsPage = ({
        customerId, customer, //state values
        searchResult, savedState, transactionTypes, authenticatedUser, //state values
        customerActions, paymentActions, //API actions
        isLoading, errors //logic renders
    }) => {

    const resultsPerPage = defaultPageSize;

    const [filter, setFilter] = useState(defaultFilter);  // A live copy of the form values
    const [sort, setSort] = useState(_.get(savedState, 'sort', {field: 'processedDate', descending: true}));
    const [reload, setReload] = useState(false);
    const [currentPage, setCurrentPage] = useState(_.get(savedState, 'pageIndex', 0) + 1);
    const [initialised, setInitialised] = useState(false);

    const [showNewPaymentModal, setShowNewPaymentModal] = useState(false);

    // On page load, load Customer details if we don't already have it
    useEffect(() => {
        if(!customer || reload)
            customerActions.getCustomer(customerId)
    }, [customerActions, reload]);

    // On page load, fetch things from API, get transactions and restore previous state (page number, scroll position, filters) if any
    useEffect(() => {
        if(!transactionTypes)
            paymentActions.getTransactionTypes();

        if(savedState)
            handleFilterChange(savedState.filter, false);
        else
            setReload(true);
    }, [paymentActions]);

    useEffect(() => {
        if(reload && customer) {
            paymentActions.getTransactions(resultsPerPage, currentPage - 1, filter, sort, customer.customerId)
                .then(() => {
                    if(!initialised) {
                        if (savedState && savedState.scrollPosition)
                            windowUtil.scrollTo(savedState.scrollPosition); // Scroll back to previous position
                        setInitialised(true);
                    }
                });
            setReload(false);
        }
    }, [reload, customer]);

    function handleFilterChange(newFilter, resetPage=true) {  // Note: This could also be called at first render (from useEffect)
        if(resetPage)
            setCurrentPage(1);

        setFilter(newFilter);
        setReload(true);
    }

    function handleSort(field, descending) {
        setSort({field, descending});
        setReload(true);
    }

    function handlePageChange(newPage) {
        setCurrentPage(newPage);
        setReload(true);
    }

    function saveScrollPosition() {
        var pageState = Object.assign({}, savedState, {
            scrollPosition: document.body.scrollTop || document.documentElement.scrollTop
        });
        paymentActions.saveTransactionsPageState(pageState);
    }

    function handlePaymentModalClose(reload) {
        setShowNewPaymentModal(false);

        if(reload)
            setReload(true);
    }

    function getTransactions() {
        // Hides transactions if errors in the transaction search
        return errors.length ? [] : _.get(searchResult, 'items', []);
    }

    return (
        <div className='customer-transactions-page'>
            <PageSection>
                {isLoading && !customer && (
                    <CardContainer header="Customer Transactions">
                        <LoadingIndicator />
                    </CardContainer>
                )}

                {!isLoading && !customer && <>
                    <BackButton to={PlatformRoutesConfiguration.customerRoute.manageCustomers.generatePath()}>Back to Customers</BackButton>
                    <IconText alert>Customer not found or an error occurred.</IconText>
                </>}

                {customer && <>
                    <PageHeader backButton title={billpayUtil.getCustomerFullName(customer)} >
                        {
                            securityUtil.hasAccess(PlatformRoutesConfiguration.transactionRoute.newPayment.roles, authenticatedUser) ?
                            <Button onClick={() => setShowNewPaymentModal(true)}>New payment</Button> : null
                        }
                    </PageHeader>

                    {customer && (
                        <CustomerSummarySubtitle customer={customer} />
                    )}

                    <FormError errors={errors} />

                    <h2>Transaction records</h2>

                    {_.get(searchResult, 'count', 0) >= 1000 && (
                        <p><Icon info inline /> Search is limited to 1000 transactions. Please refine your search.</p>
                    )}

                    <Form initialValues={defaultFilter}>
                        <TransactionList
                            // For the quick filter
                            savedFilter={defaultFilter}
                            onFilterChange={handleFilterChange}
                            validDateRange={searchableTransactionDateRange}
                            responseTypeOptions={responseTypeOptions}
                            transactionTypeOptions={uiUtil.generateOptions(transactionTypes)}
                            // For the table/list
                            sort={sort}
                            onSort={handleSort}
                            transactions={getTransactions()}
                            onLinkClick={saveScrollPosition}
                            isLoading={isLoading}
                        />
                        <PaginationControl
                            currentPage={currentPage}
                            itemsPerPage={resultsPerPage}
                            itemCount={_.get(searchResult, 'count', 0)}
                            onPageChanged={handlePageChange}
                        />
                    </Form>
                </>}
            </PageSection>



            {customer && (
                <CustomerPaymentModal
                    show={showNewPaymentModal}
                    onClose={handlePaymentModalClose}
                    customer={customer}
                />
            )}

        </div>
    );
};

function mapStateToProps(state, ownProps) {
    var urlId = ownProps.match.params.id;
    var customerId = parseInt(urlId);
    var customer = null;

    // If (or as soon as) the redux store has the requested customer then use that
    if(_.get(state.dataVault, 'customer.details.customerId', '').toString() === urlId)
        customer = state.dataVault.customer.details

    return {
        customerId,
        customer,
        isLoading: state.transactions.payments.isLoading || state.dataVault.customer.isLoading,
        searchResult: state.transactions.payments.searchResult,
        savedState: state.transactions.payments.transactionsPageState,
        transactionTypes: state.transactions.payments.transactionTypes,
        transactionSources: state.transactions.payments.transactionSources,
        orderTypes: state.transactions.payments.orderTypes,
        accountTypes: state.transactions.payments.accountTypes,
        authenticatedUser: state.accounts.users.authenticatedUser
    }
}

function mapDispatchToProps(dispatch) {
    return {
        paymentActions: bindActionCreators(paymentActions, dispatch),
        customerActions: bindActionCreators(customerActions, dispatch),
    };
}

function mapStoreToErrors(state) {
    // Filter out errors from billerCodeForm
    return state.transactions.payments.errors?.filter(x => !x.field || !x.field.includes("billerCodeForm"));
}

function mapErrorToString(error) {
    var paramLabels = {
        'Crn1': labels.reference1,
    };

    return errorUtil.getMessage(error, paramLabels);
}

export default withError(
    connect(mapStateToProps, mapDispatchToProps)(CustomerTransactionsPage),
    mapStoreToErrors,
    mapErrorToString
);
