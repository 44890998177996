import _ from 'lodash';
import * as actionTypes from 'components/Common/_actions/actionTypes';
import * as commonActions from 'components/Common/_actions/actionTypes';
import { blockingActions } from './appReducer';

export default function asyncReducer(state = {}, action) {

    var reqType = action.type.replace(/_(SUCCESS|FAILED)$/, '_REQUESTED');  // the _REQUESTED version of the action.type
    var blockingAction = _.find(blockingActions, {type: reqType});

    var currentRequestCount = state.asyncCount || 0;

    if (action.type.endsWith('_REQUESTED')) {
        if(!blockingAction)  // If not already handled by appReducer's block UI
            currentRequestCount++;

        var newErrors = state.errors;
        var newClearedBy = state.errorsClearedBy;
        if (state.errorsClearedBy === action.type) {
            newErrors = null;
            newClearedBy = null;
        }

        return Object.assign({}, state, {
            asyncCount: currentRequestCount,
            isLoading: currentRequestCount > 0,
            errors: newErrors,
            errorsClearedBy: newClearedBy
        });
    }

    else if (action.type.endsWith('_SUCCESS')) {
        if(!blockingAction)
            currentRequestCount--;

        return Object.assign({}, state, {
            asyncCount: currentRequestCount,
            isLoading: currentRequestCount > 0
        });
    }

    else if (action.type.endsWith('_FAILED')) {
        if(!blockingAction)
            currentRequestCount--;

        return Object.assign({}, state, {
            asyncCount: currentRequestCount,
            isLoading: currentRequestCount > 0,
            errors: action.errors || action.err,  // err is for backward-compatibility
            errorsClearedBy: action.type.replace(/_FAILED$/, '_REQUESTED')
        });
    }

    if (action.type === commonActions.PATH_CHANGE || action.type === actionTypes.CLEAR_ERRORS) {
        return Object.assign({}, state, {
            errors: []
        });
    }
    return state;
}