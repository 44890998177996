import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import fileUtil from '@premier/utils/file';
import { Button } from '@premier/ui';

import * as paymentActions from 'components/Transactions/_actions/paymentActions';

const ExportTransactionButton = ({
    filter, format, //data
    onExported, //functions
    actions //actions
}) => {

    const [isExporting, setIsExporting] = useState(false);

    function handleExportClicked() {
        // Fetch data from API
        setIsExporting(true);
        actions.downloadTransactionsExport(format, filter)
            .then(report => {
                fileUtil.download(report.fileData, report.fileName);
                onExported();
            })
            .catch(err => console.error(err))
            .finally(() => {
                setIsExporting(false);
            });
    }

    return (<>
        <Button primary onClick={handleExportClicked} disabled={isExporting}>Export</Button>
    </>);
};

ExportTransactionButton.propTypes = {
    filter: PropTypes.object,
    /** Export all 50+ fields */
    format: PropTypes.string,
    onExported: PropTypes.func,
};

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(paymentActions, dispatch),
    };
}

export default connect(null, mapDispatchToProps)(ExportTransactionButton);