import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { PaymentRequestStatus } from 'constants/billpay';
import { RequestPendingModal}  from 'components/Common';
import * as paymentRequestActions from 'components/PaymentRequests/_actions/paymentRequestActions';

/** Dialog that displays the status of a payment request with a timer */
const PaymentMethodRequestPendingModal = ({
    paymentMethodRequestId, //data
    actions,//API actions
    requestStatus, //state
    onClose //functions
}) => {

    const [requestIsPending, setRequestIsPending] = useState(true);
    const [requestIsCancelled, setRequestIsCancelled] = useState(false);

    // Set up the timer
    useEffect(() => {
        setRequestIsPending(!!paymentMethodRequestId);
        return () => actions.clearPaymentRequestStatus();
    }, [paymentMethodRequestId]);

    // Get the request details when status changes to PaidInFull
    useEffect(() => {
        if (!requestStatus || requestStatus !== PaymentRequestStatus.REGISTERED) return;

        setRequestIsPending(false);
        onClose(requestStatus);
    }, [requestStatus]);

    function handleOnCheckRequestStatus() {
        if (paymentMethodRequestId)
            actions.getStatus(paymentMethodRequestId);
    }

    function handleCancel() {
        actions.cancel(paymentMethodRequestId)
            .then(() => {
                setRequestIsCancelled(true);
            })
            .catch(() => {
                setRequestIsCancelled(false);
            });
    }

    function handleClose() {
        onClose(requestStatus);
    }

    return <RequestPendingModal
        isPending={requestIsPending}
        isCancelled={requestIsCancelled}
        onCheckRequestStatus={handleOnCheckRequestStatus}
        onCancel={handleCancel}
        onClose={handleClose}
    />
}

PaymentMethodRequestPendingModal.propTypes = {
    paymentMethodRequestId: PropTypes.string.isRequired,
    onClosed: PropTypes.func
};

function mapStateToProps(state) {
    return {
        requestStatus: state.paymentRequest.status.data,
        cancelErrors: state.paymentRequest.cancel.errors,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(paymentRequestActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethodRequestPendingModal);