import * as actions from './actionTypes';
import { invoiceApi } from 'api';

import { promiseResultRequestActions } from 'components/Common/requestActions';
import {
    PaginateRequestInvoiceSearchInputModelInvoiceSearchSortFields,
} from 'packages/webapi-client';

export let searchInvoiceModel = promiseResultRequestActions(
    actions.SEARCH_INVOICES,
    (
        pagedRequest: PaginateRequestInvoiceSearchInputModelInvoiceSearchSortFields,
        options: any
    ) => invoiceApi.searchInvoices(pagedRequest, options)
).send;

export let getInvoiceStatusSources = promiseResultRequestActions(
    actions.GET_INVOICE_STATUS_SOURCE,
    (options: any) => invoiceApi.getInvoiceStatusSources(options),
).send;