import React from 'react';
import { useSelector } from 'react-redux';
import { DropdownField, EmailAddressField, Form, InputField, validate } from '@premier/form';
import { Accordion, LoadingIndicator, Row } from '@premier/ui';
import countryUtil from '@premier/utils/country';
import useCustomer from '../../_hooks/useCustomer';

import { maxLengthErrorMessage } from './InvoiceForm';
import IsFormDirty from './IsFormDirty';

import './InvoiceBillToForm.scss';

interface Props {
    name?: string;
    setIsDirty?: (value: boolean) => void;
}

export const labels = {
    firstName: 'Contact first name',
    lastName: 'Contact last name',
    email: 'Email',
    additionalInformation: 'Additional bill to information',
    companyName: 'Company name',
    addressLine1: 'Address line 1',
    addressLine2: 'Address line 2',
    suburb: 'Suburb',
    state: 'State',
    postcode: 'Postcode',
    country: 'Country',
};

export const fieldNames = {
    firstName: 'firstName',
    lastName: 'lastName',
    email: 'email',
    companyName: 'companyName',
    addressLine1: 'addressLine1',
    addressLine2: 'addressLine2',
    suburb: 'suburb',
    state: 'state',
    postcode: 'postcode',
    country: 'country',
};

const InvoiceBillToForm: React.FC<Props> = ({ name, setIsDirty }) => {
    const merchantCountryCode = useSelector((state: any) => state?.accounts?.users?.merchant?.countryCode);
    const { details: customerDetails } = useCustomer();

    // Display a loading spinner until the customer details are retrieved
    if (!customerDetails) {
        return <LoadingIndicator />;
    }

    const address = customerDetails?.addresses?.length > 0 && customerDetails.addresses[0];
    const customerCountryStates = address?.countryCode && countryUtil.getCountry(address.countryCode)?.states;
    const customerState = customerCountryStates?.find((s: any) => s.stateId === address?.stateId);

    const initialValues = {
        [fieldNames.firstName]: customerDetails?.firstName,
        [fieldNames.lastName]: customerDetails?.lastName,
        [fieldNames.email]: customerDetails?.emailAddress,
        [fieldNames.country]: address?.countryCode || merchantCountryCode,
        [fieldNames.addressLine1]: address?.addressLine1,
        [fieldNames.addressLine2]: address?.addressLine2,
        [fieldNames.suburb]: address?.suburb,
        [fieldNames.state]: customerState?.state || address?.state,
        [fieldNames.postcode]: address?.postcode,
    };

    const validation = {
        [fieldNames.firstName]: validate().maxLength(50, maxLengthErrorMessage(50)),
        [fieldNames.lastName]: validate().maxLength(50, maxLengthErrorMessage(50)),
        [fieldNames.email]: validate().required().maxLength(250, maxLengthErrorMessage(250)).email(),
        [fieldNames.companyName]: validate().maxLength(100, maxLengthErrorMessage(100)),
        [fieldNames.addressLine1]: validate().maxLength(100, maxLengthErrorMessage(100)),
        [fieldNames.addressLine2]: validate().maxLength(100, maxLengthErrorMessage(100)),
        [fieldNames.suburb]: validate().maxLength(50, maxLengthErrorMessage(50)),
        [fieldNames.state]: validate().maxLength(100, maxLengthErrorMessage(100)),
        [fieldNames.postcode]: validate().maxLength(20, maxLengthErrorMessage(20)),
    };

    const render = (context: any) => {
        const states = countryUtil.getCountry(context.getValue(fieldNames.country))?.states;

        const onCountryChange = (countryValue: any, context: any) => {
            // If there's a list of states for the selected country, but the current value of the state in the context
            // does not correspond to a state in the list, then remove the state value
            const states = countryUtil.getCountry(countryValue)?.states;
            const stateValue = context.getValue(fieldNames.state);
            if (states?.length && stateValue && !states.find((s) => s.state === stateValue)) {
                context.setFormValues({
                    ...context.values,
                    [fieldNames.country]: countryValue,
                    [fieldNames.state]: undefined,
                });
            }
        };

        return (
            <>
                {setIsDirty && <IsFormDirty initialValues={initialValues} setDirty={setIsDirty} />}
                <InputField name={fieldNames.firstName} label={labels.firstName} validateOnChange />
                <InputField name={fieldNames.lastName} label={labels.lastName} validateOnChange />
                <EmailAddressField name={fieldNames.email} label={labels.email} validateOnChange />
                <Accordion className='invoice-form_accordion' title={labels.additionalInformation}>
                    <InputField name={fieldNames.companyName} label={labels.companyName} validateOnChange />
                    <DropdownField
                        key={fieldNames.country}
                        name={fieldNames.country}
                        label={labels.country}
                        onChange={onCountryChange}
                        {...{
                            options: countryUtil.countries.map((c) => ({
                                value: c.countryCode,
                                label: c.name,
                            })),
                        }}
                    />
                    <InputField name={fieldNames.addressLine1} label={labels.addressLine1} validateOnChange />
                    <InputField name={fieldNames.addressLine2} label={labels.addressLine2} validateOnChange />
                    <InputField name={fieldNames.suburb} label={labels.suburb} />
                    <Row lessGap>
                        <div className='col-lg-6'>
                            {states?.length ? (
                                <DropdownField
                                    name={fieldNames.state}
                                    label={labels.state}
                                    {...{
                                        options: states.map((s) => ({ value: s.state, label: s.state })),
                                    }}
                                />
                            ) : (
                                <InputField name={fieldNames.state} label={labels.state} validateOnChange />
                            )}
                        </div>
                        <InputField
                            className='col-lg-6'
                            name={fieldNames.postcode}
                            label={labels.postcode}
                            validateOnChange
                        />
                    </Row>
                </Accordion>
            </>
        );
    };

    return <Form name={name} initialValues={initialValues} initialValidation={validation} {...{ render }} />;
};

export default InvoiceBillToForm;
