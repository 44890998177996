import React, { ReactNode, useState } from 'react';
import classNames from 'classnames';
import './AccordionCard.scss';

type Props = {
    title: string,
    icon?: ReactNode,
    description?: ReactNode,
    initiallyExpanded?: boolean,
    disableToggle?: boolean,
    children?: ReactNode,
};

const AccordionCard = ({
    title, icon, description,
    initiallyExpanded, disableToggle,
    children
}: Props) => {

    const [expanded, setExpanded] = useState(initiallyExpanded);

    function handleClick(e: React.MouseEvent<HTMLDivElement>) {
        if (e.target && e.target instanceof HTMLElement && e.target.tagName === 'A')
            return;

        setExpanded(!expanded);
    }

    return (
        <div className={classNames('accordion-card', { expanded }, { 'togglable': !disableToggle })}>
            <div className='accordion-card-header' onClick={!disableToggle ? handleClick : undefined}>
                <div className='accordion-card-title-line'>
                    {icon && <div className='accordion-card-icon'>
                        {icon}
                    </div>}
                    <div className='accordion-card-title'>{title}</div>
                </div>
                {description && <div className='accordion-card-description'>
                    {description}
                </div>}
            </div>
            <div className='accordion-card-content'>
                {children}
            </div>
        </div>
    );
};

export default AccordionCard;