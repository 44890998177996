import React from "react";
import { InvoiceSettingsItemsPageCopy } from "components/Settings/pages/InvoiceSettings/InvoiceSettingsItemsPage";
import { Button, FormGroup, Input, ReorderableList } from "@premier/ui";
import { Item } from "@premier/ui/src/ReorderableList";
import "components/Settings/components/InvoiceSettings/InvoiceSettingsItemsForm.scss";

interface Props {
    items: Item[];
    taxName?: string;
    taxRate?: string;
    taxNameError: string;
    taxRateError: string;
    listError: string;
    onAdd: () => void;
    onTaxNameInputChange: (value?: string) => void;
    onTaxRateChange: (value?: string) => void;
    onListChange: (items: Item[]) => void;
}

const InvoiceSettingsItemsForm: React.FC<Props> = ({
    items,
    taxName,
    taxRate,
    taxNameError,
    taxRateError,
    listError,
    onAdd,
    onTaxNameInputChange,
    onTaxRateChange,
    onListChange,
}) => {
    const parseInputChange = (input: string) => {
        // get the correct decimal places entered by the user, prevent parseFloat from changing it
        const decimalPlaces = input.split(".")[1]?.length || 0;
        const value = parseFloat(input);

        if (isNaN(value) || value < 0) {
            onTaxRateChange(undefined);
            return;
        }
        onTaxRateChange(parseFloat(input).toFixed(decimalPlaces));
    };

    return (
        <>
            <div className="invoice-settings-input-field">
                <div className="invoice-settings-input-container">
                    <FormGroup
                        label={
                            InvoiceSettingsItemsPageCopy.tax.input.newRateName
                        }
                        error={taxNameError}
                        fieldId={"invoice_tax_name"}
                        className="invoice-tax-name-input"
                    >
                        <Input
                            id={"invoice_tax_name_input"}
                            onEnter={onAdd}
                            value={taxName}
                            error={taxNameError}
                            placeholder={
                                InvoiceSettingsItemsPageCopy.tax.input
                                    .newRateNamePlaceholder
                            }
                            onChange={(e) =>
                                onTaxNameInputChange(e.target.value)
                            }
                        />
                    </FormGroup>
                    <FormGroup
                        label={InvoiceSettingsItemsPageCopy.tax.input.rate}
                        error={taxRateError}
                        fieldId={"invoice_tax_rate"}
                        className="invoice-tax-rate-input"
                    >
                        <Input
                            id={"invoice_tax_rate_input"}
                            type="number"
                            decimal
                            noSpinner
                            onEnter={onAdd}
                            value={taxRate?.toString()}
                            error={taxRateError}
                            placeholder={
                                InvoiceSettingsItemsPageCopy.tax.input
                                    .ratePlaceholder
                            }
                            onChange={(e) => parseInputChange(e.target.value)}
                        />
                    </FormGroup>

                    <Button
                        className="invoice-settings-add-button"
                        onClick={onAdd}
                    >
                        {InvoiceSettingsItemsPageCopy.addButton}
                    </Button>
                </div>
            </div>

            <div className="invoice-settings-input-field">
                <FormGroup
                    label={InvoiceSettingsItemsPageCopy.tax.list.title}
                    fieldId={"invoice_term_payment_list"}
                    className={listError ? "" : "invoice-settings-list"}
                    error={listError}
                >
                    <ReorderableList
                        items={items}
                        onChange={onListChange}
                        description={
                            InvoiceSettingsItemsPageCopy.tax.list.description
                        }
                    />
                </FormGroup>
            </div>
        </>
    );
};

export default InvoiceSettingsItemsForm;
