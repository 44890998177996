import * as accountActions from 'components/Account/_actions/actionTypes';
import * as actions from '../_actions/actionTypes';

export default function reportReducer(state = {}, action) {
    switch (action.type) {

        case actions.GET_DAILY_REPORTS_SUCCESS:
            return Object.assign({}, state, {
                reportsList: action.reportsList
            });
            

        case accountActions.LOGOUT_USER_SUCCESS:
            return {
                asyncCount: state.asyncCount,
                isLoading: state.isLoading,
            };

        default:
            return state;
    }
}
