import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { ErrorText, Mandatory } from '@premier/ui';
import './CheckboxRadioWrapper.scss';

export type CheckboxRadioWrapperProps = {
    label?: ReactNode,
    id?: string,
    mandatory?: boolean,
    error?: string,
    className?: string,
    children?: ReactNode,
};

/** Renders a Bootstrap div.form-check, for wrapping a checkbox / radio so it can display validation errors */
const CheckboxRadioWrapper : React.FC<CheckboxRadioWrapperProps> = ({ label, id, mandatory, error, className, children }) => {
    return (
        <div className={classNames('form-check',  className)}>
            {children}
            <label className='form-check-label' htmlFor={id}>
                {label}
                {mandatory && <Mandatory />}
            </label>

            { error && <ErrorText>{error}</ErrorText> }
        </div>
    );
};

export default CheckboxRadioWrapper;