import React from 'react';
import classNames from 'classnames';
import './Icon.scss';

export type IconTypeProps = {
    /** A question mark icon (grey with circle on transparent) */
    question?: boolean,
    /** An info icon (white on blue circle) */
    info?: boolean,
    /** An exclamation mark icon (white on red circle) */
    alert?: boolean,
    /** A tick icon (white on green circle) */
    tick?: boolean,
    /** A tick icon (white on transparent) */
    check?: boolean,
    /** A magnifier icon (grey on transparent) */
    search?: boolean,
    /** An X icon for close (grey on transparent) */
    x?: boolean,
    /** A left-arrow icon (grey on transparent) */
    arrowLeft?: boolean,
    /** A person icon in a circle (white on transparent) */
    user?: boolean,
    /** A calendar icon (black on transparent) */
    calendar?: boolean,
    /** A lock icon (black/white/yellow on transparent) */
    lock?: boolean,
    /** A not found icon (black/white/yellow on transparent) */
    notFound?: boolean,
    /** A simple minus icon. */
    minus?: boolean,
    /** A simple plus icon */
    plus?: boolean,
    /** A plus icon in a dotted circle */
    plusCircled?: boolean,
    /** A person icon with yellow bottom */
    person?: boolean,
    /** A rectangle with yellow stripe and dollar sign */
    money?: boolean,
    /** A rectangle with yellow triangle on right side */
    card?: boolean,
    /** A simple share icon */
    share?: boolean,
    /** A lightbulb pictogram */
    ideas?: boolean,
    /** A medical doctors bag pictogram */
    trauma?: boolean,
    /** A blue switch icon */
    switchArrows?: boolean,
    /** An hourglass icon */
    hourglass?: boolean,
    /** A desktop screen (grey/yellow) */
    desktop?: boolean,
    /** A mobile phone (grey/yellow) */
    mobile?: boolean,
    identity?: boolean,
    /** A note icon */
    note?: boolean,
    /** A wallet/card -like icon */
    account?: boolean,
    /** A trash can/bin delete icon */
    delete?: boolean,
}

const iconTypes: Array<{prop: keyof IconTypeProps, className: string}> = [
    { prop: 'question', className: 'icon-question' },
    { prop: 'info', className: 'icon-info' },
    { prop: 'alert', className: 'icon-alert' },
    { prop: 'tick', className: 'icon-tick' },
    { prop: 'check', className: 'icon-check' },
    { prop: 'search', className: 'icon-search' },
    { prop: 'x', className: 'icon-x' },
    { prop: 'arrowLeft', className: 'icon-arrow-left' },
    { prop: 'user', className: 'icon-user' },
    { prop: 'calendar', className: 'icon-calendar' },
    { prop: 'lock', className: 'icon-lock' },
    { prop: 'notFound', className: 'icon-not-found' },
    { prop: 'plus', className: 'icon-add' },
    { prop: 'minus', className: 'icon-remove' },
    { prop: 'plusCircled', className: 'icon-plus-circle' },
    { prop: 'person', className: 'icon-person' },
    { prop: 'money', className: 'icon-money' },
    { prop: 'card', className: 'icon-card' },
    { prop: 'share', className: 'icon-share' },
    { prop: 'ideas', className: 'icon-ideas' },
    { prop: 'trauma', className: 'icon-trauma' },
    { prop: 'switchArrows', className: 'icon-switch' },
    { prop: 'hourglass', className: 'icon-hourglass' },
    { prop: 'desktop', className: 'icon-desktop' },
    { prop: 'mobile', className: 'icon-mobile' },
    { prop: 'identity', className: 'icon-identity' },
    { prop: 'note', className: 'icon-note' },
    { prop: 'account', className: 'icon-account' },
    { prop: 'delete', className: 'icon-delete' },
];

type Props = IconTypeProps & {
    /** Either specify one of the type as a prop, or pass the type as a string using this type prop */
    type?: keyof IconTypeProps,

    /** Use this if the icon is used inline at the beginning of the text */
    inline?: boolean,
    className?: string,
    [ otherProps:string ]: any;
}

/** An icon or pictogram, mostly from SVG files */
const Icon = (props: Props) => {
    var { question, info, alert, tick, check, search, x, arrowLeft, user, calendar, lock, notFound, plus, minus, plusCircled,  // Icon types
        person, money, card, share, ideas, trauma, switchArrows, hourglass, desktop, mobile, identity, note, account,  // Icon types
        type, inline, className,
        ...otherProps } = props;

    var iconClassName = iconTypes
        .filter(iconType => (type ? iconType.prop === type : props[iconType.prop]))
        .map(iconType => iconType.className)[0];

    if (!iconClassName) {
        throw new Error(`Invalid icon type, must be one of the following: ` + iconTypes.join(' / '));
    }

    return (
        <span
            className={classNames(iconClassName, 'icon', {'inline': inline}, className)}
            {...otherProps}
        ></span>
    );
};

export default Icon;
