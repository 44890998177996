import { ApiResult, mapResultErrors } from "./mapErrors";
import { config, emptyResultHandler, resultHandler } from "./util";
import { RequestApiFactory, PaymentRequestSettings } from "@premier/webapi-client";

import {
    ExpirePaymentLinkEnum,
} from "platforms/base/constants/billpay";

const requestApi = RequestApiFactory(config);

// TS extension of paymentRequestApi.js
class RequestApi {

    /** Get */
    static getPaymentRequestSettings(merchantNumber: string, billerCode: string): Promise<ApiResult<PaymentRequestSettings>> {
        return requestApi.requestGetPaymentRequestSettings(merchantNumber, billerCode)
            .then(...resultHandler).then(r => mapResultErrors(r));
    }

    /** Put */
    static updatePaymentRequestSettings(merchantNumber: string, billerCode: string, paymentRequestSettings: PaymentRequestSettings): Promise<ApiResult<void>> {

        // Clear out Expiry Date Mandatory to false if user clicks away from Custom Expiry Date radio button
        if (paymentRequestSettings && paymentRequestSettings.expirePaymentLink !== ExpirePaymentLinkEnum.CUSTOM_EXPIRY_DATE) {
            paymentRequestSettings.expiryDateMandatory = false;
        }

        return requestApi.requestUpdatePaymentRequestSettings(merchantNumber, billerCode, paymentRequestSettings)
            .then(...emptyResultHandler).then(r => mapResultErrors(r));
    }

}

export default RequestApi;
