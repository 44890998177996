import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import classNames from 'classnames';

import { Link, PageSection, PaginationControl, BackToTop, Button } from '@premier/ui';
import { Form } from '@premier/form';
import { BackofficeDeeplink, PageHeader, FormError, MerchantPageCountDropdowns, ProductTooltip } from 'components/Common';
import { UserList, UserFilter, EditMFADialog } from 'components/Settings';

import { defaultPageSize, defaultPageSizeOptions } from 'constants/billpay';
import SecurityUtil from "@premier/utils/security";

import { PlatformRoutesConfiguration } from 'components/Routing';
import * as userActions from 'components/Settings/_actions/userActions';
import './ManageUsersPage.scss';
import { useSelector } from "react-redux";
import { userRoles as userRolesRouting} from "components/Routing";

const defaultFullFilter = {
    // this excludes childMerchantNumber
    dailyRefundCount: { min: '', max: '' },
    dailyRefundAmount: { min: null, max: null },
    roleName: '',
    status: '',
    fullName: '',
    userName: '',
    emailAddress: '',
    mobileNumber: '',
    phoneNumber: '',
};

const statusOptions = [
    { value: '', label: 'All' },
    { value: 'active', label: 'Active' },
    { value: 'inactive', label: 'Inactive' },
];

const ManageUsersPage = ({
    actions, // actions
    searchResult,
    userRoles,
    savedState,
    isLoading,
    errors, // state
}) => {
    const [showFilter, setShowFilter] = useState(false);
    const [lastSavedFilter, setLastSavedFilter] = useState({});
    const [currentPage, setCurrentPage] = useState(_.get(savedState, 'currentPage', 1));
    const [filter, setFilter] = useState(_.get(savedState, 'filter', defaultFullFilter));
    const [sort, setSort] = useState(_.get(savedState, 'sort', { field: 'lastUpdated', descending: true }));
    const [resultsPerPage, setResultsPerPage] = useState(_.get(savedState, 'resultsPerPage', defaultPageSize));
    const [showEditMFADialog, setShowEditMFADialog] = useState(false);
    const { authenticatedUser } = useSelector((state) => ({
        authenticatedUser: state.accounts.users.authenticatedUser,
    }));
    
    useEffect(() => {
        if (!userRoles) {
            actions.getUserRoles();
        }
    }, [userRoles]);

    useEffect(() => {
        actions.saveManageUsersPageState({ currentPage, filter, sort, resultsPerPage });
        actions.getUsers(resultsPerPage, currentPage, filter, sort);
    }, [filter, sort, resultsPerPage, currentPage]);

    //#region ========== Filter stuff ==========
    /** Get the number of applied filters in the Advanced Filter */
    const filterCount = useMemo(() => {
        var count = 0;

        Object.keys(defaultFullFilter).forEach((key) => {
            if (!_.isEqual(filter[key], defaultFullFilter[key])) count++;
        });

        return count;
    }, [filter]);

    function handleFilterChange(newFilter, resetPage = true) {
        if (resetPage) setCurrentPage(1);

        setFilter(newFilter);
        setLastSavedFilter(newFilter);
    }
    //#endregion

    //#region ========== User roles ==========
    function getRoleNames(merchantNumber) {
        if (!userRoles) return [];

        var roleNames = [];
        userRoles.forEach((m) => {
            if (merchantNumber && merchantNumber !== m.childMerchantNumber)
                // If no child merchant selected, show all roles
                return;

            m.roles.forEach((r) => {
                if (!roleNames.includes(r.roleName)) {
                    // Dedupe if the role is available from multiple merchants
                    roleNames.push(r.roleName);
                }
            });
        });
        return roleNames;
    }

    function getRoleOptions() {
        var roleNames = getRoleNames(filter.childMerchantNumber);
        var options = [{ value: '', label: 'All' }];
        return options.concat(
            roleNames.map((r) => ({
                label: r,
                value: r,
            }))
        );
    }
    //#endregion

    function handlePageSizeChange(pageSizeOption) {
        setResultsPerPage(pageSizeOption.value);
        setCurrentPage(1);
    }

    return (
        <Form initialValues={filter}>
            <div className={classNames('manage-users-page', { 'hidden': showFilter })} aria-hidden={showFilter}>
                <PageSection className={'manage-users-page'}>
                    <PageHeader title={<>{'Manage users'}<ProductTooltip productTooltipModule={"MANAGE_USERS_SETTINGS"} /></>}>
                        <Link button primary to={PlatformRoutesConfiguration.usersRoute.newUser.generatePath()}>
                            New user
                        </Link>
                        {SecurityUtil.hasAccess([userRolesRouting.multiFactorAuthentication], authenticatedUser) && <Link button primary onClick={() => { setShowEditMFADialog(true); }}>
                            Multi-Factor Authentication
                        </Link>}
                    </PageHeader>

                    <Button
                        subtle
                        onClick={() => {
                            setShowFilter(true);
                        }}
                    >
                        View filters ({filterCount} applied)
                    </Button>

                    <MerchantPageCountDropdowns
                        resultsPerPageOptions={defaultPageSizeOptions}
                        resultsPerPage={resultsPerPage}
                        onChildMerchantChanged={(val, context) => handleFilterChange(context.values)}
                        onResultsPerPageChanged={handlePageSizeChange}
                    />

                    <FormError apiErrors={errors} />

                    <UserList
                        onFilterChange={handleFilterChange}
                        sort={sort}
                        onSort={(field, descending) => setSort({ field, descending })}
                        users={_.get(searchResult, 'items', [])}
                        userRoles={userRoles}
                        isLoading={isLoading}
                        statusOptions={statusOptions}
                        roleOptions={getRoleOptions()}
                    />

                    <PaginationControl
                        className='manage-users-pagination-control'
                        currentPage={currentPage}
                        itemsPerPage={resultsPerPage}
                        itemCount={_.get(searchResult, 'count', 0)}
                        onPageChanged={(page) => setCurrentPage(page)}
                    />

                    <BackofficeDeeplink
                        className='manage-users-deeplink'
                        preLinkMessage='To add or edit contacts to receive notifications about BPOINT, e.g. maintenance windows and release updates, click'
                        periodAfterLink
                        topMargin
                        path='Notifications'
                    />
                </PageSection>
                <BackToTop />
            </div>

            <UserFilter
                show={showFilter}
                onHide={() => setShowFilter(false)}
                defaultFilter={defaultFullFilter}
                lastSavedFilter={lastSavedFilter}
                onChange={handleFilterChange}
                roleOptions={getRoleOptions()}
                statusOptions={statusOptions}
            />
            <EditMFADialog
                show={showEditMFADialog}
                onClose={() => setShowEditMFADialog(false)}
            />
        </Form>
    );
};

function mapStateToProps(state, ownProps) {
    return {
        searchResult: state.settings.user.searchResult,
        userRoles: state.settings.user.userRoles,
        savedState: state.settings.user.manageUsersPageState,
        isLoading: state.settings.user.isLoading,
        errors: state.settings.user.errors,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(userActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageUsersPage);
