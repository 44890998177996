import base from 'platforms/base/constants/text';

export default {...base,
    platformName: 'BPOINT',
    loginHeader: 'BPOINT merchant log on',
    loginSubheader: 'Sign in to your biller back office',
    supportPhoneNumber: '1300 766 031',
    supportPhoneLink: 'tel:+611300766031',
    supportEmail: 'support@bpoint.com.au',
};
