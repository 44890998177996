import { Merchant } from "models/Merchant";
import { Customer, MaskedCardNumber, Phone, Transaction, UiOption } from "./models";
import { Biller,  MessagingConfigurationModel } from "packages/webapi-client";
import { userRoles } from "components/Routing";

/** Returns the common Merchant dropdown options
 * @param {object} merchant Merchant object consisting of merchantNumber, merchantName, and childMerchant object list
 * @param {boolean} allOption will defaultly add an 'All' option to the array of merchants, can set to False.
 * @param {string?} feature is an optional parameter that will filter out merchants that do not have access to this role/feature
 */
export function getMerchantOptions(merchant: Merchant, allOption = true, feature?: userRoles) {
    if (!merchant)
        throw new Error("Required parameter: merchant");

    const merchantArray = [];

    if (allOption)
        merchantArray.push({ value: "", label: "All" });

    merchantArray.push({ value: merchant.merchantNumber ?? "", label: merchant.merchantName ?? "" });

    let childMerchants = merchant.childMerchants;

    // If there are child merchants and a feature has been passed in then we want to make sure
    // that the returned child merchants have access to that feature.
    if (childMerchants && feature) {
        childMerchants = childMerchants.filter(cm => cm.features?.some(f => f === feature));
    }

    return merchantArray.concat(
        (childMerchants || []).map(m => (
            { value: m.merchantNumber ?? "", label: m.merchantName ?? "" }
        ))
    ).filter(x => x !== null);
}

/** returns a string of the merchant's name, given the merchant state & merchamt number
* @param {object} merchant Merchant object consisting of merchantNumber, merchantName, and childMerchant object list
* @param {string} merchantNumber The merchant number to find the merchant's name on
*/
export function getMerchantNameFromNumber(merchant: Merchant, merchantNumber: string) {
    if (!merchant)
        throw new Error("Required parameter: merchant");
    if (!merchantNumber)
        throw new Error("Required parameter: merchantNumber");

    const merchantList = getMerchantOptions(merchant, false);
    const foundMerchant = merchantList.find(m => m.value === merchantNumber);

    return foundMerchant?.label;
}


/** Returns a string like '02/22'
 * @param expiryDate eg. "0222" or { month: 2, year: 22 }
 * @param {string} separator Default = '/'
 * @param {string} defaultValue What is returned when !expiryDate.month (Default is empty string)
 */
export function formatExpiry(expiryDate: string | {month?: number; year?: number}, separator = "/", defaultValue = "") {
    if (!expiryDate)
        return defaultValue;

    if (typeof expiryDate === "string" && expiryDate.length === 4)
        return expiryDate.substr(0, 2) + separator + expiryDate.substr(2, 2);

    else if (typeof expiryDate === "object" && (expiryDate.month || expiryDate.month === 0))
        return ("0" + expiryDate.month).slice(-2) + separator + ("0" + expiryDate.year).slice(-2);

    return defaultValue;
}


/** Converts a string in the form of +61-123456789 to an object consisting of phoneCountryCode, and phoneNumber'
 * @param {string} phoneNumber eg. "+61-12345678" = { iddCode: '+61', phoneNumber: '123456789' }
 */
export function formatPhoneApiStringToObject(phoneNumber: string | undefined): Phone | undefined {
    if (phoneNumber == null)
        return phoneNumber;

    const splitPhone = phoneNumber.split("-");
    if (splitPhone.length <= 1)
        return {
            phoneNumber: phoneNumber
        };

    return {
        iddCode: splitPhone[0],
        phoneNumber: splitPhone[1]
    };
}


/** Converts a phoneObject (iddCode, phoneNumber) to a stored string on the API
 * @param {object} phoneObject eg. { iddCode: '+61', phoneNumber: '123456789' } = "+61-12345678"
 */
export function formatPhoneObjectToApiString(phoneObject: Phone) {
    if (phoneObject == null)
        return undefined;

    // when the field is optional, we need to send blank/null if they don't enter a phone
    if (!phoneObject.phoneNumber)
        return undefined;

    // bad data might not have an IDD code
    if (!phoneObject.iddCode)
        return phoneObject.phoneNumber;

    return `${phoneObject.iddCode}-${phoneObject.phoneNumber.replace(/\s/g, "")}`;
}

/** Converts a masked card number to an API-friendly string
 * @param {object} maskedCardNumber the masked card number object to use.
 */
export function maskedCardNumberToApiString(maskedCardNumber: MaskedCardNumber) {
    const prefix = maskedCardNumber?.prefix;
    const suffix = maskedCardNumber?.suffix;

    if (!prefix && !suffix)
        return null;

    return `${prefix || ""}...${suffix || ""}`;
}


/** Converts a customerObject (title, firstName, lastName) to a display string.
 * @param {object} customer eg. { title: 'Mr.', firstName: 'John', lastName: 'Doe' } = 'Mr. John Doe'
 */
export function getCustomerFullName(customer: Customer) {
    return [
        customer?.title,
        customer?.firstName,
        customer?.lastName
    ].filter(x => !!x).join(" ");
}


export type MerchantRolesParam = {
    childMerchantNumber: string;
    roles: {
        roleId: number;
        roleName: string;
    }[];
}

/** Returns an array of label / value objects for use with user role drop-downs
 * @param {array[object]} roles An array of merchant objects which have each role underneath the merchantn options.
 * @param {string} merchantNumber A merchant number to filter the roles by
*/
export function getUserRoleOptions(roles: MerchantRolesParam[], merchantNumber: string): UiOption<number>[] {
    if (!roles)
        throw new Error("Required parameter: roles");
    if (!merchantNumber)
        throw new Error("Required parameter: merchantNumber");

    let roleOptions: UiOption<number>[] = [];

    roles.forEach(roleMerchantGroups => {
        if (merchantNumber === roleMerchantGroups.childMerchantNumber) {
            roleOptions = roleMerchantGroups.roles.map((role) => ({ label: role.roleName, value: role.roleId }));
        }
    });
    return roleOptions;
}

/**
 * Get whether sms/email is enabled in the the merchant's messaging configuration for a particular transaction receipt
 * @param {object} transaction The transaction/receipt
 * @param {object} messaging The merchant's messaging config from redux (state.accounts.users.merchant.messaging)
 * @returns {emailEnabled: bool, smsEnabled: bool} Whether email/sms is enabled for this transaction receipt
 */
export function getMessagingConfigForTransaction(transaction: Transaction, messaging: MessagingConfigurationModel | undefined) {
    const code = transaction?.paymentProcessedTxnModel?.responseCode ?? transaction.responseCode;
    const success = code === "0";
    const config = success ? messaging?.singlePaymentReceipt : messaging?.singlePaymentDecline;

    return config ?? { emailEnabled: false, smsEnabled: false };
}

/**
 * Get the first active biller in an array of billers
 * @param {Biller[]} billers array of billers
 */
export function getFirstActiveBiller(billers : Biller[]) {
    const result = billers?.find((b : Biller) => b.enabled);
    return result;
}
