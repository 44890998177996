import { Modal, LoadingIndicator } from "@premier/ui";
import { FilenameSubheading } from "components/Batch";

type Props = {
    show: boolean;
    append?: boolean;
    addToLibrary: boolean;
    onHide: () => void;
    filename: string;
    batchLibraryFileName?: string;
}

const FileWillUploadModal = ({
    show, append, addToLibrary,  //logic render
    onHide, //functions
    filename, batchLibraryFileName //data
}: Props) => {

    let whereText;

    if (append) {
        whereText = `The file will be appended to ${batchLibraryFileName} after it is uploaded.`;
    } else {
        const textLibrary = addToLibrary ? " or in \"batch library\" page" : "";
        whereText = `You can view the file in "batch payments" page${textLibrary}.`;
    }


    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Upload will start shortly
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <FilenameSubheading filenames={[filename]} />
                <LoadingIndicator />

                <p>{whereText}</p>
                <p>The file will continue uploading if this dialog is closed.</p>
            </Modal.Body>
        </Modal>
    );
};

export default FileWillUploadModal;
