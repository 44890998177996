import httpRequest from './httpRequest';
import mapErrors from './mapErrors';

import { config } from './util';
import {
    PaymentMethodsApiFactory
} from '@premier/webapi-client';

const paymentMethodsApi = PaymentMethodsApiFactory(config);

class MerchantApi {
    static getEnabledWallets(merchantNumber) {
        return paymentMethodsApi.paymentMethodsGetEnabledWallets(merchantNumber);
    }

    static getPaymentMethods(merchantNumber) {
        return new Promise((resolve, reject) => {
            httpRequest
                .get(`/merchants/${merchantNumber}/payment-methods`)
                .then(response => {
                    resolve(response);
                })
                .catch(errors => {
                    reject(mapErrors(errors));
                });
        });
    }

    static getKlarnaConfig() {
        return new Promise((resolve, reject) => {
            httpRequest
                .get(`/merchant/facility/klarna`)
                .then(response => {
                    resolve(response);
                })
                .catch(errors => {
                    reject(mapErrors(errors));
                });
        });
    }

    static saveKlarnaConfig({username, password}) {
        return new Promise((resolve, reject) => {
            httpRequest
                .put(`/merchant/facility/klarna`, {username, password})
                .then(response => {
                    resolve(response);
                })
                .catch(errors => {
                    reject(mapErrors(errors));
                });
        });
    }

}

export default MerchantApi;