import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { useDebounce } from 'components/Common';
import { ResponsiveTable } from '@premier/ui';
import { FormContext, InputField, MaskedCardNumberField } from '@premier/form';

import { PaymentTypeKey } from 'constants/billpay';
import labels from 'constants/labels';

import { PlatformRoutesConfiguration } from 'components/Routing';

const FindLinkTokensList = ({
    sort, tokens, //data
    onFilterChange, onSort, //table functions
    onLinkTokens, //functions
    isLoading, //logic renders
}) => {

    const context = React.useContext(FormContext);

    const batchActions = [{
        label: 'Link',
        batchLabel: 'Link',
        tableButtonProps: {primary: true},
        handleClick: handleLinkClick
    }]

    function handleLinkClick(selectedTokens) {
        var tokenIds = _.map(selectedTokens, 'dataVaultId');
        onLinkTokens(tokenIds);
    }

    /** Triggers onFilterChange (ie. API call) on the parent */
    function handleQuickFilterChange(values) {
        onFilterChange(values || context.values);  // context.values may not be updated yet, pass values if needed
    }
    const debouncedFilterChange = useDebounce(handleQuickFilterChange);

    // The quick filter fields
    const fields = {
        maskedCardNumber: (
            <MaskedCardNumberField
                name='maskedCardNumber'
                onChange={val => debouncedFilterChange({ ...context.values, maskedCardNumber: val })}
                aria-label={labels.maskedCardNumber + ' filter'}
            />
        ),
        deAccountNumber: (
            <InputField
                name='deAccountNumber'
                onChange={val => debouncedFilterChange({ ...context.values, deAccountNumber: val })}
                aria-label={labels.bankAccountNumber + ' filter'}
            />
        ),
        crn1: (
            <InputField
                name='crn1'
                onChange={val => debouncedFilterChange({ ...context.values, crn1: val })}
                aria-label={labels.tokenReference1 + ' filter'}
            />
        ),
        crn2: (
            <InputField
                name='crn2'
                onChange={val => debouncedFilterChange({ ...context.values, crn2: val })}
                aria-label={labels.tokenReference2 + ' filter'}
            />
        ),
        crn3: (
            <InputField
                name='crn3'
                onChange={val => debouncedFilterChange({ ...context.values, crn3: val })}
                aria-label={labels.tokenReference3 + ' filter'}
            />
        )
    };

    // The table columns (or list items)
    const columns = [{
        label: labels.maskedCardNumber,
        getter: 'maskedCardNumber',
        sortKey: 'maskedCardNumber',
        filter: fields.maskedCardNumber
    }, {
        label: labels.bankAccountNumber,
        getter: 'deAccountNumber',
        sortKey: 'deAccountNumber',
        filter: fields.deAccountNumber
    }, {
        label: labels.tokenReference1,
        showAsLink: true,
        getter: 'crn1',
        sortKey: 'crn1',
        filter: fields.crn1
    }, {
        label: labels.tokenReference2,
        getter: 'crn2',
        sortKey: 'crn2',
        filter: fields.crn2
    }, {
        label: labels.tokenReference3,
        getter: 'crn3',
        sortKey: 'crn3',
        filter: fields.crn3
    }];

    return (<>
        <ResponsiveTable
            data={tokens}
            className='find-link-tokens-list'
            isLoading={isLoading}
            columns={columns}
            sort={sort}
            onSort={onSort}
            getRowLink={c => PlatformRoutesConfiguration.tokenRoute.viewToken.generatePath(c.dataVaultId)}
            rowLinkNewWindow
            noDataText='No tokens to display.'
            batchActions={batchActions}
            batchHideSelectAll
        />
    </>);
};


FindLinkTokensList.propTypes = {
    onFilterChange: PropTypes.func.isRequired,

    /** The current sort order */
    sort: PropTypes.shape({
        field: PropTypes.string,
        descending: PropTypes.bool,
    }).isRequired,

    /** to handle sort field/order changes */
    onSort: PropTypes.func.isRequired,

    /** What happens when redirecting to view item details (eg. to save the current page state into Redux) */
    onLinkTokens: PropTypes.func.isRequired,

    /** List of tokens that can be linked to */
    tokens: PropTypes.arrayOf(PropTypes.shape({
          dataVaultId: PropTypes.number.isRequired,
          token: PropTypes.string.isRequired,
          expiryDate: PropTypes.shape({
            month: PropTypes.number,
            year: PropTypes.number
          }),
          accountName: PropTypes.string,
          maskedCardNumber: PropTypes.string,
          deBsbNumber: PropTypes.string,
          deAccountNumber: PropTypes.string,
          crn1: PropTypes.string.isRequired,
          crn2: PropTypes.string,
          crn3: PropTypes.string,
          customerV2Id: PropTypes.number,
          customerUniqueId: PropTypes.string,
          type: PropTypes.oneOf([PaymentTypeKey.CARD, PaymentTypeKey.BANK_ACCOUNT]).isRequired,
          cardTypeCode: PropTypes.string.isRequired
        })),


    isLoading: PropTypes.bool.isRequired
};

export default FindLinkTokensList;
