import * as billpayUtil from "@premier/utils/billpay";
import { DescriptionList, CardLogo } from "@premier/ui";

// @ts-ignore
import labels from "constants/labels";
import { Token } from "models/Token";

type Props = {
    showTokenReferences?: boolean;
    token?: Token | null;
}

/** A list of the Tokens values */
const TokenDetailsList = ({
    showTokenReferences, //logic renders
    token, //data
}: Props) => {
    function getTokenDetails() {
        const returnDetails = [];

        if (token?.cardTypeCode === "BA") {
            returnDetails.push({ name: labels.accountType, value: "Bank account" });
            returnDetails.push({ name: labels.bsb, value: token?.deBsbNumber });
            returnDetails.push({ name: labels.bankAccountNumber, value: token?.deAccountNumber });
            returnDetails.push({ name: labels.accountName, value: token?.accountName });
        }
        else {
            returnDetails.push({ name: labels.accountType, value: <CardLogo cardTypeCode={token?.cardTypeCode}/> });
            returnDetails.push({ name: labels.maskedCardNumber, value: token?.maskedCardNumber });
            returnDetails.push({ name: labels.expiryDate, value: token ? billpayUtil.formatExpiry(token.expiryDate) : null });
            returnDetails.push({ name: labels.cardholderName, value: token?.cardholderName });
        }

        if (showTokenReferences) {
            returnDetails.push({ name: labels.tokenReference1, value: token?.crn1 });
            returnDetails.push({ name: labels.tokenReference2, value: token?.crn2 });
            returnDetails.push({ name: labels.tokenReference3, value: token?.crn3 });
        }

        return returnDetails;
    }

    return <DescriptionList items={getTokenDetails()} />;
};

export default TokenDetailsList;
