import { useState } from "react";
import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import _ from "lodash";

import errorUtil from "@premier/utils/error";
import windowUtil from "@premier/utils/window";
import countryUtil from "@premier/utils/country";
import * as billpayUtil from "@premier/utils/billpay";

// @ts-ignore
import labels from "constants/labels";

import { PlatformRoutesConfiguration } from "components/Routing";

import { Button, Divider, IconText } from "@premier/ui";
import { Form, EmailAddressField, RadioField, PhoneNumberField, SubmitButton, validate } from "@premier/form";
import { withError, FormError } from "components/Common";

import * as paymentActions from "../_actions/paymentActions";

import { FieldError } from "api/mapErrors";
import { RootState } from "store/store";
import { Biller } from "models/Biller";
import { Merchant, Phone } from "models";
import { PaymentReceipt } from "models/PaymentReceipt";

import "./SendReceiptForm.scss";

type Props = {
    receipt: PaymentReceipt;
    biller: Biller;
    merchant: Merchant;
    customerEmail: string;
    customerPhoneNumber: Phone;
    divider: boolean;
    actions: any;
    errors: string[];
    onSent: () => void;
}

type FormValues = {
    receiptType: string;
    emailAddress: string;
    phoneNumber: Phone;
}

const RECEIPT_TYPE_KEY = {
    EMAIL: "EMAIL",
    SMS: "SMS"
};

const SendReceiptForm = ({
    receipt, biller, customerEmail, customerPhoneNumber, //data
    onSent, //function
    merchant, //states
    divider, //logic renders
    actions, errors
}: Props) => {
    const [showSentSucessfully, setShowSentSucessfully] = useState(false);
    const [showApiError, setShowApiError] = useState(false);
    const selectedMerchant = biller?.merchantNumber === merchant.merchantNumber || !biller.billerCode ? merchant : _.find(merchant.childMerchants, { merchantNumber: biller.merchantNumber });
    const { emailEnabled, smsEnabled } = selectedMerchant ? billpayUtil.getMessagingConfigForTransaction(receipt, selectedMerchant.messaging) : { emailEnabled: false, smsEnabled: false };

    const resetValues: FormValues = {
        receiptType: emailEnabled ? RECEIPT_TYPE_KEY.EMAIL : smsEnabled ? RECEIPT_TYPE_KEY.SMS : "",
        emailAddress: customerEmail || "",
        phoneNumber: {
            iddCode: customerPhoneNumber?.iddCode ?? countryUtil.getIddCode(merchant.countryCode),
            phoneNumber: customerPhoneNumber?.phoneNumber ?? "",
        }
    };

    /** Send payment/capture/refund receipt to email/sms */
    function handleSendReceipt(values: FormValues, { resetForm }: { resetForm: (resetValues: FormValues) => void }) {
        actions.sendPaymentReceipt({
            txnHistoryId: receipt.txnHistoryId || _.get(receipt, "paymentProcessedTxnModel.txnHistoryId"), //I duplicated this for the print receipt button, would it be smart to move it to it's own function?
            childMerchantNumber: selectedMerchant?.merchantNumber,
            mobileNumber: values.receiptType === RECEIPT_TYPE_KEY.SMS ? billpayUtil.formatPhoneObjectToApiString(values.phoneNumber) : null,
            emailAddress: values.receiptType === RECEIPT_TYPE_KEY.EMAIL ? values.emailAddress : null //fixes the API throwing an error when passing in a phone # when not required.
        }).then(() => {
            resetForm(resetValues);
            showSentSuccessfullyMessage();
            if (onSent) {
                onSent();
            }
        }).catch(() => {
            setShowApiError(true);
        });
    }

    function showSentSuccessfullyMessage() {
        setShowSentSucessfully(true);
        setTimeout(() => {
            setShowSentSucessfully(false);
        }, 3000);
    }

    function getEmailField() {
        if (emailEnabled) {
            return (<>
                <EmailAddressField name="emailAddress" label={labels.email} placeholder="customer@email.com" mandatory />
                <SubmitButton defaultStyle>Send</SubmitButton>
            </>);
        }

        return <div className="enable-option">Contact your administrator to enable this option</div>;
    }

    function getSmsfield() {
        if (smsEnabled) {
            return (<>
                <PhoneNumberField name="phoneNumber" labelText={labels.phoneNumber} placeholder="4 9999 9999" mandatory />
                <SubmitButton defaultStyle>Send</SubmitButton>
            </>);
        }

        return <div className="enable-option">Contact your administrator to enable this option</div>;
    }

    function hideApiError() {
        setShowApiError(false);
    }

    const printWindowUrl = process.env.PUBLIC_URL + PlatformRoutesConfiguration.transactionRoute?.transactionDetailsPrint.generatePath(receipt.txnHistoryId || _.get(receipt, "paymentProcessedTxnModel.txnHistoryId"));

    function getReceiptOptions() {
        const options = [];

        if (emailEnabled) {
            options.push({ value: RECEIPT_TYPE_KEY.EMAIL, label: "By email" });
        }

        if (smsEnabled) {
            options.push({ value: RECEIPT_TYPE_KEY.SMS, label: "By SMS" });
        }

        return options;
    }

    const printButton = (<Button id="printButton" subtle left onClick={() => { windowUtil.openPrintReceiptWindow(printWindowUrl); }}>Print receipt</Button>);

    return (smsEnabled || emailEnabled) ? (
        <>
            <Form
                name="contactForm"
                initialValues={resetValues}
                initialValidation={{
                    emailAddress: validate().if((val, values) => values.receiptType === RECEIPT_TYPE_KEY.EMAIL,
                        validate().required().email()),
                    phoneNumber: validate().if((val, values) => values.receiptType === RECEIPT_TYPE_KEY.SMS,
                        validate().requiredPhone().phone())
                }}
                onSubmit={handleSendReceipt}
                render={formContext => {
                    return (
                        <div className="send-receipt-form">
                            <RadioField
                                inlineUpSm
                                name="receiptType"
                                label="Send receipt to customer"
                                options={getReceiptOptions()}
                                onChange={hideApiError}
                            />
                            <div className="field-wrapper">
                                {formContext.getValue("receiptType") === RECEIPT_TYPE_KEY.EMAIL && ( getEmailField() )}
                                {formContext.getValue("receiptType") === RECEIPT_TYPE_KEY.SMS && ( getSmsfield() )}
                                <br/>
                            </div>
                            {showApiError && <FormError errors={errors} /> }

                            {showSentSucessfully &&
                                <IconText tick className="sent-sucessfully">Sent successfully</IconText>
                            }

                            {printButton}
                        </div>
                    );
                }}
            />

            {divider && <Divider />}
        </>
    ) : <div className="receipt-button-container">{printButton}</div>;
};

function mapStateToProps(state: RootState) {
    return {
        merchant: state.accounts.users.merchant,
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        actions: bindActionCreators(paymentActions, dispatch)
    };
}

function mapStoreToErrors(state: RootState) {
    return state.transactions.payments.errors;
}

function mapErrorToString(error: FieldError) {
    const paramLabels = {
        "EmailAddress": labels.email,
        "MobileNumber": labels.mobile,
    };

    return errorUtil.getMessage(error, paramLabels);
}

export default withError(
    connect(mapStateToProps, mapDispatchToProps)(SendReceiptForm),
    mapStoreToErrors,
    mapErrorToString
);
