import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import textUtil from '@premier/utils/text';
import BaseTransactionDetailCategory from './BaseTransactionDetailCategory';

const PaymentSourceCategory = ({ transaction }) => {

    function formatPaymentSource() {
        var source = _.get(transaction, 'paymentSource.txnSourceDescription');
        if (source && _.get(transaction, 'paymentSource.recurringScheduleId') > 0)
            source += ' (Scheduled)';
        return source;
    }

    var paymentSource = [
        { name: 'Source', value: formatPaymentSource() },
        { name: 'Sub-source', value: transaction.paymentSource && transaction.paymentSource.txnSubSourceDescription},
        { name: 'Processed by', value: transaction.paymentSource && transaction.paymentSource.userNameProcessed },
        { name: 'Transaction type', value: transaction.paymentSource && transaction.paymentSource.txnTypeDetails && transaction.paymentSource.txnTypeDetails.description },
        { name: 'Transaction sub-type', value: transaction.paymentSource && textUtil.toInitialCapital(transaction.paymentSource.txnSubType) }
    ];

    return (
        <BaseTransactionDetailCategory title='Payment source' items={paymentSource} />
    );
}

PaymentSourceCategory.propTypes = {
    transaction: PropTypes.object.isRequired
};

export default PaymentSourceCategory;
