import { ApiResult, mapResultErrors } from './mapErrors';
import { config, resultHandler, emptyResultHandler } from './util';
import { FeedbackApiFactory, FeedbackAreaModel } from '@premier/webapi-client';
import { Feedback } from 'models';

const api = FeedbackApiFactory(config);

class FeedbackApi {

    static getFeedbackAreas(): Promise<ApiResult<FeedbackAreaModel[]>> {
        return api.feedbackGetFeedbackAreas().then(...resultHandler).then((r) => mapResultErrors(r));
    }

    static submitFeedback(feedback: Feedback): Promise<ApiResult<void>> {
        return api.feedbackAddFeedback({
            ...feedback,
            type: feedback.type
        }).then(...emptyResultHandler).then((r) => mapResultErrors(r));
    }

}
export default FeedbackApi;
