import ReactSelect, {components}  from 'react-select';
import classNames from 'classnames';
import './MultiDropdown.scss';

export type MultiDropdownProps = {
    options : any[];
    compact? : boolean;
    error? : boolean;
    disabled? : boolean;
    className?: string,
    useValueAsLabel?: boolean,
    [rest:string]: any;
};

const MultiDropdown = ({
    options, compact, error, disabled,
    className,useValueAsLabel,
    ...rest
}: MultiDropdownProps) => {

    
    const MultiValue = (props: any) => (
        <components.MultiValue {...props}>
          {props.data.value}
        </components.MultiValue>
      );
     
    return (
        <ReactSelect
            className={classNames('multi-dropdown', className, {
                'compact': compact,
                'has-error': error
            })}
            classNamePrefix='rselect'
            options={options}
            isDisabled={disabled}
            isOptionDisabled={(option) => option.disabled}
            isMulti
            components={useValueAsLabel ? { MultiValue } : undefined}
            {...rest}
        />
    );
};

export default MultiDropdown;
