import { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { InputGroup } from 'react-bootstrap';
import { Input } from '@premier/ui';
import FormGroup from './FormGroup';
import withField from './withField';

const CssDimensionField = props => {
    var {
        name,
        suffix,
        formProps, groupProps, ...otherProps
    } = props;

    const [id] = useState(_.uniqueId(`${name}_`));
    const numericValue = (formProps.value || '').replace(/[^\d]/g, '');

    function handleChange(e) {
        formProps.setValue(e.target.value + suffix);
    }

    return (
        <FormGroup fieldId={id} {...groupProps}>
            <InputGroup>
                <Input
                    name={name}
                    id={id}
                    type={'text'}
                    onChange={handleChange}
                    value={numericValue}
                    error={formProps.error}
                    aria-labelledby={groupProps.label && `${id}_label`}
                    aria-required={groupProps.mandatory}
                    aria-invalid={!!formProps.error}
                    aria-describedby={formProps.error && `${id}_error`}
                    digitsOnly
                    {...otherProps}
                />
                {suffix &&
                    <InputGroup.Append>
                        <InputGroup.Text>
                            <strong>{suffix.toUpperCase()}</strong>
                        </InputGroup.Text>
                    </InputGroup.Append>
                }
            </InputGroup>
        </FormGroup>
    );
};

CssDimensionField.propTypes = {

    suffix: PropTypes.string,

    //Events
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onMaxLengthReached: PropTypes.func,

    /** Fires when Enter key is pressed. eg. (e) => { alert(e.currentTarget.value); } */
    onEnter: PropTypes.func,
};

export default withField(CssDimensionField);
