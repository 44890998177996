import { ReactNode, useState } from 'react';
import { AccordionChild } from './AccordionChild';

type Props = {
    title: ReactNode,

    enableUpXs?: boolean,
    enableUpSm?: boolean,
    enableUpMd?: boolean,
    enableUpLg?: boolean,
    enableUpXl?: boolean,
    enableDownXs?: boolean,
    enableDownSm?: boolean,
    enableDownMd?: boolean,
    enableDownLg?: boolean,
    enableDownXl?: boolean,

    initiallyExpanded?: boolean,
    className?: string,
    children?: ReactNode,
};

/** A collapsible section (hide the content then collapsed, only showing the title).
 * It can be disabled (ie. always show the content) on wider screens. */
const Accordion = ({
    title,
    initiallyExpanded,
    enableUpXs,
    enableUpSm,
    enableUpMd,
    enableUpLg,
    enableUpXl,
    enableDownXs,
    enableDownSm,
    enableDownMd,
    enableDownLg,
    enableDownXl,
    className,
    children
}: Props) => {

    const [expanded, setExpanded] = useState(initiallyExpanded);

    return (
        <AccordionChild title={title}
            expanded={expanded}
            enableUpXs={enableUpXs}
            enableUpSm={enableUpSm}
            enableUpMd={enableUpMd}
            enableUpLg={enableUpLg}
            enableUpXl={enableUpXl}
            enableDownXs={enableDownXs}
            enableDownSm={enableDownSm}
            enableDownMd={enableDownMd}
            enableDownLg={enableDownLg}
            enableDownXl={enableDownXl}
            className={className}
            onToggle={() => setExpanded(!expanded)}
        >
            {children}
        </AccordionChild>
    );
};

export default Accordion;