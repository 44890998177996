import { useState }  from "react";
import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import { Navigate, useSearchParams } from "react-router-dom";
import { RootState } from "store/store";

import { SingleColumnFormContainer } from "@premier/ui";
import { PageHeader } from "components/Common";
import { CustomerDetailsForm } from "components/DataVault";

import * as customerActions from "components/DataVault/_actions/customerActions";
import { PlatformRoutesConfiguration } from "components/Routing";
import _ from "lodash";

type Props = {
    actions: any;
    errors: any;
}

type Customer = {
    customerId: number;
}

const NewCustomerPage = ({
    actions, // API actions
    errors // state
}: Props) => {

    const [redirect, setRedirect] = useState(false);
    const [newCustomerId, setNewCustomerId] = useState(0);
    const [searchParams] = useSearchParams();

    function handleCustomerSave(values: any) {
        return new Promise<void>((resolve) => {
            actions.addCustomer(values)
                .then((newCustomer: Customer) => {
                    setNewCustomerId(newCustomer.customerId);
                    setRedirect(true);

                    const dataVaultId = searchParams.get("dataVaultId");

                    if (dataVaultId) {
                        actions.linkToken(newCustomer.customerId, [dataVaultId])
                            .then(() => {
                                resolve();
                            })
                            .catch((error: any) => {
                                throw error;
                            });
                    } else {
                        resolve();
                    }
                })
                .catch((error: any) => {
                    throw error;
                });
        });
    }

    if (redirect)
        return <Navigate to={PlatformRoutesConfiguration.customerRoute?.viewCustomer.generatePath(newCustomerId)!} />;

    return (
        <SingleColumnFormContainer>
            <PageHeader
                backButton
                title="New customer"
            />
            <h2>Personal details</h2>
            <CustomerDetailsForm
                errors={errors}
                onSubmit={handleCustomerSave}
                cancelTo={PlatformRoutesConfiguration.customerRoute?.manageCustomers.generatePath()}
            />

        </SingleColumnFormContainer>
    );
};

function mapStateToProps(state: RootState) {
    return {
        errors: state.dataVault.customer.errors
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        actions: bindActionCreators(customerActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(NewCustomerPage);
