import { useState } from 'react';
import text from 'platforms/current/constants/text';
import { ButtonContainer, ServerError, SuccessModal, Button } from '@premier/ui';
import { FeedbackAreaModel } from '@premier/webapi-client';
import { useForm } from '@premier/ts-form';
import { Form, DropdownField, TextareaField } from '@premier/ts-form-ui';
import { feedbackApi } from 'api';
import { Feedback } from 'models';
import { applyErrorMaps } from 'components/Common/applyErrorMaps';

const FeedbackForm = ({
    feedbackAreas, feedbackType
}: {
    feedbackType: "Improvement" | "Defect",
    feedbackAreas: FeedbackAreaModel[]
}) => {

    const [isLoading, setIsLoading] = useState(false);
    const [hasSucceeded, setHasSucceeded] = useState(false);

    const { field, externalErrors, addErrors, submit } = useForm<Feedback>({
        init: () => ({
            feedbackAreaId: undefined,
            comment: '',
            type: feedbackType
        }),
        onSubmit: (value) => {
            setIsLoading(true);
            feedbackApi.submitFeedback(value)
                .then(r => {
                    setIsLoading(false);
                    if (r.ok)
                        setHasSucceeded(true);
                    else
                        addErrors(applyErrorMaps(r.val));
                });
        }
    });

    function handleHideSuccessModal() {
        setHasSucceeded(false);
    }

    return <Form onSubmit={submit}>
        <DropdownField
            label={feedbackType === 'Defect' ? 'Where the issue occurred' : 'What can we improve'}
            options={feedbackAreas.map(x => ({value: x.id!, label: x.name!}))}
            field={field('feedbackAreaId')}
        />

        <TextareaField mandatory label='Comment' maxLength={10000} field={field('comment')} />

        <ServerError errors={externalErrors} />

        <ButtonContainer>
            <Button disabled={isLoading} onClick={submit}>Submit</Button>
        </ButtonContainer>

        <SuccessModal
            show={hasSucceeded}
            onClose={handleHideSuccessModal}
            disableAutoClose={feedbackType === 'Defect'}
            title={feedbackType === 'Defect' ? 'Submitted Successfully' : 'Thank you!'}
        >
            { feedbackType === 'Defect' && <>
                Thanks for reporting the issue! For urgent assistance please contact our friendly help desk on <a href={`mailto:${text.supportEmail}`}>{text.supportEmail}</a> or call <a href={text.supportPhoneLink}>{text.supportPhoneNumber}</a>
            </>}
        </SuccessModal>
    </Form>;
}

export default FeedbackForm;