/**
 * IMPORTANT INFO!!!!
 * Do not attempt to dynamically generate routes in other places for the sake of maintainability.
 */
import React, { useEffect, useMemo } from 'react';
import _ from 'lodash';
import { useLocation, useRoutes } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ExceptionErrorBoundary } from 'components/Common';
import { getPlatform } from 'platforms/current/util';
import * as commonActions from 'components/Common/_actions/actions';
import AuthenticatedRoute from './AuthenticatedRoute';
import ReduxCacheClearOnRedirect from './ReduxCacheClearOnRedirect';
import LegacyPropsProvider from './LegacyPropsProvider';
import RouteTitleModifier from './RouteTitleModifier';


// Note that any exported variables from PlatformRoutes may have nulls like stationeryRoute === null for CBA
// because these routes are not applicable/valid for the CBA Platform and vice versa.
import { PlatformRoutesConfiguration, RouteConfiguration, RouteConfigurationMap } from 'components/Routing';

const RouteContainer = () => {
    const location = useLocation();

    const dispatch = useDispatch();
    useEffect(() => {
        if(dispatch) {
            dispatch(commonActions.storeCurrentPathAndDeducePreviousPath(location.pathname));
        }

    }, [dispatch, location.pathname]);

    const validRoutes = useMemo(() => {
        function constructAllRoutes() {
            // MAKE SURE TO DEEP CLONE PlatformRoutesConfiguration to ensure no mutation can occur to the module (which gets imported everywhere in the system)
            const deepClonedPlatformRouteConfig = _.cloneDeep(PlatformRoutesConfiguration);
            // The intent here is to get all route configs from the imported RouteConfigurationMaps via PlatformRoutesConfiguration into one flat array
            const allRoutes = Object.values(deepClonedPlatformRouteConfig)
                .filter((module : RouteConfigurationMap | null) => !!module)
                .map((module : RouteConfigurationMap | null) => {
                    let rootPath = module?.root.path ?? undefined;
                    return Object.values(module ?? {}).map((route : RouteConfiguration) => {
                        return {...route, rootPath: rootPath};
                    })
                }).flat();
            return allRoutes;
        }

        // Wraps the base element from the configuration so any routes configured with the roles param will be permission + user account protected.
        function wrapRouteElementWithAuthenticatedRouteIfEligible(route : RouteConfiguration) {
            // @ts-ignore
            if(!route.publicPath || route?.roles?.length > 0 ) {
                let routeNew = {...route};
                let oldElement = routeNew.element;
                // @ts-ignore children prop already populated via 3rd param
                routeNew.element = React.createElement(AuthenticatedRoute, { requiredUserRoles: routeNew.roles ?? [] }, oldElement);
                return routeNew;
            } else {
                return route;
            }
        }

        // Wraps the page element such that if the user goes onto another page where base root route is different to the redirected path,
        // clear the Redux cache
        function wrapRouteElementWithReduxCacheClearUponRedirect(route : RouteConfiguration) {
            // @ts-ignore
            if(getPlatform() !== "stationery_shop" && route.rootPath?.length > 0) {
                let routeNew = {...route};
                let oldElement = routeNew.element;
                // @ts-ignore children prop already populated via 3rd param
                routeNew.element = React.createElement(ReduxCacheClearOnRedirect, {rootPath: routeNew.rootPath?.valueOf()}, oldElement);
                return routeNew;
            } else {
                return route;
            }
        }

        // Wraps the element so it has access to legacy props.
        // For example, some pages expect access to props.match.params so this is provided.
        function wrapRouteElementWithLegacyPropsProvider(route : RouteConfiguration) {
            // @ts-ignore
            route.element = React.createElement(LegacyPropsProvider, {}, route.element);
            return route;
        }

        // Wraps the element such that it will know the appropriate title to display
        // for the displayed page
        function wrapRouteElementWithRouteTitleModifier(route : RouteConfiguration) {
            // @ts-ignore
            route.element = React.createElement(RouteTitleModifier, {title: route.title}, route.element);
            return route;
        }

        function produceValidRoutes(allRoutes : RouteConfiguration[]) {
            // All valid routes must have a path and element
            let retval = allRoutes.filter(route => route.path && route.element);
            // If a route has a role associated with it, it will be wrapped in an AuthenticatedRoute
            // This means if the user does not have the sufficient permissions (as given by the route.roles prop), they
            // will redirected to another page (depending on type of failure)
            retval = retval.map(route => { // This code is responsible for wrapping the page components for functionality required by all of them
                let routeNew = wrapRouteElementWithLegacyPropsProvider(route);
                routeNew = wrapRouteElementWithAuthenticatedRouteIfEligible(routeNew);
                routeNew = wrapRouteElementWithReduxCacheClearUponRedirect(routeNew);
                routeNew = wrapRouteElementWithRouteTitleModifier(routeNew);
                return routeNew;
            });
            return retval;
        }

        const allRoutes : RouteConfiguration[] = constructAllRoutes();
        return produceValidRoutes(allRoutes);
    }, []);

    // ExceptionErrorBoundary must contain a key or else when the router attempts to redirect,
    // it will fail because React won't know to react (since the components state won't change according to the DOM diff)
    // See https://stackoverflow.com/questions/49130876/how-to-integrate-error-boundary-in-components-routed-using-react-router
    return (
        <ExceptionErrorBoundary key={location.pathname}>
            {useRoutes(validRoutes)}
        </ExceptionErrorBoundary>
    );
};

export default RouteContainer;