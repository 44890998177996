import { Address, Country } from './models';

export class CountryUtil {
    countries: Country[];
    country: Country;


    constructor() {
        this.countries = [];
        this.country = {};
    }

    /** default postcode length for Australia */
    getDefaultPostcodeLength() {
        return 4
    };

    /** Gets a Country object (by countryCode) with the country details.
      * @param {number} countryCode The countryCode from database
      */
    getCountry(countryCode: number) {
        return this.countries.find(c => c.countryCode === countryCode);
    }

    /** Returns the IDD code of a Country e.g. '+61'
     * @param {number} countryCode (Optional) The countryCode from database. If not specified, return the default country's IDD code
     */
    getIddCode(countryCode?: number) {
       var country = this.getCountry(countryCode || this.country.countryCode as number);
       return country?.iddCode;
    }

    //#region ---------- UI only ----------

    /** Returns dropdown options (ie. {value, label}) for some/all countries
      * @param {object[]} countries (Optional) The array of countries to map. Default = Use all countries
      */
    getCountryOptions(countries?: Country[]) {
        return (countries || this.countries).map(country => ({
            label: country.name,
            value: country.countryCode
        }));
    }

    /** Returns dropdown options (ie. {value, label}) for Country IDD codes
      * @param {object[]} countries (Optional) The array of countries to map. Default = Use all countries
      */
    getPhoneCountryOptions(countries?: Country[]) {
        return (countries || this.countries).map(country => ({
            label: `${country.iddCode} (${country.name})`,
            value: country.iddCode
        }));
    }

    /** Returns dropdown options (ie. {value, label}) for States of a particular Country, or an empty array if not found.
     * @param {number} countryCode The countryCode from database
     * @param {bool} useLongName Use the full name (eg. Victoria) instead of the default name (eg. VIC)
     */
    getStateOptions(countryCode: number, useLongName?: boolean) {
        if(!countryCode) return [];

        var country = this.getCountry(countryCode);
        if(!country || !country.states) return [];

        return country.states.map(s => ({
            value: s.stateId,
            label: useLongName? s.stateFullName : s.state,
        }));
    }

    /** Returns a single-line string representation of the address (eg. "501 Swanston St, Melbourne, VIC 3000")
     * @param {object} address eg. { addressLine1: '501 Swanston St', suburb: 'Melbourne', stateId: 7, state: '', postcode: '3000', countryCode: 36 }
     * @param {number} omitCountryCode Do not print this country name (for default country when > 99% of addresses are in this country)
     */
    formatAddress(address: Address, omitCountryCode = this.country.countryCode) {
        if(!address) return '';

        var country = address.countryCode ? this.getCountry(address.countryCode) : null;

        var countryName = (address.countryCode === omitCountryCode)
                            ? ''     // Don't bother showing default country
                            : country?.name;

        var stateName = address.stateId
                        ? country?.states?.find(c => c.stateId === address.stateId)?.state ?? address.state
                        : address.state;

        return [
            address.addressLine1,
            address.addressLine2,
            address.addressLine3,
            address.suburb,
            [stateName, address.postcode].filter(x => !!x).join(' '),
            countryName
        ].filter(x => !!x).join(', ');
    }
}

const countryUtil = new CountryUtil();
export default countryUtil;

export const setCountryList = (countries: Country[]) => {
    countryUtil.countries = countries;
}

export const setCountry = (countryCode: number) => {
    let country = countryUtil.countries.find(c => c.countryCode === countryCode);
    if(!country)
        throw new Error(`countryCode [${country}] not found in countries`);

    countryUtil.country = country;
}
