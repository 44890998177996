import _ from "lodash";
import Validation from "./validation";
import regexUtil from "@premier/utils/regEx";
import textUtil from "@premier/utils/text";

export default class FieldValidation extends Validation {
    /**
     * Performs a field validation test which returns true if the field is falsy (eg. 0 / empty string / object with empty values)
     * @param {string=} errorMessage Default = "{label} is required", where {label} is the field label
     */
    required(errorMessage) {
        return this.test(
            value => value && (
                typeof value === "boolean" || typeof value === "number" ||
                value.length > 0 ||
                (value instanceof Date && !isNaN(value)) ||
                (typeof value === "object" && this.objectHasPropWithTruthyValue(value))
            ),
            errorMessage || "{label} is required.",
            "required"
        );
    }

    // Private function (this is different from !isEmpty)
    objectHasPropWithTruthyValue(obj) {
        return _.values(obj).some(x =>
            typeof(x) === "object"
                ? this.objectHasPropWithTruthyValue(x)
                : !!x
        );
    }


    /** This is just like required() but for the phone object.
     * It will show an error if you leave the phone number blank, even if you've selected a country.
     * @param {string} [errorMessage] Default = "{label} is required", where {label} is the field label
     */
    requiredPhone(errorMessage) {
        return this.test(
            (value) => _.get(value, "phoneNumber"),
            errorMessage || "{label} is required",
            "requiredPhone",
        );
    }


    /** This is just like requiredIf() but for the phone object.
     * It will show an error if you leave the phone number blank, even if you've selected a country.
     * @param conditionFunction eg. (formValues) => true
     * @param {string} [errorMessage] Default = "{label} is required", where {label} is the field label
     */
    requiredPhoneIf(conditionFunction, errorMessage = null) {
        return this.test(
            (value, values) => !conditionFunction(values) || _.get(value, "phoneNumber"),
            errorMessage || "{label} is required",
            "requiredPhone",
        );
    }

    /**
     * Performs a field validation test which returns true if the length of the phoneNumber value is
     * greater than or equal to the specified length.
     * @param {number} length
     * @param {string} errorMessage Default = `{label} must be at least ${length} characters.`
     */
    maxLengthPhone(length, errorMessage)
    {
        return this.test(
            (value) => textUtil.isNullOrEmpty(_.get(value, "phoneNumber")) || _.get(value, "phoneNumber").length <= length,
            errorMessage || `{label} must be no more than ${length} characters`
        );
    }

    /** Make the field required based on other field value(s). It will be required only when conditionFunction returns true.
     * eg. Email field is required if they want to be emailed: requiredIf((values) => !!values['emailMe'])
     * @param conditionFunction eg. (formValues) => true
     * @param {string} errorMessage Default = "{label} is required", where {label} is the field label
     */
    requiredIf(conditionFunction, errorMessage = null) {
        return this.test(
            (value, values) => !conditionFunction(values) || (value && (
                typeof value === "boolean" || typeof value === "number" ||
                                  value.length > 0 ||
                                  (value instanceof Date && !isNaN(value)) ||
                                  (typeof value === "object" && this.objectHasPropWithTruthyValue(value))
            )),
            errorMessage || "{label} is required",
            "requiredIf",
            conditionFunction
        );
    }

    //#region ========== String Validations ==========

    /**
     * Performs a field validation test which returns true if the length of the field value is
     * greater than or equal to the specified length.
     * @param {number} length
     * @param {string} errorMessage Default = `{label} must be at least ${length} characters.`
     */
    minLength(length, errorMessage) {
        return this.test(
            value => textUtil.isNullOrEmpty(value) || value.length >= length,
            errorMessage || `{label} must be at least ${length} characters`
        );
    }

    /** Performs a field validation test which returns true if the length of the field value
     * is less than or equal to the specified length.
     * @param {number} length
     * @param {string} errorMessage Default = `{label} must be no more than ${length} characters.`
     */
    maxLength(length, errorMessage = undefined) {
        return this.test(
            value => textUtil.isNullOrEmpty(value) || value.length <= length,
            errorMessage || `{label} must be no more than ${length} characters`
        );
    }

    /** Performs a field validation test which returns true if the length of the field value
     * is equal to the specified length.
     * @param {number} length
     * @param {string=} errorMessage Default = `{label} must be ${length} characters`
     */
    exactLength(length, errorMessage) {
        return this.test(
            value => textUtil.isNullOrEmpty(value) || value.length === length,
            errorMessage || `{label} must be ${length} characters`
        );
    }

    /**
     * Performs a field validation test which passes only if the input matches the given regular expression.
     * @param {string | regex} regex
     * @param {string} errorMessage Default = `{label} is not formatted correctly.`
     */
    matches(regex, errorMessage) {
        return this.test(
            value => textUtil.isNullOrEmpty(value) || value.match(regex),
            errorMessage || "{label} is not formatted correctly"
        );
    }
    //#endregion


    //#region ========== Email & Phone Validations ==========

    /**
     * Performs a field validation test which passes only if the input matches an email format.
     * @param {string=} errorMessage
     */
    email(errorMessage) {
        return this.test(
            value => textUtil.isNullOrEmpty(value) || value.match(regexUtil.getEmailRegex()),
            errorMessage || "{label} is not formatted correctly."
        );
    }

    /**
     * Performs a field validation test which passes only if the input matches an phone format.
     * Value to be checked that's passed in is an object consisting of a phoneNumber.
     * @param {string=} errorMessage
     */
    phone(errorMessage) {
        return this.test(
            value => value == null || textUtil.isNullOrEmpty(value.phoneNumber) || value.phoneNumber.match(regexUtil.getPhoneRegex()),
            errorMessage || "{label} is not valid"
        );
    }

    /**NOTE: If any other ...If functions are created below, we should look at moving it to a
     * validateIf function to remove repeated functionality. Where the individual field validation is passed in
     * as well to validate on.
     *
     * I.E. validateIf(() => true, validate().email())
     */


    //#region ========== Number Validations ==========

    /**
     * Performs a validation against a value which passes if the value is a number
     * @param {string} errorMessage
     */
    number(errorMessage) {
        return this.test(value => !isNaN(value), errorMessage || "{label} must be a number");
    }

    /**
     * Performs a field validation against a value which passes if the field value is
     * greater than the provided value
     * @param {number} val The value to validate the field is greater than
     * @param {*} errorMessage
     */
    greaterThan(val, errorMessage) {
        return this.test(value => value > val, errorMessage || `{label} must be greater than ${val}`);
    }

    /**
     * Performs a field validation against a value which passes if the field value is less
     * than the provided value.
     * @param {number} val The value to validate the field is less than
     * @param {*} errorMessage
     */
    lessThan(val, errorMessage) {
        return this.test(value => value < val, errorMessage || `{label} must be less than ${val}`);
    }

    /**
     * Performs a field validation against a value which passes if the field value is
     * greater than or equal to (inclusive) the provided value
     * @param {number} val The value to validate the field is greater than
     * @param {*} errorMessage
     */
    greaterThanOrEqual(val, errorMessage) {
        return this.test(value => value >= val, errorMessage || `{label} must be greater than ${val}`);
    }

    /**
     * Performs a field validation against a value which passes if the field value is less
     * than or equal to (inclusive) the provided value.
     * @param {number} val The value to validate the field is less than
     * @param {*} errorMessage
     */
    lessThanOrEqual(val, errorMessage) {
        return this.test(value => value <= val, errorMessage || `{label} must be less than ${val}`);
    }
    //#endregion

    //#region ========== Array Validations ==========

    /**
     * Performs a field validation test which returns true if the field is an array and it has
     * at least the specified number of elements
     * @param {number} length
     * @param {string} errorMessage Default = `{label} must have at least ${length} items.`
     */
    arrayMinLength(length, errorMessage) {
        return this.test(
            (value) => Array.isArray(value) && value.length >= length,
            errorMessage || `{label} must have at least ${length} ${length === 1 ? "item" : "items"}`
        );
    }
    //#endregion

    cardExpiryMonth(errorMessage, defaultValue) {
        return this.test(
            o => (!o) || (o.month === undefined && o.year === undefined) || (o.month === "" && o.year === "") || (o.month >= 1 && o.month <= 12) || o.month == 99 || (defaultValue && o.month === defaultValue && o.year === defaultValue),  // eslint-disable-line eqeqeq
            errorMessage || "Invalid month"
        );
    }

    cardExpiryYear(errorMessage, defaultValue) {
        return this.test(
            o => (!o) || (o.month === undefined && o.year === undefined) || (o.month === "" && o.year === "") || (o.year !== "" && (o.year >= 0 && o.year <= 99)) || (defaultValue && o.year === defaultValue && o.month === defaultValue),
            errorMessage || "Invalid year"
        );
    }

    maxFileSize(logoMaxSize, errorMessage) {
        return this.test(
            o => {
                return !o || !o.newFiles || !o.newFiles[0] || o.newFiles[0].size <= logoMaxSize;},
            errorMessage || "Invalid file size"
        );
    }

    acceptedFileTypes(fileTypes, errorMessage) {
        return this.test(
            o => !o || !o.newFiles || !o.newFiles[0] || fileTypes.some(item => item === o.newFiles[0].type),
            errorMessage || "Invalid file type"
        );
    }

    /**
     * Treat this as a "when not", ie. whenNot(expectedCondition, errorMessageToShowWhenUnexpected).
     * Performs a validation test which is custom defined by the user.  Will fail if the callback returns false.
     * @param {function(value, values)} callback - Value is the value of the field, values are the values of
     * all the fields.
     * @param {string} errorMessage The error message to display when callback returns false
     */
    when(callback, errorMessage) {
        return this.test((value, values) => callback(value, values), errorMessage || "Field validation error");
    }

    /**
     * Calls a callback, and if the resulting value is true, then the supplied field validation is
     * run.  If the resulting value is false then the validation is skipped. This is used to create
     * optional validation schemas.
     * When defining the .if() function, it needs to be in this style:
     *      .if((val, values) => {return PARAM1 === PARAM2}, validate.xxx())
     * @param {function(value, values)} callback - value is the value of the field, values are the context values
     * of the form.  If this callback returns true, the validation schema will be run
     * @param {FieldValidation} validation The validation
     */
    if(callback, validation) {
        return this.test((value, values) => {
            if (callback(value, values)) {
                validation.validate(value, values);
            }
            return true;
        });
    }
}

export function validate() {
    return new FieldValidation();
}
