import { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import Cookies from "js-cookie";
import queryString from "query-string";

import textUtil from "@premier/utils/text";

import { LoadingIndicator, Link, ButtonContainer } from "@premier/ui";
import { Form, PasswordField, InputField, SubmitButton, validate } from "@premier/form";
import { FormHeader, FormError, HelpText, FloatingContainer } from "components/Common";
import HCaptchaField from "packages/form/fields/HCaptchaField";

// @ts-ignore
import labels from "constants/labels";
// @ts-ignore
import text from "constants/text";

import { PlatformRoutesConfiguration } from "components/Routing";
import * as accountActions from "components/Account/_actions/accountActions";
import { platforms } from "platforms/constants";
// @ts-ignore
import { getPlatform } from "platforms/current/util.ts";

import "./LoginPage.scss";
import { RootState } from "store/store";

type Props = {
    authenticatedUser: boolean;
    userDetails: UserDetails;
    legacyBackOfficeUrl: string;
    actions: any;
    isLoading: boolean;
}

type FormValues = {
    userName?: string;
    password: string;
    merchantNumber: string | null;
    captchaResponse?: string;
}

type UserDetails = {
    username: string;
    password: string;
    merchantNumber: string | null;
}

const LoginPage = ({
    authenticatedUser, userDetails, legacyBackOfficeUrl, //state values
    actions, //API actions
    isLoading //logic render
}: Props) => {
    const location = useLocation();
    const [errorMessage, setErrorMessage] = useState("");
    const [captchaResponse, setCaptchaResponse] = useState("");
    const queryParams = queryString.parse(location.search);
    const lastLocationStringFromArray = Array.isArray(queryParams.lastLocation) && queryParams.lastLocation.length > 0 ? queryParams.lastLocation[0] : "";
    const lastLocationString = typeof queryParams.lastLocation === "string" ? queryParams.lastLocation : lastLocationStringFromArray;
    const lastLocation = queryParams.lastLocation ? lastLocationString : "";
    const last = queryParams && decodeURI(lastLocation);
    const isExpired = queryParams && queryParams.ref === "sessionExpired";

    useEffect(() => {
        if (isExpired)
            setErrorMessage("Your session has expired.");
    }, []);

    let isAuthenticated = !!authenticatedUser;

    const handleSubmit = (values: FormValues, { resetForm }: { resetForm: (values: FormValues) => void }) => {

        const details = {
            userName: process.env.REACT_APP_STATIC_USER || values.userName,
            captchaResponse: captchaResponse,
            platform: getPlatform()
        };

        actions.loginUser(Object.assign({}, values, details))
            .then(() => {
                isAuthenticated = true;
            })
            .catch(() => {
                setErrorMessage("Please enter valid username, password and merchant number.");
                resetForm({ ...userDetails });
            });
    };

    if (isAuthenticated) {
        if (last && last !== "undefined")
            return <Navigate to={last} />;
        else
            return <Navigate to={
                getPlatform() !== "stationery_shop" ? PlatformRoutesConfiguration.accountRoute.landingPage.generatePath() :
            PlatformRoutesConfiguration.stationeryRoute?.homePage.generatePath()!
            } />;
    }

    const forgotPasswordLink = (tabIndex: number) => (
        <Link to={PlatformRoutesConfiguration.accountRoute.forgotPassword.generatePath()} tabIndex={tabIndex}>
            Forgot password?
        </Link>
    );

    const handleVerificationSuccess = (token: string, ekey: string) => {
        setCaptchaResponse(token);
    };

    const getInitialValidation = () => {
        return getPlatform() === platforms.stationery_shop ? {
            password: validate().required(),
            merchantNumber: validate().required(),
            captchaResponse: validate().required()
        } : {};
    };

    return (
        <FloatingContainer className="login-page">
            <FormHeader title={text.loginHeader} description={text.loginSubheader} />

            {text.loginText && <p> {textUtil.newlineToBr(text.loginText)}</p>}

            <Form initialValues={userDetails} onSubmit={handleSubmit} initialValidation={getInitialValidation()} inlineLabelsUpMd>
                <InputField
                    name="username"
                    label={labels.loginUsername}
                    tabIndex={1}
                    className="login-username-input" />
                <PasswordField
                    name="password"
                    label={labels.loginPassword}
                    tabIndex={2}
                    help={forgotPasswordLink(3)}
                    className="login-password-input" />
                { getPlatform() !== "merchantsuite" && <InputField
                    name="merchantNumber"
                    label={labels.loginMerchantNumber}
                    tabIndex={4}
                    maxLength={16}
                    className="login-merchantNumber-input"
                /> }

                { getPlatform() === platforms.stationery_shop &&
                    <HCaptchaField name="captchaResponse" onVerify={handleVerificationSuccess} />
                }

                <div className="forgot-password-md d-none d-md-block">{forgotPasswordLink(5)}</div>
                <FormError errors={errorMessage ? [errorMessage] : []} />

                <ButtonContainer>
                    <SubmitButton disabled={isLoading} tabIndex={6}>Log on</SubmitButton>
                    <LoadingIndicator inline hidden={!isLoading} />
                </ButtonContainer>
            </Form>

            <HelpText className="login-help-text"
                title="Not yet registered for BPOINT?"
                description="Contact our eCommerce specialists on 1800 230 177"
            />
            <div className="switch-to-old-url-md">
                <a href={legacyBackOfficeUrl} >Log on to previous back office</a>
            </div>
        </FloatingContainer>
    );
};

const deduceMerchantNumber = (platform: string) => {
    switch (platform) {
        case "merchantsuite":
            return null;
        default:
            return Cookies.get("Merchant") || "";
    }
};

function mapStateToProps(state: RootState) {
    const initialValues = {
        username: process.env.REACT_APP_STATIC_USER || "",
        password: "",
        merchantNumber: deduceMerchantNumber(getPlatform())
    };

    return {
        userDetails: initialValues,
        isLoading: !!state.accounts.users.isLoading,
        authenticatedUser: state.accounts.users.authenticatedUser,
        legacyBackOfficeUrl: state.accounts.users.configuration?.legacyBackOfficeUrl
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        actions: bindActionCreators(accountActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
