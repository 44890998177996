import React, { useState } from 'react';
import Table from './Table';
import PaginationDots from './PaginationDots';
import { ScrollingTableRecordPartial, ScrollingTableSharedProps } from './ScrollingTableTypes';
import './ScrollingTableNarrow.scss';

export type ScrollingTableNarrowProps<TRecord extends ScrollingTableRecordPartial> = ScrollingTableSharedProps<TRecord>;

/** Not to be used directly. Use ScrollingTable instead */
const ScrollingTableNarrow = <TRecord extends ScrollingTableRecordPartial>(props: ScrollingTableNarrowProps<TRecord>) => {

    const columnsPerSlide = 1;  // The number of columns per slide excluding the first/sticky column
    const numFrozenColumns = 1;  // Freeze the first column
    const [activeDotNumber, setActiveDotNumber] = useState(1);

    function numOfColumns() {
        return Math.min(props.columns.length, numFrozenColumns + columnsPerSlide);
    }

    function showColumn(colIndex: number) {
        if(colIndex < numFrozenColumns) return true;  // Always show first column

        var pageNumber = Math.floor((colIndex-numFrozenColumns)/columnsPerSlide) + 1;  // the page number this column is in
        return pageNumber === activeDotNumber;
    }


    return (
        <div className='scrolling-table-narrow'>
            <Table highlightTotal
                {...props}
                hideFirstColumnHeader
                getNumberOfColumns={numOfColumns}
                fnShowColumn={showColumn}
            />

            <PaginationDots
                itemsPerPage={columnsPerSlide}
                itemCount={props.columns.length - numFrozenColumns}
                currentPage={activeDotNumber}
                onPageChange={(newPage) => setActiveDotNumber(newPage)}
            />
        </div>
    );

};

export default ScrollingTableNarrow;