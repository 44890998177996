import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import RadioInput from './RadioInput';
// @ts-ignore
import backArrowSvg from 'images/BackArrow.svg';
// @ts-ignore
import deleteSvg from 'images/icon-control-delete-24.svg';

import './ReorderableList.scss';

type ItemKey = string | number;

export interface Item {
    label: string;
    key: ItemKey;
}

interface Props {
    items: Item[];
    description?: string;
    className?: string;
    onChange?: (items: Item[]) => void;
    noDataMessage?: string;
}

/**
 * A List of items displayed as rows. Each item has a radio button that allows it to be selected.
 * When selected, a set of controls are shown that allow the items to be moved up/down the list or removed.
 */
const ReorderableList: React.FC<Props> = ({
    items,
    description,
    className,
    onChange,
    noDataMessage = 'No option to display',
    ...otherProps
}) => {
    const [selectedItem, setSelectedItem] = useState<ItemKey | undefined>();
    const [internalItems, setInternalItems] = useState<Item[]>(items);

    useEffect(() => setInternalItems(items), [items]);

    const moveItem = (key: ItemKey, up: boolean) => {
        const newInternalItems = [...internalItems];
        const idx = newInternalItems.findIndex((o) => o.key === key);
        const direction = up ? -1 : 1;

        // If item was not found or the item will be moved out of the bounds of the list, exit early.
        if (idx < 0 || idx + direction < 0 || idx + direction >= newInternalItems.length) {
            return;
        }

        // Swap the necessary items
        const temp = newInternalItems[idx];
        newInternalItems[idx] = newInternalItems[idx + direction];
        newInternalItems[idx + direction] = temp;

        setInternalItems(newInternalItems);
        if (onChange) {
            onChange(newInternalItems);
        }
    };

    const removeItem = (key: ItemKey) => {
        const newInternalItems = [...internalItems];
        const idx = newInternalItems.findIndex((o) => o.key === key);

        if (idx < 0) {
            return;
        }

        newInternalItems.splice(idx, 1);

        setInternalItems(newInternalItems);
        if (onChange) {
            onChange(newInternalItems);
        }
    };

    return (
        <div className={classnames('reorderablelist', className)} {...otherProps}>
            {description && <p>{description}</p>}
            <ul>
                {internalItems && internalItems.length > 0 ? (
                    internalItems.map((o, i) => (
                        <li className='reorderablelist-item' key={o.key} onClick={() => setSelectedItem(o.key)}>
                            <div className='reorderablelist-radiolabel'>
                                <RadioInput
                                    className='reorderablelist-radio'
                                    onChange={() => setSelectedItem(o.key)}
                                    checked={selectedItem === o.key}
                                />
                                <div>{o.label}</div>
                            </div>
                            {selectedItem === o.key && (
                                <div className='reorderablelist-controls'>
                                    {i !== 0 && <UpIcon onClick={() => moveItem(o.key, true)} />}
                                    {i !== internalItems.length - 1 && (
                                        <DownIcon onClick={() => moveItem(o.key, false)} />
                                    )}
                                    <DeleteIcon onClick={() => removeItem(o.key)} />
                                </div>
                            )}
                        </li>
                    ))
                ) : (
                    <div className='reorderablelist-nodata'>{noDataMessage}</div>
                )}
            </ul>
        </div>
    );
};

interface IconProps {
    onClick: () => void;
}

const UpIcon: React.FC<IconProps> = ({ onClick }) => (
    <div className='reorderablelist-icon reorderablelist-icon__up' onClick={onClick}>
        <img src={backArrowSvg} alt='Move item up' />
    </div>
);

const DownIcon: React.FC<IconProps> = ({ onClick }) => (
    <div className='reorderablelist-icon reorderablelist-icon__down' onClick={onClick}>
        <img src={backArrowSvg} alt='Move item down' />
    </div>
);

const DeleteIcon: React.FC<IconProps> = ({ onClick }) => (
    <div className='reorderablelist-icon' onClick={onClick}>
        <img src={deleteSvg} alt='Delete item' />
    </div>
);

export default ReorderableList;
