import React from 'react';
import Selector, { SelectorProps } from './Selector';
import './Checkbox.scss';

type Props<TValue extends string | number> = Omit<SelectorProps<TValue>, 'type'>;

/** A single checkbox with label + error message */
const Checkbox = <TValue extends string | number>({
    checked,
    ...otherProps  // includes: name, defaultChecked, and other props for <input type='checkbox'>
}: Props<TValue>) => {

    return (
        <Selector
            type='checkbox'
            {...otherProps}
            checked={(checked == null && otherProps.defaultChecked == null && otherProps.onChange) ? false : checked}
        />
    );
};

export default Checkbox;
