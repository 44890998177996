import React, { useState, useEffect } from 'react';
import { PaddedContainer, RadioInput, Button, ButtonContainer, SuccessModal, LoadingIndicator } from '@premier/ui';
import { useApiCallDeprecated, APICallRequestState } from 'components/Common';
import { credentialOnFileApi } from 'api';
import { CredentialOnFileModel, CredentialOnFileModelModeEnum } from '@premier/webapi-client';

enum ComponentState {
    INITIATED,
    UPDATING_SETTING,
    READY
}

interface Props {}

export const CredentialOnFileSection : React.FC<Props> = () => {

    const [model, setModel] = useState<CredentialOnFileModel>({});
    const [componentState, setComponentState] = useState<ComponentState>(ComponentState.INITIATED);
    const [isExpandDetails, setIsExpandDetails] = useState<boolean>(false);
    const [showSuccess, setShowSuccess] = useState<boolean>(false);

    const [cofApi, cofApiStatus] = useApiCallDeprecated(() => {

        if (componentState === ComponentState.INITIATED) {
            // @ts-ignore
            return credentialOnFileApi.getCredentialOnFile();
        }

    }, [componentState]);

    useApiCallDeprecated(() => {

        if (componentState === ComponentState.UPDATING_SETTING) {
            setShowSuccess(true);
            setComponentState(ComponentState.READY);
            return credentialOnFileApi.updateCredentialOnFile(model);
        }

    }, [componentState]);

    useEffect(() => {

        if (cofApiStatus === APICallRequestState.SUCCESSFUL) {
            setComponentState(ComponentState.READY);
            setIsExpandDetails(false);
            handleCoFOption(cofApi.mode);
        }

    }, [cofApi, cofApiStatus]);

    const handleCoFOption = (selected : CredentialOnFileModelModeEnum) => {
        if (selected === 'UNKNOWN') {
            selected = 'DATAVAULT';
        }
        setModel({mode: selected});
    }

    return (
        <>
            {componentState !== ComponentState.READY && <LoadingIndicator />}

            { <div className='credential-on-file'>
                <PaddedContainer title="Setup agreement mode for transactions via customers' tokens" button={!isExpandDetails ? <Button onClick={() => setIsExpandDetails(true)}>Update setting</Button> : null} lessMargin noDivider>

                    <p>
                        Configure this setting to declare the way agreements are collected with your customers while using their stored card details in BPOINT, i.e. tokens.
                    </p>

                    { isExpandDetails ? (<>

                        <RadioInput
                            name="credential-on-file-option"
                            label='Only one payment agreement is expected per token saved.'
                            checked={model.mode === 'DATAVAULT' || model.mode === 'UNKNOWN'}
                            onChange={() => handleCoFOption('DATAVAULT') }
                        />
                        <p style={{color: 'grey'}}>This option is mostly selected if you only provide one type of service or product to your customers.</p>

                        <RadioInput
                            name="credential-on-file-option"
                            label='Multiple payment agreements are expected per token saved.'
                            checked={model.mode === 'CRN1'}
                            onChange={() => handleCoFOption('CRN1') }
                        />
                        <p style={{color: 'grey'}}>This option is mostly selected if you provide multiple types of services or products to your customers.</p>

                        <ButtonContainer>
                            <Button primary onClick={() => setComponentState(ComponentState.UPDATING_SETTING)}>Update setting</Button>
                            <Button onClick={() => setComponentState(ComponentState.INITIATED)}>Cancel</Button>
                        </ButtonContainer>

                    </>) : (<>

                        <p>
                            Current setting: {model.mode === 'DATAVAULT' ?
                                'Only one payment agreement is expected per token saved.' :
                                'Multiple payment agreements are expected per token saved.'
                            }
                        </p>

                    </>)}

                </PaddedContainer>
            </div> }

            <SuccessModal
                show={showSuccess}
                onClose={() => {
                    setShowSuccess(false);
                    setIsExpandDetails(false);
                }}
                title='Agreement mode updated successfully!'
            />
        </>);
}

export default CredentialOnFileSection;