import React from 'react';

type Props = {
    error?: string,
    id?: string,
};

/** Only used in some Form components. See if you could use ErrorText instead. */
const ErrorMessage = ({
    error, id
}: Props) => {
    return <>{error && (
        <div id={id} className='error'>
            <span className='sr-only'>Error: </span>
            {error}
        </div>
    )}</>;
};

export default ErrorMessage;
