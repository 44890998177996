import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import { Navigate } from "react-router-dom";
import _ from "lodash";

import currencyUtil from "@premier/utils/currency";
import dateUtil from "@premier/utils/date";

import { PageSection, Button, CardContainer, DescriptionList, Dialog, Icon } from "@premier/ui";
import { PageHeader } from "components/Common";
import { BatchRecords, BatchRenameModal, ProcessBatchDialogs, AppendRecordsDialogs } from "components/Batch";

import { PlatformRoutesConfiguration } from "components/Routing";
import * as actions from "components/Common/_actions/actions";
import * as batchActions from "components/Batch/_actions/batchActions";

// @ts-ignore
import labels from "constants/labels";

import "./BatchLibraryFile.scss";
import { RootState } from "store/store";

const defaultFilter = {
    reference: "",
    accountNumber: {
        prefix: "",
        suffix: ""
    },
    expiryDate: {
        month: "",
        year: ""
    },
    amount: "",
    orderTypeKey: "",
};

type Props = {
    actions: any;
    isLoading: boolean;
    batchActions: any;
    file: File;
    savedState: any;
}

type File = {
    fileId: number;
}

const BatchLibraryFile = ({
    actions, //API actions
    batchActions, file, savedState //state values
}: Props) => {

    const [redirectBack, setRedirectBack] = useState(false);
    const [showRenameModal, setShowRenameModal] = useState(false);
    const [showProcessDialogs, setShowProcessDialogs] = useState(false);
    const [showAppendRecordsDialogs, setShowAppendRecordsDialogs] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);

    const defaultPageSize = 10;
    const defaultSort = { field: "reference" };
    const defaultPageNumber = 1;

    useEffect(() => {
        batchActions.saveBatchRecordsPageState({
            pageSize: defaultPageSize,
            pageNumber: defaultPageNumber,
            filter: defaultFilter,
            sort: defaultSort
        });
    }, []);

    useEffect(() => {
        getBatchDetails();
    }, [file.fileId, savedState]);

    function getBatchDetails() {
        batchActions.getBatchLibraryFileDetails(
            file.fileId,
            _.get(savedState, "pageSize", defaultPageSize),
            _.get(savedState, "pageNumber", defaultPageNumber) - 1,
            _.get(savedState, "filter", defaultFilter),
            _.get(savedState, "sort", defaultSort));
    }

    function handleDelete() {
        setShowDeleteDialog(true);
    }

    function handleDeleteConfirmed() {
        setShowDeleteDialog(false);
        batchActions.deleteBatchLibraryFile(file.fileId).then(() => {
            setRedirectBack(true);
        });
    }

    function handleProcess() {
        setShowProcessDialogs(true);
    }

    function handleRename(newName: string) {
        batchActions.renameBatchLibraryFile(file.fileId, newName).then(() => {
            setShowRenameModal(false);
        });
    }

    function handleRenameClose() {
        setShowRenameModal(false);
        actions.clearErrors();
    }

    function handleAppendClose() {
        setShowAppendRecordsDialogs(false);

        getBatchDetails();
    }

    if (redirectBack)
        return <Navigate to={PlatformRoutesConfiguration.transactionRoute?.batchLibrary.generatePath()!} />;

    return (
        <div className="batch-library-file-page">
            <PageSection>
                <PageHeader
                    backButton={{
                        to: PlatformRoutesConfiguration.transactionRoute?.batchLibrary.generatePath(),
                        label: "Back to batch library"
                    }}
                    title={(
                        <div tabIndex={0} role="link"
                            onClick={() => setShowRenameModal(true)}
                            title="Click to rename"
                        >
                            {_.get(file, "filename", "Filename")}
                        </div>
                    )}
                    subtitle={`${labels.createdTime}: ${dateUtil.convertToDateTimeString(_.get(file, "submittedDateTime", ""))}`}
                >
                    <Button primary onClick={handleProcess}>Process batch file</Button>
                </PageHeader>

                <CardContainer header="Overview">
                    <DescriptionList items={[
                        { name: "Status", value: _.get(file, "status.description", "Created") },
                        { name: "Uploaded records", value: currencyUtil.formatAmount(_.get(file, "uploadedCount", 0), 0) },
                        { name: "Approved records", value: "---" },
                        { name: "Uploaded value", value: currencyUtil.convertToDisplayString(_.get(file, "uploadedValue", 0)) },
                        { name: "Approved value", value: "---" },
                    ]} />
                </CardContainer>

                <h2>Batch records</h2>
                <Button className="right-button" onClick={() => setShowAppendRecordsDialogs(true)}>
                    Add records
                </Button>

                <BatchRecords fileId={+file.fileId}/>

            </PageSection>

            <PageSection>
                <Button subtle onClick={handleDelete}>Delete this batch file</Button>
            </PageSection>



            <BatchRenameModal
                show={showRenameModal}
                onClose={handleRenameClose}
                fullFileName={_.get(file, "filename", "Filename")}
                onRename={handleRename}
            />

            <ProcessBatchDialogs show={showProcessDialogs}
                files={[file]}
                onClose={() => setShowProcessDialogs(false)}
                isTemplate
            />

            <Dialog show={showDeleteDialog}
                icon={<Icon alert />}
                title="Delete this batch template?"
                footerButtons={<>
                    <Button onClick={handleDeleteConfirmed}>Delete</Button>
                    <Button onClick={() => setShowDeleteDialog(false)}>Cancel</Button>
                </>}
            >
                This batch template will be deleted from batch library. All records will be deleted.
            </Dialog>

            <AppendRecordsDialogs
                show={showAppendRecordsDialogs}
                onClose={handleAppendClose}
                batchFile={file}
            />
        </div>
    );
};

function mapStateToProps(state: RootState, ownProps: any) {
    const fileId = ownProps.match.params.id;

    let file = { fileId };

    // This is the correct one
    if (_.get(state.transactions.batch, "batchFileResult.fileDetails") &&
       state.transactions.batch.batchFileResult.fileDetails.fileId === +fileId
    ) {
        file = state.transactions.batch.batchFileResult.fileDetails;
    }
    // If we don't have the above yet, try getting the details from the previous page
    else if (_.get(state.transactions.batch, "batchLibraryFilesResult.items.length") &&
            _.some(state.transactions.batch.batchLibraryFilesResult.items, { fileId: +fileId })
    ) {
        file = _.find(state.transactions.batch.batchLibraryFilesResult.items, { fileId: +fileId });
    }

    return {
        file: file,
        savedState: state.transactions.batch.batchRecordsPageState
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch),
        batchActions: bindActionCreators(batchActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(BatchLibraryFile);
