import { useState } from "react";
import { Button, Icon } from '@premier/ui';

import windowUtil from "@premier/utils/window";

type Props = {
    clickableButtonId: string;
    text: string;
}

const ClickableButton = ({clickableButtonId, text} : Props) => {

    const [showCopied, setShowCopied] = useState(false);

    function showApiPasswordCopiedTextMessage() {
        if (text) {
            windowUtil.copyTextToClipboard(text);
            setShowCopied(true);
            setTimeout(() => {
                setShowCopied(false);
            }, 3000);
        }
    }

    return (
        showCopied
            ? <> <Icon tick inline /> Copied</>
            : <Button
                link
                label = {text}
                title ="Click to Copy"
                id = {clickableButtonId}
                onClick = {() => { showApiPasswordCopiedTextMessage(); }} />
    );
};

export default ClickableButton;