import axios from 'axios';
import http from 'http';
import https from 'https';
import _ from 'lodash';

export class HttpRequest {
    constructor(baseURL) {
        this.baseConfig = {
            baseURL: baseURL,
            withCredentials: true,
            httpAgent: new http.Agent({ keepAlive: true }),  // httpAgent: httpAgent -> for non es6 syntax
            httpsAgent: new https.Agent({ keepAlive: true })
        }
    }

    get(url, params) {
        var config = Object.assign({}, this.baseConfig, {
            method: 'get',
            url: url,
            params: params
        });

        return processRequest(config);
    }

    post(url, data) {
        var config = Object.assign({}, this.baseConfig, {
            method: 'post',
            url: url,
            data: data
        });

        return processRequest(config);
    }

    put(url, data) {
        var config = Object.assign({}, this.baseConfig, {
            method: 'put',
            url: url,
            data: data
        });

        return processRequest(config);
    }

    patch(url, data) {
        var config = Object.assign({}, this.baseConfig, {
            method: 'patch',
            url: url,
            data: data
        });

        return processRequest(config);
    }

    postFiles(url, files, data) {
        if (data && Object.keys(data).includes('file')) // validate data
            throw new Error("Invalid data key: 'file'");

        let form = new FormData();

        for (var i = 0; i < files.length; i++)
            form.append('file', files[i]);

        if (data)
            form.append('data', new Blob([JSON.stringify(data)], {type: 'application/json'}));

        return this.post(url, form);
    }

    delete(url, data) {
        var config = Object.assign({}, this.baseConfig, {
            method: 'delete',
            url: url,
            data: data
        });

        return processRequest(config);
    }

}

const processRequest = (config) => {
    return new Promise((resolve, reject) => {
        axios(config).then(response => {
            if (response.data.errors && response.data.errors.length > 0) {  // An error
                reject(response.data.errors);
            }
            else if(_.get(response, "headers['content-disposition']", '').includes('attachment')) {  // A file
                let match = /filename=(.+?)(?:;|$)/.exec(response.headers['content-disposition']);
                resolve({
                    fileData: response.data,
                    fileName: _.get(match, 1),
                });
            }
            else {  // A normal JSON result
                resolve(response.data.data);
            }
        })
        .catch(err => {
            var errors = err && err.response && err.response.data && err.response.data.errors;
            var status = err && err.response && err.response.status;
            if (status == 401)  // eslint-disable-line eqeqeq
            {
                var currentLocation = encodeURI(window.location.pathname);
                var appPath = process.env.PUBLIC_URL;

                if (appPath !== '/' && currentLocation.indexOf(appPath) === 0) {
                    currentLocation = currentLocation.substring(appPath.length);
                }

                window.location = appPath + '/logon?ref=sessionExpired&lastLocation=' + currentLocation;
            }

            reject(errors || [{ message: `Error${status ? ' ' + status : ''} - An unknown error has occurred` }]);
        });
    });
}

export default HttpRequest;

export const httpHandler = axios;
