import { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { RootState } from "store/store";
import { APICallRequestState, MerchantDropdown, PageHeader, useApiCall } from "components/Common";
import { Option } from "packages/formik-ui/src/FormikDropdown";
import { Checkbox, DescriptionList, FormGroup, LoadingIndicator, PageSection, PaginationControl, RadioInput, ResponsiveTable, Row, ScrollingTable, Tooltip, TooltipTrigger } from "packages/ui";
import { Merchant } from "packages/utils/models";
import currencyUtil from "packages/utils/currency";
import { toPercent } from "packages/utils/number";
import DateUtil from "packages/utils/date";
import DeclineApi from "api/declineApi";
import { ResponsiveTableRecordPartial } from "packages/ui/src/ResponsiveTableTypes";
import { getMerchantOptions } from "packages/utils/billpay";
import { userRoles } from "components/Routing";

import "./DeclinesDashboardPage.scss";

type Props = {
    merchant: Merchant;
};

type TrayGridItem = {
    trayName: string;
    count?: number;
    amount?: number;
    percentOfTotalCount?: number;
    percentOfTotalAmount?: number;
} & ResponsiveTableRecordPartial;

type BatchStatusItem = {
    fileName: string;
    submissionDate: string;
    approvedCount: number;
    approvedAmount: number;
    approvedPercent: number;
    recycledCount: number;
    recycledAmount: number;
    recycledPercent: number;
    processingCount: number;
    processingAmount: number;
    processingPercent: number;
    resolvedCount: number;
    resolvedAmount: number;
    resolvedPercent: number;
    unresolvedCount: number;
    unresolvedAmount: number;
    unresolvedPercent: number;
    tray1Amount: number;
    tray1Count: number;
    tray1Percent: number;
    tray2Amount: number;
    tray2Count: number;
    tray2Percent: number;
    tray3Amount: number;
    tray3Count: number;
    tray3Percent: number;
    totalCount: number;
    totalAmount: number;
} & ResponsiveTableRecordPartial;

const DeclinesDashboardPage = ({ merchant }: Props) => {
    const [selectedMerchant, setSelectedMerchant] = useState<Option | null>();

    const merchantOptions = useMemo(() => {
        return getMerchantOptions(merchant, false, userRoles.declineManager);
    }, [merchant]);

    useMemo(() => {
        if (merchantOptions.length === 1) {
            setSelectedMerchant(merchantOptions[0]);
        }
    }, [merchantOptions]);

    const [trays, traysStatus] = useApiCall(() => {
        if (selectedMerchant) {
            return DeclineApi.getDeclineTrays(selectedMerchant.value as string);
        }
    }, [selectedMerchant]);

    const trayGridItems = useMemo<TrayGridItem[]>(() => {
        const items: TrayGridItem[] = [
            {
                trayName: "Resolved",
                count: trays?.resolvedCount,
                amount: trays?.resolvedAmount,
            },
            {
                trayName: "Unresolved",
                count: trays?.unresolvedCount,
                amount: trays?.unresolvedAmount
            },
            {
                trayName: trays?.tray1Nickname || "Tray 1",
                count: trays?.tray1Count,
                amount: trays?.tray1Amount,
            },
            {
                trayName: trays?.tray2Nickname || "Tray 2",
                count: trays?.tray2Count,
                amount: trays?.tray2Amount,
            },
            {
                trayName: trays?.tray3Nickname || "Tray 3",
                count: trays?.tray3Count,
                amount: trays?.tray3Amount,
            },
        ];

        // Footer which adds up counts and amounts
        const footerItem: TrayGridItem = {
            trayName: "Total",
            count: items.reduce((runningTotal, c) => runningTotal + (c.count ?? 0), 0),
            amount: items.reduce((runningTotal, c) => runningTotal + (c.amount ?? 0), 0),
            className: "footer"
        };

        items.push(footerItem);

        // Calculate percentages for each item except the footer
        items.forEach(i => {
            if (!i.className) {
                i.percentOfTotalCount = i.count && footerItem.count ? i.count / footerItem.count : 0;
                i.percentOfTotalAmount = i.amount && footerItem.amount ? i.amount / footerItem.amount : 0;
            }
        });

        return items;
    }, [trays]);

    const [batchFilesCurrentPage, setBatchFilesCurrentPage] = useState(1);
    const [batchFilesViewAsCount, setBatchFilesViewAsCount] = useState(true);
    const [batchFilesViewAsPercentage, setBatchFilesViewAsPercentage] = useState(false);
    const batchFilesPerPage = 10;

    const [batchFiles, batchFilesStatus] = useApiCall(() => {
        if (selectedMerchant) {
            return DeclineApi.getDeclineBatchFiles(selectedMerchant.value as string);
        }
    }, [selectedMerchant]);

    const batchFileItems = useMemo<BatchStatusItem[]>(() => {
        if (!batchFiles) {
            return [];
        }

        // Handle paging of batch files
        const result: BatchStatusItem[] = batchFiles
            .slice((batchFilesCurrentPage - 1) * batchFilesPerPage, batchFilesCurrentPage * batchFilesPerPage)
            .map(i => ({
                fileName: i.batchFileName ?? "",
                submissionDate: i.submissionDate ?? "",
                approvedCount: i.approvedCount ?? 0,
                approvedAmount: i.approvedAmount ?? 0,
                approvedPercent: 0,
                recycledCount: i.recycledCount ?? 0,
                recycledAmount: i.recycledAmount ?? 0,
                recycledPercent: 0,
                processingCount: i.processingCount ?? 0,
                processingAmount: i.processingAmount ?? 0,
                processingPercent: 0,
                resolvedCount: i.resolvedCount ?? 0,
                resolvedAmount: i.resolvedAmount ?? 0,
                resolvedPercent: 0,
                unresolvedCount: i.unresolvedCount ?? 0,
                unresolvedAmount: i.unresolvedAmount ?? 0,
                unresolvedPercent: 0,
                tray1Amount: i.tray1Amount ?? 0,
                tray1Count: i.tray1Count ?? 0,
                tray1Percent: 0,
                tray2Amount: i.tray2Amount ?? 0,
                tray2Count: i.tray2Count ?? 0,
                tray2Percent: 0,
                tray3Amount: i.tray3Amount ?? 0,
                tray3Count: i.tray3Count ?? 0,
                tray3Percent: 0,
                totalCount: 0,
                totalAmount: 0,
            }));

        result.forEach((i) => {
            i.totalCount = i.approvedCount + i.recycledCount + i.processingCount + i.resolvedCount + i.unresolvedCount + i.tray1Count + i.tray2Count + i.tray3Count;
            i.totalAmount = i.approvedAmount + i.recycledAmount + i.processingAmount + i.resolvedAmount + i.unresolvedAmount + i.tray1Amount + i.tray2Amount + i.tray3Amount;

            i.approvedPercent = batchFilesViewAsCount ?
                (i.totalCount > 0 ? i.approvedCount / i.totalCount : 0) :
                (i.totalAmount > 0 ? i.approvedAmount / i.totalAmount : 0);

            i.recycledPercent = batchFilesViewAsCount ?
                (i.totalCount > 0 ? i.recycledCount / i.totalCount : 0) :
                (i.totalAmount > 0 ? i.recycledAmount / i.totalAmount : 0);

            i.processingPercent = batchFilesViewAsCount ?
                (i.totalCount > 0 ? i.processingCount / i.totalCount : 0) :
                (i.totalAmount > 0 ? i.processingAmount / i.totalAmount : 0);

            i.resolvedPercent = batchFilesViewAsCount ?
                (i.totalCount > 0 ? i.resolvedCount / i.totalCount : 0) :
                (i.totalAmount > 0 ? i.resolvedAmount / i.totalAmount : 0);

            i.unresolvedPercent = batchFilesViewAsCount ?
                (i.totalCount > 0 ? i.unresolvedCount / i.totalCount : 0) :
                (i.totalAmount > 0 ? i.unresolvedAmount / i.totalAmount : 0);

            i.tray1Percent = batchFilesViewAsCount ?
                (i.totalCount > 0 ? i.tray1Count / i.totalCount : 0) :
                (i.totalAmount > 0 ? i.tray1Amount / i.totalAmount : 0);

            i.tray2Percent = batchFilesViewAsCount ?
                (i.totalCount > 0 ? i.tray2Count / i.totalCount : 0) :
                (i.totalAmount > 0 ? i.tray2Amount / i.totalAmount : 0);

            i.tray3Percent = batchFilesViewAsCount ?
                (i.totalCount > 0 ? i.tray3Count / i.totalCount : 0) :
                (i.totalAmount > 0 ? i.tray3Amount / i.totalAmount : 0);
        });

        return result;
    }, [batchFiles, batchFilesCurrentPage, batchFilesViewAsCount]);

    const handleMerchantChange = (selectedMerchant: Option | null) => {
        setSelectedMerchant(selectedMerchant);
    };

    return (<>
        <PageSection>
            <PageHeader title="Declines dashboard" backButton />
            <div>Tray and batch file statistics.</div>
        </PageSection>

        {merchantOptions.length > 1 ?
            <PageSection>
                <Row>
                    <div className="col-md-12 col-lg-5">
                        <FormGroup fieldId="merchantNumber" label="Merchant">
                            <MerchantDropdown onChange={handleMerchantChange} useDefaultValue />
                        </FormGroup>
                    </div>
                </Row>
            </PageSection> :
            <></>}

        <PageSection>
            <h2>Trays</h2>
            {traysStatus === APICallRequestState.LOADING || traysStatus === APICallRequestState.PENDING ?
                <LoadingIndicator /> :
                <ScrollingTable
                    data={trayGridItems}
                    columns={[{
                        label: "Name",
                        getter: x => x.trayName
                    }, {
                        label: "Count",
                        getter: x => x.count,
                        textAlign: "right"
                    }, {
                        label: "% of total count",
                        getter: x => x.percentOfTotalCount !== undefined ? toPercent(x.percentOfTotalCount) : "",
                        textAlign: "right"
                    }, {
                        label: "Value",
                        getter: x => x.amount !== undefined ? currencyUtil.convertToDisplayString(x.amount) : "",
                        textAlign: "right"
                    }, {
                        label: "% of total value",
                        getter: x => x.percentOfTotalAmount !== undefined ? toPercent(x.percentOfTotalAmount) : "",
                        textAlign: "right"
                    }]}
                />}
        </PageSection>

        <PageSection>
            <h2>Batch files</h2>
            <p>List of batch files containing declined payments. <TooltipTrigger>View definitions of the displayed columns.</TooltipTrigger></p>
            <FormGroup fieldId="viewAs" label="View As">
                <div className="batch-file-view-as-panel">
                    <RadioInput label="Count" checked={batchFilesViewAsCount} onChange={() => setBatchFilesViewAsCount(true)} />
                    <RadioInput label="Value" checked={!batchFilesViewAsCount} onChange={() => setBatchFilesViewAsCount(false)} />
                    <Checkbox label="Percentage" checked={batchFilesViewAsPercentage} onChange={(e) => setBatchFilesViewAsPercentage(e.currentTarget.checked)} />
                </div>
            </FormGroup>
            {batchFilesStatus === APICallRequestState.LOADING || traysStatus === APICallRequestState.PENDING ?
                <LoadingIndicator /> :
                <>
                    <ResponsiveTable
                        data={batchFileItems}
                        columns={[
                            {
                                label: "File name",
                                getter: x => x.fileName,
                                className: "overflow-wrap"
                            }, {
                                label: "Submitted",
                                getter: x => DateUtil.formatToDateTimeString(x.submissionDate),
                            }, {
                                label: "Approved",
                                getter: x => batchFilesViewAsPercentage ? toPercent(x.approvedPercent) : (batchFilesViewAsCount ? x.approvedCount : currencyUtil.convertToDisplayString(x.approvedAmount)),
                            }, {
                                label: "Recycled",
                                getter: x => batchFilesViewAsPercentage ? toPercent(x.recycledPercent) : (batchFilesViewAsCount ? x.recycledCount : currencyUtil.convertToDisplayString(x.recycledAmount)),
                            }, {
                                label: "Processing",
                                getter: x => batchFilesViewAsPercentage ? toPercent(x.processingPercent) : (batchFilesViewAsCount ? x.processingCount : currencyUtil.convertToDisplayString(x.processingAmount)),
                            }, {
                                label: "Resolved",
                                getter: x => batchFilesViewAsPercentage ? toPercent(x.resolvedPercent) : (batchFilesViewAsCount ? x.resolvedCount : currencyUtil.convertToDisplayString(x.resolvedAmount)),
                            }, {
                                label: "Unresolved",
                                getter: x => batchFilesViewAsPercentage ? toPercent(x.unresolvedPercent) : (batchFilesViewAsCount ? x.unresolvedCount : currencyUtil.convertToDisplayString(x.unresolvedAmount)),
                            }, {
                                label: trays?.tray1Nickname ?? "Tray 1",
                                getter: x => batchFilesViewAsPercentage ? toPercent(x.tray1Percent) : (batchFilesViewAsCount ? x.tray1Count : currencyUtil.convertToDisplayString(x.tray1Amount)),
                                className: "overflow-wrap"
                            }, {
                                label: trays?.tray2Nickname ?? "Tray 2",
                                getter: x => batchFilesViewAsPercentage ? toPercent(x.tray2Percent) : (batchFilesViewAsCount ? x.tray2Count : currencyUtil.convertToDisplayString(x.tray2Amount)),
                                className: "overflow-wrap"
                            }, {
                                label: trays?.tray3Nickname ?? "Tray 3",
                                getter: x => batchFilesViewAsPercentage ? toPercent(x.tray3Percent) : (batchFilesViewAsCount ? x.tray3Count : currencyUtil.convertToDisplayString(x.tray3Amount)),
                                className: "overflow-wrap"
                            },
                        ]}
                    />
                    <PaginationControl
                        currentPage={batchFilesCurrentPage}
                        onPageChanged={newPage => setBatchFilesCurrentPage(newPage)}
                        itemCount={batchFiles?.length ?? 0}
                        itemsPerPage={batchFilesPerPage}
                    />
                </>
            }
        </PageSection>
        <Tooltip>
            <h3>Batch file column definitions</h3>
            <DescriptionList
                items={[
                    { name: "Approved", value: "Transactions approved on the first try." },
                    { name: "Recycled", value: "Transaction that got declined on the first attempt, but approved on a subsequent attempt." },
                    { name: "Processing", value: "Transactions that have been declined and are currently scheduled to be retried." },
                    { name: "Trays", value: "Transactions that have ended up in tray." },
                ]}
                descriptionWordBreak={false}
            />
        </Tooltip>
    </>);
};

function mapStateToProps(state: RootState) {
    return {
        merchant: state.accounts.users.merchant
    };
}

export default connect(mapStateToProps)(DeclinesDashboardPage);
