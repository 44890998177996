import { useState } from "react";
import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import _ from "lodash";

import countryUtil from "@premier/utils/country";
import { Divider, Button, Modal } from "@premier/ui";
import { Form, CheckboxField, SubmitButton, validate, FormErrorList } from "@premier/form";
import { PaymentMethodRequestPendingModal, PaymentMethodDetailsForms } from "components/DataVault";
// @ts-ignore
import errorMaps from "constants/errorMaps";
// @ts-ignore
import { PaymentMethodDetailsTabKeys } from "constants/billpay";

import * as commonActions from "components/Common/_actions/actions";
import * as customerActions from "components/DataVault/_actions/customerActions";
import * as paymentRequestActions from "components/PaymentRequests/_actions/paymentRequestActions";
import PaymentRequestActionTypes from "components/PaymentRequests/_actions/paymentRequestActionTypes";
import { Customer } from "packages/utils/models";
import { Merchant } from "models";
import { FieldError } from "api/mapErrors";
import { RootState } from "store/store";

type Props = {
    //property to display modal
    show: boolean;
    //function to close modal from calling page
    onCancel: (needReload: boolean) => void;
    customer: Customer;
    merchant: Merchant;
    paymentRequestId: string;
    customerActions: any;
    commonActions: any;
    paymentRequestActions: any;
    errors: FieldError[];
}

const AddCustomerTokenModal = ({
    show, //logic renders
    onCancel, //function
    customer, merchant, paymentRequestId, //state
    customerActions, commonActions, paymentRequestActions, errors, //API actions/redux
}: Props) => {
    const [showRequestPendingModal, setShowRequestPendingModal] = useState(false);

    function handleAddToken(formData: any, context: any) {
        const tokenRequest = {
            ...formData,
            customerId: customer.customerId,
        };

        if (isCreateAction(context)) {
            customerActions.addCustomerToken(tokenRequest).then(() => {
                handleClose(true);
            });
        } else {
            const paymentDetails = {
                ...formData.paymentRequest,
                customerId: customer.customerId,
                childMerchantNumber: customer.childMerchantNumber,
                currencyCode: merchant.currency?.code,
                action: PaymentRequestActionTypes.TokeniseOnly
            };
            paymentRequestActions.create(paymentDetails);
            setShowRequestPendingModal(true);
        }
    }

    function handleClose(needReload: boolean) {
        commonActions.clearErrors();
        setShowRequestPendingModal(false);
        onCancel(needReload);
    }

    function isCreateAction(context: any) {
        return context.getValue("paymentMethodDetailsTab") === PaymentMethodDetailsTabKeys.CREATE;
    }

    function mapErrors(errors: FieldError[]) {
        return errors?.map(e => ({
            ...e,
            field: e.field ? mapErrorsFromDto(e.field) : ""
        }));
    }

    function mapErrorsFromDto(parameter: string) {
        switch (parameter) {
            case "billerCodeForm.billerCrnList.crn1":
                return "paymentRequest.billerCodeForm.billerCrnList.crn1";
            case "billerCodeForm.billerCrnList.crn2":
                return "paymentRequest.billerCodeForm.billerCrnList.crn2";
            case "billerCodeForm.billerCrnList.crn3":
                return "paymentRequest.billerCodeForm.billerCrnList.crn3";
            default:
                return parameter;
        }
    }

    if (showRequestPendingModal && paymentRequestId)
        return <PaymentMethodRequestPendingModal
            paymentMethodRequestId={paymentRequestId}
            onClose={() => handleClose(true)}
        />;

    return (
        <Modal
            show={show}
            onHide={() => handleClose(false)}
            dialogClassName="modal-token"
        >
            <Modal.Header closeButton>
                <Modal.Title>New payment method</Modal.Title>
            </Modal.Header>

            <Form
                initialValues={{
                    paymentMethodDetailsTab: PaymentMethodDetailsTabKeys.CREATE,
                    customerIdentified: false,
                    paymentRequest: {
                        mobile: { iddCode: countryUtil.getIddCode(merchant.countryCode) },
                        billerCodeForm: {
                            merchantNumber: customer?.childMerchantNumber
                        }
                    }
                }}
                initialValidation={{
                    customerIdentified: validate().required("Please check the box if you want to proceed"),
                }}
                onSubmit={handleAddToken}
                errors={mapErrors(errors)}
                errorMaps={errorMaps}
                render={(context) => (<>
                    <Modal.Body>
                        <PaymentMethodDetailsForms merchantNumber={customer?.childMerchantNumber ?? ""} allowBillerSelection merchant={merchant} />
                        <Divider />

                        <CheckboxField mandatory name="customerIdentified">
                            I have identified the customer and advised that the conditions of the Direct Debit will be made available within 7 days
                        </CheckboxField>
                        <Divider />

                        <FormErrorList />
                    </Modal.Body>

                    <Modal.Footer buttons>
                        <SubmitButton primary>{isCreateAction(context) ? "Add" : "Send"}</SubmitButton>
                        <Button default onClick={() => { handleClose(false); }}>Cancel</Button>
                    </Modal.Footer>
                </>)}
            />
        </Modal>
    );
};

function mapStateToProps(state: RootState) {
    return {
        paymentRequestId: _.get(state.paymentRequest.create, "data.guid"),
        customer: state.dataVault.customer.details,
        errors: state.dataVault.customer.errors,
        merchant: state.accounts.users.merchant,
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        customerActions: bindActionCreators(customerActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch),
        paymentRequestActions: bindActionCreators(paymentRequestActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddCustomerTokenModal);
