import { StationeryShopCartItem } from "./models";

const freightDbId = 40; //40 is the default stationery shop's freight item.

export class StationeryShopUtil {
    /** True if this cart item is a Freight item */
    productIsFreight = (cartItem: StationeryShopCartItem) => {
        return !!cartItem && (cartItem.productId === freightDbId);
    }

    /** Returns (a copy of) the cart items without the freight item in it.
     * @param {Array} cartItems CartItems or OrderItems
     */
    excludeFreight = (cartItems: StationeryShopCartItem[]) => {
        if (!cartItems)
            return cartItems;

        return cartItems.filter(x => !this.productIsFreight(x));
    }

    /** True if the cart items does not contain any real products (ignoring Freight) */
   cartIsEmpty = (cartItems: StationeryShopCartItem[]) => {
        if (!cartItems)
            return true;

        return cartItems.length <= 1 && this.excludeFreight(cartItems).length === 0;
    }

}

const stationeryShopUtil = new StationeryShopUtil();
export default stationeryShopUtil;