import React, { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { NavItem } from 'models';
import './MenuItemExpander.scss';

type Props = {
    items: NavItem[],
    subMenuIsExpanded?: boolean,
    className?: string,
    children?: ReactNode,
    onClick?: () => void,
};

const MenuItemExpander = ({
    items, subMenuIsExpanded, className, children, onClick,
}: Props) => {

    const location = useLocation();
    const isActive = items.filter(i => i.href && location.pathname.startsWith(i.href)).length > 0;

    const containerClasses = classNames(className, { 'active': isActive });
    const expanderClasses = classNames('menu-item-expander', {
        'active': isActive,
        'is-expanded': subMenuIsExpanded
    });

    return (
        <div className={containerClasses} onClick={onClick}>
            {children}

            <div className={expanderClasses}></div>
        </div>
    );
}

export default MenuItemExpander;