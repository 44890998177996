import React, { ReactNode, useState } from 'react';
import { AccordionChild, AccordionChildProps } from './AccordionChild';

type Props = {
    children?: ReactNode
};

const AccordionGroup = ({
    children
}: Props) => {

    const [selected, setSelected] = useState(-1);

    let processedChildren = React.Children.map(children, (child, index) =>
    {
        if (!React.isValidElement<AccordionChildProps>(child) || child.type !== AccordionChild) {
            return child;
        }

        return React.cloneElement(child, {
                expanded: selected === index ? true : false,
                onToggle: () => setSelected(selected === index ? -1 : index)
            },
            child.props.children);
    });

    return (<>
        {processedChildren}
    </>);
};

export default AccordionGroup;
