import { IconText } from "@premier/ui";
import { PromotionCard } from "components/Common";

type Props = {
    termsAndConditionsExist: boolean | undefined | null;
}

const PaymentPlanTermsAndConditionsRequiredNotification = ({ termsAndConditionsExist }: Props) => {
    return termsAndConditionsExist === false ?
        <PromotionCard>
            <IconText info>
                Terms and conditions documents are required before payment plans can be created. Please contact
                your bank representative to set them up.
            </IconText>
        </PromotionCard> : null;
};

export default PaymentPlanTermsAndConditionsRequiredNotification;
