import { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import { RootState } from "store/store";

// @ts-ignore
import labels from "constants/labels";
import currencyUtil from "@premier/utils/currency";
import textUtil from "@premier/utils/text";
import date from "@premier/utils/date";
import * as billpayUtil from "@premier/utils/billpay";
import { LoadingIndicator, CardContainer } from "@premier/ui";

import PrintContainer from "components/Layout/PrintContainer";
import { withError } from "components/Common";
import { BaseTransactionDetailCategory } from "components/Transactions";
import { Transaction } from "models/Transaction";

import * as paymentActions from "components/Transactions/_actions/paymentActions";
import { NameValue } from "models";

import "./TransactionDetailsPrint.scss";
import { TxnAdditionalPaymentModel } from "packages/webapi-client";

type Props = {
    transaction: Transaction;
    merchantAddress: MerchantAddress;
    merchantTimeZone: MerchantTimeZone;
    isLoading: boolean;
    actions: any;
}

type MerchantAddress = {
    tradingName:    string;
    address:        Address;
    phoneNumber:    string;
    faxNumber:      string;
    websiteUrl:     string;
    emailAddress:   string;
    receiptMessage: string;
    id1:            string;
    id2:            string;
    id1Label:       string;
    id2Label:       string;
    logoFileSize:   number;
}

type Address = {
    addressLine1: string;
    addressLine2: string;
    suburb:       string;
    state:        string;
    postcode:     string;
}

type MerchantTimeZone = {
    id:          number;
    location:    string;
    description: string;
}

const TransactionDetailsPrint = ({
    transaction, merchantAddress, merchantTimeZone, //states
    isLoading, actions
}: Props) => {
    const [noTransactionFound, setNoTransactionFound] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [additionalPaymentItems, setAdditionalPaymentItems] = useState<NameValue<string>[]>([])

    useEffect(() => {
        actions.getTransactionDetails(transaction.paymentProcessedTxnModel.txnHistoryId, transaction.paymentProcessedTxnModel.isExternal)
            .then(() => {
                setIsLoaded(true);
            })
            .catch((err: any) => {
                console.error(err);
                setNoTransactionFound(true);
            });
    }, [transaction.paymentProcessedTxnModel.txnHistoryId, transaction.paymentProcessedTxnModel.isExternal]);

    useEffect(() => {
        if (transaction.paymentSource?.merchantId !== 0) {
            actions.getMerchantAddress(transaction.paymentSource?.merchantId, transaction.paymentSource?.merchantNumber)
                .then(() => {
                    setIsLoaded(true);
                });
        }
    }, [transaction.paymentSource?.merchantId]);

    useEffect(() => {
        if (isLoaded) {
            setTimeout(() => {
                window.print();
            }, 300);  // Unfortunately there is no way to tell when the DOM has been fully updated, which seems to take about 50ms from componentDidUpdate.
        }
    }, [isLoaded]);

    useMemo(() => {
        setAdditionalPaymentItems(getAdditionalPaymentsItems(transaction?.txnAdditionalPaymentModel))
    }, [transaction?.txnAdditionalPaymentModel])


    const originalAmount = transaction?.paymentProcessedTxnModel?.originalAmount;
    const surchargeAmount = transaction?.paymentProcessedTxnModel?.surchargeAmount;
    const totalAmount = transaction?.paymentProcessedTxnModel?.totalAmount;
    const currencyCode =  transaction?.paymentProcessedTxnModel?.currencyCode;

    function getCleanString(val: string) {
        if (val === "")
            return null;
        return val;
    }

    function getTransactionDetailItems() {
        const detailItems = [
            { name: "You Have Paid:", value: merchantAddress?.tradingName },
            { name: "Biller Code:", value: transaction?.paymentProcessedTxnModel?.billerCode },
            { name: (transaction?.paymentSource?.crn1Name || labels.crn1) + ":", value: getCleanString(transaction?.paymentProcessedTxnModel?.crn1 ?? "") },
            { name: (transaction?.paymentSource?.crn2Name || labels.crn2) + ":", value: getCleanString(transaction?.paymentProcessedTxnModel?.crn2 ?? "") },
            { name: (transaction?.paymentSource?.crn3Name || labels.crn3) + ":", value: getCleanString(transaction?.paymentProcessedTxnModel?.crn3 ?? "") },
            { name: "Merchant Reference:", value: getCleanString(transaction?.paymentProcessedTxnModel?.merchantReference ?? "") },
            { name: "Source:", value: getCleanString(transaction?.paymentSource?.txnSourceDescription ?? "") },
            { name: "Original Amount:", value: currencyUtil.convertToDisplayString(originalAmount ?? 0, currencyCode) },
            { name: "Surcharge Amount:", value: currencyUtil.convertToDisplayString(surchargeAmount ?? 0, currencyCode) },
            { name: "Total Amount:", value: currencyUtil.convertToDisplayString(totalAmount ?? 0, currencyCode) },
            { name: "BSB Number:", value: transaction?.paymentProcessedTxnModel?.bankDetails?.bsb },
            { name: "Account Number:", value: transaction?.paymentProcessedTxnModel?.bankDetails?.accountNumber },
            { name: "Card Number:", value: transaction?.paymentProcessedTxnModel?.cardDetails?.cardNumber },
        ];

        // Bank Accounts don't have expiry dates so don't display it if not necessary
        if (transaction.paymentProcessedTxnModel?.cardDetails?.expiryDate) {
            detailItems.push({ name: "Expiry Date:", value: billpayUtil.formatExpiry({ month: transaction?.paymentProcessedTxnModel?.cardDetails?.expiryDate?.month, year: transaction?.paymentProcessedTxnModel?.cardDetails?.expiryDate?.year }, "", "") });
        }

        return detailItems;
    }

    function getAdditionalPaymentsItems(additionalPayments: TxnAdditionalPaymentModel[] | undefined) {
        const items: NameValue<string>[] = additionalPayments?.map(x => ({
            name: x.description === 'BASE_PAYMENT' ? 'Base Payment' : (x.description ?? ""),
            value: currencyUtil.convertToDisplayString(x.amount ?? 0, currencyCode)
        })) ?? [];

        return items;
    }

    return (
        <PrintContainer title="BPOINT Payment Receipt">
            {isLoading && (<>
                <CardContainer header="Payment Details">
                    <LoadingIndicator/>
                </CardContainer>
            </>
            )}

            {!isLoading && noTransactionFound && (
                <p>Transaction not found or an error occurred.</p>
            )}

            {!isLoading && !noTransactionFound && transaction && transaction.paymentProcessedTxnModel && (<>

                <div className="transaction-details-print">

                    {merchantAddress && (
                        <div className="merchant-details">
                            <div><b>{merchantAddress.tradingName}</b></div>
                            <div>{merchantAddress.id1         && (<>{merchantAddress.id1Label ? merchantAddress.id1Label : "ABN"}: {textUtil.formatAbn(merchantAddress.id1)}</>)}</div>
                            <div>{merchantAddress.id2         && (<>{merchantAddress.id2Label ? merchantAddress.id2Label : "ACN"}: {textUtil.formatAbn(merchantAddress.id2)}</>)}</div>
                            <div>{merchantAddress.address.addressLine1}</div>
                            <div>{merchantAddress.address.addressLine2}</div>
                            <div>{merchantAddress.address.suburb}, {merchantAddress.address.state} {merchantAddress.address.postcode}</div>
                            <div>{merchantAddress.phoneNumber && (<>Phone: {merchantAddress.phoneNumber}</>)}</div>
                            <div>{merchantAddress.faxNumber   && (<>Fax: {merchantAddress.faxNumber}</>)}</div>
                            <div>{merchantAddress.websiteUrl         && (<>Web: {merchantAddress.websiteUrl}</>)}</div>
                            <div>{merchantAddress.emailAddress       && (<>Email: {merchantAddress.emailAddress}</>)}</div>
                        </div>
                    )}

                    <div className="payment-details">
                        <BaseTransactionDetailCategory title="Payment Details" items={getTransactionDetailItems()} printView />
                    </div>

                    {additionalPaymentItems.length > 0 &&
                        <div className="payment-details">
                            <BaseTransactionDetailCategory title="Payment Breakdown" items={additionalPaymentItems} printView />
                        </div>
                    }

                    <div className="auth-result">
                        <BaseTransactionDetailCategory title={"Authorisation Result - " + (transaction.paymentProcessedTxnModel.responseCode === "0" ? "Approved" : "Declined")} items={[
                            { name: "Type", value: transaction?.paymentSource?.transactionTypeDetails?.description },
                            { name: "Transaction Date:", value: transaction?.paymentProcessedTxnModel?.processedDate ? date.convertToDateTimeString(transaction?.paymentProcessedTxnModel?.processedDate) : "" },
                            { name: "Time Zone:", value: merchantTimeZone?.location },
                            { name: "Receipt Number:", value: transaction?.paymentProcessedTxnModel?.receiptNumber },
                        ]} printView />
                    </div>
                </div>
            </>)}
            {!isLoading && (<>
                {merchantAddress?.receiptMessage && (
                    <div className="receipt-message">
                        {merchantAddress.receiptMessage}
                    </div>

                )}
            </>
            )}
        </PrintContainer>
    );
};

function mapStateToProps(state: RootState, ownProps: any) {
    const txnHistoryId = ownProps.match.params.id;

    let transaction: Transaction = {
        paymentProcessedTxnModel: {
            txnHistoryId: txnHistoryId,
            txnFinancialType: undefined,
            responseCode: "",
            authorizationResult: "",
            receiptNumber: "",
            processedDate: undefined,
            billerCode: "",
            crn1: "",
            crn2: "",
            crn3: "",
            merchantReference: "",
            originalAmount: 0,
            surchargeAmount: 0,
            totalAmount: 0,
            currencyCode: 0,
            bankDetails: {
                bsb: "",
                accountNumber: ""
            },
            isExternal: false,
            cardDetails: {
                cardNumber: "",
                expiryDate: {
                    month: 0,
                    year: 0
                },
                cvcPresent: false,
                cardTypeCode: "",
                cardSubType: undefined,
                cardIssuingCountryName: ""
            },
        },
        paymentSource: {
            txnSourceDescription: "",
            userNameProcessed: "",
            txnTypeDetails: {
                key: undefined,
                description: ""
            },
            txnSubType: undefined,
            transactionTypeDetails: {
                key: undefined,
                description: ""
            },
            merchantNumber: "",
            authorisationResult: "",
            crn1Name: "",
            crn2Name: "",
            crn3Name: "",
            originalTransactionAmount: 0,
            capturedAmount: 0,
            refundedAmount: 0,
            partiallyCompleted: false,
            fullyCompleted: false,
            partiallyRefunded: false,
            completelyRefunded: false,
            preAuthSentAsVerify: false,
            token: "",
            transactionNo: "",
            isQrCodeTxn: false,
            txnSubSourceDescription: "",
            lcrEligible: false,
            eftposRouted: false,
            merchantId: 0
        },
        isThreeDs: false,
        isFraudScreened: false,
        fraudDetectionResponseModel: {
            responseMessage: "",
            responseCode: "",
            txnRejected: false,
            redResponse: {
                requestId: "",
                orderId: "",
                statusCode: "",
                responseCode: "",
                recordId: "",
                fraudNeural: ""
            }
        },
        threeDsAuthenticationModel: {}
    };

    // If (or as soon as) the redux store has the requested transaction then use that
    if (state.transactions?.payments?.transactionDetails?.paymentProcessedTxnModel?.txnHistoryId === Number(txnHistoryId))
        transaction = state.transactions.payments.transactionDetails;

    return {
        transaction: transaction,
        isLoading: state.transactions.payments.isLoading,
        merchantAddress: state.transactions.payments.merchantAddress,
        merchantTimeZone: state.accounts.users.merchant.timeZone,
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        actions: bindActionCreators(paymentActions, dispatch)
    };
}

function mapStoreToErrors(state: RootState) {
    return state.accounts.users.errors;
}

export default withError(connect(mapStateToProps, mapDispatchToProps)(TransactionDetailsPrint), mapStoreToErrors, null);
