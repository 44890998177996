import { useState, useEffect, FocusEvent } from 'react';
import classNames from 'classnames';
import currencyUtil from '@premier/utils/currency';
import { InputProps } from './Input';
import './Currency.scss';
import CurrencyInput from 'react-currency-input-field';

export type CurrencyProps = Omit<InputProps, 'value' | 'onChange' | 'ref'> & {
    /** The default is the merchant's currency alphaCode, eg. 'AUD'. Not shown if empty string or if compact is true. */
    currencySymbol?: string,
    /** The default is like '0.00' depending on merchant currency */
    placeholder?: string,
    /** Do not show currencySymbol, for quick filter */
    compact?: boolean,
    value: string | number,
    onChange?: (value: number | null) => void,
    disabled?: boolean
};

const Currency = ({
    value, error, currencySymbol, placeholder, compact, id, className,
    onFocus, onChange, onBlur, name, maxLength, disabled,
    ...otherProps
}: CurrencyProps) => {

    const [focused, setFocused] = useState(false);
    const [displayValue, setDisplayValue] = useState('');

    useEffect(() => {
        // Make sure the value is always formatted (with thousand-separators), but not when the field is in focus (You don't want the value changing when the user is typing)
        if (!focused) {
            setDisplayValue(currencyUtil.formatAmount(value).replaceAll(",", ""));
        }
    }, [value, focused]);

    const defaultPlaceholder = currencyUtil.formatAmount(0);

    function handleChange(value: any) {
        setDisplayValue(value);
        if (onChange)
            onChange(currencyUtil.parseAmount(value));
    }

    function handleFocus(e: FocusEvent<HTMLInputElement>) {
        setFocused(true);

        if (onFocus)
            onFocus(e);
    }

    function handleBlur(e: FocusEvent<HTMLInputElement>) {
        setFocused(false);

        // The user has completed data entry, so format the value
        setDisplayValue(currencyUtil.formatAmount(e.target.value).replaceAll(",", ""));

        if (onChange)
            onChange(currencyUtil.parseAmount(e.target.value));

        if (onBlur)
            onBlur(e);
    }

    return (
        <div className={classNames('input-group currency', {
            'focused': focused,
            'has-error': !!error
        }, className)}
        >
            {!compact && currencySymbol !== '' && (
                <div className='input-group-prepend'>
                    <span className='input-group-text' id={`${id}_currencyCode`}>
                        {currencySymbol || currencyUtil.getCurrency().alphaCode}
                    </span>
                </div>
            )}
            <CurrencyInput
                className={error ? 'textbox form-control has-error' : 'textbox form-control'}
                id={id}
                name={name}
                value={displayValue}
                placeholder={placeholder == null ? defaultPlaceholder : placeholder}
                decimalScale={2}
                maxLength={maxLength}
                onValueChange={(value) => handleChange(value)}
                onBlur={handleBlur}
                onFocus={handleFocus}
                disabled={disabled}
            />
        </div>
    );
};

export default Currency;
