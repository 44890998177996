import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import LoadingIndicator from './LoadingIndicator';
import './LoadingOverlay.scss';

export type LoadingOverlayProps = {
    text?: string
}

/** A dark transparent layer with a loading animation and a 'Loading...' text that overlays the whole page. */
export default function LoadingOverlay({
    text = 'Loading...'
}: LoadingOverlayProps) {

    const [domNode] = useState(() => document.createElement('div'));

    useEffect(() => {
        document.body.appendChild(domNode);
        return () => {
            document.body.removeChild(domNode);
        }
    }, [domNode]);

    return ReactDOM.createPortal(
        <div className='loading-overlay-page'>
            <div>
                <LoadingIndicator></LoadingIndicator>
                {text}
            </div>
        </div>,
        domNode
    );
}