import "./ServerError.scss";

/** This is for error message(s) coming from the server */
export const ServerError = ({
    errors,
}: {
    errors?: (string | undefined)[];
}) => {

    if (errors && errors.length > 0) {
        return (
            <div className="server-error" role="alert">
                { errors.length === 1
                    ? errors[0]
                    : (
                        <ul>
                            { errors.map((msg, idx) => <li key={idx}>{msg}</li>) }
                        </ul>
                    )
                }
            </div>
        );
    }

    return null;
};
