import _ from 'lodash';

export const formActions = {
    setValues: 'SET_VALUES',
    setValidations: 'SET_VALIDATIONS',
    setErrors: 'SET_ERRORS',
    setFieldValue: 'SET_FIELD_VALUE',
    setFieldValidation: 'SET_FIELD_VALIDATION',
    removeFieldValidation: 'REMOVE_FIELD_VALIDATION', // Completely removes validation logic for the given field
    setFieldError: 'SET_FIELD_ERROR',
    clearErrors: 'CLEAR_ERRORS',
    removeField: 'REMOVE_FIELD',
    addField: 'ADD_FIELD',
    addErrors: 'ADD_ERRORS',
    registerField: 'REGISTER_FIELD',
    unRegisterField: 'UNREGISTER_FIELD',
};

export const formReducer = (state, action) => {
    switch (action.type) {
        case formActions.setValues:
            return Object.assign({}, state, { values: Object.assign({}, action.payload) });
        case formActions.setValidations:
            return { ...state, validations: Object.assign({}, action.payload) };
        case formActions.setErrors:
            return { ...state, errors: action.payload };
        case formActions.setFieldValue:
            if(action.valuesFromParent)
                state.values = {...action.valuesFromParent};
            _.set(state.values, action.path, action.value);
            return Object.assign({}, state, { values: { ...state.values } });
        case formActions.setFieldValidation:
            _.set(state.validations, action.path, action.value);
            return Object.assign({}, state, { validations: { ...state.validations } });
        case formActions.removeFieldValidation:
            _.unset(state.validations, action.path);
            return Object.assign({}, state, { validations: { ...state.validations } });
        case formActions.setFieldError:
            _.set(state.errors, action.path, action.value);
            return Object.assign({}, state, { errors: { ...state.errors } });
        case formActions.clearErrors:
            return Object.assign({}, state, { errors: {}, formErrors: [] });
        case formActions.removeField:
            _.set(state.values, action.path, undefined);
            return Object.assign({}, state, { values: { ...state.values } });
        case formActions.addField:
            _.set(state.values, action.path, action.value);
            return Object.assign({}, state, { values: { ...state.values } });
        case formActions.addErrors:
            var errors = _.cloneDeep(state.errors);
            _.merge(errors, action.errors);
            return Object.assign({}, state, { errors: errors, formErrors: action.formErrors });
        case formActions.registerField:
            var fields = _.cloneDeep(state.fields);
            _.set(fields, action.path, action.value);
            return Object.assign({}, state, { fields: fields });
        case formActions.unRegisterField:
            var unsetfields = _.cloneDeep(state.fields);
            _.unset(unsetfields, action.path);
            return Object.assign({}, state, { unsetfields: fields });
        default:
            return state;
    }
};
