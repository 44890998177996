import * as actions from "./actionTypes";
import { paymentRequestApi, requestApi } from "api";
import { promiseRequestActions, promiseResultRequestActions } from "components/Common/requestActions";

export const search = promiseRequestActions(actions.SEARCH_PAYMENT_REQUESTS, (resultsPerPage, pageIndex, filter, sort) => paymentRequestApi.search(resultsPerPage, pageIndex, filter, sort)).send;
export const create = promiseRequestActions(actions.CREATE_PAYMENT_REQUEST, (paymentDetails, action, mapErrorsFromDto) => paymentRequestApi.create(paymentDetails, action, mapErrorsFromDto)).send;
export const createQrRequest = promiseRequestActions(actions.CREATE_PAYMENT_REQUEST, (paymentDetails, action, mapErrorsFromDto) => paymentRequestApi.createQrRequest(paymentDetails, action, mapErrorsFromDto)).send;
export const get = promiseRequestActions(actions.GET_PAYMENT_REQUEST, (id) => paymentRequestApi.get(id)).send;
const paymentRequestStatusActions = promiseRequestActions(actions.GET_PAYMENT_REQUEST_STATUS, (id) => paymentRequestApi.getStatus(id));
export const getStatus = paymentRequestStatusActions.send;
export const clearPaymentRequestStatus = paymentRequestStatusActions.clear;
export const cancel = promiseRequestActions(actions.CANCEL_PAYMENT_REQUEST, (id) => paymentRequestApi.cancel(id)).send;
export const resend = promiseRequestActions(actions.RESEND_PAYMENT_REQUEST, (id) => paymentRequestApi.resend(id)).send;

export const getRequestStatuses = promiseRequestActions(actions.GET_PAYMENT_REQUEST_STATUSES, () => paymentRequestApi.getPaymentRequestStatuses()).send;
export const getRequestActions = promiseRequestActions(actions.GET_PAYMENT_REQUEST_ACTIONS, () => paymentRequestApi.getPaymentRequestActions()).send;

export function saveRequestPageState(pageState) {
    return function(dispatch) {
        dispatch({ type: actions.SAVE_PAYMENT_REQUEST_PAGE_STATE, pageState });
    };
}

const paymentRequestConfigActions = promiseResultRequestActions(actions.GET_REQUEST_CONFIG, (merchantNumber, billerCode) => requestApi.getPaymentRequestSettings(merchantNumber, billerCode));
export const getRequestConfig = paymentRequestConfigActions.send;
export const clearRequestConfig = paymentRequestConfigActions.clear;

const updateRequestConfigActions = promiseResultRequestActions(actions.UPDATE_REQUEST_CONFIG, (merchantNumber, billerCode, requestConfog) => requestApi.updatePaymentRequestSettings(merchantNumber, billerCode, requestConfog));
export const updateRequestConfig = updateRequestConfigActions.send;
export const clearUpdateRequestConfig = updateRequestConfigActions.clear;