import { useState } from "react";
import { Navigate } from "react-router-dom";
import { TitleDropdown, UiOption } from "@premier/ui";
import { Biller } from "packages/webapi-client";

type Props = {
    billerCode: string | undefined;
    billers: Biller[] | undefined | null;
    getUrl: ((billerCode: string) => string) | undefined;
    defaultFirstOption?: UiOption<string>;
    hideInactiveBillers?: boolean;
}

/** A TitleDropdown that redirects to another URL when changed. Used on some Branding pages. */
const BillerTitleDropdown = ({
    billerCode, billers, getUrl, defaultFirstOption, hideInactiveBillers
}: Props) => {

    const [redirectToBillerCode, setRedirectToBillerCode] = useState<string>();

    function getBillerOptions() {
        const options: UiOption<string>[] = [];
        if (defaultFirstOption) {
            options.push(defaultFirstOption);
        }

        const shownBillers = hideInactiveBillers ? billers?.filter(b => b.enabled) : billers;

        return options.concat(shownBillers?.map(b => ({
            value: b.billerCode ?? "",
            label: b.billerCodeWithName ?? "" })) ?? []);
    }

    function handleBillerChange(item: UiOption<string> | null) {
        setRedirectToBillerCode(item?.value);
    }

    if (redirectToBillerCode != null &&
        redirectToBillerCode !== billerCode &&  // Only redirect if we are not already showing this billerCode
        (billerCode || redirectToBillerCode))  // Only redirect to merchant style if we are not already showing the merchant style
    {
        return getUrl ? <Navigate to={getUrl(redirectToBillerCode)} /> : <></>;
    }

    return (
        <TitleDropdown
            icon="account"
            placeholder="Choose biller code"
            options={getBillerOptions()}
            value={billerCode || ""}
            onChange={handleBillerChange}
        />
    );
};

export default BillerTitleDropdown;
