import React from 'react';
import { useSelector } from 'react-redux';
import { InvoiceModel } from 'packages/webapi-client';
import { Button, DescriptionList } from '@premier/ui';
import { userRoles } from 'components/Routing';
import SecurityUtil from '@premier/utils/security';
import currencyUtil from '@premier/utils/currency';
import dateUtil from '@premier/utils/date';
import labels from 'platforms/base/constants/labels';
import { InvoiceStatusEnum } from 'components/Invoicing/InvoiceStatusUtil';
interface Props {
    data: InvoiceModel;
    onCancel: () => void;
}

const authenticatedUserSelector = (state: any) => state.accounts.users.authenticatedUser;

const InvoiceDetailsList: React.FC<Props> = ({ data, onCancel }) => {
    const authenticatedUser = useSelector(authenticatedUserSelector);

    const invoiceDetails = [
        {
            name: labels.invoiceNumber,
            value: data.invoiceNumber,
        },
        {
            name: labels.invoiceDate,
            value: dateUtil.formatToDateString(data.invoiceDate!),
        },
        {
            name: labels.orderNumber,
            value: data.orderNumber,
        },
        {
            name: labels.termOfPayment,
            value:
                data.selectedTermOfPayment?.daysToPay === 1
                    ? `${data.selectedTermOfPayment?.daysToPay} day` // only 1 sets to a singular day
                    : `${data.selectedTermOfPayment?.daysToPay} days`, // every other option will default to day(s)
        },
        {
            name: labels.dueDate,
            value: dateUtil.formatToDateString(data.dueDate!),
        },
        {
            name: labels.dueAmount,
            value: currencyUtil.convertToDisplayString(data.amountTotal! - data.amountsPaid!, data.currencyCode!),
        },
    ];

    return (
        <>
            <DescriptionList items={invoiceDetails} />
            <div className='invoice-details-container'>
                <Button link className='invoice-details-links' onClick={() => window.open(data.customerViewUrl!)}>
                    View invoice
                </Button>

                {/* show cancel button if they have permission AND invoice is not cancelled */}
                {SecurityUtil.hasAccess([userRoles.invoiceSettings], authenticatedUser) &&
                    data.invoiceStatusId !== InvoiceStatusEnum.Cancelled &&
                    data.invoiceStatusId !== InvoiceStatusEnum.PaidInFull &&
                    data.invoiceStatusId !== InvoiceStatusEnum.Refunded && (
                        <Button link className='invoice-details-links' onClick={onCancel}>
                            Cancel invoice
                        </Button>
                    )}
            </div>
        </>
    );
};

export default InvoiceDetailsList;
