import 'react-app-polyfill/ie11';
import './polyfills.js';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'platform/fileLoader.js';
import {Provider} from 'react-redux';
import store from './store/store';
import { createRoot } from 'react-dom/client';

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
    <Provider store={store}>
        <App />
    </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
