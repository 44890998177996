import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import { Button, Link, PaddedContainer, Tooltip, TooltipTrigger } from '@premier/ui';
import { BillerSurchargeTableModal } from 'components/Settings';

import { PlatformRoutesConfiguration } from 'components/Routing';
import * as merchantActions from 'components/Settings/_actions/merchantActions';
import * as billerActions from 'components/Settings/_actions/billerActions';


/**
 * A PaddedContainer of Surcharge summary for a Biller.
 */
const SurchargeSection = ({
    merchantActions, billerActions,
    biller,
    paymentMethodsState, surchargesState,  // API / redux
}) => {

    const paymentMethods = paymentMethodsState.data;
    const surchargeRules = surchargesState.data;

    const [showSurchargeDialog, setShowSurchargeDialog] = useState(false)

    useEffect(() => {
        merchantActions.getPaymentMethods(biller.merchantNumber);
        billerActions.getSurchargeRules(biller.billerCode);
    }, []);


    return (<>
        <PaddedContainer lessMargin noDivider
            title='Surcharge'
            titleSuffix={
                <TooltipTrigger tip='Set up surcharge by card scheme, domestic or international cards and debit or credit cards'>
                    What's this?
                </TooltipTrigger>
            }
            button={<>
                <Link button to={PlatformRoutesConfiguration.settingsRoute.surcharges.generatePath(biller.billerCode)}>
                    Edit
                </Link>
            </>}
        >
            <Button subtle onClick={() => setShowSurchargeDialog(true)}>View Example</Button>
        </PaddedContainer>



        <Tooltip />

        <BillerSurchargeTableModal
            paymentMethods={paymentMethods}
            surchargeRules={surchargeRules}
            show={showSurchargeDialog}
            onClose={() => setShowSurchargeDialog(false)}
        />
    </>);
};

SurchargeSection.propTypes = {
    biller: PropTypes.shape({
        billerCode: PropTypes.string.isRequired,
        merchantNumber: PropTypes.string.isRequired
    }),
};

function mapStateToProps(state, ownProps) {
    return {
        // old way
        merchantNumber: state.accounts.users.merchant.merchantNumber,

        // new way
        paymentMethodsState: state.settings.paymentSettings.merchantPaymentMethods,
        surchargesState: state.settings.paymentSettings.surcharges,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        merchantActions: bindActionCreators(merchantActions, dispatch),
        billerActions: bindActionCreators(billerActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SurchargeSection);