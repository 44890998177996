import * as actions from './actionTypes';
import { requestsBatchApi } from 'api';
import { promiseRequestActions, promiseResultRequestActions } from 'components/Common/requestActions';

export function saveRequestPageState(pageState) {
    return function(dispatch) {
        dispatch({type: actions.SAVE_BATCH_PAYMENT_REQUEST_PAGE_STATE, pageState});
    }
}

export let uploadBatch = promiseRequestActions(actions.UPLOAD_BATCH_PAYMENT_REQUEST, (files) => {
    return requestsBatchApi.uploadBatch(files);
}).send;

export let getPaymentRequestBatches = promiseResultRequestActions(actions.GET_PAYMENT_REQUEST_BATCHES, (resultsPerPage, pageIndex, filter, sort) => {
    return requestsBatchApi.getBatches(resultsPerPage, pageIndex, filter, sort)
}).send;
