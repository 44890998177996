import { utilitiesApi } from 'api';
import * as actions from './actionTypes';

/** Return a list of currencies, which will either fetch from API or alternatively Redux if passed in.
* @param {Object[]} currencies object with information about each currency.
*/
export function getCurrencyList(currencies) {
    return function(dispatch) {
        if (currencies){
            return currencies
        }

        dispatch(getCurrencyListRequested());
        return utilitiesApi.getCurrencyList().then(currencies => {
            dispatch(getCurrencyListSuccess(currencies));
            return currencies;
        }).catch(err => {
            dispatch(getCurrencyListFailed(err));
            throw err;
        });
    }
}

export function getCurrencyListRequested() {
    return { type: actions.GET_CURRENCIES_REQUESTED };
}
export function getCurrencyListSuccess(currencies) {
    return { type: actions.GET_CURRENCIES_SUCCESS, currencies };
}
export function getCurrencyListFailed(err) {
    return { type: actions.GET_CURRENCIES_FAILED, err };
}

/** Return a list of countries, which will either fetch from API or alternatively Redux if passed in.
* @param {Object[]} countries object with information about each currency.
*/
export function getCountryList(countries) {
    return function(dispatch) {
        if (countries){
            return countries
        }

        dispatch(getCountryListRequested());
        return utilitiesApi.getCountryList().then(countries => {
            dispatch(getCountryListSuccess(countries));
            return countries;
        }).catch(err => {
            dispatch(getCountryListFailed(err));
            throw err;
        });
    }

}

export function getCountryListRequested() {
    return { type: actions.GET_COUNTRY_LIST_REQUESTED };
}
export function getCountryListSuccess(countries) {
    return { type: actions.GET_COUNTRY_LIST_SUCCESS, countries };
}
export function getCountryListFailed(err) {
    return { type: actions.GET_COUNTRY_LIST_FAILED, err };
}


export function getProcessTransactionOrderTypes() {
    return function(dispatch) {
        dispatch(getProcessTransactionOrderTypesRequested());

        return utilitiesApi.getProcessTransactionOrderTypes().then(types => {
            dispatch(getProcessTransactionOrderTypesSuccess(types));
        }).catch(err => {
            dispatch(getProcessTransactionOrderTypesFailed(err));
            throw err;
        });
    }
}

export function getProcessTransactionOrderTypesRequested() {
    return {type: actions.GET_PROCESS_TRANSACTION_ORDER_TYPES_REQUESTED};
}
export function getProcessTransactionOrderTypesSuccess(orderTypes) {
    return {type: actions.GET_PROCESS_TRANSACTION_ORDER_TYPES_SUCCESS, orderTypes};
}
export function getProcessTransactionOrderTypesFailed(err) {
    return {type: actions.GET_PROCESS_TRANSACTION_ORDER_TYPES_FAILED, err};
}

export function getOrderTypes() {
    return function(dispatch) {
        dispatch(getOrderTypesRequested());

        return utilitiesApi.getOrderTypes().then(types => {
            dispatch(getOrderTypesSuccess(types));
        }).catch(err => {
            dispatch(getOrderTypesFailed(err));
            throw err;
        });
    }
}

export function getOrderTypesRequested() {
    return {type: actions.GET_ORDER_TYPES_REQUESTED};
}
export function getOrderTypesSuccess(orderTypes) {
    return {type: actions.GET_ORDER_TYPES_SUCCESS, orderTypes};
}
export function getOrderTypesFailed(err) {
    return {type: actions.GET_ORDER_TYPES_FAILED, err};
}
