import { useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const Recaptcha = ({
    onChange, //logic render
    captchaSettings //state
}) => {
    const reCaptchaRef = useRef();

    if (!captchaSettings) {
        return null;
    }

    const SITE_KEY = captchaSettings.publicKey;

    return (<ReCAPTCHA
        style={{ display: "inline-block" }}
        theme="light"
        ref={reCaptchaRef}
        sitekey={SITE_KEY}
        onChange={onChange}
    />);
}

Recaptcha.propTypes = {
    onChange: PropTypes.func
};

function mapStateToProps(state) {
    return {
        captchaSettings: state.accounts.users.configuration && state.accounts.users.configuration.recaptchaSettings
    }
};

export default connect(mapStateToProps)(Recaptcha);