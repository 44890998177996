import React from "react";
import { useField } from "formik";
import { Switch } from "@premier/ui";
import classNames from "classnames";
import { Sizes } from "@premier/ui/src/Switch";

import "./FormikDatePicker.scss";

type FormikSwitchProps = {
    // name refers to the form field name (this is used as a reference ID by Formik and forms in general)
    name: string;
    on?: boolean;
    disabled?: boolean;
    className?: string;
    size?: Sizes;
    handleSwitchChange?: () => void;
};

const FormikSwitch: React.FC<FormikSwitchProps> = ({ name, disabled, className, size, handleSwitchChange }) => {
    const [field, meta, helpers] = useField(name);
    const { setValue } = helpers;
    const handleChange = () => {
        if (handleSwitchChange) {
            handleSwitchChange();
        }
        setValue(!field.value);
    };
    return <Switch
        className={classNames(className, { small: size === Sizes.Small })}
        on={field.value}
        disabled={disabled}
        onChange={handleChange}
        size={size}
    />;
};

export default FormikSwitch;