import { Icon } from "@premier/ui";
import { Transaction } from "models/Transaction";

type Props = {
    transaction?: Transaction;
    responseCode?: string;
}

const TransactionStatusIcon = (props: Props) => {
    const code = props.responseCode || props.transaction?.paymentProcessedTxnModel?.responseCode || props.transaction?.responseCode;

    if (code === "0")
        return <Icon {...props} tick title="Success" />;

    return <Icon {...props} alert title="Failed" />;
};

export default TransactionStatusIcon;