import { PageHeader, ProductTooltip } from "components/Common";
import { PageSection, SingleColumnFormContainer } from "packages/ui";
import NewPaymentRequestForm from "../components/NewPaymentRequestForm";

const NewPaymentRequestPage = () => {
    return (<>
        <PageSection>
            <PageHeader
                title={<>{"New payment request"}<ProductTooltip productTooltipModule={"NEW_PAYMENT_REQUEST"} /></>}
                subtitle="Make a payment request"
            />
        </PageSection>
        <PageSection>
            <SingleColumnFormContainer>
                <NewPaymentRequestForm />
            </SingleColumnFormContainer>
        </PageSection>
    </>);
};

export default NewPaymentRequestPage;
