import { config } from './util';
import {
    PaymentPlanOptionApiFactory,
    PaymentPlanOptionRequest
} from '@premier/webapi-client';

const paymentPlanOptionApi = PaymentPlanOptionApiFactory(config);

class PaymentPlanOptionApi {
    static async getPaymentPlanOptions(billerCode : string) {
        return await paymentPlanOptionApi.paymentPlanOptionGetPaymentPlanOptions(billerCode);
    }

    static async getPaymentPlanOption(optionId : number) {
        return await paymentPlanOptionApi.paymentPlanOptionGetPaymentPlanOption(optionId);
    }

    static async addPaymentPlanOption(request : PaymentPlanOptionRequest) {
        return await paymentPlanOptionApi.paymentPlanOptionAddPaymentPlanOption(request);
    }

    static async updatePaymentPlanOption(request : PaymentPlanOptionRequest) {
        return await paymentPlanOptionApi.paymentPlanOptionUpdatePaymentPlanOption(request);
    }
}

export default PaymentPlanOptionApi;
