import { AxiosPromise } from 'axios';
import { config } from './util';
import {
    GetBackofficeUrlRequest,
    ApiResponseGetBackofficeUrlResponse,
    DeeplinkingApiFactory,
} from '@premier/webapi-client';

const deeplinkingApi = DeeplinkingApiFactory(config);

class DeeplinkingApi {
    static getBackofficeUrl(request: GetBackofficeUrlRequest): AxiosPromise<ApiResponseGetBackofficeUrlResponse> {
        return deeplinkingApi.deeplinkingGetBackofficeUrl(request);
    }
}

export default DeeplinkingApi;
