import { Button, FormGroup, Input, ReorderableList } from "@premier/ui";
import { Item } from "@premier/ui/src/ReorderableList";

import React from "react";
import "./InvoiceSettingsDetailsForm.scss";

export const InvoiceSettingsDetailsFormCopy = {
    inputTitle: "New term of payment",
    inputPlaceholder: "Enter no. of days...",
    addButton: "Add term",
    listTitle: "Existing term of payment options",
    listDescription:
        "To remove or reorder option, please select an option to edit.",
};

interface Props {
    items: Item[];
    value?: number;
    inputError: string;
    listError: string;
    onAdd: () => void;
    onListChange: (items: Item[]) => void;
    onInputChange: (value?: number) => void;
}

const InvoiceSettingsDetailForm: React.FC<Props> = ({
    value,
    items,
    inputError,
    listError,
    onAdd,
    onInputChange,
    onListChange,
}) => {

    const parseInputChange = (input : string) => {
        const value = parseInt(input);

        if (isNaN(value)) {
            onInputChange(undefined);
            return;
        }
        onInputChange(value);
    };

    return (
        <>
            <div className="invoice-settings-input-field">
                <div className="invoice-settings-input-container">
                    <FormGroup
                        fieldId={"invoice_term_payment"}
                        error={inputError}
                        className="invoice-detail-input"
                        label={InvoiceSettingsDetailsFormCopy.inputTitle}
                    >
                        <Input
                            id={"invoice_term_payment_input"}
                            digitsOnly
                            placeholder={
                                InvoiceSettingsDetailsFormCopy.inputPlaceholder
                            }
                            value={value?.toString()}
                            error={inputError}
                            onEnter={onAdd}
                            onChange={(e) => parseInputChange(e.target.value)}
                        />
                    </FormGroup>

                    <Button
                        className="invoice-settings-add-button"
                        onClick={onAdd}
                    >
                        {InvoiceSettingsDetailsFormCopy.addButton}
                    </Button>
                </div>
            </div>

            <div className="invoice-settings-input-field">
                <FormGroup
                    fieldId={"invoice_term_payment_list"}
                    className={"invoice-settings-list"}
                    error={listError}
                    label={InvoiceSettingsDetailsFormCopy.listTitle}
                >
                    <ReorderableList
                        items={items}
                        onChange={onListChange}
                        description={
                            InvoiceSettingsDetailsFormCopy.listDescription
                        }
                    />
                </FormGroup>
            </div>
        </>
    );
};

export default InvoiceSettingsDetailForm;
