import React from 'react';
import { BackButton, IconText } from '@premier/ui';
import DisplayableError from 'components/Common/components/DisplayableError';
// TODO: This causes Jest to fail many unit tests due to a weird import bug.
// Need to fix this if we wish to enable telemetry properly.
// import { telemetryApi } from 'api';

/***
 * Error boundary that is responsible for capturing thrown DisplayableErrors (not all Errors) and displaying their contents.
 * Captures all errors and pushes it to an endpoint that stores errors that was encountered by users.
 */
const DEFAULT_DISPLAY_MESSAGE = "An error has occurred.";
class ExceptionErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, displayMessage: DEFAULT_DISPLAY_MESSAGE };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  async componentDidCatch(error, errorInfo) {
    if (error instanceof DisplayableError && error.displayMessage)
      this.setState({ displayMessage: error.displayMessage });
    else
      this.setState({ displayMessage: DEFAULT_DISPLAY_MESSAGE })

    // await telemetryApi.StoreExternalErrorLog({ error: error.message, stackTrace: error.stack });
  }

  render() {
    if (this.state.hasError) {
      return (<>
        <BackButton />
        <IconText alert>{this.state.displayMessage}</IconText>
      </>
      );
    }

    return this.props.children;
  }
}

export default ExceptionErrorBoundary;