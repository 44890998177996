import React from 'react';
import classNames from 'classnames';
import './Divider.scss';

const Divider = ({
    className //style
}) => {
    return (
        <div className={classNames('divider', className)}></div>
    );
};

export default Divider;