import * as actions from './actionTypes';
import { userApi } from 'api';


//#region ----- GET Users (list) -----
export function getUsersRequested() {
    return {type: actions.GET_USERS_REQUESTED};
}
export function getUsersSuccess(result) {
    return {type: actions.GET_USERS_SUCCESS, result};
}
export function getUsersFailed(err) {
    return {type: actions.GET_USERS_FAILED, err};
}

export function getUsers(resultsPerPage, pageNumber, filter, sort) {
    return function(dispatch) {
        dispatch(getUsersRequested());

        return userApi.getUsers(resultsPerPage, pageNumber, filter, sort).then((result) => {
            dispatch(getUsersSuccess(result));
            return result;
        }).catch(err => {
            dispatch(getUsersFailed(err));
            throw err;
        });
    }
}

export function saveManageUsersPageState(pageState) {
    return {
        type: actions.SAVE_MANAGE_USERS_PAGE_STATE,
        pageState: pageState
    };
}

//#endregion


//#region ----- GetUserRoles -----
export function getUserRolesRequested() {
    return {type: actions.GET_USER_ROLES_REQUESTED};
}
export function getUserRolesSuccess(merchants) {
    return {type: actions.GET_USER_ROLES_SUCCESS, merchants};
}
export function getUserRolesFailed(err) {
    return {type: actions.GET_USER_ROLES_FAILED, err};
}

export function getUserRoles() {
    return function(dispatch) {
        dispatch(getUserRolesRequested());

        return userApi.getUserRoles().then(({merchants}) => {
            dispatch(getUserRolesSuccess(merchants));
            return merchants;
        }).catch(err => {
            dispatch(getUserRolesFailed(err));
            throw err;
        });
    }
}
//#endregion


//#region ----- Reset user password -----
export function resetUserPasswordRequested() {
    return {type: actions.RESET_USER_PASSWORD_REQUESTED}
}
export function resetUserPasswordSuccess() {
    return {type: actions.RESET_USER_PASSWORD_SUCCESS};
}
export function resetUserPasswordFailed(err) {
    return {type: actions.RESET_USER_PASSWORD_FAILED, err};
}

export function resetUserPassword(userId, merchantNumber) {
    return function (dispatch) {
        dispatch(resetUserPasswordRequested());

        return userApi.resetUserPassword(userId, merchantNumber)
            .then(() => {
                dispatch(resetUserPasswordSuccess());
            })
            .catch(err => {
                dispatch(resetUserPasswordFailed(err));
                throw(err);
            })
    }
}
//#endregion


//#region ----- GET User details -----
export function getUserRequested() {
    return {type: actions.GET_USER_REQUESTED};
}
export function getUserSuccess(user) {
    return {type: actions.GET_USER_SUCCESS, user};
}
export function getUserFailed(err) {
    return {type: actions.GET_USER_FAILED, err};
}

export function getUser(userId) {
    return function (dispatch) {
        dispatch(getUserRequested());

        return userApi.getUser(userId)
            .then(user => {
                dispatch(getUserSuccess(user));
                return user;
            })
            .catch(err => {
                dispatch(getUserFailed(err));
                throw(err);
            })
    }
}
//#endregion



//#region ----- ADD User details -----
export function addUserRequested() {
    return {type: actions.ADD_USER_REQUESTED};
}
export function addUserSuccess(user) {
    return {type: actions.ADD_USER_SUCCESS, user};
}
export function addUserFailed(err) {
    return {type: actions.ADD_USER_FAILED, err};
}

export function addUser(userData) {
    return function (dispatch) {
        dispatch(addUserRequested());

        return userApi.addUser(userData)
            .then(user => {
                dispatch(addUserSuccess(user));
                return user;
            })
            .catch(err => {
                dispatch(addUserFailed(err));
                throw(err);
            })
    }
}
//#endregion


//#region: ----- EDIT User details -----
export function editUserRequested() {
    return {type: actions.EDIT_USER_REQUESTED}
}
export function editUserSuccess() {
    return {type: actions.EDIT_USER_SUCCESS};
}
export function editUserFailed(err) {
    return {type: actions.EDIT_USER_FAILED, err};
}

export function editUser(userData) {
    return function (dispatch) {
        dispatch(editUserRequested());

        return userApi.editUser(userData)
            .then(() => {
                dispatch(editUserSuccess());
            })
            .catch(err => {
                dispatch(editUserFailed(err));
                throw(err);
            })
    }
}
//#endregion


//#region: ----- DELETE User details -----
export function deleteUserRequested() {
    return {type: actions.DELETE_USER_REQUESTED}
}
export function deleteUserSuccess() {
    return {type: actions.DELETE_USER_SUCCESS};
}
export function deleteUserFailed(err) {
    return {type: actions.DELETE_USER_FAILED, err};
}

export function deleteUser(userId, merchantNumber) {
    return function (dispatch) {
        dispatch(deleteUserRequested());

        return userApi.deleteUser(userId, merchantNumber)
            .then(() => {
                dispatch(deleteUserSuccess());
            })
            .catch(err => {
                dispatch(deleteUserFailed(err));
                throw(err);
            })
    }
}
//#endregion