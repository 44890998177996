import { useMemo } from 'react';
import { connect } from 'react-redux';

import { Link, PaddedContainer, PageSection } from '@premier/ui';
import SecurityUtil from '@premier/utils/security';
import { userRoles } from 'components/Routing';
import { PageHeader, FormError, ProductTooltip } from 'components/Common';
import {
    AcceptedAmountSection,
    AcceptedCardsSections,
    BillerListContainer,
    SurchargeSection,
    TokenisationSettingsSection,
    CredentialOnFileSection
} from 'components/Settings';

import { PlatformRoutesConfiguration } from 'components/Routing';

/** Payment settings for a single (or the default) biller code */
const PaymentSettingsPage = ({
    merchant,
    billers,
    biller,
    updateBiller,
    authenticatedUser, // from Redux
}) => {
    const filteredBillers = useMemo(() => billers.filter((b) => b.merchantNumber === merchant.merchantNumber), [
        billers,
    ]);

    return (
        <PageSection>
            <PageHeader title={<>{'Payment settings'}<ProductTooltip productTooltipModule={"PAYMENT_SETTINGS"} /></>} />
            <p>Please set up your payment settings for each biller code below.</p>

            <FormError apiErrors={updateBiller.errors} />

            <AcceptedCardsSections showLCRConfiguration={SecurityUtil.hasAccess([userRoles.LeastCostRouting], authenticatedUser)}/>

            { SecurityUtil.hasAccess([userRoles.dataVault], authenticatedUser) ? <CredentialOnFileSection /> : null }

            {biller && (
                <>
                    <SurchargeSection biller={biller} />

                    <AcceptedAmountSection billerCode={biller.billerCode} />

                    <PaddedContainer
                        lessMargin
                        noDivider
                        title='Set up biller codes? It’s optional'
                        button={
                            <Link button to={PlatformRoutesConfiguration.settingsRoute.manageBillers.generatePath()}>
                                Set up biller codes
                            </Link>
                        }
                    >
                        <p>
                            If you have multiple income streams, set up different payment settings using biller codes to
                            help you manage and keep track of payments.
                        </p>
                        <p>You can disable your biller codes anytime.</p>
                    </PaddedContainer>

                    <TokenisationSettingsSection billerCode={biller.billerCode} />
                </>
            )}

            <BillerListContainer
                billers={filteredBillers}
                description={
                    <>
                        <p>
                            Using biller codes makes it easy to manage and keep track of payments if you have
                            multiple income streams. You can disable a biller code at anytime.
                        </p>
                        <p>
                            Your first set payment settings are now stored against your first biller code. Select a
                            biller code to edit the payment settings for each income stream.
                        </p>
                    </>
                }
                instruction='Click to edit the payment settings for your first biller code'
                generateLink={(b) => PlatformRoutesConfiguration.settingsRoute.billerDetails.generatePath(b.billerCode)}
                allowAdd
            />
        </PageSection>
    );
};

function mapStateToProps(state) {
    const biller =
        state.accounts.users.billers && state.accounts.users.billers.length === 1 && state.accounts.users.billers[0];

    return {
        merchant: state.accounts.users.merchant,
        billers: state.accounts.users.billers,
        biller,
        updateBiller: state.settings.paymentSettings.updateBiller,
        authenticatedUser: state.accounts.users.authenticatedUser,
    };
}

export default connect(mapStateToProps)(PaymentSettingsPage);
