import React, { useState } from 'react';
import classNames from 'classnames';
import { Dropdown, FormGroup, CardContainer, DescriptionList, Link, LoadingIndicator, Button } from '..';
import {
    ResponsiveTableColumn,
    ResponsiveTableRecordPartial,
    ResponsiveTableSharedProps,
    DEFAULT_SELECTED_ITEMS,
} from './ResponsiveTableTypes';
import './CardList.scss';
import { uniqueId } from '@premier/utils/helpers';

export type CardListProps<TRecord extends ResponsiveTableRecordPartial> = ResponsiveTableSharedProps<TRecord> & {
    /** Hide sort dropdown */
    hideSortDropdown?: boolean;
    /** Hide quick filter dropdowns */
    hideFilterDropdowns?: boolean;

    /** eg. (listItems, link, index) => <YourComponent/> */
    renderCard?: any;
};

/** Consider using ResponsiveTable component instead of this directly. */
const CardList = <TRecord extends ResponsiveTableRecordPartial>({
    data,
    columns,
    sort,
    onSort,
    hideSortDropdown,
    hideFilterDropdowns,
    getRowLink,
    onRowLinkClick,
    rowLinkNewWindow,
    batchActions,
    singleSelect,
    selectedItems = DEFAULT_SELECTED_ITEMS,
    handleItemSelected,
    renderCard,
    isLoading,
    noDataText,
    highlightTotal,
}: CardListProps<TRecord>) => {
    const [filteringBy, setFilteringBy] = useState(columns[0].label);

    const [sortFieldId] = useState(uniqueId('sortfield_'));
    const [sortOrderId] = useState(uniqueId('sortorder_'));
    const [filterFieldId] = useState(uniqueId('filterfield_'));

    function renderSortDropdown() {
        let sortableColumns = columns.filter((c) => c.sortKey);

        if (onSort && sortableColumns.length) {
            let options = sortableColumns.map((c) => ({ label: c.label, value: c.sortKey! }));

            return (
                <>
                    <FormGroup className='field-name' label='Sort by' fieldId={sortFieldId}>
                        <Dropdown
                            id={sortFieldId}
                            options={options}
                            defaultValue={sort?.field}
                            onChange={(o) => {
                                o && onSort(o.value, false);
                            }}
                        />
                    </FormGroup>
                    <FormGroup fieldId={sortOrderId}>
                        <Dropdown
                            id={sortOrderId}
                            options={[
                                { label: 'Ascending', value: 0 },
                                { label: 'Descending', value: 1 },
                            ]}
                            defaultValue={sort?.descending ? 1 : 0}
                            onChange={(o) => {
                                o && onSort(sort?.field ?? options[0].value, o.value === 1);
                            }}
                        />
                    </FormGroup>
                </>
            );
        }

        return null;
    }

    function renderFilterDropdowns() {
        let filterableColumns = columns.filter((c) => c.filter);

        if (filterableColumns.length) {
            let options = filterableColumns.map((c) => ({ label: c.label, value: c.label }));

            return (
                <>
                    <FormGroup className='field-name' label='Quick search' fieldId={filterFieldId}>
                        <Dropdown
                            id={filterFieldId}
                            options={options}
                            defaultValue={options[0]}
                            onChange={(o) => {
                                o && setFilteringBy(o.value);
                            }}
                        />
                    </FormGroup>

                    <div className='filter-fields'>
                        {filterableColumns.map((c, index) => (
                            <div key={index} className={classNames({ active: c.label === filteringBy })}>
                                {c.filter}
                            </div>
                        ))}
                    </div>
                </>
            );
        }

        return null;
    }

    // Copied straight from Table.js
    function getCellValue(item: TRecord, col: ResponsiveTableColumn<TRecord>, itemIndex: number) {
        if (typeof col.getter === 'string') return item[col.getter];
        else if (typeof col.getter === 'function') return col.getter(item, itemIndex);

        throw new Error('Column "' + col.label + '" needs a getter');
    }

    function getCardComponents(data: TRecord[]) {
        if (!data.length) return <div>{noDataText}</div>;

        return data.map((item, index) => {
            return getCardComponent(item, index);
        });
    }

    function getCardComponent(item: TRecord, index: number) {
        let listItems = columns.map((col) => ({
            name: col.label,
            value: getCellValue(item, col, index),
            className: col.className,
        }));

        if (renderCard) {
            let link = getRowLink && getRowLink(item);
            return renderCard(listItems, link, index);
        } else {
            let card = <DescriptionList showBlankName items={listItems} />;
            let singleSelectable = singleSelect && !batchActions?.length && !data[index].noBatchActions;
            let isSelected = selectedItems.includes(item);

            if (getRowLink)
                card = (
                    <Link
                        to={getRowLink(item)}
                        onClick={onRowLinkClick && (() => onRowLinkClick(item))}
                        newWindow={rowLinkNewWindow}
                    >
                        {card}
                    </Link>
                );
            else if (onRowLinkClick)
                card = (
                    <div role='button' onClick={() => onRowLinkClick(item)}>
                        {card}
                    </div>
                );

            card = (
                <>
                    <div className='actions'>
                        {batchActions &&
                            batchActions.length &&
                            !data[index].noBatchActions &&
                            batchActions.map((action, aIndex) => (
                                <Button link key={aIndex} onClick={() => action.handleClick([data[index]])}>
                                    {action.label}
                                </Button>
                            ))}
                    </div>
                    {card}
                </>
            );

            return (
                <CardContainer
                    key={index}
                    selectable={singleSelectable}
                    selected={isSelected}
                    onSelect={() => handleItemSelected && handleItemSelected(item)}
                >
                    {card}
                </CardContainer>
            );
        }
    }

    return (
        <>
            <div className={classNames('card-list', { 'highlight-total': highlightTotal })}>
                {!hideSortDropdown && data.length > 0 && renderSortDropdown()}
                {!hideFilterDropdowns && renderFilterDropdowns()}
                {isLoading && <LoadingIndicator />}

                <div className='cards'>{getCardComponents(data)}</div>
            </div>
        </>
    );
};

export default CardList;
