import React, { useEffect, useState, useRef } from "react";
import _ from "lodash";
// @ts-ignore
import labels from "constants/labels";
import { Form, FormContext } from "@premier/form";
import { Tooltip, TooltipTrigger, Icon, Button, Modal } from "@premier/ui";
import { CustomerReferenceField, SearchPaymentMethodsDialog } from "components/Transactions";
import tokenApi from "api/tokenApi";
import { Token } from "models/Token";
import { Biller } from "packages/webapi-client";

import "./BillerCrnForm.scss";

type Props = {
    parentMerchantNumber?: string;
    merchantNumber?: string;
    token?: Token;
    name?: string;
    biller?: Biller;
    tokenDisplay?: boolean;
    showPaymentMethodButton?: boolean;
    customerUniqueId?: string;
    selectDataVault?: (token: Token[]) => void;
}

type ContextCrnList = {
    crn2: string;
    crn3: string;
}

/** CRN fields, up to three depending on biller configuration */
const BillerCrnForm = ({
    parentMerchantNumber, merchantNumber, token, name, biller, customerUniqueId, selectDataVault, //prop
    tokenDisplay, showPaymentMethodButton //logic renders
}: Props) => {
    const context = React.useContext(FormContext);
    const [showSearchPaymentMethods, setShowSearchPaymentMethods] = useState(false);
    const [paymentMethodsList, setPaymentMethodsList] = useState<Token[]>([]);
    const [paymentMethodsResultCount, setPaymentMethodsResultCount] = useState<number>();
    const [showNoPaymentMethods, setShowNoPaymentMethods] = useState(false);
    const [contextCrnList, setContextCrnList] = useState<ContextCrnList>();

    function usePrevious(value?: Biller) {
        const ref = useRef<Biller>();
        useEffect(() => {
            ref.current = value;
        }, [value]);
        return ref.current;
    }
    const prevBiller = usePrevious(biller);

    useEffect(() => {
        // When changing biller to one with < 3 crns, wipe those that are no longer used/visible.
        // biller onChange event doesn't fire when changing merchant so jammed this in here.

        // Avoid clearErrors when Customer Payment Modal get response with error messages
        if (prevBiller !== undefined) {
            context.clearErrors();
        }
        if (biller && !biller.acceptedCrn2) {
            context.setValue("billerCrnList.crn2", "");
            _.set(context.validations.billerCrnList, "crn2", null);
        }
        if (biller && !biller.acceptedCrn3) {
            context.setValue("billerCrnList.crn3", "");
            _.set(context.validations.billerCrnList, "crn3", null);
        }

        // when changing biller to one with crn2/crn3, add context for crn2 and crn3 back
        if (biller && biller.acceptedCrn2 && contextCrnList?.crn2) {
            context.setValue("billerCrnList.crn2", contextCrnList.crn2);
        }
        if (biller && biller.acceptedCrn3 && contextCrnList?.crn3) {
            context.setValue("billerCrnList.crn3", contextCrnList.crn3);
        }

    }, [biller]);

    useEffect(() => {
        setContextCrnList({
            crn2: context.getValue("billerCrnList.crn2"),
            crn3: context.getValue("billerCrnList.crn3")
        });
    }, []);

    function showReferenceTooltip() {
        // 1. Do not show for Token page.
        // 2. Show if no biller set
        // 3. Show if biller set and all visible labels are default.
        if (tokenDisplay)
            return false;

        if (biller?.billerCode === "")
            return true;

        if (biller?.acceptedCrn1?.crnName !== labels.reference1)
            return false;
        if (biller?.acceptedCrn2 && biller?.acceptedCrn2.crnName !== labels.reference2)
            return false;
        if (biller?.acceptedCrn3 && biller?.acceptedCrn3.crnName !== labels.reference3)
            return false;

        return true;
    }

    function searchPaymentMethodsHandler(isShow: boolean) {
        if (isShow) {
            if (!context.getValue("billerCrnList.crn1")) {
                const label = biller?.acceptedCrn1?.crnName ?? (tokenDisplay ? labels.tokenReference1 : labels.reference1);
                context.setError("billerCrnList.crn1", `${label} is required`);
            } else {
                const params = {
                    crn1: context.getValue("billerCrnList.crn1"),
                    // Attach child merchant number if the selected merchant number is different from the parent merchant number
                    childMerchantNumber: parentMerchantNumber !== merchantNumber ? merchantNumber: null,
                    // Restricts search to within specific customer's tokens, if required
                    customerUniqueId: customerUniqueId
                };

                tokenApi.getTokens(10, 0, params, {}).then(response => {
                    if (response.tokens.length === 0) {
                        setShowNoPaymentMethods(true);
                    } else if (response.tokens.length === 1) {
                        // if there is only one result matched, don't show searchPaymentMethod modal
                        selectDataVault && selectDataVault(response.tokens);
                    }
                    else {
                        setShowSearchPaymentMethods(true);
                        setPaymentMethodsList(response.tokens);
                        setPaymentMethodsResultCount(response.resultCount);
                    }
                })
                    .catch(err => {
                        throw err;
                    });
            }
        }
        else {
            setShowSearchPaymentMethods(false);
        }
    }


    function renderReferenceTooltip() {
        if (showReferenceTooltip())
            return (
                <TooltipTrigger tipId="tip-reference">
                    <Icon question />
                </TooltipTrigger>
            );
        else
            return null;
    }


    if (!biller)
        return null;

    return (
        <>
            <Form name={name || "billerCrnList"} initialValues={{ ...token }}>
                {biller.acceptedCrn1 && (
                    <CustomerReferenceField
                        referenceField={biller.acceptedCrn1}
                        name="crn1"
                        defaultLabel={tokenDisplay ? labels.tokenReference1 : labels.reference1}
                        help={renderReferenceTooltip()}
                    />
                )}
                {(searchPaymentMethodsHandler !== undefined && showPaymentMethodButton) && <Button className="search-payment-method-button" disabled={!context.getValue("billerCrnList.crn1")} subtle onClick={() => { searchPaymentMethodsHandler(true); }}>Search payment methods</Button>}

                {biller.acceptedCrn2 && (
                    <CustomerReferenceField
                        referenceField={biller.acceptedCrn2}
                        name="crn2"
                        defaultLabel={tokenDisplay ? labels.tokenReference2 : labels.reference2}
                    />
                )}
                {biller.acceptedCrn3 && (
                    <CustomerReferenceField
                        referenceField={biller.acceptedCrn3}
                        name="crn3"
                        defaultLabel={tokenDisplay ? labels.tokenReference3 : labels.reference3}
                    />
                )}

            </Form>

            <Tooltip id="tip-reference">
                <p>Reference fields can be used to identify the customer who makes the payment.</p>
                <p>
                    This helps to make the reconciliation process easier. The labels of the reference fields can be
                    configured in Settings, where you can select labels such as Customer Number, Account Number, Invoice
                    Number and more.
                </p>
            </Tooltip>
            <SearchPaymentMethodsDialog
                show={showSearchPaymentMethods}
                onCancel={()=>setShowSearchPaymentMethods(false)}
                selectDataVault={selectDataVault}
                paymentMethodsList={paymentMethodsList}
                paymentMethodsResultCount={paymentMethodsResultCount} />

            <Modal show={showNoPaymentMethods} onHide={()=>setShowNoPaymentMethods(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <Icon alert />
                        <h2>No payment methods to display.</h2>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default BillerCrnForm;
