import { useState, useEffect, ReactNode, useCallback } from 'react';
import { NavItem } from 'models';
import Menu from './Menu';
import OutsideAlerter from './OutsideAlerter';


type Props = {
    items: NavItem[],
    toggleClosed: ReactNode,
    toggleOpen: ReactNode,
    right?: boolean,
    className?: string,
};

const NavMenu = ({
    items, toggleClosed, toggleOpen, right, className,
}: Props) => {

    const [isOpen, setIsOpen] = useState(false);
    const [isAnimating, setIsAnimating] = useState(false);

    useEffect(() => {
        if (!isAnimating)
            return;

        const timeout = setTimeout(() => {
            setIsAnimating(false);
        }, 100);

        return () => {
            clearTimeout(timeout);
        }
    }, [isAnimating]);

    function handleToggleButtonClicked() {
        if (!isAnimating) {
            setIsAnimating(true);
            setIsOpen(!isOpen);
        }
    }

    function handleItemClicked() {
        if (!isAnimating) {
            setIsAnimating(true);
            setIsOpen(false);
        }
    }

    const handleBlur = useCallback(function () {
        if (!isAnimating && isOpen) {
            setIsAnimating(true);
            setIsOpen(false);
        }
    }, [isAnimating, isOpen]);

    return (
        <div className={className}>
            <div onClick={handleToggleButtonClicked}>
                {isOpen ? toggleOpen : toggleClosed}
            </div>

            <OutsideAlerter onBlur={handleBlur}>
                <Menu items={items} right={right} isOpen={isOpen} onLinkClick={handleItemClicked} />
            </OutsideAlerter>
        </div>
    );
}

export default NavMenu;