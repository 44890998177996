import React from 'react';
import PropTypes from 'prop-types';

import dateUtil from '@premier/utils/date';
import currencyUtil from '@premier/utils/currency';

import { ResponsiveTable } from '@premier/ui';

import { PlatformRoutesConfiguration } from 'components/Routing';

import labels from 'constants/labels';

/** ResponsiveTable table of Subscriptions with columns for the customers page, including status, description, amount, frequency and next payment date  */
const CustomerSubscriptionsList = ({
    subscriptions, //data
    isLoading, // logic renders
    sort, onSort //functions
}) => {

    // The table columns (or list items)
    const columns = [{
        label: labels.status,
        getter: s => s.subscriptionStatus.description,
        sortKey: 'subscriptionStatus.description',
    }, {
        label: labels.subscriptionReference,
        getter: 'scheduleReference',
        sortKey: 'scheduleReference',
    }, {
        label: labels.recurringAmount,
        sortKey: 'recurringAmount',
        getter: s => currencyUtil.convertToDisplayString(s.recurringAmount),
    }, {
        label: labels.frequency,
        getter: s => s.frequency.description,
    }, {
        label: labels.nextPayment,
        sortKey: 'nextPaymentDate',
        getter: (item) => dateUtil.convertToDateTimeString(item.nextPaymentDate)
    }];

    return (
        <ResponsiveTable
            data={subscriptions}
            isLoading={isLoading}
            columns={columns}
            sort={sort}
            onSort={onSort}
            noDataText='No subscriptions to display.'
            getRowLink={c => PlatformRoutesConfiguration.transactionRoute.scheduleDetails.generatePath(c.recurringScheduleId)}
        />
    );
};


CustomerSubscriptionsList.propTypes = {
    /** The current sort order */
    sort: PropTypes.shape({
        field: PropTypes.string,
        descending: PropTypes.bool,
    }),
    /** to handle sort field/order changes */
    onSort: PropTypes.func,

    subscriptions: PropTypes.arrayOf(PropTypes.shape({
        subscriptionStatus: PropTypes.shape({
            description: PropTypes.string
        }),
        scheduleReference: PropTypes.string,
        recurringAmount: PropTypes.number,
        frequency: PropTypes.shape({
            description: PropTypes.string
        }),
        nextPaymentDate: PropTypes.string
    })).isRequired,
    isLoading: PropTypes.bool,
};

export default CustomerSubscriptionsList;