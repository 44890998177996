import { config } from './util';
import {
    LCRConfigurationApiFactory,
    LCRConfigurationModel
} from '@premier/webapi-client';

const lcrConfigurationApi = LCRConfigurationApiFactory(config);

class LCRConfigurationApi {
    static async updateLCRConfiguration(lcrModel : LCRConfigurationModel) {
        return await lcrConfigurationApi.lCRConfigurationUpdateLCRConfiguration(lcrModel);
    }

    static async getLCRConfiguration() {
        return await lcrConfigurationApi.lCRConfigurationGetLCRConfiguration();
    }
}

export default LCRConfigurationApi;
