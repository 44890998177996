
/**
 * Splits the parameter by dashes -, and maps to values
 * @param {string} parameter
 * @param  {...function} mappers
 */
export function splitAndMap(parameter: string, ...mappers: ((input?: string) => any)[]) {
    var params = parameter.split('-');

    return mappers.map((mapper, idx) => {
        if (params && params[idx])
            return mapper(params[idx]);
        return mapper(undefined);
    });
}

/** Converts input to an valid integer
 * @param {string} val the url parameter, e.g. 123 from the url .../customer/123
 * If it can be converted to an integer then returns the integer value.
 * If it cannot then returns undefined
*/
export function asInteger(val: string | number | undefined) {
    var int = Number(val);

    if(!Number.isInteger(int))
        return;

    return int;
}
