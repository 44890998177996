import { useState, useEffect } from 'react';
import { Dispatch, bindActionCreators } from "redux";
import { connect, } from 'react-redux';
import queryString from "query-string";
import { ButtonContainer, LoadingIndicator, IconText,Button } from '@premier/ui';
import { Form, SubmitButton, InputField, CheckboxField } from '@premier/form';
import { FormHeader, HelpText, FormError, FloatingContainer } from 'components/Common';
// @ts-ignore
import labels from "constants/labels";
import { accountApi } from "api";
import { useApiCall, APICallRequestState } from "components/Common";
import { PlatformRoutesConfiguration } from 'components/Routing';
import * as accountActions from 'components/Account/_actions/accountActions';
import { RootState } from "store/store";
import { Navigate, useLocation } from "react-router-dom";
// @ts-ignore
import { getPlatform } from "platforms/current/util.ts";
import { AccountModel } from 'packages/webapi-client/api';
import { Merchant } from 'models/Merchant';

import './MultiFactorAuthenticationPage.scss';

type Props = {
    requiredUserAction: string;
    authenticatedUser: AccountModel,
    merchant: Merchant
    actions: any;
}

type FormFields = {
    code: string;
    remember: boolean;
};

export const MultiFactorAuthenticationPage = ({
    actions, //API actions
    requiredUserAction,
    authenticatedUser,
    merchant
}: Props) => {
    const location = useLocation();

    const [submitErrors, setSubmitErrors] = useState<string[]>([]);
    const queryParams = queryString.parse(location.search);
    const lastLocationStringFromArray = Array.isArray(queryParams.lastLocation) && queryParams.lastLocation.length > 0 ? queryParams.lastLocation[0] : "";
    const lastLocationString = typeof queryParams.lastLocation === "string" ? queryParams.lastLocation : lastLocationStringFromArray;
    const lastLocation = queryParams.lastLocation ? lastLocationString : "";
    const last = queryParams && decodeURI(lastLocation);
    const [navigateTo, setNavigateTo] = useState<string | undefined>("");
    const [showResentSuccessfully, setShowResentSuccessfully] = useState(false);

    const [MFAConfiguration, MFAConfigurationStatus] = useApiCall(() => {
        if (merchant.merchantNumber)
            return accountApi.getMFAConfiguration(merchant.merchantNumber);
    }, []);

    useEffect(() => {
        if (requiredUserAction !== 'MULTI_FACTOR_AUTHENTICATION') {
            if (last && last !== "undefined")
                setNavigateTo(last);
            else {
                setNavigateTo(getPlatform() !== "stationery_shop" ? PlatformRoutesConfiguration.accountRoute.landingPage.generatePath() :
                    PlatformRoutesConfiguration.stationeryRoute?.homePage.generatePath()!);
            }
        }
    }, [requiredUserAction, last]);

    const handleSubmit = async (formValues: FormFields) => {
        try {
            setSubmitErrors([]);
            const response = await accountApi.validateMfaCode({
                code: formValues.code,
                remember: formValues.remember ?? false,
                userName: authenticatedUser.username,
                merchantNumber: merchant.merchantNumber
            });

            if (response?.status === 200 && !response?.data?.errors?.length) {
                if (!response?.data?.data?.codeIsValid) {
                    setSubmitErrors(['Code entered is incorrect']);
                } else {
                    await actions.getSessionLogin();
                }
            }
            else {
                throw response;
            }
        } catch (response: any) {
            if (response?.data?.errors?.length) {
                setSubmitErrors(response.data.errors.map((r: any) => r.message));
            } else {
                setSubmitErrors([labels.unknownErrorMessage]);
            }
            return false;
        }
        return true;
    };

    const resendCode = async () => {
        try {
            if (authenticatedUser.username && merchant.merchantNumber) {
                const response = await accountApi.resendMfaCode(authenticatedUser.username, merchant.merchantNumber);

                if (response?.status === 200 && !response?.data?.errors?.length) {
                    showSentSuccessfullyMessage();
                    setSubmitErrors([]);
                }
                else {
                    throw response;
                }
            }
        } catch (response: any) {
            if (response?.data?.errors?.length) {
                setSubmitErrors(response.data.errors.map((r: any) => r.message));
            } else {
                setSubmitErrors([labels.unknownErrorMessage]);
            }
        }
    };

    const showSentSuccessfullyMessage=()=> {
        setShowResentSuccessfully(true);
        setTimeout(() => {
            setShowResentSuccessfully(false);
        }, 3000);
    }

    const BuildRememberLabelText = (timeoutMins: number) => {
        const hours = Math.floor(timeoutMins / 60);
        const minutes = timeoutMins % 60;
        let result = "Don't ask again for ";

        if (hours > 0) {
            result += `${hours} ${hours === 1 ? "hour" : "hours"}`;
        }

        if (minutes > 0) {
            if (hours > 0) {
                result += " ";
            }
            result += `${minutes} ${minutes === 1 ? "minute" : "minutes"}`;
        }

        return result;
    };

    if (navigateTo) {
        return <Navigate to={navigateTo} />;
    }

    return (
        <>
            {MFAConfigurationStatus === APICallRequestState.SUCCESSFUL ?
                <FloatingContainer>
                    <FormHeader title='Enter the one time code' />
                    <Form inlineLabelsUpMd onSubmit={handleSubmit} >
                        <InputField type='text' name='code' label={labels.MFACode} />
                        <div className='inline-content'>
                            {MFAConfiguration?.isEnabled && MFAConfiguration?.timeoutMins !== undefined && MFAConfiguration?.timeoutMins > 0 &&
                                <CheckboxField name="remember">{BuildRememberLabelText(MFAConfiguration?.timeoutMins)}</CheckboxField>
                            }
                            <div className='resend-code'>
                                <Button link onClick={resendCode}>Resend code</Button>
                            </div>
                        </div>
                        <div className="message-container">
                            <FormError errors={submitErrors ?? []} />
                            {showResentSuccessfully &&
                                <IconText tick>Code has been resent</IconText>}
                        </div>
                        <ButtonContainer>
                            <SubmitButton >Verify</SubmitButton>
                        </ButtonContainer>
                    </Form>
                    <HelpText
                        title='Not yet registered for BPOINT?'
                        description='Contact our eCommerce specialists on 1800 230 177'
                    />
                </FloatingContainer>
                : <LoadingIndicator />}
        </>
    );
};

function mapStateToProps(state: RootState) {

    return {
        requiredUserAction: state.accounts.users.requiredUserAction,
        authenticatedUser: state.accounts.users.authenticatedUser,
        merchant: state.accounts.users.merchant
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        actions: bindActionCreators(accountActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MultiFactorAuthenticationPage);