
import { useMemo } from "react"
import { RegisterOptions, FieldAccess, FormError, ValidateSettings } from './FormTypes';

export function useFieldBinding<T>(
    setFieldValue: <K extends keyof T>(key: K, mutate: (value: T[K]) => T[K]) => void,
    validateField: <K extends keyof T>(key: K, validate?: ValidateSettings<T[K]>) => void,
    registerField: <K extends keyof T>(key: K, options: RegisterOptions<T[K]>) => () => void,
    values: T,
    error: FormError<T>
) {
    // we want to cache these functions
    // as they should never change
    // and changing each render will prevent optimisations in children
    const cachedFieldStatics = useMemo(() => {
        const cache: {[K in keyof T]?: Omit<FieldAccess<T[K]>, 'value' | 'fieldError'>} = {};
        return <K extends keyof T>(key: K) => {
            return cache[key] ?? (cache[key] = {
                setValue: (mutate: (value: T[K]) => T[K]) => setFieldValue(key, mutate),
                validate: (settings?: ValidateSettings<T[K]>) => validateField(key, settings),
                register: (options: RegisterOptions<T[K]>) => registerField(key, options)
            });
        };
    }, [setFieldValue, validateField, registerField])

    return <K extends keyof T>(key: K): FieldAccess<T[K]> => ({
        ...cachedFieldStatics(key),
        value: values[key],
        fieldError: error?.details?.[key]
    });
}