import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getFraudSettings, updateMessagingSettings, updateBillerMessagingSettings } from 'components/Settings/_actions/fraudActions';
import { FraudBasicSettingsModel, BillerFraudBasicSettingsModel, FraudBasicExclusionsModel } from 'packages/webapi-client';
import _ from 'lodash';
import SecurityUtil from '@premier/utils/security';
import { userRoles } from 'components/Routing';
import { fraudApi } from 'api';

const settingsSelector = (state: any) => state.settings.fraud.basicSettings;
const updateSettingsSelector = (state: any) => state.settings.fraud.updateBasicSettings;
const authenticatedUserSelector = (state: any) => state.accounts.users.authenticatedUser;

export const useFraudSettings = () => {
    const { isInitial, hasLoaded, hasFailed, data: settings } = useSelector(settingsSelector);
    const { isLoading: isUpdating, hasSucceeded: hasUpdated, errors } = useSelector(updateSettingsSelector);
    const authenticatedUser = useSelector(authenticatedUserSelector);
    const dispatch = useDispatch();

    useEffect(() => {
        if (isInitial && SecurityUtil.hasAccess([userRoles.fraudBasic], authenticatedUser)) {
            dispatch(getFraudSettings());
        }
    }, [dispatch, isInitial, authenticatedUser]);

    const updateBasicSettings = async (values: FraudBasicSettingsModel) => {

        if (SecurityUtil.hasAccess([userRoles.fraudBasic], authenticatedUser)) {
            await dispatch(updateMessagingSettings(_.cloneDeep(values)));
            dispatch(getFraudSettings());
        }
    }

    const updateBillerBasicSettings = async (values: BillerFraudBasicSettingsModel) => {
        if (SecurityUtil.hasAccess([userRoles.fraudBasic], authenticatedUser)) {
            await dispatch(updateBillerMessagingSettings(_.cloneDeep(values)));
        }
    }

    const updateBasicExclusions = async (values: FraudBasicExclusionsModel) => {
        if (SecurityUtil.hasAccess([userRoles.fraudBasic], authenticatedUser)) {
            // @ts-ignore
            await fraudApi.fraudUpdateFraudBasicExclusions(values);
        }
    }

    return {
        hasLoaded,
        hasFailed,
        settings,
        updateBasicSettings,
        updateBillerBasicSettings,
        updateBasicExclusions,
        isUpdating,
        hasUpdated,
        errors,
    }
}