import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Navigate } from 'react-router-dom';
import _ from 'lodash';

import currencyUtil from '@premier/utils/currency';
import dateUtil from '@premier/utils/date';
import fileUtil from '@premier/utils/file';

import { PageSection, Button, CardContainer, DescriptionList, Icon, LoadingIndicator, Tooltip } from '@premier/ui';
import { FormError, PageHeader } from 'components/Common';
import { DownloadBatchDialog, ProcessBatchDialogs, BatchJobStatus, BatchJobWarningDeleteDialog, BatchJobWarningProcessDialog } from 'components/Batch'

import { PlatformRoutesConfiguration } from 'components/Routing';
import * as batchActions from 'components/Batch/_actions/batchActions';
import { BatchJobStatus as Status } from 'api/transactionsApi';

import './BatchJob.scss';

const BatchJob = ({
    actions, //API actions
    isLoading, //logic render
    errors, //form
    file //state values
}) => {

    const isInLibrary = true; // TODO (Drop 3): Get this from API

    const [redirectBack, setRedirectBack] = useState(false);
    const [showDownloadDialog, setShowDownloadDialog] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const [showContinueDialog, setShowContinueDialog] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showProcessDialogs, setShowProcessDialogs] = useState(false);

    useEffect(() => {
        if (!file.filename) {
            setRedirectBack(true);  // TODO (Drop 3) Remove this and reenable the below after API is done
            // actions.getBatchFileDetails(file.fileId).catch(err => {
            //     setRedirectBack(true);
            // });
        }
    }, [file.fileId]);


    //#region ----- Download file -----
    function handleDownload() {
        setShowDownloadDialog(true);
        setIsDownloading(false);
    }

    async function startDownload(reportOptions) {
        setIsDownloading(true);

        downloadFileResult(
            file.fileId,
            reportOptions.downloadFormat,
            Number(reportOptions.returnAll) === 1,
            Number(reportOptions.zipContents) === 1
        ).then(() => {
            // Close dialog and reenable buttons after all selected files have been downloaded
            setShowDownloadDialog(false);
            setIsDownloading(false);
        }).catch(() => {
            setShowDownloadDialog(false);
            setIsDownloading(false);
        });
    }

    async function downloadFileResult(fileId, downloadFormat, returnAll, zipContents) {
        await actions.downloadBatchJobResult(fileId, downloadFormat, returnAll, zipContents)
            .then(result => {
                var data = zipContents ? fileUtil.decodeBase64Binary(result.fileData) : result.fileData;
                var type = zipContents ? 'application/zip' : 'text/csv';
                fileUtil.download(data, result.fileName, type);
        });
    }
    //#endregion


    function handleReschedule() {
        setShowProcessDialogs(true);
    }

    function handleDelete() {
        setShowDeleteDialog(true);
    }

    function handleDeleteConfirmed() {
        setShowDeleteDialog(false);
        actions.deleteBatchJob(file.fileId, file.status.key).then(() => {
            setRedirectBack(true);
        });
    }

    function handleContinue() {
        setShowContinueDialog(true);
    }

    function handleStop() {
        actions.stopRecurringManager(file.fileId).then(() => {
            setRedirectBack(true);
        });
    }

    function handleContinueConfirmed() {
        setShowContinueDialog(false);
        actions.processDuplicateBatchJob(file.fileId).then(() => {
            setRedirectBack(true);
        });
    }

    function handleRetryDeclined() {
        // TODO Drop 3 (API not done yet)
    }

    function getSubtitle() {
        if (isLoading || !file.status)
            return null;

        return (<>
            Time submitted: {dateUtil.convertToDateTimeString(file.submittedDateTime)}
            {file.status.key === Status.COMPLETE && (<>
                <br />Time processed: {dateUtil.convertToDateTimeString(file.processDateTime)}
            </>)}
            {file.status.key === Status.SCHEDULED && (<>
                <br />Time scheduled: {dateUtil.convertToDateTimeString(file.scheduledDateTime)}
            </>)}
        </>);
    }


    if (redirectBack)
        return <Navigate to={PlatformRoutesConfiguration.transactionRoute.batchPayments.generatePath()} />;

    return (
        <div className='batch-job-page'>
            <PageSection noDivider>
                <PageHeader
                    backButton={{
                        to: PlatformRoutesConfiguration.transactionRoute.batchPayments.generatePath(),
                        label: 'Back to batch payments'
                    }}
                    title={file.filename}
                    subtitle={getSubtitle()}
                >
                    {!isLoading && file.status && (<>
                        {file.status.key === Status.COMPLETE && (
                            <Button primary onClick={handleDownload}>Download report</Button>
                        )}

                        {file.status.key === Status.IN_PROGRESS && file.isDeclineMgr && file.isFirstAttemptCompleted && (<>
                            <Button primary onClick={handleDownload}>Download partial file</Button>
                            <Button onClick={handleStop}>Stop retrying</Button>
                        </>)}

                        {file.status.key === Status.WARNING && (<>
                            <Button onClick={handleDelete}>Delete batch file</Button> {/* Fully implemented but API not working yet, for Drop 3 */}
                            <Button onClick={handleContinue}>Continue processing</Button>
                        </>)}

                        {file.status.key === Status.SCHEDULED && (
                            <Button primary onClick={handleReschedule}>Change schedule</Button>
                        )}
                    </>)}
                </PageHeader>
            </PageSection>

            {isLoading && (
                <PageSection>
                    <CardContainer header="Loading batch file details">
                        <LoadingIndicator />
                    </CardContainer>
                </PageSection>
            )}

            {!isLoading && file.status && (<>
                <PageSection noDivider>
                    <FormError apiErrors={errors} />

                    <CardContainer header='Overview'>
                        <DescriptionList items={[
                            { name: 'Status', value: <BatchJobStatus file={file} verbose /> },
                            { name: 'Uploaded records', value: currencyUtil.formatAmount(_.get(file, 'uploadedRecordCount', 0), 0) },
                            { name: 'Approved records', value: currencyUtil.formatAmount(_.get(file, 'approvedRecordCount', 0), 0) },
                            { name: 'Uploaded value', value: currencyUtil.convertToDisplayString(_.get(file, 'uploadedValue', 0)) },
                            { name: 'Approved value', value: currencyUtil.convertToDisplayString(_.get(file, 'approvedValue', 0)) },
                        ]} />
                    </CardContainer>
                </PageSection>

                <PageSection noDivider>
                    {!isInLibrary && (
                        <Button subtle>Add to Batch library <Icon question data-tip data-for='tip-add-to-library' /></Button>
                    )}

                    {file.status.key === Status.SCHEDULED && (
                        <Button subtle onClick={handleDelete}>Delete this batch file</Button>
                    )}

                    {false && _.get(file, 'status.key') === Status.COMPLETE &&
                        _.get(file, 'approvedRecordCount', 0) < _.get(file, 'uploadedRecordCount', 0) && (
                            <Button subtle onClick={handleRetryDeclined}>Retry processing declined records</Button>
                        )} {/* API doesn't exist yet, for Drop 3 */}
                </PageSection>
            </>)}



            <Tooltip id='tip-add-to-library'>
                <h4>If you wish to use this batch file again, you can add it to Batch library</h4>
                <p>
                    Batch library helps you manage the batch files which you wish to process again in the future.
                    You can also edit batch files in Batch library.
                    </p>
            </Tooltip>

            <DownloadBatchDialog show={showDownloadDialog}
                isDownloading={isDownloading}
                onConfirm={startDownload}
                onCancel={() => { setShowDownloadDialog(false) }}
            />

            <BatchJobWarningDeleteDialog show={showDeleteDialog}
                handleDeleteConfirmed={handleDeleteConfirmed}
                onClose={() => {
                    setShowDeleteDialog(false)
                }}
            />

            <BatchJobWarningProcessDialog show={showContinueDialog}
                handleContinueConfirmed={handleContinueConfirmed}
                onClose={() => {
                    setShowContinueDialog(false)
                }}
            />

            <ProcessBatchDialogs
                show={showProcessDialogs}
                files={[file]}
                onClose={() => setShowProcessDialogs(false)}
            />
        </div>
    );
};

function mapStateToProps(state, ownProps) {
    var fileId = ownProps.match.params.id;

    var file = { fileId };

    // This is the correct one (API not done yet - for Drop 3)
    // if(_.get(state.transactions.batch, 'batchFileResult.fileDetails') &&
    //    state.transactions.batch.batchFileResult.fileDetails.fileId === +fileId
    //   ) {
    //     file = state.transactions.batch.batchFileResult.fileDetails;
    // }
    // If we don't have the above yet, try getting the details from the previous page
    if (_.get(state.transactions.batch, 'batchFilesResult.items.length') &&
        _.some(state.transactions.batch.batchFilesResult.items, { fileId: +fileId })
    ) {
        file = _.find(state.transactions.batch.batchFilesResult.items, { fileId: +fileId });
    }

    return {
        file: file,
        isLoading: state.transactions.batch.isLoading,
        errors: state.transactions.batch.errors,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(batchActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(BatchJob);