import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import dateUtil from '@premier/utils/date';
import countryUtil from '@premier/utils/country';
import * as billpayUtil from '@premier/utils/billpay';

import { PaddedContainer, DescriptionList, Icon, Link } from '@premier/ui';
import labels from 'constants/labels';
import { PlatformRoutesConfiguration } from 'components/Routing';

const CustomerDetails = ({
    customer, //data
    hideEditButton, showIdentifiers
}) => {

    function getDetails(){
        var deets = [];
        if(showIdentifiers) {
            deets.push(
                {name:"Name", value: billpayUtil.getCustomerFullName(customer)},
            );
        }

        deets.push(
            {name:"Email", value: customer.emailAddress},
            {name:"Phone", value: customer.phoneNumbers && customer.phoneNumbers.length > 0 ? billpayUtil.formatPhoneObjectToApiString(customer.phoneNumbers[0].phoneNumber) : null},
            {name:"Address", value: countryUtil.formatAddress(_.get(customer, 'addresses[0]'))},
            {name:"Date of birth", value: dateUtil.convertToDateString(customer.dob)},
        );
        if(showIdentifiers) {
            deets.push(
                {name: labels.customerCode, value: <Link to={PlatformRoutesConfiguration.customerRoute.viewCustomer.generatePath(customer.customerId)}>{customer.uniqueId}</Link>}
            );
        }
        deets.push(
            {name:labels.customerId1, value: customer.crn1},
            {name:labels.customerId2, value: customer.crn2},
            {name:labels.customerId3, value: customer.crn3},
            {name:"Note", value: customer.note },
        );
        return deets;
    }

    return (
        <PaddedContainer
            className="customer-details"
            icon={<Icon person />}
            title='Personal details'
            button={!hideEditButton && <Link button to={PlatformRoutesConfiguration.customerRoute.editCustomer.generatePath(customer.customerId)}>Edit personal details</Link>}
        >
            <DescriptionList twoColumn items={getDetails()} />
        </PaddedContainer>
    );
}


CustomerDetails.propTypes = {
    /** The Customer to edit. Leave blank for new Customer. */
    customer: PropTypes.shape({
        uniqueId: PropTypes.string,
        crn1: PropTypes.string,
        crn2: PropTypes.string,
        crn3: PropTypes.string,
        emailAddress: PropTypes.string,
        title: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        dob: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
        note: PropTypes.string,
        addresses: PropTypes.arrayOf(PropTypes.shape({
            typeId: PropTypes.number,
            addressLine1: PropTypes.string,
            addressLine2: PropTypes.string,
            addressLine3: PropTypes.string,
            suburb: PropTypes.string,
            stateId: PropTypes.number,
            state: PropTypes.string,
            postcode: PropTypes.string,
            countryCode: PropTypes.number,
        })),
        phoneNumbers: PropTypes.arrayOf(PropTypes.shape({
            typeId: PropTypes.number,
            phoneNumber: PropTypes.shape({
                iddCode: PropTypes.string,
                phoneNumber: PropTypes.string,
            }),
        })),
    }),

    /** Control visibility of the Edit details button, plus Name and Customer code fields */
    hideEditButton: PropTypes.bool,
    showIdentifiers: PropTypes.bool,

};

export default CustomerDetails;