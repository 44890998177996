import { userRoles } from 'components/Routing';

import { ManageTokensPage, ViewTokenPage, NewTokenPage, EditTokenPage, LinkTokenCustomerPage, NewCustomerPage, ManageCustomersPage, EditCustomerPage,
    ViewCustomerPage, CustomerTransactionsPage, LinkCustomerTokensPage, ViewCustomerSubscriptionsPage, NewInvoicePage, ManageCustomerInvoicesPage } from 'components/DataVault';
import { NewEditSchedulePage } from 'components/Transactions';
import { RouteConfigurationMap } from 'components/Routing';

// This is a legacy route configuration was only intended to be used to get the root path i.e. /data-vault
export const dataVaultRoute : RouteConfigurationMap = {
    root: {
        path: '/data-vault',
        generatePath: () => '/data-vault',
        roles: [userRoles.dataVault],

    },
};

export const customerRoute : RouteConfigurationMap = {
    root: {
        path: '/data-vault/customers',
        generatePath: () => '/data-vault/customers',
        element: <ManageCustomersPage />,
        title: 'Customers',
        roles: [userRoles.dataVault]
    },
    manageCustomers: {
        path: '/data-vault/customers',
        element: <ManageCustomersPage />,
        title: 'Customers',
        generatePath: () => '/data-vault/customers',
        roles: [userRoles.dataVault]
    },
    newCustomer: {
        path: '/data-vault/customers/new-customer',
        element: <NewCustomerPage />,
        generatePath: () => '/data-vault/customers/new-customer',
        title: 'New customer',
        roles: [userRoles.dataVault]
    },
    editCustomer: {
        path: '/data-vault/customers/:id/edit',
        element: <EditCustomerPage />,
        title: 'Edit customer details',
        generatePath: (id: number) => `/data-vault/customers/${id}/edit`,
        roles: [userRoles.dataVault]
    },
    viewCustomer: {
        path: '/data-vault/customers/:id',
        element: <ViewCustomerPage />,
        title: 'Customer details',
        generatePath: (id: number) => `/data-vault/customers/${id}`,
        roles: [userRoles.dataVault]
    },
    customerTransactions: {
        path: '/data-vault/customers/:id/transactions',
        element: <CustomerTransactionsPage />,
        title: 'Customer transactions',
        generatePath: (id: number) => `/data-vault/customers/${id}/transactions`,
        roles: [userRoles.dataVault]
    },
    linkCustomerToTokens: {
        path: '/data-vault/customers/:id/link-tokens',
        element: <LinkCustomerTokensPage />,
        title: 'Link payment methods to customer',
        generatePath: (id: number) => `/data-vault/customers/${id}/link-tokens`,
        roles: [userRoles.dataVault]
    },
    newCustomerSchedule: {
        path: '/data-vault/customers/:customerId/subscriptions/new',
        element: <NewEditSchedulePage />,
        title: 'New subscription',
        generatePath: (customerId: number) => `/data-vault/customers/${customerId}/subscriptions/new`,
        roles: [userRoles.recurringSchedule]
    },
    editCustomerSchedule: {
        path: '/data-vault/customers/:customerId/subscriptions/:id/edit',
        element: <NewEditSchedulePage />,
        title: 'Edit subscription',
        generatePath: (customerId: number, id: number) => `/data-vault/customers/${customerId}/subscriptions/${id}/edit`,
        roles: [userRoles.recurringSchedule]
    },
    viewCustomerSubscriptions: {
        path: '/data-vault/customers/:id/subscriptions',
        element: <ViewCustomerSubscriptionsPage />,
        title: 'Customer subscriptions',
        generatePath: (id: number) => `/data-vault/customers/${id}/subscriptions`,
        roles: [
            [userRoles.dataVault, userRoles.recurringSchedule] // dataVault AND recurringSchedule
        ]
    },
    newCustomerInvoice: {
        path: '/data-vault/customers/:customerId/invoices/new',
        element: <NewInvoicePage />,
        title: 'New invoice',
        generatePath: (id: number) => `/data-vault/customers/${id}/invoices/new`,
        roles: [userRoles.invoices]
    },
    manageCustomerInvoices: {
        path: '/data-vault/customers/:customerId/invoices',
        element: <ManageCustomerInvoicesPage />,
        title: 'Manage invoices',
        generatePath: (id: number) => `/data-vault/customers/${id}/invoices`,
        roles: [userRoles.invoices]
    },
};

export const tokenRoute : RouteConfigurationMap = {
    root: {
        path: '/data-vault/tokens',
        element: <ManageTokensPage />,
        generatePath: () => '/data-vault/tokens',
        title: 'Payment methods',
        roles: [userRoles.dataVault]
    },
    manageTokens: {
        path: '/data-vault/tokens',
        element: <ManageTokensPage />,
        generatePath: () => '/data-vault/tokens',
        title: 'Payment methods',
        roles: [userRoles.dataVault]
    },
    newToken: {
        path: '/data-vault/tokens/new-token',
        element: <NewTokenPage />,
        generatePath: () => `/data-vault/tokens/new-token`,
        title: 'New payment method',
        roles: [userRoles.dataVault]
    },
    editToken: {
        path: '/data-vault/tokens/:id/edit',
        element: <EditTokenPage />,
        generatePath: (id: number) => `/data-vault/tokens/${id}/edit`,
        title: 'Edit payment method',
        roles: [userRoles.dataVault]
    },
    viewToken: {
        path: '/data-vault/tokens/:id',
        element: <ViewTokenPage />,
        generatePath: (id: number) => `/data-vault/tokens/${id}`,
        title: 'Payment method details',
        roles: [userRoles.dataVault]
    },
    linkTokenToCustomer: {
        path: '/data-vault/tokens/:id/link-customer',
        element: <LinkTokenCustomerPage />,
        title: 'Search customers',
        generatePath: (id: number) => `/data-vault/tokens/${id}/link-customer`,
        roles: [userRoles.dataVault]
    },
    newTokenSchedule: {
        path: '/data-vault/tokens/:tokenId/subscriptions/new',
        element: <NewEditSchedulePage />,
        title: 'New subscription',
        generatePath: (tokenId: number) => `/data-vault/tokens/${tokenId}/subscriptions/new`,
        roles: [userRoles.dataVault]
    },
};
