import { config } from './util';
import {
    ApiIntegrationApiFactory,
    PrivateKeyModel,
} from '@premier/webapi-client';

import mapErrors, { ApiResult } from './mapErrors';
import httpRequest from './httpRequest';

const apiIntegrationApi = ApiIntegrationApiFactory(config);

class APIIntegrationApi {
    static UpdateApplePayPrivateKeyWithFile(model: PrivateKeyModel, files: FileList): Promise<ApiResult<void>> {

        return new Promise((resolve, reject) => {
            httpRequest.postFiles('/ApiIntegration/UpdateApplePayPrivateKeyWithFile', files, { PrivateKeyModel: model })
                .then(() => {
                    resolve({
                        ok: true,
                        val: undefined
                    });
                })
                .catch((err) => {
                    reject(mapErrors(err));
                });
        });
    }

    static getPrivateKeySetting() {
        return apiIntegrationApi.apiIntegrationGetApplePayPrivateKey();
    }
}

export default APIIntegrationApi;
