import React, { useContext } from 'react';

import { ResponsiveTable } from '@premier/ui';
import { FormContext, InputField, DropdownField } from '@premier/form';
import { useDebounce } from 'components/Common';
import { ValueLabel } from './FraudAdvancedForm';
import { FraudRuleModel } from 'packages/webapi-client';

// @ts-ignore
import labels from 'constants/labels';

export const fieldInfo = {
    action: {
        name: 'action', // key in the form context data
        defaultValue: '', // default value for the field
        sortKey: 'Action', // backend key for sorting
    },
    type: {
        name: 'type',
        defaultValue: '',
        sortKey: 'Type',
    },
    lowField: {
        name: 'lowField',
        defaultValue: '',
        sortKey: 'LowField',
    },
    highField: {
        name: 'highField',
        defaultValue: '',
        sortKey: 'HighField',
    },
};

interface Props {
    actionOptions: ValueLabel[];
    typeOptions: ValueLabel[];
    onFilterChange: (values: any) => void;
    fraudRules: FraudRuleModel[];
    hasLoaded: boolean;
    noDataText: string;
    sort: { field: string; descending: boolean };
    onSort: (sortKey: string, isDescending: boolean) => void;
}

const FraudRulesList: React.FC<Props> = ({
    actionOptions,
    typeOptions,
    onFilterChange,
    fraudRules,
    hasLoaded,
    noDataText,
    sort,
    onSort,
}) => {
    const context = useContext(FormContext);
    const debouncedFilterChange = useDebounce(onFilterChange);

    function handleQuickFilterChange() {
        debouncedFilterChange(context.values);
    }

    const fields = {
        action: (
            <DropdownField
                name={fieldInfo.action.name}
                // @ts-ignore
                defaultValue={fieldInfo.action.defaultValue}
                options={actionOptions}
                onChange={handleQuickFilterChange}
            />
        ),
        type: (
            <DropdownField
                name={fieldInfo.type.name}
                // @ts-ignore
                defaultValue={fieldInfo.type.defaultValue}
                options={typeOptions}
                onChange={handleQuickFilterChange}
            />
        ),
        lowField: (
            <InputField
                name={fieldInfo.lowField.name}
                onChange={handleQuickFilterChange}
                data-testid='low-field-input'
            />
        ),
        highField: <InputField name={fieldInfo.highField.name} onChange={handleQuickFilterChange} />,
    };

    const columns = [
        {
            label: labels.fraudAction,
            sortKey: fieldInfo.action.sortKey,
            getter: (k: FraudRuleModel) => k.action?.description,
            filter: fields.action,
        },
        {
            label: labels.fraudType,
            sortKey: fieldInfo.type.sortKey,
            getter: (k: FraudRuleModel) => k.type?.description,
            filter: fields.type,
        },
        {
            label: labels.fraudLowValue,
            sortKey: fieldInfo.lowField.sortKey,
            getter: 'lowField',
            filter: fields.lowField,
        },
        {
            label: labels.fraudHighValue,
            sortKey: fieldInfo.highField.sortKey,
            getter: 'highField',
            filter: fields.highField,
        },
    ];

    return (
        <ResponsiveTable
            className='fraud-rules-list'
            // @ts-ignore
            data={fraudRules}
            // @ts-ignore
            columns={columns}
            isLoading={!hasLoaded}
            sort={sort}
            onSort={onSort}
            noDataText={noDataText}
        />
    );
};

export default FraudRulesList;
