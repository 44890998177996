import React from 'react';
import FormContext from '../FormContext';
import { Button } from '@premier/ui';

const ResetButton = props => {
    const { resetForm } = React.useContext(FormContext);

    function handleButtonClicked() {
        if (props.onSubmit) {
            var result = props.onReset();
            resetForm(result);
        } else {
            resetForm();
        }
    }

    return (
        <Button type='reset' onClick={handleButtonClicked}>
            {props.children}
        </Button>
    );
};

export default ResetButton;
