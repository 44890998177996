import { requestActionTypes } from 'components/Common/requestActions';


//#region ----- Payment Request -----
export const SEARCH_PAYMENT_REQUESTS = requestActionTypes('SEARCH_PAYMENT_REQUESTS');
export const CREATE_PAYMENT_REQUEST = requestActionTypes('CREATE_PAYMENT_REQUEST');
export const GET_PAYMENT_REQUEST = requestActionTypes('GET_PAYMENT_REQUEST');
export const GET_PAYMENT_REQUEST_STATUS = requestActionTypes('GET_PAYMENT_REQUEST_STATUS');
export const CANCEL_PAYMENT_REQUEST = requestActionTypes('CANCEL_PAYMENT_REQUEST');
export const RESEND_PAYMENT_REQUEST = requestActionTypes('RESEND_PAYMENT_REQUEST');
export const GET_PAYMENT_REQUEST_STATUSES = requestActionTypes('GET_PAYMENT_REQUEST_STATUSES');
export const GET_PAYMENT_REQUEST_ACTIONS = requestActionTypes('GET_PAYMENT_REQUEST_ACTIONS');
export const SAVE_PAYMENT_REQUEST_PAGE_STATE = 'SAVE_PAYMENT_REQUEST_PAGE_STATE';
export const GET_REQUEST_CONFIG = requestActionTypes('GET_REQUEST_CONFIG');
export const UPDATE_REQUEST_CONFIG = requestActionTypes('UPDATE_REQUEST_CONFIG');
//#endregion

//#region ----- Batch Payment Requests -----
export const SAVE_BATCH_PAYMENT_REQUEST_PAGE_STATE = 'SAVE_BATCH_PAYMENT_REQUEST_PAGE_STATE';
export const UPLOAD_BATCH_PAYMENT_REQUEST = requestActionTypes('UPLOAD_BATCH_PAYMENT_REQUEST');

export const GET_PAYMENT_REQUEST_BATCHES = requestActionTypes('GET_PAYMENT_REQUEST_BATCHES');
//#endregion