import { useState } from "react";
import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import { Navigate } from "react-router-dom";
import _ from "lodash";

import { Dialog, Icon, LoadingIndicator, Button } from "@premier/ui";
import { FormError } from "components/Common";
import { ProcessNowDialog, ScheduleDialog, ProcessBatchWarningDialog } from "components/Batch";

import { PlatformRoutesConfiguration } from "components/Routing";
import { RootState } from "store/store";

import * as batchActions from "components/Batch/_actions/batchActions";

const Step = {
    PROCESS_NOW: "processNow",
    SCHEDULE: "schedule",
    CALLING_API: "callingApi",
    SUCCESS: "success",
    WARNING: "warning",
    ERROR: "error",
};

type File = {
    fileId: number;
    filename?: string;
}

type Props = {
    show: boolean;
    isTemplate:boolean;
    files:File[];
    actions: any;
    onClose: () => void;
}

/** The ProcessBatch flow that consists of several Dialogs */
const ProcessBatchDialogs = ({
    show, isTemplate, //logic renders
    files, //data
    actions, //actions
    onClose //functions
}: Props) => {

    const [step, setStep] = useState(Step.PROCESS_NOW);
    const [errors, setErrors] = useState([]);
    const [redirectTo, setRedirectTo] = useState<string>("");
    const [fileList, setFileList]= useState<File[]>([]);    
    const [scheduleDate, setScheduleDate]= useState<Date | null>(null);

    function reset() {
        setStep(Step.PROCESS_NOW);
    }

    function handleCancel() {
        reset();
        setRedirectTo("");
        onClose();
    }

    function onInputComplete(processDate: Date | null) {
        setStep(Step.CALLING_API);
        startProcess(files, true, processDate);
    }

    function handleProcess() {
        setStep(Step.CALLING_API);
        startProcess(fileList, false, scheduleDate);
    }


    /** Call the API to process the batch file(s)/template(s)
      * @param {Date} processDate Set to null to process now
      */
    async function startProcess(files:File[], ignoreDuplicate:boolean, processDate: Date | null) {
        const promises = files.map(f => {
            if (isTemplate) {  // A batch library file
                return actions.processBatchLibraryFile(f.fileId, ignoreDuplicate, processDate);
            } else {  // A batch job
                return actions.editBatchJobSchedule(f.fileId, processDate);
            }
        });


        Promise.all(promises).then((response) => {
            let warningOrErrorFound = false;

            if (ignoreDuplicate)
            {
                fileList.length = 0;

                response.forEach(result =>
                {
                    if (result?.hasWarnings)
                    {
                        const fileToAdd = files.find(file => file.fileId === result?.batchFileId);
                        if (fileToAdd) {
                            fileList.push(fileToAdd);
                        }

                        setStep(Step.WARNING);
                        warningOrErrorFound = true;
                    }
                    else if (result?.hasErrors)
                    {
                        setStep(Step.ERROR);
                        warningOrErrorFound = true;
                    }
                });

                if (fileList.length > 0)
                {
                    setFileList(fileList);
                }

                if (!warningOrErrorFound)
                {
                    setStep(Step.SUCCESS);
                    reset();
                    handleDone(!!processDate);
                }
            }
            else {
                setStep(Step.SUCCESS);
                reset();
                handleDone(!!processDate);
            }

        }).catch(errors => {
            setStep(Step.ERROR);
            setErrors(errors);
        });
    }

    function handleDone(isScheduled:boolean) {
        setRedirectTo(isScheduled ? "scheduled" : "inProgress");
    }


    if (redirectTo === "scheduled")
        return <Navigate to={PlatformRoutesConfiguration.transactionRoute!.batchPayments.generatePath("scheduled")} />;
    else if (redirectTo === "inProgress")
        return <Navigate to={PlatformRoutesConfiguration.transactionRoute!.batchPayments.generatePath("inProgress")} />;

    return (<>
        <ProcessNowDialog show={show && step === Step.PROCESS_NOW}
            filenames={fileList && fileList.map((f:any) => f.filename)}

            onClose={handleCancel}
            onNow={() => { onInputComplete(null); }}
            onLater={() => { setStep(Step.SCHEDULE); }}
        />

        <ProcessBatchWarningDialog show={show && step === Step.WARNING}
            filenames={fileList && fileList.map((f:any) => f.filename)}
            onCancel={handleCancel}
            onProcess={handleProcess}
        />

        <ScheduleDialog show={show && step === Step.SCHEDULE}
            filenames={files && files.map((f:any) => f.filename)}
            onCancel={handleCancel}
            onSchedule={(date) => { setScheduleDate(date); onInputComplete(date); }}
        />

        <Dialog show={show && step === Step.CALLING_API}>
            <LoadingIndicator />
        </Dialog>

        <Dialog show={show && step === Step.ERROR}
            icon={<Icon alert />}
            title="An error occurred"
            footerButtons={<Button onClick={handleCancel}>OK</Button>}
        >
            <FormError apiErrors={errors} />
        </Dialog>

    </>);
};

function mapStateToProps(state: RootState) {
    return {
        allowLibrary: _.get(state, "accounts.users.authenticatedUser.features", []).includes("BATCH_MANAGER"),
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        actions: bindActionCreators(batchActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProcessBatchDialogs);