import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AccordionCard, PageSection, LoadingIndicator, IconText, Icon } from '@premier/ui';
import { PageHeader } from 'components/Common';
import text from 'constants/text';

import * as feedbackActions from '../_actions/feedbackActions'
import FeedbackForm from '../components/FeedbackForm';

const SubmitFeedbackPage = ({
    areas, // state
    feedbackActions
}) => {


    useEffect(() => {
        if (areas.isInitial) {
            feedbackActions.getFeedbackAreas();
        }
    }, [areas]);

    if (areas.hasFailed)
        return (
            <PageSection>
                <PageHeader title="Error" />
                <IconText alert>An unexpected error has occurred.</IconText>
            </PageSection>
        );

    return (
        <PageSection className='submit-feedback'>
            <PageHeader title='Feedback' />
            {!areas.hasLoaded && <LoadingIndicator/>}
            {areas.hasSucceeded && <>
                <AccordionCard
                    initiallyExpanded
                    disableToggle
                    icon={<Icon ideas />}
                    title={`Help us improve ${text.platformName}`}
                    description={<>
                        <p>Your feedback is valuable to us. Please let us know what you think should be improved in BPOINT. Please include as much details as possible.</p>

                        <p>For assistance, please contact our friendly help desk on <a href={`mailto:${text.supportEmail}`}>{text.supportEmail}</a> or call <a href={text.supportPhoneLink}>{text.supportPhoneNumber}</a></p>   </>}
                >
                    <FeedbackForm feedbackAreas={areas.data} feedbackType="Improvement" />
                </AccordionCard>
                <AccordionCard
                    initiallyExpanded
                    disableToggle
                    icon={<Icon trauma/>}
                    title='Report issues'
                    description={<>
                        For urgent assistance please contact our friendly help desk on <a href={`mailto:${text.supportEmail}`}>{text.supportEmail}</a> or call <a href={text.supportPhoneLink}>{text.supportPhoneNumber}</a>
                    </>}
                >
                    <FeedbackForm feedbackAreas={areas.data} feedbackType="Defect" />
                </AccordionCard>
            </>}
        </PageSection>
    );
}

function mapStateToProps(state, ownProps) {
    return {
        areas: state.feedback.areas
    };
}

function mapDispatchToProps(dispatch) {
    return {
        feedbackActions: bindActionCreators(feedbackActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SubmitFeedbackPage);