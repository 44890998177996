import _ from "lodash";
import PropTypes from "prop-types";

import { PlatformRoutesConfiguration } from "components/Routing";
import currencyUtil from "@premier/utils/currency";
import { Link } from "@premier/ui";
import BaseTransactionDetailCategory from "./BaseTransactionDetailCategory";
import { Transaction } from "models/Transaction";
import { NameValue } from "models";

type Props = {
    transaction: Transaction;
}

const PaymentAmountsCategory = ({ transaction }: Props) => {

    const categoryTitle = _.get(transaction, "paymentSource.transactionTypeDetails.description") + " amounts";
    const paymentAmounts = [];

    if (_.get(transaction, "paymentProcessedTxnModel.txnFinancialType") === "CAPTURE") {
        paymentAmounts.push({ name: "Captured amount", value: currencyUtil.convertToDisplayString(transaction.paymentProcessedTxnModel.totalAmount ?? 0, transaction.paymentProcessedTxnModel.currencyCode) });
        paymentAmounts.push({ name: "Original pre-auth amount", value: currencyUtil.convertToDisplayString(transaction.paymentSource?.originalTransactionAmount ?? 0, transaction.paymentProcessedTxnModel.currencyCode) });
    }
    else if (_.get(transaction, "paymentProcessedTxnModel.txnFinancialType") === "VERIFY") {
        paymentAmounts.push({ name: "Verify amount", value: currencyUtil.convertToDisplayString(transaction.paymentProcessedTxnModel.totalAmount ?? 0, transaction.paymentProcessedTxnModel.currencyCode) });
    }
    else {
        paymentAmounts.push({ name: "Amount paid", value: currencyUtil.convertToDisplayString(transaction.paymentProcessedTxnModel.totalAmount ?? 0, transaction.paymentProcessedTxnModel.currencyCode) });
        paymentAmounts.push({ name: "Amount original", value: currencyUtil.convertToDisplayString(transaction.paymentProcessedTxnModel.originalAmount ?? 0, transaction.paymentProcessedTxnModel.currencyCode) });
        paymentAmounts.push({ name: "Amount surcharge", value: currencyUtil.convertToDisplayString(transaction.paymentProcessedTxnModel.surchargeAmount ?? 0, transaction.paymentProcessedTxnModel.currencyCode) });

        if (_.get(transaction, "paymentProcessedTxnModel.txnFinancialType") === "REFUND" && _.get(transaction, "paymentSource.originalTransactionId")) {
            paymentAmounts.push({ name: "Original transaction amount", value: currencyUtil.convertToDisplayString(_.get(transaction, "paymentSource.originalTransactionAmount") ?? 0, transaction.paymentProcessedTxnModel.currencyCode) });
        }
        if (_.get(transaction, "paymentProcessedTxnModel.txnFinancialType") === "PREAUTH") {
            const fullyCompleted = _.get(transaction, "paymentSource.fullyCompleted");
            const partiallyCompleted = _.get(transaction, "paymentSource.partiallyCompleted");

            paymentAmounts.push({ name: "Capture status", value: fullyCompleted ? "Fully captured" : partiallyCompleted ? "Partially captured" : "Not captured" });
            paymentAmounts.push({ name: "Captured amount", value: transaction && transaction.paymentSource && currencyUtil.convertToDisplayString(transaction.paymentSource.capturedAmount ?? 0, transaction.paymentProcessedTxnModel.currencyCode) });
        }
    }

    const partiallyRefunded = _.get(transaction, "paymentSource.partiallyRefunded");
    const fullyRefunded = _.get(transaction, "paymentSource.completelyRefunded");

    if (partiallyRefunded || fullyRefunded) {
        paymentAmounts.push({ name: "Refund status", value: fullyRefunded ? "Fully refunded" : "Partially refunded" });
        paymentAmounts.push({ name: "Refund amount", value: currencyUtil.convertToDisplayString(_.get(transaction, "paymentSource.refundedAmount") ?? 0, transaction.paymentProcessedTxnModel.currencyCode) });
    }
    const additionalPayments = transaction?.txnAdditionalPaymentModel;
    const additionalPaymentAmounts: NameValue<string>[] = [];

    if (additionalPayments && additionalPayments.length > 0) {
        additionalPayments.forEach(item => {
            additionalPaymentAmounts.push({ name: item.description ? (item.description === 'BASE_PAYMENT' ? 'Base Payment' : item.description) : "", value: currencyUtil.convertToDisplayString(item.amount ?? 0, transaction.paymentProcessedTxnModel.currencyCode) });
        });
    }

    return (
        <BaseTransactionDetailCategory title={categoryTitle} items={paymentAmounts} additionalPaymentAmounts={additionalPaymentAmounts}>
            {_.get(transaction, "paymentProcessedTxnModel.txnFinancialType") === "CAPTURE" && _.get(transaction, "paymentSource.originalTransactionId")
                && <Link to={PlatformRoutesConfiguration.transactionRoute?.transactionDetails.generatePath(transaction.paymentSource?.originalTransactionId + (transaction.paymentProcessedTxnModel.isExternal ? "-external" : ""))}>View PreAuth</Link>
            }
            {_.get(transaction, "paymentProcessedTxnModel.txnFinancialType") === "REFUND" && _.get(transaction, "paymentSource.originalTransactionId")
                && <Link to={PlatformRoutesConfiguration.transactionRoute?.transactionDetails.generatePath(transaction.paymentSource?.originalTransactionId + (transaction.paymentProcessedTxnModel.isExternal ? "-external" : ""))}>View original transaction</Link>
            }
            {_.get(transaction, "paymentProcessedTxnModel.txnFinancialType") === "REVERSAL" && _.get(transaction, "paymentSource.originalTransactionId")
                && <Link to={PlatformRoutesConfiguration.transactionRoute?.transactionDetails.generatePath(transaction.paymentSource?.originalTransactionId + (transaction.paymentProcessedTxnModel.isExternal ? "-external" : ""))}>View original transaction</Link>
            }
        </BaseTransactionDetailCategory>
    );
};

PaymentAmountsCategory.propTypes = {
    transaction: PropTypes.object.isRequired,
};

export default PaymentAmountsCategory;
