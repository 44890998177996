import countryUtil from "@premier/utils/country";

// @ts-ignore
import labels from "constants/labels";
import Form from "../Form";
import InputField from "../fields/InputField";
import DropdownField from "../fields/DropdownField";
import { UiOption } from "packages/utils/models";

type Props = {
    name: string;
    countryOptions?: UiOption<number>[];
    disableCountry?: boolean;
    defaultCountryCode?: number;
};

/** An optional country dropdown, 2 address lines, suburb, state dropdown/textbox and postcode. */
const AddressForm = ({
    name, defaultCountryCode, countryOptions, //data
    disableCountry //logic render
} : Props) => {
    function getCountryOptions() {
        return countryOptions || countryUtil.getCountryOptions();
    }

    function getStateOptions(context: any) {
        return countryUtil.getStateOptions(context.values.countryCode);
    }

    return (
        <Form
            name={name || "address"}
            initialValues={{
                countryCode: disableCountry ? undefined : (defaultCountryCode ?? countryUtil.country.countryCode)
            }}
            render={context => (<>
                {!disableCountry &&
                    <DropdownField name="countryCode" label={labels.country} options={getCountryOptions()} />
                }
                <InputField name="addressLine1" label="Address line 1"/>
                <InputField name="addressLine2" label="Address line 2"/>
                <InputField name="suburb" label={labels.locality}/>
                <div className="row">
                    <div className="col-sm-6">
                        {getStateOptions(context).length
                            ? <DropdownField name="stateId" label={labels.state} options={getStateOptions(context)} />
                            : <InputField name="state" label={labels.state} />
                        }
                    </div>
                    <div className="col-sm-6">
                        <InputField name="postcode" label={labels.postcode}/>
                    </div>
                </div>
            </>)}
        />
    );
};

export default AddressForm;
