import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import * as billpayUtil from '@premier/utils/billpay';

import { ResponsiveTable, Link } from '@premier/ui';
import { FormContext, InputField } from '@premier/form';
import { useDebounce } from 'components/Common';

import labels from 'constants/labels';
import { PlatformRoutesConfiguration } from 'components/Routing';


const FindLinkCustomerList = ({
    savedFilter, customers, //data
    isLoading, //logic renders
    onFilterChange, sort, onSort, onLinkTokens //functions
}) => {

    const context = React.useContext(FormContext);

    const batchActions = [{
        label: 'Link',
        batchLabel: 'Link',
        tableButtonProps: {primary: true},
        handleClick: handleLinkClick
    }]

    // Update quick filter values from Redux
    useEffect(() => {
        if(savedFilter)
            context.setFormValues(savedFilter);
    }, [savedFilter]);

    function handleLinkClick(selectedCustomers) {
        if (_.isEmpty(selectedCustomers)) {
            return;
        }
        onLinkTokens(selectedCustomers[0]);
    }

    /** Triggers onFilterChange (ie. API call) on the parent */
    function handleQuickFilterChange(values) {
        onFilterChange(values || context.values);  // context.values may not be updated yet, pass values if needed
    }
    const debouncedFilterChange = useDebounce(handleQuickFilterChange);

    // The quick filter fields
    const fields = {
        uniqueId: (
            <InputField
                name='uniqueId'
                onChange={val => debouncedFilterChange({ ...context.values, uniqueId: val })}
                aria-label={labels.customerCode}
            />
        ),
        name: (
            <InputField
                name='name'
                onChange={val => debouncedFilterChange({ ...context.values, name: val })}
                aria-label={labels.name}
            />
        ),
        email: (
            <InputField
                name='emailAddress'
                onChange={val => debouncedFilterChange({ ...context.values, emailAddress: val })}
                aria-label={labels.email}
            />
        ),
        phoneNumber: (
            <InputField
                name='phone'
                onChange={val => debouncedFilterChange({ ...context.values, phone: val })}
                aria-label={labels.phone}
            />
        )
    };

    // The table columns (or list items)
    const columns = [{
        label: labels.customerCode,
        sortKey: 'uniqueId',
        getter: customer => <Link newWindow to={PlatformRoutesConfiguration.customerRoute.viewCustomer.generatePath(customer.customerId)}>{customer.uniqueId}</Link>,
        filter: fields.uniqueId
    }, {
        label: labels.name,
        sortKey: 'name',
        getter: customer => billpayUtil.getCustomerFullName(customer),
        filter: fields.name,
    }, {
        label: labels.email,
        getter: 'emailAddress',
        sortKey: 'emailAddress',
        filter: fields.email
    }, {
        label: labels.phone,
        getter: customer => billpayUtil.formatPhoneObjectToApiString(_.get(customer, 'phoneNumbers[0].phoneNumber')),
        filter: fields.phoneNumber,
    }];

    return (<>
        <ResponsiveTable
            data={customers}
            isLoading={isLoading}
            columns={columns}
            sort={sort}
            onSort={onSort}
            noDataText='No customers to display.'
            batchActions={batchActions}
            singleSelect
        />
    </>);
};


FindLinkCustomerList.propTypes = {
    /** The saved filter from Redux (used only to update Form values when it's updated in Redux) */
    savedFilter: PropTypes.object,
    onFilterChange: PropTypes.func,
    /** The current sort order */
    sort: PropTypes.shape({
        field: PropTypes.string,
        descending: PropTypes.bool,
    }),
    /** to handle sort field/order changes */
    onSort: PropTypes.func,

    /** What happens when redirecting to view item details (eg. to save the current page state into Redux) */
    onLinkTokens: PropTypes.func,

    customers: PropTypes.array,
    isLoading: PropTypes.bool,
};

export default FindLinkCustomerList;