import base from 'platforms/base/constants/text';

export default {...base,
    platformName: 'MerchantSuite',
    loginHeader: 'MerchantSuite log on',
    loginText: 'Please log in',
    footerText: 'Brought to you by Linkly.',
    supportPhoneNumber: 'TBA',
    supportPhoneLink: 'TBA',
};
