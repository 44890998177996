import React from 'react';
import { IconText } from '@premier/ui';

interface Props {
    show: boolean;
    message: string;
}

const InvoiceMaxItemsMessage: React.FC<Props> = ({ show, message }) => {
    return (
        <>
            {show && (
                <IconText info>
                    <span>{message}</span>
                </IconText>
            )}
        </>
    );
};

export default InvoiceMaxItemsMessage;
