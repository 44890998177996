import React from "react";
import {
    Icon,
    LoadingIndicator,
    PageSection,
    Tooltip,
    TooltipTrigger,
} from "@premier/ui";
import textUtil from "@premier/utils/text";

import "./InvoiceSettings.scss";

interface Props {
    title: string;
    subtitle?: string | React.ReactElement;
    tooltip?: string | React.ReactElement;
    tooltipId?: string;
    isLoading?: boolean;
}

const InvoiceSettingsHeading: React.FC<Props> = ({
    title,
    subtitle,
    tooltip,
    tooltipId,
    isLoading,
}) => {
    const showSubtitle = () => {
        if (isLoading !== undefined && subtitle !== undefined) {
            // Display a loading spinner until the invoice config is retrieved
            if (isLoading) {
                return (
                    <div className="invoice-settings-item-loading-indicator">
                        <LoadingIndicator />
                    </div>
                );
            } else if (typeof subtitle === "string" && subtitle.length > 0) {
                return (
                    <div className="invoice-settings-item-subtitle">
                        {textUtil.newlineToBr(subtitle)}
                    </div>
                );
            } else if (React.isValidElement(subtitle)) {
                return (
                    <div className="invoice-settings-item-subtitle">
                        {subtitle}
                    </div>
                );
            }
        }
        return <></>;
    };

    return (
        <PageSection noDivider={true} noPaddingBottom>
            <div className="invoice-settings-item-container">
                <h2 className="invoice-settings-item-heading">{title}</h2>
                <TooltipTrigger
                    className="invoice-settings-tooltip-icon-container"
                    tipId={tooltipId}
                >
                    <Icon className="invoice-settings-tooltip-icon" question />
                </TooltipTrigger>
            </div>
            {showSubtitle()}

            <Tooltip id={tooltipId}>
                <p>{tooltip}</p>
            </Tooltip>
        </PageSection>
    );
};
export default InvoiceSettingsHeading;
