import { PageSection } from '@premier/ui';
import { AddOrUpdatePaymentPlanOptionForm } from 'components/Settings'
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { VerifyBiller } from '../../../util/verificationUtil'
import { useEffect } from 'react';
import { PlatformRoutesConfiguration } from 'components/Routing';

const EditPaymentPlanOptionPage = () => {
    const { billerCode, optionId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        try {
            // Verify that we have access to this biller code.
            VerifyBiller(billerCode);
        } catch (error) {
            // Show a 404 page if we do not have access.
            navigate(PlatformRoutesConfiguration.accountRoute.notFound.generatePath());
        }
    }, [billerCode]);

    return (
        <PageSection>
            <AddOrUpdatePaymentPlanOptionForm optionId={optionId} billerCode={billerCode} />
        </PageSection>
    );
};

export default EditPaymentPlanOptionPage;
