import { userRoles } from 'components/Routing';
import { CustomReports, SettlementReports, ExportSettlements } from 'components/Reports';
import { RouteConfigurationMap } from 'components/Routing';

export const reportRoute : RouteConfigurationMap = {
    root: {
        path:               '/reports',
        generatePath: () => '/reports',
    },
    settlementReports: {
        path:               '/reports/settlement-reports',
        generatePath: () => '/reports/settlement-reports',
        element: <SettlementReports />,
        title: 'Settlements',
        roles:              [userRoles.settlement]
    },
    exportSettlements: {
        path:               '/reports/settlement-reports/export',
        generatePath: () => '/reports/settlement-reports/export',
        element: <ExportSettlements />,
        title: 'Export settlements',
        roles:              [userRoles.settlement]
    },
    customReports: {
        path:               '/reports/custom-reports',
        generatePath: () => '/reports/custom-reports',
        element: <CustomReports />,
        title: 'Custom reports',
        roles:              [userRoles.reports]
    },
};