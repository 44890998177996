import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import classNames from 'classnames';

import windowUtil from '@premier/utils/window';
import errorUtil from '@premier/utils/error';
import uiUtil from '@premier/utils/ui';
import * as billpayUtil from '@premier/utils/billpay';
import { PageSection, BackToTop, PaginationControl, CardContainer, LoadingIndicator, Button, Link, Icon, Dialog } from '@premier/ui';
import { Form, DropdownField } from '@premier/form';

import { withError, FormError, PageHeader, PromotionBoxesPageSection, PromotionCard, ProductTooltip } from 'components/Common';
import { ScheduleList, ScheduleFilter, OverdueSchedulesBanner } from 'components/Transactions';

import labels from 'constants/labels';
import { defaultPageSize, defaultPageSizeOptions } from 'constants/billpay';

import * as scheduleActions from 'components/Transactions/_actions/scheduleActions';

import { PlatformRoutesConfiguration } from 'components/Routing';

import './ManageSchedulesPage.scss';

const ManageSchedulesPage = ({
    scheduleStatuses, scheduleFrequencies, schedulePaymentStatuses,
    searchResult, scheduleCount, savedState, pageIsLoading, searchIsLoading, merchant, billers, overduePaymentsCount, filterInArrears, //state values
    actions, //API actions
    errors, //forms
}) => {

    const [showFilter, setShowFilter] = useState(false);
    const [filter, setFilter] = useState({});  // A live copy of the form values
    const [lastSavedFilter, setLastSavedFilter] = useState({});
    const [sort, setSort] = useState(_.get(savedState, 'sort', { field: 'Created', descending: true }));
    const [reload, setReload] = useState(false);
    const [resultsPerPage, setResultsPerPage] = useState(_.get(savedState, 'resultsPerPage', defaultPageSize));
    const [currentPage, setCurrentPage] = useState(_.get(savedState, 'pageIndex', 0) + 1);
    const [showAddDialog, setShowAddDialog] = useState(false);

    const promotions =  [
        <>
            <Icon person />
            <h2>Create profile</h2>
            <p>Link one or more subscriptions to a new or existing customer.</p>
        </>,
        <>
            <Icon card />
            <h2>Add payment method</h2>
            <p>Keep track on each customer’s subscriptions and other payment activities.</p>
        </>,
        <>
            <Icon calendar />
            <h2>Create a subscription</h2>
            <p>Set up a payment subscription using a stored payment method.</p>
        </>
    ]

    // On page load, fetch things from API, get schedules and restore previous state (page number, scroll position, filters) if any
    useEffect(() => {
        const refDataRequests = [
            actions.getScheduleStatuses(),
            actions.getScheduleFrequencies(),
            actions.getSchedulePaymentStatuses()
        ]

        Promise.all(refDataRequests).then(() => {
            if (savedState && savedState.scrollPosition)
                windowUtil.scrollTo(savedState.scrollPosition); // Scroll back to previous position
        });

        const queryFilter = filterInArrears ? {schedulePaymentStatus: 'OVERDUE'} : {};
        if (savedState && !_.isEmpty(savedState))
            handleFilterChange({...savedState.filter, ...queryFilter}, false);
        else
            handleFilterChange({...defaultFilter, ...queryFilter});
    }, [actions]);

    useEffect(() => {
        if (reload) {
            const page = currentPage -1;
            actions.saveSchedulesPageState({resultsPerPage, page, filter, sort})
            actions.getSchedules(resultsPerPage, page, filter, sort);
            actions.getOverduePaymentsCount({ childMerchantNumber: _.get(filter, 'childMerchantNumber') });
            setReload(false);
        }
    }, [reload]);
    //#region ========== Filter stuff ==========

    const defaultFilter = {
        deAccountName: '',
        billerCode: '',
        childMerchantNumber: '',
        createdDateRange: Array(0),
        customerUniqueCode: '',
        deAccountNumber: '',
        deBsbNumber: '',
        endDateRange: {dates: Array(0), presetValue: "all"},
        expiryDate: {month: '', year: ''},
        frequency: '',
        maskedCardNumber: {prefix: '', suffix: ''},
        nextPayment: [],
        recurringAmount: null,
        reference1: '',
        reference2: '',
        reference3: '',
        schedulePaymentStatus: '',
        scheduleReference: '',
        scheduleStatus: '',
        tokenReference1: '',
        tokenReference2: '',
        tokenReference3: '',
    };

    const filterCount = useMemo(() => {
        var count = 0;

        Object.keys(defaultFilter).forEach(key => {
            if (!_.isEqual(filter[key], defaultFilter[key])) {
                count++;
            }
        });

        return count;
    }, [filter, reload]);

    /** This is called when a component auto-updates it's internal value (ie. DateRangePreset updates the dates/presetValue based on the other) */
    function handleFilterUpdate(newFilter) {
        setFilter(newFilter);
    }

    function handleFilterChange(newFilter, resetPage = true) {  // Note: This is also called at first render (from either useEffect or DateRangePresetField completing the dates)
        if (resetPage)
            setCurrentPage(1);

        setFilter(newFilter);
        setLastSavedFilter(newFilter);
        setReload(true);
    }
    //#endregion

    function handleSort(field, descending) {
        setSort({ field, descending });
        setReload(true);
    }

    function pageChanged(newPage) {
        setCurrentPage(newPage);
        setReload(true);
    }

    function resultsPerPageChanged(newResultsPerPage) {
        setResultsPerPage(newResultsPerPage);
        setCurrentPage(1);
        setReload(true);
    }

    function saveScrollPosition() {
        var pageState = Object.assign({}, savedState, {
            scrollPosition: document.body.scrollTop || document.documentElement.scrollTop
        });

        actions.saveSchedulesPageState(pageState);
    }

    function setArearsFilter() {
        const newFilter = {
            ...defaultFilter,
            schedulePaymentStatus: 'OVERDUE'
        };
        handleFilterChange(newFilter);
    }

    if (pageIsLoading)
        return (
            <CardContainer header="Loading subscriptions">
                <LoadingIndicator />
            </CardContainer>
        );

    // ========== Render ==========
    return (<>
        {(!searchIsLoading && scheduleCount === 0 && filterCount === 0) && (<>
            <PromotionBoxesPageSection
                noDivider
                title='Subscriptions'
                subText='Set and forget payment subscription using payment methods stored in a secure environment.'
                promotions={promotions}
            />

            <PageSection>
                <PromotionCard
                    badge='New'
                    title='Manage your payments better through Customers'
                    subText='Use Customer profiles to simply create and manage all your customer’s transactions, payment methods and subscriptions.'
                    buttons={
                        <Link primary newWindow to={PlatformRoutesConfiguration.customerRoute.root.generatePath()}>Go to Customers</Link>
                    }
                />
            </PageSection>

        </>)}

        {(searchIsLoading || scheduleCount > 0 || filterCount > 0) && (
            <Form initialValues={(savedState && savedState.filter) || defaultFilter}>
                <PageSection className={classNames('schedules-page', 'full-width', { 'hidden': showFilter })} aria-hidden={showFilter}>
                    <PageHeader title={<>{'Subscriptions'}<ProductTooltip productTooltipModule={"SUBSCRIPTION_TRANSACTIONS"} /></>} >
                        {merchant.isScheduleWithoutCustomer
                            ? <Link button primary to={PlatformRoutesConfiguration.transactionRoute.newSchedule.generatePath()}>New subscription</Link>
                            : <Button primary onClick={() => {setShowAddDialog(true)}}>New subscription</Button>
                        }
                    </PageHeader>

                    <Button subtle onClick={() => { setShowFilter(true) }}>View filters ({filterCount} applied)</Button>

                    <div className="row dropdowns">
                        {_.get(merchant, 'childMerchants.length') > 0 && (
                            <div className="col-lg-6 col-xl-5 filter-container merchant-filter-container inline-label">
                                <DropdownField name='childMerchantNumber' label={labels.merchant}
                                    options={billpayUtil.getMerchantOptions(merchant)}
                                    onChange={(val, context) => handleFilterChange(context.values)}
                                />
                            </div>
                        )}

                        <div className="col-lg-6 filter-container results-filter-container inline-label">
                            <DropdownField id='pageSize' name="pageSize" label="Items per page" aria-labelledby='pageSize_label'
                                onChange={resultsPerPageChanged}
                                options={defaultPageSizeOptions.map(result => ({ value: result, label: result }))}
                                defaultValue={resultsPerPage}
                            />
                        </div>
                    </div>

                    <OverdueSchedulesBanner overduePaymentsCount={overduePaymentsCount} onManageClick={setArearsFilter} />

                    <FormError errors={errors} />

                    <ScheduleList
                        // For the quick filter
                        savedFilter={savedState && savedState.filter}
                        onAutoUpdate={handleFilterUpdate}
                        onFilterChange={handleFilterChange}
                        // For the table/list
                        sort={sort}
                        onSort={handleSort}
                        onLinkClick={saveScrollPosition}
                        schedules={searchResult}
                        scheduleStatusOptions={uiUtil.generateOptions(scheduleStatuses)}
                        isLoading={searchIsLoading}
                    />

                    <PaginationControl currentPage={currentPage} onPageChanged={pageChanged} itemCount={scheduleCount} itemsPerPage={resultsPerPage} />

                    <BackToTop />
                </PageSection>

                <ScheduleFilter
                    show={showFilter}
                    onHide={() => setShowFilter(false)}
                    defaultFilter={defaultFilter}
                    lastSavedFilter={lastSavedFilter}
                    onAutoUpdate={handleFilterUpdate}
                    onChange={handleFilterChange}
                    billers={billers}
                    scheduleStatuses={scheduleStatuses}
                    scheduleFrequencies={scheduleFrequencies}
                    schedulePaymentStatuses={schedulePaymentStatuses}
                />
            </Form>

        )}

        <Dialog
            show={showAddDialog}
            title='New subscription'
            footerButtons={<>
                <Link primary to={PlatformRoutesConfiguration.customerRoute.root.generatePath()}>Go to Customers</Link>
                <Button onClick={() => {setShowAddDialog(false)}}>Cancel</Button>
            </>}
            >
            Create subscriptions for your customers
        </Dialog>
    </>);
}

function mapStateToProps(state, ownProps) {
    const queryString = _.get(ownProps, 'location.search');

    const searchResult = state.transactions.schedule.searchResult;
    const scheduleStatuses = state.transactions.schedule.scheduleStatuses;
    const scheduleFrequencies = state.transactions.schedule.scheduleFrequencies;
    const schedulePaymentStatuses = state.transactions.schedule.schedulePaymentStatuses;
    const pageIsLoading = searchResult.isInitial;

    const savedState = state.transactions.schedule.schedulesPageState;
    const selectedMerchant = _.get(savedState, 'filter.childMerchantNumber', null);

    let billers = state.accounts.users.activeBillers;
    if (selectedMerchant)
        billers = _.filter(billers, { merchantNumber: selectedMerchant })

    return {
        pageIsLoading,
        savedState,
        searchResult: _.get(searchResult, 'data.schedules', []),
        searchIsLoading: searchResult.isLoading,
        scheduleCount: _.get(searchResult, 'data.resultCount', 0),
        scheduleStatuses: scheduleStatuses.data,
        scheduleFrequencies: scheduleFrequencies.data,
        schedulePaymentStatuses: schedulePaymentStatuses.data,
        overduePaymentsCount: _.get(state, 'transactions.schedule.overduePaymentsCount.data.resultCount'),
        merchant: state.accounts.users.merchant,
        childMerchants: state.accounts.users.merchant.childMerchants,
        billers,
        filterInArrears: queryString && /.*inArrears=true.*/g.test(queryString),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(scheduleActions, dispatch)
    };
}

function mapStoreToErrors(state) {
    return state.transactions.schedule.errors;
}

function mapErrorToString(error) {
    var paramLabels = {
        'Status': labels.status,
        'Reference1': labels.reference1,
        'TokenReference1': labels.tokenReference1,
        'MaskedCardNumber': labels.maskedCardNumber,
        'AccountNumber': labels.bankAccountNumber,
    };

    return errorUtil.getMessage(error, paramLabels);
}

export default withError(connect(mapStateToProps, mapDispatchToProps)(ManageSchedulesPage), mapStoreToErrors, mapErrorToString);
