import { useState } from "react";
import _ from "lodash";

import regexUtil from "@premier/utils/regEx";

// @ts-ignore
import labels from "constants/labels";

import { FormGroup } from "@premier/ui";
import Form from "../Form";
import InputField from "../fields/InputField";
import { validate } from "../validation/fieldValidation";

import "./BankAccountForm.scss";

type Props = {
    /** The sub-form name. Default='bankAccountDetails'. Try not to overwrite it (ie. leave it undefined) unless you have multiple of this in a Form. */
    name?: string;

    label?: string;

    /** Sets the component to run the required validations & style changes*/
    required: boolean;
};

const BankAccountForm = ({ name = "bankAccountDetails", label, required } : Props) => {

    const [bankAccountFieldId] = useState(_.uniqueId("bankAccountField_"));  // currently no use for accessibility because the FormGroup only has more FormGroups

    return (
        <Form
            name={name}
            initialValidation = {{
                bsbNumber: validate()
                    .requiredIf(() => required, "BSB number is required")
                    .matches(regexUtil.bsbRegex, "Invalid BSB number"),
                accountNumber: validate()
                    .requiredIf(() => required, "Account number is required")
                    .matches(regexUtil.accountNumberRegex, "Invalid account number"),
                accountName: validate()
                    .requiredIf(() => required, "Account name is required")
            }}
        >
            <FormGroup
                fieldId={bankAccountFieldId}
                label={label || labels.bankAccountNumber}
                mandatory={required}
            >
                <div className="bank-account-field">
                    <InputField
                        digitsOnly
                        maxLength={6}
                        className="bsb-input"
                        name="bsbNumber"
                        labelText={labels.bsb}
                        placeholder="BSB"
                    />
                    <div className="dash">-</div>
                    <InputField
                        digitsOnly
                        maxLength={9}
                        className="account-input"
                        name="accountNumber"
                        labelText={labels.accountNumber}
                        placeholder="Account"
                    />
                </div>
            </FormGroup>
            <InputField
                maxLength={50}
                name="accountName"
                label={labels.accountName}
                mandatory={required}
            />
        </Form>
    );
};

export default BankAccountForm;
