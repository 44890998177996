import { userRoles } from 'components/Routing';
import { ViewInvoicePage } from 'components/Invoicing';
import { InvoiceSettingsPage, InvoiceSettingsDetailsPage, InvoiceSettingsItemsPage, InvoiceSettingsCustomerNotesPage } from 'components/Settings';
import { RouteConfigurationMap } from 'components/Routing';

export const invoicingRoute : RouteConfigurationMap = {
    root: {
        path: '/invoicing',
        generatePath: () => '/invoicing',
        roles: [userRoles.invoices],
    },
    viewInvoice: {
        path: '/invoicing/invoices/:invoiceId',
        element: <ViewInvoicePage />,
        title: 'View invoice',
        generatePath: (id: number) => `/invoicing/invoices/${id}`,
        roles: [userRoles.invoices],
    },
};

export const invoiceSettingsRoute : RouteConfigurationMap = {
    root: {
        path:               '/settings/invoices',
        element: <InvoiceSettingsPage />,
        generatePath: () => '/settings/invoices',
        title: 'Invoice settings',
        roles:              [userRoles.invoiceSettings]
    },
    editInvoiceDetail: {
        path:               '/settings/invoices/invoiceDetail',
        element: <InvoiceSettingsDetailsPage />,
        generatePath: () => '/settings/invoices/invoiceDetail',
        title: 'Invoice settings',
        roles:              [userRoles.invoiceSettings]
    },
    editInvoiceItems: {
        path:               '/settings/invoices/invoiceItems',
        element: <InvoiceSettingsItemsPage />,
        generatePath: () => '/settings/invoices/invoiceItems',
        title: 'Invoice items',
        roles:              [userRoles.invoiceSettings]
    },
    editInvoiceCustomerNotes: {
        path:               '/settings/invoices/customerNotes',
        element: <InvoiceSettingsCustomerNotesPage />,
        generatePath: () => '/settings/invoices/customerNotes',
        title: 'Customer notes',
        roles:              [userRoles.invoiceSettings]
    },

}