import { FormEvent, ReactNode } from "react";

export type FormProps = {
    children: ReactNode,
    onSubmit: () => void
}

export function Form({
    children,
    onSubmit
}: FormProps) {

    function handleSubmit(e: FormEvent<HTMLFormElement>) {
        e.preventDefault();
        onSubmit();
    }

    return <form onSubmit={handleSubmit}>
        {children}
    </form>;
}