import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';

import { PageSection, DescriptionList, LoadingIndicator, IconText, BackButton, PaddedContainer, Icon, Button, Dialog, SuccessModal } from '@premier/ui';
import { PageHeader, FormError } from 'components/Common';
import { CustomerDetails } from 'components/DataVault';
import { PaymentRequestPendingModal } from 'components/Transactions';

import dateUtil from '@premier/utils/date';
import currencyUtil from '@premier/utils/currency';

import { PlatformRoutesConfiguration } from 'components/Routing';
import * as paymentRequestActions from 'components/PaymentRequests/_actions/paymentRequestActions';
import * as billerActions from 'components/Settings/_actions/billerActions';

import labels from 'constants/labels';

const ViewPaymentRequestPage = ({
    actions, billerActions, //API actions
    paymentRequest, paymentRequestId, biller, cancelErrors, //state values
    isLoading //logic renders
}) => {

    const [reload, setReload] = useState(true);
    const [showResendConfirmModal, setShowResendConfirmModal] = useState(false);
    const [showResendPendingModal, setShowResendPendingModal] = useState(false);
    const [showCancelConfirmModal, setShowCancelConfirmModal] = useState(false);
    const [showCancelSuccessModal, setShowCancelSuccessModal] = useState(false);

    useEffect(() => {
        if (reload) {
            actions.get(paymentRequestId)
                .then(() => {
                    setReload(false);
                });
        }
    }, [reload]);

    function getPaymentRequestDetails() {
        return [
            {name: labels.action, value: paymentRequest.action.description},
            {name: labels.billerCode, value: paymentRequest.billerCode},
            {name: labels.reference1, value: paymentRequest.crn1},
            {name: labels.amount, value: currencyUtil.convertToDisplayString(paymentRequest.amount)},
            {name: labels.dueDate, value: dateUtil.convertToDateString(paymentRequest.dueDate)},
            {name: labels.dateSent, value: dateUtil.convertToDateString(paymentRequest.createdDate)},
            {name: labels.deliveryEmailAddress, value: paymentRequest.emailAddress},
            {name: labels.deliveryPhoneNumber, value: paymentRequest.mobileNumber},
        ];
    }

    function getPrefixDetails() {
        return [
            {name: labels.createdTime, value: dateUtil.convertToDateTimeString(paymentRequest.createdDate)},
            {name: labels.userNameUpdated, value: paymentRequest.userNameUpdated},
        ];
    }

    function getPaymentRequestStatusDescription() {
        return _.get(paymentRequest, 'status.description') || 'Unknown';
    }

    function getPaymentRequestStatusIcon() {
        const statusKey = _.get(paymentRequest, 'status.key');

        switch (statusKey) {
            case 'AuthOnly':
            case 'PaidPartially':
            case 'Sent':
            case 'Unpaid':
                return {'info': true};

            case 'Cancelled':
            case 'Expired':
            case 'PaidInFull':
            case 'Refunded':
            case 'RefundedPartially':
            case 'Registered':
            case 'Saved':
                return {'tick': true};

            case 'OverDue':
            default:
                return {'alert': true};
        }
    }

    function canActionPaymentRequest() {
        const statusKey = _.get(paymentRequest, 'status.key');

        return ['Saved', 'Sent', 'Unpaid', 'OverDue'].indexOf(statusKey) >= 0;
    }

    function handleResend() {
        setShowResendConfirmModal(true);
    }

    function handleConfirmResend() {
        actions.resend(paymentRequest.guid)
            .then(() => {
                setShowResendConfirmModal(false);
                setShowResendPendingModal(true);
            });
    }

    function handleProcessingPaymentModalClosed() {
        setShowResendPendingModal(false);
        setReload(true);
    }

    function handleCancel() {
        setShowCancelConfirmModal(true);
    }

    function handleConfirmCancel() {
        actions.cancel(paymentRequest.guid)
            .then(() => {
                setShowCancelConfirmModal(false);
                setShowCancelSuccessModal(true);
            });
    }

    function handleCancelledSuccessClose() {
        setReload(true);
        setShowCancelSuccessModal(false);
    }

    return (<>
        <PageSection className='view-payment-request-page'>
            {isLoading && <LoadingIndicator />}

            {!isLoading && !paymentRequest && <>
                <BackButton to={PlatformRoutesConfiguration.paymentRequestRoute.manageRequests.generatePath()} />
                <IconText alert>Payment request not found or an error occurred.</IconText>
            </>}

            {paymentRequest && <>
                <PageHeader
                    backButton
                    title={<IconText {...getPaymentRequestStatusIcon()}>{getPaymentRequestStatusDescription()}</IconText>}
                    subtitle={<>
                        {paymentRequest.paymentRequestReference && <h2>{paymentRequest.paymentRequestReference}</h2>}
                        <DescriptionList greyLabel items={getPrefixDetails()} />
                    </>}
                >
                    {canActionPaymentRequest() && <>
                        <Button primary onClick={handleResend}>Resend request</Button>
                        <Button onClick={handleCancel}>Cancel request</Button>
                    </>}
                </PageHeader>

                {paymentRequest.customer &&
                    <CustomerDetails
                        customer={paymentRequest.customer}
                        hideEditButton
                        showIdentifiers
                    />
                }

                <PaddedContainer
                    className="paymentRequest-details"
                    icon={<Icon note />}
                    title='Request details'
                >
                    <DescriptionList twoColumn items={getPaymentRequestDetails()} />
                </PaddedContainer>
            </>}
        </PageSection>

        <Dialog
            show={showResendConfirmModal}
            closeButton
            icon={<Icon alert />}
            title='Resend outstanding request?'
            onClose={() => setShowResendConfirmModal(false)}
            footerButtons={<>
                <Button onClick={handleConfirmResend}>Resend</Button>
                <Button onClick={() => setShowResendConfirmModal(false)}>Close</Button>
            </>}
        />

        <Dialog
            show={showCancelConfirmModal}
            closeButton
            icon={<Icon alert />}
            title='Cancel request?'
            onClose={() => setShowCancelConfirmModal(false)}
            footerButtons={<>
                <Button onClick={handleConfirmCancel}>Confirm</Button>
                <Button onClick={() => setShowCancelConfirmModal(false)}>Back</Button>
            </>}
        >
            Please confirm if you want to cancel this request
            <FormError apiErrors={cancelErrors} />
        </Dialog>

        {showResendPendingModal &&
            <PaymentRequestPendingModal
                paymentRequestId={paymentRequest.guid}
                biller={biller}
                onClosed={handleProcessingPaymentModalClosed}
            />
        }

        {showCancelSuccessModal &&
            <SuccessModal
                show={showCancelSuccessModal}
                title='Request cancelled successfully'
                onClose={handleCancelledSuccessClose}
            />
        }
    </>);
}

function mapStateToProps(state, ownProps) {
    const paymentRequest = state.paymentRequest.details.data;
    const biller = paymentRequest
                   && paymentRequest.billerCode
                   && _.find(state.accounts.users.billers, {billerCode: paymentRequest.billerCode});

    return {
        paymentRequestId: ownProps.match.params.id,
        paymentRequest,
        isLoading: state.paymentRequest.details.isLoading,
        biller,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(paymentRequestActions, dispatch),
        billerActions: bindActionCreators(billerActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewPaymentRequestPage);