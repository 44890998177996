type ErrorMessageGenerator = (error: {label: string}) => string;

const errorMaps: {[key: string]: ErrorMessageGenerator} = {
    INVALID_PARAM: error => {
        return `${error.label} is invalid`;
    },
    MANDATORY_PARAM: error => {
        return `${error.label} is required`;
    },
    PARAM_MAY_NOT_CONTAIN_CARD_NUMBER: error => {
        return `${error.label} may not contain card number`;
    },
    PARAM_CONTAINS_ILLEGAL_CHARACTERS: error => {
        return `${error.label} contains illegal characters. Characters & ? , are not allowed`;
    },
    CARD_TYPE_NOT_ACCEPTED_BY_BILLER: error => {
        return `Type of card number supplied is not accepted by the biller`;
    },
    INVALID_TOKEN: error => {
        return `Invalid token number`;
    },
    PASSWORD_COMPLEXITY_NOT_MET: error => {
        return `Miniumum password complexity rules not met`;
    },
    PASSWORD_USED_HISTORICALLY: error => {
        return `Must not use one of the last 10 passwords`;
    },
    USERNAME_ALREADY_IN_USE: error => {
        return `The specified condition was not met for username.`;
    },
    DOMAIN_USERNAME_CANNOT_BE_CHANGED: error => {
        return `The specified condition was not met for username.`;
    },
    MISMATCHED_NEW_PASSWORD: error => {
        return `New passwords do not match`;
    },
    INCORRECT_PASSWORD_PROVIDED: error => {
        return `Incorrect password provided`;
    },
    INVALID_DATE_RANGE_END_BEFORE_START: error => {
        return `To date cannot be before from date`;
    },
    INVALID_CREDENTIALS: error => {
        return `Please enter a valid username and merchant number.`;
    },
    BILLER_INVALID_PARAM: error => {
        return `Invalid ${error.label} according to the biller rules`;
    },
    BILLER_EXPIRED_PARAM: error => {
        return `${error.label} is expired according to the biller rules`;
    },
    BILLER_INVALID_AMOUNT_HAS_FIXED_AMOUNT: error => {
        return `Amount does not match the required fixed amount`;
    },
    ACCOUNT_LOCKED: error => {
        return `Your account is locked. Please contact your administrator`;
    },
    INVALID_PARAM_TOO_LONG: error => {
        return `${error.label} exceeds maximum length`;
    },
};

export default errorMaps;