import { useState } from 'react';
import IsFormDirty from 'components/DataVault/components/InvoiceForms/IsFormDirty';
import { contextHasErrors } from 'components/Utilities/form/formUtils';
import {
    ButtonContainer,
    Button,
    Row,
    PageSection,
    FormGroup,
    Textarea,
} from '@premier/ui';
import {
    Form,
    SubmitButton,
    InputField,
    PhoneNumberField,
    DropdownField,
    FileUploadField,
} from '@premier/form';
import labels from 'platforms/base/constants/labels';
import countryUtil from '@premier/utils/country';
import _ from 'lodash';

import {
    businessDetailsCopy,
    BusinessDetailsModalState,
} from '../../pages/BusinessDetailsSettings/BusinessDetailsSettingsBody';
import './BusinessDetailsForm.scss';

export const fieldNames = {
    tradingName: 'tradingName',
    companyId1: 'companyId1', // ABN
    companyId2: 'companyId2', // ACN
    firstName: 'firstName',
    lastName: 'lastName',
    email: 'email',
    website: 'website',
    phoneNumber: 'phoneNumber',
    faxNumber: 'faxNumber',
    addressLine1: 'addressLine1',
    addressLine2: 'addressLine2',
    suburb: 'suburb',
    state: 'state',
    postcode: 'postcode',
    country: 'country',
    receiptMessage: 'receiptMessage',
    logo: 'logo',
};

const fieldValidation = {
    shortTextMaxLength: 100,
    longTextMaxLength: 250,
    suburbMaxLength: 50,
    abnMaxLength: 11,
    acnMaxLength: 9,
};

const nCharactersString = (n: number) => `${n} character${n === 1 ? '' : 's'}`;

type Props = {
    formName: string;
    validation: Object;
    initialValues: Object;
    countryCode: number;
    setModalState: (state: BusinessDetailsModalState) => void;
    handleCancel: () => void;
    handleSubmit: (values: any, ctx: any) => Promise<void>;
};

const BusinessDetailsForm: React.FC<Props> = ({
    formName,
    validation,
    initialValues,
    countryCode,
    setModalState,
    handleCancel,
    handleSubmit,
}) => {

    const [isDirty, setIsDirty] = useState(false);
    const [loading, setLoading] = useState(false);

    // States dropdown ---
    const states = countryUtil.getCountry(countryCode)
        ?.states;

    const onCountryChange = (countryValue: any, context: any) => {
        // If there's a list of states for the selected country, but the current value of the state in the context
        // does not correspond to a state in the list, then remove the state value
        const states = countryUtil.getCountry(countryValue)?.states;
        const stateValue = context.getValue(fieldNames.state);
        if (
            states?.length &&
            stateValue &&
            !states.find((s) => s.state === stateValue)
        ) {
            context.setFormValues({
                ...context.values,
                [fieldNames.state]: undefined,
            });
        }
    };

    const onCancel = () => {
        isDirty ? setModalState('UNSAVED_CHANGES') : handleCancel();
    };

    const render = (context: any) => {
        // receipt message ---
        const messageLength =
            context.getValue(fieldNames.receiptMessage)?.length || 0;
        const messageLengthRemaining =
            fieldValidation.longTextMaxLength - messageLength;

        // form context errors ---
        const formError = contextHasErrors(context) || messageLengthRemaining < 0;

        return (
            <>
                <IsFormDirty
                    initialValues={initialValues}
                    setDirty={setIsDirty}
                />
                <PageSection>
                    <h2>{businessDetailsCopy.businessIdentity}</h2>
                    <InputField
                        name={fieldNames.tradingName}
                        label={labels.tradingName}
                        validateOnChange
                    />
                    <InputField
                        //@ts-ignore
                        name={fieldNames.companyId1}
                        label={labels.companyId1}
                    />
                    <InputField
                        //@ts-ignore
                        name={fieldNames.companyId2}
                        label={labels.companyId2}
                    />
                </PageSection>

                <PageSection>
                    <h2>{businessDetailsCopy.businessBrand}</h2>
                    <FileUploadField
                        name={fieldNames.logo}
                        label={labels.logo}
                        //@ts-ignore
                        buttonText={
                            _.get(initialValues, 'logo.existingFile.name')
                                || _.get(context.values, 'logo.newFileUrl')
                                ? 'Reupload logo'
                                : 'Upload logo'
                        }
                        deleteButtonText={"Delete logo"}
                    />
                </PageSection>

                <PageSection>
                    <h2>{businessDetailsCopy.contactDetails}</h2>
                    <InputField
                        name={fieldNames.addressLine1}
                        label={labels.addressLine1}
                        validateOnChange
                    />
                    <InputField
                        name={fieldNames.addressLine2}
                        label={labels.addressLine2}
                        validateOnChange
                    />
                    <InputField
                        name={fieldNames.suburb}
                        label={labels.suburb}
                        validateOnChange
                    />
                    <Row lessGap>
                        <DropdownField
                            className='col-lg-6'
                            name={fieldNames.state}
                            label={labels.state}
                            onChange={onCountryChange}
                            {...{
                                options: states!.map((s) => ({
                                    value: s.state,
                                    label: s.state,
                                })),
                            }}
                        />
                        <InputField
                            className='col-lg-6'
                            name={fieldNames.postcode}
                            label={labels.postcode}
                            validateOnChange
                            //@ts-ignore
                            digitsOnly
                        />
                    </Row>
                    <PhoneNumberField
                        name={fieldNames.phoneNumber}
                        label={labels.phoneNumber}
                    />
                    <PhoneNumberField
                        name={fieldNames.faxNumber}
                        label={labels.faxNumber}
                    />
                    <InputField
                        name={fieldNames.website}
                        label={labels.website}
                        validateOnChange
                    />
                    <InputField name={fieldNames.email} label={labels.email} />
                </PageSection>

                <PageSection>
                    <h2>{businessDetailsCopy.receiptTitle}</h2>
                    <p>{businessDetailsCopy.receiptSubtitle}</p>

                    <FormGroup
                        fieldId={fieldNames.receiptMessage}
                        error={
                            messageLengthRemaining < 0
                                ? `${nCharactersString(
                                      -messageLengthRemaining
                                  )} exceeding limit`
                                : undefined
                        }
                    >
                        <Textarea
                            name={fieldNames.receiptMessage}
                            value={context.getValue(fieldNames.receiptMessage)}
                            error={messageLengthRemaining < 0}
                            onChange={(e) =>
                                context.setValue(
                                    fieldNames.receiptMessage,
                                    e.target.value
                                )
                            }
                        />
                        {messageLengthRemaining >= 0 && (
                            <div className='business-details-form__char-remaining'>
                                {nCharactersString(messageLengthRemaining)}{' '}
                                remaining
                            </div>
                        )}
                    </FormGroup>
                </PageSection>

                <ButtonContainer>
                    <SubmitButton loading={loading}>Save</SubmitButton>
                    <Button onClick={onCancel}>Cancel</Button>
                </ButtonContainer>
                {formError && (
                    <div className='business-details-form__submit-error'>
                        {businessDetailsCopy.pageError}
                    </div>
                )}
            </>
        );
    };

    return (
        <Form
            name={formName}
            initialValues={initialValues}
            initialValidation={validation}
            validateOnMount
            {...{ render, onSubmit: async (values: any, ctx: any) => {
                setLoading(true);
                await handleSubmit(values, ctx);
                setLoading(false);
            } }}
        />
    );
};

export default BusinessDetailsForm;
