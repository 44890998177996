import { useEffect, useState } from 'react';
import { ResponsiveTable, ErrorText, Button, SuccessModal, LoadingIndicator } from '@premier/ui';
import labels from 'constants/labels';
import { useDebounce } from 'components/Common';
import { MaskedCardNumberField, InputField, DropdownField } from '@premier/form';
import { PaymentPlanCancelDialog } from 'components/Transactions'
import { paymentPlanApi } from 'api';
import { PlatformRoutesConfiguration } from 'components/Routing';

import './PaymentPlanList.scss'

export const PaymentPlanListColumns = {
    STATUS: 1,
    REFERENCE: 2,
    OPTION_NAME: 3,
    MASKED_CARD_NUM: 4,
    ACCOUNT_NUM: 5,
};

const PaymentPlanList = ({
    crnLabel,
    paymentPlanFilter,
    optionNameList,
    onTotalRecords,
    onFilterChange,
    onReload,
    apiData, apiErrors,
    sort, onSort,
}) => {

    const [isCancelOrCompletedStatus, setIsCancelOrCompletedStatus] = useState(false);
    const [doCancel, setDoCancel] = useState(false);
    const [itemToCancel, setItemToCancel] = useState({});
    const [cancelSuccess, setCancelSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');

    const statusOptions = Object.freeze([
        {value:'', label:'All'},
        {value:'1', label:'Active'},
        {value:'2', label:'Cancelled'},
        {value:'3', label:'Completed'},
        {value:'4', label:'Pending'},
    ]);

    const handleQuickFilterChange = (field, val) => {
        onFilterChange(field, val);
    }

    const selectItemToCancel = (item) => {
        setIsCancelOrCompletedStatus(false);
        setError('');

        // If not active then refuse to cancel
        if (item[0].status !== statusOptions[1].label) {
            setIsCancelOrCompletedStatus(true);
            return;
        }

        setIsLoading(true);
        paymentPlanApi.getCustomerSummary({billerCode: item[0].billerCode, crn1: item[0].reference, paymentPlanId: item[0].paymentPlanId}).then(r => {
            setIsLoading(false);
            setDoCancel(true);
            setItemToCancel({
                paymentPlanId: r.data?.data?.paymentPlanId,
                billerCode: r.data?.data?.billerCode,
                crn1: r.data?.data?.crn1,
                originalAmount: r.data?.data?.originalAmount,
                paymentPlanOptionName: r.data?.data?.paymentPlanOptionName,
                totalPaid: r.data?.data?.totalPaid
            });
        }).catch(e => {
            if (e.response?.data?.errors) {
                setError(e.response?.data?.errors);
            }
            else {
                setError([{message: e.response?.statusText}]);
            }
            setIsLoading(false);
        });
    }

    const cancelItem = () => {

        setIsLoading(true);
        paymentPlanApi.cancelPaymentPlan({
            billerCode: itemToCancel.billerCode,
            crn1: itemToCancel.crn1,
            paymentPlanId: itemToCancel.paymentPlanId
        }).then(r => {
            setIsLoading(false);
            setCancelSuccess(true);
        }).catch(e => {
            setError(e.response?.data?.errors);
            setIsLoading(false);
        });
        setDoCancel(false);
    }

    const debouncedFilterChange = useDebounce(handleQuickFilterChange);

    const fields = {
        status: (
            <DropdownField
                name='status'
                className="status-property"
                options={statusOptions}
                onChange={val => handleQuickFilterChange(PaymentPlanListColumns.STATUS, {val})}
                compact={true}
                defaultValue={''}
                aria-label={labels.status}
            />
        ),
        reference1: (
            <InputField
                name='reference1'
                onChange={val => debouncedFilterChange(PaymentPlanListColumns.REFERENCE, {val})}
                aria-label={crnLabel}
            />
        ),
        optionName: (
            <DropdownField
                name='optionName'
                options={optionNameList}
                onChange={val => handleQuickFilterChange(PaymentPlanListColumns.OPTION_NAME, {val})}
                compact={true}
                defaultValue={''}
                aria-label={labels.status}
            />
        ),
        maskedCardNumber: (
            <MaskedCardNumberField
                name='maskedCardNumber'
                onChange={val => debouncedFilterChange(PaymentPlanListColumns.MASKED_CARD_NUM, {val})}
                aria-label={labels.maskedCardNumber}
            />
        ),
        deAccountNumber: (
            <InputField
                name='deAccountNumber'
                onChange={val => debouncedFilterChange(PaymentPlanListColumns.ACCOUNT_NUM, {val})}
                aria-label={labels.bankAccountNumber}
            />
        )
    };

    const columns = [{
        label: labels.status,
        getter: r => r.status,
        filter: fields.status,
        sortKey: 'Status',
        className: "status-property"
    }, {
        label: crnLabel ?? labels.reference1,
        getter:  r => <Button link>{r.reference}</Button>,
        filter: fields.reference1,
        sortKey: 'Reference'
    }, {
        label: labels.planOption,
        getter: r => r.paymentPlanOptionName,
        filter: fields.optionName,
        sortKey: 'PaymentPlanOptionName'
    }, {
        label: labels.maskedCardNumber,
        getter: r => r.maskedCardNumber,
        filter: fields.maskedCardNumber,
        sortKey: 'CardNumber'
    },{
        label: labels.bankAccountNumber,
        getter: r => r.accountNumber,
        filter: fields.deAccountNumber,
        sortKey: 'AccountNumber'
    }];

    const [paginationState, setPaginationState] = useState({
        currentPage: paymentPlanFilter.pageIndex+1,
        currentPagePlans: []
    });

    // Handles the pagination logic, assumes that data props will contain all plans for a biller code will be retrieved upon API call
    const onPageChanged = (newPage) => {
        setPaginationState({
            currentPage: newPage,
            currentPagePlans: apiData.list ?? [],
        });
    }

    const getRowLink = (r) => {
        const request = {
            billerCode: r.billerCode,
            paymentPlanId: r.paymentPlanId,
            crn1: r.reference,
            crn2: r.crn2 ?? "",
            crn3: r.crn3 ?? ""
        };
        return PlatformRoutesConfiguration.transactionRoute.paymentPlanDetails.generatePath(request);
    }

    // If new plans have been loaded, reset the pagination state
    useEffect(() => {
        setError('');
        setIsCancelOrCompletedStatus(false);
        if(apiData) {
            onPageChanged(1);
            onTotalRecords(apiData.totalCount);
        }
    }, [apiData]);

    if (isLoading)
        return (<LoadingIndicator />);

    return (<>

        <PaymentPlanCancelDialog
            show={doCancel}
            closeButton
            onClose={() => { setDoCancel(false);}}
            footerButtons={<>
                <Button onClick={() => cancelItem()}>Cancel plan</Button>
                <Button onClick={() => setDoCancel(false)}>Close</Button>
            </>}

            crnLabel={crnLabel}
            crn1={itemToCancel.crn1}
            originalTotalAmount={itemToCancel.originalAmount}
            paymentPlanOptionName={itemToCancel.paymentPlanOptionName}
            totalAmountPaid={itemToCancel.totalPaid}
        />

        <SuccessModal
            show={cancelSuccess}
            title={`Payment plan cancelled successfully!`}
            onClose={() => {
                onReload();
                setCancelSuccess(false); }}
        />

        {apiErrors ? apiErrors.map((error, i) => <ErrorText key={i}>{error.message}</ErrorText>) : null}
        {error ? error.map((e, i) => <ErrorText key={i}>{e.message}</ErrorText>) : null }
        <ResponsiveTable
            className='payment-plan-list'
            data={paginationState.currentPagePlans}
            selectable
            singleSelect
            isLoading={apiData === null}
            columns={columns}
            sort={sort}
            onSort={onSort}
            getRowLink={getRowLink}
            noDataText='No payment plans to display'
            batchActions={[
                {
                    label: 'Cancel',
                    batchLabel: 'Cancel selected',
                    tableButtonProps: {
                        subtle: true,
                        iconType: 'minus'
                    },
                    handleClick: items => {
                        selectItemToCancel(items);
                    }
                }
            ]}
            batchInfo={isCancelOrCompletedStatus ? <ErrorText>Please select a payment plan that is not completed or cancelled.</ErrorText> : null}
        />
    </>);
};

export default PaymentPlanList;
