import React from 'react';
import './AppContainer.scss';
import './AuthenticatedContainer.scss';

import { Menu } from '@premier/ui';

const AuthenticatedContainer = ({
    menuItems, children //data
}) => {

    return (
        <div className="menu-body-wrapper">
            <div className="nav-container">
                <Menu items={menuItems} isOpen nav/>
            </div>
            <main className="body-container">
                <div className="body-content">
                    {children}
                </div>
            </main>
        </div>
    );
}

export default AuthenticatedContainer;