// From https://github.com/unicode-org/cldr/blob/master/common/supplemental/windowsZones.xml

export default [
    {
        "windows": "Dateline Standard Time",
        "tz": "Etc/GMT+12"
    },
    {
        "windows": "Dateline Standard Time",
        "tz": "Etc/GMT+12"
    },
    {
        "windows": "UTC-11",
        "tz": "Etc/GMT+11"
    },
    {
        "windows": "UTC-11",
        "tz": "Pacific/Pago_Pago"
    },
    {
        "windows": "UTC-11",
        "tz": "Pacific/Niue"
    },
    {
        "windows": "UTC-11",
        "tz": "Pacific/Midway"
    },
    {
        "windows": "UTC-11",
        "tz": "Etc/GMT+11"
    },
    {
        "windows": "Aleutian Standard Time",
        "tz": "America/Adak"
    },
    {
        "windows": "Aleutian Standard Time",
        "tz": "America/Adak"
    },
    {
        "windows": "Hawaiian Standard Time",
        "tz": "Pacific/Honolulu"
    },
    {
        "windows": "Hawaiian Standard Time",
        "tz": "Pacific/Rarotonga"
    },
    {
        "windows": "Hawaiian Standard Time",
        "tz": "Pacific/Tahiti"
    },
    {
        "windows": "Hawaiian Standard Time",
        "tz": "Pacific/Johnston"
    },
    {
        "windows": "Hawaiian Standard Time",
        "tz": "Pacific/Honolulu"
    },
    {
        "windows": "Hawaiian Standard Time",
        "tz": "Etc/GMT+10"
    },
    {
        "windows": "Marquesas Standard Time",
        "tz": "Pacific/Marquesas"
    },
    {
        "windows": "Marquesas Standard Time",
        "tz": "Pacific/Marquesas"
    },
    {
        "windows": "Alaskan Standard Time",
        "tz": "America/Anchorage"
    },
    {
        "windows": "Alaskan Standard Time",
        "tz": "America/Anchorage America/Juneau America/Nome America/Sitka America/Yakutat"
    },
    {
        "windows": "UTC-09",
        "tz": "Etc/GMT+9"
    },
    {
        "windows": "UTC-09",
        "tz": "Pacific/Gambier"
    },
    {
        "windows": "UTC-09",
        "tz": "Etc/GMT+9"
    },
    {
        "windows": "Pacific Standard Time (Mexico)",
        "tz": "America/Tijuana"
    },
    {
        "windows": "Pacific Standard Time (Mexico)",
        "tz": "America/Tijuana America/Santa_Isabel"
    },
    {
        "windows": "UTC-08",
        "tz": "Etc/GMT+8"
    },
    {
        "windows": "UTC-08",
        "tz": "Pacific/Pitcairn"
    },
    {
        "windows": "UTC-08",
        "tz": "Etc/GMT+8"
    },
    {
        "windows": "Pacific Standard Time",
        "tz": "America/Los_Angeles"
    },
    {
        "windows": "Pacific Standard Time",
        "tz": "America/Vancouver America/Dawson America/Whitehorse"
    },
    {
        "windows": "Pacific Standard Time",
        "tz": "America/Los_Angeles America/Metlakatla"
    },
    {
        "windows": "Pacific Standard Time",
        "tz": "PST8PDT"
    },
    {
        "windows": "US Mountain Standard Time",
        "tz": "America/Phoenix"
    },
    {
        "windows": "US Mountain Standard Time",
        "tz": "America/Dawson_Creek America/Creston America/Fort_Nelson"
    },
    {
        "windows": "US Mountain Standard Time",
        "tz": "America/Hermosillo"
    },
    {
        "windows": "US Mountain Standard Time",
        "tz": "America/Phoenix"
    },
    {
        "windows": "US Mountain Standard Time",
        "tz": "Etc/GMT+7"
    },
    {
        "windows": "Mountain Standard Time (Mexico)",
        "tz": "America/Chihuahua"
    },
    {
        "windows": "Mountain Standard Time (Mexico)",
        "tz": "America/Chihuahua America/Mazatlan"
    },
    {
        "windows": "Mountain Standard Time",
        "tz": "America/Denver"
    },
    {
        "windows": "Mountain Standard Time",
        "tz": "America/Edmonton America/Cambridge_Bay America/Inuvik America/Yellowknife"
    },
    {
        "windows": "Mountain Standard Time",
        "tz": "America/Ojinaga"
    },
    {
        "windows": "Mountain Standard Time",
        "tz": "America/Denver America/Boise"
    },
    {
        "windows": "Mountain Standard Time",
        "tz": "MST7MDT"
    },
    {
        "windows": "Central America Standard Time",
        "tz": "America/Guatemala"
    },
    {
        "windows": "Central America Standard Time",
        "tz": "America/Belize"
    },
    {
        "windows": "Central America Standard Time",
        "tz": "America/Costa_Rica"
    },
    {
        "windows": "Central America Standard Time",
        "tz": "Pacific/Galapagos"
    },
    {
        "windows": "Central America Standard Time",
        "tz": "America/Guatemala"
    },
    {
        "windows": "Central America Standard Time",
        "tz": "America/Tegucigalpa"
    },
    {
        "windows": "Central America Standard Time",
        "tz": "America/Managua"
    },
    {
        "windows": "Central America Standard Time",
        "tz": "America/El_Salvador"
    },
    {
        "windows": "Central America Standard Time",
        "tz": "Etc/GMT+6"
    },
    {
        "windows": "Central Standard Time",
        "tz": "America/Chicago"
    },
    {
        "windows": "Central Standard Time",
        "tz": "America/Winnipeg America/Rainy_River America/Rankin_Inlet America/Resolute"
    },
    {
        "windows": "Central Standard Time",
        "tz": "America/Matamoros"
    },
    {
        "windows": "Central Standard Time",
        "tz": "America/Chicago America/Indiana/Knox America/Indiana/Tell_City America/Menominee America/North_Dakota/Beulah America/North_Dakota/Center America/North_Dakota/New_Salem"
    },
    {
        "windows": "Central Standard Time",
        "tz": "CST6CDT"
    },
    {
        "windows": "Easter Island Standard Time",
        "tz": "Pacific/Easter"
    },
    {
        "windows": "Easter Island Standard Time",
        "tz": "Pacific/Easter"
    },
    {
        "windows": "Central Standard Time (Mexico)",
        "tz": "America/Mexico_City"
    },
    {
        "windows": "Central Standard Time (Mexico)",
        "tz": "America/Mexico_City America/Bahia_Banderas America/Merida America/Monterrey"
    },
    {
        "windows": "Canada Central Standard Time",
        "tz": "America/Regina"
    },
    {
        "windows": "Canada Central Standard Time",
        "tz": "America/Regina America/Swift_Current"
    },
    {
        "windows": "SA Pacific Standard Time",
        "tz": "America/Bogota"
    },
    {
        "windows": "SA Pacific Standard Time",
        "tz": "America/Rio_Branco America/Eirunepe"
    },
    {
        "windows": "SA Pacific Standard Time",
        "tz": "America/Coral_Harbour"
    },
    {
        "windows": "SA Pacific Standard Time",
        "tz": "America/Bogota"
    },
    {
        "windows": "SA Pacific Standard Time",
        "tz": "America/Guayaquil"
    },
    {
        "windows": "SA Pacific Standard Time",
        "tz": "America/Jamaica"
    },
    {
        "windows": "SA Pacific Standard Time",
        "tz": "America/Cayman"
    },
    {
        "windows": "SA Pacific Standard Time",
        "tz": "America/Panama"
    },
    {
        "windows": "SA Pacific Standard Time",
        "tz": "America/Lima"
    },
    {
        "windows": "SA Pacific Standard Time",
        "tz": "Etc/GMT+5"
    },
    {
        "windows": "Eastern Standard Time (Mexico)",
        "tz": "America/Cancun"
    },
    {
        "windows": "Eastern Standard Time (Mexico)",
        "tz": "America/Cancun"
    },
    {
        "windows": "Eastern Standard Time",
        "tz": "America/New_York"
    },
    {
        "windows": "Eastern Standard Time",
        "tz": "America/Nassau"
    },
    {
        "windows": "Eastern Standard Time",
        "tz": "America/Toronto America/Iqaluit America/Montreal America/Nipigon America/Pangnirtung America/Thunder_Bay"
    },
    {
        "windows": "Eastern Standard Time",
        "tz": "America/New_York America/Detroit America/Indiana/Petersburg America/Indiana/Vincennes America/Indiana/Winamac America/Kentucky/Monticello America/Louisville"
    },
    {
        "windows": "Eastern Standard Time",
        "tz": "EST5EDT"
    },
    {
        "windows": "Haiti Standard Time",
        "tz": "America/Port-au-Prince"
    },
    {
        "windows": "Haiti Standard Time",
        "tz": "America/Port-au-Prince"
    },
    {
        "windows": "Cuba Standard Time",
        "tz": "America/Havana"
    },
    {
        "windows": "Cuba Standard Time",
        "tz": "America/Havana"
    },
    {
        "windows": "US Eastern Standard Time",
        "tz": "America/Indianapolis"
    },
    {
        "windows": "US Eastern Standard Time",
        "tz": "America/Indianapolis America/Indiana/Marengo America/Indiana/Vevay"
    },
    {
        "windows": "Paraguay Standard Time",
        "tz": "America/Asuncion"
    },
    {
        "windows": "Paraguay Standard Time",
        "tz": "America/Asuncion"
    },
    {
        "windows": "Atlantic Standard Time",
        "tz": "America/Halifax"
    },
    {
        "windows": "Atlantic Standard Time",
        "tz": "Atlantic/Bermuda"
    },
    {
        "windows": "Atlantic Standard Time",
        "tz": "America/Halifax America/Glace_Bay America/Goose_Bay America/Moncton"
    },
    {
        "windows": "Atlantic Standard Time",
        "tz": "America/Thule"
    },
    {
        "windows": "Venezuela Standard Time",
        "tz": "America/Caracas"
    },
    {
        "windows": "Venezuela Standard Time",
        "tz": "America/Caracas"
    },
    {
        "windows": "Central Brazilian Standard Time",
        "tz": "America/Cuiaba"
    },
    {
        "windows": "Central Brazilian Standard Time",
        "tz": "America/Cuiaba America/Campo_Grande"
    },
    {
        "windows": "SA Western Standard Time",
        "tz": "America/La_Paz"
    },
    {
        "windows": "SA Western Standard Time",
        "tz": "America/Antigua"
    },
    {
        "windows": "SA Western Standard Time",
        "tz": "America/Anguilla"
    },
    {
        "windows": "SA Western Standard Time",
        "tz": "America/Aruba"
    },
    {
        "windows": "SA Western Standard Time",
        "tz": "America/Barbados"
    },
    {
        "windows": "SA Western Standard Time",
        "tz": "America/St_Barthelemy"
    },
    {
        "windows": "SA Western Standard Time",
        "tz": "America/La_Paz"
    },
    {
        "windows": "SA Western Standard Time",
        "tz": "America/Kralendijk"
    },
    {
        "windows": "SA Western Standard Time",
        "tz": "America/Manaus America/Boa_Vista America/Porto_Velho"
    },
    {
        "windows": "SA Western Standard Time",
        "tz": "America/Blanc-Sablon"
    },
    {
        "windows": "SA Western Standard Time",
        "tz": "America/Curacao"
    },
    {
        "windows": "SA Western Standard Time",
        "tz": "America/Dominica"
    },
    {
        "windows": "SA Western Standard Time",
        "tz": "America/Santo_Domingo"
    },
    {
        "windows": "SA Western Standard Time",
        "tz": "America/Grenada"
    },
    {
        "windows": "SA Western Standard Time",
        "tz": "America/Guadeloupe"
    },
    {
        "windows": "SA Western Standard Time",
        "tz": "America/Guyana"
    },
    {
        "windows": "SA Western Standard Time",
        "tz": "America/St_Kitts"
    },
    {
        "windows": "SA Western Standard Time",
        "tz": "America/St_Lucia"
    },
    {
        "windows": "SA Western Standard Time",
        "tz": "America/Marigot"
    },
    {
        "windows": "SA Western Standard Time",
        "tz": "America/Martinique"
    },
    {
        "windows": "SA Western Standard Time",
        "tz": "America/Montserrat"
    },
    {
        "windows": "SA Western Standard Time",
        "tz": "America/Puerto_Rico"
    },
    {
        "windows": "SA Western Standard Time",
        "tz": "America/Lower_Princes"
    },
    {
        "windows": "SA Western Standard Time",
        "tz": "America/Port_of_Spain"
    },
    {
        "windows": "SA Western Standard Time",
        "tz": "America/St_Vincent"
    },
    {
        "windows": "SA Western Standard Time",
        "tz": "America/Tortola"
    },
    {
        "windows": "SA Western Standard Time",
        "tz": "America/St_Thomas"
    },
    {
        "windows": "SA Western Standard Time",
        "tz": "Etc/GMT+4"
    },
    {
        "windows": "Pacific SA Standard Time",
        "tz": "America/Santiago"
    },
    {
        "windows": "Pacific SA Standard Time",
        "tz": "America/Santiago"
    },
    {
        "windows": "Turks And Caicos Standard Time",
        "tz": "America/Grand_Turk"
    },
    {
        "windows": "Turks And Caicos Standard Time",
        "tz": "America/Grand_Turk"
    },
    {
        "windows": "Newfoundland Standard Time",
        "tz": "America/St_Johns"
    },
    {
        "windows": "Newfoundland Standard Time",
        "tz": "America/St_Johns"
    },
    {
        "windows": "Tocantins Standard Time",
        "tz": "America/Araguaina"
    },
    {
        "windows": "Tocantins Standard Time",
        "tz": "America/Araguaina"
    },
    {
        "windows": "E. South America Standard Time",
        "tz": "America/Sao_Paulo"
    },
    {
        "windows": "E. South America Standard Time",
        "tz": "America/Sao_Paulo"
    },
    {
        "windows": "SA Eastern Standard Time",
        "tz": "America/Cayenne"
    },
    {
        "windows": "SA Eastern Standard Time",
        "tz": "Antarctica/Rothera"
    },
    {
        "windows": "SA Eastern Standard Time",
        "tz": "America/Fortaleza America/Belem America/Maceio America/Recife America/Santarem"
    },
    {
        "windows": "SA Eastern Standard Time",
        "tz": "Atlantic/Stanley"
    },
    {
        "windows": "SA Eastern Standard Time",
        "tz": "America/Cayenne"
    },
    {
        "windows": "SA Eastern Standard Time",
        "tz": "America/Paramaribo"
    },
    {
        "windows": "SA Eastern Standard Time",
        "tz": "Etc/GMT+3"
    },
    {
        "windows": "Argentina Standard Time",
        "tz": "America/Buenos_Aires"
    },
    {
        "windows": "Argentina Standard Time",
        "tz": "America/Buenos_Aires America/Argentina/La_Rioja America/Argentina/Rio_Gallegos America/Argentina/Salta America/Argentina/San_Juan America/Argentina/San_Luis America/Argentina/Tucuman America/Argentina/Ushuaia America/Catamarca America/Cordoba America/Jujuy America/Mendoza"
    },
    {
        "windows": "Greenland Standard Time",
        "tz": "America/Godthab"
    },
    {
        "windows": "Greenland Standard Time",
        "tz": "America/Godthab"
    },
    {
        "windows": "Montevideo Standard Time",
        "tz": "America/Montevideo"
    },
    {
        "windows": "Montevideo Standard Time",
        "tz": "America/Montevideo"
    },
    {
        "windows": "Magallanes Standard Time",
        "tz": "America/Punta_Arenas"
    },
    {
        "windows": "Magallanes Standard Time",
        "tz": "Antarctica/Palmer"
    },
    {
        "windows": "Magallanes Standard Time",
        "tz": "America/Punta_Arenas"
    },
    {
        "windows": "Saint Pierre Standard Time",
        "tz": "America/Miquelon"
    },
    {
        "windows": "Saint Pierre Standard Time",
        "tz": "America/Miquelon"
    },
    {
        "windows": "Bahia Standard Time",
        "tz": "America/Bahia"
    },
    {
        "windows": "Bahia Standard Time",
        "tz": "America/Bahia"
    },
    {
        "windows": "UTC-02",
        "tz": "Etc/GMT+2"
    },
    {
        "windows": "UTC-02",
        "tz": "America/Noronha"
    },
    {
        "windows": "UTC-02",
        "tz": "Atlantic/South_Georgia"
    },
    {
        "windows": "UTC-02",
        "tz": "Etc/GMT+2"
    },
    {
        "windows": "Azores Standard Time",
        "tz": "Atlantic/Azores"
    },
    {
        "windows": "Azores Standard Time",
        "tz": "America/Scoresbysund"
    },
    {
        "windows": "Azores Standard Time",
        "tz": "Atlantic/Azores"
    },
    {
        "windows": "Cape Verde Standard Time",
        "tz": "Atlantic/Cape_Verde"
    },
    {
        "windows": "Cape Verde Standard Time",
        "tz": "Atlantic/Cape_Verde"
    },
    {
        "windows": "Cape Verde Standard Time",
        "tz": "Etc/GMT+1"
    },
    {
        "windows": "UTC",
        "tz": "Etc/GMT"
    },
    {
        "windows": "UTC",
        "tz": "America/Danmarkshavn"
    },
    {
        "windows": "UTC",
        "tz": "Etc/GMT Etc/UTC"
    },
    {
        "windows": "GMT Standard Time",
        "tz": "Europe/London"
    },
    {
        "windows": "GMT Standard Time",
        "tz": "Atlantic/Canary"
    },
    {
        "windows": "GMT Standard Time",
        "tz": "Atlantic/Faeroe"
    },
    {
        "windows": "GMT Standard Time",
        "tz": "Europe/London"
    },
    {
        "windows": "GMT Standard Time",
        "tz": "Europe/Guernsey"
    },
    {
        "windows": "GMT Standard Time",
        "tz": "Europe/Dublin"
    },
    {
        "windows": "GMT Standard Time",
        "tz": "Europe/Isle_of_Man"
    },
    {
        "windows": "GMT Standard Time",
        "tz": "Europe/Jersey"
    },
    {
        "windows": "GMT Standard Time",
        "tz": "Europe/Lisbon Atlantic/Madeira"
    },
    {
        "windows": "Greenwich Standard Time",
        "tz": "Atlantic/Reykjavik"
    },
    {
        "windows": "Greenwich Standard Time",
        "tz": "Africa/Ouagadougou"
    },
    {
        "windows": "Greenwich Standard Time",
        "tz": "Africa/Abidjan"
    },
    {
        "windows": "Greenwich Standard Time",
        "tz": "Africa/Accra"
    },
    {
        "windows": "Greenwich Standard Time",
        "tz": "Africa/Banjul"
    },
    {
        "windows": "Greenwich Standard Time",
        "tz": "Africa/Conakry"
    },
    {
        "windows": "Greenwich Standard Time",
        "tz": "Africa/Bissau"
    },
    {
        "windows": "Greenwich Standard Time",
        "tz": "Atlantic/Reykjavik"
    },
    {
        "windows": "Greenwich Standard Time",
        "tz": "Africa/Monrovia"
    },
    {
        "windows": "Greenwich Standard Time",
        "tz": "Africa/Bamako"
    },
    {
        "windows": "Greenwich Standard Time",
        "tz": "Africa/Nouakchott"
    },
    {
        "windows": "Greenwich Standard Time",
        "tz": "Atlantic/St_Helena"
    },
    {
        "windows": "Greenwich Standard Time",
        "tz": "Africa/Freetown"
    },
    {
        "windows": "Greenwich Standard Time",
        "tz": "Africa/Dakar"
    },
    {
        "windows": "Greenwich Standard Time",
        "tz": "Africa/Lome"
    },
    {
        "windows": "W. Europe Standard Time",
        "tz": "Europe/Berlin"
    },
    {
        "windows": "W. Europe Standard Time",
        "tz": "Europe/Andorra"
    },
    {
        "windows": "W. Europe Standard Time",
        "tz": "Europe/Vienna"
    },
    {
        "windows": "W. Europe Standard Time",
        "tz": "Europe/Zurich"
    },
    {
        "windows": "W. Europe Standard Time",
        "tz": "Europe/Berlin Europe/Busingen"
    },
    {
        "windows": "W. Europe Standard Time",
        "tz": "Europe/Gibraltar"
    },
    {
        "windows": "W. Europe Standard Time",
        "tz": "Europe/Rome"
    },
    {
        "windows": "W. Europe Standard Time",
        "tz": "Europe/Vaduz"
    },
    {
        "windows": "W. Europe Standard Time",
        "tz": "Europe/Luxembourg"
    },
    {
        "windows": "W. Europe Standard Time",
        "tz": "Europe/Monaco"
    },
    {
        "windows": "W. Europe Standard Time",
        "tz": "Europe/Malta"
    },
    {
        "windows": "W. Europe Standard Time",
        "tz": "Europe/Amsterdam"
    },
    {
        "windows": "W. Europe Standard Time",
        "tz": "Europe/Oslo"
    },
    {
        "windows": "W. Europe Standard Time",
        "tz": "Europe/Stockholm"
    },
    {
        "windows": "W. Europe Standard Time",
        "tz": "Arctic/Longyearbyen"
    },
    {
        "windows": "W. Europe Standard Time",
        "tz": "Europe/San_Marino"
    },
    {
        "windows": "W. Europe Standard Time",
        "tz": "Europe/Vatican"
    },
    {
        "windows": "Central Europe Standard Time",
        "tz": "Europe/Budapest"
    },
    {
        "windows": "Central Europe Standard Time",
        "tz": "Europe/Tirane"
    },
    {
        "windows": "Central Europe Standard Time",
        "tz": "Europe/Prague"
    },
    {
        "windows": "Central Europe Standard Time",
        "tz": "Europe/Budapest"
    },
    {
        "windows": "Central Europe Standard Time",
        "tz": "Europe/Podgorica"
    },
    {
        "windows": "Central Europe Standard Time",
        "tz": "Europe/Belgrade"
    },
    {
        "windows": "Central Europe Standard Time",
        "tz": "Europe/Ljubljana"
    },
    {
        "windows": "Central Europe Standard Time",
        "tz": "Europe/Bratislava"
    },
    {
        "windows": "Romance Standard Time",
        "tz": "Europe/Paris"
    },
    {
        "windows": "Romance Standard Time",
        "tz": "Europe/Brussels"
    },
    {
        "windows": "Romance Standard Time",
        "tz": "Europe/Copenhagen"
    },
    {
        "windows": "Romance Standard Time",
        "tz": "Europe/Madrid Africa/Ceuta"
    },
    {
        "windows": "Romance Standard Time",
        "tz": "Europe/Paris"
    },
    {
        "windows": "Morocco Standard Time",
        "tz": "Africa/Casablanca"
    },
    {
        "windows": "Morocco Standard Time",
        "tz": "Africa/El_Aaiun"
    },
    {
        "windows": "Morocco Standard Time",
        "tz": "Africa/Casablanca"
    },
    {
        "windows": "Sao Tome Standard Time",
        "tz": "Africa/Sao_Tome"
    },
    {
        "windows": "Sao Tome Standard Time",
        "tz": "Africa/Sao_Tome"
    },
    {
        "windows": "Central European Standard Time",
        "tz": "Europe/Warsaw"
    },
    {
        "windows": "Central European Standard Time",
        "tz": "Europe/Sarajevo"
    },
    {
        "windows": "Central European Standard Time",
        "tz": "Europe/Zagreb"
    },
    {
        "windows": "Central European Standard Time",
        "tz": "Europe/Skopje"
    },
    {
        "windows": "Central European Standard Time",
        "tz": "Europe/Warsaw"
    },
    {
        "windows": "W. Central Africa Standard Time",
        "tz": "Africa/Lagos"
    },
    {
        "windows": "W. Central Africa Standard Time",
        "tz": "Africa/Luanda"
    },
    {
        "windows": "W. Central Africa Standard Time",
        "tz": "Africa/Porto-Novo"
    },
    {
        "windows": "W. Central Africa Standard Time",
        "tz": "Africa/Kinshasa"
    },
    {
        "windows": "W. Central Africa Standard Time",
        "tz": "Africa/Bangui"
    },
    {
        "windows": "W. Central Africa Standard Time",
        "tz": "Africa/Brazzaville"
    },
    {
        "windows": "W. Central Africa Standard Time",
        "tz": "Africa/Douala"
    },
    {
        "windows": "W. Central Africa Standard Time",
        "tz": "Africa/Algiers"
    },
    {
        "windows": "W. Central Africa Standard Time",
        "tz": "Africa/Libreville"
    },
    {
        "windows": "W. Central Africa Standard Time",
        "tz": "Africa/Malabo"
    },
    {
        "windows": "W. Central Africa Standard Time",
        "tz": "Africa/Niamey"
    },
    {
        "windows": "W. Central Africa Standard Time",
        "tz": "Africa/Lagos"
    },
    {
        "windows": "W. Central Africa Standard Time",
        "tz": "Africa/Ndjamena"
    },
    {
        "windows": "W. Central Africa Standard Time",
        "tz": "Africa/Tunis"
    },
    {
        "windows": "W. Central Africa Standard Time",
        "tz": "Etc/GMT-1"
    },
    {
        "windows": "Jordan Standard Time",
        "tz": "Asia/Amman"
    },
    {
        "windows": "Jordan Standard Time",
        "tz": "Asia/Amman"
    },
    {
        "windows": "GTB Standard Time",
        "tz": "Europe/Bucharest"
    },
    {
        "windows": "GTB Standard Time",
        "tz": "Asia/Famagusta Asia/Nicosia"
    },
    {
        "windows": "GTB Standard Time",
        "tz": "Europe/Athens"
    },
    {
        "windows": "GTB Standard Time",
        "tz": "Europe/Bucharest"
    },
    {
        "windows": "Middle East Standard Time",
        "tz": "Asia/Beirut"
    },
    {
        "windows": "Middle East Standard Time",
        "tz": "Asia/Beirut"
    },
    {
        "windows": "Egypt Standard Time",
        "tz": "Africa/Cairo"
    },
    {
        "windows": "Egypt Standard Time",
        "tz": "Africa/Cairo"
    },
    {
        "windows": "E. Europe Standard Time",
        "tz": "Europe/Chisinau"
    },
    {
        "windows": "E. Europe Standard Time",
        "tz": "Europe/Chisinau"
    },
    {
        "windows": "Syria Standard Time",
        "tz": "Asia/Damascus"
    },
    {
        "windows": "Syria Standard Time",
        "tz": "Asia/Damascus"
    },
    {
        "windows": "West Bank Standard Time",
        "tz": "Asia/Hebron"
    },
    {
        "windows": "West Bank Standard Time",
        "tz": "Asia/Hebron Asia/Gaza"
    },
    {
        "windows": "South Africa Standard Time",
        "tz": "Africa/Johannesburg"
    },
    {
        "windows": "South Africa Standard Time",
        "tz": "Africa/Bujumbura"
    },
    {
        "windows": "South Africa Standard Time",
        "tz": "Africa/Gaborone"
    },
    {
        "windows": "South Africa Standard Time",
        "tz": "Africa/Lubumbashi"
    },
    {
        "windows": "South Africa Standard Time",
        "tz": "Africa/Maseru"
    },
    {
        "windows": "South Africa Standard Time",
        "tz": "Africa/Blantyre"
    },
    {
        "windows": "South Africa Standard Time",
        "tz": "Africa/Maputo"
    },
    {
        "windows": "South Africa Standard Time",
        "tz": "Africa/Kigali"
    },
    {
        "windows": "South Africa Standard Time",
        "tz": "Africa/Mbabane"
    },
    {
        "windows": "South Africa Standard Time",
        "tz": "Africa/Johannesburg"
    },
    {
        "windows": "South Africa Standard Time",
        "tz": "Africa/Lusaka"
    },
    {
        "windows": "South Africa Standard Time",
        "tz": "Africa/Harare"
    },
    {
        "windows": "South Africa Standard Time",
        "tz": "Etc/GMT-2"
    },
    {
        "windows": "FLE Standard Time",
        "tz": "Europe/Kiev"
    },
    {
        "windows": "FLE Standard Time",
        "tz": "Europe/Mariehamn"
    },
    {
        "windows": "FLE Standard Time",
        "tz": "Europe/Sofia"
    },
    {
        "windows": "FLE Standard Time",
        "tz": "Europe/Tallinn"
    },
    {
        "windows": "FLE Standard Time",
        "tz": "Europe/Helsinki"
    },
    {
        "windows": "FLE Standard Time",
        "tz": "Europe/Vilnius"
    },
    {
        "windows": "FLE Standard Time",
        "tz": "Europe/Riga"
    },
    {
        "windows": "FLE Standard Time",
        "tz": "Europe/Kiev Europe/Uzhgorod Europe/Zaporozhye"
    },
    {
        "windows": "Israel Standard Time",
        "tz": "Asia/Jerusalem"
    },
    {
        "windows": "Israel Standard Time",
        "tz": "Asia/Jerusalem"
    },
    {
        "windows": "Kaliningrad Standard Time",
        "tz": "Europe/Kaliningrad"
    },
    {
        "windows": "Kaliningrad Standard Time",
        "tz": "Europe/Kaliningrad"
    },
    {
        "windows": "Sudan Standard Time",
        "tz": "Africa/Khartoum"
    },
    {
        "windows": "Sudan Standard Time",
        "tz": "Africa/Khartoum"
    },
    {
        "windows": "Libya Standard Time",
        "tz": "Africa/Tripoli"
    },
    {
        "windows": "Libya Standard Time",
        "tz": "Africa/Tripoli"
    },
    {
        "windows": "Namibia Standard Time",
        "tz": "Africa/Windhoek"
    },
    {
        "windows": "Namibia Standard Time",
        "tz": "Africa/Windhoek"
    },
    {
        "windows": "Arabic Standard Time",
        "tz": "Asia/Baghdad"
    },
    {
        "windows": "Arabic Standard Time",
        "tz": "Asia/Baghdad"
    },
    {
        "windows": "Turkey Standard Time",
        "tz": "Europe/Istanbul"
    },
    {
        "windows": "Turkey Standard Time",
        "tz": "Europe/Istanbul"
    },
    {
        "windows": "Arab Standard Time",
        "tz": "Asia/Riyadh"
    },
    {
        "windows": "Arab Standard Time",
        "tz": "Asia/Bahrain"
    },
    {
        "windows": "Arab Standard Time",
        "tz": "Asia/Kuwait"
    },
    {
        "windows": "Arab Standard Time",
        "tz": "Asia/Qatar"
    },
    {
        "windows": "Arab Standard Time",
        "tz": "Asia/Riyadh"
    },
    {
        "windows": "Arab Standard Time",
        "tz": "Asia/Aden"
    },
    {
        "windows": "Belarus Standard Time",
        "tz": "Europe/Minsk"
    },
    {
        "windows": "Belarus Standard Time",
        "tz": "Europe/Minsk"
    },
    {
        "windows": "Russian Standard Time",
        "tz": "Europe/Moscow"
    },
    {
        "windows": "Russian Standard Time",
        "tz": "Europe/Moscow Europe/Kirov Europe/Volgograd"
    },
    {
        "windows": "Russian Standard Time",
        "tz": "Europe/Simferopol"
    },
    {
        "windows": "E. Africa Standard Time",
        "tz": "Africa/Nairobi"
    },
    {
        "windows": "E. Africa Standard Time",
        "tz": "Antarctica/Syowa"
    },
    {
        "windows": "E. Africa Standard Time",
        "tz": "Africa/Djibouti"
    },
    {
        "windows": "E. Africa Standard Time",
        "tz": "Africa/Asmera"
    },
    {
        "windows": "E. Africa Standard Time",
        "tz": "Africa/Addis_Ababa"
    },
    {
        "windows": "E. Africa Standard Time",
        "tz": "Africa/Nairobi"
    },
    {
        "windows": "E. Africa Standard Time",
        "tz": "Indian/Comoro"
    },
    {
        "windows": "E. Africa Standard Time",
        "tz": "Indian/Antananarivo"
    },
    {
        "windows": "E. Africa Standard Time",
        "tz": "Africa/Mogadishu"
    },
    {
        "windows": "E. Africa Standard Time",
        "tz": "Africa/Juba"
    },
    {
        "windows": "E. Africa Standard Time",
        "tz": "Africa/Dar_es_Salaam"
    },
    {
        "windows": "E. Africa Standard Time",
        "tz": "Africa/Kampala"
    },
    {
        "windows": "E. Africa Standard Time",
        "tz": "Indian/Mayotte"
    },
    {
        "windows": "E. Africa Standard Time",
        "tz": "Etc/GMT-3"
    },
    {
        "windows": "Iran Standard Time",
        "tz": "Asia/Tehran"
    },
    {
        "windows": "Iran Standard Time",
        "tz": "Asia/Tehran"
    },
    {
        "windows": "Arabian Standard Time",
        "tz": "Asia/Dubai"
    },
    {
        "windows": "Arabian Standard Time",
        "tz": "Asia/Dubai"
    },
    {
        "windows": "Arabian Standard Time",
        "tz": "Asia/Muscat"
    },
    {
        "windows": "Arabian Standard Time",
        "tz": "Etc/GMT-4"
    },
    {
        "windows": "Astrakhan Standard Time",
        "tz": "Europe/Astrakhan"
    },
    {
        "windows": "Astrakhan Standard Time",
        "tz": "Europe/Astrakhan Europe/Ulyanovsk"
    },
    {
        "windows": "Azerbaijan Standard Time",
        "tz": "Asia/Baku"
    },
    {
        "windows": "Azerbaijan Standard Time",
        "tz": "Asia/Baku"
    },
    {
        "windows": "Russia Time Zone 3",
        "tz": "Europe/Samara"
    },
    {
        "windows": "Russia Time Zone 3",
        "tz": "Europe/Samara"
    },
    {
        "windows": "Mauritius Standard Time",
        "tz": "Indian/Mauritius"
    },
    {
        "windows": "Mauritius Standard Time",
        "tz": "Indian/Mauritius"
    },
    {
        "windows": "Mauritius Standard Time",
        "tz": "Indian/Reunion"
    },
    {
        "windows": "Mauritius Standard Time",
        "tz": "Indian/Mahe"
    },
    {
        "windows": "Saratov Standard Time",
        "tz": "Europe/Saratov"
    },
    {
        "windows": "Saratov Standard Time",
        "tz": "Europe/Saratov"
    },
    {
        "windows": "Georgian Standard Time",
        "tz": "Asia/Tbilisi"
    },
    {
        "windows": "Georgian Standard Time",
        "tz": "Asia/Tbilisi"
    },
    {
        "windows": "Caucasus Standard Time",
        "tz": "Asia/Yerevan"
    },
    {
        "windows": "Caucasus Standard Time",
        "tz": "Asia/Yerevan"
    },
    {
        "windows": "Afghanistan Standard Time",
        "tz": "Asia/Kabul"
    },
    {
        "windows": "Afghanistan Standard Time",
        "tz": "Asia/Kabul"
    },
    {
        "windows": "West Asia Standard Time",
        "tz": "Asia/Tashkent"
    },
    {
        "windows": "West Asia Standard Time",
        "tz": "Antarctica/Mawson"
    },
    {
        "windows": "West Asia Standard Time",
        "tz": "Asia/Oral Asia/Aqtau Asia/Aqtobe Asia/Atyrau Asia/Qyzylorda"
    },
    {
        "windows": "West Asia Standard Time",
        "tz": "Indian/Maldives"
    },
    {
        "windows": "West Asia Standard Time",
        "tz": "Indian/Kerguelen"
    },
    {
        "windows": "West Asia Standard Time",
        "tz": "Asia/Dushanbe"
    },
    {
        "windows": "West Asia Standard Time",
        "tz": "Asia/Ashgabat"
    },
    {
        "windows": "West Asia Standard Time",
        "tz": "Asia/Tashkent Asia/Samarkand"
    },
    {
        "windows": "West Asia Standard Time",
        "tz": "Etc/GMT-5"
    },
    {
        "windows": "Ekaterinburg Standard Time",
        "tz": "Asia/Yekaterinburg"
    },
    {
        "windows": "Ekaterinburg Standard Time",
        "tz": "Asia/Yekaterinburg"
    },
    {
        "windows": "Pakistan Standard Time",
        "tz": "Asia/Karachi"
    },
    {
        "windows": "Pakistan Standard Time",
        "tz": "Asia/Karachi"
    },
    {
        "windows": "India Standard Time",
        "tz": "Asia/Calcutta"
    },
    {
        "windows": "India Standard Time",
        "tz": "Asia/Calcutta"
    },
    {
        "windows": "Sri Lanka Standard Time",
        "tz": "Asia/Colombo"
    },
    {
        "windows": "Sri Lanka Standard Time",
        "tz": "Asia/Colombo"
    },
    {
        "windows": "Nepal Standard Time",
        "tz": "Asia/Katmandu"
    },
    {
        "windows": "Nepal Standard Time",
        "tz": "Asia/Katmandu"
    },
    {
        "windows": "Central Asia Standard Time",
        "tz": "Asia/Almaty"
    },
    {
        "windows": "Central Asia Standard Time",
        "tz": "Antarctica/Vostok"
    },
    {
        "windows": "Central Asia Standard Time",
        "tz": "Asia/Urumqi"
    },
    {
        "windows": "Central Asia Standard Time",
        "tz": "Indian/Chagos"
    },
    {
        "windows": "Central Asia Standard Time",
        "tz": "Asia/Bishkek"
    },
    {
        "windows": "Central Asia Standard Time",
        "tz": "Asia/Almaty Asia/Qostanay"
    },
    {
        "windows": "Central Asia Standard Time",
        "tz": "Etc/GMT-6"
    },
    {
        "windows": "Bangladesh Standard Time",
        "tz": "Asia/Dhaka"
    },
    {
        "windows": "Bangladesh Standard Time",
        "tz": "Asia/Dhaka"
    },
    {
        "windows": "Bangladesh Standard Time",
        "tz": "Asia/Thimphu"
    },
    {
        "windows": "Omsk Standard Time",
        "tz": "Asia/Omsk"
    },
    {
        "windows": "Omsk Standard Time",
        "tz": "Asia/Omsk"
    },
    {
        "windows": "Myanmar Standard Time",
        "tz": "Asia/Rangoon"
    },
    {
        "windows": "Myanmar Standard Time",
        "tz": "Indian/Cocos"
    },
    {
        "windows": "Myanmar Standard Time",
        "tz": "Asia/Rangoon"
    },
    {
        "windows": "SE Asia Standard Time",
        "tz": "Asia/Bangkok"
    },
    {
        "windows": "SE Asia Standard Time",
        "tz": "Antarctica/Davis"
    },
    {
        "windows": "SE Asia Standard Time",
        "tz": "Indian/Christmas"
    },
    {
        "windows": "SE Asia Standard Time",
        "tz": "Asia/Jakarta Asia/Pontianak"
    },
    {
        "windows": "SE Asia Standard Time",
        "tz": "Asia/Phnom_Penh"
    },
    {
        "windows": "SE Asia Standard Time",
        "tz": "Asia/Vientiane"
    },
    {
        "windows": "SE Asia Standard Time",
        "tz": "Asia/Bangkok"
    },
    {
        "windows": "SE Asia Standard Time",
        "tz": "Asia/Saigon"
    },
    {
        "windows": "SE Asia Standard Time",
        "tz": "Etc/GMT-7"
    },
    {
        "windows": "Altai Standard Time",
        "tz": "Asia/Barnaul"
    },
    {
        "windows": "Altai Standard Time",
        "tz": "Asia/Barnaul"
    },
    {
        "windows": "W. Mongolia Standard Time",
        "tz": "Asia/Hovd"
    },
    {
        "windows": "W. Mongolia Standard Time",
        "tz": "Asia/Hovd"
    },
    {
        "windows": "North Asia Standard Time",
        "tz": "Asia/Krasnoyarsk"
    },
    {
        "windows": "North Asia Standard Time",
        "tz": "Asia/Krasnoyarsk Asia/Novokuznetsk"
    },
    {
        "windows": "N. Central Asia Standard Time",
        "tz": "Asia/Novosibirsk"
    },
    {
        "windows": "N. Central Asia Standard Time",
        "tz": "Asia/Novosibirsk"
    },
    {
        "windows": "Tomsk Standard Time",
        "tz": "Asia/Tomsk"
    },
    {
        "windows": "Tomsk Standard Time",
        "tz": "Asia/Tomsk"
    },
    {
        "windows": "China Standard Time",
        "tz": "Asia/Shanghai"
    },
    {
        "windows": "China Standard Time",
        "tz": "Asia/Shanghai"
    },
    {
        "windows": "China Standard Time",
        "tz": "Asia/Hong_Kong"
    },
    {
        "windows": "China Standard Time",
        "tz": "Asia/Macau"
    },
    {
        "windows": "North Asia East Standard Time",
        "tz": "Asia/Irkutsk"
    },
    {
        "windows": "North Asia East Standard Time",
        "tz": "Asia/Irkutsk"
    },
    {
        "windows": "Singapore Standard Time",
        "tz": "Asia/Singapore"
    },
    {
        "windows": "Singapore Standard Time",
        "tz": "Asia/Brunei"
    },
    {
        "windows": "Singapore Standard Time",
        "tz": "Asia/Makassar"
    },
    {
        "windows": "Singapore Standard Time",
        "tz": "Asia/Kuala_Lumpur Asia/Kuching"
    },
    {
        "windows": "Singapore Standard Time",
        "tz": "Asia/Manila"
    },
    {
        "windows": "Singapore Standard Time",
        "tz": "Asia/Singapore"
    },
    {
        "windows": "Singapore Standard Time",
        "tz": "Etc/GMT-8"
    },
    {
        "windows": "W. Australia Standard Time",
        "tz": "Australia/Perth"
    },
    {
        "windows": "W. Australia Standard Time",
        "tz": "Antarctica/Casey"
    },
    {
        "windows": "W. Australia Standard Time",
        "tz": "Australia/Perth"
    },
    {
        "windows": "Taipei Standard Time",
        "tz": "Asia/Taipei"
    },
    {
        "windows": "Taipei Standard Time",
        "tz": "Asia/Taipei"
    },
    {
        "windows": "Ulaanbaatar Standard Time",
        "tz": "Asia/Ulaanbaatar"
    },
    {
        "windows": "Ulaanbaatar Standard Time",
        "tz": "Asia/Ulaanbaatar Asia/Choibalsan"
    },
    {
        "windows": "Aus Central W. Standard Time",
        "tz": "Australia/Eucla"
    },
    {
        "windows": "Aus Central W. Standard Time",
        "tz": "Australia/Eucla"
    },
    {
        "windows": "Transbaikal Standard Time",
        "tz": "Asia/Chita"
    },
    {
        "windows": "Transbaikal Standard Time",
        "tz": "Asia/Chita"
    },
    {
        "windows": "Tokyo Standard Time",
        "tz": "Asia/Tokyo"
    },
    {
        "windows": "Tokyo Standard Time",
        "tz": "Asia/Jayapura"
    },
    {
        "windows": "Tokyo Standard Time",
        "tz": "Asia/Tokyo"
    },
    {
        "windows": "Tokyo Standard Time",
        "tz": "Pacific/Palau"
    },
    {
        "windows": "Tokyo Standard Time",
        "tz": "Asia/Dili"
    },
    {
        "windows": "Tokyo Standard Time",
        "tz": "Etc/GMT-9"
    },
    {
        "windows": "North Korea Standard Time",
        "tz": "Asia/Pyongyang"
    },
    {
        "windows": "North Korea Standard Time",
        "tz": "Asia/Pyongyang"
    },
    {
        "windows": "Korea Standard Time",
        "tz": "Asia/Seoul"
    },
    {
        "windows": "Korea Standard Time",
        "tz": "Asia/Seoul"
    },
    {
        "windows": "Yakutsk Standard Time",
        "tz": "Asia/Yakutsk"
    },
    {
        "windows": "Yakutsk Standard Time",
        "tz": "Asia/Yakutsk Asia/Khandyga"
    },
    {
        "windows": "Cen. Australia Standard Time",
        "tz": "Australia/Adelaide"
    },
    {
        "windows": "Cen. Australia Standard Time",
        "tz": "Australia/Adelaide Australia/Broken_Hill"
    },
    {
        "windows": "AUS Central Standard Time",
        "tz": "Australia/Darwin"
    },
    {
        "windows": "AUS Central Standard Time",
        "tz": "Australia/Darwin"
    },
    {
        "windows": "E. Australia Standard Time",
        "tz": "Australia/Brisbane"
    },
    {
        "windows": "E. Australia Standard Time",
        "tz": "Australia/Brisbane Australia/Lindeman"
    },
    {
        "windows": "AUS Eastern Standard Time",
        "tz": "Australia/Sydney"
    },
    {
        "windows": "AUS Eastern Standard Time",
        "tz": "Australia/Sydney Australia/Melbourne"
    },
    {
        "windows": "West Pacific Standard Time",
        "tz": "Pacific/Port_Moresby"
    },
    {
        "windows": "West Pacific Standard Time",
        "tz": "Antarctica/DumontDUrville"
    },
    {
        "windows": "West Pacific Standard Time",
        "tz": "Pacific/Truk"
    },
    {
        "windows": "West Pacific Standard Time",
        "tz": "Pacific/Guam"
    },
    {
        "windows": "West Pacific Standard Time",
        "tz": "Pacific/Saipan"
    },
    {
        "windows": "West Pacific Standard Time",
        "tz": "Pacific/Port_Moresby"
    },
    {
        "windows": "West Pacific Standard Time",
        "tz": "Etc/GMT-10"
    },
    {
        "windows": "Tasmania Standard Time",
        "tz": "Australia/Hobart"
    },
    {
        "windows": "Tasmania Standard Time",
        "tz": "Australia/Hobart Australia/Currie"
    },
    {
        "windows": "Vladivostok Standard Time",
        "tz": "Asia/Vladivostok"
    },
    {
        "windows": "Vladivostok Standard Time",
        "tz": "Asia/Vladivostok Asia/Ust-Nera"
    },
    {
        "windows": "Lord Howe Standard Time",
        "tz": "Australia/Lord_Howe"
    },
    {
        "windows": "Lord Howe Standard Time",
        "tz": "Australia/Lord_Howe"
    },
    {
        "windows": "Bougainville Standard Time",
        "tz": "Pacific/Bougainville"
    },
    {
        "windows": "Bougainville Standard Time",
        "tz": "Pacific/Bougainville"
    },
    {
        "windows": "Russia Time Zone 10",
        "tz": "Asia/Srednekolymsk"
    },
    {
        "windows": "Russia Time Zone 10",
        "tz": "Asia/Srednekolymsk"
    },
    {
        "windows": "Magadan Standard Time",
        "tz": "Asia/Magadan"
    },
    {
        "windows": "Magadan Standard Time",
        "tz": "Asia/Magadan"
    },
    {
        "windows": "Norfolk Standard Time",
        "tz": "Pacific/Norfolk"
    },
    {
        "windows": "Norfolk Standard Time",
        "tz": "Pacific/Norfolk"
    },
    {
        "windows": "Sakhalin Standard Time",
        "tz": "Asia/Sakhalin"
    },
    {
        "windows": "Sakhalin Standard Time",
        "tz": "Asia/Sakhalin"
    },
    {
        "windows": "Central Pacific Standard Time",
        "tz": "Pacific/Guadalcanal"
    },
    {
        "windows": "Central Pacific Standard Time",
        "tz": "Antarctica/Macquarie"
    },
    {
        "windows": "Central Pacific Standard Time",
        "tz": "Pacific/Ponape Pacific/Kosrae"
    },
    {
        "windows": "Central Pacific Standard Time",
        "tz": "Pacific/Noumea"
    },
    {
        "windows": "Central Pacific Standard Time",
        "tz": "Pacific/Guadalcanal"
    },
    {
        "windows": "Central Pacific Standard Time",
        "tz": "Pacific/Efate"
    },
    {
        "windows": "Central Pacific Standard Time",
        "tz": "Etc/GMT-11"
    },
    {
        "windows": "Russia Time Zone 11",
        "tz": "Asia/Kamchatka"
    },
    {
        "windows": "Russia Time Zone 11",
        "tz": "Asia/Kamchatka Asia/Anadyr"
    },
    {
        "windows": "New Zealand Standard Time",
        "tz": "Pacific/Auckland"
    },
    {
        "windows": "New Zealand Standard Time",
        "tz": "Antarctica/McMurdo"
    },
    {
        "windows": "New Zealand Standard Time",
        "tz": "Pacific/Auckland"
    },
    {
        "windows": "UTC+12",
        "tz": "Etc/GMT-12"
    },
    {
        "windows": "UTC+12",
        "tz": "Pacific/Tarawa"
    },
    {
        "windows": "UTC+12",
        "tz": "Pacific/Majuro Pacific/Kwajalein"
    },
    {
        "windows": "UTC+12",
        "tz": "Pacific/Nauru"
    },
    {
        "windows": "UTC+12",
        "tz": "Pacific/Funafuti"
    },
    {
        "windows": "UTC+12",
        "tz": "Pacific/Wake"
    },
    {
        "windows": "UTC+12",
        "tz": "Pacific/Wallis"
    },
    {
        "windows": "UTC+12",
        "tz": "Etc/GMT-12"
    },
    {
        "windows": "Fiji Standard Time",
        "tz": "Pacific/Fiji"
    },
    {
        "windows": "Fiji Standard Time",
        "tz": "Pacific/Fiji"
    },
    {
        "windows": "Chatham Islands Standard Time",
        "tz": "Pacific/Chatham"
    },
    {
        "windows": "Chatham Islands Standard Time",
        "tz": "Pacific/Chatham"
    },
    {
        "windows": "UTC+13",
        "tz": "Etc/GMT-13"
    },
    {
        "windows": "UTC+13",
        "tz": "Pacific/Enderbury"
    },
    {
        "windows": "UTC+13",
        "tz": "Pacific/Fakaofo"
    },
    {
        "windows": "UTC+13",
        "tz": "Etc/GMT-13"
    },
    {
        "windows": "Tonga Standard Time",
        "tz": "Pacific/Tongatapu"
    },
    {
        "windows": "Tonga Standard Time",
        "tz": "Pacific/Tongatapu"
    },
    {
        "windows": "Samoa Standard Time",
        "tz": "Pacific/Apia"
    },
    {
        "windows": "Samoa Standard Time",
        "tz": "Pacific/Apia"
    },
    {
        "windows": "Line Islands Standard Time",
        "tz": "Pacific/Kiritimati"
    },
    {
        "windows": "Line Islands Standard Time",
        "tz": "Pacific/Kiritimati"
    },
    {
        "windows": "Line Islands Standard Time",
        "tz": "Etc/GMT-14"
    }
];
