import { useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import classNames from 'classnames';

import { searchableTransactionDateRange } from '@premier/utils/date';
import errorUtil from '@premier/utils/error';
import uiUtil from '@premier/utils/ui';
import windowUtil from '@premier/utils/window';
import securityUtil from '@premier/utils/security';
import { userRoles } from 'components/Routing';

import { PageSection, Icon, BackToTop, Button, PaginationControl, Link } from '@premier/ui';
import { Form, DateRangeEnum } from '@premier/form';
import { withError, PageHeader, FormError, MerchantPageCountDropdowns, ProductTooltip } from 'components/Common';
import { TransactionFilter, TransactionList, ExportTransactionsModal } from 'components/Transactions';
import labels from 'constants/labels';
import { defaultPageSize, defaultPageSizeOptions } from 'constants/billpay';

import { PlatformRoutesConfiguration } from 'components/Routing';
import * as paymentActions from 'components/Transactions/_actions/paymentActions';
import * as accountActions from 'components/Account/_actions/accountActions';

import './ManageTransactionsPage.scss';

const responseTypeOptions = [
    { value: '', label: 'All' },
    { value: 'approved', label: 'Approved' },
    { value: 'declined', label: 'Declined' },
];


const ManageTransactionsPage = ({
    actions, errors, isLoading,
    savedState, authenticatedUser, searchResult, //state
    billers, transactionTypes, txnTypes, txnSubTypes, txnSources, txnSubSources, accountTypes //state - transaction filter
}) => {

    const [showFilter, setShowFilter] = useState(false);
    const [filter, setFilter] = useState(null);  // A live copy of the form values
    const [lastSavedFilter, setLastSavedFilter] = useState({});
    const [sort, setSort] = useState(_.get(savedState, 'sort', { field: 'processedDate', descending: true }));
    const [reload, setReload] = useState(false);
    const [resultsPerPage, setResultsPerPage] = useState(_.get(savedState, 'resultsPerPage', defaultPageSize));
    const [currentPage, setCurrentPage] = useState(_.get(savedState, 'pageIndex', 0) + 1);
    const [showExportModal, setShowExportModal] = useState(false);
    const [prevFilter, setPrevFilter] = useState(null);

    // On page load, fetch things from API, get transactions and restore previous state (page number, scroll position, filters) if any
    useEffect(() => {
        var apiCalls = [
            actions.getTransactionTypes(),
            actions.getTxnTypes(),
            actions.getTxnSubTypes(),
            actions.getTxnSources(),
            actions.getTxnSubSources(),
            actions.getAccountTypes()
        ]; // Get enums for the Filter

        Promise.all(apiCalls).then(() => {
            if (savedState && savedState.scrollPosition)
                windowUtil.scrollTo(savedState.scrollPosition); // Scroll back to previous position
        });

        if (savedState)
            handleFilterChange(savedState.filter, false);
    }, [actions]);

    useEffect(() => {
        const innerAsync = async () => {
            if (reload || !_.isEqual(filter, prevFilter)) {
                setPrevFilter(filter);
                await actions.getTransactions(resultsPerPage, currentPage - 1, filter, sort);
                setReload(false);
            }
        };
        innerAsync();
    }, [reload]);


    //#region ========== Filter stuff ==========

    const defaultFilter = {
        childMerchantNumber: '',
        transactionDateRange: { presetValue: DateRangeEnum.LAST_30DAYS },
        settlementDate: [],
        amount: '',
        amountRange: { min: '', max: '' },
        billerCode: '',
        crn1: '',
        merchantReference: '',
        receiptNumber: '',
        transactionId: '',
        rrn: '',
        maskedCardNumber: { prefix: '', suffix: '' },
        token: '',
        chequeNo: '',
        searchTransactionsResponseType: '',
        transactionType: '',
        transactionTypes: [],
        txnTypes: [],
        txnSubTypes: [],
        txnSources: [],
        txnSubSources: [],
        customerUniqueId: '',
        customerName: '',
        customerEmailAddress: '',
        customerPhoneNumber: {},
        cardTypes: []
    };

    // Filter options
    const [billerOptions, setBillerOptions] = useState(uiUtil.addAllOption(billers.map(b => ({value: b.billerCode, label: b.billerCodeWithName}))));
    const transactionTypeOptions = uiUtil.getOptions(transactionTypes);
    const accountTypeOptions = uiUtil.getOptions(accountTypes);
    const txnTypesOptions = uiUtil.getOptions(txnTypes);
    const txnSubTypesOptions = uiUtil.getOptions(txnSubTypes);
    const txnSourcesOptions = uiUtil.getOptions(txnSources);
    var hasExternalReporting = securityUtil.hasAccess([userRoles.externalReporting], authenticatedUser);
    const txnSubSourcesOptions = uiUtil.getOptions(txnSubSources && { items: txnSubSources.items.filter(source => !source.externalReportingOnly || hasExternalReporting) });

    /** Get the number of applied filters in the Advanced Filter */
    const filterCount = useMemo(() => {
        var count = 0;

        if (!filter)
            return count;

        const { childMerchantNumber, transactionDateRange, customerPhoneNumber, ...otherDefaultValues } = defaultFilter;

        if (_.get(filter.transactionDateRange, 'dates.length') || filter.transactionDateRange.presetValue)
            count++;

        if (_.get(filter.customerPhoneNumber, 'phoneNumber'))
            count++;

        Object.keys(otherDefaultValues).forEach(key => {
            if (filter[key] && !_.isEqual(filter[key], defaultFilter[key]))
                count++;
        });

        return count;
    }, [filter]);

    /** This is called when a component auto-updates it's internal value (ie. DateRangePreset updates the dates/presetValue based on the other) */
    function handleFilterUpdate(newFilter) {
        if (!_.get(filter, 'transactionDateRange.dates'))  // Initially when we don't have dates yet, call API to populate results
            handleFilterChange(newFilter);
        else   // Otherwise just update the filter without calling API
            setFilter(newFilter);
    }

    function handleFilterChange(newFilter, resetPage = true) {  // Note: This is also called at first render (from either useEffect or DateRangePresetField completing the dates)
        let filteredBillers = newFilter.childMerchantNumber
            ? _.filter(billers, x => x.merchantNumber === newFilter.childMerchantNumber) //filters out billers via new filter's merchant number
            : billers;

        setBillerOptions(uiUtil.addAllOption(filteredBillers.map(b => ({value: b.billerCode, label: b.billerCodeWithName}))));

        if (resetPage)
            setCurrentPage(1);

        setFilter(newFilter);
        setLastSavedFilter(newFilter);
        setReload(true);
    }

    function handleFilterSubmit(values, context) {
        // For checkboxes, if all are selected, set to null because API would treat them just the same

        if (_.get(values, "cardTypes.length") === accountTypeOptions.length)
            values.cardTypes = null;

        if (_.get(values, "transactionTypes.length") === transactionTypeOptions.length)
            values.transactionTypes = null;

        if (_.get(values, "txnTypes.length") === txnTypesOptions.length)
            values.txnTypes = null;

        if (_.get(values, "txnSubTypes.length") === txnSubTypesOptions.length)
            values.txnSubTypes = null;

        if (_.get(values, "txnSources.length") === txnSourcesOptions.length)
            values.txnSources = null;

        if (_.get(values, "txnSubSources.length") === txnSubSourcesOptions.length)
            values.txnSubSources = null;

        return values;  // Modify Form values
    }

    //#endregion


    function handleSort(field, descending) {
        setSort({ field, descending });
        setReload(true);
    }

    function pageChanged(newPage) {
        setCurrentPage(newPage);
        setReload(true);
    }

    function resultsPerPageChanged(newResultsPerPage) {
        setResultsPerPage(newResultsPerPage.value);
        setCurrentPage(1);
        setReload(true);
    }

    function saveScrollPosition() {
        var pageState = Object.assign({}, savedState, {
            scrollPosition: document.body.scrollTop || document.documentElement.scrollTop
        });
        actions.saveTransactionsPageState(pageState);
    }

    // ========== Render ==========

    return (<>
        <Form initialValues={(savedState && savedState.filter) || defaultFilter}>
            <div className={classNames('manage-transactions-page', { 'hidden': showFilter })} aria-hidden={showFilter}>
                <PageSection>
                    <PageHeader title={<>{'Manage transactions'}<ProductTooltip productTooltipModule={"MANAGE_TRANSACTIONS"} /></>}>
                        <Button onClick={() => { setShowExportModal(true) }}>Export</Button>
                        {securityUtil.hasAccess(PlatformRoutesConfiguration.transactionRoute.newPayment.roles, authenticatedUser) ?
                            <Link button to={PlatformRoutesConfiguration.transactionRoute.newPayment.generatePath()}>New payment</Link>
                            : null
                        }
                    </PageHeader>

                    <Button subtle onClick={() => { setShowFilter(true) }}>View filters ({filterCount} applied)</Button>

                    {_.get(searchResult, 'count', 0) >= 1000 && (
                        <p><Icon info inline /> Search is limited to 1000 transactions. Please refine your search.</p>
                    )}

                    <MerchantPageCountDropdowns
                        resultsPerPageOptions={defaultPageSizeOptions}
                        resultsPerPage={resultsPerPage}
                        onChildMerchantChanged={(val, context) => handleFilterChange(context.values)}
                        onResultsPerPageChanged={resultsPerPageChanged}
                    />

                    <FormError errors={errors} />

                    <TransactionList
                        // For the quick filter
                        savedFilter={filter ?? (savedState && savedState.filter)}
                        onFilterChange={handleFilterChange}
                        validDateRange={searchableTransactionDateRange}
                        responseTypeOptions={responseTypeOptions}
                        transactionTypeOptions={uiUtil.generateOptions(transactionTypes)}
                        // For the table/list
                        sort={sort}
                        onSort={handleSort}
                        transactions={_.get(searchResult, 'items', [])}
                        onLinkClick={saveScrollPosition}
                        isLoading={isLoading}
                    />

                    <PaginationControl
                        currentPage={currentPage}
                        onPageChanged={pageChanged}
                        itemCount={_.get(searchResult, 'count', 0)}
                        itemsPerPage={resultsPerPage}
                    />
                </PageSection>

                <BackToTop />
            </div>



            <TransactionFilter
                show={showFilter}
                onHide={() => setShowFilter(false)}
                defaultFilter={defaultFilter}
                lastSavedFilter={lastSavedFilter}
                onAutoUpdate={handleFilterUpdate}
                onChange={handleFilterChange}
                onSubmit={handleFilterSubmit}
                validDateRange={searchableTransactionDateRange}
                responseTypeOptions={responseTypeOptions}
                billerOptions={billerOptions}
                transactionTypeOptions={transactionTypeOptions}
                txnTypesOptions={txnTypesOptions}
                txnSubTypesOptions={txnSubTypesOptions}
                txnSourcesOptions={txnSourcesOptions}
                txnSubSourcesOptions={txnSubSourcesOptions}
                accountTypeOptions={accountTypeOptions}
                authenticatedUser={authenticatedUser}
            />
        </Form>

        <ExportTransactionsModal
            filter={filter}
            showModal={showExportModal}
            closeModal={() => { setShowExportModal(false) }}
        />
    </>);
}


function mapStateToProps(state, ownProps) {
    return {
        savedState: state.transactions.payments.transactionsPageState,
        isLoading: state.transactions.payments.isLoading,
        childMerchants: state.accounts.users.merchant.childMerchants,
        billers: state.accounts.users.activeBillers,
        transactionTypes: state.transactions.payments.transactionTypes,
        txnTypes: state.transactions.payments.txnTypes,
        txnSubTypes: state.transactions.payments.txnSubTypes,
        txnSources: state.transactions.payments.txnSources,
        txnSubSources: state.transactions.payments.txnSubSources,
        accountTypes: state.transactions.payments.accountTypes,
        authenticatedUser: state.accounts.users.authenticatedUser,
        searchResult: state.transactions.payments.searchResult,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(paymentActions, dispatch),
        accountActions: bindActionCreators(accountActions, dispatch)
    };
}

function mapStoreToErrors(state) {
    return state.transactions.payments.errors;
}

function mapErrorToString(error) {
    var paramLabels = {
        'MerchantReference': 'Merchant reference',
        'Crn1': 'Customer reference',
        'Amount': 'Amount',
        'CardNumber': labels.maskedCardNumber
    };

    return errorUtil.getMessage(error, paramLabels);
}

export default withError(
    connect(mapStateToProps, mapDispatchToProps)(ManageTransactionsPage),
    mapStoreToErrors,
    mapErrorToString
);
