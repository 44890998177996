import React, {useState} from 'react';
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import _ from 'lodash';

import {Modal} from '@premier/ui';
import { SurchargeConfirmationStep, GetSurchargeInfoStep, NewPaymentResultModal, PaymentProcessingStep } from 'components/Transactions'

import * as commonActions from 'components/Common/_actions/actions';

const PaymentProcessingModal = ({
    commonActions, //actions
    surchargeInfo, //state
    biller, paymentRequest, customerEmail,customerPhoneNumber, //data
    onClosed, mapErrorsFromDto, //functions
}) => {

    const [isShowing, setIsShowing] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isGettingSurcharge, setIsGettingSurcharge] = useState(true);
    const [isConfirmingSurcharge, setIsConfirmingSurcharge] = useState(false);
    const [hasProcessedPayment, setHasProcessedPayment] = useState(false);
    const [receipt, setReceipt] = useState({});

    function handleModalClose(reset, dismiss) {
        setIsShowing(false);

        if (hasProcessedPayment)
            commonActions.clearErrors();

        setHasProcessedPayment(false);

        if (onClosed)
            onClosed(reset, dismiss);
    }

    function handleSurchargeProcessed(surchargeInfo){
        setIsGettingSurcharge(false);
        if (surchargeInfo.surchargeAmount > 0 ) {
            setIsConfirmingSurcharge(true);
        }
        else {
            setIsLoading(true);
        }
    }

    function handleSurchargeError(){
        handleModalClose();
    }

    function handleSurchargeConfirmed() {
        setIsConfirmingSurcharge(false);
        setIsLoading(true);
    }

    function handleSurchargeCancelled() {
        setIsConfirmingSurcharge(false);
        handleModalClose();
    }

    function handlePaymentProcessed(receipt){
        setReceipt(receipt);
        setHasProcessedPayment(true);
        setIsLoading(false);
    }

    return (
        <Modal show={isShowing} onHide={() => handleModalClose(receipt && receipt.responseCode === '0', true)}>
            {isGettingSurcharge && <GetSurchargeInfoStep payment={paymentRequest} onSurchargeProcessed={handleSurchargeProcessed} onErrorProcessing={handleSurchargeError} />}
            {isConfirmingSurcharge && <SurchargeConfirmationStep onConfirm={handleSurchargeConfirmed} onCancel={handleSurchargeCancelled} surchargeInfo={surchargeInfo} payment={paymentRequest} />}
            {isLoading && <PaymentProcessingStep payment={paymentRequest} onSuccess={handlePaymentProcessed} onError={() => handleModalClose()} mapErrorsFromDto={mapErrorsFromDto} />}
            {hasProcessedPayment && <NewPaymentResultModal receipt={receipt} biller={biller} customerEmail={customerEmail} customerPhoneNumber={customerPhoneNumber} onClose={handleModalClose} />}
        </Modal>
    );
}

function mapStateToProps(state){
    return {
        surchargeInfo: _.get(state.transactions.payments.newPayment, 'surchargeInfo', null)
    };
}

function mapDispatchToProps(dispatch) {
    return {
        commonActions: bindActionCreators(commonActions, dispatch),
    };
}

PaymentProcessingModal.propTypes = {
    biller: PropTypes.object,
    paymentRequest: PropTypes.object,
    customerEmail: PropTypes.string,
    customerPhoneNumber: PropTypes.object,

    /** eg. (resetForm, dismiss) => { if(resetForm) { resetForm(); } }
      * <p>The parameter 'resetForm' will be true when they selected New Payment or when payment was successful.</p>
      * <p>The parameter 'dimiss' will be true when they close the modal using the X button on the top right.</p>
      */
     onClosed: PropTypes.func,
     mapErrorsFromDto: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentProcessingModal);
