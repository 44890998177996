import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';

import fileUtil from '@premier/utils/file';
import windowUtil from '@premier/utils/window';

import { Icon, PaginationControl, Dialog,Button } from '@premier/ui';
import { Form, InputField, DropdownField, RadioField, FormErrorList } from '@premier/form';
import { BatchJobList, DownloadBatchDialog, BatchJobWarningDeleteDialog, BatchJobWarningProcessDialog } from 'components/Batch';
import { FormError, useInterval, useDebounce } from 'components/Common';
import labels from 'constants/labels';
import { FaExclamationTriangle } from 'react-icons/fa';

import * as batchActions from 'components/Batch/_actions/batchActions';

import './BatchJobs.scss';

const REFRESH_INTERVAL = 20000;

const initialFilter = {
    filename: '',
    timeSpanDays: 45,
    fileStatus: 'completed'
};

/** The BatchJobList including filter and pagination */
const BatchJobs = (props) => {
    const { actions, savedState, batchFilesResult } = props;

    const [filter, setFilter] = useState(_.get(savedState, 'filter', { ...initialFilter }));
    const [sort, setSort] = useState(_.get(savedState, 'sort', { field: 'submittedDateTime', descending: true }));
    const [resultsPerPage] = useState(_.get(savedState, 'resultsPerPage', 10));
    const [currentPage, setCurrentPage] = useState(_.get(savedState, 'pageIndex', 0) + 1);
    const [resetPage, setResetPage] = useState(false);
    const [listErrors, setListErrors] = useState([]);
    const [downloadErrors, setDownloadErrors] = useState([]);
    const [autoRefreshing, setAutoRefreshing] = useState(false);

    const [selectedBatchFiles, setSelectedBatchFiles] = useState(null);
    const [showDownloadDialog, setShowDownloadDialog] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [showWarningDeleteDialog, setShowWarningDeleteDialog] = useState(false);
    const [showWarningProcessDialog, setShowWarningProcessDialog] = useState(false);
    const [currentSelectedFile, setCurrentSelectedFile] = useState(null);

    // On page load, fetch things from API, get transactions and restore previous state (page number, scroll position, filters) if any
    useEffect(() => {
        handleFilterChange((savedState && savedState.filter) || filter, false);
    }, []);

    useEffect(() => {
        setListErrors([]);

        let newPage = resetPage ? 1 : currentPage;
        setCurrentPage(newPage);
        setResetPage(false);

        reloadResults(newPage, true);

    }, [resultsPerPage, currentPage, filter, sort]);

    useInterval(() => {
        reloadResults();
    }, autoRefreshing ? REFRESH_INTERVAL : null);

    function getPageIndex(pageNumber = currentPage) {
        return pageNumber - 1;
    }

    function reloadResults(page = currentPage, scrollToPreviousState) {
        setAutoRefreshing(false);

        actions.getBatchJobs(resultsPerPage, getPageIndex(page), filter, sort).then(result => {
            actions.saveBatchFilesPageState({ filter, sort, resultsPerPage, pageIndex: getPageIndex(page) });

            if (scrollToPreviousState && savedState && savedState.scrollPosition)
                windowUtil.scrollTo(savedState.scrollPosition); // Scroll back to previous position

            setAutoRefreshing(true);
        }).catch(errors => {
            setListErrors(errors);
        });
    }

    function handleFilterChange(values, resetPage = true) {
        setResetPage(resetPage);
        setFilter(values);
    }
    const debouncedHandleFilterChange = useDebounce(handleFilterChange);

    function handleFilterChangeDebounced(values) {
        debouncedHandleFilterChange(values);
    }

    function handleSort(field, descending) {
        setSort({ field, descending });
    }

    function handlePageChange(newPage) {
        setCurrentPage(newPage);
    }

    function saveScrollPosition() {
        var pageState = Object.assign({}, savedState, {
            scrollPosition: document.body.scrollTop || document.documentElement.scrollTop
        });
        props.actions.saveBatchFilesPageState(pageState);
    }

    function getTabTitle(title) {
        if (!batchFilesResult) return title;

        var prefix = title === 'In progress' ? 'progress' : title.toLowerCase();
        var count = `(${batchFilesResult[prefix + 'Count']})`;
        var icon = batchFilesResult[prefix + 'Errors'] && (
            <Icon alert />
        );

        return <>{title} <nobr>{count}{icon}</nobr></>;
    }

    function handleDownload(batchFiles) {
        setSelectedBatchFiles(batchFiles);
        setShowDownloadDialog(true);
        setIsDownloading(false);
    }

    async function startDownload(reportOptions) {
        setIsDownloading(true);

        const promises = selectedBatchFiles.map(async f => {
            await downloadFileResult(
                f.fileId,
                reportOptions.downloadFormat,
                Number(reportOptions.returnAll) === 1,
                Number(reportOptions.zipContents) === 1
            );
        });

        await Promise.all(promises).then(() => {
            // Close dialog and reenable buttons after all selected files have been downloaded
            setShowDownloadDialog(false);
            setIsDownloading(false);
        }).catch(errors => {
            setShowDownloadDialog(false);
            setIsDownloading(false);
            setDownloadErrors(errors);
        });
    }

    async function downloadFileResult(fileId, downloadFormat, returnAll, zipContents) {
        await actions.downloadBatchJobResult(fileId, downloadFormat, returnAll, zipContents).then(result => {
            var data = zipContents ? fileUtil.decodeBase64Binary(result.fileData) : result.fileData;
            var type = zipContents ? 'application/zip' : 'text/csv';
            fileUtil.download(data, result.fileName, type);
        });
    }
    async function handleDeleteConfirmed() {
        await actions.deleteBatchJob(currentSelectedFile.fileId, currentSelectedFile.status.key).then(() => {
            setShowWarningDeleteDialog(false);
            setCurrentSelectedFile(null);
        });
    }

    async function handleContinueConfirmed() {
        await actions.processDuplicateBatchJob(currentSelectedFile.fileId).then(() => {
            setShowWarningProcessDialog(false);
            setCurrentSelectedFile(null);
        });
    }

    function openErrorModal(file,isShowErrorModal) {
        setShowErrorModal(isShowErrorModal);
        setCurrentSelectedFile(file);
    }

    return (
        <div className='batch-jobs'>
            <Form
                initialValues={filter}
                errors={listErrors}
                render={(context) => (<>
                <div className='row filter'>
                    <InputField className='col-xs-12 col-sm-6 col-lg-4'
                        name='filename'
                        label={labels.batchFilename}
                        onChange={val => handleFilterChangeDebounced({ ...context.values, filename: val })}
                    />
                    <DropdownField className='col-xs-12 col-sm-6 col-lg-4'
                        name='timeSpanDays'
                        label={labels.timeSpan}
                        onChange={() => handleFilterChange(context.values)}
                        options={[
                            { value: 1, label: 'Last 24 hours' },
                            { value: 7, label: 'Last 7 days' },
                            { value: 30, label: 'Last 30 days' },
                            { value: 45, label: 'Last 45 days' },
                        ]}
                    />
                </div>

                <FormErrorList />

                <div className='tab-radio-field'>
                    <RadioField noLabels ariaLabel='Batch file status'
                        name='fileStatus'
                        onChange={val => handleFilterChange({ ...context.values, fileStatus: val })}
                        options={[
                            { value: 'completed', label: getTabTitle('Completed') },
                            { value: 'inProgress', label: getTabTitle('In progress') },
                            { value: 'scheduled', label: getTabTitle('Scheduled') },
                        ]}
                    />
                </div>
            </>)} />

            <BatchJobList data={_.get(batchFilesResult, 'items', [])}
                isLoading={props.isLoading}  // Loading may come from upload or from getBatchJobs
                statusFilter={filter.fileStatus}
                sort={sort}
                onSort={handleSort}
                onLinkClick={saveScrollPosition}
                onDownload={handleDownload}
                openErrorModal={openErrorModal}
            />

            <PaginationControl
                currentPage={currentPage}
                onPageChanged={handlePageChange}
                itemCount={_.get(batchFilesResult, 'count', 0)}
                itemsPerPage={resultsPerPage}
            />

            {downloadErrors && <FormError apiErrors={downloadErrors} />}



            <DownloadBatchDialog show={showDownloadDialog}
                isDownloading={isDownloading}
                onConfirm={startDownload}
                onCancel={() => { setShowDownloadDialog(false) }}
            />


            <Dialog show={showErrorModal}
                closeButton
                onClose={() => {
                    setShowErrorModal(false)
                }}
                icon={<div><FaExclamationTriangle size={48} color="orange" /> </div>}
                title='Error with file upload'
                footerButtons={<>
                    <Button primary onClick={() => {
                        setShowWarningDeleteDialog(true);
                        setShowErrorModal(false);
                    }}>Delete batch file</Button>
                    <Button onClick={() => {
                        setShowWarningProcessDialog(true);
                        setShowErrorModal(false);
                    }}>Continue processing</Button>
                </>}
            >
                <p>Duplicate file warning. This batch file has the same number of records and value as a file that was uploaded in the last 30 days.</p>
            </Dialog>
            <BatchJobWarningDeleteDialog show={showWarningDeleteDialog} file={currentSelectedFile}
                onClose={() => {
                    setShowWarningDeleteDialog(false);
                    setCurrentSelectedFile(null);
                }}
                handleDeleteConfirmed={handleDeleteConfirmed}
            />

            <BatchJobWarningProcessDialog show={showWarningProcessDialog} file={currentSelectedFile}
                onClose={() => {
                    setShowWarningProcessDialog(false);
                    setCurrentSelectedFile(null);
                }}
                handleContinueConfirmed={handleContinueConfirmed}
            />

        </div>
    );
};

function mapStateToProps(state, ownProps) {
    return {
        savedState: state.transactions.batch.batchFilesPageState,
        isLoading: state.transactions.batch.isLoading,
        batchFilesResult: state.transactions.batch.batchFilesResult,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(batchActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(BatchJobs);