import { config } from './util';
import {
    BreakdownRequest,
    PaginateRequestPaymentPlanViewFilterPaymentPlanViewFilterSortedFields,
    PaymentPlanApiFactory,
    SampleBreakdownRequest,
    PaymentPlanRequest,
    PaymentPlanCustomerSummaryRequest,
    PaymentPlanDetailsRequest
} from '@premier/webapi-client';

const paymentPlanApi = PaymentPlanApiFactory(config);

class PaymentPlanApi {
    static async calculatePaymentPlan(breakdownRequest : BreakdownRequest) {
        return await paymentPlanApi.paymentPlanCalculatePaymentPlan(breakdownRequest);
    }

    static async createPaymentPlan(paymentPlanRequest : PaymentPlanRequest) {
        return await paymentPlanApi.paymentPlanCreatePaymentPlan(paymentPlanRequest);
    }

    static async getPaymentPlanList(pagedRequest : PaginateRequestPaymentPlanViewFilterPaymentPlanViewFilterSortedFields) {
        return await paymentPlanApi.paymentPlanGetPaymentPlanView(pagedRequest);
    }

    static async getPaymentPlanCommitment(requestBillerCode: string) {
        return await paymentPlanApi.paymentPlanGetPaymentPlanCommitment(requestBillerCode);
    }

    static async calculateSamplePaymentPlan(request : SampleBreakdownRequest) {
        return await paymentPlanApi.paymentPlanCalculateSamplePaymentPlan(request);
    }

    static async checkIfPaymentPlanTermsAndConditionsExists(requestBillerCode: string) {
        return await paymentPlanApi.paymentPlanCheckIfPaymentPlanTermsAndConditionsExists(requestBillerCode);
    }

    static async cancelPaymentPlan(cancelRequest : PaymentPlanDetailsRequest) {
        return await paymentPlanApi.paymentPlanCancelPaymentPlan(cancelRequest);
    }

    static async getCustomerSummary(customerSummaryRequest : PaymentPlanCustomerSummaryRequest) {
        return await paymentPlanApi.paymentPlanGetCustomerSummary(customerSummaryRequest);
    }

    static async getPaymentPlanDetails(paymentPlanDetailsRequest : PaymentPlanDetailsRequest) {
        return await paymentPlanApi.paymentPlanGetPaymentPlanDetails(paymentPlanDetailsRequest);
    }
}

export default PaymentPlanApi;