import React from 'react';
import { connect } from 'react-redux';

import { Link, Icon, ButtonContainer } from '@premier/ui';
import { FloatingContainer, PageHeader, ErrorPage } from 'components/Common';

import { PlatformRoutesConfiguration } from 'components/Routing';

const NotFoundPage = ({
    authenticatedUser //state value
}) => {
    const errorPage = (
        <ErrorPage>
            <PageHeader
                icon={<Icon notFound />}
                title="We couldn't find the resource"
                subtitle="We looked everywhere but we couldn't find it..."
            />

            <ButtonContainer>
                <h2>We can help</h2>
                {!!authenticatedUser && (
                    <Link primary to={PlatformRoutesConfiguration.accountRoute.home?.generatePath()}>Back to home</Link>
                )}
                {!authenticatedUser && (
                    <Link primary to={PlatformRoutesConfiguration.accountRoute.logon?.generatePath()}>Back to log on</Link>
                )}
                <Link button to={PlatformRoutesConfiguration.accountRoute.contactUs?.generatePath()} newWindow>Contact us</Link>
            </ButtonContainer>
        </ErrorPage>
    );

    if (authenticatedUser) {
        return errorPage;
    }

    return (
        <FloatingContainer>
            {errorPage}
        </FloatingContainer>
    );
}

function mapStateToProps(state, ownProps) {
    return {
        authenticatedUser: state.accounts.users.authenticatedUser
    }
}

export default connect(mapStateToProps)(NotFoundPage);