import React from 'react';
import { InputField, Form, validate } from '@premier/form';
import labels from 'constants/labels';

import './NewPasswordFields.scss';

// Password rules are in: NewPasswordFields.js, ChangePasswordPage.js, ProfilePage.js

/** Sub-form used for inputting a new password. Has password validation within this sub-form */
const NewPasswordFields = ({
    currentPasswordEnabled, changePasswordRequired, //validation logic
    ...otherProps //data
}) => {

    return (
        <>
            <div className='password-rules'>
                <p>
                    <b>Your password must:</b>
                </p>
                <ul>
                    <li>be at least 10 characters long</li>
                    <li>contain both letters and numbers</li>
                    <li>contain at least one uppercase and one lowercase letter</li>
                    <li>
                        contain at least one special character such as ! &ldquo; # $ % &amp; &lsquo; ( ) * + , - . / : ;
                        = ? @ [ \ ] ^ &#x60; _ {'{ | }'} ~
                    </li>
                </ul>
                <p>
                    <b>Your password must not:</b>
                </p>
                <ul>
                    <li>be the same as one used previously</li>
                    <li>contain the symbols &lt; &gt;</li>
                </ul>
            </div>

            <Form
                name='newPasswordForm'
                initialValues={{
                    newPassword: '',
                    confirmPassword: '',
                    currentPassword: ''
                }}
                initialValidation={{
                    currentPassword: currentPasswordEnabled && validate().required(),
                    newPassword: validate().if(
                        (value, values) => value || changePasswordRequired,
                        validate()
                            .required()
                            .minLength(10, 'Must be at least 10 characters')
                            .matches(/\d/, 'Must contain a number')
                            .matches(/[A-Z]/, 'Must contain an uppercase')
                            .matches(/[a-z]/, 'Must contain a lowercase')
                            .matches(/[^0-9a-zA-Z]/, 'Must contain a special character')
                            .matches(/^[^<>]*$/, 'Must not contain the symbols < >')
                    ),
                    confirmPassword: validate()
                        .requiredIf((values) => values['newPassword'] || changePasswordRequired)
                        .when((value, values) => {
                            if (values.newPassword || value) {
                                return values.newPassword === value;
                            }
                            return true;
                        }, "Passwords don't match")
                }}
            >
                {currentPasswordEnabled ? (
                    <InputField type='password' name='currentPassword' label={labels.currentPassword} {...otherProps} />
                ) : null}
                <InputField type='password' name='newPassword' label={labels.newPassword} {...otherProps} />
                <InputField type='password' name='confirmPassword' label={labels.confirmPassword} {...otherProps} />
            </Form>
        </>
    );
};

export default NewPasswordFields;
