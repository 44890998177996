import { Dialog } from '@premier/ui';
import currencyUtil from '@premier/utils/currency';
import dateUtil from '@premier/utils/date';
import { useApiCallDeprecated, APICallRequestState } from 'components/Common';
import { SampleBreakdownRequest } from '@premier/webapi-client'

import paymentPlanApi from 'api/paymentPlanApi';
interface InstalmentsSummaryDialogProps {
    request? : SampleBreakdownRequest,
    show? : boolean
    [rest : string]: any;
}

// Refer to PaymentBreakdown.cs for actual model
const InstalmentsSummaryDialog : React.FC<InstalmentsSummaryDialogProps> = ({ request, show, ...rest}) => {
    const [instalmentSummary, instalmentSummaryStatus] = useApiCallDeprecated(() => {
        if (show && request) {
            return paymentPlanApi.calculateSamplePaymentPlan(request);
        }
    }, [show, request]);

    return (<>
        <Dialog show={show && instalmentSummaryStatus === APICallRequestState.SUCCESSFUL}
            {...rest}>
            <p>
                {`The following summary assumes the total amount to pay is AUD 10,000 and the customer signed up to the payment plan today.`}
            </p>
            <p>
                <b>Total no. of payments required</b> {`${instalmentSummary?.breakdown?.schedulerSettings?.numberOfPayments + 1 ?? 1}`}
            </p>
            <p>
                <b>First payment amount</b> {`${currencyUtil.convertToDisplayString(instalmentSummary?.breakdown?.firstPaymentAmount ?? 0)}`}
            </p>
            <p>
                <b>Next payment date</b> {`${dateUtil.convertToDateString(instalmentSummary?.breakdown?.schedulerSettings?.startDate ?? new Date())}`}
            </p>
            <p>
                <b>Next payment amount</b> {`${currencyUtil.convertToDisplayString(instalmentSummary?.breakdown?.schedulerSettings?.recurringAmount ?? 0)}`}
            </p>
        </Dialog>
    </>)
}

export default InstalmentsSummaryDialog;