import { InvoiceModel } from '@premier/webapi-client';
import { Icon } from '@premier/ui';
import moment from 'moment';

export interface InvoiceStatus {
    label: string;
    icon: JSX.Element;
}

export enum InvoiceStatusEnum {
    // NOTE: keep this in sync with Billpay DB lookup table: tbl_BP_InvoiceStatus
    Saved = 1,
    Sent = 2,
    Viewed = 3,
    Cancelled = 4,
    Refunded = 5,
    PaidPartially = 6,
    PaidInFull = 7,
    Archived = 8,
}

export const getInvoiceStatus = (invoice: InvoiceModel) => {
    // cancelled
    if (invoice.invoiceStatusId === InvoiceStatusEnum.Cancelled) {
        return {
            icon: <Icon alert />,
            label: 'Cancelled',
        };
    }
    // fully paid
    else if (invoice.invoiceStatusId === InvoiceStatusEnum.PaidInFull) {
        return {
            icon: <Icon tick />,
            label: 'Paid in full',
        };
    }
    // overdue
    else if (moment(invoice.dueDate).diff(moment()) < 0) {
        return {
            icon: <Icon alert />,
            label: 'Overdue',
        };
    }
    // Refunded
    else if (invoice.invoiceStatusId === InvoiceStatusEnum.Refunded) {
        return {
            icon: <Icon info />,
            label: 'Refunded',
        };
    }
    // Viewed
    else if (invoice.invoiceStatusId === InvoiceStatusEnum.Viewed) {
        return {
            icon: <Icon info />,
            label: 'Viewed',
        };
    }
    // defaults to sent
    else {
        return {
            label: 'Sent',
            icon: <Icon info />,
        };
    }
};
