import { useState } from "react";
import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import PropTypes from "prop-types";
import _ from "lodash";

// @ts-ignore
import labels from "constants/labels";

import { Button } from "@premier/ui";
import { FormDialog, FormErrorList, InputField, PasswordField, SubmitButton, validate } from "@premier/form";
import * as merchantActions from "components/Settings/_actions/merchantActions";
import { FieldError } from "api/mapErrors";
import { RootState } from "store/store";

type Props = {
    show: boolean;
    initialUsername: string;
    onClose: (wasSaved: boolean) => void;
    actions: any;
    errors: FieldError[];
}

const KlarnaCredentialsDialog = ({
    show, initialUsername, onClose,  // FormDialog stuff
    actions, errors  // API / Redux
}: Props) => {
    const [loading, setLoading] = useState(false);

    function handleSave(values: any, context: any) {
        setLoading(true);
        actions.saveKlarnaConfig(values).then(() => {
            setLoading(false);
            onClose(true);
        }).catch(() => {
            setLoading(false);
        });
    }

    function handleCancel() {
        onClose(false);
    }

    return (
        <FormDialog
            show={show}
            title="Edit Klarna credentials"
            initialValues={{
                username: initialUsername,
            }}
            initialValidation={{
                username: validate().required(),
                password: validate().required(),
            }}
            onClose={handleCancel}
            onSubmit={handleSave}
            errors={errors}
            renderButtons={(context) => <>
                <SubmitButton loading={loading}>Save</SubmitButton>
                <Button onClick={handleCancel}>Cancel</Button>
            </>}
        >
            <InputField name="username" label={labels.klarnaUsername} />
            <PasswordField name="password" label={labels.klarnaPassword} />

            <FormErrorList />
        </FormDialog>
    );
};

function mapStateToProps(state: RootState) {
    return {
        // new way
        errors: _.get(state.settings.paymentSettings, "klarnaConfigSubmission.errors"),
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        actions: bindActionCreators(merchantActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(KlarnaCredentialsDialog);
