import React, { CSSProperties, ReactNode } from 'react';
import classNames from 'classnames';

import { Icon, LoadingIndicator } from '..';
import { IconTypeProps } from './Icon';
import './IconText.scss';

export type IconTextProps = {
    srOnly?: string,
    loading?: boolean,

    id?: string,
    style?: CSSProperties,
    className?: string,
    children: ReactNode,
} & IconTypeProps;


/** Some text with an Icon on its left */
const IconText = ({
    id, className, style, //Styling
    children, srOnly, loading, //data
    ...otherProps
}: IconTextProps) => {
    return (
        <div id={id} className={classNames('icon-text-container', className)} style={style}>
            {loading ? <LoadingIndicator /> : <Icon inline {...otherProps} />}
            {srOnly && <span className='sr-only'>{srOnly}</span>}
            <div className='icon-text-contents'>{children}</div>
        </div>
    );
};

export default IconText;
