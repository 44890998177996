import React from 'react';
import store from '../../../../store/store';

//mapStoreToError = (store) => { return store.accounts.users.errors }
//export default withError(LoginPage, mapStoreToError, mapErrorToString);

/**
 * @param WrappedComponent Your component (eg. a page component)
 * @param {function} mapStoreToError Maps a Redux state to the errors prop.
 *                                   eg. (state) => state.accounts.users.errors
 * @param {function | null} mapErrorToString Maps a backend response (validation error) to a user-friendly error message
 *                                    eg. (error) => errorUtil.getMessage(error, {crn1: 'Reference 1'})
 */
function withErrors(WrappedComponent, mapStoreToError, mapErrorToString = undefined){

    class WithErrors extends React.Component {
        constructor(props){
            super(props);
            this.state = {
                currentErrorValue: null,
                mapStoreToError: mapStoreToError,
                mapErrorToString: mapErrorToString,
                errorToStringCallback: null
            }

            this.handleStoreChanged = this.handleStoreChanged.bind(this);
            this.handleRegisterErrorToStringCallback = this.handleRegisterErrorToStringCallback.bind(this);
        }

        componentDidMount(){
            this.storeUnsubscribe = store.subscribe(this.handleStoreChanged);
        }

        componentWillUnmount(){
            this.storeUnsubscribe();
            delete this.storeUnsubscribe;
        }

        handleStoreChanged(){
            // redux unsubscribe doesn't affect currently queued dispatches
            // so skip if we have arn't subscribed anymore
            if (!this.storeUnsubscribe)
                return;

            var newErrorValue = this.state.mapStoreToError(store.getState());

            if( newErrorValue !== this.state.currentErrorValue ){
                this.setState({
                    currentErrorValue: newErrorValue
                });
            }
        }

        handleRegisterErrorToStringCallback(callback){
            this.setState({
                errorToStringCallback: callback
            });
        }

        getErrorStrings(){
            if (!this.state.currentErrorValue )
                return [];

            if (!Array.isArray(this.state.currentErrorValue)) { // Hardi added this block to hopefully prevent crashes. TODO: Handle this properly (May be from errors thrown by JS?)
                console.error('Unexpected non-array errors:', this.state.currentErrorValue);
                return [this.defaultErrorString(this.state.currentErrorValue) || 'Unknown error occurred'];
            }

            return this.state.currentErrorValue.map(error => {
                if( this.state.errorToStringCallback ){
                    var result = this.state.errorToStringCallback(error);
                    if(result)
                        return result;
                }
                if( this.state.mapErrorToString ){
                    return this.state.mapErrorToString(error) || this.defaultErrorString(error);
                }
                return this.defaultErrorString(error);
            });
        }

        defaultErrorString(error){
            return error.message;
        }

        render(){
            return (<WrappedComponent errors={this.getErrorStrings()} registerErrorToStringCallback={this.handleRegisterErrorToStringCallback} {...this.props}></WrappedComponent>)
        }
    }

    WithErrors.displayName = `WithErrors(${getDisplayName(WrappedComponent)})`;

    return WithErrors;
}

function getDisplayName(WrappedComponent) {
    return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export default withErrors;