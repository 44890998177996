import React from 'react';
import PropTypes from 'prop-types';
import DropdownField from './DropdownField';

/** A font dropdown, where each option's value will be used as the font-family for that option */
const FontDropdownField = ({options, ...otherProps}) => {

    const customStyles = {
        option: (styles, state) => ({
            ...styles,
            fontFamily: state.data.value,
        }),
    };

    return (
        <DropdownField
            options={options}
            styles={customStyles}
            {...otherProps}
        />
    );
};

FontDropdownField.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            /** This is the font-family */
            value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
            label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
            disabled: PropTypes.bool
        })
    ).isRequired,

    name: PropTypes.string,

    /** A more compact version of the dropdown, reducing the width, but keeping the height */
    compact: PropTypes.bool,
    /** The text displayed when no option is selected (Default = Select...) */
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    isSearchable: PropTypes.bool,
    /** Default = false */
    isClearable: PropTypes.bool,

    // Events
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
};

export default FontDropdownField;
