import React from 'react';
import Table from './Table';
import { ScrollingTableRecordPartial, ScrollingTableSharedProps } from './ScrollingTableTypes';
import './ScrollingTableWide.scss';

export type ScrollingTableWideProps<TRecord extends ScrollingTableRecordPartial> = ScrollingTableSharedProps<TRecord> & {
    /** The min-width for the wide/scrolling version, eg. 800 or '10rem' */
    minWidth?: number | string,
};

/** Not to be used directly. Use ScrollingTable instead */
const ScrollingTableWide = <TRecord extends ScrollingTableRecordPartial>(props: ScrollingTableWideProps<TRecord>) => {
    return (
        <div className='scrolling-table-wide'>
            <Table highlightTotal {...props} />
        </div>
    );
};

export default ScrollingTableWide;