import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import * as accountActions from 'components/Account/_actions/accountActions';
import { PlatformRoutesConfiguration } from 'components/Routing';

import { FormHeader, FloatingContainer } from 'components/Common';

const LogoutPage = ({
    authenticatedUser, //state data
    actions //API actions
}) => {

    useEffect(() => {
        if (!authenticatedUser)
            return;

        actions.logoutUser();
    }, []);

    if (!authenticatedUser) {
        return (<Navigate to={PlatformRoutesConfiguration.accountRoute?.logon.generatePath()} />);
    }

    return (
        <FloatingContainer>
            <FormHeader title="" description="Logging off..." />
        </FloatingContainer>

    );
}

function mapStateToProps(state, ownProps) {
    return {
        authenticatedUser: state.accounts.users.authenticatedUser
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(accountActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(LogoutPage);