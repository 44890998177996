import { PageHeader, ProductTooltip } from "components/Common";
import { PageSection, Icon, IconText, Link, Tabs, Tab } from "@premier/ui";
import { Form } from "@premier/form";
import FraudAdvancedForm from "components/Settings/components/FraudSettings/FraudAdvancedForm";
import FraudBasicForm from "components/Settings/components/FraudSettings/FraudBasicForm";
import React, { useCallback, useState } from "react";
import { useFraudSettings } from "components/Settings/_hooks/useFraudSettings";
import { useFraudAdvancedSettings } from "components/Settings/_hooks/useFraudAdvancedSettings";
import { FraudBasicSettingsModel } from "packages/webapi-client";
import SecurityUtil from "@premier/utils/security";
import { userRoles, PlatformRoutesConfiguration } from "components/Routing";
import { useSelector } from "react-redux";

interface Props {}

export const fraudSettingsPageCopy = {
    title: "Fraud management",
    explanation: "Set up rules to prevent fraud",
    failedToLoad: "We were unable to load your fraud rules. Please reload the page or try again later.",
    failedToLoadNavigate: "Back to home",
    fraudBasicInfo: "The following rules only apply to Hosted Payment Page",
    fraudAdvanceInfo: "Fraud advanced rules will override any Fraud basic rules in place",
    failedToUpdate: "Something went wrong processing your request",
    noFraudControlRulesMessage: "No fraud control rules to display",
    multiDropDownMessage: "Select countries to be excluded from the international card block and international IP block",
    multiBillerDropDownMessage: "Select biller codes to be allowed to have anonymous access to hosted payment page",
    multiBillerDropDownMessageCaptcha: "Select biller codes to be excluded from having CAPTCHA on the hosted payment page",
    blockIntlCards: {
        title: "Block international cards",
        subtitle: "Only allow cards issued domestically and the countries added in the multiselect field below",
        value: "blockIntlCards",
        dropdownPlaceholder: "Toggle by country"
    },
    blockIntlIps: {
        title: "Block overseas payments",
        subtitle: "Only allow payments made domestically and the countries added in the multiselect field below",
        value: "blockIntlIps",
    },
    blockPaymentsFromProxy: {
        title: "Block payments via proxy",
        value: "blockPaymentsFromProxy",
    },
    blockPaymentsFromVpn: {
        title: "Block payments via VPN",
        value: "blockPaymentsFromVpn",
    },
    blockPaymentsWithIntlCardAndIntlIp: {
        title: "Block payments where both overseas IP address and overseas card is used",
        subtitle: "Only allow payments where the IP address and/or card is domestic",
        value: "blockPaymentsWithIntlCardAndIntlIp",
    },
    blockPaymentsWhereCardAndIpCountryDiffer: {
        title: "Block international payments where the IP address and card countries don't match",
        subtitle: "Only allow payments where the IP address and card are from the same country, or the IP address is domestic",
        value: "blockPaymentsWhereCardAndIpCountryDiffer",
    },
    blockHppAnonymousAccess: {
        title: "Block anonymous access to hosted payment pages",
        subtitle: "Only allow payments from people who have been sent a payment link, invoice or are making a payment through the API. If you don't intend to make your payment page publicly available, we recommend disabling anonymous access.",
        value: "blockHppAnonymousAccess",
    },
    enableCaptcha: {
        title: "Enable CAPTCHA on hosted payment pages",
        subtitle: "Enable CAPTCHA on your hosted payment pages to help prevent fraudulent activity. If enabled, biller codes in the list below will not have CAPTCHA added.",
        value: "enableCaptcha",
    },
    basicTab: {
        title: "Fraud basic",
        key: "basic",
    },
    advancedTab: {
        title: "Fraud advanced",
        key: "advanced",
    },
    HppAnonymousAccessAdditionalText: "Anonymous access is blocked on your hosted payment pages. To reenable anonymous access or for more information ",
    CaptchaAdditionalText: "Captcha is enabled on your hosted payment pages. For more information ",
};

const FraudSettingsPage: React.FC<Props> = () => {
    const { hasLoaded, settings, updateBasicSettings, isUpdating, hasFailed, errors } = useFraudSettings();

    const [activeTab, setActiveTab] = useState<string | null>(fraudSettingsPageCopy.basicTab.key);
    const { authenticatedUser } = useSelector((state: any) => ({
        authenticatedUser: state.accounts.users.authenticatedUser,
    }));

    const advancedSettings = useFraudAdvancedSettings();

    const handleToggleSettings = useCallback(
        (key: keyof FraudBasicSettingsModel) => {
            const newSettings = {
                ...settings,
                [key]: !settings[key],
            };

            updateBasicSettings(newSettings);
        },
        [settings, updateBasicSettings]
    );

    if (hasFailed)
        return (
            <PageSection>
                <PageHeader title={fraudSettingsPageCopy.title} />
                <IconText alert>{fraudSettingsPageCopy.failedToLoad}</IconText>
                <Link primary to={PlatformRoutesConfiguration.accountRoute?.home.generatePath()}>
                    {fraudSettingsPageCopy.failedToLoadNavigate}
                </Link>
            </PageSection>
        );

    const fraudAdvancedForm = (<FraudAdvancedForm
        fraudRules={advancedSettings.settings?.list}
        pageIndex={advancedSettings.settings?.pageIndex}
        pageSize={advancedSettings.settings?.pageSize}
        totalRules={advancedSettings.settings?.totalCount}
        hasLoaded={advancedSettings.hasLoaded}
        hasFailed={advancedSettings.hasFailed}
        errorMessage={advancedSettings.errors && advancedSettings.errors[0].message}
        noDataText={fraudSettingsPageCopy.noFraudControlRulesMessage}
        onFilterChange={advancedSettings.changeFilters}
        fraudTypeSources={advancedSettings.typeFilter?.items}
        fraudActionSources={advancedSettings.actionFilter?.items}
        uploadFraudRules={advancedSettings.uploadFraudRules}
    />);

    const fraudBasicForm = (<FraudBasicForm
        pageInfoMessage={fraudSettingsPageCopy.fraudBasicInfo}
        settings={settings}
        handleToggleSettings={handleToggleSettings}
        hasLoaded={hasLoaded}
        isUpdating={isUpdating}
    />);

    const getContent = () => {
        if (SecurityUtil.hasAccess([userRoles.fraudBasic], authenticatedUser) && SecurityUtil.hasAccess([userRoles.fraudControl], authenticatedUser)) {
            return (
                <Tabs activeKey={activeTab!} onSelect={(tabKey) => setActiveTab(tabKey)}>
                    <Tab eventKey={fraudSettingsPageCopy.basicTab.key} title={fraudSettingsPageCopy.basicTab.title}>
                        {fraudBasicForm}
                    </Tab>
                    <Tab
                        eventKey={fraudSettingsPageCopy.advancedTab.key}
                        title={fraudSettingsPageCopy.advancedTab.title}
                    >
                        <Form>
                            {fraudAdvancedForm}
                        </Form>
                    </Tab>
                </Tabs>
            );
        } else if (SecurityUtil.hasAccess([userRoles.fraudBasic], authenticatedUser)) {
            return (<Form>
                {fraudBasicForm}
            </Form>);
        } else {
            return (<Form>
                {fraudAdvancedForm}
            </Form>);
        }
    };

    return (
        <PageSection>
            <PageHeader
                title={<>{fraudSettingsPageCopy.title}<ProductTooltip productTooltipModule={"FRAUD_MANAGEMENT_SETTINGS"} /></>}
                subtitle={fraudSettingsPageCopy.explanation}
            />
            {errors && (
                <p>
                    <Icon alert inline /> {fraudSettingsPageCopy.failedToUpdate}
                </p>
            )}
            {getContent()}
        </PageSection>
    );
};

export default FraudSettingsPage;
