import React, { useState } from 'react';

import { PageSection, Button } from '@premier/ui';
import { PageHeader } from 'components/Common';
import { NewBatchFileDialogs, BatchLibraryFiles } from 'components/Batch';

import { PlatformRoutesConfiguration } from 'components/Routing';

const BatchLibrary = () => {
    const [showNewDialogs, setShowNewDialogs] = useState(false);

    return (
        <div className='batch-library-page'>
            <PageSection>
                <PageHeader
                    backButton={{
                        to: PlatformRoutesConfiguration.transactionRoute.batchPayments.generatePath(),
                        label: 'Back to batch payments'
                    }}
                    title='Batch library'
                    subtitle='Process one or more batch files at the same time'
                >
                    <Button onClick={() => {setShowNewDialogs(true)}}>New batch file</Button>
                </PageHeader>
            </PageSection>

            <PageSection>
                <BatchLibraryFiles />
            </PageSection>



            <NewBatchFileDialogs show={showNewDialogs} onClose={() => setShowNewDialogs(false)} />

        </div>
    );
};

export default BatchLibrary;
