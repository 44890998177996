import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Textarea } from '@premier/ui';
import FormGroup from './FormGroup';
import withField from './withField';

const TextareaField = ({ name, formProps, groupProps, ...otherProps }) => {

    const [id] = useState(_.uniqueId(`${name}_`));

    function handleChange(e){
        formProps.setValue(e.target.value);
    }

    return (
        <FormGroup fieldId={id} {...groupProps}>
            <Textarea
                name={name}
                id={id}
                onChange={handleChange}
                value={formProps.value}
                error={!!formProps.error}
                aria-labelledby={groupProps.label && `${id}_label`}
                aria-required={groupProps.mandatory}
                aria-invalid={!!formProps.error}
                aria-describedby={formProps.error && `${id}_error`}
                {...otherProps}
            />
        </FormGroup>
    );
};

TextareaField.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.any
};

export default withField(TextareaField);
