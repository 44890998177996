import { useState } from "react";
import PropTypes from "prop-types";
import { Tab } from "react-bootstrap";

import { IconText, Row, Tabs } from "@premier/ui";
import { HppPreview } from ".";
import "./PreviewTabs.scss";


const PreviewTabs = ({ style, compact, merchant, biller }) => {

    const pages = [
        { key: "payment", title: "Payment page", Component: HppPreview },
        //{key: 'receipt', title: 'Receipt', Component: ReceiptPreview},  // TODO: Reenable after we make relevant changes to Receipt page
    ];

    const [tab, setTab] = useState(pages[0].key);

    return (
        <div className="preview-tabs">
            <Tabs onSelect={key => setTab(key)}>

                {pages.map(page => (
                    <Tab eventKey={page.key} title={page.title} key={page.key}>
                        <Row>
                            {!compact && (
                                <div className="col-lg-8">
                                    <IconText desktop>View on desktop</IconText>
                                    <page.Component style={style} refreshTrigger={tab} merchant={merchant} biller={biller} />
                                </div>
                            )}

                            <div className={compact ? "col-12" : "col-lg-4"}>
                                {!compact && (
                                    <IconText mobile>View on mobile</IconText>
                                )}
                                <page.Component mobile style={style} refreshTrigger={tab} merchant={merchant} biller={biller} />
                            </div>
                        </Row>
                    </Tab>
                ))}

            </Tabs>
        </div>
    );
};

PreviewTabs.propTypes = {
    style: PropTypes.object.isRequired,
    /** Show mobile preview only */
    compact: PropTypes.bool,

    merchant: PropTypes.shape({
        merchantName: PropTypes.string,
    }),
    biller: PropTypes.shape({
        billerCodeWithName: PropTypes.string,
        acceptedCrn1: PropTypes.shape({ label: PropTypes.string }),
        acceptedCrn2: PropTypes.shape({ label: PropTypes.string }),
        acceptedCrn3: PropTypes.shape({ label: PropTypes.string }),
    }),
};

export default PreviewTabs;