import * as actions from './actionTypes';
import { invoiceApi } from 'api';

import { InvoiceConfigModel, InvoiceModel } from '@premier/webapi-client';
import { promiseResultRequestActions } from 'components/Common/requestActions';
import { ApiResult } from 'api/mapErrors';

export let getInvoicesConfig = promiseResultRequestActions(actions.GET_INVOICES_CONFIG, () => invoiceApi.getConfig())
    .send;

export let updateInvoiceConfig = promiseResultRequestActions(
    actions.UPDATE_INVOICES_CONFIG,
    (settings: InvoiceConfigModel) => invoiceApi.updateConfig(settings),
    (settings: InvoiceConfigModel) => ({ settings })
).send;

export let calculateInvoiceTotals = (promiseResultRequestActions(
    actions.CALCULATE_INVOICES_TOTALS,
    (model: InvoiceModel) => invoiceApi.calculateInvoiceTotals(model),
    (model: InvoiceModel) => ({ model })
).send as unknown) as (dispatch: any) => Promise<ApiResult<InvoiceModel>>;
