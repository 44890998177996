import { useEffect } from "react";
import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";

import { Link, PaddedContainer, LoadingIndicator } from "@premier/ui";
import { PreviewTabs } from "../components/brandingPreview";

import { PlatformRoutesConfiguration } from "components/Routing";
import * as designerActions from "components/Settings/_actions/designerActions";
import "./BrandingLandingForMerchant.scss";
import { Biller } from "packages/webapi-client";
import { RootState } from "store/store";
import { Merchant } from "models";

type Props = {
    actions: any;
    merchant?: Merchant;
    biller: Biller | null;
    style: object;
}

/** The content of Branding landing page - for when the 'Set style by billers' is turned off */
const BrandingLandingForMerchant = ({
    actions, merchant, biller, style
}: Props) => {

    useEffect(() => {
        if (!style)
        {
            actions.getBranding();
        }

    });

    return (<>
        <PaddedContainer
            title="Preview"
            button={<Link button to={PlatformRoutesConfiguration.designerRoute?.editMerchantBranding.generatePath()}>Edit</Link>}
            noDivider
            className="preview"
        >
            {!style && <LoadingIndicator />}
            {style &&
                <PreviewTabs merchant={merchant} biller={biller} style={style} />
            }
        </PaddedContainer>
    </>);
};

function mapStateToProps(state: RootState) {
    return {
        merchant: state.accounts.users.merchant,
        style: state.settings.designer.branding.data,
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        actions: bindActionCreators(designerActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(BrandingLandingForMerchant);
