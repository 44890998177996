import React, { ReactNode, useState } from 'react';
import classNames from 'classnames';
import { Link, LoadingIndicator, Checkbox, Button, ButtonContainer, ErrorText, PaddedContainer, RadioInput } from '..';
import { ResponsiveTableSharedProps, ResponsiveTableRecordPartial, ResponsiveTableBatchAction, ResponsiveTableColumn, DEFAULT_SELECTED_ITEMS } from './ResponsiveTableTypes';
import './Table.scss';


export type TableProps<TRecord extends ResponsiveTableRecordPartial> = ResponsiveTableSharedProps<TRecord> & {
    /** Shown above the buttons */
    batchInfo?: ReactNode,
    /** Hides the "All" checkbox */
    batchHideSelectAll?: boolean,

    setSelectedItems?: (items: TRecord[]) => void,

    /** Hides the first column's header (I.E. for things like checkboxes / radio button columns*/
    hideFirstColumnHeader?: boolean,
    /** For returning the number of columns back */
    getNumberOfColumns?: () => number,  // will never be undefined because of the default function
    /** Default: () => true */
    fnShowColumn?: (columnIndex: number) => boolean,

    highlightTotal?: boolean,
    minWidth?: number | string,
};


/** Do not use this directly. Use ResponsiveTable or ScrollingTable instead. */
const Table = <TRecord extends ResponsiveTableRecordPartial>({
    data, columns, isLoading, noDataText,
    sort, onSort,
    getRowLink, onRowLinkClick, rowLinkNewWindow, handleItemSelected,
    batchActions, batchInfo, batchHideSelectAll, singleSelect, selectedItems=DEFAULT_SELECTED_ITEMS, setSelectedItems, onItemSelected,
    highlightTotal, minWidth,  // visual style
    hideFirstColumnHeader,  // for ScrollingTableNarrow
    getNumberOfColumns, fnShowColumn = () => true,  // functions that can be overwritten
}: TableProps<TRecord>) => {

    const [batchActionError, setBatchActionError] = useState<ReactNode>('');

    const hasBatchActions = !!batchActions?.length;

    getNumberOfColumns = getNumberOfColumns ?? (
        () => columns.length + (hasBatchActions ? 1 : 0)  // the default function
    );

    //#region ----- Batch actions (checkboxes + buttons) -----

    function handleRowSelected(item: TRecord) {
        setBatchActionError('');
        handleItemSelected && handleItemSelected(item);
    }

    function handleAllSelected() {
        setBatchActionError('');

        setSelectedItems && setSelectedItems(
            isAllSelected() ? [] : data.filter(x => !x.noBatchActions)
        );
    }

    function isAllSelected() {
        if (!data.length || data.every(item => item.noBatchActions))
            return false;  // no selectables

        return data.every(item => selectedItems.includes(item) || item.noBatchActions);
    }

    function handleBatchActionButtonClick(action: ResponsiveTableBatchAction<TRecord>) {
        if (!selectedItems.length && action.selectionWarnings !== false) {
            setBatchActionError(action.pleaseSelectRowText || 'Please select a row');
            return;
        }

        setBatchActionError('');
        action.handleClick(selectedItems);
    }

    //#endregion

    /** Handle column label click to sort by the column */
    function handleColumnLabelClick(col: ResponsiveTableColumn<TRecord>) {
        if (!col.sortKey) return;

        let isDesc = sort?.field === col.sortKey ? !sort?.descending : false;

        if (onSort && col.sortKey)
            onSort(col.sortKey, isDesc);
    }

    function getColumnHeader(col: ResponsiveTableColumn<TRecord>, colIndex: number) {
        if(hideFirstColumnHeader && colIndex === 0) return null;
        if(!fnShowColumn(colIndex)) return null;

        let sortable = !!col.sortKey;
        let sorted = col.sortKey === sort?.field;
        let isDesc = sort?.descending;
        let sortClass = sortable && sorted && classNames('sorted', { 'desc': isDesc });

        return (
            <th key={colIndex}
                colSpan={hideFirstColumnHeader ? 2 : undefined}
                className={classNames(col.textAlign, col.className, { 'sortable': sortable }, sortClass)}
                aria-sort={(sortable && sorted) ? (isDesc ? 'descending' : 'ascending') : undefined}
            >
                <span onClick={() => handleColumnLabelClick(col)} role={sortable ? 'button' : undefined} tabIndex={sortable ? 0 : undefined}>
                    {col.label}
                    <span className='sr-only' aria-live='assertive'>
                        {sortable && sorted && 'sorted'}
                        {sortable && sorted && (isDesc ? 'descending' : 'ascending')}
                        {sortable && !sorted && '(click to sort)'}
                    </span>
                </span>
            </th>
        );
    }

    function getBatchAllCheckboxHeader() {
        return (
            <th className='check'>
                <Checkbox
                    className={classNames({ 'invisible': !data.some(d => !d.noBatchActions) })}
                    checked={isAllSelected()}
                    onChange={() => {
                        handleAllSelected();
                    }}
                    aria-label='Select all rows'
                >
                    &nbsp;
                </Checkbox>
            </th>
        );
    }

    function getBatchActionColumn(item: TRecord, index: number) {
        if (singleSelect) {
            return (
                <td className='radio'>
                    {!item.noBatchActions && (
                        <RadioInput
                            name={`${index}`}
                            checked={selectedItems.includes(item)}
                            onChange={() => handleRowSelected(item)}
                            aria-label='Select row'
                        >
                            &nbsp;
                        </RadioInput>
                    )}
                </td>
            );
        }

        return (
            <td className='check'>
                {!item.noBatchActions && (
                    <Checkbox
                        checked={selectedItems.includes(item)}
                        onChange={() => handleRowSelected(item)}
                        aria-label='Select row'
                    >
                        &nbsp;
                    </Checkbox>
                )}
            </td>
        );
    }

    function getTableHeader() {
            let hasFilter = columns.some(c => c.filter);

            return (
                <thead className={classNames({ 'has-filter': hasFilter })}>
                    <tr className='labels'>
                        {(hasBatchActions || onItemSelected) && ((hasFilter || singleSelect || batchHideSelectAll) ? <th></th> : getBatchAllCheckboxHeader())}

                        {columns.map((c,i) => getColumnHeader(c, i))}
                </tr>

                {hasFilter && (
                    <tr className='filters'>
                        {(hasBatchActions || onItemSelected) && (singleSelect || batchHideSelectAll ? <th></th> : getBatchAllCheckboxHeader())}

                        {columns.map((col, index) => (
                            <th key={index}>{col.filter}</th>
                        ))}
                    </tr>
                )}

                {isLoading && (
                    <tr className='loading'>
                        <th colSpan={getNumberOfColumns && getNumberOfColumns()}>
                            <LoadingIndicator />
                        </th>
                    </tr>
                )}
            </thead>
        );
    }

    function getCellValue(item: TRecord, col: ResponsiveTableColumn<TRecord>, itemIndex: number) {
        if(typeof col.getter === 'string')
            return item[col.getter];
        else if(typeof col.getter === 'function')
            return col.getter(item, itemIndex);

        throw new Error('Column "'+col.label+'" needs a getter');
    }

    function getTableCell(item: TRecord, col: ResponsiveTableColumn<TRecord>, colIndex: number, itemIndex: number) {
        if(!fnShowColumn(colIndex)) return null;

        let cell : any = getCellValue(item, col, itemIndex);

        if (getRowLink) {
            cell = (
                <Link to={getRowLink(item)} onClick={onRowLinkClick && (() => onRowLinkClick(item))} newWindow={rowLinkNewWindow}>
                    <div>{cell}</div>
                </Link>
            );
        }
        else if (onRowLinkClick) {
            cell = (
                <Button link onClick={() => onRowLinkClick(item)}>
                    <div>{cell}</div>
                </Button>
            );
        }

        return (
            <td key={colIndex} className={classNames(col.textAlign, col.className, { 'cell-link': col.showAsLink })}>
                {cell}
            </td>
        );
    }

    function getTableBody() {
        return (
            <tbody>
                {!data.length && (
                    <tr>
                        <td colSpan={getNumberOfColumns && getNumberOfColumns()}>{noDataText}</td>
                    </tr>
                )}

                {data.map((item, index) => (
                    <tr key={index} className={classNames(item.className, {'clickable': onRowLinkClick || getRowLink})}>
                        {(hasBatchActions || onItemSelected) && getBatchActionColumn(item, index)}

                        {columns.map((col, cIndex) => getTableCell(item, col, cIndex, index))}
                    </tr>
                ))}
            </tbody>
        );
    }

    return (
        <PaddedContainer lessPadding className='table-container' disable={!highlightTotal}>
            <table className={classNames('table', {'highlight-total': highlightTotal})}
                style={{minWidth: minWidth}}
            >
                {getTableHeader()}
                {getTableBody()}
            </table>

            {hasBatchActions && batchActions?.length && data.length > 0 && (
                <>
                    {batchInfo}

                    {batchActionError && (
                        <ErrorText>{batchActionError}</ErrorText>
                    )}

                    <ButtonContainer noTopMargin>
                        {!onItemSelected && batchActions.map((action, index) => (
                            <Button
                                key={index}
                                label={action.batchLabel || action.label}
                                {...action.tableButtonProps}
                                onClick={() => handleBatchActionButtonClick(action)}
                                disabled={action.disabled === undefined ? false : action.disabled(selectedItems)}
                            />
                        ))}
                    </ButtonContainer>
                </>
            )}
        </PaddedContainer>
    );
};

export default Table;
