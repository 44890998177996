import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {QuantityValueChanger} from 'components/StationeryShop';
import { Icon, Button } from '@premier/ui';

import './QuantityController.scss';


const QuantityController = ({
    shoppingCart, readOnly, //logic render
    onCartButtonPress, onQtyUpdate, //functions
    className, //style
    value, minQuantity, maxQuantity //data
}) => {

    const [cartAdded, setCartAdded] = useState(false);

    useEffect(() => {
        if(!cartAdded)
            return;

        const timer = setTimeout(() => setCartAdded(false), 3000);

        return () => clearTimeout(timer);
    }, [cartAdded]);

    var qtyMin = minQuantity || 1; //default to a min of 1 when not set
    var qtyMax = maxQuantity || 99; //default to a max of 99 when not set.

    value = value || qtyMin;

    function handleQtyChange(quantity) {
        value = quantity;
        onQtyUpdate(quantity);
    }

    function returnQty() {
        showCartAdded();
        onCartButtonPress(value);
    }

    function showCartAdded() {
        setCartAdded(true);
    }

    function isReadOnly() {
        return readOnly ?
            `x ${value}` :
            <QuantityValueChanger value={value} onQtyChange={handleQtyChange} qtyMin={qtyMin} qtyMax={qtyMax} />
    }

    return (
        <div className={classNames('quantity-controller-wrapper', {'shopping-cart': shoppingCart, }, className)}>
                {isReadOnly()}
            <div className="button-wrapper">
                {!shoppingCart && <Button onClick={returnQty} className="item-cart-button">Add to cart</Button>}
                {cartAdded && <div className="success-message"><Icon tick /> Added to cart</div>}
            </div>
        </div>
    );
}

QuantityController.propTypes = {
    shoppingCart: PropTypes.bool,
    price: PropTypes.number,
    minQuantity: PropTypes.number,
    maxQuantity: PropTypes.number,
    readOnly: PropTypes.bool,
};

export default QuantityController;
