import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useCurrentBreakpointName } from 'react-socks';

import currencyUtil from '@premier/utils/currency';
import { ResponsiveTable } from '@premier/ui';
import { FormContext, InputField, DropdownField, CurrencyField } from '@premier/form';
import { useDebounce } from 'components/Common';
import labels from 'constants/labels';

import { PlatformRoutesConfiguration } from 'components/Routing';

const PaymentRequestList = ({
    savedFilter, onFilterChange,
    sort, onSort,
    requests,
    requestStatusOptions, requestActionOptions,
    isLoading
}) => {

    const context = React.useContext(FormContext);

    // Update quick filter values from Redux
    useEffect(() => {
        if(savedFilter)
            context.setFormValues(savedFilter);
    }, [savedFilter]);

    /** Triggers onFilterChange (ie. API call) on the parent */
    function handleQuickFilterChange(values) {
        onFilterChange(values || context.values);  // context.values may not be updated yet, pass values if needed
    }
    const debouncedFilterChange = useDebounce(handleQuickFilterChange);

    var isTableView = ['md','lg','xl'].includes(useCurrentBreakpointName());

    // The quick filter fields
    const fields = {
        status: (
            <DropdownField
                name='status'
                options={requestStatusOptions}
                onChange={() => handleQuickFilterChange()}
                compact={isTableView}
                aria-label={labels.status}
            />
        ),
        customerUniqueCode: (
            <InputField
                name='customerUniqueCode'
                onChange={val => debouncedFilterChange({ ...context.values, customerUniqueCode: val })}
                aria-label={labels.customerCode}
            />
        ),
        action: (
            <DropdownField
                name='action'
                options={requestActionOptions}
                onChange={() => handleQuickFilterChange()}
                compact={isTableView}
                aria-label={labels.status}
            />
        ),
        reference1: (
            <InputField
                name='reference1'
                onChange={val => debouncedFilterChange({ ...context.values, reference1: val })}
                aria-label={labels.reference1}
            />
        ),
        deliveryReference: (
            <InputField
                name='deliveryReference'
                onChange={val => debouncedFilterChange({ ...context.values, deliveryReference: val })}
                aria-label={labels.sentTo}
            />
        ),
        amount: (
            <CurrencyField compact
                name='amount'
                onChange={val => debouncedFilterChange({
                    ...context.values,
                    amount: val,
                    ...val && {amountRange: {min: '', max: ''}} // clear the amount range filter, as we should only have 1 set at a time
                })}
                aria-label={labels.amount}
            />
        )
    };

    // The table columns (or list items)
    const columns = [{
        label: labels.status,
        getter: r => r.status.description,
        sortKey: 'status.description',
        filter: fields.status
    }, {
        label: labels.customerCode,
        getter: 'customerV2UniqueCode',
        sortKey: 'customerV2UniqueCode',
        filter: fields.customerUniqueCode
    }, {
        label: labels.action,
        getter: r => r.action.description,
        sortKey: 'action.description',
        filter: fields.action
    }, {
        label: labels.reference1,
        getter: 'reference1',
        sortKey: 'reference1',
        filter: fields.reference1
    }, {
        label: labels.sentTo,
        sortKey: 'deliveryReference',
        getter: (r) => <div className='sent-to'><div>{r.deliveryEmailAddress}</div><div>{r.deliveryPhoneNumber}</div></div>,
        filter: fields.deliveryReference
    }, {
        label: labels.amount,
        sortKey: 'amount',
        getter: r => currencyUtil.convertToDisplayString(r.amount),
        filter: fields.amount
    }];

    return (<>
        <ResponsiveTable
            data={requests}
            className='payment-request-list'
            isLoading={isLoading}
            columns={columns}
            sort={sort}
            onSort={onSort}
            getRowLink={c => PlatformRoutesConfiguration.paymentRequestRoute.requestDetails.generatePath(c.guid)}
            noDataText='No payment requests to display.'
        />
    </>);
};


PaymentRequestList.propTypes = {
    /** The saved filter from Redux (used only to update Form values when it's updated in Redux) */
    savedFilter: PropTypes.object,
    onFilterChange: PropTypes.func,
    /** The current sort order */
    sort: PropTypes.shape({
        field: PropTypes.string,
        descending: PropTypes.bool,
    }),
    /** to handle sort field/order changes */
    onSort: PropTypes.func,

    requests: PropTypes.array,
    requestStatusOptions: PropTypes.array,
    requestActionOptions: PropTypes.array,
    isLoading: PropTypes.bool,
};

export default PaymentRequestList;