import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import { PageSection, DescriptionList, BackToTop, PaginationControl, CardContainer, LoadingIndicator, Button, SuccessModal, Dialog, Icon } from "@premier/ui";
import { Form } from "@premier/form";
import { PageHeader, useInterval } from "components/Common";
import { ScheduledPaymentStatusIcon, ScheduledPaymentTransactionList } from "components/Transactions";
import dateUtil from "@premier/utils/date";
// @ts-ignore
import errorMaps from "constants/errorMaps";
// @ts-ignore
import labels from "constants/labels";
// @ts-ignore
import { defaultPageSize } from "constants/billpay";
import * as paymentActions from "components/Transactions/_actions/paymentActions";
import * as scheduledPaymentActions from "components/Transactions/_actions/scheduledPaymentActions";
import { FieldError } from "api/mapErrors";
import { RootState } from "store/store";
import { ScheduledPaymentModel, TransactionSummaryModel } from "packages/webapi-client";
import { SearchResult } from "models/SearchResult";

import "./ScheduledPaymentTransactionsPage.scss";

const REFRESH_INTERVAL = 5000;
const PAYMENT_PROCESSING_STATUS = "PROCESSING";
const PAYMENT_OVERDUE_STATUS = "OVERDUE";

type Props = {
    scheduledPaymentId: number;
    scheduledPayment: ScheduledPaymentModel;
    searchResult: SearchResult<TransactionSummaryModel>;
    isLoading: boolean;
    actions: any;
    scheduledPaymentActions: any;
    errors: FieldError[];
}

const ScheduledPaymentTransactionsPage = ({
    scheduledPaymentId, scheduledPayment, searchResult, isLoading, //state values
    actions, scheduledPaymentActions, //API actions
    errors, //forms
}: Props) => {
    const [sort, setSort] = useState({ field: "ProcessedDate", descending: true });
    const [reloadPage, setReloadPage] = useState(true);
    const [reloadTransactions, setReloadTransactions] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [autoRefreshing, setAutoRefreshing] = useState(false);
    const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
    const [showCancelSuccess, setShowCancelSuccess] = useState(false);

    useEffect(() => {
        if (reloadPage) {
            scheduledPaymentActions.getScheduledPayment(scheduledPaymentId).then(() => {
                setReloadPage(false);
            });
        }
    }, [reloadPage]);

    useEffect(() => {
        if (reloadTransactions || getScheduledPaymentStatus(scheduledPayment) !== PAYMENT_PROCESSING_STATUS) {
            const filter = {
                txnRequestId: scheduledPaymentId,
                transactionSource: "ALL_BPOINT"
            };
            actions.getScheduledPaymentTransactions(defaultPageSize, currentPage - 1, filter, sort)
                .then(() => setReloadTransactions(false));
        }
    }, [scheduledPayment, reloadTransactions]);

    useEffect(() => {
        const paymentStatus = getScheduledPaymentStatus(scheduledPayment);
        if (paymentStatus === PAYMENT_PROCESSING_STATUS)
            setAutoRefreshing(true);
        else
            setAutoRefreshing(false);

    }, [scheduledPayment]);

    useInterval(() => {
        setReloadPage(true);
    }, autoRefreshing ? REFRESH_INTERVAL : null);

    function handleSort(field: string, descending: boolean) {
        setSort({ field, descending });
        setReloadTransactions(true);
    }

    function pageChanged(newPage: number) {
        setCurrentPage(newPage);
        setReloadTransactions(true);
    }

    function getPrefixDetails() {
        if (!scheduledPayment)
            return [];

        return [
            { name: labels.processedPayments, value: scheduledPayment.attemptCount },
            { name: labels.successfulPayments, value: scheduledPayment.successfulPayments },
        ];
    }

    function handleCancel() {
        scheduledPaymentActions.cancelScheduledPayment(scheduledPaymentId)
            .then(() => {
                setShowCancelConfirmation(false);
                setShowCancelSuccess(true);
            });
    }

    function handleRetry() {
        scheduledPaymentActions.retryScheduledPayment(scheduledPaymentId)
            .then(() => {
                setReloadPage(true);
            });
    }

    function getScheduledPaymentStatus(payment: ScheduledPaymentModel) {
        return payment?.scheduledPaymentStatus?.key;
    }

    function handleHideSuccessModal() {
        setShowCancelSuccess(false);
        setReloadPage(true);
    }

    return (<>
        {isLoading && scheduledPayment === undefined && (
            <CardContainer header="Loading transactions">
                <LoadingIndicator />
            </CardContainer>
        )}

        {scheduledPayment !== undefined && (
            <Form
                errors={errors}
                errorMaps={errorMaps}
            >
                <PageSection className="scheduled-payment-transactions-page">
                    <PageHeader
                        backButton
                        title={<ScheduledPaymentStatusIcon scheduledPayment={scheduledPayment} />}
                        subtitle={<>
                            <h2>Scheduled payment on {scheduledPayment?.scheduledPaymentDate ? dateUtil.convertToDateString(scheduledPayment?.scheduledPaymentDate) : ""}</h2>
                            <DescriptionList greyLabel items={getPrefixDetails()} />
                        </>}
                    >
                        {getScheduledPaymentStatus(scheduledPayment) === PAYMENT_OVERDUE_STATUS && <>
                            <Button primary onClick={handleRetry}>Retry payment</Button>
                            <Button onClick={() => setShowCancelConfirmation(true)}>Cancel payment</Button>
                        </>}
                    </PageHeader>

                    <h2>Transactions records</h2>
                    <ScheduledPaymentTransactionList
                        sort={sort}
                        onSort={handleSort}
                        transactions={searchResult?.items || []}
                        isLoading={reloadTransactions || searchResult === undefined}
                    />

                    <PaginationControl currentPage={currentPage} onPageChanged={pageChanged} itemCount={searchResult?.count || 0} itemsPerPage={defaultPageSize} />

                    <BackToTop />
                </PageSection>
            </Form>
        )}

        <Dialog
            show={showCancelConfirmation}
            icon={<Icon alert />}
            title="Cancel payment?"
            dialogClassName="confirm-cancel-payment-modal"
            closeButton
            onClose={() => setShowCancelConfirmation(false)}
            footerButtons={<>
                <Button default onClick={handleCancel}>Cancel payment</Button>
                <Button default onClick={() => setShowCancelConfirmation(false)}>Close</Button>
            </>}
        />

        <SuccessModal show={showCancelSuccess} onClose={handleHideSuccessModal}>
            Payment cancelled successfully
        </SuccessModal>
    </>);
};

function mapStateToProps(state: RootState, ownProps: any) {
    return {
        scheduledPaymentId: ownProps.match.params.id,
        scheduledPayment: state.transactions.scheduledPayment.details,
        searchResult: state.transactions.payments.scheduledPaymentTransactionsSearchResult,
        isLoading: state.transactions.payments.isLoading,
        errors: state.transactions.payments.errors,
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        actions: bindActionCreators(paymentActions, dispatch),
        scheduledPaymentActions: bindActionCreators(scheduledPaymentActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ScheduledPaymentTransactionsPage);
