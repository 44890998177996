
/**
 * Combines URL segments seperate with /
 */
export function combine(...paths: string[]) {
    let url = '';
    for (let i = 0; i < paths.length; i++) {
        const path = paths[i];
        if (!path)
            continue;
        if (!url.length)
            url = path;
        else if (url.endsWith('/'))
            url += path.replace(/^\/+/, '');
        else
            url += '/' + path.replace(/^\/+/, '');
    }

    return url;
}

/**
 * Combines URL segments seperate with / and changes the URL to absolute, assumes relative to domain, not current path
 */
export function absolute(...paths: string[]) {
    let url = combine(...paths);

    if (!url.match(/^[a-z]+:\/\//)) {
        url = combine(window.location.origin, url);
    }

    return url;
}
