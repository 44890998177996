import { requestActionTypes } from 'components/Common/requestActions';

// Payment Settings actions
export const GET_PAYMENT_METHODS = requestActionTypes('GET_PAYMENT_METHODS');
export const GET_KLARNA_CONFIG = requestActionTypes('GET_KLARNA_CONFIG');
export const PUT_KLARNA_CONFIG = requestActionTypes('PUT_KLARNA_CONFIG');

// Branding actions
export const GET_BRANDING = requestActionTypes('GET_BRANDING');
export const SAVE_BRANDING = requestActionTypes('SAVE_BRANDING');
export const SAVE_BRANDING_WITH_LOGO = requestActionTypes('SAVE_BRANDING_WITH_LOGO');
export const SAVE_AND_PUBLISH_BRANDING = requestActionTypes('SAVE_AND_PUBLISH_BRANDING');
export const DISCARD_BRANDING_DRAFT = requestActionTypes('DISCARD_BRANDING_DRAFT');
export const SET_BRANDING_BY_BILLER = requestActionTypes('SET_BRANDING_BY_BILLER');

// Fraud actions
export const GET_FRAUD_BASIC_SETTINGS = requestActionTypes('GET_FRAUD_BASIC_SETTINGS');
export const GET_BILLERS_FRAUD_BASIC_SETTINGS = requestActionTypes('GET_BILLERS_FRAUD_BASIC_SETTINGS');
export const UPDATE_FRAUD_BASIC_SETTINGS = requestActionTypes('UPDATE_FRAUD_BASIC_SETTINGS');
export const UPDATE_BILLER_FRAUD_BASIC_SETTINGS = requestActionTypes('UPDATE_BILLER_FRAUD_BASIC_SETTINGS');
export const GET_FRAUD_ADVANCED_SETTINGS = requestActionTypes('GET_FRAUD_ADVANCED_SETTINGS');
export const UPLOAD_FRAUD_RULES = requestActionTypes('UPLOAD_FRAUD_RULES');
export const GET_FRAUD_ACTION_SOURCE = requestActionTypes('GET_FRAUD_ACTION_SOURCE');
export const GET_FRAUD_TYPE_SOURCE = requestActionTypes('GET_FRAUD_TYPE_SOURCE');

// iServices actions
export const UPLOAD_ISERVICE_CRN_RULES = requestActionTypes('UPLOAD_ISERVICE_CRN_RULES');

// invoice actions
export const GET_INVOICES_CONFIG = requestActionTypes('GET_INVOICES_CONFIG');
export const UPDATE_INVOICES_CONFIG = requestActionTypes('UPDATE_INVOICES_CONFIG');
export const CALCULATE_INVOICES_TOTALS = requestActionTypes('CALCULATE_INVOICES_TOTALS');

// message actions
export const GET_MESSAGING_SETTINGS = requestActionTypes('GET_MESSAGING_SETTINGS');
export const UPDATE_MESSAGING_SETTINGS = requestActionTypes('UPDATE_MESSAGING_SETTINGS');

// User actions (old way)
export const GET_USERS_REQUESTED = 'GET_USERS_REQUESTED';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAILED = 'GET_USERS_FAILED';

export const GET_USER_ROLES_REQUESTED = 'GET_USER_ROLES_REQUESTED';
export const GET_USER_ROLES_SUCCESS = 'GET_USER_ROLES_SUCCESS';
export const GET_USER_ROLES_FAILED = 'GET_USER_ROLES_FAILED';

export const SAVE_MANAGE_USERS_PAGE_STATE = 'SAVE_MANAGE_USERS_PAGE_STATE';

export const RESET_USER_PASSWORD_REQUESTED = 'RESET_USER_PASSWORD_REQUESTED';
export const RESET_USER_PASSWORD_SUCCESS = 'RESET_USER_PASSWORD_SUCCESS';
export const RESET_USER_PASSWORD_FAILED = 'RESET_USER_PASSWORD_FAILED';

export const GET_USER_REQUESTED = 'GET_USER_REQUESTED';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILED = 'GET_USER_FAILED';

export const ADD_USER_REQUESTED = 'ADD_USER_REQUESTED';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_USER_FAILED = 'ADD_USER_FAILED';

export const EDIT_USER_REQUESTED = 'EDIT_USER_REQUESTED';
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS';
export const EDIT_USER_FAILED = 'EDIT_USER_FAILED';

export const DELETE_USER_REQUESTED = 'DELETE_USER_REQUESTED';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILED = 'DELETE_USER_FAILED';
