import { ReactNode } from "react";
import classNames from "classnames";
import "./FloatingContainer.scss";

type Props = {
    children?: ReactNode;
    className?: string;
}

const FloatingContainer = ({
    children, //data
    className //style
}: Props) => {

    return (
        <div className={classNames("floating-container", className)}>
            <div className="floating-panel-container">
                {children}
            </div>
        </div>
    );
};

export default FloatingContainer;