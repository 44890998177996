import {combineReducers} from 'redux';
import * as actions from '../_actions/actionTypes';
import { requestActionReducer } from 'components/Common/requestActions';

function pageStateReducer(state = {}, action) {
    switch(action.type) {
        case actions.SAVE_BATCH_PAYMENT_REQUEST_PAGE_STATE:
            return Object.assign({}, state, {...action.pageState});

        default:
            return state;
    }
}

const batchPaymentRequestReducer = combineReducers({
    requestPageState: pageStateReducer,
    batches: requestActionReducer(actions.GET_PAYMENT_REQUEST_BATCHES),
});

export default batchPaymentRequestReducer;