import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import labels from 'constants/labels';
import * as billpayUtil from '@premier/utils/billpay';
import { ResponsiveTable } from '@premier/ui';
import { FormContext, InputField } from '@premier/form';
import { useDebounce } from 'components/Common';
import { PlatformRoutesConfiguration } from 'components/Routing';
import './CustomerList.scss';


const CustomerList = ({
    savedFilter,sort,  //filter data
    onFilterChange, onSort, //filter functions
    onLinkClick, //function
    customers, //data
    isLoading //logic render
}) => {

    const context = React.useContext(FormContext);

    // Update quick filter values from Redux
    useEffect(() => {
        if(savedFilter)
            context.setFormValues(savedFilter);
    }, [savedFilter]);

    const debouncedFilterChange = useDebounce(onFilterChange);

    /** Triggers onFilterChange (ie. API call) on the parent after a delay */
    function handleQuickFilterChange() {
        debouncedFilterChange(context.values);
    }

    function handlePhoneChange(val) {
        // Sync with phoneNumber in full filter
        context.setValue('phoneNumber', billpayUtil.formatPhoneApiStringToObject(val));

        let updatedValues = {
            ...context.values,
            phone: val,
            phoneNumber: billpayUtil.formatPhoneApiStringToObject(val)
        };
        debouncedFilterChange(updatedValues);
    }


    const fields = {  // The quick filter fields
        uniqueId: <InputField name='uniqueId' onChange={handleQuickFilterChange} />,
        name: <InputField name='name' onChange={handleQuickFilterChange} />,
        email: <InputField name='emailAddress' onChange={handleQuickFilterChange} />,
        phone: <InputField name='phone' onChange={handlePhoneChange} />,
        crn1: <InputField name='crn1' onChange={handleQuickFilterChange} />,
        crn2: <InputField name='crn2' onChange={handleQuickFilterChange} />,
        crn3: <InputField name='crn3' onChange={handleQuickFilterChange} />,
    }

    const columns = [{
        label: labels.customerCode,
        getter: 'uniqueId',
        sortKey: 'uniqueId',
        filter: fields.uniqueId,
        className: 'customer-code',
    },{
        label: labels.name,
        sortKey: 'name',
        getter: customer => billpayUtil.getCustomerFullName(customer),
        filter: fields.name,
    },{
        label: labels.email,
        getter: 'emailAddress',
        sortKey: 'emailAddress',
        filter: fields.email,
    },{
        label: labels.phone,
        getter: customer => billpayUtil.formatPhoneObjectToApiString(_.get(customer, 'phoneNumbers[0].phoneNumber')),
        filter: fields.phone,
    },{
        label: labels.customerId1,
        getter: 'crn1',
        sortKey: 'crn1',
        filter: fields.crn1,
    },{
        label: labels.customerId2,
        getter: 'crn2',
        sortKey: 'crn2',
        filter: fields.crn2,
    },{
        label: labels.customerId3,
        getter: 'crn3',
        sortKey: 'crn3',
        filter: fields.crn3,
    }];

    return (
        <ResponsiveTable className='customer-list'
            data={customers}
            columns={columns}
            isLoading={isLoading}
            sort={sort}
            onSort={onSort}
            getRowLink={c => PlatformRoutesConfiguration.customerRoute.viewCustomer.generatePath(c.customerId)}
            onRowLinkClick={onLinkClick}
        />
    );
};

CustomerList.propTypes = {
    /** The saved filter from Redux (used only to update Form values when it's updated in Redux) */
    savedFilter: PropTypes.object,
    onFilterChange: PropTypes.func,
    /** The current sort order */
    sort: PropTypes.shape({
        field: PropTypes.string,
        descending: PropTypes.bool,
    }),
    /** to handle sort field/order changes */
    onSort: PropTypes.func,

    /** What happens when redirecting to view customer details (eg. to save the current page state into Redux) */
    onLinkClick: PropTypes.func,

    customers: PropTypes.array,
    isLoading: PropTypes.bool,
};

export default CustomerList;
