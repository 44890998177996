import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import dateUtil from '@premier/utils/date';
import { ResponsiveTable, IconText } from '@premier/ui';
import labels from 'constants/labels';

const BatchPaymentRequestList = ({
    sort, onSort,
    requests,
    isLoading
}) => {

    function getStatusIcon(request) {
        const statusKey = _.get(request, 'status.key');

        switch (statusKey) {
            case 'BATCHMANAGER':
            case 'DELETED':
            case 'DONOTPROCESS':
            case 'UNSPECIFIED':
                return {'info': true};

            case 'INPROGRESS':
            case 'LOADING':
            case 'LOADING_BATCHMANAGER':
            case 'LOADING_DV':
            case 'LOADING_PAYMENTREQUEST':
            case 'PENDING':
                return {'loading': true};

            case 'COMPLETE':
            case 'COMPLETE_DV':
            case 'COMPLETE_PAYMENTREQUEST':
                return {'tick': true};

            case 'ERROR':
            default:
                return {'alert': true};
        }
    }

    // The table columns (or list items)
    const columns = [{
        label: labels.filename,
        getter: 'filename',
        sortKey: 'filename'
    }, {
        label: labels.status,
        sortKey: 'status',
        getter: r => <IconText {...getStatusIcon(r)}>{r.status.description}</IconText>
    }, {
        label: labels.uploadedRecordCount,
        getter: 'uploadedRecordCount',
        sortKey: 'uploadedRecordCount'
    }, {
        label: labels.submittedDateTime,
        sortKey: 'submittedDateTime',
        getter: r => dateUtil.convertToDateTimeString(r.submittedDateTime)
    }];

    return (<>
        <ResponsiveTable
            data={requests}
            className='batch-request-list'
            isLoading={isLoading}
            columns={columns}
            sort={sort}
            onSort={onSort}
            noDataText='No batch customer requests to display.'
        />
    </>);
};


BatchPaymentRequestList.propTypes = {
    /** The current sort order */
    sort: PropTypes.shape({
        field: PropTypes.string,
        descending: PropTypes.bool,
    }),
    /** to handle sort field/order changes */
    onSort: PropTypes.func,

    requests: PropTypes.array,
    isLoading: PropTypes.bool,
};

export default BatchPaymentRequestList;