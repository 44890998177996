import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { RootState } from "store/store";
import { getMerchantOptions } from "@premier/utils/billpay";
import { userRoles } from "components/Routing";
import { Merchant } from "packages/utils/models";
import { FormikDropdown, FormikDropdownOption } from "packages/formik-ui";
import { Dropdown, UiOption } from "packages/ui";

import "./MerchantPageCountDropdowns.scss";

type Props = {
    merchant: Merchant;
    name?: string;
    feature?: userRoles;
    allOption?: boolean;
    useDefaultValue?: boolean; // Only used by Dropdown and not by FormikDropdown
    onChange?: (selectedOption: FormikDropdownOption | null) => void;
}

/**
 * Merchant dropdown component, rendering a dropdowns of parent merchant & it's child merchants.
 * Allows for custom class names, labels, an allOption to render the 'all' option.
 */
const MerchantDropdown = ({
    merchant, //state values
    name, feature, //data
    allOption, //logic render
    useDefaultValue = false,
    onChange //function
}: Props) => {
    const [selectedOption, setSelectedOption] = useState<FormikDropdownOption | null>(null);

    useEffect(() => {
        if (selectedOption) {
            onChange && onChange(selectedOption);
        }
    }, [selectedOption]);

    useEffect(() => {
        if (useDefaultValue && !selectedOption) {
            const firstOption = getOptions()[0];
            setSelectedOption(firstOption);
        }
    }, [merchant]);

    const handleBasicChange = (selectedOption: UiOption<string> | null) => {
        setSelectedOption({
            label: selectedOption?.label as string,
            value: selectedOption?.value ?? "",
        });
    };

    const getOptions = () => {
        return getMerchantOptions(merchant, allOption || false, feature);
    };

    const getDefaultValue = () => {
        return useDefaultValue ? (allOption ? "" : merchant.merchantNumber) : undefined;
    }

    return (<>
        {(merchant?.childMerchants?.length ?? 0) > 0 &&
            (name ?
                <FormikDropdown
                    name={name || "merchantNumber"}
                    options={getOptions()}
                    // No need to pass in onChange handler as Formik will handle it for us
                /> :
                <Dropdown
                    options={getOptions()}
                    defaultValue={getDefaultValue()}
                    onChange={handleBasicChange}
                    value={selectedOption?.value as string}
                />)
        }
    </>);
};

function mapStateToProps(state: RootState) {
    return {
        merchant: state.accounts.users.merchant
    };
}

export default connect(mapStateToProps)(MerchantDropdown);
