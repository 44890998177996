import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import { Navigate } from "react-router-dom";
import SecurityUtil, { User } from "@premier/utils/security";
import * as billpayUtil from "@premier/utils/billpay";
import * as paramUtil from "@premier/utils/param";
import { PageSection, LoadingIndicator, IconText, Link, Button, Dialog, Icon, BackButton, Divider, PaddedContainer, Row, SuccessModal } from "@premier/ui";
import { PageHeader } from "components/Common";
import { CustomerPaymentMethods, CustomerDetails, CustomerSummarySubtitle, CustomerPaymentModal } from "components/DataVault";
import { OverdueSchedulesBanner } from "components/Transactions";
import * as customerActions from "components/DataVault/_actions/customerActions";
import * as scheduleActions from "components/Transactions/_actions/scheduleActions";
import { PlatformRoutesConfiguration } from "components/Routing";
import { userRoles } from "components/Routing";
import { Customer, Merchant } from "packages/utils/models";
import { RootState } from "store/store";
import CustomerPaymentRequestModal from "../components/CustomerPaymentModals/CustomerPaymentRequestModal";

import "./ViewCustomerPage.scss";

export const viewCustomerPageCopy = {
    invoices: {
        title: "Invoices",
        explanation: "Issue invoice and request payment from the customer",
        viewInvoicesLink: "View existing invoices",
        newInvoiceButton: "New invoice",
    }
};

type Props = {
    actions: any;
    scheduleActions: any;
    customer: Customer;
    authenticatedUser: User;
    overduePaymentsCount: number;
    merchant: Merchant;
    customerId?: number;
    isLoading: boolean;
}

const ViewCustomerPage = ({
    actions, scheduleActions, //apiActions
    customer, authenticatedUser, overduePaymentsCount, merchant, //state
    customerId, //urlParam
    isLoading //logic render
}: Props) => {

    const [showNewPaymentModal, setShowNewPaymentModal] = useState(false);
    const [showNewPaymentRequestModal, setShowNewPaymentRequestModal] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showDeleteSuccessModal, setShowDeleteSuccessModal] = useState(false);
    const [reload, setReload] = useState(true);
    const [redirectBack, setRedirectBack] = useState(false);
    const [redirectToLink, setRedirectToLink] = useState(false);
    const [redirectToSubscriptions, setRedirectToSubscriptions] = useState(false);

    const canDoOneOffPayment = SecurityUtil.hasAccess([userRoles.singlePayment], authenticatedUser);
    const canDoPaymentRequest = SecurityUtil.hasAccess([userRoles.paymentRequest], authenticatedUser);
    const canDoRecurringSchedule = SecurityUtil.hasAccess([userRoles.recurringSchedule], authenticatedUser);

    useEffect(() => {
        if (reload) {
            actions.getCustomer(customerId).then(() => {
                setReload(false);
            });

            if (canDoRecurringSchedule)
                scheduleActions.getOverduePaymentsCount({ customerId });
        }
    }, [reload, customerId]);


    function handlePaymentModalClose(reload: boolean) {
        setShowNewPaymentModal(false);

        if (reload)
            setReload(true);
    }

    function handlePaymentRequestModalClose(reload: boolean) {
        setShowNewPaymentRequestModal(false);

        if (reload)
            setReload(true);
    }

    function handlePaymentRequestSuccessModalClose(showNewPaymentRequestModal?: boolean) {
        setReload(true);
        setShowNewPaymentRequestModal(showNewPaymentRequestModal ?? false);
    }

    //#region ----- Delete -----
    function handleDeleteConfirmed() {
        setShowDeleteDialog(false);

        actions.deleteCustomer(customer.customerId).then(() => {
            setShowDeleteSuccessModal(true);
        });
    }

    function handleDeleteSuccessModalClose() {
        setShowDeleteSuccessModal(false);
        setRedirectBack(true);
    }
    //#endregion

    function getSinglePaymentText() {
        if (canDoOneOffPayment && canDoPaymentRequest) {
            return "Make a one-off payment or request a payment";
        } else if (canDoOneOffPayment) {
            return "Make a one-off payment";
        } else if (canDoPaymentRequest) {
            return "Request a payment";
        }
        return "";
    }

    if (redirectBack)
        return <Navigate to={PlatformRoutesConfiguration.customerRoute?.manageCustomers.generatePath()!} />;

    if (redirectToLink)
        return <Navigate to={PlatformRoutesConfiguration.customerRoute?.linkCustomerToTokens.generatePath(customer.customerId)!} />;

    if (redirectToSubscriptions)
        return <Navigate to={PlatformRoutesConfiguration.transactionRoute?.manageSchedules.generatePath() + "?inArrears=true"} />;


    // ---- Render -----

    if (!customer)
        return (
            <PageSection>
                <BackButton to={PlatformRoutesConfiguration.customerRoute?.manageCustomers.generatePath()}/>
                { isLoading ? <LoadingIndicator /> : <IconText alert>Customer not found or an error occurred.</IconText> }
            </PageSection>
        );

     return (
        <div className="customer-details-page">
            <PageSection>
                <PageHeader
                    backButton={{ to: PlatformRoutesConfiguration.customerRoute?.manageCustomers.generatePath() }}
                    title={billpayUtil.getCustomerFullName(customer)}
                    subtitle={<CustomerSummarySubtitle customer={customer} />}
                />

                {isLoading && <LoadingIndicator />}

                { canDoRecurringSchedule &&
                        <OverdueSchedulesBanner
                            overduePaymentsCount={overduePaymentsCount}
                            onManageClick={() => setRedirectToSubscriptions(true)}
                        />
                }

                <CustomerDetails
                    customer={customer}
                />

                <PaddedContainer
                    className="payments-section"
                    icon={<Icon money />}
                    title="Payments"
                >
                    <Row divided>
                        <div className="col-lg-6">
                            <h3>Single payment</h3>
                            <p>{getSinglePaymentText()}</p>
                                { canDoOneOffPayment && SecurityUtil.childMerchantHasAccess(customer.childMerchantNumber, merchant, userRoles.singlePayment, authenticatedUser) &&
                                    <Link subtle to={PlatformRoutesConfiguration.customerRoute?.customerTransactions.generatePath(customer.customerId)}>
                                        View transaction history
                                    </Link> }
                            <div>
                                { canDoOneOffPayment && SecurityUtil.childMerchantHasAccess(customer.childMerchantNumber, merchant, userRoles.singlePayment, authenticatedUser) &&
                                    <Button primary onClick={() => setShowNewPaymentModal(true)}>New payment</Button> }
                                { canDoPaymentRequest && SecurityUtil.childMerchantHasAccess(customer.childMerchantNumber, merchant, userRoles.paymentRequest, authenticatedUser) &&
                                    <Button primary onClick={() => setShowNewPaymentRequestModal(true)}>New payment request</Button> }
                            </div>
                        </div>

                        { canDoRecurringSchedule && <>
                            <div className="col-lg-6">
                                <Divider className="d-lg-none" />

                                <h3>Subscription</h3>
                                <p>Schedule a recurring payment</p>
                                <Link subtle to={PlatformRoutesConfiguration.customerRoute?.viewCustomerSubscriptions.generatePath(customer.customerId)}>
                                    View subscriptions
                                </Link>
                                <Link primary to={PlatformRoutesConfiguration.customerRoute?.newCustomerSchedule.generatePath(customer.customerId)}>New subscription</Link>
                            </div>
                        </> }
                    </Row>
                    <Divider />

                    <CustomerPaymentMethods
                        tokens={customer.tokens}
                        onTokensUpdated={() => setReload(true)}
                        onRedirectToLink={() => setRedirectToLink(true)}
                    />
                </PaddedContainer>

                { SecurityUtil.hasAccess([userRoles.invoices], authenticatedUser) && (merchant.merchantNumber === customer.childMerchantNumber) &&
                    <PaddedContainer
                        title={viewCustomerPageCopy.invoices.title}
                        lessMargin
                        noDivider
                        button={
                            <Link button to={PlatformRoutesConfiguration.customerRoute?.newCustomerInvoice.generatePath(customer.customerId)}>{viewCustomerPageCopy.invoices.newInvoiceButton}</Link>
                        }
                    >
                        {viewCustomerPageCopy.invoices.explanation}&nbsp;&nbsp;
                        <Link to={PlatformRoutesConfiguration.customerRoute?.manageCustomerInvoices.generatePath(customer.customerId)}>{viewCustomerPageCopy.invoices.viewInvoicesLink}</Link>
                    </PaddedContainer>
                }

                <Button subtle onClick={() => setShowDeleteDialog(true)}>Delete customer</Button>
            </PageSection>


            {/* Payment modal */}
            {customer && (
                <CustomerPaymentModal
                    show={showNewPaymentModal}
                    onClose={handlePaymentModalClose}
                    customer={customer}
                />
            )}

            {/* Payment request modal */}
            {customer && (
                <CustomerPaymentRequestModal
                    show={showNewPaymentRequestModal}
                    onClose={handlePaymentRequestModalClose}
                    onSuccessModalClose={handlePaymentRequestSuccessModalClose}
                    customer={customer}
                />
            )}


            <Dialog show={showDeleteDialog}
                title="Delete customer?"
                icon={<Icon alert />}
                footerButtons={<>
                    <Button onClick={handleDeleteConfirmed}>Delete</Button>
                    <Button onClick={() => setShowDeleteDialog(false)}>Cancel</Button>
                </>}
            >
                Customer profile will be deleted. Payment methods, transactions, invoices and payment requests will still be available.
            </Dialog>

            <SuccessModal show={showDeleteSuccessModal} onClose={handleDeleteSuccessModalClose}>
                Customer deleted successfully
            </SuccessModal>
        </div>
    );
};

function mapStateToProps(state: RootState, ownProps: any) {
    const customerId = paramUtil.asInteger(ownProps.match.params.id);

    return {
        customer: state.dataVault?.customer?.details?.customerId === customerId ? state.dataVault.customer.details: undefined,
        customerId: customerId,
        isLoading: state.dataVault.customer.isLoading,
        authenticatedUser: state.accounts.users.authenticatedUser,
        merchant: state.accounts.users.merchant,
        overduePaymentsCount: state.transactions?.schedule?.overduePaymentsCount?.data?.resultCount,
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        actions: bindActionCreators(customerActions, dispatch),
        scheduleActions: bindActionCreators(scheduleActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewCustomerPage);
