import React, { ReactNode } from 'react';
import { Tooltip, Button, Icon, ButtonProps } from '..';
import './SubtleButtonTooltip.scss';

type Props = ButtonProps & {
    /** ID to connect tooltip to icon, needs to be unique to render properly. */
    tooltipId: string,
    /** data to display within the tooltip section */
    tooltipData: string | ReactNode,
    /** hide the button, useful when button should only be shown under certain conditions */
    hide?: boolean,
    children: ReactNode,
}

/** A template for a subtle button with a tooltip attached to it
 * Tooltip can be either simple text, or a node
 */
const SubtleButtonTooltip = ({
    tooltipId, tooltipData, //tooltip data
    children,
    hide, //logic renders
    ...otherProps //other button props (i.e. onClick)
}: Props) => {
    if (hide) return null;

    return (<>
        <div className='button-icon-tooltip'>
            <Button subtle {...otherProps}>
                {children}
            </Button>
            <Icon question data-tip data-for={tooltipId} />
        </div>

        <Tooltip id={tooltipId} >
            {tooltipData}
        </Tooltip>
    </>);
};

export default SubtleButtonTooltip;
