export default {
    images: `${process.env.PUBLIC_URL}/media/images`,
    batchFileSpec: '',
    batchApcaFileSpec: '',
    fraudControlFileFormat: '',
    iServicesCrnUploadFormat: '',
    testingBpointPhoneInternetDDCC: '',
    exportFileLoadFormatv2: '',
    directDebitCsvFormat: '',
    directDebitVposFormat: '',
    fraudControlFileFormatAndSample: '',
    prefillingFields: '',
    paymentLinkGeneratorBatchCSVFormat: '',
}