import React from 'react';
import { connect } from 'react-redux';
import labels from 'constants/labels';

import { NewPaymentForm } from 'components/Transactions';
import { SingleColumnFormContainer, Link, Row, PageSection } from '@premier/ui';
import { PageHeader, ProductTooltip } from 'components/Common';

import { PlatformRoutesConfiguration } from 'components/Routing';

const NewPaymentPage = ({ userHasTokens }) => {

    function showToken() {
        return userHasTokens;
    }

    return (
        <div className = 'new-payment-page'>
            <PageSection>
                <PageHeader
                    title={<>{'New payment'}<ProductTooltip productTooltipModule={"NEW_PAYMENT_TRANSACTIONS"} /></>}
                    subtitle='Make a one-off payment'
                />
            </PageSection>

            <PageSection>
                <Row divided>
                    <div className='col-md-12 col-lg-6'>
                        <h2>Use stored payment account</h2>
                        <p>To search by {labels.customerCode}, email or phone number, please go to
                            <Link to={PlatformRoutesConfiguration.customerRoute.manageCustomers.generatePath()} > Customers</Link>
                        </p>
                    </div>

                    {showToken() && (
                        <div className='col-md-12 col-lg-6 token-reference'>
                            <h2>&nbsp;</h2>
                            <p>To search by token reference, please go to
                                <Link to={PlatformRoutesConfiguration.tokenRoute.manageTokens.generatePath()}> Payment methods</Link>
                            </p>
                        </div>
                    )}
                </Row>
            </PageSection>
            <PageSection>
                <SingleColumnFormContainer>
                    <NewPaymentForm />
                </SingleColumnFormContainer>
            </PageSection>
        </div>
    );
}

function mapStateToProps(state, ownProps){
    var showTokens = state.accounts.users.authenticatedUser.features.includes('DATA_VAULT');

    return {
        userHasTokens: showTokens
    };
}

export default connect(mapStateToProps)(NewPaymentPage)
