// React Router V5 used to have an wrapping component called withRouter.
// This has been removed from V6 but a subtitiute is required since the legacy code base utilize this for its Redux mapping.
// See https://stackoverflow.com/questions/71097375/how-can-i-use-withrouter-in-react-router-v6-for-passing-props
import {
    useParams
  } from "react-router-dom";
import React from 'react';

// Provide params a prop because some components will consume this
const LegacyPropsProvider = ({children}) => {
    let params = useParams();
    // Consumers will look for props.match.params (appears to be legacy access)
    return <>{React.cloneElement(children, { match: { params }})}</>;
}

export default LegacyPropsProvider;