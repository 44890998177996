import { requestActionTypes } from 'components/Common/requestActions';

// Customer actions
export const GET_CUSTOMERS_REQUESTED = 'GET_CUSTOMERS_REQUESTED';
export const GET_CUSTOMERS_SUCCESS = 'GET_CUSTOMERS_SUCCESS';
export const GET_CUSTOMERS_FAILED = 'GET_CUSTOMERS_FAILED';
export const SAVE_CUSTOMERS_PAGE_STATE = 'SAVE_CUSTOMERS_PAGE_STATE';
export const SAVE_CUSTOMER_COUNT = 'SAVE_CUSTOMER_COUNT';

export const GET_CUSTOMER_REQUESTED = 'GET_CUSTOMER_REQUESTED';
export const GET_CUSTOMER_SUCCESS = 'GET_CUSTOMER_SUCCESS';
export const GET_CUSTOMER_FAILED = 'GET_CUSTOMER_FAILED';

export const ADD_CUSTOMER_REQUESTED = 'ADD_CUSTOMER_REQUESTED';
export const ADD_CUSTOMER_SUCCESS = 'ADD_CUSTOMER_SUCCESS';
export const ADD_CUSTOMER_FAILED = 'ADD_CUSTOMER_FAILED';

export const UPDATE_CUSTOMER_REQUESTED = 'UPDATE_CUSTOMER_REQUESTED';
export const UPDATE_CUSTOMER_SUCCESS = 'UPDATE_CUSTOMER_SUCCESS';
export const UPDATE_CUSTOMER_FAILED = 'UPDATE_CUSTOMER_FAILED';

export const DELETE_CUSTOMER_REQUESTED = 'DELETE_CUSTOMER_REQUESTED';
export const DELETE_CUSTOMER_SUCCESS = 'DELETE_CUSTOMER_SUCCESS';
export const DELETE_CUSTOMER_FAILED = 'DELETE_CUSTOMER_FAILED';

export const LINK_TOKEN_REQUESTED = 'LINK_TOKEN_REQUESTED';
export const LINK_TOKEN_SUCCESS = 'LINK_TOKEN_SUCCESS';
export const LINK_TOKEN_FAILED = 'LINK_TOKEN_FAILED';

export const UNLINK_TOKEN_REQUESTED = 'UNLINK_TOKEN_REQUESTED';
export const UNLINK_TOKEN_SUCCESS = 'UNLINK_TOKEN_SUCCESS';
export const UNLINK_TOKEN_FAILED = 'UNLINK_TOKEN_FAILED';

// Token actions
export const ADD_TOKEN_REQUESTED = 'ADD_TOKEN_REQUESTED';
export const ADD_TOKEN_SUCCESS = 'ADD_TOKEN_SUCCESS';
export const ADD_TOKEN_FAILED = 'ADD_TOKEN_FAILED';

export const UPDATE_TOKEN_REQUESTED = 'UPDATE_TOKEN_REQUESTED';
export const UPDATE_TOKEN_SUCCESS = 'UPDATE_TOKEN_SUCCESS';
export const UPDATE_TOKEN_FAILED = 'UPDATE_TOKEN_FAILED';
export const CLEAR_TOKEN_CACHE = 'CLEAR_TOKEN_CACHE';

export const DELETE_TOKEN_REQUESTED = 'DELETE_TOKEN_REQUESTED';
export const DELETE_TOKEN_SUCCESS = 'DELETE_TOKEN_SUCCESS';
export const DELETE_TOKEN_FAILED = 'DELETE_TOKEN_FAILED';

export const LOAD_TOKENS_REQUESTED = 'LOAD_TOKENS_REQUESTED';
export const LOAD_TOKENS_SUCCESS = 'LOAD_TOKENS_SUCCESS';
export const LOAD_TOKENS_FAILED = 'LOAD_TOKENS_FAILED';

export const GET_TOKEN_REQUESTED = 'GET_TOKEN_REQUESTED';
export const GET_TOKEN_SUCCESS = 'GET_TOKEN_SUCCESS';
export const GET_TOKEN_FAILED = 'GET_TOKEN_FAILED';

export const SAVE_TOKENS_PAGE_STATE = 'SAVE_TOKENS_PAGE_STATE';
export const SAVE_TOKEN_COUNT = 'SAVE_TOKEN_COUNT';

export const GET_CUSTOMER_SUBSCRIPTIONS = requestActionTypes('GET_CUSTOMER_SUBSCRIPTIONS');
export const UPLOAD_TOKENS_BATCH = requestActionTypes('UPLOAD_TOKENS_BATCH');

// Invoice actions
export const SEARCH_INVOICES = requestActionTypes('SEARCH_INVOICES');
export const GET_INVOICE_STATUS_SOURCE = requestActionTypes('GET_INVOICE_STATUS_SOURCE');
export const CREATE_INVOICE = requestActionTypes('CREATE_INVOICE');
export const GET_INVOICE_BILLERS = requestActionTypes('GET_INVOICE_BILLERS');
