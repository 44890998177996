import moment from "moment";
import { IconText, Button } from "@premier/ui";
import { DatePickerField, FormDialog, validate, SubmitButton } from "@premier/form";
import { FilenameSubheading } from "components/Batch";

type Props = {
    show: boolean;
    filenames: string[];
    /** (value) => {} where value is the selected date, or null when cancelled. */
    onCancel: () => void;
    onSchedule: (date: Date) => void;
}

const ScheduleDialog = ({
    show, //logic render
    filenames, //data
    onCancel, onSchedule //functions
}: Props) => {

    return (
        <FormDialog show={show}
            title="Schedule batch file"
            initialValues={{ date: null }}
            initialValidation={{ date: validate().required() }}
            onSubmit={values => onSchedule(values.date[0])}
            renderButtons={context => (<>
                <SubmitButton>Schedule</SubmitButton>
                <Button onClick={onCancel}>Cancel</Button>
            </>)}
        >
            <FilenameSubheading filenames={filenames} />

            <IconText info>
                File may be processed at anytime after 1am on the selected date.
            </IconText>

            <DatePickerField name="date" label="Select processing date"
                minDate={moment().add(1, "days").startOf("day").toDate()}
                maxDate={moment().add(6, "weeks").startOf("day").toDate()}
            />

        </FormDialog>
    );
};

export default ScheduleDialog;
