import { NewPaymentRequestForm } from "components/PaymentRequests";
import { Token } from "models/Token";
import { Customer } from "packages/utils/models";

type Props = {
    show: boolean;
    customer: Customer;
    selectedToken: Token;
    onClose: (reload: boolean) => void;
    onSuccessModalClose?: (showNewPaymentRequestModal?: boolean) => void;
}

const TokenPaymentRequestModal = ({
    show, customer, selectedToken,
    onClose, onSuccessModalClose
}: Props) => {
    return (
        <NewPaymentRequestForm
            dialog
            tokenPage
            showDialog={show}
            customer={customer}
            selectedToken={selectedToken}
            onDialogCloseClicked={onClose}
            onSuccessModalClose={onSuccessModalClose}
        />
    );
};

export default TokenPaymentRequestModal;
