import {combineReducers} from 'redux';

import { requestActionReducer } from 'components/Common/requestActions';
import * as actions from '../_actions/actionTypes';

function scheduleReducer(state = {}, action) {
    switch(action.type) {
        case actions.SAVE_SCHEDULES_PAGE_STATE:
            return Object.assign({}, state, {...action.pageState});

        default:
            return state;
    }
}

export default combineReducers({
    details: requestActionReducer(actions.GET_SCHEDULE),
    add: requestActionReducer(actions.ADD_SCHEDULE),
    update: requestActionReducer(actions.UPDATE_SCHEDULE),
    cancel: requestActionReducer(actions.CANCEL_SCHEDULE),
    suspend: requestActionReducer(actions.SUSPEND_SCHEDULE),
    resume: requestActionReducer(actions.RESUME_SCHEDULE),
    overduePaymentsCount: requestActionReducer(actions.GET_OVERDUE_PAYMENTS_COUNT),
    scheduleFrequencies: requestActionReducer(actions.GET_SCHEDULE_FREQUENCIES),
    schedulePaymentStatuses: requestActionReducer(actions.GET_SCHEDULE_PAYMENT_STATUSES),
    scheduleStatuses: requestActionReducer(actions.GET_SCHEDULE_STATUSES),
    searchResult: requestActionReducer(actions.GET_SCHEDULES),
    schedulesPageState: scheduleReducer
});