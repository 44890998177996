import React from 'react';
import { Button, Icon } from '@premier/ui';
// @ts-ignore
import mediaPaths from 'constants/mediaPaths';

import './IServicesSection.scss';

interface TextObject {
    title: string;
    explanation: string;
    link: string;
    button: string;
    selectedButton: string;
}
interface Props {
    text: TextObject;
    fileName: string;
    fileSize: number;
    errorMessage: string;
    onClickFileUpload: () => void;
}

const IServicesUploadSection: React.FC<Props> = ({
    text,
    fileName,
    fileSize,
    errorMessage,
    onClickFileUpload,
}) => {
    const buttonText = fileName ? text.selectedButton : text.button;

    return (
        <>
            <div className='iservices-section-heading'>
                <h2>{text.title}</h2>
            </div>
            <p>
                {text.explanation}
                <a
                    href={mediaPaths.iServicesCrnUploadFormat}
                    target='_blank'
                    rel='noopener noreferrer'
                >
                    {text.link}
                </a>
            </p>

            <div className='iservices-file-indicator-container'>
                <Button
                    type={'submit'}
                    onClick={onClickFileUpload}
                    className='iservices-file-indicator-button'
                >
                    {buttonText}
                </Button>

                {fileName && (
                    <div className='iservices-file-indicator'>
                        <Icon tick className='iservices-file-indicator-icon' />
                        <div className='iservices-file-indicator-text'>
                            {fileName} ({fileSize} kb)
                        </div>
                    </div>
                )}
            </div>

            {errorMessage && (
                <p className='iservices-error-message'>{errorMessage}</p>
            )}
        </>
    );
};

export default IServicesUploadSection;
