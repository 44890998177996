import React from 'react';
import PropTypes from 'prop-types';

import requiredIf from '@premier/utils/requiredIf';
import dateUtil from '@premier/utils/date';
import currencyUtil from '@premier/utils/currency';

import { ResponsiveTable } from '@premier/ui';
import { ScheduledPaymentStatusIcon } from 'components/Transactions';



import labels from 'constants/labels';

const ScheduledPaymentList = ({
    sortable, sort, payments, //data
    onSort, onRowLinkClick, getRowLink, //functions
    isLoading, hideAttemptsColumn //logic renders
}) => {

    // The table columns (or list items)
    const columns = [{
        label: labels.scheduledDate,
        sortKey: sortable ? 'scheduledPaymentDate' : undefined,
        getter: item => dateUtil.convertToDateString(item.scheduledPaymentDate),
    }, {
        label: labels.status,
        sortKey: sortable ? 'scheduledPaymentStatus' : undefined,
        getter: s => <ScheduledPaymentStatusIcon scheduledPayment={s} />,
    }, {
        label: labels.amount,
        sortKey: sortable ? 'amountPaid' : undefined,
        getter: item => currencyUtil.convertToDisplayString(item.amountPaid, item.currencyCode),
    }];

    if (!hideAttemptsColumn) {
        columns.push({
            label: labels.attempts,
            sortKey: sortable ? 'attemptCount' : undefined,
            getter: 'attemptCount',
        });
    }

    return (<>
        <ResponsiveTable
            data={payments}
            className='scheduled-payments-list'
            isLoading={isLoading}
            columns={columns}
            sort={sort}
            onSort={onSort}
            getRowLink={getRowLink}
            onRowLinkClick={onRowLinkClick}
            noDataText='No payments to display.'
        />
    </>);
};

ScheduledPaymentList.propTypes = {
    /** Data for the list is loading */
    isLoading: PropTypes.bool,

    /** Is list sortable */
    sortable: PropTypes.bool,

    /** The current sort order */
    sort: requiredIf(PropTypes.shape({
        field: PropTypes.string,
        descending: PropTypes.bool,
    }), props => props.sortable),

    /** The collection of payments to show in the list */
    payments: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        scheduledPaymentDate: PropTypes.string.isRequired,
        status: PropTypes.string,
        amountPaid: PropTypes.number.isRequired,
        attemptCount: PropTypes.number.isRequired,
      })),

    /** to handle sort field/order changes */
    onSort: requiredIf(PropTypes.func, props => props.sortable),

    getRowLink: PropTypes.func,
    /** What happens when redirecting to view item details (eg. to save the current page state into Redux) */
    onRowLinkClick: PropTypes.func,
    hideAttemptsColumn: PropTypes.bool
};

export default ScheduledPaymentList;
