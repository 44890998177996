import * as accountActions from 'components/Account/_actions/actionTypes';
import * as actions from '../_actions/actionTypes';

export default function stationeryShopReducer(state = {}, action) {
    switch(action.type) {
        // Categories
        case actions.GET_STATIONERY_CATEGORIES_SUCCESS:
            return Object.assign({}, state, {
                stationeryCategories: action.stationeryCategories
            });

        // Products + Freight
        case actions.GET_STATIONERY_PRODUCTS_SUCCESS:
            return Object.assign({}, state, {
                stationeryProducts: action.stationeryProducts
            });

        case actions.GET_STATIONERY_FREIGHT_SUCCESS:
            return Object.assign({}, state, {
                freightProduct: action.freightProduct
            });
    
        // Shopping Cart
        case actions.GET_CART_SUCCESS:
        case actions.ADD_TO_CART_SUCCESS:
        case actions.UPDATE_CART_ITEM_QTY_SUCCESS:
            return Object.assign({}, state, {
                cart: action.cart
            });

        case actions.CLEAR_CART_SUCCESS:
            return Object.assign({}, state, {
                cart: null
            }); 
            
        // Orders
        case actions.GET_STATIONERY_ORDERS_SUCCESS:
            return Object.assign({}, state, {
                orders: action.orders
            });

        case actions.SAVE_ORDERS_PAGE_STATE:
            return Object.assign({}, state, {
                ordersPageState: action.pageState
            });

        case actions.GET_RECENT_ADDRESSES_SUCCESS:
            return Object.assign({}, state, {
                recentAddresses: action.addresses
            });

        case actions.GET_ORDER_DETAIL_SUCCESS:
            return Object.assign({}, state, {
                orderDetail: action.orderDetail
            });

        case actions.SET_DELIVERY_ADDRESS:
            return Object.assign({}, state, {
                deliveryAddress: action.address
        });
    
        case actions.CREATE_ORDER_SUCCESS:
            return Object.assign({}, state, {
                lastOrder: action.orderDetail
            });



        case accountActions.LOGOUT_USER_SUCCESS:
            return {
                asyncCount: state.asyncCount,
                isLoading: state.isLoading,
            };
            
        default:
            return state;
    }
}
