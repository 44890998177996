import React from 'react';
import './AppContainer.scss';

const PublicContainer = ({
    children //data
}) => {
    return (
        <main className="public-body-container">
            {children}
        </main>
    );
}

export default PublicContainer;