import _ from "lodash";
import httpRequest from "./httpRequest";
import dateUtil from "@premier/utils/date";
import * as billpayUtil from "@premier/utils/billpay";
import { defaultPageSize } from "constants/billpay";
import { mapTokenToDto } from "./tokenApi";
import mapErrors from "./mapErrors";

class CustomerApi {
    static getCustomers(resultsPerPage = defaultPageSize, pageNumber = 1, filter = {}, sort = { field: "lastUpdated", descending: true }) {
        function mapSortField(field) {
            switch (field) {
                case "firstName":
                    return "name";
                default:
                    return field;
            }
        }

        return new Promise((resolve, reject) => {
            const dto = {
                request: {
                    childMerchantNumber: filter.childMerchantNumber,
                    uniqueId: filter.uniqueId || undefined,
                    name: filter.name || undefined,
                    emailAddress: filter.emailAddress || undefined,
                    phoneNumber: filter.phone || undefined,
                    crn1: filter.crn1 || undefined,
                    crn2: filter.crn2 || undefined,
                    crn3: filter.crn3 || undefined,
                    dob: dateUtil.convertDateToApiValue(_.get(filter.dob, "[0]")) || undefined,
                    postcode: filter.postcode || undefined,
                },
                sortField: mapSortField(sort.field),
                order: sort.descending ? "DESCENDING" : "ASCENDING",
                pageIndex: pageNumber - 1,
                pageSize: resultsPerPage
            };

            httpRequest.post("/Customer/GetCustomers", dto)
                .then(response => {
                    let customers = response.list;
                    const resultCount = response.totalCount;

                    customers = customers.map(c => ({
                        ...c,
                        phoneNumbers: c.phoneNumbers.map(p => ({
                            ...p,
                            phoneNumber: billpayUtil.formatPhoneApiStringToObject(p.phoneNumber)
                        }))
                    }));

                    resolve({ customers, resultCount });
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    static getCustomer(customerId) {
        const dto = {
            customerId: parseInt(customerId)
        };

        return new Promise((resolve, reject) => {
            httpRequest
                .post("/Customer/GetCustomer", dto)
                .then(customer => {
                    resolve({
                        ...customer,
                        phoneNumbers: customer.phoneNumbers.map(p => ({
                            ...p,
                            phoneNumber: billpayUtil.formatPhoneApiStringToObject(p.phoneNumber),
                        })),
                    });
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    static addCustomer(customer) {
        const dto = {
            legalName: customer.legalName,
            crn1: _.get(customer, "crn1"),
            crn2: _.get(customer, "crn2"),
            crn3: _.get(customer, "crn3"),
            emailAddress: customer.emailAddress,
            title: customer.title,
            firstName: customer.firstName,
            lastName: customer.lastName,
            dob: customer.dob && dateUtil.convertDateToApiValue(Array.isArray(customer.dob) ? customer.dob[0] : customer.dob),
            currencyCode: customer.currencyCode,
            website: customer.website,
            note: customer.note,
            addresses: customer.addresses,
            phoneNumbers: customer.phoneNumbers
                .filter(p => !!p.phoneNumber && !!p.phoneNumber.phoneNumber)  // remove empty phones
                .map(p => ({
                    typeId: p.typeId,
                    phoneNumber: billpayUtil.formatPhoneObjectToApiString(p.phoneNumber),
                })),
            childMerchantNumber: customer.childMerchantNumber
        };

        return new Promise((resolve, reject) => {
            httpRequest
                .post("/Customer/AddCustomer", dto)
                .then(customer => {
                    resolve(customer);
                })
                .catch(err => {
                    reject(mapErrors(err));
                });
        });
    }

    static updateCustomer(customer) {
        const dto = {
            customerId: customer.customerId,
            legalName: customer.legalName,
            crn1: _.get(customer, "crn1"),
            crn2: _.get(customer, "crn2"),
            crn3: _.get(customer, "crn3"),
            emailAddress: customer.emailAddress,
            title: customer.title,
            firstName: customer.firstName,
            lastName: customer.lastName,
            dob: customer.dob && dateUtil.convertDateToApiValue(Array.isArray(customer.dob) ? customer.dob[0] : customer.dob),
            currencyCode: customer.currencyCode,
            website: customer.website,
            note: customer.note,
            addresses: customer.addresses,
            phoneNumbers: customer.phoneNumbers
                .filter(p => !!p.phoneNumber && !!p.phoneNumber.phoneNumber)  // remove empty phones
                .map(p => ({
                    typeId: p.typeId,
                    phoneNumber: billpayUtil.formatPhoneObjectToApiString(p.phoneNumber),
                })),
        };

        return new Promise((resolve, reject) => {
            httpRequest
                .post("/Customer/UpdateCustomer", dto)
                .then(customer => {
                    resolve(customer);
                })
                .catch(err => {
                    reject(mapErrors(err));
                });
        });
    }
    static deleteCustomer(customerId) {
        const dto = {
            customerId: customerId
        };

        return new Promise((resolve, reject) => {
            httpRequest
                .post("/Customer/DeleteCustomer", dto)
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    static addTokenForCustomer(tokenRequest) {
        const dto = mapTokenToDto(tokenRequest);

        return new Promise((resolve, reject) => {
            httpRequest
                .post("/Customer/AddToken", dto)
                .then(token => {
                    resolve(token);
                })
                .catch(err => {
                    reject(mapErrors(err, mapAddCustomerTokenErrorsFromDto));
                });
        });
    }


    static linkToken(customerId, tokenIds) {
        const dto = {
            customerId,
            dataVaultIds: tokenIds
        };

        return new Promise((resolve, reject) => {
            httpRequest
                .post("/Customer/LinkToken", dto)
                .then(() => {
                    resolve();
                })
                .catch(err => {
                    console.log(err);
                    reject(mapErrors(err));
                });
        });
    }

    static unlinkToken(customerId, tokenIds) {
        const dto = {
            customerId,
            dataVaultIds: tokenIds
        };

        return new Promise((resolve, reject) => {
            httpRequest
                .post("/Customer/UnlinkToken", dto)
                .then(() => {
                    resolve();
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }
}

//#region Error Mapping

function mapAddCustomerTokenErrorsFromDto(parameter) {
    switch (parameter) {
        case "cardNumber":
            return "paymentTypeInput.card.cardNumber";
        case "expiryDate":
            return "paymentTypeInput.card.expiryDate";
        case "accountName":
            return "paymentTypeInput.bankAccountDetails.accountName";
        case "bsbNumber":
            return "paymentTypeInput.bankAccountDetails.bsbNumber";
        default:
            return parameter;
    }
}
//#endregion

export default CustomerApi;