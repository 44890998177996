import React, { useState } from "react";

import { PageSection, Link, Button, Divider, Row } from "@premier/ui";
import { PageHeader, ProductTooltip } from "components/Common";
import { BatchJobs, NewBatchFileDialogs } from "components/Batch";

import { PlatformRoutesConfiguration } from "components/Routing";

import "./BatchPayments.scss";
import SecurityUtil from "@premier/utils/security";
import { userRoles } from "components/Routing";
import { useSelector } from "react-redux";
import { RootState } from "store/store";

const BatchPayments = () => {
    const [showNewDialogs, setShowNewDialogs] = useState(false);
    const { authenticatedUser } = useSelector((state: RootState) => ({
        authenticatedUser: state.accounts.users.authenticatedUser,
    }));
    return (
        <div className="batch-payments-page">
            <PageSection>
                <PageHeader
                    title={<>{"Batch payments"}<ProductTooltip productTooltipModule={"BATCH_PAYMENTS_TRANSACTIONS"} /></>}
                    subtitle="Manage and process batch files"
                />
            </PageSection>

            <PageSection>
                <Row divided>
                    {SecurityUtil.hasAccess([userRoles.batchPayment], authenticatedUser) &&<div className="col-md-12 col-lg-6">
                        <h2>New batch file</h2>
                        <p>Upload your batch file (.csv) or create one manually.<br/>
                            <Link to={PlatformRoutesConfiguration.transactionRoute?.batchHowTo.generatePath()} >How to create a batch file</Link>
                        </p>
                        <Button primary onClick={() => {setShowNewDialogs(true);}}>New batch file</Button>
                    </div>}
                    
                    {SecurityUtil.hasAccess([userRoles.batchManager], authenticatedUser) && <div className="col-md-12 col-lg-6">
                        <Divider className="d-lg-none" />
                        <h2>Process batch file</h2>
                        <p>Use or modify existing batch files to process payments.</p>
                        <Link subtle to={PlatformRoutesConfiguration.transactionRoute?.batchLibrary.generatePath()}>View batch library</Link>
                    </div>}
                </Row>
            </PageSection>

            {SecurityUtil.hasAccess([userRoles.batchPayment], authenticatedUser) && <PageSection>
                <h2>My batch files</h2>
                <p>View the status of your batch payments or select one or more processed batch files to download reports</p>
                <BatchJobs />
            </PageSection>}
           
            <NewBatchFileDialogs show={showNewDialogs} onClose={() => setShowNewDialogs(false)} />

        </div>
    );
};

export default BatchPayments;
