import classNames from "classnames";
import { Dropdown, DropdownProps } from "..";
import "./TitleDropdown.scss";

type Props<TValue extends string | number> = DropdownProps<TValue> & {
    icon?: "account";
    arrowLabel?: string;
};

/** A huge dropdown that can be used as a heading. Can have an icon and an arrow-label. */
const TitleDropdown = <TValue extends string | number>({
    icon, arrowLabel="", ...otherProps
}: Props<TValue>) => {

    const cssClass = classNames(
        "title-dropdown",
        { "has-icon": !!icon },
        `icon-${icon}`,
    );

    const customStyles = {
        valueContainer: (styles: any) => ({
            ...styles,
            ":after": {
                content: `'${arrowLabel}'`,
            },
        }),
    };

    return (
        <Dropdown
            className={cssClass}
            styles={customStyles}
            {...otherProps}
        />
    );
};

export default TitleDropdown;