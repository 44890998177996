import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Button, Divider, ErrorText } from '@premier/ui';

import './OverdueSchedulesBanner.scss';

/** A Banner to display the number of overdue subscriptions & an optional link to manage them */
const OverdueSchedulesBanner = ({
    overduePaymentsCount, //data
    onManageClick, //functions
}) => {

    function getOverdueAlertMessage() {
        return overduePaymentsCount > 1
            ? `${overduePaymentsCount} subscriptions have overdue payment`
            : `${overduePaymentsCount} subscription has overdue payment`
    }

    return (
        <div className={classNames({ 'd-none': (!overduePaymentsCount || overduePaymentsCount === 0) })}>
            <Divider />
            <div className='overdue-alert'>
                <ErrorText>{getOverdueAlertMessage()}</ErrorText>
                { onManageClick &&
                    <Button link onClick={onManageClick}>Manage subscriptions in arrears</Button>
                }
            </div>
            <Divider />
        </div>
    );
}

OverdueSchedulesBanner.propTypes = {
    /** the number of overdue schedules */
    numberOfOverdueSchedules: PropTypes.bool,

    /** function called when the manage link is clicked */
    onManageClick: PropTypes.func,
};

export default OverdueSchedulesBanner;