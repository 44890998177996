import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import mediaPaths from 'constants/mediaPaths';

import './ProductThumbnail.scss';

const ProductThumbnail = ({ 
    src, altText, //data
    small, large, className //style
}) => {
    return (
        <div className={classNames("product-thumbnail-container", {
            'small': small,
            'large': large
        }, className)}>
            <img src={getImageSrc(src)} alt={altText} />
        </div>
    );
}

function getImageSrc(filename){
    return `${mediaPaths.images}/${filename}`;
}

ProductThumbnail.propTypes = {
    // Required
    src: PropTypes.string.isRequired,
    altText: PropTypes.string.isRequired,

    small: PropTypes.bool,
    large: PropTypes.bool,
};

export default ProductThumbnail;