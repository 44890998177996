import { useState, useEffect } from "react";
import { Dispatch, bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Form, SubmitButton, RadioField, SwitchField, FormErrorList } from "@premier/form";
import {
    ButtonContainer,
    PaddedContainer,
    PageSection,
    Link,
    IconText,
    LoadingIndicator,
    SuccessModal,
    TooltipTrigger,
    Icon,
    Tooltip,
} from "@premier/ui";
import * as paymentRequestActions from "components/PaymentRequests/_actions/paymentRequestActions";
import { BackofficeDeeplink, PageHeader } from "components/Common";
import { PlatformRoutesConfiguration } from "components/Routing";
import {
    PaymentRequestDeliveryMethodEnum,
    AcceptablePaymentTypeEnum,
    ExpirePaymentLinkEnum,
} from "platforms/base/constants/billpay";
import * as accountActions from "components/Account/_actions/accountActions";
import { useMessagingSettings } from "components/Settings/_hooks/useMessagingSettings";
import { FieldError } from "api/mapErrors";
import { RootState } from "store/store";
import { Biller } from "models/Biller";
import { MerchantBillerDetailsForm } from "components/Transactions";
import { Merchant } from "models";

type Props = {
    requestConfig: any;
    updateRequestConfig: any;
    merchant: Merchant;
    billers: Biller[];
    errors: FieldError[];
    paymentRequestActions: any;
    loading: boolean;
}

export const MessagingRequestsPage = ({
    requestConfig,
    updateRequestConfig,
    merchant,
    billers,
    errors,
    paymentRequestActions,
    loading
}: Props) => {
    const { hasFailed, hasSucceeded, isLoading, data: requestData } = requestConfig;
    const { hasSucceeded: hasUpdated } = updateRequestConfig;

    const { settings, hasLoaded: hasLoadedEmailSetting } = useMessagingSettings();

    const [selectedMerchant, setSelectedMerchant] = useState<string>()
    const [selectedBiller, setSelectedBiller] = useState<string | null>(null);
    const [resetForm, setResetForm] = useState(false);

    useEffect(() => {
        if (selectedMerchant && selectedBiller) {
            paymentRequestActions.getRequestConfig(selectedMerchant, selectedBiller);
        }
    }, [selectedMerchant, selectedBiller]);

    useEffect(() => {
        if (resetForm) {
            setResetForm(false);
        }
    }, [resetForm]);

    useEffect(() => {
        const parentMerchant = {
            merchantNumber: merchant.merchantNumber,
            merchantName: merchant.merchantName,
            isParent: true
        };

        const childMercs =
            merchant.childMerchants &&
            merchant.childMerchants.map((cm: any) => ({
                merchantNumber: cm.merchantNumber,
                merchantName: cm.merchantName,
                isParent: false,
            }));
        const merchants = [parentMerchant].concat(childMercs || []);
        setSelectedMerchant(merchants[0].merchantNumber);

        if (billers.length > 0) {
            setSelectedBiller(billers[0].billerCode);
        }
    }, []);

    function handleMerchantChange(merchant: Merchant | undefined) {
        if (!merchant) {
            return;
        }

        setSelectedMerchant(merchant.merchantNumber);
        setSelectedBiller(null);
        setResetForm(true);
        paymentRequestActions.clearRequestConfig();
        paymentRequestActions.clearUpdateRequestConfig();
    }

    function handleBillerChange(billerCodeObj: any) {
        if (billerCodeObj === null) {
            return;
        }
        setSelectedBiller(billerCodeObj.billerCode);
        setResetForm(true);
        paymentRequestActions.clearRequestConfig();
        paymentRequestActions.clearUpdateRequestConfig();
    }

    function handleSubmit(e: any) {
        paymentRequestActions.clearUpdateRequestConfig();
        paymentRequestActions.updateRequestConfig(selectedMerchant, selectedBiller, e);
    }

    function isSMSActive(hasLoadedEmailSetting: boolean, settings: any) {
        return hasLoadedEmailSetting && settings.smsEnabled;
    }

    function isSMSEnabled(caption: string) {
        return (
            <div className="isSMSEnabled">
                {caption}
                {!isSMSActive(hasLoadedEmailSetting, settings) && (
                    <TooltipTrigger tipId="tip-sms">
                        <Icon question />
                    </TooltipTrigger>
                )}
            </div>
        );
    }

    function getDeliveryMethodOptions(barSMSLink: boolean) {

        // Exclude SMS for Payment Requests.
        if (barSMSLink) {
            return [
                { value: PaymentRequestDeliveryMethodEnum.EMAIL_ONLY, label: "Email only" },
                {
                    value: PaymentRequestDeliveryMethodEnum.EMAIL_AND_SMS,
                    label: isSMSEnabled("Both Email and SMS"),
                    disabled: !isSMSActive(hasLoadedEmailSetting, settings),
                },
            ];
        }

        return [
            { value: PaymentRequestDeliveryMethodEnum.EMAIL_ONLY, label: "Email only" },
            {
                value: PaymentRequestDeliveryMethodEnum.SMS_ONLY,
                label: isSMSEnabled("SMS only"),
                disabled: !isSMSActive(hasLoadedEmailSetting, settings),
            },
            {
                value: PaymentRequestDeliveryMethodEnum.EMAIL_AND_SMS,
                label: isSMSEnabled("Both Email and SMS"),
                disabled: !isSMSActive(hasLoadedEmailSetting, settings),
            },
        ];
    }

    if (hasFailed) {
        return (
            <PageSection>
                <PageHeader title="Error" />
                <IconText alert>An unexpected error has occurred.</IconText>
            </PageSection>
        );
    }

    return (
        <PageSection>
            <PageHeader title="Requests" backButton={{ to: PlatformRoutesConfiguration.messagingRoute?.root.generatePath() }} />
            <Form>
                <MerchantBillerDetailsForm onMerchantChange={handleMerchantChange} onBillerChange={handleBillerChange} tokenDisplay={false} hideCrnFields={true} />
            </Form>

            {isLoading && <LoadingIndicator />}

            {hasSucceeded && requestData && (
                <Form
                    name="messagingRequestsPage"
                    onSubmit={handleSubmit}
                    initialValues={{
                        deliveryMethod: requestData.deliveryMethod,
                        acceptablePaymentAmountType: requestData.acceptablePaymentAmountType,
                        expirePaymentLink: requestData.expirePaymentLink,
                        dueDateMandatory: requestData.dueDateMandatory,
                        expiryDateMandatory: requestData.expiryDateMandatory,
                    }}
                    errors={errors}
                    resetForm={resetForm}
                    render={(context) => {
                        return (
                            <>
                                <PaddedContainer title="Requests mode" lessMargin noDivider>
                                    <p>Select the mode that a request could be delivered to your customers</p>
                                    <RadioField
                                        noLabels
                                        name="deliveryMethod"
                                        options={getDeliveryMethodOptions(requestData.barSMSLinks)}
                                    />

                                    <Tooltip id="tip-sms">
                                        <div className="grid-container">
                                            <p>
                                                To enable SMS, please
                                                <br />
                                                contact your bank
                                                <br />
                                                representative to upgrade.
                                            </p>
                                        </div>
                                    </Tooltip>
                                </PaddedContainer>

                                <PaddedContainer title="Requests message settings" lessMargin noDivider>
                                    <p>
                                        <b>Acceptable payment types</b>
                                    </p>

                                    <p>This setting does not apply to Tokenise only and Update token requests</p>

                                    <RadioField
                                        noLabels
                                        name="acceptablePaymentAmountType"
                                        options={[
                                            {
                                                value: AcceptablePaymentTypeEnum.FULL_PAYMENT_REQUIRED,
                                                label: "Full payment required",
                                            },
                                            {
                                                value: AcceptablePaymentTypeEnum.MULIPLE_PAYMENTS_CAPPED_TOTAL,
                                                label: "Multiple payments, capped to total",
                                            },
                                            {
                                                value: AcceptablePaymentTypeEnum.ONE_PAYMENT_ANY_AMOUNT,
                                                label: "One payment, any amount",
                                            },
                                        ]}
                                    />

                                    <p>
                                        <b>Expire payment link</b>
                                    </p>
                                    <RadioField
                                        name="expirePaymentLink"
                                        options={[
                                            { value: ExpirePaymentLinkEnum.DO_NOT_EXPIRE, label: "Do not expire" },
                                            { value: ExpirePaymentLinkEnum.AFTER_DUE_DATE, label: "After due date" },
                                            {
                                                value: ExpirePaymentLinkEnum.CUSTOM_EXPIRY_DATE,
                                                label: "Custom expiry date",
                                            },
                                        ]}
                                    />

                                    {context.getValue("expirePaymentLink") === ExpirePaymentLinkEnum.CUSTOM_EXPIRY_DATE && (
                                        <SwitchField name="expiryDateMandatory" disabled={false}>
                                            Expiry date is mandatory.
                                        </SwitchField>
                                    )}

                                    <>
                                        <p>
                                            <b>General</b>
                                        </p>
                                        <SwitchField name="dueDateMandatory" disabled={false}>
                                            Due date is mandatory.
                                        </SwitchField>
                                    </>
                                </PaddedContainer>

                                <ButtonContainer>
                                    <SubmitButton loading={loading}>Save</SubmitButton>
                                    <Link button to={PlatformRoutesConfiguration.messagingRoute?.root.generatePath()}>
                                        Cancel
                                    </Link>
                                </ButtonContainer>

                                <FormErrorList />
                            </>
                        );
                    }}
                />
            )}

            <SuccessModal
                show={hasUpdated}
                onClose={() => {
                    paymentRequestActions.clearUpdateRequestConfig();
                }}
            >
                Messaging settings saved successfully
            </SuccessModal>

            <BackofficeDeeplink
                preLinkMessage="To edit the content for all requests, click"
                path="PaymentRequest/ConfigurePaymentRequest"
                periodAfterLink
                topMargin
            />
        </PageSection>
    );
};

function mapStateToProps(state: RootState) {
    return {
        loading: state.settings.user.isLoading,
        requestConfig: state.paymentRequest.requestConfig,
        merchant: state.accounts.users.merchant,
        billers: state.accounts.users.activeBillers,
        errors: state.paymentRequest.updateRequestConfig.errors,
        updateRequestConfig: state.paymentRequest.updateRequestConfig,
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        paymentRequestActions: bindActionCreators(paymentRequestActions, dispatch),
        accountActions: bindActionCreators(accountActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MessagingRequestsPage);
