import currencyUtil from "./currency";
import { PaymentMethod, SurchargeRule } from './models';

export function getSurchargeableRules(surchargeRules: SurchargeRule[], paymentMethods: PaymentMethod[]) {
    if(!paymentMethods || !surchargeRules) return [];

    let surchargeableCardCodes = paymentMethods.filter(pm => pm.allowSurcharge).map(pm => pm.cardTypeCode);
    return surchargeRules.filter(r => surchargeableCardCodes.includes(r.cardTypeCode));
}

export function getExampleSurcharge(rule: SurchargeRule, amount: number) {
    let surcharge = Math.max(
        (rule.fixedSurchargeAmount ?? 0) + (amount * (rule.fixedSurchargePercent ?? 0)) / 100,
        rule?.surchargeLimits?.minimum ?? 0
    );

    if (rule?.surchargeLimits?.maximum)
        surcharge = Math.min(surcharge, rule.surchargeLimits.maximum);

    return currencyUtil.formatAmount(surcharge);
}

export function getSurchargeRate(rule: SurchargeRule, alphaCode : string) {
    let fixedAmount = 0;
    if (typeof rule?.fixedSurchargeAmount === "number" && rule.fixedSurchargeAmount > 0) {
        fixedAmount = rule.fixedSurchargeAmount;
    }

    let percentAmount = 0;
    if (rule.fixedSurchargePercent && rule.fixedSurchargePercent > 0) {
        percentAmount = rule.fixedSurchargePercent;
    }

    if (fixedAmount && percentAmount) {
        return `${alphaCode} ${currencyUtil.formatAmount(rule.fixedSurchargeAmount)} + ${rule.fixedSurchargePercent}%`;
    }

    if (fixedAmount) {
        return `${alphaCode} ${currencyUtil.formatAmount(rule.fixedSurchargeAmount)}`;
    }

    if (percentAmount) {
        return `${rule.fixedSurchargePercent}%`;
    }

    return `0%`;
}

export function getMinimumSurcharge(rule: SurchargeRule) {
    return `${currencyUtil.formatAmount(rule.surchargeLimits?.minimum ?? 0)}`
}

export function getMaximumSurcharge(rule: SurchargeRule) {
    return `${currencyUtil.formatAmount(rule.surchargeLimits?.maximum ?? 0)}`
}