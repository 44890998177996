import { Dialog, Icon } from '@premier/ui';
import currencyUtil from '@premier/utils/currency';

import './PaymentPlanCancelDialog.scss'

interface PaymentPlanCancelDialogProps {
    crnLabel : string,
    crn1 : string,
    originalTotalAmount : number,
    paymentPlanOptionName : string,
    totalAmountPaid : number
    [rest: string]: any;
}

const PaymentPlanCancelDialog : React.FC<PaymentPlanCancelDialogProps> = (
    { crnLabel, crn1, originalTotalAmount, paymentPlanOptionName, totalAmountPaid, ...rest}) => {
    return (<Dialog title='Cancel payment plan?' icon={<Icon alert />} {...rest}>
        <p>
            {`The following is the summary of this payment plan as of now:`}
        </p>
        <p>
            <b>{crnLabel}</b> {`${crn1}`}
        </p>
        <p>
            <b>Total amount</b> {`${currencyUtil.convertToDisplayString(originalTotalAmount)}`}
        </p>
        <p>
            <b>Payment plan option</b> {`${paymentPlanOptionName}`}
        </p>
        <p>
            <b>Amount collected so far</b> {`${currencyUtil.convertToDisplayString(totalAmountPaid)}`}
        </p>
        <p>
            {`No future payment will be received from this payment plan after cancellation.`}
        </p>
    </Dialog>)
}

export default PaymentPlanCancelDialog;