import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { Form } from '@premier/form';
import { PaginationControl } from '@premier/ui';
import { BatchRecordList } from 'components/Batch';
import { FormError } from 'components/Common';

import * as batchActions from 'components/Batch/_actions/batchActions';
import * as utilityActions from 'components/Utilities/_actions/utilitiesActions';

const BatchRecords = ({
    fileId, //data
    batchFileResult, savedState, errors, orderTypes, //state
    batchActions, utilityActions, //actions
}) => {
    const {pageSize, pageNumber, filter, sort} = savedState || {};

    useEffect(() => {
        if (!orderTypes)
            utilityActions.getOrderTypes();
    }, [utilityActions]);

    function batchStateChange(property, value){
        batchActions.saveBatchRecordsPageState({...savedState, [property]: value});
    }

    return (
        <div className='batch-records'>
            <FormError apiErrors={errors} />

            {orderTypes && (<>
                <Form initialValues={filter} render={(context) => (<>
                    <BatchRecordList context={context}
                        fileId={fileId}
                        records={_.get(batchFileResult, 'records', [])}
                        orderTypes={orderTypes}
                        sort={sort}
                        onSort={(field, descending) => batchStateChange('sort', {field, descending})}
                        onFilterChange={(values) => batchStateChange('filter', values)}
                    />
                </>)} />

                <PaginationControl
                    currentPage={pageNumber}
                    onPageChanged={(newPage) => batchStateChange('pageNumber', newPage)}
                    itemCount={_.get(batchFileResult, 'recordCount', 0)}
                    itemsPerPage={pageSize}
                />
            </>)}
        </div>
    );
};

BatchRecords.propTypes = {
    fileId: PropTypes.number.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        orderTypes: state.transactions.payments.orderTypes,
        batchFileResult: state.transactions.batch.batchFileResult,
        isLoading: state.transactions.batch.isLoading,
        savedState: state.transactions.batch.batchRecordsPageState,
        errors: state.transactions.batch.errors
    };
}

function mapDispatchToProps(dispatch) {
    return {
        batchActions: bindActionCreators(batchActions, dispatch),
        utilityActions: bindActionCreators(utilityActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(BatchRecords);
