import { PageHeader, ProductTooltip } from "components/Common";
import { PageSection, IconText, Link, LoadingIndicator, PaddedContainer } from "@premier/ui";
import { PlatformRoutesConfiguration } from "components/Routing";
import InvoiceSettingsHeading from '../components/InvoiceSettings/InvoiceSettingsHeading';
import { useApiCall, APICallRequestState } from "components/Common";
import { apiIntegrationApi } from "api";

const ApiIntegrationPage = () => {
    const [PrivateKeySetting, PrivateKeySettingStatus] = useApiCall(() => {
        return apiIntegrationApi.getPrivateKeySetting();
    }, []);

    const formatDate = (expiryDate: string | undefined) => {
        if (!expiryDate)
            return "";
        const date = new Date(expiryDate);
        return date.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });
    };

    const getSubtitle = (expiryDate: string | undefined) => {
        return expiryDate ? <IconText tick>{`Expires ${formatDate(PrivateKeySetting?.expiryDate)}`}</IconText> : <>
            Click ‘Edit’ above to add your Apple Pay private key. For more information on how to find your private key, click <Link to={"https://developer.apple.com/help/account/configure-app-capabilities/configure-apple-pay#create-a-payment-processing-certificate"}>here</Link>.
        </>
    };

    return (
        <PageSection>
            <PageHeader title={<>{'API integration'}<ProductTooltip text={<>To understand how to use the main features within API integration, please access the <a href="https://www.bpoint.com.au/developers/v5/api">developer documentation here</a>.</>} /></>} />
            <PaddedContainer title="Apple Pay" lessMargin noDivider button={
                <Link button to={PlatformRoutesConfiguration.apiIntegrationRoute?.editPrivateKey.generatePath()}>Edit</Link>
            }>
                {PrivateKeySettingStatus === APICallRequestState.SUCCESSFUL ?
                    <InvoiceSettingsHeading
                        title={"Private Key"}
                        tooltip={<>For more information on how to find your private key, click <Link to={"https://developer.apple.com/help/account/configure-app-capabilities/configure-apple-pay#create-a-payment-processing-certificate"}>here</Link>.</>}
                        tooltipId={"invoice_settings_tax"}
                        subtitle={getSubtitle(PrivateKeySetting?.expiryDate)}
                        isLoading={false}
                    /> : <LoadingIndicator />}
            </PaddedContainer>
        </PageSection>
    );
};

export default ApiIntegrationPage;
