import { useState, useEffect } from "react";
import _ from "lodash";

// @ts-ignore
import { PaymentTypeKey } from "constants/billpay";

// @ts-ignore
import labels from "constants/labels";

import { Form, RadioField, CardInputForm, BankAccountForm } from "@premier/form";
import { Token } from "models/Request";

type Props = {
    token?: Token;
    showBankAccount?: boolean;
    name?: string;
    merchantNumber?: string;
    billerCode?: string;
};

/** Contains a radio button to switch between a CardInputForm and a BankAccountForm */
const PaymentTypeInputForm = ({ token, showBankAccount, name, merchantNumber, billerCode } : Props) => {
    const [paymentType, setPaymentType] = useState(PaymentTypeKey.CARD);

    useEffect(() => {
        if (!token)
            return;

        if (token.cardTypeCode === "BA") {
            setPaymentType(PaymentTypeKey.BANK_ACCOUNT);
        } else {
            setPaymentType(PaymentTypeKey.CARD);
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);

    const changePaymentOption = (value : PaymentTypeKey) : void => {
        setPaymentType(value);
    };

    return (
        <Form
            name={name || "paymentTypeInput"}
            initialValues={{
                billerCodeForm: {
                    merchantNumber: merchantNumber,
                    billerCode: billerCode,
                },
                accountType: paymentType,
                card: { ...token },
                bankAccountDetails: {
                    ...token,
                    accountNumber: _.get(token, "deAccountNumber"),
                    bsbNumber: _.get(token, "deBsbNumber"),
                },
            }}
            render={context => {

                /* eslint-disable react-hooks/rules-of-hooks */
                useEffect(() => {
                    setTimeout(() => context.setValue("accountType", paymentType), 0);
                    // A workaround to set this value again after BankAccountForm reverted it

                // eslint-disable-next-line react-hooks/exhaustive-deps
                }, [paymentType]);

                /* eslint-disable react-hooks/rules-of-hooks */
                useEffect(() => {
                    if (billerCode !== undefined) {
                        setTimeout(() => context.setValue("billerCodeForm.billerCode", billerCode), 100);
                    }
                // eslint-disable-next-line react-hooks/exhaustive-deps
                }, [billerCode]);

                return (<>
                    {showBankAccount && <RadioField
                        inline={true}
                        name="accountType"
                        label={labels.accountType}
                        options={[
                            { value: PaymentTypeKey.CARD, label: "Card" },
                            { value: PaymentTypeKey.BANK_ACCOUNT, label: "Bank account" },
                        ]}
                        onChange={changePaymentOption}
                        selectedValue={paymentType}
                    />}

                    {paymentType === PaymentTypeKey.CARD ? (
                        <CardInputForm
                            hideCvn
                            maskedCardNumber={_.get(token, "maskedCardNumber")}
                            required />
                    ) : (
                        <BankAccountForm required={true} />
                    )}
                </>);
            }}
        />
    );
};

export default PaymentTypeInputForm;
