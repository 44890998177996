// Must be a subset of enum located at BackOfficeRole.cs
enum userRoles {
    adminMenu = "ADMIN_MENU",
    userManagement = "USER_MANAGEMENT",
    settlement = "SETTLEMENT",
    processRefund = "PROCESS_REFUND",
    singlePayment = "SINGLE_PAYMENT",
    batchPayment = "BATCH_PAYMENT",
    batchManager = "BATCH_MANAGER",
    deleteBatchManagerBatch = "DELETE_BATCH_MANAGER_BATCH",
    dataVault = "DATA_VAULT",
    internet = "INTERNET",
    reports = "REPORTS",
    idbUpload = "IDB_UPLOAD",
    apiConfiguration = "API_CONFIGURATION",
    pluginConfiguration = "PLUGIN_CONFIGURATION",
    directDebit = "DIRECT_DEBIT",
    recurringSchedule = "RECURRING_SCHEDULE",
    eForms = "EFORMS",
    declineManager = "DECLINE_MANAGER",
    declineManagerAdmin = "DECLINE_MANAGER_ADMIN",
    notifications = "NOTIFICATIONS",
    messaging = "MESSAGING",
    transactionSearch = "TRANSACTION_SEARCH",
    invoices = "INVOICES",
    fraudControl = "FRAUD_CONTROL",
    fraudBasic = "FRAUD_BASIC",
    billerConfig = "BILLER_CONFIG",
    paymentRequest = "PAYMENT_REQUEST",
    paymentRequestAdmin = "PAYMENT_REQUEST_ADMIN",
    paymentRequestBatch = "PAYMENT_REQUEST_BATCH",
    securePhone = "SECURE_PHONE",
    legacyXml = "LEGACY_XML",
    stationeryShop = "STATIONERY_SHOP",
    externalReporting = "EXTERNAL_REPORTING",
    notStationeryShop = "NOT_STATIONERY_SHOP",
    qrCode = "QR_CODE",
    invoiceSettings = "INVOICE_SETTINGS",
    invoicePayments = "INVOICE_PAYMENTS",
    paymentPlanSettings = "PAYMENT_PLAN_SETTINGS",
    paymentPlan = "PAYMENT_PLAN",
    LeastCostRouting = "LEAST_COST_ROUTING",
    LCRAllowAmountThresholdConfiguration = "LCR_ALLOW_AMOUNT_THRESHOLD_CONFIGURATION",
    additionalPayments = "ADDITIONAL_PAYMENTS",
    multiFactorAuthentication = "MULTI_FACTOR_AUTHENTICATION",
}

export default userRoles;