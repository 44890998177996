import * as actions from './actionTypes';
import { customerApi, schedulesApi } from 'api';
import { promiseRequestActions } from 'components/Common/requestActions';

//#region ----- GET Customers (list) + Customer details -----
export function getCustomersRequested() {
    return {type: actions.GET_CUSTOMERS_REQUESTED};
}
export function getCustomersSuccess(result) {
    return {type: actions.GET_CUSTOMERS_SUCCESS, result};
}
export function getCustomersFailed(err) {
    return {type: actions.GET_CUSTOMERS_FAILED, err};
}

export function saveCustomersPageState(pageState){
    return function(dispatch) {
        dispatch({type: actions.SAVE_CUSTOMERS_PAGE_STATE, pageState});
    };
}

export function saveCustomerCount(count) {
    return {type: actions.SAVE_CUSTOMER_COUNT, count};
}

export function getCustomers(resultsPerPage, pageNumber, filter, sort) {
    return function(dispatch){
        dispatch(getCustomersRequested());

        return customerApi.getCustomers(resultsPerPage, pageNumber, filter, sort)
            .then(result => {
                dispatch(getCustomersSuccess(result));
                dispatch(saveCustomersPageState({resultsPerPage, pageNumber, filter, sort}));

                dispatch(saveCustomerCount(result.resultCount));  // Save number of customers on first call

                return result;
            })
            .catch(err => {
                dispatch(getCustomersFailed(err));
                throw(err);
            })
    }
}


export function getCustomerRequested() {
    return {type: actions.GET_CUSTOMER_REQUESTED};
}
export function getCustomerSuccess(customer) {
    return {type: actions.GET_CUSTOMER_SUCCESS, customer};
}
export function getCustomerFailed(err) {
    return {type: actions.GET_CUSTOMER_FAILED, err};
}

export function getCustomer(customerId) {
    return function (dispatch) {
        dispatch(getCustomerRequested());

        return customerApi.getCustomer(customerId)
            .then(customer => {
                dispatch(getCustomerSuccess(customer));
                return customer;
            })
            .catch(err => {
                dispatch(getCustomerFailed(err));
                throw(err);
            })
    }
}

//#endregion

//#region ----- ADD / EDIT Customer -----

export function addCustomerRequested() {
    return {type: actions.ADD_CUSTOMER_REQUESTED};
}
export function addCustomerSuccess(customer) {
    return {type: actions.ADD_CUSTOMER_SUCCESS, customer};
}
export function addCustomerFailed(err) {
    return {type: actions.ADD_CUSTOMER_FAILED, err};
}

export function updateCustomerRequested() {
    return {type: actions.UPDATE_CUSTOMER_REQUESTED};
}
export function updateCustomerSuccess(customer) {
    return {type: actions.UPDATE_CUSTOMER_SUCCESS, customer};
}
export function updateCustomerFailed(err) {
    return {type: actions.UPDATE_CUSTOMER_FAILED, err};
}

export function deleteCustomerRequested() {
    return {type: actions.DELETE_CUSTOMER_REQUESTED};
}
export function deleteCustomerSuccess() {
    return {type: actions.DELETE_CUSTOMER_SUCCESS};
}
export function deleteCustomerFailed(err) {
    return {type: actions.DELETE_CUSTOMER_FAILED, err};
}

export function addCustomer(customer) {
    return function(dispatch){
        dispatch(addCustomerRequested());

        return customerApi.addCustomer(customer)
            .then(customer => {
                dispatch(addCustomerSuccess(customer));
                return customer;
            })
            .catch(err => {
                dispatch(addCustomerFailed(err));
                throw(err);
            })
    }
}

export function updateCustomer(customer) {
    return function(dispatch){
        dispatch(updateCustomerRequested());

        return customerApi.updateCustomer(customer)
            .then(customer => {
                dispatch(updateCustomerSuccess(customer));
                return customer;
            })
            .catch(err => {
                dispatch(updateCustomerFailed(err));
                throw(err);
            })
    }
}

export function deleteCustomer(customerId) {
    return function (dispatch) {
        dispatch(deleteCustomerRequested());

        return customerApi.deleteCustomer(customerId)
            .then(() => {
                dispatch(deleteCustomerSuccess());
            })
            .catch(err => {
                dispatch(deleteCustomerFailed(err));
                throw(err);
            })
    }
}
//#endregion


// #region ----- Link / Unlink Tokens -----
export function linkTokenRequested() {
    return {type: actions.LINK_TOKEN_REQUESTED};
}
export function linkTokenSuccess() {
    return {type: actions.LINK_TOKEN_SUCCESS};
}
export function linkTokenFailed(err) {
    return {type: actions.LINK_TOKEN_FAILED, err};
}

export function linkToken(customerId, tokenIds) {
    return function (dispatch) {
        dispatch(linkTokenRequested());

        return customerApi.linkToken(customerId, tokenIds)
            .then(() => {
                dispatch(linkTokenSuccess());
            })
            .catch(err => {
                dispatch(linkTokenFailed(err));
                throw(err);
            })
    }
}


export function unlinkTokenRequested() {
    return {type: actions.UNLINK_TOKEN_REQUESTED};
}
export function unlinkTokenSuccess() {
    return {type: actions.UNLINK_TOKEN_SUCCESS};
}
export function unlinkTokenFailed(err) {
    return {type: actions.UNLINK_TOKEN_FAILED, err};
}

export function unlinkToken(customerId, tokenIds) {
    return function (dispatch) {
        dispatch(unlinkTokenRequested());

        return customerApi.unlinkToken(customerId, tokenIds)
            .then(() => {
                dispatch(unlinkTokenSuccess());
            })
            .catch(err => {
                dispatch(unlinkTokenFailed(err));
                throw(err);
            })
    }
}
//#endregion


// #region -- Add customer token
export function addCustomerTokenRequested() {
    return {type: actions.ADD_TOKEN_REQUESTED};
}
export function addCustomerTokenSuccess(token) {
    return {type: actions.ADD_TOKEN_SUCCESS, token};
}
export function addCustomerTokenFailed(err) {
    return {type: actions.ADD_TOKEN_FAILED, err};
}

export function addCustomerToken(tokenRequest) {
    return function (dispatch) {
        dispatch(addCustomerTokenRequested());

        return customerApi.addTokenForCustomer(tokenRequest)
            .then(token => {
                dispatch(addCustomerTokenSuccess(token));
                return token;
            })
            .catch(err => {
                dispatch(addCustomerTokenFailed(err));
                throw(err);
            })
    }
}
//#endregion

export let getCustomerSubscriptions = promiseRequestActions(actions.GET_CUSTOMER_SUBSCRIPTIONS, (resultsPerPage, pageIndex, filter, sort) => schedulesApi.getSchedules(resultsPerPage, pageIndex, filter, sort)).send;
