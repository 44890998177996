import _ from "lodash";
import { Form, FormErrorList, SubmitButton } from "@premier/form";
import { ButtonContainer, BackButton, PaddedContainer, PageSection, LoadingIndicator, Link, IconText, SuccessModal } from "@premier/ui";
import { PageHeader } from "components/Common";
import { MessagingConfigOptionFields } from "components/Settings/components/MessagingConfigOptionFields";
import { PlatformRoutesConfiguration } from "components/Routing";
import { useMessagingSettings } from "components/Settings/_hooks/useMessagingSettings";

export const MessagingConfirmationsPage = () => {

    const {
        hasLoaded,
        hasFailed,
        isUpdating,
        hasUpdated,
        errors,
        settings,
        updateSettings,
        resetUpdateSettings
    } = useMessagingSettings();

    if (hasFailed)
        return <PageSection>
            <BackButton to={PlatformRoutesConfiguration.messagingRoute?.root.generatePath()}/>
            <IconText alert>Something went wrong.</IconText>
        </PageSection>;

    if (!hasLoaded)
        return <LoadingIndicator />;

    return <PageSection>
        <PageHeader title="Confirmations" backButton={{ to: PlatformRoutesConfiguration.messagingRoute?.root.generatePath() }} />

        <Form
            onSubmit={updateSettings}
            initialValues={_.cloneDeep(settings)}
            errors={errors}
        >
            <PaddedContainer>
                <MessagingConfigOptionFields label="eDDR with Schedule" name="eddrScheduleAcknowledgement" />
                <MessagingConfigOptionFields label="Pay Later" name="payLaterConfirmation" />
                <MessagingConfigOptionFields label="Payment method creation" name="paymentMethodCreation" />
            </PaddedContainer>

            <ButtonContainer>
                <SubmitButton disabled={isUpdating}>Save</SubmitButton>
                <Link button to={PlatformRoutesConfiguration.messagingRoute?.root.generatePath()}>Cancel</Link>
            </ButtonContainer>

            <FormErrorList />
        </Form>

        <SuccessModal
            show={hasUpdated}
            onClose={resetUpdateSettings}
        >
            Messaging settings saved successfully
        </SuccessModal>
    </PageSection>;
};
