import { NewPaymentRequestForm } from "components/PaymentRequests";
import { Customer } from "packages/utils/models";
import * as commonActions from "components/Common/_actions/actions";
import { Dispatch, bindActionCreators } from "redux";
import { connect } from "react-redux";

type Props = {
    show: boolean;
    customer: Customer;
    commonActions: any;
    onClose: (reload: boolean) => void;
    onSuccessModalClose: (showNewPaymentRequestModal?: boolean) => void;
}

const CustomerPaymentRequestModal = ({
    show, customer, commonActions, onClose, onSuccessModalClose
}: Props) => {
    const handleDialogCloseClicked = (reload: boolean) => {
        commonActions.clearErrors();
        onClose(reload);
    }

    return (
        <NewPaymentRequestForm
            dialog
            showDialog={show}
            customer={customer}
            onDialogCloseClicked={handleDialogCloseClicked}
            onSuccessModalClose={onSuccessModalClose}
        />
    );
};

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        commonActions: bindActionCreators(commonActions, dispatch),
    };
}

export default connect(null, mapDispatchToProps)(CustomerPaymentRequestModal);
