export const GET_STATIONERY_CATEGORIES_SUCCESS = 'GET_STATIONERY_CATEGORIES_SUCCESS';
export const GET_STATIONERY_CATEGORIES_FAILED = 'GET_STATIONERY_CATEGORIES_FAILED';
export const GET_STATIONERY_CATEGORIES_REQUESTED = 'GET_STATIONERY_CATEGORIES_REQUESTED';

export const GET_STATIONERY_PRODUCTS_SUCCESS = 'GET_STATIONERY_PRODUCTS_SUCCESS';
export const GET_STATIONERY_PRODUCTS_FAILED = 'GET_STATIONERY_PRODUCTS_FAILED';
export const GET_STATIONERY_PRODUCTS_REQUESTED = 'GET_STATIONERY_PRODUCTS_REQUESTED';

export const CREATE_ORDER_REQUESTED = 'CREATE_ORDER_REQUESTED';
export const CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS';
export const CREATE_ORDER_FAILED = 'CREATE_ORDER_FAILED';

export const GET_RECENT_ADDRESSES_REQUESTED = 'GET_RECENT_ADDRESSES_REQUESTED';
export const GET_RECENT_ADDRESSES_SUCCESS   = 'GET_RECENT_ADDRESSES_SUCCESS';
export const GET_RECENT_ADDRESSES_FAILED    = 'GET_RECENT_ADDRESSES_FAILED';

export const GET_CART_REQUESTED = 'GET_CART_REQUESTED';
export const GET_CART_SUCCESS   = 'GET_CART_SUCCESS';
export const GET_CART_FAILED    = 'GET_CART_FAILED';

export const ADD_TO_CART_REQUESTED = 'ADD_TO_CART_REQUESTED';
export const ADD_TO_CART_SUCCESS   = 'ADD_TO_CART_SUCCESS';
export const ADD_TO_CART_FAILED    = 'ADD_TO_CART_FAILED';

export const UPDATE_CART_ITEM_QTY_REQUESTED = 'UPDATE_CART_ITEM_QTY_REQUESTED';
export const UPDATE_CART_ITEM_QTY_SUCCESS   = 'UPDATE_CART_ITEM_QTY_SUCCESS';
export const UPDATE_CART_ITEM_QTY_FAILED    = 'UPDATE_CART_ITEM_QTY_FAILED';

export const GET_STATIONERY_ORDERS_REQUESTED = 'GET_STATIONERY_ORDERS_REQUESTED';
export const GET_STATIONERY_ORDERS_SUCCESS   = 'GET_STATIONERY_ORDERS_SUCCESS';
export const GET_STATIONERY_ORDERS_FAILED    = 'GET_STATIONERY_ORDERS_FAILED';

export const GET_STATIONERY_FREIGHT_REQUESTED = 'GET_STATIONERY_FREIGHT_REQUESTED';
export const GET_STATIONERY_FREIGHT_SUCCESS   = 'GET_STATIONERY_FREIGHT_SUCCESS';
export const GET_STATIONERY_FREIGHT_FAILED    = 'GET_STATIONERY_FREIGHT_FAILED';

export const SET_DELIVERY_ADDRESS = 'SET_DELIVERY_ADDRESS';

export const SAVE_ORDERS_PAGE_STATE = 'SAVE_ORDERS_PAGE_STATE';
export const GET_ORDER_DETAIL_REQUESTED = 'GET_ORDER_DETAIL_REQUESTED';
export const GET_ORDER_DETAIL_SUCCESS = 'GET_ORDER_DETAIL_SUCCESS';
export const GET_ORDER_DETAIL_FAILED = 'GET_ORDER_DETAIL_FAILED';

export const CLEAR_CART_REQUESTED = 'CLEAR_CART_REQUESTED';
export const CLEAR_CART_SUCCESS   = 'CLEAR_CART_SUCCESS';
export const CLEAR_CART_FAILED    = 'CLEAR_CART_FAILED';