import _ from 'lodash';

import * as billpayUtil from '@premier/utils/billpay';
import currencyUtil from '@premier/utils/currency';
import dateUtil from '@premier/utils/date';
import httpRequest from './httpRequest';
import { absolute } from '@premier/utils/url';

import { defaultPageSize } from 'constants/billpay';
import { config } from './util';
import mapErrors from './mapErrors';
import { RequestApiFactory } from '@premier/webapi-client';

const paymentRequestApi = RequestApiFactory(config);

class PaymentRequestApi {
    //#region ----- Requests -----
    static search(resultsPerPage = defaultPageSize, pageIndex = 0, filter = {}, sort) {
        function mapSortField(field) {
            switch (field) {
                case 'status.description':
                    return 'Status';
                case 'action.description':
                    return 'Action';
                case 'customerV2UniqueCode':
                    return 'customerUniqueCode';
                default:
                    return field;
            }
        }

        return new Promise((resolve, reject) => {
            let created = (_.get(filter, 'createdDateRange.length')) ? dateUtil.convertDateRangeToApiDateTimeRange(filter.createdDateRange) : null;
            let dueDate = (_.get(filter, 'dueDateRange.length')) ? dateUtil.convertDateRangeToApiDateTimeRange(filter.dueDateRange) : null;

            const request = {
                ...filter,
                amount: currencyUtil.convertToApiValue(filter.amount),
                amountFrom: currencyUtil.convertToApiValue(_.get(filter, "amountRange.min")),
                amountTo: currencyUtil.convertToApiValue(_.get(filter, "amountRange.max")),
                amountRange: undefined,
                customerPhoneNumber: billpayUtil.formatPhoneObjectToApiString(filter.customerPhoneNumber),
                createdFrom: _.get(created, 'from'),
                createdTo: _.get(created, 'to'),
                createdDateRange: undefined,
                dueDateFrom: _.get(dueDate, 'from'),
                dueDateTo: _.get(dueDate, 'to'),
                dueDateRange: undefined,
            };

            const model = {
                request: _.pickBy(request, (value) => value),
                sortField: mapSortField(sort.field),
                order: sort.descending ? 'DESCENDING' : 'ASCENDING',
                pageIndex: pageIndex,
                pageSize: resultsPerPage
            };

            httpRequest
                .post(`/request/search`, model)
                .then(response => {
                    var requests = response.list;
                    var resultCount = response.totalCount;
                    resolve({ requests, resultCount });
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    // This is used to generate a standard payment request
    static create(paymentDetails, action, mapErrorsFromDto) {
        let billerCodeForm = paymentDetails.billerCodeForm;
        //Due date & Expiry date is not treated as UTC in old back office when displaying/emailing.
        //Leave date as value entered when passing to API (formatted to YYY-MM-DD only)
        var dto = {
            merchantReference: paymentDetails.merchantReference,
            currencyCode: paymentDetails.currencyCode,
            amount: currencyUtil.convertToApiValue(paymentDetails.amount),
            dueDate: paymentDetails.dueDate ? dateUtil.convertDateToApiDate(paymentDetails.dueDate[0]) : null,
            expiryDate: paymentDetails.expiryDate ? dateUtil.convertDateToApiDate(paymentDetails.expiryDate[0]) : null,
            emailAddress: _.get(paymentDetails, 'email'),
            mobileNumber: billpayUtil.formatPhoneObjectToApiString(paymentDetails.mobile), //util func returns null when not passed in
            customerId: _.get(paymentDetails, 'customerId', null), // will be null if not submitting payment request via customer
            childMerchantNumber: paymentDetails.childMerchantNumber,
            dataVaultId: paymentDetails.dataVaultId,
            orderType: paymentDetails.orderType,
            sendRequest: true
        };

        if (paymentDetails.customer) {
            dto.customer = {
                ...paymentDetails.customer
            };
        }

        if (billerCodeForm) {
            dto.crn1 = billerCodeForm.billerCrnList.crn1;
            dto.crn2 = billerCodeForm.billerCrnList.crn2;
            dto.crn3 = billerCodeForm.billerCrnList.crn3;
            dto.billerCode = billerCodeForm.billerCode;
            dto.childMerchantNumber = paymentDetails.childMerchantNumber ?? billerCodeForm.childMerchantNumber;
            dto.action = billerCodeForm.action ?? paymentDetails.action;
        }

        return new Promise((resolve, reject) => {
            httpRequest
                .post('/request', _.pickBy(dto, (value) => value))
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    reject(mapErrors(err, mapErrorsFromDto || mapNewPaymentRequestErrorsFromDto));
                });
        });
    }

    static createQrRequest(paymentDetails, action, mapErrorsFromDto) {
        let billerCodeForm = paymentDetails.billerCodeForm;
        var dto = {
            merchantReference: paymentDetails.merchantReference,
            currencyCode: paymentDetails.currencyCode,
            amount: currencyUtil.convertToApiValue(paymentDetails.amount),
            dueDate: null, // not required
            expiryDate: null, // not required
            emailAddress: null, // not required
            mobileNumber: null,
            customerId: _.get(paymentDetails, 'customerId', null), // will be null if not submitting payment request via customer
            childMerchantNumber: paymentDetails.childMerchantNumber,
            dataVaultId: paymentDetails.dataVaultId,
            action: action,
            sendRequest: false, // QR code is not sent to the customer, is expected to be scanned in person
            qrCode: true,
            orderType: paymentDetails.orderType
        };

        if (billerCodeForm) {
            dto.crn1 = billerCodeForm.billerCrnList.crn1;
            dto.crn2 = billerCodeForm.billerCrnList.crn2;
            dto.crn3 = billerCodeForm.billerCrnList.crn3;
            dto.billerCode = billerCodeForm.billerCode;
            dto.childMerchantNumber = billerCodeForm.childMerchantNumber;
        }

        return new Promise((resolve, reject) => {
            httpRequest
                .post('/request/qr', _.pickBy(dto, (value) => value))
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    reject(mapErrors(err, mapErrorsFromDto || mapNewPaymentRequestErrorsFromDto));
                });
        });
    }

    static get(id) {
        function fromDto(response) {
            return mapPaymentRequestFromDto(response);
        }

        return new Promise((resolve, reject) => {
            httpRequest.get(`/request/${id}`)
                .then((response) => {
                    resolve(fromDto(response));
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    static getStatus(id) {
        return new Promise((resolve, reject) => {
            httpRequest.get(`/request/${id}/status`)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    static cancel(id) {
        return new Promise((resolve, reject) => {
            httpRequest.post(`/request/${id}/cancel`)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    static resend(id) {
        return new Promise((resolve, reject) => {
            httpRequest.post(`/request/${id}/resend`)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }
    //#endregion

    //#region ----- Request Filter -----

    static getPaymentRequestStatuses() {
        return new Promise((resolve, reject) => {
            httpRequest
                .get('/request/statuses')
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    static getPaymentRequestActions() {
        return new Promise((resolve, reject) => {
            httpRequest
                .get('/request/actions')
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    // See PaymentUrlRequestModel.cs
    static getPaymentPageUrl(paymentFlowType, pathParameters, queryParameters) {
        return paymentRequestApi.requestGetPaymentPageUrl({paymentFlowType, pathParameters, queryParameters});
    }

    static getQrCodeForPaymentPage(paymentFlowType, pathParameters, queryParameters) {
        return paymentRequestApi.requestGetQrCodeForPaymentPage({paymentFlowType, pathParameters, queryParameters});
    }
    //#endregion

    //#region Getter URL functions

    // To be used for <img> tags
    // TODO: Convert this to a normal Swagger API call and change the underlying backend function to
    // use ApiResponse<T>
    static getQrCodeEndpoint(paymentRequestId) {
        const apiEndpoint = `/request/qr/${paymentRequestId}?width=256`;
        return `${absolute(String(process.env.REACT_APP_API_ENDPOINT))}${apiEndpoint}`;
    }
    //#endregion
}

function mapPaymentRequestFromDto(response) {
    let paymentRequest = {...response};

    const customerPhoneNumbers = _.get(paymentRequest, 'customer.phoneNumbers', [])
    if (!_.isEmpty(customerPhoneNumbers)) {
        paymentRequest.customer.phoneNumbers = customerPhoneNumbers.map(p => ({
                ...p,
                phoneNumber: billpayUtil.formatPhoneApiStringToObject(p.phoneNumber),
            }))
    }

    return paymentRequest;
}

// This is used to mutate the DTO that comes from the backend.
// It was designed to facilitate the legacy form implementation since its validation schema
// is inflexible.
function mapNewPaymentRequestErrorsFromDto(parameter) {
    switch (parameter) {
        case 'crn1':
        case 'dataVaultId':
            return 'billerCodeForm.billerCrnList.crn1';
        case 'crn2':
            return 'billerCodeForm.billerCrnList.crn2';
        case 'crn3':
            return 'billerCodeForm.billerCrnList.crn3';
        case 'emailAddress':
            return 'email';
        case 'mobileNumber':
            return 'mobile';
        case 'dueDate':
            return 'dueDate';
        case 'expiryDate':
            return 'expiryDate';
        default:
            return parameter;
    }
}

export default PaymentRequestApi;
