import * as actions from 'components/Batch/_actions/actionTypes';
import { transactionsApi } from 'api';

//#region ----- Batch Payments (Upload / Batch jobs / Download) -----
export function uploadAsBatchJob(files, options) {
    return function(dispatch) {
        dispatch(uploadBatchJobRequested());

        return transactionsApi.uploadAsBatchJob(files, options).then(response => {
            dispatch(uploadBatchJobSuccess());
            return response;
        }).catch(err => {
            dispatch(uploadBatchJobFailed(err));
            throw err;
        });
    }
}

/** Upload a batch file to BatchLibrary, and optionally also to BatchJob
 * @param {bool} scheduleBatchJob Create a BatchJob to be processed asap or later. If this is false, scheduledDateTime will be ignored.
 * @param {bool} scheduledDateTime Process asap after this time. If null, process asap. Only applicable if scheduleBatchJob is true.
 */
export function uploadToBatchLibrary(files, scheduleBatchJob, scheduledDateTime) {
    return function(dispatch) {
        dispatch(uploadBatchLibraryRequested());

        return transactionsApi.uploadToBatchLibrary(files, scheduleBatchJob, scheduledDateTime).then(response => {
            dispatch(uploadBatchLibrarySuccess());
            return response;
        }).catch(err => {
            dispatch(uploadBatchLibraryFailed(err));
            throw err;
        });
    }
}

export function createBatchLibraryFile(batchFileInfo){
    return function(dispatch){
        dispatch(createBatchRequested());

        return transactionsApi.createBatchLibraryFile(batchFileInfo).then(results => {
            dispatch(createBatchSuccess(results));
            return results;
        }).catch(err => {
            dispatch(createBatchFailed(err));
            throw err;
        });
    }
}

/** Calls API to get batch jobs and save the "page state" upon successful API call */
export function getBatchJobs(resultsPerPage, pageIndex, filter, sort) {
    return function(dispatch) {
        dispatch(getBatchJobsRequested());

        return transactionsApi.getBatchJobs(resultsPerPage, pageIndex, filter, sort).then((result) => {
            dispatch(getBatchJobsSuccess(result));
            return result;
        }).catch(err => {
            dispatch(getBatchJobsFailed(err));
            throw err;
        });
    }
}

export function downloadBatchJobResult(downloadFileId, downloadFormat, returnAll, zipContents) {
    return function(dispatch) {
        dispatch(downloadBatchResultRequested());

        return transactionsApi.downloadBatchJobResult(downloadFileId, downloadFormat, returnAll, zipContents).then(response => {
            dispatch(downloadBatchResultSuccess());
            return response;
        }).catch(err => {
            dispatch(downloadBatchResultFailed(err));
            throw err;
        });
    }
}


export function saveBatchFilesPageState(pageState) {
    return function(dispatch) {
        dispatch({type: actions.SAVE_BATCH_FILES_PAGE_STATE, pageState});
    };
}

export function uploadBatchJobRequested() {
    return {type: actions.UPLOAD_BATCH_JOB_REQUESTED};
}
export function uploadBatchJobSuccess() {
    return {type: actions.UPLOAD_BATCH_JOB_SUCCESS};
}
export function uploadBatchJobFailed(err) {
    return {type: actions.UPLOAD_BATCH_JOB_FAILED, err};
}

export function uploadBatchLibraryRequested() {
    return {type: actions.UPLOAD_BATCH_LIBRARY_REQUESTED};
}
export function uploadBatchLibrarySuccess() {
    return {type: actions.UPLOAD_BATCH_LIBRARY_SUCCESS};
}
export function uploadBatchLibraryFailed(err) {
    return {type: actions.UPLOAD_BATCH_LIBRARY_FAILED, err};
}

export function createBatchRequested() {
    return {type: actions.CREATE_BATCH_REQUESTED};
}
export function createBatchSuccess() {
    return {type: actions.CREATE_BATCH_SUCCESS};
}
export function createBatchFailed(err) {
    return {type: actions.CREATE_BATCH_FAILED, err};
}

export function getBatchJobsRequested() {
    return {type: actions.GET_BATCH_JOBS_REQUESTED};
}
export function getBatchJobsSuccess(result) {
    return {type: actions.GET_BATCH_JOBS_SUCCESS, result};
}
export function getBatchJobsFailed(err) {
    return {type: actions.GET_BATCH_JOBS_FAILED, err};
}

export function downloadBatchResultRequested() {
    return {type: actions.DOWNLOAD_BATCH_RESULT_REQUESTED};
}
export function downloadBatchResultSuccess() {
    return {type: actions.DOWNLOAD_BATCH_RESULT_SUCCESS};
}
export function downloadBatchResultFailed(err) {
    return {type: actions.DOWNLOAD_BATCH_RESULT_FAILED, err};
}

//#endregion

//#region ----- Batch Job Details -----

export function processDuplicateBatchJob(fileId) {
    return function(dispatch) {
        dispatch(processDuplicateBatchJobRequested());

        return transactionsApi.processDuplicateBatchJob(fileId).then(() => {
            dispatch(processDuplicateBatchJobSuccess());
        }).catch(err => {
            dispatch(processDuplicateBatchJobFailed(err));
            throw err;
        });
    }
}

export function deleteBatchJob(fileId, status) {
    return function(dispatch) {
        dispatch(deleteBatchJobRequested());

        return transactionsApi.deleteBatchJob(fileId, status).then(() => {
            dispatch(deleteBatchJobSuccess());
        }).catch(err => {
            dispatch(deleteBatchJobFailed(err));
            throw err;
        });
    }
}

export function editBatchJobSchedule(fileId, processAfterDate) {
    return function(dispatch) {
        dispatch(editBatchJobScheduleRequested());

        return transactionsApi.editBatchJobSchedule(fileId, processAfterDate).then(() => {
            dispatch(editBatchJobScheduleSuccess());
        }).catch(err => {
            dispatch(editBatchJobScheduleFailed(err));
            throw err;
        });
    }
}

export function stopRecurringManager(fileId) {
    return function(dispatch) {
        dispatch(stopRecurringManagerRequested());

        return transactionsApi.stopRecurringManager(fileId).then(() => {
            dispatch(stopRecurringManagerSuccess());
        }).catch(err => {
            dispatch(stopRecurringManagerFailed(err));
            throw err;
        });
    }
}


export function processDuplicateBatchJobRequested() {
    return {type: actions.PROCESS_DUPLICATE_BATCH_JOB_REQUESTED};
}
export function processDuplicateBatchJobSuccess() {
    return {type: actions.PROCESS_DUPLICATE_BATCH_JOB_SUCCESS};
}
export function processDuplicateBatchJobFailed(err) {
    return {type: actions.PROCESS_DUPLICATE_BATCH_JOB_FAILED, err};
}

export function deleteBatchJobRequested() {
    return {type: actions.DELETE_BATCH_FILE_REQUESTED};
}
export function deleteBatchJobSuccess() {
    return {type: actions.DELETE_BATCH_FILE_SUCCESS};
}
export function deleteBatchJobFailed(err) {
    return {type: actions.DELETE_BATCH_FILE_FAILED, err};
}

export function editBatchJobScheduleRequested() {
    return {type: actions.EDIT_BATCH_JOB_SCHEDULE_REQUESTED};
}
export function editBatchJobScheduleSuccess() {
    return {type: actions.EDIT_BATCH_JOB_SCHEDULE_SUCCESS};
}
export function editBatchJobScheduleFailed(err) {
    return {type: actions.EDIT_BATCH_JOB_SCHEDULE_FAILED, err};
}

export function stopRecurringManagerRequested() {
    return {type: actions.STOP_BATCH_JOB_RETRY_REQUESTED};
}
export function stopRecurringManagerSuccess() {
    return {type: actions.STOP_BATCH_JOB_RETRY_SUCCESS};
}
export function stopRecurringManagerFailed(err) {
    return {type: actions.STOP_BATCH_JOB_RETRY_FAILED, err};
}

//#endregion


//#region ----- Batch Library -----

/** Calls API and save the "page state" upon successful API call */
export function getBatchLibraryFiles(resultsPerPage, pageIndex, filter, sort) {
    return function(dispatch) {
        dispatch(getBatchLibraryFilesRequested());

        return transactionsApi.getBatchLibraryFiles(resultsPerPage, pageIndex, filter, sort).then((result) => {
            dispatch(getBatchLibraryFilesSuccess(result));
            dispatch(saveBatchLibraryPageState({resultsPerPage, pageIndex, filter, sort}));
            return result;
        }).catch(err => {
            dispatch(getBatchLibraryFilesFailed(err));
            throw err;
        });
    }
}

export function processBatchLibraryFile(fileId, ignoreDuplicate=false, processAfter=null) {
    return function(dispatch) {
        dispatch(processBatchLibraryFileRequested());

        return transactionsApi.processBatchLibraryFile(fileId, ignoreDuplicate, processAfter).then((response) => {
            dispatch(processBatchLibraryFileSuccess());
            return response;
        }).catch(err => {
            dispatch(processBatchLibraryFileFailed(err));
            throw err;
        });
    }
}

export function saveBatchLibraryPageState(pageState) {
    return {type: actions.SAVE_BATCH_LIBRARY_PAGE_STATE, pageState};
}

export function getBatchLibraryFilesRequested() {
    return {type: actions.GET_BATCH_LIBRARY_FILES_REQUESTED};
}
export function getBatchLibraryFilesSuccess(result) {
    return {type: actions.GET_BATCH_LIBRARY_FILES_SUCCESS, result};
}
export function getBatchLibraryFilesFailed(err) {
    return {type: actions.GET_BATCH_LIBRARY_FILES_FAILED, err};
}

export function processBatchLibraryFileRequested() {
    return {type: actions.PROCESS_BATCH_LIBRARY_FILE_REQUESTED};
}
export function processBatchLibraryFileSuccess() {
    return {type: actions.PROCESS_BATCH_LIBRARY_FILE_SUCCESS};
}
export function processBatchLibraryFileFailed(err) {
    return {type: actions.PROCESS_BATCH_LIBRARY_FILE_FAILED, err};
}

//#endregion

//#region ----- Batch Library File Details -----
export function getBatchLibraryFileDetails(fileId, pageSize, pageIndex, filter, sort) {
    return function(dispatch) {
        dispatch(getBatchLibraryFileRequested());

        return transactionsApi.getBatchLibraryFileDetails(fileId, pageSize, pageIndex, filter, sort).then(result => {
            dispatch(getBatchLibraryFileSuccess(result));
            return result;
        }).catch(err => {
            dispatch(getBatchLibraryFileFailed(err));
            throw err;
        });
    }
}

export function renameBatchLibraryFile(fileId, filename) {
    return function(dispatch) {
        dispatch(renameBatchLibraryFileRequested());

        return transactionsApi.renameBatchLibraryFile(fileId, filename).then(() => {
            dispatch(renameBatchLibraryFileSuccess(filename));
        }).catch(err => {
            dispatch(renameBatchLibraryFileFailed(err));
            throw err;
        });
    }
}

/** Upload a batch file to append to an existing BatchLibraryFile
 * @param {number} batchFileId The fileId of the existing BatchLibraryFile
 */
export function appendBatchLibraryFile(files, batchFileId) {
    return function(dispatch) {
        dispatch(appendBatchLibraryFileRequested());

        return transactionsApi.appendBatchLibraryFile(files, batchFileId).then(response => {
            dispatch(appendBatchLibraryFileSuccess());
            return response;
        }).catch(err => {
            dispatch(appendBatchLibraryFileFailed(err));
            throw err;
        });
    }
}

export function deleteBatchLibraryFile(fileId) {
    return function(dispatch) {
        dispatch(deleteBatchLibraryFileRequested());

        return transactionsApi.deleteBatchLibraryFile(fileId).then(() => {
            dispatch(deleteBatchLibraryFileSuccess());
        }).catch(err => {
            dispatch(deleteBatchLibraryFileFailed(err));
            throw err;
        });
    }
}


export function editBatchLibraryFileRecord(request) {
    return function(dispatch) {
        dispatch(editBatchLibraryFileRecordRequested());

        return transactionsApi.editBatchLibraryFileRecord(request).then(() => {
            dispatch(editBatchLibraryFileRecordSuccess());
        }).catch(err => {
            dispatch(editBatchLibraryFileRecordFailed(err));
            throw err;
        });
    }
}

export function deleteBatchLibraryFileRecord(batchFileId, recordId) {
    return function(dispatch){
        dispatch(deleteBatchLibraryFileRecordRequested());

        return transactionsApi.deleteBatchLibraryFileRecord({batchFileId, recordId}).then(() => {
            dispatch(deleteBatchLibraryFileRecordSuccess());
        }).catch(err => {
            dispatch(deleteBatchLibraryFileRecordFailed(err));
            throw err;
        });
    }
}

export function addBatchRecords(batchFileId, records) {
    return function(dispatch) {
        dispatch(addBatchRecordsRequested());

        return transactionsApi.addBatchRecords(batchFileId, records).then(() => {
            dispatch(addBatchRecordsSuccess());
        }).catch(err => {
            dispatch(addBatchRecordsFailed(err));
            throw err;
        });
    }
}


export function saveBatchRecordsPageState(pageState) {
    return {type: actions.SAVE_BATCH_RECORDS_PAGE_STATE, pageState};
}

export function getBatchLibraryFileRequested() {
    return {type: actions.GET_BATCH_LIBRARY_FILE_REQUESTED};
}
export function getBatchLibraryFileSuccess(result) {
    return {type: actions.GET_BATCH_LIBRARY_FILE_SUCCESS, result};
}
export function getBatchLibraryFileFailed(err) {
    return {type: actions.GET_BATCH_LIBRARY_FILE_FAILED, err};
}

export function renameBatchLibraryFileRequested() {
    return {type: actions.RENAME_BATCH_LIBRARY_FILE_REQUESTED};
}
export function renameBatchLibraryFileSuccess(filename) {
    return {type: actions.RENAME_BATCH_LIBRARY_FILE_SUCCESS, filename};
}
export function renameBatchLibraryFileFailed(err) {
    return {type: actions.RENAME_BATCH_LIBRARY_FILE_FAILED, err};
}

export function appendBatchLibraryFileRequested() {
    return {type: actions.APPEND_BATCH_LIBRARY_FILE_REQUESTED};
}
export function appendBatchLibraryFileSuccess() {
    return {type: actions.APPEND_BATCH_LIBRARY_FILE_SUCCESS};
}
export function appendBatchLibraryFileFailed(err) {
    return {type: actions.APPEND_BATCH_LIBRARY_FILE_FAILED, err};
}

export function deleteBatchLibraryFileRequested() {
    return {type: actions.DELETE_BATCH_LIBRARY_FILE_REQUESTED};
}
export function deleteBatchLibraryFileSuccess() {
    return {type: actions.DELETE_BATCH_LIBRARY_FILE_SUCCESS};
}
export function deleteBatchLibraryFileFailed(err) {
    return {type: actions.DELETE_BATCH_LIBRARY_FILE_FAILED, err};
}

export function editBatchLibraryFileRecordRequested(){
    return {type: actions.EDIT_BATCH_RECORD_REQUESTED};
}
export function editBatchLibraryFileRecordSuccess(){
    return {type: actions.EDIT_BATCH_RECORD_SUCCESS};
}
export function editBatchLibraryFileRecordFailed(err){
    return {type: actions.EDIT_BATCH_RECORD_FAILED, err};
}

export function deleteBatchLibraryFileRecordRequested(){
    return {type: actions.DELETE_BATCH_LIBRARY_RECORD_REQUESTED};
}
export function deleteBatchLibraryFileRecordSuccess(){
    return {type: actions.DELETE_BATCH_LIBRARY_RECORD_SUCCESS};
}
export function deleteBatchLibraryFileRecordFailed(err){
    return {type: actions.DELETE_BATCH_LIBRARY_RECORD_FAILED, err};
}

export function addBatchRecordsRequested(){
    return {type: actions.ADD_BATCH_RECORDS_REQUESTED};
}
export function addBatchRecordsSuccess(){
    return {type: actions.ADD_BATCH_RECORDS_SUCCESS};
}
export function addBatchRecordsFailed(err){
    return {type: actions.ADD_BATCH_RECORDS_FAILED, err};
}


//#endregion
