import React from 'react';
import PropTypes from 'prop-types';

import currencyUtil from '@premier/utils/currency';
import { ResponsiveTable, Link, DateTime, IconText } from '@premier/ui';
import { PlatformRoutesConfiguration } from 'components/Routing';

import './BatchLibraryFileList.scss';

const BatchLibraryFileList = ({
    data, sort, //data
    isLoading, //logic renders
    onSort, onLinkClick, onProcess //functions
}) => {

    var columns = [{
        label: 'Filename',
        sortKey: 'filename',
        className: 'filename',
        getter: f => <Link to={PlatformRoutesConfiguration.transactionRoute.batchLibraryFile.generatePath(f.fileId)} onClick={onLinkClick}>{f.filename}</Link>
    },{
        label: 'Records',
        sortKey: 'uploadedRecordCount',
        getter: f => currencyUtil.formatAmount(f.uploadedRecordCount, 0),
    },{
        label: 'Value',
        sortKey: 'uploadedValue',
        getter: f => currencyUtil.convertToDisplayString(f.uploadedValue),
    },{
        label: 'Last updated',
        sortKey: 'updatedDateTime',
        getter: f => <DateTime utcValue={f.updatedDateTime} />,
    },{
        label: 'Last processed',
        sortKey: 'processDateTime',
        getter: f => <DateTime utcValue={f.processDateTime} />,
    }];


    return (<>
        <ResponsiveTable data={data} columns={columns}
            className='batch-library-file-list'
            isLoading={isLoading}
            noDataText={<IconText info>No results. Refine your filter or click the 'New batch file' button above to create a new batch file.</IconText>}
            sort={sort}
            onSort={onSort}
            batchActions={[{
                label: 'Process',
                tableButtonProps: {
                    primary: true,
                    label: 'Process selected',
                },
                handleClick: onProcess,
                pleaseSelectRowText: 'Select batch file to process',
            }]}
        />
    </>);
};

BatchLibraryFileList.propTypes = {
    data: PropTypes.array,
    isLoading: PropTypes.bool,

    /** The current sort order */
    sort: PropTypes.shape({
        field: PropTypes.string,
        descending: PropTypes.bool,
    }),
    /** to handle sort field/order changes */
    onSort: PropTypes.func,

    /** What happens when redirecting to view item details (eg. to save the current page state into Redux) */
    onLinkClick: PropTypes.func,
    /** Handle batch process, eg. (selectedTemplates) => { console.log(selectedTemplates.map(f => f.fileId)) } */
    onProcess: PropTypes.func,
};

export default BatchLibraryFileList;