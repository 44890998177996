import React from 'react';
import { PageHeader, ProductTooltip } from 'components/Common';
import { PlatformRoutesConfiguration } from 'components/Routing';
import { Link, PaddedContainer, PageSection } from '@premier/ui';

import { CountryTax, InvoiceConfigModel, TermOfPayment } from '@premier/webapi-client';
import { useInvoiceSettings } from 'components/Settings/_hooks/useInvoicesSettings';
import InvoiceSettingsHeading from '../components/InvoiceSettings/InvoiceSettingsHeading';
import NoBillerCodeErrorPage from 'components/Common/pages/NoBillerCodeErrorPage'

export type InvoiceSettingsState = '' | 'UNSAVED' | 'SUCCESS';

export const invoiceSettingsPageCopy = {
    title: 'Invoice settings',
    explanation:
        'Set up the labels, selection options and rules for all your invoices',
    editButton: 'Edit',
    invoiceDetail: {
        title: 'Invoice detail',
        termsId: 'invoice_settings_terms',
        termsHeader: 'Term of payment options',
        termsTooltip:
            'The term of payment calculates when the invoice is due. For instance, if it is 7 days, the due date will be calculated as invoice date + 7 days.',
    },
    invoiceItems: {
        title: 'Invoice items',
        taxId: 'invoice_settings_tax',
        taxHeader: 'Tax rate options',
        taxTooltip:
            'The applicable tax rates can be set up for your organisation. These tax rates will appear in a drop down when adding items to the invoice. For example, GST 10%, VAT 20%.',
        otherInvoiceItemId: 'invoice_settings_other_item',
        otherInvoiceItemHeader: 'Other invoice item settings',
        otherInvoiceItemTooltip:
            'These additional settings are used to determine the rule for the unit price entered and whether to display an item with a zero amount.',
    },
    customerNotes: {
        title: 'Customer notes',
        customerNotesId: 'invoice_settings_customer_notes',
        customerNotesHeader: 'Default customer notes',
        customerNotesTooltip:
            'The default customer notes will prefill the customer notes section when creating an invoice.',
    },
};

interface Props {}

const InvoiceSettingsPage: React.FC<Props> = () => {

    // invoice hook ---
    const { hasLoaded, isLoading, settings } = useInvoiceSettings();

    const showDynamicSubtitle = (key: keyof InvoiceConfigModel) => {
        let subtitle = '';
        if(settings){
            switch (key) {
                case 'taxRates':
                    subtitle = `Existing tax rate options are: ${dynamicTaxRatesSubtitle(settings.taxRates!)}`
                    break;
                case 'termOfPayments':
                    subtitle = `Existing terms of payments are: ${dynamicTermsOfPaymentSubtitle(settings.termOfPayments!)}`
                break;
                case 'customerNotes':
                    subtitle = dynamicCustomerNotesSubtitle(settings.customerNotes)
                    break;
                case 'displayItemsWithZeroAmount':
                case 'unitPriceInclusiveOfTax':
                    subtitle = `You have indicated that:\n ${dynamicOtherSettingsSubtitle(
                        settings.unitPriceInclusiveOfTax!, settings.displayItemsWithZeroAmount!
                    )}`
                    break;
                default:
                    break;
            }
        }
        return subtitle;
    }

    // helper functions ---
    const dynamicTaxRatesSubtitle = (items: CountryTax[]) => {
        let itemsList = items.map(e => {
            let taxRate = (e.taxRate! * 100).toFixed(3);
            taxRate = parseFloat(taxRate).toPrecision();

            return `${e.taxName} ${taxRate}%`;
        });
        return itemsList.join(', ');
    }

    const dynamicTermsOfPaymentSubtitle = (items: TermOfPayment[]) => {
        let itemsList = items.map(e => e.description);
        return itemsList.join(', ');
    }

    const dynamicOtherSettingsSubtitle = (unitPrice: boolean, displayZeroAmount: boolean) => {
        let unitPriceTitle = unitPrice ?
            '- Unit price is inclusive of tax.' :
            '- Unit price is exclusive of tax.';

        let displayZeroAmountTitle = displayZeroAmount ?
            '- Items with zero amount are not displayed on the invoice sent.' :
            '- Items with zero amount are displayed on the invoice sent.';

        return unitPriceTitle.concat('\n', displayZeroAmountTitle);
    }

    const dynamicCustomerNotesSubtitle = (notes?: string) => {
        if(notes !== undefined){
            if(notes.length === 0){
                return 'No default customer notes has been set.'
            }
            return notes;
        }
        return '';
    }

    // no billers linked
    if (!settings?.hasInvoiceBiller && hasLoaded) {
        return <NoBillerCodeErrorPage/>;
    }

    return (
        <PageSection>
            <PageHeader
                title={<>{invoiceSettingsPageCopy.title}<ProductTooltip productTooltipModule={"INVOICE_SETTINGS"} /></>}
            />
            <p>{invoiceSettingsPageCopy.explanation}</p>
            <PaddedContainer lessMargin noDivider title={invoiceSettingsPageCopy.invoiceDetail.title} button={
                <Link button to={PlatformRoutesConfiguration.invoiceSettingsRoute?.editInvoiceDetail.generatePath()}>
                    {invoiceSettingsPageCopy.editButton}
                </Link>
            }>
                <InvoiceSettingsHeading
                    title={invoiceSettingsPageCopy.invoiceDetail.termsHeader}
                    tooltip={invoiceSettingsPageCopy.invoiceDetail.termsTooltip}
                    tooltipId={invoiceSettingsPageCopy.invoiceDetail.termsId}
                    subtitle={showDynamicSubtitle('termOfPayments')}
                    isLoading={isLoading}
                />
            </PaddedContainer>
            <PaddedContainer lessMargin noDivider title={invoiceSettingsPageCopy.invoiceItems.title} button={
                <Link button to={PlatformRoutesConfiguration.invoiceSettingsRoute?.editInvoiceItems.generatePath()}>
                    {invoiceSettingsPageCopy.editButton}
                </Link>
            }>
                <InvoiceSettingsHeading
                    title={invoiceSettingsPageCopy.invoiceItems.taxHeader}
                    tooltip={invoiceSettingsPageCopy.invoiceItems.taxTooltip}
                    tooltipId={invoiceSettingsPageCopy.invoiceItems.taxId}
                    subtitle={showDynamicSubtitle('taxRates')}
                    isLoading={isLoading}
                />
                <InvoiceSettingsHeading
                    title={invoiceSettingsPageCopy.invoiceItems.otherInvoiceItemHeader}
                    tooltip={invoiceSettingsPageCopy.invoiceItems.otherInvoiceItemTooltip}
                    tooltipId={invoiceSettingsPageCopy.invoiceItems.otherInvoiceItemId}
                    subtitle={showDynamicSubtitle('unitPriceInclusiveOfTax')}
                    isLoading={isLoading}
                />
            </PaddedContainer>
            <PaddedContainer lessMargin noDivider title={invoiceSettingsPageCopy.customerNotes.title} button={
                <Link button to={PlatformRoutesConfiguration.invoiceSettingsRoute?.editInvoiceCustomerNotes.generatePath()}>
                    {invoiceSettingsPageCopy.editButton}
                </Link>
            }>
                <InvoiceSettingsHeading
                    title={invoiceSettingsPageCopy.customerNotes.customerNotesHeader}
                    tooltip={invoiceSettingsPageCopy.customerNotes.customerNotesTooltip}
                    tooltipId={invoiceSettingsPageCopy.customerNotes.customerNotesId}
                    subtitle={showDynamicSubtitle('customerNotes')}
                    isLoading={isLoading}
                />
            </PaddedContainer>
        </PageSection>
    );
};

export default InvoiceSettingsPage;
