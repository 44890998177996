import React, { ReactNode, useState } from 'react';
import classNames from 'classnames';
import { ErrorText, Mandatory } from '@premier/ui';
import { ErrorMessage } from 'formik';

import './FormGroup.scss';

interface Props {
    labelId?: string,
    // Name of the field input that you wish to access
    name: string,
    label?: any,
    tooltip?: ReactNode,
    className?: string,
    mandatory?: boolean,
    /** The expected child would be some form of input field */
    children: ReactNode,
}

const FormGroup : React.FC<Props> = ({
    name, labelId, label, tooltip, className, mandatory, children
}) => {
    const [ mainLabel ] = useState(label && <>{label} {mandatory && <Mandatory />}</>)

    return (
        <>
            <div className={classNames('form-group', className)}>
                <div className={classNames('label-wrapper')}>
                    <label id={`${labelId}`}>
                        {mainLabel}
                    </label>
                    <div className='help'>
                        {tooltip}
                    </div>
                </div>
                {children}
                <ErrorMessage name={name}>
                    {error => <ErrorText>{error}</ErrorText>}
                </ErrorMessage>
            </div>
        </>
    );
};

export default FormGroup;
