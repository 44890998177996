import { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { RootState } from "store/store";
import { APICallRequestState, PageHeader, useApiCall } from "components/Common";
import { Button, CardLogo, Link, LoadingIndicator, PageSection, ResponsiveTable, SuccessModal } from "packages/ui";
import { Merchant } from "packages/utils/models";
import DeclineApi from "api/declineApi";
import TransactionStatusIcon from "../components/TransactionStatusIcon";
import currencyUtil from "packages/utils/currency";
import BaseTransactionDetailCategory from "../components/TransactionDetailsCategories/BaseTransactionDetailCategory";
import DateUtil from "packages/utils/date";
import { formatExpiry } from "@premier/utils/billpay";
import { ResponsiveTableRecordPartial } from "packages/ui/src/ResponsiveTableTypes";
import textUtil from "packages/utils/text";
import DeclinesResolveTransactionDialog from "./DeclinesResolveTransactionDialog";
import { PlatformRoutesConfiguration } from "components/Routing";
import { Sort } from "models/Sort";

import "./ManageDeclinesTrayPage.scss";

type Props = {
    merchant: Merchant;
};

type TxnAttempt = {
    responseCode: string;
    processedDate: string;
    description: string;
    receiptNumber: string;
    txnHistoryId: number;
} & ResponsiveTableRecordPartial;

const ManageDeclinesTransactionPage = ({ merchant }: Props) => {
    const { trayId, txnId } = useParams();
    const [sort, setSort] = useState<Sort>({ field: "submissionDate", descending: true });
    const [showResolveDialog, setShowResolveDialog] = useState(false);
    const [showResolveSuccessDialog, setShowResolveSuccessDialog] = useState(false);
    const navigate = useNavigate();

    const [tray] = useApiCall(() => {
        if (trayId) {
            return DeclineApi.getDeclineTray(Number(trayId));
        }
    }, [trayId]);

    const [txn, txnStatus] = useApiCall(() => {
        if (txnId) {
            return DeclineApi.getDeclineTrayTxn(Number(trayId), Number(txnId));
        }
    }, [txnId]);

    const txnMerchant = useMemo(() => {
        if (txn?.merchantNumber === merchant.merchantNumber) {
            return merchant;
        }

        return merchant.childMerchants?.find(x => x.merchantNumber === txn?.merchantNumber);
    }, [txn, merchant]);

    const attempts = useMemo<TxnAttempt[]>(() => {
        if (!txn?.txnAttempts) {
            return [];
        }

        return txn?.txnAttempts
            ?.map(x => ({
                responseCode: x.responseCode ?? "",
                processedDate: x.processedDate ?? "",
                description: x.description ?? "",
                receiptNumber: x.receiptNumber ?? "",
                txnHistoryId: x.txnHistoryId ?? 0,
            }))
            ?.sort((a: TxnAttempt, b: TxnAttempt) => {
                if (sort.descending) {
                    switch (sort.field) {
                        case "responseCode":
                            return a.responseCode > b.responseCode ? -1 : 1;
                        case "processedDate":
                            return a.processedDate > b.processedDate ? -1 : 1;
                        case "description":
                            return a.description > b.description ? -1 : 1;
                        case "receiptNumber":
                            return a.receiptNumber > b.receiptNumber ? -1 : 1;
                        default:
                            return 0;
                    }
                } else {
                    switch (sort.field) {
                        case "responseCode":
                            return a.responseCode < b.responseCode ? -1 : 1;
                        case "processedDate":
                            return a.processedDate < b.processedDate ? -1 : 1;
                        case "description":
                            return a.description < b.description ? -1 : 1;
                        case "receiptNumber":
                            return a.receiptNumber < b.receiptNumber ? -1 : 1;
                        default:
                            return 0;
                    }
                }
            });
    }, [txn, sort])

    let paymentDetails = [
        { name: "Merchant no.", value: txn?.merchantNumber },
        { name: "Merchant name", value: txnMerchant?.merchantName },
        { name: "Reference 1", value: txn?.crN1 },
        { name: "CRN2", value: txn?.crN2 },
        { name: "CRN3", value: txn?.crN3 },
        { name: "Merchant reference", value: txn?.merchantReference },
        { name: "Total attempts", value: txn?.txnAttempts?.length },
    ];

    if (!txn?.crN2) {
        paymentDetails = paymentDetails.filter(x => x.name !== "CRN2");
    }

    if (!txn?.crN3) {
        paymentDetails = paymentDetails.filter(x => x.name !== "CRN3");
    }

    const paymentAmounts = [
        { name: "Amount paid", value: txn?.amountPaid ? currencyUtil.convertToDisplayString(txn?.amountPaid) : currencyUtil.convertToDisplayString(0) },
        { name: "Amount original", value: txn?.amountOriginal ? currencyUtil.convertToDisplayString(txn?.amountOriginal) : currencyUtil.convertToDisplayString(0) },
        { name: "Amount surcharge", value: txn?.amountSurcharge ? currencyUtil.convertToDisplayString(txn?.amountSurcharge) : currencyUtil.convertToDisplayString(0) },
    ];

    const cardDetails = [
        { name: "Card number", value: txn?.accountNumber },
        { name: "Expiry date", value: txn?.expiryDate ? formatExpiry(txn?.expiryDate) : "" },
        { name: 'Card type', value: <CardLogo cardTypeCode={txn?.cardTypeCode} /> },
    ];

    const accountDetails = [
        { name: "BSB number", value: txn?.deBsbNumber },
        { name: "Account number", value: txn?.deAccountNumber },
    ];

    const batchDetails = [
        { name: "Batch file name", value: txn?.batchFileName },
        { name: "Submitted", value: txn?.submissionDate ? DateUtil.formatToDateTimeString(txn?.submissionDate) : "" },
    ];

    const resolvedDetails = [
        { name: "Resolved date", value: txn?.resolvedDate ? DateUtil.formatToDateTimeString(txn?.resolvedDate) : "" },
        { name: "Comments", value: txn?.comments },
    ];

    const handleResolveDialogClose = (showSuccessMessage: boolean) => {
        setShowResolveDialog(false);
        setShowResolveSuccessDialog(showSuccessMessage);
    };

    const handleSuccessModalClose = () => {
        navigate(PlatformRoutesConfiguration.transactionRoute!.manageDeclinesTray.generatePath(trayId));
    };

    const handleSort = (field: string) => {
        if (sort.field === field) {
            setSort({ field, descending: !sort.descending });
        } else {
            setSort({ field, descending: true });
        }
    };

    return (
        <>
            {txnStatus === APICallRequestState.LOADING || txnStatus === APICallRequestState.PENDING ?
                <LoadingIndicator /> :
                <>
                    <PageSection noDivider>
                        <PageHeader
                            backButton
                            icon={<TransactionStatusIcon responseCode={txn?.responseCode} />}
                            title={txn?.amountPaid !== null && txn?.amountPaid !== undefined ? currencyUtil.convertToDisplayString(txn?.amountPaid) : ""}
                        >
                            <Button onClick={() => setShowResolveDialog(true)} primary>Resolve</Button>
                        </PageHeader>
                    </PageSection>
                    <PageSection noDivider noMarginTop>
                        <BaseTransactionDetailCategory title="Payment details" items={paymentDetails} />
                        <BaseTransactionDetailCategory title="Payment amounts" items={paymentAmounts} />
                        {txn?.cardTypeCode === "BA" ? <BaseTransactionDetailCategory title="Account details" items={accountDetails} /> : <BaseTransactionDetailCategory title="Card details" items={cardDetails} />}
                        <BaseTransactionDetailCategory title="Batch details" items={batchDetails} />
                        {tray?.trayType === "Resolved" && <BaseTransactionDetailCategory title="Resolved details" items={resolvedDetails} />}
                    </PageSection>
                    <PageSection header="Transaction attempts">
                        <ResponsiveTable
                            data={attempts}
                            columns={[
                                { label: "Status", getter: x => x.responseCode && <TransactionStatusIcon responseCode={x.responseCode} />, sortKey: "responseCode", textAlign: "center" },
                                { label: "Processed", getter: x => DateUtil.formatToDateTimeString(x.processedDate), sortKey: "processedDate" },
                                { label: "Description", getter: x => x.description, sortKey: "description" },
                                { label: "Receipt", getter: x => <Link to={PlatformRoutesConfiguration.transactionRoute!.transactionDetails.generatePath(x.txnHistoryId)}>{textUtil.insertSpaces(x.receiptNumber)}</Link>, sortKey: "receiptNumber" },
                            ]}
                            sort={sort}
                            onSort={handleSort}
                        />
                    </PageSection>
                </>}
            <DeclinesResolveTransactionDialog trayId={Number(trayId)} txnId={Number(txnId)} show={showResolveDialog} onClose={handleResolveDialogClose} />
            <SuccessModal show={showResolveSuccessDialog} title="Transaction resolved successfully!" onClose={handleSuccessModalClose} />
        </>
    );
};

function mapStateToProps(state: RootState) {
    return {
        merchant: state.accounts.users.merchant,
    };
}

export default connect(mapStateToProps)(ManageDeclinesTransactionPage);
