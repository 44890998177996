import React, { ReactNode } from 'react';
import classNames from 'classnames';
import Mandatory from './Mandatory';
import ErrorMessage from './ErrorMessage';
import './FormGroup.scss';


type Props = {
    /** You need to pass in a form field with this ID as the children. Used as a prefix for the ID attribute of the child elements. */
    fieldId: string,  // For accessibility
    label?: any,
    /** Adds an asterisk next to the label */
    mandatory?: boolean,
    /** Not to use labels and error messages */
    noLabels?: boolean,
    error?: string,
    help?: ReactNode,
    /** Wrap the help text down on XS */
    helpWrapXs?: boolean,

    className?: string,
    /** A form field with id being the fieldId */
    children: ReactNode,
};

const FormGroup = ({
    fieldId, label, mandatory, error, help, helpWrapXs, className, noLabels, children
}: Props) => {

    const labelWithAsterisk = label && <>{label} {mandatory && <Mandatory />}</>;

    return (
        <>
            <div className={classNames('form-group', className)}>
                {!noLabels && (
                    <div className={classNames('label-wrapper', {'help-wrap-xs': helpWrapXs})}>
                        <label id={`${fieldId}_label`} htmlFor={fieldId}>
                            {labelWithAsterisk}
                        </label>
                        <div id={`${fieldId}_help`} className='help'>
                            {help}
                        </div>
                    </div>
                )}

                {children}

                {!noLabels && error && <ErrorMessage id={`${fieldId}_error`} error={error} />}
            </div>
        </>
    );
};

export default FormGroup;
