import * as actions from './actionTypes';
import { fraudApi } from 'api';

import { promiseResultRequestActions } from 'components/Common/requestActions';
import {
    FraudBasicSettingsModel,
    BillerFraudBasicSettingsModel,
    PaginateRequestSearchFraudRulesInputFraudRuleSortFields,
    UploadAntiFraudRulesFileResponse
} from 'packages/webapi-client';

export let getFraudSettings = promiseResultRequestActions(
    actions.GET_FRAUD_BASIC_SETTINGS,
    () => fraudApi.getSettings()
).send;

let updateMessagingSettingsActions = promiseResultRequestActions(
    actions.UPDATE_FRAUD_BASIC_SETTINGS,
    (settings: FraudBasicSettingsModel) => fraudApi.updateBasicSettings(settings),
    (settings: FraudBasicSettingsModel) => ({ settings })
);

let updateBillerMessagingSettingsActions = promiseResultRequestActions(
    actions.UPDATE_BILLER_FRAUD_BASIC_SETTINGS,
    // @ts-ignore
    (settings: BillerFraudBasicSettingsModel) => fraudApi.updateBillerBasicSettings(settings),
    (settings: BillerFraudBasicSettingsModel) => ({ settings })
);

export let updateMessagingSettings = updateMessagingSettingsActions.send;
export let clearUpdateMessagingSettings = updateMessagingSettingsActions.clear;

export let updateBillerMessagingSettings = updateBillerMessagingSettingsActions.send;
export let clearBillerUpdateMessagingSettings = updateBillerMessagingSettingsActions.clear;

export let getFraudAdvancedSettings = promiseResultRequestActions(
    actions.GET_FRAUD_ADVANCED_SETTINGS,
    (settings: PaginateRequestSearchFraudRulesInputFraudRuleSortFields, options: any) => fraudApi.fraudSearchFraudRules(settings, options),
).send;

export let getFraudActionSources = promiseResultRequestActions(
    actions.GET_FRAUD_ACTION_SOURCE,
    (options: any) => fraudApi.fraudGetFraudActionSources(options)
).send;

export let getFraudTypeSources = promiseResultRequestActions(
    actions.GET_FRAUD_TYPE_SOURCE,
    (options: any) => fraudApi.fraudGetFraudTypeSources(options)
).send;

export let uploadFraudRules = promiseResultRequestActions(
    actions.UPLOAD_FRAUD_RULES,
    (files: FileList) => fraudApi.fraudUploadAntiFraudRulesFile(files)
).send as unknown as (dispatch: any) => Promise<UploadAntiFraudRulesFileResponse>; // ts workaround as promiseResultRequestActions is untyped