import {combineReducers} from 'redux';
import reduceReducers from 'reduce-reducers';
import asyncReducer from 'src/store/asyncReducer';
import customReportReducer from './customReportReducer';
import settlementReducer from './settlementReducer';

const reportReducers = combineReducers({
    customReports: reduceReducers(customReportReducer, asyncReducer),
    settlement: reduceReducers(settlementReducer, asyncReducer)
});

export default reportReducers;