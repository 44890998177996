import React from 'react';
import PropTypes from 'prop-types';

import currencyUtil from '@premier/utils/currency';
import dateUtil from '@premier/utils/date';

import { ResponsiveTable } from '@premier/ui';
import { TransactionStatusIcon } from 'components/Transactions';

import labels from 'constants/labels';

import { PlatformRoutesConfiguration } from 'components/Routing';

const ScheduledPaymentTransactionList = ({
    transactions, //data
    sort, isLoading, //logic renders
    onSort, //functions
}) => {

    const columns = [{
        label: labels.status,
        sortKey: 'paymentProcessedTxnModel.responseCode',
        getter: t => <TransactionStatusIcon transaction={t} />,
        textAlign: 'center',
    }, {
        label: labels.cardOrBankAccountNumber,
        sortKey: 'maskedCardNumber',
        getter: item => item.maskedCardNumber || item.deAccountNumber
    }, {
        label: labels.receiptNumber,
        showAsLink: true,
        getter: 'receiptNumber',
        sortKey: 'receiptNumber',
    }, {
        label: labels.date,
        sortKey: 'processedDate',
        getter: t => <><nobr>{dateUtil.convertToDateString(t.processedDate)}</nobr> <nobr>{dateUtil.convertToTimeString(t.processedDate)}</nobr></>,
    }, {
        label: labels.amount,
        sortKey: 'totalAmount',
        getter: t => currencyUtil.convertToDisplayString(t.totalAmount, t.currencyCode),
    }];

    return (
        <ResponsiveTable
            data={transactions}
            className='scheduled-transaction-list'
            isLoading={isLoading}
            columns={columns}
            sort={sort}
            onSort={onSort}
            getRowLink={t => PlatformRoutesConfiguration.transactionRoute.transactionDetails.generatePath(t.txnHistoryId + (t.isExternal ? "-external" : ""))}
            noDataText='No transactions to display.'
        />
    );
};

ScheduledPaymentTransactionList.propTypes = {
    /** The current sort order */
    sort: PropTypes.shape({
        field: PropTypes.string,
        descending: PropTypes.bool,
    }),
    /** to handle sort field/order changes */
    onSort: PropTypes.func,

    transactions: PropTypes.array,
    isLoading: PropTypes.bool,
};

export default ScheduledPaymentTransactionList;