import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Form, validate } from '@premier/form';
import { LoadingIndicator, SingleColumnFormContainer } from '@premier/ui';
import { ReceiptDetailsModel, ApiError } from '@premier/webapi-client';
import { PageHeader } from 'components/Common';
import * as billpayUtil from '@premier/utils/billpay';
import countryUtil from '@premier/utils/country';
import currencyUtil from '@premier/utils/currency';
// @ts-ignore
import { logoMaxSize, acceptedLogoTypes } from 'constants/billpay';

import BusinessDetailsApi from 'api/businessDetailsApi';

import BusinessDetailsDialog from 'components/Settings/components/BusinessDetailsSettings/BusinessDetailsDialog';
import BusinessDetailsForm from '../../components/BusinessDetailsSettings/BusinessDetailsForm';
import { maxLengthErrorMessage } from 'components/DataVault/components/InvoiceForms/InvoiceForm';

export const businessDetailsCopy = {
    title: 'Business details',
    subtitle: 'Set up the details to be shown on invoices and receipts that are issued to your customers.',
    businessIdentity: 'Business identity',
    businessBrand: 'Business brand',
    contactDetails: 'Contact details',
    receiptTitle: 'Receipt message',
    receiptSubtitle: 'The following is only displayed on the mailed receipts for successful and unsuccessful payments.',
    pageError: 'There are errors above. Please fix them before continuing.',
    abnError: 'ABN must be 11 digits.',
    acnError: 'ACN must be 9 digits.',
    stateError: 'Current input is invalid.',
    postCodeError: (length: string) => `{label} exceeds ${length} digits.`,
    phoneError: 'Phone number is not valid.',
    faxError: 'Fax number is not valid.',
    genericError: `{label} is not valid`,
};

const businessDetailsForm = 'BusinessDetailsForm';

export type BusinessDetailsModalState = '' | 'SAVED_SUCCESS' | 'UNSAVED_CHANGES' | 'SAVED_ERROR';

export const fieldNames = {
    tradingName: 'tradingName',
    companyId1: 'companyId1', // ABN
    companyId2: 'companyId2', // ACN
    firstName: 'firstName',
    lastName: 'lastName',
    email: 'email',
    website: 'website',
    phoneNumber: 'phoneNumber',
    faxNumber: 'faxNumber',
    addressLine1: 'addressLine1',
    addressLine2: 'addressLine2',
    suburb: 'suburb',
    state: 'state',
    postcode: 'postcode',
    country: 'country',
    receiptMessage: 'receiptMessage',
    logo: 'logo',
};

const fieldValidation = {
    shortTextMaxLength: 100,
    longTextMaxLength: 250,
    suburbMaxLength: 50,
    abnMaxLength: 11,
    acnMaxLength: 9,
    abnAllowedSpaces: 3,
    acnAllowedSpaces: 3,
    logoMaxSize: logoMaxSize,
    acceptedFileTypes: acceptedLogoTypes
};
interface Props {
    apiData: ReceiptDetailsModel;
    apiErrors: ApiError[];
    onSubmitSuccess: () => void;
}

const accountSelector = (state: any) => state.accounts.users.configuration;

const BusinessDetailsSettingsBody: React.FC<Props> = ({ apiData, apiErrors, onSubmitSuccess }) => {
    const accountConfig = useSelector(accountSelector);

    const [modalState, setModalState] = useState<BusinessDetailsModalState>('');
    const navigate = useNavigate();

    if (!apiData && !apiErrors) {
        return <LoadingIndicator />;
    }

    // settings ---
    const countryCode = accountConfig?.defaultCountryCode ?? currencyUtil.getDefaultCurrencyCode();
    const postcodeLength = accountConfig?.postcodeLength ?? countryUtil.getDefaultPostcodeLength();

    // helper func ---
    const formatPhoneWithCountryCode = (phoneNumber: string) => {
        const phoneObj = billpayUtil.formatPhoneApiStringToObject(phoneNumber);

        if (phoneObj?.iddCode === undefined) phoneObj!.iddCode = countryUtil.getIddCode(countryCode);

        return phoneObj;
    };

    // form values ---
    const states = countryUtil.getCountry(countryCode)?.states;

    const initialValues = {
        [fieldNames.tradingName]: apiData.tradingName,
        [fieldNames.companyId1]: apiData.abn,
        [fieldNames.companyId2]: apiData.acn,
        [fieldNames.website]: apiData.websiteUrl,
        [fieldNames.email]: apiData.emailAddress,
        [fieldNames.phoneNumber]: apiData.phoneNumber
            ? formatPhoneWithCountryCode(apiData.phoneNumber)
            : {
                  iddCode: countryUtil.getIddCode(countryCode),
              },
        [fieldNames.faxNumber]: apiData.faxNumber
            ? formatPhoneWithCountryCode(apiData.faxNumber)
            : {
                  iddCode: countryUtil.getIddCode(countryCode),
              },
        [fieldNames.addressLine1]: apiData.address?.addressLine1,
        [fieldNames.addressLine2]: apiData.address?.addressLine2,
        [fieldNames.suburb]: apiData.address?.suburb,
        [fieldNames.state]: apiData.address?.state,
        [fieldNames.postcode]: apiData.address?.postcode,
        [fieldNames.receiptMessage]: apiData.receiptMessage,
        [fieldNames.logo]: {
            existingFile: {
                name: apiData.logoFilename,
                size: apiData.logoFileSize,
            }
        },
    };

    // form validation ---
    const validation = {
        [fieldNames.tradingName]: validate().maxLength(
            fieldValidation.shortTextMaxLength,
            maxLengthErrorMessage(fieldValidation.shortTextMaxLength)
        ),
        [fieldNames.companyId1]: validate().maxLength(fieldValidation.abnMaxLength + fieldValidation.abnAllowedSpaces, businessDetailsCopy.abnError),
        [fieldNames.companyId2]: validate().maxLength(fieldValidation.acnMaxLength + fieldValidation.acnAllowedSpaces, businessDetailsCopy.acnError),
        [fieldNames.state]: validate().test(
            (value: string) => states!.find((s) => s.state === value) || value === undefined || value === '',
            businessDetailsCopy.stateError
        ),
        [fieldNames.postcode]: validate().maxLength(postcodeLength, businessDetailsCopy.postCodeError(postcodeLength)),
        [fieldNames.suburb]: validate().maxLength(
            fieldValidation.suburbMaxLength,
            maxLengthErrorMessage(fieldValidation.suburbMaxLength)
        ),
        [fieldNames.addressLine1]: validate().maxLength(
            fieldValidation.shortTextMaxLength,
            maxLengthErrorMessage(fieldValidation.shortTextMaxLength)
        ),
        [fieldNames.addressLine2]: validate().maxLength(
            fieldValidation.shortTextMaxLength,
            maxLengthErrorMessage(fieldValidation.shortTextMaxLength)
        ),
        [fieldNames.phoneNumber]: validate().phone(businessDetailsCopy.phoneError),
        [fieldNames.faxNumber]: validate().phone(businessDetailsCopy.faxError),
        [fieldNames.email]: validate()
            .maxLength(fieldValidation.longTextMaxLength, maxLengthErrorMessage(fieldValidation.longTextMaxLength))
            .email(),
        [fieldNames.website]: validate().maxLength(
            fieldValidation.longTextMaxLength,
            maxLengthErrorMessage(fieldValidation.longTextMaxLength)
        ),
        [fieldNames.receiptMessage]: validate().maxLength(
            fieldValidation.longTextMaxLength,
            maxLengthErrorMessage(fieldValidation.longTextMaxLength)
        ),
        [fieldNames.logo]: validate().maxFileSize(fieldValidation.logoMaxSize, "The image is too big. Please upload a smaller image.")
            .acceptedFileTypes(fieldValidation.acceptedFileTypes, "Invalid file type submitted. Only png, gif, bmp, jpg and jpeg files can be uploaded."),
    };

    // form actions ---
    const onSubmit = async (values: any, ctx: any) => {
        const model = {
            tradingName: values.tradingName,
            abn: values.companyId1,
            acn: values.companyId2,
            address: {
                addressLine1: values.addressLine1,
                addressLine2: values.addressLine2,
                suburb: values.suburb,
                postcode: values.postcode,
                state: values.state,
            },
            phoneNumber: billpayUtil.formatPhoneObjectToApiString(values.phoneNumber),
            faxNumber: billpayUtil.formatPhoneObjectToApiString(values.faxNumber),
            websiteUrl: values.website,
            emailAddress: values.email,
            receiptMessage: values.receiptMessage,
        } as ReceiptDetailsModel;

        try {
            await BusinessDetailsApi.updateReceiptDetails(model, values.logo?.newFiles, values.logo);
            setModalState('SAVED_SUCCESS');
        }

        catch (err: any) {
            if (err?.length) {
                if (err.some((e: any) => !e.parameter)) {
                    setModalState('SAVED_ERROR');
                } else {
                    // handle backend validation errors
                    const fieldErrors = err.map(
                        (e: any) =>
                            // converts from TitleCase to camelCase
                            e.parameter?.charAt(0).toLowerCase() + e.parameter?.slice(1)
                    );
                    fieldErrors.map((e: any) => ctx.setError(e, businessDetailsCopy.genericError));
                    return;
                }
            }
            setModalState('SAVED_ERROR');
        }
    };

    const onSaveClose = () => {
        setModalState('');
        onSubmitSuccess();
    };

    return (
        //@ts-ignore
        <Form name={'BusinessDetailsBodyForm'}>
            <PageHeader title={businessDetailsCopy.title}/>
            <p>{businessDetailsCopy.subtitle}</p>

            <SingleColumnFormContainer>
                <BusinessDetailsForm
                    formName={businessDetailsForm}
                    validation={validation}
                    initialValues={initialValues}
                    countryCode={countryCode}
                    setModalState={setModalState}
                    handleCancel={() => { navigate(-1); }}
                    handleSubmit={onSubmit}
                />
            </SingleColumnFormContainer>

            <BusinessDetailsDialog
                formName={businessDetailsForm}
                modalState={modalState}
                setModalState={setModalState}
                handleSubmit={onSubmit}
                handleOnSaveClose={onSaveClose}
                handleBack={() => { navigate(-1); }}
            />
        </Form>
    );
};

export default BusinessDetailsSettingsBody;
