import React,  { ReactNode } from 'react';
import { useField } from 'formik';
import classNames from 'classnames';
import CheckboxRadioWrapper from  './CheckboxRadioWrapper'
import './FormikRadioButton.scss';

export type FormikRadioButtonProps = {
    name: string,
    // This is mandatory because you can't click on the option without it, think of it as the name of the widget
    id: string,
    label: ReactNode,
    mandatory?: boolean,
    error?: string,
    // TODO Change type if upgraded to Formik version that can support number values
    value?: string
};

/** A single radio button with label + error message */
const FormikRadioButton : React.FC<FormikRadioButtonProps> = ({
    name,
    label,
    value,
    error,
    mandatory,
    id,
    ...otherProps
}) => {
    const [ field ] = useField(name);
    return (
        <CheckboxRadioWrapper label={label} mandatory={mandatory} error={error} id={id}>
            <input {...otherProps}
                id={id}
                name={name}
                type='radio'
                className={classNames('form-check-input', {
                    'has-error': !!error
                })}
                checked={field.value === value}
                value={value}
            />
        </CheckboxRadioWrapper>
    );
};

export default FormikRadioButton;
