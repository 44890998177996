import React, { useState, useEffect } from 'react';
import { PaddedContainer, Button, ButtonContainer, SuccessModal, LoadingIndicator, ErrorText, Dialog, Icon } from '@premier/ui';
import { useApiCallDeprecated, APICallRequestState } from 'components/Common';
import { MessageEmailConfigurationModel } from '@premier/webapi-client';
import MessagingApi from 'api/messagingApi';
import { validateEmail } from '../../../util/emailValidateUtil';
import './MessageEmailSettings.scss';

enum ComponentState {
    INITIATED,
    UPDATING_SETTING,
    READY,
    SUCCESS_UPDATING_EMAIL,
    ERROR_UPDATING_EMAIL
}

export const MessageEmailSettings : React.FC = () => {
    const [model, setModel] = useState<MessageEmailConfigurationModel>({});
    const [lastValidEmail, setLastValidEmail] = useState<string>("");
    const [componentState, setComponentState] = useState<ComponentState>(ComponentState.INITIATED);
    const [isExpandDetails, setIsExpandDetails] = useState<boolean>(false);
    const [emailError, setEmailError] = useState<string>("");

    const [emailApi, emailApiStatus] = useApiCallDeprecated(() => {
        if (componentState === ComponentState.INITIATED) {
            return MessagingApi.getEmailMessageConfiguration();
        }
    }, []);

    useEffect(() => {
        if (emailApiStatus === APICallRequestState.SUCCESSFUL) {
             setComponentState(ComponentState.READY);
             setIsExpandDetails(false);
             setModel({ bccEmailAddress: emailApi.bccEmailAddress });
             setLastValidEmail(emailApi.bccEmailAddress);
         }
     }, [emailApi, emailApiStatus]);

    useApiCallDeprecated(async () => {
        if (componentState === ComponentState.UPDATING_SETTING) {
            let emailValid = true;
            if (model?.bccEmailAddress)
            {
                emailValid = validateEmail(model.bccEmailAddress);
                setEmailError(!emailValid ? "Email is not formatted correctly." : "");
            }

            if (emailValid) {
                const dto = await MessagingApi.updateEmailMessageConfiguration(model);
                if (dto?.status === 200) {
                    setComponentState(ComponentState.SUCCESS_UPDATING_EMAIL);
                    setLastValidEmail(model.bccEmailAddress ?? "");
                    setEmailError("");
                } else {
                    setComponentState(ComponentState.ERROR_UPDATING_EMAIL);
                    setEmailError("Email is not formatted correctly.");
                }
            } else {
                setComponentState(ComponentState.READY);
            }
        }
    }, [componentState]);

    const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
       setModel({ bccEmailAddress: e.currentTarget.value });
    }

    const renderEmailSection = (isExpandDetails : boolean) => {
        if (isExpandDetails) {
            return (<div className='inputstyle'>
            <p><b>Email</b></p>
            <input name ="Email" type="text" className={emailError ? 'textbox form-control has-error' : 'textbox form-control'} value={model?.bccEmailAddress} onChange={handleChange} ></input>
            <p></p>
            {emailError ? <ErrorText>{emailError}</ErrorText> : null }
                <ButtonContainer>
                    <Button primary onClick={() => setComponentState(ComponentState.UPDATING_SETTING)}>Update email</Button>
                    <Button onClick={() => {
                        setIsExpandDetails(false);
                        setComponentState(ComponentState.READY);
                    }}>Cancel</Button>
                </ButtonContainer>
            </div>)
        } else {
            return (
                <p>
                    { lastValidEmail ? lastValidEmail : 'No email address has been set' }
                </p>
            )
        }
    }

    return (
        <>
            { <div >
                <PaddedContainer title="Receive all customer communications" button={!isExpandDetails ? <Button onClick={() => setIsExpandDetails(true)}>Update email</Button> : null} lessMargin noDivider>
                    <p>
                        The email address below would be BCC'd in all emailed customer communications:
                    </p>
                    {
                        emailApiStatus === APICallRequestState.SUCCESSFUL ? renderEmailSection(isExpandDetails) : <LoadingIndicator />
                    }
                </PaddedContainer>
            </div>
            }
            <SuccessModal
                show={componentState === ComponentState.SUCCESS_UPDATING_EMAIL}
                onClose={() => {
                    setComponentState(ComponentState.READY);
                    setIsExpandDetails(false);
                }}
                title='Email updated successfully!'
            />
            <Dialog
                show={componentState === ComponentState.ERROR_UPDATING_EMAIL}
                closeButton
                dismissable
                autoClose={true}
                onClose={() => {
                    setComponentState(ComponentState.READY);
                    setIsExpandDetails(true);
                }}
                title='Email was not updated due to an error. Please try again.'
                icon={<Icon alert />}
            />
        </>
    );
}

export default MessageEmailSettings;