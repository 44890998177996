import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { PlatformRoutesConfiguration } from 'components/Routing';
import { BillerDefaultAcceptedAmounts, BillerAcceptedAmountsRange } from 'constants/billpay';
import errorMaps from 'constants/errorMaps';

import currencyUtil from '@premier/utils/currency';

import { CurrencyRangeField, FormDialog, InputField, SubmitButton, validate } from '@premier/form';
import { Button } from '@premier/ui';

import * as billerActions from 'components/Settings/_actions/billerActions';
import * as commonActions from 'components/Common/_actions/actions';

/**A Modal with basic input fields to create billers with */
const CreateBillerDialog = ({
    show, onClose, onCreation, disableRedirect, //modal logic
    billerActions, commonActions, //API actions
    newBillerSubmission //Redux data
}) => {

    const biller = newBillerSubmission.data;

    function handleCreateBiller(data) {
        billerActions.createNewBiller(data)
    }

    function handleClose() {
        commonActions.clearErrors();
        onClose && onClose();
    }

    if (newBillerSubmission.hasSucceeded) {
        onCreation && onCreation(biller);

        if (!disableRedirect)
            return <Navigate to={PlatformRoutesConfiguration.settingsRoute.billerDetails.generatePath(biller.billerCode)} />;
    }

    return (
        <FormDialog
            closeButton
            dismissable
            name='createBillerDialog'
            show={show}
            onClose={handleClose}
            onSubmit={handleCreateBiller}
            errorMaps={errorMaps}
            errors={newBillerSubmission.errors}
            title='Create biller code'
            initialValues={{
                acceptedAmounts: {
                    min: BillerDefaultAcceptedAmounts.MIN,
                    max: BillerDefaultAcceptedAmounts.MAX
                }
            }}
            initialValidation={{
                billerName: validate().required(),
                acceptedAmounts: validate().required()
                    .when(val => !(val && val.min && val.max && Number(val.min) > Number(val.max)),
                        'Minimum amount must be smaller than Maximum amount'
                    )
                    .when(val => val.max <= BillerAcceptedAmountsRange.MAX,
                        `Maximum amount cannot exceed ${currencyUtil.formatAmount(BillerAcceptedAmountsRange.MAX)}`
                    )
                    .when(val => val.max >= BillerAcceptedAmountsRange.MIN,
                        `Maximum amount cannot be less than ${currencyUtil.formatAmount(BillerAcceptedAmountsRange.MIN)}`
                    )
                    .when(val => val.min >= BillerAcceptedAmountsRange.MIN,
                        `Minimum amount cannot be less than ${currencyUtil.formatAmount(BillerAcceptedAmountsRange.MIN)}`
                    )
            }}
            renderButtons={context => (<>
                <SubmitButton disabled={newBillerSubmission.isLoading} primary>Save</SubmitButton>
                <Button onClick={handleClose}>Cancel</Button>
            </>)}
        >
            <InputField
                name='billerName'
                label='Name of biller code'
            />
            <CurrencyRangeField
                name='acceptedAmounts'
                label='Accepted amount'
                placeholders={['Minimum', 'Maximum']}
            />
        </FormDialog>
    )
}

CreateBillerDialog.propTypes = {
    /**Show / Hide the modal */
    show: PropTypes.bool,

    /**Function to run when closing the dialog */
    onClose: PropTypes.func,

    /**Function to run when creation of the biller has completed */
    onCreation: PropTypes.func,

    /**Disable the redirect to new biller page */
    disableRedirect: PropTypes.bool,
}

function mapStateToProps(state, ownProps) {
    return {
        newBillerSubmission: state.settings.paymentSettings.newBillerSubmission,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        billerActions: bindActionCreators(billerActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateBillerDialog);
