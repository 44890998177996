import _ from 'lodash';
import httpRequest from './httpRequest';
import dateUtil from '@premier/utils/date';
import currencyUtil from '@premier/utils/currency';
import textUtil from '@premier/utils/text';
import * as billpayUtil from '@premier/utils/billpay';
import { defaultPageSize } from 'constants/billpay';
import mapErrors from './mapErrors';

//#region ========== Constants / Enums ==========

export const BatchJobStatus = {
    COMPLETE: "COMPLETE",
    IN_PROGRESS: "INPROGRESS",
    WARNING: "DONOTPROCESS",
    SCHEDULED: "PENDING"
};

export const OrderTypes = {
    eCommerce: 'ECOMMERCE',
    inPerson: 'IN_PERSON',
    mailFax: 'MAIL_FAX',
    telephone: 'TELEPHONE',
    recurring: 'RECURRING'
}

//#endregion

/* eslint eqeqeq: "off" */
class TransactionsApi {
    static toCamel(o) {
        var newO, origKey, newKey, value;
        if (o instanceof Array) {
            return o.map(function(value) {
                if (typeof value === 'object') {
                    value = TransactionsApi.toCamel(value);
                }
                return value;
            });
        } else {
            newO = {};
            for (origKey in o) {
                if (o.hasOwnProperty(origKey)) {
                    newKey = (origKey.charAt(0).toLowerCase() + origKey.slice(1) || origKey).toString();
                    value = o[origKey];
                    if (value instanceof Array || (value !== null && value.constructor === Object)) {
                        value = TransactionsApi.toCamel(value);
                    }
                    newO[newKey] = value;
                }
            }
        }
        return newO;
    }

    //#region ----- Utilities -----

    /** Gets the child merchants of the currently logged-in merchant */
    static getChildMerchants() {
        return new Promise((resolve, reject) => {
            httpRequest
                .get('/Utilities/GetChildMerchants')
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }
    //#endregion

    //#region ----- Transaction History -----
    static getTransactions(resultsPerPage = defaultPageSize, pageIndex = 0, filter = {}, sort, customerId) {
        /** Translates display-field name to sort-field name (Backend is using different field names for displaying & sorting for some reason) */
        function mapSortField(field) {
            switch (field) {
                case 'paymentProcessedTxnModel.responseCode':
                    return 'responseCode';
                case 'transactionTypeDetails.description':
                    return 'transactionType';
                case 'totalAmount':
                    return 'amount';
                default:
                    return field;
            }
        }

        return new Promise((resolve, reject) => {
            var model = {
                request: this.mapSearchFilterFields(filter, customerId),
                sortField: mapSortField(sort.field),
                order: sort.descending ? 'DESCENDING' : 'ASCENDING',
                pageIndex: pageIndex,
                pageSize: resultsPerPage,
            };

            httpRequest
                .post(`/TransactionsSearch/GetTransactions`, model)
                .then((response) => {
                    var transactions = response.list;
                    var resultCount = response.totalCount;
                    resolve({ transactions, resultCount });
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    static mapSearchFilterFields(filter, customerId) {
        return  {
            ...filter,
            transactionFromDate: filter.transactionDateRange
                ? dateUtil.convertToApiValue(filter.transactionDateRange.dates[0])
                : null,
            transactionToDate: filter.transactionDateRange
                ? dateUtil.convertToApiValue(dateUtil.roundEndOfMinute(filter.transactionDateRange.dates[1]))
                : null,
            settlementDate: filter.settlementDate
                ? dateUtil.convertDateToApiValue(filter.settlementDate[0])
                : null,
            amount: currencyUtil.convertToApiValue(filter.amount),
            amountMin: currencyUtil.convertToApiValue(_.get(filter, "amountRange.min")),
            amountMax: currencyUtil.convertToApiValue(_.get(filter, "amountRange.max")),
            amountRange: undefined,
            cardNumber: billpayUtil.maskedCardNumberToApiString(filter.maskedCardNumber),
            receiptNumber: textUtil.removeSpaces(filter.receiptNumber),
            customerPhoneNumber: billpayUtil.formatPhoneObjectToApiString(filter.customerPhoneNumber),
            customerId: customerId,
        };
    }

    static downloadTransactionsExport(format, filter = {}) {
        return new Promise((resolve, reject) => {
            var dto = {
                format: format,
                search: this.mapSearchFilterFields(filter, null),
            };

            httpRequest
                .post(`/TransactionsSearch/DownloadTransactionsReport`, dto)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    static getTransactionDetails(txnHistoryId, isExternal) {
        const queryString = isExternal
                            ? `?detailTxnId=${txnHistoryId}&isExternal=true`
                            : `?detailTxnId=${txnHistoryId}`;

        return new Promise((resolve, reject) => {
            httpRequest
                .get(`/TransactionsSearch/GetTransactionDetails${queryString}`)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }
    //#endregion

    //#region ----- Transaction Filter -----

    /**
     * Gets a list of "TransactionTypes" (known as TxnFinancialTypes on the backend)
     */
    static getTransactionTypes() {
        return new Promise((resolve, reject) => {
            httpRequest
                .get('/TransactionsSearch/GetTransactionTypes')
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    static getTransactionSources() {
        return new Promise((resolve, reject) => {
            httpRequest
                .get('/TransactionsSearch/GetTransactionSources')
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    static getAccountTypes() {
        return new Promise((resolve, reject) => {
            httpRequest
                .get('/TransactionsSearch/GetCardTypes')
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    static getTxnTypes() {
        return new Promise((resolve, reject) => {
            httpRequest
                .get('/TransactionsSearch/GetTxnTypes')
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    static getTxnSubTypes() {
        return new Promise((resolve, reject) => {
            httpRequest
                .get('/TransactionsSearch/GetTxnSubTypes')
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    static getTxnSources() {
        return new Promise((resolve, reject) => {
            httpRequest
                .get('/TransactionsSearch/GetTxnSources')
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    static getTxnSubSources() {
        return new Promise((resolve, reject) => {
            httpRequest
                .get('/TransactionsSearch/GetTxnSubSources')
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }
    //#endregion

    //#region ----- Transaction Refund + Capture -----

    static refundPayment(paymentDetails) {
        return new Promise((resolve, reject) => {
            httpRequest
                .post('/SinglePayment/ProcessRefund', paymentDetails)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    static capturePayment(paymentDetails) {
        return new Promise((resolve, reject) => {
            httpRequest
                .post('/SinglePayment/ProcessCapture', paymentDetails)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    //#endregion

    //#region ----- New Payment (Single Payment) -----
    static createNewPayment(paymentDetails, mapErrorsFromDto) {
        let billerCodeForm = paymentDetails.billerCodeForm;
        var dto = {
            childMerchantNumber: billerCodeForm.childMerchantNumber,
            billerCode: billerCodeForm.billerCode,
            crn1: billerCodeForm.billerCrnList.crn1,
            crn2: billerCodeForm.billerCrnList.crn2,
            crn3: billerCodeForm.billerCrnList.crn3,
            merchantReference: paymentDetails.merchantReference,
            orderType: paymentDetails.orderType,
            amount: currencyUtil.convertToApiValue(paymentDetails.amount),
            card: {
                accountName: paymentDetails.card.cardholderName,
                number: paymentDetails.card.cardNumber,
                expiryDate: {
                    month: paymentDetails.card.expiryDate?.month,
                    year: paymentDetails.card.expiryDate?.year
                },
                cvc: paymentDetails.card.cvn
            },
            customerId: _.get(paymentDetails, 'customerId', null),
            saveCustomerPaymentMethod: _.get(paymentDetails, 'saveCustomerPaymentMethod', null)
        };

        return new Promise((resolve, reject) => {
            httpRequest
                .post('/SinglePayment/ProcessPayment', dto)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(mapErrors(err, mapErrorsFromDto || mapNewPaymentErrorsFromDto));
                });
        });
    }

    static sendPaymentReceipt(paymentDetails) {
        return new Promise((resolve, reject) => {
            httpRequest
                .post('/SinglePayment/SendReceipt', paymentDetails)
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    static getCardSurchargeDetails(purchaseInfo) {
        return new Promise((resolve, reject) => {
            httpRequest
                .post('/SinglePayment/GetCardSurchargeDetails', {
                    ...purchaseInfo,
                    amount: currencyUtil.convertToApiValue(purchaseInfo.amount),
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    //#endregion

    //#region ----- Print Receipt -----

    static getMerchantAddress(merchantId, merchantNumber) {
        return new Promise((resolve, reject) => {
            httpRequest
                .get(`/SinglePayment/GetReceiptDetails?merchantId=${merchantId}&merchantNumber=${merchantNumber}`)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    //#endregion

    //#region ----- Batch Payments -----

    static uploadAsBatchJob(files, options) {
        return new Promise((resolve, reject) => {
            httpRequest
                .postFiles(`/BatchJob/UploadBatchFile`, files, options)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    static uploadToBatchLibrary(files, scheduleBatchJob, scheduledDateTime) {
        return new Promise((resolve, reject) => {
            httpRequest
                .postFiles(`/BatchLibrary/UploadBatchFile`, files, { scheduleBatchJob, scheduledDateTime })
                .then((response) => {
                      resolve(response);

                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    static appendBatchLibraryFile(files, batchFileId) {
        return new Promise((resolve, reject) => {
            httpRequest
                .postFiles(`/BatchLibrary/AppendBatchFile`, files, { batchFileId })
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    static getBatchJobs(resultsPerPage = 10, pageIndex = 0, filter = {}, sort) {
        /** Maps frontend values to backend values */
        function mapFilter(filter) {
            return Object.assign({}, filter, {
                fileStatus: textUtil.capitaliseFirstLetter(filter.fileStatus),
            });
        }

        return new Promise((resolve, reject) => {
            var model = {
                request: mapFilter(filter),
                sortField: sort.field === 'approvedRecordCount' ? 'approvedCount' : sort.field,
                order: sort.descending ? 'DESCENDING' : 'ASCENDING',
                pageIndex: pageIndex,
                pageSize: resultsPerPage,
            };

            httpRequest
                .post(`/BatchJob/GetBatchFiles`, model)
                .then((response) => {
                    var batchFiles = response.batchFiles.list;
                    var resultCount = response.batchFiles.totalCount;
                    var { completedCount, progressCount, scheduledCount, progressErrors } = response;

                    resolve({
                        batchFiles,
                        resultCount,
                        completedCount,
                        progressCount,
                        scheduledCount,
                        progressErrors,
                    });
                })
                .catch((err) => {
                    reject(mapErrors(err, (p) => {
                        if (p === "sortField")
                            return "field";
                        if (p === "pageSize")
                            return "resultsPerPage";
                        if (p === "order")
                            return "descending";
                        return p;
                    }));
                });
        });
    }

    static downloadBatchJobResult(downloadFileId, downloadFormat, returnAll, zipContents) {
        return new Promise((resolve, reject) => {
            var model = { downloadFileId, downloadFormat, returnAll, zipContents };

            httpRequest
                .post(`/BatchJob/DownloadBatchFileResult`, model)
                .then((response) => {
                    resolve(response);
                    return response;
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    static createBatchLibraryFile(batchFileInfo) {
        batchFileInfo = Object.assign({}, batchFileInfo, {
            batchPriority: 'UNSPECIFIED',
            batchState: 'UNSPECIFIED',
        });

        return new Promise((resolve, reject) => {
            httpRequest
                .post(`/BatchLibrary/CreateBatchFile`, batchFileInfo)
                .then((response) => {
                    resolve(response);
                    return response;
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    static addBatchRecords(batchFileId, records) {
        var model = {
            batchFileId,
            batchRecords: records.map((r) => {
                return { ...r, amount: currencyUtil.convertToApiValue(r.amount) };
            }),
        };

        return new Promise((resolve, reject) => {
            httpRequest
                .post(`/BatchLibrary/AddBatchRecords`, model)
                .then((response) => {
                    resolve(response);
                    return response;
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    static getBatchLibraryFiles(resultsPerPage = 10, pageIndex = 0, filter = {}, sort) {
        /** Maps frontend values to backend values */
        function mapFilter(filter) {
            return Object.assign({}, filter, {
                updatedDate: _.get(filter, 'updatedDate.length')
                    ? dateUtil.convertToApiValue(filter.updatedDate[0])
                    : null,
            });
        }

        return new Promise((resolve, reject) => {
            var model = {
                request: mapFilter(filter),
                sortField: sort.field,
                order: sort.descending ? 'DESCENDING' : 'ASCENDING',
                pageIndex: pageIndex,
                pageSize: resultsPerPage,
            };

            httpRequest
                .post(`/BatchLibrary/GetBatchFiles`, model)
                .then((response) => {
                    var libraryFiles = response.list;
                    var resultCount = response.totalCount;

                    resolve({ libraryFiles, resultCount });
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    static getBatchLibraryFileDetails(
        fileId,
        pageSize = 10,
        pageIndex = 0,
        filter = { expiryDate: {}, accountNumber: {} },
        sort = { field: 'reference' }
    ) {
        return new Promise((resolve, reject) => {
            var mappedFilter = Object.assign({}, filter, {
                accountNumber: `${filter.accountNumber.prefix}...${filter.accountNumber.suffix}`,
                amount: currencyUtil.convertToApiValue(filter.amount),
            });

            var model = {
                request: { fileId, ...mappedFilter },
                sortField: sort.field.toUpperCase(),
                order: sort.descending ? 'DESCENDING' : 'ASCENDING',
                pageIndex,
                pageSize,
            };

            httpRequest
                .post(`/BatchLibrary/GetBatchFileContents`, model)
                .then((response) => {
                    let { fileId, filename, uploadedCount, uploadedValue, submittedDateTime, fileRecords } = response;

                    resolve({
                        fileDetails: { fileId, filename, uploadedCount, uploadedValue, submittedDateTime },
                        records: fileRecords.list,
                        recordCount: fileRecords.totalCount,
                    });
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    static deleteBatchJob(fileId, status) {
        var model = {
            batchFileId: fileId,
            batchStatus: status,
        };

        return new Promise((resolve, reject) => {
            httpRequest
                .post(`/BatchJob/DeleteBatchFile`, model)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    static deleteBatchLibraryFile(fileId) {
        var model = {
            batchFileId: fileId,
        };

        return new Promise((resolve, reject) => {
            httpRequest
                .post(`/BatchLibrary/DeleteBatchFile`, model)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    static processBatchLibraryFile(fileId, ignoreDuplicate, processAfter) {
        var model = {
            batchFileId: fileId,
            checkUploadHistory: ignoreDuplicate,
            processAfter: dateUtil.convertToApiValue(processAfter),
        };

        return new Promise((resolve, reject) => {
            httpRequest
                .post(`/BatchLibrary/ProcessFile`, model)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    static editBatchLibraryFileRecord(record) {
        var model = {
            batchFileId: record.fileId,
            recordId: record.recordId,
            batchRecord: {
                crn1: record.crn1,
                card: {
                    number: record.cardNumber,
                    expiryDate: record.expiryDate,
                },
                amount: record.amount,
                authType: record.orderTypeDescription,
            },
        };

        return new Promise((resolve, reject) => {
            httpRequest
                .post(`/BatchLibrary/EditBatchRecord`, model)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    static deleteBatchLibraryFileRecord(request) {
        return new Promise((resolve, reject) => {
            httpRequest
                .post(`/BatchLibrary/DeleteBatchRecord`, request)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    static editBatchJobSchedule(fileId, processAfterDate) {
        var model = {
            batchFileId: fileId,
            processAfterDate: dateUtil.convertToApiValue(processAfterDate) || dateUtil.nowToApiValue(),
        };

        return new Promise((resolve, reject) => {
            httpRequest
                .post(`/BatchJob/EditBatchFileSchedule`, model)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    static renameBatchLibraryFile(fileId, filename) {
        return new Promise((resolve, reject) => {
            var model = {
                batchFileId: fileId,
                fileName: filename,
            };

            httpRequest
                .post(`/BatchLibrary/RenameBatchFile`, model)
                .then(() => {
                    resolve();
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    static processDuplicateBatchJob(fileId) {
        return new Promise((resolve, reject) => {
            var model = {
                batchFileId: fileId,
            };
            httpRequest
                .post(`/BatchJob/ProcessDuplicateBatchFile`, model)
                .then((response) => {
                    resolve(response);
                    return response;
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    /** Stops declined BatchJob from retrying */
    static stopRecurringManager(fileId) {
        return new Promise((resolve, reject) => {
            var model = { batchFileId: fileId };

            httpRequest
                .post(`/BatchJob/StopRecurringManager`, model)
                .then(() => resolve())
                .catch((err) => reject(err));
        });
    }
    //#endregion
}

//#region ========== Error Mappings ==========

/** This works for Transactions > New Payment page. If called from somewhere else with a different Form structure, please pass a custom mapErrorsFromDto. */
function mapNewPaymentErrorsFromDto(parameter) {
    switch (parameter) {
        case 'crn1':
            return 'billerCodeForm.billerCrnList.crn1';
        case 'crn2':
            return 'billerCodeForm.billerCrnList.crn2';
        case 'crn3':
            return 'billerCodeForm.billerCrnList.crn3';
        case 'card.number':
            return 'card.cardNumber';
        default:
            return parameter;
    }
}

export default TransactionsApi;
