import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import regexUtil from "@premier/utils/regEx";
import {
    Button,
    PaddedContainer,
    PageSection,
    FileSelector,
} from "@premier/ui";
import { FocusSelectorRef } from "packages/ui/src/FileSelector";
import { uploadBillerCRNLookupRulesFile } from "components/Settings/_actions/billerActions";

import IServicesUploadSection from "./IServicesUploadSection";
import IServicesEmailSection from "./IServicesEmailSection";
import IServicesUploadDialog from "./IServicesUploadDialog";
import { FieldError } from "api/mapErrors";
import { RootState } from "store/store";

export type FileUploadState =
    | ""
    | "OVERWRITE_WARNING"
    | "INVALID_FILE_TYPE"
    | "FILE_SELECTED"
    | "BACKEND_ERROR"
    | "GENERIC_ERROR"
    | "IN_PROGRESS"
    | "SUCCESS";
const allowedFileExtensions = [".TXT", ".DAT", ".CSV", ".ZIP"];

export const defaultValues = {
    fileUpload: {
        fileRef: null,
        defaultFileName: "",
        defaultFileSize: 0,
        defaultErrorMessage: "",
        defaultFileUploadState: "" as FileUploadState,
        noFileWarning: "An i-Services file is required.",
    },
    email: {
        maxLength: 250,
        defaultErrorMessage: "",
        maxLengthWarning: "Send confirmation email to exceeds 250 characters.",
        noEmailWarning: "Send confirmation email to is required.",
        invalidEmailWarning:
            "Send confirmation email to is not formatted correctly.",
    },
    generalErrorMessage:
        "There are errors above. Please fix them before continuing.",
    backendErrorMessage: [],
};

export const iServicesFormCopy = {
    upload: {
        title: "Select file to update rules",
        explanation:
            "Your i-Services file will be picked up every 3 hours starting from 12:00AM AEST. Rules set by your existing i-Services file will be overridden by the rules in this new file. For the correct format of the i-Services file, please check it here: ",
        link: "i-Services Format",
        button: "Select file",
        selectedButton: "Select another file",
    },
    email: {
        title: "Set emails to receive processing confirmation",
        explanation:
            "A confirmation email will be sent to the following emails once the uploaded file is processed by BPOINT. Please seperate the emails using commas.",
        emailInputLabel: "Send confirmation email to",
    },
    submit: "Update rules",
};

interface Props {}

const IServicesForm: React.FC<Props> = () => {
    const { authenticatedUser } = useSelector((state: RootState) => ({
        authenticatedUser: state.accounts.users.authenticatedUser,
    }));

    const [fileUploadState, setFileUploadState] = useState<FileUploadState>(
        defaultValues.fileUpload.defaultFileUploadState
    );
    const [fileErrorMsg, setFileErrorMsg] = useState<string>(
        defaultValues.email.defaultErrorMessage
    );
    const [file, setFile] = useState<FileList>();
    const [fileName, setFileName] = useState<string>(
        defaultValues.fileUpload.defaultFileName
    );
    const [fileSize, setFileSize] = useState<number>(
        defaultValues.fileUpload.defaultFileSize
    );
    const fileSelectorRef = useRef<FocusSelectorRef>(
        defaultValues.fileUpload.fileRef
    );

    const [emails, setEmails] = useState<string>(
        authenticatedUser.emailAddress
    );
    const [emailerrorMsg, setEmailerrorMsg] = useState<string>(
        defaultValues.email.defaultErrorMessage
    );
    const [numChar, setNumChar] = useState<number>(
        defaultValues.email.maxLength - authenticatedUser.emailAddress.length
    );
    const [onSubmitErr, setOnSubmitErr] = useState<boolean>(false);

    const [backendErrorMessages, setBackendErrorMessages] = useState<string[]>(
        defaultValues.backendErrorMessage
    );

    const dispatch = useDispatch();

    useEffect(() => {
        if (!fileErrorMsg && !emailerrorMsg) {
            setOnSubmitErr(false);
        }
    }, [fileErrorMsg, emailerrorMsg]);

    const onSubmit = () => {
        const emailStatus = validateEmailInputOnSubmit();
        const fileStatus = validateFileUploadOnSubmit();

        // show overwrite warning modal
        if (emailStatus && fileStatus) {
            setFileUploadState("OVERWRITE_WARNING");
        } else {
            setOnSubmitErr(true);
        }
    };

    const updateIServicesRules = () => {
        // remove spaces
        const emailString = emails.replace(/\s/g, "");

        setFileUploadState("IN_PROGRESS");

        // @ts-ignore
        dispatch(uploadBillerCRNLookupRulesFile(file, emailString))
            .then((r) => {
                // backend error
                if (r.hasErrors) {
                    const errorList = r.uploadBillerCRNLookupRulesValidationResult;
                    if (errorList && errorList[0]) {
                        setFileUploadState("BACKEND_ERROR");
                        setBackendErrorMessages(errorList[0]?.errors?.map(e => e) ?? []);
                    }
                    else {
                        setFileUploadState("GENERIC_ERROR");
                    }
                }
                else {
                    // success, reset states
                    setFileUploadState("SUCCESS");
                    setFileName(defaultValues.fileUpload.defaultFileName);
                    setFileSize(defaultValues.fileUpload.defaultFileSize);
                    setBackendErrorMessages(defaultValues.backendErrorMessage);
                }
            })
            .catch((e) => {
                // backend error
                if (e[0]?.code) {
                    setFileUploadState("BACKEND_ERROR");
                    setBackendErrorMessages(
                        (e as FieldError[])
                            .filter((e) => !!e.message)
                            .map((e) => e.message!)
                    );
                } else {
                    setFileUploadState("GENERIC_ERROR");
                }
            });
    };

    // File uploads ---
    const onFileSelect = (files: FileList): void => {
        if (!files || files.length !== 1) {
            // invalid number of files
            return;
        }

        const filename = files[0].name.toUpperCase();

        // invalid file type
        if (!allowedFileExtensions.some((ext) => filename.endsWith(ext))) {
            setFileName(files[0].name);
            setFileUploadState("INVALID_FILE_TYPE");
            return;
        }
        setFileName(files[0].name);
        setFileSize(files[0].size);
        setFile(files);
        setFileErrorMsg(defaultValues.fileUpload.defaultErrorMessage);
        setFileUploadState(defaultValues.fileUpload.defaultFileUploadState);
        return;
    };

    const onInvalidFileClose = () => {
        setFileUploadState(defaultValues.fileUpload.defaultFileUploadState);
        setFileName(defaultValues.fileUpload.defaultFileName);
    };

    const validateFileUploadOnSubmit = () => {
        if (fileName) {
            return true;
        }

        setFileErrorMsg(defaultValues.fileUpload.noFileWarning);
        return false;
    };

    // Email inputs ---
    const onEmailChange = (e: { target: { value: string } }) => {
        const inputValue = e.target.value;
        setEmails(inputValue);
        validateEmailLength(inputValue);
    };

    const handleMaxLengthReached = () => {
        setEmailerrorMsg(defaultValues.email.maxLengthWarning);
    };

    const validateEmailLength = (emailInput: string) => {
        // prevent counter to go below 0
        const charRemaining = Math.max(
            0,
            defaultValues.email.maxLength - emailInput.length
        );
        setNumChar(charRemaining);

        // reset error message
        if (charRemaining >= 0) {
            setEmailerrorMsg(defaultValues.email.defaultErrorMessage);
        }
    };

    const validateEmailInputOnSubmit = () => {
        let errorMessage = defaultValues.email.defaultErrorMessage;

        if (emails.length === 0) {
            errorMessage = defaultValues.email.noEmailWarning;
        } else {
            const emailsWithoutSpaces = emails.replace(/\s/g, "");
            const list = emailsWithoutSpaces.split(",");

            // check regex for each email entered
            list.forEach((email) => {
                if (!email.match(regexUtil.getEmailRegex())) {
                    errorMessage = defaultValues.email.invalidEmailWarning;
                    return false;
                }
            });
        }
        setEmailerrorMsg(errorMessage);
        return !errorMessage;
    };

    return (
        <>
            <PaddedContainer>
                <PageSection noDivider={false}>
                    <IServicesUploadSection
                        text={iServicesFormCopy.upload}
                        onClickFileUpload={() =>
                            fileSelectorRef.current?.open()
                        }
                        errorMessage={fileErrorMsg}
                        fileSize={fileSize}
                        fileName={
                            fileUploadState !== "INVALID_FILE_TYPE"
                                ? fileName
                                : defaultValues.fileUpload.defaultFileName
                        }
                    />
                </PageSection>

                <PageSection>
                    <IServicesEmailSection
                        text={iServicesFormCopy.email}
                        email={emails}
                        errorMessage={emailerrorMsg}
                        numChar={numChar}
                        maxLength={defaultValues.email.maxLength}
                        handleMaxLengthReached={handleMaxLengthReached}
                        onChange={onEmailChange}
                        onSubmit={onSubmit}
                    />
                </PageSection>

                <IServicesUploadDialog
                    fileUploadState={fileUploadState}
                    onSubmit={updateIServicesRules}
                    onClose={() =>
                        setFileUploadState(
                            defaultValues.fileUpload.defaultFileUploadState
                        )
                    }
                    onInvalidFileClose={onInvalidFileClose}
                    onClickFileUpload={() => fileSelectorRef.current?.open()}
                    fileName={fileName}
                    errorMessages={backendErrorMessages}
                ></IServicesUploadDialog>
            </PaddedContainer>

            <FileSelector ref={fileSelectorRef} onSelect={onFileSelect} />

            <Button primary onClick={() => onSubmit()}>
                {iServicesFormCopy.submit}
            </Button>

            {onSubmitErr && (
                <p className="iservices-error-message">
                    {defaultValues.generalErrorMessage}
                </p>
            )}
        </>
    );
};

export default IServicesForm;
