import { useEffect, useState, ChangeEvent } from 'react';
import { Textarea, FormGroup } from '@premier/ui';
import { FieldAccess, combine, required, haveMaxLengthOf, replaceLabel } from '@premier/ts-form';
import { uniqueId } from '@premier/utils/helpers';

export const TextareaField = ({
    field, label, mandatory, maxLength
}: {
    field: FieldAccess<string>
    label: string,
    mandatory?: boolean
    maxLength?: number
}) => {

    const { value, fieldError, setValue, register, validate } = field;
    useEffect(() => register({
        label: label,
        validation: { self: combine(
            mandatory && required(),
            maxLength != null && haveMaxLengthOf(maxLength)
        )}
    }), [register, label, mandatory, maxLength]);

    const [elementId] = useState(uniqueId('input_'));

    function handleChange(e: ChangeEvent<HTMLTextAreaElement>) {
        setValue(() => e.target.value);
        validate();
    }

    return (
        <FormGroup fieldId={elementId} label={label} mandatory={mandatory} error={replaceLabel(fieldError?.message, label)}>
            <Textarea
                id={elementId}
                onChange={handleChange}
                value={value}
                error={!!fieldError?.message}
                aria-labelledby={`${elementId}_label`}
                aria-required={mandatory}
                aria-invalid={!!fieldError?.message}
                aria-describedby={!!fieldError?.message ? `${elementId}_error` : undefined}
                maxLength={maxLength}
            />
        </FormGroup>
    );
};
