import { useState, useRef } from "react";
import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import _ from "lodash";

import { PaymentProcessingModal, PaymentRequestPendingModal, QrPaymentRequestPendingModal } from "components/Transactions";
import NewPaymentModal from "./NewPaymentModal";
// @ts-ignore
import { PaymentDetailsTabKeys } from "constants/billpay";

import * as commonActions from "components/Common/_actions/actions";
import * as paymentRequestActions from "components/PaymentRequests/_actions/paymentRequestActions";
import PaymentRequestActionTypes from "components/PaymentRequests/_actions/paymentRequestActionTypes";
import { Biller, PaymentProcessedTxnModel, MerchantModel } from "packages/webapi-client";
import { Customer } from "packages/utils/models";
import { RootState } from "store/store";
import { OrderType } from "models/OrderType";
import { Phone } from "models";

const CustomerPaymentModalSteps = {
    FORM: 1,
    PROCESSING_PAYMENT: 2,
    PROCESSING_REQUEST: 3,
    PROCESSING_QR_PAYMENT_REQUEST: 4
};

type Props = {
    show:boolean;
    onClose: (reload: boolean) => void;
    receipt?: PaymentProcessedTxnModel;
    merchant: MerchantModel;
    billers: Biller[];
    customer: Customer;
    paymentRequestId: string;
    paymentRequestActions: any;
    commonActions: any;
}

export type CustomerPaymentFormValues = {
    billerCodeForm: BillerCodeForm;
    orderType: OrderType;
    paymentInputSelector: PaymentInputSelector;
}

type BillerCodeForm = {
    merchantNumber: string;
    billerCode: string;
    billerCrnList: CrnList;
}

type CrnList = {
    crn1: string;
    crn2: string;
    crn3: string;
}

type PaymentInputSelector = {
    paymentRequest: PaymentRequestType;
}

type PaymentRequestType = {
    email: string;
    mobile: Phone;
}

/** New Payment form/modal + the payment-processing / Payment Receipt modals */
const CustomerPaymentModal = ({
    show, onClose, //Logic Renders
    merchant, billers, customer, paymentRequestId, //data
    paymentRequestActions, commonActions, //actions
}: Props) => {
    const [paymentStep, setPaymentStep] = useState(CustomerPaymentModalSteps.FORM);
    const [formValues, setFormValues] = useState<CustomerPaymentFormValues | null>(null);
    const [reloadRequired, setReloadRequired] = useState(false);
    const [selectedBiller, setSelectedBiller] = useState<Biller>();
    const paymentBeingProcessed = useRef({});

    function handleClose() {
        setFormValues(null);
        commonActions.clearErrors();
        onClose(reloadRequired);
    }

    async function onNewPaymentSubmit(values: any, actionType:any) {
        setFormValues(values); // Save the values, to be restored in case of an error
        setSelectedBiller(_.find(billers, { billerCode: values.billerCodeForm.billerCode }));
        const paymentDetails = {
            customerId: customer.customerId,
            merchantReference: values.merchantReference,
            amount: values.amount,
            currencyCode: merchant.currency?.code,
            billerCodeForm: {
                childMerchantNumber: values.billerCodeForm.merchantNumber,
                billerCode: values.billerCodeForm.billerCode,
                billerCrnList: {
                    crn1: values.billerCodeForm.billerCrnList.crn1,
                    crn2: values.billerCodeForm.billerCrnList.crn2,
                    crn3: values.billerCodeForm.billerCrnList.crn3,
                },
            }
        };

        if (actionType === PaymentDetailsTabKeys.PAYMENT) {
            const newCard = values.paymentInputSelector.radio === "new" || !values.paymentInputSelector.radio;
            // Will always be a new card if the radio isn't even defined (since only appears with multiple payment inputs.

            const payment = _.merge(paymentDetails, {
                orderType: values.orderType,
                saveCustomerPaymentMethod: newCard
                    ? _.get(values.paymentInputSelector, "saveNewToken")
                    : null,
                paymentRequest: values.paymentInputSelector.paymentRequest,
                card: {
                    cardholderName: newCard ? values.paymentInputSelector.card.cardholderName : null,
                    cardNumber: newCard ? values.paymentInputSelector.card.cardNumber : values.paymentInputSelector.radio,
                    expiryDate: newCard ? values.paymentInputSelector.card.expiryDate : { month: null, year: null },
                    cvn: newCard ? values.paymentInputSelector.card.cvn : null,
                }
            });

            paymentBeingProcessed.current = payment;
            setPaymentStep(CustomerPaymentModalSteps.PROCESSING_PAYMENT);
        }
        else if (actionType === PaymentDetailsTabKeys.REQUESTS || actionType === PaymentDetailsTabKeys.QRREQUESTS)  {
            const payment = _.merge(paymentDetails, {
                ...values.paymentInputSelector.paymentRequest,
                orderType: values.orderType
            });
            const action = payment.savePaymentMethod ? PaymentRequestActionTypes.PaymentAndTokenise : PaymentRequestActionTypes.PaymentOnly;
            if (actionType === PaymentDetailsTabKeys.REQUESTS) {
                // set paymentStep after paymentRequestId is set
                try {
                    await paymentRequestActions.create(payment, action, mapErrorsFromDto);
                    setPaymentStep(CustomerPaymentModalSteps.PROCESSING_REQUEST);
                } catch {
                    setPaymentStep(CustomerPaymentModalSteps.FORM);
                }
            } else {
                try {
                    await paymentRequestActions.createQrRequest(payment, action, mapErrorsFromDto);
                    setPaymentStep(CustomerPaymentModalSteps.PROCESSING_QR_PAYMENT_REQUEST);
                } catch {
                    setPaymentStep(CustomerPaymentModalSteps.FORM);
                }
            }
        } else {
            throw Error("Invalid state when submitting payment.");
        }
    }

    function handleModalClosed(reset?: boolean, dismiss?: boolean) {
        paymentBeingProcessed.current = {};
        setPaymentStep(CustomerPaymentModalSteps.FORM);

        // reset should be true only when the payment was successful OR when user clicked "New Payment" button
        if (reset) {
            setFormValues(null); // clear the form
            setReloadRequired(true);
        }

        // only close modal when dismiss is true, dismiss is undefined when user click "Start a new payment" button
        if (dismiss)
            handleClose();
    }

    function mapErrorsFromDto(parameter: string) {  // We have this custom mapper only because the form structure here is different than the default one (ie. NewPaymentForm)
        switch (parameter) {
            case "crn1":
            case "reference1":
                return "billerCodeForm.billerCrnList.crn1";
            case "crn2":
            case "reference2":
                return "billerCodeForm.billerCrnList.crn2";
            case "crn3":
            case "reference3":
                return "billerCodeForm.billerCrnList.crn3";
            case "card.number":
                return "paymentInputSelector.card.cardNumber";
            case "card.expiryDate":
                return "paymentInputSelector.card.expiryDate";
            case "emailAddress":
                return "paymentInputSelector.paymentRequest.email";
            case "mobileNumber":
                return "paymentInputSelector.paymentRequest.mobile";
            case "dueDate":
                return "paymentInputSelector.paymentRequest.dueDate";
            default:
                return parameter;
        }
    }

    if (!show)
        return null;

    if (paymentStep === CustomerPaymentModalSteps.PROCESSING_PAYMENT)
        return <PaymentProcessingModal
            paymentRequest={paymentBeingProcessed.current}
            biller={selectedBiller}
            customerEmail={customer.emailAddress}
            customerPhoneNumber={customer.phoneNumbers && customer.phoneNumbers.length ? customer.phoneNumbers[0]?.phoneNumber : undefined}
            onClosed={handleModalClosed}
            mapErrorsFromDto={mapErrorsFromDto}
        />;

    if (paymentStep === CustomerPaymentModalSteps.PROCESSING_REQUEST && paymentRequestId) {
        return <PaymentRequestPendingModal
            paymentRequestId={paymentRequestId}
            biller={selectedBiller}
            onClosed={handleModalClosed}
        />;
    }

    if (paymentStep === CustomerPaymentModalSteps.PROCESSING_QR_PAYMENT_REQUEST && paymentRequestId) {
        return <QrPaymentRequestPendingModal
            paymentRequestId={paymentRequestId}
            biller={selectedBiller}
            handleModalClose={handleModalClosed}
        />;
    }

    return <NewPaymentModal
        show={paymentStep === CustomerPaymentModalSteps.FORM}
        onClose={handleClose}
        customerData={customer}
        initialValues={formValues}
        onSubmit={onNewPaymentSubmit}
    />;
};

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        paymentRequestActions: bindActionCreators(paymentRequestActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch),
    };
}

function mapStateToProps(state: RootState) {
    return {
        paymentRequestId: _.get(state.paymentRequest.create, "data.guid"),
        merchant: state.accounts.users.merchant,
        billers: state.accounts.users.activeBillers,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerPaymentModal);
