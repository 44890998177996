import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { IconText } from '@premier/ui';

/** Shows the scheduledPaymentStatus field with an alert icon if the status is Overdue */
const ScheduledPaymentStatusIcon = ({
    scheduledPayment,
}) => {
    const paymentStatus = _.get(scheduledPayment, 'scheduledPaymentStatus.key');
    const paymentStatusDescription = _.get(scheduledPayment, 'scheduledPaymentStatus.description');

    function getIconType() {
        switch (paymentStatus) {
            case 'OVERDUE':
                return {alert: true};
            case 'PROCESSING':
                return {loading: true};
            case 'RECEIVED':
                return {tick: true};
            case 'CANCELLED':
            default:
                return {info: true};
        }
    }

    return <IconText {...getIconType()}>{paymentStatusDescription ? paymentStatusDescription : paymentStatus[0] + paymentStatus.substring(1).toLowerCase()}</IconText>
}

ScheduledPaymentStatusIcon.propTypes = {
    /** The scheduled payment containing a scheduledPaymentStatus object */
    scheduledPayment: PropTypes.shape({
        scheduledPaymentStatus: PropTypes.shape({
            key: PropTypes.string,
            description: PropTypes.string
        })
    })
};

export default ScheduledPaymentStatusIcon;