import { Biller } from 'packages/webapi-client';
import store from '../store/store';
import { DisplayableError } from 'components/Common'

export function VerifyBiller(billerCode : string, billerMustBeEnabled : boolean = false) {
    const billers = store.getState().accounts.users.billers;
    const result = billers.find((b : Biller) => b.billerCode === billerCode);
    if(!result) {
        throw new DisplayableError("Biller code not found or an error occurred.")
    }

    if (billerMustBeEnabled && !result.enabled) {
        throw new DisplayableError("Biller code not enabled or an error occurred.")
    }
}