import { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import _ from "lodash";

import countryUtil from "@premier/utils/country";
import dateUtil from "@premier/utils/date";
import * as billpayUtil from "@premier/utils/billpay";

// @ts-ignore
import errorMaps from "constants/errorMaps";
// @ts-ignore
import { PaymentMethodDetailsTabKeys } from "constants/billpay";

import { Form, SubmitButton, FormErrorList } from "@premier/form";
import { Divider, DescriptionList, Link, Modal, Button, LoadingIndicator, ButtonContainer, SubtleButtonTooltip } from "@premier/ui";
import { PaymentMethodRequestPendingModal, PaymentMethodDetailsForms } from "components/DataVault";
import { FormError } from "components/Common";

import * as tokenActions from "components/DataVault/_actions/tokenActions";
import * as paymentRequestActions from "components/PaymentRequests/_actions/paymentRequestActions";
import PaymentRequestActionTypes from "components/PaymentRequests/_actions/paymentRequestActionTypes";
import { PlatformRoutesConfiguration } from "components/Routing";
import { RootState } from "store/store";
import { Token } from "models/Request";
import { FieldError } from "api/mapErrors";
import { Merchant } from "models/Merchant";
import { SurchargeRule } from "packages/utils/models";
import { getCardInfo } from "@premier/cards";

type Props = {
    actions: any;
    paymentRequestActions: any;
    token: Token;
    merchant: Merchant;
    paymentRequestId: string;
    surchargeRules?: SurchargeRule[];
    onCancel: () => void;
    onSaved: () => void;
    onUnlink?: () => void;
    onDelete: () => void;
    errors: FieldError[];
}

const EditTokenModalContent = ({
    actions, paymentRequestActions, //API actions
    token, //data
    merchant, paymentRequestId, surchargeRules, //state
    onCancel, onSaved, onUnlink, onDelete, //functions
    errors, //form
}: Props) => {
    const [showRequestPendingModal, setShowRequestPendingModal] = useState(false);
    const [validationErrors, setValidationErrors] =  useState<string[]>([]);

    const merchantOptions = billpayUtil.getMerchantOptions(merchant, false);

    function handleSave(values: any, context: any) {
        if (isCreateAction(context)) {
            const cardInfo = getCardInfo(context.getValue("paymentTypeInput.card.cardNumber") || context.getValue("paymentTypeInput.card.maskedCardNumber"));
            if (surchargeRules?.length && cardInfo?.network?.serverCode && !surchargeRules.some((r) => r.cardTypeCode === cardInfo?.network?.serverCode)) {
                setValidationErrors(["Payment method is not supported"]);
                return;
            } else {
                setValidationErrors([]);
            }

            actions.updateToken({
                ...token,
                customerIdentified: true,
                ...values,
            }).then(() => {
                onSaved();
            });
        } else {
            const paymentRequestDetails = {
                ...values.paymentRequest,
                billerCodeForm: {
                    billerCode: values.paymentRequest.billerCodeForm.billerCode,
                    childMerchantNumber: token.childMerchantNumber,
                    billerCrnList: {
                        crn1: values.paymentRequest.billerCodeForm.billerCrnList.crn1,
                        crn2: values.paymentRequest.billerCodeForm.billerCrnList.crn2,
                        crn3: values.paymentRequest.billerCodeForm.billerCrnList.crn3,
                    },
                },
                dataVaultId: token.dataVaultId,
                childMerchantNumber: token.childMerchantNumber,
                currencyCode: merchant.currency?.code,
                action: PaymentRequestActionTypes.UpdateToken
            };
            paymentRequestActions.create(paymentRequestDetails).then(() => {
                setShowRequestPendingModal(true);
            });

        }
    }

    function handleClose() {
        setShowRequestPendingModal(false);
        onSaved();
    }

    function isCreateAction(context: any) {
        return context.getValue("paymentMethodDetailsTab") === PaymentMethodDetailsTabKeys.CREATE;
    }

    if (!token)
        return <Modal.Body><LoadingIndicator /></Modal.Body>;

    if (showRequestPendingModal && paymentRequestId)
        return <PaymentMethodRequestPendingModal
            paymentMethodRequestId={paymentRequestId}
            onClose={() => handleClose()}
        />;

    function mapErrors(errors: FieldError[]) {
        return errors?.map(e => ({
            ...e,
            field: e.field ? mapErrorsFromDto(e.field) : ""
        }));
    }

    function mapErrorsFromDto(parameter: string) {
        switch (parameter) {
            case "billerCodeForm.billerCrnList.crn1":
                return "paymentRequest.billerCodeForm.billerCrnList.crn1";
            case "billerCodeForm.billerCrnList.crn2":
                return "paymentRequest.billerCodeForm.billerCrnList.crn2";
            case "billerCodeForm.billerCrnList.crn3":
                return "paymentRequest.billerCodeForm.billerCrnList.crn3";
            default:
                return parameter;
        }
    }

    return (<>
        <Modal.Header closeButton>
            <Modal.Title>Edit payment method</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            <h2>Overview</h2>
            <DescriptionList spaceBetween hideBlankValue items={[
                { name: "Token", value: <Link newWindow to={PlatformRoutesConfiguration.tokenRoute?.viewToken.generatePath(token.dataVaultId)}>{token.token}</Link> },
                { name: "Last updated", value: token.updatedTime ? dateUtil.convertToDateTimeString(token.updatedTime) : "" },
                { name: "Merchant", value: merchantOptions.length > 1 ? _.find(merchantOptions, { "value": token.childMerchantNumber })?.label : "" },
            ]} />
            <Divider />

            <Form
                initialValues={{
                    paymentMethodDetailsTab: PaymentMethodDetailsTabKeys.CREATE,
                    paymentRequest: {
                        mobile: { iddCode: countryUtil.getIddCode(merchant.countryCode) },
                        billerCodeForm: {
                            merchantNumber: token.childMerchantNumber
                        }
                    }
                }}
                onSubmit={handleSave}
                errors={mapErrors(errors)}
                errorMaps={errorMaps}
                render={context => (<>
                    <h2>Account details</h2>

                    <PaymentMethodDetailsForms allowBillerSelection token={token} merchantNumber={token.childMerchantNumber} merchant={merchant} />

                    <FormError errors={validationErrors} />

                    <ButtonContainer justifyOverride>
                        <SubmitButton primary>{isCreateAction(context) ? "Save changes" : "Send"}</SubmitButton>
                        <Button onClick={onCancel}>Cancel</Button>
                    </ButtonContainer>

                    <FormErrorList />
                </>)}
            />
            <Divider />

            <SubtleButtonTooltip
                onClick={onUnlink}
                tooltipId="tip-add-to-library"
                tooltipData='The payment method will not be deleted. You can still find the payment method from "Payment methods" and link it to a customer.'
                hide={!onUnlink}
            >
                Unlink payment method
            </SubtleButtonTooltip>

            <Button subtle onClick={onDelete}>Delete payment method</Button>
        </Modal.Body>
    </>);
};

function mapStateToProps(state: RootState) {
    return {
        paymentRequestId: _.get(state.paymentRequest.create, "data.guid"),
        merchant: state.accounts.users.merchant,
        token: state.dataVault.token.details,
        errors: state.dataVault.token.errors,
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        actions: bindActionCreators(tokenActions, dispatch),
        paymentRequestActions: bindActionCreators(paymentRequestActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditTokenModalContent);
