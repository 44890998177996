import { userRoles } from "components/Routing";
import { NewPaymentRequestPage, ManagePaymentRequestsPage, ViewPaymentRequestPage, ManageBatchPaymentRequestsPage } from "components/PaymentRequests";

export const paymentRequestRoute = {
    root: {
        path: "/requests",
        generatePath: () => "/requests",
        roles: [userRoles.paymentRequest]
    },
    newRequest: {
        path: "/requests/new-request",
        generatePath: () => "/requests/new-request",
        element: <NewPaymentRequestPage />,
        title: "New Request",
        roles: [userRoles.paymentRequest]
    },
    manageRequests: {
        path: "/requests/manage",
        generatePath: () => "/requests/manage",
        // @ts-ignore
        element: <ManagePaymentRequestsPage />,
        title: "Requests",
        roles: [userRoles.paymentRequest]
    },
    requestDetails: {
        path: "/requests/:id",
        generatePath: (id: number) => `/requests/${id}`,
        element: <ViewPaymentRequestPage />,
        title: "Payment request details",
        roles: [userRoles.paymentRequest]
    },
};

export const batchPaymentRequestRoute = {
    manageBatchRequests: {
        path: "/batch-requests",
        generatePath: () => "/batch-requests",
        element: <ManageBatchPaymentRequestsPage />,
        title: "Batch requests",
        roles: [userRoles.paymentRequestBatch]
    },
    root: {
        path: "/batch-requests",
        generatePath: () => "/batch-requests",
    },
};
