import * as actions from '../../Batch/_actions/actionTypes';
import * as accountActions from 'components/Account/_actions/actionTypes';
import * as commonActions from 'components/Common/_actions/actionTypes';

export default function batchReducer(state = {}, action) {
    switch(action.type) {
        case actions.GET_BATCH_JOBS_SUCCESS:
            return Object.assign({}, state, {
                batchFilesResult: {
                    items         : action.result.batchFiles,
                    count         : action.result.resultCount,
                    completedCount: action.result.completedCount,
                    progressCount : action.result.progressCount,
                    scheduledCount: action.result.scheduledCount,
                    progressErrors: action.result.progressErrors,
                },
            });

        case actions.SAVE_BATCH_FILES_PAGE_STATE:
            return Object.assign({}, state, {
                batchFilesPageState: action.pageState
            });


        case actions.GET_BATCH_LIBRARY_FILES_SUCCESS:
            return Object.assign({}, state, {
                batchLibraryFilesResult: {
                    items: action.result.libraryFiles,
                    count: action.result.resultCount,
                },
            });

        case actions.SAVE_BATCH_LIBRARY_PAGE_STATE:
            return Object.assign({}, state, {
                batchLibraryPageState: action.pageState
            });


        case actions.GET_BATCH_LIBRARY_FILE_SUCCESS:
            return Object.assign({}, state, {
                batchFileResult: action.result
            });

        case actions.SAVE_BATCH_RECORDS_PAGE_STATE:
            return Object.assign({}, state, {
                batchRecordsPageState: action.pageState
            });

        case actions.RENAME_BATCH_LIBRARY_FILE_SUCCESS:
            return Object.assign({}, state, {
                batchFileResult: {...state.batchFileResult,
                    fileDetails: {...state.batchFileResult.fileDetails,
                        filename: action.filename
                    }
                }
            });



        case accountActions.LOGOUT_USER_SUCCESS:
        case commonActions.CLEAR_SENSITIVE_DATA:
            return {
                asyncCount: state.asyncCount,
                isLoading: state.isLoading,
            };
        default:
                return state;

    }
}