import { config } from './util';
import {
    ApiResponseReceiptDetailsModel,
    ReceiptDetailsApiFactory,
    ReceiptDetailsModel,
} from '@premier/webapi-client';
import { AxiosPromise } from 'axios';
import mapErrors, { ApiResult } from './mapErrors';
import httpRequest from './httpRequest';

const businessReceiptDetailsApi = ReceiptDetailsApiFactory(config);

class BusinessDetailsApi {
    static getReceiptDetails(
        options?: any
    ): AxiosPromise<ApiResponseReceiptDetailsModel> {
        return businessReceiptDetailsApi.receiptDetailsGetReceiptDetails(
            options
        );
    }

    static updateReceiptDetails(model: ReceiptDetailsModel, files: FileList, currentLogo: any): Promise<ApiResult<void>> {
        if (files?.length) {
            return new Promise((resolve, reject) => {
                httpRequest.postFiles('/ReceiptDetails/UpdateReceiptDetailsWithLogo', files, { ReceiptDetailsModel: model })
                    .then(() => {
                        resolve({
                            ok: true,
                            val: undefined
                        });
                    })
                    .catch((err) => {
                        reject(mapErrors(err));
                    });
            });
        } else {
            // currentLogo is {} after delete logo 
            if (Object.keys(currentLogo).length === 0) {
                return new Promise((resolve, reject) => {
                    httpRequest.post('/ReceiptDetails/DeleteReceiptDetailsLogo')
                        .then(() => {
                            return httpRequest.post('/ReceiptDetails/UpdateReceiptDetails', model);
                        })
                        .then(() => {
                            resolve({
                                ok: true,
                                val: undefined
                            });
                        })
                        .catch((err) => {
                            reject(mapErrors(err));
                        });
                });
            }
            return new Promise((resolve, reject) => {
                httpRequest.post('/ReceiptDetails/UpdateReceiptDetails', model)
                    .then(() => {
                        resolve({
                            ok: true,
                            val: undefined
                        });
                    })
                    .catch((err) => {
                        reject(mapErrors(err));
                    });
            });
        }
    }
}

export default BusinessDetailsApi;
