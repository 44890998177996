import React, {useState} from 'react';
import { Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { SingleColumnFormContainer } from '@premier/ui';
import { TokenDetailsForm, PaymentMethodRequestPendingModal } from 'components/DataVault';
import { PageHeader } from 'components/Common';

import { PaymentMethodDetailsTabKeys } from 'constants/billpay';
import { PlatformRoutesConfiguration } from 'components/Routing';
import * as tokenActions from 'components/DataVault/_actions/tokenActions';
import * as paymentRequestActions from 'components/PaymentRequests/_actions/paymentRequestActions';
import PaymentRequestActionTypes from 'components/PaymentRequests/_actions/paymentRequestActionTypes';

import './NewTokenPage.scss';

const NewTokenPage = ({
    actions, paymentRequestActions, //API actions
    merchant, errors
}) => {

    const [redirectToView, setRedirectToView] = useState(false);
    const [redirectToManage, setRedirectToManage] = useState(false);
    const [newTokenId, setNewTokenId] = useState(0);
    const [sentPaymentMethodRequestId, setSentPaymentMethodRequestId] = useState();

    function handleSubmit(values) {
        if (values['paymentMethodDetailsTab'] === PaymentMethodDetailsTabKeys.CREATE) {
            actions.addToken(values)
                .then(token => {
                    setNewTokenId(token.dataVaultId);
                    setRedirectToView(true);
                })
                .catch(err => {
                    console.error(err);
                });
        } else {
            var paymentDetails = {
                ...values.paymentRequest,
                billerCodeForm: {
                    billerCode: values.tokenDetailsBillerCodeForm.billerCode,
                    childMerchantNumber: values.tokenDetailsBillerCodeForm.merchantNumber,
                    billerCrnList: values.tokenDetailsBillerCodeForm.billerCrnList,
                },
                currencyCode: merchant.currency.code,
                action: PaymentRequestActionTypes.TokeniseOnly
            };
            paymentRequestActions.create(paymentDetails).then((request) => {
                setSentPaymentMethodRequestId(request.guid);
            });
        }
    }

    function handlePaymentMethodRequestModalClosed() {
        setRedirectToManage(true);
    }

    if (redirectToView)
        return <Navigate to={PlatformRoutesConfiguration.tokenRoute.viewToken.generatePath(newTokenId)} />;

    if (redirectToManage)
        return <Navigate to={PlatformRoutesConfiguration.tokenRoute.manageTokens.generatePath()} />;

    return (<>
        <SingleColumnFormContainer className='new-token-form'>
            <PageHeader
                backButton
                title='New payment method'
            />

            <TokenDetailsForm
                errors={errors}
                onSubmit={handleSubmit}
            />
        </SingleColumnFormContainer>



        {sentPaymentMethodRequestId &&
            <PaymentMethodRequestPendingModal
                paymentMethodRequestId={sentPaymentMethodRequestId}
                onClose={handlePaymentMethodRequestModalClosed}
            />
        }
    </>)
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(tokenActions, dispatch),
        paymentRequestActions: bindActionCreators(paymentRequestActions, dispatch),
    };
}

function mapStateToProps(state) {
    return {
        errors: state.dataVault.token.errors,
        merchant: state.accounts.users.merchant,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(NewTokenPage);