import { useState, useEffect, useCallback, DependencyList } from "react";
import { ApiError } from '@premier/webapi-client';
import { APICallRequestState } from "./useApiCall";
// Successor to withApiCall, DO NOT use withApiCall.
// Parameter apiCallback is expecting a Function that returns an Axios promise but the callback can also return null
// You can use this to prevent the API call from running eg. you need to wait on some dependencies first to load.
// See any of the autogenerated Swagger API client calls.
// Parameter deps works similar to useEffect dependency array and can be used to watch a change in variables and reload if needed.

/**
  @deprecated
  This function has been marked as deprecated because it does not support type safety and types do not flow through here to client code. Use useApiCall instead.
**/
export const useApiCallDeprecated = (apiCallback : (...args: any[]) => any, deps : DependencyList) => {
  const [data, setData] = useState<any>(null);
  const [errors, setErrors] = useState<ApiError[]>([]);
  const [requestState, setRequestState] = useState<APICallRequestState>(APICallRequestState.PENDING);

  // See https://reactjs.org/docs/hooks-faq.html#is-it-safe-to-omit-functions-from-the-list-of-dependencies
  // If apiCallback is added to deps (this removes the warning), the lambda function creates a new "signature" and causes useApiCallDeprecated to run indefinitely.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const callbackRef = useCallback<(...args: any[]) => any>(apiCallback, deps);

  useEffect(() => {
      const resolveApiCall = async () => {
        try {
          setRequestState(APICallRequestState.LOADING);
          const request = await callbackRef();
          if (request?.status === 200) { // Successful result from an Axios request
              setData(request.data.data);
              setErrors(request.data.errors);
              setRequestState(APICallRequestState.SUCCESSFUL);
          } else { // If anything else was returned, throw it to be captured below
            throw request; // Will be captured to the catch as below
          }
        } catch (error : any) {
          setRequestState(APICallRequestState.ERROR);
          if (error?.data?.errors?.length) { // This is the thrown request as above
            // Format for each object is {code: 'MANDATORY_PARAM', label: 'PaymentPlanOptionName', message: "example"}
            setData(null);
            setErrors(error.data.errors);
          } else {
            if (error instanceof Error) {
              throw error; // Re-throw the error because an application crashing error has occurred (mostly likely will be captured by ExceptionErrorBoundary)
            }
            // No API error for whatever reason (this can happen if apiCallback returns null because it wasn't ready to commit to a network request),
            // have a fallback error message for display purposes
            setData(null);
            const fallbackError : ApiError = { message: "An unknown error was encountered. Please try again." }
            setErrors([fallbackError]);
          }
        }
      }
      resolveApiCall();
  }, [callbackRef]);

  return [ data, requestState, errors ]; // errors should always return an array
};