//import React from 'react';
//import {PaddedContainer, IconText} from '@premier/ui';
import './DeliveryImpactNotice.scss';

const DeliveryImpactNotice = () => {
    /* disable the container, leave the code in here in case we want to display message again
    return (
        <PaddedContainer className='delivery-impact-notice'>
            <IconText info>
                We're aware of a technical issue with our delivery partner which is impacting delivery times, so it may take up to 14 days for your stationery items to be delivered. Call
                &nbsp;<a href="tel:1800 230 177">1800 230 177</a>&nbsp;
                for any urgent stationery deliveries.
            </IconText>
        </PaddedContainer>
    ); */

    return(null);
};

export default DeliveryImpactNotice;