import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';

import uiUtil from '@premier/utils/ui';
import currencyUtil from '@premier/utils/currency';
import dateUtil from '@premier/utils/date';
import { Button, ButtonContainer, Divider } from '@premier/ui';
import { Form, FormErrorList, SubmitButton, InputField, CurrencyField, DropdownField, DatePickerField, validate, RadioField, CheckboxField } from '@premier/form';

import { MerchantBillerDetailsForm } from 'components/Transactions';

import errorMaps from 'constants/errorMaps';
import labels from 'constants/labels';

import './ScheduleDetailsForm.scss';

/** A Form to collect Schedule details */
const ScheduleDetailsForm = ({
    name, schedule, scheduleFrequencies, merchantNumber, billerCrnList, loading, //data
    billers, addErrors, updateErrors, //state
    onSubmit, onCancel, //functions
    canSave, canSelectMerchant, //logic renders
    ...otherProps
}) => {
    const NO_END = 'NO_END';
    const END_AFTER = 'END_AFTER';
    const END_DATE = 'END_DATE';

    const endDateOptions = [
        {
            value: NO_END,
            label: 'No end date',
        },
        {
            value: END_AFTER,
            label: (
                <>
                    <span>End after</span>
                    <InputField
                        name='numberOfPayments'
                        labelText={labels.numberOfPayments}
                        className='end-after-field'
                        placeholder='e.g. 5'
                        onChange={handleEndAfterChanged}
                        maxLength={6}
                    />
                    <span>payments</span>
                </>
            ),
        },
        {
            value: END_DATE,
            label: (
                <>
                    <span>End on</span>
                    <DatePickerField
                        name='endDate'
                        labelText={labels.endDate}
                        className='end-date-field'
                        minDate={schedule ? null : moment().add(2, 'days').startOf('day').toDate()}
                        onChange={handleEndDateChanged}
                        autoComplete='off'
                    />
                </>
            ),
        },
    ];

    const paymentFrequencyOptions = uiUtil.getOptions(scheduleFrequencies);
    const scheduleEndFieldName = `${name || 'ScheduleDetailsForm'}_scheduleEnd`;

    function getInitialValues() {
        const values = {
            ...schedule,
            numberOfPayments:schedule?.remainingPayments,
            scheduleDetailsBillerCodeForm: {
                merchantNumber: merchantNumber,
                billerCode: _.get(schedule, 'billerCode', ''),
                billerCrnList: {
                    crn1: _.get(schedule, 'reference1', ''),
                    crn2: _.get(schedule, 'reference2', ''),
                    crn3: _.get(schedule, 'reference3', ''),
                },
            },
        };

        if (schedule) {
            let endType;
            if (schedule.numberOfPayments) endType = END_AFTER;
            else if (schedule.endDate) endType = END_DATE;
            else endType = NO_END;

            values[scheduleEndFieldName] = endType;
            values.recurringAmount = currencyUtil.fromApiValue(schedule.recurringAmount);
            values.startDate = new Date(dateUtil.convertToMerchantDate(schedule.startDate));
            values.nextPaymentDate = new Date(dateUtil.convertToMerchantDate(schedule.nextPaymentDate));

            if (schedule.endDate) values.endDate = new Date(dateUtil.convertToMerchantDate(schedule.endDate));
        } else if (billerCrnList) {
            // if access ScheduleDetailsForm from payment method -> new subscription
            values.scheduleDetailsBillerCodeForm.billerCrnList.crn1 = billerCrnList.reference1;
            values.scheduleDetailsBillerCodeForm.billerCrnList.crn2 = billerCrnList.reference2;
            values.scheduleDetailsBillerCodeForm.billerCrnList.crn3 = billerCrnList.reference3;
        }

        return values;
    }

    function getDateValue(date) {
        if (!Array.isArray(date)) return date;
        return _.isEmpty(date) ? null : date[0];
    }

    function getStartDate(values) {
        return values.nextPaymentDate ? getDateValue(values.nextPaymentDate) : getDateValue(values.startDate);
    }

    function handleScheduleEndChanged(selectedOption, context) {
        switch (selectedOption) {
            case END_AFTER:
                context.setValue('endDate', null);
                break;
            case END_DATE:
                context.setValue('numberOfPayments', null);
                break;
            default:
                context.setValue('endDate', null);
                context.setValue('numberOfPayments', null);
                break;
        }
    }

    function handleEndAfterChanged(newValue, context) {
        if (newValue && !_.isEmpty(newValue)) {
            context.setValue(scheduleEndFieldName, END_AFTER);
            context.setValue('endDate', null);
        }
    }

    function handleEndDateChanged(newValue, context) {
        if (newValue && !_.isEmpty(newValue)) {
            context.setValue(scheduleEndFieldName, END_DATE);
            context.setValue('numberOfPayments', null);
        }
    }

    function handleSubmit(values, context) {
        if (!values.customerIdentified){
            context.setError('customerIdentified', 'Please check the box if you want to proceed');
            return;
        }

        const schedule = {
            ...values,
            scheduleEnd: values[scheduleEndFieldName],
        };

        onSubmit(schedule);
    }

    const renderChildren = _.memoize((billerCode, billers) => {
        const biller = _.find(billers, (b) => b.billerCode === billerCode);
        return otherProps.children(biller);
    });

    return (
        <Form
            name={name || 'ScheduleDetailsForm'}
            initialValues={getInitialValues()}
            initialValidation={{
                recurringAmount: validate().required(),
                frequency: validate().required(),
                startDate: validate().requiredIf(() => !schedule),
                [scheduleEndFieldName]: validate().required(),
                nextPaymentDate: validate().requiredIf(() => schedule),
                endDate: validate()
                    .requiredIf((values) => values[scheduleEndFieldName] === END_DATE)
                    .if(
                        (value, values) => !_.isEmpty(value) && (values.nextPaymentDate || values.startDate),
                        validate().when(
                            (value, values) => getDateValue(value) > getStartDate(values),
                            `${labels.endDate} must be after ${labels.startDate}`
                        )
                    ),
                numberOfPayments: validate().requiredIf((values) => values[scheduleEndFieldName] === END_AFTER),
            }}
            errors={schedule ? updateErrors : addErrors}
            errorMaps={errorMaps}
            onSubmit={handleSubmit}
            render={(ctx) => {
                const billerCode = _.get(ctx.getValue("scheduleDetailsBillerCodeForm"), "billerCode");
                return <>
                    <div className='schedule-details-form-fields'>
                        <MerchantBillerDetailsForm
                            name='scheduleDetailsBillerCodeForm'
                            hideMerchantField={!canSelectMerchant}
                        />

                        <InputField name='scheduleReference' label={labels.subscriptionReference} />

                        <CurrencyField name='recurringAmount' label={labels.recurringAmount} />

                        <DropdownField
                            name='frequency'
                            label={labels.frequency}
                            options={paymentFrequencyOptions}
                        />

                        {schedule && (
                            <DatePickerField
                                name='nextPaymentDate'
                                label={labels.nextPayment}
                                minDate={moment().add(1, 'days').startOf('day').toDate()}
                            />
                        )}

                        {!schedule && (
                            <DatePickerField
                                name='startDate'
                                label={labels.startDate}
                                minDate={moment().add(1, 'days').startOf('day').toDate()}
                            />
                        )}

                        <RadioField
                            name={scheduleEndFieldName}
                            className='schedule-end-field'
                            label={labels.end}
                            options={endDateOptions}
                            onChange={handleScheduleEndChanged}
                            mandatory
                        />
                    </div>

                    {renderChildren(billerCode, billers)}

                    <Divider />
                    <CheckboxField mandatory name='customerIdentified'>
                        I have identified the customer and advised that the conditions of the Direct Debit will be made available within 7 days
                    </CheckboxField>

                    <FormErrorList />

                    {canSave && (
                        <>
                            <Divider />
                            <ButtonContainer>
                                <SubmitButton loading={loading}>Save</SubmitButton>
                                <Button onClick={onCancel}>Cancel</Button>
                            </ButtonContainer>
                        </>
                    )}
                </>;
            }}
        />
    );
};

ScheduleDetailsForm.propTypes = {
    /** property for the name of the form */
    name: PropTypes.string,
    schedule: PropTypes.object,
    /** property for schedule frequency options */
    scheduleFrequencies: PropTypes.object.isRequired,
    /** property to allow or prevent the form from saving */
    canSave: PropTypes.bool,
    /** property to show or hide the merchant selector */
    canSelectMerchant: PropTypes.bool,

    /** function called when the form is submitted */
    onSubmit: PropTypes.func.isRequired,
    /** function called when the Cancel button is clicked */
    onCancel: PropTypes.func.isRequired,
    /** property to billerCrnList */
    billerCrnList: PropTypes.object
};

function mapStateToProps(state) {
    return {
        loading: state.transactions.scheduledPayment.isLoading,
        addErrors: state.transactions.schedule.add.errors,
        updateErrors: state.transactions.schedule.update.errors,
        billers: state.accounts.users.activeBillers,
    };
}

export default connect(mapStateToProps)(ScheduleDetailsForm);
