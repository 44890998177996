import regexUtil from './regEx';

/** Returns the hex form of the given colour (eg. #a52a2a), or null if given invalid colour or on unsupported browser.
 * @param {string} colour eg. 'brown' or 'rgb(30,70,70)'
*/
export function getHexColour(colour: string) {  // From https://stackoverflow.com/a/47355187/36036
    colour = colour.trim();
    if(new RegExp(regexUtil.colourHexRegex, 'i').test(colour))
        return colour;  // Already in hex

    var canvas = document.createElement('canvas');
    if(!canvas.getContext)
        return null;  // Unsupported browser

    var ctx = canvas.getContext('2d');
    if(!ctx)
        return null;  // Unsupported browser


    // --- Conversion ---

    const DEFAULT_FILL_COLOUR = '#000000';  // Apparently always #000000 in all browsers

    if(colour.toLowerCase() === 'black')  // Handle default canvas fill colour
        return DEFAULT_FILL_COLOUR;

    ctx.fillStyle = colour;
    if(!ctx.fillStyle || ctx.fillStyle === DEFAULT_FILL_COLOUR)
        return null;  // Invalid colour

    return ctx.fillStyle;
}
