import React from 'react';
import PropTypes from 'prop-types';
import { NavMenu } from '@premier/ui';

import exit from 'images/exit.svg';
import iconProfile from 'images/agent-avatar-32-px.svg';

import './UserNavigation.scss';

const UserNavigation = (props) => {

    // function getInitials(user){
    //     var retValue = String(user.firstName[0] || "") + String(user.lastName[0] || "");
    //     return retValue === "" ? user.username.substring(0,2).toUpperCase() : retValue;
    // }

    const openedButton = (
        <div className="menu-toggle-button menu-right profile-image">
            {/* <div className="profile-background-circle">
                <div className="initials-text">{getInitials(props.user)}</div>
            </div> */}
            <img src={iconProfile} className="profile-image-placeholder" alt="profile-avatar" ></img>
        </div>);

    const closedButton = (
        <div className="menu-toggle-button menu-right exit-button-right">
            <img className="img" src={exit} alt="exit-button" />
        </div>);

    return (
        <>
            <NavMenu items={props.items} 
                toggleOpen={closedButton}
                toggleClosed={openedButton}
                right/>
        </>
    );
}

UserNavigation.propTypes = {
    items: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default UserNavigation; 