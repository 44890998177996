import React, { ClipboardEvent, DragEvent, FormEvent, forwardRef, KeyboardEvent, LegacyRef } from 'react';
import KeyCode from 'keycode-js';
import classNames from 'classnames';
import './Input.scss';

export type InputProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
    /** Only allow digits in the textbox */
    digitsOnly?: boolean,
    /** Only allow non-negative number/decimal in the textbox */
    decimal?: boolean,
    pattern?: string,
    /** Do not show up/down arrows on input[type=number] */
    noSpinner?: boolean,
    /** Style the textbox accordingly when truthy (eg. red border) */
    error?: boolean | string,
    /** Display field as plaintext text (no textbox) */
    plaintext?: boolean,
    /** Display field as readonly text (with disabled textbox) */
    readonly?: boolean,

    /** To limit the characters that can be entered. eg. key => /[0-9]/.test(key) */
    filterKey?: (key: string) => boolean,
    onMaxLengthReached?: (event: KeyboardEvent<HTMLInputElement>) => void,
    /** Fires when the Enter key is pressed. eg. (e) => { alert(e.currentTarget.value); } */
    onEnter?: (event: KeyboardEvent<HTMLInputElement>) => void,
}

// This class has been deprecated. Please see ~/packages/formik-ui/src/Input.ts
const Input = forwardRef((props: InputProps, ref?: LegacyRef<HTMLInputElement>) => {
    var { digitsOnly, decimal, pattern, noSpinner, className, error, plaintext, filterKey,
          onInput, onKeyDown, onKeyUp, onKeyPress, onEnter, onDrop, onPaste, onMaxLengthReached,
          value, ...otherProps
        } = props;

    var navigationKeys = [KeyCode.KEY_CONTROL, KeyCode.KEY_SHIFT, KeyCode.KEY_TAB, KeyCode.KEY_BACK_SPACE, KeyCode.KEY_DELETE, KeyCode.KEY_LEFT, KeyCode.KEY_RIGHT, KeyCode.KEY_HOME, KeyCode.KEY_END];

    function isNagivationKey(keyCode: number) {
        return ~navigationKeys.indexOf(keyCode);
    }

    if (digitsOnly) {
        filterKey = filterKey || (key => /[0-9]/.test(key));
        pattern = pattern || '\\d*';
    }
    else if (decimal) {
        filterKey = filterKey || (key => /[0-9.,]/.test(key));
        pattern = pattern || '\\d*([.,]?\\d+)*';
    }

    /** Adds a filter to allow only certain characters to be entered into a field. */
    function filterInput(e: {currentTarget: HTMLInputElement}) {
        if (!filterKey)
            return;

        var input = e.currentTarget.value;

        if (!input)
            return;

        var value = "";
        for (var index = 0; index < input.length; index++) {
            if (filterKey(input.charAt(index))) {
                value += input.charAt(index);
            }
        }

        if (e.currentTarget.maxLength > 0 && value.length >= e.currentTarget.maxLength) {
            value = value.substr(0, e.currentTarget.maxLength);
        }

        e.currentTarget.value = value;
    }

    function getReachedMaxLength(target: HTMLInputElement) {
        return target.maxLength > 0 && target.value.length >= target.maxLength;
    }

    function handleInput(e: FormEvent<HTMLInputElement>) {
        filterInput(e);

        if (onInput) {
            onInput(e);
        }
    }

    function handleKeyDown(e: KeyboardEvent<HTMLInputElement>) {
        //filterInput(e);

        if (!isNagivationKey(e.keyCode) && getReachedMaxLength(e.currentTarget)) {
            //this won't work when type='number'
            var textIsSelected = e.currentTarget.selectionStart !== e.currentTarget.selectionEnd;
            //only prevent input when no text is selected
            if (!textIsSelected) {
                e.preventDefault();
            }
        }

        if (!e.isDefaultPrevented() && onKeyDown) {
            onKeyDown(e);
        }
    }

    function handleKeyUp(e: KeyboardEvent<HTMLInputElement>) {
        filterInput(e);

        if (!isNagivationKey(e.keyCode) && getReachedMaxLength(e.currentTarget) && onMaxLengthReached) {
            onMaxLengthReached(e);
        }

        if (onKeyUp) {
            onKeyUp(e);
        }
    }

    function handleKeyPress(e: KeyboardEvent<HTMLInputElement>) {
        if(onKeyPress)
            onKeyPress(e);

        if(e.key === 'Enter' && onEnter)
            onEnter(e);
    }

    function handleDrop(e: DragEvent<HTMLInputElement>) {
        filterInput(e);

        if (onDrop) {
            onDrop(e);
        }
    }

    function handlePaste(e: ClipboardEvent<HTMLInputElement>) {
        filterInput(e);

        if (onPaste) {
            onPaste(e);
        }
    }

    return (
        <input {...otherProps}
            value={(!value && !props.defaultValue && props.onChange) ? '' : value}
            className={
                classNames({
                    'textbox form-control': !plaintext,
                    'form-control-plaintext': plaintext,
                    'has-error': !!error,
                    'no-number-spinner': noSpinner,
                }, className)
            }
            pattern={pattern}
            onInput={handleInput}
            onKeyDown={handleKeyDown}
            onKeyUp={handleKeyUp}
            onKeyPress={handleKeyPress}
            onDrop={handleDrop}
            onPaste={handlePaste}
            ref={ref}
            disabled={props.disabled || plaintext || props.readonly}
        />
    );
});

export default Input;