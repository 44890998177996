import React from 'react';
import labels from 'constants/labels';

import { InputField, Form, CurrencyField, DatePickerField } from '@premier/form';
import { PageSection, SingleColumnFormContainer } from '@premier/ui';

/** Sub-form used for a user to submit a charge-back. Used for the contact us page */
const ChargebackForm = () => {
    return (
        <PageSection>
            <SingleColumnFormContainer>
                <Form
                    name='chargebackForm'
                    initialValues={{
                        caseNumber: '',
                        chargebackAmount: ''
                    }}>
                    <InputField name='caseNumber' label='Case number' />
                    <CurrencyField name='chargebackAmount' label={labels.amount} />
                    <DatePickerField
                        name='dateRange'
                        label={labels.dateRange}
                        mode='range'
                    />
                </Form>
            </SingleColumnFormContainer>
        </PageSection>
    );
};

export default ChargebackForm;
