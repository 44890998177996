import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '@premier/ui';
import withField from './withField';

/** This is a single checkbox. See CheckboxGroupField for multiple options mapped to a single field. */
const CheckboxField = ({
    name, 
    children, // CheckboxField specific props
    formProps, groupProps, ...otherProps
}) => {

    function handleChange(e) {
        formProps.setValue(!formProps.value);
    }

    return (
        <Checkbox
            {...otherProps}
            name={name}
            onChange={handleChange}
            checked={formProps.value}
            error={formProps.error}
        >
            {children}
        </Checkbox>
    );
};

CheckboxField.propTypes = {
    name: PropTypes.string,
    mandatory: PropTypes.bool,
    className: PropTypes.string,
};

export default withField(CheckboxField);
