import classNames from 'classnames';
import { SingleColumnFormContainer } from '@premier/ui';
import { InputField, DropdownField } from '@premier/form';
import labels from 'constants/labels';
import countryUtil from '@premier/utils/country';

const NewAddressInput = ({
    useWhiteTheme
}) => {
    return (<div className={classNames('new-address-form', { 'white': useWhiteTheme })}>
                <SingleColumnFormContainer>
                    <InputField name='customerName' label='Business name' />
                    <InputField name='addressLine1' label='Address line 1'/>
                    <InputField name='addressLine2' label='Address line 2'/>
                    <InputField name='suburb' label={labels.locality}/>
                    <div className='row'>
                        <div className='col-sm-6'>
                            <DropdownField name='state' label={labels.state} options={countryUtil.getStateOptions(countryUtil.country.countryCode, false)} />
                        </div>
                        <div className='col-sm-6'>
                            <InputField name='postcode' label={labels.postcode}/>
                        </div>
                    </div>
                </SingleColumnFormContainer>
            </div>
        );
    };


export default NewAddressInput;