import { ApiError } from 'packages/webapi-client';
import { Result } from '@premier/utils/helpers';

/**
 * Lowers the initial capital letters of a parameter
 * e.g. "FirstName" => "firstName", "CRN1" = "crn1"
 */
function lowerFirst(value: string) {
    for (var i = 0; i < value.length; i++) {
        if (value[i] === value[i].toLowerCase()) {
            return value.substring(0, i).toLowerCase() + value.substring(i);
        }
    }
    return value.toLowerCase();
}

function correctCasing(value: string) {
    return value.split(".").map((val) => lowerFirst(val)).join(".");
}

export type FieldError = {
    code?: string;
    message?: string;
    field?: string;
    label?: string;
}
export type ApiResult<T> = Result<T, FieldError[]>;

export default function mapErrors(errors: ApiError[], mapFunc?: (propertyName: string) => string): FieldError[] {
    return errors.map((val) => {
        var message = val.message;
        var field;
        if (val.parameter) {
            // replace the param name with {label} and fix the casing
            // eventually these should be fixed in the API itself
            // once everything is converted to this new error handling
            message = message?.replace(val.parameter, "{label}");
            field = correctCasing(val.parameter);

            if (mapFunc) {
                field = mapFunc(field) || field;
            }
        }
        return {
            message: message,
            field: field,
            code: val.code,
            label: val.label,
            parameter: val.parameter
        };
    });
}

export function mapResultErrors<T>(
    result: Result<T, ApiError[]>,
    mapFunc?: (propertyName: string) => string
): ApiResult<T> {
    if (result.ok)
        return result;

    return {
        ok: false,
        val: mapErrors(result.val, mapFunc)
    }
}
