import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ButtonContainer, Button, CloseButton } from '@premier/ui';
import { FormContext } from '@premier/form';

import './withAdvancedFilter.scss';

const withAdvancedFilter = (WrappedComponent) => {
    const AdvancedFilterComponent = ({
        show,  // render
        defaultFilter, lastSavedFilter, onHide, onChange, onSubmit = undefined,  // events
        ...otherProps
    }) => {

        const context = React.useContext(FormContext);

        function handleApply() {
            context
                .validate()
                .then(() => {
                    var values = context.values;

                    if(onSubmit)
                        values = onSubmit(values, context) || values;  // Allow the handler to update the values

                    onChange(values);
                    context.resetForm(values);

                    onHide();
                })
                .catch(() => {
                    // Prevent form from submitting if there are errors
                });
        };

        function handleReset() {
            context.resetForm(defaultFilter);
        }

        function handleUndo() {
            if (lastSavedFilter) {
                context.resetForm(lastSavedFilter);
            }
            onHide();
        }

        return (
            <div
                className={classNames('advanced-filter-container', {'hidden': !show})}
                aria-hidden={!show}
            >
                <CloseButton onClick={handleUndo} />

                <WrappedComponent context={context} {...otherProps} />

                <ButtonContainer divider>
                    <Button primary onClick={handleApply}>
                        Apply filters
                    </Button>
                    <Button type='reset' onClick={handleReset}>
                        Reset filters
                    </Button>
                </ButtonContainer>
            </div>
        );
    }

    AdvancedFilterComponent.propTypes = {
        show: PropTypes.bool.isRequired,

        /** The values to restore to when Reset is clicked */
        defaultFilter: PropTypes.object,
        /** The value to restor to when Close button is clicked */
        lastSavedFilter: PropTypes.object,

        onHide: PropTypes.func.isRequired,
        onChange: PropTypes.func.isRequired,
        onSubmit: PropTypes.func,
    };

    return AdvancedFilterComponent;
}

export default withAdvancedFilter;