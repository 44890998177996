import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { RootState } from "store/store";
import { APICallRequestState, PageHeader, useApiCall, useDebounce } from "components/Common";
import { DatePicker, DescriptionList, Divider, Dropdown, FormGroup, Input, LoadingIndicator, PaddedContainer, PageSection, PaginationControl, ResponsiveTable, UiOption, Link } from "packages/ui";
import { Merchant } from "packages/utils/models";
import DeclineApi from "api/declineApi";
import currencyUtil from "packages/utils/currency";
import { ResponsiveTableRecordPartial } from "packages/ui/src/ResponsiveTableTypes";
import * as paymentActions from 'components/Transactions/_actions/paymentActions';
import { CardTypeModel } from "packages/webapi-client";
import DateUtil from "packages/utils/date";
// @ts-ignore
import { defaultPageSize, defaultPageSizeOptions } from "constants/billpay";
import { PlatformRoutesConfiguration } from "components/Routing";
import { Sort } from "models/Sort";

import "./ManageDeclinesTrayPage.scss";

type Props = {
    merchant: Merchant;
    accountTypes: { items: CardTypeModel[] };
    actions: any;
};

type DeclineTxnItem = {
    txnRequestId: number;
    submissionDate: string;
    crn1: string;
    amount?: number;
    maskedCardNumber: string;
    cardType: string;
} & ResponsiveTableRecordPartial;

const ManageDeclinesTrayPage = ({ merchant, accountTypes, actions }: Props) => {
    const { trayId } = useParams();
    const [sort, setSort] = useState<Sort>({ field: 'submissionDate', descending: true });
    const [submissionDate, setSubmissionDate] = useState<Date>();
    const [crn1Prefix, setCrn1Prefix] = useState<string>();
    const [amount, setAmount] = useState<number>();
    const [accountNumberPrefix, setAccountNumberPrefix] = useState<string>();
    const [accountNumberSuffix, setAccountNumberSuffix] = useState<string>();
    const [cardTypeCode, setCardTypeCode] = useState<string>();
    const [formCrn1Prefix, setFormCrn1Prefix] = useState<string>();
    const [formAmount, setFormAmount] = useState<string>();
    const [formAccountNumberPrefix, setFormAccountNumberPrefix] = useState<string>();
    const [formAccountNumberSuffix, setFormAccountNumberSuffix] = useState<string>();
    const [txnGridCurrentPage, setTxnGridCurrentPage] = useState(1);
    const [txnGridItemsPerPage, setTxnGridItemsPerPage] = useState(defaultPageSize);
    const [txnGridTotalCount, setTxnGridTotalCount] = useState(1);

    useEffect(() => {
        actions.getAccountTypes()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const cardTypes = useMemo<UiOption<string>[]>(() => {
        // Empty option so user can reset filter
        const items: UiOption<string>[] = [{
            label: <>&nbsp;</>,
            value: "",
        }];

        accountTypes?.items?.forEach((item) => {
            items.push({
                label: item.code?.toString() ?? "",
                value: item.code?.toString() ?? "",
            });
        });

        return items;
    }, [accountTypes])

    const [tray, trayStatus] = useApiCall(() => {
        if (trayId) {
            return DeclineApi.getDeclineTray(Number(trayId));
        }
    }, [trayId]);

    const trayMerchant = useMemo(() => {
        if (tray?.merchantNumber === merchant.merchantNumber) {
            return merchant;
        }

        return merchant.childMerchants?.find(x => x.merchantNumber === tray?.merchantNumber);
    }, [tray, merchant]);

    const [txns, txnsStatus] = useApiCall(() => {
        return DeclineApi.getDeclineTrayTxns(Number(trayId), txnGridItemsPerPage, txnGridCurrentPage, submissionDate, crn1Prefix, amount, accountNumberPrefix, accountNumberSuffix, cardTypeCode, sort.field, sort.descending);
    }, [trayId, txnGridItemsPerPage, txnGridCurrentPage, submissionDate, crn1Prefix, amount, accountNumberPrefix, accountNumberSuffix, cardTypeCode, sort]);

    const formDebounceFunc = useDebounce((formCrn1Prefix?: string, formAmount?: string, formAccountNumberPrefix?: string, formAccountNumberSuffix?: string, formCardTypeCode?: string) => {
        setCrn1Prefix(formCrn1Prefix);
        setAccountNumberPrefix(formAccountNumberPrefix);
        setAccountNumberSuffix(formAccountNumberSuffix);
        setCardTypeCode(formCardTypeCode);

        if (formAmount !== null && formAmount !== undefined) {
            setAmount(Number(formAmount));
        } else {
            setAmount(undefined);
        }
    });

    useMemo(() => {
        formDebounceFunc(formCrn1Prefix, formAmount, formAccountNumberPrefix, formAccountNumberSuffix);
    }, [formDebounceFunc, formCrn1Prefix, formAmount, formAccountNumberPrefix, formAccountNumberSuffix]);

    // Reset the page to 1 whenever these search filters change
    useMemo(() => {
        setTxnGridCurrentPage(1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submissionDate, crn1Prefix, amount, accountNumberPrefix, accountNumberSuffix, cardTypeCode])

    const txnItems = useMemo<DeclineTxnItem[]>(() => {
        if (!txns || !txns.txns) {
            return [];
        }

        setTxnGridTotalCount(txns.totalCount ?? 0);

        return txns.txns.map(x => ({
            txnRequestId: x.txnRequestId ?? 0,
            submissionDate: x.submissionDate ?? "",
            crn1: x.crN1 ?? "",
            amount: x.amountPaid,
            maskedCardNumber: x.accountNumber ?? "",
            cardType: x.cardTypeCode ?? ""
        }));
    }, [txns]);

    const handleTransactionSort = (sortKey: string, isDescending: boolean) => {
        setSort({ field: sortKey, descending: isDescending });
    };

    return (
        trayStatus === APICallRequestState.LOADING || trayStatus === APICallRequestState.PENDING ?
            <LoadingIndicator /> :
            <>
                <PageSection noDivider>
                    <PageHeader title={tray?.nickname} subtitle={`Merchant ${trayMerchant?.merchantNumber}`} backButton={{ to: PlatformRoutesConfiguration.transactionRoute!.manageDeclines.path }} />
                    <PaddedContainer title="Tray overview" lessMargin noDivider>
                        <p>{tray?.description}</p>
                        <DescriptionList items={[
                            { name: "Tray count", value: tray?.count },
                            { name: "Tray amount", value: tray?.amount ? currencyUtil.convertToDisplayString(tray?.amount) : "" },
                        ]} />
                    </PaddedContainer>
                    <div className="inline-label manage-declines-tray-page-size-drop-down">
                        <FormGroup fieldId='pageSize' label="Items per page">
                            <Dropdown
                                onChange={(option) => setTxnGridItemsPerPage(option ? Number(option?.value) : defaultPageSize)}
                                options={defaultPageSizeOptions.map((result: any) => ({ value: result, label: result }))}
                                defaultValue={defaultPageSize}
                            />
                        </FormGroup>
                    </div>
                    <ResponsiveTable
                        isLoading={txnsStatus === APICallRequestState.LOADING || txnsStatus === APICallRequestState.PENDING}
                        data={txnItems}
                        sort={sort}
                        onSort={handleTransactionSort}
                        columns={[
                            {
                                label: "Submitted",
                                filter: <DatePicker value={submissionDate} onChange={(dates) => setSubmissionDate(dates[0])} />,
                                getter: x => <Link to={PlatformRoutesConfiguration.transactionRoute!.manageDeclinesTxn.generatePath(trayId, x.txnRequestId)}>{DateUtil.formatToDateTimeString(x.submissionDate)}</Link>,
                                sortKey: "submissionDate"
                            },
                            {
                                label: "Reference 1",
                                filter: <Input value={formCrn1Prefix} onChange={(e) => { setFormCrn1Prefix(e.currentTarget.value) }} />,
                                getter: x => x.crn1,
                                sortKey: "crn1"
                            },
                            {
                                label: "Amount",
                                filter: <Input
                                            value={formAmount}
                                            onChange={(e) => setFormAmount(e.currentTarget.value)}
                                            placeholder="0.00"
                                            digitsOnly
                                />,
                                getter: x => x.amount !== null && x.amount !== undefined ? currencyUtil.convertToDisplayString(x.amount) : "",
                                sortKey: "amount"
                            },
                            {
                                label: "Masked card number",
                                filter: <div className="masked-card-number-inputs">
                                    <Input
                                        value={formAccountNumberPrefix}
                                        onChange={(e) => setFormAccountNumberPrefix(e.currentTarget.value)}
                                        placeholder="First 6"
                                        maxLength={6}
                                        digitsOnly
                                    />
                                    <Input
                                        value={formAccountNumberSuffix}
                                        onChange={(e) => setFormAccountNumberSuffix(e.currentTarget.value)}
                                        placeholder="Last 3"
                                        maxLength={3}
                                        digitsOnly
                                    />
                                </div>,
                                getter: x => x.maskedCardNumber,
                                sortKey: "accountNumber",
                                className: "masked-card-number-column"
                            },
                            {
                                label: "Card type",
                                filter: <Dropdown value={cardTypeCode} onChange={(option) => setCardTypeCode(option?.value)} options={cardTypes} />,
                                getter: x => x.cardType,
                                sortKey: "cardTypeCode"
                            },
                        ]}
                    />
                    <PaginationControl
                        currentPage={txnGridCurrentPage}
                        itemCount={txnGridTotalCount}
                        itemsPerPage={txnGridItemsPerPage}
                        onPageChanged={(page) => setTxnGridCurrentPage(page)}
                    />
                </PageSection>
            </>
    );
};

function mapStateToProps(state: RootState) {
    return {
        merchant: state.accounts.users.merchant,
        accountTypes: state.transactions.payments.accountTypes,
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        actions: bindActionCreators(paymentActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageDeclinesTrayPage);
