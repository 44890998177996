//#region ========== Settlement ==========
export const GET_SETTLEMENT_REPORT_REQUESTED = 'GET_SETTLEMENT_REPORT_REQUESTED';
export const GET_SETTLEMENT_REPORT_SUCCESS = 'GET_SETTLEMENT_REPORT_SUCCESS';
export const GET_SETTLEMENT_REPORT_FAILED = 'GET_SETTLEMENT_REPORT_FAILED';

export const GET_SETTLEMENT_EXPORT_TYPES_REQUESTED = 'GET_SETTLEMENT_EXPORT_TYPES_REQUESTED';
export const GET_SETTLEMENT_EXPORT_TYPES_SUCCESS   = 'GET_SETTLEMENT_EXPORT_TYPES_SUCCESS';
export const GET_SETTLEMENT_EXPORT_TYPES_FAILED    = 'GET_SETTLEMENT_EXPORT_TYPES_FAILED';

export const GET_SETTLEMENT_EXPORT_COLUMNS_REQUESTED = 'GET_SETTLEMENT_EXPORT_COLUMNS_REQUESTED';
export const GET_SETTLEMENT_EXPORT_COLUMNS_SUCCESS   = 'GET_SETTLEMENT_EXPORT_COLUMNS_SUCCESS';
export const GET_SETTLEMENT_EXPORT_COLUMNS_FAILED    = 'GET_SETTLEMENT_EXPORT_COLUMNS_FAILED';

export const GET_SETTLEMENT_EXPORT_OPTIONS_REQUESTED = 'GET_SETTLEMENT_EXPORT_OPTIONS_REQUESTED';
export const GET_SETTLEMENT_EXPORT_OPTIONS_SUCCESS   = 'GET_SETTLEMENT_EXPORT_OPTIONS_SUCCESS';
export const GET_SETTLEMENT_EXPORT_OPTIONS_FAILED    = 'GET_SETTLEMENT_EXPORT_OPTIONS_FAILED';

export const DOWNLOAD_SETTLEMENT_EXPORT_REQUESTED = 'DOWNLOAD_SETTLEMENT_EXPORT_REQUESTED';
export const DOWNLOAD_SETTLEMENT_EXPORT_SUCCESS   = 'DOWNLOAD_SETTLEMENT_EXPORT_SUCCESS';
export const DOWNLOAD_SETTLEMENT_EXPORT_FAILED    = 'DOWNLOAD_SETTLEMENT_EXPORT_FAILED';

export const DOWNLOAD_SETTLEMENT_SUMMARY_REQUESTED = 'DOWNLOAD_SETTLEMENT_SUMMARY_REQUESTED';
export const DOWNLOAD_SETTLEMENT_SUMMARY_SUCCESS   = 'DOWNLOAD_SETTLEMENT_SUMMARY_SUCCESS';
export const DOWNLOAD_SETTLEMENT_SUMMARY_FAILED    = 'DOWNLOAD_SETTLEMENT_SUMMARY_FAILED';

//#endregion

//#region ========== Custom Reports ==========
export const GET_DAILY_REPORTS_REQUESTED = 'GET_DAILY_REPORTS_REQUESTED';
export const GET_DAILY_REPORTS_SUCCESS = 'GET_DAILY_REPORTS_SUCCESS';
export const GET_DAILY_REPORTS_FAILED = 'GET_DAILY_REPORTS_FAILED';

export const GET_DAILY_REPORT_REQUESTED = 'GET_DAILY_REPORT_REQUESTED';
export const GET_DAILY_REPORT_SUCCESS = 'GET_DAILY_REPORT_SUCCESS';
export const GET_DAILY_REPORT_FAILED = 'GET_DAILY_REPORT_FAILED';

export const GET_DAILY_REPORT_COUNT_REQUESTED = 'GET_DAILY_REPORT_COUNT_REQUESTED';
export const GET_DAILY_REPORT_COUNT_SUCCESS = 'GET_DAILY_REPORT_COUNT_SUCCESS';
export const GET_DAILY_REPORT_COUNT_FAILED = 'GET_DAILY_REPORT_COUNT_FAILED';//#endregion