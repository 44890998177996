import { Validator } from "../FormTypes"
import { must } from "./validateUtil"

export function haveMinLengthOf<T extends string | undefined>(length: number): Validator<T> {
    return must(r => (r?.length ?? 0) >= length, `{label} must be at least ${length} characters`)
}

export function haveMaxLengthOf<T extends string | undefined>(length: number): Validator<T> {
    return must(r => (r?.length ?? 0) <= length, `{label} must be no more than ${length} characters`)
}

export function beOneOf<T extends string | number | undefined>(...args: T[]): Validator<T> {
    return must(v => args.includes(v), `{label} must be one of ${args.join(', ')}`)
}

export function required(): Validator<string | undefined> {
    return must(v => !!v, `{label} is required`)
}