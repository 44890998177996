import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

import stationeryShopUtil from '@premier/utils/stationeryShop';

import { PageSection, Link } from '@premier/ui';
import { PageHeader } from 'components/Common';
import { ShoppingCartTable } from 'components/StationeryShop';
import withHydrateProductList from 'components/StationeryShop/components/WithHydrateProductList';

import { PlatformRoutesConfiguration } from 'components/Routing';

import './ShoppingCart.scss'

const ShoppingCart = ({
    cart, //state
    freightProduct,
    productsCatalog,
    products //hydrate
}) => {

    var isCartEmpty = stationeryShopUtil.cartIsEmpty(cart);

    const mapProductCatalogToCart = (products, productCatalog) =>
    {
        return products.map(p => {

            var item = productCatalog.products.filter(f => {
                return p.productId === f.productId;
            });

            return {
                ...p,
                maxOrderQuantity : item && item.length > 0 ? item[0].maxOrderQuantity : 99,
                minOrderQuantity : item && item.length > 0 ? item[0].minOrderQuantity : 0
            };

        });
    }

    function displayShoppingCart() {
        if (isCartEmpty) {
            return (
                <>
                    Your cart is empty
                    <br />
                    <br />
                    <Link primary to={PlatformRoutesConfiguration.stationeryRoute.homePage.generatePath()}>
                        Go to shop
                    </Link>
                </>
            );
        }

        return (
            <>
                <ShoppingCartTable cartItems={mapProductCatalogToCart(products, productsCatalog)} freightProduct={freightProduct} />
                <div className='button-wrapper'>
                    <Link primary to={PlatformRoutesConfiguration.stationeryRoute.checkoutAddress.generatePath()}>
                        Check out
                    </Link>
                </div>
            </>
        );
    }

    return (
        <div className="shopping-cart-page">
            <PageSection>
                <PageHeader
                    backButton={!isCartEmpty && {
                        to: PlatformRoutesConfiguration.stationeryRoute.homePage.generatePath(),
                        label: 'Continue shopping'
                    }}
                    title="Shopping cart"
                />
                {displayShoppingCart()}
            </PageSection>
        </div>
    );
};

function mapStateToProps(state, ownProps) {
    return {
        cart: state.stationeryShop.shop.cart,
        freightProduct: state.stationeryShop.shop.freightProduct,
        productsCatalog: state.stationeryShop.shop.stationeryProducts
    };
}

function getSelectedProductLocation(state) {
    return _.get(state, 'stationeryShop.shop.cart');
}

export default withHydrateProductList(
    connect(mapStateToProps, null)(ShoppingCart),
    getSelectedProductLocation
);
