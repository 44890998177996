import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Button,
    ButtonContainer,
    PaddedContainer,
    PageSection,
} from '@premier/ui';
import { PageHeader } from 'components/Common';

import { InvoiceSettingsState } from '../InvoiceSettingsPage';
import { useInvoiceSettings } from 'components/Settings/_hooks/useInvoicesSettings';
import NoBillerCodeErrorPage from 'components/Common/pages/NoBillerCodeErrorPage';
import InvoiceDialog from 'components/Settings/components/InvoiceSettings/InvoiceDialog';
import InvoiceSettingsHeading from '../../components/InvoiceSettings/InvoiceSettingsHeading';
import InvoiceSettingsCustomerNotesForm from '../../components/InvoiceSettings/InvoiceSettingsCustomerNotesForm';
import '../../components/InvoiceSettings/InvoiceSettingsCustomerNotesForm.scss'

export const defaultValues = {
    maxCharacters: 400,
    errorState: false,
    notes: '',
    modalState: '' as InvoiceSettingsState,
};

const InvoiceSettingsCustomerNotesPageCopy = {
    title: 'Settings for customer notes',
    customerNotes: {
        title: 'Default customer notes',
        tooltipId: 'invoice_customer_notes',
        tooltip:
            'The following notes will be displayed on the invoice sent. This will be prefilled with the default customer notes if it has been configured.',
    },
    onSubmitError: 'There are errors above. Please fix them before continuing.',
    SaveButton: 'Save',
    CancelButton: 'Cancel',
};

interface Props {}

const InvoiceSettingsCustomerNotesPage: React.FC<Props> = () => {
    // invoice hook ---
    const { hasLoaded, settings, updateInvoiceSettings } = useInvoiceSettings();

    // states ---
    const [notes, setNotes] = useState<string>(
        settings?.customerNotes || defaultValues.notes
    );
    const [charRemaining, setCharRemaining] = useState<number>(
        defaultValues.maxCharacters
    );

    const [errorMsg, setErrorMsg] = useState<string>(defaultValues.notes);
    const [modalState, setModalState] = useState<InvoiceSettingsState>(
        defaultValues.modalState
    );
    const [onSubmitErr, setOnSubmitErr] = useState<boolean>(false);

    // customer notes ---
    const handleNotesMaxLengthReached = (numChar: number) => {
        setErrorMsg(`${-numChar} characters exceeding limit`);
    };

    const validateNotesLength = useCallback((input: string) => {
        var inputLengthRemaining = defaultValues.maxCharacters - input.length;
        setCharRemaining(inputLengthRemaining);

        // reset error message
        if (inputLengthRemaining >= 0) {
            resetNotesError();
        } else {
            handleNotesMaxLengthReached(inputLengthRemaining);
        }
    }, []);

    const onInputChange = useCallback(
        (value: string) => {
            setNotes(value);
            validateNotesLength(value);
        },
        [validateNotesLength]
    );

    const resetNotesError = () => {
        setErrorMsg(defaultValues.notes);
        setOnSubmitErr(false);
    };

    useEffect(() => {
        if (settings?.customerNotes) {
            onInputChange(settings.customerNotes);
        }
    }, [settings, onInputChange]);

    // modal ---
    const navigate = useNavigate();

    const handleExit = () => {
        navigate(-1);
    };

    const handleSave = () => {
        if (errorMsg) {
            setOnSubmitErr(true);
            return;
        }
        // submit
        let newSettings = {
            ...settings,
            customerNotes: notes
        }
        // newSettings.customerNotes = notes;

        updateInvoiceSettings(newSettings);
        setModalState('SUCCESS');
    };

    const handleBack = () => {
        // no changes made
        if (notes === (settings?.customerNotes || defaultValues.notes)) {
            handleExit();
            return;
        }
        // unsaved changes
        setModalState('UNSAVED');
    };

    // no billers linked ---
    if (!settings?.hasInvoiceBiller && hasLoaded) {
        return <NoBillerCodeErrorPage />;
    }

    return (
        <PageSection>
            <PageHeader
                title={InvoiceSettingsCustomerNotesPageCopy.title}
                backButton={{...{ onClick: handleBack }}}
            />
            <PaddedContainer noDivider lessMargin>
                <InvoiceSettingsHeading
                    title={
                        InvoiceSettingsCustomerNotesPageCopy.customerNotes.title
                    }
                    tooltip={
                        InvoiceSettingsCustomerNotesPageCopy.customerNotes
                            .tooltip
                    }
                    tooltipId={
                        InvoiceSettingsCustomerNotesPageCopy.customerNotes
                            .tooltipId
                    }
                />

                <InvoiceSettingsCustomerNotesForm
                    value={notes}
                    numChar={charRemaining}
                    errorMsg={errorMsg}
                    onChange={onInputChange}
                />
            </PaddedContainer>

            <InvoiceDialog
                modalState={modalState}
                pageName={'customer notes'}
                onClose={() => setModalState(defaultValues.modalState)}
                onExit={handleExit}
            />

            <ButtonContainer>
                <Button primary onClick={handleSave}>
                    {InvoiceSettingsCustomerNotesPageCopy.SaveButton}
                </Button>
                <Button onClick={handleBack}>
                    {InvoiceSettingsCustomerNotesPageCopy.CancelButton}
                </Button>
            </ButtonContainer>

            {onSubmitErr && (
                <p className='invoices-error-message'>
                    {InvoiceSettingsCustomerNotesPageCopy.onSubmitError}
                </p>
            )}
        </PageSection>
    );
};

export default InvoiceSettingsCustomerNotesPage;
