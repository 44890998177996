import React, { useState, useRef, ChangeEvent, FocusEvent } from 'react';
import classNames from 'classnames';
import { ColorResult, SketchPicker } from 'react-color';
import { getHexColour } from '@premier/utils/html';
import Input from './Input';
import defaultBackgroundImage from 'images/no-color.svg';
import './ColourInput.scss';

export type ColourInputProps = {
    id?: string,
    className?: string,
    placeholder?: string,
    value?: string,
    error?: string | boolean,
    onChange?: (colour: string) => void,
    onFocus?: () => void,
    onBlur?: () => void,
};

/** A colour input textbox with a popup colour picker on focus */
const ColourInput = ({
    value, error, id, className,
    onFocus, onChange, onBlur,
    ...otherProps
}: ColourInputProps) => {

    const DEFAULT_BACKGROUND = `url('${defaultBackgroundImage}') center center/contain no-repeat`;

    const [focused, setFocused] = useState(false);
    const [background, setBackground] = useState(value);

    const containerRef = useRef<HTMLDivElement | null>(null);
    const inputRef = useRef<HTMLInputElement | null>(null);


    function updateColour(colour: string, disableDefault?: boolean) {
        if(!disableDefault) {
            // Show defaultBackgroundImage if invalid colour
            setBackground(DEFAULT_BACKGROUND);
        }

        setTimeout(() => setBackground(colour), 0);

        if(onChange)
            onChange(colour);
    }

    function handleChange(e: ChangeEvent<HTMLInputElement>) {
        updateColour(e.currentTarget.value);
    }

    function handleColourPick(value: ColorResult) {
        updateColour(value.hex, true);
    }

    function handleFocus() {
        setFocused(true);

        if(onFocus)
            onFocus();
    }

    function handleBlur(e: FocusEvent<HTMLDivElement>) {
        closePickerIfLeaving();

        if(onChange && e.target === inputRef.current && e.target instanceof HTMLInputElement)
            onChange(getHexColour(e.target.value) || e.target.value);

        if (onBlur)
            onBlur();
    }

    /** Instead of closing it immediately on blur, this makes sure we don't close the Picker if we're only
      * jumping from the Input to the Picker (ie. still on this same component, but it might trigger a blur in between).
      */
    function closePickerIfLeaving() {
        let container = containerRef.current;

        setTimeout(() => {
            // Close the Picker by setting focused to false only if the focus is outside this ColourInput container
            if (document.activeElement !== container && !container?.contains(document.activeElement)) {
                setFocused(false);
            }
        }, 0);
    }


    return (
        <div
            ref={containerRef}
            className={classNames('input-group colour', {
                'focused': focused,
                'has-error': !!error
            }, className)}
            tabIndex={0}  // enables focus/blur for the color picker
            onFocus={handleFocus}
            onBlur={handleBlur}
        >
            <div className='input-group-prepend'>
                <span className='input-group-text' style={{background: background || DEFAULT_BACKGROUND}}></span>
            </div>

            <Input
                ref={inputRef}
                id={id}
                value={value}
                onChange={handleChange}
                {...otherProps}
            />

            <div className='color-picker' style={{display: focused ? 'block' : 'none'}}>
                <SketchPicker disableAlpha
                    color={value || undefined}
                    presetColors={[]}
                    onChange={handleColourPick}
                    onChangeComplete={handleColourPick}
                />
            </div>
        </div>
    );
};

export default ColourInput;
