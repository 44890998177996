import { useState } from "react";
import { Dispatch, bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as billerActions from "components/Settings/_actions/billerActions";
import { PaddedContainer, Switch } from "packages/ui";
import { billerApi } from "api";
import { RootState } from "store/store";
import { Biller } from "packages/webapi-client";
import { ProductTooltip } from "components/Common";

type Props = {
    billerCode: string;
    biller?: Biller;
    billerActions?: any;
}

const PayLaterSection = ({ billerCode, biller, billerActions }: Props) => {
    const [deferredPaymentsEnabled, setDeferredPaymentsEnabled] = useState(biller?.deferredPayment ?? false);

    const toggleDeferredPayments = async (value: any) => {
        // Set switch
        setDeferredPaymentsEnabled(value);

        // Toggle in back-end
        await billerApi.updateIsDeferredPaymentsEnabled(billerCode, value);

        // Reload billers
        await billerActions.loadBillers();
    };

    return <PaddedContainer
            title={<>Pay later<ProductTooltip productTooltipModule={"PAY_LATER"} /></>}
            button={<Switch onChange={toggleDeferredPayments} on={deferredPaymentsEnabled} noBottomMargin />}
            lessMargin
            noDivider>
    </PaddedContainer>;
};

function mapStateToProps(state: RootState, ownProps: any) {
    const biller = state.accounts.users.billers && state.accounts.users.billers.find((biller: Biller) => biller.billerCode === ownProps.billerCode);

    return {
        biller
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        billerActions: bindActionCreators(billerActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PayLaterSection);
