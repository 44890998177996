import React, { ReactNode } from 'react';
import classNames from 'classnames';
import './PaddedContainer.scss';

type Props = {
    blueBorder?: boolean,
    greyBorder?: boolean,

    icon?: ReactNode,
    title?: ReactNode,
    titleSuffix?: ReactNode,
    button?: ReactNode,
    /** Adding a title automatically adds a divider below the header. This disables it. */
    noDivider?: boolean,
    /** This prop will move the Switch inside this component to the top right on SM+ */
    switchOnRight?: boolean,

    /** Do not apply the "padded container" style (eg. on certain condition) */
    disable?: boolean,

    /** Lessens padding for the bottom of the container. So far only used on scrolling table */
    lessPadding?: boolean,

    /** Lessens the margin from the bottom of the padded Container */
    lessMargin?: boolean,

    className?: string,
    children?: ReactNode,
}

/** A div with white padding around it */
const PaddedContainer = ({
    children, //data
    disable, lessPadding, lessMargin, blueBorder, greyBorder, className, // classNames
    icon, title, titleSuffix, button, noDivider, switchOnRight, // header
    ...otherProps  // div props
}: Props) => {
    return (
        <div className={classNames('padded-container', {
                'no-padding': disable,
                'less-padding': lessPadding,
                'less-margin': lessMargin,
                'blue-border': blueBorder,
                'grey-border': greyBorder,
                'switch-on-right': switchOnRight,
            }, className)}

            {...otherProps}
        >
            {title && (
                <div className={classNames('header', { 'no-divider': noDivider })}>
                    <div className='title-wrapper'>
                        {icon}
                        <h2>{title}</h2>
                        {titleSuffix && (
                            <div className='title-suffix'>{titleSuffix}</div>
                        )}
                    </div>
                    <div className='button-wrapper'>
                        {button}
                    </div>
                </div>
            )}

            <div className='content'>
                {children}
            </div>
        </div>
    );
};

export default PaddedContainer;
