import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { PageHeader } from 'components/Common';
import * as billpayUtil from '@premier/utils/billpay';
import currencyUtil from '@premier/utils/currency';
import { Link, LoadingIndicator, PageSection, PaginationControl } from '@premier/ui';
import dateUtil from '@premier/utils/date';
import { Form } from '@premier/form';
import _ from 'lodash';

// @ts-ignore
import { defaultPageSize } from 'constants/billpay';
import { PlatformRoutesConfiguration } from 'components/Routing';
import { getCustomer } from '../_actions/customerActions';
import NoBillerCodeErrorPage from 'components/Common/pages/NoBillerCodeErrorPage';
import CustomerSummarySubtitle from '../components/CustomerSummarySubtitle';
import InvoiceRecordsList from '../components/InvoiceRecordsList';
import useCustomerInvoice from '../_hooks/useCustomerInvoice';
import { useInvoiceSettings } from 'components/Settings/_hooks/useInvoicesSettings';
import {
    PaginateRequestInvoiceSearchInputModelInvoiceSearchSortFieldsSortFieldEnum as SortFieldEnum,
    KeyDescriptionBaseInvoiceStatusFilter,
} from 'packages/webapi-client';

import './ManageCustomerInvoicesPage.scss';

export interface ValueLabel {
    label: string;
    value: string;
    disabled?: boolean;
}

export const manageCustomerInvoicesPageCopy = {
    emptyPageTitle: 'Customer not found or an error occurred.',
    newInvoiceLink: 'New invoice',
    invoiceTableTitle: 'Invoice records',
    noInvoiceMsg: 'No invoices to display',
};

const defaultValues = {
    pageIndex: 0,
    totalCount: 0,
    filters: {},
    InvoiceLists: [],
    InvoiceStatusSource: [],
    sort: { field: 'InvoiceNumber' as SortFieldEnum, descending: true },
};

interface Props {}

const ManageCustomerInvoicesPage: React.FC<Props> = () => {
    const dispatch = useDispatch();
    const { customerId } = useParams();
    const { settings, isLoading, hasFailed, changeFilters, invoiceStatusSource, errors } = useCustomerInvoice();
    const { settings: invoiceSettings } = useInvoiceSettings();
    const { customer } = useSelector((state: any) => ({
        customer:
            _.toString(_.get(state, 'dataVault.customer.details.customerId')) === customerId
                ? state.dataVault.customer.details
                : {},
    }));

    // states ---
    const [currentPageIndex, setCurrentPageIndex] = useState<number>(defaultValues.pageIndex);
    const [filters, setFilters] = useState<any>(defaultValues.filters);
    const [sort, setSort] = useState<{
        field: SortFieldEnum;
        descending: boolean;
    }>(defaultValues.sort);

    useEffect(() => {
        if (_.isEmpty(customer)) dispatch(getCustomer(customerId));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerId, dispatch]);

    useEffect(() => {
        var request = {
            ...filters,
            status: filters['status'] && {
                key: filters['status'],
            },
            invoiceDate: filters['invoiceDate'] && dateUtil.convertDateToApiDate(filters['invoiceDate'][0]),
            amount: filters['amount'] && currencyUtil.convertToApiValue(filters['amount']),
        };

        changeFilters(currentPageIndex, sort.field, sort.descending ? 'DESCENDING' : 'ASCENDING', request);
    }, [changeFilters, currentPageIndex, filters, sort]);

    // helper func ---
    const convertStatusSourceFormat = (list: KeyDescriptionBaseInvoiceStatusFilter[]): ValueLabel[] => {
        if (list) {
            var returnedFilters: ValueLabel[] = list.map((element: KeyDescriptionBaseInvoiceStatusFilter) => {
                return Object.assign({
                    label: element.description,
                    value: element.key,
                });
            });
            return returnedFilters;
        }
        return [];
    };

    // no biller code
    if (invoiceSettings && !invoiceSettings.hasInvoiceBiller) return <NoBillerCodeErrorPage />;

    // no customer page ---
    if (_.isEmpty(customer))
        return (
            <PageSection>
                <PageSection>
                    <PageHeader backButton title={manageCustomerInvoicesPageCopy.emptyPageTitle} />
                </PageSection>
            </PageSection>
        );

    return (
        <PageSection>
            <PageSection noDivider>
                <PageHeader
                    backButton
                    title={billpayUtil.getCustomerFullName(customer)}
                    subtitle={<CustomerSummarySubtitle customer={customer} />}
                >
                    <Link button primary to={PlatformRoutesConfiguration.customerRoute?.newCustomerInvoice.generatePath(customer.customerId)}>
                        {manageCustomerInvoicesPageCopy.newInvoiceLink}
                    </Link>
                </PageHeader>

                <h2>{manageCustomerInvoicesPageCopy.invoiceTableTitle}</h2>
                {hasFailed && <p className='manage-invoice-error-message'>{errors[0].message}</p>}

                {isLoading && !invoiceStatusSource?.items ? (
                    <LoadingIndicator />
                ) : (
                    <Form>
                        <InvoiceRecordsList
                            invoiceList={settings?.list ? settings.list : defaultValues.InvoiceLists}
                            statusOptions={convertStatusSourceFormat(invoiceStatusSource?.items)}
                            isLoading={isLoading}
                            noDataText={manageCustomerInvoicesPageCopy.noInvoiceMsg}
                            onFilterChange={(values: any) => setFilters(values)}
                            sort={sort}
                            onSort={(field, descending) =>
                                setSort({
                                    field: field as SortFieldEnum,
                                    descending,
                                })
                            }
                        />
                        <PaginationControl
                            currentPage={currentPageIndex + 1}
                            itemsPerPage={defaultPageSize}
                            itemCount={settings?.totalCount ?? defaultValues.totalCount}
                            onPageChanged={(page: number) => setCurrentPageIndex(page - 1)}
                        />
                    </Form>
                )}
            </PageSection>
        </PageSection>
    );
};

export default ManageCustomerInvoicesPage;
