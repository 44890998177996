import React from 'react';
import PropTypes from 'prop-types';
import { DescriptionList } from '@premier/ui';
import dateUtil from '@premier/utils/date';

import labels from 'constants/labels';

/** A summary of the Customer details as a DescriptionList used as the header subtitle in Customer pages */
const CustomerSummarySubtitle =({
    customer
}) => {
    const items = [
        {name: labels.customerCode + ':', value: customer.uniqueId},
        {name: labels.createdTime + ':', value: dateUtil.convertToDateTimeString(customer.created)},
        {name: labels.updatedTime + ':', value: dateUtil.convertToDateTimeString(customer.lastUpdated)}
    ];

    return <DescriptionList greyLabel items={items} />
};

CustomerSummarySubtitle.propTypes = {
    customer: PropTypes.shape({
        uniqueId: PropTypes.string,
        created: PropTypes.string,
        lastUpdated: PropTypes.string,
    })
};

export default CustomerSummarySubtitle;