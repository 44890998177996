/* eslint eqeqeq: "off" */
import * as actions from '../_actions/actionTypes';
import _ from 'lodash';

export default function noticeReducer(state = {}, action) {
    switch(action.type) {
        case actions.LOAD_NOTICES_SUCCESS:
            return Object.assign({}, state, {
                notices: action.notices,
                lastUpdated: action.lastUpdated
            });

        case actions.LOAD_NOTICE_SUCCESS: {
            let idx = _.findIndex(state.notices, n => n.id == action.notice.id);
            let notices = (state.notices && ~idx)
                            ? state.notices.map(n => n.id == action.notice.id ? action.notice : n)
                            : [ action.notice ];

            return Object.assign({}, state, {
                notices: notices
            });
        }

        default:
            return state;
    }
}