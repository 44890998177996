import errorMaps from 'platforms/current/constants/errorMaps';
import { LabelledApiError, ParamLabel, CustomErrorMessages } from './models';
import { camelCase } from 'lodash';
import { ApiError } from 'packages/webapi-client';
import { FormikErrors } from 'formik';

export class ErrorUtil {

    /** Gets a user-friendly error message for one of the errors returned by the backend.
     * @param {object} error One of the errors returned by the backend
     * @param {object} paramLabels eg. {crn1: 'Reference 1', lastname: 'Last name'}
     * @param {object} customMessages Custom message for some error.code, eg. {ERRCODE01: 'Please enter a valid password'}
     */
    getMessage(error: LabelledApiError, paramLabels?: ParamLabel, customMessages?: CustomErrorMessages) {

        if(!error.cachedMessage) {
            var paramLabel = (paramLabels && error.parameter) ? (paramLabels[error.parameter] || error.parameter) : error.parameter;
            if (typeof paramLabel === 'function') {
                paramLabel = paramLabel();
            }

            var errorWithLabel = {...error, label: paramLabel || 'Unknown'};

            if (customMessages) {
                var customMessage = error.code && customMessages[error.code];
                if (customMessage) {
                    if (typeof customMessage === 'function') {
                        customMessage = customMessage(errorWithLabel);
                    }

                    if (customMessage)
                        error.cachedMessage = customMessage;
                }
            }

            if(!error.cachedMessage) {
                var msgFunc = error.code && errorMaps[error.code];
                error.cachedMessage =  msgFunc ? msgFunc(errorWithLabel) : error.message;
            }
        }

        return error.cachedMessage;
    }

    convertJsonBracketNotationToObject(input: any) {
        const output = {};

        Object.keys(input).forEach(k => {
            const path = k.replace(/\[/g, '.').replace(/\]/g, '').split('.'),
                last: any = path.pop();

            if (path.length) {
                path.reduce((o: any, p: any) => o[p] = o[p] || {}, output)[last] = input[k];
            }
        });

        return output;
    }

    cameliseKeys(obj: any): any {
        if (Array.isArray(obj)) {
            return obj.map(v => this.cameliseKeys(v));
        } else if (obj != null && obj.constructor === Object) {
            return Object.keys(obj).reduce(
                (result, key) => ({
                    ...result,
                    [camelCase(key)]: this.cameliseKeys(obj[key]),
                }),
                {},
            );
        }
        return obj;
    };

    /** Given an array of ApiError objects this will return an object that is consumable by formik.setErrors().
     * @param {object} apiErrors Array of the errors returned by the backend.
     * @returns {object} Object consumable by Formik. T is the type used by Formik to represent the shape of the form.
     */
    convertApiErrorsToFormikErrors<T>(apiErrors: ApiError[]): FormikErrors<T> {
        const input: any = {};

        apiErrors.forEach((x) => { input[x.label ?? ""] = x.message; });

        return this.cameliseKeys(input);
    }
};

const errorUtil = new ErrorUtil();
export default errorUtil;