import { Validator } from '../FormTypes';

export function must<T>(
    condition: (value: T) => boolean | undefined,
    message: string | ((value: T) => string)
): Validator<T> {
    return (value: T) => !condition(value)
        ? typeof message === "string"
            ? message
            : message(value)
        : undefined;
}

export function combine<T>(
    ...validators: (Validator<T> | false | undefined)[]
): Validator<T> {
    return (value) => {
        for (const validator of validators) {
            if (!validator)
                continue;
            const message = validator(value);
            if (message)
                return message;
        }
        return undefined;
    }
}

export function when<T>(
    condition: (value: T) => boolean | undefined,
    ...validators: (Validator<T> | false | undefined)[]
): Validator<T> {
    return (value) => {
        if (!condition(value))
            return undefined;
        return combine(...validators)(value);
    }
}

export function notNull(): Validator<unknown> {
    return must(v => v != null, `{label} is required`)
}