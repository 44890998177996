import { useCallback, useEffect, useReducer, useRef } from "react"

import { RegisterOptions, FormAccess, FormError, FormOptions, FormState, FieldsType, ValidateSettings, ShouldValidateMap, FormAction, ErrorMap, ExternalError } from './FormTypes';
import { validateValues, asError, removeFalsyKeys, addExternalErrors } from './util';
import { useFieldBinding } from './useFieldBinding';

export function useForm<T>({
    init,
    validation,
    onSubmit
}: FormOptions<T>): FormAccess<T> {

    // fields is a ref to avoid form rerenders as components register
    // be careful when accessing fields
    const fields = useRef<FieldsType<T>>({});

    const [{ values, error, externalErrors, shouldSubmit }, dispatch] = useReducer((state: FormState<T>, action: FormAction<T>): FormState<T> => {
        if (!action)
            return state;
        if (action.type === "SET_VALUE") {
            let values = action.mutate(state.values);
            return { ...state, values, shouldSubmit: false };
        } else if (action.type === "VALIDATE") {
            let error = validateValues(state.values, state.error, action.validate, fields.current, validation);
            return { ...state, error, shouldSubmit: false };
        } else if (action.type === "SET_ERROR") {
            let error = action.mutate(state.error);
            return { ...state, error, shouldSubmit: false };
        } else if (action.type === "RESET") {
            return { values: init(), shouldSubmit: false };
        } else if (action.type === "ADD_ERRORS") {
            let [error, externalErrors] = addExternalErrors(state.error, action.errors, fields.current);
            return { ...state, error, externalErrors, shouldSubmit: false };
        } else if (action.type === "SUBMIT") {
            let error = validateValues(state.values, state.error, "all", fields.current, validation);
            return { ...state, error, shouldSubmit: error == null, externalErrors: undefined };
        } else if (action.type === "CLEAR_SUBMIT") {
            return { ...state, shouldSubmit: false };
        }
        return state;
    }, undefined, (): FormState<T> => ({ values: init(), shouldSubmit: false }))


    const onSubmitRef = useRef<typeof onSubmit>();
    onSubmitRef.current = onSubmit;

    useEffect(() => {
        if (shouldSubmit && onSubmitRef.current) {
            onSubmitRef.current(values);
        }
        dispatch({ type: "CLEAR_SUBMIT" });
    }, [shouldSubmit, values]);

    const validateForm = useCallback(() => {
        dispatch({ type: "VALIDATE", validate: "all" });
    }, []);

    const submit = useCallback(() => {
        dispatch({ type: "SUBMIT" });
    }, []);

    const setFieldValue = useCallback(<K extends keyof T>(key: K, mutate: (value: T[K]) => T[K]) => {
        dispatch({ type: "SET_VALUE", mutate: (prev) => ({
            ...prev,
            [key]: mutate(prev[key])
        })});
    }, []);

    const setFieldError = useCallback(<K extends keyof T>(key: K, mutate: (error: FormError<T[K]>) => FormError<T[K]>) => {
        dispatch({ type: "SET_ERROR", mutate: (prev) => {
            let details: ErrorMap<T> = {...prev?.details};
            details[key] = mutate(prev?.details?.[key]);
            removeFalsyKeys(details);
            return asError(prev?.message, details);
        }});
    }, []);

    const validateField = useCallback(<K extends keyof T>(key: K, validate: ValidateSettings<T[K]> = "all") => {
        const validateOuter: ShouldValidateMap<T> = {};
        validateOuter[key] = validate;
        dispatch({ type: "VALIDATE", validate: validateOuter });
    }, []);


    const registerField = useCallback(<K extends keyof T>(key: K, options: RegisterOptions<T[K]>) => {
        fields.current[key] = options;
        return () => {
            delete fields.current[key];
        }
    }, []);

    const reset = useCallback(() => {
        dispatch({ type: "RESET" });
    }, []);

    const addErrors = useCallback((errors: ExternalError[]) => {
        dispatch({ type: "ADD_ERRORS", errors });
    }, []);

    const field = useFieldBinding(
        setFieldValue,
        validateField,
        registerField,
        values,
        error,
    );

    return {
        value: values,
        setFieldValue,
        validateField,
        setFieldError,
        validate: validateForm,
        field: field,
        reset,
        externalErrors,
        addErrors,
        submit
    }
}