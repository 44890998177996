import {combineReducers} from 'redux';
import app from './appReducer';
import accounts from '../components/Account/_reducers/accountsReducer';
import transactions from '../components/Transactions/_reducers/transactionsReducers';
import shop from '../components/StationeryShop/_reducers/shopReducers';
import report from '../components/Reports/_reducers/reportReducers';
import dataVault from '../components/DataVault/_reducers/dataVaultReducers';
import settings from '../components/Settings/_reducers/settingsReducers';
import feedback from '../components/Feedback/_reducers/feedbackReducer';
import paymentRequest from '../components/PaymentRequests/_reducers/paymentRequestReducer';
import batchPaymentRequest from '../components/PaymentRequests/_reducers/batchPaymentRequestReducer';

export default () => combineReducers({
    app,
    accounts,
    dataVault,
    reports: report,
    stationeryShop: shop,
    transactions,
    settings,
    feedback,
    paymentRequest,
    batchPaymentRequest
});
