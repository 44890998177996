import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { IconText } from '@premier/ui';
import { deeplinkingApi } from 'api';
import { useApiCallDeprecated, APICallRequestState } from 'components/Common';
import { GetBackofficeUrlResponse } from '@premier/webapi-client';

import './BackofficeDeeplink.scss';

interface Props {
    apiData?: GetBackofficeUrlResponse;
    preLinkMessage: string;
    path: string;
    linkText?: string;
    periodAfterLink: boolean;
    postLinkMessage?: string;
    billerCode?: number;

    className?: string;
    topMargin?: boolean;
}

const BackofficeDeeplink: React.FC<Props> = ({
    preLinkMessage,
    path,
    linkText = 'here',
    periodAfterLink,
    postLinkMessage = 'You may be asked to sign in again.',
    billerCode,

    className,
    topMargin,
    ...otherProps
}) => {
    const [link, setLink] = useState<string>("");

    const [apiData, apiDataStatus] = useApiCallDeprecated(() => {
        if (billerCode) {
            return deeplinkingApi.getBackofficeUrl({ billerCode: billerCode });
        } else {
            return deeplinkingApi.getBackofficeUrl({ billerCode: undefined });
        }
    }, [billerCode])

    useEffect(() => {
        if (apiDataStatus === APICallRequestState.SUCCESSFUL) {
            // If billercode is present, use the editBillerUrl from the api, otherwise use the base url with the path
            setLink(!!billerCode ? apiData.editBillerUrl : `${apiData.backofficeBaseUrl}/${path}`)
        }
    }, [apiData, apiDataStatus, path, billerCode]);

    return (
        <div
            className={classNames(
                'backoffice-deeplink',
                {
                    'top-margin': topMargin,
                },
                className
            )}
            {...otherProps}
        >
            <IconText info>
                {preLinkMessage}
                {preLinkMessage && ' '}
                <a target='_blank' rel='noopener noreferrer' href={link}>
                    {linkText}
                </a>
                {periodAfterLink && '.'}
                {postLinkMessage && ' '}
                {postLinkMessage}
            </IconText>
        </div>
    );
};

export default BackofficeDeeplink;
