import * as actionTypes from './actionTypes';

export function clearErrorsRequested() {
    return {type: actionTypes.CLEAR_ERRORS};
}

export function clearErrors() {
    return function(dispatch) {
        dispatch(clearErrorsRequested());
    }
}

export function blockUi(text) {
    return function(dispatch) {
        dispatch({type: actionTypes.BLOCK_UI, text});
    }
}

export function unblockUi() {
    return function(dispatch) {
        dispatch({type: actionTypes.UNBLOCK_UI});
    }
}

export function storeCurrentPathAndDeducePreviousPath(currentPath) {
    return {type: actionTypes.PATH_CHANGE, currentPath};
}

export function clearSensitiveData() {
    return { type: actionTypes.CLEAR_SENSITIVE_DATA };
}