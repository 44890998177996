import { combineReducers } from 'redux';
import reduceReducers from 'reduce-reducers';
import asyncReducer from 'store/asyncReducer';
import userReducer from './userReducer';

import * as actions from '../_actions/actionTypes';
import * as billerActions from '../_actions/billerActionTypes';
import { requestActionReducer, RequestActionState } from 'components/Common/requestActions';

const settingsReducers = combineReducers({
    user: reduceReducers(userReducer, asyncReducer),
    paymentSettings: combineReducers({
        merchantPaymentMethods: requestActionReducer(actions.GET_PAYMENT_METHODS),
        klarnaConfig: requestActionReducer(actions.GET_KLARNA_CONFIG),
        klarnaConfigSubmission: requestActionReducer(actions.PUT_KLARNA_CONFIG),
        newBillerSubmission: requestActionReducer(billerActions.ADD_BILLER),
        updateBiller: requestActionReducer(billerActions.UPDATE_BILLER),
        updateBillerDetails: requestActionReducer(billerActions.UPDATE_BILLER_DETAILS),
        billerWallets: requestActionReducer(billerActions.GET_BILLER_WALLETS),
        billerWalletsSubmission: requestActionReducer(billerActions.PUT_BILLER_WALLETS),
        surcharges: requestActionReducer(billerActions.GET_SURCHARGE_RULES),
        surchargesSubmission: requestActionReducer(billerActions.PUT_SURCHARGE_RULES),
        acceptedAmountSubmission: requestActionReducer(billerActions.PUT_BILLER_PAYMENT_LIMITS),
    }),
    designer: combineReducers({
        branding: requestActionReducer(actions.GET_BRANDING),
        brandingSubmission: requestActionReducer(actions.SAVE_BRANDING),
        savePublishSubmission: requestActionReducer(actions.SAVE_AND_PUBLISH_BRANDING),
        discardDraftSubmission: requestActionReducer(actions.DISCARD_BRANDING_DRAFT),
        setByBillerSubmission: requestActionReducer(actions.SET_BRANDING_BY_BILLER),
    }),
    fraud: combineReducers({
        basicSettings: requestActionReducer(actions.GET_FRAUD_BASIC_SETTINGS, undefined, {
            [actions.UPDATE_FRAUD_BASIC_SETTINGS.SUCCESS]: (_: unknown, action: any) => new RequestActionState("SUCCESS", action.settings)
        }),
        updateBasicSettings: requestActionReducer(actions.UPDATE_FRAUD_BASIC_SETTINGS),
        advancedSettings: requestActionReducer(actions.GET_FRAUD_ADVANCED_SETTINGS),
        advancedFilterSources: combineReducers({
            actionSources: requestActionReducer(actions.GET_FRAUD_ACTION_SOURCE),
            typeSources: requestActionReducer(actions.GET_FRAUD_TYPE_SOURCE),
        })
    }),
    invoice: requestActionReducer(actions.GET_INVOICES_CONFIG, undefined, {
        [actions.UPDATE_INVOICES_CONFIG.SUCCESS]: (_: unknown, action: any) => new RequestActionState("SUCCESS", action.settings)
    }),
    messagingSettings: requestActionReducer(actions.GET_MESSAGING_SETTINGS, undefined, {
        [actions.UPDATE_MESSAGING_SETTINGS.SUCCESS]: (_: unknown, action: any) => new RequestActionState("SUCCESS", action.settings)
    }),
    updateMessagingSettings: requestActionReducer(actions.UPDATE_MESSAGING_SETTINGS),
});

export default settingsReducers;