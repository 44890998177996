import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';
import $ from 'jquery';

import './PreviewContainer.scss';


const PreviewContainer = ({mobile, children, refreshTrigger}) => {

    const MAX_SCALE = 0.825;
    const [id] = useState(_.uniqueId('preview-container-'));
    const [debouncedResizePreview] = useState(() => _.debounce(resizePreview, 100));

    useEffect(() => {
        window.addEventListener('resize', debouncedResizePreview);

        return () => {
            window.removeEventListener('resize', debouncedResizePreview);
        };
    }, []);

    useEffect(() => {
        resizePreview();
    }, [refreshTrigger]);  // Alternative way: https://www.npmjs.com/package/use-resize-observer

    function resizePreview() {
        let container = '#'+id;
        let content = container + ' > div';

        if(!$(content).length) {
            console.error("Element not found: " + content);
            return;
        }
        if(!$(container).is(':visible')) {
            return;
        }


        $(container).css('max-width', 'none');  // Edge/Firefox fix
        /*$(content).css('display', 'none');  // Edge/Firefox fix */

        var containerW = $(container).width();
        var scale = Math.min(MAX_SCALE, containerW / (mobile ? 400 : 1280));

        $(content).css('display', 'block');  // Edge/Firefox fix

        let contentH = $(content).height();
        let contentW = $(content).width();

        $(container).height(contentH * scale);
        $(container).css('max-width', contentW * scale);  // Edge/Firefox fix
        $(content)
            .css('transform', `scale(${scale})`)
            .css('margin-left', scale === MAX_SCALE ? (containerW - contentW * scale) / 2 : 'inherit')  // horizontally center
            .css('border-width', `${1/scale}px`);  // keep border width to 1px
    }


    return (
        <div id={id} className={classNames('preview-container', {'mobile': mobile})} aria-live='off'>
            <div>
                {children}
            </div>
        </div>
    );
};

PreviewContainer.propTypes = {
    /** A mobile view instead of the default desktop view */
    mobile: PropTypes.bool,
    /** Any change to this prop will redraw the preview. Useful when the preview is initially hidden and hence its size cannot be calculated. */
    refreshTrigger: PropTypes.any,
};

export default PreviewContainer;