import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import classNames from 'classnames';

import errorUtil from '@premier/utils/error';
import * as billpayUtil from '@premier/utils/billpay';
import dateUtil from '@premier/utils/date';

import { PageSection, BackToTop, PaginationControl, IconText, Button, ButtonContainer, ErrorText, DescriptionList } from '@premier/ui';
import { Form } from '@premier/form';
import { FindLinkCustomerList, CustomerDetails } from 'components/DataVault';
import { withError, PageHeader, FormError } from 'components/Common';

import * as commonActions from 'components/Common/_actions/actions';
import * as customerActions from 'components/DataVault/_actions/customerActions';
import * as tokenActions from 'components/DataVault/_actions/tokenActions';

import { PlatformRoutesConfiguration } from 'components/Routing';
import labels from 'constants/labels';

import './LinkTokenCustomerPage.scss'

const LinkTokenCustomerPage = ({
    customerActions, commonActions, tokenActions, //API actions
    tokenId, searchResult, savedState, token, isLoading, //state values
    errors //form
}) => {

    const [filter, setFilter] = useState(null);  // A live copy of the form values
    const [sort, setSort] = useState({field: 'LastUpdated', descending: true});
    const [currentPage, setCurrentPage] = useState(1);
    const [customer, setCustomer] = useState(null);
    const [redirect, setRedirect] = useState(false);
    const [linkErrors, setLinkErrors] = useState(false);

    const resultsPerPage = 10;

    useEffect(() => {
        if (!token) {
            tokenActions.getToken(tokenId);
        }
    }, [tokenId]);

    useEffect(() => {
        handleFilterChange(_.get(savedState, 'filter') || defaultFilter);
    }, [customerActions]);

    useEffect(() => {
        if(currentPage && filter && sort && token) {
            customerActions.getCustomers(resultsPerPage, currentPage, {...filter, childMerchantNumber: _.get(token, "childMerchantNumber")}, sort);
        }
    }, [currentPage, filter, sort, token]);

    function showConfirmationModal(customer) {
        setCustomer(customer);
    }

    function confirmLinkCustomerToToken() {
        setLinkErrors(null);
        customerActions.linkToken(customer.customerId, [tokenId])
            .then(() => setRedirect(true))
            .catch((errors) => {
                setLinkErrors(errors);
            });
    }

    function cancelLinkCustomerToToken() {
        setLinkErrors(null);
        setCustomer(null);
        commonActions.clearErrors();
    }


    function getPrefixDetails() {
        return [
            {name: labels.merchant + ":", value:token.childMerchantNumber},
            {name: labels.customerCode + ":", value:customer.uniqueId},
            {name:"Created on:", value: dateUtil.convertToDateTimeString(customer.created)},
            {name:"Last updated on:", value: dateUtil.convertToDateTimeString(customer.lastUpdated)}];
    }

    //#region ========== Filter stuff ==========

    const defaultFilter = {
        uniqueId: '',
        firstName: '',
        lastName: '',
        emailAddress: '',
        phone: '',
    };

    /** This is called when a component auto-updates it's internal value (ie. DateRangePreset updates the dates/presetValue based on the other) */
    function handleFilterChange(newFilter) {  // Note: This is also called at first render (from either useEffect or DateRangePresetField completing the dates)
        setCurrentPage(1);
        setFilter(newFilter);
    }

    //#endregion

    function handleSort(field, descending) {
        setSort({field, descending});
    }

    function pageChanged(newPage) {
        setCurrentPage(newPage);
    }

    // ========== Render ==========

    if (redirect) return <Navigate to={PlatformRoutesConfiguration.tokenRoute.viewToken.generatePath(tokenId)} />;

    return (<>
        <Form initialValues={_.get(savedState, 'filter') || defaultFilter}>
            <div className={classNames('link-token-customer-page', { 'hidden': !!customer})}>
                <PageSection>
                    <PageHeader
                        backButton
                        title='Search customers'
                    >
                    </PageHeader>

                    <FormError errors={errors} />

                    <FindLinkCustomerList
                        // For the quick filter
                        savedFilter={savedState && savedState.filter}
                        onFilterChange={handleFilterChange}
                        // For the table/list
                        sort={sort}
                        onSort={handleSort}
                        customers={_.get(searchResult, 'items', [])}
                        isLoading={isLoading}
                        onLinkTokens={showConfirmationModal}
                    />

                    <PaginationControl currentPage={currentPage} onPageChanged={pageChanged} itemCount={_.get(searchResult, 'count', 0)} itemsPerPage={resultsPerPage} />
                </PageSection>

                <BackToTop />
            </div>
        </Form>


        {customer && (
            <PageSection className='link-token-customer-confirmation'>
                <PageHeader
                    icon={<IconText info />}
                    title='You are going to link this payment method to the customer below'
                    backButton
                />

                <h2>{billpayUtil.getCustomerFullName(customer)}</h2>
                <DescriptionList greyLabel items={getPrefixDetails()} />

                <CustomerDetails
                    customer={customer}
                />

                {linkErrors && (
                    <ErrorText>Error in linking token to customer. Please try again</ErrorText>
                )}

                <ButtonContainer>
                    <Button primary onClick={confirmLinkCustomerToToken}>Confirm link</Button>
                    <Button onClick={cancelLinkCustomerToToken}>Back to search</Button>
                </ButtonContainer>
            </PageSection>
        )}
    </>);
}

function mapStateToProps(state, ownProps) {
    const tokenId = ownProps.match.params.id;
    const token = state.dataVault.token.details;

    return {
        tokenId,
        token: _.get(token, "dataVaultId") === +tokenId ? token : null,
        savedState: state.dataVault.customer.customersPageState,
        searchResult: state.dataVault.customer.searchResult,
        isLoading: state.dataVault.customer.isLoading,
        customerCount: state.dataVault.customer.customerCount,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        customerActions: bindActionCreators(customerActions, dispatch),
        tokenActions: bindActionCreators(tokenActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch)
    };
}

function mapStoreToErrors(state) {
    return state.dataVault.customer.errors;
}

function mapErrorToString(error) {
    var paramLabels = {
        'customerCode': labels.customerCode,
        'firstName': labels.firstName,
        'lastName': labels.lastName,
        'email': labels.email,
        'phoneNumber': labels.phoneNumber
    };

    return errorUtil.getMessage(error, paramLabels);
}

export default withError(
    connect(mapStateToProps, mapDispatchToProps)(LinkTokenCustomerPage),
    mapStoreToErrors,
    mapErrorToString
);
