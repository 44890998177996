import React from 'react';
import BaseTransactionDetailCategory from './BaseTransactionDetailCategory';
import _ from 'lodash';
import PropTypes from 'prop-types';

import textUtil from '@premier/utils/text';
import labels from 'constants/labels';

const PaymentDetailsCategory = (props) => {

    var { transaction } = props;

    var paymentDetails = [
        { name: 'Merchant no.', value: _.get(transaction, 'paymentSource.merchantNumber') },
        { name: labels.billerCode, value: _.get(transaction, 'paymentProcessedTxnModel.billerCode') },
        { name: _.get(transaction, 'paymentSource.crn1Name') || labels.crn1, value: _.get(transaction, 'paymentProcessedTxnModel.crn1') },
        { name: _.get(transaction, 'paymentSource.crn2Name') || labels.crn2, value: _.get(transaction, 'paymentProcessedTxnModel.crn2') },
        { name: _.get(transaction, 'paymentSource.crn3Name') || labels.crn3, value: _.get(transaction, 'paymentProcessedTxnModel.crn3') },
        { name: "Refund reason", value: _.get(transaction, 'paymentSource.refundReason') },
        { name: 'Merchant reference', value: _.get(transaction, 'paymentProcessedTxnModel.merchantReference') },
        { name: 'Receipt no.', value: textUtil.insertSpaces(transaction.paymentProcessedTxnModel.receiptNumber) },
        { name: 'RRN', value: _.get(transaction, 'paymentProcessedTxnModel.rrn') }
    ];

    return <BaseTransactionDetailCategory title='Payment details' items={paymentDetails} />;
};

PaymentDetailsCategory.propTypes = {
    transaction: PropTypes.object.isRequired
};

export default PaymentDetailsCategory;
