import * as actions from './actionTypes';
import { messagingApi } from 'api';

import { promiseResultRequestActions } from 'components/Common/requestActions';
import { MessagingSettingsModel } from '@premier/webapi-client';

export let getMessagingSettings = promiseResultRequestActions(
    actions.GET_MESSAGING_SETTINGS,
    () => messagingApi.getSettings()
).send;

let updateMessagingSettingsActions = promiseResultRequestActions(
    actions.UPDATE_MESSAGING_SETTINGS,
    (settings: MessagingSettingsModel) => messagingApi.updateSettings(settings),
    (settings: MessagingSettingsModel) => ({ settings })
);
export let updateMessagingSettings = updateMessagingSettingsActions.send;
export let clearUpdateMessagingSettings = updateMessagingSettingsActions.clear;