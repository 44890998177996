import { ReactNode } from "react";
import { Dialog } from "@premier/ui";
import { FieldError } from "api/mapErrors";
import Form from "../Form";

type Props = {
    show: boolean;
    /** Shows a close (X) button on top right */
    closeButton?: boolean;
    dismissable?: boolean;
    /** Only applicable when closeButton is true */
    onClose?: () => void;
    /** The icon above the title */
    icon?: ReactNode;
    title: string;
    children?: ReactNode;
    /** Adds a divider (horizontal line) on top of the footer, to separate the content from the footer */
    footerDivider?: boolean;
    /** Form props */
    name?: string;
    initialValues: any;
    initialValidation?: any;
    onValidate?: () => void;
    onSubmit?: (values: any, context: any) => void;
    /** A function on the formDialog definition that passes-in a node of buttons, allowing linking of context.
     *  eg. context => <SubmitButton /> */
    renderButtons?: (context: any) => ReactNode;
    renderForm?: (context: any) => ReactNode;
    errors?: FieldError[];
    errorMaps?: any;
    className?: string;
}

// Note: Similar code in Dialog.js (Make sure to check the other file if you're making changes)
/** A Dialog with Form. You will get the form values on a button click. */
const FormDialog = ({
    show, closeButton, onClose, icon, title, children, footerDivider, // modal props
    name, initialValues, initialValidation, onValidate, onSubmit, renderButtons, renderForm, errors, errorMaps,  // form props
    ...otherProps
}: Props) => {

    if (!show) //allows for rendering of the form ONLY when show is enabled, allowing for initialValues / initialValidation to run
        return null;

    return (
        <Form
            name={name}
            initialValues={initialValues}
            initialValidation={initialValidation}
            onValidate={onValidate}
            onSubmit={onSubmit}
            errors={errors}
            errorMaps={errorMaps}
            render={(context: any) => (
                <Dialog
                    show={show}
                    onClose={onClose}
                    closeButton={closeButton}
                    //header
                    icon={icon}
                    title={title}
                    //footer
                    divider={footerDivider}
                    footerButtons={renderButtons && renderButtons(context)}
                    {...otherProps}
                >
                    {/* Form fields go here */}
                    {renderForm ? renderForm(context) : children}
                </Dialog>
            )}
        />
    );
};

export default FormDialog;
