import React from 'react';

import { PageHeader } from 'components/Common';
import { PlatformRoutesConfiguration } from 'components/Routing';
import mediaPaths from 'constants/mediaPaths';

import { PageSection, Button } from '@premier/ui';

const BatchHowToAdd = () => {
    return (
       <>
            <PageSection>
                <PageHeader
                    backButton={{
                        to: PlatformRoutesConfiguration.transactionRoute.batchPayments.generatePath(),
                        label: 'Back to batch payments'
                    }}
                    title='How to create a BPOINT batch file'
                />
                <p>A batch file can be created to process card and bank account transactions. Batch files must be in a supported BPOINT batch file format.</p>
                <p>Download the PDF to view the BPOINT standard batch file format.</p>
                <a href={mediaPaths.batchFileSpec} target="_blank" rel="noopener noreferrer">
                    <Button primary>Download</Button>
                </a>
            </PageSection>

            <PageSection>
                <h2>Other available batch file formats</h2>
                <p>Download PDF for BPOINT APCA format for direct debit bank accounts.</p>
                <a href={mediaPaths.batchApcaFileSpec} target="_blank" rel="noopener noreferrer">
                    <Button>Download</Button>
                </a>
            </PageSection>
       </>
    );
};

export default BatchHowToAdd;
