import { SuccessModal } from "@premier/ui";
import { FilenameSubheading } from "components/Batch";

type Props = {
    show: boolean;
    addToLibrary: boolean;
    append?: boolean;
    batchLibraryFileName?: string;
    filename: string;
    onHide: () => void;
}

const UploadSuccessModal = ({
    show, onHide, //modal logic render
    addToLibrary, append, //logic render
    batchLibraryFileName, filename //data
}: Props) => {

    let whereText: string;

    if (append) {
        whereText = `The file has been appended to ${batchLibraryFileName}.`;
    }
    else {
        const textLibrary = addToLibrary ? " and in \"batch library\" page" : "";
        whereText = `You can view the file in "batch payments" page${textLibrary}.`;
    }

    return (
        <SuccessModal
            show={show}
            onClose={onHide}
            disableAutoClose
            title="Uploaded"
        >
            <FilenameSubheading filenames={[filename]} />
            <p>{whereText}</p>
        </SuccessModal>
    );
};

export default UploadSuccessModal;
