//#region ----- Base64 Util -----
// From https://github.com/danguer/blog-examples/blob/master/js/base64-binary.js
var Base64Binary = {
    _keyStr : "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",

    /* will return a  Uint8Array type */
    decodeArrayBuffer: function(input) {
        var bytes = (input.length/4) * 3;
        var ab = new ArrayBuffer(bytes);
        this.decode(input, ab);

        return ab;
    },

    removePaddingChars: function(input){
        var lkey = this._keyStr.indexOf(input.charAt(input.length - 1));
        if(lkey === 64){
            return input.substring(0,input.length - 1);
        }
        return input;
    },

    decode: function (input, arrayBuffer) {
        //get last chars to see if are valid
        input = this.removePaddingChars(input);
        input = this.removePaddingChars(input);

        var bytes = parseInt((input.length / 4) * 3, 10);

        var uarray;
        var chr1, chr2, chr3;
        var enc1, enc2, enc3, enc4;
        var i = 0;
        var j = 0;

        if (arrayBuffer)
            uarray = new Uint8Array(arrayBuffer);
        else
            uarray = new Uint8Array(bytes);

        input = input.replace(/[^A-Za-z0-9+/=]/g, "");

        for (i=0; i<bytes; i+=3) {
            //get the 3 octects in 4 ascii chars
            enc1 = this._keyStr.indexOf(input.charAt(j++));
            enc2 = this._keyStr.indexOf(input.charAt(j++));
            enc3 = this._keyStr.indexOf(input.charAt(j++));
            enc4 = this._keyStr.indexOf(input.charAt(j++));

            chr1 = (enc1 << 2) | (enc2 >> 4);
            chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
            chr3 = ((enc3 & 3) << 6) | enc4;

            uarray[i] = chr1;
            if (enc3 !== 64) uarray[i+1] = chr2;
            if (enc4 !== 64) uarray[i+2] = chr3;
        }

        return uarray;
    }
}
//#endregion

export class FileUtil {

    /** Creates a file to be downloaded
     * @param data The file content
     * @param type MIME type, eg. 'text/plain'
    */
    // From https://stackoverflow.com/questions/13405129/javascript-create-and-save-file#30832210
    download(data, filename, type) {
        var file = new Blob([data], {type: type});
        if (window.navigator.msSaveOrOpenBlob) // IE10+
            window.navigator.msSaveOrOpenBlob(file, filename);
        else { // Others
            var a = document.createElement("a"),
                url = URL.createObjectURL(file);
            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            setTimeout(function() {
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
            }, 0);
        }
    }

    decodeBase64Binary(str) {
        return Base64Binary.decode(str);
    }

    /**
     * eg. readableFileSize(1000) -> "1 kB"
     * @param {number} bytes The file size
     * @param {number} decimalPoints How many decimal points to show in the resulting string (eg. 1.0 kB)
     * @param {boolean} si International System of Units (ie. 1000 instead of 1024)
     */
    readableFileSize(bytes, decimalPoints = 0, si = true) {
        // https://stackoverflow.com/questions/10420352/converting-file-size-in-bytes-to-human-readable-string
        // Alternative: https://github.com/sindresorhus/pretty-bytes
        const threshold = si ? 1000 : 1024;

        if (Math.abs(bytes) < threshold) {
            return bytes + ' B';
        }

        const units = si
            ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
            : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
        let u = -1;
        const r = 10 ** decimalPoints;

        do {
            bytes /= threshold;
            ++u;
        } while (Math.round(Math.abs(bytes) * r) / r >= threshold && u < units.length - 1);

        return bytes.toFixed(decimalPoints) + ' ' + units[u];
    }
}

const fileUtil = new FileUtil();
export default fileUtil;