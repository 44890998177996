import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

import currencyUtil from '@premier/utils/currency';
import { QuantityController, ProductThumbnail } from 'components/StationeryShop';
import { ResponsiveTable, Button } from '@premier/ui';

import * as actions from 'components/StationeryShop/_actions/stationeryShopActions';

import './ShoppingCartTable.scss';

const ShoppingCartTable = ({
    cartItems, //data
    readOnly, freightProduct, //logic render
    actions //API actions
}) => {

    function getGST(data) {
        var total = getTotal(data);
        var nonTax = Math.round(getTotal(data) / 1.1);
        return total - nonTax;
    }

    function getTotal(data) {
        var total = 0;
        data.forEach(item => (total += item.unitCost * item.quantity));
        return total;
    }

    function updateCartItemInfo(item, qty) {
        actions.updateCartItemQty(item.productId, qty);
    }

    function getLinePrice(item) {
        return readOnly ? (
            currencyUtil.convertToDisplayString(item.amountPaid)
        ) : (
            <>
                {currencyUtil.convertToDisplayString(
                    item.quantity * item.unitCost
                )}
                <div className="remove-button">
                    <Button link onClick={() => updateCartItemInfo(item, 0)}>
                        Remove
                    </Button>
                </div>
            </>
        );
    }

    return (
        <div className="products-table">
            <ResponsiveTable
                data={cartItems && cartItems.filter(x => x.productId !== freightProduct.productId)}
                columns={[
                    {
                        label: '',
                        getter: item => (
                            <ProductThumbnail small
                                src={item.imagePath}
                                altText={''}
                            />
                        ),
                    },
                    {
                        label: 'Item',
                        getter: 'itemName',
                    },
                    {
                        label: 'Price',
                        getter: item => currencyUtil.convertToDisplayString(item.unitCost),
                        textAlign: 'right',
                        className: 'currency',
                    },
                    {
                        label: 'Quantity',
                        getter: item =>
                            <QuantityController
                                shoppingCart
                                maxQuantity={item.maxOrderQuantity}
                                minQuantity={item.minOrderQuantity}
                                readOnly={readOnly}
                                value={item.quantity}
                                onQtyUpdate={qty => updateCartItemInfo(item, qty)}
                            />,
                        textAlign: 'center',
                    },
                    {
                        label: 'Subtotal',
                        getter: getLinePrice,
                        textAlign: 'right',
                        className: 'currency',
                    },
                ]}
            />
            <div className="flex-container">
                <div>Delivery</div>
                <div>
                    {freightProduct &&
                        currencyUtil.convertToDisplayString(
                            freightProduct.itemCost
                        )}
                </div>
            </div>
            <div className="flex-container total">
                <div>Total</div>
                <div>
                    {currencyUtil.convertToDisplayString(
                        getTotal(cartItems)
                    )}
                </div>
            </div>
            <div className="flex-container">
                <div>
                    ( GST 10%:{' '}
                    {currencyUtil.convertToDisplayString(
                        getGST(cartItems)
                    )}{' '}
                    )
                </div>
                <div></div>
            </div>
        </div>
    );
};

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch),
    };
}

ShoppingCartTable.propTypes = {
    cartItems: PropTypes.array.isRequired,
    freightProduct: PropTypes.object.isRequired,
    readOnly: PropTypes.bool,
};

export default connect(null, mapDispatchToProps)(ShoppingCartTable);
