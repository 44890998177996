import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';

import { Link, LoadingIndicator, PaddedContainer, PageSection } from '@premier/ui';
import { PageHeader, useApiCallDeprecated, APICallRequestState } from 'components/Common';
import { BillerTitleDropdown, PaymentPageUrlSection } from '..';
import { PreviewTabs } from '../components/brandingPreview';
import { useParams } from 'react-router-dom';

import { PlatformRoutesConfiguration } from 'components/Routing';
import * as commonActions from 'components/Common/_actions/actions';
import * as designerActions from 'components/Settings/_actions/designerActions';
import { billerApi } from 'api';
import { VerifyBiller } from '../../../util/verificationUtil';

/** This page shows a preview of a Biller's branding style.
 * (Then from the TitleDropdown in this page, you can also preview the Merchant/default style)
 */
const PreviewBrandingPage = ({
    designerActions,
    merchant,
    brandingState, // from Redux/API
}) => {
    const { billerCode } = useParams();
    // A null biller code is valid here
    if (billerCode)
        VerifyBiller(billerCode);

    const [ billers, billersStatus ] = useApiCallDeprecated(() => {
        return billerApi.getBillersV2(false);
    }, []);

    const [ biller, setBiller ] = useState(null);

    const style = brandingState.data;

    useEffect(() => {
        if (billersStatus === APICallRequestState.SUCCESSFUL) {
            if (billerCode) {
                setBiller(_.find(billers, { billerCode }));
            } else {
                setBiller(null);
            }
        }
    }, [billerCode, billers, billersStatus]);

    useEffect(() => {
        if (biller)
            designerActions.getBranding(biller.billerCode);
        else
            designerActions.getBranding(null);
    }, [biller]);

    return (
        <PageSection>
            <PageHeader backButton title='Branding' />

            {
                billersStatus === APICallRequestState.SUCCESSFUL ?
                <>
                    <BillerTitleDropdown
                        billerCode={billerCode}
                        billers={billers}
                        getUrl={PlatformRoutesConfiguration.designerRoute.previewBillerBranding.generatePath}
                        defaultFirstOption={{
                            value: '',
                            label: 'Default brand style',
                        }}
                    />

                    <PaymentPageUrlSection billerCode={billerCode} billerFullName={biller?.billerCodeWithName} hideEditBrandingRedirect />

                    <PaddedContainer
                        title='Preview'
                        button={
                            <Link button to={PlatformRoutesConfiguration.designerRoute.editBillerBranding.generatePath(billerCode)}>
                                Edit
                            </Link>
                        }
                        noDivider
                        className='preview'
                    >
                        {(brandingState.isLoading || !style) && <LoadingIndicator />}

                        {style && <PreviewTabs merchant={merchant} biller={biller} style={style} />}
                    </PaddedContainer>
                </> : <LoadingIndicator />
            }

        </PageSection>
    );
};

function mapStateToProps(state, ownProps) {
    return {
        merchant: state.accounts.users.merchant,
        brandingState: state.settings.designer.branding,
        authenticatedUser: state.accounts.users.authenticatedUser,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        commonActions: bindActionCreators(commonActions, dispatch),
        designerActions: bindActionCreators(designerActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PreviewBrandingPage);
