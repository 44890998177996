import { ChangeEvent, useState } from "react";
import { Dialog, Button, Checkbox } from "@premier/ui";
import { ProductTooltip } from "components/Common";
import { FilenameSubheading } from "components/Batch";

type Props = {
    show: boolean;
    filenames: string[];
    /** When the dialog is closed/cancelled */
    onClose: () => void;
    /** When the user selects Now */
    onNow: (addToBatchLibrary: boolean) => void;
    /** When the user selects Schedule */
    onLater: (addToBatchLibrary: boolean) => void;
}

const ProcessNowDialog = ({
    show, //logic render
    filenames, //data
    onClose, onNow, onLater //functions
}: Props) => {
    const [addToBatchLibrary, setAddToBatchLibrary] = useState(false);
    const multipleFiles = filenames && filenames.length > 1;
    const s = multipleFiles ? "s" : "";

    const handleAddToBatchLibraryChanged = (e: ChangeEvent<HTMLInputElement>) => {
        setAddToBatchLibrary(e.target.checked);
    };

    return (
        <Dialog show={show}
            title={`Process batch file${s} now?`}
            closeButton
            onClose={onClose}
            footerButtons={<>
                <Checkbox label="add to batch library" checked={addToBatchLibrary} onChange={handleAddToBatchLibraryChanged}  />
                <ProductTooltip text="You can save this batch file to use again later." />
                <div>
                    <Button primary onClick={() => { onNow(addToBatchLibrary); }}>Process now</Button>
                    <Button onClick={() => { onLater(addToBatchLibrary); }}>Schedule</Button>
                </div>
            </>}
        >
            <FilenameSubheading filenames={filenames} />

            <p>
                Select "Process now" to process the file{s} immediately.<br/>
                Select "Schedule" to set a future date to process the file{s}.<br/>
                Close the dialog to cancel.
            </p>
        </Dialog>
    );
};

export default ProcessNowDialog;
