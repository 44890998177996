import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from 'components/DataVault/_actions/tokenActions';
import _ from 'lodash';

import { FileSelector, Dialog, Button, LoadingIndicator, SuccessModal } from '@premier/ui';
import { UploadErrorDialog, FilenameSubheading } from 'components/Batch';

const uploadTokensBatch = (state) => state.dataVault.uploadTokensBatch

const useBatchUpload = () => {
    const state = useSelector(uploadTokensBatch);
    const dispatch = useDispatch();

    return {
        state,
        clear: () => dispatch(actions.clearUploadBatch()),
        upload: (files) => dispatch(actions.uploadBatch(files)).catch(() => {})
    }
}

const UploadTokenBatchDialogs = ({
    fileSelectRef
}) => {
    const { state, clear, upload } = useBatchUpload();
    const { data, errors, hasLoaded, isInitial } = state;

    const [files, setFiles] = useState(undefined);
    const [confirmed, setConfirmed] = useState(false);
    const [retry, setRetry] = useState(false);

    const handleSelect = (files) => {
        setFiles(files);
    };

    const handleRetry = () => {
        setRetry(true);
        setConfirmed(false);
        setFiles(undefined);
        clear();
    };

    const handleHide = () => {
        setFiles(undefined);
        setConfirmed(false);
        clear();
    };

    const handleConfirm = () => {
        setConfirmed(true);
        upload(files);
    };

    const checkFileValid = (files) => {

        const fileName = files[0].name.toUpperCase();
        if (!fileName.includes('.TXT') && !fileName.includes('.CSV') && !fileName.includes('.DAT')) {

            return [
                {
                    code: "INVALID_BATCH_FILE_EXTENSION",
                    message: "Batch filename is invalid. Valid extensions include dat, txt and csv",
                    parameter: "File.Filename"
                }
            ];
        }

        return null;
    };

    const getFilenames = () => ([...files]).map(r => r.name);

    useEffect(() => {
        if (retry && isInitial) {
            setRetry(false);
            fileSelectRef.current.open();
        }
    }, [retry, isInitial]);

    if (!files)
        return <FileSelector ref={fileSelectRef} onSelect={handleSelect} />;

    if (!confirmed) {
        return <Dialog show closeButton
            title="Upload this file?"
            onClose={handleHide}
            footerButtons={<>
                <Button primary onClick={handleConfirm}>OK</Button>
                <Button onClick={handleHide}>Cancel</Button>
            </>}
        >
            <FilenameSubheading filenames={getFilenames()} />
        </Dialog>
    }

    const fileValidationErrors = checkFileValid(files);
    if (fileValidationErrors) {

        return <UploadErrorDialog show
            filenames={getFilenames()}
            filename={""} // Not required because there is a list provided by filenames={getFilenames()}
            errors={fileValidationErrors}
            batchFileResult={null}
            onRetry={handleRetry}
            onCancel={handleHide}
        />
    }

    if (!hasLoaded && !fileValidationErrors)
        return <Dialog show title='Uploading'>
            <FilenameSubheading filenames={getFilenames()} />
            <LoadingIndicator />
        </Dialog>;

    if (errors || _.get(data, "hasErrors", true))
        return <UploadErrorDialog show
            filenames={getFilenames()}
            filename={""} // Not required because there is a list provided by filenames={getFilenames()}
            errors={errors}
            batchFileResult={_.get(data, 'batchFileValidationResult')}
            onRetry={handleRetry}
            onCancel={handleHide}
        />;

    return <SuccessModal
        show disableAutoClose onClose={handleHide}
        title='File uploaded sucessfully'
    >
        <FilenameSubheading filenames={getFilenames()} />
    </SuccessModal>
}

export default UploadTokenBatchDialogs;