import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';

import FormGroup from './FormGroup';
import withField from './withField';
import { validate } from '../validation/fieldValidation';
import './withRangeField.scss';


/** Duplicate the field and make it a range field
 * @param {Array} defaultPlaceholders Default placeholder for the range component (as opposed to the instance of it). Default = ['From', 'To']
*/
const withRangeField = (WrappedComponent, defaultPlaceholders=['From', 'To']) => {
    const RangeComponent = ({
        name, formProps, groupProps, onBlur,
        validation, placeholders, labels,  // range specific props
        ...otherProps
    }) => {
        const { value, error, setValue, setValidation, getValidation } = formProps;

        const [id] = useState(_.uniqueId(`${name}_`));
        const groupClassName = 'range-wrapper';

        const minPlaceholder = _.get(placeholders, '[0]', defaultPlaceholders[0]);
        const maxPlaceholder = _.get(placeholders, '[1]', defaultPlaceholders[1]);
        const minLabel = _.get(labels, '[0]', minPlaceholder || 'From');
        const maxLabel = _.get(labels, '[1]', maxPlaceholder || 'To');

        useEffect(() => {
            if(!value) {
                setValue({
                    min: '',
                    max: '',
                });
            }
        }, []);

        useEffect(() => {
            if (!getValidation()) {
                setValidation(
                    validation ||
                    validate().when(
                        val => !(val && val.min && val.max && Number(val.min) > Number(val.max)),
                        `'${minLabel}' must be smaller than '${maxLabel}'`
                    )
                );
            }
        }, []);

        function handleFieldChange(fieldName) {
            return e => {
                setValue({ ...value, [fieldName]: _.get(e, 'target.value', e)});
            };
        }

        function handleBlur(e) {
            // Do not call onBlur if we're still here (eg. on the To field)
            if(!_.get(e, 'relatedTarget.parentNode.parentNode.className', '').includes(groupClassName)) {
                onBlur(e);
            }
        }


        return (
            <FormGroup
                fieldId={id+'_min'}
                {...groupProps}
                className={classNames('range-field', groupClassName, groupProps.className)}
            >
                <div className='field-wrapper'>
                    <WrappedComponent
                        id={id+'_min'}
                        placeholder={minPlaceholder}
                        value={_.get(value, 'min', '')}
                        onChange={handleFieldChange('min')}
                        onBlur={handleBlur}
                        error={error}
                        {...otherProps}
                    />
                    <span className='separator'>to</span>
                    <WrappedComponent
                        id={id+'_max'}
                        placeholder={maxPlaceholder}
                        value={_.get(value, 'max', '')}
                        onChange={handleFieldChange('max')}
                        onBlur={handleBlur}
                        error={error}
                        {...otherProps}
                    />
                </div>
            </FormGroup>
        );
    }

    RangeComponent.propTypes = {
        name: PropTypes.string.isRequired,
        compact: PropTypes.bool,

        /** The placeholders for the two inputs, eg. ['Min', 'Max'] */
        placeholders: PropTypes.arrayOf(PropTypes.string),

        /** Not shown, only used for describing the fields in error messages. Default = same as placeholders or From/To */
        labels: PropTypes.arrayOf(PropTypes.string),

        onChange: PropTypes.func,

        /** eg. validate().when(...) */
        validation: PropTypes.func,
    };

    return withField(RangeComponent);
}

export default withRangeField;