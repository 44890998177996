import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import classNames from 'classnames';

import errorUtil from '@premier/utils/error';
import * as billpayUtil from '@premier/utils/billpay';

import { PageSection, BackToTop, PaginationControl, CardContainer, LoadingIndicator, BackButton, IconText, SuccessModal } from '@premier/ui';
import { Form } from '@premier/form';
import { FindLinkTokensList } from 'components/DataVault';
import { withError, PageHeader, FormError } from 'components/Common'
import labels from 'constants/labels';

import * as tokenActions from 'components/DataVault/_actions/tokenActions';
import * as customerActions from 'components/DataVault/_actions/customerActions';
import { PlatformRoutesConfiguration } from 'components/Routing';

const LinkCustomerTokensPage = ({
    customer, isLoading, errors, searchResult, //state values
    actions, tokenActions, //API actions
}) => {

    const [filter, setFilter] = useState(null);  // A live copy of the form values
    const [sort, setSort] = useState({ field: 'Updated', descending: true });
    const [currentPage, setCurrentPage] = useState(1);
    const [customerFound, setCustomerFound] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [redirectToCustomer, setRedirectToCustomer] = useState(false);

    const resultsPerPage = 10;

    useEffect(() => {
        actions.getCustomer(customer.customerId)
            .then(() => {
                setCustomerFound(true);
                handleFilterChange(defaultFilter);
            });
    }, [actions]);

    useEffect(() => {
        if (currentPage && filter && sort && customerFound) {
            // only include tokens for same merchant as the customer.
            filter.childMerchantNumber = customer.childMerchantNumber;
            tokenActions.getTokens(resultsPerPage, currentPage - 1, filter, sort);
        }
    }, [currentPage, filter, sort, customer]);

    function linkTokensToCustomer(tokens) {
        actions.linkToken(customer.customerId, tokens)
            .then(() => setShowSuccessModal(true));
    }

    //#region ========== Filter stuff ==========

    const defaultFilter = {
        maskedCardNumber: { prefix: '', suffix: '' },
        deBsbNumber: '',
        crn1: '',
        crn2: '',
        crn3: '',
        withoutCustomerOnly: true,
    };

    /** This is called when a component auto-updates it's internal value (ie. DateRangePreset updates the dates/presetValue based on the other) */
    function handleFilterChange(newFilter) {  // Note: This is also called at first render (from either useEffect or DateRangePresetField completing the dates)
        setCurrentPage(1);
        setFilter(newFilter);
    }

    //#endregion

    function handleSort(field, descending) {
        setSort({ field, descending });
    }

    function pageChanged(newPage) {
        setCurrentPage(newPage);
    }

    function getTokens() {
        //hides tokens if errors in the token search
        return errors.length ? null : _.get(searchResult, 'items', []);
    }

    // ========== Render ==========
    if (redirectToCustomer)
        return <Navigate to={PlatformRoutesConfiguration.customerRoute.viewCustomer.generatePath(customer.customerId)} />;

    return (<>
        {isLoading && !customerFound && (
            <CardContainer header="Loading tokens">
                <LoadingIndicator />
            </CardContainer>
        )}

        {!isLoading && !customerFound && <>
            <BackButton to={PlatformRoutesConfiguration.customerRoute.manageCustomers.generatePath()} />
            <IconText alert>Customer not found or an error occurred.</IconText>
        </>}

        {customerFound && (
            <Form initialValues={defaultFilter}>
                <div className={classNames('link-customer-tokens-page')}>
                    <PageSection>
                        <PageHeader
                            backButton
                            title={`Please select the payment methods to link to customer ${billpayUtil.getCustomerFullName(customer)}`}
                        >
                        </PageHeader>

                        <FormError errors={errors} />

                        <FindLinkTokensList
                            // For the quick filter
                            onFilterChange={handleFilterChange}
                            // For the table/list
                            sort={sort}
                            onSort={handleSort}
                            tokens={getTokens()}
                            isLoading={isLoading}
                            onLinkTokens={linkTokensToCustomer}
                        />
                        {!errors.length &&
                            <PaginationControl currentPage={currentPage} onPageChanged={pageChanged} itemCount={_.get(searchResult, 'count', 0)} itemsPerPage={resultsPerPage} />
                        }
                    </PageSection>

                    <BackToTop />
                </div>
            </Form>
        )}



        <SuccessModal show={showSuccessModal} onClose={() => setRedirectToCustomer(true)}>
            Linked
        </SuccessModal>
    </>);
}

function mapStateToProps(state, ownProps) {
    var urlId = ownProps.match.params.id;
    var customerId = parseInt(urlId);

    if (isNaN(customerId) || customerId.toString() !== urlId) //urlId is not an integer
        return {
            customer: {},
            isLoading: false
        };

    var customer = { customerId: customerId };

    // If (or as soon as) the redux store has the requested customer then use that
    if (_.get(state, 'dataVault.customer.details.customerId') === customerId)
        customer = state.dataVault.customer.details;

    return {
        customer,
        searchResult: state.dataVault.token.searchResult,
        isLoading: state.dataVault.token.isLoading,
        tokenCount: state.dataVault.token.tokenCount,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(customerActions, dispatch),
        tokenActions: bindActionCreators(tokenActions, dispatch),
    };
}

function mapStoreToErrors(state) {
    return state.dataVault.customer.errors;
}

function mapErrorToString(error) {
    var paramLabels = {
        'Request.MaskedCardNumber': 'Card number',
        'Request.DeBsbNumber': labels.bsb,
        'Request.DeAccountNumber': labels.bankAccountNumber,
        'Request.Crn1': labels.tokenReference1,
        'Request.Crn2': labels.tokenReference2,
        'Request.Crn3': labels.tokenReference3
    };
    return errorUtil.getMessage(error, paramLabels);
}

export default withError(
    connect(mapStateToProps, mapDispatchToProps)(LinkCustomerTokensPage),
    mapStoreToErrors,
    mapErrorToString
);
