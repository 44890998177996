import React from "react";
import labels from 'constants/labels';
import { checkSessionTimeOut } from "api/util";


// !!! Depreciated !!! - Always use useApiCall instead (custom React hook)
// !!! Depreciated !!! - Always use useApiCall instead (custom React hook)
// !!! Depreciated !!! - Always use useApiCall instead (custom React hook)
// A higher level component that is responsible for making a single API call and storing
// it within state if successful. If in error, it will store the errors instead.
// dataSourceCallback is expecting Swagger API function calls. eg. (props) => paymentPlanOptionApi.getPaymentPlanOptions(props.billerCode)
// Associated API calls must return ApiResponse<T> on the WebAPI.
// Props within this lambda function would contain the props passed from the base level component.
// See Decorator pattern for a more generic explanation of this coding practice.
// Supports multiple API request callbacks. See below for example.
// !!! Depreciated !!! - Always use useApiCall instead (custom React hook)
// !!! Depreciated !!! - Always use useApiCall instead (custom React hook)
// !!! Depreciated !!! - Always use useApiCall instead (custom React hook)
const DEFAULT_API_DATA_PROP_NAME = "apiData";
const DEFAULT_API_ERRORS_PROP_NAME = "apiErrors";

const withApiCall = (WrappedComponent, dataSourceCallback, trackProps = []) => {
  class withApiCall extends React.Component {
    constructor(props) {
      // Network resolution cannot be called in the render method since this can lead to performance and missing state.
      // (see https://github.com/facebook/react/blob/044015760883d03f060301a15beef17909abbf71/docs/docs/higher-order-components.md#dont-use-hocs-inside-the-render-method)
      super(props);
      this.state = { callbacks: dataSourceCallback, trackProps, isResolved: false };
    }

    // Side effects should be called here only
    async componentDidMount() {
      await this.resolveDependencies();
    }

    async resolveDependencies() {
      this.setState(prevState => ({ ...prevState, isResolved: false}));
      if (this.state.callbacks instanceof Function) { // a single callback is provided, apiData and apiErrors will be the default prop names
        await this.resolveApiRequest(this.state.callbacks, DEFAULT_API_DATA_PROP_NAME, DEFAULT_API_ERRORS_PROP_NAME);
      } else if (this.state.callbacks instanceof Object) { // dataSourceCallback is in format { <propName>: { <network request callback>, propName + "Errors" } }
        for (const [propName, apiRequestCallback] of Object.entries(this.state.callbacks)) {
          await this.resolveApiRequest(apiRequestCallback, propName, propName + "Errors");
        }
      } else {
        throw new Error("Expected either Function or Object for withApiCall.")
      }
      this.setState(prevState => ({ ...prevState, isResolved: true}));
    }

    async resolveApiRequest(dataSourceCallback, apiDataPropName, apiErrorsPropName) {
      // request refers to an Axios request which is the promise return type for all of the Swagger API client methods
      try {
        const request = await dataSourceCallback(this.props);
        if (request.status === 200) {
          this.setState(prevState => ({ ...prevState, [apiDataPropName]: request.data.data, [apiErrorsPropName]: request.data.errors }))
        } else {
                    throw request; // Will be captured to the catch as below
        }
      } catch (request) {
        checkSessionTimeOut(request?.response?.status);

        if (request?.data?.errors?.length) {
          // Format for each object is {code: 'MANDATORY_PARAM', label: 'PaymentPlanOptionName', message: "example"}
          this.setState(prevState => ({ ...prevState, [apiDataPropName]: null, [apiErrorsPropName]: request.data.errors }));
        } else {
          // No API error for whatever reason, have a fallback error message for display purposes
          this.setState(prevState => ({ ...prevState, [apiDataPropName]: null, [apiErrorsPropName]: [{ message: labels.unknownErrorMessage }] }));
        }
      }
    }

    // This functionality allows rerenders on components that cannot be updated by the virtual DOM via an parent component
    // eg. HOC applied to components at the export statement, export default withApiCall(<exampleComponent/>, ...)
    componentDidUpdate(prevProps) {
      for (let propName of this.state.trackProps) {
        if (this.props[propName] !== prevProps[propName]) {
          this.resolveDependencies();
          break;
        }
      }
    }

    render() {
      // Remove props that shouldn't be passed down to the child component
      const { callbacks, trackProps, isResolved, ...relevantProps } = this.state;
      return this.state.isResolved && <WrappedComponent {...relevantProps} {...this.props} />;
    }
  }

  return withApiCall;
};

export default withApiCall;