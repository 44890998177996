import { useContext, useEffect } from "react";
import InputField from "./InputField";
import FormContext from "../FormContext";
import { validate } from "../validation/fieldValidation";

type Props = {
    name: string;
    label: string;
    placeholder?: string;
    mandatory?: boolean;
    validateOnChange?: boolean;
    onChange?: (newValue: string, context: any) => void;
}

const EmailAddressField = (props: Props) => {
    const { setValidation, getValidation } = useContext(FormContext);

    useEffect(() => {
        const validation = getValidation(props.name);
        if (!validation)
            setValidation(props.name, validate().email());
    }, []);

    return <InputField type="text" {...props}></InputField>;
};

export default EmailAddressField;
