import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import _ from "lodash";
import { Navigate } from "react-router-dom";
import * as userActions from "components/Settings/_actions/userActions";
import { userApi } from 'api';
import { PlatformRoutesConfiguration } from "components/Routing";
import * as billpayUtil from "@premier/utils/billpay";
import * as paramUtil from "@premier/utils/param";
import { ApiError } from "@premier/webapi-client";


import { PageSection, LoadingIndicator, IconText, Link, Button, Dialog, Icon, BackButton, CardContainer, SuccessModal } from "@premier/ui";
import ClickableButton from 'components/Common/components/Controls/ClickableButton';
import { PageHeader, withError, FormError } from "components/Common";
import { UserDetails } from "components/Settings";

import "./ManageUsersPage.scss";
import { RootState } from "store/store";
import { User } from "models/User";

type Props = {
    userActions: any;
    user: User;
    userId: number | undefined;
    isLoading: boolean;
    currentUserId: number;
}

const ViewUserPage = ({
    userActions, //apiActions
    user, //state
    userId, //urlParam
    isLoading, //logic render
    currentUserId //state
}: Props) => {

    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showDeleteSuccessDialog, setShowDeleteSuccessDialog] = useState(false);
    const [showResetPasswordDialog, setShowResetPasswordDialog] = useState(false);
    const [showResetPasswordSuccessDialog, setShowResetPasswordSuccessDialog] = useState(false);
    const [redirectBack, setRedirectBack] = useState(false);
    const [apiPassword, setApiPassword] = useState<any>("");
    const [errors, setErrors] = useState<ApiError[]>([]);

    useEffect(() => {
        userActions.getUser(userId)
            .catch((err: any) => {
                setErrors(err);
            });
    }, [userId]);

    //Delete dialog functions
    function handleDeleteConfirmed() {
        setErrors([]);
        userActions.deleteUser(user.userId, user.merchantNumber)
            .then(() => {
                setShowDeleteDialog(false);
                setShowDeleteSuccessDialog(true);
            })
            .catch((err: any) => {
                setShowDeleteDialog(false);
                setErrors(err);
            });
    }

    function closeDeleteSuccessModal() {
        setErrors([]);
        setShowDeleteSuccessDialog(false);
        setRedirectBack(true);
    }

    //Password Reset dialog functions
    async function handleResetPasswordConfirmed() {
        setErrors([]);

        userApi.resetUserPassword(userId, user.merchantNumber)
            .then((response: string) => {
                setApiPassword(response);
                setShowResetPasswordSuccessDialog(true);
            })
            .catch((error: any) => {
                setErrors(error);
            });

        setShowResetPasswordDialog(false);
    }

    function closePasswordResetSuccessModal() {
        setApiPassword("");
        setErrors([]);
        setShowResetPasswordSuccessDialog(false);
    }

    if (redirectBack)
        return <Navigate to={PlatformRoutesConfiguration.usersRoute?.manageUsers.generatePath()!} />;


    if (!user) {
        return (
            isLoading ?
                (<CardContainer header="Loading user details">
                    <LoadingIndicator />
                </CardContainer>) :
                (<PageSection>
                    <BackButton to={PlatformRoutesConfiguration.usersRoute?.manageUsers.generatePath()}/>
                    <IconText alert>User not found or an error occurred.</IconText>
                </PageSection>)

        );
    }

    //Loaded and user data present
    return (<>
        <PageSection>
            <PageHeader
                backButton
                title={billpayUtil.getCustomerFullName(user)}
            >
                {user.isActive && <Button onClick={() => setShowResetPasswordDialog(true)}>Reset password</Button>}
                <Link button to={PlatformRoutesConfiguration.usersRoute?.editUser.generatePath(user.userId)}>Edit user</Link>
            </PageHeader>

            <UserDetails user={user} />
            <Button subtle disabled={currentUserId === userId} onClick={() => setShowDeleteDialog(true)}>Delete this user</Button>
        </PageSection>

        {/* with changes to deleting a user, this may need to be swapped for the confirmPasswordModal (see EditUserPage for example) */}
        <Dialog show={showDeleteDialog}
            title="Delete user?"
            icon={<Icon alert />}
            footerButtons={<>
                <Button disabled={isLoading} className="Redbackground-button" onClick={handleDeleteConfirmed}>Delete</Button>
                <Button onClick={() => setShowDeleteDialog(false)}>Cancel</Button>
            </>}
        >
            {user.isApiUser ? "Deleting this user will stop all API transactions for this merchant account from being processed." : "Are you sure you want to delete this user?"}
        </Dialog>

        <SuccessModal
            show={showDeleteSuccessDialog}
            title="User deleted successfully"
            onClose={closeDeleteSuccessModal}
        />

        <Dialog
            show={showResetPasswordDialog}
            title="Reset password?"
            icon={<Icon info />}
            closeButton
            onClose={() => setShowResetPasswordDialog(false)}
            footerButtons={<>
                <Button primary disabled={isLoading} onClick={handleResetPasswordConfirmed}>Reset</Button>
                <Button onClick={() => setShowResetPasswordDialog(false)}>Cancel</Button>
            </>}
        />

        {user.isApiUser ? <Dialog
            show={showResetPasswordSuccessDialog}
            title="Password reset successfully"
            icon={<Icon tick />}
            closeButton
            onClose={closePasswordResetSuccessModal}
        >
            <p>API user password has been reset. Please find the details below and copy them in a secure place, they will not be shown again.</p>
            <p><b>API Username:</b> {user.userName}<br/>
            <b>Password:</b> <ClickableButton clickableButtonId="apiPasswordCopy" text={apiPassword} /></p>
        </Dialog> : <SuccessModal
            show={showResetPasswordSuccessDialog}
            title="Password reset successfully"
            onClose={closePasswordResetSuccessModal}
        >
            A new password has been sent to {billpayUtil.getCustomerFullName(user)}
        </SuccessModal>}

        {errors && <FormError apiErrors={errors} /> }

    </>);
};

function mapStateToProps(state: RootState, ownProps: any) {
    const userId = paramUtil.asInteger(ownProps.match.params.id);

    return {
        user: _.get(state, "settings.user.details.userId") === userId ? state.settings.user.details : undefined,
        userId: userId,
        isLoading: state.settings.user.isLoading,
        currentUserId: _.get(state, "accounts.users.authenticatedUser.userId")
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        userActions: bindActionCreators(userActions, dispatch)
    };
}

function mapStoreToErrors(state: RootState) {
    return state.settings.user.errors;
}

export default withError(
    connect(mapStateToProps, mapDispatchToProps)(ViewUserPage),
    mapStoreToErrors
);
