import { Dialog, CardContainer, DescriptionList, Button, Icon } from '@premier/ui';
import { EmailAddressField, Form, SubmitButton, validate } from '@premier/form';
import { InvoiceModel } from 'packages/webapi-client';
import { MerchantInvoiceModalState } from './ViewInvoiceBody';
import { maxLengthErrorMessage } from 'components/DataVault/components/InvoiceForms/InvoiceForm';

import currencyUtil from '@premier/utils/currency';
import dateUtil from '@premier/utils/date';
import labels from 'platforms/base/constants/labels';

import './ViewInvoiceDialog.scss';

export const viewInvoiceDialogCopy = {
    resend: {
        title: 'Send invoice',
        subtitle: 'Invoice summary',
        success: 'Invoice sent successfully!',
        send: 'Send',
        cancel: 'Cancel',
        errorTitle: 'Error with sending invoice',
        errorDescription: 'We are unable to send this invoice now. Please try again.',
    },
    cancel: {
        title: 'Cancel this invoice?',
        description:
            "Your customer won't be able to make the payment via the link in the generated invoice PDF once this invoice is cancelled.",
        success: 'Invoice cancelled successfully!',
        discardChanges: 'Keep invoice',
        cancel: 'Cancel invoice',
        errorTitle: 'Error with invoice cancellation',
        errorDescription:
            'We are unable to cancel this invoice now. Please try again. If this error persists, please contact us.',
    },
};

interface Props {
    invoice: InvoiceModel;
    modalState: MerchantInvoiceModalState;
    onSubmit: (email: string) => void;
    onCancel?: () => void;
    setModalState: (state: MerchantInvoiceModalState) => void;
    onClose: () => void;
}

const ViewInvoiceDialog: React.FC<Props> = ({ invoice, modalState, onSubmit, onCancel, onClose, setModalState }) => {
    const invoiceSummary = [
        {
            name: labels.invoiceNumber,
            value: invoice.invoiceNumber,
        },
        {
            name: labels.dueDate,
            value: dateUtil.formatToDateString(invoice.dueDate!),
        },
        {
            name: labels.dueAmount,
            value: currencyUtil.convertToDisplayString(
                invoice.amountTotal! - invoice.amountsPaid!,
                invoice.currencyCode!
            ),
        },
    ];

    const invoiceValidator = {
        email: validate().required().maxLength(250, maxLengthErrorMessage(250)).email(),
    };

    // resend dialog with email form ---
    const renderResendDialog = (context: any) => {

        const handleClose = () => {
            context.resetForm();
            setModalState('');
        };
        
        return (
            <Dialog
                closeButton
                onClose={handleClose}
                show={modalState === 'RESEND_INVOICE'}
                title={viewInvoiceDialogCopy.resend.title}
                footerButtons={
                    <>
                        <SubmitButton>{viewInvoiceDialogCopy.resend.send}</SubmitButton>
                        <Button onClick={handleClose}>{viewInvoiceDialogCopy.resend.cancel}</Button>
                    </>
                }
            >
                <div className='merchant-invoice-dialog-input-container'>
                    <EmailAddressField
                        mandatory
                        data-testid={'resend-invoice-email-input'}
                        name={'email'}
                        label={labels.email}
                        validateOnChange
                    />
                </div>

                <CardContainer
                    header={
                        <h2 className='merchant-invoice-dialog-input-header'>
                            {viewInvoiceDialogCopy.resend.subtitle}
                        </h2>
                    }
                >
                    <DescriptionList items={invoiceSummary} />
                </CardContainer>
            </Dialog>
        );
    };

    // onSubmit ---
    const onSubmitButton = (values: any, context: any) => {
        context.validateField('email', values['email']);
        onSubmit(values['email']);
    };

    return (
        <>
            {/* Resend invoice */}
            <Form
                name={'test'}
                initialValidation={invoiceValidator}
                initialValues={{ email: invoice?.customer?.email }}
                {...{ onSubmit: onSubmitButton, render: renderResendDialog }}
            />

            {/* Resend error */}
            <Dialog
                closeButton
                icon={<Icon alert />}
                onClose={onClose}
                show={modalState === 'RESEND_ERROR'}
                title={viewInvoiceDialogCopy.resend.errorTitle}
            >
                <p>{viewInvoiceDialogCopy.resend.errorDescription}</p>
            </Dialog>

            {/* Cancel invoice */}
            <Dialog
                closeButton
                icon={<Icon alert />}
                onClose={() => setModalState('')}
                show={modalState === 'CANCEL_INVOICE'}
                title={viewInvoiceDialogCopy.cancel.title}
                footerButtons={
                    <>
                        <Button onClick={onCancel}>{viewInvoiceDialogCopy.cancel.cancel}</Button>
                        <Button onClick={() => setModalState('')}>{viewInvoiceDialogCopy.cancel.discardChanges}</Button>
                    </>
                }
            >
                <p>{viewInvoiceDialogCopy.cancel.description}</p>
            </Dialog>

            {/* Cancel error */}
            <Dialog
                closeButton
                icon={<Icon alert />}
                onClose={onClose}
                show={modalState === 'CANCEL_ERROR'}
                title={viewInvoiceDialogCopy.cancel.errorTitle}
            >
                <p>{viewInvoiceDialogCopy.cancel.errorDescription}</p>
            </Dialog>

            {/* Resend success */}
            <Dialog
                closeButton
                icon={<Icon tick />}
                onClose={onClose}
                show={modalState === 'RESEND_SUCCESS'}
                title={viewInvoiceDialogCopy.resend.success}
                autoClose={3}
            ></Dialog>

            {/* Cancel success */}
            <Dialog
                closeButton
                icon={<Icon tick />}
                onClose={onClose}
                show={modalState === 'CANCEL_SUCCESS'}
                title={viewInvoiceDialogCopy.cancel.success}
                autoClose={3}
            ></Dialog>
        </>
    );
};

export default ViewInvoiceDialog;
