import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '@premier/ui';
import { SubmitButton, FormDialog, PasswordField, validate } from '@premier/form';
import labels from 'constants/labels';

/**A FormDialog which takes in a user's current password, and returns the password in the onSubmit  */
const ConfirmPasswordModal =({
    name, title, message,//data
    show, isProcessing, //logic render
    onClose, onSubmit //functions
}) => {

    function handleSubmit(values, context) {
        onSubmit(values, context)
    }

    if (!message) 
        message = 'Enter your password to proceed'

    return (<> 
        <FormDialog
            name={name || 'confirmPasswordModal'}
            title={title || 'Confirm password'}
            closeButton
            className='confirm-password-modal'
            initialValues={{ currentPassword: '' }}
            initialValidation={{ currentPassword: validate().required() }}
            show={show}
            onSubmit={handleSubmit}
            onClose={onClose}
            renderButtons={context => (<>
                <SubmitButton primary disabled={isProcessing}>
                    Confirm
                </SubmitButton>
                <Button onClick={onClose}>Cancel</Button>
            </>)}
        >
            <p>{message}</p>
            <PasswordField className='password-field' name='currentPassword' label={labels.loginPassword} labelText='Current password' />
        </FormDialog>
    </>)
}

ConfirmPasswordModal.propTypes = {

    /**form dialog name */
    name: PropTypes.string,
    /**Title of the modal */
    title: PropTypes.string,
    /**Message to be displayed in the modal */
    message: PropTypes.string,

    /**shows / hides the modal */
    show: PropTypes.bool.isRequired,
    /**passed in to make the submit button disabled (i.e. for processing of an api call) */
    isProcessing: PropTypes.bool,
    
    /**funciton to run with submitting the form*/
    onSubmit: PropTypes.func.isRequired,
    /**funciton to run with closing the form*/
    onClose: PropTypes.func.isRequired,
}

export default ConfirmPasswordModal;