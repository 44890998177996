import React, { useState, useEffect } from 'react';
import { uniqueId } from '@premier/utils/helpers';
import { Checkbox } from '@premier/ui';
import { UiOption } from 'models';
import withSelectorGroup from './withSelectorGroup';

type Props<TValue extends string | number> = {
    /** The checkbox group name (Default = chkgroup_{randomId}) */
    name?: string,
    /** options object of each checkbox item, including a label and value **/
    options: UiOption<TValue>[],
    /** Adds the checkbox of HasAll if it's existing **/
    hasAllOption?: boolean,
    /** The selected values (making this a controlled component) */
    value?: (string | number)[],
    /** The initially selected values (optional if uncontrolled, not applicable if controlled) */
    defaultValues?: (string | number)[],
    onChange?: (selectedValues: (string | number)[]) => void,

    // Also see SelectorGroupComponentProps in withSelectorGroup.tsx
};

/** A group of checkboxes, with an option to select all */
const CheckboxGroup = <TValue extends string | number>({
    name,
    options, hasAllOption, value, defaultValues, onChange,
}: Props<TValue>) => {

    const [groupName] = useState(name || uniqueId(`chkgroup_`));
    const [selectedValues, setSelectedValues] = useState(defaultValues || []);

    useEffect(() => {
        if(value)  // Note: This seems to cause selectedValues not reset (on Form reset) unless the initialValue is truthy
            setSelectedValues(value);
    }, [value]);

    function handleAllChange() {
        var values: TValue[];

        if (selectedValues.length === options.length) {
            values = [];
        }
        else {
            values = options.map(o => o.value);
        }

        setSelectedValues(values);
        onChange && onChange(values);
    }

    function handleChange(value: TValue) {
        var values = [...selectedValues];

        if (!values.includes(value)) {
            values.push(value);
        } else {
            var index = values.indexOf(value);
            if (index > -1) {
                values.splice(index, 1)
            }
        }

        setSelectedValues(values);
        onChange && onChange(values);
    }


    // ========== Render ==========

    if (!options) {
        return null;
    }

    return (<>
        {hasAllOption && (
            <Checkbox
                name={`${groupName}_all`}
                value=''
                checked={selectedValues.length === options.length}
                onChange={() => {handleAllChange()}}
            >
                All
            </Checkbox>
        )}

        {options.map(o => (
            <Checkbox key={o.value}
                name={groupName}
                value={o.value}
                disabled={o.disabled}
                checked={selectedValues.includes(o.value)}
                onChange={() => {handleChange(o.value)}}
            >
                {o.label}
            </Checkbox>
        ))}
    </>);

};

export default withSelectorGroup(CheckboxGroup, 'group');
