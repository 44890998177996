import httpRequest from './httpRequest';
import mapErrors, { ApiResult, mapResultErrors } from './mapErrors';
import { config, resultHandler } from './util';
import { BatchRequestsApiFactory, GetBatchRequestsRequest, PaginatedListBatchFileInfo, PaginateRequestGetBatchRequestsRequestBatchFileStatusSortFieldsSortFieldEnum as SortField } from '@premier/webapi-client';

const api = BatchRequestsApiFactory(config);
class BatchRequestsApi {

    static uploadBatch(files: FileList): Promise<unknown> {
        // Note: API doesn't currently support correct generation of OpenAPI for file uploads
        return new Promise((resolve, reject) => {
            httpRequest
                .postFiles('/request/batch/upload', files, {})
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(mapErrors(err));
                });
        });
    }

    static getBatches(
        resultsPerPage = 10,
        pageIndex = 0,
        filter: GetBatchRequestsRequest = {},
        sort: { field: SortField | 'approvedRecordCount', descending: boolean}
    ): Promise<ApiResult<PaginatedListBatchFileInfo>> {
        return api.batchRequestsGetBatchFiles({
                request: filter,
                sortField: sort.field === 'approvedRecordCount' ? 'ApprovedCount' : sort.field,
                order: sort.descending ? 'DESCENDING' : 'ASCENDING',
                pageIndex: pageIndex,
                pageSize: resultsPerPage,
            })
            .then(...resultHandler)
            .then((r) => mapResultErrors(r));
    }

}
export default BatchRequestsApi;
