import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { RadioGroup } from '@premier/ui';
import FormGroup from './FormGroup';
import withField from './withField';

/** This renders multiple radio buttons (from options prop) */
const RadioField = ({
    name,
    options,  // RadioField specific props
    description,
    inline,
    formProps,
    groupProps, ...otherProps
}) => {

    const [id] = useState(_.uniqueId(`${name}_`));

    function handleChange(e) {
        formProps.setValue(e.value);
    }

    return (
        <FormGroup fieldId={id} {...groupProps}>
            {description && <p>{description}</p>}
            <RadioGroup
                name={name}
                options={options}
                onChange={handleChange}
                selectedValue={formProps.value}
                ariaLabelledBy={groupProps.label && `${id}_label`}
                inline={inline}
                {...otherProps}
            />
        </FormGroup>
    );
};

RadioField.propTypes = {
    /** The radio group name. You can only select one radio in a group. */
    name: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
            disabled: PropTypes.bool
        })
    ).isRequired,

    /** The label for the group (above the radio buttons) */
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    /** Not to use labels and error messages */
    noLabels: PropTypes.bool,
    /** If noLabels, specify the label here for accessibility */
    ariaLabel: PropTypes.string,
    onChange: PropTypes.func,
    /** Description shows below the label */
    description: PropTypes.string,
    inline: PropTypes.bool,
};

export default withField(RadioField);
