import { ReactNode } from "react";
import { LinkProps } from "react-router-dom";
import { ButtonProps } from "./Button";

export const DEFAULT_SELECTED_ITEMS = [];

export type ResponsiveTableRecordPartial = {
    // This can contain anything (even nested object) plus these optional props:

    /** Disable batchAction on this row/card */
    noBatchActions?: boolean;
    className?: string;
}

export type ResponsiveTableColumn<TRecord extends ResponsiveTableRecordPartial> = {
    /** The column header label (or item label in CardList) */
    label: string;
    /** A key of TRecord (eg. 'startDate') or a function, eg. (item) => formatDate(item.startDate) */
    getter: keyof TRecord & string | ((item: TRecord, itemIndex?: number) => ReactNode);
    /** What will be sent to the API as the sort field (eg. 'address.suburb') */
    sortKey?: string;
    /** The class for th + td (on desktop view) and dt (on mobile view) */
    className?: string;
    showAsLink?: boolean;
    /** Table view only */
    textAlign?: "center" | "right";
    filter?: ReactNode;
}

export type ResponsiveTableBatchAction<TRecord extends ResponsiveTableRecordPartial> = {
    /** The button label (eg. 'Delete') */
    label: ReactNode;
    /** Triggered on button click, eg. (selectedItems) => { console.log(selectedItems) } */
    handleClick: (selectedItems: TRecord[]) => void;
    /** (Table view only) The button label under the table, if different than the card button label (eg. 'Delete selected rows') */
    batchLabel?: ReactNode;
    /** (Table view only) This message is shown when you click the button without selecting any row (Default = 'Please select a row') */
    pleaseSelectRowText?: ReactNode;
    /** (Table view only) The props for the button under the table (eg. label, type, className, etc) */
    tableButtonProps?: ButtonProps[] | { primary: boolean };
    /** batch button will be disabled when no item selected  */
    disabled?: (selectedItems: TRecord[]) => boolean;
    /** show warnings when no item selected  */
    selectionWarnings?: boolean;
}

/** Props of ResponsiveTable that are used on both CardList (mobile view) and Table (desktop view) */
export type ResponsiveTableSharedProps<TRecord extends ResponsiveTableRecordPartial> = {
    data: TRecord[];
    columns: ResponsiveTableColumn<TRecord>[];

    /** Applies the 'highlightTotal' style */
    highlightTotal?: boolean;

    /** The current sort order. Required if sortable (ie. sortKey is set on at least 1 column). */
    sort?: {
        field: string;
        descending: boolean;
    };
    /** eg. handleSort(sortKey, isDescending) where sortColumn is the sortKey. Required if sortable (ie. sortKey is set on at least 1 column). */
    onSort?: (sortKey: string, isDescending: boolean) => void;

    /** A function to generate a link for a row/card. eg. rowItem => rowItem.url (where rowItem would be an element from the data array) */
    getRowLink?: (item: TRecord) => LinkProps["to"] | string;
    onRowLinkClick?: (item: TRecord) => void;
    /** Whether the row link should open in a new tab */
    rowLinkNewWindow?: boolean;

    /** Make rows selectable by adding checkboxes on the left */
    selectable?: boolean;
    /** Makes only a single row selectable at a time (ie. radio buttons on Table view instead of checkboxes) */
    singleSelect?: boolean;

    /** Renders a loading spinner when true */
    isLoading?: boolean;
    /** What to display when there is no data */
    noDataText?: ReactNode;

    /** On table, show a checkbox on each row + button(s) below the table. On card list, show button(s) on top of each card. */
    batchActions?: ResponsiveTableBatchAction<TRecord>[];

    /** Triggered when a row is selected */
    onItemSelected?: (item: TRecord, selectedItems: TRecord[]) => void;

    // Props generated by ResponsiveTable as they are used on both Table and CardList
    selectedItems?: TRecord[];
    handleItemSelected?: (item: TRecord) => void;
}
