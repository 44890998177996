import React, { useState } from 'react';
import PropTypes from 'prop-types';

import text from 'constants/text';
import { maxBillers } from 'constants/billpay';

import { PaddedContainer, Link, IconText } from '@premier/ui';
import { CreateBillerDialog } from '../..';

import './BillerListContainer.scss';

/** A PaddedContainer with a list of billers and their active status, with an option to add a new biller. */
const BillerListContainer = ({
    billers, description, instruction, //data
    allowAdd, showContact, //conditional rendering
    generateLink, //function
    children
}) => {

    const [showCreateBillerDialog, setShowCreateBillerDialog] = useState(false);

    return (
        <div className='biller-list-container'>
            <PaddedContainer>
                <h3>{`You have ${billers.length} biller ${billers.length > 1 ? 'codes to be updated for branding' : 'code'}`}</h3>

                <div className='biller-list-description'>
                    {description}
                </div>

                {billers.map((b, idx) => (
                    <Link to={generateLink(b)} className='biller' key={b.billerCode}>
                        <PaddedContainer greyBorder lessMargin>
                            <div className='biller-status-and-code'>
                                {b.enabled
                                    ? <IconText tick>Active</IconText>
                                    : <IconText info>Inactive</IconText>
                                }
                                <b>Biller code {b.billerCode}</b>
                            </div>
                            {b.billName}
                            {idx === 0 && instruction && (<>{' '}&mdash; {instruction}</>)}
                        </PaddedContainer>
                    </Link>
                ))}

                {allowAdd && billers.length < maxBillers && (
                    <PaddedContainer
                        className='add-biller'
                        blueBorder lessMargin
                        onClick={() => setShowCreateBillerDialog(true)}
                    >
                        <IconText plus> <b>Add a biller code</b></IconText>
                    </PaddedContainer>

                )}

                {children}
            </PaddedContainer>

            {allowAdd && billers.length >= maxBillers && (<>
                {showContact && (
                    <PaddedContainer blueBorder lessMargin>
                        <p><b>Need more biller codes?</b></p>
                        <p>Call {text.platformName} support team on <a href={text.supportPhoneLink}>{text.supportPhoneNumber}</a> or <a href={`mailto:${text.supportEmail}`}>{text.supportEmail}</a></p>
                    </PaddedContainer>
                )}
                {!showContact && (
                    <IconText info>You have reached the maximum number of biller code</IconText>
                )}
            </>)}



            <CreateBillerDialog
                show={showCreateBillerDialog}
                onClose={() => setShowCreateBillerDialog(false)}
            />
        </div>
    );
};

BillerListContainer.propTypes = {
    billers: PropTypes.array.isRequired,

    /** The main description under the component title */
    description: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),

    /** Shown next to the biller name of the first biller */
    instruction: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),

    /** Shows the 'Add new biller' / 'Need new biller?' section */
    allowAdd: PropTypes.bool,

    /** When they have reached the max number of billers, whether to show contact info to add more billers (Set this depending on merchant type?) */
    showContact: PropTypes.bool,

    /** eg. (biller) => settingsRoute.billerDetails.generatePath(biller.billerCode) */
    generateLink: PropTypes.func.isRequired,
}

export default BillerListContainer;