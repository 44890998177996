import { Button, Dialog } from "@premier/ui";
import { FaExclamationTriangle } from "react-icons/fa";

import { FilenameSubheading } from "components/Batch";

type Props = {
    show: boolean;
    filenames: string[];
    onCancel: () => void;
    onProcess: () => void;
}

const ProcessBatchWarningDialog = ({
    show, //logic render
    filenames, //data
    onCancel, onProcess //functions
}: Props) => {

    const multipleFiles = filenames && filenames.length > 1;

    return (
        <Dialog show={show}
            title={`Process ${multipleFiles ? "these" : "this"} batch file${multipleFiles ? "s" : ""}?`}
            icon={<div><FaExclamationTriangle size={48} color="orange" /> </div>}

            footerButtons={<>
                <Button primary onClick={onProcess}>Process</Button>
                <Button onClick={onCancel}>Cancel</Button>
            </>}
        >
            <FilenameSubheading filenames={filenames} isListAllFiles = {true}  />

            <p>{`Duplicate record warning. ${multipleFiles ? "These" : "This"} batch file${multipleFiles ? "s" : ""} contain${multipleFiles ? "" : "s"} the same number of records and value, already uploaded in the last 30 days.`}</p>

        </Dialog>

    );
};

export default ProcessBatchWarningDialog;