import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Currency } from '@premier/ui';
import FormGroup from './FormGroup';
import withField from './withField';

const CurrencyField = props => {
    const {
        name, onChange,
        formProps, groupProps, ...otherProps
    } = props;

    const { value, error, setValue } = formProps;

    function handleChange(val) {
        setValue(val);

        if (onChange)
            onChange(val);
    }

    const [id] = useState(_.uniqueId(`${name}_`));

    return (
        <FormGroup fieldId={id} {...groupProps}>
            <Currency
                name={name}
                id={id}
                value={value}
                error={error}
                onChange={handleChange}
                aria-labelledby={groupProps.label && `${id}_label`}
                aria-describedby={`${id}_currencyCode`}
                aria-required={groupProps.mandatory}
                aria-invalid={!!formProps.error}
                {...otherProps}
            />
        </FormGroup>
    );
};

CurrencyField.propTypes = {
    /** The default is the merchant's currency alphaCode, eg. 'AUD'. Not shown if empty string or if compact is true. */
    currencySymbol: PropTypes.string,
    /** The default is like '0.00' depending on merchant currency */
    placeholder: PropTypes.string,
    /** Do not show currencySymbol, for quick filter */
    compact: PropTypes.bool,

    onChange: PropTypes.func,
    onFocus: PropTypes.func
};

export default withField(CurrencyField);
