import React, { CSSProperties, ReactNode } from 'react';
import classNames from 'classnames';
import './TooltipTrigger.scss';


export type TooltipTriggerProps = {
    /** The tooltip content for a simple text tooltip. Required unless tipId is present (otherwise ignored). */
    tip?: string,
    /** The Tooltip ID for a separate complex Tooltip */
    tipId?: string,
    className?: string,
    /** This is the element (eg. a question-mark icon) that will show a Tooltip when hovered. */
    children?: ReactNode,
    style?: CSSProperties,
};


/** A span which children (eg. icon) will show a Tooltip when hovered. See Tooltip storybook for usage. */
const TooltipTrigger = ({
    tip = '', tipId,
    className, children, ...otherProps
}: TooltipTriggerProps) => {

    return (
        <span data-tip={tip} data-for={tipId}
            className={classNames('tooltip-trigger', className)}
            tabIndex={0}
            {...otherProps}
        >
            {children}
        </span>
    );
};

export default TooltipTrigger;
