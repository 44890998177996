import { Button, Dialog } from "packages/ui";
import { Field, FormikProvider, useFormik } from "formik";
import { FormGroup, TextArea } from "packages/formik-ui";
import declineApi from "api/declineApi";
import errorUtil from "packages/utils/error";

import "./DeclinesResolveTransactionDialog.scss";

type Props = {
    trayId: number;
    txnId: number;
    show: boolean;
    onClose: (showSuccessMessage: boolean) => void;
};

type FormValues = {
    comments: string;
};

const DeclinesResolveTransactionDialog = ({ trayId, txnId, show, onClose }: Props) => {
    const formik = useFormik<FormValues>({
        initialValues: {
            comments: ""
        },
        onSubmit: async (formValues: FormValues) => {
            const response = await declineApi.resolveTxn(trayId, txnId, formValues.comments);

            if (response.status === 200) {
                onClose(true);
            } else if (response.data.errors) {
                const errors = response.data.errors ? errorUtil.convertApiErrorsToFormikErrors<FormValues>(response.data.errors) : null;

                if (errors) {
                    formik.setErrors(errors);
                }
            }
        }
    });

    const handleCancelClick = (e?: React.MouseEvent) => {
        e?.preventDefault();
        formik.resetForm();
        onClose(false);
    }

    return <Dialog title="Resolve transaction" show={show} onClose={() => handleCancelClick()} closeButton>
        <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit}>
                <FormGroup name="comments" label="Comments">
                    <Field name="comments" as={TextArea} resize={false} rows={10} />
                </FormGroup>
                <div className="resolve-transaction-form-actions">
                    <Button type="submit" disabled={formik.isSubmitting} primary>Resolve transaction</Button>
                    <Button type="submit" onClick={handleCancelClick}>Cancel</Button>
                </div>
            </form>
        </FormikProvider>
    </Dialog>;
};

export default DeclinesResolveTransactionDialog;
