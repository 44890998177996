import React, {useState} from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import textUtil from '@premier/utils/text';
import currencyUtil from '@premier/utils/currency';
import { Divider } from '@premier/ui';
import { QuantityController, ProductThumbnail } from 'components/StationeryShop';

import * as StationeryShopActions from 'components/StationeryShop/_actions/stationeryShopActions';

import './StationeryItem.scss';
 
const StationeryItem = ({ 
    item, //data
    actions //API actions
}) => {

    var [value, setValue] = useState(item.minOrderQuantity || 1);
    
    function handleCartButton(qty) {
        actions.addToCart(item.productId, qty, item.maxOrderQuantity);
    }

    function updateQuantity(qty) {
        setValue(qty)
    }

    return (
        <>
            <div className="shop-item-container">
                <div className="item-image"><ProductThumbnail large src={item.productImage} altText={''}></ProductThumbnail></div>
                <div className="item-description">
                    <div className='product-name-and-code'>{item.productName}</div>
                    <div className='product-cost'>{currencyUtil.convertToDisplayString(item.itemCost)}</div>
                    {textUtil.newlineToBr(item.productDescription)}
                </div>
                <div className='quantity-controller'>
                    <QuantityController 
                        value={value} 
                        minQuantity={item.minOrderQuantity} 
                        maxQuantity={item.maxOrderQuantity} 
                        onCartButtonPress={handleCartButton} 
                        unitPrice={item.itemCost}
                        onQtyUpdate={updateQuantity}
                    />
                </div>
            </div>
            <Divider />
        </>
    );
}

StationeryItem.propTypes = {
    item: PropTypes.object.isRequired,
}; 

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(StationeryShopActions, dispatch),
    };
}

export default connect(null, mapDispatchToProps)(StationeryItem);