import { Dialog, Icon, Button, Divider } from "@premier/ui";
import { TokenDetailsList } from "components/DataVault";
import { ScheduleDetailsDescriptionList } from "components/Transactions";
import "./ConfirmScheduleDialog.scss";
import { Biller } from "models/Biller";
import { Token } from "models/Token";
import { Schedule } from "models/Schedule";

type Props = {
    biller: Biller;
    /** property to display or hide modal */
    show: boolean;
    /** property to display either confirm or success variant */
    isSuccessful: boolean;
    isSubmitting: boolean;
    /** property for Schedule details to display */
    schedule: Schedule;
    /** property for Payment Method details to display */
    paymentMethod: Token | null;
    /** property for schedule frequency options */
    scheduleFrequencies: any;
    /** function called when the Submit button is clicked */
    onSubmit: () => void;
    /** function called when the Back button is clicked */
    onCancel: () => void;
}

/** A Modal to confirm a Schedule's details before it is created. E.g. for the New Schedule Page */
const ConfirmScheduleDialog = ({
    biller,
    show, isSuccessful, isSubmitting, //logic renders
    schedule, paymentMethod, scheduleFrequencies, //data
    onSubmit, onCancel,
}: Props) => {
    return (
        <Dialog
            show={show}
            icon={isSuccessful ? <Icon tick /> : null}
            title={isSuccessful ? "Scheduled" : "Confirm subscription"}
            dialogClassName="confirm-schedule-modal"
            footerButtons={<>
                <Button primary onClick={onSubmit} loading={isSubmitting}>{isSuccessful ? "Start new subscription" : "Submit"}</Button>
                <Button default onClick={onCancel}>{isSuccessful ? "Thanks, I'm done" : "Back"}</Button>
            </>}
        >
            <h2>Subscription details</h2>
            <ScheduleDetailsDescriptionList schedule={schedule} scheduleFrequencies={scheduleFrequencies} biller={biller} />
            <Divider />
            <h2>Payment method</h2>
            <TokenDetailsList token={paymentMethod} />

            <Divider />
        </Dialog>
    );
};

export default ConfirmScheduleDialog;
