import React, { useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';

import { Modal } from '@premier/ui';
import { ImageFromUrl } from 'components/Common'
import { NewPaymentResultModal } from 'components/Transactions'

import * as paymentActions from 'components/Transactions/_actions/paymentActions';
import * as paymentRequestActions from 'components/PaymentRequests/_actions/paymentRequestActions';
import { Dialog, Icon, Button, SuccessModal } from '@premier/ui';

import { FormError, useInterval } from 'components/Common';
import { paymentRequestApi } from 'api';

import './QrPaymentRequestPendingModal.scss'

const REQUEST_POLLING_INTERVAL = 5000;
const REQUEST_STATUS = Object.freeze({
    PENDING: "pending",
    CANCELLED:  "cancelled",
    COMPLETED: "completed"
  });

/** Dialog that displays the status of a payment request with a timer */
const QrPaymentRequestPendingModal = ({
    paymentRequestId, txnHistoryId, paymentReceipt, biller, //data
    actions, paymentActions,//API actions
    cancelErrors, //state
    handleModalClose //functions
}) => {
    const [requestStatus, setRequestStatus] = useState(REQUEST_STATUS.PENDING);
    const [showConfirmCancel, setConfirmCancel] = useState(false);

    // Use the lastTxnHistoryId from the request to get the full transaction details for the receipt
    useEffect(() => {
        if (txnHistoryId)
            paymentActions.getTransactionDetails(txnHistoryId);
    }, [txnHistoryId]);

    useEffect(() => {
        if (paymentReceipt)
            setRequestStatus(REQUEST_STATUS.COMPLETED);
    }, [paymentReceipt]);

    async function checkRequestStatus() {
        if (requestStatus === REQUEST_STATUS.PENDING)
            await actions.search(1, 0, {guid: paymentRequestId, qrCode: true}, {});
    }

    async function handleConfirmCancel() {
        try {
            await actions.cancel(paymentRequestId);
            setRequestStatus(REQUEST_STATUS.CANCELLED);
        } catch(err) {
        }
    }

    // Periodically check if the request has been completed by the customer
    // TODO: Refactor constant polling of the internal API to find info about transactions.
    useInterval(() => {
        checkRequestStatus();
    }, requestStatus === REQUEST_STATUS.PENDING ? REQUEST_POLLING_INTERVAL : null);

    if (requestStatus === REQUEST_STATUS.COMPLETED)
        return <Modal show onHide={() => handleModalClose(paymentReceipt.responseCode === '0', true)}>
            <NewPaymentResultModal receipt={paymentReceipt} biller={biller} onClose={handleModalClose} />
        </Modal>

    if (requestStatus === REQUEST_STATUS.CANCELLED)
        return <SuccessModal
            show={true}
            title='Payment cancelled successfully'
            onClose={() => handleModalClose(false, true)}
        />

    if (showConfirmCancel)
        return <Dialog
            icon={<Icon alert/>}
            title='Cancel payment?'
            show={true}
            footerButtons={<>
                <Button onClick={handleConfirmCancel}>Confirm</Button>
                <Button onClick={() => setConfirmCancel(false)}>Back</Button>
            </>}>
            Please confirm if you want to cancel this payment
            <FormError apiErrors={cancelErrors} />
        </Dialog>

    return <Dialog
        dialogClassName='qr-request-pending-dialog'
        title='QR code for scan and pay'
        show={true}
        footerButtons={<>
            <Button onClick={() => setConfirmCancel(true)}>Cancel payment</Button>
        </>}>
        <p>
            Ask your customer to scan the following QR code to initiate the payment. Once the payment is made successfully, a confirmation
            will be shown.
        </p>
        <ImageFromUrl url={paymentRequestApi.getQrCodeEndpoint(paymentRequestId)} altText="QRCode" />
    </Dialog>
}

QrPaymentRequestPendingModal.propTypes = {
    paymentRequestId: PropTypes.string.isRequired,
    biller: PropTypes.object,
    handleModalClose: PropTypes.func
};

function mapStateToProps(state, ownProps) {
    const searchResult = state.paymentRequest.searchResult.data;
    let txnHistoryId = null;
    if (searchResult
        && searchResult.requests
        && searchResult.requests.length === 1
        && _.get(searchResult.requests[0], 'status.key') !== "Unpaid"
        && searchResult.requests[0].guid === ownProps.paymentRequestId) {
        txnHistoryId = searchResult.requests[0].lastTxnHistoryId;
    }

    const paymentReceipt = txnHistoryId ? _.get(state.transactions.payments.transactionDetails, 'paymentProcessedTxnModel') : null;

    return {
        cancelErrors: state.paymentRequest.cancel.errors,
        txnHistoryId,
        paymentReceipt,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(paymentRequestActions, dispatch),
        paymentActions: bindActionCreators(paymentActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(QrPaymentRequestPendingModal);