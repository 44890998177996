import React from 'react';
import PropTypes from 'prop-types';

import currencyUtil from '@premier/utils/currency';
import { Modal, Icon, DescriptionList, Button } from '@premier/ui';

const SurchargeConfirmationStep = ({
    surchargeInfo, //data
    onConfirm, onCancel //functions
}) => {
    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>
                    <Icon info />
                    Surcharge applied
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p style={{marginBottom: '2rem'}}>A surcharge applies for this payment</p>

                <DescriptionList spaceBetween items={[
                    { name: `Amount (${currencyUtil.currency.alphaCode})`, value: currencyUtil.formatApiValue(surchargeInfo.originalAmount) },
                    { name: `Surcharge amount (${currencyUtil.currency.alphaCode})`, value: currencyUtil.formatApiValue(surchargeInfo.surchargeAmount) },
                    { name: `Total Amount (${currencyUtil.currency.alphaCode})`, value: currencyUtil.formatApiValue(surchargeInfo.totalAmount) },
                ]} />
            </Modal.Body>

            <Modal.Footer buttons>
                <Button primary onClick={onConfirm}>Confirm payment</Button>
                <Button onClick={onCancel}>Cancel</Button>
            </Modal.Footer>
        </>
    );
}

SurchargeConfirmationStep.propTypes = {
    payment: PropTypes.object,
    surchargeInfo: PropTypes.object
};

export default SurchargeConfirmationStep;