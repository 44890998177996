import React from 'react';
import classNames from 'classnames';
import loadingIndicator from 'images/activity-indicator.svg';
import './LoadingIndicator.scss';


type Props = {
    inline?: boolean,
    horizontal?: boolean,
    hidden?: boolean,
};

const LoadingIndicator = ({inline, horizontal, hidden}: Props) => {
    return (
        <div className={classNames('loading-indicator-container', {
            'inline': inline,
            'horizontal': horizontal,
            'hidden': hidden
        })}>
            {horizontal && <>
                <div className='indicator-1'></div>
                <div className='indicator-2'></div>
                <div className='indicator-3'></div>
            </>}
            {!horizontal &&
                <img src={loadingIndicator} className='loading-indicator' alt='Loading...' />
            }
        </div>
    );
}

export default LoadingIndicator;
