import React from 'react';
import { PageSection, Link, Icon, ButtonContainer, Button } from '@premier/ui';
import { PromotionCard, PromotionBoxesPageSection } from 'components/Common';

import { PlatformRoutesConfiguration } from 'components/Routing';

const promotions = [
    <>
        <Icon plusCircled />
        <h2>Add payment method</h2>
        <p>Add card or bank account details and save it securely as a token.</p>
    </>,
    <>
        <div>
            <Icon share />
            <Icon person />
        </div>
        <h2>Link to a customer</h2>
        <p>Link one or more payment methods to your customers.</p>
    </>,
    <>
        <Icon money />
        <h2>Track payments</h2>
        <p>Keep track on each customer's past and future payment activities.</p>
    </>
];

function TokensGettingStarted({
    onClickImport
}) {
    return <>
        <PromotionBoxesPageSection
            noDivider
            title='Payment methods'
            subText='Store and manage payment methods in a secure environment for future and recurring payments.'
            promotions={promotions}
        >
            <ButtonContainer justify>
                <Link button to={PlatformRoutesConfiguration.tokenRoute.newToken.generatePath()}>Add payment method</Link>
                <Button onClick={onClickImport}>Import tokens</Button>
            </ButtonContainer>
        </PromotionBoxesPageSection>

        <PageSection>
            <PromotionCard
                blueBorder
                badge='New'
                title='Understand your customers better'
                subText={`Create a customer profile before adding payment methods so you can learn and keep track on your customer's payment activities.`}
                buttons={
                    <Link primary newWindow to={PlatformRoutesConfiguration.customerRoute.root.generatePath()}>Go to Customers</Link>
                }
            />
        </PageSection>
    </>;
}

export default TokensGettingStarted;