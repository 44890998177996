import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
    searchInvoiceModel,
    getInvoiceStatusSources,
} from '../_actions/customerInvoiceActions';
import * as paramUtil from '@premier/utils/param';
import SecurityUtil from '@premier/utils/security';
import { userRoles } from 'components/Routing';
import {
    PaginateRequestInvoiceSearchInputModelInvoiceSearchSortFieldsSortFieldEnum as SortFieldEnum,
    PaginateRequestInvoiceSearchInputModelInvoiceSearchSortFieldsOrderEnum as OrderEnum,
    PaginateRequestInvoiceSearchInputModelInvoiceSearchSortFields as SearchRequest,
} from 'packages/webapi-client';

// @ts-ignore
import { defaultPageSize } from 'constants/billpay';

const defaultSearchRequest: SearchRequest = {
    pageIndex: 0,
    pageSize: defaultPageSize,
    sortField: 'InvoiceNumber',
    order: 'DESCENDING',
    request: undefined,
};

const customerInvoiceSelector = (state: any) => state.dataVault.invoice.invoices;
const invoiceStatusSourceSelector = (state: any) => state.dataVault.invoice.invoiceStatusSource;
const authenticatedUserSelector = (state: any) => state.accounts.users.authenticatedUser;

const useCustomerInvoice = () => {
    const dispatch = useDispatch();
    const { customerId } = useParams<{ customerId: string }>();

    const authenticatedUser = useSelector(authenticatedUserSelector);
    const { isLoading, hasFailed, data: settings, errors } = useSelector(customerInvoiceSelector);
    const { data: invoiceStatusSource } = useSelector(invoiceStatusSourceSelector);

    useEffect(() => {
        if (SecurityUtil.hasAccess([userRoles.dataVault, userRoles.invoices], authenticatedUser)) {
            var searchRequest = defaultSearchRequest;

            searchRequest.request = {
                customerId: paramUtil.asInteger(customerId),
            };

            // search invoices
            dispatch(searchInvoiceModel(searchRequest));
            // invoice status
            dispatch(getInvoiceStatusSources());
        }
    }, [authenticatedUser, customerId, dispatch]);

    const changeFilters = useCallback(
        (
            pageIndex: number,
            sortField: SortFieldEnum,
            order: OrderEnum,
            request: any
        ) => {
            const r = {
                ...defaultSearchRequest,
                pageIndex,
                sortField,
                order,
                request: { ...defaultSearchRequest.request, ...request },
            };

            if (SecurityUtil.hasAccess([userRoles.dataVault, userRoles.invoices], authenticatedUser)) {
                dispatch(searchInvoiceModel(r));
            }
        },
        [dispatch, authenticatedUser]
    );

    return {
        customerId,
        isLoading,
        hasFailed,
        errors,
        settings:
            settings?.list[0]?.customerId === paramUtil.asInteger(customerId)
                ? settings
                : undefined,
        invoiceStatusSource,
        changeFilters,
    };
};

export default useCustomerInvoice;
