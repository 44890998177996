import * as actions from '../_actions/actionTypes';
import { LOAD_BILLERS as LOAD_BILLERS_ACTION } from '../../Settings/_actions/billerActionTypes';
import { UPDATE_MESSAGING_SETTINGS } from '../../Settings/_actions/actionTypes';
import _ from 'lodash';

export default function accountReducer(state = {}, action) {
    switch (action.type) {
        case actions.LOGIN_USER_SUCCESS:
            return Object.assign({}, state, {
                requiredUserAction: action.userDetails.requiredUserAction,
                authenticatedUser: action.userDetails.user,
                merchant: action.userDetails.merchant,
                userMenu: action.userDetails.userMenu,
                billers: action.userDetails.billers,
                activeBillers: _.filter(action.userDetails.billers, b => b.enabled),
            });

        case actions.LOGOUT_USER_SUCCESS:
            return {
                asyncCount: state.asyncCount,
                isLoading: state.isLoading,
                billers: [],
                configuration: { legacyBackOfficeUrl: state.configuration?.legacyBackOfficeUrl}
            };

        case actions.RESET_PASSWORD_REQUESTED:
            return Object.assign({}, state, {
                resetPasswordSuccess: false
            });

        case actions.RESET_PASSWORD_SUCCESS:
            return Object.assign({}, state, {
                resetPasswordSuccess: true
            });

        case actions.CHANGE_PASSWORD_REQUESTED:
            return Object.assign({}, state, {
                changePasswordSuccess: false
            });

        case actions.CHANGE_PASSWORD_SUCCESS:
            return Object.assign({}, state, {
                changePasswordSuccess: true
            });

        case actions.UPDATE_PROFILE_SUCCESS:
            return Object.assign({}, state, {
                authenticatedUser: Object.assign({}, state.authenticatedUser, action.user)
            });

        case actions.LOAD_CONFIG_SUCCESS:
            return Object.assign({}, state, {
                configuration: action.configuration
            });
        case actions.GET_BILLER_CODES_SUCCESS:
            return Object.assign({}, state, {
                selectedChildBillerCodes: action.billerCodes
            });

        case actions.GET_TERMS_SUCCESS:
            return Object.assign({}, state, {
                termsAndConditions: action.terms
            });

        case actions.GET_BUSINESS_DETAILS_SUCCESS:
            return Object.assign({}, state, {
                businessDetails: action.result
            });

        case actions.SET_BRANDING_BY_BILLER:
            return {
                ...state,
                merchant: {
                    ...state.merchant,
                    brandingByBiller: action.byBiller
                }
            };

        case LOAD_BILLERS_ACTION.SUCCESS:
            return Object.assign({}, state, {
                billers: action.data
            });

        case UPDATE_MESSAGING_SETTINGS.SUCCESS: {
            return {
                ...state,
                merchant: {
                    ...state.merchant,
                    messaging: {
                        ...state.merchant?.messaging,
                        singlePaymentReceipt: action.settings.singlePaymentReceipt ?? state.merchant?.messaging?.singlePaymentReceipt,
                        singlePaymentDecline: action.settings.singlePaymentDecline ?? state.merchant?.messaging?.singlePaymentDecline
                    }
                }
            };
        }

        default:
            return state;
    }


}