import {combineReducers} from 'redux';
import reduceReducers from 'reduce-reducers';
import asyncReducer from 'src/store/asyncReducer';
import paymentsReducer from './paymentsReducer';
import scheduleReducer from './scheduleReducer';
import scheduledPaymentReducer from './scheduledPaymentReducer';
import batchReducer from 'components/Batch/_reducers/batchReducer';

const transactionsReducers = combineReducers({
    payments: reduceReducers(paymentsReducer, asyncReducer),
    schedule: scheduleReducer,
    scheduledPayment: reduceReducers(scheduledPaymentReducer, asyncReducer),
    batch: reduceReducers(batchReducer, asyncReducer),
});

export default transactionsReducers;