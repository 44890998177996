import { useState, useEffect } from "react";
import { Dispatch, bindActionCreators } from "redux";
import { connect } from "react-redux";
import _ from "lodash";
import { Modal } from "@premier/ui";
import { NewPaymentResultModal, NewPaymentRequestResultModal } from "components/Transactions";
import { RequestPendingModal }  from "components/Common";
// @ts-ignore
import { PaymentRequestStatus } from "constants/billpay";
import * as paymentActions from "components/Transactions/_actions/paymentActions";
import * as paymentRequestActions from "components/PaymentRequests/_actions/paymentRequestActions";
import { tokenApi } from "api";
import { RootState } from "store/store";
import { Biller, PaymentProcessedTxnModel } from "packages/webapi-client";
import { Request } from "models/Request";
import { Phone } from "models";

type Props = {
    paymentRequestId: string;
    txnHistoryId?: number;
    paymentReceipt?: PaymentProcessedTxnModel;
    biller?: Biller;
    paymentRequestStatus?: string;
    request?: Request;
    customerEmail?: string;
    customerPhoneNumber?: Phone;
    actions?: any;
    paymentActions?: any;
    paymentRequestActions?: any;
    onClosed: (reset?: boolean, dismiss?: boolean, showNewPaymentRequestModal?: boolean) => void;
}

/** Dialog that displays the status of a payment request with a timer */
const PaymentRequestPendingModal = ({
    paymentRequestId, txnHistoryId, paymentReceipt, biller, paymentRequestStatus, request, customerEmail, customerPhoneNumber, //data
    actions, paymentActions, paymentRequestActions, //API actions
    onClosed //functions
}: Props) => {
    const [requestIsPending, setRequestIsPending] = useState(true);
    const [requestIsCancelled, setRequestIsCancelled] = useState(false);
    const [token, setToken] = useState();

    // Set up the timer
    useEffect(() => {
        actions.clearPaymentRequestStatus();
        setRequestIsPending(!!paymentRequestId);
    }, [paymentRequestId]);

    // Use the lastTxnHistoryId from the request to get the full transaction details for the receipt
    // This is for any request that actually requests a payment
    useEffect(() => {
        if (txnHistoryId) {
            paymentActions.getTransactionDetails(txnHistoryId);
        }
    }, [txnHistoryId]);

    // Stop timing when we have the details for the payment receipt
    useEffect(() => {
        if (paymentReceipt) {
            setRequestIsPending(false);
        }
    }, [paymentReceipt]);

    useEffect(() => {
        if (paymentRequestStatus === PaymentRequestStatus.REGISTERED) {
            setRequestIsPending(false);
            paymentRequestActions.get(paymentRequestId);
        }
    }, [paymentRequestStatus]);

    useEffect(() => {
        async function innerAsync() {
            if (request) {
                setToken(await tokenApi.getToken(request.dataVaultId));
            }
        }

        innerAsync();
    }, [request]);

    function handleOnCheckRequestStatus() {
        if (paymentRequestId)
            actions.search(1, 0, { guid: paymentRequestId }, {});
    }

    function handleOnCancel() {
        actions.cancel(paymentRequestId)
            .then(() => {
                setRequestIsCancelled(true);
            })
            .catch(() => {
                setRequestIsCancelled(false);
            });
    }

    function handleModalClose(reset?: boolean, dismiss?: boolean, showNewPaymentRequestModal = true) {
        onClosed(reset, dismiss, showNewPaymentRequestModal);
    }

    // This modal will be shown for any request that requests a payment
    if (paymentReceipt)
        return <Modal show onHide={() => handleModalClose(paymentReceipt && paymentReceipt.responseCode === "0", true, false)}>
            <NewPaymentResultModal receipt={paymentReceipt} onClose={handleModalClose} customerEmail={customerEmail} customerPhoneNumber={customerPhoneNumber} />
        </Modal>;

    // This modal will be shown for any request that does not request a payment (tokenisation only and update only requests)
    if (paymentRequestStatus === PaymentRequestStatus.REGISTERED) {
        return <Modal show onHide={() => handleModalClose(true, true, false)}>
            <NewPaymentRequestResultModal request={request} token={token} onClose={handleModalClose} />
        </Modal>;
    }

    return <RequestPendingModal
        isPending={requestIsPending}
        isCancelled={requestIsCancelled}
        onCheckRequestStatus={handleOnCheckRequestStatus}
        onCancel={handleOnCancel}
        onClose={handleModalClose}
    />;
};

function mapStateToProps(state: RootState, ownProps: Props) {
    const searchResult = state.paymentRequest.searchResult.data;
    let txnHistoryId = null;
    let paymentRequestStatus = null;

    if (searchResult
        && searchResult.requests
        && searchResult.requests.length === 1
        && searchResult.requests[0].guid === ownProps.paymentRequestId) {

        paymentRequestStatus = _.get(searchResult.requests[0], "status.key");

        if (paymentRequestStatus !== "Unpaid") {
            txnHistoryId = searchResult.requests[0].lastTxnHistoryId;
        }
    }

    const paymentReceipt = txnHistoryId ? _.get(state.transactions.payments.transactionDetails, "paymentProcessedTxnModel") : null;
    const request = state.paymentRequest.details.data;

    return {
        txnHistoryId,
        paymentReceipt,
        paymentRequestStatus,
        request
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        actions: bindActionCreators(paymentRequestActions, dispatch),
        paymentActions: bindActionCreators(paymentActions, dispatch),
        paymentRequestActions: bindActionCreators(paymentRequestActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentRequestPendingModal);
