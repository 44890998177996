import React, { ReactNode } from "react";
import { InputField, FormContext, validate } from "@premier/form";
import { CustomerReferenceNumberModel } from "packages/webapi-client";

type Props = {
    name: string;
    referenceField: CustomerReferenceNumberModel;
    defaultLabel: string;
    help?: string | ReactNode;
}

const CustomerReferenceField = ({ name, referenceField, defaultLabel, help }: Props) => {
    const context = React.useContext(FormContext);
    const label = referenceField.crnName || defaultLabel;

    React.useEffect(() => {
        context.setValidation(name, validate().requiredIf(() => referenceField.minLength && referenceField.minLength > 0, `${label} is required`));
    }, [referenceField, defaultLabel]);

    return (
        <InputField name={name} label={label} mandatory={referenceField.minLength ? referenceField.minLength > 0 : false} help={help}/>
    );
};

export default CustomerReferenceField;
