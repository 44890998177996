import React, { useContext, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { CurrencyField, Form, FormContext, InputField, DropdownField, validate } from '@premier/form';
import { Currency, IconText, ResponsiveTable } from '@premier/ui';
import { ResponsiveTableRecordPartial } from 'packages/ui/src/ResponsiveTableTypes';
import { useInvoiceSettings } from 'components/Settings/_hooks/useInvoicesSettings';
import InvoiceMaxItemsMessage from './InvoiceMaxItemsMessage';
import IsFormDirty from './IsFormDirty';
import { fieldNames as invoiceFormFieldNames } from './InvoiceForm';
import InvoiceItemsErrors from './InvoiceItemsErrors';
import { itemTemplateString, maxCurrency, replaceTemplateWithIndex } from './invoiceUtil';
import currencyUtil from '@premier/utils/currency';

import './InvoiceDiscountFeeForm.scss';

const InvoiceDiscountFeeFormCopy = {
    maxItemsMessage: 'You have reached the maximum number of discount/fee items.',
};

const labels = {
    discountFee: 'Discount/Fee',
    description: 'Description',
    amountPercent: 'Amount/Percent',
    tax: 'Tax',
    amount: 'Amount (AUD)',
};

export const fieldNames = {
    discountFee: 'discountFee',
    description: 'description',
    amountPercent: 'amountPercent',
    tax: 'tax',
    amount: 'amount',
};

export const discountFeeOptions = [
    { label: 'Discount ($)', value: 1, isCurrency: true },
    { label: 'Discount (%)', value: 2, isCurrency: false },
    { label: 'Fee ($)', value: 3, isCurrency: true },
    { label: 'Fee (%)', value: 4, isCurrency: false },
];

interface DiscountFeeRecord extends ResponsiveTableRecordPartial {
    id: string;
    discountFee?: string;
    description?: string;
    amountPercent?: number | string;
    tax?: string;

    noBatchActions: boolean;
}

const createNewDiscountFeeRecord = (id: string) => ({
    id,
    discountFee: null,
    description: null,
    amountPercent: null,
    tax: null,
    amount: null,

    noBatchActions: true,
});

interface Props {
    name: string;
    maxItems?: number;
    calculatedAmounts: number[];
    subtotalExTax?: string;
    totalTax?: string;
    total?: string;
    updateTotals: () => void;
    setIsDirty?: (value: boolean) => void;
}

const InvoiceDiscountFeeForm: React.FC<Props> = ({
    name,
    maxItems,
    calculatedAmounts,
    subtotalExTax,
    totalTax,
    total,
    updateTotals,
    setIsDirty,
}) => {
    const invoiceSettings = useInvoiceSettings();
    const { values, setValue, setFormValues, getError, setValidation } = useContext(FormContext);
    const [tableData, setTableData] = useState<DiscountFeeRecord[]>([]);
    const [totalsHash, setTotalsHash] = useState<string>('');

    // Automatically add a new row when there is no blank row (unless max rows has been reached)
    // Use useMemo() here instead of useEffect() because useEffect() will cause an unnecessary re-render that will incorrectly cause TWO new items to be added to table instead of one.
    useMemo(() => {
        const newTableData: DiscountFeeRecord[] = [];
        let hasBlankRecord = false;

        for (var prop in values[name]) {
            const element = values[name][prop];
            if (element !== undefined) {
                newTableData.push(Object.assign({}, { ...element, id: prop }));

                if (element.noBatchActions) {
                    hasBlankRecord = true;
                }
            }
        }

        // Add a blank item if there are no blank items
        if (!hasBlankRecord && (!maxItems || newTableData.length < maxItems)) {
            const newRecordId = `${_.uniqueId('element_')}`;
            setValue(`${name}.${newRecordId}`, createNewDiscountFeeRecord(newRecordId));
        }

        // Update the table data if the context values change
        setTableData(newTableData);
    }, [values, maxItems, name, setTableData, setValue]);

    // Sets value to show the checkbox when a record is altered
    const updateDfRecord = (record: DiscountFeeRecord) => () => {
        if (record.noBatchActions) {
            // Add the checkbox now this row has data
            setValue(`${name}.${record.id}.noBatchActions`, false);

            // Add validation to this row
            setValidation(
                `${name}.${record.id}.${fieldNames.discountFee}`,
                validate().required(`Discount/Fee of ${itemTemplateString} is required.`)
            );
            setValidation(
                `${name}.${record.id}.${fieldNames.description}`,
                validate()
                    .required(`Description of ${itemTemplateString} is required.`)
                    .maxLength(200, `Description of ${itemTemplateString} exceeds 200 characters.`)
            );
            setValidation(
                `${name}.${record.id}.${fieldNames.amountPercent}`,
                validate()
                    .required(`Description of ${itemTemplateString} is required.`)
                    .lessThanOrEqual(
                        maxCurrency,
                        `Amount/Percent of ${itemTemplateString} exceeds ${currencyUtil.formatWithPrefix(maxCurrency)}.`
                    )
            );
            setValidation(
                `${name}.${record.id}.${fieldNames.tax}`,
                validate().required(`Tax of ${itemTemplateString} is required.`)
            );
        }
    };

    useEffect(() => {
        // Hash the important fields and update the totals when the hash changes
        const data = [];
        for (var prop in values[name]) {
            if (
                values[name][prop] &&
                values[name][prop][fieldNames.description] &&
                values[name][prop][fieldNames.discountFee] &&
                values[name][prop][fieldNames.amountPercent] &&
                values[name][prop][fieldNames.tax]
            ) {
                data.push(values[name][prop][fieldNames.discountFee]);
                data.push(values[name][prop][fieldNames.amountPercent]);
                data.push(values[name][prop][fieldNames.tax]);
            }
        }

        const newHash = data.join('');
        if (newHash !== totalsHash) {
            setTotalsHash(newHash);
            updateTotals();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values]);

    // Callback for delete batch action
    const removeItems = (items: DiscountFeeRecord[]) => {
        const newValues = { ...values };
        items.forEach((item) => {
            newValues[name][item.id] = undefined;

            setValidation(`${name}.${item.id}.${fieldNames.description}`, undefined);
            setValidation(`${name}.${item.id}.${fieldNames.discountFee}`, undefined);
            setValidation(`${name}.${item.id}.${fieldNames.amountPercent}`, undefined);
            setValidation(`${name}.${item.id}.${fieldNames.tax}`, undefined);
        });
        setFormValues(newValues);
    };

    // Convert error messages to readable errors
    const validationErrorMessage = (errors: any) => {
        const errorMessages = [];

        for (let i = 0; i < tableData.length; i++) {
            const data = tableData[i];
            const error = errors[data.id];
            if (error && !data.noBatchActions) {
                errorMessages.push(
                    ...[
                        error[fieldNames.discountFee],
                        error[fieldNames.description],
                        error[fieldNames.amountPercent],
                        error[fieldNames.tax],
                    ].map((e) => replaceTemplateWithIndex(i, e))
                );
            }
        }

        return errorMessages.filter((em) => !!em) as string[];
    };

    // Error for the amount total gets displayed in this form, but is handled by the root invoice form
    const amountError = getError(invoiceFormFieldNames.invoiceAmount);

    const validation = {};

    const render = (context: any) => {
        // Clear row when discount/fee field changes
        const onDiscountFeeTypeChange = (itemId: string) => (dropdownValue: any, context: any) => {
            context.setValue(itemId, {
                [fieldNames.discountFee]: dropdownValue,
                [fieldNames.description]: undefined,
                [fieldNames.amountPercent]: undefined,
                [fieldNames.tax]: undefined,
            });
        };

        const isDiscountFeeTypeCurrency = (itemId: string) => {
            const type = context.getValue(`${itemId}.${fieldNames.discountFee}`);
            return discountFeeOptions.find((df) => df.value === type)?.isCurrency;
        };

        const getAmount = (itemId: string) => {
            const index = tableData.findIndex((td) => td.id === itemId);
            if (index < 0 || index >= calculatedAmounts.length) return 0;

            return calculatedAmounts[index];
        };

        // Any error or info messages that should appear below the responsive table
        const messages = (
            <>
                <InvoiceItemsErrors errors={validationErrorMessage(context.errors)} />
                <InvoiceMaxItemsMessage
                    message={InvoiceDiscountFeeFormCopy.maxItemsMessage}
                    show={tableData.filter((td) => !td.noBatchActions).length === maxItems}
                />
            </>
        );

        return (
            <>
                {setIsDirty && (
                    <IsFormDirty
                        initialValues={{}}
                        setDirty={setIsDirty}
                        customIsDirty={(record) => record && !record.noBatchActions}
                    />
                )}
                <ResponsiveTable
                    data={tableData}
                    selectable
                    columns={[
                        {
                            label: labels.discountFee,
                            getter: (item) => {
                                return (
                                    <DropdownField
                                        noLabels
                                        key={`${item.id}.${fieldNames.discountFee}`}
                                        name={`${item.id}.${fieldNames.discountFee}`}
                                        className='invoice-discountfees__discount-fee'
                                        onChange={(value, context) => {
                                            updateDfRecord(item)();
                                            onDiscountFeeTypeChange(item.id)(value, context);
                                        }}
                                        {...{
                                            options: discountFeeOptions,
                                        }}
                                    />
                                );
                            },
                        },
                        {
                            label: labels.description,
                            getter: (item) => {
                                return (
                                    <InputField
                                        noLabels
                                        key={`${item.id}.${fieldNames.description}`}
                                        name={`${item.id}.${fieldNames.description}`}
                                        data-testid='discountfee-description'
                                        onChange={updateDfRecord(item)}
                                    />
                                );
                            },
                        },
                        {
                            label: labels.amountPercent,
                            getter: (item) => {
                                return isDiscountFeeTypeCurrency(item.id) ? (
                                    <CurrencyField
                                        noLabels
                                        key={`${item.id}.${fieldNames.amountPercent}.currency`}
                                        name={`${item.id}.${fieldNames.amountPercent}`}
                                        className='invoice-discountfees__amount-percent'
                                        data-testid='discountfee-amountpercent'
                                        onChange={updateDfRecord(item)}
                                        {...{ compact: true }}
                                    />
                                ) : (
                                    <InputField
                                        noLabels
                                        key={`${item.id}.${fieldNames.amountPercent}.percent`}
                                        name={`${item.id}.${fieldNames.amountPercent}`}
                                        className='invoice-discountfees__amount-percent'
                                        data-testid='discountfee-amountpercent'
                                        onChange={updateDfRecord(item)}
                                        {...{ decimal: true }}
                                    />
                                );
                            },
                        },
                        {
                            label: labels.tax,
                            getter: (item) => {
                                return (
                                    <DropdownField
                                        noLabels
                                        key={`${item.id}.tax${context.getValue(`${item.id}.${fieldNames.tax}`)}`}
                                        name={`${item.id}.${fieldNames.tax}`}
                                        onChange={() => {
                                            updateDfRecord(item)();
                                        }}
                                        {...{
                                            options: invoiceSettings.settings?.taxRates?.map((tr) => ({
                                                value: tr.taxName,
                                                label: tr.taxName,
                                            })),
                                        }}
                                    />
                                );
                            },
                        },
                        {
                            label: labels.amount,
                            getter: (item) => {
                                return (
                                    <Currency
                                        key={`${item.id}.${fieldNames.amount}`}
                                        name={`${item.id}.${fieldNames.amount}`}
                                        value={getAmount(item.id)}
                                        className='invoice-discountfees__amount'
                                        data-testid='discountfee-amount'
                                        {...{ compact: true, disabled: true }}
                                    />
                                );
                            },
                        },
                    ]}
                    batchActions={[
                        {
                            label: 'Delete',
                            batchLabel: 'Delete selected',
                            tableButtonProps: {
                                // @ts-ignore
                                iconType: 'delete',
                                className: 'invoice-discountfees__delete-selected',
                                'data-testid': 'invoice-discountfees-delete',
                            },
                            handleClick: removeItems,
                        },
                    ]}
                    batchInfo={messages}
                />
                <div className='invoice-discountfees__max-items'>{messages}</div>
            </>
        );
    };

    return (
        <div className='invoice-discountfees'>
            <Form name={name} initialValidation={validation} {...{ render }}></Form>
            <div className='invoice-discountfees__totals'>
                <div>
                    <div className='invoice-discountfees__total-label'>
                        <strong>Subtotal (excl. tax)</strong>
                    </div>
                    <div className='invoice-discountfees__total-value'>{subtotalExTax}</div>
                </div>
                <div>
                    <div className='invoice-discountfees__total-label'>
                        <strong>Total tax</strong>
                    </div>
                    <div className='invoice-discountfees__total-value'>{totalTax}</div>
                </div>
                <div>
                    <div className='invoice-discountfees__total-label'>
                        <strong>Total (incl. tax)</strong>
                    </div>
                    <div className='invoice-discountfees__total-value'>{total}</div>
                </div>
            </div>
            {amountError && (
                <IconText alert className='invoice-discountfees__total-error'>
                    {amountError}
                </IconText>
            )}
        </div>
    );
};

export default InvoiceDiscountFeeForm;
