import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import classNames from 'classnames';

import windowUtil from '@premier/utils/window';
import { PageSection, BackToTop, PaginationControl, FileSelector, Button, Dialog, LoadingIndicator } from '@premier/ui';
import { Form, InputField, DropdownField, FormErrorList } from '@premier/form';

import { PageHeader, useDebounce, useInterval, ProductTooltip } from 'components/Common';
import { BatchPaymentRequestList } from 'components/PaymentRequests';
import { UploadErrorDialog, FilenameSubheading } from 'components/Batch'

import labels from 'constants/labels';
import { defaultPageSize } from 'constants/billpay';

import * as requestActions from 'components/PaymentRequests/_actions/batchPaymentRequestActions';

import './ManageBatchPaymentRequestsPage.scss';

const REFRESH_INTERVAL = 5000;
const LOADING_STATUS_KEYS = ['INPROGRESS', 'LOADING', 'LOADING_BATCHMANAGER', 'LOADING_DV', 'LOADING_PAYMENTREQUEST', 'PENDING'];

const ManageBatchPaymentRequestsPage = ({
    batches, savedState, //state values
    actions, //API actions
}) => {

    const [filter, setFilter] = useState(null);  // A live copy of the form values
    const [sort, setSort] = useState(_.get(savedState, 'sort', { field: 'SubmittedDateTime', descending: true }));
    const [reload, setReload] = useState(false);
    const [resultsPerPage] = useState(_.get(savedState, 'resultsPerPage', defaultPageSize));
    const [currentPage, setCurrentPage] = useState(_.get(savedState, 'pageIndex', 0) + 1);
    const [filesToUpload, setFilesToUpload] = useState([]);
    const [fileUploadResult, setFileUploadResult] = useState([]);
    const [showUploadingDialog, setShowUploadingDialog] = useState(false);
    const [showUploadFailedDialog, setShowUploadFailedDialog] = useState(false);
    const [autoRefreshing, setAutoRefreshing] = useState(false);

    const fileSelectRef = useRef(null);


    // On page load, fetch things from API, get requests and restore previous state (page number, scroll position, filters) if any
    useEffect(() => {
        if (savedState && savedState.scrollPosition)
            windowUtil.scrollTo(savedState.scrollPosition); // Scroll back to previous position

        if (savedState && !_.isEmpty(savedState))
            handleFilterChange(savedState.filter, false);
        else
            handleFilterChange(defaultFilter);
    }, [actions]);

    useEffect(() => {
        if (reload) {
            const pageIndex = currentPage -1;
            actions.getPaymentRequestBatches(resultsPerPage, pageIndex, filter, sort).then(result => {
                actions.saveRequestPageState({resultsPerPage, pageIndex, filter, sort});
            }).catch(_.noop);
            setReload(false);
        }
    }, [reload]);

    useInterval(() => {
        setReload(true);
    }, autoRefreshing ? REFRESH_INTERVAL : null);

    useEffect(() => {
        if (batches?.data?.list?.some(x => LOADING_STATUS_KEYS.includes(x?.status?.key)))
            setAutoRefreshing(true);
        else
            setAutoRefreshing(false);
    }, [batches]);

    //#region ========== Filter stuff ==========

    const defaultFilter = {
        filename: '',
        timeSpanDays: 45,
        fileStatus: 'all'
    };

    /** This is called when a component auto-updates it's internal value (ie. DateRangePreset updates the dates/presetValue based on the other) */
    function handleFilterChange(newFilter, resetPage = true) {  // Note: This is also called at first render (from either useEffect or DateRangePresetField completing the dates)
        if (resetPage)
            setCurrentPage(1);

        setFilter(newFilter);
        setReload(true);
    }

    const debouncedHandleFilterChange = useDebounce(handleFilterChange);

    function handleFilterChangeDebounced(values) {
        debouncedHandleFilterChange(values);
    }
    //#endregion

    function handleSort(field, descending) {
        setSort({ field, descending });
        setReload(true);
    }

    function pageChanged(newPage) {
        setCurrentPage(newPage);
        setReload(true);
    }

    function getFilename() {
        return filesToUpload && filesToUpload.length > 0 ? filesToUpload[0].name : '';
    }

    function handleProcessFileClick() {
        setShowUploadingDialog(false);
        setShowUploadFailedDialog(false);

        fileSelectRef.current.open();
    }

    function generateInvalidFileError(files, errorMsg, codeId) {

        const errors = [{
            cachedMessage: errorMsg,
            code: codeId,
            field: "file.filename",
            message: errorMsg
        }];

        setFilesToUpload(files);
        setShowUploadingDialog(false);
        setFileUploadResult([errors, null]);
        setShowUploadFailedDialog(true);
    }

    function checkFileValid(files) {

        if (files.length === 0) {
            generateInvalidFileError([], "The file requested is either invalid or not present", "INVALID_BATCH_FILENAME");
            return false;
        }

        const fileName = files[0].name.toUpperCase();
        if (!fileName.endsWith('.TXT') && !fileName.endsWith('.CSV') && !fileName.endsWith('.DAT')) {
            generateInvalidFileError(files, "Batch filename is invalid. Valid extensions include dat, txt and csv", "INVALID_BATCH_FILE_EXTENSION");
            return false;
        }

        return true;
    }

    function handleProcessFileSelected(files) {
        if (!checkFileValid(files)) {
            return;
        }

        setShowUploadingDialog(true);
        setShowUploadFailedDialog(false);
        setFilesToUpload(files);

        actions.uploadBatch(files).then((response) => {
            if(response.hasErrors) {
                setShowUploadingDialog(false);
                setFileUploadResult([null, response.batchFileValidationResult]);
                setShowUploadFailedDialog(true);
            } else {
                setShowUploadingDialog(false);
                setReload(true);
            }
        }).catch(errors => {
            setShowUploadingDialog(false);
            setFileUploadResult([errors, null]);
            setShowUploadFailedDialog(true);
        });
    }

    // ========== Render ==========
    return (<>
        <Form
            initialValues={filter || defaultFilter}
            errors={batches.errors}
        >
            <PageHeader title={<>{'Batch requests'}<ProductTooltip productTooltipModule={"BATCH_REQUESTS"} /></>}>
                <Button primary onClick={handleProcessFileClick}>Process batch file</Button>
            </PageHeader>
            <p>Manage and process batch customer requests</p>
            <PageSection className={classNames('batch-payment-requests-page', 'full-width')}>
                <div className='row filter'>
                    <InputField className='col-xs-12 col-sm-6 col-lg-4'
                        name='filename'
                        label={labels.batchFilename}
                        onChange={(val, context) => handleFilterChangeDebounced({ ...context.values, filename: val })}
                    />
                    <DropdownField className='col-xs-12 col-sm-6 col-lg-4'
                        name='timeSpanDays'
                        label={labels.timeSpan}
                        onChange={(_val, context) => handleFilterChange(context.values)}
                        options={[
                            { value: 1, label: 'Last 24 hours' },
                            { value: 7, label: 'Last 7 days' },
                            { value: 30, label: 'Last 30 days' },
                            { value: 45, label: 'Last 45 days' },
                        ]}
                    />
                </div>

                <FormErrorList />

                <BatchPaymentRequestList
                    sort={sort}
                    onSort={handleSort}
                    requests={_.get(batches, 'data.list', [])}
                    isLoading={batches.isLoading}
                />

                <PaginationControl
                    currentPage={currentPage}
                    onPageChanged={pageChanged}
                    itemCount={_.get(batches, 'data.resultCount', 0)}
                    itemsPerPage={resultsPerPage}
                />

                <BackToTop />
            </PageSection>
        </Form>

        <FileSelector ref={fileSelectRef} onSelect={handleProcessFileSelected} />
        <Dialog
            show={showUploadingDialog}
            dialogClassName='batch-request-upload-success-dialog'
            closeButton
            onClose = {() => setShowUploadingDialog(false)}
            title='Upload will start shortly'
        >
            <FilenameSubheading filenames={[getFilename()]} />
            <LoadingIndicator />
            <p>You can track the progress in 'Batch requests' page.</p>
            <p>The file will continue uploading if this dialog is closed.</p>
        </Dialog>
        <UploadErrorDialog show={showUploadFailedDialog}
            filename={getFilename()}
            errors={fileUploadResult[0]}
            batchFileResult={fileUploadResult[1]}
            onRetry={handleProcessFileClick}
            onCancel={() => setShowUploadFailedDialog(false)}
        />
    </>);
}

function mapStateToProps(state) {
    return {
        savedState: state.batchPaymentRequest.requestsPageState,
        batches: state.batchPaymentRequest.batches,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(requestActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageBatchPaymentRequestsPage);
