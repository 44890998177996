import { useEffect, useContext } from "react";
// @ts-ignore
import labels from "constants/labels";
import { DropdownField, FormContext } from "@premier/form";
import { BillerCrnForm } from "components/Transactions";
import { Token } from "models/Token";
import { Biller } from "packages/webapi-client";
import { TokenNumberLookup } from "components/PaymentRequests/components/TokenNumberLookup";
import PaymentRequestActionDropdown from "components/PaymentRequests/components/PaymentRequestActionDropdown";

type Props = {
    parentMerchantNumber: string | undefined;

    merchantNumber: string | undefined;

    /** Hiding the CRN fields form entirely */
    hideCrnFields?: boolean;

    /** Renders the crn as 'Token Refernece 1' etc. when rendering billerCrn fields */
    tokenDisplay?: boolean;

    /** Renders show payment method button to lookup tokens */
    showPaymentMethodButton?: boolean;

    /** Renders payment request action drop down. Used on payment request forms/screens. */
    showPaymentRequestAction?: boolean;

    /** Renders token lookup field */
    showTokenNumberLookup?: boolean;

    /** Selected merchant's billers to be displayed */
    merchantBillers: Biller[];

    /** Used when you want to restrict token lookups/searches to a particular customer */
    customerUniqueId?: string;

    selectedToken?: Token;

    tokenRequired?: boolean;

    tokenLocked?: boolean;

    tokenTriggerValidation?: boolean;

    tokenPage?: boolean;

    selectDataVault: (token: Token[]) => void;

    onBillerCodeChange: (biller?: Biller) => void;

    onClearDataVault: () => void;

    onPaymentRequestActionChange?: (action: number) => void;
}

/** BillerCode dropdown + CRN fields */
const BillerDetailsInput = ({
    tokenDisplay, hideCrnFields, showPaymentMethodButton, showPaymentRequestAction = false, showTokenNumberLookup = false, //logic renders
    parentMerchantNumber, merchantNumber, merchantBillers, customerUniqueId, selectedToken, tokenRequired = false, tokenLocked = false, tokenTriggerValidation = false, tokenPage = false, //prop
    onBillerCodeChange, selectDataVault, onClearDataVault, onPaymentRequestActionChange //functions
}: Props) => {
    const context = useContext(FormContext);

    // This is needed because previously the merchantBillers array was being passed directly into the
    // useEffect() function as a dependency. React does === comparisons and so this will cause an infinite
    // loop as arrays are compared by reference by default (not by their contents). So, we grab a unique
    // property of the objects in the array and stringify it so we can do a proper comparison.
    function stringifyMerchantBillerCodes(merchantBillers: Biller[]) {
        return JSON.stringify(merchantBillers.map(mb => mb.billerCodeWithName));
    }

    useEffect(() => {
        if (!merchantBillers || merchantBillers.length === 0)
            return;

        const code = context.getValue("billerCode");

        if (!merchantBillers.find(mb => mb.billerCode === code)) {
            const biller = merchantBillers[0];
            context.setValue("billerCode", biller.billerCode);
            onBillerCodeChange && onBillerCodeChange(biller);
        }

        if (!code)
            onBillerCodeChange && onBillerCodeChange(merchantBillers[0]);
    }, [stringifyMerchantBillerCodes(merchantBillers)]);

    const selectedBiller = merchantBillers.find(mb => mb.billerCode === context.getValue("billerCode"));

    function handleBillerCodeChange(value: string) {
        const biller = merchantBillers.find(mb => mb.billerCode === value);
        onBillerCodeChange && onBillerCodeChange(biller);
    }

    function handleTokenRetrieved(token: Token) {
        selectDataVault([token]);
    }

    function handleSelectDataVault(tokens: Token[]) {
        selectDataVault(tokens);
    }

    return (<>
        {merchantBillers?.length > 1 && (
            <DropdownField
                name="billerCode"
                label={labels.billerCode}
                options={merchantBillers.map(b => ({ value: b.billerCode, label: b.billerCodeWithName }))}
                onChange={handleBillerCodeChange}
            />
        )}

        {showPaymentRequestAction && <PaymentRequestActionDropdown merchantNumber={merchantNumber} billerCode={selectedBiller?.billerCode} tokenPage={tokenPage} onActionChanged={onPaymentRequestActionChange} />}

        {showTokenNumberLookup && showPaymentMethodButton &&
            <TokenNumberLookup
                parentMerchantNumber={parentMerchantNumber}
                merchantNumber={merchantNumber}
                selectedToken={selectedToken}
                customerUniqueId={customerUniqueId}
                required={tokenRequired}
                locked={tokenLocked}
                triggerValidation={tokenTriggerValidation}
                onTokenRetrieved={handleTokenRetrieved}
                onTokenCleared={onClearDataVault}
            />
        }

        {!hideCrnFields &&
            <BillerCrnForm
                parentMerchantNumber={parentMerchantNumber}
                merchantNumber={merchantNumber}
                token={selectedToken}
                tokenDisplay={tokenDisplay}
                biller={selectedBiller}
                selectDataVault={handleSelectDataVault}
                showPaymentMethodButton={showPaymentMethodButton && !tokenLocked}
                customerUniqueId={customerUniqueId}
            />
        }
    </>
    );
};

export default BillerDetailsInput;
