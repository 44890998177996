import webAccountApi from './accountApi';
import webApiIntegrationApi from './apiIntegrationApi';
import webBillerApi from './billerApi';
import webBusinessDetailsApi from './businessDetailsApi';
import webCustomerApi from './customerApi';
import webDeeplinkingApi from './deeplinkingApi';
import webDesignerApi from './designerApi';
import webFeedbackApi from './feedbackApi';
import webFraudApi from './fraudApi';
import webInvoiceApi from './invoiceApi';
import webMerchantApi from './merchantApi';
import webMessagingApi from './messagingApi';
import webNoticeApi from './noticeApi';
import webPaymentRequestApi from './paymentRequestApi';
import webPlatformConfigurationApi from './platformConfigurationApi';
import webReportsApi from './reportsApi';
import webRequestApi from './requestApi';
import webScheduledPaymentsApi from './scheduledPaymentsApi';
import webSchedulesApi from './schedulesApi';
import webSettlementApi from './settlementApi';
import webStationeryShopApi from './stationeryShopApi';
import webTokenApi from './tokenApi';
import webTransactionsApi from './transactionsApi';
import webUserApi from './userApi';
import webUtilitiesApi from './utilitiesApi';
import webRequestsBatchApi from './requestsBatchApi';
import webPaymentPlanOptionApi from './paymentPlanOptionApi';
import webPaymentPlanApi from './paymentPlanApi';
import webTelemetryApi from './telemetryApi';
import webCredentialOnFileApi from './credentialOnFileApi';
import webContentManagementApi from './contentManagementApi';
import webDeclineManagerApi from './declineManagerApi';

const webApis = {
    accountApi: webAccountApi,
    apiIntegrationApi: webApiIntegrationApi,
    billerApi: webBillerApi,
    businessDetailsApi: webBusinessDetailsApi,
    customerApi: webCustomerApi,
    deeplinkingApi: webDeeplinkingApi,
    designerApi: webDesignerApi,
    feedbackApi: webFeedbackApi,
    fraudApi: webFraudApi,
    invoiceApi: webInvoiceApi,
    merchantApi: webMerchantApi,
    messagingApi: webMessagingApi,
    noticeApi: webNoticeApi,
    paymentRequestApi: webPaymentRequestApi,
    configurationApi: webPlatformConfigurationApi,
    reportsApi: webReportsApi,
    requestApi: webRequestApi,
    scheduledPaymentsApi: webScheduledPaymentsApi,
    schedulesApi: webSchedulesApi,
    settlementApi: webSettlementApi,
    stationeryShopApi: webStationeryShopApi,
    tokenApi: webTokenApi,
    transactionsApi: webTransactionsApi,
    userApi: webUserApi,
    utilitiesApi: webUtilitiesApi,
    requestsBatchApi: webRequestsBatchApi,
    paymentPlanOptionApi: webPaymentPlanOptionApi,
    paymentPlanApi: webPaymentPlanApi,
    telemetryApi: webTelemetryApi,
    credentialOnFileApi: webCredentialOnFileApi,
    contentManagementApi: webContentManagementApi,
    declineManagerApi: webDeclineManagerApi,
};

export const {
    accountApi,
    apiIntegrationApi,
    billerApi,
    businessDetailsApi,
    customerApi,
    deeplinkingApi,
    designerApi,
    feedbackApi,
    fraudApi,
    invoiceApi,
    merchantApi,
    messagingApi,
    noticeApi,
    paymentRequestApi,
    configurationApi,
    reportsApi,
    requestApi,
    scheduledPaymentsApi,
    schedulesApi,
    settlementApi,
    stationeryShopApi,
    tokenApi,
    transactionsApi,
    userApi,
    utilitiesApi,
    requestsBatchApi,
    paymentPlanOptionApi,
    paymentPlanApi,
    telemetryApi,
    credentialOnFileApi,
    contentManagementApi,
    declineManagerApi
} = webApis;