import React from "react";
import { Dialog, Icon, Button } from "@premier/ui";
import { InvoiceSettingsState } from "components/Settings/pages/InvoiceSettingsPage";

export const InvoiceDialogCopy = {
    unsaved: {
        title: "Changes to invoice settings are not saved",
        body:
            "Leaving this page without saving will discard your unsaved changes.",
    },
    success: {
        title: (pageName: string) => {
            return `Settings for ${pageName} saved successfully!`;
        },
    },
    continueButton: "Continue editing",
    cancelButton: "Discard changes",
};

interface Props {
    modalState: InvoiceSettingsState;
    pageName: string;
    onClose: () => void;
    onExit: () => void;
}

const InvoiceDialog: React.FC<Props> = ({
    modalState,
    pageName,
    onClose,
    onExit,
}) => {
    return (
        <>
            <Dialog
                show={modalState === "UNSAVED"}
                closeButton
                onClose={onClose}
                title={InvoiceDialogCopy.unsaved.title}
                icon={<Icon alert />}
                footerButtons={
                    <>
                        <Button primary onClick={onClose}>
                            {InvoiceDialogCopy.continueButton}
                        </Button>
                        <Button onClick={onExit}>
                            {InvoiceDialogCopy.cancelButton}
                        </Button>
                    </>
                }
            >
                <p>{InvoiceDialogCopy.unsaved.body}</p>
            </Dialog>
            <Dialog
                show={modalState === "SUCCESS"}
                closeButton
                onClose={onExit}
                title={InvoiceDialogCopy.success.title(pageName)}
                icon={<Icon tick />}
                autoClose={3}
            ></Dialog>
        </>
    );
};

export default InvoiceDialog;
