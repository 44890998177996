//#region ----- DataVault APIs -----
/** Payment keys used in api / back-end to determine payment type
  * @enum {string}
  */
export const PaymentTypeKey = {
    CARD: "CARD",
    BANK_ACCOUNT: "BANKACCOUNT"
};

export const NewTokenOptions = {
    ADD_NEW: "ADDNEW",
    LINK_EXISTING: "LINKEXISTING"
};
//#endregion

export const PaymentDetailsTabKeys = {
    PAYMENT: "paymentDetails",
    REQUESTS: "paymentRequest",
    QRREQUESTS: "qrCodePaymentRequest"
};

export const PaymentMethodDetailsTabKeys = {
    CREATE: "paymentMethodDetails",
    REQUEST: "paymentMethodRequest"
};

export const TokenExportFormatOptions = [
    { value: "VIRTUALPOS", label: "VirtualPOS" },
    { value: "BPOINT", label: "eVolve - BPOINT" },
    { value: "UNSPECIFIED", label: "CSV Export" }
];

//#region ----- Settlement APIs -----
/** Possible returned optionDesc from GetSettlementExportOptions API
  * @enum {string}
  */
export const SettlementExportOptionLabel = {
    STANDARD: "Standard",
    EXTENDED: "Extended",
};

/** WalletTypes used for WalletLogo rendering.
  * @enum {string}
  */
export const WalletTypes = {
    APPLEPAY: "ApplePay",
    AMEX_WALLET: "AmexWallet",
    MASTERPASS: "MasterPass",
    GOOGLEPAY: "GooglePay"
};
//#endregion

//#region ----- Settlement APIs -----
export const PaymentRequestStatus = {
    REGISTERED: "Registered",
};

export enum PaymentRequestAction {
    PaymentOnly = 1,
    PreAuthOnly = 2,
    PaymentAndTokenise = 3,
    PreAuthAndTokenise = 4,
    TokeniseOnly = 5,
    UpdateToken = 6,
    PaymentAndUpdateToken = 7,
    PreAuthAndUpdateToken = 8,
}
//#endregion

//#region ----- Biller -----
/** The maximum number of billers a merchant can create in BackOffice */
export const maxBillers = 5;

/** The default minimum and maximum values for a Biller's accepted amounts
 * in dollar amounts (1 = $1.00)
*/
export const BillerDefaultAcceptedAmounts = {
    MIN: 0.01,
    MAX: 100000
};

/** The minimum and maximum values for a Biller's accepted amounts
 *  As consistent to legacy backoffice, maximum amount value should be less than
 *  21474836.47(will convert into 2147483647 as Int32.MaxValue when passing to backend)
*/
export const BillerAcceptedAmountsRange = {
    MIN: 0.01,
    MAX: 21474836.47
};

/** Default biller object to populate biller dropdown field. */
export const defaultBiller = {
    billerCode: "",
    billerCodeWithName: "Select (Optional)...",
    acceptedCrn1: {
        number: 1,
        label: "",
        helpText: null,
        minLength: 1,
        maxLength: 50
    },
    acceptedCrn2: {
        number: 2,
        label: "",
        helpText: null,
        minLength: 0,
        maxLength: 50
    },
    acceptedCrn3: {
        number: 3,
        label: "",
        helpText: null,
        minLength: 0,
        maxLength: 50
    }
};
//#endregion

//#region ----- PaymentSettings APIs -----
export const CardLocalisationEnum = {
    UNSPECIFIED: "UNSPECIFIED",
    LOCAL: "LOCAL",
    INTERNATIONAL: "INTERNATIONAL",
};

export const CardSubTypeEnum = {
    UNSPECIFIED: "UNSPECIFIED",
    DEBIT: "DEBIT",
    CREDIT: "CREDIT",
};

export const PaymentRequestDeliveryMethodEnum = {
    EMAIL_ONLY: 1,
    SMS_ONLY: 2,
    EMAIL_AND_SMS: 3
};
//#endregion

export const AcceptablePaymentTypeEnum = {
    FULL_PAYMENT_REQUIRED: 1,
    MULIPLE_PAYMENTS_CAPPED_TOTAL: 2,
    ONE_PAYMENT_ANY_AMOUNT: 3
};


export const ExpirePaymentLinkEnum = {
    DO_NOT_EXPIRE: 1,
    AFTER_DUE_DATE: 2,
    CUSTOM_EXPIRY_DATE: 3
};

//#region ----- Paging -----
export const defaultPageSize = 20;
export const defaultPageSizeOptions = [20, 40, 60];
//#endregion

export const TOKEN_LENGTH = 16;

export const logoMaxSize = 2 * 1024 * 1024;

export const acceptedLogoTypes = ["image/png", "image/gif", "image/bmp", "image/jpg", "image/jpeg"];