import { useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { CheckboxGroup } from "@premier/ui";
import FormGroup from "./FormGroup";
import withField from "./withField";

const CheckboxGroupField = ({
    name,
    options, defaultValues, // CheckboxGroupField specific props
    formProps, groupProps, ...otherProps
}) => {

    const [id] = useState(_.uniqueId(`${name}_`));

    function handleChange(selectedValues) {
        formProps.setValue(selectedValues);
    }

    return (
        <>
            <FormGroup fieldId={id} {...groupProps}>
                <CheckboxGroup
                    name={name}
                    options={options}
                    onChange={handleChange}
                    defaultValues={defaultValues || []}
                    value={formProps.value}
                    error={formProps.error}
                    {...otherProps}
                />
            </FormGroup>
        </>
    );
};

CheckboxGroupField.propTypes = {
    /** The form-connected name of the field */
    name: PropTypes.string.isRequired,

    /** Whether or not this field is required (defined by having a .required() validation) */
    mandatory: PropTypes.bool,

    /** Help contents (if any) */
    help: PropTypes.any,

    /** Additional class names for the inner component */
    className: PropTypes.string,

    /** Whether to display the field without labels or error messages, for use in compact areas or tables */
    noLabels: PropTypes.bool,

    inlineUpSm: PropTypes.bool
};

export default withField(CheckboxGroupField);
