import $ from 'jquery';

export class WindowUtil {

    /** Set the scroll position to yPx without animation */
    scrollTo = (yPx: number) => {
        document.body.scrollTop = yPx; // For Safari
        document.documentElement.scrollTop = yPx; // For Chrome, Firefox, IE and Opera
    }

    /** Scroll to a DOM element with animation (unless timeMs is 0)
     * @param {number} timeMs Animation time in ms. Default = 300
     * @param {func} callback A function to be run after animation ends
     */
    scrollToElement(element: HTMLElement, timeMs=300, callback?: (this: HTMLElement) => void) {
        $('html, body').animate({
            scrollTop: $(element)?.offset()?.top
        }, timeMs, callback);
    }

    /** Scroll to a DOM element with animation (unless timeMs is 0) and set focus to that element
     * @param {number} timeMs Animation time in ms. Default = 300
     */
    scrollAndFocus(element: HTMLElement, timeMs?: number) {
        this.scrollToElement(element, timeMs, () => {
            element.focus();
        });
    }

    openPrintReceiptWindow = (url: string) => {
        window.open(url, 'print-txn-details-page', 'resizable, height=750, width=770');
    }

    copyTextToClipboard = (text: string) => {
        var tempTextareaElement = document.createElement("textarea");
        document.body.appendChild(tempTextareaElement);
        tempTextareaElement.value = text;
        tempTextareaElement.select();
        document.execCommand("copy");
        document.body.removeChild(tempTextareaElement);
    }
}

const windowUtil = new WindowUtil();
export default windowUtil;