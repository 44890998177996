import React from 'react';
import Selector, { SelectorProps } from './Selector';
import './RadioInput.scss';

type Props<TValue extends string | number> = Omit<SelectorProps<TValue>, 'type'>;

/** A single radio button with label + error message */
const RadioInput = <TValue extends string | number>({
    checked,
    ...otherProps  // includes: name, defaultChecked, and other props for <input type='radio'>
}: Props<TValue>) => {

    return (
        <Selector
            type='radio'
            {...otherProps}
            checked={checked}
        />
    );
};

export default RadioInput;
