import { connect } from "react-redux";
import { RootState } from "store/store";

import { CardContainer, DescriptionList } from "@premier/ui";
import * as billpayUtil from "@premier/utils/billpay";
import currencyUtil from "@premier/utils/currency";
import { MerchantCurrency } from "models/MerchantCurrency";
import { User } from "models/User";
import { Merchant } from "packages/utils/models";

type Props = {
    user: User;
    merchantCurrency: MerchantCurrency;
    merchant: Merchant;
};

const UserDetails = ({
    user, //data
    merchantCurrency, merchant  //state
} : Props) => {
    return (
        <CardContainer header="User details">
            <DescriptionList items={[
                { name: "Merchant", value: billpayUtil.getMerchantNameFromNumber(merchant, user.merchantNumber) },
                { name: "Name", value: billpayUtil.getCustomerFullName(user) },
                { name: "Username", value: user.userName },
                { name: "Email", value: user.emailAddress },
                { name: "Mobile", value: user.mobileNumber },
                { name: "Phone", value: user.phoneNumber },
                { name: "Status", value: user.isActive ? "Active" : "Inactive" },
                { name: "User role", value: user.roleName },
                { name: "Daily allowed refunds", value: user.dailyRefundCount || user.dailyRefundCount === 0  ? user.dailyRefundCount : "Unlimited" },
                { name: "Daily refund amount", value: user.dailyRefundAmount || user.dailyRefundAmount === 0 ? currencyUtil.convertToDisplayString(user.dailyRefundAmount, merchantCurrency.code) : "Unlimited" },
            ]} />
        </CardContainer>
    );
};

function mapStateToProps(state : RootState) {
    return {
        merchant: state.accounts.users.merchant,
        merchantCurrency: state.accounts.users.merchant.currency
    };
}

export default connect(mapStateToProps)(UserDetails);
