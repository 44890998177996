import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { billerApi } from "api";
import { Field, FieldArray, useFormik, FormikProvider } from "formik";
import * as Yup from "yup";
import { DragDropContext, DropResult, Droppable, Draggable } from "react-beautiful-dnd";
import { PageSection, Button, Divider, Icon, LoadingIndicator, PaddedContainer, Row, Link, Tooltip, TooltipTrigger, SuccessModal } from "@premier/ui";
import { FormGroup, FormikRadioButton, FormikSwitch, TextArea, FormikCurrencyInput, FormikError } from "@linkly/formik-ui";
import { PlatformRoutesConfiguration } from "components/Routing";
import { RootState } from "store/store";
import { APICallRequestState, PageHeader, useApiCall, ProductTooltip } from "components/Common";
import deleteSvg from "images/icon-control-delete-24.svg";
import { FaArrowsAltV } from "react-icons/fa";
import errorUtil from "@premier/utils/error";
import { Sizes } from "packages/ui/src/Switch";

import "./EditAdditionalPaymentsPage.scss";

type Props = {
    billerCode: string;
}

type AdditionalPayment = {
    number: number;
    description: string;
    reportableDescription: string;
    reportableDescriptionSameAsAbove: boolean;
    amountSetting: AmountSettings;
    prePopulatedAmount?: number | null;
    defaultOptIn: boolean;
    optOutAllowed: boolean;
}

type FormikAdditionalPayment = {
    reportableDescription: string | undefined;
}

enum AmountSettings {
    Variable = "Variable",
    Fixed = "Fixed"
}

type FormValues = {
    additionalPayments: AdditionalPayment[];
}

const EditAdditionalPaymentsPage = ({ billerCode }: Props) => {
    const [saving, setSaving] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);

    const [additionalPayments, additionalPaymentsStatus] = useApiCall(() => {
        return billerApi.getAdditionalPayments(billerCode);
    }, [billerCode]);

    const initialValues: FormValues = {
        additionalPayments: []
    };

    const saveAdditionalPayments = async (values: FormValues) => {
        setSaving(true);

        try {
            const response = await billerApi.saveAdditionalPayments(billerCode, values.additionalPayments.map((x) => ({
                description: x.description,
                reportableDescription: x.reportableDescription,
                amountSetting: x.amountSetting === AmountSettings.Fixed ? "Fixed" : "Variable",
                prePopulatedAmount: Number(x.prePopulatedAmount) ?? undefined,
                optedInByDefault: x.defaultOptIn,
                optOutAllowed: x.optOutAllowed,
                listOrder: x.number,
            })));

            const errors = response.data.errors ? errorUtil.convertApiErrorsToFormikErrors<FormValues>(response.data.errors) : null;

            if (errors?.additionalPayments) {
                formik.setErrors(errors);
            } else {
                setShowSuccess(true);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setSaving(false);
        }
    };

    const formik = useFormik({
        initialValues: initialValues,
        onSubmit: saveAdditionalPayments,
        validationSchema: Yup.object().shape({
            additionalPayments: Yup.array()
                .of(
                    Yup.object().shape({
                        description: Yup.string().label("Description").trim().required(),
                        reportableDescription: Yup.string().label("Reportable description").trim().required(),
                        amountSetting: Yup.string().label("Amount").required(),
                        prePopulatedAmount: Yup.string().nullable().label("Pre-populated amount").when("amountSetting", {
                            is: AmountSettings.Fixed,
                            then: (schema) => schema.required()
                        }),
                        defaultOptIn: Yup.boolean().label("Default opt-in").required(),
                        optOutAllowed: Yup.boolean().label("Opt-out allowed").required()
                    })
                )
                .test("unique", "Reportable description must be unique across items", (value) => {
                    if (!value) {
                        return true;
                    }

                    const unique = value.filter((v: FormikAdditionalPayment, i: number, a: FormikAdditionalPayment[]) => a.findIndex((t: FormikAdditionalPayment) => (t.reportableDescription === v.reportableDescription)) === i);

                    return unique.length === value.length;
                })
        })
    });

    // Once the data has loaded then set the values of items in the form.
    useEffect(() => {
        if (additionalPaymentsStatus === APICallRequestState.SUCCESSFUL && additionalPayments) {
            formik.setValues({
                ...formik.values,
                additionalPayments: additionalPayments.map(x => ({
                    number: x.listOrder ?? 1,
                    description: x.description ?? "",
                    reportableDescription: x.reportableDescription ?? "",
                    reportableDescriptionSameAsAbove: x.description === x.reportableDescription,
                    amountSetting: x.amountSetting === "Fixed" ? AmountSettings.Fixed : AmountSettings.Variable,
                    prePopulatedAmount: x.prePopulatedAmount,
                    defaultOptIn: x.optedInByDefault ?? false,
                    optOutAllowed: x.optOutAllowed ?? false
                }))
            });
        }
    }, [additionalPayments, additionalPaymentsStatus]);

    useEffect(() => {
        formik.values.additionalPayments.map((item, index) => {
            // Set item order.
            item.number = index + 1;

            // Handle situation when "same as above" is toggled ON.
            if (item.reportableDescriptionSameAsAbove) {
                item.reportableDescription = item.description;
            }

            return item;
        });
    }, [formik.values]);

    const buildNewAdditionalPayment = (values: FormValues): AdditionalPayment => {
        let nextNumber = 1;

        if (values.additionalPayments.length > 0) {
            nextNumber = Math.max(...values.additionalPayments.map(x => x.number)) + 1;
        }

        return {
            description: "",
            reportableDescription: "",
            reportableDescriptionSameAsAbove: false,
            number: nextNumber,
            amountSetting: AmountSettings.Fixed,
            prePopulatedAmount: null,
            defaultOptIn: false,
            optOutAllowed: false
        };
    };

    // Drop event handler
    const onDragEnd = ({ destination, source }: DropResult) => {
        if (!destination) {
            return;
        }

        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }

        if (destination) {
            // Grab the item that has been drag and dropped
            const itemToMove = formik.values.additionalPayments[source.index];

            // Can't find it? Do nothing.
            if (!itemToMove) {
                return;
            }

            // Grab the original list of additional payments.
            const additionalPayments = formik.values.additionalPayments;

            // Remove the item that was drag and dropped.
            additionalPayments.splice(source.index, 1);

            // Put the item that was drag and dropped into it's new position.
            additionalPayments.splice(destination.index, 0, itemToMove);

            // Assign new order to item.
            additionalPayments.map((item, index) => {
                item.number = index + 1;

                return item;
            });

            // Set the values of the form with the newly sorted list of additional payments.
            // First we need to clear the additionalPayments array. We need to do this because FormikCurrencyInput is being weird
            // and holding on to the old value of "amount" after items have been dragged and dropped.
            formik.setValues({
                ...formik.values,
                additionalPayments: []
            });

            // Then re-set it with the re-sorted items.
            setTimeout(() => {
                formik.setValues({
                    ...formik.values,
                    additionalPayments: additionalPayments
                });
            }, 50);
        }
    };

    return <>
        <PageSection className="page-section">
            <PageHeader
                backButton
                title={<>{"Additional payments"}<ProductTooltip productTooltipModule={"ADDITIONAL_PAYMENTS"} /></>}
            />
            <PaddedContainer>
                {additionalPaymentsStatus === APICallRequestState.LOADING ? <LoadingIndicator /> :
                    <FormikProvider value={formik}>
                        <form onSubmit={formik.handleSubmit}>
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="additionalPaymentsDroppableContainer">
                                    {(provided) => (
                                        <div ref={provided.innerRef} {...provided.droppableProps}>
                                            <FieldArray name="additionalPayments">
                                                {({ push, remove }) => (
                                                    <>
                                                        {formik.values.additionalPayments.length > 0 ?
                                                            formik.values.additionalPayments.map((item, index) => (
                                                                <Draggable draggableId={item.number.toString()} index={index} key={item.number}>
                                                                    {(provided) => (
                                                                        <div className="additional-payment-draggable-card" {...provided.draggableProps} ref={provided.innerRef}>
                                                                            <dt className="additional-payment-title">Additional payment {item.number}
                                                                                <div title="Click and drag to re-order" {...provided.dragHandleProps}>
                                                                                    <FaArrowsAltV />
                                                                                </div>
                                                                            </dt>
                                                                            <Row className="additional-payment-container">
                                                                                <div className="col-sm-12 pt-2">
                                                                                    <Row>
                                                                                        <FormGroup name={`additionalPayments[${index}].description`} label="Description" className="col-sm-12">
                                                                                            <Field name={`additionalPayments[${index}].description`} as={TextArea} />
                                                                                        </FormGroup>
                                                                                    </Row>
                                                                                    <Row>
                                                                                        <FormGroup name={`additionalPayments.${index}.reportableDescription`} label="Reportable description" tooltip={<div className="same-as-above-switch-container">same as above<div><Field name={`additionalPayments.${index}.reportableDescriptionSameAsAbove`} as={FormikSwitch} size={Sizes.Small} /></div></div>} className="col-sm-12">
                                                                                            <Field name={`additionalPayments.${index}.reportableDescription`} as={TextArea} disabled={item.reportableDescriptionSameAsAbove} />
                                                                                        </FormGroup>
                                                                                    </Row>
                                                                                    <Row>
                                                                                        <div className="col-sm-3">
                                                                                            <Row>
                                                                                                <FormGroup name={`additionalPayments.${index}.amountSetting`} label={<>Amount<TooltipTrigger tipId="tooltip-amount-setting"><Icon question className="product_tooltip_question_mark" /></TooltipTrigger></>} className="col-sm-12">
                                                                                                    <Row>
                                                                                                        <div className="col-sm-6">
                                                                                                            <Field as={FormikRadioButton} value={AmountSettings.Variable} label="Variable" id={`additionalPayments.${index}.amountSettingVariable`} name={`additionalPayments.${index}.amountSetting`} />
                                                                                                        </div>
                                                                                                        <div className="col-sm-6">
                                                                                                            <Field as={FormikRadioButton} value={AmountSettings.Fixed} label="Fixed" id={`additionalPayments.${index}.amountSettingFixed`} name={`additionalPayments.${index}.amountSetting`} />
                                                                                                        </div>
                                                                                                    </Row>
                                                                                                </FormGroup>
                                                                                            </Row>
                                                                                        </div>
                                                                                        <div className="col-sm-3">
                                                                                            <Row>
                                                                                                <FormGroup name={`additionalPayments.${index}.prePopulatedAmount`} label="Pre-populated amount" className="col-sm-12">
                                                                                                    <Field name={`additionalPayments.${index}.prePopulatedAmount`} as={FormikCurrencyInput} showCurrencySymbol={false} />
                                                                                                </FormGroup>
                                                                                            </Row>
                                                                                        </div>
                                                                                        <div className="col-sm-3">
                                                                                            <Row>
                                                                                                <FormGroup name={`additionalPayments.${index}.defaultOptIn`} label="Default opt-in" className="col-sm-12">
                                                                                                    <Field name={`additionalPayments.${index}.defaultOptIn`} as={FormikSwitch} />
                                                                                                </FormGroup>
                                                                                            </Row>
                                                                                        </div>
                                                                                        <div className="col-sm-3">
                                                                                            <Row>
                                                                                                <FormGroup name={`additionalPayments.${index}.optOutAllowed`} label="Opt-out allowed" className="col-sm-12">
                                                                                                    <Field name={`additionalPayments.${index}.optOutAllowed`} as={FormikSwitch} />
                                                                                                </FormGroup>
                                                                                            </Row>
                                                                                        </div>
                                                                                    </Row>
                                                                                </div>
                                                                            </Row>
                                                                            <Row className="additional-payment-actions">
                                                                                <div className="delete-additional-payment-button" onClick={() => { remove(index); }}>
                                                                                    <img src={deleteSvg} alt="Delete item" />
                                                                                    <span>Delete additional payment</span>
                                                                                </div>
                                                                            </Row>
                                                                            <Divider />
                                                                            <Tooltip id="tooltip-amount-setting">
                                                                                Selecting 'Variable' will allow your customers to edit the additional payment amount on the hosted payment page. Selecting 'Fixed' will display and only allow the pre-populated amount.
                                                                            </Tooltip>
                                                                        </div>
                                                                    )}
                                                                </Draggable>
                                                            )) : <></>
                                                        }
                                                        {provided.placeholder}
                                                        <Row className="new-additional-payment-button"><Button onClick={() => { push(buildNewAdditionalPayment(formik.values)); }}>New additional payment</Button></Row>
                                                        <FormikError name="additionalPayments" />
                                                        {formik.values.additionalPayments.length <= 0 ?
                                                            <Row className="additional-payments-empty">
                                                                No additional payments exist.
                                                            </Row> :
                                                            <>
                                                                <Button type="submit" disabled={saving} primary>Save</Button>
                                                                <Link to={PlatformRoutesConfiguration.settingsRoute?.billerDetails.generatePath(billerCode)} button>Cancel</Link>
                                                            </>
                                                        }
                                                    </>
                                                )}
                                            </FieldArray>
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </form>
                    </FormikProvider>
                }
            </PaddedContainer>
        </PageSection>
        <SuccessModal
            show={showSuccess}
            onClose={() => {
                setShowSuccess(false);
            }}
            title="Additional payments updated successfully!"
        />
    </>;
};

function mapStateToProps(state: RootState, ownProps: any) {
    const billerCode = ownProps.match.params.billerCode;

    return {
        billerCode,
    };
}

export default connect(mapStateToProps)(EditAdditionalPaymentsPage);
