export default {
    actions: "Actions",
    amount: "Amount",
    billerCode: "Biller code",
    billerName: "Biller name",
    billerDescription: "Biller code name",
    cardNumber: "Card number",
    cardOrBankAccountNumber: "Card/bank account",
    createdTime: "Created on",
    crn1: "Customer reference number 1",
    crn2: "Customer reference number 2",
    crn3: "Customer reference number 3",
    dataVault: "Data vault",
    date: "Date",
    dateRange: "Date range",
    end: "End",
    endDate: "End date",
    expiry: "Expiry",
    expiryDate: "Expiry date",
    footerLogoAlt: "Footer logo",
    headerLogoAlt: "Home",
    loginMerchantNumber: "Merchant number",
    loginPassword: "Password",
    loginUsername: "Username",
    logo: "Logo",
    merchant: "Merchant",
    merchantReference: "Merchant reference",
    orderType: "Order type",
    reference1: "Reference 1",
    reference2: "Reference 2",
    reference3: "Reference 3",
    startDate: "Start date",
    status: "Status",
    updatedTime: "Last updated on",
    userNameUpdated: "Last updated by",
    userNameCreated: "Created by",
    endAfter: "End after",

    // Account / Profile
    confirmPassword: "Confirm password",
    currentPassword: "Current password",
    displayName: "Display name",
    salutation: "Salutation",
    firstName: "First name",
    lastName: "Last name",
    name: "Name",
    newPassword: "New password",
    website: "Website",
    MFACode: "Code",

    // Bank account
    accountName: "Account name",
    accountNumber: "Account number",
    accountType: "Account type",
    bankAccountDetails: "Bank account details",
    bankAccountName: "Bank account name",
    bankAccountNumber: "Bank account number",
    bsb: "BSB",

    // Batch jobs
    batchFilename: "Batch file name",
    filename: "Filename",
    uploadedRecordCount: "Request records",
    submittedDateTime: "Time submitted",
    timeSpan: "Time span",

    // Business details
    tradingName: "Trading name",
    companyId1: "Australian business number (ABN)",
    companyId2: "Australian company number (ACN)",
    url: "URL",
    addressLine1: "Address line 1",
    addressLine2: "Address line 2",
    suburb: "Suburb",
    faxNumber: "Fax number",

    // Card
    cardholderName: "Cardholder name",
    maskedCardNumber: "Masked card number",

    // Customer
    address: "Address",
    customerCode: "Customer code",
    customerId1: "Customer identifier 1",
    customerId2: "Customer identifier 2",
    customerId3: "Customer identifier 3",
    customerName: "Name",
    dateOfBirth: "Date of birth",
    email: "Email",
    emailAddress: "Email address",
    mobile: "Mobile",
    sms: "SMS",
    mobileNumber: "Mobile number",
    phone: "Phone",
    phoneNumber: "Phone number",
    contactName: "Contact name",
    companyName: "Company name",
    sendTo: "Send to",

    // Fraud Managment
    fraudAction: "Action",
    fraudType: "Type",
    fraudLowValue: "Low value",
    fraudHighValue: "High value",

    // Invoice
    invoiceNumber: "Invoice number",
    orderNumber: "Order number",
    invoiceDate: "Invoice date",
    termOfPayment: "Term of payment",
    termsOfPayment: "Terms of payment",
    dueAmount: "Due amount",
    lastSentDate: "Last sent date",

    // Transactions
    transactionType: "Type",
    transactionReceiptNumber: "Receipt number",
    transactionDate: "Transaction date",
    transactionAmount: "Amount",

    // Location
    county: "Country",
    locality: "City",
    postcode: "Postcode",
    state: "State",

    // Payment Settings
    local: "Domestic",
    international: "International",
    klarnaUsername: "Klarna username",
    klarnaPassword: "Klarna password",

    // Payment Request
    action: "Type",
    dateSent: "Date sent",
    deliveryEmailAddress: "Email",
    deliveryPhoneNumber: "Phone",
    sentTo: "Sent to",

    // Scheduled Payment
    attempts: "Attempts",
    processedPayments: "Processed payments",
    receiptNumber: "Receipt number",
    scheduledDate: "Scheduled date",
    successfulPayments: "Successful payments",

    // Subscription
    amountCollected: "Amount collected",
    amountOriginalCollected: "Amount collected (ex. surcharge)",
    amountSurchargeCollected: "Surcharge collected",
    createdDateRange: "Created date range",
    endDateRange: "End date range",
    frequency: "Frequency",
    nextPayment: "Next payment date",
    dueDate: "Due date",
    numberOfPayments: "Number of payments",
    recurringAmount: "Recurring amount",
    remainingAmount: "Remaining amount",
    recurringAmountExSurcharge: "Recurring amount (ex. surcharge)",
    remainingAmountExSurcharge: "Remaining amount (ex. surcharge)",
    remainingPayments: "Remaining payments",
    subscriptionPeriod: "Subscription period",
    subscriptionReference: "Subscription description",
    subscriptionStatus: "Subscription status",
    suspendSubscription: "Suspend subscription",

    // Token
    token: "Token",
    tokenReference1: "Token reference 1",
    tokenReference2: "Token reference 2",
    tokenReference3: "Token reference 3",

    // User
    dailyRefundAmount: "Daily refund amount",
    dailyRefundCount: "Daily allowed refunds",
    userRole: "User role",
    userStatus: "Status",
    byNetwork: "By network",
    byLocalisation: "By domestic / international",
    bySubType: "By debit / credit",

    // Payment plan options
    optionName: "Option name",
    availablePeriod: "Available period",
    lastUpdatedOn: "Last updated on",

    //payment plan
    paymentPlanOption: "Payment plan option",
    planOption: "Plan option",
    totalAmountToPay: "Total amount to pay",
    instalmentAmount: "Instalment amount",
    noOfInstalmentsCollected: "No. of instalments collected",
    nextInstalmentDate: "Next instalment date",
    noOfInstalmentsOverdue: "No. of instalments overdue",
    noOfInstalmentsRemaining: "No. of instalments remaining",
    amountOverdue: "Amount overdue",
    amountScheduled: "Amount scheduled",


    // Errors
    unknownErrorMessage: "An unknown error was encountered. Please try again.",

    // Generic Form
    leavingPageUnsavedChange: "Leaving this page without saving will discard your unsaved changes."
};
