import currencyUtil from "@premier/utils/currency";
import { CurrencyRangeField, FormDialog, FormErrorList, SubmitButton, validate } from "@premier/form";
import { Button } from "@premier/ui";
import { FieldError } from "api/mapErrors";

// @ts-ignore
import { BillerAcceptedAmountsRange } from "constants/billpay";
// @ts-ignore
import errorMaps from "constants/errorMaps";
import { PaymentLimits } from "models/PaymentLimits";

type Props = {
    paymentLimits: PaymentLimits;
    loading?: boolean;
    onSubmit: (values: FormValues) => void;
    errors: FieldError[];
    show: boolean;
    onClose: () => void;
}

export type FormValues = {
    acceptedAmounts: AcceptedAmounts;
}

type AcceptedAmounts = {
    min: number;
    max: number;
}

const AcceptedAmountModal = ({
    paymentLimits, loading, //data
    onSubmit, errors,
    show, onClose //modal logic
}: Props) => {
    return (
        <FormDialog
            closeButton
            dismissable
            show={show}
            onClose={onClose}
            onSubmit={onSubmit}
            errorMaps={errorMaps}
            errors={errors}
            title="Accepted amount range"
            initialValues={{
                acceptedAmounts: {
                    min: paymentLimits.minimum,
                    max: paymentLimits.maximum
                }
            }}
            initialValidation={{
                acceptedAmounts: validate()
                    .when(val => !(val && val.min && val.max && Number(val.min) > Number(val.max)),
                        "Minimum amount must be smaller than Maximum amount"
                    )
                    .when(val => val.max <= BillerAcceptedAmountsRange.MAX,
                        `Maximum amount cannot exceed ${currencyUtil.formatAmount(BillerAcceptedAmountsRange.MAX)}`
                    )
                    .when(val => val.max >= BillerAcceptedAmountsRange.MIN,
                        `Maximum amount cannot be less than ${currencyUtil.formatAmount(BillerAcceptedAmountsRange.MIN)}`
                    )
                    .when(val => val.min >= BillerAcceptedAmountsRange.MIN,
                        `Minimum amount cannot be less than ${currencyUtil.formatAmount(BillerAcceptedAmountsRange.MIN)}`
                    )
            }}
            renderButtons={() => <>
                <SubmitButton loading={loading} primary>Save</SubmitButton>
                <Button onClick={onClose}>Cancel</Button>
            </>}
        >
            <CurrencyRangeField
                name="acceptedAmounts"
                label="Accepted amount"
                placeholders={["Minimum", "Maximum"]}
            />
            <FormErrorList />

        </FormDialog>
    );
};

export default AcceptedAmountModal;
