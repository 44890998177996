import { FieldError } from 'api/mapErrors';
import errorMaps from 'platforms/current/constants/errorMaps';

export function applyErrorMaps(errors: FieldError[]): FieldError[] {
    return errors.map(r => r.code && errorMaps[r.code]
        ? {
            ...r,
            message: errorMaps[r.code]({ label: "{label}" })
        }
        : r
    );
}