import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { NavItem } from 'models';
import Menu from './Menu';
import MenuItemExpander from './MenuItemExpander';
import MenuItemSeparator from './MenuItemSeparator';
import MenuLink from './MenuLink';
import './MenuItem.scss';

type Props = {
    item: NavItem,
    isSubMenu?: boolean,
    nav?: boolean,
    onLinkClick?: () => void,
};

const MenuItem = ({
    item, isSubMenu, nav, onLinkClick,
}: Props) => {

    const location = useLocation();
    let hasSubmenu = item.items?.length;
    let hasActiveChild = !!(hasSubmenu && item.items?.filter((i : any) => i.href && location.pathname.startsWith(i.href))?.length);

    const [subMenuIsExpanded, setSubMenuIsExpanded] = useState(hasActiveChild && nav);

    // expand/unexpand tabs when the route changes
    useEffect(() => {
        setSubMenuIsExpanded(hasActiveChild && nav);
    }, [location, hasActiveChild, nav])

    function handleMenuItemExpanded() {
        setSubMenuIsExpanded(!subMenuIsExpanded);
    }

    function getItemContent() {
        var containerClasses = classNames({
            "menu-item-content": true,
            "nav-menu-item-content": nav,
            'sub-menu-item': isSubMenu
        })

        if (hasSubmenu && item.items) {
            return (
                <MenuItemExpander
                    subMenuIsExpanded={subMenuIsExpanded}
                    onClick={handleMenuItemExpanded}
                    items={item.items}
                    className={containerClasses}
                >
                    {item.text}
                </MenuItemExpander>
            );
        }

        if (item.href) {
            return (
                <MenuLink onClick={onLinkClick} href={item.href} newWindow={item.newWindow} className={containerClasses}>
                    {item.text}
                </MenuLink>
            );
        }

        return (<div>{item.text}</div>);
    }

    return (
        <li>
            {item.text
                ? getItemContent()
                : <MenuItemSeparator />
            }

            {hasSubmenu && item.items && subMenuIsExpanded && (
                <Menu items={item.items} isSubMenu nav={nav} onLinkClick={onLinkClick} />
            )}
        </li>
    );
}

export default MenuItem;