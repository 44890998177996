import React  from "react";
import classNames from "classnames";
import { useField } from "formik";
import "./Input.scss";

interface TextAreaProps {
    name : string;
    noSpinner? : boolean;
    disabled? : boolean;
    plaintext? : boolean;
    className?: string;
    value? : string;
    type? : string;
    resize? : boolean;
    [otherProps : string]: any;
}

const TextArea : React.FC<TextAreaProps> = ({ name, className, value, type, noSpinner, disabled, plaintext, resize=true, ...otherProps }) => {
    const [ , meta] = useField(name);
    return (
        <>
            <textarea {...otherProps}
                name={name}
                value={value ?? ""}
                className={
                    classNames("textbox form-control", {
                        "has-error": meta.touched && !!meta.error?.length,
                        "no-number-spinner": noSpinner,
                        "form-control-plaintext": plaintext,
                        "textarea-no-resize": !resize
                    }, className)
                }
                disabled={disabled || plaintext}
            />
        </>
    );
};

export default TextArea;