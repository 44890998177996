export type Platform = 'cba' | 'stationery_shop' | 'merchantsuite';

export function getPlatform(): Platform {
    return process.env.REACT_APP_PLATFORM as Platform;
}

export function map<T>(map: {[P in Platform]: T}) {
    const platform = getPlatform();
    const ret = map[platform];
    if (!ret)
        throw new Error(`Module not available for ${platform}`);
    return ret;
}
