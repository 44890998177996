import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Navigate } from 'react-router-dom';
import _ from 'lodash';

import { Form, SubmitButton } from '@premier/form';
import { PageSection, ErrorText, Link, Dialog, Icon, Button } from '@premier/ui';
import { PageHeader, FormError } from 'components/Common';
import { AddRecordsForm } from 'components/Batch';

import * as batchActions from 'components/Batch/_actions/batchActions';
import { PlatformRoutesConfiguration } from 'components/Routing';


const BatchAppendRecords = ({ actions, file }) => {
    const [errors, setErrors] = useState([]);
    const [formErrors, setFormErrors] = useState([]);
    const [isExiting, setIsExiting] = useState(false);
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
    const [redirectBackToLibraryFile, setRedirectBackToLibraryFile] = useState(false);

    useEffect(() => {
        if(!file.filename) {
            actions.getBatchLibraryFileDetails(file.fileId).catch(err => {
                setRedirectBackToLibraryFile(true);
            });
        }
    }, [file.fileId]);

    const batchErrorFormat = /BatchRecords\[(\d+)\].(.+)/;
    const batchLibraryFileUrl = PlatformRoutesConfiguration.transactionRoute.batchLibraryFile.generatePath(file.fileId);

    function parseErrors(err) {
        return err.map(e => {
            let match = e.parameter.match(batchErrorFormat);
            return {
                batchId: 0,
                recordId: match[1],
                property: match[2]
            };
        });
    }

    function handleSubmit(values) {
        let records = [];
        for (let prop in values.records) {
            //Use no batch actions as a flag the row is empty (untouched).
            if (values.records[prop] && !values.records[prop].noBatchActions) {
                records.push(Object.assign({}, { ...values.records[prop], id: prop }));
            }
        }

        if (records.length === 0) return;

        setErrors([]);
        setFormErrors([]);

        actions.addBatchRecords(file.fileId, records)
            .then(() => {
                setRedirectBackToLibraryFile(true);
            })
            .catch(errors => {
                let recordErrors = errors.filter(e => e.parameter && e.parameter.match(batchErrorFormat));
                setFormErrors(errors.filter(e => !(e.parameter && e.parameter.match(batchErrorFormat))));
                setErrors(parseErrors(recordErrors));
            });
    }

    /* {usePrompt('You have unsaved changes, are you sure you want to leave?', hasUnsavedChanges && !isExiting)} */
    return (
        <>

            {redirectBackToLibraryFile && <Navigate to={batchLibraryFileUrl} />}
            <PageSection>
                <PageHeader
                    backButton={hasUnsavedChanges ? {
                        onClick: () => setIsExiting(true),
                        label: 'Back'
                    } : {
                        to: batchLibraryFileUrl,
                        label: 'Back'
                    }}
                />
            </PageSection>

            <Form
                initialValues={{ records: {} }}
                onSubmit={handleSubmit}
                submitOnError
            >
                <PageSection>
                    <h2>{file.filename}</h2>
                </PageSection>

                <PageSection>
                    <h2>Batch records</h2>
                    <FormError apiErrors={formErrors}></FormError>
                    <AddRecordsForm
                        errors={errors}
                        onUnsavedRecordsChanged={e => {
                            setHasUnsavedChanges(e);
                        }}
                    ></AddRecordsForm>
                </PageSection>

                <PageSection>
                    <SubmitButton>Save</SubmitButton>
                    {hasUnsavedChanges ? (
                        <Button onClick={() => setIsExiting(true)}>Cancel</Button>
                    ) : (
                        <Link button to={batchLibraryFileUrl}>
                            Cancel
                        </Link>
                    )}
                    {errors && errors.length > 0 && (
                        <ErrorText>
                            One or more records have errors or are incomplete. See highlighted fields. Please correct
                            the errors and click ‘Save’ button again
                        </ErrorText>
                    )}
                </PageSection>

                <Dialog
                    show={isExiting}
                    closeButton
                    onClose={() => setIsExiting(false)}
                    icon={<Icon alert />}
                    title='Changes to batch file are not saved'
                    footerButtons={<>
                        <Button primary onClick={() => setIsExiting(false)}>Continue editing</Button>
                        <Link subtle to={batchLibraryFileUrl}>Discard changes</Link>
                    </>}
                >
                    Leaving this page without saving will discard your unsaved changes.
                </Dialog>
            </Form>
        </>
    );
};

function mapStateToProps(state, ownProps) {
    let fileId = ownProps.match.params.id;
    let file = { fileId };

    if(_.get(state.transactions.batch, 'batchFileResult.fileDetails') &&
       state.transactions.batch.batchFileResult.fileDetails.fileId === +fileId
      ) {
        file = state.transactions.batch.batchFileResult.fileDetails;
    }

    return {
        file: file,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(batchActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(BatchAppendRecords);
