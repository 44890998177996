import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import currencyUtil from '@premier/utils/currency';
import { Button, Dialog, Icon } from '@premier/ui';
import { FormDialog, FormErrorList, SubmitButton, validate, CheckboxField, RadioField, CurrencyField } from '@premier/form';
import './LCRConfigurationDialog.scss';
import LCRConfigurationApi from 'api/LCRConfigurationApi';
import { PrimaryNetwork, getLCRConfigurationContent } from './LCRConfigurationSection';
import { Divider } from 'components/Common';


const LCRConfigurationDialog = ({
    show, onClose, lcrConfigurationModel, allowAmountThreshold
}) => {
    const [isMasterCardThresholdSet, setIsMasterCardThresholdSet] = useState(false);
    const [isVisaThresholdSet, setIsVisaThresholdSet] = useState(false);
    const [masterCardThresholdAmount, setMasterCardThresholdAmount] = useState(0);
    const [visaThresholdAmount, setVisaThresholdAmount] = useState(0);
    const [showConfirmSave, setShowConfirmSave] = useState(false);
    const [visaPrimaryNetwork, setVisaPrimaryNetwork] = useState(PrimaryNetwork.VISA);
    const [masterCardPrimaryNetwork, setMasterCardPrimaryNetwork] = useState(PrimaryNetwork.MASTERCARD);

    useEffect(() => {
        setVisaPrimaryNetwork(lcrConfigurationModel?.visaPrimaryNetwork);
        setMasterCardPrimaryNetwork(lcrConfigurationModel?.masterCardPrimaryNetwork);
        setIsMasterCardThresholdSet(allowAmountThreshold && lcrConfigurationModel?.isMasterCardThresholdSet);
        setIsVisaThresholdSet(allowAmountThreshold && lcrConfigurationModel?.isVisaThresholdSet);
        setMasterCardThresholdAmount(allowAmountThreshold ? currencyUtil.parseAmount(lcrConfigurationModel?.masterCardThresholdAmount) : 0);
        setVisaThresholdAmount(allowAmountThreshold ? currencyUtil.parseAmount(lcrConfigurationModel?.visaThresholdAmount) : 0);
    }, [lcrConfigurationModel]);

    async function confirmSaveHandler() {
        const model = {
            vcPrimaryNetwork: visaPrimaryNetwork === PrimaryNetwork.VISA ? 0 : 1,
            vcMaxAmountSet: isVisaThresholdSet ?? false,
            vcMaxAmount: isVisaThresholdSet ? currencyUtil.convertToApiValue(visaThresholdAmount) ?? 0 : 0,
            mcPrimaryNetwork: masterCardPrimaryNetwork === PrimaryNetwork.MASTERCARD ? 0 : 1,
            mcMaxAmountSet: isMasterCardThresholdSet ?? false,
            mcMaxAmount: isMasterCardThresholdSet ? currencyUtil.convertToApiValue(masterCardThresholdAmount) ?? 0 : 0,
        };
        setShowConfirmSave(false);
        await LCRConfigurationApi.updateLCRConfiguration(model);
        onClose(false);
    }

    function handleCancel() {
        setIsMasterCardThresholdSet(false);
        setIsVisaThresholdSet(false);
        setMasterCardThresholdAmount(0);
        setVisaThresholdAmount(0);
        setVisaPrimaryNetwork(PrimaryNetwork.VISA);
        setMasterCardPrimaryNetwork(PrimaryNetwork.MASTERCARD);
        onClose(false);
    }

    if (showConfirmSave)
        return <Dialog
            icon={<Icon info />}
            title='Confirm and save?'
            show={showConfirmSave}
            footerButtons={<>
                <SubmitButton onClick={confirmSaveHandler}>Confirm</SubmitButton>
                <Button onClick={() => {
                    setShowConfirmSave(false);
                }}>Back</Button>
            </>}>
            <p>
                Please confirm you would like to update your Least Cost Routing settings.
            </p>
            <div className='set-transaction-threshold'>
                <p>
                    For Mastercard dual network debit cards,

                    {!isMasterCardThresholdSet && masterCardPrimaryNetwork === PrimaryNetwork.MASTERCARD && " all transactions will be processed via the Mastercard network."}
                    {!isMasterCardThresholdSet && masterCardPrimaryNetwork === PrimaryNetwork.EFTPOS && " all transactions will be processed via the eftpos network."}
                    {isMasterCardThresholdSet && masterCardPrimaryNetwork === PrimaryNetwork.MASTERCARD &&
                        getLCRConfigurationContent(masterCardThresholdAmount, 'eftpos', 'Mastercard')
                    }
                    {isMasterCardThresholdSet && masterCardPrimaryNetwork === PrimaryNetwork.EFTPOS &&
                        getLCRConfigurationContent(masterCardThresholdAmount, 'Mastercard', 'eftpos')
                    }

                </p>
                <p>

                    For Visa dual network debit cards,
                    {!isVisaThresholdSet && visaPrimaryNetwork === PrimaryNetwork.VISA && " all transactions will be processed via the Visa network."}
                    {!isVisaThresholdSet && visaPrimaryNetwork === PrimaryNetwork.EFTPOS && " all transactions will be processed via the eftpos network."}
                    {isVisaThresholdSet && visaPrimaryNetwork === PrimaryNetwork.VISA &&
                        getLCRConfigurationContent(visaThresholdAmount, 'eftpos', 'Visa')
                    }
                    {isVisaThresholdSet && visaPrimaryNetwork === PrimaryNetwork.EFTPOS &&
                        getLCRConfigurationContent(visaThresholdAmount, 'Visa', 'eftpos')
                    }
                </p>
            </div>
            <br />
            <div className='disclaimer-section'>
                <b>‘Things you need to know’</b><br />
                By enabling or updating your Least Cost Routing (“LCR”) options for your transactions you have read and understood the Merchant Agreement and the related LCR Terms and Conditions available at<a href="https://www.commbank.com.au/merchantagreement" target="_blank"> www.commbank.com.au/merchantagreement</a>. You also warrant that you are authorised to enable and update your LCR options for this BPOINT merchant facility.<br /><br />


                We cannot advise you which network will be best for you and we cannot guarantee cost savings. You must understand your pricing associated with Visa, Mastercard and eftpos card transactions and set your own Multi-Network Debit Card transaction routing preference. Should you choose to route any of your card transactions via the eftpos network, please note the following:<br /><br />
                <ul>
                    <li>3D Secure is currently not available for one-off BPOINT payment transactions when routed via eftpos.</li>
                    <li>If you choose to surcharge for transactions, the Visa/Mastercard debit surcharge value will be applied to transactions routed to eftpos. When you receive your first monthly statement after switching on LCR you will need to ensure the Visa debit and Mastercard debit surcharge rate is set to the lowest rate between Visa debit and eftpos & Mastercard debit and eftpos.</li>
                    <li>Whilst same day settlement is available for schemes, Mastercard and Visa settlement will include transactions made before 5:30 PM (Sydney/Melbourne time) and eftpos will include transactions made before 9:45 PM (Sydney/Melbourne time).</li>
                </ul>
            </div>

        </Dialog>

    return (
        <FormDialog
            show={show}
            title='Edit Least Cost Routing settings'
            initialValues={{
                masterCardDual: masterCardPrimaryNetwork,
                masterCardThreshold: isMasterCardThresholdSet,
                masterCardThresholdAmount: masterCardThresholdAmount,
                visaDual: visaPrimaryNetwork,
                visaThreshold: isVisaThresholdSet,
                visaThresholdAmount: visaThresholdAmount,
            }}
            initialValidation={{
                masterCardThresholdAmount: validate().if((val, values) => values.masterCardThreshold === true,
                    validate()
                        .greaterThan(0, 'Amount should exceed 0.00.')
                        .lessThanOrEqual(21474836.47)
                        .required()
                ),
                visaThresholdAmount: validate().if((val, values) => values.visaThreshold === true,
                    validate()
                        .greaterThan(0, 'Amount should exceed 0.00.')
                        .lessThanOrEqual(21474836.47)
                        .required()
                ),
            }}
            onClose={handleCancel}
            onSubmit={() => { setShowConfirmSave(true) }}
            renderButtons={() => <>
                <SubmitButton>Save</SubmitButton>
                <Button onClick={handleCancel}>Cancel</Button>
            </>}
        >

            <RadioField
                inlineUpSm
                name='masterCardDual'
                label='Select LCR options for Mastercard dual network debit cards'
                options={[
                    { value: PrimaryNetwork.MASTERCARD, label: 'Mastercard' },
                    { value: PrimaryNetwork.EFTPOS, label: 'eftpos' }
                ]}
                onChange={setMasterCardPrimaryNetwork}
                description='Choose a default network'
            />

            {allowAmountThreshold && <div className='set-transaction-threshold'>
                <CheckboxField name='masterCardThreshold' onChange={setIsMasterCardThresholdSet}>
                    Set a transaction threshold limit for default network
                </CheckboxField>

                {!isMasterCardThresholdSet &&
                    <>
                        <Divider />
                        <p>
                            For Mastercard dual network debit cards, all transactions will be processed by the {masterCardPrimaryNetwork === PrimaryNetwork.MASTERCARD ? "Mastercard network" : "eftpos network"}
                        </p>
                    </>
                }
                {isMasterCardThresholdSet &&
                    <div>
                        <div className='currency-configuration'>
                            <span className='currency-label'>Where amount is </span>

                            <CurrencyField
                                maxLength={11}
                                name='masterCardThresholdAmount'
                                className='currency-field'
                                onChange={setMasterCardThresholdAmount}
                            />
                        </div>
                        <Divider />
                        <p>
                            For Mastercard dual network debit cards,
                            {getLCRConfigurationContent(masterCardThresholdAmount, masterCardPrimaryNetwork === PrimaryNetwork.MASTERCARD ? "eftpos" : "Mastercard", masterCardPrimaryNetwork === PrimaryNetwork.MASTERCARD ? "Mastercard" : "eftpos")}
                        </p>
                    </div>
                }

            </div>}

            <RadioField
                inlineUpSm
                name='visaDual'
                label='Select LCR options for Visa dual network debit cards'
                options={[
                    { value: PrimaryNetwork.VISA, label: 'Visa' },
                    { value: PrimaryNetwork.EFTPOS, label: 'eftpos' }
                ]}
                onChange={setVisaPrimaryNetwork}
                description='Choose a default network'
                className='set-visa-configuration'
            />

            {allowAmountThreshold && <div className='set-transaction-threshold'>
                <CheckboxField name='visaThreshold' onChange={setIsVisaThresholdSet}>
                    Set a transaction threshold limit for default network
                </CheckboxField>

                {!isVisaThresholdSet && <>
                    <Divider />
                    <p>
                        For Visa dual network debit cards, all transactions will be processed by the {visaPrimaryNetwork === PrimaryNetwork.VISA ? "Visa network" : "eftpos network"}
                    </p>
                </>
                }
                {isVisaThresholdSet &&
                    <div>
                        <div className='currency-configuration'>
                            <span className='currency-label'>Where amount is </span>

                            <CurrencyField
                                maxLength={11}
                                name='visaThresholdAmount'
                                className='currency-field'
                                onChange={setVisaThresholdAmount}
                            />
                        </div>
                        <Divider />
                        <p>
                            For Visa dual network debit cards,
                            {getLCRConfigurationContent(visaThresholdAmount, visaPrimaryNetwork === PrimaryNetwork.VISA ? "eftpos" : "Visa", visaPrimaryNetwork === PrimaryNetwork.VISA ? "Visa" : "eftpos")}
                        </p>
                    </div>
                }
            </div>}

            <FormErrorList />
        </FormDialog>
    );
};

LCRConfigurationDialog.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func,
};


export default LCRConfigurationDialog;