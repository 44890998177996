import { ReactNode, useEffect } from "react";
import classNames from "classnames";
import ReactTooltip, { TooltipProps } from "react-tooltip";  // https://github.com/wwayne/react-tooltip
import "./Tooltip.scss";

type Props = TooltipProps & {
    /** Default = top. You can also use data-place prop on the trigger element. */
    place?: "top" | "right" | "bottom" | "left";
    /** Float = move with mouse. Default = solid */
    effect?: "float" | "solid";
    /** Delay hiding the tooltip in milliseconds (Default = 100) */
    delayHide?: number;
    className?: string;
    overridePosition?: ReactNode;
    children?: ReactNode;
};

/** See the Storybook for usage */
const Tooltip = ({
    effect="solid", delayHide, children, ...otherProps
}: Props) => {

    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    useEffect(() => {
        ReactTooltip.rebuild();
    }, [children]);

    return (
        <ReactTooltip
            effect={effect}
            delayHide={delayHide || 100}
            event={classNames("mouseover focusin", { "touchstart": isMobile })}
            eventOff="mouseout focusout"
            globalEventOff={isMobile ? "touchstart" : undefined}
            overridePosition={( // Prevents truncation of tooltip prioritising the top left part of the tooltip, no effect on large screens
                { left, top },
                currentEvent, currentTarget, node) => {
                // {left, top} refers to the default position as calculated by react-tooltip.
                // Node refers to actual tooltip object that gets drawn on the browser.
                const d = document.documentElement;
                left = Math.min(d.clientWidth - node!.clientWidth, left);
                top = Math.min(d.clientHeight - node!.clientHeight, top);
                left = Math.max(2, left);
                top = Math.max(2, top);
                return { top, left };
            }}
            {...otherProps}
        >
            {children && (
                <div className="tooltip-content">
                    {children}
                </div>
            )}
        </ReactTooltip>
    );
};

export default Tooltip;