import React from 'react';
import classNames from 'classnames';
import './CustomerAddress.scss';

type Address = {
    customerName?: string,
    street1?: string,
    street2?: string,
    suburb?: string,
    state?: string,
    postcode?: string,
};

type CustomerAddressProps = {
    address: Address,
    className?: string,
};

const CustomerAddress = ({
    address, className
}: CustomerAddressProps) => {

    function getStreetAddress(addr: Address) {
        return addr && [
            addr.street1,
            addr.street2,
            addr.suburb,
            ((addr.state ?? '') + ' ' + (addr.postcode ?? '')).trim()
        ].filter(x => x).join(', ');
    }

    return (
        <div className={classNames('customer-address', className)}>
            <div className='customer-name'>{address.customerName}</div>
            <div>{getStreetAddress(address)}</div>
        </div>
    );
};

export default CustomerAddress;