import * as actions from './actionTypes';
import { stationeryShopApi } from 'api';


//#region ========== Categories ==========

export function getStationeryCategories() {
    return function(dispatch) {
        dispatch(loadStationeryCategoriesRequested());
        return stationeryShopApi.getStationeryCategories().then((result) => {
            if (result.ok) {
                dispatch(loadStationeryCategoriesSuccess(result.val));
                return {categories: result.val};
            } else {
                dispatch(loadStationeryCategoriesFailed(result.val));
                throw result.val;
            }
        });
    }
}

export function loadStationeryCategoriesRequested() {
    return { type: actions.GET_STATIONERY_CATEGORIES_REQUESTED }
}
export function loadStationeryCategoriesSuccess(stationeryCategories) {
    return {type: actions.GET_STATIONERY_CATEGORIES_SUCCESS, stationeryCategories};
}
export function loadStationeryCategoriesFailed(err) {
    return {type: actions.GET_STATIONERY_CATEGORIES_FAILED, err};
}

//#endregion


//#region ========== Products + Freight ==========

export function getStationeryProducts(idList) {
    return function(dispatch) {
        dispatch(loadStationeryProductsRequested());
        return stationeryShopApi.getStationeryProducts(idList).then((result) => {
            if (result.ok) {
                dispatch(loadStationeryProductsSuccess(result.val));
                return {products: result.val};
            } else {
                dispatch(loadStationeryProductsFailed(result.val));
                throw result.val;
            }
        });
    }
}

export function getStationeryFreightFee() {
    return function(dispatch){
        dispatch(getFreightFeeRequested());
        return stationeryShopApi.getFreightFee().then((result) => {
            if (result.ok) {
                dispatch(getFreightFeeSuccess(result.val));
                return result.val;
            } else {
                dispatch(getFreightFeeFailed(result.val));
                throw result.val;
            }
        });
    }
}

export function loadStationeryProductsRequested() {
    return { type: actions.GET_STATIONERY_PRODUCTS_REQUESTED }
}
export function loadStationeryProductsSuccess(stationeryProducts) {
    return {type: actions.GET_STATIONERY_PRODUCTS_SUCCESS, stationeryProducts};
}
export function loadStationeryProductsFailed(err) {
    return {type: actions.GET_STATIONERY_PRODUCTS_FAILED, err};
}
export function getFreightFeeRequested(){
    return { type: actions.GET_STATIONERY_FREIGHT_REQUESTED };
}
export function getFreightFeeSuccess(freightProduct){
    return { type: actions.GET_STATIONERY_FREIGHT_SUCCESS, freightProduct }
}
export function getFreightFeeFailed(err){
    return { type: actions.GET_STATIONERY_FREIGHT_FAILED, err };
}

//#endregion


//#region ========== Shopping Cart ==========

export function getCart() {
    return function(dispatch){
        dispatch(getCartRequested());
        return stationeryShopApi.getCart()
            .then(cart => {
                dispatch(getCartSuccess(cart));
                return cart;
            })
            .catch(err => {
                dispatch(getCartFailed(err));
                throw err;
            });
    }
}

export function addToCart(productId, qty, maxQty=99) {
    return function(dispatch){
        dispatch(addToCartRequested());
        return stationeryShopApi.addToCart([{productId, qty, maxQty}])
            .then(cart => {
                dispatch(addToCartSuccess(cart));
                return cart;
            })
            .catch(err => {
                dispatch(addToCartFailed(err));
                throw err;
            });
    }
}

export function addItemsToCart(products) {
    return function(dispatch){
        dispatch(addToCartRequested());
        return stationeryShopApi.addToCart(products)
            .then(cart => {
                dispatch(addToCartSuccess(cart));
                return cart;
            })
            .catch(err => {
                dispatch(addToCartFailed(err));
                throw err;
            });
    }
}

export function updateCartItemQty(productId, qty) {
    return function(dispatch){
        dispatch(updateCartItemQtyRequested());
        return stationeryShopApi.updateCartItemQty(productId, qty)
            .then(cart => {
                dispatch(updateCartItemQtySuccess(cart));
                return cart;
            })
            .catch(err => {
                dispatch(updateCartItemQtyFailed(err));
                throw err;
            });
    }
}

export function clearCart(){
    return function(dispatch){
        dispatch(clearCartRequested());
        return stationeryShopApi.clearCart()
            .then(() => {
                dispatch(clearCartSuccess());
                return;
            })
            .catch(err => {
                dispatch(clearCartFailed(err));
                throw err;
            });
    }
}


export function getCartRequested() {
    return { type: actions.GET_CART_REQUESTED };
}
export function getCartSuccess(cart) {
    return { type: actions.GET_CART_SUCCESS, cart };
}
export function getCartFailed(err) {
    return { type: actions.GET_CART_FAILED, err };
}

export function addToCartRequested() {
    return { type: actions.ADD_TO_CART_REQUESTED };
}
export function addToCartSuccess(cart) {
    return { type: actions.ADD_TO_CART_SUCCESS, cart };
}
export function addToCartFailed(err) {
    return { type: actions.ADD_TO_CART_FAILED, err };
}

export function updateCartItemQtyRequested() {
    return { type: actions.UPDATE_CART_ITEM_QTY_REQUESTED };
}
export function updateCartItemQtySuccess(cart) {
    return { type: actions.UPDATE_CART_ITEM_QTY_SUCCESS, cart };
}
export function updateCartItemQtyFailed(err) {
    return { type: actions.UPDATE_CART_ITEM_QTY_FAILED, err };
}

export function clearCartRequested() {
    return { type: actions.CLEAR_CART_REQUESTED };
}
export function clearCartSuccess() {
    return { type: actions.CLEAR_CART_SUCCESS };
}
export function clearCartFailed(err) {
    return { type: actions.CLEAR_CART_FAILED, err };
}

//#endregion


//#region ========== Orders ==========

export function createOrder(order) {
    return function(dispatch){
        dispatch(createOrderRequested());
        return stationeryShopApi.createOrder(order).then(result => {
            if (result.ok) {
                dispatch(createOrderSuccess(result.val));
                return result.val;
            } else {
                dispatch(createOrderFailed(result.val));
                throw result.val;
            }
        });
    }
}

export function loadOrders(pageIndex, resultsPerPage, sort) {
    return function(dispatch){
        dispatch(loadStationeryOrdersRequested());
        return stationeryShopApi.getOrders(pageIndex, resultsPerPage, sort)
            .then((result) => {
                if (result.ok) {
                    dispatch(loadStationeryOrdersSuccess(result.val.orders));
                    return { orders: result.val.orders };
                } else {
                    dispatch(loadStationeryOrdersFailed(result.val));
                    throw result.val;
                }
            });
    }
}

export function loadOrderDetails(orderId) {
    return function(dispatch){
        dispatch(loadOrderDetailRequested());
        return stationeryShopApi.getOrder(orderId).then((result) => {
            if (result.ok) {
                let orderDetail = result.val;
                // Map backend model to frontend model (so orderDetails object matches cartItems object in cookie)
                if(orderDetail && orderDetail.items) {
                    orderDetail.items.forEach(i => {
                        i.qty = i.quantity;
                    });
                }

                dispatch(loadOrderDetailSuccess(orderDetail));
                return { orderDetail };
            } else {
                dispatch(loadOrderDetailFailed(result.val));
                throw result.val;
            }
        });
    }
}

export function saveOrdersPageState(pageState){
    return function(dispatch) {
        dispatch({type: actions.SAVE_ORDERS_PAGE_STATE, pageState});
    };
}

export function getRecentAddresses() {
    return function(dispatch) {
        dispatch(getRecentAddressesRequested());
        return stationeryShopApi.getRecentAddresses().then((result) => {
            if (result.ok) {
                dispatch(getRecentAddressesSuccess(result.val));
                return result.val;
            } else {
                dispatch(getRecentAddressesFailed(result.val));
                throw result.val;
            }
        });
    }
}

export function setDeliveryAddress(address) {
    return function(dispatch){
        dispatch({ type: actions.SET_DELIVERY_ADDRESS, address });
    }
}

export function createOrderRequested(){
    return { type: actions.CREATE_ORDER_REQUESTED }
}
export function createOrderSuccess(orderDetail){
    return { type: actions.CREATE_ORDER_SUCCESS, orderDetail }
}
export function createOrderFailed(err){
    return { type: actions.CREATE_ORDER_FAILED, err }
}
export function loadStationeryOrdersRequested(){
    return { type: actions.GET_STATIONERY_ORDERS_REQUESTED };
}
export function loadStationeryOrdersSuccess(orders){
    return { type: actions.GET_STATIONERY_ORDERS_SUCCESS, orders }
}
export function loadStationeryOrdersFailed(err){
    return { type: actions.GET_STATIONERY_ORDERS_FAILED, err };
}
export function loadOrderDetailRequested(){
    return { type: actions.GET_ORDER_DETAIL_REQUESTED };
}
export function loadOrderDetailSuccess(orderDetail) {
    return { type: actions.GET_ORDER_DETAIL_SUCCESS, orderDetail }
}
export function loadOrderDetailFailed(err){
    return { type: actions.GET_ORDER_DETAIL_FAILED, err };
}
export function getRecentAddressesRequested(){
    return { type: actions.GET_RECENT_ADDRESSES_REQUESTED }
}
export function getRecentAddressesSuccess(addresses){
    return { type: actions.GET_RECENT_ADDRESSES_SUCCESS, addresses }
}
export function getRecentAddressesFailed(err){
    return { type: actions.GET_RECENT_ADDRESSES_FAILED, err }
}

//#endregion
