import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Navigate } from 'react-router-dom';

import { SingleColumnFormContainer, LoadingIndicator, DescriptionList, SuccessModal } from '@premier/ui';
import { UserDetailsForm } from 'components/Settings';
import { ConfirmPasswordModal, PageHeader } from 'components/Common';

import * as billpayUtil from '@premier/utils/billpay';

import * as userActions from 'components/Settings/_actions/userActions';
import { PlatformRoutesConfiguration } from 'components/Routing';

import labels from 'constants/labels';

const EditUserPage = ({
    userActions, //API actions
    userId, //URL param
    userDetails, merchant //state values
}) => {

    const [showPasswordDialog, setShowPasswordDialog] = useState(false);
    const [showSuccessfulModal, setShowSuccessfulModal] = useState(false);
    const [newUserDetails, setNewUserDetails] = useState({});
    const [savingUserDetails, setSavingUserDetails] = useState(false);
    const [redirect, setRedirect] = useState(false);


    useEffect(() => {
        userActions.getUser(userId);
    }, [userActions]);


    function handlePasswordConfirmed(values) {
        setSavingUserDetails(true)
        let model = {
            currentPassword: values.currentPassword,
            userId: userId,
            ...newUserDetails
        };

        userActions.editUser(model)
            .then(() => {
                setShowPasswordDialog(false);
                setShowSuccessfulModal(true);
            })
            .catch(err => {
                console.error(err);
                setSavingUserDetails(false);
                setShowPasswordDialog(false);
            });
    }

    function handleDetailsSave(values) {
        setNewUserDetails(values);
        setShowPasswordDialog(true);
    }

    function handlePasswordModalClosed() {
        setNewUserDetails({});
        setShowPasswordDialog(false);
    }

    function handleSuccessModalHide() {
        setShowSuccessfulModal(false);
        setRedirect(true);
    }

    function getPrefixDetails() {
        let merchantName = billpayUtil.getMerchantNameFromNumber(merchant, userDetails.merchantNumber);

        return [
            { name: labels.merchant + ":", value: merchantName }
        ];
    }


    if (redirect)
        return <Navigate to={PlatformRoutesConfiguration.usersRoute.viewUser.generatePath(userId)} />;

    if (!userDetails)
        return <LoadingIndicator />;

    return (<>
        <SingleColumnFormContainer>
            <PageHeader backButton title={billpayUtil.getCustomerFullName(userDetails)} />
            <DescriptionList greyLabel items={getPrefixDetails()} />

            <UserDetailsForm
                userDetails={userDetails}
                onSubmit={handleDetailsSave}
                onCancel={() => setRedirect(true)}
            />
        </SingleColumnFormContainer>

        <ConfirmPasswordModal
            title='Confirm changes'
            show={showPasswordDialog}
            onClose={handlePasswordModalClosed}
            onSubmit={handlePasswordConfirmed}
            isProcessing={savingUserDetails}
        />

        <SuccessModal
            show={showSuccessfulModal}
            title='Changes saved successfully'
            onClose={handleSuccessModalHide}
            closeButton
        />
    </>);
}

function mapStateToProps(state, ownProps) {
    return {
        merchant: state.accounts.users.merchant,
        userDetails: state.settings.user.details,
        userId: ownProps.match.params.id
    };
}

function mapDispatchToProps(dispatch) {
    return {
        userActions: bindActionCreators(userActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditUserPage);