import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getCustomer } from '../_actions/customerActions';
import * as paramUtil from '@premier/utils/param';
import SecurityUtil from '@premier/utils/security';
import { userRoles } from 'components/Routing';

const useCustomer = () => {
    const dispatch = useDispatch();
    const authenticatedUser = useSelector((state: any) => state.accounts.users.authenticatedUser);
    const { isLoading, errors, details } = useSelector((state: any) => state?.dataVault?.customer);
    const { customerId } = useParams<{ customerId: string }>();

    useEffect(() => {
        if (!SecurityUtil.hasAccess([userRoles.dataVault], authenticatedUser)) return;
        if (!details || details?.customerId !== paramUtil.asInteger(customerId)) {
            dispatch(getCustomer(customerId));
        }
    }, [authenticatedUser, customerId, details, dispatch]);

    return {
        isLoading,
        errors,
        details: details?.customerId !== paramUtil.asInteger(customerId) ? undefined : details,
    };
};

export default useCustomer;
