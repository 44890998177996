import React, { ReactNode } from 'react';
import classNames from 'classnames';
import Mandatory from './Mandatory';
import ErrorMessage from './ErrorMessage';
import './FormCheck.scss';

export type FormCheckProps = {
    fieldId: string,
    label?: ReactNode,
    mandatory?: boolean,
    error?: string,
    className?: string,
    children?: ReactNode,
};

/** Not to be used directly. Renders a Bootstrap div.form-check, for wrapping a checkbox / radio */
const FormCheck = ({ fieldId, label, mandatory, error, className, children }: FormCheckProps) => {

    return (
        <div className={classNames('form-check',  className)}>
            {children}

            <label htmlFor={fieldId} className='form-check-label'>
                {label}
                {mandatory && <Mandatory />}
            </label>

            <ErrorMessage error={error} />
        </div>
    );
};

export default FormCheck;