import { userRoles } from 'components/Routing';
import { PaymentPlanSettingsPage, AddPaymentPlanOptionPage, EditPaymentPlanOptionPage } from 'components/Settings'
import { RouteConfigurationMap } from 'components/Routing';

export const paymentPlanSettingsRoute : RouteConfigurationMap = {
    root: {
        path:               '/settings/payment-plan/',
        generatePath: () => '/settings/payment-plan/',
        element: <PaymentPlanSettingsPage />,
        title: 'Payment plan settings',
        roles:              [userRoles.paymentPlanSettings]
    },
    landingPageBillerCodeSelected: {
        path:               '/settings/payment-plan/:billerCode',
        element: <PaymentPlanSettingsPage />,
        title: 'Payment plan settings',
        generatePath: (billerCode: string) => `/settings/payment-plan/${billerCode}`,
        roles:              [userRoles.paymentPlanSettings]
    },
    addPaymentPlanOption: {
        path:               '/settings/payment-plan/:billerCode/add-payment-plan-option',
        generatePath: (billerCode: string) => `/settings/payment-plan/${billerCode}/add-payment-plan-option`,
        element: <AddPaymentPlanOptionPage />,
        title: 'Payment plan settings',
        roles:              [userRoles.paymentPlanSettings]
    },
    editPaymentPlanOption: {
        path: '/settings/payment-plan/:billerCode/edit-payment-plan-option/:optionId',
        generatePath: (billerCode: string, optionId: string) => `/settings/payment-plan/${billerCode}/edit-payment-plan-option/${optionId}`,
        element: <EditPaymentPlanOptionPage />,
        title: 'Edit payment plan option',
        roles: [userRoles.paymentPlanSettings]
    }
};
