import { Link } from "@premier/ui";
import { BillerListContainer } from "..";
import { PlatformRoutesConfiguration } from "components/Routing";
import { Biller } from "packages/webapi-client";

type Props = {
    billers: Biller[];
}

/** The content of Branding landing page - for when the 'Set style by billers' is turned on */
const BrandingLandingByBiller = ({ billers }: Props) => {
    return (
        <BillerListContainer
            billers={billers}
            instruction="Click to edit the branding style for your first biller code"
            generateLink={b => PlatformRoutesConfiguration.designerRoute?.previewBillerBranding.generatePath(b.billerCode)}
        >
            <Link to={PlatformRoutesConfiguration.designerRoute?.editMerchantBranding.generatePath()}>Edit default style</Link> {/* TODO waiting for design */}
        </BillerListContainer>
    );
};

export default BrandingLandingByBiller;
