import Cookies from 'js-cookie';
import dateUtil from '@premier/utils/date';
import { PaginateRequestStationeryShopOrderListRequestOrderSummarySortFields, StationeryShopAddressModel, StationeryShopApiFactory,
    StationeryShopCategoryModel, StationeryShopOrderModel, StationeryShopOrderSummaryModel, StationeryShopProductModel,
    StationeryShopProductRequest } from '@premier/webapi-client';
import { ApiResult, mapResultErrors } from './mapErrors';
import { config, resultHandler, mapFromResult } from './util';
import { StationeryShopCart, StationeryShopCartItem } from 'models';

const MAX_QTY = 99;
const api = StationeryShopApiFactory(config);

class StationeryShopApi {

    static getStationeryCategories(): Promise<ApiResult<StationeryShopCategoryModel[]>> {
        return api.stationeryShopGetCategories().then(...resultHandler).then((r) => mapResultErrors(r));
    };

    static getStationeryProducts(id: number) {
        var dto: StationeryShopProductRequest = {
            categoryId: id,
            order: 'ASCENDING',
            sortField: 'ProductCode',
        };

        return api.stationeryShopGetProductsByCategory(dto)
            .then(...resultHandler).then((r) => mapResultErrors(r))
            .then(r => mapFromResult(r, products => ({
                products,
                resultCount: products.length,
            })));
    }

    static createOrder(order: StationeryShopOrderModel): Promise<ApiResult<StationeryShopOrderSummaryModel>> {
        return api.stationeryShopCreateOrder(order).then(...resultHandler);
    }

    static getOrders(
        pageIndex: number,
        resultsPerPage: number,
        sort: {
            field: 'orderStatus.description' | 'OrderStatus' | 'DateCreated' | 'OrderReference' | 'TotalAmount',
            descending: boolean,
        }
    ) {
        var fromDate = new Date();
        fromDate.setMonth(fromDate.getMonth() - 18);

        var toDate = new Date();
        toDate.setDate(toDate.getDate() + 1);

        var sortFieldKey = sort.field === 'orderStatus.description' ? 'OrderStatus' : sort.field;

        var dto: PaginateRequestStationeryShopOrderListRequestOrderSummarySortFields = {
            request: {
                fromDate: dateUtil.convertToApiValue(fromDate),
                toDate: dateUtil.convertToApiValue(toDate),
                orderReference: ''
            },
            sortField: sortFieldKey,
            order: sort.descending ? 'DESCENDING' : 'ASCENDING',
            pageIndex: pageIndex,
            pageSize: resultsPerPage
        };

        return api.stationeryShopGetOrders(dto)
            .then(...resultHandler).then((r) => mapResultErrors(r))
            .then(r => mapFromResult(r, orders => ({orders})));
    }

    static getOrder(orderId: number): Promise<ApiResult<StationeryShopOrderModel>> {
        return api.stationeryShopGetOrder(orderId).then(...resultHandler).then((r) => mapResultErrors(r));
    }

    static getRecentAddresses(): Promise<ApiResult<StationeryShopAddressModel[]>> {
        return api.stationeryShopGetRecentAddresses().then(...resultHandler).then((r) => mapResultErrors(r));
    }

    //#region ---------- Shopping Cart (Cookie only, no backend API) -----------

    static CART_EXPIRY_DAYS = 30;

    static getFreightFee(): Promise<ApiResult<StationeryShopProductModel>> {
        return api.stationeryShopGetFreightFee().then(...resultHandler).then((r) => mapResultErrors(r));
    };

    /** Get CartItems as an array (includes freight) */
    static getCart(): Promise<StationeryShopCart> {
        return new Promise((resolve, reject) => {
            resolve(Cookies.getJSON('cart'));
        });
    }

    static async initialiseCart(): Promise<StationeryShopCart> {
        var cart = Cookies.getJSON('cart');
        if (cart)
            return cart;

        let r = await this.getFreightFee();
        if(!r.ok)
            throw r.val;

        let freightItem = r.val;
        return [{productId: freightItem.productId ?? 0, qty: 1}];
    }

    static addToCart(cartItems: StationeryShopCartItem[]) {
        return new Promise((resolve, reject) => {
            this.initialiseCart().then((cart) => {
                cartItems.forEach(newItem => {
                    var cartMaxQty = newItem.maxQty || MAX_QTY; //set to either the const MAX_QTY, or the passed in max qty.

                    if(newItem.qty < 1)
                        throw new Error('Qty must be positive');

                    var item = cart.find(x => x.productId === newItem.productId);
                    if(item) {
                        item.qty = Math.min(item.qty + newItem.qty, cartMaxQty);
                    }
                    else {
                        cart.push({
                            productId: newItem.productId,
                            qty: Math.min(newItem.qty, cartMaxQty)
                        });
                    }
                });

                Cookies.set('cart', cart, {expires: this.CART_EXPIRY_DAYS});
                resolve(cart);
            })
        })
    }

    static clearCart(): Promise<void> {
        return new Promise((resolve, reject) => {
            Cookies.remove('cart');
            resolve();
        })
    }

    /** Updates the item qty in the cart. This may add/remove the item to/from the cart. */
    static updateCartItemQty(productId: number, qty: number) {
        return new Promise((resolve, reject) => {
            if(qty < 0)
                throw new Error('Qty cannot be negative');

            var cart: StationeryShopCart = Cookies.getJSON('cart') || [];
            var item = cart.find(x => x.productId === productId);

            if(qty === 0) {  // Remove
                cart = cart.filter(x => x.productId !== productId);
            }
            else {           // Update Qty
                if(item) {
                    item.qty = Math.min(qty, MAX_QTY)
                }
                else {
                    cart.push({productId, qty});
                }
            }

            Cookies.set('cart', cart, {expires: this.CART_EXPIRY_DAYS});
            resolve(cart);
        });
    }

    //#endregion

}

export default StationeryShopApi;