import React, { useState } from 'react';
import PropTypes from 'prop-types';
import fileUtil from '@premier/utils/file';
import { Button } from '@premier/ui';
import { DropdownField, FormDialog, SubmitButton, validate } from '@premier/form';
import { TokenExportFormatOptions } from 'platforms/current/constants/billpay';
import { tokenApi } from 'api';

const ExportTokensDialog = ({
    show, onClose, filter
}) => {

    const [isExporting, setIsExporting] = useState(false);

    const DEFAULT_FORMAT = TokenExportFormatOptions[0].value;

    function handleSubmit(values, context) {
        setIsExporting(true);

        tokenApi.downloadTokensExport(values.format, filter)
            .then(report => {
                fileUtil.download(report.fileData, report.fileName);

                context.setValue('format', DEFAULT_FORMAT);
                onClose();
            })
            .finally(() => {
                setIsExporting(false);
            });
    }

    if(!show)
        return null;

    return (
        <FormDialog
            show={show}
            name='exportTokensForm'
            title='Export tokens'
            dismissable
            onClose={onClose}
            onSubmit={handleSubmit}
            initialValues={{
                format: DEFAULT_FORMAT
            }}
            initialValidation={{
                format: validate().required(),
            }}
            renderButtons={() => <>
                <SubmitButton disabled={isExporting}>Export</SubmitButton>
                <Button onClick={onClose}>Cancel</Button>
            </>}
        >
            <p>This will export the filtered tokens.</p>

            <DropdownField
                name='format'
                label='Report format'
                options={TokenExportFormatOptions}
            />
        </FormDialog>
    );
};

ExportTokensDialog.Props = {
    show: PropTypes.bool,
    /** () => void */
    onClose: PropTypes.func.isRequired,
    filter: PropTypes.object.isRequired,
}

export default ExportTokensDialog;