import React, { useEffect, useState } from 'react';
import { PaymentPlanDetailsModelStatusEnum, SubscriptionModel } from '@premier/webapi-client'
import { PaddedContainer, DescriptionList } from '@premier/ui';
// @ts-ignore
import labels from 'constants/labels';
import currencyUtil from '@premier/utils/currency';
import dateUtil from '@premier/utils/date';

interface InstalmentCollectionSummaryTableProps {
    subscription : SubscriptionModel
    status : PaymentPlanDetailsModelStatusEnum,
    firstPaymentAmount : number
}

// {name: labels.userNameUpdated + ':', value: "Customer"}
const InstalmentCollectionSummaryTable : React.FC<InstalmentCollectionSummaryTableProps> = ({ subscription, status, firstPaymentAmount }) => {
    const generateItems = (subscription : SubscriptionModel, status : PaymentPlanDetailsModelStatusEnum, firstPaymentAmount : number) => {
        let items : {name : string, value : any}[] = [];
        const safeFirstPaymentAmount = (firstPaymentAmount ?? 0);
        // Ordering matters!
        if (subscription) { // Recurring
            items.push({name: labels.instalmentAmount, value: typeof subscription?.recurringAmount === "number" ? currencyUtil.convertToDisplayString(subscription.recurringAmount) : '' });
            items.push({name: labels.noOfInstalmentsCollected, value: typeof subscription?.numberOfSuccessfulPayments === "number" ? subscription.numberOfSuccessfulPayments + 1 : 1});

            if (status !== "COMPLETED") {
                items.push({name: labels.noOfInstalmentsOverdue, value: subscription?.numberOfOverduePayments});
                items.push({name: labels.noOfInstalmentsRemaining, value: subscription?.remainingPayments});
            }

            items.push({name: labels.nextInstalmentDate, value: subscription?.nextPaymentDate ? dateUtil.convertToDateString(subscription.nextPaymentDate) : '' });
            items.push({name: labels.amountCollected, value: typeof subscription?.amountCollected === "number" ? currencyUtil.convertToDisplayString(subscription.amountCollected + safeFirstPaymentAmount) : '' });

            if (status !== "COMPLETED") {
                items.push({name: labels.amountOverdue, value: typeof subscription?.amountOverdue  === "number" ? currencyUtil.convertToDisplayString(subscription.amountOverdue) : ''});
                items.push({name: labels.amountScheduled, value: typeof subscription?.remainingAmountScheduled === "number" ? currencyUtil.convertToDisplayString(subscription.remainingAmountScheduled) : ''});
            }

        } else { // Single payment
            var totalAmountToPayAsDisplayString = currencyUtil.convertToDisplayString(safeFirstPaymentAmount);
            items.push({name: labels.instalmentAmount, value: totalAmountToPayAsDisplayString });
            items.push({name: labels.noOfInstalmentsCollected, value: "1" });
            items.push({name: labels.nextInstalmentDate, value: "" });
            items.push({name: labels.instalmentAmount, value: totalAmountToPayAsDisplayString });
        }

        return items;
    }

    const [ displayItems, setDisplayItems ] = useState<{name : string, value : any}[]>([]);

    useEffect(() => {
        setDisplayItems(generateItems(subscription, status, firstPaymentAmount))
    }, [subscription, status, firstPaymentAmount]);

    return (
        <PaddedContainer
            className="instalment-collection-summary"
            title='Instalment collection summary'
        >
            <DescriptionList twoColumn items={displayItems} />
        </PaddedContainer>
    );
}

export default InstalmentCollectionSummaryTable;