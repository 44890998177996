import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import uiUtil from '@premier/utils/ui';
import labels from 'constants/labels';
import { Accordion, Divider } from '@premier/ui';
import { InputField, DropdownField, RadioField, CurrencyField, MaskedCardNumberField, CardExpiryField, DateRangePresetField, DateRangeEnum, DatePickerField } from '@premier/form';
import { withAdvancedFilter } from 'components/Common';

const ScheduleFilter = ({
    context, // from withAdvancedFilter
    onAutoUpdate,
    billers, scheduleStatuses, scheduleFrequencies, schedulePaymentStatuses  // data
}) => {

    // Dropdown options
    const billerOptions = useMemo(
        () => uiUtil.addAllOption(billers.map(b => ({value: b.billerCode, label: b.billerCodeWithName}))),
        [billers]
    );
    const scheduleStatusOptions   = uiUtil.generateOptions(scheduleStatuses);
    const scheduledPaymentStatusOptions = uiUtil.generateOptions(schedulePaymentStatuses);
    const paymentFrequencyOptions = uiUtil.generateOptions(scheduleFrequencies);


    function onEndDatePresetSelected(option) {
        const scheduleFilters = {...context.values};

        if (option === DateRangeEnum.ALL)
            delete scheduleFilters.scheduleEnd;
        else if (option === DateRangeEnum.NONE)
            scheduleFilters.scheduleEnd = 'NO_END';
        else
            scheduleFilters.scheduleEnd = 'END_DATE';

        context.setFormValues(scheduleFilters);
    }

    return (
        <>
            <h1>Filter subscriptions</h1>

            <Accordion title='By subscription details' enableDownMd>
                <div className='row'>
                    <div className='col-lg-4 col-xl-4'>
                        <DropdownField name='billerCode' label={labels.billerCode} options={billerOptions} />
                    </div>
                    <div className='col-lg-4 col-xl-4'>
                        <InputField name='reference1' label={labels.reference1} />
                    </div>
                    <div className='col-lg-4 col-xl-4'>
                        <InputField name='reference2' label={labels.reference2} />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg-4 col-xl-4'>
                        <InputField name='reference3' label={labels.reference3} />
                    </div>
                    <div className='col-lg-4 col-xl-4'>
                        <InputField name='scheduleReference' label={labels.subscriptionReference} />
                    </div>
                    <div className='col-lg-4 col-xl-4'>
                        <CurrencyField name='recurringAmount' label={labels.recurringAmount} />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg-4 col-xl-4'>
                        <DropdownField name='frequency' label={labels.frequency} options={paymentFrequencyOptions} />
                    </div>
                    <div className='col-lg-4 col-xl-4'>
                        <DropdownField name='scheduleStatus' label={labels.subscriptionStatus} options={scheduleStatusOptions} />
                    </div>
                </div>
            </Accordion>
            <Divider />

            <Accordion title='By payment status' enableDownMd>
                <RadioField inline noLabels name='schedulePaymentStatus' options={scheduledPaymentStatusOptions} ariaLabel='Payment status' />
            </Accordion>
            <Divider />

            <Accordion title='By payment method' enableDownMd>
                <div className='row'>
                    <div className='col-lg-4 col-xl-4'>
                        <InputField name='tokenReference1' label={labels.tokenReference1} />
                    </div>
                    <div className='col-lg-4 col-xl-4'>
                        <InputField name='tokenReference2' label={labels.tokenReference2} />
                    </div>
                    <div className='col-lg-4 col-xl-4'>
                        <InputField name='tokenReference3' label={labels.tokenReference3} />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg-4 col-xl-4'>
                        <InputField name='deAccountName' label={labels.bankAccountName} />
                    </div>
                    <div className='col-lg-4 col-xl-4'>
                        <InputField name='deBsbNumber' label={labels.bsb} />
                    </div>
                    <div className='col-lg-4 col-xl-4'>
                        <InputField name='deAccountNumber' label={labels.bankAccountNumber} />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg-4 col-xl-4'>
                        <MaskedCardNumberField name='maskedCardNumber' label={labels.maskedCardNumber} />
                    </div>
                    <div className='col-lg-4 col-xl-4'>
                        <CardExpiryField name='expiryDate' label={labels.expiryDate} />
                    </div>
                </div>
            </Accordion>
            <Divider />

            <Accordion title='By subscription date' enableDownMd>
                <div className='row'>
                    <div className='col-lg-4 col-xl-3'>
                        <DatePickerField name='createdDateRange' mode='range' label={labels.createdDateRange} />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg-8 col-xl-6'>
                        <DateRangePresetField name="endDateRange" label={labels.endDateRange}
                            presetOptions={[
                                {value: DateRangeEnum.NONE, label: 'No end date' },
                                {value: DateRangeEnum.ALL, label: 'All' }
                            ]}
                            onAutoUpdate={val => onAutoUpdate({...context.values, endDateRange: val})}
                            onPresetSelected={onEndDatePresetSelected}
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg-4 col-xl-3'>
                        <DatePickerField name='nextPayment' label={labels.nextPayment} />
                    </div>
                </div>
            </Accordion>
            <Divider />

            <Accordion title='By customer' enableDownMd>
                <div className='row'>
                    <div className='col-lg-4 col-xl-4'>
                        <InputField name='customerUniqueCode' label={labels.customerCode} />
                    </div>
                </div>
            </Accordion>
        </>
    );
};

ScheduleFilter.propTypes = {
    billers: PropTypes.array,
    scheduleStatuses: PropTypes.object,
    scheduleFrequencies: PropTypes.object,
    schedulePaymentStatuses: PropTypes.object,

    onAutoUpdate: PropTypes.func.isRequired,

    // The rest of the props are in advancedFilterComponent.propTypes in withAdvancedFilter.js
};

export default withAdvancedFilter(ScheduleFilter);