import moment from 'moment';
import { fieldNames as invoiceFieldNames } from './InvoiceForm';
import { fieldNames as billToFieldNames } from './InvoiceBillToForm';
import { fieldNames as detailsFieldNames } from './InvoiceDetailForm';
import { discountFeeOptions } from './InvoiceDiscountFeeForm';
import {
    InvoiceModel,
    InvoiceProductItemModel,
    InvoiceSubTotalItemModel,
    CountryTax,
    TermOfPayment,
} from '@premier/webapi-client';
import countryUtil from '@premier/utils/country';
import currencyUtil from '@premier/utils/currency';
import dateUtil from '@premier/utils/date';

export const maxCurrency = 214748.36;
export const itemTemplateString = '%ITEM%';

export const formToInvoiceModel = (
    customerId: number,
    values: any,
    taxRates: CountryTax[],
    termOfPayments: TermOfPayment[]
): InvoiceModel => {
    const billTo = values[invoiceFieldNames.billTo];
    const details = values[invoiceFieldNames.invoiceDetail];

    // Calculated Bill To fields
    const countryCode = billTo[billToFieldNames.country];
    const country = countryUtil.getCountry(countryCode);
    const state = billTo[billToFieldNames.state];
    const stateId = country?.states?.find((s) => s.state === state)?.stateId;

    // Calculated Details fields
    const termOfPayment = termOfPayments.find((top) => top.daysToPay === details[detailsFieldNames.termsOfPayments])!;
    const invoiceDate = new Date(details[detailsFieldNames.invoiceDate]);
    const invoiceDateApi = dateUtil.convertDateToApiDate(invoiceDate) as string;
    const dueDate = dateUtil.convertDateToApiDate(
        moment(invoiceDate).add(termOfPayment.daysToPay, 'days').toDate()
    ) as string;

    // Convert items and subtotals
    const { items, subtotals } = formToTotals(values, taxRates);

    return {
        customer: {
            customerId: customerId,
            firstName: billTo[billToFieldNames.firstName],
            lastName: billTo[billToFieldNames.lastName],
            companyName: billTo[billToFieldNames.companyName],
            email: billTo[billToFieldNames.email],
            address: {
                country: country?.name,
                addressLine1: billTo[billToFieldNames.addressLine1],
                addressLine2: billTo[billToFieldNames.addressLine2],
                suburb: billTo[billToFieldNames.suburb],
                stateId: stateId,
                state: state,
                postcode: billTo[billToFieldNames.postcode],
                countryCode: billTo[billToFieldNames.country],
            },
        },
        customerNotes: values[invoiceFieldNames.notesForCustomer],
        invoiceNumber: details[detailsFieldNames.invoiceNumber],
        orderNumber: details[detailsFieldNames.orderNumber],
        selectedTermOfPayment: termOfPayment,
        invoiceDate: invoiceDateApi,
        dueDate: dueDate,
        currencyCode: details[detailsFieldNames.currency],
        invoiceProductItems: items,
        invoiceSubTotalItems: subtotals,
    };
};

export const formToTotals = (
    values: any,
    taxRates: CountryTax[]
): { items: InvoiceProductItemModel[]; subtotals: InvoiceSubTotalItemModel[] } => {
    // Convert invoice items to API structure
    const items = [];
    for (var itemKey in values[invoiceFieldNames.invoiceItems]) {
        const element = values[invoiceFieldNames.invoiceItems][itemKey];
        if (!element) continue;

        const taxRate = taxRates.find((tr) => tr.taxName === element.tax);
        if (!taxRate) continue;
        if (!element.description) continue;

        const quantity = parseFloat(element.quantity) || 0;
        if (quantity <= 0) continue;

        items.push({
            unitPrice: currencyUtil.convertToApiValue(element.unitPrice || 0)!,
            quantity: parseFloat(element.quantity) || 0,
            description: element.description,
            selectedTaxRate: taxRate,
        });
    }

    // Convert discounts and fees to API structure
    const subtotals = [];
    for (var discountFeeKey in values[invoiceFieldNames.discountFee]) {
        const element = values[invoiceFieldNames.discountFee][discountFeeKey];
        if (!element) continue;

        const option = discountFeeOptions.find((df) => df.value === element.discountFee);
        if (!option) continue;
        const selectedOption = {
            optionId: element.discountFee,
            optionName: option.label,
        };

        const taxRate = taxRates.find((tr) => tr.taxName === element.tax);
        if (!taxRate) continue;
        if (!element.description) continue;

        subtotals.push({
            selectedOption: selectedOption,
            value: option.isCurrency
                ? currencyUtil.convertToApiValue(element.amountPercent)!
                : Math.round(parseFloat(element.amountPercent) * 100),
            description: element.description,
            selectedTaxRate: taxRate,
        });
    }

    return {
        items,
        subtotals,
    };
};

// Some fields are given values asyncronously which requires a manual validation to properly display to the user.
export const silentValidation = (context: any, fieldName: string, value: any) => {
    try {
        context.validateField(fieldName, value);
    } catch {}
};

export const replaceTemplateWithIndex = (index: number, message?: string) =>
    message?.replace(itemTemplateString, `item ${index + 1}`);
