import React, { useState, useEffect } from 'react';
import {connect} from 'react-redux';

import { AuthenticatedContainer, PublicContainer } from 'components/Common';
import { loggedInItems, filterMenuItems } from 'components/Routing';

import './AppContainer.scss';

const AppContainer = ({
    children, requiredUserAction, authenticatedUser, merchant//state values
}) => {
    const [navigationMenuItems, setNavigationMenuItems] = useState([]);

    useEffect(() => {
        if (authenticatedUser) {
            setNavigationMenuItems(filterMenuItems(loggedInItems, authenticatedUser, merchant?.hasAnyReports ?? false))
        } else {
            setNavigationMenuItems(filterMenuItems(loggedInItems));
        }
    }, [authenticatedUser]);

    const container = authenticatedUser && !requiredUserAction ? (
        <AuthenticatedContainer menuItems={navigationMenuItems}>
            {children}
        </AuthenticatedContainer>
    ) : (
        <PublicContainer>
            {children}
        </PublicContainer>
    )

    return (<div className="app-body">{container}</div>);
}

function mapStateToProps(state) {
    return {
        requiredUserAction: state.accounts.users.requiredUserAction,
        authenticatedUser: state.accounts.users.authenticatedUser,
        merchant: state.accounts.users.merchant,
    };
}

export default connect(mapStateToProps)(AppContainer);