import React, { ReactNode } from 'react';
import classNames from 'classnames';
import './SingleColumnFormContainer.scss';

/** A container with limited width, centered on small screen. */
const SingleColumnFormContainer = ({
    className,
    children
}: {
    className?: string,
    children?: ReactNode
}) => {
    return (
        <div className={classNames('single-column-form-container', className)}>
            {children}
        </div>
    );
}

export default SingleColumnFormContainer;