import React from 'react';
import { useField } from 'formik';
import { DateOption } from 'flatpickr/dist/types/options';
import { DatePicker } from "@premier/ui";
import './FormikDatePicker.scss';
import dateUtil from '@premier/utils/date';

type FormikDatePickerProps = {
    // name refers to the form field name (this is used as a reference ID by Formik and forms in general)
    name : string,
    // Show time
    enableTime? : boolean,
    /** The earliest selectable date. Note that dynamic time might cause infinite refreshes */
    minDate?: DateOption,
    /** The latest selectable date. Note that dynamic time might cause infinite refreshes */
    maxDate?: DateOption,
    disabled?: boolean
};

const FormikDatePicker : React.FC<FormikDatePickerProps> = ({ name, enableTime, minDate, maxDate, disabled }) => {
    const [ field, meta, helpers ] = useField(name);
    const { setValue, setTouched } = helpers;
    // https://flatpickr.js.org/events/
    // On init, deduce the type of the associated field value in via Formik and convert it into the
    // native type for DatePicker which is [<Date object>] on init
    const DEFAULT_ON_READY_CALLBACK = (dates: Date[], currentDateString?: string, self?: any, data?: any) => {
        if (field.value instanceof String) {
            self?.setDate(new Date(field.value.toString()))
        } else if (field.value instanceof Date) {
            self?.setDate(field.value)
        } else if (Array.isArray(field.value)) {
            // Assume date as first object
            self?.setDate(field.value[0])
        }
    }

    const DEFAULT_ON_CHANGE_CALLBACK = (date: Date[], currentDateString?: string, self?: any, data?: any) => {
        // This is to ensure the validation is shown if date[0] is undefined. Errors do not show
        // if the user just deletes/backspaces on the date on a preloaded form because this
        // doesn't change its touched status to true.
        setTouched(true);
        // Set undefined to null to ensure Formik recognizes the change in the field's value.
        setValue(date[0] ?? null, true);
    }

    return <DatePicker
        dotnetDateFormat={dateUtil.getDateFormat()}
        dotnetTimeFormat={dateUtil.getTimeFormat()}
        enableTime={enableTime}
        onChange={DEFAULT_ON_CHANGE_CALLBACK}
        onReady={DEFAULT_ON_READY_CALLBACK}
        minDate={minDate}
        maxDate={maxDate}
        disabled={disabled ?? false}
        error={(meta.touched && !!meta.error?.length)}
        disableKeyboardInput
        value={
            field.value instanceof Date ||
                (Array.isArray(field.value) && field.value.every(item => item instanceof Date))
                ? field.value
                : undefined
        }
    />
}

export default FormikDatePicker;