import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import FormContext from '../FormContext';
import { FormGroup as UIFormGroup } from '@premier/ui';

const FormGroup = ({ name, mandatory, children, ...otherProps }) => {

    const formContext = useContext(FormContext);
    const { getError, getMandatory } = formContext;

    return (
        <>
            <UIFormGroup
                error={getError(name)}
                mandatory={getMandatory(name) || mandatory}
                {...otherProps}
            >
                {children}
            </UIFormGroup>
        </>
    );
};

FormGroup.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.any,
    /** Adds an asterisk next to the label */
    mandatory: PropTypes.bool,
    placeholder: PropTypes.string
};

export default FormGroup;
