import _ from 'lodash';

export default class Validation {
    constructor() {
        this.tests = [];
    }

    test(testFunction, errorMessage, validationName, condition) {
        this.tests.push({ test: testFunction, message: errorMessage, validationName, condition });
        return this;
    }

    hasTest(validationName) {
        return _.some(this.tests, t => t.validationName === validationName);
    }

    hasEnabledTest(validationName, values) {
        return _.some(this.tests, t => t.validationName === validationName && t.condition(values || {}));
    }

    getTest(validationName){
        var test = _.find(this.tests, t => t.validationName === validationName);
        return test ? test.test : null;
    }

    validate(value, values) {
        for (var i = 0; i < this.tests.length; i++) {
            var testResults;

            try {
                testResults = this.tests[i].test(value, values);
            }
            //if the validator itself throws an error, bubble the exception
            //in case it was raised by a conditional validation
            catch(e){
                throw new Error(e.message);
            }

            if (!testResults) {
                throw new Error(this.tests[i].message || 'error');
            }
        }
    }
}
