import { useField } from "formik";
import "./Input.scss";
import React, { useRef, ChangeEvent, useEffect } from 'react';
import _ from 'lodash';
import { Button, Icon } from '@premier/ui';
import fileUtil from '@premier/utils/file';
import classNames from "classnames";
import './FormikFileSelector.scss';

interface InputProps {
    name: string;
    noSpinner?: boolean;
    disabled?: boolean;
    plaintext?: boolean;
    className?: string;
    value?: any;
    type?: string;
    [otherProps: string]: any;
    deleteButtonText?: string;
    fileName?: string;
}

const FormikFileSelector: React.FC<InputProps> = ({ name, className, value, type, noSpinner, disabled, plaintext, deleteButtonText, fileName, ...otherProps }) => {
    const [field, meta, helpers] = useField(name);
    const { setValue } = helpers;
    const fileInputEl = useRef<HTMLInputElement | null>(null);
    useEffect(() => {
        if (fileName) {
            setValue(fileName);
        }
    }, []);


    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            let newValue = {
                newFiles: e.target.files,
                newFileUrl: URL.createObjectURL(e.target.files[0])
            };

            setValue(newValue);
        }
    };
    const existingFileExists = () => {
        return value && Object.keys(value).length > 0;
    }

    const newFileExists = () => {
        return !!_.get(value, 'newFiles.length');
    }

    const handleDelete = () => {
        setValue({});
    }

    return (
        <>
            <input {...otherProps}
                ref={fileInputEl}
                className={
                    classNames("d-xs-none", {
                        "has-error": meta.touched && !!meta.error?.length,
                        "no-number-spinner": noSpinner,
                        "form-control-plaintext": plaintext,
                    }, className)
                }
                name={name}
                type={"file"}
                onChange={handleChange}
                disabled={disabled || plaintext}
            />

            <div className="file-upload-field-actions">
                {existingFileExists() && !newFileExists() && (
                    <span className='file-info'>
                        <Icon tick />
                        {fileName}
                    </span>
                )}
                {newFileExists() && (
                    <span className='file-info'>
                        {value?.newFiles[0]?.name}
                        {' '}
                        ({fileUtil.readableFileSize(value.newFiles[0].size)})
                    </span>
                )}
                <div className="file-upload-field-button-container">
                    <Button
                        link
                        onClick={() => {
                            if (fileInputEl.current) {
                                fileInputEl.current.click();
                            }
                        }}
                    >upload file</Button>
                    {(existingFileExists() || newFileExists()) && <Button className="delete-button" link onClick={handleDelete}>{deleteButtonText}</Button>}
                </div>
            </div>
        </>
    );
};

export default FormikFileSelector;