import { DeclineTrayModel } from "packages/webapi-client";
import { Icon, DescriptionList, Link, PaddedContainer } from "@premier/ui";
import { PlatformRoutesConfiguration } from 'components/Routing';

type Props = {
    declineTray?: DeclineTrayModel;
    merchantNumber: string;
}

const DeclineManagerTrayDetails = (props: Props) => {

    const { declineTray } = props;

    const declineMangerDetails = [
        {
            name: "Description",
            value: declineTray?.description,
        },
        {
            name: "Instruction",
            value: declineTray?.comments,
        },
        {
            name: "Notifications",
            value: declineTray?.emailAddress ? <><Icon tick/> {declineTray?.emailAddress}</> : "No",
        }
    ];

    return (
        <PaddedContainer title={declineTray?.nickname} button={<Link button to={PlatformRoutesConfiguration.batchDeclineSettingsRoute!.manageEditTrays.generatePath()} state={props}>Edit</Link>} lessMargin noDivider>
            <DescriptionList items={declineMangerDetails} />
        </PaddedContainer>
    );
}

export default DeclineManagerTrayDetails;