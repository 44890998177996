import React from 'react';
import { InvoiceCustomerModel } from 'packages/webapi-client';
import { DescriptionList } from '@premier/ui';

import labels from 'platforms/base/constants/labels';
import _ from 'lodash';

interface Props {
    data: InvoiceCustomerModel;
}

// helper func ---
const displayText = (text: string | undefined): string => {
    return text ?? '';
};

const InvoiceBillToInformationList: React.FC<Props> = ({ data }) => {
    const address = _.isEmpty(data.address)
        ? []
        : [
              data?.address?.addressLine1,
              data?.address?.addressLine2,
              data?.address?.suburb,
              `${data?.address?.state} ${data?.address?.postcode}`
          ];

    const addressString = address.filter(
        (e) => e !== undefined &&
         e !== null &&
         e !== ''
    ).join(', ');

    const billToInformation = [
        {
            name: labels.contactName,
            value: `${displayText(data?.firstName)} ${displayText(
                data?.lastName
            )}`,
        },
        {
            name: labels.companyName,
            value: displayText(data?.companyName),
        },
        {
            name: labels.address,
            value: addressString,
        },
        {
            name: labels.sendTo,
            value: displayText(data?.email),
        },
    ];

    return <DescriptionList items={billToInformation} />;
};

export default InvoiceBillToInformationList;
