import { PageSection } from '@premier/ui';
import { AddOrUpdatePaymentPlanOptionForm } from 'components/Settings'
import { useParams } from 'react-router-dom';
import { VerifyBiller } from '../../../util/verificationUtil'

const AddPaymentPlanOptionPage = () =>
{
    const { billerCode } = useParams();
    VerifyBiller(billerCode);
    return (
        <PageSection>
            <AddOrUpdatePaymentPlanOptionForm billerCode={billerCode} />
        </PageSection>
    );
};

export default AddPaymentPlanOptionPage;
