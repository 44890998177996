import PropTypes from "prop-types";
import { Switch } from "@premier/ui";
import withField from "./withField";

/** Unlike other field, this is not designed to be placed among other fields. You'll need to position it yourself. */
const SwitchField = ({
    children,
    formProps, groupProps, ...otherProps
}) => {

    function handleChange(val) {
        formProps.setValue(val);
    }

    return (
        <Switch
            {...otherProps}
            on={formProps.value}
            onChange={handleChange}
        >
            {children}
        </Switch>
    );
};

SwitchField.propTypes = {
    name: PropTypes.string.isRequired,
    className: PropTypes.string,
    on: PropTypes.bool,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    children: PropTypes.node
};

export default withField(SwitchField);
