import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import requiredIf from '@premier/utils/requiredIf';
import * as billpayUtil from '@premier/utils/billpay';
import { ResponsiveTable, CardLogo, ErrorText } from '@premier/ui';

import { PaymentTypeKey } from 'constants/billpay';
import labels from 'constants/labels';
import './LinkedTokensList.scss';
import { isValidExpiryDate } from '@premier/utils/date';

const LinkedTokensList = ({
    sortable, sort, tokens, //data
    onSort, onLinkClick, //functions
    isLoading, //logic renders
}) => {

    // The table columns (or list items)
    const columns = [{
        label: labels.accountType,
        sortKey: sortable ? 'type' : undefined,
        getter: item => item.type === PaymentTypeKey.CARD ?  <CardLogo cardTypeCode={item.cardTypeCode}/> : 'Bank account'
    }, {
        label: labels.cardOrBankAccountNumber,
        sortKey: sortable ? 'maskedCardNumber' : undefined,
        getter: item => item.maskedCardNumber || item.deAccountNumber
    }, {
        label: labels.expiryDate,
        sortKey: sortable ? 'expiryDate' : undefined,
        getter: item => {
            if (!item.expiryDate) return '';
            if ((item.expiryDate.month && item.expiryDate.year) || item.expiryDate.month === 99) {
                if (!isValidExpiryDate(item.expiryDate.month, item.expiryDate.year)) {
                    return (<div className='expiry-warning'>
                        <ErrorText>{billpayUtil.formatExpiry(item.expiryDate)}</ErrorText>
                        <span className='update-text'>Update now</span>
                    </div>);
                } else {
                    return billpayUtil.formatExpiry(item.expiryDate);

                }
            }
        }
    }];

    return (<>
        <ResponsiveTable
            data={tokens}
            className='linked-tokens-list'
            isLoading={isLoading}
            columns={columns}
            sort={sort}
            onSort={onSort}
            onRowLinkClick={onLinkClick}
            noDataText='No tokens to display.'
        />
    </>);
};

LinkedTokensList.propTypes = {
    /** Is list sortable */
    sortable: PropTypes.bool,

    /** The current sort order */
    sort: requiredIf(PropTypes.shape({
        field: PropTypes.string,
        descending: PropTypes.bool,
    }), props => props.sortable),

    /** to handle sort field/order changes */
    onSort: requiredIf(PropTypes.func, props => props.sortable),

    /** What happens when redirecting to view item details (eg. to save the current page state into Redux) */
    onLinkClick: PropTypes.func,

    tokens: PropTypes.arrayOf(PropTypes.shape({
        dataVaultId: PropTypes.number.isRequired,
        token: PropTypes.string.isRequired,
        expiryDate: PropTypes.shape({
          month: PropTypes.number,
          year: PropTypes.number
        }),
        accountName: PropTypes.string,
        maskedCardNumber: PropTypes.string,
        deBsbNumber: PropTypes.string,
        deAccountNumber: PropTypes.string,
        crn1: PropTypes.string.isRequired,
        crn2: PropTypes.string,
        crn3: PropTypes.string,
        customerV2Id: PropTypes.number,
        customerUniqueId: PropTypes.string,
        type: PropTypes.oneOf([PaymentTypeKey.CARD, PaymentTypeKey.BANK_ACCOUNT]).isRequired,
        cardTypeCode: PropTypes.string.isRequired
      })),

    isLoading: PropTypes.bool,
};

export default LinkedTokensList;
