import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import errorUtil from '@premier/utils/error';
import * as billpayUtil from '@premier/utils/billpay';
import labels from 'constants/labels';

import { SingleColumnFormContainer, PageSection, ButtonContainer, SuccessModal } from '@premier/ui';
import { InputField, PhoneNumberField, EmailAddressField, Form, SubmitButton, ResetButton, validate, FormErrorList } from '@premier/form';
import { ConfirmPasswordModal, PageHeader } from 'components/Common';
import { NewPasswordFields } from 'components/Account';
import errorMaps from 'constants/errorMaps';

import * as commonActions from 'components/Common/_actions/actions';
import * as accountActions from 'components/Account/_actions/accountActions';

import './ProfilePage.scss';


const ProfilePage = ({
    userDetails, //data
    actions, commonActions, //API actions
}) => {

    const [showPasswordModal, setShowPasswordModal] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [isChangingDetails, setIsChangingDetails] = useState(false);
    const [userSubmission, setUserSubmission] = useState(null);
    const [errors, setErrors] = useState(null);

    // we need to reset the password fields after submission
    // unfortunately, we don't have access to the Form component below
    // from here, or <NewPasswordFields /> inner Form component
    // and we would need to reset both (as both contain the password values)
    // instead, massive hack, for 1 render, remove the Form component
    // which should clear all state, thus wiping the password values
    // and bonus, resetting the form to the new user values
    const [shouldRenderForm, setShouldRenderForm] = useState(true);

    useEffect(() => {
        if (!shouldRenderForm)
            setShouldRenderForm(true);
    }, [shouldRenderForm])

    /** Copy blankFormValues and overwrite its properties with the provided userDetails and return it */
    function generateFormValues(userDetails) {
        var values = {
            displayName: userDetails.displayName,
            firstName: userDetails.firstName,
            lastName: userDetails.lastName,
            emailAddress: userDetails.emailAddress,
            phoneNumber: billpayUtil.formatPhoneApiStringToObject(userDetails.phoneNumber),
            mobileNumber: billpayUtil.formatPhoneApiStringToObject(userDetails.mobileNumber),
        };
        return values;
    }

    async function handlePasswordConfirmed(values, context) {
        setIsChangingDetails(true);
        setErrors(null);
       try {
            await actions.updateProfile({
                ...userSubmission,
                phoneNumber: billpayUtil.formatPhoneObjectToApiString(userSubmission.phoneNumber),
                mobileNumber: billpayUtil.formatPhoneObjectToApiString(userSubmission.mobileNumber),
                ...values,
                newPassword: isStationeryPlatform ? null : userSubmission.newPasswordForm.newPassword
            });
            context.resetForm();
            commonActions.clearErrors();

            closePasswordModal();
            setShowSuccessModal(true);
            setIsChangingDetails(false);
        } catch(errors) {
            if (errors && errors.length && errors[0].code === 'INCORRECT_PASSWORD_PROVIDED') {
                context.setError('currentPassword', errorUtil.getMessage(errors[0]));
            } else {
                setErrors(errors);
                closePasswordModal();
            }
            setIsChangingDetails(false);
        }
    }

    function closePasswordModal(formProps) {
        setShowPasswordModal(false);
        setShouldRenderForm(false);
    }
    function closeSuccessModal() {
        setShowSuccessModal(false);
    }

    var initialFormValues = generateFormValues(userDetails);

    //Checking if an environment is a Stationery Shop environment, to render components below.
    var isStationeryPlatform = process.env.REACT_APP_PLATFORM === 'stationery_shop';
    return (
        <div className='profile-page'>
            <PageSection>
                <PageHeader
                    title='My profile'
                    subtitle={`User role: ${userDetails.roleName}`}
                />

                <SingleColumnFormContainer>
                    {shouldRenderForm && <Form
                        initialValues={initialFormValues}
                        initialValidation={{
                            firstName: validate().required(),
                            lastName: validate().required(),
                            emailAddress: validate().required().email()
                        }}
                        onSubmit={values => {
                            setUserSubmission(values);
                            setShowPasswordModal(true);
                        }}
                        errors={errors}
                        errorMaps={errorMaps}
                    >
                        <InputField name='displayName' label={labels.displayName} />
                        <InputField name='firstName' label={labels.firstName} />
                        <InputField name='lastName' label={labels.lastName} />
                        <EmailAddressField name='emailAddress' label={labels.emailAddress} />
                        <PhoneNumberField name='phoneNumber' label={labels.phoneNumber} />
                        <PhoneNumberField name='mobileNumber' label={labels.mobileNumber} />

                        {!isStationeryPlatform && (
                            <>
                                <h2 style={{ marginTop: '4rem' }}>Change password</h2>
                                <NewPasswordFields />
                            </>
                        )}

                        <FormErrorList />

                        <ButtonContainer>
                            <SubmitButton>Save</SubmitButton>
                            <ResetButton>Reset</ResetButton>
                        </ButtonContainer>
                    </Form>}
                </SingleColumnFormContainer>

                <ConfirmPasswordModal
                    show={showPasswordModal}
                    title="Confirm updates"
                    message="Enter your current password to save any updates on your profile"
                    isProcessing={isChangingDetails}
                    onSubmit={handlePasswordConfirmed}
                    onClose={() => closePasswordModal()}
                />

                <SuccessModal
                    show={showSuccessModal}
                    onClose={closeSuccessModal}
                    title='Saved successfully'
                />
            </PageSection>
        </div>
    );
};

function mapStateToProps(state) {
    return {
        userDetails: state.accounts.users.authenticatedUser
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(accountActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);
