import {createStore, applyMiddleware} from 'redux';
import createRootReducer from './rootReducer';
import thunk from 'redux-thunk';
import {composeWithDevTools} from 'redux-devtools-extension';

const composeEnhancers = composeWithDevTools({
    trace:true,
    traceLimit: 25
});

export default function configureStore(preloadedState) {
    const store = createStore(
      createRootReducer(), // root reducer with router state
      preloadedState,
      composeEnhancers(
        applyMiddleware(
          thunk
        ),
      ),
    )
    return store
}