import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';

import fileUtil from '@premier/utils/file';
import { Button, FormGroup, Icon } from '@premier/ui';
import withField from './withField';
import './FileUploadField.scss';

/** A single-file upload field that shows the current file (from the server/API) if any. */
const FileUploadField = ({
    name, onChange,
    buttonText, deleteButtonText,  // FileUpload specific props
    formProps, groupProps, ...otherProps
}) => {

    const {value, setValue} = formProps;
    const [id] = useState(_.uniqueId(`${name}_`));
    const fileInputEl = useRef(null);

    function handleChange(e) {
        let newValue = {
            ...value,
            newFiles: e.target.files,
            newFileUrl: e.target.files.length ? URL.createObjectURL(e.target.files[0]) : undefined,
        };

        setValue(newValue);

        if (onChange)
            onChange(newValue);
    }

    function handleDelete() {
        setValue({});
    }

    function existingFileExists() {
        return _.get(value, 'existingFile.name') && !_.get(value, 'newFiles.length');
    }

    function newFileExists() {
        return !!_.get(value, 'newFiles.length');
    }

    return (
        <FormGroup
            fieldId={id}
            {...groupProps}
            error={formProps.error}
            className={classNames('file-upload-field', groupProps.className)}
        >
            <input
                ref={fileInputEl}
                className='d-xs-none'
                type='file'
                name={name}
                id={id}
                onChange={handleChange}
                //value={formProps.value.newFiles}
                error={formProps.error}
                aria-labelledby={groupProps.label && `${id}_label`}
                aria-required={groupProps.mandatory}
                aria-invalid={!!formProps.error}
                aria-describedby={formProps.error && `${id}_error`}
                {...otherProps}
            />

            <div className="file-upload-field-actions">
                {existingFileExists() && (
                    <span className='file-info'>
                        <Icon tick />
                        {value.existingFile.name}
                        {value.existingFile.size != null && (<>
                            {' '}({fileUtil.readableFileSize(value.existingFile.size)})
                        </>)}
                    </span>
                )}
                {newFileExists() && (
                    <span className='file-info'>
                        {value.newFiles[0].name}
                        {' '}
                        ({fileUtil.readableFileSize(value.newFiles[0].size)})
                    </span>
                )}
                <div className="file-upload-field-button-container">
                    <Button link onClick={() => fileInputEl.current.click()}>{buttonText}</Button>
                    {(existingFileExists() || newFileExists()) && <Button className="delete-button" link onClick={handleDelete}>{deleteButtonText}</Button>}
                </div>
            </div>
        </FormGroup>
    );
};

FileUploadField.propTypes = {
    buttonText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

FileUploadField.defaultProps = {
    buttonText: 'Select file...',
};

export default withField(FileUploadField);