import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import { RootState } from "store/store";
import _ from "lodash";

// @ts-ignore
import labels from "constants/labels";
import { FormContext, InputField, CardExpiryField, CurrencyField } from "@premier/form";
import { ResponsiveTable, LoadingIndicator } from "@premier/ui";
import { OrderTypesField } from "components/Batch";
import { OrderTypeModel, OrderTypeModelKeyEnum } from "@premier/webapi-client";
import { OrderTypes } from "api/transactionsApi";
import * as paymentActions from "components/Utilities/_actions/utilitiesActions";
import "./AddRecordsForm.scss";

const createNewRecord = () => {
    return {
        crn1: "",
        card: {
            number: ""
        },
        amount: "",
        orderType: "RECURRING",
        noBatchActions: true
    };
};

type Record = {
    id: string;
    crn1: string;
    card: {
        number: string;
    };
    amount: string;
    orderType: OrderTypeModelKeyEnum;
    noBatchActions: boolean;
}

type ErrorRecord = {
    recordId: number;
    property: string;
};

type Props = {
    orderTypes: OrderTypeModel[];
    actions: any;
    isLoading: boolean;
    onUnsavedRecordsChanged: (isDirty: boolean) => void;
    errors: ErrorRecord[];
};

const AddRecordsForm = ({
    orderTypes,
    actions,
    isLoading,
    onUnsavedRecordsChanged, //function
    errors //form
} : Props) => {
    const { values, setValue, clearErrors, setError, removeField } = React.useContext(FormContext);

    const [tableData, setTableData] = React.useState<Record[]>([]);

    useEffect(() => {
        actions.getProcessTransactionOrderTypes();
    }, []);

    useEffect(() => {
        const arr: Record[] = [];
        let hasBlankRecord = false;

        for (const prop in values.records) {
            if (values.records[prop]) {
                arr.push(Object.assign({}, { ...values.records[prop], id: prop }));

                if (values.records[prop].noBatchActions) {
                    hasBlankRecord = true;
                }
            }
        }

        if (!hasBlankRecord) {
            setValue(`records.${_.uniqueId("element_")}`, createNewRecord());
        }

        if (onUnsavedRecordsChanged) {
            onUnsavedRecordsChanged(arr.some(i => !i.noBatchActions));
        }
        setTableData(arr);
    }, [values]);

    useEffect(() => {
        clearErrors();
        //need to set errors on the fields that are in error.
        for (const errorIndex in errors) {
            const error = errors[errorIndex];
            const record = tableData[error.recordId];

            if (record) {
                setError(`records.${record.id}.${translateErrorProperty(error.property)}`, "error");
            }
        }
    }, [errors]);

    function handleElementChanged(record: Record) {
        return (e: Record) => {
            if (record.noBatchActions) {
                setValue(`records.${record.id}.noBatchActions`, false);
            }
        };
    }

    /**
     * works by translating the C# PascalCase pathing to a JS camelCase pathing
     * Card.Number -> card.number
     * Card.ExpiryDate -> card.expiryDate
     */
    function translateErrorProperty(prop: string) {
        return prop
            .split(".")
            .map(s => s.charAt(0).toLowerCase() + s.slice(1))
            .join(".");
    }

    function removeItems(items: Record[]) {
        items.forEach(i => {
            removeField(`records.${i.id}`);
        });
    }

    if (isLoading) {
        return <LoadingIndicator />;
    }

    return (
        <>
            <ResponsiveTable
                className="table-no-label add-records-table"
                data={tableData}
                selectable
                columns={[
                    {
                        label: labels.reference1,
                        getter: (item: Record) => {
                            return (
                                <InputField
                                    noLabels
                                    name={`records.${item.id}.crn1`}
                                    onChange={handleElementChanged(item)}/>
                            );
                        }
                    },
                    {
                        label: labels.cardNumber,
                        getter: (item: Record) => {
                            return (
                                <InputField
                                    noLabels
                                    name={`records.${item.id}.card.number`}
                                    onChange={handleElementChanged(item)}/>
                            );
                        }
                    },
                    {
                        label: labels.expiryDate,
                        getter: (item: Record) => {
                            return (
                                <CardExpiryField
                                    noLabels
                                    name={`records.${item.id}.card.expiryDate`}
                                    required
                                    defaultValue={"00"} /> // 00 allows for expiry to be ignored in batch processing
                            );
                        }
                    },
                    {
                        label: labels.amount,
                        getter: (item: Record) => {
                            return (
                                <CurrencyField
                                    noLabels
                                    compact
                                    name={`records.${item.id}.amount`}
                                    onChange={handleElementChanged(item)}/>
                            );
                        }
                    },
                    {
                        label: labels.orderType,
                        getter: (item: Record) => {
                            return (
                                <OrderTypesField
                                    noLabels
                                    name={`records.${item.id}.orderType`}
                                    compact
                                    onChange={handleElementChanged(item)}
                                    excludeOrderTypes={[OrderTypes.inPerson]}
                                    orderTypes={orderTypes}/>
                            );
                        }
                    }
                ]}
                batchActions={[
                    {
                        label: "Delete",
                        batchLabel: "Delete selected",
                        tableButtonProps: {
                            // @ts-ignore
                            subtle: true,
                            iconType: "minus",
                        },
                        handleClick: items => {
                            removeItems(items);
                        },
                        disabled: (selectedItems) => { return !selectedItems?.length; }
                    }
                ]}/>
        </>
    );
};

function mapStateToProps(state: RootState) {
    return {
        orderTypes: state.transactions.payments.newPaymentOrderTypes
            ? state.transactions.payments.newPaymentOrderTypes.items
            : [],
        isLoading: state.transactions.payments.isLoading
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        actions: bindActionCreators(paymentActions, dispatch)
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddRecordsForm);
