import React from 'react';
import { Link, Icon, ButtonContainer } from '@premier/ui';
import { PageHeader, ErrorPage } from 'components/Common';

import text from 'platforms/cba/constants/text';
import { PlatformRoutesConfiguration } from 'components/Routing';
import './NoBillerCodeErrorPage.scss';

interface Props {}

const NoBillerCodeErrorPage: React.FC<Props> = () => {
    const phoneNumber = (
        <a href={`tel:${text.supportPhoneNumber.replace(/\s+/g, '')}`}>
            {text.supportPhoneNumber}
        </a>
    );
    const errorMessage = (
        <div className='no-biller-error-page'>
            Sorry, it looks like you don’t have a biller code for invoices.
            Please contact {phoneNumber} to set it up.
        </div>
    );

    return (
        <ErrorPage>
            <PageHeader
                icon={<Icon lock />}
                title='No biller code for invoices'
                subtitle={errorMessage}
            />

            <ButtonContainer>
                {// @ts-ignore
                <Link button to={PlatformRoutesConfiguration.accountRoute.home.generatePath()}>
                    Back to home
                </Link>}

            </ButtonContainer>
        </ErrorPage>
    );
};

export default NoBillerCodeErrorPage;
