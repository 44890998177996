import React, { ReactNode } from 'react';
import classNames from 'classnames';
import './Badge.scss';

type Props = {
    /** Less side padding and bigger font. Since this has rounded border by default, this prop is only useful when you are showing a single digit. */
    round?: boolean,

    className?: string,
    children?: ReactNode,
};

const Badge = ({
    round, className, children, ...otherProps
}: Props) => {

    return (
        <span
            className={classNames('badge', className, {
                'round': round,
            })}
            {...otherProps}
        >
            {children}
        </span>
    );
};

export default Badge;