import { IconText } from '@premier/ui';
import { PageHeader } from 'components/Common';

type Props = {};

const InvoiceErrorPage = (props: Props) => {
    const description =
        'Failed to load invoice. An unexpected error has occurred.';

    return (
        <>
            <PageHeader backButton />
            <IconText alert>{description}</IconText>
        </>
    );
};

export default InvoiceErrorPage;
