import { useEffect, useState } from "react";
import { ResponsiveTable, ErrorText, PaginationControl } from "@premier/ui";
import labels from "constants/labels";
import { PlatformRoutesConfiguration } from "components/Routing";
import dateUtil from "@premier/utils/date";
import "./PaymentPlanOptionList.scss";
const MAX_OPTIONS_PER_PAGE = 10;

const PaymentPlanOptionList = ({
    apiData, apiErrors = [],
    billerCode
}) => {
    const statusGetter = (availableFrom, availableTo) => {
        const dateNow = new Date(); // This gives the current time
        return (!availableFrom || new Date(availableFrom) <= dateNow) && (!availableTo || dateNow <= new Date(availableTo)) ? "Available" : "Unavailable";
    };

    const availablePeriodGetter = (availableFrom, availableTo) => {
        if (availableFrom && availableTo) {
            return `${dateUtil.convertToDateString(availableFrom)} to ${dateUtil.convertToDateString(availableTo)}`;
        } else if (availableFrom && !availableTo) {
            return `From ${dateUtil.convertToDateString(availableFrom)}`;
        } else if (!availableFrom && availableTo) {
            return `Until ${dateUtil.convertToDateString(availableTo)}`;
        } else {
            return "Always available";
        }
    };

    const frequencyGetter = (frequency, interval) => {
        if (!frequency) {
            return "Once";
        } else {
            return `Recur every ${frequency} ${interval.toLowerCase()}${frequency > 1 ? "s" : ""}`;
        }
    };

    const lastUpdatedOnGetter = (lastUpdateDate) => {
        return `${dateUtil.convertToDateTimeString(lastUpdateDate)}`;
    };

    const columns = [{
        label: labels.optionName,
        getter: r => <span className="option-name">{r.paymentPlanOptionName}</span>,
    }, {
        label: labels.status,
        getter: r => statusGetter(r.availableFrom, r.availableTo)
    }, {
        label: labels.availablePeriod,
        getter: r => availablePeriodGetter(r.availableFrom, r.availableTo)
    }, {
        label: labels.frequency,
        getter: r => frequencyGetter(r.frequency, r.interval)
    }, {
        label: labels.lastUpdatedOn,
        getter: r => lastUpdatedOnGetter(r.lastUpdated)
    }];

    const [paginationState, setPaginationState] = useState({
        currentPage: 1,
        currentPageOptions: []
    });

    // Handles the pagination logic, assumes that data props will contain all options for a biller code will be retrieved upon API call
    const onPageChanged = (newPage) => {
        setPaginationState({
            currentPage: newPage,
            currentPageOptions: apiData.slice((newPage - 1) * MAX_OPTIONS_PER_PAGE, newPage * MAX_OPTIONS_PER_PAGE),
        });
    };

    // If new options have been loaded, reset the pagination state
    useEffect(() => {
        if (apiData)
            onPageChanged(1);
    }, [apiData]);

    return (<>
        {apiErrors ? apiErrors.map((error, i) => <ErrorText key={i}>{error}</ErrorText>) : null}
        <ResponsiveTable
            data={paginationState.currentPageOptions}
            className="payment-plan-option-list"
            isLoading={apiData === null}
            columns={columns}
            getRowLink={c => PlatformRoutesConfiguration.paymentPlanSettingsRoute.editPaymentPlanOption.generatePath(billerCode, c.paymentPlanOptionId)}
            noDataText="No options to display"
        />
        <PaginationControl currentPage={paginationState.currentPage} onPageChanged={onPageChanged} itemCount={apiData?.length ?? 0} itemsPerPage={MAX_OPTIONS_PER_PAGE} />
    </>);
};

export default PaymentPlanOptionList;
