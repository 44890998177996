import React from 'react';
import PropTypes from 'prop-types';

import currencyUtil from '@premier/utils/currency';
import { getSurchargeableRules, getSurchargeRate, getMinimumSurcharge, getMaximumSurcharge } from '@premier/utils/settings';
import { CardLogo, Dialog, ResponsiveTable } from '@premier/ui';

import { CardLocalisationEnum, CardSubTypeEnum } from 'constants/billpay';
import labels from 'constants/labels';

/** Dialog to show specific biller code's surcharge examples, with rules passed in */
const BillerSurchargeTableModal = ({
    paymentMethods, surchargeRules, //data
    show, onClose //modal logic
}) => {
    let currencyAlpha = currencyUtil.getCurrencyAlpha()

    function getDialogData() {
        if (surchargeRules) {
            let rules = getSurchargeableRules(surchargeRules, paymentMethods);

            let surchargeTableData = rules
                .map(surcharge => ({
                    card: <CardLogo cardTypeCode={surcharge.cardTypeCode} />,
                    description: <>
                        {surcharge.cardSubType === CardSubTypeEnum.DEBIT && <span>Debit</span>}
                        {surcharge.cardSubType === CardSubTypeEnum.CREDIT && <span>Credit</span>}
                        {surcharge.cardSubType !== CardSubTypeEnum.UNSPECIFIED && surcharge.cardLocalisation !== CardLocalisationEnum.UNSPECIFIED && <br/> }
                        {surcharge.cardLocalisation === CardLocalisationEnum.LOCAL && <span>{labels.local}</span>}
                        {surcharge.cardLocalisation === CardLocalisationEnum.INTERNATIONAL && <span>{labels.international}</span>}
                    </>,
                    surchargerate: getSurchargeRate(surcharge, currencyAlpha),
                    minimumsurcharge: getMinimumSurcharge(surcharge),
                    maximumsurcharge: getMaximumSurcharge(surcharge),
                }));
            return surchargeTableData;
        }
    }

    return (
        <Dialog
            closeButton
            dismissable
            show={show}
            onClose={onClose}
            title={`Surcharge Rates (${currencyAlpha})`}
        >
            <ResponsiveTable breakpoints={['xs', 'sm']} className='surcharge-table'
                data={getDialogData()}
                columns={[
                    { getter: 'card', label: 'Option' },
                    { getter: 'description', label: '' },
                    { getter: 'surchargerate', label: 'Surcharge Rate' },
                    { getter: 'minimumsurcharge', label: 'Minimum Surcharge' },
                    { getter: 'maximumsurcharge', label: 'Maximum Surcharge' }
                ]}
            />
        </Dialog>
    )
}

BillerSurchargeTableModal.propTypes = {
    /** From the Form values */
    rules: PropTypes.arrayOf(PropTypes.shape({
        cardTypeCode: PropTypes.string,
        cardSubType: PropTypes.string,
        cardLocalisation: PropTypes.string,
        fixedSurchargeAmount: PropTypes.number,
        fixedSurchargePercent: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        surchargeLimits: PropTypes.shape({
            minimum: PropTypes.number,
            maximum: PropTypes.number
        }),
    })),

    /** To show / hide the dialog */
    show: PropTypes.bool,

    /** Function to run when close */
    onClose: PropTypes.func
};

export default BillerSurchargeTableModal;