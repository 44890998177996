import * as accountActions from 'components/Account/_actions/actionTypes';
import * as actions from '../_actions/actionTypes';

export default function settlementReducer(state = {}, action) {
    switch (action.type) {

        case actions.GET_SETTLEMENT_REPORT_SUCCESS:
            return Object.assign({}, state, {
                report: action.report
            });

        case actions.GET_SETTLEMENT_EXPORT_TYPES_SUCCESS:
            return Object.assign({}, state, {
                exportTypes: action.exportTypes
            });

        case actions.GET_SETTLEMENT_EXPORT_COLUMNS_SUCCESS:
            return Object.assign({}, state, {
                exportColumns: action.exportColumns
            });

        case actions.GET_SETTLEMENT_EXPORT_OPTIONS_SUCCESS:
            return Object.assign({}, state, {
                exportOptions: action.exportOptions
            });


            
        case accountActions.LOGOUT_USER_SUCCESS:
            return {
                asyncCount: state.asyncCount,
                isLoading: state.isLoading,
            };

        default:
            return state;
    }
}
