import {combineReducers} from 'redux';
import { requestActionReducer } from 'components/Common/requestActions';
import * as actions from '../_actions/actionTypes';

function pageStateReducer(state = {}, action) {
    switch(action.type) {
        case actions.SAVE_PAYMENT_REQUEST_PAGE_STATE:
            return Object.assign({}, state, {...action.pageState});

        default:
            return state;
    }
}

const paymentRequestReducer = combineReducers({
    searchResult: requestActionReducer(actions.SEARCH_PAYMENT_REQUESTS),
    details: requestActionReducer(actions.GET_PAYMENT_REQUEST),
    status: requestActionReducer(actions.GET_PAYMENT_REQUEST_STATUS),
    create: requestActionReducer(actions.CREATE_PAYMENT_REQUEST),
    cancel: requestActionReducer(actions.CANCEL_PAYMENT_REQUEST),
    resend: requestActionReducer(actions.RESEND_PAYMENT_REQUEST),
    requestActions: requestActionReducer(actions.GET_PAYMENT_REQUEST_ACTIONS),
    requestStatuses: requestActionReducer(actions.GET_PAYMENT_REQUEST_STATUSES),
    requestPageState: pageStateReducer,
    requestConfig: requestActionReducer(actions.GET_REQUEST_CONFIG),
    updateRequestConfig: requestActionReducer(actions.UPDATE_REQUEST_CONFIG),
});

export default paymentRequestReducer;