import React from 'react';
import PropTypes from 'prop-types';

import { Badge, Link } from '@premier/ui';
import { PlatformRoutesConfiguration } from 'components/Routing';

import './CheckoutSteps.scss';

import DeliveryImpactNotice from './DeliveryImpactNotice';


const CheckoutSteps = ({
    currentStep //data
}) => {

    const steps = [{
        label: 'Shopping cart',
        url: PlatformRoutesConfiguration.stationeryRoute.shoppingCart.generatePath()
    },{
        label: 'Billing & delivery address',
        url: PlatformRoutesConfiguration.stationeryRoute.checkoutAddress.generatePath()
    },{
        label: 'Review & submit',
        url: PlatformRoutesConfiguration.stationeryRoute.checkoutReview.generatePath()
    }];

    function renderStep(step, index) {
        var text = <><Badge round>{index+1}</Badge> {step.label}</>;

        if(index < currentStep-1)
            return <li key={index}><Link to={step.url}>{text}</Link></li>;
        else if(index === currentStep-1)
            return <li key={index} className='active'>{text}</li>;
        else
            return <li key={index} className='disabled'>{text}</li>;
    }

    return (<>
        <DeliveryImpactNotice />

        <ul className='checkout-steps'>
            { steps.map(renderStep) }
        </ul>
    </>);
};

CheckoutSteps.propTypes = {
    /** 1, 2 or 3 */
    currentStep: PropTypes.number.isRequired,
};

export default CheckoutSteps;