import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '@premier/ui';
import { FormDialog, SubmitButton, RadioField } from '@premier/form';

import { NewTokenOptions } from 'constants/billpay';


/** Called from customer details. Lets you choose New or Existing Token. */
const AddPaymentMethodDialog = ({
    show, //logic renders
    onSubmit, onClose //functions
}) => {

    const addOptions = [
        {label: 'New payment method', value: NewTokenOptions.ADD_NEW},
        {label: 'Link existing payment method', value: NewTokenOptions.LINK_EXISTING}
    ];

    return (
        <FormDialog show={show}
            title='Add payment method'
            initialValues={{ newTokenOption: NewTokenOptions.ADD_NEW }}
            onSubmit={onSubmit}
            renderButtons={context => (<>
                <SubmitButton>Add</SubmitButton>
                <Button onClick={onClose}>Cancel</Button>
            </>)}
        >
            <RadioField name='newTokenOption' options={addOptions} />
        </FormDialog>
    );
}


AddPaymentMethodDialog.propTypes = {
    //property to display modal
    show: PropTypes.bool.isRequired,

    //function to handle Add button click
    onSubmit: PropTypes.func.isRequired,

    //function to close modal from calling page
    onClose: PropTypes.func.isRequired,
};

export default AddPaymentMethodDialog;