import { defaultPageSize } from 'constants/billpay';
import * as actions from './actionTypes';
import { transactionsApi } from 'api';

//#region ----- Utilities -----

export function getChildMerchants() {
    return function(dispatch) {
        dispatch(getChildMerchantsRequested());

        return transactionsApi.getChildMerchants().then(childMerchants => {
            dispatch(getChildMerchantsSuccess(childMerchants));
        }).catch(err => {
            dispatch(getChildMerchantsFailed(err));
            throw err;
        });
    }
}

export function getChildMerchantsRequested() {
    return {type: actions.GET_CHILD_MERCHANTS_REQUESTED};
}
export function getChildMerchantsSuccess(childMerchants) {
    return {type: actions.GET_CHILD_MERCHANTS_SUCCESS, childMerchants};
}
export function getChildMerchantsFailed(err) {
    return {type: actions.GET_CHILD_MERCHANTS_FAILED, err};
}

//#endregion

//#region ----- Transaction History -----

export function getTransactions(resultsPerPage = defaultPageSize, pageIndex = 0, filter = {}, sort, customerId) {
    return function(dispatch) {
        dispatch(loadTransactionsRequested());

        return transactionsApi.getTransactions(resultsPerPage, pageIndex, filter, sort, customerId).then(({transactions, resultCount}) => {
            dispatch(loadTransactionsSuccess(transactions, resultCount));
            dispatch(saveTransactionsPageState({resultsPerPage, pageIndex, filter, sort}));
            return {transactions, resultCount};
        }).catch(err => {
            dispatch(loadTransactionsFailed(err));
            throw err;
        });
    }
}

export function getScheduledPaymentTransactions(resultsPerPage = defaultPageSize, pageIndex = 0, filter = {}, sort, customerId) {
    return function(dispatch) {
        dispatch(loadScheduledPaymentTransactionsRequested());

        return transactionsApi.getTransactions(resultsPerPage, pageIndex, filter, sort, customerId).then(({transactions, resultCount}) => {
            dispatch(loadScheduledPaymentTransactionsSuccess(transactions, resultCount));
            dispatch(saveScheduledPaymentTransactionsPageState({resultsPerPage, pageIndex, filter, sort}));
            return {transactions, resultCount};
        }).catch(err => {
            dispatch(loadScheduledPaymentTransactionsFailed(err));
            throw err;
        });
    }
}

export function downloadTransactionsExport(format, filter = {}) {
    return function(dispatch) {
        dispatch(downloadTransactionsExportRequested());

        return transactionsApi.downloadTransactionsExport(format, filter).then(report => {
            dispatch(downloadTransactionsExportSuccess());
            return report;
        }).catch(err => {
            dispatch(downloadTransactionsExportFailed(err));
            throw err;
        });
    }
}

export function getTransactionDetails(id, isExternal) {
    return function(dispatch) {
        dispatch(loadTransactionDetailsRequested());

        return transactionsApi.getTransactionDetails(id, isExternal).then(transaction => {
            dispatch(loadTransactionDetailsSuccess(transaction));
        }).catch(err => {
            dispatch(loadTransactionDetailsFailed(err));
            throw err;
        });
    }
}


export function loadTransactionsRequested() {
    return { type: actions.LOAD_TRANSACTIONS_REQUESTED }
}
export function loadTransactionsSuccess(transactions, resultCount) {
    return {type: actions.LOAD_TRANSACTIONS_SUCCESS, transactions, resultCount};
}
export function loadTransactionsFailed(err) {
    return {type: actions.LOAD_TRANSACTIONS_FAILED, err};
}

export function loadTransactionDetailsRequested() {
    return {type: actions.LOAD_TRANSACTION_DETAILS_REQUESTED};
}
export function loadTransactionDetailsSuccess(transactionDetails) {
    return {type: actions.LOAD_TRANSACTION_DETAILS_SUCCESS, transactionDetails};
}
export function loadTransactionDetailsFailed(err) {
    return {type: actions.LOAD_TRANSACTION_DETAILS_FAILED, err};
}

export function downloadTransactionsExportRequested() {
    return { type: actions.DOWNLOAD_TRANSACTIONS_EXPORT_REQUESTED }
}
export function downloadTransactionsExportSuccess() {
    return {type: actions.DOWNLOAD_TRANSACTIONS_EXPORT_SUCCESS};
}
export function downloadTransactionsExportFailed(err) {
    return {type: actions.DOWNLOAD_TRANSACTIONS_EXPORT_FAILED, err};
}

export function loadScheduledPaymentTransactionsRequested() {
    return { type: actions.LOAD_SCHEDULED_PAYMENT_TRANSACTIONS_REQUESTED }
}
export function loadScheduledPaymentTransactionsSuccess(transactions, resultCount) {
    return {type: actions.LOAD_SCHEDULED_PAYMENT_TRANSACTIONS_SUCCESS, transactions, resultCount};
}
export function loadScheduledPaymentTransactionsFailed(err) {
    return {type: actions.LOAD_SCHEDULED_PAYMENT_TRANSACTIONS_FAILED, err};
}
export function saveScheduledPaymentTransactionsPageState(pageState) {
    return {type: actions.SAVE_SCHEDULED_PAYMENT_TRANSACTIONS_PAGE_STATE, pageState};
}
//#endregion

//#region ----- Transaction Filter -----

/**
 * Gets a list of "TransactionTypes" (known as TxnFinancialTypes on the backend)
 */
export function getTransactionTypes() {
    return function(dispatch) {
        dispatch(getTransactionTypesRequested());

        return transactionsApi.getTransactionTypes().then(types => {
            dispatch(getTransactionTypesSuccess(types));
        }).catch(err => {
            dispatch(getTransactionTypesFailed(err));
            throw err;
        });
    }
}

export function getTransactionTypesRequested() {
    return {type: actions.GET_TRANSACTION_TYPES_REQUESTED};
}
export function getTransactionTypesSuccess(transactionTypes) {
    return {type: actions.GET_TRANSACTION_TYPES_SUCCESS, transactionTypes};
}
export function getTransactionTypesFailed(err) {
    return {type: actions.GET_TRANSACTION_TYPES_FAILED, err};
}

export function getTxnTypes() {
    return function(dispatch) {
        dispatch(getTxnTypesRequested());

        return transactionsApi.getTxnTypes().then(types => {
            dispatch(getTxnTypesSuccess(types));
        }).catch(err => {
            dispatch(getTxnTypesFailed(err));
            throw err;
        });
    }
}

export function getTxnTypesRequested() {
    return {type: actions.GET_TXN_TYPES_REQUESTED};
}
export function getTxnTypesSuccess(txnTypes) {
    return {type: actions.GET_TXN_TYPES_SUCCESS, txnTypes};
}
export function getTxnTypesFailed(err) {
    return {type: actions.GET_TXN_TYPES_FAILED, err};
}


export function getTxnSubTypes() {
    return function(dispatch) {
        dispatch(getTxnSubTypesRequested());

        return transactionsApi.getTxnSubTypes().then(types => {
            dispatch(getTxnSubTypesSuccess(types));
        }).catch(err => {
            dispatch(getTxnSubTypesFailed(err));
            throw err;
        });
    }
}

export function getTxnSubTypesRequested() {
    return {type: actions.GET_TXN_SUB_TYPES_REQUESTED};
}
export function getTxnSubTypesSuccess(txnSubTypes) {
    return {type: actions.GET_TXN_SUB_TYPES_SUCCESS, txnSubTypes};
}
export function getTxnSubTypesFailed(err) {
    return {type: actions.GET_TXN_SUB_TYPES_FAILED, err};
}


export function getTxnSources() {
    return function(dispatch) {
        dispatch(getTxnSourcesRequested());

        return transactionsApi.getTxnSources().then(types => {
            dispatch(getTxnSourcesSuccess(types));
        }).catch(err => {
            dispatch(getTxnSourcesFailed(err));
            throw err;
        });
    }
}

export function getTxnSourcesRequested() {
    return {type: actions.GET_TXN_SOURCES_REQUESTED};
}
export function getTxnSourcesSuccess(txnSources) {
    return {type: actions.GET_TXN_SOURCES_SUCCESS, txnSources};
}
export function getTxnSourcesFailed(err) {
    return {type: actions.GET_TXN_SOURCES_FAILED, err};
}



export function getTxnSubSources() {
    return function(dispatch) {
        dispatch(getTxnSubSourcesRequested());

        return transactionsApi.getTxnSubSources().then(types => {
            dispatch(getTxnSubSourcesSuccess(types));
        }).catch(err => {
            dispatch(getTxnSubSourcesFailed(err));
            throw err;
        });
    }
}

export function getTxnSubSourcesRequested() {
    return {type: actions.GET_TXN_SUB_SOURCES_REQUESTED};
}
export function getTxnSubSourcesSuccess(txnSubSources) {
    return {type: actions.GET_TXN_SUB_SOURCES_SUCCESS, txnSubSources};
}
export function getTxnSubSourcesFailed(err) {
    return {type: actions.GET_TXN_SUB_SOURCES_FAILED, err};
}



export function getAccountTypes() {
    return function(dispatch) {
        dispatch(getAccountTypesRequested());

        return transactionsApi.getAccountTypes().then(types => {
            dispatch(getAccountTypesSuccess(types));
        }).catch(err => {
            dispatch(getAccountTypesFailed(err));
            throw err;
        });
    }
}

export function getAccountTypesRequested() {
    return {type: actions.GET_ACCOUNT_TYPES_REQUESTED};
}
export function getAccountTypesSuccess(accountTypes) {
    return {type: actions.GET_ACCOUNT_TYPES_SUCCESS, accountTypes};
}
export function getAccountTypesFailed(err) {
    return {type: actions.GET_ACCOUNT_TYPES_FAILED, err};
}

export function saveTransactionsPageState(pageState) {
    return {type: actions.SAVE_TRANSACTIONS_PAGE_STATE, pageState};
}

//#endregion

//#region ----- Transaction Refund + Capture -----

export function refundPaymentRequested() {
    return {type: actions.REFUND_PAYMENT_REQUESTED};
}
export function refundPaymentSuccess(receipt) {
    return {type: actions.REFUND_PAYMENT_SUCCESS, receipt};
}
export function refundPaymentFailed(err) {
    return {type: actions.REFUND_PAYMENT_FAILED, err};
}

export function refundPayment(paymentDetails) {
    return function(dispatch) {
        dispatch(refundPaymentRequested());

        return transactionsApi.refundPayment(paymentDetails).then(receipt => {
            dispatch(refundPaymentSuccess(receipt));
            return receipt;
        }).catch(err => {
            dispatch(refundPaymentFailed(err));
            throw err;
        });
    }
}

export function capturePaymentRequested() {
    return {type: actions.CAPTURE_PAYMENT_REQUESTED};
}
export function capturePaymentSuccess(receipt) {
    return {type: actions.CAPTURE_PAYMENT_SUCCESS, receipt};
}
export function capturePaymentFailed(err) {
    return {type: actions.CAPTURE_PAYMENT_FAILED, err};
}

export function capturePayment(paymentDetails) {
    return function(dispatch) {
        dispatch(capturePaymentRequested());

        return transactionsApi.capturePayment(paymentDetails).then(receipt => {
            dispatch(capturePaymentSuccess(receipt));
            return receipt;
        }).catch(err => {
            dispatch(capturePaymentFailed(err));
            throw err;
        });
    }
}

//#endregion

//#region ----- New Payment (Single Payment) -----

export function getTransactionSourcesRequested() {
    return {type: actions.GET_TRANSACTION_SOURCES_REQUESTED};
}
export function getTransactionSourcesSuccess(data) {
    return {type: actions.GET_TRANSACTION_SOURCES_SUCCESS, data};
}
export function getTransactionSourcesFailed(err) {
    return {type: actions.GET_TRANSACTION_SOURCES_FAILED, err};
}

export function getTransactionSources() {
    return function(dispatch) {
        dispatch(getTransactionSourcesRequested());

        return transactionsApi.getTransactionSources().then(data => {
            dispatch(getTransactionSourcesSuccess(data));
            return data;
        }).catch(err => {
            dispatch(getTransactionSourcesFailed(err));
            throw err;
        });
    }
}

export function clearNewPayment() {
    return {type: actions.CLEAR_NEW_PAYMENT}
}

export function setNewPaymentDetails(details) {
    return {type: actions.SET_NEW_PAYMENT_DETAILS, details}
}

export function getNewPaymentSurchargeRequested(){
    return {type: actions.GET_NEW_PAYMENT_SURCHARGE_REQUESTED};
}

export function getNewPaymentSurchargeSuccess(surchargeInfo) {
    return {type: actions.GET_NEW_PAYMENT_SURCHARGE_SUCCESS, surchargeInfo};
}

export function getNewPaymentSurchargeFailed(err){
    return {type: actions.GET_NEW_PAYMENT_SURCHARGE_FAILED, err};
}

export function getNewPaymentSurcharge(paymentInfo){
    return function(dispatch) {
        dispatch(getNewPaymentSurchargeRequested());

        return transactionsApi.getCardSurchargeDetails(paymentInfo).then(data => {
            dispatch(getNewPaymentSurchargeSuccess(data));
            return data;
        }).catch(err => {
            dispatch(getNewPaymentSurchargeFailed(err));
            throw err;
        })
    }
}

export function createNewPaymentRequested() {
    return {type: actions.CREATE_NEW_PAYMENT_REQUESTED};
}
export function createNewPaymentSuccess(receipt) {
    return {type: actions.CREATE_NEW_PAYMENT_SUCCESS, receipt};
}
export function createNewPaymentFailed(err) {
    return {type: actions.CREATE_NEW_PAYMENT_FAILED, err};
}

export function createNewPayment(paymentDetails, mapErrorsFromDto) {
    return function(dispatch) {
        dispatch(createNewPaymentRequested());

        return transactionsApi.createNewPayment(paymentDetails, mapErrorsFromDto)
        .then(receipt => {
            dispatch(createNewPaymentSuccess(receipt));
            return receipt;
        }).catch(err => {
            dispatch(createNewPaymentFailed(err));
            throw err;
        });
    }
}

export function sendPaymentReceiptRequested() {
    return {type: actions.SEND_PAYMENT_RECEIPT_REQUESTED};
}
export function sendPaymentReceiptSuccess(receipt) {
    return {type: actions.SEND_PAYMENT_RECEIPT_SUCCESS, receipt};
}
export function sendPaymentReceiptFailed(err) {
    return {type: actions.SEND_PAYMENT_RECEIPT_FAILED, err};
}

export function sendPaymentReceipt(paymentDetails) {
    return function(dispatch) {
        dispatch(sendPaymentReceiptRequested());

        return transactionsApi.sendPaymentReceipt(paymentDetails).then(receipt => {
            dispatch(sendPaymentReceiptSuccess(receipt));
            return receipt;
        }).catch(err => {
            dispatch(sendPaymentReceiptFailed(err));
            throw err;
        });
    }
}

//#endregion

//#region ----- Print Receipt -----

export function getMerchantAddressRequested() {
    return {type: actions.GET_MERCHANT_ADDRESS_REQUESTED};
}
export function getMerchantAddressSuccess(address) {
    return {type: actions.GET_MERCHANT_ADDRESS_SUCCESS, address};
}
export function getMerchantAddressFailed(err) {
    return {type: actions.GET_MERCHANT_ADDRESS_FAILED, err};
}
export function getMerchantAddress(merchantId, merchantNumber) {
    return function (dispatch) {
        dispatch(getMerchantAddressRequested());

        return transactionsApi.getMerchantAddress(merchantId, merchantNumber).then(address => {
            dispatch(getMerchantAddressSuccess(address));
        }).catch(err => {
            dispatch(getMerchantAddressFailed(err));
            throw err;
        });
    }
}

//#endregion
