import * as actions from '../_actions/actionTypes';
import * as accountActions from 'components/Account/_actions/actionTypes';
import * as commonActions from 'components/Common/_actions/actionTypes';
import * as utilitiesActions from 'components/Utilities/_actions/actionTypes';

export default function paymentsReducer(state = {}, action) {
    switch(action.type) {

        //#region ----- Utilities -----
        case actions.GET_CHILD_MERCHANTS_SUCCESS:
            return Object.assign({}, state, {
                childMerchants: action.childMerchants
            });

        //#endregion

        //#region ----- Transaction History -----
        case actions.LOAD_TRANSACTIONS_SUCCESS:
            return Object.assign({}, state, {
                searchResult: {
                    items: action.transactions,
                    count: action.resultCount
                }
            });
        case actions.LOAD_SCHEDULED_PAYMENT_TRANSACTIONS_SUCCESS:
            return Object.assign({}, state, {
                scheduledPaymentTransactionsSearchResult: {
                    items: action.transactions,
                    count: action.resultCount
                }
            });

        case actions.LOAD_TRANSACTION_DETAILS_SUCCESS:
            return Object.assign({}, state, {
                transactionDetails: action.transactionDetails
            });
        //#endregion

        //#region ----- Transaction Filter -----
        case actions.GET_TRANSACTION_TYPES_SUCCESS:
            return Object.assign({}, state, {
                transactionTypes: action.transactionTypes
            });

        case utilitiesActions.GET_ORDER_TYPES_SUCCESS:
            return Object.assign({}, state, {
                orderTypes: action.orderTypes
            });

        case actions.GET_TXN_TYPES_SUCCESS:
            return Object.assign({}, state, {
                txnTypes: action.txnTypes
            });

        case actions.GET_TXN_SUB_TYPES_SUCCESS:
            return Object.assign({}, state, {
                txnSubTypes: action.txnSubTypes
            });

        case actions.GET_TXN_SOURCES_SUCCESS:
            return Object.assign({}, state, {
                txnSources: action.txnSources
            });

        case actions.GET_TXN_SUB_SOURCES_SUCCESS:
            return Object.assign({}, state, {
                txnSubSources: action.txnSubSources
            });

        case actions.GET_ACCOUNT_TYPES_SUCCESS:
            return Object.assign({}, state, {
                accountTypes: action.accountTypes
            });

        case actions.SAVE_TRANSACTIONS_PAGE_STATE:
            return Object.assign({}, state, {
                transactionsPageState: action.pageState
            });

        //#endregion

        //#region ----- Transaction Refund + Capture -----
        case actions.REFUND_PAYMENT_SUCCESS:
            return Object.assign({}, state, {
                refundReceipt: action.receipt
            });

        case actions.CAPTURE_PAYMENT_SUCCESS:
            return Object.assign({}, state, {
                captureReceipt: action.receipt
            });

        //#endregion

        //#region ----- New Payment (Single Payment) -----
        case actions.GET_TRANSACTION_SOURCES_SUCCESS:
            return Object.assign({}, state, {
                transactionSources: action.data
            });

        case actions.CREATE_NEW_PAYMENT_SUCCESS:
            return Object.assign({}, state, {
                paymentReceipt: action.receipt
            });

        case utilitiesActions.GET_PROCESS_TRANSACTION_ORDER_TYPES_REQUESTED:
            return Object.assign({}, state, {
                isPaymentFormLoading: true
            });

        case utilitiesActions.GET_PROCESS_TRANSACTION_ORDER_TYPES_SUCCESS:
            return Object.assign({}, state, {
                isPaymentFormLoading: false,
                newPaymentOrderTypes: action.orderTypes
            });

        case utilitiesActions.GET_PROCESS_TRANSACTION_ORDER_TYPES_FAILED:
            return Object.assign({}, state, {
                isPaymentFormLoading: false
            });

        case actions.CLEAR_NEW_PAYMENT:
            return Object.assign({}, state, {
                newPayment: {}
            });

        case actions.SET_NEW_PAYMENT_DETAILS:
            return Object.assign({}, state, {
                newPayment: action.details
            });

        case actions.GET_NEW_PAYMENT_SURCHARGE_SUCCESS:
            return Object.assign({}, state, {
                newPayment: Object.assign({}, state.newPayment, {
                    surchargeInfo: action.surchargeInfo
                })
            });
        //#endregion

        //#region ----- Print Receipt -----
        case actions.GET_MERCHANT_ADDRESS_SUCCESS:
            return Object.assign({}, state, {
                merchantAddress: action.address
            });
        //#endregion



        case accountActions.LOGOUT_USER_SUCCESS:
        case commonActions.CLEAR_SENSITIVE_DATA:
            return {
                asyncCount: state.asyncCount,
                isLoading: state.isLoading,
            };
        default:
            return state;
    }
}
