import React from 'react';
import { Icon } from '@premier/ui';
import { PaymentPlanModelStatusEnum } from '@premier/webapi-client'

interface PaymentPlanStatusIconProps {
    status : PaymentPlanModelStatusEnum
}

const PaymentPlanStatusIcon : React.FC<PaymentPlanStatusIconProps> = ({ status }) => {
    switch (status) {
        case 'ACTIVE':
        case 'COMPLETED':
            return <Icon tick />
        case 'CANCELLED':
            return <Icon info/>
        case 'PENDING':
            return <Icon question/>
        case 'NONE':
            return <Icon alert />
    }
}

export default PaymentPlanStatusIcon;