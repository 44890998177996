import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import uiUtil from '@premier/utils/ui';
import labels from 'constants/labels';
import { Accordion, Divider } from '@premier/ui';
import { InputField, DropdownField, PhoneNumberField, CurrencyRangeField, DatePickerField } from '@premier/form';
import { withAdvancedFilter } from 'components/Common';

const PaymentRequestFilter = ({
    context, // from withAdvancedFilter
    billers, requestStatuses, requestActions  // data
}) => {

    // Dropdown options
    const billerOptions = useMemo(
        () => uiUtil.addAllOption(billers.map(b => ({value: b.billerCode, label: b.billerCodeWithName}))),
        [billers]
    );
    const requestStatusOptions = uiUtil.generateOptions(requestStatuses);
    const requestActionOptions = uiUtil.generateOptions(requestActions);

    function handleAmountChange(val) {
        // Clear the amount quick-filter, as we should only have 1 set at a time (amount OR amountRange)
        if (val && (val.min || val.max))
            context.setValue("amount", '');
    }

    return (
        <>
            <h1>Filter requests</h1>

            <Accordion title='By biller information' enableDownMd>
                <div className='row'>
                    <div className='col-lg-4 col-xl-4'>
                        <DropdownField
                            name='billerCode'
                            label={labels.billerCode}
                            options={billerOptions}
                        />
                    </div>
                    <div className='col-lg-4 col-xl-4'>
                        <InputField name='reference1' label={labels.reference1} />
                    </div>
                    <div className='col-lg-4 col-xl-4'>
                        <InputField name='reference2' label={labels.reference2} />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg-4 col-xl-4'>
                        <InputField name='reference3' label={labels.reference3} />
                    </div>
                </div>
            </Accordion>
            <Divider />

            <Accordion title='By request details' enableDownMd>
                <div className='row'>
                    <div className='col-lg-4 col-xl-4'>
                        <DropdownField
                            name='status'
                            options={requestStatusOptions}
                            label={labels.status}
                        />
                    </div>
                    <div className='col-lg-4 col-xl-4'>
                        <DropdownField
                            name='action'
                            options={requestActionOptions}
                            label={labels.action}
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg-8 col-xl-8'>
                        <CurrencyRangeField name='amountRange' label={labels.amount} onChange={handleAmountChange} />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg-4 col-xl-4'>
                        <DatePickerField name='createdDateRange' mode='range' label={labels.dateSent} />
                    </div>
                    <div className='col-lg-4 col-xl-4'>
                        <DatePickerField name='dueDateRange' mode='range' label={labels.dueDate} />
                    </div>
                </div>
            </Accordion>
            <Divider />

            <Accordion title='By customer' enableDownMd>
                <div className='row'>
                    <div className='col-lg-4 col-xl-4'>
                        <InputField name='customerUniqueCode' label={labels.customerCode} />
                    </div>
                    <div className='col-lg-4 col-xl-4'>
                        <InputField name='customerName' label={labels.name} />
                    </div>
                    <div className='col-lg-4 col-xl-4'>
                        <InputField name='customerEmailAddress' label={labels.email} />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg-8 col-xl-8'>
                        <PhoneNumberField name='customerPhoneNumber' label={labels.phone} lessGapDownMd />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg-4 col-xl-4'>
                        <InputField name='customerIdentifier1' label={labels.customerId1} />
                    </div>
                    <div className='col-lg-4 col-xl-4'>
                        <InputField name='customerIdentifier2' label={labels.customerId2} />
                    </div>
                    <div className='col-lg-4 col-xl-4'>
                        <InputField name='customerIdentifier3' label={labels.customerId3} />
                    </div>
                </div>
            </Accordion>
        </>
    );
};

PaymentRequestFilter.propTypes = {
    billers: PropTypes.array,
    requestStatuses: PropTypes.object,
    requestActions: PropTypes.object,

    // The rest of the props are in advancedFilterComponent.propTypes in withAdvancedFilter.js
};

export default withAdvancedFilter(PaymentRequestFilter);