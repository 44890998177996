import { useDispatch, useSelector } from "react-redux";
import {
    getInvoicesConfig,
    updateInvoiceConfig,
    calculateInvoiceTotals,
} from "components/Settings/_actions/invoiceActions";
import { useEffect } from "react";
import {
    InvoiceConfigModel,
    InvoiceModel,
    InvoiceProductItemModel,
    InvoiceSubTotalItemModel,
} from "packages/webapi-client";
import SecurityUtil from "@premier/utils/security";
import { userRoles } from "components/Routing";

const invoiceSettingsSelector = (state: any) => state.settings.invoice;
const authenticatedUserSelector = (state: any) => state.accounts.users.authenticatedUser;

export const useInvoiceSettings = () => {
    const { isInitial, hasLoaded, isLoading, hasFailed, data: settings } = useSelector(invoiceSettingsSelector);
    const authenticatedUser = useSelector(authenticatedUserSelector);
    const dispatch = useDispatch();

    useEffect(() => {
        if (isInitial) {
            dispatch(getInvoicesConfig());
        }
    }, [dispatch, isInitial]);

    const updateInvoiceSettings = async (values: InvoiceConfigModel) => {
        if (SecurityUtil.hasAccess([userRoles.invoiceSettings], authenticatedUser)) {
            await dispatch(updateInvoiceConfig(values));
            dispatch(getInvoicesConfig());
        }
    };

    const calculateTotals = (
        items: InvoiceProductItemModel[],
        discountFees: InvoiceSubTotalItemModel[],
        callback: (model: InvoiceModel) => void
    ) => {
        if (SecurityUtil.hasAccess([userRoles.invoices], authenticatedUser)) {
            return dispatch(
                calculateInvoiceTotals({
                    invoiceProductItems: items,
                    invoiceSubTotalItems: discountFees,
                })
            ).then((r) => {
                if (r.ok && callback) {
                    callback(r.val);
                }
            });
        }
    };

    return {
        settings: settings as InvoiceConfigModel,
        isLoading,
        hasLoaded,
        hasFailed,
        updateInvoiceSettings,
        calculateTotals,
    };
};
