import React from 'react';
import { Input } from '@premier/ui';
import withRangeField from './withRangeField';

const NumberRangeField = (props) => {
    return (
        <Input digitsOnly {...props} />
    );
};

// For the PropTypes, see the rangeComponent.propTypes in withRangeField.js

export default withRangeField(NumberRangeField, ['Min number', 'Max number']);
