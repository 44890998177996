import React, { useState } from 'react';
import currencyUtil from '@premier/utils/currency';
import SecurityUtil from '@premier/utils/security';
import { useSelector } from 'react-redux';
import { userRoles } from 'components/Routing';
import { invoiceApi } from 'api';
import { PageHeader, withApiCall } from 'components/Common';
import { ApiError, InvoiceModel } from '@premier/webapi-client';
import { Button, CardContainer, LoadingIndicator } from '@premier/ui';

import InvoiceDetailsList from './InvoiceDetailsList';
import InvoiceErrorPage from '../InvoiceErrorPage';
import InvoiceBillToInformationList from './InvoiceBillToInformationList';
import InvoiceTransactionHistoryList from './InvoiceTransactionHistoryList';
import ViewInvoiceDialog from './ViewInvoiceDialog';
import { getInvoiceStatus, InvoiceStatusEnum } from '../../InvoiceStatusUtil';

import './ViewInvoiceBody.scss';

export type MerchantInvoiceModalState =
    | ''
    | 'RESEND_INVOICE'
    | 'RESEND_ERROR'
    | 'RESEND_SUCCESS'
    | 'CANCEL_INVOICE'
    | 'CANCEL_ERROR'
    | 'CANCEL_SUCCESS';
interface Props {
    apiData: InvoiceModel;
    apiErrors: ApiError[];
    onSubmitSuccess: () => void;
}

const authenticatedUserSelector = (state: any) => state.accounts.users.authenticatedUser;

const ViewInvoiceBody: React.FC<Props> = ({ apiData, apiErrors, onSubmitSuccess }) => {
    const authenticatedUser = useSelector(authenticatedUserSelector);

    const [modalState, setModalState] = useState<MerchantInvoiceModalState>('');

    if (!apiData && !apiErrors) {
        return <LoadingIndicator />;
    } else if (apiErrors && apiErrors.length) {
        return <InvoiceErrorPage />;
    }

    // get transaction history api call ---
    const TransactionHistoryList = withApiCall(InvoiceTransactionHistoryList, () =>
        invoiceApi.getInvoicePayments({ invoiceId: apiData.invoiceId })
    );

    // resend or cancel invoice api call ---
    const resendInvoice = async (email: string) => {
        try {
            const request = await invoiceApi.resendInvoice({
                invoiceId: apiData.invoiceId,
                recipientEmail: email,
            });
            if (request?.status !== 200 || request?.data?.errors?.length) {
                throw request;
            }
            setModalState('RESEND_SUCCESS');
        } catch (request: any) {
            setModalState('RESEND_ERROR');
        }
    };

    const cancelInvoice = async () => {
        try {
            const request = await invoiceApi.cancelInvoice({
                invoiceId: apiData.invoiceId,
            });
            if (request?.status !== 200 || request?.data?.errors?.length) {
                throw request;
            }
            setModalState('CANCEL_SUCCESS');
        } catch (request: any) {
            setModalState('CANCEL_ERROR');
        }
    };

    // refresh and close modal ---
    const handleClose = () => {
        setModalState('');
        onSubmitSuccess();
    };

    return (
        <div>
            <PageHeader
                backButton
                title={getInvoiceStatus(apiData).label}
                subtitle={
                    <p className='invoice-info-status'>
                        Invoice number: {apiData.invoiceNumber} <br />
                        Amount: {currencyUtil.convertToDisplayString(apiData.amountTotal!, apiData.currencyCode!)}
                    </p>
                }
                icon={getInvoiceStatus(apiData).icon}
            >
                {apiData.invoiceStatusId !== InvoiceStatusEnum.Cancelled && (
                    <Button primary onClick={() => setModalState('RESEND_INVOICE')}>
                        Resend
                    </Button>
                )}
            </PageHeader>

            <CardContainer header={<h2>Invoice details</h2>}>
                <InvoiceDetailsList data={apiData} onCancel={() => setModalState('CANCEL_INVOICE')} />
            </CardContainer>

            <CardContainer header={<h2>Bill to information</h2>}>
                <InvoiceBillToInformationList data={apiData.customer!} />
            </CardContainer>

            {SecurityUtil.hasAccess([userRoles.invoicePayments], authenticatedUser) &&
                (apiData.invoiceStatusId === InvoiceStatusEnum.PaidInFull ||
                    apiData.invoiceStatusId === InvoiceStatusEnum.Refunded) && (
                    <TransactionHistoryList currencyCode={apiData.currencyCode} />
                )}

            {/* Send and cancel modal */}
            <ViewInvoiceDialog
                modalState={modalState}
                setModalState={setModalState}
                invoice={apiData}
                onSubmit={resendInvoice}
                onCancel={cancelInvoice}
                onClose={handleClose}
            />
        </div>
    );
};

export default ViewInvoiceBody;
