import React, { useState } from 'react';

import { Input, FormGroup, Icon, Button } from '@premier/ui';

import './QuantityValueChanger.scss';

const QuantityValueChanger = ({ 
    onQtyChange, //functions
    qtyMax, qtyMin, value //data
}) => {

    const [formError, setFormError] = useState('');

    qtyMin = !qtyMin && qtyMin !== 0 ? 1 : qtyMin; //falsy logic to enable qtyMin to have a value of 0.
    qtyMax = qtyMax || 99;

    value = value || qtyMin || 1;

    function checkQty(quantity) {
        if (quantity > qtyMax) {
            setFormError(`Max quantity: ${qtyMax}`);
            return false;
        }

        if (quantity < qtyMin) {
            setFormError(`Min quantity: ${qtyMin}`);
            return false;
        }
        return true;
    }

    function increaseQty() {
        var newQty = value + 1;

        if (checkQty(newQty)) {
            handleQtyChange(newQty);
        } else {
            handleQtyChange(qtyMax);
        }
    }

    function decreaseQty() {
        var newQty = value - 1;

        if (checkQty(newQty)) {
            handleQtyChange(newQty);
        } else {
            handleQtyChange(qtyMin);
        }
    }

    function handleInputChange(e) {
        var value = Number(e.target.value);
        if (checkQty(value)) {
            handleQtyChange(value);
        }
    }

    function handleQtyChange(qty) {
        setFormError('');
        onQtyChange(qty);
    }

    return (
        <div className='quantity-value-controller'>
            <FormGroup fieldId='' label='Qty' error={formError}>
                <div className='quantity-controls'>
                    <Button link className='left' onClick={decreaseQty} disabled={Number(value) === Number(qtyMin)}>
                        <Icon minus />
                    </Button>
                    <Input digitsOnly value={value} error={formError} onChange={handleInputChange} />
                    <Button link className='right' onClick={increaseQty} disabled={Number(value) === Number(qtyMax)}>
                        <Icon plus />
                    </Button>
                </div>
            </FormGroup>
        </div>
    );
};

export default QuantityValueChanger;
