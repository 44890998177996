import { defaultPageSize } from 'constants/billpay';
import * as actions from './actionTypes';
import { scheduledPaymentsApi } from 'api';

export function getScheduledPayments(resultsPerPage = defaultPageSize, pageIndex = 0, filter = {}, sort) {
    return function(dispatch) {
        dispatch(loadScheduledPaymentsRequested());

        return scheduledPaymentsApi.getScheduledPayments(resultsPerPage, pageIndex, filter, sort).then(({scheduledPayments, resultCount}) => {
            dispatch(loadScheduledPaymentsSuccess(scheduledPayments, resultCount));
            dispatch(saveScheduledPaymentsCount(resultCount));  // Save number of scheduled payments on first call

            return {scheduledPayments, resultCount};
        }).catch(err => {
            dispatch(loadScheduledPaymentsFailed(err));
            throw err;
        });
    }
}

export function loadScheduledPaymentsRequested() {
    return { type: actions.LOAD_SCHEDULED_PAYMENTS_REQUESTED }
}
export function loadScheduledPaymentsSuccess(scheduledPayments, resultCount) {
    return {type: actions.LOAD_SCHEDULED_PAYMENTS_SUCCESS, scheduledPayments, resultCount};
}
export function loadScheduledPaymentsFailed(err) {
    return {type: actions.LOAD_SCHEDULED_PAYMENTS_FAILED, err};
}
export function saveScheduledPaymentsCount(count) {
    return {type: actions.SAVE_SCHEDULED_PAYMENTS_COUNT, count};
}

export function getScheduledPayment(scheduledPaymentId) {
    return function (dispatch) {
        dispatch(getScheduledPaymentRequested());

        return scheduledPaymentsApi.getScheduledPayment(scheduledPaymentId)
            .then(schedule => {
                dispatch(getScheduledPaymentSuccess(schedule));
                return schedule;
            })
            .catch(err => {
                dispatch(getScheduledPaymentFailed(err));
                throw(err);
            })
    }
}

export function getScheduledPaymentRequested() {
    return {type: actions.GET_SCHEDULED_PAYMENT_REQUESTED};
}
export function getScheduledPaymentSuccess(result) {
    return {type: actions.GET_SCHEDULED_PAYMENT_SUCCESS, result};
}
export function getScheduledPaymentFailed(err) {
    return {type: actions.GET_SCHEDULED_PAYMENT_FAILED, err};
}

export function cancelScheduledPayment(scheduledPaymentId) {
    return function (dispatch) {
        dispatch(cancelScheduledPaymentRequested());

        return scheduledPaymentsApi.cancelScheduledPayment(scheduledPaymentId)
            .then(scheduledPayment => {
                dispatch(cancelScheduledPaymentSuccess(scheduledPayment));
                return scheduledPayment;
            })
            .catch(err => {
                dispatch(cancelScheduledPaymentFailed(err));
                throw(err);
            })
    }
}

export function cancelScheduledPaymentRequested() {
    return {type: actions.CANCEL_SCHEDULED_PAYMENT_REQUESTED};
}
export function cancelScheduledPaymentSuccess(scheduledPayment) {
    return {type: actions.CANCEL_SCHEDULED_PAYMENT_SUCCESS, scheduledPayment};
}
export function cancelScheduledPaymentFailed(err) {
    return {type: actions.CANCEL_SCHEDULED_PAYMENT_FAILED, err};
}

export function retryScheduledPayment(scheduledPaymentId) {
    return function (dispatch) {
        dispatch(retryScheduledPaymentRequested());

        return scheduledPaymentsApi.retryScheduledPayment(scheduledPaymentId)
            .then(scheduledPayment => {
                dispatch(retryScheduledPaymentSuccess(scheduledPayment));
                return scheduledPayment;
            })
            .catch(err => {
                dispatch(retryScheduledPaymentFailed(err));
                throw(err);
            })
    }
}

export function retryScheduledPaymentRequested() {
    return {type: actions.RETRY_SCHEDULED_PAYMENT_REQUESTED};
}
export function retryScheduledPaymentSuccess(scheduledPayment) {
    return {type: actions.RETRY_SCHEDULED_PAYMENT_SUCCESS, scheduledPayment};
}
export function retryScheduledPaymentFailed(err) {
    return {type: actions.RETRY_SCHEDULED_PAYMENT_FAILED, err};
}