import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Navigate } from 'react-router-dom';
import _ from 'lodash';

import * as billpayUtil from '@premier/utils/billpay';

import { PageSection, LoadingIndicator, SingleColumnFormContainer, IconText } from '@premier/ui';
import { PageHeader } from 'components/Common';
import { CustomerDetailsForm, CustomerSummarySubtitle } from 'components/DataVault';

import { PlatformRoutesConfiguration } from 'components/Routing';
import * as customerActions from 'components/DataVault/_actions/customerActions';

const EditCustomerPage = ({
    actions, // API actions
    customer, errors, // state
    isLoading // logic render
}) => {

    const [customerFound, setCustomerFound] = useState(false);
    const [redirect, setRedirect] = useState(false);

    useEffect(() => {
        actions.getCustomer(customer.customerId)
        .then(() => {
            setCustomerFound(true);
        })
        .catch(err => {
            console.error(err);
        });
    },[]);

    function handleCustomerSave(values) {
        return new Promise((resolve, reject) => {
            actions.updateCustomer(values).then(() => {
                setRedirect(true);
                resolve();
            }).catch(errors => {
            });
        });
    }

    if (redirect)
        return <Navigate to={PlatformRoutesConfiguration.customerRoute.viewCustomer.generatePath(customer.customerId)} />;

    return (
        <PageSection>
            <PageHeader
                backButton
                title={billpayUtil.getCustomerFullName(customer)}
            />

            {isLoading && <LoadingIndicator />}
            {!isLoading && !customerFound && <IconText alert title='Failed'>Customer not found or an error occurred.</IconText>}
            {customerFound &&
                <SingleColumnFormContainer>
                    <h2>Personal Details</h2>
                    <CustomerSummarySubtitle customer={customer} />
                    <CustomerDetailsForm
                        customer={customer}
                        errors={errors}
                        onSubmit={handleCustomerSave}
                        cancelTo={PlatformRoutesConfiguration.customerRoute.viewCustomer.generatePath(customer.customerId)}
                    />
                </SingleColumnFormContainer>
            }
        </PageSection>
    );
}

function mapStateToProps(state, ownProps) {
    var customerId = ownProps.match.params.id;
    var customer = { customerId: parseInt(customerId) };

    // If (or as soon as) the redux store has the requested customer then use that
    if(_.get(state, 'dataVault.customer.details.customerId') === parseInt(customerId))
        customer = state.dataVault.customer.details

    return {
        customer: customer,
        isLoading: state.dataVault.customer.isLoading,
        errors: state.dataVault.customer.errors
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(customerActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditCustomerPage);