import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';

import labels from 'constants/labels';
import currencyUtil from '@premier/utils/currency';
import errorUtil from '@premier/utils/error';

import { Modal, Icon, LoadingIndicator, DescriptionList, Button } from '@premier/ui';
import { Form, CurrencyField, validate, SubmitButton } from '@premier/form';
import { withError, FormError } from 'components/Common';
import { CaptureResultModal } from 'components/Transactions';

import * as paymentActions from '../_actions/paymentActions';

import './CaptureModal.scss';

const CaptureModal = ({
    showModal, closeModal, //logic renders
    transaction, biller, //data
    isLoading, actions, errors, captureReceipt //state
}) => {

    const [showSuccessModal, setShowSuccessModal] = useState(false);

    const maxCaptureAmountCents = _.get(transaction, 'paymentProcessedTxnModel.totalAmount', 0) - _.get(transaction, 'paymentSource.capturedAmount', 0);
    const maxCaptureAmountFormatted = currencyUtil.formatApiValue(
        maxCaptureAmountCents,
        _.get(transaction, 'paymentProcessedTxnModel.currencyCode')
    );

    const initialFormValues = {
        amount: maxCaptureAmountFormatted,
        reason: ''
    };

    const txnCurrency = currencyUtil.getCurrency(transaction.paymentProcessedTxnModel.currencyCode);
    const currencyAlphaCode = txnCurrency.alphaCode;
    const currencySymbol = currencyUtil.getSymbol(txnCurrency.code);

    function getAmount(propertyName) {
        return currencyUtil.formatApiValue(
            _.get(transaction, 'paymentProcessedTxnModel.' + propertyName, 0),
            _.get(transaction, 'paymentProcessedTxnModel.currencyCode')
        );
    }

    function closeThisModal(formProps) {
        if (formProps) formProps.resetForm();

        closeModal();
    }

    function closeSuccessModal() {
        setShowSuccessModal(false);
    }

    function handleSubmit(values, formProps) {
        actions.capturePayment({
                txnHistoryId: transaction.paymentProcessedTxnModel.txnHistoryId,
                childMerchantNumber: transaction.paymentSource.merchantNumber,
                amount: currencyUtil.convertToApiValue(values.amount)
            })
            .then(() => {
                closeThisModal(formProps);
                setShowSuccessModal(true);
            })
            .catch(err => {
                console.error(err);
                formProps.setSubmitting(false);
            });
    }

    // ---------- Render ----------

    const modalContent =
        !transaction || !transaction.paymentProcessedTxnModel.originalAmount ? (
            <LoadingIndicator />
        ) : (
            <Form
                initialValues={initialFormValues}
                initialValidation={{
                    amount: validate()
                        .required()
                        .when(x => currencyUtil.convertToApiValue(x) > 0, 'Must be positive')
                        .when(
                            x => currencyUtil.convertToApiValue(x) <= maxCaptureAmountCents,
                            `Must not exceed ${maxCaptureAmountFormatted}`
                        )
                }}
                onSubmit={handleSubmit}
            >
                <Modal.Body>
                    <DescriptionList
                        wrapOnSm
                        spaceBetween
                        hideBlankValue
                        items={[
                            { name: 'Merchant number', value: transaction.paymentSource.merchantNumber },
                            { name: labels.billerName, value: _.get(biller, 'billName') },
                            { name: labels.billerCode, value: _.get(biller, 'billerCode') },
                            {
                                name: _.get(biller, 'acceptedCrn1.label') || labels.crn1,
                                value: transaction.paymentProcessedTxnModel.crn1
                            },
                            {
                                name: _.get(biller, 'acceptedCrn2.label') || labels.crn2,
                                value: transaction.paymentProcessedTxnModel.crn2
                            },
                            {
                                name: _.get(biller, 'acceptedCrn3.label') || labels.crn3,
                                value: transaction.paymentProcessedTxnModel.crn3
                            }
                        ]}
                        divider
                    />

                    {transaction.paymentProcessedTxnModel.cardDetails && (
                        <DescriptionList
                            wrapOnSm
                            spaceBetween
                            hideBlankValue
                            items={[
                                {
                                    name: 'Card number',
                                    value: transaction.paymentProcessedTxnModel.cardDetails.cardNumber
                                },
                                {
                                    name: `Amount (${currencyAlphaCode})`,
                                    value: getAmount('originalAmount')
                                },
                                {
                                    name: `Surcharge amount (${currencyAlphaCode})`,
                                    value: getAmount('surchargeAmount')
                                },
                                {
                                    name: `Total amount (${currencyAlphaCode})`,
                                    value: getAmount('totalAmount')
                                }
                            ]}
                            divider
                        />
                    )}

                    <CurrencyField
                        name='amount'
                        label={`Capture amount (${currencyAlphaCode})`}
                        currencySymbol={currencySymbol}
                    />

                    <p style={{ margin: '2.5rem 0 0' }}>
                        <Icon alert inline /> A captured transaction cannot be reversed. A refund can be processed
                        against the capture transaction.
                    </p>

                    <FormError errors={errors} />
                </Modal.Body>

                <Modal.Footer buttons divider>
                    <SubmitButton disabled={isLoading}>
                        Capture payment
                    </SubmitButton>
                    <Button
                        onClick={() => {
                            closeThisModal();
                        }}
                    >
                        Cancel
                    </Button>
                </Modal.Footer>
            </Form>
        );

    return (
        <>
            <Modal show={showModal} onHide={closeThisModal} className='transaction-capture-modal'>
                <Modal.Header closeButton>
                    <Modal.Title>Capture transaction</Modal.Title>
                </Modal.Header>

                {modalContent}
            </Modal>

            <CaptureResultModal
                showModal={showSuccessModal}
                closeModal={closeSuccessModal}
                receipt={captureReceipt}
                biller={biller}
            />
        </>
    );
};

CaptureModal.propTypes = {
    // Required
    showModal: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,

    // Optional
    transaction: PropTypes.object,
    biller: PropTypes.object
};

function mapStateToProps(state, ownProps) {
    return {
        captureReceipt: state.transactions.payments.captureReceipt,
        isLoading: state.transactions.payments.isLoading
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(paymentActions, dispatch)
    };
}

function mapStoreToErrors(state) {
    return state.transactions.payments.errors;
}

function mapErrorToString(error) {
    var paramLabels = {
        Amount: 'Amount'
    };

    return errorUtil.getMessage(error, paramLabels);
}

export default withError(
    connect(mapStateToProps, mapDispatchToProps)(CaptureModal),
    mapStoreToErrors,
    mapErrorToString
);
