import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';

import labels from 'constants/labels';
import currencyUtil from '@premier/utils/currency';
import errorUtil from '@premier/utils/error';

import { validate, Form, CurrencyField, TextareaField, SubmitButton } from '@premier/form';
import { Modal, Icon, LoadingIndicator, DescriptionList, Button } from '@premier/ui';
import { withError, FormError } from 'components/Common';
import { RefundResultModal } from 'components/Transactions';

import * as paymentActions from 'components/Transactions/_actions/paymentActions';

import './RefundModal.scss';

const RefundModal = ({
    showModal, closeModal, //logic render
    transaction, biller, //data
    refundReceipt, isLoading,  //state
    actions, errors
}) => {

    const [showSuccessModal, setShowSuccessModal] = useState(false);


    const maxRefundAmountCents =
        _.get(transaction, 'paymentProcessedTxnModel.totalAmount', 0) -
        _.get(transaction, 'paymentSource.refundedAmount', 0);

    const maxRefundAmountFormatted = currencyUtil.formatApiValue( maxRefundAmountCents,
        _.get(transaction, 'paymentProcessedTxnModel.currencyCode')
    );

    const txnCurrency = currencyUtil.getCurrency(transaction.paymentProcessedTxnModel.currencyCode);
    const currencyAlphaCode = txnCurrency.alphaCode;
    const currencySymbol = currencyUtil.getSymbol(txnCurrency.code);

    function getAmount(propertyName) {
        return currencyUtil.formatApiValue(
            _.get(transaction, 'paymentProcessedTxnModel.' + propertyName, 0),
            _.get(transaction, 'paymentProcessedTxnModel.currencyCode')
        );
    }


    function handleModalClose(formContext) {
        if (formContext)
            formContext.resetForm();

        closeModal(true);
    }

    function closeSuccessModal() {
        closeModal(true);
        setShowSuccessModal(false);
    }


    function handleSubmit(values, formContext) {
        var submitObject = {
            txnHistoryId: transaction.paymentProcessedTxnModel.txnHistoryId,
            childMerchantNumber: transaction.paymentSource.merchantNumber,
            amount: currencyUtil.convertToApiValue(values.amount),
            refundReason: values.reason
        }

        actions.refundPayment(submitObject).then(() => {
            handleModalClose(formContext);
            setShowSuccessModal(true);
        })
    }

    // ---------- Render ----------

    const modalContent = !transaction || !transaction.paymentProcessedTxnModel.originalAmount
        ? <LoadingIndicator />
        : <Form
            initialValues={{
                amount: maxRefundAmountFormatted,
                reason: ''
            }}
            validationSchema={{
                amount: validate()
                    .required()
                    .when(x => currencyUtil.convertToApiValue(x) > 0,
                        'Must be positive'
                    )
                    .when(x => currencyUtil.convertToApiValue(x) <= maxRefundAmountCents,
                        `Must not exceed ${maxRefundAmountFormatted}`
                    )
            }}
            onSubmit={handleSubmit}
        >
            <Modal.Body>
                <DescriptionList
                    wrapOnSm
                    spaceBetween
                    hideBlankValue
                    divider
                    items={[
                        {
                            name: 'Merchant number',
                            value: transaction.paymentSource.merchantNumber
                        },
                        {
                            name: labels.billerName,
                            value: _.get(biller, 'billName')
                        },
                        {
                            name: labels.billerCode,
                            value: _.get(biller, 'billerCode')
                        },
                        {
                            name: _.get(biller, 'acceptedCrn1.label') || labels.crn1,
                            value: transaction.paymentProcessedTxnModel.crn1
                        },
                        {
                            name: _.get(biller, 'acceptedCrn2.label') || labels.crn2,
                            value: transaction.paymentProcessedTxnModel.crn2
                        },
                        {
                            name: _.get(biller, 'acceptedCrn3.label') || labels.crn3,
                            value: transaction.paymentProcessedTxnModel.crn3
                        }
                    ]}
                />

                {transaction.paymentProcessedTxnModel.cardDetails && (
                    <DescriptionList
                        wrapOnSm
                        spaceBetween
                        hideBlankValue
                        divider
                        items={[
                            {
                                name: 'Card number',
                                value: transaction.paymentProcessedTxnModel.cardDetails.cardNumber
                            },
                            {
                                name: `Amount (${currencyAlphaCode})`,
                                value: getAmount('originalAmount')
                            },
                            {
                                name: `Surcharge amount (${currencyAlphaCode})`,
                                value: getAmount('surchargeAmount')
                            },
                            {
                                name: `Total amount (${currencyAlphaCode})`,
                                value: getAmount('totalAmount')
                            }
                        ]}
                    />
                )}

                <CurrencyField
                    name='amount'
                    label={`Refund amount (${currencyAlphaCode})`}
                    currencySymbol={currencySymbol}
                />

                <TextareaField name='reason' label='Refund reason' />

                <p style={{ margin: '2.5rem 0 0' }}>
                    <Icon alert inline /> Once a refund has been processed, it cannot be reversed
                </p>

                <FormError errors={errors} />
            </Modal.Body>

            <Modal.Footer buttons divider>
                <SubmitButton disabled={isLoading}>Refund payment</SubmitButton>
                <Button onClick={() => { handleModalClose(); }} >
                    Cancel
                </Button>
            </Modal.Footer>
        </Form>

    return (
        <>
            <Modal show={showModal} onHide={handleModalClose} className='transaction-refund-modal'>
                <Modal.Header closeButton>
                    <Modal.Title>Process refund</Modal.Title>
                </Modal.Header>

                {modalContent}
            </Modal>

            <RefundResultModal
                showModal={showSuccessModal}
                closeModal={closeSuccessModal}
                receipt={refundReceipt}
                biller={biller}
            />
        </>
    );
};

RefundModal.propTypes = {
    // Required
    showModal: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,

    // Optional
    transaction: PropTypes.object,
    biller: PropTypes.object
};

function mapStateToProps(state, ownProps) {
    return {
        refundReceipt: state.transactions.payments.refundReceipt,
        isLoading: state.transactions.payments.isLoading
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(paymentActions, dispatch)
    };
}

function mapStoreToErrors(state) {
    return state.transactions.payments.errors;
}

function mapErrorToString(error) {
    var paramLabels = {
        Amount: 'Amount'
    };

    return errorUtil.getMessage(error, paramLabels);
}

export default withError(
    connect(mapStateToProps, mapDispatchToProps)(RefundModal),
    mapStoreToErrors,
    mapErrorToString
);
