import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import {
    Button,
    ButtonContainer,
    PaddedContainer,
    PageSection,
} from '@premier/ui';

import { PageHeader } from 'components/Common';
import { Item } from '@premier/ui/src/ReorderableList';
import { useInvoiceSettings } from 'components/Settings/_hooks/useInvoicesSettings';
import { TermOfPayment } from 'packages/webapi-client/api';

import NoBillerCodeErrorPage from 'components/Common/pages/NoBillerCodeErrorPage';
import InvoiceSettingsDetailForm from 'components/Settings/components/InvoiceSettings/InvoiceSettingsDetailForm';
import InvoiceSettingsHeading from 'components/Settings/components/InvoiceSettings/InvoiceSettingsHeading';
import InvoiceDialog from 'components/Settings/components/InvoiceSettings/InvoiceDialog';
import { InvoiceSettingsState } from '../InvoiceSettingsPage';
import { useNavigate } from 'react-router-dom';
import 'components/Settings/components/InvoiceSettings/InvoiceSettings.scss';

export const defaultValues = {
    newTerm: undefined,
    errorMessage: '',
    errorState: false,
    maxChar: 9,
    modalState: '' as InvoiceSettingsState,
};

export const InvoiceSettingsDetailsPageCopy = {
    title: 'Settings for invoice detail',
    invoiceDetails: {
        title: 'Term of payment options',
        tooltipId: 'invoice_details',
        tooltip:
            'The terms of payment calculates when the invoice is due. For instance, if it is 7 days, the due date will be calculated as invoice date + 7 days.',
    },
    termOfPayment: {
        inputTitle: 'New term of payment',
        inputPlaceholder: 'Enter no. of days...',
        addButton: 'Add term',
        listTitle: 'Existing term of payment options',
        listDescription:
            'to remove or reorder option, please select an option to edit.',
    },
    error: {
        emptyInput: 'Term of payment is required.',
        characterLimit: 'New term of payment exceeds 9 characters.',
        duplicate: 'This new term of payment has already been used.',
        emptyList: 'At least one option is required.',
        submit: 'There are errors above. Please fix them before continuing.',
    },
    SaveButton: 'Save',
    CancelButton: 'Cancel',
};

interface Props {}

const InvoiceSettingsDetailsPage: React.FC<Props> = () => {
    // invoice hook ---
    const { hasLoaded, settings, updateInvoiceSettings } = useInvoiceSettings();

    const convertToItem = (setting?: TermOfPayment[]) => {
        let itemsList: Item[] = [];

        if (setting) {
            setting.forEach((e) => {
                itemsList.push({
                    //@ts-ignore
                    key: e.daysToPay,
                    label: e.description || '',
                });
            });
        }
        return itemsList;
    };

    const convertToModel = (items: Item[]) => {
        let settingList: TermOfPayment[] = [];

        if (items) {
            items.forEach((e) => {
                settingList.push({
                    //@ts-ignore
                    daysToPay: e.key,
                    description: e.label,
                });
            });
        }
        return settingList;
    };

    // states ---
    const [newTerm, setNewTerm] = useState<number | undefined>();
    const [items, setItems] = useState<Item[]>(
        convertToItem(settings?.termOfPayments)
    );

    const [inputError, setInputError] = useState<string>(
        defaultValues.errorMessage
    );
    const [listError, setListError] = useState<string>(
        defaultValues.errorMessage
    );
    const [modalState, setModalState] = useState<InvoiceSettingsState>(
        defaultValues.modalState
    );

    useEffect(() => {
        setItems(convertToItem(settings?.termOfPayments));
    }, [settings]);

    // input ---
    const addNewTerm = () => {
        if (validateNewTerm()) {
            let itemList = items;
            itemList.push({
                label: newTerm + ' days',
                key: newTerm!,
            });

            setItems(itemList);
            resetInput();
        }
    };

    const validateNewTerm = () => {
        // empty input
        if (!newTerm && newTerm !== 0) {
            setInputError(InvoiceSettingsDetailsPageCopy.error.emptyInput);
            return false;
        }
        // duplicated value
        if (items.find((e) => e.key === newTerm)) {
            setInputError(InvoiceSettingsDetailsPageCopy.error.duplicate);
            return false;
        }
        // character limit
        if (newTerm.toString().length > defaultValues.maxChar) {
            setInputError(InvoiceSettingsDetailsPageCopy.error.characterLimit);
            return false;
        }
        return true;
    };

    const resetInput = () => {
        setNewTerm(defaultValues.newTerm);
        setListError(defaultValues.errorMessage);
        setInputError(defaultValues.errorMessage);
    };

    // reorderable list ---
    const onChange = (items: Item[]) => {
        setItems(items);
        setListError(defaultValues.errorMessage);
    };

    // modal ---
    const navigate = useNavigate();

    const handleExit = () => {
        navigate(-1);
    };

    const handleSave = () => {
        if (items.length <= 0) {
            setListError(InvoiceSettingsDetailsPageCopy.error.emptyList);
            return;
        }
        // submit
        const newSettings = {
            ...settings,
            termOfPayments: convertToModel(items),
        };

        updateInvoiceSettings(newSettings);
        setModalState('SUCCESS');
    };

    const handleBack = () => {
        // no changes made
        if (_.isEqual(items, convertToItem(settings?.termOfPayments))) {
            handleExit();
            return;
        }
        // unsaved changes
        setModalState('UNSAVED');
    };

    // no billers linked ---
    if (!settings?.hasInvoiceBiller && hasLoaded) {
        return <NoBillerCodeErrorPage />;
    }

    return (
        <PageSection>
            <PageHeader
                title={InvoiceSettingsDetailsPageCopy.title}
                backButton={{...{ onClick: handleBack }}}
            />
            <PaddedContainer noDivider lessMargin>
                <InvoiceSettingsHeading
                    title={InvoiceSettingsDetailsPageCopy.invoiceDetails.title}
                    tooltip={
                        InvoiceSettingsDetailsPageCopy.invoiceDetails.tooltip
                    }
                    tooltipId={
                        InvoiceSettingsDetailsPageCopy.invoiceDetails.tooltipId
                    }
                />

                <InvoiceSettingsDetailForm
                    value={newTerm}
                    items={items}
                    onInputChange={setNewTerm}
                    onListChange={onChange}
                    onAdd={addNewTerm}
                    inputError={inputError}
                    listError={listError}
                />
            </PaddedContainer>

            <InvoiceDialog
                modalState={modalState}
                pageName={'invoice details'}
                onClose={() => setModalState(defaultValues.modalState)}
                onExit={handleExit}
            />

            <ButtonContainer>
                <Button primary onClick={handleSave}>
                    {InvoiceSettingsDetailsPageCopy.SaveButton}
                </Button>
                <Button onClick={handleBack}>
                    {InvoiceSettingsDetailsPageCopy.CancelButton}
                </Button>
            </ButtonContainer>

            {!!listError && (
                <p className='invoices-error-message'>
                    {InvoiceSettingsDetailsPageCopy.error.submit}
                </p>
            )}
        </PageSection>
    );
};

export default InvoiceSettingsDetailsPage;
