import _ from "lodash";
import { useEffect, useState } from "react";
import { Dispatch, bindActionCreators } from "redux";
import { connect } from "react-redux";
import { RootState } from "store/store";
import { Button, ButtonContainer, Divider } from "packages/ui";
import { MerchantBillerDetailsForm, PaymentRequestPendingModal } from "components/Transactions";
import { DatePickerField, EmailAddressField, Form, FormErrorList, PhoneNumberField, SubmitButton, CurrencyField, InputField, validate, FormDialog } from "@premier/form";
// @ts-ignore
import { PaymentRequestDeliveryMethodEnum, ExpirePaymentLinkEnum } from "constants/billpay";
// @ts-ignore
import labels from "constants/labels";
// @ts-ignore
import errorMaps from "constants/errorMaps";
import { actionTypeValueToEnumName } from "components/PaymentRequests/_actions/paymentRequestActionTypes";
import * as paymentRequestActions from "components/PaymentRequests/_actions/paymentRequestActions";
import countryUtil from "packages/utils/country";
import { ChildMerchantModel, MerchantModel } from "packages/webapi-client";
import { FieldError } from "api/mapErrors";
import { Merchant, Phone } from "models";
import { PaymentRequestAction } from "platforms/base/constants/billpay";
import moment from "moment";
import dateUtil from "packages/utils/date";
import { Customer } from "packages/utils/models";
import { Token } from "models/Token";
import { Biller } from "packages/webapi-client";

import "./NewPaymentRequestForm.scss";
import { PhoneNumberOnChangeEvent } from "packages/form/fields/PhoneNumberField";
import { isAmountRequired } from "util/paymentRequestValidation";
import { userRoles } from "components/Routing";

type Props = {
    dialog?: boolean;
    showDialog?: boolean;
    tokenPage?: boolean;
    customer?: Customer;
    selectedToken?: Token;
    isLoading: boolean;
    merchant: MerchantModel;
    billers: Biller[];
    paymentRequestId: string;
    requestConfig: RequestConfig;
    errors: Error[];
    paymentRequestActions: any;
    onDialogCloseClicked?: (reload: boolean) => void;
    onSuccessModalClose?: (showNewPaymentRequestModal?: boolean) => void;
}

type RequestConfig = {
    deliveryMethod: number;
    expirePaymentLink: number;
    dueDateMandatory: boolean;
    expiryDateMandatory: boolean;
    barSMSLinks: boolean;
}

type PaymentDetails = {
    billerCodeForm: BillerCodeForm;
    currencyCode?: number;
    customerId: number;
    merchantReference: string;
    amount: number;
    dueDate: Date[];
    expiryDate: Date[];
    customer: CustomerDetails;
    email: string;
    mobile: Phone;
}

type FormValues = {
    billerCodeForm: BillerCodeForm;
    paymentRequest: PaymentRequest;
    customerId: number;
    merchantReference: string;
    amount: number;
    dueDate: Date[];
    expiryDate: Date[];
    customerSalutation: string;
    customerFirstName: string;
    customerLastName: string;
    email: string;
    mobile: Phone;
}

type PaymentRequest = {
    action: number;
}

type BillerCodeForm = {
    billerCode: string;
    merchantNumber?: string | undefined;
    childMerchantNumber: string | undefined;
    billerCrnList: BillerCrn[];
    action: string | null;
}

type CustomerDetails = {
    title: string;
    firstName: string;
    lastName: string;
}

type BillerCrn = {

}

type MerchantDetail = {
    merchantNumber: string | undefined;
    merchantName:   string | undefined;
    isParent:       boolean;
}

const NewPaymentRequestForm = ({
    dialog = false, customer, selectedToken, showDialog = false, tokenPage = false, isLoading, merchant, billers,
    paymentRequestId, errors, paymentRequestActions, requestConfig, onSuccessModalClose, onDialogCloseClicked
}: Props) => {
    const [selectedMerchant, setSelectedMerchant] = useState<Merchant | undefined>();
    const [selectedBiller, setSelectedBiller] = useState<Biller | undefined>();
    const [billerCode, setBillerCode] = useState<string>();
    const [email, setEmail] = useState<string>();
    const [mobile, setMobile] = useState<Phone>();
    const [showRequestPendingModal, setShowRequestPendingModal] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [formContext, setFormContext] = useState<any>();

    const parentMerchant: MerchantDetail = {
        merchantNumber: merchant.merchantNumber,
        merchantName: merchant.merchantName,
        isParent: true
    };

    useEffect(() => {
        if (selectedMerchant && selectedMerchant.merchantNumber && billerCode) {
            paymentRequestActions.getRequestConfig(selectedMerchant.merchantNumber, billerCode);
        } else {
            paymentRequestActions.clearRequestConfig();
        }
    }, [selectedMerchant, billerCode]);

    useEffect(() => {
        const childMercs =
            merchant.childMerchants &&
            merchant.childMerchants.map((cm: ChildMerchantModel) => ({
                merchantNumber: cm.merchantNumber,
                merchantName: cm.merchantName,
                isParent: false,
            }));
        const merchants = [parentMerchant].concat(childMercs || []);

        if (tokenPage) {
            if (selectedToken) {
                setSelectedMerchant(merchants.find(m => m.merchantNumber === selectedToken.childMerchantNumber));
            }
        } else {
            if (customer?.childMerchantNumber) {
                setSelectedMerchant(merchants.find(m => m.merchantNumber === customer.childMerchantNumber));
            } else {
                setSelectedMerchant(merchants[0]);
            }
        }

        if (billers.length > 0) {
            setSelectedBiller(billers[0]);
        }
    }, [selectedToken, customer, tokenPage, billers]);

    // Here we need to reset email & mobile validation everytime the requestConfig or email/mobile changes.
    useEffect(() => {
        resetValidation(requestConfig, formContext, email, mobile);
    }, [requestConfig, formContext, email, mobile]);

    function resetValidation(requestConfig: RequestConfig, formContext: any, email: string | undefined, mobile: Phone | undefined) {
        if (requestConfig && formContext) {
            // First clear errors and existing validation rules
            formContext.clearErrors();
            formContext.removeValidation("dueDate");
            formContext.removeValidation("expiryDate");
            formContext.removeValidation("mobile");
            formContext.removeValidation("email");

            // Now set validation rules depending on config
            if (requestConfig.dueDateMandatory) {
                formContext.setValidation("dueDate", validate().required());
            }

            if (requestConfig.expiryDateMandatory) {
                formContext.setValidation("expiryDate", validate().required());
            }

            if (requestConfig.deliveryMethod === PaymentRequestDeliveryMethodEnum.SMS_ONLY) {
                formContext.setValidation("mobile", validate().requiredPhone());
            }

            if (requestConfig.deliveryMethod === PaymentRequestDeliveryMethodEnum.EMAIL_ONLY) {
                formContext.setValidation("email", validate().required().email());
            }

            if (requestConfig.deliveryMethod === PaymentRequestDeliveryMethodEnum.EMAIL_AND_SMS) {
                formContext.setValidation("mobile", validate().requiredPhoneIf(() => !email?.trim()));
                formContext.setValidation("email", validate().requiredIf(() => !mobile?.phoneNumber?.trim() || requestConfig.barSMSLinks).email());
            }
        }
    }

    const handleMerchantChanged = (merchantDetails: Merchant | undefined) => {
        setBillerCode(undefined);
        setSelectedMerchant(merchantDetails);
    };

    const handleBillerChange = (context: any, biller: Biller) => {
        if (biller) {
            setBillerCode(biller.billerCode);
        }

        // Reset action to PaymentOnly
        context.setValue("billerCodeForm.action", tokenPage ? PaymentRequestAction.UpdateToken.toString() : PaymentRequestAction.PaymentOnly.toString());
    };

    const handleOnFormValidate = () => {
        // This is done to trigger validation of anything that does not use Form.js in-built validation (in this case, Token Number field)
        setSubmitting(true);

        // Need to put this in a timeout because React won't re-render changes due to batching changes
        setTimeout(() => {
            setSubmitting(false);
        }, 100);
    };

    const handleSubmit = async (values: FormValues, formContext: any) => {
        setFormContext(formContext);

        const paymentDetails: PaymentDetails = {
            ...values.paymentRequest,
            customerId: values.customerId,
            merchantReference: values.merchantReference,
            amount: values.amount,
            dueDate: values.dueDate,
            expiryDate: values.expiryDate,
            currencyCode: merchant.currency?.code,
            email: values.email,
            mobile: values.mobile,
            customer: {
                title: values.customerSalutation,
                firstName: values.customerFirstName,
                lastName: values.customerLastName,
            },
            billerCodeForm: {
                billerCode: values.billerCodeForm.billerCode,
                childMerchantNumber: values.billerCodeForm.merchantNumber,
                billerCrnList: values.billerCodeForm.billerCrnList,
                action: values.billerCodeForm.action ? actionTypeValueToEnumName(values.billerCodeForm.action) : null,
            }
        };

        await createPaymentRequest(paymentDetails);

        setSelectedBiller(_.find(billers, { billerCode: values.billerCodeForm.billerCode }));
    };

    function handleClose() {
        onDialogCloseClicked && onDialogCloseClicked(false);
    }

    // Must wait until the paymentRequestId is fully set before attempting to open the modal
    // or else a race condition would occur within the modal where you have the payment request
    // with the payment receipt vs the new one generated by paymentRequestActions.create
    const createPaymentRequest = async (paymentDetails: PaymentDetails) => {
        await paymentRequestActions.create(paymentDetails);

        if (onDialogCloseClicked) {
            onDialogCloseClicked(false);
        }

        setShowRequestPendingModal(true);
    };

    function mapErrors(errors: FieldError[]) {
        return errors?.map(e => ({
            ...e,
            field: e.field ? mapErrorsFromDto(e.field) : ""
        }));
    }

    function mapErrorsFromDto(parameter: string | undefined) {
        switch (parameter) {
            case "reference1":
                return "billerCodeForm.billerCrnList.crn1";
            case "reference2":
                return "billerCodeForm.billerCrnList.crn2";
            case "reference3":
                return "billerCodeForm.billerCrnList.crn3";
            case "customer.title":
                return "customerSalutation";
            case "customer.firstName":
                return "customerFirstName";
            case "customer.lastName":
                return "customerLastName";
            default:
                return parameter;
        }
    }

    const handleModalDisplay = () => {
        if (showRequestPendingModal && paymentRequestId) {
            return <PaymentRequestPendingModal
                paymentRequestId={paymentRequestId}
                biller={selectedBiller}
                onClosed={handlePaymentRequestModalClosed}
                customerEmail={email}
                customerPhoneNumber={mobile}
            />;
        }

        return null;
    };

    function handlePaymentRequestModalClosed(reset?: boolean, dismiss?: boolean, showNewPaymentRequestModal?: boolean) {
        setShowRequestPendingModal(false);
        onSuccessModalClose && onSuccessModalClose(showNewPaymentRequestModal);

        if (reset) {
            processFormReset();
        }
    }

    function processFormReset() {
        formContext.resetForm();
        formContext.setValue("billerCodeForm.merchantNumber", customer?.childMerchantNumber ?? parentMerchant.merchantNumber);
        formContext.setValue("billerCodeForm.billerCode", selectedBiller?.billerCode);
        formContext.setValue("card.cardNumber", null);
        formContext.setValue("card.expiryDate.month", null);
        formContext.setValue("card.expiryDate.year", null);
        formContext.setValue("card.accountNumber", null);
        resetValidation(requestConfig, formContext, undefined, undefined);
    }

    function doesSelectedActionSupportTokenLookups(action: number | string) {
        const supportedActions = [
            PaymentRequestAction.UpdateToken,
            PaymentRequestAction.PaymentAndUpdateToken,
            PaymentRequestAction.PreAuthAndUpdateToken
        ];

        return supportedActions.includes(Number(action));
    }

    function renderForm(context: any) {
        // We need to do this so that this component always has the most up to date formContext
        if (!formContext || !_.isEqual(context.values, formContext.values)) {
            setFormContext(context);
        }

        const actionNumber = Number(context.getValue("billerCodeForm.action"));
        let updateTokenAction: boolean;
        let tokeniseOnlyAction: boolean;
        let paymentAction: boolean;

        if (actionNumber === PaymentRequestAction.TokeniseOnly) {
            updateTokenAction = false;
            tokeniseOnlyAction = true;
            paymentAction = false;
        } else if (actionNumber === PaymentRequestAction.UpdateToken) {
            updateTokenAction = true;
            tokeniseOnlyAction = false;
            paymentAction = false;
        } else if (actionNumber === PaymentRequestAction.PaymentAndUpdateToken || actionNumber === PaymentRequestAction.PreAuthAndUpdateToken) {
            updateTokenAction = true;
            tokeniseOnlyAction = false;
            paymentAction = true;
        } else {
            updateTokenAction = false;
            tokeniseOnlyAction = false;
            paymentAction = true;
        }

        const isUpdateTokenOrTokeniseOnlyPaymentRequest = updateTokenAction || tokeniseOnlyAction;

        function calculateExpiryDate() {
            if (requestConfig?.expirePaymentLink === ExpirePaymentLinkEnum.AFTER_DUE_DATE) {
                const dueDate: Date[] = context.getValue("dueDate");

                if (dueDate && dueDate.length > 0) {
                    const expiryDate = moment(dueDate[0]).add(1, "days");

                    return dateUtil.formatToDateString(expiryDate.toDate());
                }
            }

            return "";
        }

        function handlePaymentRequestActionChange(action: number) {
            if (isAmountRequired(action)) {
                context.setValidation("amount", validate().required());
            } else {
                context.removeValidation("amount");
            }
        }

        return <>
            <h2>Enter payment request details</h2>

            <MerchantBillerDetailsForm
                onMerchantChange={handleMerchantChanged}
                onBillerChange={(biller: Biller) => handleBillerChange(context, biller)}
                showPaymentMethodButton={doesSelectedActionSupportTokenLookups(context.getValue("billerCodeForm.action"))}
                showTokenNumberLookup={doesSelectedActionSupportTokenLookups(context.getValue("billerCodeForm.action"))}
                initialValuesTokens={selectedToken ?? (customer?.tokens ? customer?.tokens[0] : undefined)}
                customerUniqueId={customer?.uniqueId}
                preSelectedToken={selectedToken}
                hideMerchantField={tokenPage || !!customer?.childMerchantNumber}
                tokenRequired={doesSelectedActionSupportTokenLookups(context.getValue("billerCodeForm.action"))}
                tokenLocked={tokenPage}
                tokenTriggerValidation={submitting}
                tokenPage={tokenPage}
                onPaymentRequestActionChange={handlePaymentRequestActionChange}
                showPaymentRequestAction
                feature={userRoles.paymentRequest}
            />

            {/* Amount is not relevant if it's an "update token" or "tokenise only" payment request */}
            {(!isUpdateTokenOrTokeniseOnlyPaymentRequest || paymentAction) && <CurrencyField maxLength={11} name="amount" label={labels.amount} className="no-number-spinner" mandatory />}

            <DatePickerField name="dueDate" label={labels.dueDate} mode="single" mandatory={requestConfig && requestConfig.dueDateMandatory} />

            {requestConfig?.expirePaymentLink === ExpirePaymentLinkEnum.CUSTOM_EXPIRY_DATE &&
                <DatePickerField name="expiryDate" label={labels.expiryDate} mode="single" mandatory={requestConfig && requestConfig.expiryDateMandatory} />}

            {requestConfig?.expirePaymentLink === ExpirePaymentLinkEnum.AFTER_DUE_DATE &&
                <InputField name="expiryDate" label={labels.expiryDate} value={calculateExpiryDate()} readonly />}

            <Divider />

            <h2>Customer details</h2>

            <InputField name="customerSalutation" label={labels.salutation} />
            <InputField name="customerFirstName" label={labels.firstName} />
            <InputField name="customerLastName" label={labels.lastName} />

            {requestConfig?.deliveryMethod !== PaymentRequestDeliveryMethodEnum.SMS_ONLY &&
                <EmailAddressField name="email" label={labels.email} onChange={(email) => setEmail(email)} />}

            {requestConfig?.deliveryMethod !== PaymentRequestDeliveryMethodEnum.EMAIL_ONLY &&
                <PhoneNumberField name="mobile" label={labels.mobile} onChange={(mobile: PhoneNumberOnChangeEvent) => setMobile(mobile)} />}

            <Divider />

            <FormErrorList />

            <ButtonContainer>
                <SubmitButton loading={isLoading}>Send request</SubmitButton>
                <Button onClick={handleClose}>Cancel</Button>
            </ButtonContainer>
        </>;
    }

    // Grab whichever phone number is first
    const customerFirstPhoneNumber = customer?.phoneNumbers && customer?.phoneNumbers.length >= 1 ? customer?.phoneNumbers[0] : null;

    if (email === undefined && customer?.emailAddress) {
        setEmail(customer.emailAddress);
    }

    if (mobile === undefined && customerFirstPhoneNumber) {
        setMobile(customerFirstPhoneNumber.phoneNumber);
    }

    const formProps = {
        initialValues: {
            billerCodeForm: {
                merchantNumber: customer?.childMerchantNumber ?? selectedMerchant?.merchantNumber,
                action: tokenPage ? PaymentRequestAction.UpdateToken.toString() : PaymentRequestAction.PaymentOnly.toString(),
                billerCode: "",
                billerCrnList: {
                    crn1: "",
                    crn2: "",
                    crn3: ""
                },
            },
            amount: "",
            card: {
                cardNumber: "",
                cardType: "",
                cvn: "",
                cardholderName: "",
                accountNumber: "",
            },
            paymentRequest: { },
            customerId: customer?.customerId,
            customerSalutation: customer?.title,
            customerFirstName: customer?.firstName,
            customerLastName: customer?.lastName,
            email: customer?.emailAddress,
            mobile: customerFirstPhoneNumber?.phoneNumber ?? { iddCode: countryUtil.getIddCode(merchant.countryCode) }
        },
        initialValidation: {
            billerCodeForm: {
                action: validate().required()
            }
        },
        onSubmit: handleSubmit,
        errors: mapErrors(errors),
        errorMaps: errorMaps,
        render: renderForm
    };

    return (
        <>
            { dialog ?
                <FormDialog show={showDialog} title="Payment request" closeButton onValidate={handleOnFormValidate} onClose={handleClose} {...formProps} renderForm={renderForm} />
                : <Form onValidate={handleOnFormValidate} {...formProps} /> }
            { handleModalDisplay() }
        </>
    );
};

function mapStateToProps(state: RootState) {
    return {
        isLoading: state.transactions.payments.isLoading,
        merchant: state.accounts.users.merchant,
        requestConfig: state.paymentRequest?.requestConfig.data,
        billers: state.accounts.users.activeBillers,
        paymentRequestId: state.paymentRequest?.create.data?.guid,
        errors: state.transactions.payments.errors,
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        paymentRequestActions: bindActionCreators(paymentRequestActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(NewPaymentRequestForm);
