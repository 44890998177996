import React, { useState } from 'react';
import { LoadingIndicator } from '@premier/ui';

// Note: img also accepts base64 strings like data:image/png;base64,<base64_data>
const ImageFromUrl = ({ url, altText = "" }) => {
    const [imageLoaded, setImageLoaded] = useState(false);
    return <>
            {!imageLoaded ? <LoadingIndicator /> : null}
            <img
                src={url} // See QrCodeForPaymentUrlModal for example
                onLoad={() => setImageLoaded(true)}
                alt={altText}
                style={{display: !imageLoaded ? "none" : "block"}}
            />
        </>
}

export default ImageFromUrl;