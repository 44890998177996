
import { userRoles } from 'components/Routing';
import { RouteConfigurationMap } from 'components/Routing';
import { DeclineManagerGeneralSettingsPage, DeclineManagerSettingsPage, EditTrayPage, ManageBankAccountActions, ManageCardActions, ManageTraysPage } from "components/Settings";

export const batchDeclineSettingsRoute: RouteConfigurationMap = {
    /** Decline Manager **/
    root: {
        path: '/settings/batch-decline-settings/',
        generatePath: () => '/settings/batch-decline-settings/',
    },
    settings: {
        path: '/settings/batch-decline-settings/settings',
        element: <DeclineManagerSettingsPage />,
        generatePath: () => '/settings/batch-decline-settings/settings',
        roles: [userRoles.declineManagerAdmin],
        title: 'Batch Decline Settings',
    },
    generalSettings: {
        path: '/settings/batch-decline-settings/settings/general',
        element: <DeclineManagerGeneralSettingsPage />,
        generatePath: () => '/settings/batch-decline-settings/settings/general',
        roles: [userRoles.declineManagerAdmin],
        title: 'Batch Decline General Settings',
    },
    manageTrays: {
        path: '/settings/batch-decline-settings/settings/trays',
        element: <ManageTraysPage />,
        generatePath: () => '/settings/batch-decline-settings/settings/trays',
        roles: [userRoles.declineManagerAdmin],
        title: 'Manage Trays',
    },
    manageEditTrays: {
        path: '/settings/batch-decline-settings/settings/trays/edit',
        element: <EditTrayPage />,
        generatePath: () => '/settings/batch-decline-settings/settings/trays/edit',
        roles: [userRoles.declineManagerAdmin],
        title: 'Edit Tray',
    },
    cardActions: {
        path: "/settings/batch-decline-settings/settings/card-actions",
        element: <ManageCardActions />,
        generatePath: () => "/settings/batch-decline-settings/settings/card-actions",
        roles: [userRoles.declineManagerAdmin],
        title: "Card actions",
    },
    bankAccountActions: {
        path: "/settings/batch-decline-settings/settings/bank-account-actions",
        element: <ManageBankAccountActions />,
        generatePath: () => "/settings/batch-decline-settings/settings/bank-account-actions",
        roles: [userRoles.declineManagerAdmin],
        title: "Bank account actions",
    },
}
