import { connect } from "react-redux";
import { RootState } from "store/store";
import * as billpayUtil from "@premier/utils/billpay";

// @ts-ignore
import labels from "constants/labels";

// @ts-ignore
import { PaymentRequestStatus } from "constants/billpay";

import { DescriptionList, Button, Modal, Icon } from "@premier/ui";

import { Request } from "models/Request";
import { Biller } from "models/Biller";
import { Token } from "models/Token";
import { NameValue } from "models";

type Props = {
    request?: Request;
    token?: Token;
    billers?: Biller[];
    onClose: (reset: boolean, dismiss: boolean) => void;
}

const NewPaymentRequestResultModal = ({ request, token, billers, onClose }: Props) => {
    const success = request?.status.key === PaymentRequestStatus.REGISTERED;

    const getBillerDetailsItems = () => {
        const detailsItems: NameValue<string>[] = [];
        const biller = billers?.find(b => b.billerCode === request?.billerCode);

        if (request?.billerCode) {
            detailsItems.push(
                { name: labels.billerName, value: biller?.billName },
                { name: labels.billerCode, value: request?.billerCode }
            );
        }
        if (request?.crn1) {
            detailsItems.push({ name: biller?.acceptedCrn1?.crnName || labels.crn1, value: request?.crn1 });
        }
        if (request?.crn2) {
            detailsItems.push({ name: biller?.acceptedCrn2?.crnName || labels.crn2, value: request?.crn2 });
        }
        if (request?.crn3) {
            detailsItems.push({ name: biller?.acceptedCrn3?.crnName || labels.crn3, value: request?.crn3 });
        }

        return detailsItems;
    };

    const handleClose = (reset: boolean) => {
        if (onClose)
            onClose(reset, true);
    };

    const getTitle = (actionName: string | undefined) => {
        switch (actionName) {
            case "Tokenise only":
                return "Tokenisation";
        }

        return actionName;
    };

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>
                    {request && <>
                        { success ? <Icon tick /> : <Icon alert /> }
                        { success ? `${getTitle(request.actionDetails.description)} success`: "An error has occurred" }
                    </>}
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                { success && <>
                    <DescriptionList spaceBetween hideBlankValue items={getBillerDetailsItems()} divider />

                    {token?.type.toLowerCase() === "card" && <DescriptionList spaceBetween hideBlankValue items={[
                        { name: "Cardholder name", value: token?.cardholderName },
                        { name: "Card number", value: token?.maskedCardNumber },
                        { name: "Expiry", value: billpayUtil.formatExpiry(token?.expiryDate!) },
                    ]} divider />}

                    {token?.cardTypeCode.toLowerCase() === "ba" && <DescriptionList spaceBetween hideBlankValue items={[
                        { name: "Account name", value: token?.accountName },
                        { name: "Account number", value: token?.accountNumber },
                    ]} divider />}
                </>}
            </Modal.Body>

            <Modal.Footer buttons>
                <Button primary onClick={(e) => handleClose(true)}>Start a new request</Button>
            </Modal.Footer>
        </>
    );
};

function mapStateToProps(state: RootState) {
    return {
        billers: state.accounts.users.activeBillers,
    };
}

export default connect(mapStateToProps, null)(NewPaymentRequestResultModal);
