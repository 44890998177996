import React, { useState } from 'react';
import PropTypes from 'prop-types';
import deprecated from '@premier/utils/deprecatedPropType';
import _ from 'lodash';

import { DatePicker } from '@premier/ui';
import FormGroup from './FormGroup';
import withField from './withField';


const DatePickerField = ({
    formProps,
    groupProps,
    onBlur,
    ...otherProps
}) => {

    const [id] = useState(_.uniqueId(`${formProps.name}_`));

    function handleChange(dates) {
        formProps.setValue(dates);
    };

    return (
        <FormGroup fieldId={id} {...groupProps}>
            <DatePicker
                onChange={handleChange}
                onClose={onBlur}
                value={formProps.value}
                error={formProps.error}
                aria-labelledby={groupProps.label && `${id}_label`}
                aria-required={groupProps.mandatory}
                aria-invalid={!!formProps.error}
                aria-describedby={formProps.error && `${id}_error`}
                {...otherProps}
            />
        </FormGroup>
    );
};

DatePickerField.propTypes = {
    // Form
    name: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    /** The text to show on the right of the label */
    help: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    onChange: PropTypes.func,
    onBlur: PropTypes.func,

    // Flatpickr
    mode: PropTypes.oneOf(['single', 'range']),
    enableTime: PropTypes.bool,
    /** Date format from the server (in .NET). Defaults to merchant's date format. */
    dotnetDateFormat: PropTypes.string,
    /** Time format from the server (in .NET). Defaults to merchant's time format. */
    dotnetTimeFormat: PropTypes.string,
    /** Disable some dates. See the docs (https://flatpickr.js.org/examples/#disabling-specific-dates) */
    disable: PropTypes.any,
    /** The earliest selectable date. Note that dynamic time might cause infinite refreshes */
    minDate: PropTypes.object,
    /** The latest selectable date. Note that dynamic time might cause infinite refreshes */
    maxDate: PropTypes.object,
    /** Set to true for automatic placeholder (from merchant settings), otherwise specify the placeholder manually as a string */
    placeholder: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),

    // Deprecated
    /** The TZ timezone to show date/time in. By default, it shows them in local time. */
    timeZone: deprecated(PropTypes.string, 'Deprecated because it created more confusions'),
    mandatory: deprecated(PropTypes.bool, 'Please use validate().required() in the Form initialValidation'),
};

export default withField(DatePickerField);