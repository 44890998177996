import { DeclineApiFactory, PaginateRequestSearchTrayTransactionsModelTrayTransactionsSortFieldsSortFieldEnum } from "packages/webapi-client";
import { config } from "./util";
import dateUtil from "packages/utils/date";
import currencyUtil from "packages/utils/currency";

const declineApi = DeclineApiFactory(config);

class DeclineApi {
    static getDeclineTrays(merchantNumber: string) {
        return declineApi.declineGetTrays(merchantNumber);
    }

    static getDeclineTray(trayId: number) {
        return declineApi.declineGetTray(trayId);
    }

    static getDeclineBatchFiles(merchantNumber: string) {
        return declineApi.declineGetBatchFiles(merchantNumber);
    }

    static getDeclineTrayTxns(trayId: number, pageSize: number, page: number, submissionDate?: Date, crn1Prefix?: string, amount?: number,
        accountNumberPrefix?: string, accountNumberSuffix?: string, cardTypeCode?: string,
        orderBy?: string, orderDesc?: boolean) {
        const submissionDateFrom = submissionDate ? dateUtil.convertToApiValue(submissionDate) : undefined;
        let amountApiValue = amount ? currencyUtil.convertToApiValue(amount) : undefined;
        let submissionDateTo = undefined;

        if (!crn1Prefix) {
            crn1Prefix = undefined;
        }

        if (!amountApiValue) {
            amountApiValue = undefined;
        }

        if (submissionDate) {
            // Set to date as 24 hours in the future from submissionDate
            submissionDateTo = new Date(submissionDate);
            submissionDateTo.setDate(submissionDateTo.getDate() + 1);
            submissionDateTo = dateUtil.convertToApiValue(submissionDateTo);
        }

        if (!accountNumberPrefix) {
            accountNumberPrefix = undefined;
        }

        if (!accountNumberSuffix) {
            accountNumberSuffix = undefined;
        }

        if (!cardTypeCode) {
            cardTypeCode = undefined;
        }

        return declineApi.declineGetTrayTransactions(
            trayId,
            {
                request: {
                    submissionDateFrom: submissionDateFrom,
                    submissionDateTo: submissionDateTo,
                    crn1Prefix: crn1Prefix,
                    amount: amountApiValue,
                    accountNumberPrefix: accountNumberPrefix,
                    accountNumberSuffix: accountNumberSuffix,
                    cardTypeCode: cardTypeCode,
                },
                pageIndex: page - 1,
                pageSize: pageSize,
                sortField: orderBy as PaginateRequestSearchTrayTransactionsModelTrayTransactionsSortFieldsSortFieldEnum,
                order: orderDesc ? "DESCENDING" : "ASCENDING",
            }
        )
    }

    static getDeclineTrayTxn(trayId: number, txnId: number) {
        return declineApi.declineGetTrayTransaction(trayId, txnId);
    }

    static resolveTxn(trayId: number, txnId: number, comment: string) {
        return declineApi.declineResolveTransaction(trayId, txnId, { comment });
    }
}

export default DeclineApi;
