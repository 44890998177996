import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import dateUtil from '@premier/utils/date';

import { PageSection, LoadingIndicator } from '@premier/ui';
import { PageHeader } from 'components/Common';
import { NoticeTable, UXROverviewModal } from 'components/Account';

import * as noticeActions from 'components/Account/_actions/noticeActions';

const HomePage = ({
    isLoading, //logic render
    lastLoginTime, lastLoginIp, timeZoneDescription, //user info state
    notices, //notices state
    actions  //API actions
}) => {

    useEffect(() => {
        actions.getNotices();
    }, []);

    var lastLoginText = lastLoginTime
        ? `You last logged on at ${dateUtil.convertToTimeString(lastLoginTime)} (${timeZoneDescription}) on ${dateUtil.convertToDateString(lastLoginTime)} with an IP address of ${lastLoginIp}`
        : `You never logged on before`;

    function renderNotices() {
        return isLoading || !notices
            ? <LoadingIndicator />
            : (<>
                {notices.length === 0
                    ? <p className='text-secondary'>No available eNotices</p>
                    : (<>
                        <p className='text-secondary'>Important messages from your Bank. Last Updated {dateUtil.convertToDateString(Math.max(...notices.map(n => new Date(n.updated))))}:</p>
                        <NoticeTable notices={notices} />
                    </>)
                }
            </>);
    }

    return (
        <div className='home-page'>
            <UXROverviewModal displayIntro={true} />
            <PageSection>
                <PageHeader title='Welcome' subtitle={lastLoginText} />

                <div className='enotice-section'>
                    <h2>eNotices</h2>
                    {renderNotices()}
                </div>
            </PageSection>
        </div>
    );
};

function mapStateToProps(state, ownProps) {
    let usersLoading = state.accounts.users.isLoading === undefined ? true : state.accounts.users.isLoading;
    let noticesLoading = state.accounts.eNotices.isLoading === undefined ? true : state.accounts.eNotices.isLoading

    return {
        isLoading: usersLoading || noticesLoading,

        lastLoginTime   : state.accounts.users.authenticatedUser ? state.accounts.users.authenticatedUser.lastSuccessfulLogOnTime : null,
        lastLoginIp     : state.accounts.users.authenticatedUser ? state.accounts.users.authenticatedUser.lastLogOnIpAddress : null,
        timeZoneDescription: state.accounts.users.merchant       ? state.accounts.users.merchant.timeZone.description : null,

        notices           : state.accounts.eNotices.notices
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(noticeActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
