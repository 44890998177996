import React from 'react';
import classNames from 'classnames';
import './Textarea.scss';

type Props = React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> & {
    error?: boolean
};

const Textarea = ({
    className, error, ...otherProps
}: Props) => {

    return (
        <>
            <textarea
                className={classNames('form-control', 'textarea', className, {
                    'has-error': error
                })}
                {...otherProps}
            />
        </>
    );
};

export default Textarea;
