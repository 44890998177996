import React, { useState } from 'react';
import { absolute } from '@premier/utils/url';
import { PageHeader } from 'components/Common';
import { DevelopersResourceTable } from 'components/Account';
// @ts-ignore
import mediaPaths from 'constants/mediaPaths';

const DevelopersPage : React.FC = () => {
    const [resources] = useState([
        {
            resourceName: "Testing BPOINT (Phone, Internet, DD CC)",
            pathToResource: mediaPaths.testingBpointPhoneInternetDDCC,
        },
        {
            resourceName: "BPOINT Internet - Export File Load Format v2.0",
            pathToResource: mediaPaths.exportFileLoadFormatv2,
        },
        {
            resourceName: "BPOINT Direct Debit CC - CSV File Format",
            pathToResource: mediaPaths.directDebitCsvFormat,
        },
        {
            resourceName: "BPOINT Direct Debit CC - VPOS File Format",
            pathToResource: mediaPaths.directDebitVposFormat,
        },
        {
            resourceName: "BPOINT Fraud Control - File Format and Sample",
            pathToResource: mediaPaths.fraudControlFileFormatAndSample,
        },
        {
            resourceName: "BPOINT Internet - Pre-filling fields",
            pathToResource: mediaPaths.prefillingFields,
        },
        {
            resourceName: "BPOINT Payment Link Generator Batch - CSV File Format",
            pathToResource: mediaPaths.paymentLinkGeneratorBatchCSVFormat,
        },
        {
            resourceName: "Developer reference for BPOINT REST API",
            pathToResource: absolute(process.env.REACT_APP_SITE_DOMAIN || '', 'developers/v3')
        }
    ])

    return (
        <>
            <PageHeader
                title='Developers'
            />
            <DevelopersResourceTable data={resources}/>
        </>
    );
}


export default DevelopersPage;