import React, { KeyboardEvent, ReactNode } from 'react';
import classNames from 'classnames';
import Icon from './Icon';
import './CardContainer.scss';

type Props = {
    header?: ReactNode,
    selectable?: boolean,
    selected?: boolean,
    /** Only applicable if selectable is true. This is the function called when the Select bar is clicked. */
    onSelect?: () => void,
    className?: string,
    children?: ReactNode,
}

/** A "card" with drop-shadow and an optional header. */
const CardContainer = ({
    className, header, children,
    selectable, selected, onSelect,
}: Props) => {

    function handleSelectKeyPress(e: KeyboardEvent<HTMLDivElement>) {
        if(e.key === ' ') {
            onSelect && onSelect();
        }
    }

    return (
        <div
            className={classNames('card-item-container', {
                'selectable': selectable,
                'selected': selected,
            }, className)}
        >
            {header && (
                <div className='card-item-header'>
                    {header}
                </div>
            )}

            <div className='card-item-contents'>
                {children}
            </div>

            {selectable && (
                <div className='select-bar'
                    onClick={onSelect}
                    onKeyPress={handleSelectKeyPress}
                    role='button'
                    tabIndex={0}
                    aria-label={selected ? 'Selected' : undefined}
                >
                    {selected ? <Icon check /> : 'Select'}
                </div>
            )}
        </div>
    );
}

export default CardContainer;