import { useState, useEffect } from "react";
import { Dialog, Icon, Button, LoadingIndicator, SuccessModal } from "@premier/ui";
import { useInterval, FormError } from "components/Common";

import "./RequestPendingModal.scss";

type Props = {
    isPending: boolean;
    isCancelled: boolean;
    cancelErrors?: any;
    onCheckRequestStatus: () => void;
    onClose: (reset: boolean, dismiss: boolean, showNewPaymentRequestModal: boolean) => void;
    onCancel: () => void;
}

const REQUEST_POLLING_INTERVAL = 5000;
const TIMER_REFRESH_INTERVAL = 300;

/** Dialog that displays the status of a payment request with a timer */
const RequestPendingModal = ({
    isPending, isCancelled, //data
    cancelErrors, //state
    onCheckRequestStatus, onClose, onCancel //functions
}: Props) => {

    const [timer, setTimer] = useState<number>();
    const [timerStart, setTimerStart] = useState<number | null>();
    const [autoRefreshing, setAutoRefreshing] = useState(true);
    const [showConfirmCancel, setShowConfirmCancel] = useState(false);
    const [showCancelledModal, setShowCancelledModal] = useState(false);

    // Set up the timer
    useEffect(() => {
        setTimerStart(isPending ? Date.now() : null);
        setTimer(0);
        setAutoRefreshing(isPending);
    }, [isPending]);

    useEffect(() => {
        if (isCancelled) {
            setAutoRefreshing(true);
            setShowConfirmCancel(false);
            setShowCancelledModal(true);
        }
    }, [isCancelled]);

    // Interval to get status & update timer
    useInterval(() => {
        if (isPending) {
            onCheckRequestStatus();
        }
    }, autoRefreshing ? REQUEST_POLLING_INTERVAL : null);

    useInterval(() => {
        setTimer(Date.now() - (timerStart ? timerStart : 0));
    }, autoRefreshing ? TIMER_REFRESH_INTERVAL : null);

    function confirmCancelRequest() {
        setShowConfirmCancel(true);
    }

    function cancelRequest() {
        onCancel();
    }

    function closeModal(startNewPaymentRequest?: boolean) {
        onClose(false, true, startNewPaymentRequest ?? !isCancelled);
    }

    if (showCancelledModal) {
        return <SuccessModal
            show={isCancelled}
            title="Request cancelled successfully"
            onClose={closeModal}
        />;
    }

    if (showConfirmCancel) {
        return <Dialog
            icon={<Icon alert/>}
            title="Cancel request?"
            show={showConfirmCancel}
            footerButtons={<>
                <Button onClick={cancelRequest}>Confirm</Button>
                <Button onClick={() => setShowConfirmCancel(false)}>Back</Button>
            </>}>
            Please confirm if you want to cancel this request
            <FormError apiErrors={cancelErrors} />
        </Dialog>;
    }

    return <Dialog
        className="request-pending-dialog"
        title="Request pending"
        show={true}
        footerButtons={<>
            <Button onClick={confirmCancelRequest} disabled={!isPending}>Cancel request</Button>
            <Button onClick={() => closeModal(false)}>Close</Button>
        </>}
    >
        <p>Request sent successfully.</p>
        <p>Close the dialog if you're not expecting the customer to complete the request immediately.</p>
        <div className="request-pending-timer">
            <Icon hourglass />
            <LoadingIndicator horizontal />
            <span>Request pending</span>
            <div><span className="timer-part">{`0${Math.floor((timer ? timer : 0)/60000) % 60}`.slice(-2)}</span>:<span className="timer-part">{`0${Math.floor((timer ? timer : 0)/1000) % 60}`.slice(-2)}</span></div>
        </div>
    </Dialog>;
};

export default RequestPendingModal;
