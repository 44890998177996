import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';

import { Input } from '@premier/ui';

import FormGroup from './FormGroup';
import withField from './withField';

import './MaskedCardNumberField.scss'

const MaskedCardNumberField = ({
    name, 
    compact, showEllipsis, //render props
    formProps, groupProps //forms
}) => {

    const { value, error, setValue } = formProps;

    const PREFIX_LENGTH = 6;
    const SUFFIX_LENGTH = 3;

    const [id] = useState(_.uniqueId(`${name}_`));

    React.useEffect(() => {
        if(!value) {
            setValue({
                prefix: '',
                suffix: '',
            });
        }
    }, []);

    function handleFieldChanged(fieldName) {
        return e => {
            setValue({ ...value, [fieldName]: e.target.value });
        };
    }

    return (
        <FormGroup 
            fieldId={id+'_first'}
            {...groupProps} 
            className={classNames('masked-card-number-field', {'compact': compact}, groupProps.className)}
        >
            <Input
                id={id+'_first'}
                digitsOnly
                className={classNames('card-prefix', {'ellipsis' : showEllipsis, 'noEllipsis' : !showEllipsis})}
                placeholder={`First ${PREFIX_LENGTH} digits`}
                title={`The first ${PREFIX_LENGTH} digits of the card number`} 
                maxLength={PREFIX_LENGTH}
                value={_.get(value, 'prefix', '')}
                onChange={handleFieldChanged('prefix')}
                error={error} 
            />
            {showEllipsis && <span className='ellipsis'>&hellip;</span>}
            <Input
                id={id+'_last'}
                digitsOnly
                className='card-suffix'
                placeholder={`Last ${SUFFIX_LENGTH} digits`}
                title={`The last ${SUFFIX_LENGTH} digits of the card number`} 
                maxLength={SUFFIX_LENGTH}
                value={_.get(value, 'suffix', '')}
                onChange={handleFieldChanged('suffix')}
                error={error}
            />
        </FormGroup>
    );
};

MaskedCardNumberField.propTypes = {
    // Name of the form field
    name: PropTypes.string.isRequired,

    // Compact version of the field
    compact: PropTypes.bool,

    //shows the ... between input fields
    showEllipsis: PropTypes.bool
};

export default withField(MaskedCardNumberField);
