import _ from 'lodash';
import httpRequest from './httpRequest';

var delay = 0;  // Hardcoding this here because we don't have webNoticeApi

class NoticeApi {
    static getNotices() {
        return new Promise((resolve, reject) => {
            httpRequest.get(`Notices/GetLiveNotices`).then(response => {
                resolve(response)
            }).catch(err => {
                reject(err);
            })
        });
    }

    static getNotice(id) {
        return new Promise((resolve, reject) => {
            this.getNotices().then(response => {
                var notice = _.find(response, x => x.noticeId == id);  // eslint-disable-line eqeqeq
                resolve(notice);
            }).catch(err => {
                reject(err);
            })
            setTimeout(() => {

            }, delay);
            //change this to call getNotices and then filter on the ID given.
        });
    }
}

export default NoticeApi;