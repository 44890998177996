import React, {ReactNode, useEffect, useRef} from 'react';

type Props = {
    enabled?: boolean,
    children: ReactNode,
    onBlur?: () => void,
};

const OutsideAlerter = ({
    children, onBlur,
}: Props) => {

    const wrapperRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!onBlur)
            return;

        const handleClickOutside = function (this: Document, e: Event) {
            if (
                e.target instanceof HTMLElement
                && !wrapperRef.current?.contains(e.target)
            ) {
                onBlur();
            }
        }

        document.addEventListener('click', handleClickOutside);
        document.addEventListener('touchstart', handleClickOutside, { passive: false });

        return () => {
            document.removeEventListener('click', handleClickOutside);
            document.removeEventListener('touchstart', handleClickOutside);
        };
    }, [onBlur]);

    return (
        <div ref={wrapperRef}>{children}</div>
    );
}

export default OutsideAlerter;