import { Dialog, ResponsiveTable } from "@premier/ui";
import "./SearchPaymentMethodsDialog.scss";

// @ts-ignore
import labels from "constants/labels";
import { Token } from "models/Token";

type Props = {
    /** property to display or hide modal */
    show: boolean;

    /** function called when the Back button is clicked */
    onCancel: () => void;

    selectDataVault?: (items: Token[]) => void;
    paymentMethodsList: Token[];
    paymentMethodsResultCount?: number;
}


/** A Modal to select payment methods according to CRN1 */
const SearchPaymentMethodsDialog = ({
    show, onCancel, selectDataVault, paymentMethodsList, paymentMethodsResultCount
}: Props) => {
    return (
        <Dialog
            show={show}
            title={"Select payment method"}
            className="search-payment-modal"
        >
            <p>- Choose the account you wish to make payment on and click Select account button.</p>
            <p>- Click Cancel button if you do not wish to proceed.</p>
            {(paymentMethodsResultCount && paymentMethodsResultCount > 10) &&
                <p>- NOTE: Your search returned {paymentMethodsResultCount} records. The list below is displaying only top 10 records.</p>}

            {(show && paymentMethodsList) && <ResponsiveTable
                className="search-payment-table"
                data={paymentMethodsList}
                singleSelect
                columns={[
                    { label: labels.reference1, getter: "crn1" },
                    { label: labels.reference2, getter: "crn2" },
                    { label: labels.reference3, getter: "crn3" },
                    { label: labels.transactionType, getter: "cardTypeCode" },
                    { label: labels.accountNumber, getter: "maskedCardNumber" },
                ]}
                batchActions={[
                    {
                        label: "Select",
                        batchLabel: "Select account",
                        tableButtonProps: {
                            primary: true,
                        },
                        handleClick: items => {
                            selectDataVault && selectDataVault(items);
                            onCancel();
                        }
                    },
                    {
                        label: "Cancel",
                        batchLabel: "Cancel",
                        tableButtonProps: {
                            primary: false,
                        },
                        selectionWarnings: false,
                        handleClick: () => {
                            onCancel();
                        }
                    }
                ]}
            />}
        </Dialog>
    );
};

export default SearchPaymentMethodsDialog;
