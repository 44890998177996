import React, { useEffect, useRef } from 'react';
import { Modal as BootstrapModal, ModalProps as BootstrapModalProps } from 'react-bootstrap';
import ModalFooter from './ModalFooter';
import './Modal.scss';

export type ModalProps = BootstrapModalProps & {
    /** Enable dismissal of the modal when clicking outside */
    dismissable?: boolean,
    /** When set/truthy, this modal will auto-close after it was shown for this number of seconds (true = 1 second) */
    autoClose?: boolean | number,
};

// For usage guide, see Modal storybook or https://react-bootstrap.github.io/components/modal/
/**
 * Before using Modal directly, see if you could just use Dialog (or FormDialog if it contains a Form)
 */
const Modal = ({
    dismissable, autoClose, show, onHide, children, ...otherProps
}: ModalProps) => {

    const DEFAULT_TIMEOUT_SECS = 3;

    // we don't want to reset the timer when onHide changes
    // so we use a ref to prevent onHide from being stale
    const savedOnHide = useRef<typeof onHide>();
    useEffect(() => {
        savedOnHide.current = onHide;
    }, [onHide]);

    useEffect(() => {
        if (!show || !autoClose)
            return;

        const id = setTimeout(() => {
            savedOnHide.current && savedOnHide.current();
        }, (autoClose === true ? DEFAULT_TIMEOUT_SECS : autoClose) * 1000);

        return () => {
            clearTimeout(id);
        }
    }, [show, autoClose]);

    return (
        <BootstrapModal
            show={show}
            onHide={onHide}
            centered
            backdrop={dismissable ? true : 'static' }
            aria-live='polite'
            {...otherProps}
        >
            {children}
        </BootstrapModal>
    );
};

Modal.Body   = BootstrapModal.Body;
Modal.Header = BootstrapModal.Header;
Modal.Title  = BootstrapModal.Title;
Modal.Footer = ModalFooter;

export default Modal;
