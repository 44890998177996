import httpRequest from './httpRequest';
import mapErrors, { ApiResult, mapResultErrors } from './mapErrors';
import { config, emptyResultHandler, resultHandler } from './util';
import {
    FraudApiFactory,
    FraudBasicSettingsModel,
    BillerFraudBasicSettingsModel,
    PaginateRequestSearchFraudRulesInputFraudRuleSortFields,
    PaginatedListFraudRuleModel,
    UploadAntiFraudRulesFileResponse,
    KeyDescriptionListAntiFraudAction,
    KeyDescriptionListAntiFraudType,
    FraudBasicExclusionsModel
} from '@premier/webapi-client';

const fraudApi = FraudApiFactory(config);

class FraudApi {
    static getSettings(): Promise<ApiResult<FraudBasicSettingsModel>> {
        return fraudApi.fraudGetFraudBasicSettings().then(...resultHandler).then((r) => mapResultErrors(r));
    }

    static getBillersFraudBasicSettings(options?: any): any {
        return fraudApi.fraudGetBillersFraudBasicSettings(options);
    }

    static getBillersFraudBasicCaptchaSettings(options?: any): any {
        return fraudApi.fraudGetBillersFraudBasicCaptchaSettings(options);
    }

    static updateBasicSettings(settings: FraudBasicSettingsModel): Promise<ApiResult<void>> {
        return fraudApi.fraudUpdateFraudBasicSettings(settings).then(...emptyResultHandler).then((r) => mapResultErrors(r));
    }

    static updateBillerBasicSettings(billerSettings: BillerFraudBasicSettingsModel){
          return fraudApi.fraudUpdateBillerFraudBasicSettings(billerSettings);
    }

    static fraudSearchFraudRules(pagedRequest: PaginateRequestSearchFraudRulesInputFraudRuleSortFields, options?: any): Promise<ApiResult<PaginatedListFraudRuleModel>> {
        return fraudApi.fraudSearchFraudRules(pagedRequest, options).then(...resultHandler).then((r) => mapResultErrors(r));
    }

    static fraudUploadAntiFraudRulesFile(files: FileList): Promise<UploadAntiFraudRulesFileResponse> {
        return new Promise((resolve, reject) => {
            httpRequest
                .postFiles('/Fraud/UploadAntiFraudRulesFile', files, {})
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(mapErrors(err));
                });
        })
    }

    static fraudGetFraudActionSources(options?: any): Promise<ApiResult<KeyDescriptionListAntiFraudAction>> {
        return fraudApi.fraudGetFraudActionSources(options).then(...resultHandler).then((r) => mapResultErrors(r));
    }

    static fraudGetFraudTypeSources(options?: any): Promise<ApiResult<KeyDescriptionListAntiFraudType>> {
        return fraudApi.fraudGetFraudTypeSources(options).then(...resultHandler).then((r) => mapResultErrors(r));
    }

    static fraudUpdateFraudBasicExclusions(model : FraudBasicExclusionsModel, options?: any): any {
        return fraudApi.fraudUpdateFraudBasicExclusions(model, options);
    }

    static fraudGetFraudBasicExclusions(options?: any): any {
        return fraudApi.fraudGetFraudBasicExclusions(options);
    }
}
export default FraudApi;
