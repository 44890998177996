import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import currencyUtil from '@premier/utils/currency';
import { ResponsiveTable, CardContainer, DescriptionList } from '@premier/ui';

import './SettlementSummaryReport.scss';

const SettlementSummaryReport = ({
    report //data
}) => {

    const SUMMARY_TYPES = ['cards', 'bankAccounts', 'digitalWallets', 'totals'];

    const [data, setData] = useState([]);

    useEffect(() => {
        var data = SUMMARY_TYPES.map(type => ({
            type,
            ...report[type],
            className: type === 'totals' ? 'footer' : undefined,
        }));
        setData(data);
    }, [report]);


    function getTypeLabel(item) {
        switch (item.type) {
            case 'cards':
                return 'Cards';
            case 'bankAccounts':
                return 'Bank accounts';
            case 'digitalWallets':
                return 'Digital wallets';
            case 'totals':
                return 'Total transactions';
            default:
                return item.type;
        }
    }


    return (
        <div className='settlement-summary-report'>
            <ResponsiveTable highlightTotal
                data = {data}
                columns = {[
                    {label: 'Type',       getter: getTypeLabel},
                    {label: 'Authorised', getter: x => currencyUtil.convertToDisplayString(x.authorised), textAlign: 'right'},
                    {label: 'Rejected',   getter: x => currencyUtil.convertToDisplayString(x.rejected),   textAlign: 'right'},
                    {label: 'Refunded',   getter: x => currencyUtil.convertToDisplayString(x.refunded),   textAlign: 'right'},
                    {label: 'Net amount', getter: x => currencyUtil.convertToDisplayString(x.net),        textAlign: 'right', className: 'net'},
                ]}
                firstColIsHeader
                renderCard={(listItems, link, index) => {
                    let title = listItems.shift().value;
                    return (
                        <CardContainer key={index} header={title}>
                            <DescriptionList showBlankName items={listItems} spaceBetween inlineXs />
                        </CardContainer>
                    );
                }}
            />
        </div>
    );
};

SettlementSummaryReport.propTypes = {
    /** The summary report, having amounts in dollars */
    report: PropTypes.shape({
        cards: PropTypes.shape({
            authorised: PropTypes.number,
            rejected: PropTypes.number,
            refunded: PropTypes.number,
            net: PropTypes.number
        }),
        bankAccounts: PropTypes.shape({
            authorised: PropTypes.number,
            rejected: PropTypes.number,
            refunded: PropTypes.number,
            net: PropTypes.number
        }),
        digitalWallets: PropTypes.shape({
            authorised: PropTypes.number,
            rejected: PropTypes.number,
            refunded: PropTypes.number,
            net: PropTypes.number
        }),
        totals: PropTypes.shape({
            authorised: PropTypes.number,
            rejected: PropTypes.number,
            refunded: PropTypes.number,
            net: PropTypes.number
        })
    }),
};

export default SettlementSummaryReport;