import React from 'react';
import { connect } from 'react-redux';
import text from 'constants/text';
import labels from 'constants/labels';
import footerLogo from 'images/footerlogo.svg';

const Footer = ({
    authenticatedUser //state
}) => {
    return (
        <footer aria-live='off'>
            <div className='footer-content'>
                <p className='footer-text'>
                    {authenticatedUser && (
                        <span>
                            Need help? Call us on{' '}
                            {/* This needs to be an anchor instead of a Link component because
                            Link doesn't support tel: or mailto: prefixes. */}
                            <a href={`tel:${text.supportPhoneNumber.replace(/\s+/g, '')}`}>
                                <strong>{text.supportPhoneNumber}</strong>
                            </a>
                            <span className='footer-support-code'>
                                {' '}
                                - Support Code <strong>{authenticatedUser.supportCode}</strong>
                            </span>
                            <br />
                        </span>
                    )}
                    Brought to you by Linkly and Commonwealth Bank of Australia, ABN 48 123 123 124 AFSL
                    and Australian credit licence 234945
                </p>
                <img className='footer-logo' src={footerLogo} alt={labels.footerLogoAlt} />
            </div>
        </footer>
    );
};

function mapStateToProps(state, ownProps) {
    return {
        authenticatedUser: state.accounts.users.authenticatedUser
    };
}

export default connect(mapStateToProps)(Footer);
