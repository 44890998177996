import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Navigate } from 'react-router-dom';
import _ from 'lodash';

import shopUtil from '@premier/utils/stationeryShop';

import {Icon, PageSection, Row, SingleColumnFormContainer} from '@premier/ui';
import {Form, InputField, PhoneNumberField, SubmitButton, validate} from '@premier/form';
import { PageHeader } from 'components/Common';
import { CheckoutSteps, CustomerAddress, DeliveryAddressSelector } from 'components/StationeryShop';
import countryUtil from '@premier/utils/country'

import * as actions from 'components/StationeryShop/_actions/stationeryShopActions';
import { PlatformRoutesConfiguration } from 'components/Routing';

import './CheckoutAddress.scss';

const CheckoutAddress = ({
    recentAddresses, merchantName, savedAddress, cart, //state values
    actions //API actions
}) => {

    const [redirectNext, setRedirectNext] = useState(false);

    useEffect(() => {
        actions.getRecentAddresses();
    }, []);

    if(shopUtil.cartIsEmpty(cart))
        return <Navigate to={PlatformRoutesConfiguration.stationeryRoute.homePage.generatePath()} />;

    const billingAddress = _.find(recentAddresses, {addressType: 'BILLING'});
    const otherAddresses = recentAddresses && recentAddresses.filter(a => a.addressType !== 'BILLING');

    function getState(defaultStateVal) {
        const stateOptions = countryUtil.getStateOptions(countryUtil.country.countryCode, false);
        if (stateOptions.length) {
            const state = _.find(stateOptions, {value:defaultStateVal});
            return state.label;
        }
        return '';
    }

    function handleSubmit(values) {
        var address;
        if (values.delRadio === 'same') {
            address = billingAddress;
        }
        else if (values.delRadio === 'new') {
            address = {
                customerName: values.customerName,
                street1     : values.addressLine1,
                street2     : values.addressLine2,
                suburb      : values.suburb,
                state       : getState(values.state),
                postcode    : values.postcode,
            }
        }
        else {
            var index = parseInt(values.delRadio.split('_').pop());  // delRadio will be like 'del_2'
            address = otherAddresses[index];
        }

        actions.setDeliveryAddress({
            firstName: values.firstName,
            lastName: values.lastName,
            phoneNumber: values.phoneNumber,
            delRadio: values.delRadio,
            address
        });

        setRedirectNext(true);
    }


    if (redirectNext)
        return <Navigate to={PlatformRoutesConfiguration.stationeryRoute.checkoutReview.generatePath()} />;

    return (
        <div className='checkout-address-page'>
            <PageSection>
                <PageHeader
                    backButton={{
                        to: PlatformRoutesConfiguration.stationeryRoute.homePage.generatePath(),
                        label: 'Continue shopping'
                    }}
                />

                <CheckoutSteps currentStep={2} />

                {recentAddresses && (<>
                    <h2>Billing address</h2>
                    <CustomerAddress address={billingAddress} className='billing-address' />
                    <p><Icon info inline /> This is your billing address. If you wish to change it, please contact CommBank.</p>

                    <h2>Delivery address</h2>

                    <Form onSubmit={handleSubmit}
                        initialValues={{
                            firstName:    _.get(savedAddress, 'firstName', ''),
                            lastName:     _.get(savedAddress, 'lastName', ''),
                            phoneNumber:  {
                               iddCode: countryUtil.getIddCode(countryUtil.country.countryCode),
                                ..._.get(savedAddress, 'phoneNumber')
                            },
                            delRadio:     _.get(savedAddress, 'delRadio', ''),
                            customerName: _.get(savedAddress, 'address.customerName', merchantName),
                        }}
                        initialValidation={{
                            firstName:    validate().required().maxLength(100),
                            lastName:     validate().required().maxLength(100),
                            phoneNumber:  validate().requiredPhone().phone().maxLengthPhone(30),
                            delRadio:     validate().required('Please select a delivery address'),
                            customerName: validate().requiredIf(vals => vals['delRadio'] === 'new').maxLength(100),
                            addressLine1: validate().requiredIf(vals => vals['delRadio'] === 'new').maxLength(100),
                            addressLine2: validate().maxLength(100),
                            suburb:       validate().requiredIf(vals => vals['delRadio'] === 'new').maxLength(100),
                            state:        validate().requiredIf(vals => vals['delRadio'] === 'new'),
                            postcode:     validate().requiredIf(vals => vals['delRadio'] === 'new').maxLength(15),
                        }}
                    >
                        <SingleColumnFormContainer>
                            <Row>
                                <div className='col-sm-6'>
                                    <InputField name='firstName' label='First name' maxLength={100} />
                                </div>
                                <div className='col-sm-6'>
                                    <InputField name='lastName' label='Last name' maxLength={100} />
                                </div>
                            </Row>
                            <PhoneNumberField name='phoneNumber' label='Phone number' />
                        </SingleColumnFormContainer>

                        <DeliveryAddressSelector addresses={otherAddresses}/>

                        <SubmitButton>Next</SubmitButton>
                    </Form>
                </>)}
            </PageSection>
        </div>
    );
};

function mapStateToProps(state, ownProps) {
    return {
        recentAddresses: state.stationeryShop.shop.recentAddresses,
        merchantName: state.accounts.users.merchant.merchantName,
        savedAddress: state.stationeryShop.shop.deliveryAddress,
        cart: state.stationeryShop.shop.cart,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutAddress);
