import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import currencyUtil from '@premier/utils/currency';
import { ScrollingTable } from '@premier/ui';

import './SettlementTransactionsBreakdown.scss';

const SettlementTransactionsBreakdown = ({
    report //data
}) => {

    const [data, setData] = useState([]);

    useEffect(() => {
        var data = [];

        report.subTotalCards.forEach((item) => {
            data.push({
                type: item.card.description,
                amount: item.amount,
                collected: item.amountOriginal,
                surcharge: item.amountSurcharge
            });
        });

        if(report.subTotalCards.length && report.cardsExclSubTotal.length) {
            data.push({
                type: 'Sub total',
                amount: report.subTotals.amount,
                collected: report.subTotals.amountOriginal,
                surcharge: report.subTotals.amountSurcharge,
                className: 'highlight'
            });
        }

        report.cardsExclSubTotal.forEach((item) => {
            data.push({
                type: item.card.description,
                amount: item.amount,
                collected: item.amountOriginal,
                surcharge: item.amountSurcharge
            });
        });

        if(report.subTotalCards.length || report.cardsExclSubTotal.length) {
            data.push({
                type: 'Total',
                amount:    report.totals.amount,
                collected: report.totals.amountOriginal,
                surcharge: report.totals.amountSurcharge,
                className: 'footer'
            });
        }

        setData(data);
    }, [report]);


    return (
        <div className='settlement-transactions-breakdown'>
            <ScrollingTable
                data={data}
                columns={[
                    { label: 'Account type', getter: x => x.type },
                    {
                        label: 'Amount',
                        getter: (x) => currencyUtil.convertToDisplayString(x.amount),
                        textAlign: 'right',
                    },
                    {
                        label: <>Amounts collected<br />(excluding surcharge)</>,
                        getter: (x) => currencyUtil.convertToDisplayString(x.collected),
                        textAlign: 'right',
                    },
                    {
                        label: 'Surcharge collected',
                        getter: (x) => currencyUtil.convertToDisplayString(x.surcharge),
                        textAlign: 'right',
                    },
                ]}
            />
        </div>
    );
};

SettlementTransactionsBreakdown.propTypes = {
    report: PropTypes.shape({
        subTotalCards: PropTypes.arrayOf(PropTypes.shape({
            card: PropTypes.shape({
                code: PropTypes.string,
                key: PropTypes.string,
                description: PropTypes.string,
            }),
            amount: PropTypes.number,
            amountOriginal: PropTypes.number,
            amountSurcharge: PropTypes.number,
        })),
        subTotals: PropTypes.shape({
            amount: PropTypes.number,
            amountOriginal: PropTypes.number,
            amountSurcharge: PropTypes.number,
        }),
        cardsExclSubTotal: PropTypes.arrayOf(PropTypes.shape({
            card: PropTypes.shape({
                code: PropTypes.string,
                key: PropTypes.string,
                description: PropTypes.string,
            }),
            amount: PropTypes.number,
            amountOriginal: PropTypes.number,
            amountSurcharge: PropTypes.number,
        })),
        totals: PropTypes.shape({
            amount: PropTypes.number,
            amountOriginal: PropTypes.number,
            amountSurcharge: PropTypes.number,
        }),
    }),
};

export default SettlementTransactionsBreakdown;