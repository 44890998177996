import { useMemo, useRef, useState } from "react";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import _ from "lodash";
import FormGroup from "./FormGroup";
import withField from "./withField";
import classNames from "classnames";
import { accountApi } from "api";
import { APICallRequestState, useApiCall } from "components/Common";
import { getPlatform } from "platforms/current/util";

import "./HCaptchaField.scss";

type Props = {
    name: string;
    formProps: any;
    onVerify: (token: string, ekey: string) => void;
}

const HCaptchaField = ({ name, formProps, onVerify }: Props) => {
    const [id] = useState(_.uniqueId(`${name}_`));
    const captchaRef = useRef<HCaptcha>(null);

    // Reset hCaptcha if the token response has been cleared from the form (happens when user login fails due to invalid credentials)
    useMemo(() => {
        if (!formProps.value) {
            captchaRef.current?.resetCaptcha();
        }
    }, [formProps.value]);

    const [siteKey, siteKeyStatus] = useApiCall(async ()=> {
        return await accountApi.getHCaptchaSiteKey(getPlatform());
    }, []);

    const handleVerificationSuccess = (token: string, ekey: string) => {
        formProps.setValue(token);
        onVerify(token, ekey);
    };

    return (
        <FormGroup fieldId={id} name={name} mandatory={false}>
            <div className={
                classNames({
                    "hcaptcha-container": true,
                    "has-error": formProps.error
                })
            }>
                {siteKeyStatus === APICallRequestState.SUCCESSFUL && siteKey && siteKey.siteKey && <HCaptcha
                    ref={captchaRef}
                    sitekey={siteKey.siteKey}
                    onVerify={(token, ekey) => handleVerificationSuccess(token, ekey)}
                />}
            </div>
        </FormGroup>
    );
};

export default withField(HCaptchaField);
