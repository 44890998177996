import React from 'react';
import PropTypes from 'prop-types';

import currencyUtil from '@premier/utils/currency';
import { getExampleSurcharge } from '@premier/utils/settings';
import { ResponsiveTable, CardLogo, TooltipTrigger, Tooltip } from '@premier/ui';
import { InputField, CurrencyField } from '@premier/form';

import { CardLocalisationEnum, CardSubTypeEnum } from 'constants/billpay';
import labels from 'constants/labels';

import './BillerSurchargeTable.scss';

/**A Responsive Biller surcharge table, used in the Accepted Cards & Surcharge page.
 * Allows for switch between simple / advanced layouts, as well as input of surcharge values.
 */
const BillerSurchargeTable = ({
    rules, advanced,
}) => {

    const columns = [
        {
            simple: true,
            advanced: true,
            label: 'Option',
            getter: getOptionCellValue,
        },
        {
            advanced: true,
            label: 'Fixed amount',
            getter: (rule, idx) => (
                <CurrencyField compact noLabels label='Fixed amount' name={`rules[${idx}].fixedSurchargeAmount`} />
            ),
            className: 'fixed-amount',
        },
        {
            advanced: true,
            label: '',
            getter: () => '+',
            textAlign: 'center',
            className: 'plus',
        },
        {
            simple: true,
            advanced: true,
            label: '%',
            getter: (rule, idx) => (
                <InputField decimal noLabels label='%' name={`rules[${idx}].fixedSurchargePercent`} />
            ),
            className: 'percent',
        },
        {
            advanced: true,
            label: 'Minimum amount',
            getter: (rule, idx) => (
                <CurrencyField compact noLabels label='Minimum amount' name={`rules[${idx}].surchargeLimits.minimum`} />
            ),
            className: 'min-amount',
        },
        {
            simple: true,
            advanced: true,
            label: 'Maximum amount',
            getter: (rule, idx) => (
                <CurrencyField compact noLabels name={`rules[${idx}].surchargeLimits.maximum`} placeholder='' />
            ),
            className: 'max-amount',
        },
        {
            advanced: true,
            label: 'Example payment amounts',
            getter: (rule, idx) => (
                <TooltipTrigger tipId={`tipExample_${idx}`} className='view-example-text'>
                    View
                </TooltipTrigger>
            ),
            textAlign: 'center',
            className: 'example-advanced'
        },
        {
            simple: true,
            label: currencyUtil.formatWithPrefix(10),
            getter: (rule, idx) => getExampleSurcharge(rule, 10),
        },
        {
            simple: true,
            label: currencyUtil.formatWithPrefix(100),
            getter: (rule, idx) => getExampleSurcharge(rule, 100),
        },
        {
            simple: true,
            label: currencyUtil.formatWithPrefix(1000),
            getter: (rule, idx) => getExampleSurcharge(rule, 1000),
        },
    ];

    function getOptionCellValue(rule) {
        if (
            rule.cardTypeCode === 'UNSPECIFIED' &&
            rule.cardSubType === CardSubTypeEnum.UNSPECIFIED &&
            rule.cardLocalisation === CardLocalisationEnum.UNSPECIFIED
        ) {
            return 'Surcharge';
        }

        return (
            <div className='card-option'>
                <CardLogo cardTypeCode={rule.cardTypeCode} />
                <div>
                    {rule.cardSubType === CardSubTypeEnum.DEBIT && <span>Debit</span>}
                    {rule.cardSubType === CardSubTypeEnum.CREDIT && <span>Credit</span>}
                    {rule.cardLocalisation === CardLocalisationEnum.LOCAL && <span>{labels.local}</span>}
                    {rule.cardLocalisation === CardLocalisationEnum.INTERNATIONAL && <span>{labels.international}</span>}
                </div>
            </div>
        );
    }

    function getExampleSurcharges(rule) {
        return (
            <table className='tbl-example'>
                <thead>
                    <tr>
                        <th>Payment amount</th>
                        <th>Surcharge</th>
                    </tr>
                </thead>
                <tbody>
                    {[10, 100, 1000].map((amount, idx) => (
                        <tr key={`tr_${idx}`}>
                            <td>{currencyUtil.formatWithPrefix(amount, undefined, 0)}</td>
                            <td>{getExampleSurcharge(rule, amount)}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    }

    function getColumns() {
        return columns.filter(c => advanced ? c.advanced : c.simple);
    }

    return (
        <div className='biller-surcharge-table'>
            <ResponsiveTable data={rules} columns={getColumns()} />

            {(rules || []).map((rule, idx) => (
                <Tooltip id={`tipExample_${idx}`} key={`tipExample_${idx}`}>
                    {getExampleSurcharges(rule)}
                </Tooltip>
            ))}
        </div>
    );
};

BillerSurchargeTable.propTypes = {
    /** From the Form values */
    rules: PropTypes.arrayOf(PropTypes.shape({
        cardTypeCode: PropTypes.string,
        cardSubType: PropTypes.string,
        cardLocalisation: PropTypes.string,
        fixedSurchargeAmount: PropTypes.number,
        fixedSurchargePercent: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        surchargeLimits: PropTypes.shape({
            minimum: PropTypes.number,
            maximum: PropTypes.number
        }),
    })),

    /** Toggle between the simple & advanced layouts for the table */
    advanced: PropTypes.bool,
};

export default BillerSurchargeTable;