import { ErrorMessage, useFormikContext } from "formik";

type Props = {
    name: string;
}

const FormikError = ({ name }: Props) => {
    const formik = useFormikContext<any>();

    return <>
        {typeof formik.errors[name] === "string" &&
            <ErrorMessage
                name={name}
                component="div"
                className="server-error"
            />
        }
    </>;
}

export default FormikError;
