import { useState } from "react";
import { connect } from "react-redux";
import { RootState } from "store/store";

import { declineManagerApi } from "api";

import { PageHeader, MerchantDropdown, useApiCall, APICallRequestState } from "components/Common";
import DeclineManagerTrays from "components/Settings/components/BatchDeclineSettings/DeclineManagerTrays";
import { FormGroup, LoadingIndicator, PageSection } from "packages/ui";
import { Option } from "packages/formik-ui/src/FormikDropdown";
import { MerchantModel } from "packages/webapi-client";
import { userRoles } from "components/Routing";
import { getMerchantOptions } from "packages/utils/billpay";

type Props = {
    merchant: MerchantModel;
}

const ManageTraysPage = ({ merchant }: Props) => {

    const [merchantNumber, setMerchantNumber] = useState<Option | null>({
        label: merchant?.merchantName ?? "",
        value: merchant?.merchantNumber ?? ""
    });

    const [declineManagerTrays, declineManagerTraysStatus] = useApiCall(() => {
        return declineManagerApi.retrieveMerchantTrays(getMerchantNumber())
    }, [merchantNumber]);

    const merchantOptions = getMerchantOptions(merchant, false, userRoles.declineManager);

    const getMerchantNumber = () => {
        return merchantNumber?.value?.toString() ?? "";
    }

    const handleMerchantChange = (selectedMerchant: Option | null) => {
        setMerchantNumber(selectedMerchant);
    };

    return (
        <PageSection>
                <PageHeader backButton title="Trays" subtitle="Manage properties of declined batch payment trays." />

            {merchantOptions.length > 1 ?
                <FormGroup fieldId="merchantNumber">
                    <MerchantDropdown
                        useDefaultValue
                        feature={userRoles.declineManager}
                        onChange={handleMerchantChange}
                    />
                </FormGroup> :
                <></>}

            {declineManagerTraysStatus === APICallRequestState.LOADING || declineManagerTraysStatus === APICallRequestState.PENDING
                    ? <LoadingIndicator /> : <DeclineManagerTrays declineTrays={declineManagerTrays} merchantNumber={getMerchantNumber()} />}

        </PageSection>
    );
};

function mapStateToProps(state: RootState) {
    return {
        merchant: state.accounts.users.merchant,
    };
}

export default connect(mapStateToProps)(ManageTraysPage);
