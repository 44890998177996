import React from 'react';
import './Mandatory.scss';

/** A red asterisk as a mandatory field indicator */
const Mandatory = () => {
    return (
        <span className='mandatory-asterisk' title='This field is mandatory' aria-label='Required'>*</span>
    );
};

export default Mandatory;