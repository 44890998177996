import { Currency } from './models';
import currencies from './currencies';

export class CurrencyUtil {
    currency: Currency;
    currencies: Currency[];

    constructor() {
        let aud = currencies.find(c => c.alphaCode === 'AUD');
        if(!aud)
            throw new Error("AUD not found in currencies");

        this.currency = aud;
        this.currencies = currencies;
    }

    set currencyAlphaCode(alphaCode: string) {
        let cur = currencies.find(c => c.alphaCode === alphaCode);
        if(!cur)
            throw new Error(`${alphaCode} not found in currencies`);

        this.currency = cur;
    }

    /** default currency code for Australia */
    getDefaultCurrencyCode() {
        return 36
    };

    /** Returns the currency object (eg. 36 => object: {alphaCode: 'AUD'})
      * @param currencyCode (Optional) The server code of the Currency, eg. 36 (for AUD)
      */
    getCurrency(currencyCode?: number) {
        var currency = currencyCode && currencies.find(c => c.code === currencyCode);
        return currency || this.currency;
    }

    /** Returns the currency symbol, falling back to alpha code (eg. 36 => "$")
      * @param currencyCode (Optional) The server code of the Currency, eg. 36 (for AUD)
      * @param returns eg. "$" or "AFN"
      */
    getSymbol(currencyCode?: number) {
        var currency = this.getCurrency(currencyCode);
        return currency.symbol || currency.alphaCode;
    }

    /** Returns the currency alpha and Name, e.g. AUD - Australia Peso
      * @param currencyCode (Optional) The server code of the Currency, eg. 36 (for AUD)
      * @param returns eg. "AUD - Australia Lira"
      */
    getCurrencySymbolAndName(currencyCode?: number) {
        var cur = this.getCurrency(currencyCode);
        if(!cur)
            return '';

        return `${cur.alphaCode} - ${cur.name}`;
    }

    //#region ---------- UI to API ----------

    /** Format user-input amount to a string to be sent to the backend API (eg. "1,500.00" => 150000)
      * @param userInput What the user types (eg. "1,500.00")
      * @param returns What will be sent to the API, eg. 150000 (for $1500)
      */
    convertToApiValue(userInput: string | number | null) {
        if(typeof userInput === 'string')
            userInput = this.parseAmount(userInput);

        if(!userInput)
            return userInput;

        return Math.round(userInput * Math.pow(10, this.currency.decimalPlaces));
    }

    //#endregion

    //#region ---------- API to UI ----------

    /** Converts API value amount (eg. cents) to the real amount (eg. dollars)
      * @param apiValue The amount (usually in cents), eg. 1000
      * @param currencyCode (Optional) The server code of the Currency, eg. 36 (for AUD)
      * @param returns eg. 10.00
      */
    fromApiValue(apiValue: number, currencyCode?: number) {
        if(!apiValue) return apiValue;

        var currency = this.getCurrency(currencyCode);
        return apiValue / Math.pow(10, currency.decimalPlaces);
    }

    /** Format API value amount to a user-friendly string (eg. (1000, 36) => "AUD 10.00")
      * @param amount The amount (usually in cents)
      * @param currencyCode (Optional) The server code of the Currency, eg. 36 (for AUD)
      * @param returns eg. "AUD 10.00"
      */
    convertToDisplayString(apiValue: number, currencyCode?: number) {
        return this.formatWithPrefix(this.fromApiValue(apiValue, currencyCode), currencyCode);
    }

    /** (Legacy code) Format API value amount to a formatted string (eg. (1000, 36) => "10.00")
      * @param amount The amount (usually in cents)
      * @param currencyCode (Optional) The server code of the Currency, eg. 36 (for AUD)
      * @param returns eg. "10.00"
      */
    formatApiValue(apiValue: number, currencyCode?: number, decimalPlaces?: number) {
        if(decimalPlaces == null) {
            var currency = this.getCurrency(currencyCode);
            decimalPlaces = currency.decimalPlaces;
        }

        return this.formatAmount(this.fromApiValue(apiValue, currencyCode), decimalPlaces);
    }

    /** For surcharges
     * @param apiValue API value, eg. 1000 for 1%
     * @param returns eg. "1%"
     */
    convertToDisplayPercent(apiValue: number) {
        return (apiValue / 100000).toLocaleString('en', { style: 'percent', maximumFractionDigits: 2 });
    }

    //#endregion

    //#region ---------- UI only ----------

    /** Format amount to a user-friendly string (eg. 1500 -> '1,500.00')
      * @param {number|string} amount The amount to format, eg. 1500. But it can also accept a string, eg. '1,500'
      * @param {number} decimalPlaces (Optional) The number of decimal places to show. Defaults to the currency's number of decimal places.
      * @param {boolean} useGrouping (Optional) See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
      */
    formatAmount(amount: number | string | undefined, decimalPlaces?: number, useGrouping : boolean = true) {
        if(amount == null || amount === '')
            return '';

        if(typeof amount === 'string') {
            let parsedAmount = this.parseAmount(amount);
            if(parsedAmount === null)
                return '';
            amount = parsedAmount;
        }

        if(decimalPlaces == null)
            decimalPlaces = this.getCurrency().decimalPlaces;

        //TODO Thousands separator handling - read separators from merchant settings
        var amountStr = Number(Number(amount).toFixed(decimalPlaces)).toLocaleString('en-AU', {minimumFractionDigits: decimalPlaces, useGrouping});
        return amountStr;
    }

    /** Parse a formatted amount back to number (eg. '1,500.00' -> 1500). Returns null if amountStr is an invalid number.
      * @param {string} amountStr The (formatted) amount to parse, eg. '1,500.00'
      */
     parseAmount(amountStr: string | number) {
        if(amountStr === '')
            return null;

        if(typeof(amountStr) === 'number')
            return amountStr;

        if(/[^\d.,]/.test(amountStr))  // having invalid character
            return null;

        // TODO Read thousand/decimal separator from merchant config
        var amount = parseFloat(amountStr.replace(/,/g, ''));
        return isNaN(amount) ? null : amount;
    }

    /** Format amount to a user-friendly string with currency-alpha-code prefix (eg. (10, 36) => "AUD 10.00")
      * @param amount The amount (eg. 10)
      * @param currencyCode (Optional) The server code of the Currency, eg. 36 (for AUD)
      * @param decimalPlaces (Optional) Decimal places to show the currency result to (defaults to currency default)
      * @param returns eg. "AUD 10.00"
      */
    formatWithPrefix(amount: number | string, currencyCode?: number, decimalPlaces?: number) {
        if(amount === '')
            return amount;

        var currency = this.getCurrency(currencyCode);
        return currency.alphaCode + ' ' + this.formatAmount(amount, decimalPlaces == null ? currency.decimalPlaces : decimalPlaces);
    }

    /** Takes API currencyId e.g. 36 and returns the alphacode eg AUD
     * @param currencyCode Optional currency code to pass in, if required to get a different currency alpha
     */
    getCurrencyAlpha(currencyCode: number) {
        var currency = this.getCurrency(currencyCode);
        return currency.alphaCode;
    }

    /** Returns a currency code list. E.G. 'AUD - Australian Dollar', 'NZD - New Zealand Dollar'
      * @param currencyList The currency array object that can be mapped for return
      */
    getCurrencyOptions(currencyList?: Currency[]) {
        return (currencyList || this.currencies).map(currency => ({
            label: `${currency.alphaCode} - ${currency.name}`,
            value: currency.code
        }));
    }

    //#endregion
}


const currencyUtil = new CurrencyUtil();
export default currencyUtil;

//exporting as its own method allows for a run-once global configuration.
export const setCurrency = (currencyAlphaCode: string) => {
    currencyUtil.currencyAlphaCode = currencyAlphaCode;
}

// Set a default currency, although this would probably never be used (the website should always use merchant currency by default)
setCurrency('AUD');
