import React, { useState, useEffect } from 'react';
import { Dialog, Button } from '@premier/ui';
import { useSelector } from 'react-redux';

import mobileModalImage from 'images/Modal_Mobile.png';
import navigationModalImage from 'images/Modal_Navigation.png';
import guidesModalImage from 'images/Modal_Guides.png';
import feedbackModalImage from 'images/Modal_Feedback.png';

import './UXROverviewModal.scss';

enum UXROverviewModalState {
    MOBILE_MODAL,
    NAVIGATION_MODAL,
    GUIDES_MODAL,
    FEEDBACK_MODAL,
    HIDE_MODAL
}

const UXROverviewModalCopy = {
    modalTitle: "Welcome to the new BPOINT back office​",
    mobileModalSubText: "BPOINT back office is now optimised for all device types from desktop to mobile.",
    navigationModalSubText: "BPOINT now has a simplified left hand navigation panel.​",
    guidesModalSubText: "Helpful guides can be found across BPOINT pages. Just look for the question mark.",
    feedbackModalSubText: "Help us improve BPOINT by sharing your feedback."
}

const UXROverviewModal : React.FC = () => {
    const [authenticatedUser] = useState(useSelector((state: any) => state.accounts.users.authenticatedUser));
    const [cookieName, setCookieName] = useState<string>("");
    const [componentState, setComponentState] = useState<UXROverviewModalState>(UXROverviewModalState.HIDE_MODAL);

    useEffect(() => {
        if(authenticatedUser?.userId) {
            setCookieName(`hideUxrModal-${authenticatedUser.userId}`);
        }
    }, [authenticatedUser])

    useEffect(() => {
        if(cookieName && !!!window.localStorage.getItem(cookieName)) {
            setComponentState(UXROverviewModalState.MOBILE_MODAL);
        }
    }, [cookieName])

    const onClose = () => {
        if(cookieName)
            window.localStorage.setItem(cookieName, "true");
        setComponentState(UXROverviewModalState.HIDE_MODAL);
    }

    switch (componentState) {
        case UXROverviewModalState.MOBILE_MODAL:
            return (<Dialog
                title={UXROverviewModalCopy.modalTitle}
                show={true}
                onClose={onClose}
                closeButton
                dismissable
                footerButtons={<>
                    <Button disabled className="hidden-button">Previous</Button>
                    <Button primary onClick={() => ( setComponentState(UXROverviewModalState.NAVIGATION_MODAL))}>Next</Button>
                </>}>
                <p>{UXROverviewModalCopy.mobileModalSubText}</p>
                <img src={mobileModalImage} alt="Mobile"></img>
            </Dialog>);
        case UXROverviewModalState.NAVIGATION_MODAL:
            return (<Dialog
                title={UXROverviewModalCopy.modalTitle}
                show={true}
                onClose={onClose}
                closeButton
                dismissable
                footerButtons={<>
                    <Button onClick={() => (setComponentState(UXROverviewModalState.MOBILE_MODAL ))}>Previous</Button>
                    <Button primary onClick={() => ( setComponentState(UXROverviewModalState.GUIDES_MODAL))}>Next</Button>
                </>}>
                <p>{UXROverviewModalCopy.navigationModalSubText}</p>
                <img src={navigationModalImage} alt="Navigation"></img>
            </Dialog>);
        case UXROverviewModalState.GUIDES_MODAL:
            return (<Dialog
                title={UXROverviewModalCopy.modalTitle}
                show={true}
                onClose={onClose}
                closeButton
                dismissable
                footerButtons={<>
                    <Button onClick={() => (setComponentState(UXROverviewModalState.NAVIGATION_MODAL ))}>Previous</Button>
                    <Button primary onClick={() => (setComponentState(UXROverviewModalState.FEEDBACK_MODAL))}>Next</Button>
                </>}>
                <p>{UXROverviewModalCopy.guidesModalSubText}</p>
                <img src={guidesModalImage} alt="Guides"></img>
            </Dialog>);
        case UXROverviewModalState.FEEDBACK_MODAL:
            return (<Dialog
                title={UXROverviewModalCopy.modalTitle}
                show={true}
                onClose={onClose}
                closeButton
                dismissable
                footerButtons={<>
                    <Button onClick={() => (setComponentState(UXROverviewModalState.GUIDES_MODAL ))}>Previous</Button>
                    <Button primary onClick={onClose}>Get Started</Button>
                </>}>
                <p>{UXROverviewModalCopy.feedbackModalSubText}</p>
                <img src={feedbackModalImage} alt="Feedback"></img>
            </Dialog>);
        case UXROverviewModalState.HIDE_MODAL:
        default:
            return null;
    }
}

export default UXROverviewModal;
