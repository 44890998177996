import _ from "lodash";
import { Form, FormErrorList, SubmitButton } from "@premier/form";
import {
    ButtonContainer,
    BackButton,
    PaddedContainer,
    PageSection,
    LoadingIndicator,
    Link,
    IconText,
    SuccessModal,
} from "@premier/ui";
import { BackofficeDeeplink, PageHeader } from "components/Common";
import { MessagingConfigOptionFields } from "components/Settings/components/MessagingConfigOptionFields";
import { PlatformRoutesConfiguration } from "components/Routing";
import { useMessagingSettings } from "components/Settings/_hooks/useMessagingSettings";

export const MessagingRemindersPage = () => {
    const {
        hasLoaded,
        hasFailed,
        isUpdating,
        hasUpdated,
        errors,
        settings,
        updateSettings,
        resetUpdateSettings,
    } = useMessagingSettings();

    if (hasFailed)
        return (
            <PageSection>
                <BackButton to={PlatformRoutesConfiguration.messagingRoute?.root.generatePath()} />
                <IconText alert>Something went wrong.</IconText>
            </PageSection>
        );

    if (!hasLoaded) 
        return <LoadingIndicator />;

    return (
        <PageSection>
            <PageHeader title="Reminders" backButton={{ to: PlatformRoutesConfiguration.messagingRoute?.root.generatePath() }} />
            <Form onSubmit={updateSettings} initialValues={_.cloneDeep(settings)} errors={errors}>
                <PaddedContainer>
                    <MessagingConfigOptionFields label="Scheduled payment" name="scheduledPaymentReminder" />
                    <MessagingConfigOptionFields label="Scheduled batch" name="scheduledBatchReminder" />
                </PaddedContainer>

                <ButtonContainer>
                    <SubmitButton disabled={isUpdating}>Save</SubmitButton>
                    <Link button to={PlatformRoutesConfiguration.messagingRoute?.root.generatePath()}>
                        Cancel
                    </Link>
                </ButtonContainer>
                <FormErrorList />
            </Form>
            <BackofficeDeeplink
                preLinkMessage="To edit the notification period for reminders, click"
                path="Messaging"
                periodAfterLink
                topMargin
            />

            <SuccessModal show={hasUpdated} onClose={resetUpdateSettings}>
                Messaging settings saved successfully
            </SuccessModal>
        </PageSection>
    );
};
