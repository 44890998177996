export default {
    platformName: '[Platform Name]',
    loginHeader: 'Log on',
    loginSubheader: '',
    loginText: '',
    footerText: '',
    supportPhoneNumber: '',
    supportPhoneLink: 'tel:+61',
    supportEmail: '',
};
