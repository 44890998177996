import { userRoles } from 'components/Routing';
import { HomePage, ShoppingCart, CheckoutAddress, CheckoutReview, OrderReceived, Orders, OrderDetails } from 'components/StationeryShop';

const defaultRoles = [userRoles.stationeryShop];


export const stationeryRoute = {
    root: {
        path:               '/stationery-shop',
        element:  <HomePage />,
        generatePath: () => '/stationery-shop',
        title: 'EFTPOS Stationery shop',
        roles:              defaultRoles
    },
    homePage: {
        path:               '/stationery-shop/home',
        element:  <HomePage />,
        generatePath: () => '/stationery-shop/home',
        title: 'EFTPOS Stationery shop',
        roles:              defaultRoles
    },
    shoppingCart: {
        path:               '/stationery-shop/cart',
        element:  <ShoppingCart />,
        generatePath: () => '/stationery-shop/cart',
        roles:              defaultRoles
    },
    checkoutAddress: {
        path:               '/stationery-shop/cart/checkout-address',
        element:  <CheckoutAddress />,
        generatePath: () => '/stationery-shop/cart/checkout-address',
        roles:              defaultRoles
    },
    checkoutReview: {
        path:               '/stationery-shop/cart/checkout-review',
        element:  <CheckoutReview />,
        generatePath: () => '/stationery-shop/cart/checkout-review',
        roles:              defaultRoles
    },
    orderReceived: {
        path:               '/stationery-shop/cart/order-received',
        element:  <OrderReceived />,
        generatePath: () => '/stationery-shop/cart/order-received',
        roles:              defaultRoles
    },
    orders: {
        path:               '/stationery-shop/orders',
        element:  <Orders />,
        generatePath: () => '/stationery-shop/orders',
        roles:              defaultRoles
    },
    orderDetails: {
        path:               '/stationery-shop/orders/:id',
        element:  <OrderDetails />,
        generatePath: (id: number) => `/stationery-shop/orders/${id}`,
        roles:              defaultRoles
    },
    //checkout success add
    termsAndConditions: {
        path:               'https://www.commbank.com.au/business/pds/customer_merchant_agreement.pdf',
        element:  <></>,
        generatePath: () => 'https://www.commbank.com.au/business/pds/customer_merchant_agreement.pdf',
        roles:              defaultRoles
    },
};