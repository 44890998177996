import { PaymentRequestAction } from "platforms/base/constants/billpay";

export function isAmountRequired(action: number) {
    if (!action) {
        return true;
    }

    return [PaymentRequestAction.PaymentOnly,
        PaymentRequestAction.PreAuthOnly,
        PaymentRequestAction.PaymentAndTokenise,
        PaymentRequestAction.PreAuthAndTokenise,
        PaymentRequestAction.PaymentAndUpdateToken,
        PaymentRequestAction.PreAuthAndUpdateToken].includes(action);
}
