import { useMemo, useState } from "react";
import { Field, FormikProvider, useFormik } from "formik";
import { FormGroup, FormikDropdown } from "packages/formik-ui";
import { Option } from "packages/formik-ui/src/FormikDropdown";
import { Button, Dialog } from "packages/ui";
import * as Yup from "yup";
import { FormError, useApiCall } from "components/Common";
import { declineManagerApi } from "api";
import { DeclineCodeActionType } from "api/declineManagerApi";

type Props = {
    show: boolean;
    merchantNumber: string;
    type: DeclineCodeActionType;
    onClose: (showSuccess: boolean) => void;
}

type FormValues = {
    actionId: number;
    finalTrayId: number;
};

const ManageActionsQuickConfigDialog = ({ show, merchantNumber, type, onClose }: Props) => {
    const [errors, setErrors] = useState<string[]>([]);

    const [actions] = useApiCall(() => {
        return declineManagerApi.getRetryActions();
    }, []);

    const [trays] = useApiCall(() => {
        return declineManagerApi.retrieveMerchantTrays(merchantNumber)
    }, [merchantNumber]);

    const retryActionOptions = useMemo<Option[]>(() => {
        if (!actions) {
            return [];
        }

        return actions.map(a => ({
            label: a.actionName ?? "",
            value: a.actionId ?? 0,
        }));
    }, [actions]);

    const trayOptions = useMemo<Option[]>(() => {
        if (!trays) {
            return [];
        }

        return trays
            .filter(t => t.trayType !== "Resolved")
            .map(t => ({
                label: t.nickname ?? "",
                value: t.trayID ?? 0,
            }));
    }, [trays]);

    const formik = useFormik<FormValues>({
        initialValues: {
            actionId: 0,
            finalTrayId: 0,
        },
        validationSchema: Yup.object().shape({
            actionId: Yup.number().required(),
            finalTrayId: Yup.number().required(),
        }),
        onSubmit: async (formValues: FormValues) => {
            try {
                await declineManagerApi.updateAllActions(merchantNumber, formValues.actionId, formValues.finalTrayId, type);
                handleClose(true);
            } catch {
                setErrors(["An error occurred while updating the actions."]);
            }
        },
    });

    const handleClose = (showSuccess: boolean) => {
        formik.resetForm();
        setErrors([]);
        onClose(showSuccess);
    }

    return (<Dialog
        title="Quick configuration"
        show={show}
        onClose={() => handleClose(false)}
        footerButtons={<>
            <Button onClick={formik.submitForm} disabled={formik.isSubmitting} primary>Apply and save changes</Button>
            <Button onClick={() => handleClose(false)}>Cancel</Button>
        </>}
        closeButton
    >
        <p>Apply rules to all decline codes.</p>
        <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit}>
                <FormGroup name="actionId" label="Action">
                    <Field name="actionId" as={FormikDropdown} options={retryActionOptions} />
                </FormGroup>
                <FormGroup name="finalTrayId" label="Final tray">
                    <Field name="finalTrayId" as={FormikDropdown} options={trayOptions} />
                </FormGroup>
            </form>
        </FormikProvider>
        <FormError errors={errors} />
    </Dialog>);
};

export default ManageActionsQuickConfigDialog;
