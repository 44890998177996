import React from 'react';
import PropTypes from 'prop-types';

import { Form, RadioField } from '@premier/form';
import { Modal, Button} from '@premier/ui';

import ExportTransactionButton from './ExportTransactionsButton';

const ExportTransactionsModal = ({
    showModal, //logic render
    closeModal, //function
    filter //data
}) => {

    const initialValues = {
        format: 'standard'
    }

    return (<>
        <Modal show={showModal} onHide={closeModal} className='export-transactions-modal'>
            <Modal.Header closeButton>
                <Modal.Title>
                    Export transactions
                </Modal.Title>
            </Modal.Header>

            <Form initialValues={initialValues} render={(context) => <>
                <Modal.Body>
                    <RadioField name='format' label='Choose a format to export' options={[
                            { label: 'Standard', value: 'standard' },
                            { label: 'Extended', value: 'extended' },
                        ]} />
                </Modal.Body>

                <Modal.Footer buttons>
                    <ExportTransactionButton filter={filter} format={context.values['format']} onExported={() => { closeModal() }}>Export</ExportTransactionButton>
                    <Button onClick={() => { closeModal() }}>Cancel</Button>
                </Modal.Footer>
            </>} />

        </Modal>
    </>);
};

ExportTransactionsModal.propTypes = {
    showModal: PropTypes.bool,
    closeModal: PropTypes.func,
    filter: PropTypes.object,
};

export default ExportTransactionsModal;