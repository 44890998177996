// @ts-ignore
import labels from "constants/labels";

import { CurrencyField, Form, InputField, DatePickerField, BankAccountForm } from "@premier/form";
import { PageSection, SingleColumnFormContainer } from "@premier/ui";

/** Sub-form used for a user to change contact details. Used for the contact us page */
const FeesChargedForm = () => {
    return (
        <PageSection>
            <Form name="feesChargedForm"
                initialValues={{
                    feesAmount: "",
                    bankAccountDetails: {},
                    dateRange: {},
                    numberOfTransactions: ""
                }}>
                <SingleColumnFormContainer>
                    <h2>Provide details of fees charged below</h2>
                    <CurrencyField name="feesAmount" label={labels.amount} />
                    <BankAccountForm required={false} name="bankAccountDetails" label={labels.bankAccountDetails} />
                    <DatePickerField
                        label={labels.dateRange}
                        name="dateRange"
                        mode="range"
                    />
                    <InputField name="feeType" label="Fee type" placeholder="Value" />
                </SingleColumnFormContainer>
            </Form>
        </PageSection>
    );
};

export default FeesChargedForm;
