import { useSelector } from "react-redux";
import { Tooltip, TooltipTrigger, Icon, LoadingIndicator } from "@premier/ui";
import { CheckboxField } from "@premier/form";
import { RootState } from "store/store";
import "./MessagingConfigOptionFields.scss";

const settingsSelector = (state: RootState) => state.settings.messagingSettings;

type Props = {
    label: string;
    name: string;
    links?: string;
}

export const MessagingConfigOptionFields = ({
    label,
    name,
    links
} : Props) => {
    const { hasLoaded, data } = useSelector(settingsSelector);

    if (!hasLoaded)
        return <LoadingIndicator />;

    if (!data[name])
        return null;

    return <div className="messaging-config-options">
        <div className="label">
            {label}
        </div>
        <div className="content">
            <CheckboxField name={`${name}.emailEnabled`} disabled={!data.emailEnabled}>Email</CheckboxField>
            <CheckboxField name={`${name}.smsEnabled`} disabled={!data.smsEnabled}>
                SMS
                {!data.smsEnabled && <TooltipTrigger tipId="tip-cvn" style={{ fontSize: "1.25rem" }}>
                    <Icon question />
                </TooltipTrigger>}
            </CheckboxField>
            {links && <div>{links}</div>}
        </div>
        <Tooltip id="tip-cvn">
            To enable SMS, please contact your bank representative to upgrade.
        </Tooltip>
    </div>;
};