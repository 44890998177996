import React from 'react';

import dateUtil from '@premier/utils/date';
import { ResponsiveTable, Link } from '@premier/ui';
import { PlatformRoutesConfiguration } from 'components/Routing';

import './NoticeTable.scss';

/** Table to display live notices. Shows on the home page. */
const NoticeTable = ({
    notices //data
}) => {
    var noticesData = (notices || []).map(n => ({
        ...n,
        dateStr: dateUtil.convertToDateString(n.updated),
        subjectText: <Link to={PlatformRoutesConfiguration.accountRoute.noticeDetails.generatePath(n.noticeId)}>{n.subject}</Link>
    }));

    return (
        <ResponsiveTable className='notices'
            data={noticesData}
            columns={[
                { label: 'Date', getter: 'dateStr', className: 'date' },
                { label: 'Subject', getter: 'subjectText' }
            ]}
            renderCard={(listItems, link, index) => (
                <div className='notice-item' key={index}>
                    {listItems.map((item,idx) => (
                        <div className={item.className} key={idx}>{item.value}</div>
                    ))}
                </div>
            )}
        />
    );
};

export default NoticeTable;
