import _ from 'lodash';
import * as commonActions from 'components/Common/_actions/actionTypes';
import * as accountActions from 'components/Account/_actions/actionTypes';
import * as designerActions from 'components/Settings/_actions/actionTypes';

// Define actions that should block the whole UI, and optionally the loading-text to show
export var blockingActions = [
    // Old actions
    {type: accountActions.LOGIN_USER_REQUESTED, text: 'Logging on...'},
    {type: accountActions.LOGOUT_USER_REQUESTED, text: 'Logging off...'},

    // New actions
    {type: designerActions.SET_BRANDING_BY_BILLER.REQUESTED, text: 'Applying changes...'},
];

export default function appReducer(state = {}, action) {

    if (action.type === commonActions.BLOCK_UI) {
        return {...state,
            blockUi: action.text || 'Loading...'
        };
    }
    else if (action.type === commonActions.UNBLOCK_UI) {
        return {...state,
            blockUi: false
        };
    }
    else if (action.type === commonActions.PATH_CHANGE) {
        let oldHistory = state.history;
        return Object.assign({}, state, {
            history: { previousPath: oldHistory?.currentPath, currentPath: action.currentPath }
        });
    }
    else {
        let reqType = action.type.replace(/_(SUCCESS|FAILED)$/, '_REQUESTED');  // the _REQUESTED version of the action.type
        let blockingAction = _.find(blockingActions, {type: reqType});

        if(blockingAction) {
            if (action.type.endsWith('_REQUESTED')) {
                return Object.assign({}, state, {
                    blockUi: blockingAction.text || 'Loading...'
                });
            }
            else if (action.type.endsWith('_SUCCESS') || action.type.endsWith('_FAILED')) {
                return Object.assign({}, state, {
                    blockUi: false
                });
            }
        }
    }

    return state;
}
