import { useState, useMemo } from "react";
import { Field, Form, Formik } from "formik";
import { FormGroup, FormikSwitch, FormikDropdown } from "@linkly/formik-ui";
import { Button, Dialog, ErrorText, Row, SingleColumnFormContainer, LoadingIndicator, SuccessModal } from "@premier/ui";
import labels from "platforms/current/constants/labels";
import { ProductTooltip } from 'components/Common';
import { useApiCall, APICallRequestState } from "components/Common";
import { accountApi } from "api";
import { Merchant } from 'models/Merchant';
import { RootState } from "store/store";
import { connect } from "react-redux";

type Props = {
    show: boolean;
    onClose: () => void;
    merchant: Merchant;
};

type FormFields = {
    enableMFA?: boolean;
    timeout?: number;
};

export const EditMFADialog = ({
    show, //logic renders
    onClose, //functions
    merchant
}: Props) => {
    const [submitErrors, setSubmitErrors] = useState<string[]>([]);
    const [showSuccess, setShowSuccess] = useState<boolean>(false);
    const [MFAConfiguration, MFAConfigurationStatus] = useApiCall(() => {
        if (show && merchant.merchantNumber) {
            return accountApi.getMFAConfiguration(merchant.merchantNumber);

        }
    }, [show]);

    const doClose = () => {
        setSubmitErrors([]);
        onClose();
    };

    const getTimeoutOptions = (options: (number | null)[]) => {
        return options.map((item: number | null) => ({
            value: item,
            label: item == null ? "Not set (MFA always required)" : item / 60
        }));
    };

    const timeoutOptions = useMemo(() => {
        if (MFAConfigurationStatus === APICallRequestState.SUCCESSFUL && MFAConfiguration?.timeoutMinsSelection) {
            return getTimeoutOptions(MFAConfiguration?.timeoutMinsSelection);
        }
    }, [MFAConfiguration?.timeoutMinsSelection, MFAConfigurationStatus]);

    const handleSubmit = async (formValues: FormFields) => {
        try {
            const response = await accountApi.updateMFAConfiguration({
                isEnabled: formValues.enableMFA,
                timeoutMins: formValues.timeout,
            });

            if (response?.status !== 200 || response?.data?.errors?.length) {
                throw response;
            } else {
                setShowSuccess(true);
                setSubmitErrors([]);
            }
        } catch (response: any) {
            if (response?.data?.errors?.length) {
                setSubmitErrors(response.data.errors.map((r: any) => r.message));
            } else {
                setSubmitErrors([labels.unknownErrorMessage]);
            }

            return false;
        }

        return true;
    };

    return (
        <>
            {MFAConfigurationStatus === APICallRequestState.SUCCESSFUL ?
                <Dialog title="Edit Multi-Factor Authentication Settings" show={show} onClose={onClose} closeButton>

                    <Formik
                        initialValues={{
                            enableMFA: MFAConfiguration?.isEnabled,
                            timeout: MFAConfiguration?.timeoutMins,
                        }}
                        onSubmit={async (formValues, { setSubmitting }) => {
                            const success = await handleSubmit(formValues);
                            setSubmitting(false);

                            if (success) {
                                doClose();
                            }
                        }}
                    >
                        {(props) => (
                            <Form>
                                <SingleColumnFormContainer>
                                    <Row>
                                        <FormGroup label={<>{'Enable Multi-Factor Authentication'}<ProductTooltip productTooltipModule={"MULTI_FACTOR_AUTHENTICATION_ENABLE"} /></>} className="col-sm-12" name="enableMFA">
                                            <Field as={FormikSwitch} name="enableMFA" disabled={!MFAConfiguration?.allowDisablingMFA} />
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup label={<>{'Timeout (hours)'}<ProductTooltip productTooltipModule={"MULTI_FACTOR_AUTHENTICATION_TIMEOUT"} /></>} className="col-sm-12" name="MFAtimeout">
                                            <Field as={FormikDropdown} name="timeout" options={timeoutOptions} />
                                        </FormGroup>
                                    </Row>
                                    <div className="dialog-actions">
                                        <Button type="submit" primary>
                                            Save
                                        </Button>
                                        <Button onClick={doClose}>Cancel</Button>
                                    </div>
                                    {submitErrors.length
                                        ? submitErrors.map((errorMessage, i) => <ErrorText key={i}>{errorMessage}</ErrorText>)
                                        : null}
                                </SingleColumnFormContainer>
                            </Form>
                        )}
                    </Formik>
                </Dialog>
                : <LoadingIndicator />}
            <SuccessModal
                show={showSuccess}
                onClose={() => {
                    setShowSuccess(false);
                }}
            >
                Multi-Factor Authentication settings saved successfully
            </SuccessModal>
        </>

    );
};

function mapStateToProps(state: RootState) {

    return {
        merchant: state.accounts.users.merchant
    };
}

export default connect(mapStateToProps, null)(EditMFADialog);
