import { config } from './util';
import {
    CredentialOnFileApiFactory,
    CredentialOnFileModel
} from '@premier/webapi-client';

const credentialOnFileApi = CredentialOnFileApiFactory(config);

class CredentialOnFileApi {
    static async updateCredentialOnFile(cofModel : CredentialOnFileModel) {
        return await credentialOnFileApi.credentialOnFileUpdateConfiguration(cofModel);
    }

    static async getCredentialOnFile() {
        return await credentialOnFileApi.credentialOnFileGetConfiguration();
    }
}

export default CredentialOnFileApi;
