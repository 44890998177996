import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import regexUtil from '@premier/utils/regEx';
import { ColourInput } from '@premier/ui';
import FormContext from '../FormContext';
import { validate } from '../validation/fieldValidation';
import FormGroup from './FormGroup';
import withField from './withField';

/** A colour input field */
const ColourField = ({
    name, onChange,
    formProps, groupProps, ...otherProps
}) => {

    const { value, error, setValue } = formProps;
    const {setValidation, getValidation} = useContext(FormContext);

    React.useEffect(() => {
        if (!getValidation(name)) {
            setValidation(
                name,
                validate().test(value => regexUtil.colourHexRegex.test(value), 'Invalid hex colour')
            );
        }
        return () => {
            setValidation(name, undefined);
        }
    },[]);


    function handleChange(val) {
        setValue(val);

        if (onChange)
            onChange(val);
    }

    const [id] = useState(_.uniqueId(`${name}_`));

    return (
        <FormGroup fieldId={id} {...groupProps}>
            <ColourInput
                name={name}
                id={id}
                value={value}
                error={error}
                onChange={handleChange}
                aria-labelledby={groupProps.label && `${id}_label`}
                aria-required={groupProps.mandatory}
                aria-invalid={!!formProps.error}
                {...otherProps}
            />
        </FormGroup>
    );
};

ColourField.propTypes = {
    placeholder: PropTypes.string,

    onChange: PropTypes.func,
    onFocus: PropTypes.func
};

export default withField(ColourField);
