import React, { useState, useEffect } from 'react';
import { Input, FormGroup, FormikDropdown, FormikRadioButton, FormikSwitch } from "@linkly/formik-ui";
import { connect } from 'react-redux';
import { PageSection, PaddedContainer, Button, Divider, Row, DescriptionList, LoadingIndicator } from '@premier/ui';
import { PageHeader, useApiCallDeprecated, APICallRequestState } from 'components/Common';
import './EditCustomerReferencesPage.scss';
import { Formik, Form, Field, FormikProps } from 'formik';
import deleteSvg from 'images/icon-control-delete-24.svg';
import { billerApi } from 'api';
import { SuccessModal, ErrorText } from '@premier/ui';
import * as Yup from 'yup';
import labels from 'platforms/current/constants/labels';

interface Props {
    billerCode: any;
}

interface checkSumOptions {
    checkSumId: number,
    checkSumName: string
}

interface crnNameOptions {
    crnLibraryId: number,
    crnName: string
}

enum PREFIX_SURFFIX_TYPE {
    RANGE = "range",
    SPECIFY = "specify"
};

enum TOGGLE_TYPE {
    PREFIX = "Prefix",
    SUFFIX = "Suffix",
    CHECKSUM = "Checksum algorithm"
};

// length options range from 1 to 50
const lengthOptionsFromOne = Array.from({ length: 50 }, (_, index) => ({
    value: index + 1,
    label: String(index + 1),
}));

// length options range from 0 to 49
const lengthOptionsFromZero = Array.from({ length: 50 }, (_, index) => ({
    value: index,
    label: String(index),
}));


export const EditCustomerReferencesPage: React.FC<Props> = ({
    billerCode
}) => {
    const [submitErrors, setSubmitErrors] = useState<string[]>([]);
    const [initialCRNCount, setInitialCRNCount] = useState<number>(0);
    const [crnNameOptions, setCrnNameOptions] = useState<any>([]);
    const [showSuccess, setShowSuccess] = useState<boolean>(false);
    const [currentCRNCount, setCurrentCRNCount] = useState<number>(0);

    const [biller, billerStatus] = useApiCallDeprecated(() => {
        // @ts-ignore
        return billerApi.getBillerDetails(billerCode)
    }, [billerCode, showSuccess])

    useEffect(() => {
        if (biller) {
            if (biller.acceptedCrn3) {
                setInitialCRNCount(3);
                setCurrentCRNCount(3);
            } else if (biller.acceptedCrn2) {
                setInitialCRNCount(2);
                setCurrentCRNCount(2);
            } else if (biller.acceptedCrn1) {
                setInitialCRNCount(1);
                setCurrentCRNCount(1);
            } else {
                setInitialCRNCount(0);
                setCurrentCRNCount(0);
            }
            setCrnNameOptions(biller.crnNameList.map((item: crnNameOptions) => ({
                value: item.crnLibraryId,
                label: item.crnName
            })));
        }
    }, [biller])

    const getValidationSchema = (count: number) => {
        return Yup.object().shape({
            biller: Yup.object().shape({
                acceptedCrn1: ValidationSchema,
                ...(count >= 2 && { acceptedCrn2: ValidationSchema }),
                ...(count >= 3 && { acceptedCrn3: ValidationSchema }),
            }),
        });
    }

    const handleDeleteCustomerReference = (num: number, props: any) => {
        // make sure CurrentCRNCount set before update Formik value, to avoid field undefined error
        Promise.resolve()
            .then(() => { setCurrentCRNCount(num - 1); })
            .then(() => {
                const deletedValues = props.values;
                delete deletedValues.biller[`acceptedCrn${num}`];
                props.setValues(deletedValues);
            });
    }

    const handleNewCustomerReference = (num: number, props: any) => {
        setCurrentCRNCount(num + 1);
        props.setFieldValue(`biller.acceptedCrn${num + 1}`, {
            isActive: true,
            number: num + 1,
            crnReferenceId: crnNameOptions ? crnNameOptions[0].value : null,
            minLength: 1,
            maxLength: 1,
            prefixRangeStart: '',
            prefixRangeEnd: '',
            prefixSpecify: '',
            suffixRangeStart: '',
            suffixRangeEnd: '',
            suffixSpecify: '',
        }, false);
    }

    const handleRadioButtonClick = (props: any, fieldNames: string[]) => {
        fieldNames.forEach((x) => { props.setFieldValue(x, null); })
    }

    const handleToggleButtonClick = (num: any, props: any, fieldName: TOGGLE_TYPE) => {
        switch (fieldName) {
            case TOGGLE_TYPE.PREFIX: {
                //if isPrefixActive switch from true to false
                if (props.values.biller[`acceptedCrn${num}`].isPrefixActive) {
                    props.setFieldValue(`biller.acceptedCrn${num}.prefixRangeEnd`, null);
                    props.setFieldValue(`biller.acceptedCrn${num}.prefixRangeStart`, null);
                    props.setFieldValue(`biller.acceptedCrn${num}.prefixSpecify`, null);
                    props.setFieldValue(`biller.acceptedCrn${num}.isPrefixRangeEnabled`, true);
                } else {
                    props.setFieldValue(`biller.acceptedCrn${num}.prefixType`, PREFIX_SURFFIX_TYPE.RANGE);
                }
                break;
            }
            case TOGGLE_TYPE.SUFFIX: {
                //if isPrefixActive switch from true to false
                if (props.values.biller[`acceptedCrn${num}`].isSuffixActive) {
                    props.setFieldValue(`biller.acceptedCrn${num}.suffixRangeEnd`, null);
                    props.setFieldValue(`biller.acceptedCrn${num}.suffixRangeStart`, null);
                    props.setFieldValue(`biller.acceptedCrn${num}.suffixSpecify`, null);
                    props.setFieldValue(`biller.acceptedCrn${num}.isSuffixRangeEnabled`, true);
                } else {
                    props.setFieldValue(`biller.acceptedCrn${num}.suffixType`, PREFIX_SURFFIX_TYPE.RANGE);
                }
                break;
            }
            case TOGGLE_TYPE.CHECKSUM: {
                //if isPrefixActive switch from true to false
                if (props.values.biller[`acceptedCrn${num}`].isChecksumAlgoActive) {
                    props.setFieldValue(`biller.acceptedCrn${num}.xAdd`, null);
                    props.setFieldValue(`biller.acceptedCrn${num}.xFirst`, null);
                    props.setFieldValue(`biller.acceptedCrn${num}.xLast`, null);
                    props.setFieldValue(`biller.acceptedCrn${num}.xAddDisplay`, null);
                    props.setFieldValue(`biller.acceptedCrn${num}.xFirstDisplay`, null);
                    props.setFieldValue(`biller.acceptedCrn${num}.xLastDisplay`, null);
                    props.setFieldValue(`biller.acceptedCrn${num}.crnCheckSum`, null);
                } else {
                    props.setFieldValue(`biller.acceptedCrn${num}.crnCheckSum`, 0);
                }
                break;
            }
        }
    }

    const getAcceptedCrnInitialValues = (acceptedCrn: any) => {
        if (!acceptedCrn) return null;

        return {
            ...acceptedCrn,
            // have to assign initial value to those fields for validation if the field is not touched
            prefixRangeStart: acceptedCrn?.prefixRangeStart ?? '',
            prefixRangeEnd: acceptedCrn?.prefixRangeEnd ?? '',
            prefixSpecify: acceptedCrn?.prefixSpecify ?? '',
            suffixRangeStart: acceptedCrn?.suffixRangeStart ?? '',
            suffixRangeEnd: acceptedCrn?.suffixRangeEnd ?? '',
            suffixSpecify: acceptedCrn?.suffixSpecify ?? '',
            prefixType: acceptedCrn?.isPrefixRangeEnabled ? PREFIX_SURFFIX_TYPE.RANGE : PREFIX_SURFFIX_TYPE.SPECIFY,
            suffixType: acceptedCrn?.isSuffixRangeEnabled ? PREFIX_SURFFIX_TYPE.RANGE : PREFIX_SURFFIX_TYPE.SPECIFY,
            crnReferenceId: biller.crnNameList.find((item: any) => item.crnName === acceptedCrn.crnName)?.crnLibraryId ?? biller.crnNameList.find((item: any) => item.crnName === acceptedCrn.crnName + " [T]")?.crnLibraryId,
        };
    };

    // Minimum length should have range from 1 to 50 for Customer Reference 1
    // Minimum length should have range from 0 to 49 for Customer Reference 2 and Customer Reference 2(optional field)
    // Maximum length should have range from 1 to 50 for all Customer References
    const getDescriptionList = (num: number, props?: any) => {
        const items = [[
            {
                name: 'Name:', value: <FormGroup name={`biller.acceptedCrn${num}.crnReferenceId`}>
                    <Field
                        as={FormikDropdown}
                        name={`biller.acceptedCrn${num}.crnReferenceId`}
                        options={crnNameOptions}
                    />
                </FormGroup>, id: 'Name' + String(num)
            },
            {
                name: 'Prefix:', value:

                    <FormGroup name={`biller.acceptedCrn${num}.isPrefixActive`} >
                        <Field
                            as={FormikSwitch}
                            handleSwitchChange={() => handleToggleButtonClick(num, props, TOGGLE_TYPE.PREFIX)}
                            name={`biller.acceptedCrn${num}.isPrefixActive`}
                        />
                    </FormGroup>, id: 'Prefix' + String(num)
            },
        ],
        [
            {
                name: 'Minimum length:', value: <FormGroup name={`biller.acceptedCrn${num}.minLength`}>
                    <Field
                        as={FormikDropdown}
                        name={`biller.acceptedCrn${num}.minLength`}
                        options={num === 1 ? lengthOptionsFromOne : lengthOptionsFromZero}

                    />
                </FormGroup>, id: 'MinimumLength' + String(num)
            },
            {
                name: 'Suffix:', value: <FormGroup name={`biller.acceptedCrn${num}.isSuffixActive`} >
                    <Field
                        as={FormikSwitch}
                        handleSwitchChange={() => handleToggleButtonClick(num, props, TOGGLE_TYPE.SUFFIX)}
                        name={`biller.acceptedCrn${num}.isSuffixActive`}
                    />
                </FormGroup>, id: 'Suffix' + String(num)
            },
        ], [
            {
                name: 'Maximum length:', value: <FormGroup name={`biller.acceptedCrn${num}.maxLength`}>
                    <Field
                        as={FormikDropdown}
                        name={`biller.acceptedCrn${num}.maxLength`}
                        options={lengthOptionsFromOne}
                    />
                </FormGroup>, id: 'MaximumLength' + String(num)
            },
            {
                name: 'Checksum algorithm:', value: <FormGroup name={`biller.acceptedCrn${num}.isChecksumAlgoActive`}  >
                    <Field
                        as={FormikSwitch}
                        handleSwitchChange={() => handleToggleButtonClick(num, props, TOGGLE_TYPE.CHECKSUM)}
                        name={`biller.acceptedCrn${num}.isChecksumAlgoActive`}
                    />
                </FormGroup>, id: 'Checksum' + String(num)
            },
        ]];

        return items;
    }

    function getCrn(num: number, props: any): any | undefined {
        if (num === 1) {
            return props.values.biller.acceptedCrn1 ?? null;
        }
        if (num === 2) {
            return props.values.biller.acceptedCrn2 ?? null;
        }
        if (num === 3) {
            return props.values.biller.acceptedCrn3 ?? null;
        }
    }

    const checkSumOptions = biller?.acceptedCrn1?.checkSumList?.map((item: checkSumOptions) => ({
        value: item.checkSumId,
        label: item.checkSumName
    }));

    function getCustomerReferenceEditElement(props: any) {
        return [1, 2, 3].map((num) => {
            var crn = getCrn(num, props);
            if (crn && crn.isActive) {
                return (
                    <>
                        <dt className='reference-title'>Customer reference {num}</dt>
                        <Row>
                            <div className='col-lg-4 reference-items'>
                                <DescriptionList className='edit-description-list' showBlankName inlineXs items={getDescriptionList(num, props)[0]} />

                                {props.values.biller[`acceptedCrn${num}`].isPrefixActive &&
                                    <div className='set-thresholds-form'>
                                        <Row>
                                            <FormGroup name='RangeOptionButton' className='col-sm-4'>
                                                <Field as={FormikRadioButton}
                                                    onClick={() => handleRadioButtonClick(props, [`biller.acceptedCrn${num}.prefixSpecify`])}
                                                    id={`prefixRangeOptionButton${num}`} name={`biller.acceptedCrn${num}.prefixType`} label="Range" value={PREFIX_SURFFIX_TYPE.RANGE} />
                                            </FormGroup>
                                        </Row>
                                        <Row>
                                            <FormGroup name={`prefixRangeStartLabel`} className='col-sm-1 range-input-field-text-from'>
                                                <div>From</div>
                                            </FormGroup>
                                            <FormGroup name={`biller.acceptedCrn${num}.prefixRangeStart`} className='col-sm-5'>
                                                <Field as={Input} type='number' name={`biller.acceptedCrn${num}.prefixRangeStart`} disabled={props.values.biller[`acceptedCrn${num}`].prefixType === PREFIX_SURFFIX_TYPE.SPECIFY} />
                                            </FormGroup>
                                            <FormGroup name={`prefixRangeEndLabel`} className='col-sm-1 range-input-field-text-to'>
                                                <div>to</div>
                                            </FormGroup>
                                            <FormGroup name={`biller.acceptedCrn${num}.prefixRangeEnd`} className='col-sm-5'>
                                                <Field as={Input} type='number' name={`biller.acceptedCrn${num}.prefixRangeEnd`} disabled={props.values.biller[`acceptedCrn${num}`].prefixType === PREFIX_SURFFIX_TYPE.SPECIFY} />
                                            </FormGroup>
                                        </Row>

                                        <div className='specify-radio'>
                                            <Row>
                                                <FormGroup name="SpecifyOptionButton" className='col-sm-4'>
                                                    <Field as={FormikRadioButton}
                                                        onClick={() => handleRadioButtonClick(props, [`biller.acceptedCrn${num}.prefixRangeStart`, `biller.acceptedCrn${num}.prefixRangeEnd`])}
                                                        id={`prefixSpecifyOptionButton${num}`} name={`biller.acceptedCrn${num}.prefixType`} label="Specify" value={PREFIX_SURFFIX_TYPE.SPECIFY} />
                                                </FormGroup>
                                            </Row>
                                            <Row>
                                                <FormGroup name={`biller.acceptedCrn${num}.prefixSpecify`} className='col-sm-12'>
                                                    <Field as={Input} name={`biller.acceptedCrn${num}.prefixSpecify`} disabled={props.values.biller[`acceptedCrn${num}`].prefixType === PREFIX_SURFFIX_TYPE.RANGE} maxlength={49} />
                                                </FormGroup>
                                            </Row>
                                        </div>
                                    </div>
                                }
                            </div>

                            <div className='col-lg-4 reference-items'>
                                <DescriptionList showBlankName inlineXs items={getDescriptionList(num, props)[1]} />
                                {props.values.biller[`acceptedCrn${num}`].isSuffixActive &&
                                    <div className='set-thresholds-form'>
                                        <Row>
                                            <FormGroup name={`biller.acceptedCrn${num}.suffixType`} className='col-sm-5'>
                                                <Field as={FormikRadioButton}
                                                    onClick={() => handleRadioButtonClick(props, [`biller.acceptedCrn${num}.suffixSpecify`])}

                                                    id={`suffixRangeOptionButton${num}`} name={`biller.acceptedCrn${num}.suffixType`} label="Range" value={PREFIX_SURFFIX_TYPE.RANGE} />
                                            </FormGroup>
                                        </Row>
                                        <Row>
                                            <FormGroup name={`suffixRangeStartLabel`} className='col-sm-1 range-input-field-text-from'>
                                                <div>From</div>
                                            </FormGroup>
                                            <FormGroup name={`biller.acceptedCrn${num}.suffixRangeStart`} className='col-sm-5'>
                                                <Field as={Input} type='number' name={`biller.acceptedCrn${num}.suffixRangeStart`} disabled={props.values.biller[`acceptedCrn${num}`].suffixType === PREFIX_SURFFIX_TYPE.SPECIFY} />
                                            </FormGroup>
                                            <FormGroup name={`suffixRangeEndLabel`} className='col-sm-1 range-input-field-text-to'>
                                                <div>to</div>
                                            </FormGroup>
                                            <FormGroup name={`biller.acceptedCrn${num}.suffixRangeEnd`} className='col-sm-5'>
                                                <Field as={Input} type='number' name={`biller.acceptedCrn${num}.suffixRangeEnd`} disabled={props.values.biller[`acceptedCrn${num}`].suffixType === PREFIX_SURFFIX_TYPE.SPECIFY} />
                                            </FormGroup>
                                        </Row>

                                        <div className='specify-radio'>
                                            <Row>
                                                <FormGroup name="SpecifyOptionButton" className='col-sm-4'>
                                                    <Field as={FormikRadioButton}
                                                        onClick={() => handleRadioButtonClick(props, [`biller.acceptedCrn${num}.suffixRangeStart`, `biller.acceptedCrn${num}.suffixRangeEnd`])}
                                                        id={`suffixSpecifyOptionButton${num}`} name={`biller.acceptedCrn${num}.suffixType`} label="Specify" value={PREFIX_SURFFIX_TYPE.SPECIFY} />
                                                </FormGroup>
                                            </Row>
                                            <Row>
                                                <FormGroup name={`biller.acceptedCrn${num}.suffixSpecify`} className='col-sm-12'>
                                                    <Field as={Input} name={`biller.acceptedCrn${num}.suffixSpecify`} disabled={props.values.biller[`acceptedCrn${num}`].suffixType === PREFIX_SURFFIX_TYPE.RANGE} maxlength={49} />
                                                </FormGroup>
                                            </Row>
                                        </div>
                                    </div>}
                            </div>

                            <div className='col-lg-4 reference-items'>
                                <DescriptionList showBlankName inlineXs items={getDescriptionList(num, props)[2]} />
                                {props.values.biller[`acceptedCrn${num}`].isChecksumAlgoActive &&
                                    <div className='set-thresholds-form'>
                                        <Row>
                                            <FormGroup name='crnCheckSum' className='col-sm-10'>
                                                <Field as={FormikDropdown} name={`biller.acceptedCrn${num}.crnCheckSum`} options={checkSumOptions} />
                                            </FormGroup>
                                            <FormGroup label="xAdd:" name={`biller.acceptedCrn${num}.xAdd`} className='input-label-inline' >
                                                <Field as={Input} type='number' className='col-sm-6' name={`biller.acceptedCrn${num}.xAdd`} />
                                            </FormGroup>
                                            <FormGroup label="xFirst:" name={`biller.acceptedCrn${num}.xFirst`} className='input-label-inline' >
                                                <Field as={Input} type='number' className='col-sm-6' name={`biller.acceptedCrn${num}.xFirst`} />
                                            </FormGroup>
                                            <FormGroup label="xLast:" name={`biller.acceptedCrn${num}.xLast`} className='input-label-inline' >
                                                <Field as={Input} type='number' className='col-sm-6' name={`biller.acceptedCrn${num}.xLast`} />
                                            </FormGroup>
                                        </Row>
                                    </div>
                                }
                            </div>
                        </Row>

                        {!props.values.biller[`acceptedCrn${num + 1}`] && num !== 1 &&
                            <div className='delete-customer-reference-button' onClick={() => { handleDeleteCustomerReference(num, props) }}>
                                <img src={deleteSvg} alt='Delete item' />
                                <span >Delete customer reference</span>
                            </div>
                        }
                        <Divider className='edit-reference-divider' />

                        {!props.values.biller[`acceptedCrn${num + 1}`] && num !== 3 &&
                            <Row className='new-customer-reference-button'><Button onClick={() => handleNewCustomerReference(num, props)}>New customer reference</Button></Row>
                        }
                    </>);
            }
            return null;
        });

    }

    const generateDTO = (formValues: any) => {
        let dto = Object.values(formValues.biller).map((crn: any) => {
            if (crn === null) return null
            const {
                number,
                isActive,
                minLength,
                maxLength,
                isPrefixActive,
                prefixRangeStart,
                prefixRangeEnd,
                prefixSpecify,
                isSuffixActive,
                suffixRangeStart,
                suffixRangeEnd,
                suffixSpecify,
                isChecksumAlgoActive,
                xAdd,
                xFirst,
                xLast,
                crnCheckSum,
                crnReferenceId,
            } = crn;
            return {
                number,
                isActive,
                minLength,
                maxLength,
                crnReferenceId,
                isPrefixActive: isPrefixActive ?? false,
                checkSumList: biller.acceptedCrn1?.checkSumList ?? undefined,
                isPrefixRangeEnabled: crn.prefixType === PREFIX_SURFFIX_TYPE.RANGE,
                isSuffixRangeEnabled: crn.suffixType === PREFIX_SURFFIX_TYPE.RANGE,
                prefixRangeStart: prefixRangeStart !== undefined && prefixRangeStart !== null ? String(prefixRangeStart) : '',
                prefixRangeEnd: prefixRangeEnd !== undefined && prefixRangeEnd !== null ? String(prefixRangeEnd) : '',
                prefixSpecify: prefixSpecify && !prefixSpecify.endsWith(",") ? prefixSpecify + "," : prefixSpecify,
                isSuffixActive,
                suffixRangeStart,
                suffixRangeEnd,
                suffixSpecify: suffixSpecify && !suffixSpecify.endsWith(",") ? suffixSpecify + "," : suffixSpecify,
                isChecksumAlgoActive,
                xAddDisplay: xAdd !== undefined ? String(xAdd) : '',
                xFirstDisplay: xFirst !== undefined ? String(xFirst) : '',
                xLastDisplay: xLast !== undefined ? String(xLast) : '',
                xAdd,
                xFirst,
                xLast,
                crnCheckSum,
                crnName: biller.crnNameList.find((item: any) => item.crnLibraryId === crnReferenceId)?.crnName,
            }

        });
        if (initialCRNCount > Object.values(formValues.biller).length) {
            const missingCrnNumber = initialCRNCount - Object.values(formValues.biller).length;
            const missingCrnObjects = Array.from({ length: missingCrnNumber }, (_, index) => index + 1).map((number) => {
                return {
                    number: number + Object.values(formValues.biller).length,
                    isActive: false,
                };
            });
            //@ts-ignore
            dto = dto.concat(missingCrnObjects);
        }
        return dto;
    }

    const ValidationSchema = Yup.object().shape({
        prefixRangeStart: Yup.number().nullable().label('Prefix range from').when(['prefixType', 'isPrefixActive'], {
            is: (prefixType: PREFIX_SURFFIX_TYPE, isPrefixActive: boolean) => prefixType === PREFIX_SURFFIX_TYPE.RANGE && isPrefixActive,
            then: Yup.number().nullable().required('Prefix range from is required').min(-2147483648)
            .max(2147483647),
        }),
        prefixRangeEnd: Yup.number()
            .nullable()
            .label('Prefix range to')
            .max(2147483647)
            .when(['prefixType', 'isPrefixActive'], {
                is: (prefixType: PREFIX_SURFFIX_TYPE, isPrefixActive: boolean) =>
                    prefixType === PREFIX_SURFFIX_TYPE.RANGE && isPrefixActive,
                then: Yup.number()
                    .nullable()
                    .required('Prefix range to is required')
                    .test('greater-than-or-equal-to-start', 'Invalid value', function (value) {
                        const prefixRangeStart = this.resolve(Yup.ref('prefixRangeStart'));
                        if (!prefixRangeStart || !value) return true;
                        //@ts-ignore
                        return parseInt(value, 10) >= parseInt(prefixRangeStart, 10);
                    }),
            }),
        prefixSpecify: Yup.string().nullable().when(['prefixType', 'isPrefixActive'], {
            is: (prefixType: PREFIX_SURFFIX_TYPE, isPrefixActive: boolean) => prefixType === PREFIX_SURFFIX_TYPE.SPECIFY && isPrefixActive,
            then: Yup.string().nullable().required('Prefix specify is required'),
        }),
        suffixRangeStart: Yup.number().nullable().label('Suffix range from').when(['suffixType', 'isSuffixActive'], {
            is: (suffixType: PREFIX_SURFFIX_TYPE, isSuffixActive: boolean) => suffixType === PREFIX_SURFFIX_TYPE.RANGE && isSuffixActive,
            then: Yup.number().nullable().required('Suffix range from is required').min(-2147483648).max(2147483647),
        }),
        suffixRangeEnd: Yup.number()
            .nullable()
            .label('Suffix range to')
            .max(2147483647)
            .when(['suffixType', 'isSuffixActive'], {
                is: (suffixType: PREFIX_SURFFIX_TYPE, isSuffixActive: boolean) =>
                    suffixType === PREFIX_SURFFIX_TYPE.RANGE && isSuffixActive,
                then: Yup.number().nullable().required('Suffix range to is required')
                    .test('greater-than-or-equal-to-start', 'Invalid value', function (value) {
                        const suffixRangeStart = this.resolve(Yup.ref('suffixRangeStart'));
                        if (!suffixRangeStart || !value) return true;
                        //@ts-ignore
                        return parseInt(value, 10) >= parseInt(suffixRangeStart, 10);
                    }),
            }),
        suffixSpecify: Yup.string().nullable().when(['suffixType', 'isSuffixActive'], {
            is: (suffixType: PREFIX_SURFFIX_TYPE, isSuffixActive: boolean) => suffixType === PREFIX_SURFFIX_TYPE.SPECIFY && isSuffixActive,
            then: Yup.string().nullable().required('Suffix specify is required'),
        }),
        minLength: Yup.number().min(0).max(50),
        maxLength: Yup.number()
            .label('Maximum length')
            .min(Yup.ref('minLength'))
            .max(50),
        xAdd: Yup.number().label('xAdd').min(-2147483647).max(2147483647),
        xFirst: Yup.number().label('xFirst').min(-2147483647).max(2147483647),
        xLast: Yup.number().label('xLast').min(-2147483647).max(2147483647),
    });

    const [loading, setLoading] = useState(false);

    const handleSubmit = async (formValues: any) => {
        const dto = generateDTO(formValues);
        try {
            setLoading(true);
            const response = await billerApi.updateCustomerReference({
                billerCode: billerCode,

                //@ts-ignore
                billerCRNList: dto,
            });
            if (response?.status !== 200 || response?.data?.errors?.length) {
                throw response;
            }
            setShowSuccess(true);
            setSubmitErrors([]);
        }  catch (response: any) {
            if (response?.data?.errors?.length) {
                // Convert error message from 'Prefix Range values must be identical number of digits of Reference BillerCRNList[0]'
                // to 'Prefix Range values must be identical number of digits of Reference 1'
                setSubmitErrors(response.data.errors.map((r: any) => r.message.replace(/BillerCRNList\[(\d)\]/, (_: any, number: number) => Number(number) + 1)));
            } else {
                setSubmitErrors([labels.unknownErrorMessage]);
            }
            return false;
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            {billerStatus === APICallRequestState.SUCCESSFUL ? <PageSection className='page-section'>
                <PageHeader
                    backButton
                    title={'Customer references'}
                />
                <PaddedContainer>

                    <Formik
                        initialValues={{
                            biller: {
                                acceptedCrn1: getAcceptedCrnInitialValues(biller.acceptedCrn1),
                                ...(biller.acceptedCrn2 && { acceptedCrn2: getAcceptedCrnInitialValues(biller.acceptedCrn2) }),
                                ...(biller.acceptedCrn3 && { acceptedCrn3: getAcceptedCrnInitialValues(biller.acceptedCrn3) }),
                            }
                        }}
                        validationSchema={getValidationSchema(currentCRNCount)}
                        onSubmit={(formValues) => { handleSubmit(formValues); }}
                    >
                        {(props: FormikProps<any>) => {
                            return (
                                <Form>
                                    <>
                                        {getCustomerReferenceEditElement(props)}
                                        <Button type='submit' loading={loading} primary>
                                            Save
                                        </Button>
                                        <Button onClick={() => {
                                            props.resetForm({
                                                values: {
                                                    biller: {
                                                        acceptedCrn1: getAcceptedCrnInitialValues(biller.acceptedCrn1),
                                                        ...(biller.acceptedCrn2 && { acceptedCrn2: getAcceptedCrnInitialValues(biller.acceptedCrn2) }),
                                                        ...(biller.acceptedCrn3 && { acceptedCrn3: getAcceptedCrnInitialValues(biller.acceptedCrn3) }),
                                                    }
                                                }
                                            })
                                        }}>
                                            Cancel</Button>
                                        {submitErrors.length ? submitErrors.map((errorMessage: string, i: number) => <ErrorText key={i}>{errorMessage}</ErrorText>) : null}
                                    </>
                                </Form>
                            )
                        }
                        }
                    </Formik>

                </PaddedContainer>
            </PageSection> : <LoadingIndicator />}

            <SuccessModal
                show={showSuccess}
                onClose={() => {
                    setShowSuccess(false);
                }}
                title='Customer references updated successfully!'
            />
        </>);
}

// use Redux to get billerCode for now as it won't be so harmful,
// it can be replaced by get billerCode from URL using { useLocation } if necessary
function mapStateToProps(state: any, ownProps: any) {
    const billerCode = ownProps.match.params.billerCode;
    return {
        billerCode,
    };
}

export default connect(mapStateToProps)(EditCustomerReferencesPage);
