import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useCurrentBreakpointName } from 'react-socks';
import PropTypes from 'prop-types';
import _ from 'lodash';

import labels from 'constants/labels';
import currencyUtil from '@premier/utils/currency';
import * as billpayUtil from '@premier/utils/billpay';

import { InputField, DropdownField, CardExpiryField, MaskedCardNumberField  } from '@premier/form';
import { ResponsiveTable, SuccessModal, Button } from '@premier/ui';
import { EditBatchRecordDialog } from 'components/Batch';
import { useDebounce } from 'components/Common';

import * as actions from 'components/Common/_actions/actions';

import './BatchRecordList.scss';

const validOrderTypes = ['MAIL_FAX', 'TELEPHONE', 'RECURRING', 'ECOMMERCE'];

const BatchRecordList = ({
    actions, //API actions
    records, fileId, isLoading, // data
    sort, onSort, // sorting
    context, orderTypes, onFilterChange // quick filter
}) => {

    const [reload, setReload] = useState(false);
    const [showDetailModal, setShowDetailModal] = useState(false);
    const [showSavedModal, setShowSavedModal] = useState(false);
    const [currentRecord, setCurrentRecord] = useState({});


    // Call API to reload data when triggered via the reload state
    useEffect(() => {
        if (reload) {
            onFilterChange(context.values);  // This triggers the API call on the parent
            setReload(false);
        }
    }, [reload]);

    const triggerReload = () => { setReload(true) };
    const debouncedTriggerReload = useDebounce(triggerReload);

    const onRecordClick = (f) => {
        setShowDetailModal(true);
        setCurrentRecord({
            fileId: fileId,
            recordId: f.recordId,
            cardNumber: f.card.number,
            crn1: f.crn1,
            amount: f.amount,
            expiryDate: f.card.expiryDate,
            orderTypeKey: f.authType
        });
     };

     function getOrderTypeOptions() {
        return [{ value: '', label: 'All' }].concat(
            _.filter(_.get(orderTypes, 'items'), x => validOrderTypes.includes(x.key)).map(x => (
                { value: x.key, label: x.description }
            ))
        );
    }

    function handleRecordSaved(isChanged) {
        if(isChanged){
            setShowSavedModal(true);
            onFilterChange(context.values); // Trigger a reload
        }
        setShowDetailModal(false); //Hide the edit window.
        onHideModal();
    };

    function handleQuickFilterChange() {
        debouncedTriggerReload();
    }

    function handleRecordCancel(){
        setShowDetailModal(false);
        onHideModal();
        actions.clearErrors();
    }

    function onHideModal(){
        context.setValue('editRecordForm', null);
    }

    var isTableView = ['md','lg','xl'].includes(useCurrentBreakpointName());

    const fields = {  // The quick filter fields
        reference: <InputField name='reference' onChange={handleQuickFilterChange} />,
        cardNumber: <MaskedCardNumberField name='accountNumber' onChange={handleQuickFilterChange} />,
        expiry: <CardExpiryField name='expiryDate' onChange={handleQuickFilterChange} compact={isTableView} />,
        amount: <InputField name='amount' decimal placeholder='0.00' onChange={handleQuickFilterChange} />,
        orderType: <DropdownField name='orderTypeKey'
                        options={getOrderTypeOptions()}
                        onChange={handleQuickFilterChange}
                        compact={isTableView} />,
    }

     const columns = [{
        label: labels.reference1,
        sortKey: 'reference',
        filter: fields.reference,
        getter: f => <Button link onClick={() => onRecordClick(f)}>{f.crn1}</Button>
    },{
        label: labels.cardNumber,
        sortKey: 'accountNumber',
        className: 'card-number',
        getter: r => r.card.number,
        filter: fields.cardNumber,
    },{
        label: labels.expiryDate,
        className: 'expiry',
        sortKey: 'expiryDate',
        getter: r => billpayUtil.formatExpiry(r.card.expiryDate),
        filter: fields.expiry,
    },{
        label: labels.amount,
        className: 'amount',
        sortKey: 'amount',
        getter: r => currencyUtil.convertToDisplayString(r.amount),
        filter: fields.amount,
    },{
        label: labels.orderType,
        sortKey: 'authType',
        getter: 'authType',
        filter: fields.orderType,
    }];

    return (
        <>
            <ResponsiveTable className='batch-record-list'
                data={records}
                columns={columns}
                isLoading={isLoading}
                sort={sort}
                onSort={onSort}
            />



            <EditBatchRecordDialog
                show={showDetailModal}
                record={currentRecord}
                orderTypes={orderTypes}
                onSave={handleRecordSaved}
                onCancel ={handleRecordCancel}
            />

            <SuccessModal show={showSavedModal} onClose={() => setShowSavedModal(false)}>
                Saved
            </SuccessModal>
        </>
    );
};

BatchRecordList.propTypes = {
    // Data
    records: PropTypes.array,
    isLoading: PropTypes.bool,
    fileId: PropTypes.number,

    // Sorting
    sort: PropTypes.object,
    onSort: PropTypes.func,

    // Quick filter
    /** The Form context */
    context: PropTypes.any,
    orderTypes: PropTypes.object,
    onFilterChange: PropTypes.func,
};

function mapStateToProps(state, ownProps) {
    return {
        isLoading: state.transactions.batch.isLoading,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(BatchRecordList);
