import { AxiosPromise } from "axios";
import { DeclineManagerApiFactory, ApiResponseListDeclineTrayModel, DeclineTrayModel, MerchantDeclineActionModel } from "packages/webapi-client";
import { config } from "./util";

const declineManagerApi = DeclineManagerApiFactory(config);

export enum DeclineCodeActionType {
    CreditCard = "CreditCard",
    BankAccount = "BankAccount",
}

class DeclineManagerApi {
    static getDelinceManagerSettings(merchantNumber: string) {
        return declineManagerApi.declineManagerGetDeclineSettings(merchantNumber);
    }

    static saveDeclineManagerSettings(merchantNumber: string, maxAttempts: number, maxDays: number, useDeclineManager: boolean, applyToAllMerchants: boolean) {
        return declineManagerApi.declineManagerSaveDeclineSettings(merchantNumber,
            {
                maxAttempts: maxAttempts,
                maxAttemptDays: maxDays,
                useDeclineManager: useDeclineManager,
                applyToAllMerchants: applyToAllMerchants
            }
        );
    }

    static retrieveMerchantTrays(request: string): AxiosPromise<ApiResponseListDeclineTrayModel> {
        return declineManagerApi.declineManagerRetrieveMerchantTrays(request);
    }

    static updateMerchantTray(request: DeclineTrayModel) {
        return declineManagerApi.declineManagerUpdateMerchantTray(request);
    }

    static getActions(merchantNumber: string, type: DeclineCodeActionType) {
        return declineManagerApi.declineManagerGetActions(merchantNumber, type);
    }

    static updateAllActions(merchantNumber: string, actionId: number, finalTrayId: number, type: DeclineCodeActionType) {
        return declineManagerApi.declineManagerUpdateAllActions(merchantNumber, { actionId, finalTrayId, type });
    }

    static updateActions(merchantNumber: string, type: DeclineCodeActionType, actions: MerchantDeclineActionModel[]) {
        return declineManagerApi.declineManagerUpdateActions(merchantNumber, type, actions);
    }

    static getRetryActions() {
        return declineManagerApi.declineManagerGetRetryActions();
    }
}

export default DeclineManagerApi;
