import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { Navigate, useParams } from "react-router-dom";
import { AppDispatch, RootState } from "store/store";
import dateUtil from "@premier/utils/date";
import { PageSection, LoadingIndicator, IconText, Link, Button, Icon, BackButton, Divider, PaddedContainer, Row, CardContainer, DescriptionList } from "@premier/ui";
import { PageHeader, useApiCall, APICallRequestState } from "components/Common";
import { CustomerProfileCard, EditTokenModals, EditTokenModalType, TokenDetailsList, CustomerPaymentModal } from "components/DataVault";
// @ts-ignore
import labels from "constants/labels";
import { PlatformRoutesConfiguration } from "components/Routing";
import SecurityUtil, { User } from "@premier/utils/security";
import { userRoles } from "components/Routing";
import * as tokenActions from "components/DataVault/_actions/tokenActions";
import { customerApi } from "api";
import { Token } from "models/Token";
import { Merchant } from "packages/utils/models";
import TokenPaymentRequestModal from "../components/TokenPaymentModals/TokenPaymentRequestModal";
import { Token as CustomerToken, PhoneNumber } from "packages/utils/models";

import "./ViewTokenPage.scss";

type Props = {
    actions: any;
    token: Token;
    isLoading: boolean;
    authenticatedUser: User;
    merchant: Merchant;
}

type CustomerDetails = {
    customerId: number;
    emailAddress: string;
    phoneNumbers: PhoneNumber[];
}

const ViewTokenPage = ({
    actions, //API actions
    token,
    isLoading, //logic renders
    authenticatedUser,
    merchant
}: Props) => {
    const params = useParams();
    const [tokenId] = useState(params.id);
    const [tokenFound, setTokenFound] = useState(false);
    const [tokenGetFailed, setTokenGetFailed] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [redirectBack, setRedirectBack] = useState(false);
    const [showNewPaymentModal, setShowNewPaymentModal] = useState(false);
    const [showNewPaymentRequestModal, setShowNewPaymentRequestModal] = useState(false);
    const [customerDetails, setCustomerDetails] = useState<CustomerDetails | null>(null);
    const dispatch = useDispatch();

    const canDoOneOffPayment = SecurityUtil.hasAccess([userRoles.singlePayment], authenticatedUser);
    const canDoPaymentRequest = SecurityUtil.hasAccess([userRoles.paymentRequest], authenticatedUser);
    const canDoRecurringSchedule = SecurityUtil.hasAccess([userRoles.recurringSchedule], authenticatedUser);

    useEffect(() => {
        refreshToken();
    }, [tokenId, actions]);

    const [customer, customerStatus] = useApiCall(async () => {
        if (token?.customerV2Id) {
            const dto = await customerApi.getCustomer(token.customerV2Id);
            return { data: { data: dto }, status: 200 };
        }
    }, [token]);

    useEffect(() => {
        if (customerStatus === APICallRequestState.SUCCESSFUL) {
            setCustomerDetails(customer);
        }
    }, [customerStatus, customer]);

    useEffect(() => {
        // Upon unmount, clear the token from Redux so it doesn't affect other pages.
        return () => {
            dispatch(tokenActions.clearTokenCache());
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (redirectBack)
        return <Navigate to={PlatformRoutesConfiguration.tokenRoute?.manageTokens.generatePath()!} />;

    function refreshToken() {
        actions.getToken(tokenId).then(() =>
            setTokenFound(true)
        ).catch((err: any) => {
            console.error(err);
            setTokenGetFailed(false);
        });
    }

    function getPrefixDetails() {
        return [
            { name: "Merchant:", value: token?.childMerchantNumber },
            { name: labels.createdTime + ":", value: token && token.createdTime ? dateUtil.convertToDateTimeString(token.createdTime) : null },
            { name: labels.updatedTime + ":", value: token && token.updatedTime ? dateUtil.convertToDateTimeString(token.updatedTime) : null }
        ];
    }

    function handleDeleteClose(deleted: boolean) {
        setShowDeleteDialog(false);

        if (deleted)
            setRedirectBack(true);
    }

    function handlePaymentModalClose() {
        setShowNewPaymentModal(false);
    }

    function handlePaymentRequestModalClose() {
        setShowNewPaymentRequestModal(false);
        refreshToken();
    }

    function handlePaymentRequestSuccessModalClose(showNewPaymentRequestModal?: boolean) {
        setShowNewPaymentRequestModal(showNewPaymentRequestModal ?? false);
        refreshToken();
    }

    function getSinglePaymentText() {
        if (canDoOneOffPayment && canDoPaymentRequest) {
            return "Make a one-off payment or request a payment";
        } else if (canDoOneOffPayment) {
            return "Make a one-off payment";
        } else if (canDoPaymentRequest) {
            return "Request a payment";
        }
        return "";
    }

    return (<>
        <PageSection className="view-token-page">
            {isLoading && <LoadingIndicator />}

            {!isLoading && tokenGetFailed && !tokenFound && <>
                <BackButton to={PlatformRoutesConfiguration.tokenRoute?.manageTokens.generatePath()} />
                <IconText alert>Token not found or an error occurred.</IconText>
            </>}

            {tokenFound && <>
                <PageHeader
                    backButton
                    title={token?.token}
                    subtitle={
                        <DescriptionList greyLabel items={getPrefixDetails()} />
                    }
                >
                    <Link button to={PlatformRoutesConfiguration.tokenRoute?.editToken.generatePath(token?.dataVaultId)}>Edit payment method</Link>
                </PageHeader>

                <CardContainer header="Payment method details">
                    <TokenDetailsList token={token} showTokenReferences />
                </CardContainer>

                <CustomerProfileCard customerId={token?.customerV2Id} tokenId={token.dataVaultId} customerCode={token.customerUniqueId} />

                <PaddedContainer
                    className="payments-section"
                    icon={<Icon money />}
                    title="Payments"
                >
                    <Row divided>
                        <div className="col-lg-6">
                            <h3>Single payment</h3>
                            <p>{getSinglePaymentText()}</p>
                            <div>
                                { canDoOneOffPayment && SecurityUtil.childMerchantHasAccess(token?.childMerchantNumber, merchant, userRoles.singlePayment, authenticatedUser) &&
                                    <Button primary onClick={() => setShowNewPaymentModal(true)}>New payment</Button> }
                                { canDoPaymentRequest && SecurityUtil.childMerchantHasAccess(token?.childMerchantNumber, merchant, userRoles.paymentRequest, authenticatedUser) &&
                                    <Button primary onClick={() => setShowNewPaymentRequestModal(true)}>New payment request</Button> }
                            </div>
                        </div>
                        { canDoRecurringSchedule &&
                            <div className="col-lg-6">
                                <h3>Subscription</h3>
                                <p>Schedule a recurring payment</p>
                                <Link primary to={PlatformRoutesConfiguration.tokenRoute?.newTokenSchedule.generatePath(tokenId)}>New subscription</Link>
                            </div>
                        }
                    </Row>
                    <Divider />
                </PaddedContainer>

                <Button subtle onClick={() => { setShowDeleteDialog(true); }}>Delete payment method</Button>
            </>}
        </PageSection>

        <CustomerPaymentModal
            show={showNewPaymentModal}
            onClose={handlePaymentModalClose}
            customer={{ tokens: [token as CustomerToken],
                emailAddress: customerDetails?.emailAddress,
                phoneNumbers: customerDetails?.phoneNumbers,
                customerId: customerDetails?.customerId
            }}
        />

        <TokenPaymentRequestModal
            show={showNewPaymentRequestModal}
            onClose={handlePaymentRequestModalClose}
            onSuccessModalClose={handlePaymentRequestSuccessModalClose}
            customer={customer}
            selectedToken={token}
        />

        <EditTokenModals initialModal={EditTokenModalType.DELETE_DIALOG} show={showDeleteDialog} allowUnlink onClose={handleDeleteClose} />
    </>);
};

function mapStateToProps(state: RootState) {
    return {
        token: state.dataVault.token.details,
        isLoading: state.dataVault.token.isLoading,
        authenticatedUser: state.accounts.users.authenticatedUser,
        merchant: state.accounts.users.merchant,
    };
}

function mapDispatchToProps(dispatch: AppDispatch) {
    return {
        actions: bindActionCreators(tokenActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewTokenPage);
