import { config } from './util';
import { TelemetryApiFactory, ClientsideErrorModel } from '@premier/webapi-client';

const telemetryApi = TelemetryApiFactory(config);

class TelemetryApi {
    static StoreExternalErrorLog(clientsideErrorModel : ClientsideErrorModel) {
        return telemetryApi.telemetryStoreExternalErrorLog(clientsideErrorModel);
    }
}

export default TelemetryApi;