import React from 'react';

import * as billpayUtil from '@premier/utils/billpay';
import { InputField, PhoneNumberField, DatePickerField } from '@premier/form';
import { Row } from '@premier/ui';
import { withAdvancedFilter } from 'components/Common';
import labels from 'constants/labels';

const CustomerFilter = () => {

    function handlePhoneNumberChange(val, context) {
        // Sync with phone in quick filter
        context.setValue('phone', billpayUtil.formatPhoneObjectToApiString(val));
    }

    return (
        <>
            <h1>Filter customers</h1>

            <Row>
                <div className='col-lg-4'>
                    <InputField name='uniqueId' label={labels.customerCode} />
                </div>
                <div className='col-lg-4'>
                    <InputField name='name' label={labels.name} />
                </div>
                <div className='col-lg-4'>
                    <InputField name='emailAddress' label={labels.email} />
                </div>
            </Row>

            <Row>
                <div className='col-lg-8'>
                    <PhoneNumberField name='phoneNumber' label={labels.phone} onChange={handlePhoneNumberChange} lessGapDownMd />
                </div>
            </Row>

            <Row>
                <div className='col-lg-4'>
                    <InputField name='crn1' label={labels.customerId1} />
                </div>
                <div className='col-lg-4'>
                    <InputField name='crn2' label={labels.customerId2} />
                </div>
                <div className='col-lg-4'>
                    <InputField name='crn3' label={labels.customerId3} />
                </div>
            </Row>

            <Row>
                <div className='col-lg-4'>
                    <DatePickerField name='dob' label={labels.dateOfBirth} />
                </div>
                <div className='col-lg-4'>
                    <InputField digitsOnly name='postcode' label={labels.postcode} />
                </div>
            </Row>

        </>
    );
};

export default withAdvancedFilter(CustomerFilter);