import { requestActionTypes } from 'components/Common/requestActions';

// Utilities
export const GET_CHILD_MERCHANTS_SUCCESS   = 'GET_CHILD_MERCHANTS_SUCCESS';
export const GET_CHILD_MERCHANTS_FAILED    = 'GET_CHILD_MERCHANTS_FAILED';
export const GET_CHILD_MERCHANTS_REQUESTED = 'GET_CHILD_MERCHANTS_REQUESTED';


//#region ----- Transaction History -----
export const LOAD_TRANSACTIONS_SUCCESS = 'LOAD_TRANSACTIONS_SUCCESS';
export const LOAD_TRANSACTIONS_FAILED = 'LOAD_TRANSACTIONS_FAILED';
export const LOAD_TRANSACTIONS_REQUESTED = 'LOAD_TRANSACTIONS_REQUESTED';

export const LOAD_SCHEDULED_PAYMENT_TRANSACTIONS_SUCCESS = 'LOAD_SCHEDULED_PAYMENT_TRANSACTIONS_SUCCESS';
export const LOAD_SCHEDULED_PAYMENT_TRANSACTIONS_FAILED = 'LOAD_SCHEDULED_PAYMENT_TRANSACTIONS_FAILED';
export const LOAD_SCHEDULED_PAYMENT_TRANSACTIONS_REQUESTED = 'LOAD_SCHEDULED_PAYMENT_TRANSACTIONS_REQUESTED';
export const SAVE_SCHEDULED_PAYMENT_TRANSACTIONS_PAGE_STATE = 'SAVE_SCHEDULED_PAYMENT_TRANSACTIONS_PAGE_STATE';

export const LOAD_TRANSACTION_DETAILS_SUCCESS   = 'LOAD_TRANSACTION_DETAILS_SUCCESS';
export const LOAD_TRANSACTION_DETAILS_FAILED    = 'LOAD_TRANSACTION_DETAILS_FAILED';
export const LOAD_TRANSACTION_DETAILS_REQUESTED = 'LOAD_TRANSACTION_DETAILS_REQUESTED';

export const DOWNLOAD_TRANSACTIONS_EXPORT_SUCCESS = 'DOWNLOAD_TRANSACTIONS_EXPORT_SUCCESS';
export const DOWNLOAD_TRANSACTIONS_EXPORT_FAILED = 'DOWNLOAD_TRANSACTIONS_EXPORT_FAILED';
export const DOWNLOAD_TRANSACTIONS_EXPORT_REQUESTED = 'DOWNLOAD_TRANSACTIONS_EXPORT_REQUESTED';
//#endregion

//#region ----- Transaction Filter -----
export const GET_TRANSACTION_TYPES_SUCCESS   = 'GET_TRANSACTION_TYPES_SUCCESS';
export const GET_TRANSACTION_TYPES_FAILED    = 'GET_TRANSACTION_TYPES_FAILED';
export const GET_TRANSACTION_TYPES_REQUESTED = 'GET_TRANSACTION_TYPES_REQUESTED';

export const GET_TXN_TYPES_SUCCESS   = 'GET_TXN_TYPES_SUCCESS';
export const GET_TXN_TYPES_FAILED    = 'GET_TXN_TYPES_FAILED';
export const GET_TXN_TYPES_REQUESTED = 'GET_TXN_TYPES_REQUESTED';

export const GET_TXN_SUB_TYPES_SUCCESS   = 'GET_TXN_SUB_TYPES_SUCCESS';
export const GET_TXN_SUB_TYPES_FAILED    = 'GET_TXN_SUB_TYPES_FAILED';
export const GET_TXN_SUB_TYPES_REQUESTED = 'GET_TXN_SUB_TYPES_REQUESTED';

export const GET_TXN_SOURCES_SUCCESS   = 'GET_TXN_SOURCE_SUCCESS';
export const GET_TXN_SOURCES_FAILED    = 'GET_TXN_SOURCE_FAILED';
export const GET_TXN_SOURCES_REQUESTED = 'GET_TXN_SOURCE_REQUESTED';

export const GET_TXN_SUB_SOURCES_SUCCESS   = 'GET_TXN_SUB_SOURCE_SUCCESS';
export const GET_TXN_SUB_SOURCES_FAILED    = 'GET_TXN_SUB_SOURCE_FAILED';
export const GET_TXN_SUB_SOURCES_REQUESTED = 'GET_TXN_SUB_SOURCE_REQUESTED';

export const GET_ACCOUNT_TYPES_SUCCESS   = 'GET_ACCOUNT_TYPES_SUCCESS';
export const GET_ACCOUNT_TYPES_FAILED    = 'GET_ACCOUNT_TYPES_FAILED';
export const GET_ACCOUNT_TYPES_REQUESTED = 'GET_ACCOUNT_TYPES_REQUESTED';

export const SAVE_TRANSACTIONS_PAGE_STATE = 'SAVE_TRANSACTIONS_PAGE_STATE';

//#endregion

//#region ----- Transaction Refund + Capture -----
export const REFUND_PAYMENT_SUCCESS   = 'REFUND_PAYMENT_SUCCESS';
export const REFUND_PAYMENT_FAILED    = 'REFUND_PAYMENT_FAILED';
export const REFUND_PAYMENT_REQUESTED = 'REFUND_PAYMENT_REQUESTED';

export const CAPTURE_PAYMENT_SUCCESS   = 'CAPTURE_PAYMENT_SUCCESS';
export const CAPTURE_PAYMENT_FAILED    = 'CAPTURE_PAYMENT_FAILED';
export const CAPTURE_PAYMENT_REQUESTED = 'CAPTURE_PAYMENT_REQUESTED';

//#endregion


//#region ----- New Payment -----
export const CLEAR_NEW_PAYMENT = 'CLEAR_NEW_PAYMENT';
export const SET_NEW_PAYMENT_DETAILS = 'SET_NEW_PAYMENT_DETAILS';
export const GET_NEW_PAYMENT_SURCHARGE_REQUESTED = 'GET_NEW_PAYMENT_SURCHARGE_REQUESTED';
export const GET_NEW_PAYMENT_SURCHARGE_SUCCESS = 'GET_NEW_PAYMENT_SURCHARGE_SUCCESS';
export const GET_NEW_PAYMENT_SURCHARGE_FAILED = 'GET_NEW_PAYMENT_SURCHARGE_FAILED';

export const GET_TRANSACTION_SOURCES_SUCCESS   = 'GET_TRANSACTION_SOURCES_SUCCESS';
export const GET_TRANSACTION_SOURCES_FAILED    = 'GET_TRANSACTION_SOURCES_FAILED';
export const GET_TRANSACTION_SOURCES_REQUESTED = 'GET_TRANSACTION_SOURCES_REQUESTED';

export const CREATE_NEW_PAYMENT_SUCCESS   = 'CREATE_NEW_PAYMENT_SUCCESS';
export const CREATE_NEW_PAYMENT_FAILED    = 'CREATE_NEW_PAYMENT_FAILED';
export const CREATE_NEW_PAYMENT_REQUESTED = 'CREATE_NEW_PAYMENT_REQUESTED';

export const CREATE_NEW_PAYMENT_REQUEST_SUCCESS   = 'CREATE_NEW_PAYMENT_REQUEST_SUCCESS';
export const CREATE_NEW_PAYMENT_REQUEST_FAILED    = 'CREATE_NEW_PAYMENT_REQUEST_FAILED';
export const CREATE_NEW_PAYMENT_REQUEST_REQUESTED = 'CREATE_NEW_PAYMENT_REQUEST_REQUESTED';

export const SEND_PAYMENT_RECEIPT_SUCCESS   = 'SEND_PAYMENT_RECEIPT_SUCCESS';
export const SEND_PAYMENT_RECEIPT_FAILED    = 'SEND_PAYMENT_RECEIPT_FAILED';
export const SEND_PAYMENT_RECEIPT_REQUESTED = 'SEND_PAYMENT_RECEIPT_REQUESTED';

export const GET_MERCHANT_ADDRESS_SUCCESS   = 'GET_MERCHANT_ADDRESS_SUCCESS';
export const GET_MERCHANT_ADDRESS_FAILED    = 'GET_MERCHANT_ADDRESS_FAILED';
export const GET_MERCHANT_ADDRESS_REQUESTED = 'GET_MERCHANT_ADDRESS_REQUESTED';
//#endregion

//#region ----- Schedules -----
export const GET_SCHEDULES = requestActionTypes('GET_SCHEDULES');
export const GET_SCHEDULE = requestActionTypes('GET_SCHEDULE');
export const ADD_SCHEDULE = requestActionTypes('ADD_SCHEDULE');
export const UPDATE_SCHEDULE = requestActionTypes('UPDATE_SCHEDULE');
export const CANCEL_SCHEDULE = requestActionTypes('CANCEL_SCHEDULE');
export const SUSPEND_SCHEDULE = requestActionTypes('SUSPEND_SCHEDULE');
export const RESUME_SCHEDULE = requestActionTypes('RESUME_SCHEDULE');
export const GET_OVERDUE_PAYMENTS_COUNT = requestActionTypes('GET_OVERDUE_PAYMENTS_COUNT');
//#endregion

//#region ----- Schedules Filter -----
export const GET_SCHEDULE_STATUSES = requestActionTypes('GET_SCHEDULE_STATUSES');
export const GET_SCHEDULE_FREQUENCIES = requestActionTypes('GET_SCHEDULE_FREQUENCIES');
export const GET_SCHEDULE_PAYMENT_STATUSES = requestActionTypes('GET_SCHEDULE_PAYMENT_STATUSES');
export const SAVE_SCHEDULES_PAGE_STATE = 'SAVE_SCHEDULES_PAGE_STATE';
//#endregion

//#region ----- Scheduled Payments -----
export const LOAD_SCHEDULED_PAYMENTS_SUCCESS = 'LOAD_SCHEDULED_PAYMENTS_SUCCESS';
export const LOAD_SCHEDULED_PAYMENTS_FAILED = 'LOAD_SCHEDULED_PAYMENTS_FAILED';
export const LOAD_SCHEDULED_PAYMENTS_REQUESTED = 'LOAD_SCHEDULED_PAYMENTS_REQUESTED';
export const SAVE_SCHEDULED_PAYMENTS_COUNT = 'SAVE_SCHEDULED_PAYMENTS_COUNT';

export const GET_SCHEDULED_PAYMENT_REQUESTED = 'GET_SCHEDULED_PAYMENT_REQUESTED';
export const GET_SCHEDULED_PAYMENT_SUCCESS = 'GET_SCHEDULED_PAYMENT_SUCCESS';
export const GET_SCHEDULED_PAYMENT_FAILED = 'GET_SCHEDULED_PAYMENT_FAILED';

export const CANCEL_SCHEDULED_PAYMENT_REQUESTED = 'CANCEL_SCHEDULED_PAYMENT_REQUESTED';
export const CANCEL_SCHEDULED_PAYMENT_SUCCESS = 'CANCEL_SCHEDULED_PAYMENT_SUCCESS';
export const CANCEL_SCHEDULED_PAYMENT_FAILED = 'CANCEL_SCHEDULED_PAYMENT_FAILED';

export const RETRY_SCHEDULED_PAYMENT_REQUESTED = 'RETRY_SCHEDULED_PAYMENT_REQUESTED';
export const RETRY_SCHEDULED_PAYMENT_SUCCESS = 'RETRY_SCHEDULED_PAYMENT_SUCCESS';
export const RETRY_SCHEDULED_PAYMENT_FAILED = 'RETRY_SCHEDULED_PAYMENT_FAILED';
//#endregion
