import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import { RootState } from "store/store";
import classNames from "classnames";

import currencyUtil from "@premier/utils/currency";
import countryUtil from "@premier/utils/country";

// @ts-ignore
import labels from "constants/labels";
// @ts-ignore
import { PaymentDetailsTabKeys, BillerDefaultAcceptedAmounts } from "constants/billpay";
// @ts-ignore
import errorMaps from "constants/errorMaps";

import { Button, LoadingIndicator } from "@premier/ui";
import { validate, CurrencyField, FormDialog, DropdownField, InputField, SubmitButton, FormErrorList } from "@premier/form";
import { MerchantBillerDetailsForm, PaymentInputSelectorForm } from "components/Transactions";
import { useLocation } from "react-router-dom";
import { PlatformRoutesConfiguration } from "components/Routing";
import * as paymentActions from "components/Utilities/_actions/utilitiesActions";
import { Customer } from "packages/utils/models";
import { Merchant } from "models";
import { OrderType } from "models/OrderType";
import { FieldError } from "api/mapErrors";
import { CustomerPaymentFormValues } from "./CustomerPaymentModal";

type Props = {
    name?: string;
    initialValues: CustomerPaymentFormValues | null;
    customerData: Customer;
    show: boolean;
    onClose: () => void;
    onSubmit: (values: any, currentAction: string | null) => void;
    merchant: Merchant;
    defaultOrderType: OrderType;
    orderTypes: OrderType[];
    paymentActions: any;
    errors: FieldError[];
}

const NewPaymentModal = ({
    name = "newCustomerPaymentModal", initialValues, customerData, //data
    show, onClose, onSubmit, //modal functions
    merchant, defaultOrderType, orderTypes, //state values
    paymentActions, //API actions
    errors, //forms
}: Props) => {
    const [currentAction, setCurrentAction] = useState<string | null>(PaymentDetailsTabKeys.PAYMENT);
    const isPaymentFromPaymentMethodPage = useLocation().pathname.startsWith(PlatformRoutesConfiguration.tokenRoute!.manageTokens.path);

    const parentMerchant = {
        merchantNumber: merchant.merchantNumber,
        merchantName: merchant.merchantName,
        isParent: true,
    };

    useEffect(() => {
        if (!orderTypes.length)
            paymentActions.getProcessTransactionOrderTypes();
    }, []);

    function handleTabChange(tabKey: string | null) {
        setCurrentAction(tabKey);
    }

    function handleSubmitButtonTextContent() {
        switch (currentAction) {
            case PaymentDetailsTabKeys.PAYMENT:
                return "Submit payment";
            case PaymentDetailsTabKeys.REQUESTS:
                return "Send request";
            case PaymentDetailsTabKeys.QRREQUESTS:
                return "Generate QR code";
            default:
                throw new Error("currentAction not set.");
        }
    }

    function handleSubmit(values: any) {
        onSubmit(values, currentAction);
    }

    // Do not render Form before getting orderTypes to make sure the defaultOrderType is initially selected
    if (!initialValues && !orderTypes.length)
        return <LoadingIndicator />;

    return (
        <FormDialog
            name={name}
            show
            className={classNames("new-payment-modal", { "d-none": !show })}
            title="New payment"
            closeButton
            onClose={onClose}
            onSubmit={handleSubmit}
            errors={errors}
            errorMaps={errorMaps}
            initialValues={
                initialValues || {
                    billerCodeForm: {
                        merchantNumber: customerData?.childMerchantNumber ?? (customerData?.tokens ? customerData?.tokens[0]?.childMerchantNumber : undefined) ?? parentMerchant.merchantNumber, //for filtering biller drop-down on customer's merchant number
                        billerCode: "",
                        billerCrnList: {
                            crn1: isPaymentFromPaymentMethodPage ? ((customerData?.tokens ? customerData.tokens[0]?.crn1 : undefined) ?? "") : "",
                            crn2: isPaymentFromPaymentMethodPage ? ((customerData?.tokens ? customerData.tokens[0]?.crn2 : undefined) ?? "") : "",
                            crn3: isPaymentFromPaymentMethodPage ? ((customerData?.tokens ? customerData.tokens[0]?.crn3 : undefined) ?? "") : "",
                        }
                    },
                    orderType: defaultOrderType,
                    paymentInputSelector: {
                        paymentRequest: {
                            email: customerData.emailAddress ?? "",
                            mobile: (customerData && customerData.phoneNumbers ? customerData.phoneNumbers[0]?.phoneNumber : null) ?? {
                                iddCode: countryUtil.getIddCode(merchant.countryCode)
                            }
                        }
                    }
                }
            }
            initialValidation={{
                amount: validate()
                    .required()
                    .when((val) => currencyUtil.parseAmount(val)! > BillerDefaultAcceptedAmounts.MIN && currencyUtil.parseAmount(val)! <= BillerDefaultAcceptedAmounts.MAX, "Invalid amount"),
                orderType: validate()
                    .required(),
            }}
            renderButtons={(context) => (
                <>
                    <SubmitButton>{handleSubmitButtonTextContent()}</SubmitButton>
                    <Button onClick={onClose}>Cancel</Button>
                </>
            )}
        >
            <h2>Enter payment details</h2>

            <MerchantBillerDetailsForm
                hideMerchantField
                initialValuesTokens={customerData.tokens ? customerData.tokens[0] : undefined}
            />

            <InputField
                name="merchantReference"
                label={labels.merchantReference}
            />

            <DropdownField
                name="orderType"
                label={labels.orderType}
                options={orderTypes.map((x) => ({ value: x.key, label: x.description }))}
            />

            <CurrencyField
                name="amount"
                label={labels.amount}
                className="no-number-spinner"
                maxLength={12}
            />

            <PaymentInputSelectorForm
                required={currentAction === PaymentDetailsTabKeys.PAYMENT}
                label="Payment"
                paymentMethods={customerData.tokens}
                onTabChange={handleTabChange}
            />

            <FormErrorList />
        </FormDialog>
    );
};

function mapStateToProps(state: RootState) {
    return {
        orderTypes: state.transactions.payments.newPaymentOrderTypes
            ? state.transactions.payments.newPaymentOrderTypes.items
            : [],
        defaultOrderType: state.transactions.payments.newPaymentOrderTypes
            ? state.transactions.payments.newPaymentOrderTypes.defaultKey
            : "",
        merchant: state.accounts.users.merchant,
        errors: state.transactions.payments.errors,
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        paymentActions: bindActionCreators(paymentActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(NewPaymentModal);
