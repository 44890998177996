import React from 'react';
import './ErrorPage.scss';

const ErrorPage = ({
    children //data
}) => {
    return (
        <div className="error-page">
            {children}
        </div>
    );
}

export default ErrorPage;