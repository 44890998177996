import { UiOption } from "./models";

type ApiItemsData = {
    items: {
        key: string,
        description: string
    }[],
    defaultKey?: string
};

export class UiUtil {

    /** Add an All option to a list of options (to be passed to a dropdown/radio/etc) */
    addAllOption = (options?: UiOption<string>[], allValue = '', allLabel = 'All') => {
        var allOption: UiOption<string> = {
            value: allValue,
            label: allLabel
        };

        return [allOption].concat(options ?? []);
    }

    /** Generate options from a list returned by API, including an 'All' option
     * @param apiData eg. { items: [{key:'x', description:'x'}], defaultKey: 'x' }
     */
    generateOptions = (apiData?: ApiItemsData) => {
        let options = this.getOptions(apiData);
        return this.addAllOption(options);
    }

    /** Get options from a list returned by API
     * @param apiData eg. { items: [{key:'x', description:'x'}] }
     */
    getOptions = (apiData?: ApiItemsData) => {
        return apiData?.items?.map(x => ({value: x.key, label: x.description})) ?? [];
    }
}

const uiUtil = new UiUtil();
export default uiUtil;
