import React from 'react';
import { PageSection, Link } from '@premier/ui';
import { absolute } from '@premier/utils/url';

/* eslint jsx-a11y/anchor-is-valid: 0 */

/** Contact us page's footer.
 * Display's buisness contact details for use on the contact us page */
const ContactFooter = () => {
    return (
        <>
            <PageSection>
                <div className='row'>
                    <div className='col-lg-4 col-md-6'>
                        <h2>Business enquiries</h2>
                        <strong>Call 1800 230 177 (Option 3)</strong>
                        <p>Any time</p>
                    </div>

                    <div className='col-lg-8 col-md-6'>
                        <strong>Call any time for</strong>
                        <ul>
                            <li>Changes to your facility and details</li>
                            <li>Fees</li>
                            <li>Settlement and chargebacks</li>
                        </ul>
                    </div>
                </div>
            </PageSection>

            <PageSection>
                <div className='row'>
                    <div className='col-lg-4 col-md-6'>
                        <h2>Technical enquiries</h2>
                        <strong>Call 1300 766 031</strong>
                        <p>
                            7am - 7pm, Mon - Fri
                            <br />
                            (Sydney/Melbourne time)
                        </p>
                    </div>

                    <div className='col-lg-8 col-md-6'>
                        <strong>Call for</strong>
                        <ul>
                            <li>How to use BPOINT</li>
                            <li>
                                <Link to={absolute(process.env.REACT_APP_SITE_DOMAIN, 'developers/v3/')}>Software Development Kit</Link> and{' '}
                                <Link to={absolute(process.env.REACT_APP_SITE_DOMAIN, 'developers/v3/#plugins')}>plugins</Link>
                            </li>
                            <li>
                                <Link to={absolute(process.env.REACT_APP_SITE_DOMAIN, 'developers/v3/#libraries_mobile')}>Mobile Toolkit</Link> for in-App
                                payments
                            </li>
                        </ul>
                    </div>
                </div>
            </PageSection>
        </>
    );
};

export default ContactFooter;
