import React from 'react';
import { Navigate } from 'react-router-dom';
import { connect } from 'react-redux';

import { Icon, PageSection } from '@premier/ui';
import { PromotionBoxesPageSection } from 'components/Common';
import { BillerListContainer } from '..';

import { PlatformRoutesConfiguration } from 'components/Routing';

/**This Manage Billers Page will only be shown when the
 * current merchant only has 1 biller code enabled.
*/
const ManageBillersPage = ({
    billers
}) => {

    if (billers.length > 1)
        return <Navigate to={PlatformRoutesConfiguration.settingsRoute.paymentSettings.generatePath()} />

    const promotions = [
        <>
            <Icon plusCircled />
            <h2>Add a biller code</h2>
            <p>For each income stream</p>
        </>,
        <>
            <Icon card />
            <h2>Set up accepted cards {`&`} surcharge</h2>
            <p>Add more payment options and recover the cost of your card payments</p>
        </>,
        <>
            <Icon identity />
            <h2>Set up references</h2>
            <p>To identify payments</p>
        </>
    ]

    return (<>
        <PromotionBoxesPageSection
            backButton
            noDivider
            title='Manage biller codes'
            subText='Using biller codes makes it easier to manage and keep track of payments if you have multiple income streams.'
            promotions={promotions}
        />
        <PageSection>
            <BillerListContainer
                billers={billers}
                description='Your current payment settings are saved against your first biller code. Edit your payment settings for each biller code you create or disable a biller code at anytime.'
                instruction='Click to edit the payment settings for your first biller code'
                generateLink={(b) => PlatformRoutesConfiguration.settingsRoute.billerDetails.generatePath(b.billerCode)}
                allowAdd
            />
        </PageSection>
    </>);
}

function mapStateToProps(state, ownProps) {
    return {
        billers: state.accounts.users.billers,
    };
}

export default connect(mapStateToProps)(ManageBillersPage);