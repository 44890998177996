import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';

import { PageSection, PaginationControl } from '@premier/ui';

import { ScheduledPaymentList } from 'components/Transactions';
import { PageHeader } from 'components/Common';
import { PlatformRoutesConfiguration } from 'components/Routing';

import * as scheduledPaymentActions from 'components/Transactions/_actions/scheduledPaymentActions';

const ScheduledPaymentsHistoryPage = ({
    actions, //API actions
    subscriptionId, searchResult, //state values
    isLoading, //logic renders
}) => {

    const [sort, setSort] = useState({field: 'scheduledPaymentDate', descending: true});
    const [currentPage, setCurrentPage] = useState(1);

    const resultsPerPage = 10;

    useEffect(() => {
        if(currentPage && sort && subscriptionId) {
            actions.getScheduledPayments(resultsPerPage, currentPage-1, {subscriptionId}, sort);
        }
    }, [currentPage, sort, subscriptionId]);

    function handleSort(field, descending) {
        setSort({field, descending});
    }

    function pageChanged(newPage) {
        setCurrentPage(newPage);
    }

    // ========== Render ==========
    return (
        <PageSection className='scheduled-payments-history'>
            <PageHeader
                backButton
                title='Scheduled payments history'
            >
            </PageHeader>

            <ScheduledPaymentList
                sortable={true}
                sort={sort}
                onSort={handleSort}
                payments={_.get(searchResult, 'items', [])}
                isLoading={isLoading}
                getRowLink={t => PlatformRoutesConfiguration.transactionRoute.scheduledPaymentTransactions.generatePath(t.id)}
            />

            <PaginationControl
                currentPage={currentPage}
                onPageChanged={pageChanged}
                itemCount={_.get(searchResult, 'count', 0)}
                itemsPerPage={resultsPerPage}
            />
        </PageSection>
    );
}

function mapStateToProps(state, ownProps) {
    const subscriptionId = ownProps.match.params.id;

    return {
        subscriptionId,
        searchResult: state.transactions.scheduledPayment.searchResult,
        isLoading: state.transactions.scheduledPayment.isLoading,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(scheduledPaymentActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ScheduledPaymentsHistoryPage)
