import "./FilenameSubheading.scss";

type Props = {
    filenames: string[];
    isListAllFiles?: boolean;
}

/** A simple text showing the filename (or the number of files if multiple) to be shown under the heading in a Modal */
const FilenameSubheading = ({ filenames, isListAllFiles = false }: Props) => {

    const multipleFiles = filenames && filenames.length > 1;

    return filenames && (
        <div className="filename-subheading-wrapper">
            <p>
                {!isListAllFiles && !multipleFiles ? filenames[0] : !isListAllFiles ? `${filenames.length} files` : ""}
            </p>

            {isListAllFiles && (
                <div>
                    {filenames.map(filename => (
                        <p>
                            {filename}
                        </p>
                    ))}
                </div>
            )}
        </div>
    );

};

export default FilenameSubheading;
