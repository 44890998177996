import * as actions from '../_actions/actionTypes';
import * as accountActions from 'components/Account/_actions/actionTypes';
import * as commonActions from 'components/Common/_actions/actionTypes';

export default function customerReducer(state = {}, action) {
    switch (action.type) {

        case actions.GET_CUSTOMERS_SUCCESS:
            return Object.assign({}, state, {
                searchResult: {
                    items: action.result.customers,
                    count: action.result.resultCount
                }
            });

        case actions.SAVE_CUSTOMERS_PAGE_STATE:
            return Object.assign({}, state, {
                customersPageState: action.pageState
            });

        case actions.SAVE_CUSTOMER_COUNT:
            return Object.assign({}, state, {
                customerCount: state.customerCount || action.count
            });

        case actions.GET_CUSTOMER_SUCCESS:
            return Object.assign({}, state, {
                details: action.customer
            });



        case accountActions.LOGOUT_USER_SUCCESS:
        case commonActions.CLEAR_SENSITIVE_DATA:
            return {
                asyncCount: state.asyncCount,
                isLoading: state.isLoading
            };
        default:
            return state;
    }
}
