import React from 'react';
import { Tabs as BootstrapTabs, TabsProps as BootstrapTabsProps } from 'react-bootstrap';
import './Tabs.scss';

type Props = BootstrapTabsProps;

// For documentation, see https://react-bootstrap.github.io/components/tabs/
const Tabs = (props: Props) => {
    return (
        <BootstrapTabs {...props} />
    );
};

export default Tabs;
