import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { ResponsiveTable, IconText } from '@premier/ui';
import { FormContext, InputField, DropdownField } from '@premier/form';
import { useDebounce } from 'components/Common';

import { PlatformRoutesConfiguration } from 'components/Routing';
import * as billpayUtil from '@premier/utils/billpay';
import labels from 'constants/labels';

import './UserList.scss';


const CustomerList = ({
    onFilterChange, sort, onSort,
    onRowLinkClick,
    users, statusOptions, roleOptions, isLoading
}) => {

    const context = useContext(FormContext);
    const debouncedFilterChange = useDebounce(onFilterChange);

    function handleQuickFilterChange() {
        debouncedFilterChange(context.values);
    }

    useEffect(() => {
        var selectedRole = context.getValue("roleName");
        if (selectedRole && !_.some(roleOptions, {value: selectedRole})) {
            context.setValue("roleName", "");
            onFilterChange(context.values);
        }
    }, [context.values.childMerchantNumber]);

    const fields = {
        status: <DropdownField
                    name='status'
                    options={statusOptions}
                    onChange={handleQuickFilterChange}
                />,
        name: <InputField name='fullName' onChange={handleQuickFilterChange} />,
        userName: <InputField name='userName' onChange={handleQuickFilterChange} />,
        emailAddress: <InputField name='emailAddress' onChange={handleQuickFilterChange} />,
        userRole: <DropdownField
                      name='roleName'
                      options={roleOptions}
                      onChange={handleQuickFilterChange}
                  />,
    }

    const columns = [{
        label: labels.userStatus,
        sortKey: 'status',
        getter: user => user.isActive
            ? <IconText tick> Active </IconText>
            : <IconText alert> Inactive </IconText>,
        filter: fields.status,
    },{
        label: labels.name,
        sortKey: 'fullName',
        getter: user => billpayUtil.getCustomerFullName(user),
        filter: fields.name,
    },{
        label: labels.loginUsername,
        sortKey: 'userName',
        getter: t => <span className='username'>{t.userName}</span>,
        filter: fields.userName,
    },{
        label: labels.email,
        getter: 'emailAddress',
        sortKey: 'emailAddress',
        filter: fields.emailAddress,
    },{
        label: labels.userRole,
        getter: 'roleName',
        sortKey: 'roleName',
        filter: fields.userRole,
    }];

    return (
        <ResponsiveTable className='user-list'
            data={users}
            columns={columns}
            isLoading={isLoading}
            sort={sort}
            onSort={onSort}
            getRowLink={c => PlatformRoutesConfiguration.usersRoute.viewUser.generatePath(c.userId)}
            onRowLinkClick={onRowLinkClick}
        />
    );
};

CustomerList.propTypes = {
    onFilterChange: PropTypes.func,
    /** The current sort order */
    sort: PropTypes.shape({
        field: PropTypes.string,
        descending: PropTypes.bool,
    }),
    /** to handle sort field/order changes */
    onSort: PropTypes.func,

    onRowLinkClick: PropTypes.func,

    users: PropTypes.array,
    userRoles: PropTypes.array,
    isLoading: PropTypes.bool,
};

export default CustomerList;
