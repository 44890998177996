import React from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import _ from 'lodash';

import dateUtil from '@premier/utils/date';
import { Icon, Link } from '@premier/ui';
import { PageHeader } from 'components/Common';

import { PlatformRoutesConfiguration } from 'components/Routing';

import DeliveryImpactNotice from '../components/DeliveryImpactNotice';


const OrderReceived = ({
    stationeryShop //state values
}) => {
    const orderDetail = _.get(stationeryShop, 'lastOrder');

    if (!orderDetail)
        return <Navigate to={PlatformRoutesConfiguration.stationeryRoute.shoppingCart.generatePath()} />;

    return (
        <>
            <DeliveryImpactNotice />

            <PageHeader
                icon={<Icon tick />}
                title='Your order has been submitted!'
            />

            <div>
                {`Order submitted on ${dateUtil.formatToDateTimeString(orderDetail.dateCreated)}`}
            </div>
            <p>
                {`Please use the order number `}
                <Link
                    to={PlatformRoutesConfiguration.stationeryRoute.orderDetails.generatePath(orderDetail.orderId)}
                >
                    {orderDetail.orderReference}
                </Link>
                {` if you need to contact us with any questions.`}
            </p>
            <p>The delivery time is 5-10 business days. You can view your order under <Link to={PlatformRoutesConfiguration.stationeryRoute.orders.generatePath()}>My orders</Link>.</p>
        </>
    );
};

function mapStateToProps(state, ownProps) {
    return {
        stationeryShop: state.stationeryShop.shop,
    };
}

export default connect(mapStateToProps)(OrderReceived);
