import { userRoles } from "components/Routing";
import { Merchant } from "packages/utils/models";

type Role = string | string[];

export type User = {
    features?: string[];
}

export class SecurityUtil {
    hasAccess(roles: Role[] | undefined, user: User) {
        if (!roles || roles.length === 0) {
            return true;
        }

        const features = user?.features;
        if (!features) {
            return false;
        }

        return roles.some((role: Role) =>
            Array.isArray(role)
                ? role.every(innerRole => features.indexOf(innerRole) >= 0) // inner array means the user must have all the roles
                // eg. [[role1, role2]] means role1 and role2 must be available [role1, role2] just means either role1 OR role2 is sufficient
                : features.indexOf(role) >= 0
        );
    }

    childMerchantHasAccess(childMerchantNumber: string | undefined, merchant: Merchant, role: userRoles, user: User) {
        // If no child merchant or if the childMerchantNumber passed through matches the parent's merchant number,
        // then access should be determined by hasAccess() instead.
        if (!childMerchantNumber || childMerchantNumber === merchant.merchantNumber) {
            return this.hasAccess([role], user);
        }

        // Find the relevant child merchant.
        const childMerchant = merchant.childMerchants?.find(cm => cm.merchantNumber === childMerchantNumber)

        // Do they have that role/feature?
        return childMerchant?.features?.some(f => f === role) ?? false;
    }
}

const S = new SecurityUtil();
export default S;
